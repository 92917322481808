import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGEASK } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import {
  AddQuestionModal,
  FilterDataModel,
  QuestionsListModel
} from "@timhortons/common/src/models/askTell/manageAskModule";

const getAllQuestions = (data: FilterDataModel): IFluxStandardAction<FilterDataModel> => {
  return {
    type: MANAGEASK.Questions.GET_ALL_QUESTIONS.LOADING,
    payload: data
  };
};
const getAllQuestionsSuccess = (
  data: QuestionsListModel
): IFluxStandardAction<QuestionsListModel> => {
  return {
    type: MANAGEASK.Questions.GET_ALL_QUESTIONS.SUCCESS,
    payload: data
  };
};
const getAllQuestionsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGEASK.Questions.GET_ALL_QUESTIONS.ERROR,
    payload: error
  };
};
const deleteQuestion = (data: {
  params: FilterDataModel;
  questionId: string;
}): IFluxStandardAction<{ params: FilterDataModel; questionId: string }> => {
  return {
    type: MANAGEASK.Questions.DELETE_QUESTION.LOADING,
    payload: data
  };
};
const deleteQuestionSuccess = () => {
  return {
    type: MANAGEASK.Questions.DELETE_QUESTION.SUCCESS
  };
};
const deleteQuestionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGEASK.Questions.DELETE_QUESTION.ERROR,
    payload: error
  };
};
const postQuestion = (data: {
  body: AddQuestionModal;
  params: FilterDataModel;
}): IFluxStandardAction<{ body: AddQuestionModal; params: FilterDataModel }> => {
  return {
    type: MANAGEASK.Questions.POST_QUESTION.LOADING,
    payload: data
  };
};
const postQuestionSuccess = () => {
  return {
    type: MANAGEASK.Questions.POST_QUESTION.SUCCESS
  };
};
const postQuestionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGEASK.Questions.POST_QUESTION.ERROR,
    payload: error
  };
};
const resetError = () => {
  return {
    type: MANAGEASK.Questions.RESET_ERROR
  };
};
export const QuestionsModuleActions = {
  getAllQuestions,
  getAllQuestionsSuccess,
  getAllQuestionsError,
  deleteQuestion,
  deleteQuestionSuccess,
  deleteQuestionError,
  postQuestion,
  postQuestionSuccess,
  postQuestionError,
  resetError
};
