import { StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";

export const levelOfReportStyles = StyleSheet.create({
  levelReportText: {
    marginBottom: 24
  },
  detailedContainer: {
    flexDirection: "row",
    flex: 1,
    flexWrap: "wrap"
  },
  comparisonContainer: {
    flex: 1,
    paddingTop: 30
  },
  allComparisonContainer: {
    flexDirection: "row",
    flex: 1,
    paddingTop: 30
  },
  maxWidth: {
    flex: 1,
    paddingTop: 30,
    maxWidth: 530
  },
  dropdownContainer: {
    flexBasis: "50%",
    paddingBottom: 30
  },
  dropdownLabel: {
    color: colorPallete.black4,
    marginBottom: 12
  },
  dropdown: {
    ...I18nService.select({
      rtl: { paddingStart: 30 },
      ltr: { paddingEnd: 30 }
    })
  },
  singleSelect: {
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey6,
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 16
  },
  singleSelectedLabel: {
    color: colorPallete.black2
  },
  singleSelectIcon: {
    fontSize: 14
  }
});
