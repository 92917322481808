import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import { manageLmsEndPoints } from "@timhortons/common/src/constants/endPoints";
import {
  LmsFeedbackDataModel,
  LmsFeedbackResponseModel
} from "@timhortons/common/src/models/manageLms/lmsFeedback";
import { getFeedbackDataFormatter } from "@timhortons/common/src/repositories/manageLms/formatters/lmsFeedbackFormatter";
import { IFeedbackTexts } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/feedback/organisms/feedbackArrayConstants";

class LmsFeedbackRepository {
  getLmsFeedbackByModule = async (data: { moduleId: string }): Promise<IFeedbackTexts[]> => {
    const { moduleId } = data;
    let response: LmsFeedbackResponseModel[] = await ApiClient.get(
      manageLmsEndPoints.feedback.getLmsFeedbackByModuleId(moduleId),
      {},
      LMSKeys.LMS_MANAGE
    );
    return getFeedbackDataFormatter(response);
  };
  postLmsFeedback = async (data: LmsFeedbackDataModel) => {
    let response: LmsFeedbackResponseModel = await ApiClient.post(
      manageLmsEndPoints.feedback.postLmsFeedback(),
      data,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  updateLmsFeedback = async (data: {
    moduleId: string;
    obj: LmsFeedbackResponseModel;
  }): Promise<LmsFeedbackResponseModel[]> => {
    const { moduleId, obj } = data;
    let response: LmsFeedbackResponseModel[] = await ApiClient.put(
      manageLmsEndPoints.feedback.updateLmsFeedback(moduleId),
      { feedbacks: obj },
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
}

const lmsFeedbackRepository = new LmsFeedbackRepository();
export { lmsFeedbackRepository as LmsFeedbackRepository };
