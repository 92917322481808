import { Roles } from "@timhortons/common/src/constants/roles";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  ObservationDataModel,
  TippingPointsModel,
  CommentDataModel,
  StoreAuditInfoModel,
  YesNoDataModel,
  PostObservationSuccessModel,
  SosAverageTimeModel,
  PostCommentModel,
  PreviousAuditDataModel,
  SubCategoryModel,
  GetStoreListByCodeOrNamePayload
} from "@timhortons/common/src/models/rev/selfCalibration";
import { DetailedReportIdModel } from "@timhortons/common/src/models/rev/reports";
import { IExternalMeta } from "@timhortons/common/src/repositories/rev/selfCalibration";
import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { ComparisonStoresAdminModel } from "models/profile";

const setAuditTypeRedux = (data: {
  auditType: AuditType;
}): IFluxStandardAction<{ auditType: AuditType }> => {
  return {
    type: "SET_AUDIT_TYPE",
    payload: data
  };
};

const getTestAuditRejectData = (data: { id: number }) => {
  return {
    type: REV.GET_REJECT_DATA.LOADING,
    payload: data
  };
};
const getTestAuditRejectDataSuccess = (serialNumbers: { id: number }, data: any) => {
  return {
    type: REV.GET_REJECT_DATA.SUCCESS,
    payload: { serialNumbers, data }
  };
};
const getTestAuditRejectDataError = (error: Error) => {
  return {
    type: REV.GET_REJECT_DATA.ERROR,
    payload: error
  };
};

const postCommentData = (data: PostCommentModel) => {
  return {
    type: REV.POST_COMMENT_DATA.LOADING,
    payload: data
  };
};
const postCommentDataSuccess = (data: CommentDataModel, value: PostCommentModel) => {
  return {
    type: REV.POST_COMMENT_DATA.SUCCESS,
    payload: { data, value }
  };
};
const postCommentDataError = (error: Error) => {
  return {
    type: REV.POST_COMMENT_DATA.ERROR,
    payload: error
  };
};

const resetAuthID = () => {
  return {
    type: REV.RESET_AUTH_ID
  };
};

const resetAppealAuthID = () => {
  return {
    type: REV.RESET_APPEAL_AUTH_ID
  };
};

const resetReportID = () => {
  return {
    type: REV.RESET_REPORT_ID
  };
};

const checkAuthID = (data: {
  storeId: number;
  startTime: Date;
  auditedBy: number;
  auditType: AuditType;
  isIndividualLogin: boolean;
}): IFluxStandardAction<{
  storeId: number;
  startTime: Date;
  auditedBy: number;
  auditType: AuditType;
  isIndividualLogin: boolean;
}> => {
  return {
    type: REV.CHECK_AUTH_ID.LOADING,
    payload: data
  };
};

const checkAuthIDSuccess = (
  data: StoreAuditInfoModel
): IFluxStandardAction<StoreAuditInfoModel> => {
  return {
    type: REV.CHECK_AUTH_ID.SUCCESS,
    payload: data
  };
};

const checkAuthIDError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.CHECK_AUTH_ID.ERROR,
    payload: error
  };
};
const answerYesNO = (data: {
  questionId: number;
  calibrationId: number;
  ztStatus: string;
  status: string;
  currentCategory?: number;
  subCategoryId: number;
  auditType: AuditType;
}): IFluxStandardAction<{
  questionId: number;
  ztStatus: string;
  calibrationId: number;
  status: string;
  currentCategory?: number;
  subCategoryId: number;
  auditType: AuditType;
}> => {
  return {
    type: REV.ANSWER_YES_NO_NA_DATA.LOADING,
    payload: data
  };
};

const answerYesNOSuccess = (data: {
  apiRes: YesNoDataModel;
  metaData: { subCategoryId: number; questionId: number; status: string; ztStatus: string };
}): IFluxStandardAction<{
  apiRes: YesNoDataModel;
  metaData: { subCategoryId: number; questionId: number; status: string; ztStatus: string };
}> => {
  return {
    type: REV.ANSWER_YES_NO_NA_DATA.SUCCESS,
    payload: data
  };
};
const answerYesNOError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.ANSWER_YES_NO_NA_DATA.ERROR,
    payload: error
  };
};
const getObservationData = (data: {
  calibrationId: number;
  questionId: number;
}): IFluxStandardAction<{
  calibrationId: number;
  questionId: number;
}> => {
  return {
    type: REV.GET_OBSERVATION_DATA.LOADING,
    payload: data
  };
};
const updateCompletionPercentage = () => {
  return {
    type: REV.UPDATE_COMPLETION.SUCCESS
  };
};
const getObservationDataSuccess = (
  data: ObservationDataModel
): IFluxStandardAction<ObservationDataModel> => {
  return {
    type: REV.GET_OBSERVATION_DATA.SUCCESS,
    payload: data
  };
};
const getObservationDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_OBSERVATION_DATA.ERROR,
    payload: error
  };
};
const prepareReport = (data: {
  calibrationId: number;
}): IFluxStandardAction<{
  calibrationId: number;
}> => {
  return {
    type: REV.PREPARE_REPORT.LOADING,
    payload: data
  };
};
const submitAudit = (data: {
  calibrationId: number;
}): IFluxStandardAction<{
  calibrationId: number;
}> => {
  return {
    type: REV.SUBMIT_AUDIT.LOADING,
    payload: data
  };
};

const submitAuditSuccess = (data: {
  reportId: number | null;
  calibrationId: number;
}): IFluxStandardAction<{
  reportId: number | null;
  calibrationId: number;
}> => {
  return {
    type: REV.SUBMIT_AUDIT.SUCCESS,
    payload: data
  };
};

const submitAuditError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.SUBMIT_AUDIT.ERROR,
    payload: error
  };
};

const getTippingPoints = (data: {
  questionId: number;
  auditType: AuditType;
}): IFluxStandardAction<{
  questionId: number;
  auditType: AuditType;
}> => {
  return {
    type: REV.GET_TIPPING_POINTS_DATA.LOADING,
    payload: data
  };
};

const getTippingPointsSuccess = (
  data: TippingPointsModel
): IFluxStandardAction<TippingPointsModel> => {
  return {
    type: REV.GET_TIPPING_POINTS_DATA.SUCCESS,
    payload: data
  };
};
const getTippingPointsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_TIPPING_POINTS_DATA.ERROR,
    payload: error
  };
};

const postObservationSelection = (data: {
  questionId: number;
  observationId: number;
  calibrationId: number;
}): IFluxStandardAction<{
  questionId: number;
  observationId: number;
  calibrationId: number;
}> => {
  return {
    type: REV.POST_OBSERVATION_DATA.LOADING,
    payload: data
  };
};

const postObservationSelectionSuccess = (
  data: PostObservationSuccessModel
): IFluxStandardAction<PostObservationSuccessModel> => {
  return {
    type: REV.POST_OBSERVATION_DATA.SUCCESS,
    payload: data
  };
};
const postObservationSelectionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.POST_OBSERVATION_DATA.ERROR,
    payload: error
  };
};

const getDetailedReportId = (data: {
  storeNumber: number;
  locationId: string;
}): IFluxStandardAction<{
  storeNumber: number;
  locationId: string;
}> => {
  return {
    type: REV.GET_DETAILED_REPORT_ID.LOADING,
    payload: data
  };
};

const getDetailedReportIdSuccess = (
  data: DetailedReportIdModel
): IFluxStandardAction<DetailedReportIdModel> => {
  return {
    type: REV.GET_DETAILED_REPORT_ID.SUCCESS,
    payload: data
  };
};
const getDetailedReportIdError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_DETAILED_REPORT_ID.ERROR,
    payload: error
  };
};

const getStoreAuditData = (): IFluxStandardAction => {
  return {
    type: REV.GET_STORE_AUDIT_DATA.LOADING
  };
};
const getStoreAuditDataSuccess = (
  data: StoreAuditInfoModel
): IFluxStandardAction<StoreAuditInfoModel> => {
  return {
    type: REV.GET_STORE_AUDIT_DATA.SUCCESS,
    payload: data
  };
};
const getStoreAuditDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_STORE_AUDIT_DATA.ERROR,
    payload: error
  };
};

const getSubCategoryData = (data: {
  calibrationId: number;
  categoryId: string;
  auditType: AuditType;
  storeCode: number;
}): IFluxStandardAction<{
  calibrationId: number;
  categoryId: string;
  auditType: AuditType;
  storeCode: number;
}> => {
  return {
    type: REV.GET_SUB_CATEGORY_DATA.LOADING,
    payload: data
  };
};
const getSubCategoryDataSuccess = (
  data: SubCategoryModel
): IFluxStandardAction<SubCategoryModel> => {
  return {
    type: REV.GET_SUB_CATEGORY_DATA.SUCCESS,
    payload: data
  };
};
const getSubCategoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_SUB_CATEGORY_DATA.ERROR,
    payload: error
  };
};
const getPreviousAuditData = (data: {
  empId: number;
  role: Roles[];
}): IFluxStandardAction<{
  empId: number;
  role: Roles[];
}> => {
  return {
    type: REV.GET_PREVIOUS_AUDIT_DATA.LOADING,
    payload: data
  };
};
const getPreviousAuditDataSuccess = (
  data: PreviousAuditDataModel
): IFluxStandardAction<PreviousAuditDataModel> => {
  return {
    type: REV.GET_PREVIOUS_AUDIT_DATA.SUCCESS,
    payload: data
  };
};
const getPreviousAuditDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_PREVIOUS_AUDIT_DATA.ERROR,
    payload: error
  };
};

const postSosAvgTime = (data: {
  sosTime: Array<{ orderId: "string"; timeTaken: "string" }>;
  calibrationId: number;
  subCategoryId: number;
}): IFluxStandardAction<{
  sosTime: Array<{ orderId: "string"; timeTaken: "string" }>;
  calibrationId: number;
  subCategoryId: number;
}> => {
  return {
    type: REV.POST_SOS_AVG_TIME.LOADING,
    payload: data
  };
};
const postSosAvgTimeSuccess = (
  data: SosAverageTimeModel
): IFluxStandardAction<SosAverageTimeModel> => {
  return {
    type: REV.POST_SOS_AVG_TIME.SUCCESS,
    payload: data
  };
};

const postSosAvgTimeError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.POST_SOS_AVG_TIME.ERROR,
    payload: error
  };
};
const postSosAvgReset = (): IFluxStandardAction => {
  return {
    type: REV.POST_SOS_AVG_TIME_RESET.SUCCESS
  };
};
const getSosAvgTime = (data: {
  calibrationId: number;
  subCategoryId: number;
}): IFluxStandardAction<{
  calibrationId: number;
  subCategoryId: number;
}> => {
  return {
    type: REV.GET_SOS_AVG_TIME.LOADING,
    payload: data
  };
};
const getSosAvgTimeSuccess = (
  data: SosAverageTimeModel
): IFluxStandardAction<SosAverageTimeModel> => {
  return {
    type: REV.GET_SOS_AVG_TIME.SUCCESS,
    payload: data
  };
};

const getSosAvgTimeError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_SOS_AVG_TIME.ERROR,
    payload: error
  };
};
const deleteSos = (data: {
  calibrationId: number;
  subCategoryId: number;
}): IFluxStandardAction<{ calibrationId: number; subCategoryId: number }> => {
  return {
    type: REV.DELETE_SOS.LOADING,
    payload: data
  };
};
const deleteSosSuccess = () => {
  return {
    type: REV.DELETE_SOS.SUCCESS
  };
};
const deleteSosError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.DELETE_SOS.ERROR,
    payload: error
  };
};

const deleteAudit = (data: {
  calibrationId: number;
}): IFluxStandardAction<{ calibrationId: number }> => {
  return {
    type: REV.DELETE_AUDIT.LOADING,
    payload: data
  };
};
const deleteAuditSuccess = () => {
  return {
    type: REV.DELETE_AUDIT.SUCCESS
  };
};
const deleteAuditError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.DELETE_AUDIT.ERROR,
    payload: error
  };
};
const resumeAudit = (data: {
  calibrationId: number;
  type: AuditType;
}): IFluxStandardAction<{ calibrationId: number; type: AuditType }> => {
  return {
    type: REV.RESUME_AUDIT.LOADING,
    payload: data
  };
};

const getFeedbackData = (data: PostCommentModel) => {
  return {
    type: REV.GET_COMMENT_DATA.LOADING,
    payload: data
  };
};
const getFeedbackDataSuccess = (data: CommentDataModel) => {
  return {
    type: REV.GET_COMMENT_DATA.SUCCESS,
    payload: data
  };
};
const getFeedbackDataError = (error: Error) => {
  return {
    type: REV.GET_COMMENT_DATA.ERROR,
    payload: error
  };
};
const getExternalMetaDetails = () => {
  return {
    type: REV.GET_EXTERNAL_META.LOADING
  };
};
const getExternalMetaDetailsSuccess = (data: IExternalMeta) => {
  return {
    type: REV.GET_EXTERNAL_META.SUCCESS,
    payload: data
  };
};
const getExternalMetaDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_EXTERNAL_META.ERROR,
    payload: error
  };
};
const postExternalAudit = (data: {
  formData: any;
  files: FormData;
}): IFluxStandardAction<{ formData: any; files: FormData }> => {
  return {
    type: REV.POST_EXTERNAL_AUDIT.LOADING,
    payload: data
  };
};
const postExternalAuditSuccess = (data: any) => {
  return {
    type: REV.POST_EXTERNAL_AUDIT.SUCCESS,
    payload: data
  };
};
const postExternalAuditError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.POST_EXTERNAL_AUDIT.ERROR,
    payload: error
  };
};
const updateCommentData = (data: PostCommentModel) => {
  return {
    type: REV.UPDATE_COMMENT_DATA.LOADING,
    payload: data
  };
};
const updateCommentDataSuccess = (data: CommentDataModel, value: PostCommentModel) => {
  return {
    type: REV.UPDATE_COMMENT_DATA.SUCCESS,
    payload: { data, value }
  };
};
const updateCommentDataError = (error: Error) => {
  return {
    type: REV.UPDATE_COMMENT_DATA.ERROR,
    payload: error
  };
};
const getStoreListByStoreCodeOrName = (value: GetStoreListByCodeOrNamePayload) => {
  return {
    type: REV.GET_STORELIST_BY_CODE_OR_NAME.LOADING,
    payload: value
  };
};
const getStoreListByStoreCodeOrNameSuccess = (
  data: ComparisonStoresAdminModel
): IFluxStandardAction<ComparisonStoresAdminModel> => {
  return {
    type: REV.GET_STORELIST_BY_CODE_OR_NAME.SUCCESS,
    payload: data
  };
};
const getStoreListByStoreCodeOrNameError = (error: Error) => {
  return {
    type: REV.GET_STORELIST_BY_CODE_OR_NAME.ERROR,
    payload: error
  };
};
const getStoreListEnd = () => {
  return {
    type: REV.GET_STORELIST_BY_CODE_OR_NAME_LISTEND
  };
};
const resetStoreListState = () => {
  return {
    type: REV.RESET_STORE_LIST_BYSTORE_CODENAME
  };
};
export const SelfCalibrationActions = {
  getTestAuditRejectDataSuccess,
  getTestAuditRejectDataError,
  checkAuthID,
  checkAuthIDSuccess,
  checkAuthIDError,
  answerYesNO,
  answerYesNOSuccess,
  answerYesNOError,
  getStoreAuditData,
  getStoreAuditDataSuccess,
  getStoreAuditDataError,
  getSubCategoryData,
  getSubCategoryDataError,
  getSubCategoryDataSuccess,
  getDetailedReportId,
  getDetailedReportIdSuccess,
  getDetailedReportIdError,
  getObservationData,
  getObservationDataSuccess,
  getObservationDataError,
  postCommentData,
  postCommentDataSuccess,
  postCommentDataError,
  postObservationSelection,
  postObservationSelectionSuccess,
  postObservationSelectionError,
  prepareReport,
  submitAudit,
  submitAuditError,
  getTippingPoints,
  getTippingPointsError,
  getTippingPointsSuccess,
  resetAuthID,
  resetReportID,
  getPreviousAuditData,
  getPreviousAuditDataSuccess,
  getPreviousAuditDataError,
  postSosAvgTimeError,
  postSosAvgTime,
  postSosAvgTimeSuccess,
  getSosAvgTime,
  getSosAvgTimeSuccess,
  getSosAvgTimeError,
  deleteAudit,
  deleteAuditSuccess,
  updateCompletionPercentage,
  deleteAuditError,
  getTestAuditRejectData,
  postSosAvgReset,
  resumeAudit,
  getFeedbackData,
  getFeedbackDataSuccess,
  getFeedbackDataError,
  submitAuditSuccess,
  resetAppealAuthID,
  deleteSos,
  deleteSosSuccess,
  deleteSosError,
  getExternalMetaDetails,
  getExternalMetaDetailsSuccess,
  getExternalMetaDetailsError,
  postExternalAudit,
  postExternalAuditSuccess,
  postExternalAuditError,
  updateCommentData,
  updateCommentDataSuccess,
  updateCommentDataError,
  setAuditTypeRedux,
  getStoreListByStoreCodeOrName,
  getStoreListByStoreCodeOrNameSuccess,
  getStoreListByStoreCodeOrNameError,
  getStoreListEnd,
  resetStoreListState
};
