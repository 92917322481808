import React, { ReactElement } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Image } from "@timhortons/common/src/components/atoms/image";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { useTranslation } from "react-i18next";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { pageNotFoundImg, redLogo } from "@timhortons/common/src/assets/images/imageAssets";

interface IProps {
  ParentCallBack?: () => void;
}

export default function PageNotFound(props: IProps): ReactElement {
  const { t } = useTranslation("errorsScreen");
  const { ParentCallBack } = props;
  return (
    <>
      <ScrollView style={styles.container}>
        <View style={styles.imageElement}>
          <Image
            testId={testIds.iam.pageNotFound}
            source={pageNotFoundImg}
            imageStyle={styles.pageNotFoundImage}
          />
        </View>
        <View style={styles.titleElement}>
          <Text
            fontWeight={FontWeight.SemiBold}
            textSize={TextSize.ExtraDoubeLarge}
            testId="page_not_found"
            textStyle={styles.headerText}
          >
            {t("pageNotFound")}
          </Text>
        </View>
        <View style={styles.contentElement}>
          <Text
            fontWeight={FontWeight.Regular}
            textSize={TextSize.ExtraRegular}
            testId="page_not_found"
            textStyle={styles.subText}
          >
            {t("pagenotfoundContent")}
          </Text>
        </View>
        {ParentCallBack && (
          <View style={styles.buttonElement}>
            <Button
              onPress={() => ParentCallBack()}
              type={ButtonType.Primary}
              buttonStyles={styles.backButton}
            >
              <Text fontWeight={FontWeight.Bold} textSize={TextSize.Regular} testId="id">
                {t("BackToHome")}
              </Text>
            </Button>
          </View>
        )}
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1
    paddingVertical: 24
  },
  logoElement: {
    alignItems: "center",
    marginBottom: 43
  },
  timHortonLogoImage: {
    height: 70,
    width: 200
  },
  imageElement: {
    flex: 1,
    width: "100%",
    height: "100%",
    alignItems: "center",
    marginBottom: 30
  },
  pageNotFoundImage: {
    width: 600,
    height: 400
  },
  titleElement: {
    alignItems: "center",
    marginBottom: 24
  },
  contentElement: {
    justifyContent: "center",
    alignSelf: "center",
    marginBottom: 30,
    maxWidth: PlatformUtils.isWeb() ? 520 : 330
  },
  headerText: {
    color: colorPallete.blue3
  },
  subText: {
    color: colorPallete.blue4,
    textAlign: "center",
    alignSelf: "center"
  },
  buttonElement: {
    alignItems: "center",
    marginBottom: 24
  },
  backButton: {
    paddingHorizontal: 36,
    paddingVertical: 12,
    borderRadius: 8
  }
});
