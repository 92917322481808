import React, { ReactElement, useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  LayoutChangeEvent,
  StyleProp,
  ViewStyle
} from "react-native";
import { placeHolders } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { TextArea } from "@timhortons/common/src/components/atoms/textArea";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Text, FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { UploadBox } from "@timhortons/web/src/components/molecules/uploadfile/uploadFile";
import { CommentDataModel, FileModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import UploadBoxComponent, {
  FileTypes
} from "@timhortons/tablet/src/components/molecules/fileUpload/uploadBoxComponent";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import ImgMapper from "@timhortons/common/src/components/organisms/imgMapper";

interface IProps {
  commentData?: CommentDataModel;
  translate?: any;
  subCategoryId?: number;
  saveClickCall?: Function;
  initialCall?: Function;
  editIcon?: boolean;
  isComment?: boolean;
  acceptFileType: string;
  maxFiles?: number;
  commentConatinerStyle?: StyleProp<ViewStyle>;
  editCommentContainerStyle?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  updateCall?: Function;
  showSaveCancelButton?: boolean;
  commentBoxEmpty?: boolean;
  operationalHours?: any;
  isShowError?: boolean;
}

export default function CommentBox(props: IProps): ReactElement {
  const {
    commentData,
    translate,
    subCategoryId,
    saveClickCall,
    initialCall = null,
    editIcon = true,
    isComment = true,
    acceptFileType,
    maxFiles = 5,
    commentConatinerStyle,
    editCommentContainerStyle,
    iconStyle,
    updateCall = () => {
      // props
    },
    showSaveCancelButton = true,
    commentBoxEmpty,
    operationalHours,
    isShowError = true
    // disableLogsSaveButton = false,
    // isAssignedPersonThere,
    // showLogsDiffCancelSave = false,
    // assignedPerson
  } = props;

  useEffect(() => {
    let data;

    data = commentData;

    setCommentFormData(commentData);

    if (data === undefined || data === null) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, [JSON.stringify(commentData)]);

  const [commentFormData, setCommentFormData] = useState<CommentDataModel>(null);
  const [initialFiles, setInitialFiles] = useState(null);
  const [commentBoxWidth, setCommentBoxWidth] = useState<number>(0);
  const [edit, setEdit] = useState(false);
  const [cancelFiles, setCancelFiles] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleCommentTextChange = (event: string): void => {
    setCommentFormData({ ...commentFormData, comment: event });
  };
  useEffect(() => {
    setCancelFiles(false);
    const data = commentData;
    if (data === undefined || data === null) {
      setCommentFormData({
        ...commentFormData,
        comment: "",
        files: []
      });
      setCancelFiles(true);
    } else {
      setCommentFormData(commentData);
      setEdit(true);
    }
  }, [commentBoxEmpty]);

  const handleFileChange = (files: FileModel[]): void => {
    setCommentFormData({ ...commentFormData, files: files });
  };

  const handleEditIconClick = (): void => {
    setEdit(!edit);
    setInitialFiles(commentFormData && commentFormData.files);
  };

  useEffect(() => {
    if (initialCall) initialCall(subCategoryId);
  }, []);

  useEffect(() => {
    if (commentFormData?.files?.length > maxFiles) {
      setShowErrorModal(true);
    }
  }, [commentFormData?.files]);
  const handleSaveButtonClick = (): void => {
    if (JSON.stringify(commentData) !== JSON.stringify(commentFormData)) {
      if (commentFormData.files) {
        if (commentFormData.files.length > maxFiles) {
          setShowErrorModal(true);
          return;
        } else {
          commentData !== null && commentData !== undefined
            ? updateCall(commentFormData, initialFiles, subCategoryId)
            : saveClickCall(commentFormData, initialFiles, subCategoryId);
          handleEditIconClick();
          return;
        }
      } else {
        commentData !== null && commentData !== undefined
          ? updateCall(commentFormData, initialFiles, subCategoryId)
          : saveClickCall(commentFormData, initialFiles, subCategoryId);
        handleEditIconClick();
      }
    }
  };

  const handleCancelButtonClick = (): void => {
    setCancelFiles(false);
    const data = commentData;
    if (data === undefined || data === null) {
      setCommentFormData({
        ...commentFormData,
        comment: "",
        files: []
      });
      setCancelFiles(true);
    } else {
      setCommentFormData(commentData);
      setEdit(!edit);
    }
  };

  const onPageLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;
    setCommentBoxWidth(width);
  };

  const disableSaveButton = (): boolean => {
    const commentValid =
      commentFormData &&
      commentFormData.comment &&
      commentFormData.comment.length >= 10 &&
      commentFormData.comment.length < 250;
    const fileValid = commentFormData && commentFormData.files && commentFormData.files.length > 0;
    const validation =
      commentFormData &&
      commentFormData.comment &&
      commentFormData.comment.length > 0 &&
      commentFormData &&
      commentFormData.files &&
      commentFormData.files.length > 0
        ? fileValid && commentValid
        : fileValid || commentValid;

    return !validation;
  };

  return (
    <View
      style={
        edit
          ? [styles.commentContainer, commentConatinerStyle]
          : [styles.editCommentContainer, editCommentContainerStyle]
      }
    >
      <View style={styles.commentDataContainer}>
        <View style={styles.commentDataHeader}>
          {isComment ? (
            <>
              <Icon name={IconNames.askTellFilled} customStyle={styles.commentHeaderIconStyle} />
              <Text
                fontWeight={FontWeight.SemiBold}
                textSize={TextSize.Regular}
                testId="a"
                textStyle={styles.commentHeaderTextStyle}
              >
                {translate("Add Comments")}
              </Text>
            </>
          ) : (
            <Text
              fontWeight={FontWeight.SemiBold}
              textSize={TextSize.Regular}
              testId="a"
              textStyle={styles.nonConformanceStyle}
            >
              {translate("nonConformanceDescription")}
            </Text>
          )}
        </View>
        {!edit ? (
          <>
            <View style={styles.commentBodyContainer}>
              <TextArea
                label="comment"
                value={
                  (commentFormData &&
                    ((commentFormData.comment === "undefined" && "") || commentFormData.comment)) ||
                  ""
                }
                placeholder={
                  (isComment && translate(placeHolders.accordianComment)) ||
                  translate("conformancePlaceholder")
                }
                onMessageChange={handleCommentTextChange}
                textAreaStyle={styles.textAreaContainer}
                inputContainerStyle={styles.inputStyle}
                wordCountLimit={250}
                labelStyle={styles.labelStyle}
                autoFocus={false}
                isShowError={isShowError}
              />
            </View>
            {PlatformUtils.isWeb() ? (
              <View style={styles.imageContainer}>
                <View style={styles.uploadBoxContainer}>
                  <UploadBox
                    header={translate("attachment")}
                    multipleUpload={true}
                    // acceptFileType={"image/jpeg, image/png, .pdf, .doc, .docx"}
                    acceptFileType={acceptFileType}
                    headerStyle={styles.imageHeaderStyle}
                    handleFileChange={handleFileChange}
                    cancelFiles={cancelFiles}
                    setCancelFiles={setCancelFiles}
                    webOnDropAccepted={() => {
                      // This is intentional
                    }}
                    initialFiles={initialFiles}
                  >
                    {showSaveCancelButton && (
                      <View style={styles.commentButtonContainer}>
                        <Button
                          type={ButtonType.Primary}
                          title={translate("cancel")}
                          buttonStyles={styles.cancelButtonStyle}
                          textStyles={styles.cancelButtonTextStyle}
                          onPress={handleCancelButtonClick}
                        />
                        <Button
                          type={ButtonType.Primary}
                          title={translate("save")}
                          buttonStyles={styles.saveButtonStyle}
                          onPress={handleSaveButtonClick}
                          disabled={disableSaveButton()}
                        />
                      </View>
                    )}
                  </UploadBox>
                </View>
              </View>
            ) : (
              <UploadBoxComponent
                header={translate("attachment")}
                headerStyle={styles.imageHeaderStyle}
                handleFileChange={handleFileChange}
                cancelFiles={cancelFiles}
                setCancelFiles={setCancelFiles}
                initialFiles={initialFiles}
                iconStyle={iconStyle}
                fileType={
                  acceptFileType === "image/jpeg, image/png, image/jpg" ||
                  acceptFileType === "image/png" ||
                  acceptFileType === "image/jpg"
                    ? FileTypes.images
                    : FileTypes.all
                }
              >
                {showSaveCancelButton && (
                  <View style={styles.commentButtonContainer}>
                    <Button
                      type={ButtonType.Primary}
                      title={translate("cancel")}
                      buttonStyles={styles.cancelButtonStyle}
                      textStyles={styles.cancelButtonTextStyle}
                      onPress={handleCancelButtonClick}
                    />
                    <Button
                      type={ButtonType.Primary}
                      title={translate("save")}
                      buttonStyles={styles.saveButtonStyle}
                      onPress={handleSaveButtonClick}
                      disabled={disableSaveButton()}
                    />
                  </View>
                )}
              </UploadBoxComponent>
            )}
          </>
        ) : (
          <View>
            <Text
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraRegular}
              testId="a"
              textStyle={styles.textStyle}
            >
              {commentFormData &&
                ((commentFormData.comment === null && "") || commentFormData.comment)}
            </Text>
            <View style={styles.imageContainer} onLayout={onPageLayout}>
              <ScrollView style={{ maxWidth: commentBoxWidth - 50 }} horizontal={true}>
                {commentFormData?.files?.length > 0 && (
                  <ImgMapper items={commentFormData?.files.map((i: any) => i.signedUrl)} />
                )}
              </ScrollView>
            </View>
            {editIcon && (
              <View
                pointerEvents={
                  operationalHours
                    ? (!operationalHours?.isEnable && operationalHours?.isShiftComplete) ||
                      operationalHours === undefined
                      ? "none"
                      : null
                    : null
                }
              >
                <Button
                  type={ButtonType.Primary}
                  iconName={IconNames.edit}
                  title={translate("editHere")}
                  buttonStyles={styles.editHereContainerStyle}
                  iconStyles={styles.editHereIconStyle}
                  textStyles={styles.editHereTextStyle}
                  onPress={handleEditIconClick}
                />
              </View>
            )}
          </View>
        )}
      </View>
      {showErrorModal && (
        <AlertModal
          setModalVisible={() => setShowErrorModal(!showErrorModal)}
          errorMsg={`Cannot upload more than ${maxFiles} files`}
        />
      )}
    </View>
  );
}

export const styles = StyleSheet.create({
  editCommentContainer: {
    padding: -10,
    backgroundColor: colorPallete.white2
  },
  commentContainer: {
    padding: -10,
    backgroundColor: colorPallete.white6
  },
  commentDataContainer: {
    // paddingStart: 54,
    ...I18nService.select({
      rtl: { paddingEnd: 54 },
      ltr: { paddingStart: 54 }
    }),
    // paddingEnd: 30,
    ...I18nService.select({
      rtl: { paddingStart: 30 },
      ltr: { paddingEnd: 30 }
    }),
    paddingVertical: 24
  },
  commentDataHeader: {
    flexDirection: "row"
  },
  commentHeaderIconStyle: {
    color: colorPallete.red2
  },
  commentHeaderTextStyle: {
    color: colorPallete.grey1,
    ...I18nService.select({
      rtl: { marginStart: 12 },
      ltr: { marginStart: 12 }
    })
    // marginStart: 12
  },
  nonConformanceStyle: { color: colorPallete.grey1 },
  textAreaContainer: {
    height: 70,
    borderWidth: 0
  },
  inputStyle: {
    flex: 1,
    height: 50,
    borderWidth: 0,
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    })
    // marginEnd: 24,
    // outlineStyle: "none"
  },
  labelStyle: {
    display: "none"
  },
  commentBodyContainer: {
    marginTop: 20,
    flex: 1
  },
  imageHeaderStyle: {
    color: colorPallete.grey2
  },
  imageContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "flex-end"
  },
  uploadBoxContainer: {
    flex: 1
  },
  commentButtonContainer: {
    alignSelf: "flex-end",
    flexDirection: "row"
  },
  saveButtonStyle: {
    paddingHorizontal: 36,
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 14 },
      ltr: { marginStart: 14 }
    }),
    // marginStart: 14,
    borderRadius: 8
  },
  cancelButtonStyle: {
    paddingHorizontal: 36,
    paddingVertical: 12,
    backgroundColor: colorPallete.white3,
    borderWidth: 1,
    borderColor: colorPallete.red3,
    borderRadius: 8
  },
  cancelButtonTextStyle: {
    color: colorPallete.red1
  },
  editHereContainerStyle: {
    marginTop: 32,
    alignSelf: "flex-start",
    backgroundColor: colorPallete.white6
  },
  editHereIconStyle: {
    color: colorPallete.red1
  },
  editHereTextStyle: {
    color: colorPallete.grey2,
    ...I18nService.select({
      rtl: { marginStart: 14 },
      ltr: { marginStart: 14 }
    })
    // marginStart: 14
  },
  attachments: {
    borderRadius: 8,
    margin: 0,
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    })
    // marginEnd: 24
  },

  previewIconStyle: {
    marginTop: -110,
    ...I18nService.select({
      rtl: { marginStart: 95 },
      ltr: { marginStart: 95 }
    }),
    // marginStart: 80,
    color: colorPallete.white1
  },
  textStyle: {
    color: colorPallete.black3,
    marginVertical: 20
  },
  imgElement: {
    borderRadius: 8,
    height: 120,
    width: 120
  }
});
