import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { lmsReportsEndPoints } from "@timhortons/common/src/constants/endPoints";
import { getEmpDetailedLmsReportFormatter } from "@timhortons/common/src/repositories/lmsReports/formatters/lmsReportsFormatter";
import {
  LMSEmpDetailedReport,
  ReportsResponseDataModel,
  ReportsTableDetailsModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";
import { LmsReportsKeys } from "@timhortons/common/src/constants/apiKeys";

class LmsReportsRepository {
  getReportsTableDetails = async (param: {
    pageNumber: number;
    pageSize: number;
    roleId: string;
    search: string;
    idList?: [string];
  }) => {
    const { pageNumber, pageSize, roleId, idList = [] } = param;
    let keyword = param.search === "" ? null : param.search.toLowerCase();
    let object: any = {
      pageNo: pageNumber,
      pageSize: pageSize,
      roleIds: idList?.length > 0 ? idList.join(",") : roleId
    };
    if (param.search !== null) {
      object = {
        ...object,
        keyword: keyword
      };
    }
    let tableDetails = await ApiClient.get(
      lmsReportsEndPoints.getReportsTableDetails(),
      {
        ...object
      },
      LmsReportsKeys.USER_ROLES
    );
    return deserialize(ReportsTableDetailsModel, tableDetails);
  };
  getEmpDetailedLmsReport = async (param: { empId: string }): Promise<LMSEmpDetailedReport> => {
    const { empId } = param;
    let response: ReportsResponseDataModel = await ApiClient.get(
      lmsReportsEndPoints.getDetailedReport(),
      { employeeId: empId },
      LmsReportsKeys.USER_ROLES
    );
    return getEmpDetailedLmsReportFormatter(response);
  };
}

const lmsReportsRepository = new LmsReportsRepository();
export { lmsReportsRepository as LmsReportsRepository };
