import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IActionPlansLoading,
  IActionPlansError,
  IActionPlansState
} from "@timhortons/common/src/redux/rev/reducers/actionPlans";
import {
  ActionPlanHeaderModel,
  ActionPlanTableModel,
  ActionPlanCategoryDataModel,
  ActionPlanAccordionDataModel,
  FilterDataModel,
  SelectedFilterDataModel
} from "@timhortons/common/src/models/rev/actionPlan";
import { AcknowledgementModel } from "@timhortons/common/src/models/rev/reports";

const actionPlanSelect = (state: IState): IActionPlansState => state.rev.actionPlan;

const actionPlanLoading: Selector<IState, IActionPlansLoading> = createSelector(
  actionPlanSelect,
  (data) => data.loading
);

const actionPlanError: Selector<IState, IActionPlansError> = createSelector(
  actionPlanSelect,
  (data) => data.error
);

const getActionPlans: Selector<IState, ActionPlanTableModel> = createSelector(
  actionPlanSelect,
  (data) => data.actionPlansList
);
const getActionPlanAccordionData: Selector<IState, ActionPlanAccordionDataModel[]> = createSelector(
  actionPlanSelect,
  (data) => data.actionPlanAccordianData
);
const getAppealAcoordianData: Selector<IState, ActionPlanAccordionDataModel[]> = createSelector(
  actionPlanSelect,
  (data) => data.appealAccordionBodyData
);

const getCategoryStatusData: Selector<IState, ActionPlanCategoryDataModel[]> = createSelector(
  actionPlanSelect,
  (data) => data.categoryTable
);
const getActionPlanDetails: Selector<IState, ActionPlanHeaderModel> = createSelector(
  actionPlanSelect,
  (data) => data.actionPlanDetailsData
);
const getFilterData: Selector<IState, FilterDataModel> = createSelector(
  actionPlanSelect,
  (data) => data.filterData
);
const getSelectedFilterData: Selector<IState, SelectedFilterDataModel> = createSelector(
  actionPlanSelect,
  (data) => data.selectedFilterData
);
const getOwnerData: Selector<IState, AcknowledgementModel> = createSelector(
  actionPlanSelect,
  (data) => data.ownerData
);
const postActionStepsResponse: Selector<IState, any> = createSelector(
  actionPlanSelect,
  (data) => data.postActionStepsData
);
export const ActionPlanSelectors = {
  actionPlanLoading,
  actionPlanError,
  getActionPlans,
  getActionPlanAccordionData,
  getCategoryStatusData,
  getActionPlanDetails,
  getFilterData,
  getSelectedFilterData,
  getOwnerData,
  getAppealAcoordianData,
  postActionStepsResponse
};
