import {
  LMSEmpDetailedReport,
  LMSReportBuilderResponseModel,
  LMSReportEmployeeDetailModel,
  LMSReportPracticalData,
  LMSReportsGraphicalDataModel,
  LMSReportTrainingTablesModel,
  ReportsResponseDataModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";

export const getEmpDetailedLmsReportFormatter = async (
  data: ReportsResponseDataModel
): Promise<LMSEmpDetailedReport> => {
  console.log(data.reportUserResponseDto, "yo");
  let recvEmpData = data && data.reportUserResponseDto;
  let recvPieData = data && data.continueLearningPercentageDto;
  let recvTimbitData = data && data.timbitCollectionDto;
  let recvCoreTrainingData = data && data.coreTrainingResponseDto;
  let recvSpecialTrainingData = data && data.specialTrainingResponseDto;
  let recvPracticalTrainingData = data && data.ptsPracticalTopicCheckistResponseDto;
  const empInfoData: LMSReportEmployeeDetailModel = {
    name: `${recvEmpData.firstName} ${recvEmpData.lastName}`,
    storeName: recvEmpData.storeName,
    role: recvEmpData.role,
    profileImg: recvEmpData.profileLink,
    trainingCompletion: recvEmpData.trainingCompletion,
    countryLabel: recvEmpData.countryLabel,
    details: [
      { id: "dummyData-details-0", label: "Emp ID", value: recvEmpData.employeeId },
      { id: "dummyData-details-1", label: "Country", value: recvEmpData.countryLabel },
      { id: "dummyData-details-2", label: "Area Managers", value: `${recvEmpData.areaManagers}` },
      { id: "dummyData-details-3", label: "Restaurants", value: `${recvEmpData.ofStores}` },
      {
        id: "dummyData-details-4",
        label: "Cluster Head of Ops",
        value: `${recvEmpData.clusterHeadOfOps}`
      },
      {
        id: "dummyData-details-5",
        label: "Country Managers",
        value: `${recvEmpData.countryManagers}`
      },
      { id: "dummyData-details-6", label: "Sr. Ops Manager", value: `${recvEmpData.srOpsManager}` },
      { id: "dummyData-details-7", label: "Ops Manager", value: `${recvEmpData.opsManager}` },
      { id: "dummyData-details-8", label: "Area Trainers", value: `${recvEmpData.areaTrainers}` },
      { id: "dummyData-details-9", label: "Employees", value: `${recvEmpData.employees}` },
      {
        id: "dummyData-details-10",
        label: "GCC Average Score",
        value: `${recvEmpData.gccAverageScore}%`
      },

      {
        id: "dummyData-details-11",
        label: "Your Average Score",
        value: `${recvEmpData.averageScore}%`
      }
    ]
  };

  const graphicalInfoData: LMSReportsGraphicalDataModel = {
    pieChartData: [
      recvPieData.completedPercent,
      recvPieData.pendingPercent,
      recvPieData.inProgressPercent
    ],
    timbitData: [
      { id: "timbitData-0", label: "Birthday Cake", value: `${recvTimbitData.birthdayCake}` },
      { id: "timbitData-1", label: "Chocolate Glazed", value: `${recvTimbitData.chocolateGlazed}` },
      { id: "timbitData-2", label: "Honey Dip", value: `${recvTimbitData.honeyDip}` }
    ]
  };
  //status-->done,failed,notStarted
  //theoretical-->done, notStarted
  //practical-->done, notStarted, null
  //completedOn--> text, null
  //certifications-->done, expired, failed, null
  //timibits-->text, null
  const trainingTableData: LMSReportTrainingTablesModel = {
    core: [...recvCoreTrainingData],
    special: [...recvSpecialTrainingData]
  };

  let practicalTrainingData: LMSReportPracticalData[] = [];
  recvPracticalTrainingData &&
    recvPracticalTrainingData.map((el: any) => {
      let datum = {
        moduleName: el.name,
        subText: "",
        subModules: el.ptsPracticalTopicCheckistResponseDtos.map((item: any) => ({
          topicTitle: item.topic,
          incorrectDetails: {
            points: item.checklists.map((data: any) => data.checklist),
            attatchments: item.images.map((data: any) => data.imageUrl)
          }
        }))
      };
      practicalTrainingData.push(datum);
    });
  let tempObj = {
    empInfoData,
    graphicalInfoData,
    trainingTableData,
    practicalTrainingData
  };
  return tempObj;
};
export const reportBuilderFormatter = async (
  metaData: any,
  response: any
): Promise<LMSReportBuilderResponseModel> => {
  let levelOfReport: string = "";
  let reportType: string = "";
  if (metaData.object.levelOfReport === "COUNTRY") {
    levelOfReport = "Country";
  } else if (metaData.object.levelOfReport === "COUNTRY_MANAGER") {
    levelOfReport = "Country Manager";
  } else if (metaData.object.levelOfReport === "AREA_MANAGER") {
    levelOfReport = "Area Manager";
  } else if (metaData.object.levelOfReport === "CHANNEL") {
    levelOfReport = "Channels";
  } else if (metaData.object.levelOfReport === "OPS_MANAGER") {
    levelOfReport = "Operational Manager";
  } else if (metaData.object.levelOfReport === "CLUSTER_HEAD_OF_OPS") {
    levelOfReport = "Cluster Head of Ops";
  } else if (metaData.object.levelOfReport === "LP_AUDITOR") {
    levelOfReport = "LP Auditor";
  } else {
    levelOfReport = "Stores";
  }
  if (metaData.reportType === "DETAILED") {
    reportType = "Detailed";
  } else {
    reportType = "Highest to Lowest";
  }
  let data: LMSReportBuilderResponseModel = {
    metaData: {
      startDate: metaData.object.startDate,
      endDate: metaData.object.endDate,
      reportType: reportType,
      levelOfReport: levelOfReport,
      countryLabel: response?.content?.length > 0 ? response?.content[0]?.countryLabel : null,
      dataType: metaData.object.selectedDataType,
      dependentDataType: metaData.object.selectedDependentDataType
    },
    apiResponse: {
      content: response?.data,
      totalElements: response?.totalElements
    }
  };

  return data;
};

export const lmsBuilderFormatter = async (
  metaData: any,
  response: any
): Promise<LMSReportBuilderResponseModel> => {
  let reportTypeName: string = "";
  const { object, reportType } = metaData;
  const { startDate, endDate, selectedDataType, selectedDependentDataType, levelOfReport } = object;
  const lvlReportName: Record<any, string> = {
    COUNTRY: "Country",
    COUNTRY_MANAGER: "Country Manager",
    AREA_MANAGER: "Area Manager",
    CHANNEL: "Channels",
    OPS_MANAGER: "Operational Manager",
    CLUSTER_HEAD_OF_OPS: "Cluster Head of Ops",
    LP_AUDITOR: "LP Auditor"
  };

  if (reportType === "DETAILED") {
    reportTypeName = "Detailed";
  } else {
    reportTypeName = "Highest to Lowest";
  }

  let data: LMSReportBuilderResponseModel = {
    metaData: {
      startDate: startDate,
      endDate: endDate,
      reportType: reportTypeName,
      levelOfReport: lvlReportName[levelOfReport] ?? "Stores",
      countryLabel: response?.content?.length > 0 ? response?.content[0]?.countryLabel : null,
      dataType: selectedDataType || metaData?.dataType,
      dependentDataType: selectedDependentDataType || metaData?.moduleType
    },
    apiResponse: {
      content: response?.content,
      totalElements: response?.totalElements
    }
  };
  return data;
};
