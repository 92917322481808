import { intersection } from "lodash";
import PlatformUtils from "../../../common/src/utils/platformUtils";
import { useRoute } from "@react-navigation/native";

export function isArrayWithLength(arr: Array<any>) {
  return Array.isArray(arr) && arr.length;
}

export const getAllowedRoutes = (routes: Array<any>) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return;
  }
  return routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, user.roles).length;
  });
};

export function useQuery() {
  let params: any;
  if (PlatformUtils.isWeb()) {
    const urlSearchParams = new URLSearchParams(location.search);
    params = Object.fromEntries(urlSearchParams.entries());
  } else {
    params = useRoute().params;
  }
  return params;
}

export function getComponent() {
  if (PlatformUtils.isMobile()) {
    return useRoute().name;
  } else {
    return (
      PlatformUtils.isWeb() && location.pathname.substr(location.pathname.lastIndexOf("/") + 1)
    );
  }
}
