import { Roles } from "@timhortons/common/src/constants/roles";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import {
  ObservationDataModel,
  ObservationDataPointsModel,
  PostObservationSuccessModel,
  StoreAuditInfoModel,
  SubCategoryInfoModel,
  YesNoDataModel,
  TippingPointsDataModel,
  ResumeAuditInfoModel,
  PostCommentModel,
  CommentDataModel,
  CategoryDataModel,
  GetStoreListByCodeOrNamePayload
} from "@timhortons/common/src/models/rev/selfCalibration";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { selfCalibrationEndPoints } from "@timhortons/common/src/constants/endPoints";
import { isEmpty } from "lodash";
import { Logger } from "@timhortons/common/src/services/logger";
import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { REVKeys } from "@timhortons/common/src/constants/apiKeys";
import { IApiClientOptions } from "@timhortons/common/src/network/interfaces";
import {
  ComparisonStoresAdminModel,
  ComparisonStoresAdminModelList
} from "@timhortons/common/src/models/profile";

interface Timer {
  id: number;
  label: string;
  name: string;
}

export interface IExternalMeta {
  timerSubCategories: Timer[];
  kpiCategories: Timer[];
}

export const validateAuthModel = async (
  isIndividualLogin: boolean,
  auditedBy: number,
  storeCode: number,
  headers: IApiClientOptions
) => {
  let validateStore;
  let params = {
    storeCode: storeCode,
    employeeId: auditedBy
  };
  if (!isIndividualLogin) {
    validateStore = await ApiClient.get(
      selfCalibrationEndPoints.storeAccessValidation(),
      { ...params, managerAccess: false },
      headers
    );
  } else {
    validateStore = await ApiClient.get(
      selfCalibrationEndPoints.individualAccessValidation(),
      params,
      headers
    );
  }
  return validateStore;
};

class SelfCalibrationRepository {
  private keysBasedOnType = {
    [AuditType.TEST]: REVKeys.TEST_AUDIT,
    [AuditType.STANDARD]: REVKeys.STANDARD_AUDIT,
    [AuditType.EXTERNAL]: REVKeys.STANDARD_AUDIT
  };
  checkAuthID = async (param: {
    storeId: number;
    startTime: Date;
    auditedBy: number;
    auditType: AuditType;
    isIndividualLogin: boolean;
  }) => {
    let paramFinal = {
      storeCode: param.storeId,
      startTime: param.startTime,
      auditedBy: param.auditedBy,
      auditType: param.auditType
    };
    let data;
    let validateStore = await validateAuthModel(
      param.isIndividualLogin,
      param.auditedBy,
      param.storeId,
      this.keysBasedOnType[param.auditType]
    );
    if (validateStore) {
      if (param.auditType === AuditType.STANDARD) {
        data = await ApiClient.post(
          selfCalibrationEndPoints.createStandardAudit(),
          paramFinal,
          null,
          REVKeys.STANDARD_AUDIT
        );
      } else if (param.auditType === AuditType.TEST) {
        data = await ApiClient.post(
          selfCalibrationEndPoints.createTestAudit(),
          paramFinal,
          null,
          REVKeys.TEST_AUDIT
        );
      }
    }
    // let data = await ApiClient.post(selfCalibrationEndPoints.audit(), paramFinal);
    return deserialize(StoreAuditInfoModel, data);
  };

  fetchCategoryData = async (param: { selfCalibrationId: number; auditType: AuditType }) => {
    let data = await ApiClient.get(
      selfCalibrationEndPoints.fetchCategory(param.selfCalibrationId),
      null,
      this.keysBasedOnType[param.auditType]
    );
    return deserialize(CategoryDataModel, data);
  };

  postAnswerYesNoNa = async (param: {
    questionId: number;
    calibrationId: number;
    ztStatus: string;
    status: string;
    auditType: AuditType;
  }) => {
    let data = await ApiClient.post(
      selfCalibrationEndPoints.postAnswer(),
      param,
      null,
      this.keysBasedOnType[param.auditType]
    );
    return deserialize(YesNoDataModel, data);
  };

  fetchTippingPointsData = async (param: { questionId: number; auditType: AuditType }) => {
    let data = await ApiClient.get(
      selfCalibrationEndPoints.fetchTippingPoints(param.questionId),
      null,
      this.keysBasedOnType[param.auditType]
    );
    let tippingPointsData: TippingPointsDataModel[] = [];
    data.map((item: TippingPointsDataModel) => {
      let deserializeVar: TippingPointsDataModel = deserialize(TippingPointsDataModel, item);
      tippingPointsData.push(deserializeVar);
    });
    return {
      tippingPoints: tippingPointsData
    };
  };
  fetchObservationData = async (
    param: { calibrationId: number; questionId: number },
    auditType: AuditType
  ) => {
    let { calibrationId, questionId } = param;
    let data = await ApiClient.get(
      selfCalibrationEndPoints.observationData(calibrationId, questionId),
      param,
      this.keysBasedOnType[auditType]
    );
    let finalData: ObservationDataModel = { questionId: 0, rejectedDataPoints: [] };
    let rejectedData: ObservationDataPointsModel[] = [];
    if (data.length > 0) {
      data.map((item: ObservationDataPointsModel) => {
        let deserializevar: ObservationDataPointsModel = deserialize(
          ObservationDataPointsModel,
          item
        );
        finalData.rejectedDataPoints.push(deserializevar);
      });

      finalData.questionId = finalData.rejectedDataPoints[0].id;
    }
    return finalData;
  };

  postObservationSelectionData = async (
    param: {
      questionId: number;
      observationId: number;
      calibrationId: number;
    },
    auditType: AuditType
  ) => {
    let data = await ApiClient.post(
      selfCalibrationEndPoints.postObservationSelection(),
      param,
      null,
      this.keysBasedOnType[auditType]
    );
    return deserialize(PostObservationSuccessModel, data);
  };

  fetchSubCategoryData = async (param: {
    calibrationId: number;
    categoryId: string;
    auditType: AuditType;
    storeCode: number;
  }) => {
    const { calibrationId, categoryId, storeCode } = param;
    let data = await ApiClient.get(
      selfCalibrationEndPoints.subCategory(storeCode, calibrationId, Number(categoryId)),
      null,
      this.keysBasedOnType[param.auditType]
    );
    let subCategoryData: SubCategoryInfoModel[] = [];
    try {
      data.map((item: SubCategoryInfoModel) => {
        let deserializevar: SubCategoryInfoModel = deserialize(SubCategoryInfoModel, item);

        // deserializevar.questionList.map((element,index)=>(
        //  deserializevar.questionList[index]= deserialize(QuestionListModel,element )
        // ))

        subCategoryData.push(deserializevar);
      });
      // let p = subCategoryData.slice(0, 5);
      // p.forEach((el, index) => { p[index].questionList = el.questionList.slice(0, 10) })
      return {
        calibrationId: calibrationId,
        categoryId: categoryId,
        subCategories: subCategoryData
      };
    } catch (error) {
      Logger.error(JSON.stringify("repo/rev/selfCalibration", error, "fetchSubCategoryData"));
      return null;
    }
  };
  submitAudit = async (param: { calibrationId: number }, auditType: AuditType) => {
    await ApiClient.put(
      selfCalibrationEndPoints.submitAudit(param.calibrationId),
      { isCompleted: true },
      null,
      this.keysBasedOnType[auditType]
    );
    let reportId: null = null;
    return { calibrationId: param.calibrationId, reportId };
  };
  prepareReport = async (param: { calibrationId: number }, auditType: AuditType) => {
    let reportId = await ApiClient.post(
      selfCalibrationEndPoints.prepareReport(),
      param.calibrationId.toString(),
      null,
      this.keysBasedOnType[auditType]
    );
    return { calibrationId: param.calibrationId, reportId };
  };
  postSosAvgTime = async (
    param: {
      sosTime: Array<{ orderId: "string"; timeTaken: "string" }>;
      calibrationId: number;
      subCategoryId: number;
    },
    auditType: AuditType
  ) => {
    const { calibrationId, subCategoryId, sosTime } = param;
    return ApiClient.post(
      selfCalibrationEndPoints.sosAvgTime(calibrationId, subCategoryId),
      sosTime,
      null,
      this.keysBasedOnType[auditType]
    );
  };
  getSosAvgTime = async (
    param: { calibrationId: number; subCategoryId: number },
    auditType: AuditType
  ) => {
    const { calibrationId, subCategoryId } = param;
    return ApiClient.get(
      selfCalibrationEndPoints.sosAvgTime(calibrationId, subCategoryId),
      null,
      this.keysBasedOnType[auditType]
    );
  };
  deleteSos = async (
    param: { calibrationId: number; subCategoryId: number },
    auditType: AuditType
  ) => {
    const { calibrationId, subCategoryId } = param;
    return ApiClient.delete(
      selfCalibrationEndPoints.sosAvgTime(calibrationId, subCategoryId),
      null,
      null,
      this.keysBasedOnType[auditType]
    );
  };
  fetchPreviousAudit = async (param: { empId: number; role: Roles[] }) => {
    const { empId, role } = param;
    if (role[0] === Roles.StoreAccess) {
      return ApiClient.get(
        selfCalibrationEndPoints.previousAuditStore(empId),
        null,
        REVKeys.TEST_AUDIT
      );
    } else {
      return ApiClient.get(
        selfCalibrationEndPoints.previousAuditIndividual(empId),
        null,
        REVKeys.STANDARD_AUDIT
      );
    }
  };
  deleteAudit = async (param: { calibrationId: number }) => {
    const { calibrationId } = param;
    await ApiClient.delete(
      selfCalibrationEndPoints.deleteAudit(calibrationId),
      null,
      null,
      REVKeys.TEST_AUDIT
    );
  };
  resumeAudit = async (param: { calibrationId: number; type: AuditType }) => {
    const { calibrationId, type } = param;
    let data = await ApiClient.get(
      selfCalibrationEndPoints.deleteAudit(calibrationId),
      null,
      this.keysBasedOnType[type]
    );
    data.selfCalibrationId = calibrationId;
    data.isCompleted = false;
    try {
      return deserialize(ResumeAuditInfoModel, data);
    } catch (error) {
      Logger.error(JSON.stringify("repo/rev/selfCalibration", error, "resumeAudit"));
      return null;
    }
  };

  postCommentData = async (param: PostCommentModel, AuditType: AuditType) => {
    let headers = {
      ...this.keysBasedOnType[AuditType],
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      selfCalibrationEndPoints.postCommentData(param.subCategoryId, param.calibrationId),
      param.body,
      null,
      headers
    );
    return deserialize(CommentDataModel, data);
  };

  getCommentData = async (param: PostCommentModel, auditType: AuditType) => {
    let data = await ApiClient.get(
      selfCalibrationEndPoints.getCommentData(param.subCategoryId, param.calibrationId),
      null,
      this.keysBasedOnType[auditType]
    );
    if (isEmpty(data)) {
      data.calibrationId = param.calibrationId;
      data.subcategoryId = param.subCategoryId;
      data.files = [];
      data.comment = "";
      return data;
    } else {
      return deserialize(CommentDataModel, data);
    }
  };

  getExternalMetaDetails = async () => {
    let data: any = await ApiClient.get(
      selfCalibrationEndPoints.getExternalMeta(),
      null,
      this.keysBasedOnType["STANDARD"]
    );
    return data;
  };
  postExternalAuditData = async (value: any) => {
    let data: any = await ApiClient.post(
      selfCalibrationEndPoints.postExternalAuditData(),
      value,
      null,
      this.keysBasedOnType["STANDARD"]
    );
    return data;
  };
  uploadExternalAuditAsset = async (param: { calibrationId: number; files: FormData }) => {
    const { calibrationId, files } = param;
    let data: any = await ApiClient.post(
      selfCalibrationEndPoints.postExternalAuditAsset(calibrationId),
      files,
      null,
      this.keysBasedOnType["STANDARD"]
    );
    return data;
  };
  updateCommentData = async (param: PostCommentModel, auditType: AuditType) => {
    let headers = {
      ...this.keysBasedOnType[auditType],
      "Content-Type": "multipart/form-data"
    };

    let data = await ApiClient.put(
      selfCalibrationEndPoints.postCommentData(param.subCategoryId, param.calibrationId),
      param.body,
      null,
      headers
    );
    return deserialize(CommentDataModel, data);
  };
  getStoreListByCodeOrName = async (param: GetStoreListByCodeOrNamePayload) => {
    const { pageNo, pageSize, keyword, role } = param;
    let data = await ApiClient.get(
      selfCalibrationEndPoints.getStoreListByCodeOrName(param.empId),
      {
        pageNo,
        pageSize,
        keyword,
        role
      },
      REVKeys.TEST_AUDIT
    );
    let storesData: ComparisonStoresAdminModel = {
      content: [],
      totalElements: null
    };
    let finalContent: ComparisonStoresAdminModelList[] = [];
    data.content.map((item: ComparisonStoresAdminModelList) => {
      let deserializevar: ComparisonStoresAdminModelList = deserialize(
        ComparisonStoresAdminModelList,
        item
      );
      finalContent.push(deserializevar);
    });
    storesData.totalElements = data.totalElements;
    storesData.content = finalContent;
    storesData.currentPage = pageNo;
    return storesData;
  };
}

const selfCalibrationRepository = new SelfCalibrationRepository();
export { selfCalibrationRepository as selfCalibrationRepository };
