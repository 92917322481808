import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  ProductTemperatureCategoriesModel,
  WeeklyCleaningItemsModel,
  WeeklyPostItemsResponseModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { ITimeTempCategories } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { AnswerStatus } from "@timhortons/common/src/modules/logs/formatter/cleaningFormatter";

export interface ICleaningLoading {
  getEmployeeDropDown: boolean;
  getDeepCleaningItems: boolean;
  postDeepCleaningItems: boolean;
  getRestRoomItems: boolean;
  postRestRoomItems: boolean;
  getCleaningCategories: boolean;
  cleaningLogsShift: boolean;
  getRestRoomProducts: boolean;
}
export interface ICleaningError {
  getEmployeeDropDown: string;
  getDeepCleaningItems: string;
  postDeepCleaningItems: string;
  getRestRoomItems: string;
  postRestRoomItems: string;
  getCleaningCategories: string;
  cleaningLogsShift: string;
  getRestRoomProducts: string;
}
export interface ICleaningState {
  loading: ICleaningLoading;
  error: ICleaningError;
  deepCleaningItems: WeeklyCleaningItemsModel[];
  getEmployeeDropDown: any; //TODO - model
  postDeepCleaningItems: any; //TODO - model
  getRestRoomItems: any; //TODO - model
  postRestRoomItems: WeeklyPostItemsResponseModel; //TODO - model
  getCleaningCategories: ITimeTempCategories[];
  cleaningLogsShift: null;
  restRoomProducts: ProductTemperatureCategoriesModel[];
}

export const cleaningLogsInitialState: ICleaningState = {
  loading: {
    getEmployeeDropDown: false,
    getDeepCleaningItems: false,
    postDeepCleaningItems: false,
    getRestRoomItems: false,
    postRestRoomItems: false,
    getCleaningCategories: false,
    cleaningLogsShift: false,
    getRestRoomProducts: false
  },
  error: {
    getEmployeeDropDown: null,
    getDeepCleaningItems: null,
    postDeepCleaningItems: null,
    getRestRoomItems: null,
    postRestRoomItems: null,
    getCleaningCategories: null,
    cleaningLogsShift: null,
    getRestRoomProducts: null
  },
  deepCleaningItems: null,
  getEmployeeDropDown: null,
  postDeepCleaningItems: null,
  getRestRoomItems: null,
  postRestRoomItems: null,
  getCleaningCategories: null,
  cleaningLogsShift: null,
  restRoomProducts: null
};
export const cleaningLogsReducer = (
  state = cleaningLogsInitialState,
  action: { payload: any; type: string }
): ICleaningState => {
  switch (action.type) {
    case LOGS.MainLogs.Cleaning.GET_DEEP_CLEANING_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getDeepCleaningItems: true },
        error: { ...state.error, getDeepCleaningItems: null }
      };
    case LOGS.MainLogs.Cleaning.GET_DEEP_CLEANING_ITEMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDeepCleaningItems: false },
        error: { ...state.error, getDeepCleaningItems: null },
        deepCleaningItems: action.payload
      };
    case LOGS.MainLogs.Cleaning.GET_DEEP_CLEANING_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getDeepCleaningItems: false },
        error: { ...state.error, getDeepCleaningItems: action.payload }
      };
    case LOGS.MainLogs.Cleaning.POST_DEEP_CLEANING_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postDeepCleaningItems: true },
        error: { ...state.error, postDeepCleaningItems: null }
      };
    case LOGS.MainLogs.Cleaning.POST_DEEP_CLEANING_ITEMS.SUCCESS:
      let formattedData = state.deepCleaningItems;
      let dataIndex =
        formattedData &&
        formattedData.findIndex((obj) => obj.uniqueItemId === action.payload.questionId);
      if (dataIndex > -1) {
        formattedData[dataIndex].status = action.payload.answerType;
        formattedData[dataIndex].employeeName = action.payload.employeeId;
        formattedData[dataIndex].images = action.payload.assets;
        formattedData[dataIndex].comment = action.payload.comment;
        formattedData[dataIndex].isComment =
          action.payload.comment || action.payload.assets ? true : false;
      }
      let d = state.getCleaningCategories;
      let i = d && d.findIndex((item) => item.id === action.payload.categoryId);
      if (i > -1) {
        d[i].percentageCompleted = action.payload.percentageCompleted;
      }
      return {
        ...state,
        loading: { ...state.loading, postDeepCleaningItems: false },
        error: { ...state.error, postDeepCleaningItems: null },
        deepCleaningItems: formattedData,
        postDeepCleaningItems: action.payload
      };
    case LOGS.MainLogs.Cleaning.POST_DEEP_CLEANING_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postDeepCleaningItems: false },
        error: { ...state.error, postDeepCleaningItems: action.payload }
      };
    case LOGS.MainLogs.Cleaning.GET_EMPLOYEE_DROPDOWN.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeDropDown: true },
        error: { ...state.error, getEmployeeDropDown: null }
      };
    case LOGS.MainLogs.Cleaning.GET_EMPLOYEE_DROPDOWN.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeDropDown: false },
        error: { ...state.error, getEmployeeDropDown: null },
        getEmployeeDropDown: action.payload
      };
    case LOGS.MainLogs.Cleaning.GET_EMPLOYEE_DROPDOWN.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeDropDown: false },
        error: { ...state.error, getEmployeeDropDown: action.payload }
      };

    case LOGS.MainLogs.Cleaning.GET_RESTROOM_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomItems: true },
        error: { ...state.error, getRestRoomItems: null }
      };
    case LOGS.MainLogs.Cleaning.GET_RESTROOM_ITEMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomItems: false },
        error: { ...state.error, getRestRoomItems: null },
        getRestRoomItems: action.payload
      };
    case LOGS.MainLogs.Cleaning.GET_RESTROOM_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomItems: false },
        error: { ...state.error, getRestRoomItems: action.payload }
      };

    case LOGS.MainLogs.Cleaning.POST_RESTROOM_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postRestRoomItems: true },
        error: { ...state.error, postRestRoomItems: null }
      };
    case LOGS.MainLogs.Cleaning.POST_RESTROOM_ITEMS.SUCCESS:
      let s = state.getCleaningCategories;
      let z = s && s.findIndex((item) => item.id === action.payload.categoryId);
      if (z > -1) {
        s[z].percentageCompleted = action.payload.percentageCompleted;
      }
      let updatedRestRoomState = state.getRestRoomItems;
      let id =
        updatedRestRoomState &&
        updatedRestRoomState.findIndex(
          (obj: any) => obj.uniqueItemId === action.payload.questionId
        );
      let isCommentAvailable = action.payload.answerStatus === AnswerStatus.comment;
      if (id > -1) {
        updatedRestRoomState[id].status = action.payload.answerType;
        updatedRestRoomState[id].images = action.payload.assets;
        updatedRestRoomState[id].comment = action.payload.comment;
        updatedRestRoomState[id].isComment = isCommentAvailable;
      }
      return {
        ...state,
        loading: { ...state.loading, postRestRoomItems: false },
        error: { ...state.error, postRestRoomItems: null },
        getRestRoomItems: updatedRestRoomState
      };
    case LOGS.MainLogs.Cleaning.POST_RESTROOM_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postRestRoomItems: false },
        error: { ...state.error, postRestRoomItems: action.payload }
      };
    case LOGS.MainLogs.Cleaning.RESET_CLEANING_ERRORS:
      return {
        ...state,
        postDeepCleaningItems: null,
        error: {
          ...state.error,
          postDeepCleaningItems: null,
          postRestRoomItems: null
        }
      };
    case LOGS.MainLogs.Cleaning.GET_CLEANING_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCleaningCategories: true },
        error: { ...state.error, getCleaningCategories: null }
      };
    case LOGS.MainLogs.Cleaning.GET_CLEANING_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCleaningCategories: false },
        getCleaningCategories: action.payload
      };
    case LOGS.MainLogs.Cleaning.GET_CLEANING_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCleaningCategories: false },
        error: { ...state.error, getCleaningCategories: action.payload }
      };
    case LOGS.MainLogs.Cleaning.GET_CLEANING_LOGS_SHIFT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, cleaningLogsShift: true },
        error: { ...state.error, cleaningLogsShift: null }
      };
    case LOGS.MainLogs.Cleaning.GET_CLEANING_LOGS_SHIFT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, cleaningLogsShift: false },
        error: { ...state.error, cleaningLogsShift: null },
        cleaningLogsShift: action.payload
      };
    case LOGS.MainLogs.Cleaning.GET_CLEANING_LOGS_SHIFT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, cleaningLogsShift: false },
        error: { ...state.error, cleaningLogsShift: action.payload }
      };

    case LOGS.MainLogs.Cleaning.GET_RESTROOM_PRODUCTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomProducts: true },
        error: { ...state.error, getRestRoomProducts: null }
      };
    case LOGS.MainLogs.Cleaning.GET_RESTROOM_PRODUCTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomProducts: false },
        error: { ...state.error, getRestRoomProducts: null },
        restRoomProducts: action.payload
      };
    case LOGS.MainLogs.Cleaning.GET_RESTROOM_PRODUCTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomProducts: false },
        error: { ...state.error, getRestRoomProducts: action.payload }
      };

    case LOGS.MainLogs.Cleaning.RESET_CLEANING_STATE:
      return cleaningLogsInitialState;
    default:
      return state;
  }
};
