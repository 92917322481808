import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import * as React from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";

export enum RowType {
  HorizontalRow = "row",
  Column = "column"
}

interface IProps {
  header?: boolean;
  rowType?: RowType;
  children?: any;
  rowStyle?: StyleProp<ViewStyle>;
}

export const Row = (props: IProps): React.ReactElement => {
  const { children, rowStyle, rowType = RowType.HorizontalRow, header = false } = props;
  const containerStyle = getContainerStyle(rowType, header, rowStyle);

  return <View style={containerStyle}>{children}</View>;
};

const getContainerStyle = (type: RowType, header: boolean, rowStyle = {}): ViewStyle => {
  let themedStyle: StyleProp<ViewStyle> = styles.container;
  if (type === RowType.Column) {
    themedStyle = styles.column;
  }
  return StyleSheet.flatten([styles.container, themedStyle, header && styles.header, rowStyle]);
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorPallete.white3,
    flexDirection: "row"
    // flex: 1
  },
  column: {
    flexDirection: "column"
  },
  header: {
    backgroundColor: colorPallete.grey9
  }
});
