import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IMainMenuError,
  IMainMenuLoading,
  IMainMenuState
} from "@timhortons/common/src/redux/productionSheet/reducers/mainMenu";
import {
  BakingTableModel,
  ICategoryList,
  IProductList,
  PostMainMenuApiResp
} from "@timhortons/common/src/models/productionSheet/mainMenu";

const mainMenuSelect = (state: IState): IMainMenuState => state.productionSheet.mainMenu;

const mainMenuLoading: Selector<IState, IMainMenuLoading> = createSelector(
  mainMenuSelect,
  (data) => data.loading
);

const mainMenuError: Selector<IState, IMainMenuError> = createSelector(
  mainMenuSelect,
  (data) => data.error
);
const getAllCategoryAndProducts: Selector<IState, ICategoryList[] | BakingTableModel[]> =
  createSelector(mainMenuSelect, (data) => data.getAllCategoryAndProducts);

const postCategoryAndProducts: Selector<IState, PostMainMenuApiResp[]> = createSelector(
  mainMenuSelect,
  (data) => data.postCategoryAndProducts
);

const getAllStations: Selector<IState, IProductList[]> = createSelector(
  mainMenuSelect,
  (data) => data.allStations
);
export const MainMenuSelectors = {
  mainMenuLoading,
  mainMenuError,
  getAllCategoryAndProducts,
  postCategoryAndProducts,
  getAllStations
};
