import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import {
  LmsAssessmentTheoryDataModel,
  LmsModulePtsDataModel,
  LmsPtsCheckListDataModel,
  LmsPtsCheckListResponseModel,
  LmsPtsTopicDataModel,
  LmsPtsTopicResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";

//* Action to get assesment practical's data
const getLmsAssessmentPracticalData = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.assessments.GET_MODULE_ASSESSMENTS_PRACTICAL_DATA.LOADING,
    payload: data
  };
};
const getLmsAssessmentPracticalDataSuccess = (
  data: LmsModulePtsDataModel[]
): IFluxStandardAction<LmsModulePtsDataModel[]> => {
  return {
    type: MANAGELMS.assessments.GET_MODULE_ASSESSMENTS_PRACTICAL_DATA.SUCCESS,
    payload: data
  };
};
const getLmsAssessmentPracticalDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.GET_MODULE_ASSESSMENTS_PRACTICAL_DATA.ERROR,
    payload: error
  };
};

//* Action to post assessments practicaltopic
const postAssessmentTopic = (
  data: LmsPtsTopicDataModel
): IFluxStandardAction<LmsPtsTopicDataModel> => {
  return {
    type: MANAGELMS.assessments.POST_ASSESSMENTS_TOPIC.LOADING,
    payload: data
  };
};
const postAssessmentTopicSuccess = (
  data: LmsPtsTopicResponseModel
): IFluxStandardAction<LmsPtsTopicResponseModel> => {
  return {
    type: MANAGELMS.assessments.POST_ASSESSMENTS_TOPIC.SUCCESS,
    payload: data
  };
};
const postAssessmentTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.POST_ASSESSMENTS_TOPIC.ERROR,
    payload: error
  };
};

//* Action to update assessments practicaltopic
const updateAssessmentTopic = (data: {
  topicId: string;
  obj: LmsPtsTopicDataModel;
}): IFluxStandardAction<{ topicId: string; obj: LmsPtsTopicDataModel }> => {
  return {
    type: MANAGELMS.assessments.UPDATE_ASSESSMENTS_TOPIC.LOADING,
    payload: data
  };
};
const updateAssessmentTopicSuccess = (
  data: LmsPtsTopicResponseModel
): IFluxStandardAction<LmsPtsTopicResponseModel> => {
  return {
    type: MANAGELMS.assessments.UPDATE_ASSESSMENTS_TOPIC.SUCCESS,
    payload: data
  };
};
const updateAssessmentTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.UPDATE_ASSESSMENTS_TOPIC.ERROR,
    payload: error
  };
};

//* Action to delete assessments practicaltopic
const deleteAssessmentTopic = (data: {
  topicId: string;
}): IFluxStandardAction<{ topicId: string }> => {
  return {
    type: MANAGELMS.assessments.DELETE_ASSESSMENTS_TOPIC.LOADING,
    payload: data
  };
};
const deleteAssessmentTopicSuccess = (
  data: LmsPtsTopicResponseModel
): IFluxStandardAction<LmsPtsTopicResponseModel> => {
  return {
    type: MANAGELMS.assessments.DELETE_ASSESSMENTS_TOPIC.SUCCESS,
    payload: data
  };
};
const deleteAssessmentTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.DELETE_ASSESSMENTS_TOPIC.ERROR,
    payload: error
  };
};

//* Action to post assessments practicalChecklist
const postAssessmentCheckList = (
  data: LmsPtsCheckListDataModel
): IFluxStandardAction<LmsPtsCheckListDataModel> => {
  return {
    type: MANAGELMS.assessments.POST_ASSESSMENTS_CHECKLIST.LOADING,
    payload: data
  };
};
const postAssessmentCheckListSuccess = (
  data: LmsPtsCheckListResponseModel
): IFluxStandardAction<LmsPtsCheckListResponseModel> => {
  return {
    type: MANAGELMS.assessments.POST_ASSESSMENTS_CHECKLIST.SUCCESS,
    payload: data
  };
};
const postAssessmentCheckListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.POST_ASSESSMENTS_CHECKLIST.ERROR,
    payload: error
  };
};

//* Action to update assessments practicalChecklist
const updateAssessmentCheckList = (data: {
  checkListId: string;
  obj: LmsPtsCheckListDataModel;
}): IFluxStandardAction<{ checkListId: string; obj: LmsPtsCheckListDataModel }> => {
  return {
    type: MANAGELMS.assessments.UPDATE_ASSESSMENTS_CHECKLIST.LOADING,
    payload: data
  };
};
const updateAssessmentCheckListSuccess = (
  data: LmsPtsCheckListResponseModel
): IFluxStandardAction<LmsPtsCheckListResponseModel> => {
  return {
    type: MANAGELMS.assessments.UPDATE_ASSESSMENTS_CHECKLIST.SUCCESS,
    payload: data
  };
};
const updateAssessmentCheckListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.UPDATE_ASSESSMENTS_CHECKLIST.ERROR,
    payload: error
  };
};

//* Action to delete assessments practical Checklist
const deleteAssessmentCheckList = (data: {
  checkListId: string;
  topicId: string;
}): IFluxStandardAction<{ checkListId: string; topicId: string }> => {
  return {
    type: MANAGELMS.assessments.DELETE_ASSESSMENTS_CHECKLIST.LOADING,
    payload: data
  };
};
const deleteAssessmentCheckListSuccess = (
  data: LmsPtsCheckListResponseModel
): IFluxStandardAction<LmsPtsCheckListResponseModel> => {
  return {
    type: MANAGELMS.assessments.DELETE_ASSESSMENTS_CHECKLIST.SUCCESS,
    payload: data
  };
};
const deleteAssessmentCheckListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.DELETE_ASSESSMENTS_CHECKLIST.ERROR,
    payload: error
  };
};

//* Action to get assesment practical's data
const getLmsAssessmentTheoryData = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.assessments.GET_LMS_ASSESSMENTS_THEORY_DATA.LOADING,
    payload: data
  };
};
const getLmsAssessmentTheoryDataSuccess = (
  data: LmsAssessmentTheoryDataModel[]
): IFluxStandardAction<LmsAssessmentTheoryDataModel[]> => {
  return {
    type: MANAGELMS.assessments.GET_LMS_ASSESSMENTS_THEORY_DATA.SUCCESS,
    payload: data
  };
};
const getLmsAssessmentTheoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.assessments.GET_LMS_ASSESSMENTS_THEORY_DATA.ERROR,
    payload: error
  };
};

const resetAssessmentError = () => {
  return {
    type: MANAGELMS.assessments.RESET_ASSESSMENTS_ERROR
  };
};

export const LmsAssessmentActions = {
  getLmsAssessmentPracticalData,
  getLmsAssessmentPracticalDataSuccess,
  getLmsAssessmentPracticalDataError,
  postAssessmentTopic,
  postAssessmentTopicSuccess,
  postAssessmentTopicError,
  updateAssessmentTopic,
  updateAssessmentTopicSuccess,
  updateAssessmentTopicError,
  deleteAssessmentTopic,
  deleteAssessmentTopicSuccess,
  deleteAssessmentTopicError,
  postAssessmentCheckList,
  postAssessmentCheckListSuccess,
  postAssessmentCheckListError,
  updateAssessmentCheckList,
  updateAssessmentCheckListSuccess,
  updateAssessmentCheckListError,
  deleteAssessmentCheckList,
  deleteAssessmentCheckListSuccess,
  deleteAssessmentCheckListError,
  getLmsAssessmentTheoryData,
  getLmsAssessmentTheoryDataSuccess,
  getLmsAssessmentTheoryDataError,
  resetAssessmentError
};
