import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import {
  LMSCertificateCarousel,
  LMSModuleListModel,
  LMSContinueLearningModel,
  LMSEarnedPointModel,
  LMSTopicSubtopicModel,
  LMSUserCategoriesModel,
  LMSVideoUpdateModel,
  LMSGetQuizTableModel,
  LMSPostQuizResponseModel,
  FAQsModel,
  NewsListModel,
  NewsDetailModel,
  LearningPathDetailsModel,
  StatisticsModel,
  PublishModuleModel,
  ModuleDetailModel,
  LMSModuleData,
  TotalDaysLeftModel,
  NotificationModel
} from "@timhortons/common/src/models/userLms/userLms";
import { FCMTokenDetails } from "@timhortons/common/src/redux/userLms/actions/userLms";

export interface IUserLmsLoading {
  getLmsModulesList: boolean;
  getCertificateCarousel: boolean;
  getContinueLearning: boolean;
  getLmsEarnedPoints: boolean;
  getTopicSubtopic: boolean;
  getCategoriesLoading: boolean;
  getVideoUpdateLoading: boolean;
  getTotalDaysLeft: boolean;
  getQuizDetails: boolean;
  postQuizResponse: boolean;
  isGetFAQsLoading: boolean;
  isGetFAQSearchResultLoading: boolean;
  isGetEnquiriesLoading: boolean;
  isPostQuestionLoading: boolean;
  isGetNewsListLoading: boolean;
  isGetNewsDetailsLoading: boolean;
  isGetLearningPathDetailsLoading: boolean;
  isGetStatisticsLoading: boolean;
  isGetSpecialModulesLoading: boolean;
  isGetModuleDetailLoading: boolean;
  isGetNotificationLoading: boolean;
  saveFcmLoading: boolean;
  editUserProfile: boolean;
}
export interface IUserLmsError {
  getLmsModulesList: string;
  getCertificateCarousel: string;
  getContinueLearning: string;
  getLmsEarnedPoints: string;
  getTopicSubtopic: string;
  getCategoriesError: string;
  getVideoUpdateError: string;
  getTotalDaysLeft: string;
  getQuizDetails: string;
  postQuizResponse: string;
  faqs: string;
  faqSearchResult: string;
  enquiries: string;
  question: string;
  getNewsList: string;
  getNewsDetails: string;
  learningPathDetails: string;
  getStatistics: string;
  getSpecialModules: string;
  getModuleDetails: string;
  isNotificationError: string;
  saveFCMError: string;
  editUserProfile: string;
}

export interface IFAQSearchResult {
  content: FAQsModel[];
  totalElements: number;
  pageNo?: number;
}

export interface IUserLmsState {
  loading: IUserLmsLoading;
  error: IUserLmsError;
  lmsModulesList: LMSModuleData;
  lmsCertificateCarousel: LMSCertificateCarousel[];
  continueLearning: LMSContinueLearningModel;
  getLmsEarnedPoints: LMSEarnedPointModel;
  topicSubtopic: LMSTopicSubtopicModel;
  getCategories: LMSUserCategoriesModel;
  getVideoUpdate: LMSVideoUpdateModel;
  daysLeft: TotalDaysLeftModel;
  getQuizDetailsData: LMSGetQuizTableModel;
  postQuizResponseData: LMSPostQuizResponseModel;
  videoCurrentTime: number;
  faqs: FAQsModel[];
  faqSearchResult: IFAQSearchResult;
  enquiries: FAQsModel[];
  question: any;
  getNewsList: NewsListModel[] | null;
  getNewsDetails: NewsDetailModel;
  learningPathDetails: LearningPathDetailsModel[];
  getStatistics: StatisticsModel | null;
  getSpecialModules: PublishModuleModel[];
  getModuleDetails: ModuleDetailModel;
  getNotifications: NotificationModel[];
  saveFCMToken: FCMTokenDetails;
  editUserProfile: String;
  profileUpdated: { isUpdated: Boolean };
  addedNotification: null;
}
export const appealsInitialState: IUserLmsState = {
  lmsModulesList: {
    coreTraining: [],
    specialTraining: []
  },
  lmsCertificateCarousel: [],
  continueLearning: null,
  getLmsEarnedPoints: null,
  topicSubtopic: null,
  getCategories: null,
  getVideoUpdate: null,
  daysLeft: null,
  getQuizDetailsData: null,
  postQuizResponseData: null,
  videoCurrentTime: null,
  faqs: [],
  faqSearchResult: { content: [], totalElements: 0 },
  enquiries: [],
  question: null,
  getNewsList: null,
  getNewsDetails: null,
  learningPathDetails: [],
  getStatistics: null,
  getSpecialModules: null,
  getModuleDetails: null,
  editUserProfile: null,
  profileUpdated: { isUpdated: false },
  addedNotification: null,
  loading: {
    getLmsModulesList: false,
    getCertificateCarousel: false,
    getContinueLearning: false,
    getLmsEarnedPoints: false,
    getTopicSubtopic: false,
    getCategoriesLoading: false,
    getVideoUpdateLoading: false,
    getTotalDaysLeft: false,
    getQuizDetails: false,
    postQuizResponse: false,
    isGetFAQsLoading: false,
    isGetFAQSearchResultLoading: false,
    isGetEnquiriesLoading: false,
    isPostQuestionLoading: false,
    isGetNewsListLoading: false,
    isGetNewsDetailsLoading: false,
    isGetLearningPathDetailsLoading: false,
    isGetStatisticsLoading: false,
    isGetSpecialModulesLoading: false,
    isGetModuleDetailLoading: false,
    isGetNotificationLoading: false,
    saveFcmLoading: false,
    editUserProfile: false
  },
  error: {
    getLmsModulesList: null,
    getCertificateCarousel: null,
    getContinueLearning: null,
    getLmsEarnedPoints: null,
    getTopicSubtopic: null,
    getCategoriesError: null,
    getVideoUpdateError: null,
    getTotalDaysLeft: null,
    getQuizDetails: null,
    postQuizResponse: null,
    faqs: null,
    faqSearchResult: null,
    enquiries: null,
    question: null,
    getNewsList: null,
    getNewsDetails: null,
    learningPathDetails: null,
    getStatistics: null,
    getSpecialModules: null,
    getModuleDetails: null,
    isNotificationError: null,
    saveFCMError: null,
    editUserProfile: null
  }
};

export const userLmsReducer = (
  state = appealsInitialState,
  action: { payload: any; type: string }
): IUserLmsState => {
  switch (action.type) {
    // * Reducers for module list
    case UserLMS.GET_LMS_MODULE_LIST.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsModulesList: true
        },
        error: {
          ...state.error,
          getLmsModulesList: null
        }
      };
    case UserLMS.GET_LMS_MODULE_LIST.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsModulesList: false
        },
        error: {
          ...state.error,
          getLmsModulesList: null
        },
        lmsModulesList: action.payload
      };
    case UserLMS.GET_LMS_MODULE_LIST.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsModulesList: false
        },
        error: {
          ...state.error,
          getLmsModulesList: action.payload
        }
      };

    // * Reducers for continue learning
    case UserLMS.GET_CONTINUE_LEARNING.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getContinueLearning: true },
        error: { ...state.error, getContinueLearning: null }
      };
    case UserLMS.GET_CONTINUE_LEARNING.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getContinueLearning: false },
        error: { ...state.error, getContinueLearning: null },
        continueLearning: action.payload,
        videoCurrentTime: action.payload.playBackTime,
        getVideoUpdate: null
      };
    case UserLMS.GET_CONTINUE_LEARNING.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getContinueLearning: false },
        error: { ...state.error, getContinueLearning: action.payload }
      };

    // * Reducers for certificate carousel
    case UserLMS.GET_LMS_CERTIFICATE_CAROUSEL.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCertificateCarousel: true
        },
        error: {
          ...state.error,
          getCertificateCarousel: null
        }
      };
    case UserLMS.GET_LMS_CERTIFICATE_CAROUSEL.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCertificateCarousel: false
        },
        error: {
          ...state.error,
          getCertificateCarousel: null
        },
        lmsCertificateCarousel: action.payload
      };
    case UserLMS.GET_LMS_CERTIFICATE_CAROUSEL.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCertificateCarousel: false
        },
        error: {
          ...state.error,
          getCertificateCarousel: action.payload
        }
      };

    // * Reducers to get Earned Points
    case UserLMS.GET_TOTAL_EARNED_POINTS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsEarnedPoints: true
        },
        error: {
          ...state.error,
          getLmsEarnedPoints: null
        }
      };
    case UserLMS.GET_TOTAL_EARNED_POINTS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsEarnedPoints: false
        },
        error: {
          ...state.error,
          getLmsEarnedPoints: null
        },
        getLmsEarnedPoints: action.payload
      };
    case UserLMS.GET_TOTAL_EARNED_POINTS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsEarnedPoints: false
        },
        error: {
          ...state.error,
          getLmsEarnedPoints: action.payload
        }
      };

    case UserLMS.GET_CATEGORIES.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCategoriesLoading: true
        },
        error: {
          ...state.error,
          getCategoriesError: null
        },
        getCategories: null
      };

    case UserLMS.GET_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCategoriesLoading: false
        },
        error: {
          ...state.error,
          getCategoriesError: null
        },
        getCategories: action.payload
      };

    case UserLMS.GET_CATEGORIES.ERROR:
      let error = "";
      if (action.payload === null) {
        error = "error";
      } else {
        error = action.payload;
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          getCategoriesLoading: false
        },
        error: {
          ...state.error,
          getCategoriesError: error
        }
      };

    // * Reducers for topics and subtopics
    case UserLMS.GET_TOPICS_SUBTOPICS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTopicSubtopic: true
        },
        error: {
          ...state.error,
          getTopicSubtopic: null
        }
      };
    case UserLMS.GET_TOPICS_SUBTOPICS.SUCCESS:
      const categoryList: LMSUserCategoriesModel = state.getCategories;
      const topicSubtopic: LMSTopicSubtopicModel = action.payload;

      for (let i = 0; i < categoryList.categories.length; i++) {
        if (categoryList.categories[i].uuid === topicSubtopic.id) {
          categoryList.categories[i] = {
            ...categoryList.categories[i],
            topicSubtopic: topicSubtopic
          };
        }
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          getTopicSubtopic: false
        },
        error: {
          ...state.error,
          getTopicSubtopic: null
        },
        getCategories: categoryList
      };
    case UserLMS.GET_TOPICS_SUBTOPICS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTopicSubtopic: false
        },
        error: {
          ...state.error,
          getTopicSubtopic: action.payload
        }
      };

    // * Reducers for Update Video Status
    case UserLMS.UPDATE_VIDEO_STATUS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoUpdateLoading: true
        },
        error: {
          ...state.error,
          getVideoUpdateError: null
        }
      };

    case UserLMS.UPDATE_VIDEO_STATUS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoUpdateLoading: false
        },
        error: {
          ...state.error,
          getVideoUpdateError: null
        },
        getVideoUpdate: action.payload
      };
    case UserLMS.UPDATE_VIDEO_STATUS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoUpdateLoading: false
        },
        error: {
          ...state.error,
          getVideoUpdateError: action.payload
        }
      };

    // * Reducers for get quiz Details
    case UserLMS.GET_QUIZ_DETAILS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getQuizDetails: true
        },
        error: {
          ...state.error,
          getQuizDetails: null
        }
      };

    case UserLMS.GET_QUIZ_DETAILS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getQuizDetails: false
        },
        error: {
          ...state.error,
          getQuizDetails: null
        },
        getQuizDetailsData: action.payload
      };
    case UserLMS.GET_QUIZ_DETAILS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getQuizDetails: false
        },
        error: {
          ...state.error,
          getQuizDetails: action.payload
        }
      };
    // * Reducers for post quiz response
    case UserLMS.POST_QUIZ_RESPONSE.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          postQuizResponse: true
        },
        error: {
          ...state.error,
          postQuizResponse: null
        }
      };

    case UserLMS.POST_QUIZ_RESPONSE.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          postQuizResponse: false
        },
        error: {
          ...state.error,
          postQuizResponse: null
        },
        postQuizResponseData: action.payload
      };
    case UserLMS.POST_QUIZ_RESPONSE.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          postQuizResponse: false
        },
        error: {
          ...state.error,
          postQuizResponse: action.payload
        }
      };

    case UserLMS.RESET_QUIZ_DETAILS:
      return {
        ...state,
        getQuizDetailsData: null,
        postQuizResponseData: null
      };

    case UserLMS.UPDATE_VIDEO_ACTION.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getContinueLearning: false
        },
        error: {
          ...state.error,
          getContinueLearning: null
        },
        continueLearning: action.payload,
        getVideoUpdate: null,
        videoCurrentTime: 0
      };

    case UserLMS.UPDATE_VIDEO_CURRENTTIME.SUCCESS:
      return {
        ...state,
        videoCurrentTime: action.payload
      };
    // Reducers for FAQs
    case UserLMS.GET_FAQS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetFAQsLoading: true
        },
        error: {
          ...state.error,
          faqs: null
        }
      };

    case UserLMS.GET_FAQS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetFAQsLoading: false
        },
        error: {
          ...state.error,
          faqs: null
        },
        faqs: action.payload
      };
    case UserLMS.GET_FAQS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetFAQsLoading: false
        },
        error: {
          ...state.error,
          faqs: action.payload
        }
      };
    case UserLMS.GET_FAQ_SEARCH_RESULT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetFAQSearchResultLoading: true
        },
        error: {
          ...state.error,
          faqSearchResult: null
        }
      };

    case UserLMS.GET_FAQ_SEARCH_RESULT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetFAQSearchResultLoading: false
        },
        error: {
          ...state.error,
          faqSearchResult: null
        },
        faqSearchResult: {
          content:
            action.payload.pageNo !== 0
              ? [...state.faqSearchResult.content, ...action.payload.content]
              : action.payload.content,
          totalElements: action.payload.totalElements
        }
      };
    case UserLMS.GET_FAQ_SEARCH_RESULT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetFAQSearchResultLoading: false
        },
        error: {
          ...state.error,
          faqSearchResult: action.payload
        }
      };
    case UserLMS.GET_ENQUIRIES.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetEnquiriesLoading: true
        },
        error: {
          ...state.error,
          enquiries: null
        }
      };

    case UserLMS.GET_ENQUIRIES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetEnquiriesLoading: false
        },
        error: {
          ...state.error,
          enquiries: null
        },
        enquiries: action.payload
      };
    case UserLMS.GET_ENQUIRIES.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetEnquiriesLoading: false
        },
        error: {
          ...state.error,
          enquiries: action.payload
        }
      };
    case UserLMS.POST_QUESTION.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isPostQuestionLoading: true
        },
        error: {
          ...state.error,
          question: null
        }
      };

    case UserLMS.POST_QUESTION.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isPostQuestionLoading: false
        },
        error: {
          ...state.error,
          question: null
        },
        question: action.payload
      };
    case UserLMS.POST_QUESTION.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isPostQuestionLoading: false
        },
        error: {
          ...state.error,
          question: action.payload
        }
      };

    // Reducers for News
    case UserLMS.GET_NEWS_LIST.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNewsListLoading: true
        },
        error: {
          ...state.error,
          getNewsList: null
        }
      };

    case UserLMS.GET_NEWS_LIST.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNewsListLoading: false
        },
        error: {
          ...state.error,
          getNewsList: null
        },
        getNewsList: action.payload
      };
    case UserLMS.GET_NEWS_LIST.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNewsListLoading: false
        },
        error: {
          ...state.error,
          getNewsList: action.payload
        }
      };
    case UserLMS.RESET_NEWS_LIST:
      return {
        ...state,
        getNewsList: null
      };
    case UserLMS.GET_NEWS_DETAILS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNewsDetailsLoading: true
        },
        error: {
          ...state.error,
          getNewsDetails: null
        }
      };

    case UserLMS.GET_NEWS_DETAILS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNewsDetailsLoading: false
        },
        error: {
          ...state.error,
          getNewsDetails: null
        },
        getNewsDetails: action.payload
      };
    case UserLMS.GET_NEWS_DETAILS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNewsDetailsLoading: false
        },
        error: {
          ...state.error,
          getNewsDetails: action.payload
        }
      };
    case UserLMS.RESET_NEWS_DETAILS:
      return {
        ...state,
        getNewsDetails: null
      };
    // Reducers for Statistics
    case UserLMS.GET_STATISTICS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetStatisticsLoading: true
        },
        error: {
          ...state.error,
          getStatistics: null
        }
      };

    case UserLMS.GET_STATISTICS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetStatisticsLoading: false
        },
        error: {
          ...state.error,
          getStatistics: null
        },
        getStatistics: action.payload
      };
    case UserLMS.GET_STATISTICS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetStatisticsLoading: false
        },
        error: {
          ...state.error,
          getStatistics: action.payload
        }
      };
    case UserLMS.GET_SPECIAL_MODULES.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetSpecialModulesLoading: true
        },
        error: {
          ...state.error,
          getSpecialModules: null
        }
      };

    case UserLMS.GET_SPECIAL_MODULES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetSpecialModulesLoading: false
        },
        error: {
          ...state.error,
          getSpecialModules: null
        },
        getSpecialModules: action.payload
      };
    case UserLMS.GET_SPECIAL_MODULES.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetSpecialModulesLoading: false
        },
        error: {
          ...state.error,
          getSpecialModules: action.payload
        }
      };
    case UserLMS.GET_MODULE_DETAIL.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetModuleDetailLoading: true
        },
        error: {
          ...state.error,
          getModuleDetails: null
        }
      };

    case UserLMS.GET_MODULE_DETAIL.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetModuleDetailLoading: false
        },
        error: {
          ...state.error,
          getModuleDetails: null
        },
        getModuleDetails: action.payload
      };
    case UserLMS.GET_MODULE_DETAIL.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetModuleDetailLoading: false
        },
        error: {
          ...state.error,
          getModuleDetails: action.payload
        }
      };
    // Reducers for Learning Path
    case UserLMS.GET_LEARNING_PATH_DETAILS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetLearningPathDetailsLoading: true
        },
        error: {
          ...state.error,
          learningPathDetails: null
        }
      };

    case UserLMS.GET_LEARNING_PATH_DETAILS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetLearningPathDetailsLoading: false
        },
        error: {
          ...state.error,
          learningPathDetails: null
        },
        learningPathDetails: action.payload
      };
    case UserLMS.GET_LEARNING_PATH_DETAILS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetLearningPathDetailsLoading: false
        },
        error: {
          ...state.error,
          learningPathDetails: action.payload
        }
      };
    case UserLMS.RESET_LEARNING_PATH_DETAILS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetLearningPathDetailsLoading: true
        },
        learningPathDetails: []
      };
    case UserLMS.RESET_QUIZ_RESPONSE.LOADING:
      return {
        ...state,
        error: {
          ...state.error,
          postQuizResponse: null
        }
      };

    case UserLMS.GET_USER_NOTIFICATIONS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNotificationLoading: true
        }
      };

    case UserLMS.GET_USER_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          isGetNotificationLoading: false
        },
        getNotifications: action.payload
      };

    case UserLMS.GET_USER_NOTIFICATIONS.ERROR:
      return {
        ...state,
        loading: {
          isGetNotificationLoading: false,
          ...state.loading
        },
        error: {
          isNotificationError: action.payload,
          ...state.error
        }
      };

    case UserLMS.GET_TOTAL_DAYS_LEFT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTotalDaysLeft: true
        },
        error: {
          ...state.error,
          getTotalDaysLeft: null
        }
      };

    case UserLMS.GET_TOTAL_DAYS_LEFT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTotalDaysLeft: false
        },
        error: {
          ...state.error,
          getTotalDaysLeft: null
        },
        daysLeft: action.payload
      };
    case UserLMS.GET_TOTAL_DAYS_LEFT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTotalDaysLeft: false
        },
        error: {
          ...state.error,
          getTotalDaysLeft: action.payload
        }
      };
    case UserLMS.SAVE_USER_NOTIFICATION.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          saveFcmLoading: true
        }
      };

    case UserLMS.SAVE_USER_NOTIFICATION.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          saveFcmLoading: false
        },
        saveFCMToken: action.payload
      };

    case UserLMS.SAVE_USER_NOTIFICATION.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          saveFcmLoading: false
        },
        error: {
          ...state.error,
          saveFCMError: action.payload
        }
      };
    case UserLMS.EDIT_PROFILE.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          editUserProfile: true
        },
        error: {
          ...state.error,
          editUserProfile: null
        }
      };
    case UserLMS.EDIT_PROFILE.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          editUserProfile: false
        },
        editUserProfile: "updated"
      };
    case UserLMS.EDIT_PROFILE.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          editUserProfile: false
        },
        error: {
          ...state.error,
          editUserProfile: action.payload
        }
      };
    case UserLMS.PROFILE_UPDATED:
      return {
        ...state,
        profileUpdated: action.payload
      };
    case UserLMS.RESET_EDIT_PROFILE:
      return {
        ...state,
        editUserProfile: null,
        error: {
          ...state.error,
          editUserProfile: null
        }
      };
    case UserLMS.ADD_NOTIFICATION:
      return {
        ...state,
        addedNotification: action.payload
      };
    case UserLMS.CLEAR_NOTIFICATION:
      return {
        ...state,
        addedNotification: null
      };
    default:
      return state;
  }
};
