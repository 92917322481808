import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { LogsSummaryDataModel } from "@timhortons/common/src/models/logs/mainLogs";

export const logsSummaryFormatter = async (response: any) => {
  let data: LogsSummaryDataModel;
  data = {
    completedReport: {
      series: [],
      labels: []
    },
    complianceReport: {
      series: [],
      categories: []
    },
    resolvedReport: {
      series: [],
      categories: []
    }
  };

  response &&
    response.completeReport &&
    response.completeReport.map((el: any) => {
      return {
        series: data.completedReport.series.push(el.count),
        labels: data.completedReport.labels.push(el.label)
      };
    });

  response &&
    response.complianceReport.map((el: any) => {
      return {
        series: data.complianceReport.series.push(Number(el.count.toFixed(0))),
        categories: data.complianceReport.categories.push(
          el.label === "Non-Compliance"
            ? PlatformUtils.isWeb()
              ? ["Non -", "Compliance"]
              : "Non Compliance"
            : el.label
        )
      };
    });
  response &&
    response.resolvedReport.map((el: any) => {
      return {
        series: data.resolvedReport.series.push(Number(el.count.toFixed(0))),
        categories: data.resolvedReport.categories.push(el.label)
      };
    });
  return data;
};
