import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { LpCategoryDataModel, LpYesNoDataModel } from "models/lp/audits";
import { AuditType } from "modules/lp/components/dashboard";
import { IFluxStandardAction } from "store/interfaces";

const getLpCategoryData = (data: {
  selfCalibrationId: number;
  auditType: AuditType;
}): IFluxStandardAction<{ selfCalibrationId: number; auditType: AuditType }> => {
  return {
    type: LP.LP_CATEGORY_DATA.LOADING,
    payload: data
  };
};

const getLpCategoryDataSuccess = (
  data: LpCategoryDataModel
): IFluxStandardAction<LpCategoryDataModel> => {
  return {
    type: LP.LP_CATEGORY_DATA.SUCCESS,
    payload: data
  };
};

const getLpCategoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.LP_CATEGORY_DATA.ERROR,
    payload: error
  };
};
const updateLpCategoryData = (
  data: LpYesNoDataModel,
  categoryId: number
): IFluxStandardAction<{ data: LpYesNoDataModel; categoryId: number }> => {
  return {
    type: LP.UPDATE_LP_CATEGORY_DATA.SUCCESS,
    payload: { data, categoryId }
  };
};

export const LpCategoryActions = {
  getLpCategoryData,
  getLpCategoryDataSuccess,
  getLpCategoryDataError,
  updateLpCategoryData
};
