import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { call, put, takeLatest } from "redux-saga/effects";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { CategoryActions } from "@timhortons/common/src/redux/rev/actions/category";
import { CategoryDataModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { IFluxStandardAction } from "store/interfaces";
import { selfCalibrationRepository } from "@timhortons/common/src/repositories/rev/selfCalibration";
import { Logger } from "@timhortons/common/src/services/logger";

function* getCategoryDataWorkerSaga(
  value: IFluxStandardAction<{ selfCalibrationId: number; auditType: AuditType }>
) {
  try {
    const { payload } = value;
    const response: CategoryDataModel = yield call(
      selfCalibrationRepository.fetchCategoryData,
      payload
    );
    yield put(CategoryActions.getCategoryDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/category", error, "getCategoryDataWorkerSaga");

    yield put(CategoryActions.getCategoryDataError(error));
  }
}

function* categoryWatcherSaga() {
  yield takeLatest(REV.CATEGORY_DATA.LOADING, getCategoryDataWorkerSaga);
}

export default categoryWatcherSaga;
