import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { CALENDAR } from "./actionTypes";

export interface CreateEvent {
  date: string;
  startTime: string;
  endTime: string;
  location: string;
  category: string;
  note: string;
  employeeId: Array<string>;
  storeCode: Array<string>;
  eventName: String;
  countryNames: Array<string>;
}

export interface AreaCountryStorePayload {
  role: string;
  pageSize: Number;
  pageNo: Number;
  countryCodes: Array<Number>;
}

const getEvents = (data: {
  startDate: String;
  endDate: String;
}): IFluxStandardAction<{ startDate: String; endDate: String }> => {
  return {
    type: CALENDAR.GET_ALL_EVENTS.LOADING,
    payload: data
  };
};

const getEventsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.GET_ALL_EVENTS.SUCCESS,
    payload: data
  };
};

const getEventsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.GET_ALL_EVENTS.ERROR,
    payload: error
  };
};

const getGccCountry = () => {
  return {
    type: CALENDAR.GET_GCC_COUNTRIES.LOADING,
    payload: {}
  };
};
const getGccCountriesSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.GET_GCC_COUNTRIES.SUCCESS,
    payload: data
  };
};
const getGccCountriesError = (error: Error): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.GET_GCC_COUNTRIES.ERROR,
    payload: error
  };
};

const createEvents = (data: CreateEvent): IFluxStandardAction<CreateEvent> => {
  return {
    type: CALENDAR.CREATE_EVENT.LOADING,
    payload: data
  };
};

const createEventsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.CREATE_EVENT.SUCCESS,
    payload: data
  };
};

const createEventsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.CREATE_EVENT.ERROR,
    payload: error
  };
};

const getAreaAndCountryManagers = (
  data: AreaCountryStorePayload
): IFluxStandardAction<AreaCountryStorePayload> => {
  return {
    type: CALENDAR.GET_AREA_COUNTRY_MANAGER.LOADING,
    payload: data
  };
};

const getAreaAndCountryManagersSuccess = (
  data: any
): IFluxStandardAction<AreaCountryStorePayload> => {
  return {
    type: CALENDAR.GET_AREA_COUNTRY_MANAGER.SUCCESS,
    payload: data
  };
};

const getAreaAndCountryManagersError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.GET_AREA_COUNTRY_MANAGER.ERROR,
    payload: error
  };
};

const getStores = (data: AreaCountryStorePayload): IFluxStandardAction<AreaCountryStorePayload> => {
  return {
    type: CALENDAR.GET_STORES.LOADING,
    payload: data
  };
};
const getStoresSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.GET_STORES.SUCCESS,
    payload: data
  };
};
const getStoresError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.GET_STORES.ERROR,
    payload: error
  };
};

const getEmployees = (data: []): IFluxStandardAction<[]> => {
  return {
    type: CALENDAR.GET_EMPLOYEES.LOADING,
    payload: data
  };
};

const getEmployeesSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.GET_EMPLOYEES.SUCCESS,
    payload: data
  };
};

const getEmployeesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.GET_EMPLOYEES.ERROR,
    payload: error
  };
};

const deleteEvent = (data: { eventId: string }): IFluxStandardAction<{ eventId: string }> => {
  return {
    type: CALENDAR.DELETE_EVENT.LOADING,
    payload: data
  };
};

const deleteEventSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.DELETE_EVENT.SUCCESS,
    payload: data
  };
};

const deleteEventError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.DELETE_EVENT.ERROR,
    payload: error
  };
};

const updateEvent = (data: any): IFluxStandardAction<{ eventId: string }> => {
  return {
    type: CALENDAR.UPDATE_EVENT.LOADING,
    payload: data
  };
};

const updateEventSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.UPDATE_EVENT.SUCCESS,
    payload: data
  };
};

const updateEventError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.UPDATE_EVENT.ERROR,
    payload: error
  };
};

const resetData = (data: any): IFluxStandardAction<{ eventId: string }> => {
  return {
    type: CALENDAR.RESET_DATA.LOADING,
    payload: data
  };
};

const getEventById = (data: { eventId: string }): IFluxStandardAction<{ eventId: string }> => {
  return {
    type: CALENDAR.GET_EVENTBYID.LOADING,
    payload: data
  };
};

const getEventByIdSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: CALENDAR.GET_EVENTBYID.SUCCESS,
    payload: data
  };
};

const getEventByIdError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: CALENDAR.GET_EVENTBYID.ERROR,
    payload: error
  };
};

export const CalendarAction = {
  getEvents,
  getEventsSuccess,
  getEventsError,
  getGccCountry,
  getGccCountriesSuccess,
  getGccCountriesError,
  createEvents,
  createEventsSuccess,
  createEventsError,
  getAreaAndCountryManagers,
  getAreaAndCountryManagersSuccess,
  getAreaAndCountryManagersError,
  getStores,
  getStoresSuccess,
  getStoresError,
  getEmployees,
  getEmployeesSuccess,
  getEmployeesError,
  deleteEvent,
  deleteEventSuccess,
  deleteEventError,
  updateEvent,
  updateEventSuccess,
  updateEventError,
  resetData,
  getEventById,
  getEventByIdSuccess,
  getEventByIdError
};
