import { AxiosError, AxiosResponse } from "axios";
import { HTTP_STATUS_CODES, serverErrors } from "@timhortons/common/src/network/constants";
import { Logger } from "@timhortons/common/src/services/logger";
export const successHandler = (response: AxiosResponse) => {
  const { data } = response.data;
  if (!data || response.status === HTTP_STATUS_CODES.noContent) {
    return Promise.resolve({});
  }
  return Promise.resolve(data);
};
export const errorHandler = (error: AxiosError) => {
  let errorMessage = serverErrors.SERVER_ERROR;
  let erroObjForLogger = {
    config: { url: error?.config?.url, traceId: error?.config?.headers["Trace-Id"] },
    response: error.response?.data
      ? {
          code: error?.response?.data?.errors?.code,
          message: error?.response?.data?.errors?.message
        }
      : "NO CODE FROM SERVER"
  };
  if (error.response) {
    const { data } = error.response;
    if (data && data.errors) {
      errorMessage = data.errors.message;
    }
  }
  // Logger.error(JSON.stringify(erroObjForLogger));
  return Promise.resolve(errorMessage);
};
