import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IAuditError,
  IAuditLoading,
  IAuditState
} from "@timhortons/common/src/redux/lp/reducers/audits";

import {
  LpAuditInfoModel,
  AuditStoresListByCodeOrName,
  LpPreviousAuditDataModel,
  LpSubCategoryModel,
  LpYesNoDataModel,
  LpObservationDataModel,
  LpCommentDataModel,
  LpTippingPointsModel
} from "@timhortons/common/src/models/lp/audits";

const auditSelect = (state: IState): IAuditState => state.lp.audit;

const auditLoading: Selector<IState, IAuditLoading> = createSelector(
  auditSelect,
  (data) => data.loading
);

const auditError: Selector<IState, IAuditError> = createSelector(auditSelect, (data) => data.error);

const checkLpAuditAuth: Selector<IState, LpAuditInfoModel> = createSelector(
  auditSelect,
  (data) => data.lpCheckAuth
);

const getLpAuditStoresListByStoreCodeName: Selector<IState, AuditStoresListByCodeOrName> =
  createSelector(auditSelect, (data) => data.getLpAuditStoreListByCodeOrName);

const getLpPreviousAuditData: Selector<IState, LpPreviousAuditDataModel> = createSelector(
  auditSelect,
  (data) => data.lpPreviousAuditData
);
const getLpSubCategoryData: Selector<IState, LpSubCategoryModel> = createSelector(
  auditSelect,
  (data) => data.lpSubCategoryData
);
const getTippingPointsData: Selector<IState, LpTippingPointsModel> = createSelector(
  auditSelect,
  (data) => data.tippingPoints
);
const lpUpdateCompletion: Selector<IState, number> = createSelector(
  auditSelect,
  (data) => data.lpUpdateCompletion
);
const getLpAnsweringYesNoResponse: Selector<IState, LpYesNoDataModel> = createSelector(
  auditSelect,
  (data) => data.lpAnsweringYesNoResponse
);
const getLpAnsweringYesNoResponseError: Selector<IState, Object> = createSelector(
  auditSelect,
  (data) => data.error.lpAnsweringYesNoResponse
);
const getLpObservationData: Selector<IState, LpObservationDataModel[]> = createSelector(
  auditSelect,
  (data) => data.lpObservationData
);
const getLpCommentData: Selector<IState, LpCommentDataModel> = createSelector(
  auditSelect,
  (data) => data.lpCommentData
);
const getLpFeedbackData: Selector<IState, LpCommentDataModel[]> = createSelector(
  auditSelect,
  (data) => data.getLpCommentData
);

export const AuditsSelectors = {
  auditError,
  auditLoading,
  getLpAuditStoresListByStoreCodeName,
  checkLpAuditAuth,
  getLpPreviousAuditData,
  getLpSubCategoryData,
  getTippingPointsData,
  lpUpdateCompletion,
  getLpAnsweringYesNoResponseError,
  getLpAnsweringYesNoResponse,
  getLpObservationData,
  getLpCommentData,
  getLpFeedbackData
};
