import { ManagerReviewTableModel } from "@timhortons/common/src/models/logs/mainLogs";
export const managerReviewTableDataFormatter = async (
  response: any
): Promise<Array<ManagerReviewTableModel>> => {
  let formattedData: Array<ManagerReviewTableModel> = [];

  response &&
    response.map((item: any, index: number) => {
      let data: ManagerReviewTableModel = {
        date: "",
        logName: "",
        category: "",
        categoryId: "",
        itemsInAction: 0,
        completed: 0,
        autoClosed: 0,
        isApproved: null,
        shiftLabel: "",
        actionId: "",
        inComplete: 0,
        isAdminApproval: false,
        isEditable: false,
        resolvedCount: 0,
        unResolvedCount: 0
      };
      data.date = item.date;
      data.logName = item.categoryName !== null ? item.categoryName : item.shiftLabel;
      data.category = item.actionName;
      data.categoryId = item.categoryId;
      data.itemsInAction = item.itemsInActionCount;
      data.completed = item.completedCount;
      data.autoClosed = item.autoClosedCount;
      data.isApproved = item.isApproved;
      data.actionId = item.actionId;
      data.shiftLabel = item.shiftLabel;
      data.inComplete = item.inCompleteCount;
      data.resolvedCount = item.resolvedCount;
      data.unResolvedCount = item.unResolvedCount;
      data.isAdminApproval = item.isAdminApproval;
      data.isEditable = item.isEditable;
      formattedData.push(data);
    });
  return formattedData;
};
