import React from "react";
import { StyleSheet, View } from "react-native";
import OpportunityTable from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsSummaryOpportunityTable";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import ActionplanGraph from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/actionPlanGraph";
import CommonBarGraph from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/commonBarGraph";
import StoreRankingsTable from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/storeRankingsTable";
import KpiBrandStandardTable from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/kpiBrandStandardTable";
import { Roles } from "@timhortons/common/src/constants/roles";
import { inProcessReportModal } from "models/rev/reports";

interface IProps {
  translate?: any;
  user?: IUserDetails;
  gradeBarChartData?: any;
  momBarChartData?: any;
  fsDeductionGraphData?: any;
  brandStandardKPIData?: any;
  opportunityData?: any;
  storeRankingTableHeaderData?: any;
  storeRankingsTopFiveTableData?: any;
  storeRankingsLastFiveTableData?: any;
  series?: number[];
  labels?: string[];
  inProcessReport: inProcessReportModal;

  totalReports?: number;
  areaRankingTableHeaderData?: any;
  areaRankingsTableData?: any;
  actionPlanGraphHeight?: number;
  showTopAndLastFive: boolean;
  kpiHeaderTitle: string;
  areaManagerRankingTableHeaderTitle?: string;
  storeRankingTableHeaderTitle: string;
}

export default function ReportSummaryTemplate(props: IProps) {
  const {
    translate,
    user,
    gradeBarChartData,
    momBarChartData,
    fsDeductionGraphData,
    opportunityData,
    brandStandardKPIData,
    storeRankingTableHeaderData,
    storeRankingsTopFiveTableData,
    storeRankingsLastFiveTableData,
    series,
    labels,
    totalReports,
    actionPlanGraphHeight,
    showTopAndLastFive,
    areaRankingTableHeaderData,
    areaRankingsTableData,
    kpiHeaderTitle,
    areaManagerRankingTableHeaderTitle,
    storeRankingTableHeaderTitle,
    inProcessReport
  } = props;

  return (
    <View style={styles.container}>
      <View style={styles.firstElementConatiner}>
        <View style={styles.gradeGraphContainer}>
          <CommonBarGraph barChartData={gradeBarChartData} />
        </View>
        <View style={styles.momGraphContainer}>
          <CommonBarGraph barChartData={momBarChartData} />
        </View>
      </View>
      {(user.roles.includes(Roles.CountryManager) ||
        user.roles.includes(Roles.ClusterHeadofOps)) && (
        <View style={styles.opportunityTableContainer}>
          <StoreRankingsTable
            translate={translate}
            headerTitle={areaManagerRankingTableHeaderTitle}
            tableHeaderData={areaRankingTableHeaderData}
            showTopAndLastFive={false}
            topFiveTableData={areaRankingsTableData}
            lastFiveTableData={[]}
          />
        </View>
      )}
      <View style={styles.secondElementConatiner}>
        <View style={styles.actionplanGraphContainer}>
          <ActionplanGraph
            translate={translate}
            series={series}
            labels={labels}
            inProcessReport={inProcessReport}
            totalReports={totalReports}
            height={actionPlanGraphHeight}
            leftHeader={translate("Action Plans")}
            showActionItemSteps={true}
          />
        </View>
        <View style={styles.fsGraphConatiner}>
          <CommonBarGraph barChartData={fsDeductionGraphData} />
        </View>
      </View>
      <View style={styles.bsTableContainer}>
        <KpiBrandStandardTable
          translate={translate}
          brandStandardKPIData={brandStandardKPIData}
          headerTitle={kpiHeaderTitle}
        />
      </View>
      <View style={styles.opportunityTableContainer}>
        <OpportunityTable
          translate={translate}
          currentUserRoles={user && user.roles}
          opportunityData={opportunityData}
          loading={false}
        />
      </View>
      <View style={styles.storeRankingTableContainer}>
        <StoreRankingsTable
          translate={translate}
          headerTitle={storeRankingTableHeaderTitle}
          tableHeaderData={storeRankingTableHeaderData}
          showTopAndLastFive={showTopAndLastFive}
          topFiveTableData={storeRankingsTopFiveTableData}
          lastFiveTableData={storeRankingsLastFiveTableData}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colorPallete.white3,
    // borderRadius: 8,
    // padding: 30
  },
  firstElementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  secondElementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  gradeGraphContainer: {
    flex: 1
  },
  momGraphContainer: {
    flex: 1,
    marginLeft: 24
  },
  actionplanGraphContainer: {
    flex: 1
  },
  fsGraphConatiner: {
    flex: 1,
    marginLeft: 24
  },
  bsTableContainer: {
    marginBottom: 24
  },
  opportunityTableContainer: {
    marginBottom: 24
  },
  storeRankingTableContainer: {}
});
