import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "calendar";

const CALENDAR = {
  GET_ALL_EVENTS: ActionHelper.actionTypesCreator(prefix, "GET_ALL_EVENTS"),
  GET_GCC_COUNTRIES: ActionHelper.actionTypesCreator(prefix, "GET_GCC_COUNTRIES"),
  CREATE_EVENT: ActionHelper.actionTypesCreator(prefix, "CREATE_EVENT"),
  GET_AREA_COUNTRY_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_AREA_COUNTRY_MANAGER"),
  GET_STORES: ActionHelper.actionTypesCreator(prefix, "GET_STORES"),
  GET_EMPLOYEES: ActionHelper.actionTypesCreator(prefix, "GET_EMPLOYEES"),
  DELETE_EVENT: ActionHelper.actionTypesCreator(prefix, "DELETE_EVENT"),
  UPDATE_EVENT: ActionHelper.actionTypesCreator(prefix, "UPDATE_EVENT"),
  RESET_DATA: ActionHelper.actionTypesCreator(prefix, "RESET_DATA"),
  GET_EVENTBYID: ActionHelper.actionTypesCreator(prefix, "GET_EVENTID")
};

export { CALENDAR };
