import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import {
  BakingTableModel,
  ICategoryList,
  IProductList,
  PostMainMenuApiResp
} from "@timhortons/common/src/models/productionSheet/mainMenu";

export interface IMainMenuLoading {
  getAllCategoryAndProducts: boolean;
  postCategoryAndProducts: boolean;
  getAllStations: boolean;
}
export interface IMainMenuError {
  getAllCategoryAndProducts: string;
  postCategoryAndProducts: string;
  getAllStations: string;
}
export interface IMainMenuState {
  loading: IMainMenuLoading;
  error: IMainMenuError;
  getAllCategoryAndProducts: ICategoryList[] | BakingTableModel[];
  postCategoryAndProducts: PostMainMenuApiResp[];
  allStations: IProductList[];
}
export const mainMenuInitialState: IMainMenuState = {
  getAllCategoryAndProducts: null,
  postCategoryAndProducts: null,
  allStations: null,
  loading: {
    getAllCategoryAndProducts: false,
    postCategoryAndProducts: false,
    getAllStations: false
  },
  error: {
    getAllCategoryAndProducts: null,
    postCategoryAndProducts: null,
    getAllStations: null
  }
};

export const mainMenuReducer = (
  state = mainMenuInitialState,
  action: { payload: any; type: string }
): IMainMenuState => {
  switch (action.type) {
    case PRODUCTION_SHEET.MainMenu.GET_ALL_CATEGORY_AND_PRODUCTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllCategoryAndProducts: true },
        error: { ...state.error, getAllCategoryAndProducts: null }
      };
    case PRODUCTION_SHEET.MainMenu.GET_ALL_CATEGORY_AND_PRODUCTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllCategoryAndProducts: false },
        error: { ...state.error, getAllCategoryAndProducts: null },
        getAllCategoryAndProducts: action.payload
      };
    case PRODUCTION_SHEET.MainMenu.GET_ALL_CATEGORY_AND_PRODUCTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllCategoryAndProducts: false },
        error: { ...state.error, getAllCategoryAndProducts: action.payload }
      };

    case PRODUCTION_SHEET.MainMenu.POST_CATEGORY_AND_PRODUCTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postCategoryAndProducts: true },
        error: { ...state.error, postCategoryAndProducts: null }
      };
    case PRODUCTION_SHEET.MainMenu.POST_CATEGORY_AND_PRODUCTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postCategoryAndProducts: false },
        error: { ...state.error, postCategoryAndProducts: null },
        postCategoryAndProducts: action.payload
      };
    case PRODUCTION_SHEET.MainMenu.POST_CATEGORY_AND_PRODUCTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postCategoryAndProducts: false },
        error: { ...state.error, postCategoryAndProducts: action.payload }
      };
    case PRODUCTION_SHEET.MainMenu.RESET_POST_MAIN_MENU:
      return {
        ...state,
        error: {
          ...state.error,
          postCategoryAndProducts: null
        },
        postCategoryAndProducts: null
      };

    case PRODUCTION_SHEET.MainMenu.GET_ALL_STATIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllStations: true },
        error: { ...state.error, getAllStations: null }
      };
    case PRODUCTION_SHEET.MainMenu.GET_ALL_STATIONS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllStations: false },
        error: { ...state.error, getAllStations: null },
        allStations: action.payload
      };
    case PRODUCTION_SHEET.MainMenu.GET_ALL_STATIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllStations: false },
        error: { ...state.error, getAllStations: action.payload }
      };

    default:
      return state;
  }
};
