import { call, put, takeLatest } from "redux-saga/effects";
import { MANAGEASK } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  AddQuestionModal,
  FilterDataModel,
  QuestionsListModel
} from "@timhortons/common/src/models/askTell/manageAskModule";
import { Logger } from "@timhortons/common/src/services/logger";
import { QuestionsRepository } from "@timhortons/common/src/repositories/askTell/questionsModule";
import { QuestionsModuleActions } from "@timhortons/common/src/redux/askTell/actions/questionsModuleActions";

function* getQuestionsListDataWorkerSaga(value: IFluxStandardAction<FilterDataModel>) {
  const { payload } = value;
  try {
    const response: QuestionsListModel = yield call(QuestionsRepository.getQuestionsList, payload);

    yield put(QuestionsModuleActions.getAllQuestionsSuccess(response));
  } catch (error) {
    Logger.error("Manage Ask/Tell - Questions", error, "getQuestionsListDataWorkerSaga");

    yield put(QuestionsModuleActions.getAllQuestionsError(error));
  }
}
function* deleteQuestionWorkerSaga(
  value: IFluxStandardAction<{ params: FilterDataModel; questionId: string }>
) {
  const { payload } = value;
  try {
    let data: {} = yield call(QuestionsRepository.deleteQuestion, payload);
    if (data) {
      yield put(QuestionsModuleActions.deleteQuestionSuccess());
      const response: QuestionsListModel = yield call(
        QuestionsRepository.getQuestionsList,
        payload.params
      );
      if (response) {
        yield put(QuestionsModuleActions.getAllQuestionsSuccess(response));
      }
    }
  } catch (error) {
    Logger.error("Manage Ask/Tell - Questions", error, "deleteQuestionWorkerSaga");

    yield put(QuestionsModuleActions.deleteQuestionError(error));
  }
}
function* postQuestionWorkerSaga(
  value: IFluxStandardAction<{ body: AddQuestionModal; params: FilterDataModel }>
) {
  const { payload } = value;
  try {
    const response: {} = yield call(QuestionsRepository.postQuestion, payload?.body);
    if (response) {
      yield put(QuestionsModuleActions.postQuestionSuccess());
      const res: QuestionsListModel = yield call(
        QuestionsRepository.getQuestionsList,
        payload.params
      );
      if (res) {
        yield put(QuestionsModuleActions.getAllQuestionsSuccess(res));
      }
    }
  } catch (error) {
    Logger.error("Manage Ask/Tell - Questions", error, "postQuestionWorkerSaga");

    yield put(QuestionsModuleActions.postQuestionError(error));
  }
}
function* questionsWatcherSaga() {
  yield takeLatest(MANAGEASK.Questions.GET_ALL_QUESTIONS.LOADING, getQuestionsListDataWorkerSaga);
  yield takeLatest(MANAGEASK.Questions.DELETE_QUESTION.LOADING, deleteQuestionWorkerSaga);
  yield takeLatest(MANAGEASK.Questions.POST_QUESTION.LOADING, postQuestionWorkerSaga);
}

export default questionsWatcherSaga;
