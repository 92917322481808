import React, { ReactElement, useEffect, useState } from "react";
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import TextField from "@timhortons/common/src/components/atoms/textField";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontFamily } from "@timhortons/common/src/components/atoms/text";
import useDebounce from "@timhortons/web/src/utils/useDebounce";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

interface IProps {
  searchFunction: (arg1: string) => void;
  search?: boolean;
  icon?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  fieldStyle?: StyleProp<ViewStyle>;
  textFieldDimensions?: StyleProp<ViewStyle>;
  placeholder?: string;
  iconPosition?: SearchIconPositions;
  customStyles?: StyleProp<ViewStyle>;
  customTextFieldStyles?: StyleProp<ViewStyle>;
  value?: string;
  handleKeyPress?: Function;
  reset?: boolean;
  resetHandler?: Function;
  setResetHandler?: Function;
  searchIconSize?: number;
  isCancel?: boolean;
  minCharacterLength?: number;
}

export enum SearchIconPositions {
  Right = "right",
  Left = "left"
}

function SearchField(props: IProps): ReactElement {
  const {
    searchFunction,
    textFieldDimensions,
    placeholder = "Search",
    icon = true,
    search = true,
    labelStyle,
    fieldStyle,
    customStyles,
    customTextFieldStyles,
    iconPosition = SearchIconPositions.Right,
    value = "",
    reset,
    resetHandler,
    setResetHandler,
    searchIconSize = 14,
    isCancel = false,
    minCharacterLength = 3
  } = props;

  const [toggler, setToggler] = useState(false);
  const [Focus, setFocus] = useState<Object>({});
  const [steps, setSteps] = useState(value);
  const stepsDebouncedValue = useDebounce(steps, 300);

  const manageWebHighlightFocus = (styleEffect: Object) => {
    setFocus(styleEffect);
  };
  const toggle = () => {
    setToggler(!toggler);
  };

  const handleChange = (event: any) => {
    setSteps(event);
  };

  useEffect(() => {
    if (
      stepsDebouncedValue !== null &&
      stepsDebouncedValue &&
      stepsDebouncedValue.length >= minCharacterLength
    ) {
      searchFunction(stepsDebouncedValue);
    } else {
      setResetHandler && setResetHandler();
    }
  }, [stepsDebouncedValue]);

  useEffect(() => {
    if (reset) {
      setSteps("");
      resetHandler && resetHandler();
    }
  }, [reset]);

  return (
    <View style={[styles.container, textFieldDimensions]}>
      {icon && iconPosition === SearchIconPositions.Left && (
        <TouchableOpacity onPress={toggle}>
          <Icon
            testID="home"
            name={IconNames.search}
            customStyle={[styles.searchIconStyle, customStyles]}
            size={searchIconSize}
          />
        </TouchableOpacity>
      )}

      <View style={[styles.textfieldContainer, customTextFieldStyles]}>
        <TextField
          toggler={toggler}
          handleChange={(e: any) => {
            handleChange(e);
          }}
          // customChange={props.searchFunction}
          styling={{ width: "100%", outline: "none" }} //this style is for style when textfield is to be focused when cliked on icon, and it is feeded here because giving it inline will give warning in codebase with underline.
          handleBlur={() => {
            // handle blurr function
          }}
          value={steps}
          name="searchText"
          manageFocus={manageWebHighlightFocus}
          focusStyle={Focus}
          placeholder={placeholder}
          search={search}
          label="Search :"
          id="searchText"
          fieldStyle={[styles.field, fieldStyle]}
          inputStyle={styles.input}
          labelStyle={[styles.label, labelStyle]}
          handleKeyPress={(e: any) => {
            if (e.key === "Enter") {
              return props.searchFunction;
            }
          }}
        />
      </View>
      {iconPosition === SearchIconPositions.Right && icon && (
        <TouchableOpacity onPress={toggle}>
          <Icon
            testID="home"
            name={IconNames.search}
            customStyle={[styles.searchIconStyle, customStyles]}
            size={14}
          />
        </TouchableOpacity>
      )}
      {isCancel && (
        <TouchableOpacity onPress={() => resetHandler()}>
          <Icon
            testID="home"
            name={IconNames.crossCircle}
            customStyle={[styles.searchIconStyle, customStyles]}
            size={14}
          />
        </TouchableOpacity>
      )}
    </View>
  );
}

export default SearchField;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 24,
    backgroundColor: colorPallete.white1
  },
  textfieldContainer: {
    flex: 1,
    // marginStart: 16,
    ...I18nService.select({
      rtl: { marginStart: 16 },
      ltr: { marginStart: 16 }
    })
  },
  input: {
    padding: 10
    // height: 33
  },
  field: {
    paddingVertical: 7,
    fontFamily: PlatformUtils.isIOS() ? "SofiaPro" : FontFamily.Regular
    // height: 33
  },
  searchIconStyle: {
    paddingHorizontal: 10,
    color: colorPallete.grey4
  },
  label: {
    width: 43,
    height: 21,
    // marginStart: 26,
    ...I18nService.select({
      rtl: { marginStart: 26 },
      ltr: { marginStart: 26 }
    }),
    marginTop: 18
  }
});
