import { StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";

export const styles = StyleSheet.create({
  container: {
    marginBottom: 24,
    height: "100%"
  },
  dropdownHeight: {
    height: 66
  },
  headerRowStyle: {
    borderWidth: 0.5,
    borderColor: colorPallete.grey11,
    textAlign: "center",
    justifyContent: "center",
    width: 70,
    // height: PlatformUtils.isWeb() ? 50 : 49,
    height: 50,
    paddingVertical: 0
  },
  headerCell: {
    width: 240,
    justifyContent: "flex-start",
    backgroundColor: colorPallete.grey9
  },
  tableCell: {
    borderWidth: 0.5,
    borderColor: colorPallete.grey11,
    width: 240,
    justifyContent: "flex-start",
    ...I18nService.select({
      rtl: { paddingStart: 24, paddingEnd: 16 },
      ltr: { paddingStart: 24, paddingEnd: 16 }
    }),
    paddingVertical: 12,
    height: 50
  },
  shelfStyle: {
    flex: 1,
    backgroundColor: colorPallete.grey9,
    justifyContent: "flex-start"
  },
  dropDownViewStyle: {
    backgroundColor: colorPallete.variant3,
    borderRadius: 8,
    width: 200
  },
  headerButtonCell: {
    borderWidth: 0.5,
    borderColor: colorPallete.grey11,
    width: 240,
    paddingVertical: 14,
    backgroundColor: colorPallete.white2
  },
  customSelectStyle: {
    color: colorPallete.red1,
    ...I18nService.select({
      rtl: { marginStart: 8 },
      ltr: { marginStart: 8 }
    })
  },
  divider: {
    marginTop: 4
  },
  activeCell: {
    backgroundColor: colorPallete.variant4
  },
  activeChildCell: {
    backgroundColor: colorPallete.white2
  },
  rightSideCellStyle: {
    borderWidth: 0.5,
    borderColor: colorPallete.grey11,
    textAlign: "center",
    justifyContent: "center",
    width: 70,
    paddingVertical: 0
  },
  backButton: {
    width: 48,
    height: 48,
    backgroundColor: colorPallete.white1,
    borderWidth: 0,
    borderRadius: 50
  },
  backIcon: {
    color: colorPallete.grey2
  },
  elementContainer: {
    flexDirection: "row",
    alignItems: "center"
  }
});
