import { PROFILE } from "@timhortons/common/src/redux/profile/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  AllManagersDataModel,
  AssignStores,
  ChannelsDataModel,
  CountryDataModel,
  CreateStoreDataModel,
  DetailedUserInfoModel,
  DocumentDetailModel,
  EditStoreDetails,
  GetAllRolesDataModel,
  GetStoreByStoreCode,
  PostAssignStores,
  PostCreateStoreDataModel,
  PostCreateUserDataModel,
  ProfileDetailsModel,
  StoreSpecificDataModel,
  StoreTableDetailsModel,
  TeamDetailsTableModel,
  UserProfileDataModel,
  UserTableDetailsModel
} from "@timhortons/common/src/models/profile";
import { IDocumentData } from "@timhortons/common/src/modules/profile/components/detailedUserProfile";
import { StoreListModel } from "@timhortons/common/src/modules/profile/components/createEmployee/organisms/storeList";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";

const getIndividualProfile = (data: {
  userId: number;
}): IFluxStandardAction<{ userId: number }> => {
  return {
    type: PROFILE.GET_INDIVIDUAL_PROFILE.LOADING,
    payload: data
  };
};
const getIndividualProfileSuccess = (
  data: ProfileDetailsModel
): IFluxStandardAction<ProfileDetailsModel> => {
  return {
    type: PROFILE.GET_INDIVIDUAL_PROFILE.SUCCESS,
    payload: data
  };
};
const getIndividualProfileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_INDIVIDUAL_PROFILE.ERROR,
    payload: error
  };
};

const getStoreProfile = (data: { storeId: string }): IFluxStandardAction<{ storeId: string }> => {
  return {
    type: PROFILE.GET_STORE_PROFILE.LOADING,
    payload: data
  };
};

const getStoreProfileSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PROFILE.GET_STORE_PROFILE.SUCCESS,
    payload: data
  };
};

const getStoreProfileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_STORE_PROFILE.ERROR,
    payload: error
  };
};

const getStoreTeamDetails = (data: {
  storeId: number;
  pageNumber: number;
  pageSize: number;
  search: string;
}): IFluxStandardAction<{
  storeId: number;
  pageNumber: number;
  pageSize: number;
  search: string;
}> => {
  return {
    type: PROFILE.GET_STORE_TEAM_DETAILS.LOADING,
    payload: data
  };
};
const getStoreTeamDetailsSuccess = (
  data: TeamDetailsTableModel[]
): IFluxStandardAction<TeamDetailsTableModel[]> => {
  return {
    type: PROFILE.GET_STORE_TEAM_DETAILS.SUCCESS,
    payload: data
  };
};
const getStoreTeamDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_STORE_TEAM_DETAILS.ERROR,
    payload: error
  };
};

const getIndividualTeamDetails = (data: {
  pageNumber: number;
  pageSize: number;
  roleId: string;
  search: string;
  isStoresTab?: boolean;
  idList?: [string];
}): IFluxStandardAction<{
  pageNumber: number;
  pageSize: number;
  roleId: string;
  search: string;
  isStoresTab?: boolean;
  idList?: [string];
}> => {
  return {
    type: PROFILE.GET_INDIVIDUAL_TEAM_DETAILS.LOADING,
    payload: data
  };
};
const getIndividualTeamDetailsSuccess = (
  data: TeamDetailsTableModel[]
): IFluxStandardAction<TeamDetailsTableModel[]> => {
  return {
    type: PROFILE.GET_INDIVIDUAL_TEAM_DETAILS.SUCCESS,
    payload: data
  };
};
const getIndividualTeamDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_INDIVIDUAL_TEAM_DETAILS.ERROR,
    payload: error
  };
};

const getUserProfile = (data: {
  employeeId: string;
}): IFluxStandardAction<{ employeeId: string }> => {
  return {
    type: PROFILE.GET_USER_PROFILE_DETAILS.LOADING,
    payload: data
  };
};
const getUserProfileSuccess = (
  data: UserProfileDataModel
): IFluxStandardAction<UserProfileDataModel> => {
  return {
    type: PROFILE.GET_USER_PROFILE_DETAILS.SUCCESS,
    payload: data
  };
};
const getUserProfileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_USER_PROFILE_DETAILS.ERROR,
    payload: error
  };
};
const getDetailedUserInfo = (data: {
  employeeId: string;
}): IFluxStandardAction<{ employeeId: string }> => {
  return {
    type: PROFILE.GET_DETAILED_USER_INFO.LOADING,
    payload: data
  };
};

const getDetailedUserInfoSuccess = (
  data: DetailedUserInfoModel
): IFluxStandardAction<DetailedUserInfoModel> => {
  return {
    type: PROFILE.GET_DETAILED_USER_INFO.SUCCESS,
    payload: data
  };
};
const getDetailedUserInfoError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_DETAILED_USER_INFO.ERROR,
    payload: error
  };
};

////////    Create Store and user /////////

const getAllCountries = (data: { isGcc: boolean }): IFluxStandardAction<{ isGcc: boolean }> => {
  return {
    type: PROFILE.GET_ALL_COUNTRIES.LOADING,
    payload: data
  };
};

const getAllCountriesSuccess = (
  data: CountryDataModel[]
): IFluxStandardAction<CountryDataModel[]> => {
  return {
    type: PROFILE.GET_ALL_COUNTRIES.SUCCESS,
    payload: data
  };
};
const getAllCountriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_ALL_COUNTRIES.ERROR,
    payload: error
  };
};

const getAllChannels = (): IFluxStandardAction => {
  return {
    type: PROFILE.GET_ALL_CHANNELS.LOADING
  };
};

const getAllChannelsSuccess = (data: ChannelsDataModel): IFluxStandardAction<ChannelsDataModel> => {
  return {
    type: PROFILE.GET_ALL_CHANNELS.SUCCESS,
    payload: data
  };
};
const getAllChannelsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_ALL_CHANNELS.ERROR,
    payload: error
  };
};

const getStoreSpecifics = (): IFluxStandardAction => {
  return {
    type: PROFILE.GET_STORE_SPECIFICS.LOADING
  };
};

const getStoreSpecificsSuccess = (
  data: StoreSpecificDataModel
): IFluxStandardAction<StoreSpecificDataModel> => {
  return {
    type: PROFILE.GET_STORE_SPECIFICS.SUCCESS,
    payload: data
  };
};
const getStoreSpecificsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_STORE_SPECIFICS.ERROR,
    payload: error
  };
};

const getAllCities = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_ALL_CITIES.LOADING,
    payload: data
  };
};

const getAllCitiesSuccess = (
  data: CreateStoreDataModel
): IFluxStandardAction<CreateStoreDataModel> => {
  return {
    type: PROFILE.GET_ALL_CITIES.SUCCESS,
    payload: data
  };
};
const getAllCitiesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_ALL_CITIES.ERROR,
    payload: error
  };
};

const getAllAreaManager = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_ALL_AREA_MANAGER.LOADING,
    payload: data
  };
};

const getAllAreaManagerSuccess = (data: {
  // areaTrainer: AllManagersDataModel;
  areaManager: AllManagersDataModel;
  // opsManager: AllManagersDataModel;
  // countryManager: AllManagersDataModel;
}): IFluxStandardAction<{
  // areaTrainer: AllManagersDataModel;
  areaManager: AllManagersDataModel;
  // opsManager: AllManagersDataModel;
  // countryManager: AllManagersDataModel;
}> => {
  return {
    type: PROFILE.GET_ALL_AREA_MANAGER.SUCCESS,
    payload: data
  };
};
const getAllAreaManagerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_ALL_AREA_MANAGER.ERROR,
    payload: error
  };
};

const getAllOpsManager = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_OPS_MANAGER.LOADING,
    payload: data
  };
};

const getAllOpsManagerSuccess = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_OPS_MANAGER.SUCCESS,
    payload: data
  };
};
const getAllOpsManagerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_OPS_MANAGER.ERROR,
    payload: error
  };
};

const getAllCountryManager = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_COUNTRY_MANAGER.LOADING,
    payload: data
  };
};

const getAllCountryManagerSuccess = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_COUNTRY_MANAGER.SUCCESS,
    payload: data
  };
};
const getAllCountryManagerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_COUNTRY_MANAGER.ERROR,
    payload: error
  };
};

const getAllAreaTrainer = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_AREA_TRAINER.LOADING,
    payload: data
  };
};

const getAllAreaTrainerSuccess = (data: {
  countryCode: number;
}): IFluxStandardAction<{ countryCode: number }> => {
  return {
    type: PROFILE.GET_AREA_TRAINER.SUCCESS,
    payload: data
  };
};
const getAllAreaTrainerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_AREA_TRAINER.ERROR,
    payload: error
  };
};

const getAllRoles = (): IFluxStandardAction => {
  return {
    type: PROFILE.GET_ALL_ROLES.LOADING
  };
};

const getAllRolesSuccess = (data: {
  allRolesData: GetAllRolesDataModel;
  formattedRolesData: any;
}): IFluxStandardAction<{
  allRolesData: GetAllRolesDataModel;
  formattedRolesData: any;
}> => {
  return {
    type: PROFILE.GET_ALL_ROLES.SUCCESS,
    payload: data
  };
};
const getAllRolesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_ALL_ROLES.ERROR,
    payload: error
  };
};

const postCreateStore = (data: {
  formData: PostCreateStoreDataModel;
  files: any;
  isEdit: boolean;
}): IFluxStandardAction<{ formData: PostCreateStoreDataModel; files: any; isEdit: boolean }> => {
  return {
    type: PROFILE.POST_CREATE_STORE.LOADING,
    payload: data
  };
};
const postCreateStoreSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PROFILE.POST_CREATE_STORE.SUCCESS,
    payload: data
  };
};
const postCreateStoreError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.POST_CREATE_STORE.ERROR,
    payload: error
  };
};

const postCreateUser = (data: {
  formData: PostCreateUserDataModel;
  files: FormData;
  isEdit: boolean;
}): IFluxStandardAction<{
  formData: PostCreateUserDataModel;
  files: FormData;
  isEdit: boolean;
}> => {
  return {
    type: PROFILE.POST_CREATE_USER.LOADING,
    payload: data
  };
};
const postCreateUserSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PROFILE.POST_CREATE_USER.SUCCESS,
    payload: data
  };
};
const postCreateUserError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.POST_CREATE_USER.ERROR,
    payload: error
  };
};
const getStoreTableDetails = (data: {
  search: string;
  pageNumber: number;
  pageSize: number;
}): IFluxStandardAction<{ search: string; pageNumber: number; pageSize: number }> => {
  return {
    type: PROFILE.GET_STORE_TABLE_DETAILS.LOADING,
    payload: data
  };
};
const getStoreTableDetailsSuccess = (
  data: StoreTableDetailsModel[]
): IFluxStandardAction<StoreTableDetailsModel[]> => {
  return {
    type: PROFILE.GET_STORE_TABLE_DETAILS.SUCCESS,
    payload: data
  };
};
const getStoreTableDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_STORE_TABLE_DETAILS.ERROR,
    payload: error
  };
};
const getUserTableDetails = (data: {
  search: string;
  pageNumber: number;
  pageSize: number;
}): IFluxStandardAction<{ search: string; pageNumber: number; pageSize: number }> => {
  return {
    type: PROFILE.GET_USER_TABLE_DETAILS.LOADING,
    payload: data
  };
};
const getUserTableDetailsSuccess = (
  data: UserTableDetailsModel
): IFluxStandardAction<UserTableDetailsModel> => {
  return {
    type: PROFILE.GET_USER_TABLE_DETAILS.SUCCESS,
    payload: data
  };
};
const getUserTableDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_USER_TABLE_DETAILS.ERROR,
    payload: error
  };
};
//////////////// Employee documents and certificates ////////////////

const getEmployeeDocument = (data: {
  empId: string;
  docType: string;
  docSide: string;
}): IFluxStandardAction<{ empId: string; docType: string; docSide: string }> => {
  return {
    type: PROFILE.GET_EMPLOYEE_DOCUMENT.LOADING,
    payload: data
  };
};

const getEmployeeDocumentSuccess = (
  data: DocumentDetailModel
): IFluxStandardAction<DocumentDetailModel> => {
  return {
    type: PROFILE.GET_EMPLOYEE_DOCUMENT.SUCCESS,
    payload: data
  };
};
const getEmployeeDocumentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_EMPLOYEE_DOCUMENT.ERROR,
    payload: error
  };
};

const getEmployeeCertificate = (data: {
  empId: string;
  docType: string;
}): IFluxStandardAction<{ empId: string; docType: string }> => {
  return {
    type: PROFILE.GET_EMPLOYEE_CERTIFICATE.LOADING,
    payload: data
  };
};

const getEmployeeCertificateSuccess = (
  data: DocumentDetailModel
): IFluxStandardAction<DocumentDetailModel> => {
  return {
    type: PROFILE.GET_EMPLOYEE_CERTIFICATE.SUCCESS,
    payload: data
  };
};
const getEmployeeCertificateError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_EMPLOYEE_CERTIFICATE.ERROR,
    payload: error
  };
};

const getEmployeeCountDocument = (data: {
  empId: string;
}): IFluxStandardAction<{ empId: string }> => {
  return {
    type: PROFILE.GET_EMPLOYEE_COUNT_DOCUMENT.LOADING,
    payload: data
  };
};

const getEmployeeCountDocumentSuccess = (
  data: IDocumentData[]
): IFluxStandardAction<IDocumentData[]> => {
  return {
    type: PROFILE.GET_EMPLOYEE_COUNT_DOCUMENT.SUCCESS,
    payload: data
  };
};
const getEmployeeCountDocumentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_EMPLOYEE_COUNT_DOCUMENT.ERROR,
    payload: error
  };
};

const getEmployeeCountCertificate = (data: {
  empId: string;
}): IFluxStandardAction<{ empId: string }> => {
  return {
    type: PROFILE.GET_EMPLOYEE_COUNT_CERTIFICATE.LOADING,
    payload: data
  };
};

const getEmployeeCountCertificateSuccess = (
  data: IDocumentData[]
): IFluxStandardAction<IDocumentData[]> => {
  return {
    type: PROFILE.GET_EMPLOYEE_COUNT_CERTIFICATE.SUCCESS,
    payload: data
  };
};
const getEmployeeCountCertificateError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_EMPLOYEE_COUNT_CERTIFICATE.ERROR,
    payload: error
  };
};

const postEmployeeDocument = (data: {
  data: FormData;
  empId: string;
}): IFluxStandardAction<{ data: FormData; empId: string }> => {
  return {
    type: PROFILE.POST_EMPLOYEE_DOCUMENT.LOADING,
    payload: data
  };
};

const postEmployeeDocumentSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PROFILE.POST_EMPLOYEE_DOCUMENT.SUCCESS,
    payload: data
  };
};
const postEmployeeDocumentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.POST_EMPLOYEE_DOCUMENT.ERROR,
    payload: error
  };
};

const getStoreByStoreCode = (data: {
  storeCode: number;
}): IFluxStandardAction<{ storeCode: number }> => {
  return {
    type: PROFILE.GET_STORE_BY_STORECODE.LOADING,
    payload: data
  };
};

const getStoreByStoreCodeSuccess = (
  data: GetStoreByStoreCode
): IFluxStandardAction<GetStoreByStoreCode> => {
  return {
    type: PROFILE.GET_STORE_BY_STORECODE.SUCCESS,
    payload: data
  };
};

const getStoreByStoreCodeError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_STORE_BY_STORECODE.ERROR,
    payload: error
  };
};

const getAllStoresList = (data: {
  data: AssignStores;
  user: IUserDetails;
  fromLms: Boolean;
}): IFluxStandardAction<{ data: AssignStores; user: IUserDetails }> => {
  return {
    type: PROFILE.GET_ALL_STORES_LIST.LOADING,
    payload: data
  };
};

const getAllStoresListSuccess = (data: {
  totalRecords: number;
  storeList: StoreListModel[];
}): IFluxStandardAction<{ totalRecords: number; storeList: StoreListModel[] }> => {
  return {
    type: PROFILE.GET_ALL_STORES_LIST.SUCCESS,
    payload: data
  };
};

const getAllStoresListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_ALL_STORES_LIST.ERROR,
    payload: error
  };
};

const postAssignedStores = (data: {
  empId: string;
  postData: PostAssignStores[];
  isAllSelected?: boolean;
  roleId?: string;
  countryCode?: string;
  cityId?: string;
  channelId?: string;
}): IFluxStandardAction<{
  empId: string;
  postData: PostAssignStores[];
  isAllSelected?: boolean;
  roleId?: string;
  countryCode?: string;
  cityId?: string;
  channelId?: string;
}> => {
  return {
    type: PROFILE.POST_ASSIGN_STORES.LOADING,
    payload: data
  };
};

const postAssignedStoresSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PROFILE.POST_ASSIGN_STORES.SUCCESS,
    payload: data
  };
};

const postAssignedStoresError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.POST_ASSIGN_STORES.ERROR,
    payload: error
  };
};

const resetContactError = () => {
  return {
    type: PROFILE.RESET_CONTACT_INFO
  };
};
const resetPostCalls = () => {
  return {
    type: PROFILE.RESET_POST_CALLS
  };
};

const resetCreateStore = () => {
  return {
    type: PROFILE.RESET_CREATE_STORE
  };
};
const resetEndReached = () => {
  return {
    type: PROFILE.RESET_END_REACHED
  };
};

const getEditStoreDetails = (data: {
  storeId: number;
}): IFluxStandardAction<{ storeId: number }> => {
  return {
    type: PROFILE.GET_EDIT_STORE_DETAILS.LOADING,
    payload: data
  };
};

const getEditStoreDetailsSuccess = (
  data: EditStoreDetails
): IFluxStandardAction<EditStoreDetails> => {
  return {
    type: PROFILE.GET_EDIT_STORE_DETAILS.SUCCESS,
    payload: data
  };
};

const getEditStoreDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_EDIT_STORE_DETAILS.ERROR,
    payload: error
  };
};

const getGccCountries = (): IFluxStandardAction => {
  return {
    type: PROFILE.GET_GCC_COUNTRIES.LOADING
  };
};

const getGccCountriesSuccess = (
  data: CountryDataModel[]
): IFluxStandardAction<CountryDataModel[]> => {
  return {
    type: PROFILE.GET_GCC_COUNTRIES.SUCCESS,
    payload: data
  };
};
const getGccCountriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROFILE.GET_GCC_COUNTRIES.ERROR,
    payload: error
  };
};
const resetStoreDetails = (): IFluxStandardAction<{}> => {
  return {
    type: PROFILE.RESET_STORE_CALLS,
    payload: {}
  };
};

export const ProfilesAction = {
  getIndividualProfile,
  getIndividualProfileSuccess,
  getIndividualProfileError,
  getStoreTeamDetails,
  getStoreTeamDetailsSuccess,
  getStoreTeamDetailsError,
  getIndividualTeamDetails,
  getIndividualTeamDetailsSuccess,
  getIndividualTeamDetailsError,
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileError,
  getStoreProfile,
  getStoreProfileSuccess,
  getStoreProfileError,
  getDetailedUserInfo,
  getDetailedUserInfoSuccess,
  getDetailedUserInfoError,
  resetPostCalls,
  resetContactError,
  getAllCountries,
  getAllCountriesSuccess,
  getAllCountriesError,
  getAllChannels,
  getAllChannelsSuccess,
  getAllChannelsError,
  getStoreSpecifics,
  getStoreSpecificsSuccess,
  getStoreSpecificsError,
  getAllCities,
  getAllCitiesSuccess,
  getAllCitiesError,
  getAllAreaManager,
  getAllAreaManagerSuccess,
  getAllAreaManagerError,
  getAllOpsManager,
  getAllOpsManagerSuccess,
  getAllOpsManagerError,
  getAllCountryManager,
  getAllCountryManagerSuccess,
  getAllCountryManagerError,
  getAllAreaTrainer,
  getAllAreaTrainerSuccess,
  getAllAreaTrainerError,
  getAllRoles,
  getAllRolesSuccess,
  getAllRolesError,
  postCreateStore,
  postCreateStoreSuccess,
  postCreateStoreError,
  postCreateUser,
  postCreateUserSuccess,
  postCreateUserError,
  getStoreTableDetails,
  getStoreTableDetailsSuccess,
  getStoreTableDetailsError,
  getUserTableDetails,
  getUserTableDetailsSuccess,
  getUserTableDetailsError,

  getEmployeeDocument,
  getEmployeeDocumentSuccess,
  getEmployeeDocumentError,
  getEmployeeCertificate,
  getEmployeeCertificateSuccess,
  getEmployeeCertificateError,
  postEmployeeDocument,
  postEmployeeDocumentSuccess,
  postEmployeeDocumentError,
  getEmployeeCountDocument,
  getEmployeeCountDocumentSuccess,
  getEmployeeCountDocumentError,
  getEmployeeCountCertificate,
  getEmployeeCountCertificateSuccess,
  getEmployeeCountCertificateError,
  getStoreByStoreCode,
  getStoreByStoreCodeSuccess,
  getStoreByStoreCodeError,
  getAllStoresList,
  getAllStoresListSuccess,
  getAllStoresListError,
  postAssignedStores,
  postAssignedStoresSuccess,
  postAssignedStoresError,
  getEditStoreDetails,
  getEditStoreDetailsSuccess,
  getEditStoreDetailsError,
  resetCreateStore,
  resetEndReached,
  getGccCountries,
  getGccCountriesSuccess,
  getGccCountriesError,
  resetStoreDetails
};
