import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import {
  CategoryStatusModel,
  AppealCountModel,
  InProcesssClosedTable
} from "@timhortons/common/src/models/rev/appeals";
import {
  AppealsAccordionDataModel,
  NewAppealsTable,
  // InProcessAppealTableModel,
  CreateNewAppeal,
  AppealsCategoryDataModel,
  AppealCommentsDataModel
} from "models/rev/appeals";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { ActionPlanCategoryDataModel } from "@timhortons/common/src/models/rev/actionPlan";

export interface IAppealsLoading {
  getAppealsCount: boolean;
  getNewAppeals: boolean;
  getInProcessAppeals: boolean;
  getClosedAppeals: boolean;
  getCategoryStatus: boolean;
  getAppealAccordianData: boolean;
  setAppealsDecision: boolean;
  filteredData: boolean;
  closedData: boolean;
  commentData: boolean;
  createNewAppeal: boolean;
  appealsHeaderData: boolean;
  appealsCategoryData: boolean;
}
export interface IAppealsError {
  getAppealsCount: string;
  getNewAppeals: string;
  getCategoryStatus: string;
  getInProcessAppeals: string;
  getClosedAppeals: string;
  getAppealAccordianData: string;
  setAppealsDecision: string;
  filteredData: string;
  closedData: string;
  commentData: string;
  createNewAppeal: string;
  appealsHeaderData: string;
  appealsCategoryData: string;
}
export interface IAppealsState {
  appealsCount: AppealCountModel;
  newAppeals: NewAppealsTable[];
  categoryTable: CategoryStatusModel;
  inProcessAppeals: InProcesssClosedTable[];
  filteredData: InProcesssClosedTable[]; //variale contain the appeal individial data
  closedData: InProcesssClosedTable;
  closedAppeals: InProcesssClosedTable[];
  appealAccordianData: AppealsAccordionDataModel[];
  loading: IAppealsLoading;
  error: IAppealsError;
  appealsDecision: boolean;
  setAppealsDecision: any;
  commentData: AppealCommentsDataModel;
  createNewAppealData: CreateNewAppeal;
  appealsHeaderData: AppealsCategoryDataModel;
  appealsCategoryData: ActionPlanCategoryDataModel[];
}
export const appealsInitialState: IAppealsState = {
  appealsCount: null,
  newAppeals: [],
  inProcessAppeals: [],
  categoryTable: null,
  closedAppeals: [],
  appealAccordianData: null,
  appealsDecision: null,
  setAppealsDecision: null,
  closedData: null,
  filteredData: null,
  commentData: null,
  createNewAppealData: null,
  appealsHeaderData: null,
  appealsCategoryData: null,
  loading: {
    createNewAppeal: false,
    getAppealsCount: false,
    getNewAppeals: false,
    getCategoryStatus: false,
    filteredData: false,
    getInProcessAppeals: false,
    getClosedAppeals: false,
    getAppealAccordianData: false,
    setAppealsDecision: false,
    closedData: false,
    commentData: false,
    appealsHeaderData: false,
    appealsCategoryData: false
  },
  error: {
    createNewAppeal: null,
    getAppealsCount: null,
    getNewAppeals: null,
    getCategoryStatus: null,
    getInProcessAppeals: null,
    filteredData: null,
    getClosedAppeals: null,
    getAppealAccordianData: null,
    setAppealsDecision: null,
    closedData: null,
    commentData: null,
    appealsHeaderData: null,
    appealsCategoryData: null
  }
};
export const appealsReducer = (
  state = appealsInitialState,
  action: { payload: any; type: string }
): IAppealsState => {
  switch (action.type) {
    case REV.GET_APPEALS_COUNT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAppealsCount: true },
        error: { ...state.error, getAppealsCount: null }
      };
    case REV.GET_APPEALS_COUNT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAppealsCount: false },
        error: { ...state.error, getAppealsCount: null },
        appealsCount: action.payload
      };
    case REV.GET_APPEALS_COUNT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAppealsCount: false },
        error: { ...state.error, getAppealsCount: action.payload }
      };
    case REV.GET_NEW_APPEALS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getNewAppeals: true },
        error: { ...state.error, getNewAppeals: null }
      };
    case REV.GET_NEW_APPEALS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getNewAppeals: false },
        error: { ...state.error, getNewAppeals: null },
        newAppeals: action.payload
      };
    case REV.GET_NEW_APPEALS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getNewAppeals: false },
        error: { ...state.error, getNewAppeals: action.payload }
      };

    case REV.GET_IN_PROCESS_APPEALS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getInProcessAppeals: true },
        error: { ...state.error, getInProcessAppeals: null }
      };
    case REV.GET_IN_PROCESS_APPEALS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getInProcessAppeals: false },
        error: { ...state.error, getInProcessAppeals: null },
        inProcessAppeals: action.payload
      };
    case REV.GET_IN_PROCESS_APPEALS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getInProcessAppeals: false },
        error: { ...state.error, getInProcessAppeals: action.payload }
      };

    case REV.GET_CLOSED_APPEALS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getClosedAppeals: true },
        error: { ...state.error, getClosedAppeals: null }
      };
    case REV.GET_CLOSED_APPEALS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getClosedAppeals: false },
        error: { ...state.error, getClosedAppeals: null },
        closedAppeals: action.payload
      };
    case REV.GET_CLOSED_APPEALS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getClosedAppeals: false },
        error: { ...state.error, getClosedAppeals: action.payload }
      };

    case REV.GET_APPEAL_ACCORDION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAppealAccordianData: true },
        error: { ...state.error, getAppealAccordianData: "" }
      };
    case REV.GET_APPEAL_ACCORDION_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAppealAccordianData: false },
        error: { ...state.error, getAppealAccordianData: "" },
        appealAccordianData: action.payload
      };
    case REV.GET_APPEAL_ACCORDION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAppealAccordianData: false },
        error: { ...state.error, getAppealAccordianData: action.payload }
      };
    case REV.SET_APPEAL_DECISON_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, setAppealsDecision: true },
        error: { ...state.error, setAppealsDecision: null },
        appealsDecision: null
      };
    case REV.SET_APPEAL_DECISON_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, setAppealsDecision: false },
        error: { ...state.error, setAppealsDecision: null },
        appealsDecision: true
      };
    case REV.SET_APPEAL_DECISON_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, setAppealsDecision: false },
        error: { ...state.error, setAppealsDecision: action.payload },
        appealsDecision: null
      };

    case REV.SET_APPEAL_FILTER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, filteredData: true },
        error: { ...state.error, filteredData: null },
        appealsDecision: null
      };
    case REV.SET_APPEAL_FILTER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, filteredData: false },
        error: { ...state.error, filteredData: null },
        filteredData: action.payload
      };
    case REV.SET_APPEAL_FILTER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, filteredData: false },
        error: { ...state.error, filteredData: action.payload }
      };
    case REV.SET_CLOSED_APPEAL_FILTER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, closedData: true },
        error: { ...state.error, closedData: null }
      };
    case REV.SET_CLOSED_APPEAL_FILTER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, closedData: false },
        error: { ...state.error, closedData: null },
        closedData: action.payload
      };
    case REV.SET_CLOSED_APPEAL_FILTER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, closedData: false },
        error: { ...state.error, closedData: action.payload }
      };
    case REV.POST_APPEAL_COMMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, commentData: true },
        error: { ...state.error, commentData: null }
      };
    case REV.POST_APPEAL_COMMENT_DATA.SUCCESS:
      let data = state.appealAccordianData;
      let dataIndex = data.findIndex(
        (object) =>
          object.opportunityList[0].subCategoryId === action.payload.appealItem.subCategoryId
      );
      if (dataIndex > -1) {
        data[dataIndex].appealerResponse.comment = action.payload.appealItem.commentByAppealer;
        data[dataIndex].appealerResponse.hasAppealed = action.payload.appealItem.hasAppealed;
        data[dataIndex].appealerResponse.file = action.payload.appealItem.appealItemAssets;
      }

      return {
        ...state,
        loading: { ...state.loading, commentData: false },
        error: { ...state.error, commentData: null },
        commentData: action.payload,
        appealAccordianData: data
      };
    case REV.POST_APPEAL_COMMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, commentData: false },
        error: { ...state.error, commentData: action.payload }
      };
    case REV.Appeals.CREATE_NEW_APPEAL.LOADING:
      return {
        ...state,
        loading: { ...state.loading, createNewAppeal: true },
        error: { ...state.error, createNewAppeal: null }
      };
    case REV.Appeals.CREATE_NEW_APPEAL.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createNewAppeal: false },
        error: { ...state.error, createNewAppeal: null },
        createNewAppealData: action.payload
      };
    case REV.Appeals.CREATE_NEW_APPEAL.ERROR:
      return {
        ...state,
        loading: { ...state.loading, createNewAppeal: false },
        error: { ...state.error, createNewAppeal: action.payload }
      };
    case REV.GET_APPEALS_CATEGORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, appealsCategoryData: true, appealsHeaderData: true },
        error: { ...state.error, appealsCategoryData: null, appealsHeaderData: null }
      };
    case REV.GET_APPEALS_CATEGORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, appealsCategoryData: false, appealsHeaderData: false },
        appealsHeaderData: action.payload,
        appealsCategoryData: action.payload.categories
      };
    case REV.GET_APPEALS_CATEGORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, appealsCategoryData: false, appealsHeaderData: false },
        error: {
          ...state.error,
          appealsHeaderData: action.payload,
          appealsCategoryData: action.payload
        }
      };
    case REV.RESET_APPEAL_AUTH_ID:
      LocalStorage.setStorage("appealAuth", "not authorized");
      state = appealsInitialState;
      return state;
    case REV.Appeals.RESET_APPEAL_ERROR:
      return {
        ...state,
        error: { ...state.error, commentData: null }
      };
    default:
      return state;
  }
};
