import {
  productionSheetEndPoints,
  selfCalibrationEndPoints
} from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { ProductionSheetKeys, REVKeys } from "@timhortons/common/src/constants/apiKeys";
import {
  IGetManagerReviewPayload,
  ManagerReviewDataModel,
  ManagerReviewModel,
  ManagerReviewTableDataModel
} from "@timhortons/common/src/models/productionSheet/managerReview";

export interface TableModel {
  id: number;
  productsNumber: number;
  bakingDailyWaste: number;
  bakingThrows: number;
  bakingTotalWaste: number;
  sandwichDailyWaste: number;
  sandwichThrows: number;
  sandwichTotalWaste: number;
  date: string;
  isEdit: boolean;
}

function formatData(item: any, index: number) {
  let obj: ManagerReviewTableDataModel = {
    noOfProducts: null,
    date: "",
    entryTime: "",
    id: null,
    stationIds: [],
    isChecked: false,
    isApproved: null,
    isApprovable: null,
    isEditable: null,
    data: []
  };
  obj.date = item.date;
  // obj.noOfProducts = item.totalProduction;
  obj.entryTime = item.entryTime;
  obj.isApproved = item.isApproved;
  obj.isEditable = item.isEditable;
  obj.isApprovable = item.isAdminApproval;
  let ids: string[] = [];
  item.stations.map((el: any, i: number) => {
    let element: ManagerReviewDataModel = {
      totalProduction: null,
      dailyWaste: null,
      totalWaste: null,
      endOfDayThrows: null
    };
    ids.push(el.id);
    element.totalProduction = el.totalProduction;
    element.dailyWaste = el.dailyWaste;
    element.totalWaste = el.totalWaste;
    element.endOfDayThrows = el.endOfDayThrows;
    obj.stationIds = ids;
    obj.id = index;
    obj.data.push(element);
  });
  return obj;
}

function managerReviewDataFormatter(data: any) {
  let dataToSend: ManagerReviewModel;
  let abc: any = [];
  data &&
    data.content.map((item: any, index: number) => {
      let obj = formatData(item, index);
      abc.push(obj);
    });
  dataToSend = {
    totalRecords: data.totalElements,
    records: abc
  };
  return dataToSend;
}

class ManagerReviewProductionSheetRepository {
  getManagerReviewTableData = async (params: IGetManagerReviewPayload) => {
    const { storeCode, timeZone, pageNo, pageSize } = params;
    let response = await ApiClient.get(
      productionSheetEndPoints.getManagerReviewData(storeCode),
      { timeZone, pageNo, pageSize },
      ProductionSheetKeys.MAIN_MENU
    );
    return managerReviewDataFormatter(response);
  };
  updateManagerReviewData = async (param: {
    idList: Array<string>;
    empId: string;
    dateTime: string;
    rowId: number;
  }) => {
    const { idList, empId, dateTime } = param;
    await ApiClient.put(
      productionSheetEndPoints.updateManagerReviewItem(),
      {},
      { idList: idList.join(","), isApprovedBy: empId, dateTime },
      ProductionSheetKeys.MAIN_MENU
    );
  };
  checkAuthToStore = async (param: { storeCode: number; employeeId: string }) => {
    const { storeCode, employeeId } = param;
    let response = await ApiClient.get(
      selfCalibrationEndPoints.storeAccessValidation(),
      {
        storeCode,
        employeeId,
        managerAccess: false
      },
      REVKeys.TEST_AUDIT
    );
    return response;
  };
}

const managerReviewProductionSheetRepository = new ManagerReviewProductionSheetRepository();
export { managerReviewProductionSheetRepository as ManagerReviewProductionSheetRepository };
