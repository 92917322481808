import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import { manageLmsEndPoints } from "@timhortons/common/src/constants/endPoints";
import {
  LmsModulePtsDataModel,
  LmsPtsTopicDataModel,
  LmsPtsTopicResponseModel,
  LmsPtsCheckListDataModel,
  LmsPtsCheckListResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";
import { ptsModuleDataFormatter } from "@timhortons/common/src/repositories/manageLms/formatters/lmsPtsFormatter";

class LmsPtsRepository {
  getLmsPtsModuleData = async (data: { moduleId: string }): Promise<LmsModulePtsDataModel[]> => {
    const { moduleId } = data;
    let response: LmsModulePtsDataModel[] = await ApiClient.get(
      manageLmsEndPoints.pts.getLmsPtsModuleData(moduleId),
      {},
      LMSKeys.LMS_MANAGE
    );

    return ptsModuleDataFormatter(response);
    // return deserialize(LmsFeedbackResponseModel, getFeedbackDataFormatter(response));
  };
  postLmsPtsTopic = async (data: LmsPtsTopicDataModel): Promise<LmsPtsTopicResponseModel> => {
    const {} = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.post(
      manageLmsEndPoints.pts.lmsPtsTopic(),
      data,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  updateLmsPtsTopic = async (data: {
    topicId: string;
    obj: LmsPtsTopicDataModel;
  }): Promise<LmsPtsTopicResponseModel> => {
    const { topicId, obj } = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.put(
      manageLmsEndPoints.pts.lmsPtsTopicId(topicId),
      obj,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteLmsPtsTopic = async (data: { topicId: string }): Promise<LmsPtsTopicResponseModel> => {
    const { topicId } = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.delete(
      manageLmsEndPoints.pts.lmsPtsTopicId(topicId),
      null,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };

  postLmsPtsCheckList = async (
    data: LmsPtsCheckListDataModel
  ): Promise<LmsPtsCheckListResponseModel> => {
    const {} = data;
    let response: LmsPtsCheckListResponseModel = await ApiClient.post(
      manageLmsEndPoints.pts.lmsPtsCheckList(),
      data,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  updateLmsPtsCheckList = async (data: {
    checkListId: string;
    obj: LmsPtsCheckListDataModel;
  }): Promise<LmsPtsTopicResponseModel> => {
    const { checkListId, obj } = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.put(
      manageLmsEndPoints.pts.lmsPtsCheckListId(checkListId),
      obj,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteLmsPtsCheckList = async (data: {
    checkListId: string;
  }): Promise<LmsPtsCheckListResponseModel> => {
    const { checkListId } = data;
    let response: LmsPtsCheckListResponseModel = await ApiClient.delete(
      manageLmsEndPoints.pts.lmsPtsCheckListId(checkListId),
      null,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
}

const lmsPtsRepository = new LmsPtsRepository();
export { lmsPtsRepository as LmsPtsRepository };
