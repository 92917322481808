import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { View, StyleSheet, StyleProp, TextStyle, TouchableOpacity } from "react-native";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { FontWeight, TextSize, Text } from "@timhortons/common/src/components/atoms/text";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FileModel } from "@timhortons/common/src/models/rev/selfCalibration";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";

interface IProps {
  handleFileChange?: Function;
  initialFile?: FileModel;
  disabled?: boolean;
  reset?: number;
}

export function SaiGlobalUpload(props: IProps) {
  const { handleFileChange, initialFile, disabled, reset } = props;

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalError, setModalError] = useState(null);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive, open, isDragReject } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((data: any) => {
        let errors;
        data.errors.forEach((err: any) => {
          errors.push(`Cannot upload ${data.file.name}. ${err.message}`);
        });
        setModalError(errors);
        setShowErrorModal(true);
      });
      let newObject: FileModel[] = [];
      acceptedFiles.forEach((file: File) => {
        newObject.push({
          id: file.name + file.size,
          signedUrl: URL.createObjectURL(file),
          blob: file
        });
      });
      setFiles(newObject);
    },
    accept: "application/pdf",
    multiple: false,
    disabled: disabled
  });
  useEffect(() => {
    if (reset > 0) {
      setFiles([]);
    }
  }, [reset]);
  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    handleFileChange && handleFileChange(files);
  }, [files]);

  useEffect(() => {
    if (initialFile) {
      let finalFiles = [];
      finalFiles.push(initialFile);
      setFiles(finalFiles);
    } else {
      setFiles([]);
    }
  }, [initialFile]);

  const getDragContainerUI = (
    iconColor: string,
    text: string,
    textStyle: StyleProp<TextStyle>,
    iconName: IconNames
  ) => {
    return (
      <View style={styles.uploadContainer}>
        <Icon name={iconName} color={iconColor} size={16} />
        <Text
          textSize={TextSize.Regular}
          fontWeight={FontWeight.Regular}
          testId="tets"
          textStyle={textStyle}
        >
          {text}
        </Text>
      </View>
    );
  };

  const callDragMultiple = () => {
    if (isDragActive) {
      if (isDragReject) {
        return (
          <>
            {getDragContainerUI(
              colorPallete.variant10,
              "This file type is not supported",
              styles.errorText,
              IconNames.clock
            )}
          </>
        );
      } else {
        return (
          <>
            {getDragContainerUI(
              colorPallete.red1,
              "Drop it in",
              styles.dragTextActive,
              IconNames.clock
            )}
          </>
        );
      }
    } else {
      return (
        <>
          {getDragContainerUI(
            colorPallete.grey1,
            "or drag and drop your files",
            styles.dragText,
            IconNames.clock
          )}
        </>
      );
    }
  };

  const thumbs = files.map((file, index) => (
    <View style={styles.uploadContainer} key={index}>
      <View style={styles.iconContainer}>
        <Icon name={IconNames.docUpld} color={colorPallete.red1} />
      </View>
      <View>
        <Text
          textSize={TextSize.ExtraSmall}
          fontWeight={FontWeight.SemiBold}
          testId="tets"
          textStyle={styles.fileText}
        >
          {file.blob.name}
        </Text>
        <Text
          textSize={TextSize.ExtraFineSmall}
          fontWeight={FontWeight.Regular}
          testId="tets"
          textStyle={styles.fileTextInActive}
        >
          {(file.blob.size * 0.001).toFixed(2) + " Kb"}
        </Text>
      </View>
    </View>
  ));

  return (
    <View style={[styles.container, disabled && styles.disabledContainer]}>
      <Button
        type={ButtonType.Primary}
        title="Upload"
        iconName={IconNames.docUpld}
        onPress={open}
        textStyles={styles.uploadButtonText}
        buttonStyles={styles.uploadButton}
        disabled={disabled}
      />
      <Divider orientation={Orientations.Vertical} dividerStyle={styles.divider} />
      {files?.length > 0 ? (
        <View style={styles.fileContainer}>
          <View>{thumbs}</View>
          <TouchableOpacity onPress={() => setFiles([])}>
            <Icon name={IconNames.trash} color={colorPallete.grey2} />
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.fileContainer}>
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center"
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            {callDragMultiple()}
          </div>
        </View>
      )}
      {showErrorModal && (
        <AlertModal
          setModalVisible={() => setShowErrorModal(!showErrorModal)}
          errorMsg={modalError}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 8,
    borderColor: colorPallete.red1
  },
  disabledContainer: {
    borderColor: colorPallete.grey6
  },
  uploadButton: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
    margin: 16
  },
  uploadButtonText: {
    paddingHorizontal: 4
  },
  divider: {
    marginVertical: 10,
    backgroundColor: colorPallete.grey6
  },
  fileContainer: {
    flex: 1,
    flexDirection: "row",
    padding: 16,
    alignItems: "center",
    justifyContent: "space-between"
  },
  dragText: {
    paddingHorizontal: 12,
    color: colorPallete.grey4
  },
  dragTextActive: {
    paddingHorizontal: 12,
    color: colorPallete.black3
  },
  uploadContainer: {
    flex: 1,
    flexDirection: "row"
  },
  errorText: {
    paddingHorizontal: 12,
    color: colorPallete.variant10
  },
  fileText: {
    paddingHorizontal: 12,
    color: colorPallete.black4
  },
  fileTextInActive: {
    paddingHorizontal: 12,
    color: colorPallete.grey2
  },
  iconContainer: {
    alignSelf: "center"
  },
  inputStyle: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  }
});
