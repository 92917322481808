import { combineReducers } from "redux";
import { calendarModuleReducer, ICalendarModuleState } from "./calendarModuleReducer";

export interface ICalendarState {
  calendarModule: ICalendarModuleState;
}

export const calendarReducer = combineReducers({
  calendarModule: calendarModuleReducer
});
