import { call, put, takeLatest } from "redux-saga/effects";
import { PRACTICAL_ASSESSMENT } from "@timhortons/common/src/redux/practicalAssessment/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { PracticalAssessmentAction } from "@timhortons/common/src/redux/practicalAssessment/actions/index";
import {
  GetModulesModel,
  ModuleModel,
  PostChecklistModel,
  PostChecklistPayLoad,
  TopicImageModel,
  TopicsAndChecklistModel,
  UserDetailsModel
} from "@timhortons/common/src/models/practicalAssessment/practicalAssessment";
import { practicalAssessmentRepository } from "@timhortons/common/src/repositories/practicalAssessment/practicalAssessment";

function* getUserDetailsWorkerSaga(
  value: IFluxStandardAction<{ search: string; pageNumber: number; pageSize: number }>
) {
  const { payload } = value;
  try {
    const response: UserDetailsModel = yield call(
      practicalAssessmentRepository.getUserTableDetails,
      payload
    );
    yield put(PracticalAssessmentAction.getUserTableSuccess(response));
  } catch (error) {
    Logger.error("Practical Assessment", error, "getUserDetailsWorkerSaga");

    yield put(PracticalAssessmentAction.getUserTableError(error));
  }
}

function* getAllModules(value: IFluxStandardAction<{ employeeId: string }>) {
  const { payload } = value;
  try {
    const response: GetModulesModel[] = yield call(
      practicalAssessmentRepository.getAllModules,
      payload
    );
    yield put(PracticalAssessmentAction.getAllModulesSuccess(response));
  } catch (error) {
    Logger.error("Practical Assessment", error, "getAllModules");

    yield put(PracticalAssessmentAction.getAllModulesError(error));
  }
}

function* getAllTopicsAndCheckList(
  value: IFluxStandardAction<{ employeeId: string; moduleId: string }>
) {
  const { payload } = value;
  try {
    const response: TopicsAndChecklistModel[] = yield call(
      practicalAssessmentRepository.getAllTopicsAndCheckList,
      payload
    );
    yield put(PracticalAssessmentAction.getAllTopicsAndCheckListSuccess(response));
  } catch (error) {
    Logger.error("Practical Assessment", error, "getAllTopicsAndCheckList");

    yield put(PracticalAssessmentAction.getAllTopicsAndCheckListError(error));
  }
}
function* postChecklist(
  value: IFluxStandardAction<{
    employeeId: number;
    checklistId: string;
    points: number;
    answer: boolean;
    moduleId: string;
  }>
) {
  const { payload } = value;
  try {
    let data: PostChecklistModel = yield call(practicalAssessmentRepository.postChecklist, payload);
    yield put(PracticalAssessmentAction.postChecklistSuccess(data));
  } catch (error) {
    Logger.error("Practical Assessment", error, "getAllTopicsAndCheckList");

    yield put(PracticalAssessmentAction.postChecklistError(error));
  }
}
function* postImages(value: IFluxStandardAction<any>) {
  const { payload } = value;
  try {
    let data: TopicImageModel[] = yield call(practicalAssessmentRepository.postImages, payload);
    yield put(PracticalAssessmentAction.postImagesSuccess(data));
  } catch (error) {
    Logger.error("Practical Assessment", error, "postImages");

    yield put(PracticalAssessmentAction.postImagesError(error));
  }
}
function* deleteImages(value: IFluxStandardAction<any>) {
  const { payload } = value;
  try {
    let data: any = yield call(practicalAssessmentRepository.deleteImages, payload);
    yield put(PracticalAssessmentAction.deleteImagesSuccess(data));
  } catch (error) {
    Logger.error("Practical Assessment", error, "deleteImages");

    yield put(PracticalAssessmentAction.deleteImagesError(error));
  }
}
function* postModules(value: IFluxStandardAction<any>) {
  const { payload } = value;
  try {
    let data: ModuleModel = yield call(practicalAssessmentRepository.postModules, payload);
    yield put(PracticalAssessmentAction.postModulesSuccess(data));
  } catch (error) {
    Logger.error("Practical Assessment", error, "postModules");

    yield put(PracticalAssessmentAction.postModulesError(error));
  }
}

function* getUserInfo(value: any) {
  const { payload } = value;

  try {
    let response: any = yield call(practicalAssessmentRepository.getUserInfo, payload);
    yield put(PracticalAssessmentAction.getUserInfoSuccess(response));
  } catch (error) {
    Logger.error("Practical Assessment", error, "getUserInfo");

    yield put(PracticalAssessmentAction.getUserTableError(error));
  }
}

function* practicalAssessmentWatcherSaga() {
  yield takeLatest(
    PRACTICAL_ASSESSMENT.GET_USERDETAILS_BY_USERID.LOADING,
    getUserDetailsWorkerSaga
  );
  yield takeLatest(PRACTICAL_ASSESSMENT.GET_ALL_MODULES.LOADING, getAllModules);
  yield takeLatest(
    PRACTICAL_ASSESSMENT.GET_ALL_TOPICS_AND_CHECKLIST.LOADING,
    getAllTopicsAndCheckList
  );
  yield takeLatest(PRACTICAL_ASSESSMENT.POST_CHECKLIST.LOADING, postChecklist);
  yield takeLatest(PRACTICAL_ASSESSMENT.POST_IMAGES.LOADING, postImages);
  yield takeLatest(PRACTICAL_ASSESSMENT.DELETE_IMAGES.LOADING, deleteImages);
  yield takeLatest(PRACTICAL_ASSESSMENT.POST_MODULES.LOADING, postModules);
  yield takeLatest(PRACTICAL_ASSESSMENT.GET_USER_INFO.LOADING, getUserInfo);
}

export default practicalAssessmentWatcherSaga;
