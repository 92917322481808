import { JsonObject, JsonProperty } from "json2typescript";

export interface IBakingPSPostApiPayload {
  storeCode: number;
  stationId: string;
  subCategoryId: string;
  productId: string;
  quantityBaked: number;
  expired: number;
  damaged: number;
  value: number;
  label: string;
  timeZone: string;
  fromManagerReview?: any;
  managerReviewDate?: any;
}
export interface IGetBakingProducts {
  storeCode: number;
  stationId: string;
  subCategoryId: string;
  timeZone: string;
  date?: string;
  fromManagerReview: boolean;
}
export interface IGetOtherProducts {
  storeCode: number;
  stationId: string;
  categoryId: string;
  timeZone: string;
  fromManagerReview: boolean;
  date?: string;
}
export interface IOtherProductionSheetPostData {
  storeCode: number;
  stationId: string;
  productId: string;
  value: number;
  label: string;
  timeZone: string;
  fromManagerReview: boolean;
  managerReviewDate: string;
}
export interface GetBakingCategories {
  storeCode: number;
  dateTime?: string;
  stationId: string;
  fromManagerReview: boolean;
}
export interface PostStationAuthPayload {
  storeCode: number;
  stationId: string;
  timeZone: string;
  amPm: string;
  employeeId: string;
}
export interface PostStationAuthResp {
  employeeId: string;
  isAccessValid: boolean;
  name: string;
  storeCode: number;
  storeName: string;
}

export interface ProductValues {
  damaged: number;
  expired: number;
  quantityBaked: number;
  value: number;
  label: string;
}
@JsonObject("BakingStationCatResp")
export class BakingStationCatResp {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  product: string = "";
}

@JsonObject("OthersStationCatResp")
export class OthersStationCatResp {
  @JsonProperty("id", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("shelfLife", Number, true)
  shelfLife?: number = null;
  @JsonProperty("productList", Array, true)
  productList?: any[] = null;
}
@JsonObject("ProductValuesModel")
export class ProductValuesModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("value", String)
  value: string = "";
  @JsonProperty("color", String)
  color: string = "";
}

@JsonObject("ProductItems")
export class ProductItems {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("productionSheet", null, Array)
  productValues: ProductValuesModel[] = null;
  @JsonProperty("date", String)
  date: string = "";
  @JsonProperty("productId", String)
  productId: string = "";
  @JsonProperty("shelfLife", String)
  shelfLife: string = "";
  @JsonProperty("unit", String)
  unit: string = "";
  @JsonProperty("productionSheetItems", Array)
  productionSheetItems: ProductValues[] = null;
}

@JsonObject("StationProducts")
export class StationProducts {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("shelfLife", Number)
  shelfLife: string = null;
  @JsonProperty("productList", Array)
  productList: ProductItems[] = null;
}

@JsonObject("AmPmEmployeeProducts")
export class AmPmEmployeeProducts {
  @JsonProperty("amEmployeeId", null, Number)
  baker_AM_ID: number = null;
  @JsonProperty("amEmployeeName", null, String)
  baker_AM: string = "";
  @JsonProperty("pmEmployeeId", null, Number)
  baker_PM_ID: number = null;
  @JsonProperty("pmEmployeeName", null, String)
  baker_PM: string = "";
  @JsonProperty("date", String)
  date: string = "";
  @JsonProperty("isAmActive", Boolean)
  isAmActive: boolean = false;
  @JsonProperty("isPmActive", Boolean)
  isPmActive: boolean = false;
  @JsonProperty("stationId", String)
  stationId: string = "";
  @JsonProperty("stationName", String)
  title: string = "";
}
