import {
  Action_CategoryNSubCategory_Ids,
  IOperationalHours,
  IStationReadOperationalHours,
  LogLevelType
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { convertISOto12hr, getTimeZone } from "@timhortons/common/src/utils/dateFormat";
import { LogsAuthModel } from "@timhortons/common/src/models/logs/mainLogs";

export enum AnswerStatus {
  assignTaskComment = "ASSIGN_TASK_COMMENT",
  contactMaintenance = "CONTACT_MAINTENANCE",
  comment = "COMMENT"
}

export const hasResolvedCleaningRestroomFormatter = (
  operationalHours: IOperationalHours,
  user: IUserDetails,
  selectedActionShift: string
) => {
  let postFormData = new FormData();
  postFormData.append("date", operationalHours.startDateTime);
  postFormData.append("storeCode", String(user?.storeCode));
  postFormData.append("label", selectedActionShift);
  postFormData.append("hasResolved", String(false));
  return postFormData;
};

export const commonFormatter = (data: any) => {
  const {
    commentFormData,
    initialFiles,
    operationalHours,
    user,
    shift,
    currentAction,
    categoryId,
    item,
    status,
    levelId,
    levelType,
    assignee,
    answerStatus
  } = data;
  let newArray: any[] = [];
  if (commentFormData && commentFormData.files) {
    newArray = [...commentFormData.files];
  }
  let saveFiles = initialFiles && newArray?.filter((el) => !initialFiles.includes(el));
  let deleteFileIndexes =
    initialFiles &&
    initialFiles?.filter((el: any) => !newArray?.includes(el)).map((el: any) => el.id);
  let deleteFile = deleteFileIndexes?.join(",");
  let postfiles: Blob[] =
    initialFiles && initialFiles?.length > 0
      ? (saveFiles && saveFiles.map((img) => img.blob)) || []
      : (commentFormData?.files && commentFormData?.files.map((img: any) => img.blob)) || [];
  let postFormData = new FormData();
  postfiles &&
    postfiles.map((img) => {
      postFormData.append("files", img);
    });
  postFormData.append("startDate", operationalHours?.startDateTime);
  postFormData.append("endDate", operationalHours?.endDateTime);
  postFormData.append("timeZone", getTimeZone());
  commentFormData &&
    commentFormData.comment &&
    postFormData.append("comment", commentFormData.comment);
  postFormData.append("deleteImages", initialFiles ? deleteFile : "");
  postFormData.append("storeCode", String(user?.storeCode));
  postFormData.append("levelId", levelId);
  postFormData.append("levelType", levelType);
  if (shift !== "") {
    postFormData.append("label", shift);
  }
  if (status === "NO") {
    postFormData.append("answerStatus", answerStatus);
    postFormData.append("actionName", currentAction);
    postFormData.append("categoryId", categoryId);
    postFormData.append("questionId", item.uniqueItemId);
    postFormData.append("answer", "NO");
    if (assignee !== "" && assignee !== undefined && assignee !== null) {
      postFormData.append("employeeId", assignee);
    }
  }
  return postFormData;
};

export const contactMaintainenceFormatter = (
  item: any,
  operationalHours: IOperationalHours | IStationReadOperationalHours,
  shift: string,
  user: IUserDetails,
  currentAction: string,
  categoryId: string
) => {
  let postFormData = new FormData();
  postFormData.append("startDate", operationalHours?.startDateTime);
  postFormData.append("endDate", operationalHours?.endDateTime);
  postFormData.append("timeZone", getTimeZone());
  postFormData.append("storeCode", String(user && user.storeCode));
  postFormData.append("questionId", item.uniqueItemId);
  postFormData.append("levelId", Action_CategoryNSubCategory_Ids.MWT);
  postFormData.append("levelType", LogLevelType.ACTION);
  postFormData.append("answer", "NO");
  if (shift !== "") {
    postFormData.append("label", shift);
  }
  postFormData.append("answerStatus", AnswerStatus.contactMaintenance);
  postFormData.append("actionName", currentAction);
  postFormData.append("categoryId", categoryId);

  return postFormData;
};

export const displayTime = (setDisplayText: Function, startDate: string, status?: string) => {
  let z = convertISOto12hr(startDate);
  setDisplayText(`Next shift starts at ${z}`);
};
export const logSubtitleText = (logsAuth: LogsAuthModel, operationalHours: any) => {
  if (logsAuth && logsAuth?.name) {
    return logsAuth?.name;
  } else if (operationalHours && operationalHours?.employeeName) {
    return operationalHours?.employeeName;
  } else return null;
};
