import { call, put, takeLatest } from "redux-saga/effects";
import { RESOURCES } from "@timhortons/common/src/redux/userResources/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { ResourcesAction } from "@timhortons/common/src/redux/userResources/actions/index";
import { ResourcesRepository } from "@timhortons/common/src/repositories/resources/resources";
import { ISearchKeyword } from "@timhortons/common/src/modules/userResources";
import {
  ResourcesFiltersModel,
  SearchResultsModel,
  SubCategoriesModel,
  SubTopicsModel,
  postCreateNewContentModel,
  SubCategoryListModel,
  ContentsModelList,
  MarkDownContentModel,
  putCategoryTitleModel,
  deleteSubCategoryTitleModel,
  SearchResultsResponseModel
} from "@timhortons/common/src/models/userResources/userResources";

function* getContentsWorkerSaga() {
  try {
    const response: ContentsModelList[] = yield call(ResourcesRepository.getContentsList);
    yield put(ResourcesAction.getContentsSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getContentsWorkerSaga");

    yield put(ResourcesAction.getContentsError(error));
  }
}
function* getPublishedContentsWorkerSaga() {
  try {
    const response: ContentsModelList[] = yield call(ResourcesRepository.getPublishedCategories);
    yield put(ResourcesAction.getPublishedContentsSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getContentsWorkerSaga");

    yield put(ResourcesAction.getPublishedContentsError(error));
  }
}
function* getSubCategoriesListWorkerSaga(value: IFluxStandardAction<{ categoryId: number }>) {
  const { payload } = value;

  try {
    const response: SubCategoriesModel = yield call(
      ResourcesRepository.getSubCategoriesList,
      payload
    );
    yield put(ResourcesAction.getSubCategoriesListSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getSubCategoriesListWorkerSaga");

    yield put(ResourcesAction.getSubCategoriesListError(error));
  }
}

function* getPublishedSubCategoriesListWorkerSaga(
  value: IFluxStandardAction<{ categoryId: number }>
) {
  const { payload } = value;
  try {
    const response: SubCategoriesModel = yield call(
      ResourcesRepository.getPublishedSubCategoriesList,
      payload
    );
    yield put(ResourcesAction.getPublishedSubCategoriesListSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getSubCategoriesListWorkerSaga");

    yield put(ResourcesAction.getSubCategoriesListError(error));
  }
}

function* getSubCategoriesTopicsList(value: IFluxStandardAction<{ subCategoryId: number }>) {
  const { payload } = value;
  try {
    const response: SubTopicsModel[] = yield call(
      ResourcesRepository.getSubCategoriesTopicList,
      payload
    );
    yield put(ResourcesAction.getSubCategoriesTopicListSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getSubCategoriesTopicsList");

    yield put(ResourcesAction.getSubCategoriesTopicListError(error));
  }
}

function* getPublishedSubCategoriesTopicsList(
  value: IFluxStandardAction<{ subCategoryId: number }>
) {
  const { payload } = value;
  try {
    const response: SubTopicsModel[] = yield call(
      ResourcesRepository.getPublishedSubCategoriesTopicList,
      payload
    );
    yield put(ResourcesAction.getPublishedSubCategoriesTopicListSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getSubCategoriesTopicsList");

    yield put(ResourcesAction.getPublishedSubCategoriesTopicListError(error));
  }
}

function* getTopicContentWorkerSaga(value: IFluxStandardAction<{ subCategoryId: string }>) {
  const { payload } = value;
  try {
    const response: MarkDownContentModel = yield call(ResourcesRepository.getTopicContent, payload);
    yield put(ResourcesAction.getTopicContentSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getMarkDownContentWorkerSaga");

    yield put(ResourcesAction.getTopicContentError(error));
  }
}

function* getPublishedTopicContentWorkerSaga(
  value: IFluxStandardAction<{ subCategoryId: string }>
) {
  const { payload } = value;
  try {
    const response: MarkDownContentModel = yield call(
      ResourcesRepository.getPublishedTopicContent,
      payload
    );
    yield put(ResourcesAction.getPublishedTopicContentSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getMarkDownContentWorkerSaga");

    yield put(ResourcesAction.getPublishedTopicContentError(error));
  }
}

function* getSearchResults(value: IFluxStandardAction<ISearchKeyword>) {
  const { payload } = value;

  try {
    const response: Array<SearchResultsResponseModel> = yield call(
      ResourcesRepository.getSearchResults,
      payload
    );
    yield put(ResourcesAction.getSearchResultsSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getSearchResults");

    yield put(ResourcesAction.getSearchResultsError(error));
  }
}
function* getFilters() {
  try {
    const response: Array<ResourcesFiltersModel> = yield call(ResourcesRepository.getFilters);
    yield put(ResourcesAction.getFiltersSuccess(response));
  } catch (error) {
    Logger.error("User-Resources", error, "getFilters");
    yield put(ResourcesAction.getFiltersError(error));
  }
}
function* postSubCategoryWorkerSaga(
  value: IFluxStandardAction<{
    categoryId: string;
    name: string;
    description: Array<Object>;
    subCategoryId?: string;
  }>
) {
  let { payload } = value;
  try {
    const response: {
      subCategoryData: SubCategoryListModel;
      subCategoryId: string;
    } = yield call(ResourcesRepository.postSubCategory, payload);
    yield put(ResourcesAction.postSubCategorySuccess(response));
  } catch (error) {
    Logger.error("resources", error, "post sub-Category");
    yield put(ResourcesAction.postSubCategoryError(error));
  }
}
function* postTopicWorkerSaga(
  value: IFluxStandardAction<{
    name: string;
    subCategoryId: string;
    content: any;
    topicId?: string;
    categoryId?: string;
  }>
) {
  let { payload } = value;
  try {
    const response: SubTopicsModel = yield call(ResourcesRepository.postTopic, payload);
    yield put(ResourcesAction.postTopicSuccess(response));
  } catch (error) {
    Logger.error("resources", error, "post topics");
    yield put(ResourcesAction.postTopicError(error));
  }
}
function* deleteTopicWorkerSaga(
  value: IFluxStandardAction<{
    subCatId: string;
    topicId: string;
  }>
) {
  const { payload } = value;
  try {
    yield call(ResourcesRepository.deleteTopic, payload);
    yield put(
      ResourcesAction.topicDeletionSuccess({
        topicId: payload.topicId
      })
    );
  } catch (error) {
    Logger.error("resource/topic/deletion", error, "deleteTopicWorkerSaga");
    yield put(ResourcesAction.topicDeletionError(error));
  }
}
function* deleteCategoryWorkerSaga(
  value: IFluxStandardAction<{
    catId: string;
  }>
) {
  const { payload } = value;
  try {
    yield call(ResourcesRepository.deleteCategory, payload);
    yield put(
      ResourcesAction.categoryDeletionSuccess({
        catId: payload.catId
      })
    );
  } catch (error) {
    Logger.error("resource/category/deletion", error, "deleteCategoryWorkerSaga");
    yield put(ResourcesAction.categoryDeletionError(error));
  }
}
function* postCreateNewContentWorkerSaga(value: IFluxStandardAction<{ name: string }>) {
  let { payload } = value;
  try {
    const response: postCreateNewContentModel = yield call(
      ResourcesRepository.postCreateNewContent,
      payload
    );
    yield put(ResourcesAction.postCreateNewContentSuccess(response));
  } catch (error) {
    Logger.error("resources", error, "post Create New Content");
    yield put(ResourcesAction.postCreateNewContentError(error));
  }
}

// function* uploadResourceImage(value: FormData) {
//   const { payload } = value;
//   try {
//     const response: any = yield call(ResourcesRepository.uploadResourceImage, payload);
//     yield put(ResourcesAction.uploadResourceImageSuccess(response));
//   } catch (error) {
//   }
// }

function* publishCategory(value: IFluxStandardAction<{ categoryId: string }>) {
  const { payload } = value;
  try {
    yield call(ResourcesRepository.publishCategory, payload);
    yield put(ResourcesAction.publishCategorySuccess());
  } catch (error) {
    yield put(ResourcesAction.publishCategoryError());
  }
}

function* putCategoryTitle(value: IFluxStandardAction<{ categoryId: string; name: string }>) {
  const { payload } = value;
  try {
    const response: putCategoryTitleModel = yield call(
      ResourcesRepository.putCategoryTitle,
      payload
    );
    yield put(ResourcesAction.putCategoryTitleSuccess(response));
  } catch (error) {
    Logger.error("category/", error, "putCategoryTitle");
    yield put(ResourcesAction.putCategoryTitleError(error));
  }
}

function* deleteSubCategory(value: IFluxStandardAction<{ subCategoryId: string }>) {
  const { payload } = value;
  try {
    const response: deleteSubCategoryTitleModel = yield call(
      ResourcesRepository.deleteSubCategory,
      payload
    );
    yield put(ResourcesAction.deleteSubCategorySuccess(response));
  } catch (error) {
    Logger.error("subCategory/", error, "deleteSubCategory");
    yield put(ResourcesAction.deleteSubCategoryError(error));
  }
}

function* resourcesWatcherSaga() {
  yield takeLatest(RESOURCES.GET_SEARCH_RESULTS.LOADING, getSearchResults);
  yield takeLatest(RESOURCES.GET_FILTERS.LOADING, getFilters);
  yield takeLatest(RESOURCES.GET_CONTENTS.LOADING, getContentsWorkerSaga);
  yield takeLatest(RESOURCES.GET_SUBCATEGORIES_LIST.LOADING, getSubCategoriesListWorkerSaga);
  yield takeLatest(RESOURCES.GET_SUBCATEGORIES_TOPICS.LOADING, getSubCategoriesTopicsList);
  yield takeLatest(RESOURCES.GET_TOPIC_CONTENT.LOADING, getTopicContentWorkerSaga);
  yield takeLatest(RESOURCES.POST_SUBCATEGORY.LOADING, postSubCategoryWorkerSaga);
  yield takeLatest(RESOURCES.POST_TOPIC.LOADING, postTopicWorkerSaga);
  yield takeLatest(RESOURCES.TOPIC_DELETION.LOADING, deleteTopicWorkerSaga);
  yield takeLatest(RESOURCES.CATEGORY_DELETION.LOADING, deleteCategoryWorkerSaga);
  yield takeLatest(RESOURCES.POST_CREATE_NEW_CONTENT.LOADING, postCreateNewContentWorkerSaga);
  yield takeLatest(RESOURCES.PUBLISH_CATEGORY.LOADING, publishCategory);
  yield takeLatest(RESOURCES.PUBLISHED_CATEGORIES.LOADING, getPublishedContentsWorkerSaga);
  yield takeLatest(
    RESOURCES.PUBLISHED_SUBCATEGORIES_LIST.LOADING,
    getPublishedSubCategoriesListWorkerSaga
  );
  yield takeLatest(RESOURCES.PUBLISHED_TOPIC_CONTENT.LOADING, getPublishedTopicContentWorkerSaga);
  yield takeLatest(RESOURCES.PUBLISHED_TOPIC_LIST.LOADING, getPublishedSubCategoriesTopicsList);
  yield takeLatest(RESOURCES.PUT_CATEGORY_TITLE.LOADING, putCategoryTitle);
  yield takeLatest(RESOURCES.DELETE_SUB_CATEGORY_TITLE.LOADING, deleteSubCategory);
}

export default resourcesWatcherSaga;
