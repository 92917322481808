import React from "react";
import { View, StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Button, { ButtonType, IconPositions } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";

interface Props {
  title: string;
  translate: any;
  handleBack?: () => void;
  isDisabled: boolean;
}
export default function DownloadButton(props: Props) {
  const { title, translate, handleBack, isDisabled = false } = props;
  return (
    <View style={styles(isDisabled).buttonContainer}>
      <Button
        iconStyles={{ color: isDisabled ? colorPallete.grey13 : colorPallete.red1, padding: 10 }}
        iconSize={16}
        iconName={IconNames.download}
        iconPosition={IconPositions.Left}
        buttonStyles={styles(isDisabled).button}
        type={ButtonType.Primary}
        disabled={isDisabled}
        onPress={handleBack}
      >
        <Text
          textSize={TextSize.Regular}
          fontWeight={FontWeight.SemiBold}
          testId="summary-header"
          textStyle={{ color: isDisabled ? colorPallete.grey13 : colorPallete.red1 }}
        >
          {translate(title)}
        </Text>
      </Button>
    </View>
  );
}
const styles = (isDisabled: boolean) =>
  StyleSheet.create({
    buttonContainer: {
      flex: 0.5,
      justifyContent: "flex-end",
      alignItems: "flex-end"
    },
    button: {
      backgroundColor: isDisabled ? colorPallete.variant14 : colorPallete.variant1,
      borderRadius: 8,
      paddingHorizontal: 36,
      height: 48
    }
  });
