import { all } from "redux-saga/effects";
import profileWatcherSaga from "@timhortons/common/src/redux/profile/saga/profileWatcherSaga";
import revRootSaga from "@timhortons/common/src/redux/rev/saga/index";
import logsRootSaga from "@timhortons/common/src/redux/logs/saga/index";
import iamWatcherSaga from "@timhortons/common/src/modules/iam/redux/saga/iamWatcherSaga";
import resourcesWatcherSaga from "@timhortons/common/src/redux/userResources/saga/resourcesWatcherSaga";
import productionSheetRootSaga from "@timhortons/common/src/redux/productionSheet/saga/index";
import lpRootSaga from "@timhortons/common/src/redux/lp/saga";
import manageLmsSaga from "@timhortons/common/src/redux/manageLms/saga";
import mobileRootSaga from "@timhortons/common/src/redux//userLms/saga";
import calendarRootSaga from "@timhortons/common/src/redux/calendar/saga";
import practicalAssessmentWatcherSaga from "@timhortons/common/src/redux/practicalAssessment/saga/practicalAssessmentWatcherSaga";
import manageNewsWatcherSaga from "@timhortons/common/src/redux/manageNews/saga/manageNewsWatcherSaga";
import manageExistingUsersWatcherSaga from "@timhortons/common/src/redux/manageExistingUser/saga/mnageExistingUsersWatcherSaga";
import manageAskRootSaga from "@timhortons/common/src/redux/askTell/saga";
import ReportsBuilderWatcherSaga from "@timhortons/common/src/redux/reportBuilder/saga/reportsBuilderWatcherSaga";
import homeWatcherSaga from "@timhortons/common/src/redux/home/saga/userLmsWatcherSaga";
import lmsReportsRootSaga from "@timhortons/common/src/redux/lmsReports/saga/index";

export default function* rootSaga() {
  yield all([
    profileWatcherSaga(),
    revRootSaga(),
    iamWatcherSaga(),
    logsRootSaga(),
    resourcesWatcherSaga(),
    productionSheetRootSaga(),
    lpRootSaga(),
    manageLmsSaga(),
    mobileRootSaga(),
    calendarRootSaga(),
    lmsReportsRootSaga(),
    practicalAssessmentWatcherSaga(),
    manageNewsWatcherSaga(),
    manageExistingUsersWatcherSaga(),
    manageAskRootSaga(),
    ReportsBuilderWatcherSaga(),
    homeWatcherSaga()
  ]);
}
