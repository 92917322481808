import React from "react";
import { View, StyleSheet } from "react-native";
import Card from "@timhortons/common/src/components/atoms/card";
import { IBarChartOptions } from "@timhortons/web/src/components/molecules/barChart";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
const BarChartGroup = React.lazy(() => import("@timhortons/web/src/components/molecules/barChart"));
import MobileBarChartGroup, {
  NumberOfBars
} from "@timhortons/tablet/src/components/molecules/barChart";
interface IProps {
  graphData: IBarChartOptions;
}
export default function LpKpiGraph(props: IProps) {
  const { graphData } = props;
  return (
    <View style={styles.container}>
      <Card.Body bodyStyle={styles.bodyContainer}>
        {PlatformUtils.isWeb() ? (
          <View style={styles.graphContainer}>
            <View style={styles.kpiGraphContainer}>
              <React.Suspense fallback={null}>
                <BarChartGroup barChartData={graphData} />
              </React.Suspense>
            </View>
          </View>
        ) : (
          <View style={styles.graphContainer}>
            <View style={styles.kpiGraphContainer}>
              <MobileBarChartGroup barChartData={graphData} barWidthSpace={NumberOfBars.SixBars} />
            </View>
          </View>
        )}
      </Card.Body>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colorPallete.grey7,
    borderRadius: 4
  },
  bodyContainer: {},
  graphContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  kpiGraphContainer: {
    width: "100%"
  }
});
