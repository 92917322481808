import React, { useEffect, useState } from "react";
import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { Pagination } from "@timhortons/common/src/components/molecules/pagination";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { useTranslation } from "react-i18next";

interface IProps {
  handlePagination?: Function;
  pageNum: number;
  totalRecords: number;
  pageLimit?: number;
  paginationStyles?: StyleProp<ViewStyle>;
}
export interface IPaginationData {
  currentPage: number;
  totalPages: number;
  pageLimit: number;
  totalRecords: number;
}

export default function CommonPagination(props: IProps) {
  const { handlePagination, pageNum, totalRecords, pageLimit = 10, paginationStyles } = props;
  const { t } = useTranslation("common");
  const [pageNumber, setPageNumber] = useState<number>(pageNum);
  const [previousPageDataSize, setDataSize] = useState<number>(1);
  const [nextPageDataSize, setNextDataSize] = useState<number>(0);
  const [setPage, setTotalPage] = useState(1);

  const setTotalPages = (totalPages: number) => {
    setTotalPage(totalPages);
  };
  const handlePageChange = (paginationData: IPaginationData) => {
    setPageNumber(paginationData.currentPage);
  };

  useEffect(() => {
    pageNumber !== pageNum && handlePagination(pageNumber);
    setDataSize((pageNumber - 1) * pageLimit + 1);
    setNextDataSize(pageNumber * pageLimit);
  }, [pageNumber]);

  return (
    <View style={[styles.paginationContainer, paginationStyles]}>
      <View style={styles.bottomText}>
        <Text
          testId="showingTableEntries"
          textStyle={{ color: colorPallete.grey3 }}
          textSize={TextSize.Regular}
          fontWeight={FontWeight.Regular}
        >
          {t("showing")} {pageNum === 1 ? "1" : previousPageDataSize} {t("to")}{" "}
          {totalRecords < pageLimit
            ? totalRecords
            : (setPage === pageNum && totalRecords) || nextPageDataSize}{" "}
          {t("of")} {totalRecords} {t("entries")}
        </Text>
      </View>
      {totalRecords > pageLimit ? (
        <Pagination
          getTotalPages={(totalPages: number) => setTotalPages(totalPages)}
          pageNumber={pageNum}
          customStyles={styles.pagination}
          totalRecords={totalRecords}
          pageLimit={pageLimit}
          onPageChanged={(paginationData: IPaginationData) => handlePageChange(paginationData)}
        />
      ) : null}
    </View>
  );
}
const styles = StyleSheet.create({
  paginationContainer: {
    flex: 1,
    backgroundColor: colorPallete.white3,
    flexDirection: "row",
    // ...I18nService.select({
    //   rtl: { paddingEnd: 16 },
    //   ltr: { paddingStart: 16 }
    // }),
    paddingVertical: 32,
    paddingHorizontal: 30
  },
  bottomText: {
    flex: 0.3,
    justifyContent: "flex-start"
  },
  pagination: {
    flex: 0.7,
    justifyContent: "flex-end"
  }
});
