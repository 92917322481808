import { call, put, takeLatest } from "redux-saga/effects";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { ActionPlansAction } from "@timhortons/common/src/redux/rev/actions/actionPlanActions";
import {
  ActionPlanTableModel,
  ActionPlanHeaderModel,
  ActionPlanCategoryDataModel,
  ActionPlanAccordionDataModel,
  FilterDataModel
} from "@timhortons/common/src/models/rev/actionPlan";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { ActionPlanRepository } from "@timhortons/common/src/repositories/rev/actionPlan";
import { IActionPlanData } from "@timhortons/common/src/modules/rev/components/dashboards/actionPlans";
import { IActionItemsData } from "@timhortons/common/src/modules/rev/organisms/actionPlanAccordion/body";
import { Logger } from "@timhortons/common/src/services/logger";
import { AcknowledgementModel } from "@timhortons/common/src/models/rev/reports";

function* getFilterDataWorkerSaga(value: IFluxStandardAction<{ storeCode: string }>) {
  const { payload } = value;
  try {
    const response: FilterDataModel = yield call(ActionPlanRepository.getFilterData, payload);
    yield put(ActionPlansAction.getFilterDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/action-plan", error, "getFilterDataWorkerSaga");

    yield put(ActionPlansAction.getFilterDataError(error));
  }
}

function* getActionPlanDataWorkerSaga(value: IFluxStandardAction<IActionPlanData>) {
  const { payload } = value;
  try {
    const response: ActionPlanTableModel = yield call(ActionPlanRepository.getActionPlans, payload);

    yield put(ActionPlansAction.getActionPlansSuccess(response));
  } catch (error) {
    Logger.error("Rev/action-plan", error, "getActionPlanDataWorkerSaga");

    yield put(ActionPlansAction.getActionPlansError(error));
  }
}
function* getActionPlanDetailWorkerSaga(value: IFluxStandardAction<{ actionPlanId: number }>) {
  const { payload } = value;
  try {
    const response: ActionPlanHeaderModel = yield call(
      ActionPlanRepository.getActionPlanDetails,
      payload
    );
    yield put(ActionPlansAction.getActionPlanDetailSuccess(response));
  } catch (error) {
    Logger.error("Rev/action-plan", error, "getActionPlanDetailWorkerSaga");

    yield put(ActionPlansAction.getActionPlanDetailError(error));
  }
}
function* getCategoryStatusDataWorkerSaga(
  value: IFluxStandardAction<{ calibrationId: number; actionPlanId: number }>
) {
  const { payload } = value;
  try {
    const response: ActionPlanCategoryDataModel[] = yield call(
      ActionPlanRepository.getActionPlanCategoryTableData,
      payload
    );
    yield put(ActionPlansAction.getCategoryStatusDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/action-plan", error, "getCategoryStatusDataWorkerSaga");

    yield put(ActionPlansAction.getCategoryStatusDataError(error));
  }
}
function* getAccordianDataWorkerSaga(
  value: IFluxStandardAction<{
    categoryId: number;
    actionPlanId: number;
    calibrationId: number;
  }>
) {
  const { payload } = value;
  try {
    const response: ActionPlanAccordionDataModel[] = yield call(
      ActionPlanRepository.getActionPlanAccordian,
      payload
    );
    yield put(ActionPlansAction.getActionPlanAccordionDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/action-plan", error, "getAccordianDataWorkerSaga");

    yield put(ActionPlansAction.getActionPlanAccordionDataError(error));
  }
}

function* updateActionPlanStatusWorkerSaga(value: IFluxStandardAction<{ actionPlanId: number }>) {
  const { payload } = value;
  try {
    yield call(ActionPlanRepository.getUpdateActionPlanStatus, payload);
    yield put(ActionPlansAction.updateActionPlanStatusSuccess());
  } catch (error) {
    Logger.error("Rev/action-plan", error, "updateActionPlanStatusWorkerSaga");

    yield put(ActionPlansAction.updateActionPlanStatusError(error));
  }
}
function* postActionStepsWorkerSaga(value: IFluxStandardAction<IActionItemsData>) {
  const { payload } = value;
  try {
    let data: { status: string; data: any } = yield call(
      ActionPlanRepository.postActionSteps,
      payload
    );
    yield put(ActionPlansAction.postActionStepsSuccess(data, payload));
  } catch (error) {
    Logger.error("Rev/action-plan", error, "postActionStepsWorkerSaga");

    yield put(ActionPlansAction.postActionStepsError(error));
  }
}

function* getOwnerDatatWorkerSaga(value: IFluxStandardAction<{ storeId: string }>) {
  try {
    const { payload } = value;
    const response: AcknowledgementModel = yield call(ActionPlanRepository.getOwnerData, payload);
    yield put(ActionPlansAction.getOwnerDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/action-plan", error, "getOwnerDatatWorkerSaga");

    yield put(ActionPlansAction.getOwnerDataError(error));
  }
}

function* actionPlanWatcherSaga() {
  yield takeLatest(REV.GET_FILTER_DATA.LOADING, getFilterDataWorkerSaga);
  yield takeLatest(REV.ActionPlans.GET_ACTION_PLANS.LOADING, getActionPlanDataWorkerSaga);
  yield takeLatest(
    REV.ActionPlans.GET_ACTION_PLAN_HEADER_DATA.LOADING,
    getActionPlanDetailWorkerSaga
  );
  yield takeLatest(REV.GET_CATEGORY_STATUS.LOADING, getCategoryStatusDataWorkerSaga);
  yield takeLatest(
    REV.ActionPlans.GET_ACTION_PLAN_ACCORDION_DATA.LOADING,
    getAccordianDataWorkerSaga
  );
  yield takeLatest(
    REV.ActionPlans.UPDATE_ACTION_PLAN_STATUS.LOADING,
    updateActionPlanStatusWorkerSaga
  );
  yield takeLatest(REV.ActionPlans.POST_ACTION_STEPS.LOADING, postActionStepsWorkerSaga);
  yield takeLatest(REV.GET_OWNER_DATA.LOADING, getOwnerDatatWorkerSaga);
}

export default actionPlanWatcherSaga;
