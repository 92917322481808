import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { IReportBuildModule } from "@timhortons/common/src/modules/reportBuilders/template/cardsDescLayout";
import { CEOEqvRoles, Roles, SuperAdminEqvRoles } from "@timhortons/common/src/constants/roles";

//NOTE: title - translation should be added under "reportBuilder" in the translation file
export const RBModulesArr: Array<IReportBuildModule> = [
  // {
  //   id: "1-rev-report-builder",
  //   path: Pathnames.revReportBuilder,
  //   title: "REVFullTitle",
  //   description:
  //     "Sapien arcu justo sed ac mi massa semper enim neque. Velit aenean eu risus vel lacinia amet.",
  //   inTabAlso: false,
  //   allowedRoles: [
  //     ...SuperAdminEqvRoles,
  //     ...CEOEqvRoles,
  //     Roles.AreaManager,
  //     Roles.CountryManager,
  //     Roles.ClusterHeadofOps,
  //     Roles.AreaTrainer,
  //     Roles.InternalAuditor,
  //     Roles.OperationManager,
  //     Roles.SeniorOpsManager
  //   ]
  // },
  {
    id: "2-lp-report-builder",
    path: Pathnames.lpReportBuilder,
    title: "LPFullTitle",
    description: "Generate reports based on data types and levels within the specified time frame.",
    inTabAlso: false,
    allowedRoles: [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.CountryManager,
      Roles.ClusterHeadofOps,
      Roles.SeniorOpsManager,
      Roles.OperationManager,
      Roles.AreaManager
    ]
  },
  {
    id: "3-prodSheet-report-builder",
    path: Pathnames.psReportBuilder,
    title: "productionSheet",
    description: "Generate reports indicating total baked goods, end-of-throws, and total waste",
    inTabAlso: false,
    allowedRoles: [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.StoreAccess,
      Roles.CountryManager,
      Roles.ClusterHeadofOps,
      Roles.OperationManager,
      Roles.SeniorOpsManager,
      Roles.AreaManager
    ]
  },
  // {
  //   id: "4-logs-report-builder",
  //   path: Pathnames.logsReportBuilder,
  //   title: "logs",
  //   description:
  //     "Luctus risus laoreet nisl vitae metus mauris a dui. Pharetra nunc et fringilla eget lorem vitae in.",
  //   inTabAlso: false,
  //   allowedRoles: [...SuperAdminEqvRoles]
  // },
  {
    id: "5-lms-reports",
    path: Pathnames.lmsReportBuilder,
    title: "LMSFullTitle",
    description: "Reports regarding individual training sessions can be viewed and downloaded.",
    inTabAlso: true,
    allowedRoles: [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.AreaManager,
      Roles.AreaTrainer,
      Roles.CountryManager,
      Roles.ClusterHeadofOps,
      Roles.OperationManager,
      Roles.SeniorOpsManager
    ]
  },
  {
    id: "6-detailed-lms-reports",
    path: Pathnames.detailedLmsReportBuilder,
    title: "LMSFullTitle",
    description:
      "Generate and download reports based on data types and levels within the specified time frame.",
    inTabAlso: false,
    allowedRoles: [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.AreaManager,
      Roles.AreaTrainer,
      Roles.CountryManager,
      Roles.ClusterHeadofOps,
      Roles.OperationManager,
      Roles.SeniorOpsManager
    ]
  }
];
