import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  ISelfCalibrationError,
  ISelfCalibrationLoading,
  ISelfCalibrationState
} from "@timhortons/common/src/redux/rev/reducers/selfCalibration";

import {
  CommentDataModel,
  ObservationDataModel,
  SubCategoryModel,
  TippingPointsModel,
  YesNoDataModel,
  SosAverageTimeModel,
  StoreAuditInfoModel,
  PreviousAuditDataModel
} from "@timhortons/common/src/models/rev/selfCalibration";
import { DetailedReportIdModel } from "@timhortons/common/src/models/rev/reports";
import { IExternalMeta } from "@timhortons/common/src/repositories/rev/selfCalibration";
import { ComparisonStoresAdminModelList } from "models/profile";

const selfCalibrationSelect = (state: IState): ISelfCalibrationState => state.rev.selfCalibration;

const selfCalibrationLoading: Selector<IState, ISelfCalibrationLoading> = createSelector(
  selfCalibrationSelect,
  (data) => data.loading
);

const selfCalibrationError: Selector<IState, ISelfCalibrationError> = createSelector(
  selfCalibrationSelect,
  (data) => data.error
);

const selectStoreAuditData: Selector<IState, StoreAuditInfoModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.storeAuditData
);
const selectSubCategoryData: Selector<IState, SubCategoryModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.subCategoryData
);

const checkAuth: Selector<IState, StoreAuditInfoModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.checkAuth
);
const getCommentData: Selector<IState, CommentDataModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.commentData
);
const getDetailedReportId: Selector<IState, DetailedReportIdModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.detailedReportId
);
const getAnsweringYesNoResponse: Selector<IState, YesNoDataModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.asnweringYesNoResponse
);
const getAnsweringYesNoResponseError: Selector<IState, Object> = createSelector(
  selfCalibrationSelect,
  (data) => data.error.asnweringYesNoResponse
);
const getObservationDataSuccess: Selector<IState, ObservationDataModel[]> = createSelector(
  selfCalibrationSelect,
  (data) => data.observationData
);
const getTippingPointsData: Selector<IState, TippingPointsModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.tippingPoints
);

const selectPreviousAuditData: Selector<IState, PreviousAuditDataModel> = createSelector(
  selfCalibrationSelect,
  (data) => data.previousAuditData
);
const sosAvgTimeData: Selector<IState, SosAverageTimeModel[]> = createSelector(
  selfCalibrationSelect,
  (data) => data.sosAvgTimeData
);
const postSosAvgTimeData: Selector<IState, SosAverageTimeModel[]> = createSelector(
  selfCalibrationSelect,
  (data) => data.postSosAvgTimeData
);
const updateCompletion: Selector<IState, number> = createSelector(
  selfCalibrationSelect,
  (data) => data.updateCompletion
);
const getFeedbackData: Selector<IState, CommentDataModel[]> = createSelector(
  selfCalibrationSelect,
  (data) => data.getCommentData
);
const getReportAuditId: Selector<IState, { calibrationId: number; reportId: number }> =
  createSelector(selfCalibrationSelect, (data) => data.reportedGenerated);

const getExternalMeta: Selector<IState, IExternalMeta> = createSelector(
  selfCalibrationSelect,
  (data) => data.externalMetaData
);

const postExternalAuditData: Selector<IState, any> = createSelector(
  selfCalibrationSelect,
  (data) => data.externalAuditResponse
);
const getStoresListByStoreCodeName: Selector<IState, ComparisonStoresAdminModelList> =
  createSelector(selfCalibrationSelect, (data) => data.getStoreListByCodeOrName);

const getStorelistByCodeIsEnd: Selector<IState, any> = createSelector(
  selfCalibrationSelect,
  (data) => data.isStoreListEnd
);
const getMoreSToreListLoading: Selector<IState, any> = createSelector(
  selfCalibrationSelect,
  (data) => data.moreStoreListLoading
);

export const getAuditType = (state: IState): AuditType => state.rev.selfCalibration.auditType;

export const SelfCalibrationSelectors = {
  selfCalibrationError,
  selfCalibrationLoading,
  checkAuth,
  selectStoreAuditData,
  selectSubCategoryData,
  getDetailedReportId,
  getAnsweringYesNoResponse,
  getAnsweringYesNoResponseError,
  getObservationDataSuccess,
  getCommentData,
  getTippingPointsData,
  selectPreviousAuditData,
  sosAvgTimeData,
  updateCompletion,
  postSosAvgTimeData,
  getFeedbackData,
  getReportAuditId,
  getExternalMeta,
  postExternalAuditData,
  getStoresListByStoreCodeName,
  getStorelistByCodeIsEnd,
  getMoreSToreListLoading
};
