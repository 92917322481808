import React from "react";
import { StyleSheet } from "react-native";
import { fetchYMax, graphColors } from "@timhortons/common/src/utils/graphColorHelper";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { IndividualAccessSummaryDataModel } from "@timhortons/common/src/models/rev/reports";
import ReportSummaryTemplate from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/template/reportSummaryTemplate";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { storeRankingTableHeaderData } from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/momGraphHelper";

interface IProps {
  translate?: any;
  user: IUserDetails;
  summaryData: IndividualAccessSummaryDataModel;
  loading: boolean;
  error: string;
}

export default function AdminSummary(props: IProps) {
  const { translate, user, summaryData, loading, error } = props;

  const gradeBarChartData = {
    series: [
      {
        data: summaryData && summaryData.gradeDistribution.storeCount
      }
    ],
    columnWidth: "25",
    categories: summaryData && summaryData.gradeDistribution.label,
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Grades",
    yAxisTitle: "No of Restaurants",
    leftTitle: "Grade Distribution",
    rightTitle: "",
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "",
    ymax: fetchYMax(summaryData && summaryData.gradeDistribution?.storeCount, 25)
  };

  const avgScoreChartData = {
    series: [
      {
        data: summaryData && summaryData.averageScore.percentage
      }
    ],
    columnWidth: "25",
    categories: summaryData && summaryData.averageScore.name,
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Countries",
    yAxisTitle: "Percentage",
    leftTitle: "Average Score - GCC",
    rightTitle: `Avg.Score: ${(
      (summaryData && summaryData.averageScore.percentage.reduce((a, b) => a + b, 0)) /
      (summaryData && summaryData.averageScore.percentage.length)
    )?.toFixed(2)}%`,
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "%",
    ymax: fetchYMax(summaryData && summaryData.averageScore?.percentage, 25)
  };

  const series = summaryData && summaryData.actionPlansData.series;
  const labels = summaryData && summaryData.actionPlansData.labels;
  const totalReports = summaryData && summaryData.actionPlansData.totalReports;
  const inProcessReport = summaryData && summaryData.actionPlansData.inProcessReport;

  const fsDeductionGraphData = {
    series: [
      {
        data: summaryData && summaryData.fsDeductions.stores
      }
    ],
    columnWidth: "20",
    categories: summaryData && summaryData.fsDeductions.deductions,
    categoryColors: graphColors.fsDeductionGraph,
    xAxisTitle: "Deductions",
    yAxisTitle: "No. of Restaurants",
    leftTitle: "Critical Points Deductions - GCC",
    /** legends are not required after color code changes */
    // legends: [
    //   {
    //     key: "0",
    //     color: graphColors.fsDeductionGraphLabel[0]
    //   },
    //   {
    //     key: "-4 to -8",
    //     color: graphColors.fsDeductionGraphLabel[1]
    //   },
    //   {
    //     key: "-12 to -40",
    //     color: graphColors.fsDeductionGraphLabel[2]
    //   }
    // ],
    isFSDeduction: true,
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "",
    ymax: fetchYMax(summaryData && summaryData.fsDeductions?.stores, 25)
  };

  return error ? (
    <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
  ) : loading ? (
    <Loader type="component" />
  ) : (
    summaryData && (
      <>
        <ReportSummaryTemplate
          translate={translate}
          user={user}
          gradeBarChartData={gradeBarChartData}
          momBarChartData={avgScoreChartData}
          fsDeductionGraphData={fsDeductionGraphData}
          brandStandardKPIData={summaryData && summaryData.brandStandardKPIData}
          opportunityData={summaryData && summaryData.opportunityData}
          storeRankingTableHeaderData={storeRankingTableHeaderData(translate)}
          storeRankingsTopFiveTableData={summaryData && summaryData.storeRankingTopFiveData}
          storeRankingsLastFiveTableData={summaryData && summaryData.storeRankingLastFiveData}
          series={series}
          inProcessReport={inProcessReport}
          labels={labels}
          totalReports={totalReports}
          actionPlanGraphHeight={310}
          showTopAndLastFive={true}
          kpiHeaderTitle={translate("By Country")}
          storeRankingTableHeaderTitle={"Restaurant Ranking - GCC"}
        />
      </>
    )
  );
}

const styles = StyleSheet.create({
  fsGraphYAxisStyle: {
    alignSelf: "center",
    transform: [{ rotate: "270deg" }, { translateY: -275 }, { translateX: -150 }]
  }
});
