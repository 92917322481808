import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IndividualAccessActionsModel } from "@timhortons/common/src/models/logs/individualActionTable";
import { ManagerReviewTableModel } from "@timhortons/common/src/models/logs/mainLogs";
export interface IIndividualLogActionsLoading {
  getTableData: boolean;
  getLogsStoreSpecificData: boolean;
  getLogsTableData: boolean;
  getActionsStoreSpecificData: boolean;
}
export interface IIndividualLogActionsError {
  getTableData: string;
  getLogsStoreSpecificData: string;
  getLogsTableData: string;
  getActionsStoreSpecificData: string;
}
export interface IIndividualLogActionState {
  loading: IIndividualLogActionsLoading;
  error: IIndividualLogActionsError;
  getTableData: IndividualAccessActionsModel;
  getLogsStoreSpecificData: Array<ManagerReviewTableModel>;
  getLogsTableData: IndividualAccessActionsModel;
  getActionsStoreSpecificData: any;
}
export const individualLogsActionState: IIndividualLogActionState = {
  getTableData: null,
  getLogsStoreSpecificData: null,
  getLogsTableData: null,
  getActionsStoreSpecificData: null,
  loading: {
    getTableData: false,
    getLogsStoreSpecificData: false,
    getLogsTableData: false,
    getActionsStoreSpecificData: false
  },
  error: {
    getTableData: null,
    getLogsStoreSpecificData: null,
    getLogsTableData: null,
    getActionsStoreSpecificData: null
  }
};

export const individualLogActionReducer = (
  state = individualLogsActionState,
  action: { payload: any; type: string }
): IIndividualLogActionState => {
  switch (action.type) {
    case LOGS.IndividualLogActions.GET_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTableData: true },
        error: { ...state.error, getTableData: null }
      };
    case LOGS.IndividualLogActions.GET_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTableData: false },
        error: { ...state.error, getTableData: null },
        getTableData: action.payload
      };
    case LOGS.IndividualLogActions.GET_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTableData: false },
        error: { ...state.error, getTableData: action.payload }
      };
    case LOGS.IndividualLogActions.GET_LOGS_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLogsTableData: true },
        error: { ...state.error, getLogsTableData: null }
      };
    case LOGS.IndividualLogActions.GET_LOGS_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLogsTableData: false },
        error: { ...state.error, getLogsTableData: null },
        getLogsTableData: action.payload
      };
    case LOGS.IndividualLogActions.GET_LOGS_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLogsTableData: false },
        error: { ...state.error, getLogsTableData: action.payload }
      };
    case LOGS.IndividualLogActions.GET_LOGS_STORE_SPECIFIC_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLogsStoreSpecificData: true },
        error: { ...state.error, getLogsStoreSpecificData: null }
      };
    case LOGS.IndividualLogActions.GET_LOGS_STORE_SPECIFIC_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLogsStoreSpecificData: false },
        error: { ...state.error, getLogsStoreSpecificData: null },
        getLogsStoreSpecificData: action.payload
      };
    case LOGS.IndividualLogActions.GET_LOGS_STORE_SPECIFIC_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLogsStoreSpecificData: false },
        error: { ...state.error, getLogsStoreSpecificData: action.payload }
      };
    case LOGS.IndividualLogActions.GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionsStoreSpecificData: true },
        error: { ...state.error, getActionsStoreSpecificData: null }
      };
    case LOGS.IndividualLogActions.GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getActionsStoreSpecificData: false },
        error: { ...state.error, getActionsStoreSpecificData: null },
        getActionsStoreSpecificData: action.payload
      };
    case LOGS.IndividualLogActions.GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionsStoreSpecificData: false },
        error: { ...state.error, getActionsStoreSpecificData: action.payload }
      };
    default:
      return state;
  }
};
