import React, { ReactElement, useState } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RevReportsActions } from "@timhortons/common/src/redux/rev/actions/reportsAction";
import { RevReportsSelectors } from "@timhortons/common/src/redux/rev/selectors/reportsSelector";
import ReportsTableHeader from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsTableHeader";
import ReportAuditTable from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportAuditTable";
import { IState } from "@timhortons/common/src/store/interfaces";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import {
  IRevReportsError,
  IRevReportsLoading
} from "@timhortons//common/src/redux/rev/reducers/reportsReducer";
import { ReportTableResponseModel } from "@timhortons/common/src/models/rev/reports";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import Summary from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
// import ReportBuilder, { builderLevel } from "@timhortons/common/src/components/organisms/builder";

interface IProps {
  reportTableData: ReportTableResponseModel;
  getReportTableData?: Function;
  translate?: any;
  currentUserRoles: IUserDetails;
  history: any;
  loading: IRevReportsLoading;
  error: IRevReportsError;
  getSelectedMonthFilter: Function;
}

export interface IReportTableData {
  userId: string;
  storeId: number;
  pageNumber: number;
  sortOrder: string;
  pageSize: number;
  visitType: string;
  fromDate: Date;
  toDate: Date;
  search: string;
  isIndividualLogin: boolean;
  columnName: string;
}

export enum ReportTable {
  audits = "Audits",
  summary = "Summary",
  reportBuilder = "Report Builder"
}

function Reports(props: IProps): ReactElement {
  const {
    translate,
    reportTableData,
    getReportTableData,
    currentUserRoles,
    history,
    loading,
    error,
    getSelectedMonthFilter
  } = props;
  const reportApiData: IReportTableData = {
    userId: currentUserRoles && currentUserRoles.empId,
    storeId: currentUserRoles && currentUserRoles.storeCode,
    pageNumber: 1,
    sortOrder: "desc",
    pageSize: 10,
    fromDate: null,
    toDate: null,
    search: "",
    visitType: "",
    isIndividualLogin: false,
    columnName: ""
  };
  const [finalReportData, setReportData] = useState(reportApiData);
  const [currentTable, setCurrentTable] = useState<ReportTable>(ReportTable.audits);
  const [isIndividualLogin, setIndividualLogin] = useState(false);

  const handleRedirection = (calibrationId: number, reportId: number): void => {
    let searchParams = { calibrationId: calibrationId, reportId: reportId };
    navigation(history, Pathnames.detailedReports, searchParams, currentUserRoles);
  };

  return (
    <View style={styles.container}>
      <ReportsTableHeader
        currentUserRoles={currentUserRoles}
        currentTable={currentTable}
        setCurrentTable={setCurrentTable}
        translate={translate}
        isIndividualLogin={isIndividualLogin}
        setReportData={setReportData}
        getReportTableData={getReportTableData}
        finalReportData={finalReportData}
        getSelectedMonthFilter={getSelectedMonthFilter}
      />
      <View style={styles.tableContainer}>
        {
          (currentTable === ReportTable.audits && (
            <ReportAuditTable
              setReportData={setReportData}
              getReportTableData={getReportTableData}
              setCurrentTable={setCurrentTable}
              setIndividualLogin={setIndividualLogin}
              // reportApiData={reportApiData}
              history={history}
              finalReportData={finalReportData}
              translate={translate}
              reportTableData={reportTableData}
              currentUserRoles={currentUserRoles}
              loading={loading.reportTableData}
              error={error.reportTableData}
              handleViewIcon={handleRedirection}
            />
          )) ||
            (currentTable === ReportTable.summary && (
              <>
                <Summary translate={translate} user={currentUserRoles} />
              </>
            ))
          //  || (
          //   <ReportBuilder
          //     user={currentUserRoles}
          //     translate={translate}
          //     history={history}
          //     isLp={false}
          //     />
          //     )
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    marginTop: 30
  },
  tableContainer: {
    flex: 1,
    marginTop: 16,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  }
});

const mapStateToProps = (state: IState) => {
  return {
    loading: RevReportsSelectors.revReportsLoading(state),
    error: RevReportsSelectors.revReportsError(state),
    reportTableData: RevReportsSelectors.fetchReportTableData(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...RevReportsActions }, dispatch) };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
