import React from "react";
import { StyleProp, ViewStyle, TextStyle } from "react-native";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Loader from "@timhortons/common/src/components/molecules/loader";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";

export const displayComponent = (
  component: JSX.Element,
  translate: any,
  loader?: boolean,
  error?: string
): JSX.Element => {
  return loader ? (
    <Loader type="component" />
  ) : error?.length > 0 ? (
    <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
  ) : (
    component
  );
};

export const getText = (
  text: string,
  textSize: TextSize,
  fontWeight: FontWeight,
  textStyle?: StyleProp<ViewStyle> | StyleProp<TextStyle>
) => {
  return (
    <Text textSize={textSize} fontWeight={fontWeight} testId={"logs"} textStyle={textStyle}>
      {text}
    </Text>
  );
};

export const showError = (
  val: any,
  isRequired: boolean,
  showWarnings: boolean,
  label?: string,
  isValid?: boolean
) => {
  return (
    <>
      {(val?.length === 0 || val === "") &&
        showWarnings &&
        isRequired &&
        getText("Required", TextSize.ExtraFineSmall, FontWeight.Light, { color: "red" })}
      {label &&
        label === "empId" &&
        (((val?.length !== 0 || val !== "") && val?.length < 5 && isRequired) ||
          (!/^\d+$/.test(val?.trim()) && val?.length > 0)) &&
        getText(
          "Emp id should be of minimum 5 characters and only digits",
          TextSize.ExtraFineSmall,
          FontWeight.Light,
          {
            color: "red"
          }
        )}
      {label &&
        label === "phoneNumber" &&
        (val?.length !== 0 || val !== "") &&
        !isValid &&
        isRequired &&
        getText("Enter correct phone number", TextSize.ExtraFineSmall, FontWeight.Light, {
          color: "red"
        })}
      {label &&
        label === "email" &&
        (val?.length !== 0 || val !== "") &&
        !isValid &&
        getText("Enter correct email", TextSize.ExtraFineSmall, FontWeight.Light, {
          color: "red"
        })}
      {label &&
        label === "firstName" &&
        (val?.length !== 0 || val !== "") &&
        !(val?.trim()?.length >= 3) &&
        isRequired &&
        getText(
          "First Name should be of minimum 3 characters",
          TextSize.ExtraFineSmall,
          FontWeight.Light,
          {
            color: "red"
          }
        )}
    </>
  );
};
