import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import MainRouter from "@timhortons/web/src/navigation/mainRouter";
import store from "@timhortons/common/src/store/store";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { StoreProviderService } from "@timhortons/common/src/services/StoreProviderService";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { SupportedLanguages } from "@timhortons/common/src/services/internalization/constants";
import "./App.css";
import "@timhortons/common/src/services/rollBarObject";
import ErrorBoundary from "@timhortons/common/src/hoc/errorBoundary/errorBoundary";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { ConfigHelper } from "@timhortons/common/src/utils/loadEnv.util";

// Initialize store
StoreProviderService.init(store);
const reduxStore = StoreProviderService.getStore();
// const firebaseConfig = {
//   apiKey: "AIzaSyAzumbYBQ1QnWe29BXQb3ohA5Z9IEquGgw",
//   authDomain: "thi-academy-96ae5.firebaseapp.com",
//   projectId: "thi-academy-96ae5",
//   storageBucket: "thi-academy-96ae5.appspot.com",
//   messagingSenderId: "215475416060",
//   appId: "1:215475416060:web:7fa6d0c7f6d8f99a34a079"
// };
const App = () => {
  const [isLocalizationInitialised, setLocale] = useState(false);

  useEffect(() => {
    initLang().then();

    // const firebaseApp = initializeApp(firebaseConfig);
    // const messaging = getMessaging(firebaseApp);
    // onMessage(messaging, (payload) => {
    //   console.log("Message received. ", payload);
    // });
  }, []);

  const initLang = async (): Promise<void> => {
    const storedLang: SupportedLanguages = await LocalStorage.getStorage("lang");
    await ApiClient.setUrl("/api");
    if (!storedLang) {
      LocalStorage.setStorage("lang", SupportedLanguages.English).then();
      I18nService.init(SupportedLanguages.English).then();
    } else {
      await I18nService.init(storedLang);
    }
    setLocale(true);
  };

  if (!isLocalizationInitialised) return null;
  return (
    <ErrorBoundary>
      <Provider store={reduxStore}>
        <React.Fragment>
          <MainRouter />
        </React.Fragment>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
