import React from "react";
import { View, StyleSheet } from "react-native";
import Card from "@timhortons/common/src/components/atoms/card";
import { IBarChartOptions } from "@timhortons/web/src/components/molecules/barChart";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
const BarChartGroup = React.lazy(() => import("@timhortons/web/src/components/molecules/barChart"));
import MobileBarChartGroup, {
  NumberOfBars
} from "@timhortons/tablet/src/components/molecules/barChart";
interface IProps {
  brandStandardGraphData: IBarChartOptions;
  SoSGraphData: IBarChartOptions;
}
export default function BrandStandardBarGraph(props: IProps) {
  const { brandStandardGraphData, SoSGraphData } = props;
  return (
    <View style={styles.container}>
      <Card.Body bodyStyle={styles.bodyContainer}>
        {PlatformUtils.isWeb() ? (
          <View style={styles.graphContainer}>
            <View style={styles.bsGraphContainer}>
              <React.Suspense fallback={null}>
                <BarChartGroup barChartData={brandStandardGraphData} />
              </React.Suspense>
            </View>
            <Divider orientation={Orientations.Vertical} dividerStyle={styles.divider} />
            <View style={styles.sosGraphContainer}>
              <React.Suspense fallback={null}>
                <BarChartGroup barChartData={SoSGraphData} />
              </React.Suspense>
            </View>
          </View>
        ) : (
          <View style={styles.graphContainer}>
            <View style={styles.bsGraphContainer}>
              <MobileBarChartGroup
                barChartData={brandStandardGraphData}
                barWidthSpace={NumberOfBars.SixBars}
              />
            </View>
            <Divider orientation={Orientations.Vertical} dividerStyle={styles.divider} />
            <View style={styles.sosGraphContainer}>
              <MobileBarChartGroup
                barChartData={SoSGraphData}
                barWidthSpace={NumberOfBars.TwoBars}
              />
            </View>
          </View>
        )}
      </Card.Body>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colorPallete.grey7,
    borderRadius: 4
  },
  bodyContainer: {},
  graphContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  bsGraphContainer: {
    width: "70%"
  },
  sosGraphContainer: {
    width: "30%"
  },
  divider: {
    borderStyle: "dashed",
    marginVertical: 35,
    borderRadius: 1,
    borderWidth: 1,
    borderColor: colorPallete.grey7
  }
});
