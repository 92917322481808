import React, { ReactElement, useEffect, useState } from "react";
import { View } from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { History } from "history";
import { useTranslation } from "react-i18next";
import { IState } from "@timhortons/common/src/store/interfaces";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Divider } from "@timhortons/common/src/components/atoms/divider";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IItem } from "@timhortons/common/src/components/molecules/multiSelect";
import { builderTemplateStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/builder/template/builderTemplateStyles";
import {
  builderLevel,
  ComparsionLevel
} from "@timhortons/common/src/modules/reportBuilders/components/lms/index";
import LMSReportType from "@timhortons/common/src/modules/reportBuilders/components/lms/organisms/reportType";
import LMSDataInReport from "@timhortons/common/src/modules/reportBuilders/components/lms/organisms/dataInReport";
import LmsLevelOfReports, {
  IPrevReportData,
  IReportData
} from "@timhortons/common/src/modules/reportBuilders/components/lms/organisms/levelOfReports/index";
import useFetchMoreItems from "@timhortons/common/src/utils/useFetchMoreItems";
import { Roles, CEOEqvRoles, SuperAdminEqvRoles } from "@timhortons/common/src/constants/roles";
import { DetailedLmsReportsActions } from "@timhortons/common/src/redux/lmsReports/actions/detailedLmsReportsActions";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  ComparisonStoresAdminModel,
  CountryDataListModel
} from "models/profile";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { AllAreaManagersListDataModel } from "@timhortons/common/src/models/lp/reports";
import { ReportsBuilderActions } from "@timhortons/common/src/redux/reportBuilder/actions/reportsAction";
import { reportsBuilderSelectors } from "@timhortons/common/src/redux/reportBuilder/selectors/reportsSelector";
import {
  IReportsBuilderError,
  IReportsBuilderLoading
} from "@timhortons/common/src/redux/reportBuilder/reducers/reportsReducer";
import { CountryDataModel } from "@timhortons/common/src/models/profile";
import { ILPDataInReportData } from "@timhortons/common/src/components/organisms/builder/organisms/dataInReport";
import { detailedLmsReportsSelectors } from "@timhortons/common/src/redux/lmsReports/selectors/detailedLmsReportsSelector";
import {
  AllModulesDataModel,
  AllModulesrResponseModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";

interface IProps {
  translate?: any;
  user: IUserDetails;
  detailedReportPermissions: ComparsionLevel[];
  history?: History;
  loading: IReportsBuilderLoading;
  getAllCountriesLoading: Function;
  getAllAreaManagerLoading: Function;
  getAllAreaTrainerLoading: Function;
  getAllOpsManagerLoading: Function;
  getAllChannelsLoading: Function;
  getAllStoresLoading: Function;
  allCountries: CountryDataListModel;
  error: IReportsBuilderError;
  allAreaManagers: AllAreaManagersListDataModel;
  allAreaTrainer: any;
  allChannels: ChannelsListDataModel;
  allStores: AllStoresListDataModel;
  allCountryManagers: AllAreaManagersListDataModel;
  allClusterHeadOfOps: any;
  allLpAuditor: any;
  getAllCountryManagerLoading: Function;
  getAllClusterHeadOfOpsLoading: Function;
  // getAllLpAuditorLoading: Function;
  comparisonCountryManagers: any;
  comparisonClusterHeadOfOps: any;
  comparisonAreaManagers: any;
  comparisonStoresAdmin: ComparisonStoresAdminModel;
  getComparisonCountryManagersAdmin: Function;
  comparisonStoresAdminLoading: Function;
  resetReportBuilder: Function;
  getHighLowChannelsAdminData: ChannelsListDataModel;
  allOpsManagers: any;
  moduleType: string;
  getAllModules: Function;
  allModulesData: AllModulesrResponseModel;
}
export enum ReportTypeLevel {
  Detailed = "DETAILED",
  Comparison = "COMPARISON",
  HighLow = "HIGHEST_TO_LOWEST",
  Grading = "GRADING",
  ConsData = "CONS. DATA"
}
export enum DateRangeTypes {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  YearDate = "Year-to-Date",
  SpecificDate = "Specific Date",
  Yearly = "Yearly"
}
export enum LmsDataInReportLevel {
  coreTraining = "CORE_TRAINING",
  specialTraining = "SPECIAL_TRAINING",
  theoreticalScore = "THEORETICAL_SCORE",
  practicalStatus = "PRACTICAL_STATUS",
  moduleStatus = "MODULE_STATUS"
}

interface ISelectedDataOption {
  monthly: any;
  quarterly: any;
  yearDate: { startDate: Date; endDate: Date };
  specific: { startDate: Date; endDate: Date };
}
export interface ISelectAllValues {
  storesSelectAll: boolean;
  channelSelectAll: boolean;
  areaManagerSelectAll: boolean;
  areaTrainerSelectAll: boolean;
  opsManagerSelectAll: boolean;
  countryManagerSelectAll: boolean;
  countrySelectAll: boolean;
  clusterHeadOfOpsSelectAll: boolean;
}
interface IReportBuilderFinalData {
  selectedReportType: ReportTypeLevel;
  selectedDataOption: ISelectedDataOption;
  dataInReportValue: ILPDataInReportData;
  selectedDetailedData: IPrevReportData;
  selectedLevel: ComparsionLevel;
  selectedCountry: CountryDataModel;
  selectedUnitReportCountry: any;
  selectedUnitReportData: any;
  selectAllValues: ISelectAllValues;
}
export interface IPrevSelectedLORData {
  selectedUnitReportCountry: any;
  selectedUnitReportData: any;
  selectedLevel: ComparsionLevel;
  selectedCountry: CountryDataModel;
  selectAllValues: ISelectAllValues;
}

function LMSBuilderLayout(props: IProps): ReactElement {
  const {
    translate,
    user,
    detailedReportPermissions,
    history,
    loading,
    getAllCountriesLoading, // all countryh api
    allCountries,
    getAllAreaManagerLoading, // all area manager api
    getAllAreaTrainerLoading,
    getAllOpsManagerLoading,
    allAreaManagers,
    allAreaTrainer,
    getAllChannelsLoading, // all channels api
    getAllStoresLoading, //all stores api
    allCountryManagers,
    allClusterHeadOfOps,
    allLpAuditor,
    getAllCountryManagerLoading, //get all country managers
    allChannels,
    allStores,
    comparisonAreaManagers,
    getAllClusterHeadOfOpsLoading,
    // getAllLpAuditorLoading,
    comparisonCountryManagers,
    getComparisonCountryManagersAdmin, //comparision contry manager
    comparisonStoresAdmin, //data from selector
    resetReportBuilder,
    getHighLowChannelsAdminData, // data from selector
    allOpsManagers,
    moduleType,
    getAllModules,
    allModulesData
  } = props;

  const [selectedUnitReportCountry, setSelectedUnitReportCountry] = useState(null);
  const { t } = useTranslation("rev");
  const [selectedReportType, setSelectedReportType] = useState<ReportTypeLevel>(
    ReportTypeLevel.Detailed
  );
  const [selectedDataInReportLevel, setSelectedDataInReportLevel] = useState<LmsDataInReportLevel>(
    LmsDataInReportLevel.coreTraining
  );
  const [selectedDependentDataInReportLevel, setSelectedDependentDataInReportLevel] =
    useState<LmsDataInReportLevel>(LmsDataInReportLevel.theoreticalScore);
  const [selectedLevel, setSelectedLevel] = useState<ComparsionLevel>(
    user?.roles?.includes(Roles.SuperAdmin) ||
      user?.roles?.includes(Roles.TrainingAndDevLead) ||
      user?.roles?.includes(Roles.TrainingAndDevManager) ||
      user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
      user?.roles?.includes(Roles.HrAndAdminDirector) ||
      user?.roles?.includes(Roles.HeadLP) ||
      user?.roles?.includes(Roles.AuditorLP) ||
      user?.roles?.includes(Roles.CEO) ||
      user?.roles?.includes(Roles.COO)
      ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
        ? ComparsionLevel.Country
        : ComparsionLevel.ClusterHeadOfOps
      : user?.roles?.includes(Roles.ClusterHeadofOps)
      ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
        ? ComparsionLevel.Country
        : ComparsionLevel.OpsManager
      : user?.roles?.includes(Roles.CountryManager)
      ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
        ? ComparsionLevel.Channels
        : ComparsionLevel.OpsManager
      : user?.roles?.includes(Roles.AreaManager)
      ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
        ? ComparsionLevel.Channels
        : ComparsionLevel.AreaTrainer
      : user?.roles?.includes(Roles.OperationManager) ||
        user?.roles?.includes(Roles.SeniorOpsManager)
      ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
        ? ComparsionLevel.Channels
        : ComparsionLevel.AreaManager
      : user?.roles?.includes(Roles.AreaTrainer) && ComparsionLevel.AreaManager
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeTypes>(null);
  const [selectedDataOption, setSelectedDataOption] = useState<ISelectedDataOption>({
    monthly: null,
    quarterly: null,
    yearDate: {
      startDate: null,
      endDate: null
    },
    specific: {
      startDate: null,
      endDate: null
    }
  });

  const [selectedKPI, setSelectedKPI] = useState<IItem[]>([]);
  const [selectedAllModules, setSelectedAllModules] = useState<IItem[]>([]);
  const [comparisonReportData, setComparisonReportData] = useState<IReportData>(null);
  const [detailedReportData, setDetailedReportData] = useState<IReportData>({
    allCountries: [],
    allCountryManagers: [],
    allAreaManagers: [],
    allAreaTrainers: [],
    allOpsManagers: [],
    allChannels: [],
    allStores: [],
    allClusterHeadOfOps: [],
    allLpAuditor: []
  });
  const [selectedUnitReportData, setSelectedUnitReportData] = useState(null); // final selected data for comparsion & highlow reports
  const [selectedDetailedData, setSelectedDetailedData] = useState<IReportData>(null); // final selected data for detailed reports
  const [allModulesFinalData, setAllModulesFinalData] = useState<any>([]); // final selected data for detailed reports

  const [dataInReportValue, setdataInReportValue] = useState<ILPDataInReportData>({
    coreTraining: true,
    specialTraining: false,
    theoreticalScore: true,
    precticalStatus: false,
    moduleStatus: false
  });

  const [selectedCountryValue, setselectedCountryValue] = useState(null); // final selected data for comparsion & highlow reports
  const [consolidatedDate, setConsolidatedDates] = useState({
    startDate: null,
    endDate: null
  });
  const [storesFetch, setAllStoresFetch] = useState(false);
  const [allModulesFetch, setAllModulesFetch] = useState(false);

  const [channelFetch, setAllChannelFetch] = useState(false);
  const [areaManagerFetch, setAllAreaManagerFetch] = useState(false);
  const [areaTrainerFetch, setAllAreaTrainerFetch] = useState(false);
  const [opsManagerFetch, setAllOpsManagerFetch] = useState(false);
  const [countryManagerFetch, setAllCountryManagerFetch] = useState(false);
  const [clusterHeadOfOpsFetch, setAllClusterHeadOfOpsFetch] = useState(false);
  const [countryFetch, setAllCountryFetch] = useState(false);
  const [lpAuditorFetch, setAllLpAuditorFetch] = useState(false);
  const [storesSelectAll, setStoresSelectAll] = useState(false);
  const [channelSelectAll, setChannelSelectAll] = useState(false);
  const [areaManagerSelectAll, setAreaManagerSelectAll] = useState(false);
  const [areaTrainerSelectAll, setAreaTrainerSelectAll] = useState(false);
  const [opsManagerSelectAll, setOpsManagerSelectAll] = useState(false);
  const [countryManagerSelectAll, setCountryManagerSelectAll] = useState(false);
  const [countrySelectAll, setCountrySelectAll] = useState(false);
  const [clusterHeadOfOpsSelectAll, setClusterHeadOfOpsSelectAll] = useState(false);
  const [lpAuditorSelectAll, setLpAuditorSelectAll] = useState(false);
  const [countrySearchText, setCountrySearchText] = useState("");
  const [allModulesSearchText, setAllModulesSearchText] = useState("");
  const [resetAllModulesSearchText, setResetAllModulesSearchText] = useState(false);

  const [storeSearchText, setStoreSearchText] = useState("");
  const [channelSearchText, setChannelSearchText] = useState("");
  const [countryManagerSearchText, setCountryManagerSearchText] = useState("");
  const [clusterHeadOfOPsSearchText, setClusterHeadOfOPsSearchText] = useState("");
  const [areaManagerSearchText, setAreaManagerSearchText] = useState("");
  const [areaTrainerSearchText, setAreaTrainerSearchText] = useState("");
  const [opsManagerSearchText, setOpsManagerSearchText] = useState("");
  const [lpAuditorSearchText, setLpAuditorSearchText] = useState("");
  const [countryPage, setCountryPage] = useState(0);
  const [channelPage, setChannelsPage] = useState(0);
  const [storesPage, setStoresPage] = useState(0);
  const [areaManagerPage, setAreaManagerPage] = useState(0);
  const [areaTrainerPage, setAreaTrainerPage] = useState(0);
  const [opsManagerPage, setOpsManagerPage] = useState(0);
  const [countryManagerPage, setCountryManagerPage] = useState(0);
  const [clusterHeadOfOpsPage, setClusterHeadOfOpsPage] = useState(0);
  const [lpAuditorPage, setLpAuditorPage] = useState(0);
  const [allModulesPage, setAllModulesPage] = useState(0);
  const [prevSelectedDetailedData, setPrevSelectedDetailedData] = useState<IPrevReportData>(null); // final selected data for detailed reports
  const [prevSelectedLORData, setPrevSelectedLORData] = useState<IPrevSelectedLORData>(null); // final selected data for reports(other than detailed)
  const [isResetRequired, setIsResetRequired] = useState(false);
  const [reqSearch, setReqSearch] = useState("");
  const [allModulesReqSearch, setAllModulesReqSearch] = useState("");

  const [reqSearchDetailed, setReqSearchDetailed] = useState({
    countries: "",
    countryManagers: "",
    areaManagers: "",
    areaTrainer: "",
    opsManagers: "",
    channels: "",
    stores: "",
    clusterHeadOfOps: "",
    lpAuditors: ""
  });
  useEffect(() => {
    setselectedCountryValue(null);
  }, [selectedReportType, selectedLevel]);

  useEffect(() => {
    if (allModulesData?.content && allModulesData?.currentPage === 0) {
      setAllModulesFinalData(allModulesData?.content);
    }
  }, [allModulesData]);
  useEffect(() => {
    // setAllModulesFinalData([]);
    setAllModulesPage(0);
  }, [allModulesSearchText]);
  useEffect(() => {
    setResetAllModulesSearchText(true);
    setAllModulesReqSearch("");
    setAllModulesSearchText("");
    setAllModulesPage(0);
  }, [selectedDataInReportLevel, selectedDependentDataInReportLevel]);
  useEffect(() => {
    getAllModules({
      trainingType: selectedDataInReportLevel,
      pageNumber: allModulesPage,
      pageSize: 10,
      search: allModulesSearchText,
      isPracticalStatus: selectedDependentDataInReportLevel === LmsDataInReportLevel.practicalStatus
    });
  }, [
    selectedDataInReportLevel,
    selectedReportType,
    allModulesPage,
    allModulesSearchText,
    selectedDependentDataInReportLevel === LmsDataInReportLevel.practicalStatus
  ]);
  const [code, setCode] = useState([]);
  useEffect(() => {
    setReqSearch("");
    setIsResetRequired(true);
    if (selectedReportType === ReportTypeLevel.Detailed) {
      setReqSearchDetailed({
        ...reqSearchDetailed,
        countryManagers: "",
        areaManagers: "",
        areaTrainer: "",
        opsManagers: "",
        channels: "",
        stores: "",
        clusterHeadOfOps: "",
        lpAuditors: ""
      });
    }
  }, [selectedCountryValue, selectedLevel, selectedDetailedData?.allCountries]);
  useEffect(() => {
    setSelectedLevel(
      user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO)
        ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
          ? ComparsionLevel.Country
          : ComparsionLevel.ClusterHeadOfOps
        : user?.roles?.includes(Roles.ClusterHeadofOps)
        ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
          ? ComparsionLevel.Country
          : ComparsionLevel.OpsManager
        : user?.roles?.includes(Roles.CountryManager)
        ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
          ? ComparsionLevel.Channels
          : ComparsionLevel.OpsManager
        : user?.roles?.includes(Roles.AreaManager)
        ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
          ? ComparsionLevel.Channels
          : ComparsionLevel.AreaTrainer
        : user?.roles?.includes(Roles.OperationManager) ||
          user?.roles?.includes(Roles.SeniorOpsManager)
        ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
          ? ComparsionLevel.Channels
          : ComparsionLevel.AreaManager
        : user?.roles?.includes(Roles.AreaTrainer) && ComparsionLevel.AreaManager
    );
  }, [selectedDependentDataInReportLevel]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.Detailed) {
      setClusterHeadOfOpsPage(0);
      setLpAuditorPage(0);
      setOpsManagerPage(0);
      setStoresPage(0);
      setCountryManagerPage(0);
      setAreaManagerPage(0);
      setAreaTrainerPage(0);
      setChannelsPage(0);
      setChannelSearchText("");
      setStoreSearchText("");
      setAreaManagerSearchText("");
      setAreaTrainerSearchText("");
      setCountryManagerSearchText("");
      setOpsManagerSearchText("");
      setClusterHeadOfOPsSearchText("");
      setLpAuditorSearchText("");
      setReqSearch("");
      setIsResetRequired(true);
    }
  }, [selectedDetailedData?.allCountries]);

  useEffect(() => {
    setCountrySearchText("");
    setChannelSearchText("");
    setStoreSearchText("");
    setAreaManagerSearchText("");
    setAreaTrainerSearchText("");
    setCountryManagerSearchText("");
    setOpsManagerSearchText("");
    setClusterHeadOfOPsSearchText("");
    setLpAuditorSearchText("");
  }, [selectedLevel]);

  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      if (selectedLevel === ComparsionLevel.Country) {
        setCountryPage(0), setDetailedReportData({ allCountries: [] });
      }
    }
  }, [countrySearchText]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.HighLow) {
      if (selectedLevel === ComparsionLevel.Channels) {
        setChannelsPage(0), setDetailedReportData({ allChannels: [] });
      }
    }
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (selectedLevel === ComparsionLevel.Channels) {
        setChannelsPage(0);
      }
    }
  }, [channelSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      if (selectedLevel === ComparsionLevel.Stores) {
        setStoresPage(0),
          setDetailedReportData((prevState: any) => ({
            ...prevState,
            allStores: []
          }));
      }
    }
  }, [storeSearchText, selectedCountryValue]);

  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      if (selectedLevel === ComparsionLevel.RestaurantEmployees) {
        setStoresPage(0),
          setDetailedReportData((prevState: any) => ({
            ...prevState,
            allStores: []
          }));
      }
    }
  }, [storeSearchText, selectedCountryValue]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.HighLow) {
      if (selectedLevel === ComparsionLevel.AreaManager) {
        setAreaManagerPage(0), setDetailedReportData({ allAreaManagers: [] });
      }
    }
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (selectedLevel === ComparsionLevel.AreaManager) {
        setAreaManagerPage(0);
      }
    }
  }, [areaManagerSearchText, selectedCountryValue]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.HighLow) {
      if (selectedLevel === ComparsionLevel.AreaTrainer) {
        setAreaTrainerPage(0), setDetailedReportData({ allAreaTrainers: [] });
      }
    }
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (selectedLevel === ComparsionLevel.AreaTrainer) {
        setAreaTrainerPage(0);
      }
    }
  }, [areaTrainerSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      if (selectedLevel === ComparsionLevel.CountryManager) {
        setCountryManagerPage(0), setDetailedReportData({ allCountryManagers: [] });
      }
    }
  }, [countryManagerSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
        setClusterHeadOfOpsPage(0), setDetailedReportData({ allClusterHeadOfOps: [] });
      }
    }
  }, [clusterHeadOfOPsSearchText, selectedCountryValue]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.HighLow) {
      if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
        setClusterHeadOfOpsPage(0);
      }
    }
  }, [selectedLevel, selectedReportType]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      if (selectedLevel === ComparsionLevel.LpAuditor) {
        setLpAuditorPage(0), setDetailedReportData({ allLpAuditor: [] });
      }
    }
  }, [lpAuditorSearchText, selectedLevel, selectedReportType, selectedCountryValue]);

  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      if (selectedLevel === ComparsionLevel.OpsManager) {
        setOpsManagerPage(0),
          setDetailedReportData((prevState: any) => ({
            ...prevState,
            allOpsManagers: []
          }));
      }
    }
  }, [opsManagerSearchText, selectedCountryValue]);
  const getCodes = () => {
    let newArray: any[] = [];
    // to set Codes for channels dropdown in case of Detailed Report
    if (selectedDetailedData && selectedDetailedData.allCountries) {
      if (selectedDetailedData.allCountries.length !== 0) {
        selectedDetailedData?.allCountries?.forEach((item) => {
          newArray.push(item.countryCode);
          setCode(newArray);
          return newArray;
        });
      } else {
        setCode(newArray);
        return newArray;
      }
    }
    return newArray;
  };
  // useEffect(() => {
  //     setselectedCountryValue(null);
  // }, [selectedReportType, selectedLevel]);
  useEffect(() => {
    setAreaManagerSearchText("");
    setAreaTrainerSearchText("");
    setOpsManagerSearchText("");
    setChannelSearchText("");
    setStoreSearchText("");
    setLpAuditorSearchText("");
  }, [selectedCountryValue]);
  const getCountries = (pageNo: number, pageSize: number) => {
    if (pageNo === allCountries?.currentPage) {
      return allCountries && allCountries.content;
    }
  };
  const getChannel = (pageNo: number, pageSize: number, isHighLow?: boolean) => {
    if (isHighLow) {
      if (pageNo === getHighLowChannelsAdminData?.currentPage) {
        return getHighLowChannelsAdminData && getHighLowChannelsAdminData.content;
      }
    } else {
      if (pageNo === allChannels?.currentPage) {
        return allChannels && allChannels.content;
      }
    }
  };
  const getAreaManager = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === allAreaManagers?.currentPage) {
        return allAreaManagers && allAreaManagers.content;
      }
    } else {
      if (pageNo === allAreaManagers?.currentPage) {
        return allAreaManagers && allAreaManagers.content;
      }
    }
  };
  const getAreaTrainer = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === allAreaTrainer?.currentPage) {
        return allAreaTrainer && allAreaTrainer.content;
      }
    } else {
      if (pageNo === allAreaTrainer?.currentPage) {
        return allAreaTrainer && allAreaTrainer.content;
      }
    }
  };
  const getCountryManager = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === comparisonCountryManagers?.currentPage) {
        return comparisonCountryManagers && comparisonCountryManagers.content;
      }
    } else {
      if (pageNo === allCountryManagers?.currentPage) {
        return allCountryManagers && allCountryManagers.content;
      }
    }
  };
  const getClusterHeadOfOps = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === allClusterHeadOfOps?.currentPage) {
        return allClusterHeadOfOps && allClusterHeadOfOps.content;
      }
    } else {
      if (pageNo === allClusterHeadOfOps?.currentPage) {
        return allClusterHeadOfOps && allClusterHeadOfOps.content;
      }
    }
  };
  const getLpAuditor = (pageNo: number, pageSize: number, isHighLow?: boolean) => {
    if (pageNo === allLpAuditor?.currentPage) {
      return allLpAuditor && allLpAuditor.content;
    }
  };
  const getOpsManager = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (pageNo === allOpsManagers?.currentPage) {
      return allOpsManagers && allOpsManagers.content;
    }
  };
  const allModules = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (pageNo === allModulesData?.currentPage) {
      return allModulesData && allModulesData.content;
    }
  };
  const getStores = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === comparisonStoresAdmin?.currentPage) {
        return comparisonStoresAdmin && comparisonStoresAdmin.content;
      }
    } else {
      if (pageNo === allStores?.currentPage) {
        return allStores && allStores.content;
      }
    }
  };
  const countryFlatlist = useFetchMoreItems(
    getCountries,
    countryFetch,
    countryPage,
    setCountryPage,
    setAllCountryFetch,
    null,
    allCountries?.totalElements,
    true
  );
  const channelFlatlist = useFetchMoreItems(
    getChannel,
    channelFetch,
    channelPage,
    setChannelsPage,
    setAllChannelFetch,
    false,
    allChannels?.totalElements,
    true
  );
  const areaManagerFlatlist = useFetchMoreItems(
    getAreaManager,
    areaManagerFetch,
    areaManagerPage,
    setAreaManagerPage,
    setAllAreaManagerFetch,
    false,
    allAreaManagers?.totalElements,
    true
  );
  const areaTrainerFlatlist = useFetchMoreItems(
    getAreaTrainer,
    areaTrainerFetch,
    areaTrainerPage,
    setAreaTrainerPage,
    setAllAreaTrainerFetch,
    false,
    allAreaTrainer?.totalElements,
    true
  );
  const comparisionAreaManagerFlatlist = useFetchMoreItems(
    getAreaManager,
    areaManagerFetch,
    areaManagerPage,
    setAreaManagerPage,
    setAllAreaManagerFetch,
    user?.roles?.includes(Roles.ClusterHeadofOps) ? false : true,
    allAreaManagers?.totalElements,
    true
  );
  const countryManagerFlatlist = useFetchMoreItems(
    getCountryManager,
    countryManagerFetch,
    countryManagerPage,
    setCountryManagerPage,
    setAllCountryManagerFetch,
    false,
    allCountryManagers?.totalElements,
    true
  );
  const clusterHeadOfOpsFlatlist = useFetchMoreItems(
    getClusterHeadOfOps,
    clusterHeadOfOpsFetch,
    clusterHeadOfOpsPage,
    setClusterHeadOfOpsPage,
    setAllClusterHeadOfOpsFetch,
    false,
    allClusterHeadOfOps?.totalElements,
    true
  );
  const lpAuditorFlatlist = useFetchMoreItems(
    getLpAuditor,
    lpAuditorFetch,
    lpAuditorPage,
    setLpAuditorPage,
    setAllLpAuditorFetch,
    false,
    allLpAuditor?.totalElements,
    true
  );
  const comparisionCountryManagerFlatlist = useFetchMoreItems(
    getCountryManager,
    countryManagerFetch,
    countryManagerPage,
    setCountryManagerPage,
    setAllCountryManagerFetch,
    false,
    allCountryManagers?.totalElements,
    true
  );
  const opsManagerFlatList = useFetchMoreItems(
    getOpsManager,
    opsManagerFetch,
    opsManagerPage,
    setOpsManagerPage,
    setAllOpsManagerFetch,
    true,
    allOpsManagers?.totalElements,
    true
  );
  const storeFlatlist = useFetchMoreItems(
    getStores,
    storesFetch,
    storesPage,
    setStoresPage,
    setAllStoresFetch,
    false,
    allStores?.totalElements,
    true
  );
  const allModulesFlatlist = useFetchMoreItems(
    allModules,
    allModulesFetch,
    allModulesPage,
    setAllModulesPage,
    setAllModulesFetch,
    false,
    allModulesData?.totalElements,
    true
  );
  const highlowChannelsFlatlist = useFetchMoreItems(
    getChannel,
    channelFetch,
    channelPage,
    setChannelsPage,
    setAllChannelFetch,
    true,
    getHighLowChannelsAdminData?.totalElements,
    true
  );
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allCountries: countryFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allCountries: countryFlatlist.arrayItems
    }));
  }, [countryFlatlist.arrayItems]);

  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allChannels: channelFlatlist.arrayItems
    }));
  }, [channelFlatlist.arrayItems]);
  useEffect(() => {
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allChannels: channelFlatlist.arrayItems
    }));
  }, [channelFlatlist.arrayItems]);

  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allStores: storeFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allStores: storeFlatlist.arrayItems
    }));
  }, [storeFlatlist.arrayItems]);

  useEffect(() => {
    setAllModulesFinalData(allModulesFlatlist.arrayItems);
  }, [allModulesFlatlist.arrayItems]);

  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allOpsManagers: opsManagerFlatList.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allOpsManagers: opsManagerFlatList.arrayItems
    }));
  }, [opsManagerFlatList.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allCountryManagers: countryManagerFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allCountryManagers: countryManagerFlatlist.arrayItems
    }));
  }, [countryManagerFlatlist.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allCountryManagers: comparisionCountryManagerFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allCountryManagers: comparisionCountryManagerFlatlist.arrayItems
    }));
  }, [comparisionCountryManagerFlatlist.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allAreaManagers: areaManagerFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allAreaManagers: areaManagerFlatlist.arrayItems
    }));
  }, [areaManagerFlatlist.arrayItems]);

  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allAreaTrainers: areaTrainerFlatlist?.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allAreaTrainers: areaTrainerFlatlist?.arrayItems
    }));
  }, [areaTrainerFlatlist.arrayItems]);
  useEffect(() => {
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allAreaManagers: comparisionAreaManagerFlatlist.arrayItems
    }));
  }, [comparisionAreaManagerFlatlist.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allClusterHeadOfOps: clusterHeadOfOpsFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allClusterHeadOfOps: clusterHeadOfOpsFlatlist.arrayItems
    }));
  }, [clusterHeadOfOpsFlatlist.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allLpAuditor: lpAuditorFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allLpAuditor: lpAuditorFlatlist.arrayItems
    }));
  }, [lpAuditorFlatlist.arrayItems]);
  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: highlowChannelsFlatlist.arrayItems
      }));
    }
  }, [highlowChannelsFlatlist.arrayItems]);

  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.ClusterHeadofOps)) &&
      // user?.roles?.includes(Roles.CountryManager) ||
      selectedLevel === ComparsionLevel.Country
    ) {
      getAllCountriesLoading({
        pageNo: countryPage,
        pageSize: 10,
        searchByKeyword: countrySearchText,
        moduleType: moduleType
      });
    } else if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AreaTrainer) ||
        user?.roles?.includes(Roles.ClusterHeadofOps)) &&
      (selectedLevel === ComparsionLevel.OpsManager ||
        selectedLevel === ComparsionLevel.LpAuditor ||
        selectedLevel === ComparsionLevel.Channels ||
        selectedLevel === ComparsionLevel.AreaManager ||
        selectedLevel === ComparsionLevel.AreaTrainer ||
        selectedLevel === ComparsionLevel.Stores ||
        selectedLevel === ComparsionLevel.RestaurantEmployees)
    ) {
      getAllCountriesLoading({
        pageNo: countryPage,
        pageSize: 10,
        searchByKeyword: countrySearchText,
        moduleType: moduleType
      });
    }
  }, [selectedReportType, selectedLevel, countryPage, countrySearchText]);

  useEffect(() => {
    if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
      getAllClusterHeadOfOpsLoading({
        user: user,
        role: "Cluster Head of Ops",
        pageNo: clusterHeadOfOpsPage,
        pageSize: 10,
        searchByKeyword: clusterHeadOfOPsSearchText.length >= 3 ? clusterHeadOfOPsSearchText : "",
        allSelected: clusterHeadOfOpsSelectAll,
        moduleType: moduleType,
        selectedReportType
      });
    }
  }, [selectedLevel, selectedReportType, clusterHeadOfOPsSearchText, clusterHeadOfOpsPage]);

  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.HighLow &&
      selectedLevel === ComparsionLevel.ClusterHeadOfOps &&
      selectedUnitReportCountry?.data
    ) {
      if (clusterHeadOfOPsSearchText.length >= 3) {
        getAllClusterHeadOfOpsLoading({
          user: user ? user : null,
          pageNo: clusterHeadOfOpsPage,
          pageSize: 10,
          countryCode: selectedUnitReportCountry?.data?.countryCode,
          role: "Cluster Head of Ops",
          searchByKeyword: clusterHeadOfOPsSearchText,
          allSelected: true,
          moduleType: moduleType,
          selectedReportType
        });
      } else {
        getAllClusterHeadOfOpsLoading({
          user: user ? user : null,
          pageNo: clusterHeadOfOpsPage,
          pageSize: 10,
          countryCode: selectedUnitReportCountry?.data?.countryCode,
          role: "Cluster Head of Ops",
          searchByKeyword: clusterHeadOfOPsSearchText,
          allSelected: true,
          moduleType: moduleType,
          selectedReportType
        });
      }
    }
  }, [
    clusterHeadOfOPsSearchText,
    clusterHeadOfOpsPage,
    selectedLevel,
    selectedReportType,
    selectedUnitReportCountry
  ]);

  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];
    if (allowedArr.includes(user.roles[0]) && selectedLevel === ComparsionLevel.CountryManager) {
      getAllCountryManagerLoading({
        user: user ? user : null,
        role: "Country Manager",
        pageNo: countryManagerPage,
        pageSize: 10,
        searchByKeyword: countryManagerSearchText,
        allSelected: false,
        moduleType: moduleType,
        selectedReportType
      });
    }
  }, [countryManagerSearchText, selectedLevel, countryManagerPage]);

  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps,
      Roles.AreaTrainer
    ];

    if (
      allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.AreaManager &&
      selectedCountryValue
    ) {
      getAllAreaManagerLoading({
        user: user ? user : null,
        role: "Area Manager",
        pageNo: areaManagerPage,
        pageSize: 10,
        countryCode: selectedCountryValue?.countryCode,
        searchByKeyword: areaManagerSearchText,
        allSelected: false,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [areaManagerSearchText, selectedLevel, selectedCountryValue, areaManagerPage]);
  useEffect(() => {
    // Detailed report - Admin Role channel production sheet
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps
    ];
    if (selectedLevel === ComparsionLevel.AreaTrainer && selectedCountryValue) {
      getAllAreaTrainerLoading({
        user: user ? user : null,
        role: "Area Trainer",
        pageNo: areaTrainerPage,
        pageSize: 10,
        countryCode: selectedCountryValue?.countryCode,
        searchByKeyword: areaTrainerSearchText,
        allSelected: false,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [areaTrainerSearchText, selectedLevel, selectedCountryValue, areaTrainerPage]);

  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps,
      Roles.AreaTrainer
    ];
    if (
      (allowedArr.includes(user.roles[0]) && selectedLevel === ComparsionLevel.Stores) ||
      (selectedLevel === ComparsionLevel.RestaurantEmployees && selectedCountryValue)
    ) {
      getAllStoresLoading({
        user: user ? user : null,
        pageNo: storesPage,
        pageSize: 10,
        countryCode: selectedCountryValue?.countryCode,
        searchByKeyword: storeSearchText,
        allSelected: countrySelectAll,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [storeSearchText, selectedLevel, selectedCountryValue, storesPage]);

  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps
    ];

    if (allowedArr.includes(user.roles[0]) && selectedLevel === ComparsionLevel.Channels) {
      if (selectedCountryValue) {
        let dummy = selectedCountryValue?.countryCode;
        getAllChannelsLoading({
          user: user ? user : null,
          pageNo: 0,
          pageSize: 10,
          countryCode: [dummy],
          searchByKeyword: channelSearchText,
          moduleType: moduleType
        });
      }
    }
  }, [channelSearchText, selectedLevel, selectedCountryValue, channelPage]);

  useEffect(() => {
    if (
      selectedLevel === ComparsionLevel.CountryManager &&
      selectedReportType === ReportTypeLevel.HighLow &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      if (
        countryManagerPage <= comparisonCountryManagers?.totalElements / 10 &&
        comparisonCountryManagers?.totalElements > 10
      ) {
        getComparisonCountryManagersAdmin({
          pageNo: countryManagerPage,
          pageSize: 10,
          searchByKeyword: countryManagerSearchText,
          moduleType: moduleType
        });
      }
    }
  }, [countryManagerPage]);

  useEffect(() => {
    // CM /AM  Role -> Detailed report - channels api
    if (selectedLevel === ComparsionLevel.Channels) {
      if (
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.OperationManager) ||
        user?.roles?.includes(Roles.SeniorOpsManager) ||
        user?.roles?.includes(Roles.AreaManager) ||
        user?.roles?.includes(Roles.AreaTrainer)
      ) {
        if (countrySearchText.length >= 3) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        } else {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType
          });
        }
      }
    }
  }, [selectedReportType, selectedLevel, channelPage, channelSearchText]);

  useEffect(() => {
    if (selectedLevel === ComparsionLevel.OpsManager && selectedCountryValue) {
      getAllOpsManagerLoading({
        user: user ? user : null,
        role: "Ops Manager",
        pageNo: opsManagerPage,
        pageSize: 10,
        countryCode: selectedCountryValue?.countryCode,
        searchByKeyword: opsManagerSearchText,
        allSelected: countrySearchText.length >= 3 ? false : countrySelectAll,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [
    selectedReportType,
    selectedCountryValue,
    selectedLevel,
    opsManagerSearchText,
    opsManagerPage
  ]);
  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.OperationManager) ||
      user?.roles?.includes(Roles.SeniorOpsManager)
    ) {
      if (selectedLevel === ComparsionLevel.AreaManager) {
        getAllAreaManagerLoading({
          user: user ? user : null,
          role: "Area Manager",
          pageNo: areaManagerPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: areaManagerSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }

      if (selectedLevel === ComparsionLevel.CountryManager)
        getComparisonCountryManagersAdmin({
          pageNo: countryManagerPage,
          pageSize: 10,
          searchByKeyword: countryManagerSearchText,
          moduleType: moduleType
        });
    }
    if (
      // user?.roles?.includes(Roles.Admin) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.AreaTrainer) ||
      user?.roles?.includes(Roles.OperationManager) ||
      user?.roles?.includes(Roles.SeniorOpsManager)
    ) {
      if (selectedLevel === ComparsionLevel.AreaTrainer) {
        getAllAreaTrainerLoading({
          user: user ? user : null,
          role: "Area Trainer",
          pageNo: areaTrainerPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: areaTrainerSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
    }
    if (
      // user?.roles?.includes(Roles.Admin) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.OperationManager) ||
      user?.roles?.includes(Roles.SeniorOpsManager)
    ) {
      if (
        selectedLevel === ComparsionLevel.Stores ||
        selectedLevel === ComparsionLevel.RestaurantEmployees
      ) {
        getAllStoresLoading({
          user: user ? user : null,
          pageNo: storesPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: storeSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType,
          userRole: user?.roles[0]
        });
      }
    }

    if (
      user?.roles?.includes(Roles.SeniorOpsManager) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.AreaTrainer) ||
      user?.roles?.includes(Roles.OperationManager)
    ) {
      if (selectedLevel === ComparsionLevel.OpsManager) {
        getAllOpsManagerLoading({
          user: user ? user : null,
          role: "Ops Manager",
          pageNo: opsManagerPage,
          pageSize: 10,
          searchByKeyword: opsManagerSearchText,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
    }
  }, [
    selectedLevel,
    selectedReportType,
    countryManagerPage,
    channelPage,
    channelSearchText,
    opsManagerPage,
    opsManagerSearchText,
    storesPage,
    storeSearchText,
    areaManagerPage,
    areaManagerSearchText,
    areaTrainerPage,
    areaTrainerSearchText
  ]);

  useEffect(() => {
    if (
      selectedLevel === ComparsionLevel.Channels &&
      selectedReportType === ReportTypeLevel.Comparison &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: allChannels?.content,
        allCountries: allCountries?.content
      }));
    }
    if (
      selectedLevel === ComparsionLevel.Channels &&
      (selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: getHighLowChannelsAdminData?.content
      }));
    }
    if (
      (user?.roles?.includes(Roles.AuditorLP) || user?.roles?.includes(Roles.HeadLP)) &&
      selectedLevel === ComparsionLevel.Stores &&
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allStores: allStores?.content
      }));
    }
    if (
      (user?.roles?.includes(Roles.AuditorLP) || user?.roles?.includes(Roles.HeadLP)) &&
      selectedLevel === ComparsionLevel.Channels &&
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: allChannels?.content
      }));
    }
  }, [selectedLevel, selectedReportType]);

  useEffect(() => {
    if (allCountries?.content && allCountries?.currentPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allCountries: allCountries.content
      }));
      //  setAllCountryFetch(true);
      setCountryPage(0);
      setComparisonReportData((prevState) => ({
        ...prevState,
        allCountries: allCountries.content
      }));
    }
  }, [allCountries]);
  useEffect(() => {
    if (allCountryManagers?.content && countryManagerPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allCountryManagers: allCountryManagers.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allCountryManagers: allCountryManagers.content
      }));
    }
  }, [allCountryManagers, comparisonCountryManagers]);

  useEffect(() => {
    if (allClusterHeadOfOps?.content && clusterHeadOfOpsPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allClusterHeadOfOps: allClusterHeadOfOps.content
      }));
    }
    if (allClusterHeadOfOps?.content && clusterHeadOfOpsPage === 0) {
      setComparisonReportData((prevState) => ({
        ...prevState,
        allClusterHeadOfOps: allClusterHeadOfOps.content
      }));
    }
  }, [allClusterHeadOfOps]);
  useEffect(() => {
    if (allOpsManagers?.content && opsManagerPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allOpsManagers: allOpsManagers?.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allOpsManagers: allOpsManagers?.content
      }));
    }
  }, [allOpsManagers]);
  useEffect(() => {
    if (allLpAuditor?.content && lpAuditorPage === 0) {
      // setLpAuditorPage(0);
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allLpAuditor: allLpAuditor?.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allLpAuditor: allLpAuditor?.content
      }));
    }
  }, [allLpAuditor]);
  useEffect(() => {
    if (allAreaManagers?.content && areaManagerPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allAreaManagers: allAreaManagers?.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allAreaManagers: allAreaManagers?.content
      }));
    }
    if (comparisonAreaManagers?.content) {
      setComparisonReportData((prevState) => ({
        ...prevState,
        allAreaManagers: comparisonAreaManagers?.content
      }));
    }
  }, [allAreaManagers, comparisonAreaManagers]);
  useEffect(() => {
    if (allAreaTrainer?.content && areaTrainerPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allAreaTrainers: allAreaTrainer?.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allAreaTrainers: allAreaTrainer?.content
      }));
    }
  }, [allAreaTrainer]);

  useEffect(() => {
    if (getHighLowChannelsAdminData) {
      if (
        // user?.roles?.includes(Roles.Admin) ||
        user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP)
      ) {
        setAllChannelFetch(true);
        setChannelsPage(0);
        setComparisonReportData((prevState) => ({
          ...prevState,
          allChannels: getHighLowChannelsAdminData?.content
        }));
      }
    } else {
      if (allChannels?.content && allChannels?.currentPage === 0) {
        setDetailedReportData((prevState: any) => ({
          ...prevState,
          allChannels: allChannels.content
        }));
        setChannelsPage(0);
        setComparisonReportData((prevState) => ({
          ...prevState,
          allChannels: allChannels.content
        }));
      }
    }
  }, [allChannels, getHighLowChannelsAdminData]);
  useEffect(() => {
    if (allStores && allStores.content && allStores.currentPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allStores: allStores.content
      }));
      setStoresPage(0);
      setComparisonReportData((prevState) => ({
        ...prevState,
        allStores: allStores.content
      }));
    }
    if (
      comparisonStoresAdmin &&
      comparisonStoresAdmin.content &&
      comparisonStoresAdmin.currentPage === 0 &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      setStoresPage(0);
      setComparisonReportData((prevState) => ({
        ...prevState,
        allStores: comparisonStoresAdmin?.content
      }));
    }
  }, [allStores, comparisonStoresAdmin]);

  const handleCountrySelect = (countries: any) => {
    // TODO - dispatch action to get country
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allCountries: countries
    }));
  };
  const handleCountryManagerSelect = (countryManagers: IItem[]) => {
    // TODO - dispatch action to get country managers
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allCountryManagers: countryManagers
    }));
  };

  const handleAreaManagerSelect = (areaManagers: IItem[]) => {
    // TODO - dispatch action to get area managers
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allAreaManagers: areaManagers
    }));
  };

  const handleAreaTrainerSelect = (areaTrainer: IItem[]) => {
    // TODO - dispatch action to get area managers
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allAreaTrainers: areaTrainer
    }));
  };

  const handleChannelSelect = (channels: IItem[]) => {
    // TODO - dispatch action to get channels
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allChannels: channels
    }));
    setSelectedUnitReportData(channels);
  };

  const handleStoreSelect = (stores: IItem[]) => {
    // TODO - dispatch action to get stores
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allStores: stores
    }));
  };

  const handleOpsManagerSelect = (opsManagers: IItem[]) => {
    // TODO - dispatch action to get Ops Manager
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allOpsManagers: opsManagers
    }));
  };

  const handleClusterHeadOfOpsSelect = (clusterHeadOfOps: IItem[]) => {
    // TODO - dispatch action to get cluster head of ops
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allClusterHeadOfOps: clusterHeadOfOps
    }));
  };

  const handleLpAuditorSelect = (lpAuditor: IItem[]) => {
    // TODO - dispatch action to get lp auditor
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allLpAuditor: lpAuditor
    }));
  };
  const getSelectedComparisonData = (data: IItem[], isChannel?: boolean, label?: string) => {
    if (data && data[0]?.countryCode !== undefined) {
      setselectedCountryValue({
        data
      });
    }
    if (label === "Select Country") {
      setselectedCountryValue(data);
      if (moduleType === builderLevel.isProdSheet) {
        setSelectedUnitReportData(null);
      }
    }
    if (isChannel) {
      if (label === "Select Channel") {
        setSelectedUnitReportData({
          ...selectedUnitReportData,
          channel: data
        });
      } else if (moduleType === builderLevel.isProdSheet && label === "Select Country") {
        setSelectedUnitReportCountry({
          ...selectedUnitReportCountry,
          data
        });
        setSelectedUnitReportData(null);
      } else {
        setSelectedUnitReportCountry({
          ...selectedUnitReportData,
          data
        });
        if (moduleType === builderLevel.isProdSheet && label !== "Select Country") {
          setSelectedUnitReportData(data);
        }
      }
    } else {
      setSelectedUnitReportData(data);
      setSelectedUnitReportCountry(data);
    }
  };

  useEffect(() => {
    if (selectedDataInReportLevel === LmsDataInReportLevel.coreTraining) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        coreTraining: true,
        specialTraining: false
      }));
    }
    if (selectedDataInReportLevel === LmsDataInReportLevel.specialTraining) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        coreTraining: false,
        specialTraining: true
      }));
    }
    setSelectedDependentDataInReportLevel(LmsDataInReportLevel.theoreticalScore);
  }, [selectedDataInReportLevel]);
  useEffect(() => {
    if (selectedDependentDataInReportLevel === LmsDataInReportLevel.theoreticalScore) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        theoreticalScore: true,
        practicalStatus: false,
        moduleStatus: false
      }));
    }
    if (selectedDependentDataInReportLevel === LmsDataInReportLevel.practicalStatus) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        theoreticalScore: false,
        precticalStatus: true,
        moduleStatus: false
      }));
    }
    if (selectedDependentDataInReportLevel === LmsDataInReportLevel.moduleStatus) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        theoreticalScore: false,
        practicalStatus: false,
        moduleStatus: true
      }));
    }
  }, [selectedDependentDataInReportLevel]);

  const generateReport = async () => {
    let selectAllValues: ISelectAllValues = {
      storesSelectAll,
      channelSelectAll,
      areaManagerSelectAll,
      areaTrainerSelectAll,
      opsManagerSelectAll,
      countryManagerSelectAll,
      countrySelectAll,
      clusterHeadOfOpsSelectAll
    };
    await LocalStorage.setStorage("lmsSelectedData", {
      selectedDependentDataType: selectedDependentDataInReportLevel,
      selectedDataType: selectedDataInReportLevel,
      selectedDetailedData: selectedDetailedData,
      selectedUnitReportData: selectedUnitReportData,
      selectedReportType: selectedReportType,
      selectedLevel: selectedLevel,
      selectedDataOption: selectedDataOption,
      selectedUnitReportCountry: selectedUnitReportCountry,
      selectedCountry: selectedCountryValue,
      selectedModules: selectedAllModules,
      selectedModuleType: selectedDataInReportLevel,
      moduleSearchKeyword: allModulesSearchText
    });
    navigation(history, Pathnames.detailedLmsOverallReport);
  };
  useEffect(() => {
    // when no code is selected in detailed admin
    if (
      selectedReportType === ReportTypeLevel.Detailed &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      if (selectedDetailedData?.allCountries.length === 0) {
        setDetailedReportData((prevState) => ({
          ...prevState,
          allChannels: [],
          allCountryManagers: [],
          allStores: [],
          allAreaManagers: [],
          allAreaTrainers: [],
          allClusterHeadOfOps: [],
          allLpAuditor: [],
          allOpsManagers: []
        }));
      }
    }
  }, [selectedDetailedData?.allCountries]);
  useEffect(() => {
    // when no code is selected in detailed admin // TODO - NOT WORKING
    if (
      selectedReportType === ReportTypeLevel.Comparison &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      if (selectedUnitReportCountry?.length === 0) {
        setComparisonReportData((prevState) => ({
          ...prevState,
          allChannels: null
        }));
        setSelectedUnitReportData({
          ...selectedUnitReportData,
          channel: null
        });
      }
    }
  }, [selectedUnitReportCountry]);

  const handleDetailed = (title: string, searchKey: string) => {
    setReqSearchDetailed({ ...reqSearchDetailed, [title]: searchKey });
  };

  const handleReset = (isReportTypeSwitch?: boolean) => {
    setDetailedReportData((prevState) => ({
      ...prevState,
      allChannels: [],
      allStores: [],
      allCountryManagers: [],
      allAreaManagers: [],
      allAreaTrainers: [],
      allClusterHeadOfOps: [],
      allLpAuditor: [],
      allOpsManagers: []
    }));
    if (isReportTypeSwitch) {
      setSelectedDataInReportLevel(LmsDataInReportLevel.coreTraining);
      setSelectedDependentDataInReportLevel(LmsDataInReportLevel.theoreticalScore);
      setCountrySelectAll(false);
      setClusterHeadOfOpsSelectAll(false);
      setCountryManagerSelectAll(false);
      setChannelSelectAll(false);
      setOpsManagerSelectAll(false);
      setAreaManagerSelectAll(false);
      setAreaTrainerSelectAll;
      setLpAuditorSelectAll(false);
      setStoresSelectAll(false);
      setSelectedLevel(
        user?.roles?.includes(Roles.SuperAdmin) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.TrainingAndDevManager) ||
          user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
          user?.roles?.includes(Roles.HrAndAdminDirector) ||
          user?.roles?.includes(Roles.HeadLP) ||
          user?.roles?.includes(Roles.AuditorLP) ||
          user?.roles?.includes(Roles.CEO) ||
          user?.roles?.includes(Roles.COO)
          ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
            ? ComparsionLevel.Country
            : ComparsionLevel.ClusterHeadOfOps
          : user?.roles?.includes(Roles.ClusterHeadofOps)
          ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
            ? ComparsionLevel.Country
            : ComparsionLevel.OpsManager
          : user?.roles?.includes(Roles.CountryManager)
          ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
            ? ComparsionLevel.Channels
            : ComparsionLevel.OpsManager
          : user?.roles?.includes(Roles.AreaManager)
          ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
            ? ComparsionLevel.Channels
            : ComparsionLevel.AreaTrainer
          : user?.roles?.includes(Roles.OperationManager) ||
            user?.roles?.includes(Roles.SeniorOpsManager)
          ? selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
            ? ComparsionLevel.Channels
            : ComparsionLevel.AreaManager
          : user?.roles?.includes(Roles.AreaTrainer) && ComparsionLevel.AreaManager
      );
      setSelectedDataOption((prev) => ({
        ...prev,
        monthly: null,
        quarterly: null,
        yearDate: {
          startDate: null,
          endDate: null
        },
        specific: {
          startDate: null,
          endDate: null
        }
      }));
      setSelectedDateRange(null);
      setSelectedKPI([]);
    }
    setComparisonReportData(null);
    setSelectedUnitReportData(null);
    setSelectedUnitReportCountry(null);
    setselectedCountryValue(null);
    resetReportBuilder();
    setChannelsPage(0);
    setCountryPage(0);
    setStoresPage(0);
    setCountryManagerPage(0);
    setAreaManagerPage(0);
    setAreaTrainerPage(0);
    setOpsManagerPage(0);
  };
  const checkDisable = (): boolean => {
    let val = true;

    function isDateRangeNotEmpty(): boolean {
      if (
        selectedDataOption.monthly !== null ||
        selectedDataOption.quarterly !== null ||
        (selectedDataOption.specific.startDate !== null &&
          selectedDataOption.specific.endDate !== null) ||
        (selectedDataOption.yearDate.startDate !== null &&
          selectedDataOption.yearDate.endDate !== null)
      )
        return true;
      return false;
    }

    function isValidDataInReport(): boolean {
      if (
        selectedDataInReportLevel === LmsDataInReportLevel.coreTraining ||
        selectedDataInReportLevel === LmsDataInReportLevel.specialTraining
      ) {
        if (selectedAllModules && selectedAllModules?.length > 0) return true;
        return false;
      }
      return true;
    }

    function isValidLevelofReport(): boolean {
      switch (selectedLevel) {
        case ComparsionLevel.Country:
        case ComparsionLevel.Channels:
        case ComparsionLevel.CountryManager:
        case ComparsionLevel.AreaManager:
        case ComparsionLevel.AreaTrainer:
        case ComparsionLevel.Stores:
        case ComparsionLevel.RestaurantEmployees:
        case ComparsionLevel.OpsManager:
        case ComparsionLevel.ClusterHeadOfOps:
          return (
            selectedUnitReportData?.length !== undefined && selectedUnitReportData?.length !== 0
          );
        default:
          break;
      }
    }
    // switch (selectedLevel) {
    //   case ComparsionLevel.Country:
    //     return selectedUnitReportCountry?.length !== 0;
    //   case ComparsionLevel.Channels:
    //     if (selectedReportType === ReportTypeLevel.HighLow)
    //       return selectedUnitReportData?.length !== 0;
    //     return (
    //       //   selectedUnitReportData &&
    //       //   selectedUnitReportData.channel &&
    //       //   selectedUnitReportData.channel.id &&
    //       //   selectedUnitReportData.channel.id?.length !== 0
    //       // TODO:Ask if the value saved here is at proper state or not
    //       selectedDetailedData &&
    //       selectedDetailedData.allChannels &&
    //       selectedDetailedData.allChannels.length !== 0
    //     );
    //   case ComparsionLevel.CountryManager:
    //   case ComparsionLevel.AreaManager:
    //   case ComparsionLevel.AreaTrainer:
    //   case ComparsionLevel.Stores:
    //   case ComparsionLevel.OpsManager:
    //   case ComparsionLevel.ClusterHeadOfOps:
    //     if (selectedReportType === ReportTypeLevel.Comparison)
    //       return (
    //         selectedUnitReportData?.length !== undefined && selectedUnitReportData?.length !== 0
    //       );
    //     return selectedUnitReportData?.length !== 0;

    //   default:
    //     break;
    // }
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      val = isDateRangeNotEmpty() && isValidLevelofReport() && isValidDataInReport() ? false : true;
    }
    return val;
  };

  return (
    <>
      <View style={builderTemplateStyles.container}>
        <View>
          <LMSReportType
            selectedReportType={selectedReportType}
            setReportType={setSelectedReportType}
            selectedOption={selectedDateRange}
            setSelectedOption={setSelectedDateRange}
            selected={selectedDataOption}
            setSelected={setSelectedDataOption}
            translate={t}
            handleReset={() => handleReset(true)}
            moduleType={moduleType}
            consolidatedDate={consolidatedDate}
            setConsolidatedDates={setConsolidatedDates}
            user={user}
          />
        </View>
        <View style={builderTemplateStyles.margin}>
          <LMSDataInReport
            translate={t}
            selectedLevel={selectedDataInReportLevel}
            setSelectedReportLevel={setSelectedDataInReportLevel}
            selectedDependentLevel={selectedDependentDataInReportLevel}
            setSelectedDependentReportLevel={setSelectedDependentDataInReportLevel}
            selectedReportType={selectedReportType}
            user={user}
            reportData={allModulesFinalData}
            handleSelectedValues={(vals: IItem[]) => {
              setSelectedAllModules(vals);
            }}
            setAllModulesSearchText={setAllModulesSearchText}
            resetAllModulesSearchText={resetAllModulesSearchText}
            allModulesSearchText={allModulesSearchText}
            setAllModulesReqSearch={setAllModulesReqSearch}
            allModulesReqSearch={allModulesReqSearch}
            setAllModules={allModulesFlatlist.fetchMore}
            selectedAllModules={selectedAllModules}
          />
        </View>
        <View>
          <LmsLevelOfReports
            translate={t}
            user={user}
            loading={loading}
            reportType={
              selectedReportType === ReportTypeLevel.Detailed
                ? ReportTypeLevel.Detailed
                : selectedReportType === ReportTypeLevel.HighLow
                ? ReportTypeLevel.HighLow
                : null
            }
            reportData={
              selectedReportType === ReportTypeLevel.Detailed
                ? detailedReportData
                : selectedReportType === ReportTypeLevel.HighLow && comparisonReportData
            }
            handleCountrySelect={handleCountrySelect}
            handleCountryManagerSelect={handleCountryManagerSelect}
            handleAreaManagerSelect={handleAreaManagerSelect}
            handleAreaTrainerSelect={handleAreaTrainerSelect}
            handleChannelSelect={handleChannelSelect}
            handleStoreSelect={handleStoreSelect}
            handleOpsManagerSelect={handleOpsManagerSelect}
            handleClusterHeadOfOpsSelect={handleClusterHeadOfOpsSelect}
            handleLpAuditorSelect={handleLpAuditorSelect}
            setCountries={countryFlatlist.fetchMore}
            setChannel={channelFlatlist.fetchMore}
            setAreaManager={areaManagerFlatlist.fetchMore}
            setAreaTrainer={areaTrainerFlatlist.fetchMore}
            setComparisionAreaManager={comparisionAreaManagerFlatlist.fetchMore}
            setCountryManager={countryManagerFlatlist.fetchMore} // clus
            setComparisionCountryManager={comparisionCountryManagerFlatlist.fetchMore}
            setStore={storeFlatlist.fetchMore}
            setOpsManager={opsManagerFlatList.fetchMore}
            setClusterHeadOfOps={clusterHeadOfOpsFlatlist.fetchMore}
            setLpAuditor={lpAuditorFlatlist.fetchMore}
            selectedDetailedData={selectedDetailedData}
            detailedReportPermissions={detailedReportPermissions}
            getSelectedComparisonData={getSelectedComparisonData}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
            selectedUnitReportData={selectedUnitReportData}
            selectedUnitReportCountry={selectedUnitReportCountry}
            setSelectedUnitReportCountry={setSelectedUnitReportCountry}
            setComparisonStoreFlatList={storeFlatlist.fetchMore}
            handleReset={handleReset}
            setCountrySearchText={setCountrySearchText}
            setChannelSearchText={setChannelSearchText}
            setStoreSearchText={setStoreSearchText}
            setAreaManagerSearchText={setAreaManagerSearchText}
            setAreaTrainerSearchText={setAreaTrainerSearchText}
            setOpsManagerSearchText={setOpsManagerSearchText}
            setCountryManagerSearchText={setCountryManagerSearchText}
            setClusterHeadOfOpsSearchText={setClusterHeadOfOPsSearchText}
            setLpAuditorSearchText={setLpAuditorSearchText}
            setStoresSelectAll={setStoresSelectAll}
            setAreaManagerSelectAll={setAreaManagerSelectAll}
            setAreaTrainerSelectAll={setAreaTrainerSelectAll}
            setCountryManagerSelectAll={setCountryManagerSelectAll}
            setChannelSelectAll={setChannelSelectAll}
            setCountrySelectAll={setCountrySelectAll}
            selectedCountryValue={selectedCountryValue}
            setOpsManagerSelectAll={setOpsManagerSelectAll}
            setClusterHeadOfOpsSelectAll={setClusterHeadOfOpsSelectAll}
            setLpAuditorSelectAll={setLpAuditorSelectAll}
            storesSelectAll={storesSelectAll}
            areaManagerSelectAll={areaManagerSelectAll}
            areaTrainerSelectAll={areaTrainerSelectAll}
            countryManagerSelectAll={countryManagerSelectAll}
            channelSelectAll={channelSelectAll}
            countrySelectAll={countrySelectAll}
            opsManagerSelectAll={opsManagerSelectAll}
            clusterHeadOfOpsSelectAll={clusterHeadOfOpsSelectAll}
            lpAuditorSelectAll={lpAuditorSelectAll}
            moduleType={moduleType}
            prevSelectedDetailedData={prevSelectedDetailedData}
            setPrevSelectedDetailedData={setPrevSelectedDetailedData}
            setPrevSelectedLORData={setPrevSelectedLORData}
            handleResetSearch={isResetRequired}
            reqSearch={reqSearch}
            setReqSearch={setReqSearch}
            selectedDependentDataInReportLevel={selectedDependentDataInReportLevel}
            reqSearchDetailed={reqSearchDetailed}
            setReqSearchDetailed={(title: string, searchKey: string) =>
              handleDetailed(title, searchKey)
            }
          />
        </View>
        <Divider dividerStyle={builderTemplateStyles.dividerStyle} />

        <View style={builderTemplateStyles.textContainer}>
          <Text textSize={TextSize.ExtraRegular} fontWeight={FontWeight.Regular} testId="text">
            {/* {moduleType === builderLevel.isLp ? null : t("reportNote")} */}
          </Text>
          <Button
            type={ButtonType.Primary}
            title={translate("Generate Report")}
            buttonStyles={builderTemplateStyles.generateButton}
            onPress={generateReport}
            disabled={checkDisable()}
            // disabled={false}
          />
        </View>
      </View>
    </>
  );
}
const mapStateToProps = (state: IState) => {
  return {
    loading: reportsBuilderSelectors.reportsBuilderLoading(state),
    error: reportsBuilderSelectors.reportsBuilderError(state),
    allCountries: reportsBuilderSelectors.getAllCountries(state),
    allAreaManagers: reportsBuilderSelectors.getAllAreaManagers(state),
    allAreaTrainer: reportsBuilderSelectors.getAllAreaTrainer(state),
    allOpsManagers: reportsBuilderSelectors.getAllOpsManagers(state),
    allCountryManagers: reportsBuilderSelectors.getAllCountryManagers(state),
    allClusterHeadOfOps: reportsBuilderSelectors.getAllClusterHeadOfOps(state),
    allLpAuditor: reportsBuilderSelectors.getAllLpAuditor(state),
    allChannels: reportsBuilderSelectors.getAllChannels(state),
    allStores: reportsBuilderSelectors.getAllStores(state),
    comparisonCountryManagers: reportsBuilderSelectors.getComparisonCountryManagerAdmin(state),
    comparisonAreaManagers: reportsBuilderSelectors.getComparisonAreaManagerAdmin(state),
    comparisonStoresAdmin: reportsBuilderSelectors.getComparisonStoresAdmin(state),
    getHighLowChannelsAdminData: reportsBuilderSelectors.getHighLowChannelsAdmin(state),
    allKPIData: reportsBuilderSelectors.getKPIData(state),
    allCategory: reportsBuilderSelectors.getCategoryData(state),
    allProducts: reportsBuilderSelectors.getProductData(state),
    allModulesData: detailedLmsReportsSelectors.getAllModules(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({ ...DetailedLmsReportsActions, ...ReportsBuilderActions }, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LMSBuilderLayout);
