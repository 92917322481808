import React from "react";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize, Text } from "@timhortons/common/src/components/atoms/text";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { LpIndividualCriticalCategoriesModel } from "@timhortons/common/src/models/lp/reports";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";

interface IProps {
  loading: boolean;
  translate: any;
  headerTitle: string;
  criticalCategoriesData: LpIndividualCriticalCategoriesModel[];
  handlePagination?: Function;
}

export interface IHeaderData {
  id: string;
  name: string;
  cellStyle?: StyleProp<ViewStyle>;
  clickable?: boolean;
}

export default function LpCriticalCategoriesTable(props: IProps) {
  const {
    translate,
    criticalCategoriesData,
    headerTitle = translate("By Stores"),
    loading = false,
    handlePagination
  } = props;

  const headerData: IHeaderData[] = [
    { id: "1", name: headerTitle },
    { id: "2", name: translate("cashHandling") },
    { id: "3", name: translate("banking") },
    { id: "4", name: translate("safeVault") },
    { id: "5", name: translate("expiry") },
    { id: "6", name: translate("average") }
  ];
  const getDividerUI = (): JSX.Element => {
    return <Divider orientation={Orientations.Vertical} dividerStyle={styles.dashedDivider} />;
  };

  const getCellUI = (value: number, header: boolean) => {
    return (
      <Cell title={value === null ? "-" : value + "%"} cellStyle={header && styles.headerColor} />
    );
  };
  const getTableRowUI = (
    item: LpIndividualCriticalCategoriesModel,
    header: boolean
  ): JSX.Element => {
    return (
      <Row rowStyle={styles.flexStyle}>
        <Cell
          title={item.storeName}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
        {getCellUI(item.cashHandlingScore, header)}
        {getCellUI(item.bankingScore, header)}
        {getCellUI(item.safeVaultScore, header)}
        {getCellUI(item.expiryScore, header)}
        {getDividerUI()}
        {getCellUI(item.overallScore, header)}
      </Row>
    );
  };

  const getHeaderUI = (header: IHeaderData[]) => {
    return header
      .filter((i) => i !== undefined)
      .map((item: IHeaderData, index: number) => (
        <React.Fragment key={index}>
          {index === 5 && getDividerUI()}
          <HeaderCell
            title={item && item.name}
            cellStyle={index === 0 && styles.contentCell}
            wrapperStyles={{
              justifyContent: index === 0 ? "flex-start" : "center",
              flex: 1
            }}
            titleStyle={styles.centerAlign}
          />
        </React.Fragment>
      ));
  };

  return (
    <View style={[styles.flexStyle, styles.container]}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        testId="opportunity table"
        textStyle={styles.headerTextStyle}
      >
        {translate("criticalPoints-compliance")}
      </Text>
      <Table>
        <Row header>{getHeaderUI(headerData)}</Row>
        {loading ? (
          <Loader type="component" />
        ) : criticalCategoriesData && criticalCategoriesData.length > 0 ? (
          <>
            <FlatList
              extractor={(item) => item.id.toString()}
              listData={
                criticalCategoriesData?.length > 1
                  ? criticalCategoriesData.slice(0, -1)
                  : criticalCategoriesData
              }
              seperator={() => <Divider dividerStyle={styles.solidDivider} />}
              listRenderer={(item: LpIndividualCriticalCategoriesModel) =>
                getTableRowUI(item, false)
              }
            />
            {criticalCategoriesData?.length > 1 && (
              <Row header>
                {getTableRowUI(criticalCategoriesData[criticalCategoriesData?.length - 1], true)}
              </Row>
            )}
          </>
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
        )}
      </Table>
      {handlePagination && (
        <CommonPagination
          handlePagination={handlePagination}
          pageNum={1}
          totalRecords={criticalCategoriesData?.length}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7
  },
  dashedDivider: {
    borderWidth: 1,
    borderRadius: 0.1,
    borderStyle: "dashed",
    borderColor: colorPallete.grey7
  },
  solidDivider: {
    borderColor: colorPallete.white1
  },
  contentCell: {
    justifyContent: "flex-start",
    ...I18nService.select({
      rtl: { paddingEnd: 18 },
      ltr: { paddingStart: 18 }
    })
  },
  upperheaderColor: {
    backgroundColor: colorPallete.variant16
  },
  centerAlign: {
    textAlign: "center"
  },
  flexStyle: {
    flex: 1
  },
  headerColor: {
    backgroundColor: colorPallete.grey9
  },
  headerTextStyle: {
    padding: 24
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center"
    // backgroundColor: colorPallete.grey8
  }
});
