import {
  categoryList,
  productList,
  subProductsList
} from "@timhortons/common/src/models/productionSheet/reportBuilder";

export const PSCategoryDropDownFormatter = async (response: any) => {
  let data: categoryList[] = [];
  response &&
    response.map((item: any) => {
      let object: categoryList = {
        code: "",
        label: "",
        value: ""
      };
      object.code = item.id;
      object.label = item.name;
      object.value = item.name;
      data.push(object);
    });
  return data;
};
export const PSProductDropDownFormatter = async (response: any) => {
  let data: productList[] = [];
  response &&
    response.map((item: any) => {
      let object: productList = {
        code: "",
        label: "",
        value: "",
        childElements: []
      };
      object.code = item.id;
      object.label = item.name;
      object.value = item.name;
      if (item?.products?.length > 0) {
        item?.products?.map((el: any) => {
          let obj: subProductsList = {
            code: "",
            label: "",
            value: "",
            isChecked: false
          };

          obj.code = el.id;
          obj.label = el.name;
          obj.value = el.name;
          obj.isChecked = el.isSelected;

          object.childElements.push(obj);
        });
      }
      data.push(object);
    });
  return data;
};
