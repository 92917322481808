import { QuestionsListModel } from "@timhortons/common/src/models/askTell/manageAskModule";
import { MANAGEASK } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import { askTelQuestionErrorHandler } from "@timhortons/common/src/redux/askTell/saga/askTellFormatter";

export interface IQuestionsLoading {
  getQuestionsList: boolean;
  deleteQuestion: boolean;
  postQuestion: boolean;
}
export interface IQuestionsError {
  getQuestionsList: string;
  deleteQuestion: string;
  postQuestion: string;
}
export interface IQuestionsModuleState {
  questionsList: QuestionsListModel;
  loading: IQuestionsLoading;
  error: IQuestionsError;
}
export const questionsListInitalState: IQuestionsModuleState = {
  questionsList: null,
  loading: {
    getQuestionsList: false,
    deleteQuestion: false,
    postQuestion: false
  },
  error: {
    getQuestionsList: null,
    deleteQuestion: null,
    postQuestion: null
  }
};
export const questionsModuleReducer = (
  state = questionsListInitalState,
  action: { payload: any; type: string }
): IQuestionsModuleState => {
  switch (action.type) {
    case MANAGEASK.Questions.GET_ALL_QUESTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getQuestionsList: true },
        error: { ...state.error, getQuestionsList: null }
      };
    case MANAGEASK.Questions.GET_ALL_QUESTIONS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getQuestionsList: false },
        error: { ...state.error, getQuestionsList: null },
        questionsList: action.payload
      };
    case MANAGEASK.Questions.GET_ALL_QUESTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getQuestionsList: false },
        error: { ...state.error, getQuestionsList: action.payload }
      };
    case MANAGEASK.Questions.DELETE_QUESTION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteQuestion: true },
        error: { ...state.error, deleteQuestion: null }
      };
    case MANAGEASK.Questions.DELETE_QUESTION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteQuestion: false, getQuestionsList: true },
        error: { ...state.error, deleteQuestion: null }
      };
    case MANAGEASK.Questions.DELETE_QUESTION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteQuestion: false },
        error: { ...state.error, deleteQuestion: action.payload }
      };
    case MANAGEASK.Questions.POST_QUESTION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postQuestion: true },
        error: { ...state.error, postQuestion: null }
      };
    case MANAGEASK.Questions.POST_QUESTION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postQuestion: false, getQuestionsList: true },
        error: { ...state.error, postQuestion: null }
      };
    case MANAGEASK.Questions.POST_QUESTION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postQuestion: false },
        error: { ...state.error, postQuestion: askTelQuestionErrorHandler(action.payload) }
      };
    case MANAGEASK.Questions.RESET_ERROR:
      return {
        ...state,
        error: { ...state.error, deleteQuestion: null, postQuestion: null }
      };
    default:
      return state;
  }
};
