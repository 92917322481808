import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import ProgressBar from "@timhortons/common/src/components/atoms/progressBar";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { PreviousAuditDataModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";

interface IProps {
  translate: any;
  previousAuditData: PreviousAuditDataModel;
  deleteAudit: Function;
  history: any;
  isLp?: boolean;
}

export default function PreviousAuditsTable(props: IProps): ReactElement {
  const { translate, previousAuditData, deleteAudit, history, isLp = false } = props;
  const previousAuditTableHeader = [
    { name: translate("Date") },
    { name: translate("Auditor") },
    { name: translate("percentageCompleted") },
    { name: "" }
  ];

  const previousLpAuditTableHeader = [
    { name: translate("Date") },
    { name: translate("storeName") },
    { name: translate("percentageCompleted") },
    { name: "" }
  ];

  const getHeaderComp = (headerItem: any, index: number): ReactElement => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.black2 }}
        cellStyle={styles.headerCellStyle}
      />
    );
  };

  const deleteAuditHandler = () => {
    // deleteAudit({{isLp? auditId: previousAuditData.id : calibrationId: previousAuditData.id }});
    if (isLp) {
      deleteAudit({ auditId: previousAuditData.id });
    } else {
      deleteAudit({ calibrationId: previousAuditData.id });
    }
  };

  const resumeButtonHandler = (type: AuditType) => {
    let searchParams = { id: previousAuditData.id, type };
    {
      isLp
        ? navigation(history, Pathnames.lpAudit, searchParams)
        : navigation(history, Pathnames.testAudit, searchParams);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Table tableStyle={styles.container}>
        <Row
          header
          rowStyle={{
            backgroundColor: colorPallete.grey9,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }}
        >
          {!isLp
            ? previousAuditTableHeader.map((headerItem, index) => getHeaderComp(headerItem, index))
            : previousLpAuditTableHeader.map((headerItem, index) =>
                getHeaderComp(headerItem, index)
              )}
        </Row>
        <Row rowStyle={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}>
          <Cell
            cellStyle={styles.headerCellStyle}
            titleStyle={styles.cellTitleStyle}
            title={dateFormat(previousAuditData.startTime)}
          />
          <Cell
            cellStyle={styles.headerCellStyle}
            titleStyle={styles.cellTitleStyle}
            title={!isLp ? previousAuditData.auditedBy : previousAuditData.storeName}
          />
          <Cell cellStyle={styles.headerCellStyle}>
            <View style={styles.progressBarContainer}>
              <View style={styles.progressTagContainer}>
                <Text
                  textSize={TextSize.ExtraFineSmall}
                  fontWeight={FontWeight.Regular}
                  testId={testIds.testAudit.auditProgress}
                  textStyle={styles.progressTagStyle}
                >
                  {translate("progress")}
                </Text>
                <Text
                  textSize={TextSize.ExtraRegular}
                  fontWeight={FontWeight.SemiBold}
                  testId={testIds.testAudit.auditProgressPercent}
                  textStyle={styles.progressStyle}
                >
                  {previousAuditData.completedPercentage}%
                </Text>
              </View>
              <ProgressBar percentage={previousAuditData.completedPercentage} />
            </View>
          </Cell>
          <Cell cellStyle={styles.buttonCellStyle}>
            <Button
              type={ButtonType.Secondary}
              title={translate("resume")}
              onPress={() => resumeButtonHandler(previousAuditData.auditType)}
              buttonStyles={styles.resumeButtonStyle}
            />
            <Icon
              name={IconNames.trash}
              customStyle={styles.resumeButtonStyle}
              onPress={deleteAuditHandler}
              size={18}
            />
          </Cell>
        </Row>
      </Table>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  },
  headerCellStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 30 },
      ltr: { marginStart: 30 }
    })
    // marginStart: 30
  },
  buttonCellStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginEnd: 12 },
      ltr: { marginEnd: 12 }
    }),
    // marginEnd: 12,
    ...I18nService.select({
      rtl: { marginStart: 18 },
      ltr: { marginStart: 18 }
    })
    // marginStart: 18
  },
  progressBarContainer: {
    flex: 1
  },
  progressTagContainer: {
    flexDirection: "row",
    // placeContent: "space-between",
    justifyContent: "space-between"
  },
  progressTagStyle: {
    color: colorPallete.grey3
  },
  progressStyle: {
    justifyContent: "flex-end",
    color: colorPallete.grey13
  },
  resumeButtonStyle: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    color: colorPallete.red2,
    backgroundColor: colorPallete.variant1,
    borderWidth: 0,
    borderRadius: 8,
    ...I18nService.select({
      rtl: { marginEnd: 18 },
      ltr: { marginEnd: 18 }
    })
    // marginEnd: 18
  },
  cellTitleStyle: {
    color: colorPallete.grey1
  }
});
