import React, { useEffect, useMemo, useState } from "react";
import { StyleProp, TextStyle, View } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import MultiSelect, { IItem } from "@timhortons/common/src/components/molecules/multiSelect";
import RadioGroup from "@timhortons/common/src/components/molecules/radioGroup";
import ModalDropdown from "@timhortons/common/src/components/molecules/modalDropdown";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { CEOEqvRoles, Roles, SuperAdminEqvRoles } from "@timhortons/common/src/constants/roles";
import { levelOfReportStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/levelOfReports/levelOfReportStyles";
import {
  builderLevel,
  ComparsionLevel
} from "@timhortons/common/src/modules/reportBuilders/components/lms/index";
import {
  ReportTypeLevel,
  IPrevSelectedLORData,
  ISelectAllValues
} from "@timhortons/common/src/components/organisms/builder/template";
import { getField } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/levelOfReports/constants";
import { CountryDataModel } from "@timhortons/common/src/models/profile";
import { Orientations } from "@timhortons/common/src/components/atoms/divider";

interface IProps {
  translate: any;
  user: IUserDetails;
  reportType: ReportTypeLevel;
  reportData: IReportData;
  detailedReportPermissions: ComparsionLevel[];
  setCountries: () => void;
  setChannel: () => void;
  setAreaManager: () => void;
  setAreaTrainer: () => void;
  setStore: () => void;
  setCountryManager: () => void;
  setComparisionCountryManager: () => void;
  setOpsManager: () => void;
  setClusterHeadOfOps: () => void;
  setLpAuditor: () => void;
  setComparisionAreaManager: () => void;
  loading: any;
  setSelectedLevel?: Function;
  selectedLevel?: ComparsionLevel;
  handleCountrySelect?: Function;
  handleCountryManagerSelect?: Function;
  handleAreaManagerSelect?: Function;
  handleAreaTrainerSelect?: Function;
  handleChannelSelect?: Function;
  handleStoreSelect?: Function;
  handleClusterHeadOfOpsSelect?: Function;
  handleLpAuditorSelect?: Function;
  handleOpsManagerSelect?: Function;
  getSelectedComparisonData?: Function;
  selectedDetailedData?: IReportData;
  selectedUnitReportData?: any;
  selectedUnitReportCountry?: any;
  setSelectedUnitReportCountry?: any;
  handleReset?: () => void;
  setComparisonStoreFlatList?: () => void;
  highlowChannelsFlatlist?: () => void;
  setCountrySearchText?: Function;
  setStoreSearchText?: Function;
  setChannelSearchText?: Function;
  setAreaManagerSearchText?: Function;
  setAreaTrainerSearchText?: Function;
  setCountryManagerSearchText?: Function;
  setOpsManagerSearchText?: Function;
  setClusterHeadOfOpsSearchText?: Function;
  setLpAuditorSearchText?: Function;
  setStoresSelectAll?: Function;
  setAreaManagerSelectAll?: Function;
  setAreaTrainerSelectAll?: Function;
  setCountryManagerSelectAll?: Function;
  setChannelSelectAll?: Function;
  setCountrySelectAll?: Function;
  setOpsManagerSelectAll?: Function;
  setClusterHeadOfOpsSelectAll?: Function;
  setLpAuditorSelectAll?: Function;
  storesSelectAll?: boolean;
  areaManagerSelectAll?: boolean;
  areaTrainerSelectAll?: boolean;
  countryManagerSelectAll?: boolean;
  channelSelectAll?: boolean;
  countrySelectAll?: boolean;
  opsManagerSelectAll?: boolean;
  clusterHeadOfOpsSelectAll?: boolean;
  lpAuditorSelectAll?: boolean;
  moduleType?: string;
  prevSelectedDetailedData: IPrevReportData;
  setPrevSelectedDetailedData: Function;
  setPrevSelectedLORData: Function;
  selectedCountryValue: any;
  handleResetSearch: boolean;
  reqSearch: string;
  setReqSearch: Function;
  reqSearchDetailed: any;
  setReqSearchDetailed: Function;
  selectedDependentDataInReportLevel: any;
}
export enum RestaurantRadioOptions {
  showByEmployeeScore = "Show_by_Employee_Scores",
  showByRestaurantScore = "Show_by_Restaurant_Scores"
}
export interface IReportData {
  allCountries?: IItem[];
  allAreaManagers?: IItem[];
  allAreaTrainers?: IItem[];
  allClusterHeadOfOps?: IItem[];
  allLpAuditor?: IItem[];
  allCountryManagers?: IItem[];
  allOpsManagers?: IItem[];
  allChannels?: IItem[];
  allStores?: IItem[];
}
export interface IPrevReportData extends IReportData {
  selectAllValues: ISelectAllValues;
}

export default function LmsLevelOfReports(props: IProps) {
  const {
    translate,
    user,
    reportType,
    reportData,
    setSelectedLevel,
    selectedLevel,
    handleCountrySelect,
    handleCountryManagerSelect,
    handleAreaManagerSelect,
    handleAreaTrainerSelect,
    handleChannelSelect,
    handleStoreSelect,
    handleClusterHeadOfOpsSelect,
    handleLpAuditorSelect,
    handleOpsManagerSelect,
    selectedCountryValue,
    getSelectedComparisonData,
    detailedReportPermissions,
    selectedDetailedData,
    selectedUnitReportData,
    selectedUnitReportCountry,
    setCountries,
    moduleType,
    setChannel,
    setAreaManager,
    setAreaTrainer,
    setStore,
    setCountryManager,
    setComparisionCountryManager,
    setOpsManager,
    setClusterHeadOfOps,
    setLpAuditor,
    loading,
    setComparisionAreaManager,
    setComparisonStoreFlatList,
    handleReset,
    highlowChannelsFlatlist,
    setCountrySearchText,
    setStoreSearchText,
    setChannelSearchText,
    setAreaManagerSearchText,
    setAreaTrainerSearchText,
    setCountryManagerSearchText,
    setOpsManagerSearchText,
    setClusterHeadOfOpsSearchText,
    setLpAuditorSearchText,
    setAreaManagerSelectAll,
    setAreaTrainerSelectAll,
    setCountryManagerSelectAll,
    setChannelSelectAll,
    setCountrySelectAll,
    setOpsManagerSelectAll,
    setClusterHeadOfOpsSelectAll,
    setLpAuditorSelectAll,
    setStoresSelectAll,
    areaManagerSelectAll,
    areaTrainerSelectAll,
    countryManagerSelectAll,
    channelSelectAll,
    countrySelectAll,
    opsManagerSelectAll,
    clusterHeadOfOpsSelectAll,
    lpAuditorSelectAll,
    storesSelectAll,
    prevSelectedDetailedData,
    setPrevSelectedDetailedData,
    setPrevSelectedLORData,
    handleResetSearch,
    setReqSearch,
    reqSearch,
    reqSearchDetailed,
    setReqSearchDetailed,
    selectedDependentDataInReportLevel
  } = props;

  const {
    allCountries,
    allAreaManagers,
    allAreaTrainers,
    allOpsManagers,
    allClusterHeadOfOps,
    allLpAuditor,
    allCountryManagers,
    allChannels,
    allStores
  } = {
    ...reportData
  };
  const dropdownFields = useMemo(() => getField(), []);
  const [dropdownData, setDropdownData] = useState(dropdownFields);
  const [prevSelectedCountry, setPrevSelectedCountry] = useState<CountryDataModel>(null);
  const [isResetProdSheet, setIsResetProdSheet] = useState<boolean>(false);
  const [selectedRestaurentRadioType, setSelectedRestaurentRadioType] =
    useState<RestaurantRadioOptions>(null);
  const handlePress = (selLevel: ComparsionLevel) => {
    handleReset();
    setSelectedLevel(selLevel);
    setPrevSelectedCountry(null);
  };

  const comparisonOptions = [
    {
      name: "Country",
      key: ComparsionLevel.Country,
      onPress: () => handlePress(ComparsionLevel.Country),
      isSelected: ComparsionLevel.Country === selectedLevel
    },
    {
      name: "Cluster Head Of Ops",
      key: ComparsionLevel.ClusterHeadOfOps,
      onPress: () => handlePress(ComparsionLevel.ClusterHeadOfOps),
      isSelected: ComparsionLevel.ClusterHeadOfOps === selectedLevel
    },
    {
      name: "Channels",
      key: ComparsionLevel.Channels,
      onPress: () => handlePress(ComparsionLevel.Channels),
      isSelected: ComparsionLevel.Channels === selectedLevel
    },
    {
      name: "Country Manager",
      key: ComparsionLevel.CountryManager,
      onPress: () => handlePress(ComparsionLevel.CountryManager),
      isSelected: ComparsionLevel.CountryManager === selectedLevel
    },
    {
      name: "Ops Manager",
      key: ComparsionLevel.OpsManager,
      onPress: () => handlePress(ComparsionLevel.OpsManager),
      isSelected: ComparsionLevel.OpsManager === selectedLevel
    },
    {
      name: "Area Manager",
      key: ComparsionLevel.AreaManager,
      onPress: () => handlePress(ComparsionLevel.AreaManager),
      isSelected: ComparsionLevel.AreaManager === selectedLevel
    },
    {
      name: "Area Trainer",
      key: ComparsionLevel.AreaTrainer,
      onPress: () => handlePress(ComparsionLevel.AreaTrainer),
      isSelected: ComparsionLevel.AreaTrainer === selectedLevel
    },
    {
      name: "Restaurant",
      key: ComparsionLevel.Stores,
      onPress: () => handlePress(ComparsionLevel.Stores),
      isSelected: ComparsionLevel.Stores === selectedLevel
    },

    {
      name: "Restaurant Employees",
      key: ComparsionLevel.RestaurantEmployees,
      onPress: () => handlePress(ComparsionLevel.RestaurantEmployees),
      isSelected: ComparsionLevel.RestaurantEmployees === selectedLevel
    }
  ];
  let main =
    selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
      ? comparisonOptions
      : comparisonOptions
          .slice(1, 2)
          .concat(comparisonOptions.slice(3, 6))
          .concat(comparisonOptions.slice(7, 8));
  let lpCMOptions =
    selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
      ? comparisonOptions.slice(2, 3).concat(comparisonOptions.slice(4, 8))
      : comparisonOptions.slice(4, 6).concat(comparisonOptions.slice(7, 8));
  let lpOpsOptions =
    selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
      ? comparisonOptions.slice(2, 3).concat(comparisonOptions.slice(5, 8))
      : comparisonOptions.slice(5, 6).concat(comparisonOptions.slice(7, 8));
  let lpAreaManagerOptions =
    selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
      ? comparisonOptions.slice(2, 3).concat(comparisonOptions.slice(6, 8))
      : comparisonOptions.slice(7, 8);
  let lpAreaTrainerOptions =
    selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
      ? comparisonOptions.slice(5, 7)
      : comparisonOptions.slice(5, 6);

  let clusterHeadOptions =
    selectedDependentDataInReportLevel === "THEORETICAL_SCORE"
      ? comparisonOptions
          .slice(0, 1)
          .concat(comparisonOptions.slice(2, 3))
          .concat(comparisonOptions.slice(4, 8))
      : comparisonOptions.slice(4, 6).concat(comparisonOptions.slice(7, 8));
  const restaurantRadioButtonOptions = [
    {
      name: translate("Show by Employee Scores"),
      key: RestaurantRadioOptions.showByEmployeeScore,
      onPress: () => setSelectedRestaurentRadioType(RestaurantRadioOptions.showByRestaurantScore),
      isSelected: selectedRestaurentRadioType === RestaurantRadioOptions.showByEmployeeScore
    },
    {
      name: translate("Show by Restaurant Scores"),
      key: RestaurantRadioOptions.showByRestaurantScore,
      onPress: () => setSelectedRestaurentRadioType(RestaurantRadioOptions.showByRestaurantScore),
      isSelected: selectedRestaurentRadioType === RestaurantRadioOptions.showByRestaurantScore
    }
  ];
  const getRegularTextUI = (label: string, textStyle?: StyleProp<TextStyle>) => {
    return (
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.Regular}
        testId="dropdown"
        textStyle={[levelOfReportStyles.dropdownLabel, textStyle]}
      >
        {translate(label)}
      </Text>
    );
  };
  const handleDropdownChange = (index: number, val: any) => {
    let data = [...dropdownData];
    if (index > -1) {
      if (val.length > 0) {
        data[index].errorMsg = "";
      }
      data[index].value = val;
      setDropdownData(data);
    }
  };
  const checkApiCall = (item: any, apiCall: Function, label?: string) => {
    if (
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.HeadLP) ||
        // user?.roles?.includes(Roles.Admin) ||
        user?.roles?.includes(Roles.AuditorLP) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO)) &&
      reportType === ReportTypeLevel.Detailed &&
      moduleType !== builderLevel.isProdSheet
    ) {
      if (item.name !== "Select Country" && dropdownData[0].value.length > 0) {
        if (apiCall) {
          apiCall();
        }
      }
    }
    if (
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.AreaTrainer) ||
      user?.roles?.includes(Roles.OperationManager) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.InternalAuditor) ||
      user?.roles?.includes(Roles.SeniorOpsManager) ||
      user?.roles?.includes(Roles.ClusterHeadofOps) ||
      moduleType === builderLevel.isProdSheet
    ) {
      if (apiCall) {
        apiCall();
      }
    }

    if (
      (reportType === ReportTypeLevel.Comparison ||
        reportType === ReportTypeLevel.HighLow ||
        reportType === ReportTypeLevel.Grading) &&
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.HeadLP) ||
        // user?.roles?.includes(Roles.Admin) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedLevel !== ComparsionLevel.Channels
    ) {
      if (apiCall) {
        apiCall();
      }
    }
  };
  const handleEmpty = (item: any, label?: string) => {
    //need to change the logic
    const havingCountrySingleSelectLevel: Array<ComparsionLevel> = [
      ComparsionLevel.Channels,
      ComparsionLevel.OpsManager,
      ComparsionLevel.AreaManager,
      ComparsionLevel.Stores,
      ComparsionLevel.RestaurantEmployees,
      ComparsionLevel.LpAuditor,
      ComparsionLevel.AreaTrainer
    ];
    const upperSpecialRoles: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.ClusterHeadofOps,
      Roles.HeadLP
    ];
    if (
      label &&
      label === "Select Country" &&
      havingCountrySingleSelectLevel.includes(selectedLevel)
    ) {
      return "Nothing to select";
    }
    // if (item) {
    //   if (reportType && reportType !== ReportTypeLevel.Detailed) {
    if (
      selectedLevel &&
      upperSpecialRoles.includes(user?.roles[0]) &&
      havingCountrySingleSelectLevel.includes(selectedLevel)
    ) {
      return selectedCountryValue ? "Nothing to select" : "Select country first";
    }
    return "Nothing to select";
    //   } else if (item.name !== "Select Country" && upperSpecialRoles.includes(user?.roles[0])) {
    //     //Detailed Report Type
    //     return selectedDetailedData &&
    //       selectedDetailedData.allCountries &&
    //       selectedDetailedData.allCountries.length
    //       ? "Nothing to select"
    //       : "Select country first";
    //   }
    // } else if (moduleType === builderLevel.isProdSheet && reportType === ReportTypeLevel.Detailed) {
    //   if (
    //     selectedLevel &&
    //     upperSpecialRoles.includes(user?.roles[0]) &&
    //     havingCountrySingleSelectLevel.includes(selectedLevel)
    //   ) {
    //     return selectedCountryValue ? "Nothing to select" : "Select country first";
    //   }
    //   return "Nothing to select";
    // }
  };

  const getMultiSelectUI = (
    label: string,
    allItems: IItem[],
    handleSelect?: Function,
    onEndReached?: () => void,
    loadingVar?: boolean,
    apiCall?: Function,
    setSearchText?: Function,
    setSelectAll?: Function,
    selectAll?: boolean,
    resetSearchText?: boolean,
    setReqSearch?: Function,
    reqSearch?: string
  ) => {
    const index = dropdownData.findIndex((item) => item.name === label);
    let item = dropdownData[index];
    return (
      <View style={levelOfReportStyles.dropdownContainer}>
        {getRegularTextUI(label)}
        <View style={levelOfReportStyles.dropdown}>
          <MultiSelect
            // loading={loadingVar}
            title={translate(
              label === "Select Operational Manager"
                ? "Select Ops Managers"
                : label === "Select Cluster Head of Ops"
                ? label
                : label + "s"
            )}
            allItems={allItems}
            handleSelectedValues={(vals: IItem[]) => {
              handleDropdownChange(
                index,
                vals.map((i) => i.value)
              );
              handleSelect && handleSelect(vals);
            }}
            emptyMsg={handleEmpty(item)}
            onEndReached={onEndReached}
            handleApiCall={() => checkApiCall(item, apiCall, label)}
            // selectedValues={resetSearchText ? [] : item && item.value}
            selectedValues={
              selectedLevel === ComparsionLevel.Channels &&
              reportType !== ReportTypeLevel.Detailed &&
              resetSearchText
                ? []
                : item && item.value
            }
            setSearchText={setSearchText}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            enableSelectAllInitialize={moduleType === builderLevel.isLp}
            isBuilder={true}
            resetSearchText={resetSearchText}
            setReqSearch={setReqSearch}
            reqSearch={reqSearch}
          />
        </View>
      </View>
    );
  };

  const getSingleSelectUI = (
    label: string,
    allItems: IItem[],
    handleSelect?: Function,
    apiCall?: Function,
    onEndReached?: () => void,
    isSearchReq?: boolean,
    setSearchText?: Function,
    isProdSheet?: boolean
  ) => {
    const defaultValueHandler = (defaultValue: string) => {
      if (defaultValue && defaultValue === prevSelectedCountry?.value) {
        handleSelect && handleSelect(prevSelectedCountry, true, label);
      }
    };
    const prodSheetResetSingleSelect = [
      "Select Channel",
      "Select Operational Manager",
      "Select Area Manager",
      "Select Store",
      "Select Restaurants"
    ];
    const hasResetFunc =
      reportType === ReportTypeLevel.Detailed &&
      moduleType === builderLevel.isProdSheet &&
      prodSheetResetSingleSelect.includes(label);
    return (
      <View style={moduleType === builderLevel.isProdSheet ? { flex: 1 } : { flexBasis: "50%" }}>
        {getRegularTextUI(label)}
        <View style={levelOfReportStyles.dropdown}>
          <ModalDropdown
            isSearchRequired={isSearchReq}
            allItems={allItems}
            onEndReached={onEndReached}
            dropdownViewStyle={levelOfReportStyles.singleSelect}
            iconName={IconNames.filledDown}
            dropdownIconStyle={levelOfReportStyles.singleSelectIcon}
            selectedItemStyle={levelOfReportStyles.singleSelectedLabel}
            selectedValueDefault={
              prevSelectedCountry?.value
                ? prevSelectedCountry?.value
                : label === "Select Channel"
                ? selectedUnitReportData?.channel?.value
                : label === "Select Country"
                ? selectedUnitReportCountry?.data?.label
                : selectedUnitReportData
                ? selectedUnitReportData?.value
                : null
            }
            handleApiCall={() => checkApiCall(null, apiCall, label)}
            emptyMsg={handleEmpty(null, label)}
            setSearchText={setSearchText}
            onChangeCallBack={(option) => handleSelect && handleSelect(option, true, label)}
            defaultDropdownText={label}
            listContainerStyle={{ height: 300 }}
            isProdSheet={isProdSheet}
            // defaultValuexHandler={defaultValueHandler}
            resetSelectedItem={hasResetFunc ? isResetProdSheet : null}
            setResetSelectedItem={hasResetFunc ? setIsResetProdSheet : null}
          />
        </View>
      </View>
    );
  };
  const countrySingleSelect = () => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps,
      Roles.AreaTrainer
    ];
    if (
      allowedArr.includes(user?.roles[0]) &&
      (reportType === ReportTypeLevel.HighLow || reportType === ReportTypeLevel.Detailed) &&
      (selectedLevel === ComparsionLevel.OpsManager ||
        selectedLevel === ComparsionLevel.Channels ||
        selectedLevel === ComparsionLevel.AreaTrainer ||
        selectedLevel === ComparsionLevel.AreaManager ||
        selectedLevel === ComparsionLevel.LpAuditor ||
        selectedLevel === ComparsionLevel.Stores ||
        selectedLevel === ComparsionLevel.RestaurantEmployees)
    ) {
      return (
        <>
          {getSingleSelectUI(
            "Select Country",
            allCountries,
            getSelectedComparisonData,
            null,
            setCountries,
            true,
            setCountrySearchText,
            true
          )}
        </>
      );
    }
  };

  const getComparisonReportUI = () => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps,
      Roles.AreaTrainer
    ];

    return (
      <>
        {getRegularTextUI("Select Level", { marginBottom: 0 })}
        <RadioGroup
          selectedRadioOptionStyle={{ marginVertical: 8 }}
          unSelectedRadioOptionStyle={{ marginVertical: 8 }}
          radioOptions={
            user?.roles?.includes(Roles.SuperAdmin) ||
            user?.roles?.includes(Roles.HrAndAdminDirector) ||
            user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
            user?.roles?.includes(Roles.TrainingAndDevLead) ||
            user?.roles?.includes(Roles.TrainingAndDevManager) ||
            user?.roles?.includes(Roles.CEO) ||
            user?.roles?.includes(Roles.COO)
              ? main
              : user?.roles?.includes(Roles.ClusterHeadofOps)
              ? clusterHeadOptions
              : user?.roles?.includes(Roles.AreaManager)
              ? lpAreaManagerOptions
              : user?.roles?.includes(Roles.AreaTrainer)
              ? lpAreaTrainerOptions
              : user?.roles?.includes(Roles.OperationManager) ||
                user?.roles?.includes(Roles.SeniorOpsManager)
              ? lpOpsOptions
              : user?.roles?.includes(Roles.CountryManager) && lpCMOptions
          }
          isBorder
        />
        <View
          style={
            (selectedLevel === ComparsionLevel.Channels ||
              selectedLevel === ComparsionLevel.OpsManager ||
              selectedLevel === ComparsionLevel.AreaManager ||
              selectedLevel === ComparsionLevel.Stores ||
              selectedLevel === ComparsionLevel.RestaurantEmployees ||
              selectedLevel === ComparsionLevel.AreaTrainer ||
              selectedLevel === ComparsionLevel.LpAuditor) &&
            allowedArr.includes(user?.roles[0])
              ? levelOfReportStyles.comparisonContainer
              : levelOfReportStyles.maxWidth
          }
        >
          {detailedReportPermissions.includes(ComparsionLevel.Country) &&
            selectedLevel === ComparsionLevel.Country &&
            getMultiSelectUI(
              "Select Country",
              allCountries,
              getSelectedComparisonData,
              setCountries,
              loading.getAllCountries,
              null,
              setCountrySearchText,
              setCountrySelectAll,
              countrySelectAll,
              false,
              setReqSearch,
              reqSearch
            )}
          {detailedReportPermissions.includes(ComparsionLevel.CountryManager) &&
            selectedLevel === ComparsionLevel.CountryManager &&
            getMultiSelectUI(
              "Select Country Manager",
              allCountryManagers,
              getSelectedComparisonData,
              setComparisionCountryManager,
              loading.comparisonCountryManagerAdmin,
              null,
              setCountryManagerSearchText,
              setCountryManagerSelectAll,
              countryManagerSelectAll,
              handleResetSearch,
              setReqSearch,
              reqSearch
            )}

          {detailedReportPermissions.includes(ComparsionLevel.ClusterHeadOfOps) &&
            selectedLevel === ComparsionLevel.ClusterHeadOfOps &&
            getMultiSelectUI(
              "Select Cluster Head of Ops",
              allClusterHeadOfOps,
              getSelectedComparisonData,
              setClusterHeadOfOps,
              loading.getAllClusterHeadOfOps,
              null,
              setClusterHeadOfOpsSearchText,
              setClusterHeadOfOpsSelectAll,
              clusterHeadOfOpsSelectAll,
              handleResetSearch,
              setReqSearch,
              reqSearch
            )}
          <View
            style={allowedArr.includes(user?.roles[0]) && levelOfReportStyles.detailedContainer}
          >
            {detailedReportPermissions.includes(ComparsionLevel.OpsManager) &&
              selectedLevel === ComparsionLevel.OpsManager &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.OpsManager) &&
              selectedLevel === ComparsionLevel.OpsManager &&
              getMultiSelectUI(
                "Select Operational Manager",
                allOpsManagers,
                getSelectedComparisonData,
                setOpsManager,
                loading.getAllOpsManager,
                null,
                setOpsManagerSearchText,
                setOpsManagerSelectAll,
                opsManagerSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
          <View
            style={allowedArr.includes(user?.roles[0]) && levelOfReportStyles.detailedContainer}
          >
            {detailedReportPermissions.includes(ComparsionLevel.LpAuditor) &&
              selectedLevel === ComparsionLevel.LpAuditor &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.LpAuditor) &&
              selectedLevel === ComparsionLevel.LpAuditor &&
              getMultiSelectUI(
                "Select LP Auditor",
                allLpAuditor,
                getSelectedComparisonData,
                setLpAuditor,
                loading.getAllLpAuditor,
                null,
                setLpAuditorSearchText,
                setLpAuditorSelectAll,
                lpAuditorSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>

          <View
            style={allowedArr.includes(user?.roles[0]) && levelOfReportStyles.detailedContainer}
          >
            {detailedReportPermissions.includes(ComparsionLevel.AreaManager) &&
              selectedLevel === ComparsionLevel.AreaManager &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.AreaManager) &&
              selectedLevel === ComparsionLevel.AreaManager &&
              getMultiSelectUI(
                "Select Area Manager",
                allAreaManagers,
                getSelectedComparisonData,
                setComparisionAreaManager,
                loading.comparisonAreaManagerAdmin,
                null,
                setAreaManagerSearchText,
                setAreaManagerSelectAll,
                areaManagerSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
          <View
            style={allowedArr.includes(user?.roles[0]) && levelOfReportStyles.detailedContainer}
          >
            {detailedReportPermissions.includes(ComparsionLevel.Channels) &&
              selectedLevel === ComparsionLevel.Channels &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.Channels) &&
              selectedLevel === ComparsionLevel.Channels &&
              getMultiSelectUI(
                "Select Channel",
                allChannels,
                getSelectedComparisonData,
                highlowChannelsFlatlist,
                loading.highLowChannelsAdmin,
                null,
                setChannelSearchText,
                setChannelSelectAll,
                channelSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
          <View
            style={allowedArr.includes(user?.roles[0]) && levelOfReportStyles.detailedContainer}
          >
            {detailedReportPermissions.includes(ComparsionLevel.AreaTrainer) &&
              selectedLevel === ComparsionLevel.AreaTrainer &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.AreaTrainer) &&
              selectedLevel === ComparsionLevel.AreaTrainer &&
              getMultiSelectUI(
                "Select Area Trainer",
                allAreaTrainers,
                getSelectedComparisonData,
                setAreaTrainer,
                loading.getAllAreaTrainer,
                null,
                setAreaTrainerSearchText,
                setAreaTrainerSelectAll,
                areaTrainerSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
          <View
            style={allowedArr.includes(user?.roles[0]) && levelOfReportStyles.detailedContainer}
          >
            {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
              selectedLevel === ComparsionLevel.Stores &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
              selectedLevel === ComparsionLevel.Stores &&
              getMultiSelectUI(
                "Select Store",
                allStores,
                getSelectedComparisonData,
                // user?.roles?.includes(Roles.Admin) ||
                user?.roles?.includes(Roles.SuperAdmin) ||
                  user?.roles?.includes(Roles.HrAndAdminDirector) ||
                  user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
                  user?.roles?.includes(Roles.TrainingAndDevLead) ||
                  user?.roles?.includes(Roles.TrainingAndDevManager) ||
                  user?.roles?.includes(Roles.CEO) ||
                  user?.roles?.includes(Roles.COO) ||
                  user?.roles?.includes(Roles.HeadLP) ||
                  user?.roles?.includes(Roles.AuditorLP)
                  ? setComparisonStoreFlatList
                  : setStore,
                loading.comparisonStoresAdmin,
                null,
                setStoreSearchText,
                setStoresSelectAll,
                storesSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
          <View
            style={allowedArr.includes(user?.roles[0]) && levelOfReportStyles.detailedContainer}
          >
            {detailedReportPermissions.includes(ComparsionLevel.RestaurantEmployees) &&
              selectedLevel === ComparsionLevel.RestaurantEmployees &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.RestaurantEmployees) &&
              selectedLevel === ComparsionLevel.RestaurantEmployees &&
              getMultiSelectUI(
                "Select Store",
                allStores,
                getSelectedComparisonData,
                // user?.roles?.includes(Roles.Admin) ||
                user?.roles?.includes(Roles.SuperAdmin) ||
                  user?.roles?.includes(Roles.HrAndAdminDirector) ||
                  user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
                  user?.roles?.includes(Roles.TrainingAndDevLead) ||
                  user?.roles?.includes(Roles.TrainingAndDevManager) ||
                  user?.roles?.includes(Roles.CEO) ||
                  user?.roles?.includes(Roles.COO) ||
                  user?.roles?.includes(Roles.HeadLP) ||
                  user?.roles?.includes(Roles.AuditorLP)
                  ? setComparisonStoreFlatList
                  : setStore,
                loading.comparisonStoresAdmin,
                null,
                setStoreSearchText,
                setStoresSelectAll,
                storesSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
          {/* <View>
          {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
              selectedLevel === ComparsionLevel.Stores &&
              <RadioGroup 
              radioOptions={restaurantRadioButtonOptions}
              orientation={Orientations.Vertical}
              />
          }
              </View> */}
        </View>
      </>
    );
  };

  return (
    <>
      {reportType !== ReportTypeLevel.ConsData && !user?.roles?.includes(Roles.StoreAccess) && (
        <Text
          textSize={TextSize.ExtraLarge}
          fontWeight={FontWeight.SemiBold}
          testId="levelReport"
          textStyle={levelOfReportStyles.levelReportText}
        >
          {translate("lmsLevelReport")}
        </Text>
      )}
      {reportType === ReportTypeLevel.Detailed && getComparisonReportUI()}
      {reportType === ReportTypeLevel.HighLow && getComparisonReportUI()}
    </>
  );
}
