import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { testIds, validations } from "@timhortons/common/src/utils/formHelpers";
import { SelfCalibrationActions } from "@timhortons/common/src/redux/rev/actions/selfCalibration";
import { SelfCalibrationSelectors } from "@timhortons/common/src/redux/rev/selectors/selfCalibration";
import { AppealsSelectors } from "@timhortons/common/src/redux/rev/selectors/appeals";
import { AppealsAction } from "@timhortons/common/src/redux/rev/actions/appeals";
import { Roles } from "@timhortons/common/src/constants/roles";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { ButtonGroup } from "@timhortons/common/src/components/molecules/buttonGroup";
import SelfCalibration from "@timhortons/common/src/modules/rev/components/dashboards/selfCalibrations";
import Appeals from "@timhortons/common/src/modules/rev/components/dashboards/appeals";
import ActionPlans from "@timhortons/common/src/modules/rev/components/dashboards/actionPlans";
import Reports from "@timhortons/common/src/modules/rev/components/dashboards/reports";
import { ISelfCalibrationError } from "@timhortons/common/src/redux/rev/reducers/selfCalibration";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { IState } from "@timhortons/common/src/store/interfaces";
import { IAppealsError, IAppealsLoading } from "@timhortons/common/src/redux/rev/reducers/appeals";
import { CreateNewAppeal } from "@timhortons/common/src/models/rev/appeals";
import { StoreAuditInfoModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { getComponent } from "@timhortons/web/src/utils/routeHelper";
import NavButtons, { INavLinkOption } from "@timhortons/common/src/components/organisms/navButton";
import { allRoutesNames } from "@timhortons/common/src/utils/allRouteNames";
import PageNotFound from "@timhortons/common/src/components/molecules/errorsScreen/pageNotFound";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { rnDownload, webDownload } from "@timhortons/common/src/utils/download";

interface IProps {
  history: any;
  route?: any;
  translate?: any;
  user?: IUserDetails;
  checkAuthID: Function;
  checkAuth: StoreAuditInfoModel;
  error: ISelfCalibrationError;
  createNewAppealResponse?: CreateNewAppeal;
  createNewAppeal?: Function;
  appealError: IAppealsError;
  appealLoading: IAppealsLoading;
  resetAuthID: Function;
}

export enum AuditType {
  STANDARD = "STANDARD",
  TEST = "TEST",
  EXTERNAL = "EXTERNAL"
}
export interface ICreateNewAppeals {
  selfCalibrationId: number;
  auditorId: number;
  storeCode: number;
  isIndividualLogin: boolean;
}

function Dashboards(props: IProps): ReactElement {
  const [currentComponent, setCurrentComponent] = useState<string>(getComponent());
  const {
    checkAuthID,
    checkAuth,
    translate,
    history,
    user,
    error,
    createNewAppealResponse,
    createNewAppeal,
    appealError,
    appealLoading,
    resetAuthID
  } = props;
  const [isIndividualLogin, setIndividualLogin] = useState(user?.roles[0] !== Roles.StoreAccess);
  const [showSCErrorModal, setShowSCErrorModal] = useState(false);
  const [showGuideBookErrorModal, setGuideBookErrorModal] = useState(false);
  const isFocused = useFocusedHook();
  let comp = getComponent();
  useEffect(() => {
    if (comp === "reports") {
      comp = Pathnames.reportNavlink;
    }
    setCurrentComponent(comp);
  }, [comp]);

  const [modalVisible, setModalVisible] = useState(false);

  const setModalState = (): void => {
    setModalVisible(!modalVisible);
  };
  const handleAppealSubmit = async (calibrationId: number, empID: string) => {
    createNewAppeal({
      selfCalibrationId: calibrationId,
      auditorId: empID,
      storeCode: user && user.storeCode,
      isIndividualLogin: isIndividualLogin
    });
  };

  const handleSelfCalibrationAuth = async (empOrStoreID: string, audit: AuditType) => {
    checkAuthID({
      storeId: isIndividualLogin ? empOrStoreID : user?.storeCode,
      startTime: new Date(),
      auditedBy: !isIndividualLogin ? empOrStoreID : user?.empId,
      auditType: audit,
      isIndividualLogin: isIndividualLogin
    });
  };

  useEffect(() => {
    setShowSCErrorModal(false);
    if (currentComponent === Pathnames.calibrations && isFocused) {
      if (checkAuth && checkAuth.location !== null && error.checkAuth === null) {
        let searchParams = { id: checkAuth.selfCalibrationId, type: checkAuth.auditType };
        resetAuthID();
        navigation(history, Pathnames.testAudit, searchParams);
      } else if (error.checkAuth !== null) {
        setShowSCErrorModal(!showSCErrorModal);
      }
    }
  }, [checkAuth, error.checkAuth]);

  useEffect(() => {
    setShowSCErrorModal(false);
    if (currentComponent === Pathnames.appeals) {
      LocalStorage.setStorage("appealAuth", "authorized");
      if (
        createNewAppealResponse &&
        createNewAppealResponse.status === "success" &&
        appealError.createNewAppeal === null
      ) {
        let searchParams = {
          id: createNewAppealResponse.data,
          appealerId: createNewAppealResponse.appealerId
        };
        navigation(history, Pathnames.detailedAppeals, searchParams);
      } else if (appealError.createNewAppeal !== null) {
        LocalStorage.setStorage("appealAuth", "not authorized");
        setShowSCErrorModal(!showSCErrorModal);
      }
    }
  }, [createNewAppealResponse, appealError.createNewAppeal]);

  const navObjects: Array<INavLinkOption> = [
    {
      key: "1",
      path: Pathnames.calibrations,
      param: "selfCalibrations",
      title: translate("Self Calibration"),
      active: Pathnames.calibrations === currentComponent,
      root: `${allRoutesNames.rev.root}`
    },
    {
      key: "2",
      path: Pathnames.actionPlans,
      param: "actionPlans",
      title: translate("Action Plans"),
      active: Pathnames.actionPlans === currentComponent,
      root: `${allRoutesNames.rev.root}`
    },
    {
      key: "3",
      path: Pathnames.appeals,
      param: "appeals",
      title: translate("Appeals"),
      active: Pathnames.appeals === currentComponent,
      root: `${allRoutesNames.rev.root}`
    },
    {
      key: "4",
      path: Pathnames.reportNavlink,
      param: "reportsNavLink",
      title: translate("Reports"),
      active: Pathnames.reportNavlink === currentComponent,
      root: `${allRoutesNames.rev.root}`
    }
  ];

  async function downloadGuideBook() {
    try {
      let date = new Date();
      let fullName = "Rev_GuideBook_" + Math.floor(date.getTime() + date.getSeconds() / 2) + ".pdf";
      let uri = "https://drive.google.com/uc?export=download&id=1Zyfvy4CuG2mZch6pWHmxwwdJGTMcW-e_";
      if (PlatformUtils.isWeb()) {
        await webDownload(fullName, uri);
      } else {
        let res = await rnDownload(fullName, uri);
      }
    } catch (error) {
      setGuideBookErrorModal(true);
    }
  }

  const buttonLists = [
    // {
    //   id: "1",
    //   title: translate("Watch REV"),
    //   iconName: IconNames.watchVideo,
    //   onPress: () => {
    //     // TODO - Add functionality
    //   },
    //   buttonStyle: styles.buttons,
    //   iconStyles: styles.icon
    // },
    {
      id: "2",
      title: translate("Guidebook"),
      iconName: IconNames.download,
      onPress: () => {
        downloadGuideBook();
      },
      buttonStyle: [styles.buttons, { marginHorizontal: 0 }],
      iconStyles: styles.icon
    }
  ];

  const dispComponent = useMemo(() => {
    if (currentComponent) {
      switch (currentComponent) {
        case Pathnames.calibrations:
          return (
            <SelfCalibration
              translate={translate}
              isIndividualLogin={isIndividualLogin}
              setIndividualLogin={setIndividualLogin}
              currentUser={user}
              modalVisible={modalVisible}
              setModalState={setModalState}
              handleAuth={handleSelfCalibrationAuth}
              history={history}
            />
          );
        case Pathnames.appeals:
          return (
            <Appeals
              translate={translate}
              modalVisible={modalVisible}
              setModalState={setModalState}
              handleAuth={handleAppealSubmit}
              currentUserRoles={user}
            />
          );
        case Pathnames.reportNavlink:
          return <Reports currentUserRoles={user} translate={translate} history={history} />;
        case Pathnames.actionPlans:
          return (
            <ActionPlans
              translate={translate}
              history={history}
              currentUserRoles={user && user.roles}
              userDetails={user}
            />
          );
        default:
          return <PageNotFound />;
      }
    }
  }, [currentComponent, modalVisible]);

  if (appealLoading.createNewAppeal) {
    return <Loader type="component" />;
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.headerContainer}>
        <View>
          <Text
            textStyle={styles.headerTitle}
            testId={testIds.rev.dashboardHeader}
            textSize={TextSize.ExtraLarge}
            fontWeight={FontWeight.SemiBold}
          >
            {translate("REV (Restaurant Excellence Visit)")}
          </Text>
          <Text
            textStyle={styles.locationTitle}
            testId={testIds.rev.storeName}
            textSize={TextSize.Large}
            fontWeight={FontWeight.Regular}
          >
            {user && user.roles.includes(Roles.StoreAccess)
              ? translate("Location", { name: user.storeName })
              : translate("hello", { name: user && user.name })}
          </Text>
        </View>
        <View>
          <ButtonGroup
            buttonLists={buttonLists}
            buttonsType={ButtonType.Primary}
            textStyles={styles.btnTextStyle}
            textSize={TextSize.Regular}
            fontWeight={FontWeight.SemiBold}
          />
        </View>
      </View>
      <NavButtons
        navObjects={navObjects}
        history={history}
        setCurrentComponent={setCurrentComponent}
        containerStyle={styles.navContainer}
      />
      {dispComponent}
      {showSCErrorModal || showGuideBookErrorModal ? (
        <AlertModal
          setModalVisible={() => {
            showSCErrorModal
              ? setShowSCErrorModal(!showSCErrorModal)
              : setGuideBookErrorModal(false);
          }}
          errorMsg={showSCErrorModal ? validations.notAValidUser : "Error Downloading File"}
        />
      ) : null}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  headerText: {
    flexDirection: "column"
  },
  headerTitle: {
    color: colorPallete.black3,
    marginBottom: 6
  },
  locationTitle: {
    textTransform: "capitalize",
    color: colorPallete.grey1
  },
  buttons: {
    paddingHorizontal: 36,
    paddingVertical: 12,
    marginHorizontal: 12,
    borderColor: colorPallete.grey2,
    borderWidth: 1,
    backgroundColor: colorPallete.white3,
    flexWrap: "wrap"
  },
  icon: {
    color: colorPallete.black4,
    marginHorizontal: 6
  },
  btnTextStyle: {
    color: colorPallete.black4
  },
  navContainer: {
    flexDirection: "row",
    marginTop: 30
  }
});

const mapStateToProps = (state: IState) => {
  return {
    checkAuth: SelfCalibrationSelectors.checkAuth(state),
    error: SelfCalibrationSelectors.selfCalibrationError(state),
    loading: SelfCalibrationSelectors.selfCalibrationLoading(state),
    createNewAppealResponse: AppealsSelectors.createNewAppealResponse(state),
    appealError: AppealsSelectors.appealsError(state),
    appealLoading: AppealsSelectors.appealsLoading(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...AppealsAction, ...SelfCalibrationActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
