import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { GetWeeklyActionQuestions } from "@timhortons/common/src/models/logs/actionLogs/cleaning";
import { Decision } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/timeTempActionTab";

export interface ICleaningActionsLoading {
  getDeepCleaningActions: boolean;
  updateDeepcleaningActions: boolean;
  getRestRoomActions: boolean;
}
export interface ICleaningActionsError {
  getDeepCleaningActions: string;
  updateDeepcleaningActions: string;
  getRestRoomActions: string;
}

export interface ICleaningActionsState {
  loading: ICleaningActionsLoading;
  error: ICleaningActionsError;
  getDeepCleaningActions: GetWeeklyActionQuestions[];
  getRestRoomActions: GetWeeklyActionQuestions[];
}

export const cleaningActionsInitialState: ICleaningActionsState = {
  loading: {
    getDeepCleaningActions: false,
    updateDeepcleaningActions: false,
    getRestRoomActions: false
  },
  error: {
    getDeepCleaningActions: null,
    updateDeepcleaningActions: null,
    getRestRoomActions: null
  },
  getDeepCleaningActions: null,
  getRestRoomActions: null
};

export const cleaningActionsReducer = (
  state = cleaningActionsInitialState,
  action: { payload: any; type: string }
): ICleaningActionsState => {
  switch (action.type) {
    case LOGS.Actions.Cleaning.GET_DEEP_CLEANING_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getDeepCleaningActions: true },
        error: { ...state.error, getDeepCleaningActions: null }
      };
    case LOGS.Actions.Cleaning.GET_DEEP_CLEANING_ACTIONS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDeepCleaningActions: false },
        error: { ...state.error, getDeepCleaningActions: null },
        getDeepCleaningActions: action.payload
      };
    case LOGS.Actions.Cleaning.GET_DEEP_CLEANING_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getDeepCleaningActions: false },
        error: { ...state.error, getDeepCleaningActions: action.payload }
      };
    case LOGS.Actions.Cleaning.UPDATE_DEEP_CLEANING_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateDeepcleaningActions: true },
        error: { ...state.error, updateDeepcleaningActions: null }
      };
    case LOGS.Actions.Cleaning.UPDATE_DEEP_CLEANING_ACTIONS.SUCCESS:
      let data = action.payload.isRestRoom
        ? state.getRestRoomActions
        : state.getDeepCleaningActions;
      let index = data && data?.findIndex((item) => item.uniqueId === action.payload.questionId);
      if (index > -1) {
        data[index].decision = action.payload.body.hasResolved
          ? Decision.resolved
          : Decision.notResolved;
      }
      if (action.payload.isRestRoom) {
        return {
          ...state,
          loading: { ...state.loading, updateDeepcleaningActions: false },
          error: { ...state.error, updateDeepcleaningActions: null },
          getRestRoomActions: data
        };
      } else
        return {
          ...state,
          loading: { ...state.loading, updateDeepcleaningActions: false },
          error: { ...state.error, updateDeepcleaningActions: null },
          getDeepCleaningActions: data
        };
    case LOGS.Actions.Cleaning.UPDATE_DEEP_CLEANING_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateDeepcleaningActions: false },
        error: { ...state.error, updateDeepcleaningActions: action.payload }
      };
    case LOGS.Actions.Cleaning.GET_RESTROOM_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomActions: true },
        error: { ...state.error, getRestRoomActions: null }
      };
    case LOGS.Actions.Cleaning.GET_RESTROOM_ACTIONS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomActions: false },
        getRestRoomActions: action.payload
      };
    case LOGS.Actions.Cleaning.GET_RESTROOM_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getRestRoomActions: false },
        error: { ...state.error, getRestRoomActions: action.payload }
      };
    case LOGS.Actions.Cleaning.RESET_ACTIONS_CLEANING_ERRORS:
      return {
        ...state,
        error: { ...state.error, updateDeepcleaningActions: null }
      };
    default:
      return state;
  }
};
