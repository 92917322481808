import { PROFILE } from "@timhortons/common/src/redux/profile/actions/actionTypes";
import {
  ProfileDetailsModel,
  TeamDetailsTableModel,
  UserProfileDataModel,
  DetailedUserInfoModel,
  CreateStoreDataModel,
  CountryDataModel,
  ChannelsDataModel,
  StoreSpecificDataModel,
  StoreTableDetailsModel,
  AllManagersDataModel,
  UserTableDetailsModel,
  DocumentDetailModel,
  GetStoreByStoreCode,
  EditStoreDetails,
  GetAllRolesDataModel
} from "@timhortons/common/src/models/profile";
import { IDocumentData } from "@timhortons/common/src/modules/profile/components/detailedUserProfile";
import { StoreListModel } from "@timhortons/common/src/modules/profile/components/createEmployee/organisms/storeList";

export interface IProfilesLoading {
  getProfileDetails: boolean;
  getTeamDetails: boolean;
  getUserProfileDetails: boolean;
  getDetailedUserInfo: boolean;

  getAllCountries: boolean;
  getAllChannels: boolean;
  getStoreSpecifics: boolean;
  getAllCities: boolean;
  getAllAreaManager: boolean;
  getAllOpsManager: boolean;
  getAllAreaTrainer: boolean;
  getAllCountryManager: boolean;
  getAllRoles: boolean;
  postCreateStore: boolean;
  postCreateUser: boolean;
  getStoreTableDetails: boolean;
  getUserTableDetails: boolean;
  getEmployeeDocument: boolean;
  postEmployeeDocument: boolean;
  getEmployeeCertificate: boolean;
  getEmployeeCountDocument: boolean;
  getEmployeeCountCertificate: boolean;
  getStoreByStoreCode: boolean;
  getAllStoresList: boolean;
  postAssignedStores: boolean;
  getEditStoreDetails: boolean;
  getGccCountries: boolean;
}

export interface IProfilesError {
  getProfileDetails: string;
  getTeamDetails: string;
  getUserProfileDetails: string;
  getDetailedUserInfo: string;

  getAllCountries: string;
  getAllChannels: string;
  getStoreSpecifics: string;
  getAllCities: string;
  getAllAreaManager: string;
  getAllOpsManager: string;
  getAllAreaTrainer: string;
  getAllCountryManager: string;
  getAllRoles: string;
  postCreateStore: string;
  postCreateUser: string;
  getStoreTableDetails: string;
  getUserTableDetails: string;
  getEmployeeDocument: string;
  getEmployeeCertificate: string;
  postEmployeeDocument: string;
  getEmployeeCountDocument: string;
  getEmployeeCountCertificate: string;
  getStoreByStoreCode: string;
  getAllStoresList: string;
  postAssignedStores: string;
  getEditStoreDetails: string;
  getGccCountries: string;
}

export interface IProfilesState {
  profileDetails: ProfileDetailsModel;
  teamDetails: TeamDetailsTableModel;
  loading: IProfilesLoading;
  error: IProfilesError;
  userDetails: UserProfileDataModel;
  detailedUserInfo: DetailedUserInfoModel;

  getAllCountries: CountryDataModel[];
  getAllChannels: ChannelsDataModel[];
  getStoreSpecifics: StoreSpecificDataModel[];
  getAllCities: CreateStoreDataModel[];
  getAllAreaManager: AllManagersDataModel[];
  getAllOpsManager: AllManagersDataModel[];
  getAllCountryManager: AllManagersDataModel[];
  getAllAreaTrainer: AllManagersDataModel[];
  // {
  //   areaTrainer: AllManagersDataModel[];
  //   areaManager: AllManagersDataModel[];
  //   opsManager: AllManagersDataModel[];
  //   countryManager: AllManagersDataModel[];
  // };
  getAllRoles: GetAllRolesDataModel[];
  postCreateStore: any;
  postCreateUser: any;
  storeTableDetails: StoreTableDetailsModel;
  userTableDetails: UserTableDetailsModel;
  getEmployeeDocument: DocumentDetailModel;
  getEmployeeCertificate: DocumentDetailModel;
  postEmployeeDocument: { signedUrl: string };
  getEmployeeCountDocument: IDocumentData[];
  getEmployeeCountCertificate: IDocumentData[];
  formattedRoles: any;
  getStoreByStoreCode: GetStoreByStoreCode;
  getAllStoresList: { totalRecords: number; storeList: StoreListModel[] };
  postAssignedStores: any;
  storeDetails: EditStoreDetails;
  isAreaManagerEnd: boolean;
  isAreaTrainerEnd: boolean;
  isOpsManagerEnd: boolean;
  isCountryManagerEnd: boolean;
  getGccCountries: CountryDataModel[];
}

export const profilesInitialState: IProfilesState = {
  profileDetails: null,
  teamDetails: null,
  userDetails: null,
  detailedUserInfo: null,

  getAllCountries: null,
  getAllChannels: null,
  getStoreSpecifics: null,
  getAllCities: null,
  getAllAreaManager: null,
  getAllOpsManager: null,
  getAllCountryManager: null,
  getAllAreaTrainer: null,
  getAllRoles: null,
  postCreateStore: null,
  postCreateUser: null,
  storeTableDetails: null,
  userTableDetails: null,
  getEmployeeDocument: null,
  getEmployeeCertificate: null,
  postEmployeeDocument: null,
  getEmployeeCountDocument: null,
  getEmployeeCountCertificate: null,
  formattedRoles: null,
  getStoreByStoreCode: null,
  getAllStoresList: null,
  postAssignedStores: null,
  storeDetails: null,
  isAreaManagerEnd: false,
  isAreaTrainerEnd: false,
  isOpsManagerEnd: false,
  isCountryManagerEnd: false,
  getGccCountries: null,
  loading: {
    getProfileDetails: false,
    getTeamDetails: false,
    getUserProfileDetails: false,
    getDetailedUserInfo: false,

    getAllCountries: false,
    getAllChannels: false,
    getStoreSpecifics: false,
    getAllCities: false,
    getAllAreaManager: false,
    getAllOpsManager: false,
    getAllCountryManager: false,
    getAllAreaTrainer: false,
    getAllRoles: false,
    postCreateStore: false,
    postCreateUser: false,
    getStoreTableDetails: false,
    getUserTableDetails: false,
    getEmployeeDocument: false,
    getEmployeeCertificate: false,
    postEmployeeDocument: false,
    getEmployeeCountDocument: false,
    getEmployeeCountCertificate: false,
    getStoreByStoreCode: false,
    getAllStoresList: false,
    postAssignedStores: false,
    getEditStoreDetails: false,
    getGccCountries: false
  },
  error: {
    getProfileDetails: null,
    getTeamDetails: null,
    getUserProfileDetails: null,
    getDetailedUserInfo: null,

    getAllCountries: null,
    getAllChannels: null,
    getStoreSpecifics: null,
    getAllCities: null,
    getAllAreaManager: null,
    getAllOpsManager: null,
    getAllCountryManager: null,
    getAllAreaTrainer: null,
    getAllRoles: null,
    postCreateStore: null,
    postCreateUser: null,
    getStoreTableDetails: null,
    getUserTableDetails: null,
    getEmployeeDocument: null,
    getEmployeeCertificate: null,
    postEmployeeDocument: null,
    getEmployeeCountDocument: null,
    getEmployeeCountCertificate: null,
    getStoreByStoreCode: null,
    getAllStoresList: null,
    postAssignedStores: null,
    getEditStoreDetails: null,
    getGccCountries: null
  }
};

export const profilesReducer = (
  state = profilesInitialState,
  action: { payload: any; type: string }
): IProfilesState => {
  switch (action.type) {
    case PROFILE.GET_INDIVIDUAL_PROFILE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProfileDetails: true },
        error: { ...state.error, getProfileDetails: null }
      };
    case PROFILE.GET_INDIVIDUAL_PROFILE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProfileDetails: false },
        error: { ...state.error, getProfileDetails: null },
        profileDetails: action.payload
      };
    case PROFILE.GET_INDIVIDUAL_PROFILE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProfileDetails: false },
        error: { ...state.error, getProfileDetails: action.payload }
      };
    case PROFILE.GET_STORE_PROFILE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProfileDetails: true },
        error: { ...state.error, getProfileDetails: null }
      };
    case PROFILE.GET_STORE_PROFILE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProfileDetails: false },
        error: { ...state.error, getProfileDetails: null },
        profileDetails: action.payload
      };
    case PROFILE.GET_STORE_PROFILE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProfileDetails: false },
        error: { ...state.error, getProfileDetails: action.payload }
      };
    case PROFILE.GET_STORE_TEAM_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTeamDetails: true },
        error: { ...state.error, getTeamDetails: null }
      };
    case PROFILE.GET_STORE_TEAM_DETAILS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTeamDetails: false },
        error: { ...state.error, getTeamDetails: null },
        teamDetails: action.payload
      };
    case PROFILE.GET_STORE_TEAM_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTeamDetails: false },
        error: { ...state.error, getTeamDetails: action.payload }
      };
    case PROFILE.GET_INDIVIDUAL_TEAM_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTeamDetails: true },
        error: { ...state.error, getTeamDetails: null }
      };
    case PROFILE.GET_INDIVIDUAL_TEAM_DETAILS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTeamDetails: false },
        error: { ...state.error, getTeamDetails: null },
        teamDetails: action.payload
      };
    case PROFILE.GET_INDIVIDUAL_TEAM_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTeamDetails: false },
        error: { ...state.error, getTeamDetails: action.payload }
      };
    case PROFILE.GET_USER_PROFILE_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getUserProfileDetails: true },
        error: { ...state.error, getUserProfileDetails: null }
      };
    case PROFILE.GET_USER_PROFILE_DETAILS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getUserProfileDetails: false },
        error: { ...state.error, getUserProfileDetails: null },
        userDetails: action.payload
      };
    case PROFILE.GET_USER_PROFILE_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getUserProfileDetails: false },
        error: { ...state.error, getUserProfileDetails: action.payload }
      };
    case PROFILE.GET_DETAILED_USER_INFO.LOADING:
      return {
        ...state,
        postCreateUser: null,
        loading: { ...state.loading, getDetailedUserInfo: true },
        error: { ...state.error, getDetailedUserInfo: null }
      };
    case PROFILE.GET_DETAILED_USER_INFO.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDetailedUserInfo: false },
        error: { ...state.error, getDetailedUserInfo: null },
        detailedUserInfo: action.payload
      };
    case PROFILE.GET_DETAILED_USER_INFO.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getDetailedUserInfo: false },
        error: { ...state.error, getDetailedUserInfo: action.payload }
      };

    ////////    Create Store and user  /////////

    case PROFILE.GET_ALL_COUNTRIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllCountries: true },
        error: { ...state.error, getAllCountries: null }
      };
    case PROFILE.GET_ALL_COUNTRIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllCountries: false },
        error: { ...state.error, getAllCountries: null },
        getAllCountries: action.payload
      };
    case PROFILE.GET_ALL_COUNTRIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllCountries: false },
        error: { ...state.error, getAllCountries: action.payload }
      };

    case PROFILE.GET_ALL_CHANNELS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllChannels: true },
        error: { ...state.error, getAllChannels: null }
      };
    case PROFILE.GET_ALL_CHANNELS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllChannels: false },
        error: { ...state.error, getAllChannels: null },
        getAllChannels: action.payload
      };
    case PROFILE.GET_ALL_CHANNELS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllChannels: false },
        error: { ...state.error, getAllChannels: action.payload }
      };

    case PROFILE.GET_STORE_SPECIFICS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getStoreSpecifics: true },
        error: { ...state.error, getStoreSpecifics: null }
      };
    case PROFILE.GET_STORE_SPECIFICS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getStoreSpecifics: false },
        error: { ...state.error, getStoreSpecifics: null },
        getStoreSpecifics: action.payload
      };
    case PROFILE.GET_STORE_SPECIFICS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStoreSpecifics: false },
        error: { ...state.error, getStoreSpecifics: action.payload }
      };

    case PROFILE.GET_ALL_CITIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllCities: true },
        error: { ...state.error, getAllCities: null }
      };
    case PROFILE.GET_ALL_CITIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllCities: false },
        error: { ...state.error, getAllCities: null },
        getAllCities: action.payload
      };
    case PROFILE.GET_ALL_CITIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllCities: false },
        error: { ...state.error, getAllCities: action.payload }
      };

    case PROFILE.GET_ALL_AREA_MANAGER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaManager: true },
        error: { ...state.error, getAllAreaManager: null }
      };
    case PROFILE.GET_ALL_AREA_MANAGER.SUCCESS:
      if (action.payload?.content.length < 10) {
        return {
          ...state,
          loading: { ...state.loading, getAllAreaManager: false },
          error: { ...state.error, getAllAreaManager: null },
          getAllAreaManager: action.payload,
          isAreaManagerEnd: true
        };
      } else {
        return {
          ...state,
          loading: { ...state.loading, getAllAreaManager: false },
          error: { ...state.error, getAllAreaManager: null },
          getAllAreaManager: action.payload,
          isAreaManagerEnd: false
        };
      }
    case PROFILE.GET_ALL_AREA_MANAGER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaManager: false },
        error: { ...state.error, getAllAreaManager: action.payload }
      };
    case PROFILE.GET_OPS_MANAGER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllOpsManager: true },
        error: { ...state.error, getAllOpsManager: null }
      };
    case PROFILE.GET_OPS_MANAGER.SUCCESS:
      if (action.payload?.content?.length < 10) {
        return {
          ...state,
          loading: { ...state.loading, getAllOpsManager: false },
          error: { ...state.error, getAllOpsManager: null },
          getAllOpsManager: action.payload,
          isOpsManagerEnd: true
        };
      } else {
        return {
          ...state,
          loading: { ...state.loading, getAllOpsManager: false },
          error: { ...state.error, getAllOpsManager: null },
          getAllOpsManager: action.payload,
          isOpsManagerEnd: false
        };
      }
    case PROFILE.GET_OPS_MANAGER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllOpsManager: false },
        error: { ...state.error, getAllOpsManager: action.payload }
      };

    case PROFILE.GET_COUNTRY_MANAGER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllCountryManager: true },
        error: { ...state.error, getAllCountryManager: null }
      };

    case PROFILE.GET_COUNTRY_MANAGER.SUCCESS:
      if (action.payload?.content?.length < 10) {
        return {
          ...state,
          loading: { ...state.loading, getAllCountryManager: false },
          error: { ...state.error, getAllCountryManager: null },
          getAllCountryManager: action.payload,
          isCountryManagerEnd: true
        };
      } else {
        return {
          ...state,
          loading: { ...state.loading, getAllCountryManager: false },
          error: { ...state.error, getAllCountryManager: null },
          getAllCountryManager: action.payload,
          isCountryManagerEnd: false
        };
      }
    case PROFILE.GET_COUNTRY_MANAGER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllCountryManager: false },
        error: { ...state.error, getAllCountryManager: action.payload }
      };

    case PROFILE.GET_AREA_TRAINER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaTrainer: true },
        error: { ...state.error, getAllAreaTrainer: null }
      };
    case PROFILE.GET_AREA_TRAINER.SUCCESS:
      if (action.payload?.content?.length < 10) {
        return {
          ...state,
          loading: { ...state.loading, getAllAreaTrainer: false },
          error: { ...state.error, getAllAreaTrainer: null },
          getAllAreaTrainer: action.payload,
          isAreaTrainerEnd: true
        };
      } else {
        return {
          ...state,
          loading: { ...state.loading, getAllAreaTrainer: false },
          error: { ...state.error, getAllAreaTrainer: null },
          getAllAreaTrainer: action.payload,
          isAreaTrainerEnd: false
        };
      }
    case PROFILE.GET_AREA_TRAINER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaTrainer: false },
        error: { ...state.error, getAllAreaTrainer: action.payload }
      };

    case PROFILE.GET_ALL_ROLES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllRoles: true },
        error: { ...state.error, getAllRoles: null }
      };
    case PROFILE.GET_ALL_ROLES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllRoles: false },
        error: { ...state.error, getAllRoles: null },
        getAllRoles: action.payload.allRolesData,
        formattedRoles: action.payload.formattedRolesData
      };
    case PROFILE.GET_ALL_ROLES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllRoles: false },
        error: { ...state.error, getAllRoles: action.payload }
      };

    case PROFILE.POST_CREATE_STORE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postCreateStore: true },
        error: { ...state.error, postCreateStore: null }
      };
    case PROFILE.POST_CREATE_STORE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postCreateStore: false },
        error: { ...state.error, postCreateStore: null },
        postCreateStore: action.payload
      };
    case PROFILE.POST_CREATE_STORE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postCreateStore: false },
        error: { ...state.error, postCreateStore: action.payload }
      };

    case PROFILE.POST_CREATE_USER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postCreateUser: true },
        error: { ...state.error, postCreateUser: null }
      };
    case PROFILE.POST_CREATE_USER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postCreateUser: false },
        error: { ...state.error, postCreateUser: null },
        postCreateUser: action.payload
      };
    case PROFILE.POST_CREATE_USER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postCreateUser: false },
        error: { ...state.error, postCreateUser: action.payload }
      };
    case PROFILE.GET_STORE_TABLE_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getStoreTableDetails: true },
        error: { ...state.error, getStoreTableDetails: null }
      };
    case PROFILE.GET_STORE_TABLE_DETAILS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getStoreTableDetails: false },
        error: { ...state.error, getStoreTableDetails: null },
        storeTableDetails: action.payload
      };
    case PROFILE.GET_STORE_TABLE_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStoreTableDetails: false },
        error: { ...state.error, getStoreTableDetails: action.payload }
      };
    case PROFILE.GET_USER_TABLE_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getUserTableDetails: true },
        error: { ...state.error, getUserTableDetails: null }
      };
    case PROFILE.GET_USER_TABLE_DETAILS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getUserTableDetails: false },
        error: { ...state.error, getUserTableDetails: null },
        userTableDetails: action.payload
      };
    case PROFILE.GET_USER_TABLE_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getUserTableDetails: false },
        error: { ...state.error, getUserTableDetails: action.payload }
      };

    ///////////////////////// Employee Document and certificates ////////////////////
    case PROFILE.GET_EMPLOYEE_DOCUMENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeDocument: true },
        error: { ...state.error, getEmployeeDocument: null }
      };
    case PROFILE.GET_EMPLOYEE_DOCUMENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeDocument: false },
        error: { ...state.error, getEmployeeDocument: null },
        getEmployeeDocument: action.payload
      };
    case PROFILE.GET_EMPLOYEE_DOCUMENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeDocument: false },
        error: { ...state.error, getEmployeeDocument: action.payload }
      };

    case PROFILE.GET_EMPLOYEE_CERTIFICATE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCertificate: true },
        error: { ...state.error, getEmployeeCertificate: null }
      };
    case PROFILE.GET_EMPLOYEE_CERTIFICATE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCertificate: false },
        error: { ...state.error, getEmployeeCertificate: null },
        getEmployeeCertificate: action.payload
      };
    case PROFILE.GET_EMPLOYEE_CERTIFICATE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCertificate: false },
        error: { ...state.error, getEmployeeCertificate: action.payload }
      };

    case PROFILE.GET_EMPLOYEE_COUNT_DOCUMENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCountDocument: true },
        error: { ...state.error, getEmployeeCountDocument: null }
      };
    case PROFILE.GET_EMPLOYEE_COUNT_DOCUMENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCountDocument: false },
        error: { ...state.error, getEmployeeCountDocument: null },
        getEmployeeCountDocument: action.payload
      };
    case PROFILE.GET_EMPLOYEE_COUNT_DOCUMENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCountDocument: false },
        error: { ...state.error, getEmployeeCountDocument: action.payload }
      };

    case PROFILE.GET_EMPLOYEE_COUNT_CERTIFICATE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCountCertificate: true },
        error: { ...state.error, getEmployeeCountCertificate: null }
      };
    case PROFILE.GET_EMPLOYEE_COUNT_CERTIFICATE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCountCertificate: false },
        error: { ...state.error, getEmployeeCountCertificate: null },
        getEmployeeCountCertificate: action.payload
      };
    case PROFILE.GET_EMPLOYEE_COUNT_CERTIFICATE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeCountCertificate: false },
        error: { ...state.error, getEmployeeCountCertificate: action.payload }
      };

    case PROFILE.POST_EMPLOYEE_DOCUMENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postEmployeeDocument: true },
        error: { ...state.error, postEmployeeDocument: null }
      };
    case PROFILE.POST_EMPLOYEE_DOCUMENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postEmployeeDocument: false },
        error: { ...state.error, postEmployeeDocument: null },
        postEmployeeDocument: action.payload
      };
    case PROFILE.POST_EMPLOYEE_DOCUMENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postEmployeeDocument: false },
        error: { ...state.error, postEmployeeDocument: action.payload }
      };
    case PROFILE.GET_STORE_BY_STORECODE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getStoreByStoreCode: true },
        error: { ...state.error, getStoreByStoreCode: null }
      };
    case PROFILE.GET_STORE_BY_STORECODE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getStoreByStoreCode: false },
        getStoreByStoreCode: action.payload
      };
    case PROFILE.GET_STORE_BY_STORECODE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStoreByStoreCode: false },
        error: { ...state.error, getStoreByStoreCode: action.payload },
        getStoreByStoreCode: null
      };

    case PROFILE.GET_ALL_STORES_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllStoresList: true },
        error: { ...state.error, getAllStoresList: null }
      };
    case PROFILE.GET_ALL_STORES_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllStoresList: false },
        getAllStoresList: action.payload
      };
    case PROFILE.GET_ALL_STORES_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllStoresList: false },
        error: { ...state.error, getAllStoresList: action.payload },
        getAllStoresList: null
      };

    case PROFILE.POST_ASSIGN_STORES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postAssignedStores: true },
        error: { ...state.error, postAssignedStores: null }
      };
    case PROFILE.POST_ASSIGN_STORES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postAssignedStores: false },
        error: { ...state.error, postAssignedStores: null },
        postAssignedStores: action.payload
      };
    case PROFILE.POST_ASSIGN_STORES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postAssignedStores: false },
        error: { ...state.error, postAssignedStores: action.payload }
      };

    case PROFILE.RESET_CONTACT_INFO:
      return {
        ...state,
        detailedUserInfo: null
      };
    case PROFILE.RESET_POST_CALLS:
      return {
        ...state,
        postEmployeeDocument: null,
        postAssignedStores: null,
        postCreateUser: null,
        loading: {
          ...state.loading,
          postEmployeeDocument: false,
          postAssignedStores: false,
          postCreateUser: false
        },
        error: {
          ...state.error,
          postEmployeeDocument: null,
          postAssignedStores: null,
          postCreateUser: null
        }
      };
    case PROFILE.RESET_STORE_CALLS:
      return {
        ...state,
        error: {
          ...state.error,
          getStoreByStoreCode: null
        },
        getStoreByStoreCode: null
      };
    case PROFILE.RESET_END_REACHED:
      return {
        ...state,
        isAreaManagerEnd: false,
        isCountryManagerEnd: false,
        isAreaTrainerEnd: false,
        isOpsManagerEnd: false
      };

    case PROFILE.RESET_CREATE_STORE:
      return {
        ...state,
        storeDetails: null,
        postCreateStore: null,
        getAllAreaManager: null,
        getAllAreaTrainer: null,
        getAllOpsManager: null,
        getAllCountryManager: null,
        loading: { ...state.loading, getEditStoreDetails: false, postCreateStore: false },
        error: { ...state.error, getEditStoreDetails: null, postCreateStore: null }
      };

    case PROFILE.GET_EDIT_STORE_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEditStoreDetails: true },
        error: { ...state.error, getEditStoreDetails: null }
      };
    case PROFILE.GET_EDIT_STORE_DETAILS.SUCCESS:
      if (action.payload.length < 10) {
      }
      return {
        ...state,
        loading: { ...state.loading, getEditStoreDetails: false },
        error: { ...state.error, getEditStoreDetails: null },
        storeDetails: action.payload
      };
    case PROFILE.GET_EDIT_STORE_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEditStoreDetails: false },
        error: { ...state.error, getEditStoreDetails: action.payload }
      };

    case PROFILE.GET_GCC_COUNTRIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getGccCountries: true },
        error: { ...state.error, getGccCountries: null }
      };
    case PROFILE.GET_GCC_COUNTRIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getGccCountries: false },
        error: { ...state.error, getGccCountries: null },
        getGccCountries: action.payload
      };
    case PROFILE.GET_GCC_COUNTRIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getGccCountries: false },
        error: { ...state.error, getGccCountries: action.payload }
      };

    default:
      return state;
  }
};
