import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const useCurrentLanguage = () => {
  const [language, setCurrentLanguage] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return language;
};

export default useCurrentLanguage;
