import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IRevReportsError,
  IRevReportsLoading,
  IRevReportsState
} from "@timhortons/common/src/redux/rev/reducers/reportsReducer";
import {
  AcknowledgementModel,
  ReportsDataModal,
  ReportTableResponseModel,
  ReportsSummaryOpportunityDataModel,
  StoreAccessSummaryDataModel,
  IndividualAccessSummaryDataModel,
  CountryManagerDetailsModel
} from "@timhortons/common/src/models/rev/reports";
import { SelectedMonthFilter } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsTableHeader";

const revReportsSelect = (state: IState): IRevReportsState => state.rev.reports;

const revReportsLoading: Selector<IState, IRevReportsLoading> = createSelector(
  revReportsSelect,
  (data) => data.loading
);

const revReportsError: Selector<IState, IRevReportsError> = createSelector(
  revReportsSelect,
  (data) => data.error
);

const selectReportsData: Selector<IState, ReportsDataModal> = createSelector(
  revReportsSelect,
  (data) => data.reportsData
);
const selectAcknowledgementData: Selector<IState, AcknowledgementModel> = createSelector(
  revReportsSelect,
  (data) => data.acknowledgement
);

const fetchReportTableData: Selector<IState, ReportTableResponseModel> = createSelector(
  revReportsSelect,
  (data) => data.reportTableData
);

const fetchStoreAccessSummaryData: Selector<IState, StoreAccessSummaryDataModel> = createSelector(
  revReportsSelect,
  (data) => data.storeAccessSummaryData
);

const fetchStoreAccessOpportunityData: Selector<IState, ReportsSummaryOpportunityDataModel[]> =
  createSelector(revReportsSelect, (data) => data.storeAccessOpportunityData);

const getSelectedMonthFilter: Selector<IState, SelectedMonthFilter> = createSelector(
  revReportsSelect,
  (data) => data.monthFilter
);

const getIndividualAccessSummaryData: Selector<IState, IndividualAccessSummaryDataModel> =
  createSelector(revReportsSelect, (data) => data.individualAccessSummaryData);

// const getAllCountries: Selector<IState, CountryDataListModel> = createSelector(
//   revReportsSelect,
//   (data) => data.getAllCountries
// );

// const getAllAreaManagers: Selector<IState, AllAreaManagersListDataModel> = createSelector(
//   revReportsSelect,
//   (data) => data.getAllAreaManager
// );
// const getAllCountryManagers: Selector<IState, AllAreaManagersListDataModel> = createSelector(
//   revReportsSelect,
//   (data) => data.getAllCountryManager
// );

// const getAllChannels: Selector<IState, ChannelsListDataModel> = createSelector(
//   revReportsSelect,
//   (data) => data.getAllChannels
// );
// const getAllStores: Selector<IState, AllStoresListDataModel> = createSelector(
//   revReportsSelect,
//   (data) => data.getAllStores
// );
const postAckData: Selector<IState, any> = createSelector(
  revReportsSelect,
  (data) => data.postAckData
);
const generatedreportBuilderReponse: Selector<IState, any> = createSelector(
  revReportsSelect,
  (data) => data.generatedReportBuilderResponse
);

// const getComparisonCountryManagerAdmin: Selector<IState, any> = createSelector(
//   revReportsSelect,
//   (data) => data.comparisonCountryManagerAdmin
// );
// const getComparisonAreaManagerAdmin: Selector<IState, any> = createSelector(
//   revReportsSelect,
//   (data) => data.comparisonAreaManagerAdmin
// );
// const getComparisonStoresAdmin: Selector<IState, any> = createSelector(
//   revReportsSelect,
//   (data) => data.comparisonStoresAdmin
// );
// const getHighLowChannelsAdmin: Selector<IState, any> = createSelector(
//   revReportsSelect,
//   (data) => data.highLowChannelsAdmin
// );
const getCountryManagerDetails: Selector<IState, CountryManagerDetailsModel> = createSelector(
  revReportsSelect,
  (data) => data.getCountryManagerDetails
);
export const RevReportsSelectors = {
  revReportsSelect,
  selectReportsData,
  selectAcknowledgementData,
  revReportsLoading,
  revReportsError,
  fetchReportTableData,
  fetchStoreAccessSummaryData,
  fetchStoreAccessOpportunityData,
  getSelectedMonthFilter,
  getIndividualAccessSummaryData,
  // getAllCountries,
  // getAllAreaManagers,
  // getAllCountryManagers,
  // getAllChannels,
  // getAllStores,
  postAckData,
  generatedreportBuilderReponse,
  // getComparisonAreaManagerAdmin,
  // getComparisonCountryManagerAdmin,
  // getComparisonStoresAdmin,
  // getHighLowChannelsAdmin,
  getCountryManagerDetails
};
