import { MANAGENEWS } from "@timhortons/common/src/redux/manageNews/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  DeleteMediaModel,
  DeleteMediaResponseModel,
  MediaRequestModel,
  MediaResponseModel,
  NewsCategoryListModel,
  NewsDetailModel,
  NewsFeatureMediaModel,
  NewsListModel,
  SaveNewsDetailModel
} from "@timhortons/common/src/models/manageNews/manageNews";

const getNewsList = (): IFluxStandardAction => {
  return {
    type: MANAGENEWS.GET_NEWSLIST.LOADING
  };
};
const getNewsListSuccess = (data: NewsListModel[]): IFluxStandardAction<NewsListModel[]> => {
  return {
    type: MANAGENEWS.GET_NEWSLIST.SUCCESS,
    payload: data
  };
};
const getNewsListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.GET_NEWSLIST.ERROR,
    payload: error
  };
};

//get News Details By Id Actions
const getNewsDetailsById = (data: { newsId: string }): IFluxStandardAction<{ newsId: string }> => {
  return {
    type: MANAGENEWS.GET_NEWS_DETAILS_BY_ID.LOADING,
    payload: data
  };
};
const getNewsDetailsByIdSuccess = (data: NewsDetailModel): IFluxStandardAction<NewsDetailModel> => {
  return {
    type: MANAGENEWS.GET_NEWS_DETAILS_BY_ID.SUCCESS,
    payload: data
  };
};
const getNewsDetailsByIdError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.GET_NEWS_DETAILS_BY_ID.ERROR,
    payload: error
  };
};
const newsDeletion = (data: { titleId: string }): IFluxStandardAction<{ titleId: string }> => {
  return {
    type: MANAGENEWS.NEWS_DELETION.LOADING,
    payload: data
  };
};
const newsDeletionSuccess = (data: {
  titleId: string;
}): IFluxStandardAction<{ titleId: string }> => {
  return {
    type: MANAGENEWS.NEWS_DELETION.SUCCESS,
    payload: data
  };
};
const newsDeletionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.NEWS_DELETION.ERROR,
    payload: error
  };
};

//get category list
const getCategoryList = (): IFluxStandardAction => {
  return {
    type: MANAGENEWS.GET_CATEGORY_LIST.LOADING
  };
};
const getCategoryListSuccess = (
  data: NewsCategoryListModel[]
): IFluxStandardAction<NewsCategoryListModel[]> => {
  return {
    type: MANAGENEWS.GET_CATEGORY_LIST.SUCCESS,
    payload: data
  };
};
const getCategoryListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.GET_CATEGORY_LIST.ERROR,
    payload: error
  };
};

const postCreateNewNews = (data: { title: string }): IFluxStandardAction<{ title: string }> => {
  return {
    type: MANAGENEWS.POST_CREATE_NEW_NEWS.LOADING,
    payload: data
  };
};
const postCreateNewNewsSuccess = (data: NewsListModel): IFluxStandardAction<NewsListModel> => {
  return {
    type: MANAGENEWS.POST_CREATE_NEW_NEWS.SUCCESS,
    payload: data
  };
};
const postCreateNewNewsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.POST_CREATE_NEW_NEWS.ERROR,
    payload: error
  };
};

//get media link
const getMediaLink = (data: MediaRequestModel): IFluxStandardAction<MediaRequestModel> => {
  return {
    type: MANAGENEWS.GET_MEDIA_LINK.LOADING,
    payload: data
  };
};
const getMediaLinkSuccess = (data: MediaResponseModel): IFluxStandardAction<MediaResponseModel> => {
  return {
    type: MANAGENEWS.GET_MEDIA_LINK.SUCCESS,
    payload: data
  };
};
const getMediaLinkError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.GET_MEDIA_LINK.ERROR,
    payload: error
  };
};

//create media
const createMedia = (data: MediaRequestModel): IFluxStandardAction<MediaRequestModel> => {
  return {
    type: MANAGENEWS.CREATE_MEDIA.LOADING,
    payload: data
  };
};
const createMediaSuccess = (
  data: NewsFeatureMediaModel
): IFluxStandardAction<NewsFeatureMediaModel> => {
  return {
    type: MANAGENEWS.CREATE_MEDIA.SUCCESS,
    payload: data
  };
};
const createMediaError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.CREATE_MEDIA.ERROR,
    payload: error
  };
};
const saveNews = (data: {
  titleId: string;
  categoryName: string;
  description: Array<Object>;
}): IFluxStandardAction<{
  titleId: string;
  categoryName: string;
  description: Array<Object>;
}> => {
  return {
    type: MANAGENEWS.SAVE_NEWS.LOADING,
    payload: data
  };
};
const saveNewsSuccess = (data: {
  newsDetails: SaveNewsDetailModel;
  titleId: string;
}): IFluxStandardAction<{
  newsDetails: SaveNewsDetailModel;
  titleId: string;
}> => {
  return {
    type: MANAGENEWS.SAVE_NEWS.SUCCESS,
    payload: data
  };
};
const saveNewsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.SAVE_NEWS.ERROR,
    payload: error
  };
};
const publishNews = (titleId: string) => {
  return {
    type: MANAGENEWS.PUBLISH_NEWS.LOADING,
    payload: titleId
  };
};

const publishNewsSuccess = () => {
  return {
    type: MANAGENEWS.PUBLISH_NEWS.SUCCESS,
    payload: {}
  };
};

const publishNewsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.PUBLISH_NEWS.ERROR,
    payload: error
  };
};
const resetPublishError = () => {
  return {
    type: MANAGENEWS.RESET_PUBLISH_ERROR
  };
};
const editTitle = (data: {
  titleId: string;
  name: string;
}): IFluxStandardAction<{
  titleId: string;
  name: string;
}> => {
  return {
    type: MANAGENEWS.EDIT_TITLE.LOADING,
    payload: data
  };
};
const editTitleSuccess = (data: {
  data: NewsListModel;
  titleId: string;
}): IFluxStandardAction<{
  data: NewsListModel;
  titleId: string;
}> => {
  return {
    type: MANAGENEWS.EDIT_TITLE.SUCCESS,
    payload: data
  };
};
const editTitleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.EDIT_TITLE.ERROR,
    payload: error
  };
};
const resetEditTitleError = () => {
  return {
    type: MANAGENEWS.RESET_EDIT_TITLE_ERROR
  };
};

//delete media actions
const deleteMedia = (data: DeleteMediaModel): IFluxStandardAction<DeleteMediaModel> => {
  return {
    type: MANAGENEWS.DELETE_MEDIA.LOADING,
    payload: data
  };
};

const deleteMediaSuccess = (data: DeleteMediaResponseModel) => {
  return {
    type: MANAGENEWS.DELETE_MEDIA.SUCCESS,
    payload: data
  };
};

const deleteMediaError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGENEWS.DELETE_MEDIA.ERROR,
    payload: error
  };
};

export const ManageNewsAction = {
  getNewsList,
  getNewsListSuccess,
  getNewsListError,
  getNewsDetailsById,
  getNewsDetailsByIdSuccess,
  getNewsDetailsByIdError,
  newsDeletion,
  newsDeletionSuccess,
  newsDeletionError,
  getCategoryList,
  getCategoryListSuccess,
  getCategoryListError,
  postCreateNewNews,
  postCreateNewNewsSuccess,
  postCreateNewNewsError,
  getMediaLink,
  getMediaLinkSuccess,
  getMediaLinkError,
  createMedia,
  createMediaSuccess,
  createMediaError,
  saveNews,
  saveNewsSuccess,
  saveNewsError,
  publishNews,
  publishNewsSuccess,
  publishNewsError,
  resetPublishError,
  editTitle,
  editTitleSuccess,
  editTitleError,
  resetEditTitleError,
  deleteMedia,
  deleteMediaSuccess,
  deleteMediaError
};
