export const countriesFormatter = (data: any) => {
  let countriesData: any = [];
  data.map((item: any) => {
    let obj = {
      countryCode: 0,
      label: "",
      value: "",
      nationality: ""
    };
    obj.label = item.name;
    obj.value = item.label;
    obj.countryCode = item.countryCode;
    obj.nationality = item.nationality;
    countriesData.push(obj);
  });
  return countriesData;
};
