import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import { oopsCookie } from "@timhortons/common/src/assets/images/imageAssets";
import { IProductList } from "@timhortons/common/src/models/productionSheet/mainMenu";

interface IProps {
  translate: any;
  reference: any;
  stationList: IProductList[];
  setSelectedButtonId: Function;
  selectedButtonId: string;
  handleSaveButtonClick: () => void;
  showWarning: boolean;
  setShowWarning: Function;
  enablePrevNextButton: { prev: boolean; next: boolean };
  showSwipe: boolean;
}

export default function PageHeader(props: IProps) {
  const {
    translate,
    reference,
    stationList,
    setSelectedButtonId,
    selectedButtonId,
    enablePrevNextButton,
    handleSaveButtonClick,
    showWarning,
    setShowWarning,
    showSwipe
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const handleButtonChange = (id: string) => {
    setSelectedId(id);
    if (showWarning) {
      setShowModal(true);
    } else {
      setSelectedButtonId(id);
    }
  };

  const handleSaveButton = () => {
    handleSaveButtonClick();
    setShowModal(!showModal);
    // handleResetButton();
  };

  const handleResetButton = () => {
    setShowWarning(false);
    setShowModal(false);
    setSelectedButtonId(selectedId);
  };

  return (
    <View style={styles.container}>
      <Text
        textSize={TextSize.ExtraLarge}
        fontWeight={FontWeight.Regular}
        testId="card header"
        textStyle={{}}
      >
        {translate("Products")}
      </Text>
      <View style={styles.subContainer}>
        {stationList?.map((item: IProductList, index: number) => (
          <Button
            key={index}
            title={item.product}
            type={ButtonType.Primary}
            textSize={TextSize.ExtraRegular}
            onPress={() => handleButtonChange(item.id)}
            buttonStyles={[styles.button, selectedButtonId === item.id && styles.selectedButtonId]}
            textStyles={[styles.text, selectedButtonId === item.id && styles.selectedButtonIdText]}
          />
        ))}
        {showSwipe && (
          <View style={{ display: "flex" }}>
            <Text
              textSize={TextSize.Small}
              fontWeight={FontWeight.Regular}
              testId="card header"
              textStyle={{ textAlign: "center" }}
            >
              {translate("swipeRight")}
            </Text>
          </View>
        )}
        {/* commented for now since swipe is already there for both web and tab */}
        {/* <PrevNextButton
          handlePrevClick={() => {
            reference.current.scrollPrevious();
          }}
          handleNextClick={() => {
            reference.current.scrollNext();
          }}
          gap={12}
          iconSize={14}
          buttonStyle={styles.prevButtonStyle}
          enable={enablePrevNextButton}
        /> */}

        <Button
          type={ButtonType.Primary}
          title={translate("save")}
          buttonStyles={styles.saveButtonStyle}
          onPress={handleSaveButtonClick}
          disabled={!showWarning}
        />
      </View>

      {showModal && (
        <AlertModal
          setModalVisible={() => setShowModal(!showModal)}
          errorImage={oopsCookie}
          errorMsg={translate("changesSaved")}
        >
          <View style={styles.modalView}>
            <Button
              type={ButtonType.Primary}
              title={translate("saveChanges")}
              buttonStyles={styles.saveButtonStyle}
              onPress={handleSaveButton}
            />
            <Button
              type={ButtonType.Secondary}
              title={translate("resetChanges")}
              buttonStyles={styles.saveButtonStyle}
              onPress={handleResetButton}
            />
          </View>
        </AlertModal>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
    marginBottom: 16,
    alignItems: "center"
  },
  subContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  button: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colorPallete.white4,
    backgroundColor: colorPallete.white4,
    marginHorizontal: 12
  },
  text: {
    color: colorPallete.grey1
  },
  selectedButtonId: {
    backgroundColor: colorPallete.variant4
  },
  selectedButtonIdText: {
    color: colorPallete.red1
  },
  prevButtonStyle: {
    paddingHorizontal: 16
  },
  saveButtonStyle: {
    paddingHorizontal: 24,
    paddingVertical: 6,
    ...I18nService.select({
      rtl: { marginStart: 14 },
      ltr: { marginStart: 14 }
    }),
    borderRadius: 8
  },
  modalView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 32
  }
});
