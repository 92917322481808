import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  AppealCountModel,
  InProcesssClosedTable,
  SetAppealDecisionModel,
  NewAppealsTable,
  // InProcessAppealTableModel,
  AppealsAccordionDataModel,
  AppealsCategoryDataModel,
  CreateNewAppeal,
  AppealCommentsDataModel,
  PostAppealCommentsModel
} from "@timhortons/common/src/models/rev/appeals";
import { ICreateNewAppeals } from "@timhortons/common/src/modules/rev/components/dashboards/index";

const getAppealsCountData = (data: {
  appealerId: string;
  isIndividual: boolean;
}): IFluxStandardAction<{ appealerId: string; isIndividual: boolean }> => {
  return {
    type: REV.GET_APPEALS_COUNT.LOADING,
    payload: data
  };
};
const getAppealsCountDataSuccess = (
  data: AppealCountModel
): IFluxStandardAction<AppealCountModel> => {
  return {
    type: REV.GET_APPEALS_COUNT.SUCCESS,
    payload: data
  };
};
const getAppealsCountDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_APPEALS_COUNT.ERROR,
    payload: error
  };
};

const getNewAppealsData = (data: {
  storeId: number;
  status: string;
}): IFluxStandardAction<{
  storeId: number;
  status: string;
}> => {
  return {
    type: REV.GET_NEW_APPEALS_DATA.LOADING,
    payload: data
  };
};
const getNewAppealsSuccess = (data: NewAppealsTable[]): IFluxStandardAction<NewAppealsTable[]> => {
  return {
    type: REV.GET_NEW_APPEALS_DATA.SUCCESS,
    payload: data
  };
};
const getNewAppealsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_NEW_APPEALS_DATA.ERROR,
    payload: error
  };
};

const getInProcessAppealsData = (data: {
  storeId: number;
  status: string;
}): IFluxStandardAction<{
  storeId: number;
  status: string;
}> => {
  return {
    type: REV.GET_IN_PROCESS_APPEALS_DATA.LOADING,
    payload: data
  };
};
const getInProcessAppealsSuccess = (
  data: InProcesssClosedTable[]
): IFluxStandardAction<InProcesssClosedTable[]> => {
  return {
    type: REV.GET_IN_PROCESS_APPEALS_DATA.SUCCESS,
    payload: data
  };
};
const getInProcessAppealsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_IN_PROCESS_APPEALS_DATA.ERROR,
    payload: error
  };
};

const getClosedAppealsData = (data: {
  storeId: number;
  status: string;
}): IFluxStandardAction<{
  storeId: number;
  status: string;
}> => {
  return {
    type: REV.GET_CLOSED_APPEALS_DATA.LOADING,
    payload: data
  };
};

const getClosedAppealsSuccess = (
  data: InProcesssClosedTable[]
): IFluxStandardAction<InProcesssClosedTable[]> => {
  return {
    type: REV.GET_CLOSED_APPEALS_DATA.SUCCESS,
    payload: data
  };
};
const getClosedAppealsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_CLOSED_APPEALS_DATA.ERROR,
    payload: error
  };
};

const getAppealAccordionData = (data: {
  appealId: string;
  categoryId: string;
}): IFluxStandardAction<{ categoryId: string; appealId: string }> => {
  return {
    type: REV.GET_APPEAL_ACCORDION_DATA.LOADING,
    payload: data
  };
};
const getAppealAccordionDataSuccess = (
  data: AppealsAccordionDataModel[]
): IFluxStandardAction<AppealsAccordionDataModel[]> => {
  return {
    type: REV.GET_APPEAL_ACCORDION_DATA.SUCCESS,
    payload: data
  };
};
const getAppealAccordionDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_APPEAL_ACCORDION_DATA.ERROR,
    payload: error
  };
};
const setAppealDecision = (
  data: SetAppealDecisionModel
): IFluxStandardAction<SetAppealDecisionModel> => {
  return {
    type: REV.SET_APPEAL_DECISON_DATA.LOADING,
    payload: data
  };
};
const setAppealDecisionSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: REV.SET_APPEAL_DECISON_DATA.SUCCESS,
    payload: data
  };
};
const setAppealDecisionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.SET_APPEAL_DECISON_DATA.ERROR,
    payload: error
  };
};

const inProcessSetAppealFilter = (data: {
  userId: number;
  selectedValues: any;
}): IFluxStandardAction<{ userId: number; selectedValues: any }> => {
  return {
    type: REV.SET_APPEAL_FILTER.LOADING,
    payload: data
  };
};
const inProcessSetAppealFilterSuccess = (
  data: InProcesssClosedTable[]
): IFluxStandardAction<InProcesssClosedTable[]> => {
  return {
    type: REV.SET_APPEAL_FILTER.SUCCESS,
    payload: data
  };
};
const inProcessSetAppealFilterError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.SET_APPEAL_FILTER.ERROR,
    payload: error
  };
};

const postAppealCommentsData = (value: {
  data: PostAppealCommentsModel;
  employeeId: string;
}): IFluxStandardAction<{ data: PostAppealCommentsModel; employeeId: string }> => {
  return {
    type: REV.POST_APPEAL_COMMENT_DATA.LOADING,
    payload: value
  };
};
const postAppealCommentsDataSuccess = (data: AppealCommentsDataModel) => {
  return {
    type: REV.POST_APPEAL_COMMENT_DATA.SUCCESS,
    payload: data
  };
};
const postAppealCommentsDataError = (error: Error) => {
  return {
    type: REV.POST_APPEAL_COMMENT_DATA.ERROR,
    payload: error
  };
};

const createNewAppeal = (data: ICreateNewAppeals): IFluxStandardAction<ICreateNewAppeals> => {
  return {
    type: REV.Appeals.CREATE_NEW_APPEAL.LOADING,
    payload: data
  };
};
const createNewAppealSuccess = (data: CreateNewAppeal): IFluxStandardAction<CreateNewAppeal> => {
  return {
    type: REV.Appeals.CREATE_NEW_APPEAL.SUCCESS,
    payload: data
  };
};
const createNewAppealError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.Appeals.CREATE_NEW_APPEAL.ERROR,
    payload: error
  };
};
const getAppealsCategoryData = (data: {
  appealId: number;
}): IFluxStandardAction<{ appealId: number }> => {
  return {
    type: REV.GET_APPEALS_CATEGORY_DATA.LOADING,
    payload: data
  };
};
const getAppealsCategoryDataSuccess = (
  data: AppealsCategoryDataModel
): IFluxStandardAction<AppealsCategoryDataModel> => {
  return {
    type: REV.GET_APPEALS_CATEGORY_DATA.SUCCESS,
    payload: data
  };
};
const getAppealsCategoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_APPEALS_CATEGORY_DATA.ERROR,
    payload: error
  };
};
const resetAppealError = () => {
  return {
    type: REV.Appeals.RESET_APPEAL_ERROR
  };
};
export const AppealsAction = {
  createNewAppeal,
  createNewAppealSuccess,
  createNewAppealError,
  getAppealsCountData,
  getAppealsCountDataSuccess,
  getAppealsCountDataError,
  getNewAppealsData,
  getNewAppealsSuccess,
  getNewAppealsError,
  getInProcessAppealsData,
  getInProcessAppealsSuccess,
  getInProcessAppealsError,
  getClosedAppealsData,
  getClosedAppealsSuccess,
  getClosedAppealsError,
  getAppealAccordionData,
  getAppealAccordionDataSuccess,
  getAppealAccordionDataError,
  setAppealDecision,
  setAppealDecisionSuccess,
  setAppealDecisionError,
  inProcessSetAppealFilter,
  inProcessSetAppealFilterSuccess,
  inProcessSetAppealFilterError,
  // closedSetAppealFilter,
  // closedSetAppealFilterSuccess,
  // closedSetAppealFilterError,
  postAppealCommentsData,
  postAppealCommentsDataSuccess,
  postAppealCommentsDataError,
  getAppealsCategoryData,
  getAppealsCategoryDataSuccess,
  getAppealsCategoryDataError,
  resetAppealError
};
