import React, { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import RadioGroup from "@timhortons/common/src/components/molecules/radioGroup";
import MultiSelect, { IItem } from "@timhortons/common/src/components/molecules/multiSelect";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { levelOfReportStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/levelOfReports/levelOfReportStyles";
import { ReportTypeLevel } from "../../template";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Roles } from "@timhortons/common/src/constants/roles";
import { LmsDataInReportLevel } from "@timhortons/common/src/modules/reportBuilders/components/lms/template";
import { AllModulesDataModel } from "@timhortons/common/src/models/lmsReports/lmsReportsModel";

interface IProps {
  translate?: any;
  selectedReportType?: ReportTypeLevel;
  selectedDependentLevel: LmsDataInReportLevel;
  setSelectedDependentReportLevel: Function;
  setSelectedReportLevel?: Function;
  selectedLevel: LmsDataInReportLevel;
  user?: IUserDetails;
  reportData?: AllModulesDataModel;
  setAllModulesSearchText: Function;
  handleSelectedValues: Function;
  resetAllModulesSearchText?: boolean;
  allModulesSearchText?: string;
  allModulesReqSearch?: string;
  setAllModulesReqSearch?: Function;
  setAllModules: () => void;
  selectedAllModules?: any;
}

export default function LMSDataInReport(props: IProps) {
  const {
    translate,
    setSelectedReportLevel,
    selectedLevel,
    user,
    selectedDependentLevel,
    setSelectedDependentReportLevel,
    selectedReportType,
    reportData,
    handleSelectedValues,
    setAllModulesSearchText,
    resetAllModulesSearchText,
    selectedAllModules,
    allModulesSearchText,
    allModulesReqSearch,
    setAllModulesReqSearch,
    setAllModules
  } = props;

  const LmsDataInReportOptions = [
    {
      name: translate("coreTraining"),
      key: LmsDataInReportLevel.coreTraining,
      onPress: () => {
        setSelectedReportLevel(LmsDataInReportLevel.coreTraining);
      },
      isSelected: LmsDataInReportLevel.coreTraining === selectedLevel
    },
    {
      name: translate("specialTraining"),
      key: LmsDataInReportLevel.specialTraining,
      onPress: () => {
        setSelectedReportLevel(LmsDataInReportLevel.specialTraining);
      },
      isSelected: LmsDataInReportLevel.specialTraining === selectedLevel
    }
  ];

  const LmsDependentDataInReportOptions = [
    {
      name: translate("theoreticalScore"),
      key: LmsDataInReportLevel.theoreticalScore,
      onPress: () => setSelectedDependentReportLevel(LmsDataInReportLevel.theoreticalScore),
      isSelected: LmsDataInReportLevel.theoreticalScore === selectedDependentLevel
    },
    {
      name: translate("practicalStatus"),
      key: LmsDataInReportLevel.practicalStatus,
      onPress: () => setSelectedDependentReportLevel(LmsDataInReportLevel.practicalStatus),
      isSelected: LmsDataInReportLevel.practicalStatus === selectedDependentLevel,
      isDisabled:
        selectedReportType === ReportTypeLevel.HighLow ||
        (selectedReportType === ReportTypeLevel.Detailed &&
          selectedLevel === LmsDataInReportLevel.specialTraining)
    },
    {
      name: translate("moduleStatus"),
      key: LmsDataInReportLevel.moduleStatus,
      onPress: () => setSelectedDependentReportLevel(LmsDataInReportLevel.moduleStatus),
      isSelected: LmsDataInReportLevel.moduleStatus === selectedDependentLevel,
      isDisabled: selectedReportType === ReportTypeLevel.HighLow
    }
  ];
  const getMultiSelectUI = () => {
    let val: string[] = [];
    let allMod = selectedAllModules;
    allMod.map((item: any) => {
      val.push(item.value.toUpperCase());
    });
    return (
      <View style={styles.dropDownContainer}>
        <MultiSelect
          allItems={reportData}
          title={translate("Select Module")}
          isSearchRequired={true}
          handleSelectedValues={(vals: IItem[]) => {
            handleSelectedValues && handleSelectedValues(vals);
          }}
          enableChildSelectAll={true}
          setSearchText={setAllModulesSearchText}
          resetSearchText={resetAllModulesSearchText}
          //  reqSearch={allModulesReqSearch}
          //  setReqSearch={setAllModulesReqSearch}
          isBuilder={true}
          onEndReached={setAllModules}
          selectedValues={val}
        />
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {user?.roles?.includes(Roles.StoreAccess) ? (
          <Text
            fontWeight={FontWeight.SemiBold}
            textSize={TextSize.ExtraLarge}
            testId={testIds.reportBuilder.dataInReport}
            textStyle={styles.headerText}
          >
            {translate("StoreDataInReport")}
          </Text>
        ) : (
          <Text
            fontWeight={FontWeight.SemiBold}
            textSize={TextSize.ExtraLarge}
            testId={testIds.reportBuilder.dataInReport}
            textStyle={styles.headerText}
          >
            {translate("lmsDataInReport")}
          </Text>
        )}
      </View>
      <View style={styles.elementContainer}>
        <View style={styles.radioGroupContainer}>
          <RadioGroup isBorder={true} radioOptions={LmsDataInReportOptions} resetPrev />
        </View>
        <View style={styles.radioGroupContainer}>
          <RadioGroup isBorder={true} radioOptions={LmsDependentDataInReportOptions} resetPrev />
        </View>

        <View>
          <Text
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.Regular}
            testId="dropdown"
            textStyle={levelOfReportStyles.dropdownLabel}
          >
            {translate("Select Module")}
          </Text>
          <View style={styles.dropDownContainer}>{getMultiSelectUI()}</View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {
    flex: 1
  },
  headerText: {
    color: colorPallete.black3
  },
  elementContainer: {
    maxWidth: 530,
    marginTop: 24
  },
  radioGroupContainer: {
    marginBottom: 30,
    flexDirection: "row"
  },
  dropDownContainer: {}
});
