import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { ActionLogsRepository } from "@timhortons/common/src/repositories/logs/actionsLogs";
import { GetWeeklyActionQuestions } from "@timhortons/common/src/models/logs/actionLogs/cleaning";
import { ActionCleaningActions } from "@timhortons/common/src/redux/logs/actions/actionLogs/cleaning";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";
import { IUpdateActionsRequest } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";

function* getDeepCleaningActionsWatcherSaga(value: IFluxStandardAction<IProdTempApi>) {
  const { payload } = value;
  try {
    let data: GetWeeklyActionQuestions[] = yield call(
      ActionLogsRepository.getWeeklyCleaningActions,
      payload
    );
    yield put(ActionCleaningActions.getDeepCleaningActionsSuccess(data));
  } catch (error) {
    Logger.error("actionLogs-cleaningActions", error, "getDeepCleaningActionsWatcherSaga");

    yield put(ActionCleaningActions.getDeepCleaningActionsError(error));
  }
}

function* updateDeepCleaningActionsWatcherSaga(
  value: IFluxStandardAction<{ data: IUpdateActionsRequest; isRestRoom: boolean }>
) {
  const { payload } = value;
  let questionId = payload.data.itemId;
  try {
    let data: GetWeeklyActionQuestions[] = yield call(
      ActionLogsRepository.updateWeeklyCleaningActions,
      payload.data
    );
    let finalData = {
      body: data,
      questionId: questionId,
      isRestRoom: payload.isRestRoom
    };
    yield put(ActionCleaningActions.updateDeepCleaningActionsSuccess(finalData));
  } catch (error) {
    Logger.error("actionLogs-cleaningActions", error, "updateDeepCleaningActionsWatcherSaga");

    yield put(ActionCleaningActions.updateDeepCleaningActionsError(error));
  }
}

function* getRestRoomActionsWatcherSaga(
  value: IFluxStandardAction<{ categoryId: string; date: string; label: string; storeCode: string }>
) {
  const { payload } = value;
  try {
    let data: GetWeeklyActionQuestions[] = yield call(
      ActionLogsRepository.getRestRoomCleaningActions,
      payload
    );
    yield put(ActionCleaningActions.getRestRoomCleaningActionsSuccess(data));
  } catch (error) {
    Logger.error("actionLogs-cleaningActions", error, "getRestRoomActionsWatcherSaga");

    yield put(ActionCleaningActions.getRestRoomCleaningActionsError(error));
  }
}

function* cleaningActionsWatcherSaga() {
  yield takeLatest(
    LOGS.Actions.Cleaning.GET_DEEP_CLEANING_ACTIONS.LOADING,
    getDeepCleaningActionsWatcherSaga
  );
  yield takeLatest(
    LOGS.Actions.Cleaning.UPDATE_DEEP_CLEANING_ACTIONS.LOADING,
    updateDeepCleaningActionsWatcherSaga
  );
  yield takeLatest(
    LOGS.Actions.Cleaning.GET_RESTROOM_ACTIONS.LOADING,
    getRestRoomActionsWatcherSaga
  );
}
export default cleaningActionsWatcherSaga;
