import {
  LmsPTSCheckListModel,
  LmsPTSCheckListUnselectModel,
  LmsPTSDataDtoModel
} from "@timhortons/common/src/models/userLms/userLmsPTS";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import { selectPtsCheckListFuncn } from "@timhortons/common/src/repositories/userLms/formatters/userLmsPtsFormatters";

export interface IUserLmsPTSLoading {
  getUsrLmsPTSData: boolean;
  checkListSelect: boolean;
  checkListUnselect: boolean;
}

export interface IUserLmsPTSError {
  getUsrLmsPTSData: string;
  checkListSelect: string;
  checkListUnselect: string;
}

export interface IUserLmsPTSState {
  loading: IUserLmsPTSLoading;
  error: IUserLmsPTSError;
  getUsrLmsPTSData: LmsPTSDataDtoModel[];
  checkListSelect: LmsPTSCheckListModel;
  checkListUnselect: LmsPTSCheckListUnselectModel;
}

export const userLmsPTSInitialState: IUserLmsPTSState = {
  getUsrLmsPTSData: [],
  checkListSelect: null,
  checkListUnselect: null,
  loading: {
    getUsrLmsPTSData: null,
    checkListSelect: null,
    checkListUnselect: null
  },
  error: {
    getUsrLmsPTSData: null,
    checkListSelect: null,
    checkListUnselect: null
  }
};

export const userLmsPTSReducer = (
  state = userLmsPTSInitialState,
  action: { payload: any; type: string }
): IUserLmsPTSState => {
  switch (action.type) {
    case UserLMS.userLmsPTS.GET_PTS_CHECKLIST.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getUsrLmsPTSData: true
        },
        error: {
          ...state.error,
          getUsrLmsPTSData: null
        }
      };
    case UserLMS.userLmsPTS.GET_PTS_CHECKLIST.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getUsrLmsPTSData: false
        },
        error: {
          ...state.error,
          getUsrLmsPTSData: null
        },
        getUsrLmsPTSData: action.payload
      };
    case UserLMS.userLmsPTS.GET_PTS_CHECKLIST.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getUsrLmsPTSData: false
        },
        error: {
          ...state.error,
          getUsrLmsPTSData: action.payload
        }
      };

    case UserLMS.userLmsPTS.PTS_CHECKLIST_SELECT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          checkListSelect: true
        },
        error: {
          ...state.error,
          checkListSelect: null
        }
      };
    case UserLMS.userLmsPTS.PTS_CHECKLIST_SELECT.SUCCESS:
      let arrData1 = [];
      arrData1 = [...state.getUsrLmsPTSData];
      let newPtsDataSelect: LmsPTSDataDtoModel[] = selectPtsCheckListFuncn(
        action.payload,
        arrData1,
        true
      );
      return {
        ...state,
        loading: {
          ...state.loading,
          checkListSelect: false
        },
        error: {
          ...state.error,
          checkListSelect: null
        },
        getUsrLmsPTSData: newPtsDataSelect,
        checkListSelect: action.payload
      };
    case UserLMS.userLmsPTS.PTS_CHECKLIST_SELECT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          checkListSelect: false
        },
        error: {
          ...state.error,
          checkListSelect: action.payload
        }
      };
    case UserLMS.userLmsPTS.PTS_CHECLIST_UNSELECT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          checkListUnselect: true
        },
        error: {
          ...state.error,
          checkListUnselect: null
        }
      };
    case UserLMS.userLmsPTS.PTS_CHECLIST_UNSELECT.SUCCESS:
      let arrData2 = [];
      arrData2 = [...state.getUsrLmsPTSData];
      let newPtsDataUnSelect: LmsPTSDataDtoModel[] = selectPtsCheckListFuncn(
        action.payload,
        arrData2,
        false
      );

      return {
        ...state,
        loading: {
          ...state.loading,
          checkListUnselect: false
        },
        error: {
          ...state.error,
          checkListUnselect: null
        },
        getUsrLmsPTSData: newPtsDataUnSelect,
        checkListUnselect: action.payload
      };
    case UserLMS.userLmsPTS.PTS_CHECLIST_UNSELECT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          checkListUnselect: false
        },
        error: {
          ...state.error,
          checkListUnselect: action.payload
        }
      };

    default:
      return state;
  }
};
