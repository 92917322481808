import { MANAGENEWS } from "@timhortons/common/src/redux/manageNews/actions/actionTypes";
import {
  NewsListModel,
  NewsDetailModel,
  NewsCategoryListModel,
  MediaResponseModel,
  NewsFeatureMediaModel,
  MediaRequestModel,
  DeleteMediaResponseModel
} from "@timhortons/common/src/models/manageNews/manageNews";

export interface IManageNewsLoading {
  newsList: boolean;
  newsDetailsById: boolean;
  deleteNews: boolean;
  getCategory: boolean;
  postCreateNewNews: boolean;
  getMediaLink: boolean;
  createMedia: boolean;
  saveNewsDetails: boolean;
  publishNews: boolean;
  editTitle: boolean;
  deleteMedia: boolean;
}

export interface IManageNewsError {
  newsList: string;
  newsDetailsById: string;
  deleteNews: string;
  getCategory: string;
  postCreateNewNews: string;
  getMediaLink: string;
  createMedia: string;
  saveNewsDetails: string;
  publishNews: string;
  editTitle: string;
  deleteMedia: string;
}

export interface IManageNewsState {
  newsList: NewsListModel[];
  newsDetailsById: NewsDetailModel;
  loading: IManageNewsLoading;
  error: IManageNewsError;
  categoryList: NewsCategoryListModel[];
  postCreateNewNews: NewsListModel;
  createMedia: NewsFeatureMediaModel;
  getMediaLink: MediaResponseModel;
  publishNewsSuccess: boolean;
  deleteMedia: DeleteMediaResponseModel;
}
export const manageNewsInitialState: IManageNewsState = {
  newsList: [],
  newsDetailsById: {
    id: "",
    title: "",
    category: "",
    featureMedia: [],
    description: null
  },
  categoryList: null,
  postCreateNewNews: null,
  createMedia: null,
  getMediaLink: null,
  publishNewsSuccess: null,
  deleteMedia: null,
  loading: {
    newsList: false,
    newsDetailsById: false,
    deleteNews: false,
    getCategory: false,
    postCreateNewNews: false,
    createMedia: false,
    getMediaLink: false,
    saveNewsDetails: false,
    publishNews: false,
    editTitle: false,
    deleteMedia: false
  },
  error: {
    newsList: null,
    newsDetailsById: null,
    deleteNews: null,
    getCategory: null,
    postCreateNewNews: null,
    createMedia: null,
    getMediaLink: null,
    saveNewsDetails: null,
    publishNews: null,
    editTitle: null,
    deleteMedia: null
  }
};
export const manageNewsReducer = (
  state = manageNewsInitialState,
  action: { payload: any; type: string }
): IManageNewsState => {
  switch (action.type) {
    case MANAGENEWS.GET_NEWSLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, newsList: true },
        error: { ...state.error, newsList: null }
      };
    case MANAGENEWS.GET_NEWSLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, newsList: false },
        error: { ...state.error, newsList: null },
        newsList: action.payload
      };
    case MANAGENEWS.GET_NEWSLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, newsList: false },
        error: { ...state.error, newsList: action.payload }
      };
    case MANAGENEWS.NEWS_DELETION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteNews: true },
        error: { ...state.error, deleteNews: null }
      };
    case MANAGENEWS.NEWS_DELETION.SUCCESS:
      let newsList = state.newsList;
      let deleteNewsIndex = newsList.findIndex((obj: any) => obj.id === action.payload.titleId);
      if (deleteNewsIndex > -1) {
        newsList.splice(deleteNewsIndex, 1);
      }
      return {
        ...state,
        loading: { ...state.loading, deleteNews: false },
        error: { ...state.error, deleteNews: null },
        newsList: newsList
      };
    case MANAGENEWS.NEWS_DELETION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteNews: false },
        error: { ...state.error, deleteNews: action.payload }
      };

    //get news details by id reducer
    case MANAGENEWS.GET_NEWS_DETAILS_BY_ID.LOADING:
      return {
        ...state,
        loading: { ...state.loading, newsDetailsById: true },
        error: { ...state.error, newsDetailsById: null }
      };
    case MANAGENEWS.GET_NEWS_DETAILS_BY_ID.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, newsDetailsById: false },
        error: { ...state.error, newsDetailsById: null },
        newsDetailsById: action.payload
      };
    case MANAGENEWS.GET_NEWS_DETAILS_BY_ID.ERROR:
      return {
        ...state,
        loading: { ...state.loading, newsDetailsById: false },
        error: { ...state.error, newsDetailsById: action.payload ? action.payload : "error" }
      };
    case MANAGENEWS.GET_CATEGORY_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCategory: true },
        error: { ...state.error, getCategory: null }
      };
    case MANAGENEWS.GET_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCategory: false },
        error: { ...state.error, getCategory: null },
        categoryList: action.payload
      };
    case MANAGENEWS.GET_CATEGORY_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCategory: false },
        error: { ...state.error, getCategory: action.payload }
      };

    case MANAGENEWS.POST_CREATE_NEW_NEWS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postCreateNewNews: true },
        error: { ...state.error, postCreateNewNews: null }
      };
    case MANAGENEWS.POST_CREATE_NEW_NEWS.SUCCESS:
      let newsListDataModefied = state.newsList;
      let updateIndex = newsListDataModefied.length;
      newsListDataModefied.splice(updateIndex, 0, action.payload);
      return {
        ...state,
        loading: { ...state.loading, postCreateNewNews: true },
        error: { ...state.error, postCreateNewNews: null },
        postCreateNewNews: action.payload
      };
    case MANAGENEWS.POST_CREATE_NEW_NEWS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postCreateNewNews: false },
        error: { ...state.error, postCreateNewNews: action.payload }
      };

    //Get Media Link Reducer
    case MANAGENEWS.GET_MEDIA_LINK.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getMediaLink: true },
        error: { ...state.error, getMediaLink: null }
      };
    case MANAGENEWS.GET_MEDIA_LINK.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getMediaLink: false },
        error: { ...state.error, getMediaLink: null },
        getMediaLink: action.payload
      };
    case MANAGENEWS.GET_MEDIA_LINK.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getMediaLink: false },
        error: { ...state.error, getMediaLink: action.payload }
      };
    //Create Media Reducer
    case MANAGENEWS.CREATE_MEDIA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, createMedia: true },
        error: { ...state.error, createMedia: null }
      };
    case MANAGENEWS.CREATE_MEDIA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createMedia: false },
        error: { ...state.error, createMedia: null },
        createMedia: action.payload
      };
    case MANAGENEWS.CREATE_MEDIA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, createMedia: false },
        error: { ...state.error, createMedia: action.payload }
      };

    case MANAGENEWS.SAVE_NEWS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, saveNewsDetails: true },
        error: { ...state.error, saveNewsDetails: null }
      };
    case MANAGENEWS.SAVE_NEWS.SUCCESS:
      let newlyAddedDetails = state.newsDetailsById;
      let tempPayload = action.payload;
      if (tempPayload.titleId) {
        let tempNewsDetails = tempPayload.newsDetails;
        newlyAddedDetails.category = tempNewsDetails.categoryName;
        newlyAddedDetails.description = tempNewsDetails.content;
      }
      return {
        ...state,
        loading: { ...state.loading, saveNewsDetails: false },
        error: { ...state.error, saveNewsDetails: null },
        newsDetailsById: newlyAddedDetails
      };
    case MANAGENEWS.SAVE_NEWS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, saveNewsDetails: false },
        error: { ...state.error, saveNewsDetails: action.payload }
      };

    case MANAGENEWS.PUBLISH_NEWS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, publishNews: true },
        error: { ...state.error, publishNews: null },
        publishNewsSuccess: false
      };

    case MANAGENEWS.PUBLISH_NEWS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, publishNews: false },
        error: { ...state.error, publishNews: null },
        publishNewsSuccess: true
      };

    case MANAGENEWS.PUBLISH_NEWS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, publishNews: false },
        error: { ...state.error, publishNews: action.payload },
        publishNewsSuccess: false
      };
    case MANAGENEWS.RESET_PUBLISH_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          publishNews: null,
          deleteMedia: null,
          createMedia: null,
          getMediaLink: null
        }
      };
    case MANAGENEWS.DELETE_MEDIA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteMedia: true },
        error: { ...state.error, deleteMedia: null },
        deleteMedia: null
      };

    case MANAGENEWS.DELETE_MEDIA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteMedia: false },
        error: { ...state.error, deleteMedia: null },
        deleteMedia: action.payload
      };

    case MANAGENEWS.DELETE_MEDIA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteMedia: false },
        error: { ...state.error, deleteMedia: action.payload },
        deleteMedia: null
      };
    case MANAGENEWS.EDIT_TITLE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, editTitle: true },
        error: { ...state.error, editTitle: null }
      };
    case MANAGENEWS.EDIT_TITLE.SUCCESS:
      let formattedData = state.newsDetailsById;
      if (action.payload.titleId) {
        formattedData.title = action.payload.data.title;
      }
      return {
        ...state,
        loading: { ...state.loading, editTitle: false },
        error: { ...state.error, editTitle: null },
        newsDetailsById: formattedData
      };
    case MANAGENEWS.EDIT_TITLE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, editTitle: false },
        error: { ...state.error, editTitle: action.payload }
      };
    case MANAGENEWS.RESET_EDIT_TITLE_ERROR:
      return {
        ...state,
        error: { ...state.error, editTitle: null }
      };
    default:
      return state;
  }
};
