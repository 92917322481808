import React, { useEffect } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Roles } from "@timhortons/common/src/constants/roles";
import CardsDescLayout from "@timhortons/common/src/modules/reportBuilders/template/cardsDescLayout";
import Card from "@timhortons/common/src/components/atoms/card";
import { Divider } from "@timhortons/common/src/components/atoms/divider";
import ModuleHeader from "@timhortons/common/src/components/molecules/moduleHeader";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";

interface RBProps {
  history: any;
  translate: any;
  user: IUserDetails;
}

export const ReportBuilderStorageKeys: Record<any, string> = {
  // rev: "selectedData",
  lp: "lpSelectedData"
  // prodSheet: "psSelectedData"
};

export default function ReportBuildersDashboard(props: RBProps): React.ReactElement {
  const { history, translate, user } = props;

  useEffect(() => {
    const RemovingStorageKeys: Array<string> = [
      // ReportBuilderStorageKeys.rev,
      ReportBuilderStorageKeys.lp
      // ReportBuilderStorageKeys.prodSheet
    ];
    RemovingStorageKeys.forEach((keyEl: string) => LocalStorage.removeStorage(keyEl));
  }, []);

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <ModuleHeader
        title={translate("reportBuilder")}
        subTitle={
          user && user.roles.includes(Roles.StoreAccess)
            ? translate("Location", { name: user && user.storeName })
            : translate("hello", { name: user && user.name })
        }
      />
      <Card
        containerStyle={[
          styles.cardContainerStyle,
          PlatformUtils.isWeb() && {
            cursor: "default"
          }
        ]}
      >
        <Card.Header headerStyle={styles.headerContainer}>
          <Text
            textSize={TextSize.Medium3}
            fontWeight={FontWeight.SemiBold}
            testId="reportBuilders-header"
            textStyle={styles.headerText}
          >
            {translate("modules")}
          </Text>
        </Card.Header>
        <Divider dividerStyle={styles.headerDivider} />
        <CardsDescLayout user={user} history={history} translate={translate} />
      </Card>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    paddingVertical: 24,
    paddingHorizontal: 24
  },
  headerTitle: {
    color: colorPallete.black3,
    marginBottom: 6
  },
  locationTitle: {
    textTransform: "capitalize",
    color: colorPallete.grey1
  },
  cardContainerStyle: {
    flex: 1,
    shadowColor: colorPallete.black1,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.06,
    shadowRadius: 16,
    elevation: 5,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  },
  headerDivider: { borderColor: colorPallete.grey19 },
  headerText: { color: colorPallete.black2 }
});
