import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { GetWeeklyActionQuestions } from "@timhortons/common/src/models/logs/actionLogs/cleaning";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";
import { IUpdateActionsRequest } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";

const getDeepCleaningActions = (data: IProdTempApi): IFluxStandardAction<IProdTempApi> => {
  return {
    type: LOGS.Actions.Cleaning.GET_DEEP_CLEANING_ACTIONS.LOADING,
    payload: data
  };
};
const getDeepCleaningActionsSuccess = (
  data: GetWeeklyActionQuestions[]
): IFluxStandardAction<GetWeeklyActionQuestions[]> => {
  return {
    type: LOGS.Actions.Cleaning.GET_DEEP_CLEANING_ACTIONS.SUCCESS,
    payload: data
  };
};
const getDeepCleaningActionsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.Cleaning.GET_DEEP_CLEANING_ACTIONS.ERROR,
    payload: data
  };
};

const updateDeepCleaningActions = (value: {
  data: IUpdateActionsRequest;
  isRestRoom: boolean;
}): IFluxStandardAction<{ data: IUpdateActionsRequest; isRestRoom: boolean }> => {
  return {
    type: LOGS.Actions.Cleaning.UPDATE_DEEP_CLEANING_ACTIONS.LOADING,
    payload: value
  };
};

const updateDeepCleaningActionsSuccess = (value: {
  body: GetWeeklyActionQuestions[];
  questionId: string;
  isRestRoom: boolean;
}): IFluxStandardAction<{
  body: GetWeeklyActionQuestions[];
  questionId: string;
  isRestRoom: boolean;
}> => {
  return {
    type: LOGS.Actions.Cleaning.UPDATE_DEEP_CLEANING_ACTIONS.SUCCESS,
    payload: value
  };
};

const updateDeepCleaningActionsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.Cleaning.UPDATE_DEEP_CLEANING_ACTIONS.ERROR,
    payload: data
  };
};
const getRestRoomCleaningActions = (data: {
  categoryId: string;
  date: string;
  label: string;
  storeCode: string;
}): IFluxStandardAction<{ categoryId: string; date: string; label: string; storeCode: string }> => {
  return {
    type: LOGS.Actions.Cleaning.GET_RESTROOM_ACTIONS.LOADING,
    payload: data
  };
};

const getRestRoomCleaningActionsSuccess = (
  data: GetWeeklyActionQuestions[]
): IFluxStandardAction<GetWeeklyActionQuestions[]> => {
  return {
    type: LOGS.Actions.Cleaning.GET_RESTROOM_ACTIONS.SUCCESS,
    payload: data
  };
};
const getRestRoomCleaningActionsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.Cleaning.GET_RESTROOM_ACTIONS.ERROR,
    payload: data
  };
};
const resetCleaningActionErrors = () => {
  return {
    type: LOGS.Actions.Cleaning.RESET_ACTIONS_CLEANING_ERRORS
  };
};
const resetCleaningActionError = () => {
  return {
    type: LOGS.Actions.Cleaning.RESET_ACTIONS_CLEANING_STATE
  };
};
export const ActionCleaningActions = {
  getDeepCleaningActions,
  getDeepCleaningActionsSuccess,
  getDeepCleaningActionsError,
  updateDeepCleaningActions,
  updateDeepCleaningActionsSuccess,
  updateDeepCleaningActionsError,
  getRestRoomCleaningActions,
  getRestRoomCleaningActionsSuccess,
  getRestRoomCleaningActionsError,
  resetCleaningActionErrors,
  resetCleaningActionError
};
