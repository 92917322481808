import categoryWatcherSaga from "@timhortons/common/src/redux/lp/saga/categoryWatcherSaga";
import { all } from "@redux-saga/core/effects";
import auditsWatcherSaga from "@timhortons/common/src/redux/lp/saga/auditsWatcherSaga";
import lpActionPlanWatcherSaga from "@timhortons/common/src/redux/lp/saga/actionPlansWatcherSaga";
import lpReportsWatcherSaga from "@timhortons/common/src/redux/lp/saga/lpReportsWatcherSaga";
import lpAppealsWatcherSaga from "@timhortons/common/src/redux/lp/saga/appealsWatcherSaga";

export default function* lpRootSaga() {
  yield all([
    auditsWatcherSaga(),
    categoryWatcherSaga(),
    lpActionPlanWatcherSaga(),
    lpReportsWatcherSaga(),
    lpAppealsWatcherSaga()
  ]);
}
