import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { ReportsRepository } from "@timhortons/common/src/repositories/productionSheet/reports";
import { Logger } from "@timhortons/common/src/services/logger";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import { ProductionReportsActions } from "../actions/reportsAction";
import { prodsheetKpiData } from "@timhortons/common/src/models/productionSheet/reportBuilder";

function* generateReportBuilderWorkerSaga(
  value: IFluxStandardAction<{
    object: any;
    reportType: string;
    countryCodes?: any;
    allSelected?: boolean;
    prodSheetType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: prodsheetKpiData[] = yield call(
      ReportsRepository.generateReportBuilder,
      payload
    );
    yield put(ProductionReportsActions.generateReportBuilderSuccess(response));
  } catch (error) {
    Logger.error("LP/reports", error, "generateReportBuilderWorkerSaga");
    yield put(
      ProductionReportsActions.generateReportBuilderError({
        error,
        reportType: payload?.object?.levelOfReport
      })
    );
  }
}

export default function* productionSheetReportsWatcherSaga() {
  yield takeLatest(
    PRODUCTION_SHEET.ReportBuilder.GENERATE_REPORT.LOADING,
    generateReportBuilderWorkerSaga
  );
}
