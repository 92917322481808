export const resEmpFormatter = (
  prevData: {
    reportType: string;
    startDate: string;
    endDate: string;
    reportData: any[];
    countryLabel: string;
    dataType: string;
    dependentDataType: string;
  },
  apiResponse: any
) => {
  let tempAPIResp: any = { content: [], totalElements: 0 };
  if (apiResponse?.content?.length > 0) {
    const newArrData: Array<any> = apiResponse.content[0]?.content;
    if (prevData?.reportData?.content?.length > 0) {
      let tempList = prevData.reportData.content;
      let tempIdx = tempList.findIndex((item) => item?.content[1]?.code == newArrData[1].code);
      if (tempIdx > -1) {
        tempList[tempIdx] = apiResponse.content[0];
      }
      tempAPIResp = {
        ...apiResponse,
        content: tempList
      };
    } else {
      tempAPIResp = { ...apiResponse };
    }
  }
  return tempAPIResp;
};
