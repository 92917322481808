import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { oopsCookie } from "@timhortons/common/src/assets/images/imageAssets";
import { Image } from "@timhortons/common/src/components/atoms/image";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { useTranslation } from "react-i18next";

interface IProps {
  children?: React.ReactNode;
  setModalVisible: () => void;
  errorImage?: string;
  errorMsg?: string;
}

export default function AlertModal(props: IProps): ReactElement {
  const { t } = useTranslation("common");

  const { children, setModalVisible, errorImage = oopsCookie, errorMsg = t("errorText") } = props;

  return (
    <GetModal setModalState={setModalVisible} customStyle={styles.modalContainer}>
      <Icon
        name={IconNames.close}
        size={14}
        customStyle={styles.iconContainer}
        onPress={setModalVisible}
      />
      <View style={styles.container}>
        <Image
          testId={testIds.iam.gateWayLogo}
          source={errorImage}
          imageStyle={styles.imageStyle}
        />
        <Text
          textSize={TextSize.ExtraRegular}
          fontWeight={FontWeight.Regular}
          textStyle={styles.textStyle}
          testId="error"
        >
          {errorMsg}
        </Text>
      </View>
      {children}
    </GetModal>
  );
}
const styles = StyleSheet.create({
  iconContainer: {
    alignSelf: "flex-end",
    paddingTop: 24,
    paddingRight: 24,
    color: colorPallete.grey2
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 12,
    paddingBottom: 32,
    paddingHorizontal: 40
  },
  imageStyle: {
    height: 300,
    width: 250
  },
  textStyle: {
    marginTop: 32,
    color: colorPallete.brown1
  },
  modalContainer: {
    width: 500
  }
});
