import { calendarEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { countriesFormatter } from "./calendarFormatter";
import { AreaCountryStorePayload, CreateEvent } from "redux/calendar/actions/calendarActions";
import {
  formatEmployee,
  formatManagerData,
  formatPayload,
  formatEventsData,
  formatStores,
  formatEvent
} from "./utils";
import { CalendarKeys } from "@timhortons/common/src/constants/apiKeys";

class CalendarModuleRepository {
  getAllEvents = async (data: any) => {
    const { startDate, endDate } = data;
    const ressponse = await ApiClient.get(
      calendarEndPoints.getAllEvents(startDate, endDate),
      null,
      CalendarKeys.WEB
    );
    return formatEventsData(ressponse);
  };

  getEventById = async (data: any) => {
    const { eventId } = data;
    const response = await ApiClient.get(
      calendarEndPoints.getEventById(eventId),
      null,
      CalendarKeys.WEB
    );
    return formatEvent(response);
  };

  getGccCountries = async () => {
    const response = await ApiClient.get(
      calendarEndPoints.getGccCountries(),
      null,
      CalendarKeys.WEB
    );
    return countriesFormatter(response);
  };

  createEvent = async (data: CreateEvent) => {
    const response = await ApiClient.post(
      calendarEndPoints.createEvent(),
      data,
      null,
      CalendarKeys.WEB
    );
  };

  getAreaCountryManagers = async (data: AreaCountryStorePayload) => {
    let obj = formatPayload(data);
    const response = await ApiClient.get(
      calendarEndPoints.getAllAreaManager(data.role),
      obj,
      CalendarKeys.WEB
    );
    return formatManagerData(response);
  };

  getStores = async (data: AreaCountryStorePayload) => {
    let obj = formatPayload(data);
    const response = await ApiClient.get(calendarEndPoints.getStores(), obj, CalendarKeys.WEB);

    return formatStores(response);
  };

  getEmployees = async (data: []) => {
    let countryCodes = "";
    let len = data.length;
    data.forEach((item, index) => {
      if (index === len - 1) {
        countryCodes = countryCodes + item;
      } else {
        countryCodes = countryCodes + item + ",";
      }
    });
    const response = await ApiClient.get(
      calendarEndPoints.getEmployees(),
      { countryCodes: countryCodes },
      CalendarKeys.WEB
    );
    return formatEmployee(response);
  };

  deleteEvent = async (params: { eventId: string }) => {
    let response = await ApiClient.delete(
      calendarEndPoints.deleteEvent(params.eventId),
      null,
      null,
      CalendarKeys.WEB
    );
    return response;
  };

  updateEvent = async (params: any) => {
    let { eventId } = params;
    delete params.eventId;

    let response = await ApiClient.put(
      calendarEndPoints.updateEvent(eventId),
      params,
      null,
      CalendarKeys.WEB
    );
  };
}
const calendarRepository = new CalendarModuleRepository();
export { calendarRepository as CalendarModuleRepository };
