import { MANAGE_EXISTING_USER } from "@timhortons/common/src/redux/manageExistingUser/actions/actionTypes";

export interface IManageExistingUserLoading {
  onBoardUsers: boolean;
  getExistingUsers: boolean;
}

export interface IManageExistingUserError {
  onBoardUsers: string;
  getExistingUsers: string;
}

export interface IManageExistingUserState {
  loading: IManageExistingUserLoading;
  error: IManageExistingUserError;
  onBoardUsers: any;
  getExistingUsers: any;
}

export const manageExistingUserInitialState: IManageExistingUserState = {
  onBoardUsers: null,
  getExistingUsers: null,
  loading: {
    onBoardUsers: false,
    getExistingUsers: false
  },
  error: {
    onBoardUsers: null,
    getExistingUsers: null
  }
};

export const manageExistingUserReducer = (
  state = manageExistingUserInitialState,
  action: { payload: any; type: string }
): IManageExistingUserState => {
  switch (action.type) {
    case MANAGE_EXISTING_USER.ONBOARD_EXISTING_USERS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, onBoardUsers: true },
        error: { ...state.error, onBoardUsers: null }
      };
    case MANAGE_EXISTING_USER.ONBOARD_EXISTING_USERS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, onBoardUsers: false },
        error: { ...state.error, onBoardUsers: null },
        onBoardUsers: action.payload
      };
    case MANAGE_EXISTING_USER.ONBOARD_EXISTING_USERS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, onBoardUsers: false },
        error: { ...state.error, onBoardUsers: action.payload }
      };

    case MANAGE_EXISTING_USER.GET_EXISTING_USERS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getExistingUsers: true },
        error: { ...state.error, getExistingUsers: null }
      };
    case MANAGE_EXISTING_USER.GET_EXISTING_USERS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getExistingUsers: false },
        error: { ...state.error, getExistingUsers: null },
        getExistingUsers: action.payload
      };
    case MANAGE_EXISTING_USER.GET_EXISTING_USERS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getExistingUsers: false },
        error: { ...state.error, getExistingUsers: action.payload }
      };

    default:
      return state;
  }
};
