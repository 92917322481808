import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("StationReadCategoryModel")
export class StationReadCategoryModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  title: string = "";
  @JsonProperty("percentage", Number)
  percent: number = 0;
}

@JsonObject("SRSubCategoryItemParamModel")
export class SRSubCategoryItemParamModel {
  //discuss and remove the model
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("startDate", String)
  startDate: string = "";
  @JsonProperty("endDate", String)
  endDate: string = "";
  @JsonProperty("shift", String)
  shift: string = "";
}

@JsonObject("SRSubCategoryItemStatusModel")
export class SRSubCategoryItemStatusModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("actionType", String)
  actionType: string = "";
  @JsonProperty("stationReadinessItemId", String)
  stationReadinessItemId: string = "";
}

@JsonObject("SRSubCategoryItemRequestModel")
export class SRSubCategoryItemRequestModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("stationReadinessItemsValue", SRSubCategoryItemStatusModel)
  stationReadinessItemsValue: SRSubCategoryItemStatusModel = null;
}

@JsonObject("SRSubCategoryItemResponseModel")
export class SRSubCategoryItemResponseModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("uniqueId", String)
  uniqueId: string = "";
}

@JsonObject("StationReadSubCategoryModel")
export class StationReadSubCategoryModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("questionList", [SRSubCategoryItemRequestModel]) // add the get subcategory api by value model for mapping questions
  questionList: SRSubCategoryItemRequestModel[] = [];
}
