import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import { homeEndPoints } from "@timhortons/common/src/constants/endPoints";

class HomeRepository {
  getHomeScreenVideos = async (param: {}): Promise<any> => {
    const dummy = {
      status: "Success",
      data: {
        about: {
          heading: "about",
          body: "about us",
          video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        },
        description: [
          {
            heading: "1",
            body: "1",
            video:
              "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          },
          {
            heading: "2",
            body: "2",
            video:
              "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          }
        ],
        gallery: [
          {
            id: "1",
            image: "url"
          },
          {
            id: "1",
            image: "url"
          },
          {
            id: "1",
            image: "url"
          }
        ]
      }
    };

    // let data = await ApiClient.get(
    //  homeEndPoints.getVideos(),
    //  param,
    //   LMSKeys.LMS_MANAGE
    // );
    return dummy;
  };
}

const homeRepository = new HomeRepository();
export { homeRepository as HomeRepository };
