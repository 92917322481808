import { IAM } from "@timhortons/common/src/modules/iam/redux/actions/types";
import { loginForm } from "@timhortons/common/src/modules/iam/login";
import { ForgotPasswordForm } from "@timhortons/common/src/modules/iam/forgotPassword";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { IUserDetails } from "../reducer/reducer";
const login = (data: loginForm): IFluxStandardAction<loginForm> => {
  return {
    type: IAM.LOGIN.LOADING,
    payload: data
  };
};
const loginSuccess = (data: IUserDetails): IFluxStandardAction<IUserDetails> => {
  return {
    type: IAM.LOGIN.SUCCESS,
    payload: data
  };
};
const loginError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: IAM.LOGIN.ERROR,
    payload: error
  };
};
const forgotPassword = (data: ForgotPasswordForm): IFluxStandardAction<ForgotPasswordForm> => {
  return {
    type: IAM.FORGOT_PASSWORD.LOADING,
    payload: data
  };
};
const forgotPasswordSuccess = (data: string): IFluxStandardAction<string> => {
  return {
    type: IAM.FORGOT_PASSWORD.SUCCESS,
    payload: data
  };
};
const forgotPasswordError = (error: string): IFluxStandardAction<string> => {
  return {
    type: IAM.FORGOT_PASSWORD.ERROR,
    payload: error
  };
};
const masterModules = (): IFluxStandardAction<null> => {
  return {
    type: IAM.MASTER_MODULES.LOADING,
    payload: null
  };
};
const masterModulesSuccess = (data: Array<string>): IFluxStandardAction<Array<string>> => {
  return {
    type: IAM.MASTER_MODULES.SUCCESS,
    payload: data
  };
};
const masterModulesError = (error: string): IFluxStandardAction<string> => {
  return {
    type: IAM.MASTER_MODULES.ERROR,
    payload: error
  };
};
export const IamActions = {
  login,
  loginSuccess,
  loginError,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
  masterModules,
  masterModulesError,
  masterModulesSuccess
};
