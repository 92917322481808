import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import React, { ReactElement, useMemo } from "react";
import { History } from "history";
import { Roles } from "@timhortons/common/src/constants/roles";
import LMSBuilderLayout from "@timhortons/common/src/modules/reportBuilders/components/lms/template/index";
import ModuleHeader from "@timhortons/common/src/components/molecules/moduleHeader";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { useTranslation } from "react-i18next";
interface IProps {
  user: IUserDetails;
  // translate?: any;
  history?: History;
}

export enum ComparsionLevel {
  Country = "COUNTRY",
  Channels = "CHANNEL",
  CountryManager = "COUNTRY_MANAGER",
  OpsManager = "OPS_MANAGER",
  AreaManager = "AREA_MANAGER",
  Stores = "STORE",
  ClusterHeadOfOps = "CLUSTER_HEAD_OF_OPS",
  LpAuditor = "LP_AUDITOR",
  AreaTrainer = "AREA_TRAINER",
  RestaurantEmployees = "RESTAURANT_EMPLOYEES"
}

export enum builderLevel {
  isLp = "LP",
  isRev = "REV",
  isProdSheet = "PRODUCTION_SHEET"
}
export default function ReportBuilder(props: IProps): ReactElement {
  const { user, history } = props;
  const { t } = useTranslation("rev");
  const detailedReportPermissions: Array<ComparsionLevel> = [
    ComparsionLevel.Country,
    ComparsionLevel.ClusterHeadOfOps,
    ComparsionLevel.CountryManager,
    ComparsionLevel.AreaManager,
    ComparsionLevel.OpsManager,
    ComparsionLevel.Channels,
    ComparsionLevel.Stores,
    ComparsionLevel.RestaurantEmployees,
    ComparsionLevel.AreaTrainer
  ];

  const lmsSlicedPermissions = useMemo(() => {
    let userRole = user?.roles[0];
    let result: Array<ComparsionLevel> = [];
    switch (userRole) {
      case Roles.SuperAdmin:
      case Roles.TrainingAndDevLead:
      case Roles.TrainingAndDevManager:
      case Roles.CEO:
      case Roles.COO:
      case Roles.HrAndAdminDirector:
      case Roles.ProductionAndDevExecutive:
        result = detailedReportPermissions;
        break;
      case Roles.ClusterHeadofOps:
        result = detailedReportPermissions
          .slice(0, 1)
          .concat(detailedReportPermissions.slice(3, 8));
        break;
      case Roles.CountryManager:
        result = detailedReportPermissions.concat(detailedReportPermissions.slice(3, 8));
        break;
      case Roles.OperationManager:
      case Roles.SeniorOpsManager:
        result = detailedReportPermissions
          .slice(3, 4)
          .concat(detailedReportPermissions.slice(5, 8));
        break;
      case Roles.AreaManager:
        result = detailedReportPermissions.slice(5, 8);
        break;
      case Roles.AreaTrainer:
        result = detailedReportPermissions
          .slice(3, 4)
          .concat(detailedReportPermissions.slice(6, 7));
        break;
      default:
        result = [];
        break;
    }
    return result;
  }, []);

  return (
    <>
      <ModuleHeader
        title={t("LmsHeader")}
        subTitle={t("hello", { name: user && user.name })}
        backBtnHandler={() => navigation(history, Pathnames.reportBuilders)}
      />
      <LMSBuilderLayout
        translate={t}
        detailedReportPermissions={lmsSlicedPermissions}
        history={history}
        user={user}
        moduleType={"PRODUCTION_SHEET"}
      />
    </>
  );
}
