import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("AckDropDownModel")
export class AckDropDownModel {
  @JsonProperty("name", String)
  value?: string = "";
  @JsonProperty("name", String)
  label?: string = "";
  @JsonProperty("employeeId", String)
  employeeId?: string = "";
}

@JsonObject("inProcessReportModal")
export class inProcessReportModal {
  @JsonProperty("inProcessCount", Number)
  inProcessCount?: number = 0;
}

@JsonObject("AcknowledgementModel")
export class AcknowledgementModel {
  @JsonProperty("dropDownData", AckDropDownModel)
  dropDownData: AckDropDownModel[];
}
@JsonObject("DetailsDataModel")
export class DetailsDataModel {
  @JsonProperty("store", String)
  store: string = "";
  @JsonProperty("auditor", String)
  auditor: string = "";
  @JsonProperty("auditStartedDate", String)
  auditStartedDate: string = "";
  @JsonProperty("auditEndedDate", String)
  auditEndedDate: string = "";
  @JsonProperty("startTime", String)
  startTime: string = "";
  @JsonProperty("endTime", String)
  endTime: string = "";
  @JsonProperty("brandStandard", String)
  brandStandard: string = "";
  @JsonProperty("foodSafety", String)
  foodSafety: string = "";
}
@JsonObject("BrandStandardListDataModel")
export class BrandStandardListDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("earnedValue", Number)
  earnedValue: number = 0;
  @JsonProperty("availableValue", Number)
  availableValue: number = 0;
  @JsonProperty("overallValue", String)
  overallValue: string = "0";
}
@JsonObject("BranStandardDataModel")
export class BranStandardDataModel {
  @JsonProperty("BrandStandardList", [BrandStandardListDataModel])
  BrandStandardList: BrandStandardListDataModel[];
  @JsonProperty("totalEarnedValue", String)
  totalEarnedValue: string = "";
  @JsonProperty("totalAvailableValue", String)
  totalAvailableValue: string = "";
  @JsonProperty("brandStandardPercentage", String)
  brandStandardPercentage: string = "";
}
@JsonObject("ScoringInformationDataModal")
export class ScoringInformationDataModal {
  @JsonProperty("brandStandardOpportunity", Number)
  brandStandardOpportunity: number = 0;
  @JsonProperty("foodSafetyCriticals", Number)
  foodSafetyCriticals: number = 0;
  @JsonProperty("foodSafetyImportant", Number)
  foodSafetyImportant: number = 0;
}
@JsonObject("FoodSafetyListDataModel")
export class FoodSafetyListDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("criticalValue", Boolean)
  criticalValue: boolean = false;
  @JsonProperty("importantValue", Boolean)
  importantValue: boolean = false;
}
@JsonObject("FoodSafetyDataModel")
export class FoodSafetyDataModel {
  @JsonProperty("score", Number)
  score: number = 0;
  @JsonProperty("FoodSafetyList", [FoodSafetyListDataModel])
  FoodSafetyList: FoodSafetyListDataModel[];
}

export class DataModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("serialNumber", String)
  serialNumber: string = "";
  @JsonProperty("title", String)
  title: string = "";
}
export class CategoriesNameModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("categoryName", String)
  categoryName: string = "";
  @JsonProperty("serialNumber", String)
  serialNumber: string = "";
  @JsonProperty("subCategory", [DataModel])
  subCategory: DataModel[] = null;
  @JsonProperty("attachments", Array)
  attachments: Array<string> = [""];
  @JsonProperty("comment", Array)
  comment: Array<string> = [""];
}

export class OpportunityDetailsModal {
  @JsonProperty("rejectedData", [CategoriesNameModel])
  rejectedData: CategoriesNameModel[] = null;
}
export class ObservationsModel {
  @JsonProperty("observation", String)
  observation: string = "";
  @JsonProperty("observationId", Number)
  observationId: number = 0;
}
export class Categories {
  @JsonProperty("serialNumber", String)
  serialNumber: string = "";
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("description", String)
  description?: string = "";
  @JsonProperty("observations", [ObservationsModel])
  observations?: ObservationsModel[] = null;
}

export class ZeroToleranceObservationModel {
  @JsonProperty("rejectedData", [Categories])
  categories: Categories[] = null;
  @JsonProperty("comment", String)
  comment: string = "";
  @JsonProperty("attachments", Array)
  attachments: Array<string> = [""];
}

@JsonObject("ReportsDataModal")
export class ReportsDataModal {
  @JsonProperty("zeroTolerance", Boolean)
  zeroTolerance?: boolean = false;
  @JsonProperty("location", String)
  location: string = "";
  @JsonProperty("isIcon", Boolean)
  isIcon: boolean = false;
  @JsonProperty("Details", DetailsDataModel)
  Details: DetailsDataModel;
  @JsonProperty("resultPercentage", Number)
  resultPercentage: number = 0;
  @JsonProperty("resultStatus", String)
  resultStatus: string = "";
  @JsonProperty("grade", String)
  grade: string = "";
  @JsonProperty("BranStandardTable", [BranStandardDataModel])
  BranStandardTable?: BranStandardDataModel;
  @JsonProperty("ScoringInformation", ScoringInformationDataModal)
  ScoringInformation?: ScoringInformationDataModal;
  @JsonProperty("foodSafteyTable", [FoodSafetyDataModel])
  foodSafetyTable?: FoodSafetyDataModel;
  @JsonProperty("OpportunityDetails", [OpportunityDetailsModal])
  OpportunityDetails?: OpportunityDetailsModal;
  @JsonProperty("zeroToleranceObservations", [ZeroToleranceObservationModel])
  zeroToleranceObservations?: ZeroToleranceObservationModel;
  @JsonProperty("isSigned", Boolean)
  isSigned: boolean = false;
  @JsonProperty("auditType", String)
  auditType: string = "";
  @JsonProperty("signedUrl", String && null)
  externalPDFlink: string = "";
}

@JsonObject("ReportTableModel")
export class ReportTableModel {
  @JsonProperty("storeCode", Number)
  storeId: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("auditDate", String)
  auditedOn: Date = new Date();
  @JsonProperty("auditor", String)
  auditBy: string = "";
  @JsonProperty("visitType", String && null)
  visitType: string = "";
  @JsonProperty("overallResult", Number && null)
  overAllScore: number = 0;
  @JsonProperty("overallKey", String && null)
  grade: string = "";
  @JsonProperty("calibrationId", Number)
  calibrationId: number = 0;
  @JsonProperty("reportId", Number)
  reportId: number = 0;
  @JsonProperty("isZT", Boolean, true)
  isZT: boolean = false;
}

@JsonObject("ReportTableResponseModel")
export class ReportTableResponseModel {
  @JsonProperty("totalRecords", Number)
  totalRecords: number = 0;
  @JsonProperty("records", [ReportTableModel])
  records: ReportTableModel[] = null;
}

@JsonObject("DetailedReportIdModel")
export class DetailedReportIdModel {
  @JsonProperty("reportId", String)
  reportId: string = "";
}

@JsonObject("PostAcknowledgementModel")
export class PostAcknowledgementModel {
  @JsonProperty("file", String)
  file: string = "";
  @JsonProperty("reportId", Number)
  reportId: number = null;
  @JsonProperty("storeInChargeId", String)
  storeInChargeId: string = "";
  @JsonProperty("remarks", String)
  remarks: string = "";
}

@JsonObject("BrandStandardKPIDataModel")
export class BrandStandardKPIDataModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("grade", String)
  grade?: string = "";
  @JsonProperty("overallScore", Number)
  overallScore?: number = 0;
  @JsonProperty("brandStandard", Number)
  brandStandard: number = 0;
  @JsonProperty("foodSafety", Number)
  foodSafety: number = 0;
  @JsonProperty("cleanliness", Number)
  cleanliness: number = 0;
  @JsonProperty("guest", Number)
  guest: number = 0;
  @JsonProperty("sos", Number)
  sos: number = 0;
  @JsonProperty("profitability", Number)
  profitability: number = 0;
  @JsonProperty("foodQuality", Number)
  foodQuality: number = 0;
  @JsonProperty("training", Number)
  training: number = 0;
  @JsonProperty("fc", Number)
  fc: string = "";
  @JsonProperty("dt", Number)
  dt: string = "";
}

@JsonObject("StoreRankingsTableData")
export class StoreRankingsTableData {
  @JsonProperty("rank", Number)
  rank: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("country", String)
  country?: string = "";
  @JsonProperty("grade", String)
  grade: string = "";
  @JsonProperty("avgScore", Number)
  avgScore: number = 0;
  @JsonProperty("sosFc", Number)
  sosFc?: number = 0;
  @JsonProperty("sosDt", Number)
  sosDt?: number = 0;
}

@JsonObject("ReportsSummaryOpportunityDataModel")
export class ReportsSummaryOpportunityDataModel {
  @JsonProperty("categoryName", String)
  categoryName: string = "";
  @JsonProperty("label", String)
  questionLabel: string = "";
  @JsonProperty("name", String)
  question: string = "";
  @JsonProperty("points", Number)
  points: number = 0;
  @JsonProperty("frequency", Number)
  frequency: number = 0;
}

@JsonObject("ActionPlansGraphData")
export class ActionPlansGraphData {
  @JsonProperty("series", Array)
  series: number[] = [];
  @JsonProperty("labels", Array)
  labels: string[] = [];
  @JsonProperty("totalReports", Number)
  totalReports: number = 0;
  @JsonProperty("inProcessReport", inProcessReportModal)
  inProcessReport: inProcessReportModal = {};
}

@JsonObject("CompleteAverageScoreDataModel")
export class CompleteAverageScoreDataModel {
  @JsonProperty("name", Array)
  name: string[] = [];
  @JsonProperty("percentage", Array)
  percentage: number[] = [];
}
@JsonObject("MonthOnMonthAverageDataModel")
export class MonthOnMonthAverageDataModel {
  @JsonProperty("percentage", Array)
  percentage: number[] = [];
  @JsonProperty("month", Array)
  month: string[] = [];
}
@JsonObject("FoodSafetyDeductionsDataModel")
export class FoodSafetyDeductionsDataModel {
  @JsonProperty("month", Array)
  month: string[] = [];
  @JsonProperty("points", Array)
  points: number[] = [];
  averageScore: number = 0;
}
@JsonObject("AreaUnderCountryMModelRev")
export class AreaUnderCountryMModelRev {
  @JsonProperty("roleId", String, true)
  id?: string = "";
  @JsonProperty("lastName", String)
  value: string = "";
  @JsonProperty("firstName", String)
  label: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("firstName", String)
  roleName: string = "";
}
@JsonObject("AreaUnderCountryManageDataModelRev")
export class AreaUnderCountryManageDataModelRev {
  @JsonProperty("content", [AreaUnderCountryMModelRev])
  content: AreaUnderCountryMModelRev[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}

export class BrandStandardsGraphDataModel {
  series: number[] = [];
  categories: string[] = [];
  averageScore: number = 0;
}

export class SosGraphDataModel {
  series: number[] = [];
  categories: string[] = [];
}
@JsonObject("StoreAccessSummaryDataModel")
export class StoreAccessSummaryDataModel {
  @JsonProperty("actionPlansData", ActionPlansGraphData)
  actionPlansData: ActionPlansGraphData = null;
  @JsonProperty("completeAverageScore", CompleteAverageScoreDataModel)
  completeAverageScore: CompleteAverageScoreDataModel = null;
  @JsonProperty("monthOnMonthAverageData", MonthOnMonthAverageDataModel)
  monthOnMonthAverageData: MonthOnMonthAverageDataModel = null;
  @JsonProperty("foodSafetyDeductionsData", FoodSafetyDeductionsDataModel)
  foodSafetyDeductionsData: FoodSafetyDeductionsDataModel = null;
  brandStandardsData: BrandStandardsGraphDataModel = null;
  sosGraphData: SosGraphDataModel = null;
}

export class IndividualFsDeductionsModel {
  deductions: number[] = [];
  stores: number[] = [];
}

export class GradeDistributionModel {
  label: string[] = [];
  storeCount: number[] = [];
}

export class IndividualAccessSummaryDataModel {
  actionPlansData: ActionPlansGraphData = null;
  averageScore: CompleteAverageScoreDataModel = null;
  fsDeductions: IndividualFsDeductionsModel = null;
  gradeDistribution: GradeDistributionModel = null;
  monthOnMonthAverageData: MonthOnMonthAverageDataModel = null;
  opportunityData: ReportsSummaryOpportunityDataModel[] = null;
  storeRankingTopFiveData: StoreRankingsTableData[] = null;
  storeRankingLastFiveData: StoreRankingsTableData[] = null;
  brandStandardKPIData: BrandStandardKPIDataModel[] = null;
  areaManagerRankingsTableData?: StoreRankingsTableData[] = null;
}

export class ReportBuilderResponseModel {
  metaData: ReportBuilderMetaData = null;
  apiResponse: {
    content: BrandStandardKPIDataModel[];
    totalElements: number;
  };
}

export class ReportBuilderMetaData {
  startDate: string = "";
  endDate: string = "";
  reportType: string = "";
  levelOfReport: string = "";
  reportEnum?: string = "";
  isKPI?: string = "";
}

export class CountryManagerDetailsModel {
  employeeId: string = "";
  firstName: string = "";
  lastname: string = "";
  countryCode: number = null;
  countryName: string = "";
  countryLabel: string = "";
}
