export const initialDragDropOptionFuncn = () => {
  let newArr: any = [];
  for (let i = 0; i < 4; i++) {
    let tempObj: any = {};
    tempObj.id = i + 1;
    tempObj.definition = "";
    tempObj.option = "";
    newArr.push({ ...tempObj });
  }
  return newArr;
};

export const initialImageDragDropOptionFuncn = () => {
  let newArr: any = [];
  for (let i = 0; i < 4; i++) {
    let tempObj: any = {};
    tempObj.id = i + 1;
    tempObj.imageKey = "";
    tempObj.option = "";
    tempObj.image = "";
    tempObj.definition = "";
    newArr.push({ ...tempObj });
  }
  return newArr;
};

export const initialTicTacToeOptionFuncn = (length: number) => {
  let newArr: any = [];
  const cellArr = [5, 2, 4, 8, 6];
  for (let i = 0; i < length; i++) {
    let newArrObj: any = {};
    newArrObj.id = `lms-ticTacToe-${i + 1}`;
    newArrObj.question = "";
    newArrObj.image = "";
    newArrObj.options = [];
    newArrObj.isDisable = true;
    newArrObj.cell = cellArr[i];
    for (let j = 0; j < 4; j++) {
      let optionObj: any = {};
      optionObj.id = `q${i + 1}-option-${j + 1}`;
      optionObj.optionVal = "";
      if (j === 0) {
        optionObj.response = true;
      } else {
        optionObj.response = false;
      }
      newArrObj.options.push({ ...optionObj });
    }
    newArr.push({ ...newArrObj });
  }
  return newArr;
};
