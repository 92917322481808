import React, { useEffect, useState } from "react";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Label, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import Loader from "@timhortons/common/src/components/molecules/loader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ReportTableResponseModel } from "@timhortons/common/src/models/rev/reports";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";
import { ReportTable } from "@timhortons/common/src/modules/rev/components/dashboards/reports";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";

interface IProps {
  translate?: any;
  reportTableData: ReportTableResponseModel;
  currentUserRoles: IUserDetails;
  history?: any;
  loading: boolean;
  error: string;
  getReportTableData?: Function;
  setReportData?: Function;
  setCurrentTable?: Function;
  setIndividualLogin?: Function;
  // reportApiData?: IReportTableData;
  finalReportData?: any;
  handleViewIcon?: (calibrationId: number, reportId: number) => void;
}

enum TableColumns {
  auditDate = "auditDate"
  // overAllScore = "overAllScore" //TODO - add it later
}

export function gradeLabelStyle(grade: string) {
  switch (grade) {
    case "A":
      return {
        backgroundColor: colorPallete.variant8
      };
    case "B":
      return {
        backgroundColor: colorPallete.variant11
      };
    case "C":
      return {
        backgroundColor: colorPallete.variant12
      };
    case "D":
      return {
        backgroundColor: colorPallete.variant13
      };
    // case "F" :
    //   return {
    //     backgroundColor: colorPallete.variant9
    //   };
    // case "ZT":
    //   return {
    //     backgroundColor: colorPallete.variant9
    //   };
    default:
      return {
        backgroundColor: colorPallete.variant9
      };
  }
}

export const gradingStyle = (grade: string) => {
  switch (grade) {
    case "B":
      return {
        color: colorPallete.green2
      };
    case "C":
      return {
        color: colorPallete.yellow1
      };
    case "D":
      return {
        color: colorPallete.yellow1
      };
    case "F":
    case "ZT":
      return {
        color: colorPallete.red1
      };
    case "A":
    default:
      return {
        color: colorPallete.green1
      };
  }
};

export default function index(props: IProps) {
  const {
    translate,
    reportTableData,
    currentUserRoles,
    loading,
    error,
    getReportTableData,
    setReportData,
    setCurrentTable,
    setIndividualLogin,
    // reportApiData,
    finalReportData,
    handleViewIcon
  } = props;

  const sortHandler = (val: string) => {
    setReportData((prevState: any) => ({
      ...prevState,
      sortOrder: val,
      pageNumber: 1
    }));
    getReportTableData({
      ...finalReportData,
      sortOrder: val,
      pageNumber: 1
    });
  };
  const pageHandler = (val: number) => {
    setReportData((prevState: any) => ({
      ...prevState,
      pageNumber: val
    }));
    getReportTableData({
      ...finalReportData,
      pageNumber: val
    });
  };
  const isFocused = useFocusedHook();
  useEffect(() => {
    if (currentUserRoles) {
      if (!checkAllowedRoles(currentUserRoles && currentUserRoles.roles)) {
        isFocused &&
          getReportTableData({
            ...finalReportData,
            userId: currentUserRoles && currentUserRoles.empId,
            storeId: currentUserRoles && currentUserRoles.storeCode,
            isIndividualLogin: false
          });
        setReportData((prevState: any) => ({
          ...prevState,
          userId: currentUserRoles && currentUserRoles.empId,
          storeId: currentUserRoles && currentUserRoles.storeCode,
          isIndividualLogin: false
        }));
      } else {
        setCurrentTable(ReportTable.audits);
        setIndividualLogin(true);
        isFocused &&
          getReportTableData({
            ...finalReportData,
            userId: currentUserRoles && currentUserRoles.empId,
            storeId: currentUserRoles && currentUserRoles.storeCode,
            isIndividualLogin: true
          });
        setReportData((prevState: any) => ({
          ...prevState,
          userId: currentUserRoles && currentUserRoles.empId,
          storeId: currentUserRoles && currentUserRoles.storeCode,
          isIndividualLogin: true
        }));
      }
    }
  }, [currentUserRoles, isFocused]);

  const [auditDateColumnSelected, setAuditDateColumnSelected] = useState(true);
  const [overAllScoreColumnSelected, setoverAllScoreColumnSelected] = useState(false);
  const handleSort = (val: "asc" | "desc", columnName: string) => {
    if (columnName === translate(TableColumns.auditDate)) {
      setAuditDateColumnSelected(true);
      setoverAllScoreColumnSelected(false);
    } else {
      setAuditDateColumnSelected(false);
      setoverAllScoreColumnSelected(true);
    }

    sortHandler(val);
  };
  const getHeaderComp = (headerItem: any, i: number) => {
    return (
      <HeaderCell
        key={i}
        columnSelected={headerItem.selected}
        title={headerItem.name}
        sortable={headerItem.isSortable}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.grey1 }}
        sortFunction={(val: "asc" | "desc") => handleSort(val, headerItem.name)}
      />
    );
  };

  const reportTableHeader = [
    { name: translate("storeId"), isSortable: false },
    { name: translate("storeNameTable"), isSortable: false },
    { name: translate("auditDate"), isSortable: true, selected: auditDateColumnSelected },
    { name: translate("Auditor"), isSortable: false },
    { name: translate("visitType"), isSortable: false },
    { name: translate("overAllScore"), isSortable: false, selected: overAllScoreColumnSelected }, // TODO - add sorting for over all score
    { name: translate("view"), isSortable: false }
  ];

  return (
    <View style={{ flex: 1 }}>
      <Table>
        <Row header rowStyle={styles.tableRowStyle}>
          {reportTableHeader.map((headerItem, headerIndex) =>
            getHeaderComp(headerItem, headerIndex)
          )}
        </Row>
        {loading ? (
          <Loader type="component" />
        ) : (reportTableData && reportTableData.records.length > 0) || error.length < 0 ? (
          <View>
            {reportTableData &&
              reportTableData.records.map((item: any, x) => {
                return (
                  <View key={x}>
                    <Row key={x}>
                      <Cell titleStyle={styles.title} title={item.storeId} />
                      <Cell titleStyle={styles.title} title={item.storeName} />
                      <Cell titleStyle={styles.title} title={dateFormat(item.auditedOn)} />
                      <Cell titleStyle={styles.title} title={item.auditBy} />
                      <Cell titleStyle={styles.title} title={item.visitType || "-"} />
                      <Cell titleStyle={styles.title}>
                        <Label
                          textSize={TextSize.ExtraMedium}
                          fontWeight={FontWeight.SemiBold}
                          testId="id"
                          style={[styles.textStyleChip, gradeLabelStyle(item.grade)]}
                        >
                          <Text
                            textSize={TextSize.Regular}
                            fontWeight={FontWeight.SemiBold}
                            testId="id"
                            textStyle={gradingStyle(item.grade)}
                          >
                            {item.grade} - (
                            {item.overAllScore === 0 || item.overAllScore === null
                              ? 0
                              : item.overAllScore.toFixed(2)}
                            ) {item.overAllScore ? "%" : ""}
                          </Text>
                        </Label>
                      </Cell>
                      <Cell>
                        <TouchableOpacity
                          onPress={() => handleViewIcon(item.calibrationId, item.reportId)}
                        >
                          <Icon
                            name={IconNames.eyeView}
                            size={16}
                            customStyle={styles.viewIconStyle}
                          />
                        </TouchableOpacity>
                      </Cell>
                    </Row>
                    {reportTableData.records.length - 1 === x ? null : (
                      <Divider
                        orientation={Orientations.Horizontal}
                        dividerStyle={styles.divider}
                      />
                    )}
                  </View>
                );
              })}
            <CommonPagination
              handlePagination={pageHandler}
              pageNum={finalReportData.pageNumber}
              totalRecords={reportTableData && reportTableData.totalRecords}
            />
          </View>
        ) : error ? (
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
        )}
      </Table>
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    color: colorPallete.black4,
    justifyContent: "center",
    textAlign: "center"
  },
  viewIconStyle: {
    color: colorPallete.grey2
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: colorPallete.grey8
  },
  divider: {
    borderColor: colorPallete.white1
  },
  tableRowStyle: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  }
});
