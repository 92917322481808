import React, { ReactElement, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { StyleSheet, View, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from "react-native";
import "./doubleView.scss";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import OverflowModal from "@timhortons/common/src/components/atoms/overflowModal";
import { setFrameMeasures } from "@timhortons/common/src/utils/measureLayout";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import {
  yearFormat,
  doubleformatDatePicker,
  convert,
  subtractDays,
  currMonthStartEndDate
} from "@timhortons/common/src/utils/dateFormat";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { IButtonStyle } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportType";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
interface IProps {
  selectRange?: boolean;
  dateContainerStyle?: StyleProp<ViewStyle>;
  showDoubleView?: boolean;
  parentDoubleViewCall?: (val: Date | Date[]) => void;
  fontWeight?: FontWeight;
  textSize?: TextSize;
  textStyles?: StyleProp<TextStyle>;
  showWeekNumbers?: boolean;
  translate?: any;
  reset?: boolean;
  triggerComponent?: boolean;
  onPressButton?: () => void;
  buttonProps?: IButtonStyle;
  iconSize?: number;
  disableFutureDates?: boolean;
  prevSelectedValues?: any;
  enablePrevValuesFetch?: boolean;
  resetToMonthStart?: boolean;
  setReset?: Function;
}

export const DoubleDatePicker = (props: IProps): ReactElement => {
  const {
    selectRange,
    triggerComponent,
    dateContainerStyle,
    showDoubleView,
    reset,
    fontWeight = FontWeight.Medium,
    textSize = TextSize.Medium2,
    parentDoubleViewCall,
    textStyles = {},
    showWeekNumbers,
    translate,
    onPressButton,
    buttonProps,
    iconSize = 16,
    disableFutureDates = true,
    prevSelectedValues,
    enablePrevValuesFetch = false,
    resetToMonthStart = false,
    setReset
  } = props;
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [date, setDate] = useState<Date[]>(null);
  const [buttonFrame, setButtonFrame] = useState(null);
  const [disableApply, setDisabled] = useState(true);
  const [showApplyButton, setShowButton] = useState(false);
  const triggerRef = useRef(null);

  const onCalenderOpen = (): void => {
    setIsCalenderOpen(!isCalenderOpen);
  };

  const onDateClick = (value: Date[]) => {
    if (value) {
      setDate(value);
      setDisabled(false);
    }
  };
  useEffect(() => {
    if (
      prevSelectedValues &&
      prevSelectedValues.current.fromDate !== null &&
      prevSelectedValues.current.toDate !== null &&
      date === null
    ) {
      setDate([
        new Date(prevSelectedValues?.current.fromDate),
        new Date(prevSelectedValues?.current.toDate)
      ]);
    }
  }, [prevSelectedValues]);
  useEffect(() => {
    if (reset) {
      // {
      //   prevSelectedValues
      //     ? setDate([
      //         new Date(
      //           resetToMonthStart
      //             ? currMonthStartEndDate().startDate
      //             : subtractDays(2, new Date()).setHours(0, 0, 0, 0)
      //         ),
      //         new Date(new Date().setHours(23, 59, 59, 999))
      //       ])
      //     : setDate(null);
      // }
      if (prevSelectedValues && resetToMonthStart) {
        setDate([
          new Date(currMonthStartEndDate().startDate),
          new Date(new Date().setHours(23, 59, 59, 999))
        ]);
      } else {
        setDate(null);
      }
      setReset && setReset(false);
      setDisabled(true);
    }
  }, [reset]);

  const showModal = (): void => {
    setFrameMeasures(
      () => {
        setIsCalenderOpen(true);
      },
      triggerRef,
      setButtonFrame
    );
  };
  const handleApply = (): void => {
    if (date !== null) {
      setShowButton(true);
      parentDoubleViewCall(date);
      onCalenderOpen();
    }
  };
  const handleCancel = (): void => {
    setDisabled(true);
    onCalenderOpen();
    let updatedVal: any = null;
    if (prevSelectedValues) {
      updatedVal = [
        new Date(prevSelectedValues?.current.fromDate),
        new Date(new Date(prevSelectedValues?.current.toDate).setHours(23, 59, 59, 999))
      ];
    }
    enablePrevValuesFetch ? setDate(updatedVal) : setDate(null);
    //earlier we were only sending null, but to get the prev values added the enablePrevValuesFetch boolean
    enablePrevValuesFetch ? parentDoubleViewCall(updatedVal) : parentDoubleViewCall(null);
  };
  const getBottomView = (): ReactElement => {
    return (
      <>
        <View style={styles.bottomContainer}>
          <View style={styles.horizontalDivider}>
            <Divider orientation={Orientations.Horizontal} dividerStyle={styles.dividerStyle1} />
          </View>
          <View style={styles.subContainer}>
            <View>
              <Text
                fontWeight={fontWeight}
                textSize={TextSize.ExtraSmall}
                testId={testIds.actionPlan.fromDateToDate}
                textStyle={showDoubleView && { color: colorPallete.black1 }}
              >
                {translate("fromDate")} - {yearFormat(date)}
              </Text>
            </View>
            <View style={styles.buttons}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.cancelButton}
                textStyles={styles.cancelText}
                onPress={() => handleCancel()}
              >
                <Text
                  fontWeight={FontWeight.Bold}
                  textSize={TextSize.Regular}
                  testId={testIds.actionPlan.cancel}
                  textStyle={styles.cancelText}
                  // textStyle={{ color: colorPallete.white1 }}
                >
                  {translate("cancel")}
                </Text>
              </Button>
              <Button
                type={ButtonType.Primary}
                textStyles={styles.applyText}
                buttonStyles={disableApply ? styles.disabledButton : styles.applyButton}
                onPress={handleApply}
                disabled={disableApply}
              >
                <Text
                  fontWeight={FontWeight.Bold}
                  textSize={TextSize.Regular}
                  testId={testIds.actionPlan.apply}
                  textStyle={{ color: colorPallete.white1 }}
                >
                  {translate("apply")}
                </Text>
              </Button>
            </View>
          </View>
        </View>
        <View style={styles.dividerContainer}>
          <Divider orientation={Orientations.Vertical} dividerStyle={styles.verticalDivider} />
        </View>
      </>
    );
  };

  const renderCalendar = (): ReactElement => {
    return (
      <>
        <View style={{ zIndex: 1, position: "relative" }}>
          <Calendar
            className="react-double-calendar"
            value={date}
            onChange={(value: any) => onDateClick(value)}
            selectRange={selectRange}
            showWeekNumbers={showWeekNumbers}
            showDoubleView={showDoubleView}
            calendarType={"Hebrew"}
            next2Label={null}
            prev2Label={null}
            maxDate={disableFutureDates && new Date()}
          />
        </View>
        {showDoubleView && getBottomView()}
      </>
    );
  };
  const handleButton = () => {
    onPressButton && onPressButton();
    showModal();
  };
  function getDateText() {
    if (date !== null) {
      return doubleformatDatePicker(date);
    } else {
      return translate("Select Dates");
    }
  }
  return (
    <OverflowModal
      buttonFrame={buttonFrame}
      modalVisible={isCalenderOpen}
      setModalState={onCalenderOpen}
      triggerComponent={
        triggerComponent ? (
          <TouchableOpacity
            onPress={showModal}
            ref={triggerRef}
            style={[styles.container, dateContainerStyle]}
          >
            <Button
              iconSize={iconSize}
              iconStyles={buttonProps.iconColorStyles}
              textStyles={buttonProps.dateTextStyle}
              iconName={buttonProps.iconName}
              type={ButtonType.Secondary}
              buttonStyles={buttonProps.styleButton}
              onPress={handleButton}
              title={buttonProps.title}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={showModal}
            ref={triggerRef}
            style={[styles.container, dateContainerStyle]}
          >
            <View style={styles.dateView}>
              <Text
                testId={testIds.actionPlan.showSelectDates}
                fontWeight={fontWeight}
                textSize={textSize}
                textStyle={textStyles}
              >
                {getDateText()}
              </Text>
            </View>
          </TouchableOpacity>
        )
      }
    >
      {renderCalendar()}
    </OverflowModal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorPallete.white5,
    borderRadius: 8
  },
  dateView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  calendarStyle: {
    color: colorPallete.black3,
    paddingHorizontal: 4,
    marginBottom: 4
  },
  bottomContainer: {
    zIndex: 2,
    position: "absolute",
    width: "100%",
    marginTop: 395,
    backgroundColor: "yellow"
  },
  horizontalDivider: {
    flex: 1,
    width: "100%",
    marginTop: -90,
    // justifyContent:"center",

    // paddingLeft: 56,
    ...I18nService.select({
      rtl: { paddingEnd: 40 },
      ltr: { paddingStart: 40 }
    }),
    // paddingInlineEnd: 36,
    ...I18nService.select({
      rtl: { paddingStart: 36 },
      ltr: { paddingEnd: 36 }
    })
  },
  dividerStyle1: {
    borderColor: colorPallete.red1,
    width: "100%"
  },
  dividerContainer: {
    zIndex: 9,
    position: "absolute",
    ...I18nService.select({
      rtl: { marginStart: 359 },
      ltr: { marginStart: 359 }
    }),
    // marginStart: 359,
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
    height: "100%"
  },
  verticalDivider: {
    // marginBlockStart: 24,
    // marginBlockEnd: 100,
    borderStyle: "dashed",
    borderWidth: 1,
    borderRadius: 0.1,
    borderColor: colorPallete.grey11
  },
  buttons: {
    padding: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 28
  },
  cancelButton: {
    borderColor: colorPallete.red1,
    borderWidth: 1,
    backgroundColor: colorPallete.white1,
    borderRadius: 8,
    paddingHorizontal: 36,
    paddingVertical: 12,
    marginBottom: 10,
    ...I18nService.select({
      rtl: { marginEnd: 16 },
      ltr: { marginEnd: 16 }
    })
    // marginEnd: 16
  },
  cancelText: {
    color: colorPallete.red1
  },
  applyText: {},
  applyButton: {
    paddingVertical: 12,
    backgroundColor: colorPallete.red1,
    borderRadius: 8,
    paddingHorizontal: 36,
    marginBottom: 10
  },
  disabledButton: {
    paddingVertical: 12,
    backgroundColor: colorPallete.grey4,
    borderRadius: 8,
    paddingHorizontal: 36,
    marginBottom: 10
  },
  subContainer: {
    // marginTop: 20,
    paddingHorizontal: 55,
    flexDirection: "row",
    justifyContent: "space-between"
    // backgroundColor:colorPallete.white3
  }
});
