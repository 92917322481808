import { call, put } from "redux-saga/effects";
import { UserLmsRepository } from "@timhortons/common/src/repositories/userLms/userLms";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import { takeLatest } from "redux-saga/effects";
import { AssessmentActions } from "../actions/assessmentActions";

function* getAssessmentQuestions(
  value: IFluxStandardAction<{ moduleId: string; employeeId: string }>
) {
  try {
    const { payload } = value;

    const response: any = yield call(UserLmsRepository.getAssessmentQuestions, payload);
    yield put(AssessmentActions.getAssessmentQuestionsSuccess(response));
  } catch (error) {
    yield put(AssessmentActions.getAssessmentQuestionsError(error));
  }
}

function* submitAssessment(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;

    const response: any = yield call(UserLmsRepository.submitAssessment, payload);
    yield put(AssessmentActions.submitAssessmentSuccess(response));
  } catch (error) {
    yield put(AssessmentActions.submitAssessmentError(error));
  }
}

function* exitAssessment(value: IFluxStandardAction<{ moduleId: string; employeeId: string }>) {
  try {
    const { payload } = value;
    let response: any = yield call(UserLmsRepository.exitAssessment, payload);
    yield put(AssessmentActions.exitAssessmentSuccess(response));
  } catch (error) {
    yield put(AssessmentActions.exitAssessmentError(error));
  }
}

function* getAssessmentResult(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(UserLmsRepository.getAssessmentResult, payload);
    yield put(AssessmentActions.getAssessmentResultSuccess(response));
  } catch (error) {
    yield put(AssessmentActions.getAssessmentResultError(error));
  }
}
function* deleteAssessment(value: IFluxStandardAction<{ moduleId: string; employeeId: string }>) {
  try {
    const { payload } = value;
    let response: any = yield call(UserLmsRepository.deleteAssessment, payload);
    yield put(AssessmentActions.deleteAssessmentSuccess(response));
  } catch (error) {
    yield put(AssessmentActions.deleteAssessmentError(error));
  }
}

function* assessmentWatcherSaga() {
  yield takeLatest(UserLMS.GET_THEORITICAL_ASSESSMENTS.LOADING, getAssessmentQuestions);
  yield takeLatest(UserLMS.SUBMIT_ASSESSMENT.LOADING, submitAssessment);
  yield takeLatest(UserLMS.EXIT_ASSESSMENT.LOADING, exitAssessment);
  yield takeLatest(UserLMS.DELETE_ASSESSMENT.LOADING, deleteAssessment);
  yield takeLatest(UserLMS.GET_ASSESSMENT_RESULT.LOADING, getAssessmentResult);
}

export default assessmentWatcherSaga;
