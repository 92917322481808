import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import {
  AmPmEmployeeProducts,
  BakingStationCatResp,
  GetBakingCategories,
  IBakingPSPostApiPayload,
  IGetBakingProducts,
  IGetOtherProducts,
  IOtherProductionSheetPostData,
  OthersStationCatResp,
  PostStationAuthPayload,
  PostStationAuthResp,
  StationProducts
} from "@timhortons/common/src/models/productionSheet/psModels";
import {
  GetSubCategoryPayload,
  IHeaderList
} from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable";

const getStationProducts = (
  data: GetSubCategoryPayload
): IFluxStandardAction<GetSubCategoryPayload> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STATION_PRODUCTS.LOADING,
    payload: data
  };
};

const getStationProductsSuccess = (
  data: StationProducts[]
): IFluxStandardAction<StationProducts[]> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STATION_PRODUCTS.SUCCESS,
    payload: data
  };
};

const getStationProductsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STATION_PRODUCTS.ERROR,
    payload: error
  };
};

const getProductItems = (data: IGetBakingProducts): IFluxStandardAction<IGetBakingProducts> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_PRODUCT_ITEMS.LOADING,
    payload: data
  };
};

const getProductItemsSuccess = (data: {
  productId: string;
  apiRes: any;
}): IFluxStandardAction<{ productId: string; apiRes: any }> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_PRODUCT_ITEMS.SUCCESS,
    payload: data
  };
};

const getProductItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_PRODUCT_ITEMS.ERROR,
    payload: error
  };
};

const getBakingStationHeaders = (data: {
  storeCode: number;
  timeZone: string;
}): IFluxStandardAction<{ storeCode: number; timeZone: string }> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STORE_HEADERS.LOADING,
    payload: data
  };
};

const getBakingStationHeadersSuccess = (
  data: IHeaderList[]
): IFluxStandardAction<IHeaderList[]> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STORE_HEADERS.SUCCESS,
    payload: data
  };
};

const getBakingStationHeadersError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STORE_HEADERS.ERROR,
    payload: error
  };
};

const postProductionSheetData = (
  data: IBakingPSPostApiPayload
): IFluxStandardAction<IBakingPSPostApiPayload> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_PRODUCTION_SHEET_DATA.LOADING,
    payload: data
  };
};
const postProductionSheetDataSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_PRODUCTION_SHEET_DATA.SUCCESS,
    payload: data
  };
};
const postProductionSheetDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_PRODUCTION_SHEET_DATA.ERROR,
    payload: error
  };
};
const getBakingStationCategories = (
  data: GetBakingCategories
): IFluxStandardAction<GetBakingCategories> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STORE_SPECIFIC_STATIONS.LOADING,
    payload: data
  };
};
const getBakingStationCategoriesSuccess = (
  data: BakingStationCatResp[]
): IFluxStandardAction<BakingStationCatResp[]> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STORE_SPECIFIC_STATIONS.SUCCESS,
    payload: data
  };
};
const getBakingStationCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_STORE_SPECIFIC_STATIONS.ERROR,
    payload: error
  };
};

const getAmPmEmployee = (data: {
  storeCode: number;
  timeZone: string;
}): IFluxStandardAction<{ storeCode: number; timeZone: string }> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_AM_PM_EMPLOYEE.LOADING,
    payload: data
  };
};
const getAmPmEmployeeSuccess = (
  data: AmPmEmployeeProducts[]
): IFluxStandardAction<AmPmEmployeeProducts[]> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_AM_PM_EMPLOYEE.SUCCESS,
    payload: data
  };
};
const getAmPmEmployeeError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_AM_PM_EMPLOYEE.ERROR,
    payload: error
  };
};

const postAmPmEmployeeAuth = (
  data: PostStationAuthPayload
): IFluxStandardAction<PostStationAuthPayload> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_AM_PM_EMPLOYEE_AUTH.LOADING,
    payload: data
  };
};
const postAmPmEmployeeAuthSuccess = (
  data: PostStationAuthResp
): IFluxStandardAction<PostStationAuthResp> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_AM_PM_EMPLOYEE_AUTH.SUCCESS,
    payload: data
  };
};
const postAmPmEmployeeAuthError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_AM_PM_EMPLOYEE_AUTH.ERROR,
    payload: error
  };
};

const getOthersCategories = (
  data: GetBakingCategories
): IFluxStandardAction<GetBakingCategories> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_CATEGORIES.LOADING,
    payload: data
  };
};
const getOthersCategoriesSuccess = (
  data: OthersStationCatResp[]
): IFluxStandardAction<OthersStationCatResp[]> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_CATEGORIES.SUCCESS,
    payload: data
  };
};
const getOthersCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_CATEGORIES.ERROR,
    payload: error
  };
};
const getOthersProducts = (data: any): IFluxStandardAction<IGetOtherProducts> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_PRODUCTS.LOADING,
    payload: data
  };
};
const getOthersProductsSuccess = (
  data: any,
  productCategoryId: string
): IFluxStandardAction<any> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_PRODUCTS.SUCCESS,
    payload: { data, productCategoryId }
  };
};
const getOthersProductsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_PRODUCTS.ERROR,
    payload: error
  };
};
const postOthersProductionSheetData = (
  data: IOtherProductionSheetPostData
): IFluxStandardAction<IOtherProductionSheetPostData> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_OTHERS_PRODUCTION_SHEET_DATA.LOADING,
    payload: data
  };
};
const postOthersProductionSheetDataSuccess = (
  data: any
): IFluxStandardAction<IOtherProductionSheetPostData> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_OTHERS_PRODUCTION_SHEET_DATA.SUCCESS,
    payload: data
  };
};
const postOthersProductionSheetDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.POST_OTHERS_PRODUCTION_SHEET_DATA.ERROR,
    payload: error
  };
};
const resetProductionSheet = () => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.RESET_PRODUCTION_SHEET
  };
};
const resetPSStationAuthMainMenu = () => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.RESET_PS_STATION_AUTH
  };
};
const resetBakingPSErrorState = () => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.RESET_BAKING_PS_ERROR_STATE
  };
};
const resetHeadersProductsStation = () => {
  return {
    type: PRODUCTION_SHEET.ProductionSheet.RESET_HEADER_PRODUCT_STATION
  };
};
export const ProductionSheetActions = {
  getProductItems,
  getProductItemsSuccess,
  getProductItemsError,
  getStationProducts,
  getStationProductsSuccess,
  getStationProductsError,
  getBakingStationHeaders,
  getBakingStationHeadersSuccess,
  getBakingStationHeadersError,
  postProductionSheetData,
  postProductionSheetDataSuccess,
  postProductionSheetDataError,
  postOthersProductionSheetData,
  postOthersProductionSheetDataSuccess,
  postOthersProductionSheetDataError,
  getBakingStationCategoriesSuccess,
  getBakingStationCategoriesError,
  getBakingStationCategories,
  getAmPmEmployee,
  getAmPmEmployeeSuccess,
  getAmPmEmployeeError,
  postAmPmEmployeeAuth,
  postAmPmEmployeeAuthSuccess,
  postAmPmEmployeeAuthError,
  getOthersProductsError,
  getOthersProducts,
  getOthersProductsSuccess,
  getOthersCategoriesError,
  getOthersCategories,
  getOthersCategoriesSuccess,
  resetProductionSheet,
  resetPSStationAuthMainMenu,
  resetBakingPSErrorState,
  resetHeadersProductsStation
};
