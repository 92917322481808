import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import { MainMenuActions } from "@timhortons/common/src/redux/productionSheet/actions";
import { MainMenuRepository } from "@timhortons/common/src/repositories/productionSheet/mainMenu";
import {
  BakingTableModel,
  ICategoryList,
  IProductList,
  PostMainMenuApiResp,
  PostMainMenuPayload
} from "@timhortons/common/src/models/productionSheet/mainMenu";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";

function* getAllCategoryAndProductsWorkerSaga(
  value: IFluxStandardAction<{ storeCode: number; stationCode: string; date: string }>
) {
  let { payload } = value;
  try {
    const response: ICategoryList[] | BakingTableModel[] = yield call(
      MainMenuRepository.getAllCategoryAndProducts,
      payload
    );
    yield put(MainMenuActions.getAllCategoryAndProductsSuccess(response));
  } catch (error) {
    Logger.error("main-menu", error, "getAllCategoryAndProductsWorkerSaga");

    yield put(MainMenuActions.getAllCategoryAndProductsError(error));
  }
}

function* postCategoryAndProductsWorkerSaga(value: IFluxStandardAction<PostMainMenuPayload>) {
  let { payload } = value;
  let dataa = {
    storeCode: payload.storeCode,
    stationCode: payload.stationId,
    date: payload.date
  };
  try {
    let auth = {
      storeCode: payload.storeCode,
      employeeId: payload.employeeId,
      managerAccess: true
    };
    let data: {} = yield call(MainLogsRepository.checkLogsAuth, auth);
    if (data) {
      const response: PostMainMenuApiResp[] = yield call(
        MainMenuRepository.postCategoryAndProducts,
        payload
      );
      yield put(MainMenuActions.postCategoryAndProductsSuccess(response));
      if (response) {
        let a: ICategoryList[] = yield call(MainMenuRepository.getAllCategoryAndProducts, dataa);
        yield put(MainMenuActions.getAllCategoryAndProductsSuccess(a));
      }
    }
  } catch (error) {
    Logger.error("main-menu", error, "postCategoryAndProductsWorkerSaga");

    yield put(MainMenuActions.postCategoryAndProductsError(error));
    let a: ICategoryList[] = yield call(MainMenuRepository.getAllCategoryAndProducts, dataa);
    yield put(MainMenuActions.getAllCategoryAndProductsSuccess(a));
  }
}

function* getAllStationsWorkerSaga() {
  try {
    const response: IProductList[] = yield call(MainMenuRepository.getAllStations);
    yield put(MainMenuActions.getAllStationsSuccess(response));
  } catch (error) {
    Logger.error("main-menu", error, "getAllStationsWorkerSaga");

    yield put(MainMenuActions.getAllStationsError(error));
  }
}

function* mainMenuWatcherSaga() {
  yield takeLatest(
    PRODUCTION_SHEET.MainMenu.GET_ALL_CATEGORY_AND_PRODUCTS.LOADING,
    getAllCategoryAndProductsWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.MainMenu.POST_CATEGORY_AND_PRODUCTS.LOADING,
    postCategoryAndProductsWorkerSaga
  );
  yield takeLatest(PRODUCTION_SHEET.MainMenu.GET_ALL_STATIONS.LOADING, getAllStationsWorkerSaga);
}

export default mainMenuWatcherSaga;
