export const headerKey = "x-api-key";

const REVKeys = {
  STANDARD_AUDIT: { [headerKey]: "98f607fc-0c95-11ec-82a8-0242ac130003" },
  TEST_AUDIT: { [headerKey]: "98f608b0-0c95-11ec-82a8-0242ac130003" },
  ACTION_PLANS: { [headerKey]: "98f604d2-0c95-11ec-82a8-0242ac130003" },
  REPORTS: { [headerKey]: "98f6068a-0c95-11ec-82a8-0242ac130003" },
  REPORT_SUMMARY: { [headerKey]: "98f6073e-0c95-11ec-82a8-0242ac130003" },
  REPORT_BUILDER: { [headerKey]: "98f605c2-0c95-11ec-82a8-0242ac130003" },
  USER_ROLES: { [headerKey]: "54827354-603d-448d-977e-8ebfbb74ee00" },
  APPEALS: { [headerKey]: "09584527-f9d5-45df-ba79-60184125a47d" },
  DOCUMENTS: { [headerKey]: "958f30fb-d673-4e34-9c47-34cc737a577d" }
};
const LPKeys = {
  LP_STANDARD_AUDIT: { [headerKey]: "dd0962d6-c683-45d4-b32b-caf6ed9521de" },
  LP_ACTION_PLANS: { [headerKey]: "dbfd4510-17cc-4084-88c9-9fac537bea89" },
  LP_APPEALS: { [headerKey]: "c6ba4b79-5ef7-4404-b007-e56ec4251961" },
  LP_REPORTS: { [headerKey]: "33b84550-ccad-488e-a4a0-d8d789bd202f" },
  LP_REPORT_SUMMARY: { [headerKey]: "9bdde9d7-3e50-49e2-906c-f7f582d5d8fc" },
  LP_REPORT_BUILDER: { [headerKey]: "a819b121-e6d1-423a-b1e5-b2b764afa7f2" }
};
const LOGKeys = {
  StoreAccessKeys: { [headerKey]: "ac59a9b4-2830-11ec-9621-0242ac130002" },
  StoreAuthentication: { [headerKey]: "54827354-603d-448d-977e-8ebfbb74ee00" }
};
const ProductionSheetKeys = {
  MAIN_MENU: { [headerKey]: "b2a33532-2ca0-11ec-8d3d-0242ac130003" }
};
const LMSKeys = {
  LMS_USER: { [headerKey]: "33034ef8-3e4b-11ed-b878-0242ac120002" },
  LMS_MANAGE: { [headerKey]: "92d9260e-54f8-11ed-bdc3-0242ac120002" },
  LMS_PRACTICAL_ASSESSMENT: { [headerKey]: "5a5330fc-6bc5-11ed-a1eb-0242ac120002" }
};
const ResourcesKeys = {
  RESOURCES_USER: { [headerKey]: "71642b7a-5509-11ed-bdc3-0242ac120002" },
  RESOURCES_MANAGE: { [headerKey]: "6342cc42-54f8-11ed-bdc3-0242ac120002" }
};
const ProfileKeys = {
  USER_ROLES: { [headerKey]: "13ac5b13-e973-48e1-82d1-4056252b0459" },
  GCC_COUNTRY: { [headerKey]: "33034ef8-3e4b-11ed-b878-0242ac120002" },
  MANAGE_USERS: { [headerKey]: "e71375e8-0ec8-11ec-82a8-0242ac130003" },
  MANAGE_IMAGE_UPLOAD: { [headerKey]: "c81fa1cc-c955-11ed-afa1-0242ac120002" },
  USER_PROFILE: { [headerKey]: "b8257354-cf9b-11ed-afa1-0242ac120002" }
};

const LmsReportsKeys = {
  USER_ROLES: { [headerKey]: "272338dc-8815-11ed-a1eb-0242ac120002" },
  LMS_GENERATE_REPORT: { [headerKey]: "33034ef8-3e4b-11ed-b878-0242ac120002" }
};

const ManageNewsKeys = {
  NEWS_USER: { [headerKey]: "33034ef8-3e4b-11ed-b878-0242ac120002" },
  NEWS_MANAGE: { [headerKey]: "92d9260e-54f8-11ed-bdc3-0242ac120002" }
};
const AskTellKeys = {
  QUESTIONS: { [headerKey]: "13ac5b13-e973-48e1-82d1-4056252b0459" }
};
const CalendarKeys = {
  WEB: { [headerKey]: "832a4d06-b8d4-11ed-afa1-0242ac120002" }
};

export {
  LPKeys,
  REVKeys,
  LOGKeys,
  ProductionSheetKeys,
  LMSKeys,
  ResourcesKeys,
  ProfileKeys,
  AskTellKeys,
  LmsReportsKeys,
  ManageNewsKeys,
  CalendarKeys
};
