import { all } from "@redux-saga/core/effects";
import managerReviewProductionSheetWatcherSaga from "@timhortons/common/src/redux/productionSheet/saga/managerReviewProductionSheetWatcherSaga";
import mainMenuWatcherSaga from "@timhortons/common/src/redux/productionSheet/saga/mainMenuWatcherSaga";
import productionSheetWatcherSaga from "@timhortons/common/src/redux/productionSheet/saga/productionSheetWatcherSaga";
import productionSheetReportsWatcherSaga from "@timhortons/common/src/redux/productionSheet/saga/reportsWatcherSaga";

export default function* productionSheetRootSaga() {
  yield all([
    managerReviewProductionSheetWatcherSaga(),
    mainMenuWatcherSaga(),
    productionSheetWatcherSaga(),
    productionSheetReportsWatcherSaga()
  ]);
}
