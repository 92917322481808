import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { Checkbox, CheckboxType } from "@timhortons/common/src/components/atoms/checkBox";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import {
  IProductList,
  ICategoryList
} from "@timhortons/common/src/models/productionSheet/mainMenu";
interface IProps {
  productList: ICategoryList;
  flatListWidth: number;
  setUpdatedProductData: Function;
  allProductData: ICategoryList[];
  setShowWarning: Function;
  getAllCategoryProductsData: ICategoryList[];
}

export default function PageBody(props: IProps) {
  const {
    productList,
    flatListWidth,
    setUpdatedProductData,
    allProductData,
    setShowWarning,
    getAllCategoryProductsData
  } = props;

  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    handleHeaderCheckBox();
  }, [productList]);

  const handleSelectAll = () => {
    let index = allProductData.findIndex((item: ICategoryList) => item.id === productList.id);
    if (index > -1) {
      let data: ICategoryList[] = [...allProductData];
      data[index].products.forEach((item: IProductList) => (item.isSelected = !selectAll));
      let status = JSON.stringify(data) === JSON.stringify(getAllCategoryProductsData);
      setUpdatedProductData(data);
      setSelectAll(!selectAll);
      setShowWarning(!status);
    }
  };

  const handleRemoveItemSelection = (id: string) => {
    let index = allProductData.findIndex((item: ICategoryList) => item.id === productList.id);
    if (index > -1) {
      let data: ICategoryList[] = [...allProductData];
      let childIndex = data[index].products.findIndex((item: IProductList) => item.id === id);
      if (childIndex > -1) {
        data[index].products[childIndex].isSelected = !data[index].products[childIndex].isSelected;
        let status = JSON.stringify(data) === JSON.stringify(getAllCategoryProductsData);
        setUpdatedProductData(data);
        setSelectAll(false);
        setShowWarning(!status);
        handleHeaderCheckBox();
      }
    }
  };

  function handleHeaderCheckBox() {
    let productLength = productList.products.length;
    let count = 0;
    productList.products.forEach((item: IProductList) => item.isSelected && count++);
    if (count === productLength) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }

  return (
    <View style={flatListStyle(flatListWidth).cardWidth}>
      <View style={styles.cardHeader}>
        <Checkbox
          onPress={handleSelectAll}
          selected={selectAll}
          text={productList?.category}
          testId={productList?.id}
          textSize={TextSize.ExtraRegular}
          type={CheckboxType.Square}
          textFontWeight={FontWeight.SemiBold}
          iconSize={14}
          textStyle={styles.checkboxText}
        />
      </View>

      <View style={styles.cardBody}>
        <FlatList
          showsVerticalScrollIndicator
          extractor={(item) => item.id}
          listData={productList?.products}
          seperator={() => <View style={styles.dividerVertical} />}
          listContainerStyle={styles.flatList}
          listRenderer={(element: IProductList) => (
            <Checkbox
              onPress={() => handleRemoveItemSelection(element.id)}
              selected={element.isSelected}
              text={element.product}
              testId={element.product}
              textSize={TextSize.ExtraRegular}
              type={CheckboxType.Square}
              textFontWeight={FontWeight.Regular}
              iconSize={14}
              textStyle={styles.checkboxText}
            />
          )}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  dividerVertical: {
    height: 14
  },
  checkboxText: {
    paddingHorizontal: 16
  },
  cardHeader: {
    backgroundColor: colorPallete.grey9,
    paddingHorizontal: 24,
    paddingVertical: 12
  },
  cardBody: {
    backgroundColor: colorPallete.white3,
    paddingHorizontal: 24,
    paddingVertical: 12,
    height: "85%"
  },
  flatList: {
    maxHeight: "100%"
  }
});

const flatListStyle = (flatListWidth: number) =>
  StyleSheet.create({
    cardWidth: {
      width: (flatListWidth - 60) / 3,
      shadowOffset: { width: 1, height: 1 },
      elevation: 1,
      shadowColor: colorPallete.variant7,
      borderRadius: 8,
      overflow: "hidden"
    }
  });
