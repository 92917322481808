import { imgPlaceholder } from "@timhortons/common/src/assets/images/imageAssets";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import ProgressiveImage from "@timhortons/common/src/components/molecules/placeholderImage";
import React, { useState } from "react";
import { ImageStyle, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";

interface Props {
  items: Array<any>;
  customStyle?: StyleProp<ViewStyle>;
  previewImageStyle?: StyleProp<ImageStyle>;
  previewIconStyle?: any;
}

const ImgMapper = (props: Props) => {
  const { items, customStyle, previewImageStyle, previewIconStyle } = props;
  const [show, setShow] = useState({ modal: false, imgIndex: null });
  function handlePrev() {
    let prevIndex = show.imgIndex - 1 < 0 ? items.length - 1 : show.imgIndex - 1;
    setShow({ ...show, imgIndex: prevIndex });
  }
  function handleNext() {
    let nextIndex = show.imgIndex + 1 >= items.length ? 0 : show.imgIndex + 1;
    setShow({ ...show, imgIndex: nextIndex });
  }
  return (
    <>
      {show.modal ? (
        <GetModal
          customStyle={styles.masterModalWrapper}
          setModalState={(val: boolean) => setShow({ ...show, modal: val })}
        >
          <View style={styles.modal}>
            <ProgressiveImage
              thumbnailSource={imgPlaceholder}
              source={{
                uri: items[show.imgIndex]
              }}
              imageStyle={styles.modalBgImg}
              resizeMode="stretch"
              testId={testIds.reports.progImg}
              customStyle={[styles.customStyleProgresive, { height: "100%" }]}
            />
            <View style={styles.modalImgsWrapper}>
              {items.map((image, imageKey) => {
                return (
                  <TouchableOpacity
                    key={imageKey}
                    onPress={() => {
                      setShow({ modal: true, imgIndex: imageKey });
                    }}
                  >
                    <ProgressiveImage
                      thumbnailSource={imgPlaceholder}
                      source={{
                        uri: image
                      }}
                      imageStyle={[
                        styles.modalImgs,
                        {
                          borderColor:
                            imageKey === show.imgIndex ? colorPallete.yellow1 : colorPallete.black1
                        }
                      ]}
                      customStyle={{ backgroundColor: "transparent" }}
                      testId={testIds.reports.progImg}
                    />
                  </TouchableOpacity>
                );
              })}
            </View>
            <View style={styles.modalBtnsWrapper}>
              <TouchableOpacity onPress={handlePrev} style={styles.modalBtns}>
                <Icon size={20} name={IconNames.left} color={colorPallete.black1} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setShow({ modal: false, imgIndex: null });
                }}
                style={styles.modalBtns}
              >
                <Icon size={20} name={IconNames.close} color={colorPallete.black1} />
              </TouchableOpacity>
              <TouchableOpacity onPress={handleNext} style={styles.modalBtns}>
                <Icon size={20} name={IconNames.right} color={colorPallete.black1} />
              </TouchableOpacity>
            </View>
          </View>
        </GetModal>
      ) : null}
      <View style={[styles.imageStyle, customStyle]}>
        {items.map((image, itemKey) => {
          return (
            <View style={styles.imageContainer} key={`${itemKey}-main`}>
              <View>
                <ProgressiveImage
                  thumbnailSource={imgPlaceholder}
                  source={{
                    uri: image
                  }}
                  imageStyle={[styles.attachments, previewImageStyle]}
                  testId={testIds.reports.progImg}
                  customStyle={styles.customStyleProgresive}
                />
              </View>
              <Icon
                size={16}
                name={IconNames.preview}
                color={colorPallete.black1}
                onPress={() => {
                  setShow({ modal: true, imgIndex: itemKey });
                }}
                customStyle={[styles.previewIcon, previewIconStyle]}
              />
            </View>
          );
        })}
      </View>
    </>
  );
};

export default ImgMapper;

const styles = StyleSheet.create({
  masterModalWrapper: {
    height: "80%",
    marginBottom: 30,
    width: 850,
    backgroundColor: "#ffffff",
    borderRadius: 4
  },
  modal: { borderRadius: 8, backgroundColor: colorPallete.white3, height: "100%", width: "100%" },
  modalBtnsWrapper: {
    flexDirection: "row",
    position: "absolute",
    bottom: 30,
    alignSelf: "center"
  },
  modalImgs: {
    borderWidth: 2,
    height: 60,
    width: 60,
    marginHorizontal: 10,
    borderRadius: 8
  },
  modalBgImg: { width: "100%", height: "100%", borderRadius: 8 },
  modalImgsWrapper: { flexDirection: "row", position: "absolute", top: 30, alignSelf: "center" },
  modalBtns: {
    marginHorizontal: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorPallete.white1,
    height: 50,
    width: 50,
    borderRadius: 25
  },
  previewIcon: {
    marginTop: 6,
    ...I18nService.select({
      rtl: { marginStart: -46 },
      ltr: { marginStart: -46 }
    }),
    width: 24,
    height: 24
  },
  imageContainer: {
    flexDirection: "row"
  },
  customStyleProgresive: {
    backgroundColor: "transparent",
    width: "100%",
    position: "relative"
  },
  attachments: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    }),
    width: 100,
    height: 100
  },
  attachments1: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    }),
    width: 100,
    height: 100
  },

  imageStyle: {
    flexDirection: "row",
    marginTop: 20,
    ...I18nService.select({
      rtl: { paddingEnd: 30 },
      ltr: { paddingStart: 30 }
    }),
    marginBottom: 30
  }
});
