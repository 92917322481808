import React, { ReactElement, useState } from "react";
import { StyleSheet, View } from "react-native";
import { dateFormat, timeFormat, toTitleCase } from "@timhortons/common/src/utils/dateFormat";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import Loader from "@timhortons/common/src/components/molecules/loader";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { FilterStatus } from "@timhortons/common/src/modules/rev/components/dashboards/organisms/actionPlanHeader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";
import {
  IActionPlansError,
  IActionPlansLoading
} from "@timhortons/common/src/redux/rev/reducers/actionPlans";
import {
  ActionPlanTableModel,
  SelectedFilterDataModel
} from "@timhortons/common/src/models/rev/actionPlan";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";

interface IProps {
  isIndividualLogin: boolean;
  getActionPlans: Function;
  actionPlansList: ActionPlanTableModel;
  translate: any;
  onCreatePlan(actionPlanId: number, calibrationId: number, createPlan: boolean): void;
  handlePagination(val: number): void;
  handleSortingOrder(val: string, columnName: string): void;
  pageNum: number;
  loading?: IActionPlansLoading;
  error?: IActionPlansError;
  selectedFilterData: SelectedFilterDataModel;
}

export enum TableColumns {
  auditDate = "auditDate",
  auditTime = "auditTime"
}
function ActionPlanTable(props: IProps): ReactElement {
  const {
    translate,
    actionPlansList,
    onCreatePlan,
    handlePagination,
    handleSortingOrder,
    isIndividualLogin,
    pageNum,
    loading,
    error,
    selectedFilterData
  } = props;

  const [auditDateColumnSelected, setAuditDateColumnSelected] = useState(true);
  const [auditTimeColumnSelected, setAuditTimeColumnSelected] = useState(false);
  const actionPlanTableHeader = [
    {
      name: translate(TableColumns.auditDate),
      isSortable: true,
      selected: auditDateColumnSelected
    },
    {
      name: translate(TableColumns.auditTime),
      isSortable: true,
      selected: auditTimeColumnSelected
    },
    { name: translate("auditedBy"), isSortable: false },
    { name: translate("Status"), isSortable: false },
    { name: translate("completion"), isSortable: false },
    { name: translate("view"), isSortable: false }
  ];
  const individualActionTableHeader = [
    { name: translate("storeId"), isSortable: false },
    { name: translate("storeNameTable"), isSortable: false },
    {
      name: translate(TableColumns.auditDate),
      isSortable: true,
      selected: auditDateColumnSelected
    },
    { name: translate("auditedBy"), isSortable: false },
    { name: translate("Status"), isSortable: false },
    { name: translate("completion"), isSortable: false },
    { name: translate("view"), isSortable: false }
  ];

  const statusHandler = (value: string): Object => {
    if (value === FilterStatus.completed) {
      return styles.completedStatus;
    } else if (value === FilterStatus.notStarted) {
      return styles.notStartedStatus;
    } else return styles.overdueStatus;
  };
  const handleSort = (val: "asc" | "desc", columnName: string) => {
    if (columnName === translate(TableColumns.auditDate)) {
      setAuditDateColumnSelected(true);
      setAuditTimeColumnSelected(false);
    } else {
      setAuditDateColumnSelected(false);
      setAuditTimeColumnSelected(true);
    }
    handleSortingOrder(val, columnName);
  };
  const getViewIcon = (actionPlanId: number, calibrationId: number): JSX.Element => {
    return (
      <>
        <Icon
          color={colorPallete.grey2}
          onPress={() => onCreatePlan(actionPlanId, calibrationId, false)}
          name={IconNames.eyeView}
          size={16}
        />
      </>
    );
  };

  const getHeaderComp = (headerItem: any, index: number) => {
    return (
      <HeaderCell
        key={index}
        columnSelected={headerItem.selected}
        title={headerItem.name}
        sortable={headerItem.isSortable}
        columnName={headerItem.name}
        sortFunction={(val: "asc" | "desc") => handleSort(val, headerItem.name)}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.grey1 }}
        sortIconStyle={styles.sortIcon}
      />
    );
  };
  return (
    <View style={{ flex: 1, paddingBottom: 20 }}>
      <Table>
        <Row
          header
          rowStyle={{
            backgroundColor: colorPallete.grey9,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }}
        >
          {isIndividualLogin
            ? individualActionTableHeader.map((headerItem, index) =>
                getHeaderComp(headerItem, index)
              )
            : actionPlanTableHeader.map((headerItem, index) => getHeaderComp(headerItem, index))}
        </Row>
        {error.getActionPlans ? (
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        ) : loading.getActionPlans ? (
          <Loader type="component" />
        ) : actionPlansList && actionPlansList.actionPlans.length > 0 ? (
          <>
            {actionPlansList &&
              actionPlansList.actionPlans.map((rowItem: any, index: number) => {
                return (
                  <Row key={index}>
                    {isIndividualLogin && <Cell cellStyle={styles.title} title={rowItem.storeId} />}
                    {isIndividualLogin && (
                      <Cell cellStyle={styles.title} title={rowItem.storeName} />
                    )}
                    <Cell
                      cellStyle={styles.title}
                      // title={dateFormat(new Date("2021-08-05T06:11:47.701"+"Z")).toString()}
                      title={dateFormat(rowItem.auditDate)}
                    />
                    {isIndividualLogin ? null : (
                      <Cell cellStyle={styles.title} title={timeFormat(rowItem.auditDate)} />
                    )}
                    <Cell cellStyle={styles.title} title={rowItem.auditBy} />
                    <Cell>
                      <Text
                        textStyle={statusHandler(rowItem.status)}
                        testId={testIds.createPlanButton}
                        textSize={TextSize.ExtraRegular}
                        fontWeight={FontWeight.Bold}
                      >
                        {toTitleCase(rowItem.status)}
                      </Text>
                    </Cell>
                    <Cell title={`${rowItem.completionPercentage}%`} />
                    <Cell>
                      {isIndividualLogin ? (
                        getViewIcon(rowItem.actionPlanId, rowItem.calibrationId)
                      ) : rowItem.status === "COMPLETED" ? (
                        getViewIcon(rowItem.actionPlanId, rowItem.calibrationId)
                      ) : (
                        // <View style={{flex:1}}>
                        <Button
                          onPress={() =>
                            onCreatePlan(rowItem.actionPlanId, rowItem.calibrationId, true)
                          }
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraRegular}
                          buttonStyles={styles.createPlanBtn}
                          type={ButtonType.Primary}
                          title={translate("createPlan")}
                          textStyles={styles.createPlanText}
                        />
                        // </View>
                      )}
                    </Cell>
                  </Row>
                );
              })}
            <Cell cellStyle={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
              <CommonPagination
                handlePagination={handlePagination}
                pageNum={pageNum}
                totalRecords={actionPlansList.totalRecords}
              />
            </Cell>
          </>
        ) : (selectedFilterData && selectedFilterData.fromDate) ||
          (selectedFilterData && selectedFilterData.toDate) ||
          (selectedFilterData && selectedFilterData.search) ||
          (selectedFilterData && selectedFilterData.status) ? (
          <Jumbotron
            type={ErrorType.NoDataFound}
            title={translate("noActionPlanFiltersTitle")}
            subTitle={translate("noActionPlanFiltersSubTitle")}
          />
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noActionPlanAvailable")} />
        )}
      </Table>
    </View>
  );
}
export default ActionPlanTable;
const styles = StyleSheet.create({
  title: {
    color: colorPallete.black4,
    textAlign: "center"
  },
  sortIcon: {
    paddingHorizontal: 3
  },
  createPlanBtn: {
    backgroundColor: colorPallete.variant1,
    borderRadius: 8,
    paddingVertical: 6,
    paddingHorizontal: 16
  },
  createPlanText: {
    color: colorPallete.red2
  },
  completedStatus: {
    color: colorPallete.green1
  },
  notStartedStatus: {
    color: colorPallete.red3
  },
  overdueStatus: {
    color: colorPallete.yellow1
  }
});
