export const setFrameMeasures = (callback: any, ref: any, setFrame: any) => {
  if (ref && ref.current) {
    ref.current.measure(
      (x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
        setFrame({
          x: pageX,
          y: pageY,
          w: width,
          h: height
        });
        callback && callback();
      }
    );
  }
};
