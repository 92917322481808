import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import {
  ManagerReviewModel,
  CheckAuth
} from "@timhortons/common/src/models/productionSheet/managerReview";

export interface IManagerReviewLoading {
  getManagerReviewProductionSheetTable: boolean;
  updateManagerReviewItem: boolean;
  checkAuthToStore: boolean;
}
export interface IManagerReviewError {
  getManagerReviewProductionSheetTable: string;
  updateManagerReviewItem: string;
  checkAuthToStore: string;
}
export interface IManagerReviewState {
  loading: IManagerReviewLoading;
  error: IManagerReviewError;
  managerReviewProductionSheetTableData: ManagerReviewModel;
  updateManagerReviewItemSuccess: boolean;
  checkAuthToStore: CheckAuth;
}
export const managerReviewInitialState: IManagerReviewState = {
  managerReviewProductionSheetTableData: null,
  checkAuthToStore: null,
  updateManagerReviewItemSuccess: null,
  loading: {
    getManagerReviewProductionSheetTable: false,
    updateManagerReviewItem: false,
    checkAuthToStore: false
  },
  error: {
    getManagerReviewProductionSheetTable: null,
    updateManagerReviewItem: null,
    checkAuthToStore: null
  }
};

export const managerReviewProductionSheetReducer = (
  state = managerReviewInitialState,
  action: { payload: any; type: string }
): IManagerReviewState => {
  switch (action.type) {
    case PRODUCTION_SHEET.ManagerReview.GET_MANAGER_REVIEW_PRODUCTION_SHEET.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getManagerReviewProductionSheetTable: true },
        error: { ...state.error, getManagerReviewProductionSheetTable: null }
      };
    case PRODUCTION_SHEET.ManagerReview.GET_MANAGER_REVIEW_PRODUCTION_SHEET.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getManagerReviewProductionSheetTable: false },
        error: { ...state.error, getManagerReviewProductionSheetTable: null },
        managerReviewProductionSheetTableData: action.payload
      };
    case PRODUCTION_SHEET.ManagerReview.GET_MANAGER_REVIEW_PRODUCTION_SHEET.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getManagerReviewProductionSheetTable: false },
        error: { ...state.error, getManagerReviewProductionSheetTable: action.payload }
      };
    case PRODUCTION_SHEET.ManagerReview.UPDATE_MANAGE_REVIEW_ITEM.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateManagerReviewItem: true },
        error: { ...state.error, updateManagerReviewItem: null },
        updateManagerReviewItemSuccess: null
      };
    case PRODUCTION_SHEET.ManagerReview.UPDATE_MANAGE_REVIEW_ITEM.SUCCESS:
      const data = state.managerReviewProductionSheetTableData;
      if (action.payload.rowId?.length > 0) {
        action.payload.rowId.map((item: any) => {
          data.records[item].isApproved = true;
        });
      }
      let index = data.records.findIndex((item) => item.id === action.payload.rowId);
      if (index > -1) {
        data.records[index].isEditable = false;

        data.records[index].isApproved = true;
      }
      return {
        ...state,
        loading: { ...state.loading, updateManagerReviewItem: false },
        managerReviewProductionSheetTableData: data,
        updateManagerReviewItemSuccess: true
      };
    case PRODUCTION_SHEET.ManagerReview.UPDATE_MANAGE_REVIEW_ITEM.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateManagerReviewItem: false },
        error: { ...state.error, updateManagerReviewItem: action.payload },
        updateManagerReviewItemSuccess: false
      };
    case PRODUCTION_SHEET.ManagerReview.CHECK_ACCESS_TO_STORE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, checkAuthToStore: true },
        error: { ...state.error, checkAuthToStore: null }
      };
    case PRODUCTION_SHEET.ManagerReview.CHECK_ACCESS_TO_STORE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, checkAuthToStore: false },
        checkAuthToStore: action.payload
      };
    case PRODUCTION_SHEET.ManagerReview.CHECK_ACCESS_TO_STORE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, checkAuthToStore: false },
        error: { ...state.error, checkAuthToStore: action.payload }
      };
    case PRODUCTION_SHEET.ManagerReview.RESET_MRModals:
      return {
        ...state,
        updateManagerReviewItemSuccess: null,
        checkAuthToStore: null,
        error: { ...state.error, checkAuthToStore: null, updateManagerReviewItem: null }
      };
    default:
      return state;
  }
};
