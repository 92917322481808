import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PROFILE } from "@timhortons/common/src/redux/profile/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import {
  AllManagersDataModel,
  AssignStores,
  ChannelsDataModel,
  CountryDataModel,
  CreateStoreDataModel,
  DetailedUserInfoModel,
  DocumentDetailModel,
  EditStoreDetails,
  GetAllRolesDataModel,
  GetStoreByStoreCode,
  PostAssignStores,
  PostCreateStoreDataModel,
  PostCreateUserDataModel,
  ProfileDetailsModel,
  StoreSpecificDataModel,
  StoreTableDetailsModel,
  TeamDetailsTableModel,
  UserProfileDataModel,
  UserTableDetailsModel
} from "@timhortons/common/src/models/profile";
import { ProfilesAction } from "@timhortons/common/src/redux/profile/actions/index";
import { ProfileRepository } from "@timhortons/common/src/repositories/profile/profile";
import { IDocumentData } from "@timhortons/common/src/modules/profile/components/detailedUserProfile";
import { StoreListModel } from "@timhortons/common/src/modules/profile/components/createEmployee/organisms/storeList";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";

function* getIndividualProfileWorkerSaga(value: IFluxStandardAction<{ userId: string }>) {
  const { payload } = value;
  try {
    const response: ProfileDetailsModel = yield call(
      ProfileRepository.getIndividualProfile,
      payload
    );
    yield put(ProfilesAction.getIndividualProfileSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getIndividualProfileWorkerSaga");

    yield put(ProfilesAction.getIndividualProfileError(error));
  }
}

function* getStoreProfileWorkerSaga(value: IFluxStandardAction<{ storeId: string }>) {
  const { payload } = value;
  try {
    const response: ProfileDetailsModel = yield call(ProfileRepository.getStoreProfile, payload);
    yield put(ProfilesAction.getStoreProfileSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getStoreProfileWorkerSaga");

    yield put(ProfilesAction.getStoreProfileError(error));
  }
}

function* getStoreTeamDetailsWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    pageNumber: number;
    pageSize: number;
    search: string;
  }>
) {
  const { payload } = value;
  try {
    const response: TeamDetailsTableModel[] = yield call(
      ProfileRepository.getStoreTeamDetails,
      payload
    );
    yield put(ProfilesAction.getStoreTeamDetailsSuccess(response));
  } catch (error) {
    Logger.error("store profile-table", error, "getStoreTeamDetailsWorkerSaga");

    yield put(ProfilesAction.getStoreTeamDetailsError(error));
  }
}

function* getIndividualTeamDetailsWorkerSaga(
  value: IFluxStandardAction<{
    pageNumber: number;
    pageSize: number;
    roleId: string;
    search: string;
    isStoresTab?: boolean;
    idList?: [string];
  }>
) {
  const { payload } = value;
  try {
    const response: TeamDetailsTableModel[] = yield call(
      ProfileRepository.getIndividualTeamDetails,
      payload
    );
    yield put(ProfilesAction.getIndividualTeamDetailsSuccess(response));
  } catch (error) {
    Logger.error("Individual profile-table", error, "getIndividualTeamDetailsWorkerSaga");

    yield put(ProfilesAction.getIndividualTeamDetailsError(error));
  }
}

function* getUserProfileWorkerSaga(value: IFluxStandardAction<{ employeeId: string }>) {
  const { payload } = value;
  try {
    const response: UserProfileDataModel = yield call(
      ProfileRepository.getUserProfileDetails,
      payload
    );
    yield put(ProfilesAction.getUserProfileSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getUserProfileWorkerSaga");

    yield put(ProfilesAction.getUserProfileError(error));
  }
}

function* getDetailedUserInfoWorkerSaga(value: IFluxStandardAction<{ employeeId: string }>) {
  const { payload } = value;
  try {
    const response: DetailedUserInfoModel = yield call(
      ProfileRepository.getDetailedUserInfo,
      payload
    );

    yield put(ProfilesAction.getDetailedUserInfoSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getDetailedUserInfoWorkerSaga");

    yield put(ProfilesAction.getDetailedUserInfoError(error));
  }
}

////////    Create Store and user /////////

function* getAllCountriesWorkerSaga(value: IFluxStandardAction<{ isGcc: boolean }>) {
  const { payload } = value;
  try {
    const response: CountryDataModel[] = yield call(ProfileRepository.getAllCountries, payload);
    yield put(ProfilesAction.getAllCountriesSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getAllCountriesWorkerSaga");

    yield put(ProfilesAction.getAllCountriesError(error));
  }
}

function* getAllChannelsWorkerSaga() {
  try {
    const response: ChannelsDataModel = yield call(ProfileRepository.getAllChannels);

    yield put(ProfilesAction.getAllChannelsSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getAllChannelsWorkerSaga");

    yield put(ProfilesAction.getAllChannelsError(error));
  }
}

function* getStoreSpecificsWorkerSaga() {
  try {
    const response: StoreSpecificDataModel = yield call(ProfileRepository.getStoreSpecifics);

    yield put(ProfilesAction.getStoreSpecificsSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getStoreSpecificsWorkerSaga");

    yield put(ProfilesAction.getStoreSpecificsError(error));
  }
}

function* getAllCitiesWorkerSaga(value: IFluxStandardAction<{ countryCode: number }>) {
  let { payload } = value;
  try {
    const response: CreateStoreDataModel = yield call(ProfileRepository.getAllCities, payload);

    yield put(ProfilesAction.getAllCitiesSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getAllCitiesWorkerSaga");

    yield put(ProfilesAction.getAllCitiesError(error));
  }
}

function* getAllAreaManagerWorkerSaga(
  value: IFluxStandardAction<{ countryCode: number; pageNo: number; pageSize: number }>
) {
  let { payload } = value;
  try {
    const areaManager: AllManagersDataModel = yield call(ProfileRepository.getAllAreaManager, {
      countryCode: payload.countryCode,
      pageNo: payload.pageNo,
      pageSize: payload.pageSize,
      role: "Area Manager"
    });
    let response = areaManager;
    yield put(ProfilesAction.getAllAreaManagerSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getAllAreaManagerWorkerSaga");

    yield put(ProfilesAction.getAllAreaManagerError(error));
  }
}

function* getOpsManagerWorkerSaga(
  value: IFluxStandardAction<{ countryCode: number; pageNo: number; pageSize: number }>
) {
  let { payload } = value;
  try {
    const opsManager: AllManagersDataModel = yield call(ProfileRepository.getAllAreaManager, {
      countryCode: payload.countryCode,
      role: "Ops Manager",
      pageNo: payload.pageNo,
      pageSize: payload.pageSize
    });

    let response = opsManager;
    yield put(ProfilesAction.getAllOpsManagerSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getOpsManagerWorkerSaga");

    yield put(ProfilesAction.getAllOpsManagerError(error));
  }
}

function* getCountryManagerWorkerSaga(
  value: IFluxStandardAction<{ countryCode: number; pageNo: number; pageSize: number }>
) {
  let { payload } = value;
  try {
    const countryManager: AllManagersDataModel = yield call(ProfileRepository.getAllAreaManager, {
      countryCode: payload.countryCode,
      role: "Country Manager",
      pageNo: payload.pageNo,
      pageSize: payload.pageSize
    });

    let response = countryManager;
    yield put(ProfilesAction.getAllCountryManagerSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getCountryManagerWorkerSaga");

    yield put(ProfilesAction.getAllCountryManagerError(error));
  }
}

function* getAreaTrainerWorkerSaga(
  value: IFluxStandardAction<{ countryCode: number; pageNo: number; pageSize: number }>
) {
  let { payload } = value;
  try {
    const areaTrainer: AllManagersDataModel = yield call(ProfileRepository.getAllAreaManager, {
      countryCode: payload.countryCode,
      role: "Area Trainer",
      pageNo: payload.pageNo,
      pageSize: payload.pageSize
    });

    let response = areaTrainer;
    yield put(ProfilesAction.getAllAreaTrainerSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getCountryManagerWorkerSaga");

    yield put(ProfilesAction.getAllAreaTrainerError(error));
  }
}

function* getAllRolesWorkerSaga() {
  try {
    const response: {
      allRolesData: GetAllRolesDataModel;
      formattedRolesData: any;
    } = yield call(ProfileRepository.getAllRoles);

    yield put(ProfilesAction.getAllRolesSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getAllRolesWorkerSaga");
    yield put(ProfilesAction.getAllRolesError(error));
  }
}

function* postCreateStoreDataWorkerSaga(
  action: IFluxStandardAction<{ obj: PostCreateStoreDataModel; files: any; isEdit: boolean }>
) {
  let { payload } = action;
  try {
    let imageResponse: string;
    if (payload.files.length !== 0) {
      imageResponse = yield call(ProfileRepository.postStoreImage, payload.files);
    }
    let obj;
    if (payload.files.length === 0) {
      obj = {
        image: payload.obj.profilePhoto === undefined ? null : payload.obj.profilePhoto,
        data: payload.obj,
        isEdit: payload.isEdit
      };
    } else {
      obj = {
        data: payload.obj,
        image: payload.files.length !== 0 ? imageResponse : null,
        isEdit: payload.isEdit
      };
    }
    const response: {} = yield call(ProfileRepository.postCreateStore, obj);

    yield put(ProfilesAction.postCreateStoreSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "postCreateStoreWorkerSaga");

    yield put(ProfilesAction.postCreateStoreError(error));
  }
}

function* postCreateUserDataWorkerSaga(
  action: IFluxStandardAction<{ formData: PostCreateUserDataModel; files: any; isEdit: boolean }>
) {
  let { payload } = action;

  try {
    let imageResponse: { profileUrl: string };
    if (payload.files.length !== 0) {
      imageResponse = yield call(ProfileRepository.postUserImage, payload.files);
    }
    let obj;
    if (payload.files.length === 0) {
      obj = {
        image: payload.formData.userBio.profileUrl,
        data: payload.formData,
        isEdit: payload.isEdit
      };
    } else {
      obj = {
        data: payload.formData,
        image: payload.files.length !== 0 ? imageResponse.profileUrl : null,
        isEdit: payload.isEdit
      };
    }
    const response: {} = yield call(ProfileRepository.postCreateUser, obj);

    yield put(ProfilesAction.postCreateUserSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "postCreateUserWorkerSaga");

    yield put(ProfilesAction.postCreateUserError(error));
  }
}
function* getStoreTableDetailsWorkerSaga(
  value: IFluxStandardAction<{ search: string; pageNumber: number; pageSize: number }>
) {
  const { payload } = value;
  try {
    const response: StoreTableDetailsModel[] = yield call(
      ProfileRepository.getStoreTableDetails,
      payload
    );
    yield put(ProfilesAction.getStoreTableDetailsSuccess(response));
  } catch (error) {
    Logger.error("manage store", error, "getStoreTableDetailsWorkerSaga");

    yield put(ProfilesAction.getStoreTableDetailsError(error));
  }
}
function* getUserTableDetailsWorkerSaga(
  value: IFluxStandardAction<{ search: string; pageNumber: number; pageSize: number }>
) {
  const { payload } = value;
  try {
    const response: UserTableDetailsModel = yield call(
      ProfileRepository.getUserTableDetails,
      payload
    );
    yield put(ProfilesAction.getUserTableDetailsSuccess(response));
  } catch (error) {
    Logger.error("manage user", error, "getUserTableDetailsWorkerSaga");

    yield put(ProfilesAction.getUserTableDetailsError(error));
  }
}

/////////////////// Employee Documents and certificates ///////////////////

function* getEmployeeDocumentWorkerSaga(
  value: IFluxStandardAction<{ empId: string; docType: string; docSide: string }>
) {
  let { payload } = value;

  try {
    const response: DocumentDetailModel = yield call(
      ProfileRepository.getEmployeeDocument,
      payload
    );
    yield put(ProfilesAction.getEmployeeDocumentSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getEmployeeDocumentWorkerSaga");

    yield put(ProfilesAction.getEmployeeDocumentError(error));
  }
}

function* getEmployeeCertificateWorkerSaga(
  value: IFluxStandardAction<{ empId: string; docType: string }>
) {
  let { payload } = value;

  try {
    const response: DocumentDetailModel = yield call(
      ProfileRepository.getEmployeeCertificate,
      payload
    );
    yield put(ProfilesAction.getEmployeeCertificateSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getEmployeeCertificateWorkerSaga");

    yield put(ProfilesAction.getEmployeeCertificateError(error));
  }
}

function* getEmployeeCountDocumentWorkerSaga(value: IFluxStandardAction<{ empId: string }>) {
  let { payload } = value;

  try {
    const response: IDocumentData[] = yield call(
      ProfileRepository.getEmployeeCountDocument,
      payload
    );
    yield put(ProfilesAction.getEmployeeCountDocumentSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getEmployeeCountDocumentWorkerSaga");

    yield put(ProfilesAction.getEmployeeCountDocumentError(error));
  }
}

function* getEmployeeCountCertificateWorkerSaga(value: IFluxStandardAction<{ empId: string }>) {
  let { payload } = value;
  try {
    const response: IDocumentData[] = yield call(
      ProfileRepository.getEmployeeCountCertificate,
      payload
    );
    yield put(ProfilesAction.getEmployeeCountCertificateSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getEmployeeCountCertificateWorkerSaga");

    yield put(ProfilesAction.getEmployeeCountCertificateError(error));
  }
}

function* postEmployeeDocumentWorkerSaga(
  value: IFluxStandardAction<{ data: FormData; empId: string }>
) {
  let { payload } = value;

  try {
    const response: {
      signedUrl: string;
    } = yield call(ProfileRepository.postEmployeeDocument, payload);

    yield put(ProfilesAction.postEmployeeDocumentSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "postEmployeeDocumentWorkerSaga");

    yield put(ProfilesAction.postEmployeeDocumentError(error));
  }
}

function* getStoreByStoreCode(value: IFluxStandardAction<{ storeCode: number }>) {
  let { payload } = value;
  try {
    const response: GetStoreByStoreCode = yield call(
      ProfileRepository.getStoreByStoreCode,
      payload
    );
    yield put(ProfilesAction.getStoreByStoreCodeSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getStoreByStoreCode");
    yield put(ProfilesAction.getStoreByStoreCodeError(error));
  }
}

function* getAllStoresListWorkerSaga(
  value: IFluxStandardAction<{ data: AssignStores; user: IUserDetails; fromLms: Boolean }>
) {
  let { payload } = value;
  try {
    const response: { totalRecords: number; storeList: StoreListModel[] } = yield call(
      ProfileRepository.getAllStoresList,
      payload
    );
    yield put(ProfilesAction.getAllStoresListSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getAllStoresListWorkerSaga");

    yield put(ProfilesAction.getAllStoresListError(error));
  }
}

function* postAssignedStoresWorkerSaga(
  value: IFluxStandardAction<{
    empId: string;
    postData: PostAssignStores[];
    isAllSelected?: boolean;
    roleId?: string;
    countryCode?: string;
    cityId?: string;
    channelId?: string;
  }>
) {
  let { payload } = value;
  try {
    const response: {} = yield call(ProfileRepository.postAssignedStores, payload);
    yield put(ProfilesAction.postAssignedStoresSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "postAssignedStoresWorkerSaga");

    yield put(ProfilesAction.postAssignedStoresError(error));
  }
}

function* getEditStoreDetailsWorkerSaga(value: IFluxStandardAction<{ storeId: number }>) {
  const { payload } = value;
  try {
    const response: EditStoreDetails = yield call(ProfileRepository.getEditStoreDetails, payload); //model
    yield put(ProfilesAction.getEditStoreDetailsSuccess(response));
  } catch (error) {
    console.log(error, "er");
    Logger.error("profile", error, "getEditStoreDetailsWorkerSaga");

    yield put(ProfilesAction.getEditStoreDetailsError(error));
  }
}

function* getGccCountriesWorkerSaga() {
  try {
    const response: CountryDataModel[] = yield call(ProfileRepository.getGccCountries);
    yield put(ProfilesAction.getGccCountriesSuccess(response));
  } catch (error) {
    Logger.error("profile", error, "getGccCountriesWorkerSaga");

    yield put(ProfilesAction.getGccCountriesError(error));
  }
}

function* profileWatcherSaga() {
  yield takeLatest(PROFILE.GET_INDIVIDUAL_PROFILE.LOADING, getIndividualProfileWorkerSaga);
  yield takeLatest(PROFILE.GET_STORE_TEAM_DETAILS.LOADING, getStoreTeamDetailsWorkerSaga);
  yield takeLatest(PROFILE.GET_INDIVIDUAL_TEAM_DETAILS.LOADING, getIndividualTeamDetailsWorkerSaga);
  yield takeLatest(PROFILE.GET_USER_PROFILE_DETAILS.LOADING, getUserProfileWorkerSaga);
  yield takeLatest(PROFILE.GET_STORE_PROFILE.LOADING, getStoreProfileWorkerSaga);
  yield takeLatest(PROFILE.GET_DETAILED_USER_INFO.LOADING, getDetailedUserInfoWorkerSaga);
  yield takeLatest(PROFILE.GET_ALL_COUNTRIES.LOADING, getAllCountriesWorkerSaga);
  yield takeLatest(PROFILE.GET_ALL_CHANNELS.LOADING, getAllChannelsWorkerSaga);
  yield takeLatest(PROFILE.GET_STORE_SPECIFICS.LOADING, getStoreSpecificsWorkerSaga);
  yield takeLatest(PROFILE.GET_ALL_CITIES.LOADING, getAllCitiesWorkerSaga);
  yield takeEvery(PROFILE.GET_ALL_AREA_MANAGER.LOADING, getAllAreaManagerWorkerSaga);
  yield takeEvery(PROFILE.GET_OPS_MANAGER.LOADING, getOpsManagerWorkerSaga);
  yield takeEvery(PROFILE.GET_COUNTRY_MANAGER.LOADING, getCountryManagerWorkerSaga);
  yield takeEvery(PROFILE.GET_AREA_TRAINER.LOADING, getAreaTrainerWorkerSaga);

  yield takeLatest(PROFILE.GET_ALL_ROLES.LOADING, getAllRolesWorkerSaga);
  yield takeLatest(PROFILE.POST_CREATE_STORE.LOADING, postCreateStoreDataWorkerSaga);
  yield takeLatest(PROFILE.POST_CREATE_USER.LOADING, postCreateUserDataWorkerSaga);
  yield takeLatest(PROFILE.GET_STORE_TABLE_DETAILS.LOADING, getStoreTableDetailsWorkerSaga);
  yield takeLatest(PROFILE.GET_USER_TABLE_DETAILS.LOADING, getUserTableDetailsWorkerSaga);

  yield takeLatest(PROFILE.GET_EMPLOYEE_DOCUMENT.LOADING, getEmployeeDocumentWorkerSaga);
  yield takeLatest(PROFILE.GET_EMPLOYEE_CERTIFICATE.LOADING, getEmployeeCertificateWorkerSaga);

  yield takeLatest(PROFILE.GET_EMPLOYEE_COUNT_DOCUMENT.LOADING, getEmployeeCountDocumentWorkerSaga);
  yield takeLatest(
    PROFILE.GET_EMPLOYEE_COUNT_CERTIFICATE.LOADING,
    getEmployeeCountCertificateWorkerSaga
  );

  yield takeLatest(PROFILE.POST_EMPLOYEE_DOCUMENT.LOADING, postEmployeeDocumentWorkerSaga);
  yield takeLatest(PROFILE.GET_STORE_BY_STORECODE.LOADING, getStoreByStoreCode);
  yield takeLatest(PROFILE.GET_ALL_STORES_LIST.LOADING, getAllStoresListWorkerSaga);
  yield takeLatest(PROFILE.POST_ASSIGN_STORES.LOADING, postAssignedStoresWorkerSaga);
  yield takeLatest(PROFILE.GET_EDIT_STORE_DETAILS.LOADING, getEditStoreDetailsWorkerSaga);
  yield takeLatest(PROFILE.GET_GCC_COUNTRIES.LOADING, getGccCountriesWorkerSaga);
}

export default profileWatcherSaga;
