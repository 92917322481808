import { Roles } from "@timhortons/common/src/constants/roles";
import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { SelfCalibrationActions } from "@timhortons/common/src/redux/rev/actions/selfCalibration";
import {
  IExternalMeta,
  selfCalibrationRepository
} from "@timhortons/common/src/repositories/rev/selfCalibration";
import {
  ObservationDataModel,
  PostObservationSuccessModel,
  SubCategoryModel,
  YesNoDataModel,
  TippingPointsModel,
  StoreAuditInfoModel,
  SosAverageTimeModel,
  PreviousAuditDataModel,
  CommentDataModel,
  PostCommentModel,
  GetStoreListByCodeOrNamePayload
} from "@timhortons/common/src/models/rev/selfCalibration";
import { CategoryActions } from "@timhortons/common/src/redux/rev/actions/category";
import { Logger } from "@timhortons/common/src/services/logger";
import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { getAuditType } from "@timhortons/common/src/redux/rev/selectors/selfCalibration";
import { ComparisonStoresAdminModel } from "models/profile";

type SelectAuditType = ReturnType<typeof getAuditType>;
function* checkAuthIDWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    startTime: Date;
    auditedBy: number;
    auditType: AuditType;
    isIndividualLogin: boolean;
  }>
) {
  try {
    const { payload } = value;
    let response: StoreAuditInfoModel = yield call(selfCalibrationRepository.checkAuthID, payload);
    response.storeCode = payload.storeId;
    response.auditType = payload.auditType;
    yield put(SelfCalibrationActions.checkAuthIDSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "checkAuthIDWorkerSaga");

    yield put(SelfCalibrationActions.checkAuthIDError(error));
  }
}
function* postAnswerYesNoNaWorkerSaga(
  value: IFluxStandardAction<{
    questionId: number;
    ztStatus: string;
    calibrationId: number;
    status: string;
    currentCategory?: number;
    subCategoryId: number;
    auditType: AuditType;
  }>
) {
  try {
    const { payload } = value;
    const response: YesNoDataModel = yield call(
      selfCalibrationRepository.postAnswerYesNoNa,
      payload
    );
    yield put(
      SelfCalibrationActions.answerYesNOSuccess({
        apiRes: response,
        metaData: {
          subCategoryId: payload.subCategoryId,
          questionId: payload.questionId,
          status: payload.status,
          ztStatus: payload.ztStatus
        }
      })
    );
    yield put(CategoryActions.updateCategoryData(response, payload.currentCategory));
    if (payload.status === "NO" || payload.ztStatus === "ZT") {
      yield put(
        SelfCalibrationActions.getObservationData({
          calibrationId: payload.calibrationId,
          questionId: payload.questionId
        })
      );
    }
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "postAnswerYesNoNaWorkerSaga");

    yield put(SelfCalibrationActions.answerYesNOError(error));
  }
}

function* getObservationDataWorkerSaga(
  value: IFluxStandardAction<{
    calibrationId: number;
    questionId: number;
  }>
) {
  try {
    const { payload } = value;
    const auditType: SelectAuditType = yield select(getAuditType);
    const response: ObservationDataModel = yield call(
      selfCalibrationRepository.fetchObservationData,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.getObservationDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getObservationDataWorkerSaga");

    yield put(SelfCalibrationActions.getObservationDataError(error));
  }
}

function* postObservationWorkerSaga(
  value: IFluxStandardAction<{
    questionId: number;
    observationId: number;
    calibrationId: number;
  }>
) {
  try {
    const { payload } = value;
    const auditType: SelectAuditType = yield select(getAuditType);
    const response: PostObservationSuccessModel = yield call(
      selfCalibrationRepository.postObservationSelectionData,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.postObservationSelectionSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "postObservationWorkerSaga");

    yield put(SelfCalibrationActions.postObservationSelectionError(error));
  }
}

function* getSubCategoryDataWorkerSaga(
  value: IFluxStandardAction<{
    calibrationId: number;
    categoryId: string;
    auditType: AuditType;
    storeCode: number;
  }>
) {
  const { payload } = value;

  try {
    const response: SubCategoryModel = yield call(
      selfCalibrationRepository.fetchSubCategoryData,
      payload
    );
    yield put(SelfCalibrationActions.getSubCategoryDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getSubCategoryDataWorkerSaga");

    yield put(SelfCalibrationActions.getSubCategoryDataError(error));
  }
}
function* getTippingPointsWorkerSaga(
  value: IFluxStandardAction<{
    questionId: number;
    auditType: AuditType;
  }>
) {
  try {
    const { payload } = value;
    const response: TippingPointsModel = yield call(
      selfCalibrationRepository.fetchTippingPointsData,
      payload
    );
    yield put(SelfCalibrationActions.getTippingPointsSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getTippingPointsWorkerSaga");

    yield put(SelfCalibrationActions.getTippingPointsError(error));
  }
}
function* submitAuditWorkerSaga(value: IFluxStandardAction<{ calibrationId: number }>) {
  const { payload } = value;
  const auditType: SelectAuditType = yield select(getAuditType);
  try {
    let response: { calibrationId: number; reportId: null } = yield call(
      selfCalibrationRepository.submitAudit,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.submitAuditSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "submitAuditWorkerSaga");

    yield put(SelfCalibrationActions.submitAuditError(error));
  }
}
function* prepareReportWorkerSaga(value: IFluxStandardAction<{ calibrationId: number }>) {
  const auditType: SelectAuditType = yield select(getAuditType);
  const { payload } = value;
  try {
    let reponse: { reportId: number; calibrationId: number } = yield call(
      selfCalibrationRepository.prepareReport,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.submitAuditSuccess(reponse));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "prepareReportWorkerSaga");

    yield put(SelfCalibrationActions.submitAuditError(error));
  }
}

function* getPreviousAuditDataWorkerSaga(
  value: IFluxStandardAction<{
    empId: number;
    role: Roles[];
  }>
) {
  const { payload } = value;
  try {
    const response: PreviousAuditDataModel = yield call(
      selfCalibrationRepository.fetchPreviousAudit,
      payload
    );
    yield put(SelfCalibrationActions.getPreviousAuditDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getPreviousAuditDataWorkerSaga");

    yield put(SelfCalibrationActions.getPreviousAuditDataError(error));
  }
}

function* postSosAvgTimeWorkerSaga(
  value: IFluxStandardAction<{
    sosTime: Array<{ orderId: "string"; timeTaken: "string" }>;
    calibrationId: number;
    subCategoryId: number;
    categoryId: number;
  }>
) {
  const auditType: SelectAuditType = yield select(getAuditType);
  const { payload } = value;
  try {
    const response: SosAverageTimeModel = yield call(
      selfCalibrationRepository.postSosAvgTime,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.postSosAvgTimeSuccess(response));
    yield put(
      CategoryActions.updateCategoryData(
        {
          completionPercentage: response.percentageCompleted,
          categoryPercentage: response.percentageCompletedByCategory,
          selfCalibrationId: payload.calibrationId.toString()
        },
        payload.categoryId
      )
    );
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "postSosAvgTimeWorkerSaga");

    yield put(SelfCalibrationActions.postSosAvgTimeError(error));
  }
}
function* deleteSosWorkerSaga(
  value: IFluxStandardAction<{ calibrationId: number; subCategoryId: number; categoryId: number }>
) {
  const { payload } = value;
  const auditType: SelectAuditType = yield select(getAuditType);
  try {
    const response: SosAverageTimeModel = yield call(
      selfCalibrationRepository.deleteSos,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.deleteSosSuccess());
    yield put(
      CategoryActions.updateCategoryData(
        {
          completionPercentage: response.percentageCompleted,
          categoryPercentage: response.percentageCompletedByCategory,
          selfCalibrationId: payload.calibrationId.toString()
        },
        payload.categoryId
      )
    );
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "deleteSosWorkerSaga");

    yield put(SelfCalibrationActions.deleteSosError(error));
  }
}
function* getSosAvgTimeWorkerSaga(
  value: IFluxStandardAction<{
    calibrationId: number;
    subCategoryId: number;
  }>
) {
  const { payload } = value;
  const auditType: SelectAuditType = yield select(getAuditType);
  try {
    const response: SosAverageTimeModel = yield call(
      selfCalibrationRepository.getSosAvgTime,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.getSosAvgTimeSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getSosAvgTimeWorkerSaga");

    yield put(SelfCalibrationActions.getSosAvgTimeError(error));
  }
}

function* deleteAuditWorkerSaga(value: IFluxStandardAction<{ calibrationId: number }>) {
  const { payload } = value;
  try {
    yield call(selfCalibrationRepository.deleteAudit, payload);
    yield put(SelfCalibrationActions.deleteAuditSuccess());
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "deleteAuditWorkerSaga");

    yield put(SelfCalibrationActions.deleteAuditError(error));
  }
}

function* resumeAuditWorkerSaga(
  value: IFluxStandardAction<{ calibrationId: number; type: AuditType }>
) {
  const { payload } = value;
  try {
    const response: StoreAuditInfoModel = yield call(
      selfCalibrationRepository.resumeAudit,
      payload
    );

    yield put(SelfCalibrationActions.checkAuthIDSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "resumeAuditWorkerSaga");

    yield put(SelfCalibrationActions.checkAuthIDError(error));
  }
}

function* getCommentDataWorkerSaga(value: IFluxStandardAction<PostCommentModel>) {
  try {
    const { payload } = value;
    const auditType: SelectAuditType = yield select(getAuditType);
    const response: CommentDataModel = yield call(
      selfCalibrationRepository.postCommentData,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.postCommentDataSuccess(response, payload));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getCommentDataWorkerSaga");

    yield put(SelfCalibrationActions.postCommentDataError(error));
  }
}

function* getFeedbackDataWorkerSaga(value: IFluxStandardAction<PostCommentModel>) {
  try {
    const { payload } = value;
    const auditType: SelectAuditType = yield select(getAuditType);
    const response: CommentDataModel = yield call(
      selfCalibrationRepository.getCommentData,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.getFeedbackDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getFeedbackDataWorkerSaga");

    yield put(SelfCalibrationActions.getFeedbackDataError(error));
  }
}

function* getExternalMetaWatcherSaga() {
  try {
    const response: IExternalMeta = yield call(selfCalibrationRepository.getExternalMetaDetails);
    yield put(SelfCalibrationActions.getExternalMetaDetailsSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getExternalMetaWatcherSaga");

    yield put(SelfCalibrationActions.getExternalMetaDetailsError(error));
  }
}

function* postExternalAuditData(value: IFluxStandardAction<{ obj: any; files: FormData }>) {
  const { payload } = value;
  try {
    const response: { calibrationId: number; status: string } = yield call(
      selfCalibrationRepository.postExternalAuditData,
      payload.obj
    );
    let data: {} = yield call(selfCalibrationRepository.uploadExternalAuditAsset, {
      calibrationId: response.calibrationId,
      files: payload.files
    });
    yield put(SelfCalibrationActions.postExternalAuditSuccess(data));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "postExternalAuditData");

    yield put(SelfCalibrationActions.postExternalAuditError(error));
  }
}

function* updateCommentDataWorkerSaga(value: IFluxStandardAction<PostCommentModel>) {
  try {
    const { payload } = value;
    const auditType: SelectAuditType = yield select(getAuditType);
    const response: CommentDataModel = yield call(
      selfCalibrationRepository.updateCommentData,
      payload,
      auditType
    );
    yield put(SelfCalibrationActions.updateCommentDataSuccess(response, payload));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getCommentDataWorkerSaga");

    yield put(SelfCalibrationActions.updateCommentDataError(error));
  }
}
function* getStoreListByCodeOrNameWorkerSaga(
  value: IFluxStandardAction<GetStoreListByCodeOrNamePayload>
) {
  const { payload } = value;
  try {
    const response: ComparisonStoresAdminModel = yield call(
      selfCalibrationRepository.getStoreListByCodeOrName,
      payload
    );
    yield put(SelfCalibrationActions.getStoreListByStoreCodeOrNameSuccess(response));
  } catch (error) {
    Logger.error("Rev/selfCalibration", error, "getStoreListByCodeOrNameWorkerSaga");

    yield put(SelfCalibrationActions.getStoreListByStoreCodeOrNameSuccess(error));
  }
}

function* selfCalibrationWatcherSaga() {
  yield takeLatest(REV.CHECK_AUTH_ID.LOADING, checkAuthIDWorkerSaga);
  yield takeLatest(REV.GET_SUB_CATEGORY_DATA.LOADING, getSubCategoryDataWorkerSaga);
  yield takeEvery(REV.ANSWER_YES_NO_NA_DATA.LOADING, postAnswerYesNoNaWorkerSaga);
  yield takeEvery(REV.GET_OBSERVATION_DATA.LOADING, getObservationDataWorkerSaga);
  yield takeLatest(REV.POST_OBSERVATION_DATA.LOADING, postObservationWorkerSaga);
  yield takeLatest(REV.SUBMIT_AUDIT.LOADING, submitAuditWorkerSaga);
  yield takeLatest(REV.PREPARE_REPORT.LOADING, prepareReportWorkerSaga);
  yield takeLatest(REV.GET_TIPPING_POINTS_DATA.LOADING, getTippingPointsWorkerSaga);
  yield takeLatest(REV.GET_PREVIOUS_AUDIT_DATA.LOADING, getPreviousAuditDataWorkerSaga);
  yield takeLatest(REV.POST_SOS_AVG_TIME.LOADING, postSosAvgTimeWorkerSaga);
  yield takeEvery(REV.GET_SOS_AVG_TIME.LOADING, getSosAvgTimeWorkerSaga);
  yield takeLatest(REV.DELETE_AUDIT.LOADING, deleteAuditWorkerSaga);
  yield takeLatest(REV.RESUME_AUDIT.LOADING, resumeAuditWorkerSaga);
  yield takeLatest(REV.POST_COMMENT_DATA.LOADING, getCommentDataWorkerSaga);
  yield takeEvery(REV.GET_COMMENT_DATA.LOADING, getFeedbackDataWorkerSaga);
  yield takeLatest(REV.DELETE_SOS.LOADING, deleteSosWorkerSaga);
  yield takeLatest(REV.GET_EXTERNAL_META.LOADING, getExternalMetaWatcherSaga);
  yield takeLatest(REV.POST_EXTERNAL_AUDIT.LOADING, postExternalAuditData);
  yield takeLatest(REV.UPDATE_COMMENT_DATA.LOADING, updateCommentDataWorkerSaga);
  yield takeLatest(REV.GET_STORELIST_BY_CODE_OR_NAME.LOADING, getStoreListByCodeOrNameWorkerSaga);
}

export default selfCalibrationWatcherSaga;
