export enum Roles {
  SuperAdmin = "Super Admin",
  TrainingAndDevLead = "Training and Dev Lead",
  TrainingAndDevManager = "Training and Dev Manager",
  CEO = "Chief Executive Officer",
  COO = "Chief Operating Officer",
  HrAndAdminDirector = "HR and Admin Director",
  ProductionAndDevExecutive = "Production and Dev Executive",
  ClusterHeadofOps = "Cluster Head of Ops",
  CountryManager = "Country Manager",
  SeniorOpsManager = "Senior Ops Manager",
  OperationManager = "Ops Manager",
  Supervisor = "Supervisor",
  StoreManager = "Restaurant Manager",
  AssistantStoreManager = "Assistant Restaurant Manager",
  AreaManager = "Area Manager",
  AreaTrainer = "Area Trainer",
  InStoreTrainer = "In-Store Trainer",
  InternalAuditor = "Internal Auditor",
  AuditorLP = "LP Auditor",
  HeadLP = "Compliance and LP Manager",
  ServiceCrew = "Service Crew",
  OfficeTeam = "Back Office",
  // Admin = "Admin",
  AdminIT = "adminIt",
  // QAAuditor = "QA Auditor",
  SmASM = "smAsm",
  StoreAccess = "storeAccess",
  ChannelManager = "channelManager",
  All = "All"
}

export const SuperAdminEqvRoles: Array<Roles> = [
  Roles.SuperAdmin,
  Roles.TrainingAndDevLead,
  Roles.TrainingAndDevManager
];

export const CEOEqvRoles: Array<Roles> = [
  Roles.CEO,
  Roles.COO,
  Roles.HrAndAdminDirector,
  Roles.ProductionAndDevExecutive
];
