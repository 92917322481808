import React, { useEffect, useState, useRef } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, TextStyle } from "react-native";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { ButtonGroup } from "@timhortons/common/src/components/molecules/buttonGroup";

/// it can remain common to avoid duplicate code
import {
  Filters,
  HideFilters,
  ResetFilters,
  ShowFiltersView
} from "@timhortons/common/src/modules/common/organisms/filters";
import { ReportTable } from "@timhortons/common/src/modules/lp/components/dashboard/reports";
/// it can remain common to avoid duplicate code
import { tableHeaderStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsTableHeader/tableHeaderStyles";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import {
  currMonthStartEndDate,
  dateFormat,
  dateToTZ
} from "@timhortons/common/src/utils/dateFormat";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { DoubleDatePicker } from "@timhortons/web/src/components/atoms/datepicker/doubleViewCalender";
import CalendarComponent from "@timhortons/tablet/src/components/atoms/datepicker";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import ModalDropdown from "@timhortons/common/src/components/molecules/modalDropdown";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { CountryDataListModel } from "@timhortons/common/src/models/profile";
import { Roles } from "@timhortons/common/src/constants/roles";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { getDocumentDownloader } from "@timhortons/web/src/utils/docsDownloader";
import { LPEndPoints } from "@timhortons/common/src/constants/endPoints";
import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import MultiSelect from "@timhortons/common/src/components/molecules/multiSelect";

interface IProps {
  translate?: any;
  currentTable: ReportTable;
  setCurrentTable: Function;
  isIndividualLogin?: boolean;
  getReportTableData?: Function;
  setReportData?: Function;
  finalReportData?: any;
  getLpSelectedMonthFilter: Function;
  getLpSelectedCountryFilter: Function;
  isReportBuilderAllowed: boolean;
  user: IUserDetails;
  getAllCountriesLoading?: Function;
  gccCountryData?: CountryDataListModel;
}
export interface SelectedMonthFilter {
  month: {
    id: number;
    label: string;
  };
  year: number;
}
export const selectedFromDateHandler = (
  value: any,
  setReset: any,
  selectedValues: any,
  setSelectedValues: any
) => {
  setReset(false);
  if (dateFormat(value) === dateFormat(selectedValues.fromDate)) {
    return null;
  } else {
    return setSelectedValues((prevState: any) => ({
      ...prevState,
      fromDate: value && value[0],
      toDate: value && value[1]
    }));
  }
};

export const selectedSearchHandler = (value: string, setReset: any, setSelectedValues: any) => {
  setReset(false);
  setSelectedValues((prevState: any) => ({
    ...prevState,
    search: value
  }));
};
export default function ReportsTableHeader(props: IProps) {
  const {
    translate,
    currentTable,
    setCurrentTable,
    isIndividualLogin,
    getReportTableData,
    setReportData,
    finalReportData,
    getLpSelectedMonthFilter,
    getLpSelectedCountryFilter,
    isReportBuilderAllowed,
    user,
    getAllCountriesLoading,
    gccCountryData
  } = props;
  const [selectedValues, setSelectedValues] = useState({
    visitType: "",
    fromDate: null,
    toDate: null,
    search: ""
  });
  // const [selectedMonthFilter, setSelectedMonthFilter] = useState<SelectedMonthFilter>({
  //   year: new Date().getFullYear(),
  //   month: {
  //     label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
  //     id: new Date().getMonth()
  //   }
  // });
  const currDateObj = {
    fromDate: currMonthStartEndDate().startDate,
    toDate: new Date(new Date().setHours(23, 59, 59, 999))
  };
  const [selectedMonthFilter, setSelectedMonthFilter] = useState<{
    fromDate: Date;
    toDate: Date;
  }>({ ...currDateObj });
  const [isShowFilter, setShowFilter] = useState(false);
  const [reset, setReset] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [monthFilterReset, setMonthFilterReset] = useState<boolean>(false);
  const [isDownloadBtnDisabled, setIsDownloadBtnDisabled] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState<boolean>(false);
  const [resetCountryFilter, setResetCountryFilter] = useState<boolean>(false);

  // const [summaryFilter, setSummaryFilter] =
  //   useState<{ year: number; month: { label: string; id: number } }>(null);
  let prevSelectedValues = useRef(selectedValues);
  let prevSelectedMonthValues = useRef(selectedMonthFilter);

  const downloadHandler = async () => {
    setIsDownloadBtnDisabled(true);

    try {
      const { empId, storeCode, roles } = user;
      const { fromDate, toDate, search } = selectedValues;
      const { sortOrder } = finalReportData;

      const customPrefix = "AuditList_";
      const endPoint = roles.includes(Roles.StoreAccess)
        ? LPEndPoints.reports.lpStoreAuditListDownload(storeCode)
        : LPEndPoints.reports.lpAuditListDownload(empId);
      const params = {
        sortByAuditDate: sortOrder?.toUpperCase(),
        filterByStartDate: fromDate ? dateToTZ(fromDate) : null,
        filterByEndDate: toDate ? dateToTZ(toDate) : null,
        searchByKeyword: search
      };

      await getDocumentDownloader({
        endPoint,
        params,
        xApiKey: LPKeys.LP_REPORT_BUILDER,
        customPrefix
      });
    } catch (_err) {
      setDownloadFailed(true);
    }
    setIsDownloadBtnDisabled(false);
    return "Downloaded";
  };
  const selectedFilterHandler = (value: Object) => {
    setReportData((prevState: any) => ({
      ...prevState,
      ...value,
      pageNumber: 1
    }));
    getReportTableData({
      ...finalReportData,
      ...value,
      pageNumber: 1
    });
  };

  useEffect(() => {
    if (currentTable === ReportTable.summary) {
      getAllCountriesLoading({ pageNo: 0, pageSize: 10, searchByKeyword: "" });
    }
    setSelectedCountry(null);
  }, [currentTable]);
  useEffect(() => {
    if (currentTable === ReportTable.audits) {
      if (JSON.stringify(prevSelectedValues.current) !== JSON.stringify(selectedValues)) {
        selectedFilterHandler(selectedValues);
        prevSelectedValues.current = selectedValues;
      }
    }
  }, [selectedValues]);
  const getCodes = () => {
    let newArray: any[] = [];
    // to set Codes for channels dropdown in case of Detailed Report
    if (selectedCountry) {
      if (selectedCountry.length !== 0) {
        selectedCountry?.forEach((item) => {
          newArray.push(item.countryCode);
          return newArray;
        });
      } else {
        return newArray;
      }
    }
    return newArray;
  };
  useEffect(() => {
    getLpSelectedMonthFilter(selectedMonthFilter);
  }, [selectedMonthFilter]);
  useEffect(() => {
    let dummy = getCodes();
    if (selectedCountry?.length > 0) {
      getLpSelectedCountryFilter(dummy.join(","));
    } else {
      getLpSelectedCountryFilter(null);
    }
  }, [selectedCountry]);
  const resetState = (fromReset?: boolean) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      fromDate: null,
      toDate: null,
      search: "",
      visitType: ""
    }));
    if (fromReset) {
      setMonthFilterReset(true);
      setSelectedMonthFilter({ ...currDateObj });
      prevSelectedMonthValues.current = { ...currDateObj };
      setSelectedCountry(null);
      setResetCountryFilter(true);
    } else {
      setMonthFilterReset(false);
    }
    setReset(true);
  };
  const getButtonStyle = (type: ReportTable) => {
    let themedStyle: StyleProp<ViewStyle>;
    if (currentTable !== type) {
      themedStyle = tableHeaderStyles.inActiveButtonStyle;
    }
    return StyleSheet.flatten([tableHeaderStyles.buttonStyle, themedStyle]);
  };
  const getTextStyle = (type: ReportTable) => {
    let themedStyle: StyleProp<TextStyle>;
    if (currentTable !== type) {
      themedStyle = tableHeaderStyles.inActiveTextStyle;
    }
    return StyleSheet.flatten(themedStyle);
  };

  const buttonLists = [
    {
      id: "1",
      title: translate("audits"),
      onPress: () => {
        setCurrentTable(ReportTable.audits);
      },
      buttonStyle: getButtonStyle(ReportTable.audits),
      textStyles: getTextStyle(ReportTable.audits)
    },
    {
      id: "2",
      title: translate("summary"),
      onPress: () => {
        setCurrentTable(ReportTable.summary);
        setShowFilter(false);
        resetState();
        setSelectedMonthFilter({ ...currDateObj });
        prevSelectedMonthValues.current = { ...currDateObj };
      },
      buttonStyle: getButtonStyle(ReportTable.summary),
      textStyles: getTextStyle(ReportTable.summary)
    }
    // {
    //   id: "3",
    //   title: translate("reportBuilder"),
    //   onPress: () => {
    //     setCurrentTable(ReportTable.reportBuilder);

    //     setShowFilter(false);
    //   },
    //   buttonStyle: getButtonStyle(ReportTable.reportBuilder),
    //   textStyles: getTextStyle(ReportTable.reportBuilder)
    // }
  ];

  const newButtonList = buttonLists;
  // isIndividualLogin
  //   ? PlatformUtils.isWeb() && isReportBuilderAllowed
  //     ? buttonLists
  //     : buttonLists.slice(0, 2)
  //   : buttonLists.slice(0, 2);

  return (
    <>
      <View style={tableHeaderStyles.container}>
        <View style={tableHeaderStyles.textContainer}>
          <Text
            fontWeight={FontWeight.Regular}
            textSize={TextSize.ExtraLarge}
            testId={testIds.reports.reportsTableHeader}
            textStyle={tableHeaderStyles.headingColor}
          >
            {currentTable === ReportTable.reportBuilder
              ? translate("reportBuilder")
              : translate("Reports")}
          </Text>
          {currentTable === ReportTable.audits && (
            <Icon
              testID="download"
              name={IconNames.tableDownload}
              color={colorPallete.black4}
              size={16}
              customStyle={styles.download}
              onPress={downloadHandler}
              disabled={isDownloadBtnDisabled}
            />
          )}
          {isShowFilter &&
          (currentTable === ReportTable.audits || currentTable === ReportTable.summary) ? (
            <ShowFiltersView
              translate={translate}
              setShowFilter={() => setShowFilter(!isShowFilter)}
              resetState={resetState}
            />
          ) : (
            (currentTable === ReportTable.audits || currentTable === ReportTable.summary) && (
              <HideFilters
                translate={translate}
                setShowFilter={() => setShowFilter(!isShowFilter)}
              />
            )
          )}
          {currentTable === ReportTable.audits || currentTable === ReportTable.summary ? (
            <ResetFilters
              resetContainerStyle={{ marginTop: 6 }}
              translate={translate}
              setShowFilter={() => setShowFilter(!isShowFilter)}
              resetState={() => resetState(true)}
            />
          ) : null}
          {currentTable === ReportTable.audits && downloadFailed && (
            <AlertModal
              errorMsg={translate("downloadFailed")}
              setModalVisible={function (): void {
                setDownloadFailed(false);
              }}
            />
          )}
        </View>
        <View style={tableHeaderStyles.dropdownContainer}>
          <ButtonGroup
            buttonLists={newButtonList}
            buttonsType={ButtonType.Secondary}
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.Regular}
          />
        </View>
      </View>
      {isShowFilter ? (
        currentTable === ReportTable.audits ? (
          <View>
            <Filters
              isDropDownRequired={false}
              parentCallBackFrom={(value: any) =>
                selectedFromDateHandler(value, setReset, selectedValues, setSelectedValues)
              }
              searchFunction={(value: any) => {
                selectedSearchHandler(value, setReset, setSelectedValues);
              }}
              resetHandler={resetState}
              searchValue={selectedValues.search}
              reset={reset}
              disableSelectedPrevDate={selectedValues && selectedValues.fromDate}
              selectedValueDefault={selectedValues.visitType}
              translate={translate}
            />
          </View>
        ) : (
          <View style={tableHeaderStyles.monthPickerWrapper}>
            {currentTable === ReportTable.summary ? (
              <View style={{ flexDirection: "row" }}>
                {PlatformUtils.isWeb() ? (
                  <DoubleDatePicker
                    selectRange
                    showDoubleView
                    showWeekNumbers
                    parentDoubleViewCall={(data: Date[]) => {
                      prevSelectedMonthValues.current = {
                        fromDate: data && data[0],
                        toDate: data && data[1]
                      };
                      setSelectedMonthFilter((prev) => ({
                        ...prev,
                        fromDate: data && data[0],
                        toDate: data && data[1]
                      }));
                    }}
                    translate={translate}
                    dateContainerStyle={styles.datePickerContainer}
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.ExtraSmall}
                    textStyles={styles.datePickerText}
                    reset={monthFilterReset}
                    prevSelectedValues={prevSelectedMonthValues}
                    enablePrevValuesFetch
                    resetToMonthStart
                    setReset={setMonthFilterReset}
                  />
                ) : (
                  <CalendarComponent
                    selectRange
                    parentCallBack={(data: Date[]) => {
                      prevSelectedMonthValues.current = {
                        fromDate: data && data[0],
                        toDate: data && data[1]
                      };
                      setSelectedMonthFilter((prev) => ({
                        ...prev,
                        fromDate: data && new Date(data[0]?.toString()),
                        toDate: data && new Date(data[1]?.toString())
                      }));
                    }}
                    reset={monthFilterReset}
                    textStyles={styles.datePickerText}
                    iconStyles={styles.datePickerIcon}
                    iconSize={12}
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.ExtraSmall}
                    dateContainerStyle={styles.datePickerContainer}
                    prevSelectedValues={prevSelectedMonthValues}
                    enablePrevValuesFetch
                    resetToMonthStart
                    setReset={setMonthFilterReset}
                  />
                )}
                {/* <MonthPicker
                  reset={reset}
                  setReset={setReset}
                  callBk={(data) => {
                    setSelectedMonthFilter(data);
                  }}
                  // initMonth={{
                  //   label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
                  //   id: new Date().getMonth()
                  // }}
                /> */}
                {(user?.roles?.includes(Roles.SuperAdmin) ||
                  user?.roles?.includes(Roles.TrainingAndDevLead) ||
                  user?.roles?.includes(Roles.HrAndAdminDirector) ||
                  user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
                  user?.roles?.includes(Roles.TrainingAndDevManager) ||
                  user?.roles?.includes(Roles.CEO) ||
                  user?.roles?.includes(Roles.COO) ||
                  user?.roles?.includes(Roles.HeadLP)) && (
                  <View style={styles.filterGroupCotainer}>
                    <Text
                      textSize={TextSize.Small}
                      fontWeight={FontWeight.Regular}
                      testId="individualAccessStatus"
                      textStyle={styles.filterTextStyle}
                    >
                      {translate("Country")}
                    </Text>
                    {/* <ModalDropdown
                      iconName={IconNames.filledDown}
                      allItems={gccCountryData?.content}
                      selectedValueDefault={selectedCountry?.value}
                      onChangeCallBack={(obj: any) =>
                        setSelectedCountry({ code: obj.countryCode, value: obj.value })
                      }
                      isSearchRequired={true}
                    /> */}
                    <View style={styles.dropdown}>
                      <MultiSelect
                        title={translate("Select Country")}
                        allItems={gccCountryData?.content}
                        handleSelectedValues={(vals: any[]) => {
                          setSelectedCountry(vals);
                        }}
                        emptyMsg={"Nothing to select"}
                        resetData={resetCountryFilter}
                        setResetMultiselect={setResetCountryFilter}
                        countryFilterReset={resetCountryFilter}
                      />
                    </View>
                  </View>
                )}
              </View>
            ) : null}
          </View>
        )
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  download: {
    marginTop: 10,
    ...I18nService.select({
      rtl: { paddingStart: 10 },
      ltr: { paddingStart: 10 }
    })
  },
  dropdown: {
    maxHeight: 36,
    width: 350,
    ...I18nService.select({
      rtl: { paddingStart: 30 },
      ltr: { paddingEnd: 30 }
    })
  },
  datePickerContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colorPallete.white1
  },
  datePickerText: {
    color: colorPallete.grey2
  },
  datePickerIcon: {
    color: colorPallete.grey2
  },
  filterGroupCotainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  filterTextStyle: {
    color: colorPallete.grey2,
    paddingRight: 12,
    paddingHorizontal: 30
  }
});
