import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "logs";

const LOGS = {
  Authentication: {
    CHECK_LOGS_AUTH: ActionHelper.actionTypesCreator(prefix, "CHECK_LOGS_AUTH"),
    RESET_LOGS_AUTH: "LOGS/RESET_AUTH"
  },
  MainLogs: {
    TimeTemp: {
      RESET_AUTH_ERROR: "LOGS/RESET_AUTH_ERROR",
      GET_TIME_TEMP_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_TIME_TEMP_CATEGORIES"),
      GET_RECEIVING_LOGS_CATEGORIES: ActionHelper.actionTypesCreator(
        prefix,
        "GET_RECEIVING_LOGS_CATEGORIES"
      ),
      GET_RECEIVING_LOGS_SUBCATEGORIES: ActionHelper.actionTypesCreator(
        prefix,
        " GET_RECEIVING_LOGS_SUBCATEGORIES"
      ),
      GET_RECEIVING_LOGS_ITEMS: ActionHelper.actionTypesCreator(prefix, "GET_RECEIVING_LOGS_ITEMS"),
      GET_PRODUCT_TEMP_CATEGORY: ActionHelper.actionTypesCreator(
        prefix,
        "GET_PRODUCT_TEMP_CATEGORY"
      ),
      GET_PRODUCT_TEMP_ITEMS: ActionHelper.actionTypesCreator(prefix, "GET_PRODUCT_TEMP_ITEMS"),
      RECORD_PRODUCT_TEMPERATURE: ActionHelper.actionTypesCreator(
        prefix,
        "RECORD_PRODUCT_TEMPERATURE"
      ),
      RECORD_RECEIVINGLOG_TEMPERATURE: ActionHelper.actionTypesCreator(
        prefix,
        "RECORD_RECEIVINGLOG_TEMPERATURE"
      ),
      GET_LOGS_SHIFT: ActionHelper.actionTypesCreator(prefix, "GET_LOGS_SHIFT"),
      GET_EQUIPMENT_TEMP_ITEMS: ActionHelper.actionTypesCreator(prefix, "GET_EQUIPMENT_TEMP_ITEMS"),
      RESET_TIME_TEMP_ERROR: "LOGS/RESET_TIME_TEMP_ERROR",
      RESET_TIME_TEMP_CATEGORY_STATE: "LOGS/RESET_TIME_TEMP_CATEGORY_STATE",
      UPDATE_ACTIVE_SHIFT: "LOGS/UPDATE_ACTIVE_SHIFT",
      RESET_PROD_TEMP_CATEGORY_STATE: "RESET_PROD_TEMP_CATEGORY_STATE",
      RESET_REC_LOG: "LOGS/RESET_REC_LOG",
      RESET_IS_PROD_TEMP_COMPLETED: "LOGS/RESET_IS_PROD_TEMP_COMPLETED",
      RESET_TWO_RED_STATE: "LOGS/RESET_TWO_RED_STATE",
      RESET_TIME_TEMP_ENTIRE_STATE: "LOGS/RESET_TIME_TEMP_ENTIRE_STATE"
    },
    Cleaning: {
      GET_CLEANING_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_CLEANING_CATEGORIES"),
      GET_EMPLOYEE_DROPDOWN: ActionHelper.actionTypesCreator(prefix, "GET_EMPLOYEE_DROPDOWN"),
      GET_DEEP_CLEANING_ITEMS: ActionHelper.actionTypesCreator(prefix, "GET_DEEP_CLEANING_ITEMS"),
      POST_DEEP_CLEANING_ITEMS: ActionHelper.actionTypesCreator(prefix, "POST_DEEP_CLEANING_ITEMS"),
      GET_RESTROOM_ITEMS: ActionHelper.actionTypesCreator(prefix, "GET_RESTROOM_ITEMS"),
      POST_RESTROOM_ITEMS: ActionHelper.actionTypesCreator(prefix, "POST_RESTROOM_ITEMS"),
      RESET_CLEANING_ERRORS: "RESET_CLEANING_ERRORS",
      RESET_CLEANING_STATE: "RESET_CLEANING_STATE",

      GET_CLEANING_LOGS_SHIFT: ActionHelper.actionTypesCreator(prefix, "GET_ACTIONS_LOGS_SHIFT"),
      GET_RESTROOM_PRODUCTS: ActionHelper.actionTypesCreator(prefix, "GET_RESTROOM_PRODUCTS")
    },
    ManagerWalk: {
      CHECK_MWT_LOGS_AUTH: ActionHelper.actionTypesCreator(prefix, "CHECK_MWT_LOGS_AUTH"),
      RESET_MWT_LOGS_AUTH_ERROR: "LOGS/RESET_MWT_LOGS_AUTH_ERROR",
      RESET_MWT_LOGS_AUTH: "LOGS/RESET_MWT_LOGS_AUTH",
      GET_MANAGER_WALKTHROUGH_CATEGORIES: ActionHelper.actionTypesCreator(
        prefix,
        "GET_MANAGER_WALKTHROUGH_CATEGORIES"
      ),
      GET_ITEMS_LIST_BY_CATEGORY: ActionHelper.actionTypesCreator(
        prefix,
        "GET_ITEMS_LIST_BY_CATEGORY"
      ),
      POST_MWT_ITEMS: ActionHelper.actionTypesCreator(prefix, "POST_MWT_ITEMS"),
      RESET_MWT_LOGS: "LOGS/RESET_MWT_LOGS",
      RESET_MWT_ERROR: "LOGS/RESET_MWT_ERROR",
      RESET_MWT_POST_ITEMS_RESPONSE: "LOGS/RESET_MWT_POST_ITEMS_RESPONSE",
      UPDATE_MWT_ACTIVE_SHIFTS: "LOGS/UPDATE_MWT_ACTIVE_SHIFTS"
    },
    StationRead: {
      GET_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORIES"),
      GET_SUBCATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_SUBCATEGORIES"),
      POST_STATION_READINESS_ITEMS: ActionHelper.actionTypesCreator(
        prefix,
        "POST_STATION_READINESS_ITEMS"
      ),
      GET_SUBCATEGORIES_ITEMS: ActionHelper.actionTypesCreator(prefix, "GET_SUBCATEGORIES_ITEMS"),
      GET_SR_SHIFTS: ActionHelper.actionTypesCreator(prefix, "GET_SR_SHIFTS"),
      RESET_STATION_READ_LOGS: "LOGS/RESET_STATION_READ_LOGS",
      RESET_STATIONREAD_ENTIRE_LOGS: "RESET_STATIONREAD_ENTIRE_LOGS",
      UPDATE_SR_ACTIVE_SHIFTS: "LOGS/UPDATE_SR_ACTIVE_SHIFTS",
      HANDLE_CLICKED_SHIFT: "LOGS/HANDLE_CLICKED_SHIFT",
      HANDLE_AUTH: "LOGS/HANDLE_AUTH"
    }
  },

  ManagerReview: {
    GET_MANAGER_REVIEW_LOGS: ActionHelper.actionTypesCreator(prefix, "GET_MANAGER_REVIEW_LOGS"),
    UPDATE_MANAGER_REVIEW_APPROVAL: ActionHelper.actionTypesCreator(
      prefix,
      "UPDATE_MANAGER_REVIEW_APPROVAL"
    ),
    RESET_MR_APPROVAL_ERROR: "RESET_MR_APPROVAL_ERROR"
  },
  Summary: {
    GET_CATEGORIES_META_DATA: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORIES_META_DATA"),
    GET_TIME_TEMP_LOGS_SUMMARY_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "ET_TIME_TEMP_LOGS_SUMMARY_DATA"
    ),
    GET_STATION_LOGS_SUMMARY_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_STATION_LOGS_SUMMARY_DATA"
    ),
    GET_CLEANING_LOGS_SUMMARY_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_CLEANING_LOGS_SUMMARY_DATA"
    ),
    GET_MWT_LOGS_SUMMARY_DATA: ActionHelper.actionTypesCreator(prefix, "GET_MWT_LOGS_SUMMARY_DATA"),
    GET_SELECTED_MONTH_FILTER: ActionHelper.actionTypesCreator(prefix, "GET_SELECTED_MONTH_FILTER"),
    RESET_LOGS_SUMMARY: "LOGS/RESET_LOGS_SUMMARY",
    GET_STORE_LIST: ActionHelper.actionTypesCreator(prefix, "GET_STORE_LIST"),
    RESET_STORE_LIST: "RESET_STORE_LIST"
  },
  IndividualLogActions: {
    GET_TABLE_DATA: ActionHelper.actionTypesCreator(prefix, "GET_TABLE_DATA"),
    GET_LOGS_TABLE_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LOGS_TABLE_DATA"),

    GET_LOGS_STORE_SPECIFIC_TABLE_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LOGS_STORE_SPECIFIC_TABLE_DATA"
    ),
    GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA"
    )
  },
  Actions: {
    ManagerWalk: {
      GET_ACTION_MANAGER_WALTHROUGH_SHIFTS: ActionHelper.actionTypesCreator(
        prefix,
        "GET_ACTION_MANAGER_WALTHROUGH_SHIFTS"
      ),
      GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS: ActionHelper.actionTypesCreator(
        prefix,
        "GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS"
      ),
      UPDATE_MANAGER_WALKTHROUGH_ACTIONS: ActionHelper.actionTypesCreator(
        prefix,
        "UPDATE_MANAGER_WALKTHROUGH_ACTIONS"
      ),
      RESET_MWT_ACTIONS__STATE: "ACTIONS/RESET_MWT_ACTIONS__STATE",
      RESET_MWT_ACTIONS_ERROR: "RESET_MWT_ACTIONS_ERROR"
    },
    TimeTemp: {
      GET__PRODUCT_TEMP_ACTIONS: ActionHelper.actionTypesCreator(
        prefix,
        "GET__PRODUCT_TEMP_ACTIONS"
      ),
      UPDATE_PRODUCT_ACTIONS: ActionHelper.actionTypesCreator(prefix, "UPDATE_PRODUCT_ACTIONS"),
      RESET_TIME_TEMP_ACTION_ERROR: "RESET_TIME_TEMP_ACTION_ERROR",
      GET_TIME_TEMP_ACTIONS_CATEGORIES: ActionHelper.actionTypesCreator(
        prefix,
        "GET_TIME_TEMP_ACTIONS_CATEGORIES"
      ),
      RESET_PROD_TEMP_ACTION_CATEGORY_STATE: "RESET_PROD_TEMP_ACTION_CATEGORY_STATE",
      RESET_TIME_TEMP_ACTIONS: "LOGS/RESET_TIME_TEMP_ACTIONS"
    },
    Cleaning: {
      GET_DEEP_CLEANING_ACTIONS: ActionHelper.actionTypesCreator(
        prefix,
        "GET_DEEP_CLEANING_ACTIONS"
      ),
      UPDATE_DEEP_CLEANING_ACTIONS: ActionHelper.actionTypesCreator(
        prefix,
        "UPDATE_DEEP_CLEANING_ACTIONS"
      ),
      GET_RESTROOM_ACTIONS: ActionHelper.actionTypesCreator(prefix, "GET_RESTROOM_ACTIONS"),
      UPDATE_RESTROOM_ACTIONS: ActionHelper.actionTypesCreator(prefix, "UPDATE_RESTROOM_ACTIONS"),
      RESET_ACTIONS_CLEANING_ERRORS: "RESET_ACTIONS_CLEANING_ERRORS",
      RESET_ACTIONS_CLEANING_STATE: "ACTIONS/RESET_ACTIONS_CLEANING_STATE"
    },
    StationRead: {
      GET_ACTIONS_SUBCATEGORIES: ActionHelper.actionTypesCreator(
        prefix,
        "GET_ACTIONS_SUBCATEGORIES"
      ),
      GET_ACTIONS_SUBCATEGORIES_ITEMS: ActionHelper.actionTypesCreator(
        prefix,
        "GET_ACTIONS_SUBCATEGORIES_ITEMS"
      ),
      UPDATE_STATION_READINESS_ACTIONS: ActionHelper.actionTypesCreator(
        prefix,
        "UPDATE_STATION_READINESS_ACTIONS"
      ),
      RESET_ACTIONS_POST_API_ERROR: "ACTIONS/RESET_ACTIONS_POST_API_ERROR",
      RESET_ENTIRE_SR_ACTIONS: "ACTIONS/RESET_ENTIRE_SR_ACTIONS"
    }
  }
};
export { LOGS };
