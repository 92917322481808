import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import { ApiClient } from "@timhortons/common/src/network/apiClient";

import { productionSheetEndPoints } from "@timhortons/common/src/constants/endPoints";

class ReportsRepository {
  generateReportBuilder = async (param: {
    object: any;
    reportType: string;
    countryCodes?: any;
    allSelected?: boolean;
    prodSheetType: string;
  }) => {
    const { object, reportType, prodSheetType, countryCodes, allSelected } = param;
    if (reportType === "DETAILED") {
      let resp = await ApiClient.post(
        productionSheetEndPoints.reports.genReportBuilderDetailed(prodSheetType, countryCodes),
        object,
        null,
        LPKeys.LP_REPORT_BUILDER
      );

      return resp;
    } else {
      let resp = await ApiClient.post(
        productionSheetEndPoints.reports.genReportBuilderComparision(
          prodSheetType,
          countryCodes,
          allSelected
        ),
        object,
        null,
        LPKeys.LP_REPORT_BUILDER
      );

      return resp;
    }
  };
}
const reportsRepository = new ReportsRepository();
export { reportsRepository as ReportsRepository };
