import { IAM } from "@timhortons/common/src/modules/iam/redux/actions/types";
import { Roles } from "@timhortons/common/src/constants/roles";
import { ForgotPasswordForm } from "@timhortons/common/src/modules/iam/forgotPassword";
import { IFluxStandardAction } from "store/interfaces";

export interface IamLoading {
  login: boolean;
  forgotPassword: boolean;
  masterModules: boolean;
}
export interface IamError {
  login: IFluxStandardAction<Error>;
  forgotPassword: IFluxStandardAction<Error>;
  masterModules: IFluxStandardAction<Error>;
}

export interface IUserDetails {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  userName: string;
  storeCode: number;
  roles: Roles[];
  empId: string;
  storeName: string;
}
export interface IamState {
  loading: IamLoading;
  error: IamError;
  userDetails: IUserDetails;
  userName: ForgotPasswordForm;
  masterModules: Array<string>;
}
const initailIamState: IamState = {
  loading: { login: false, forgotPassword: false, masterModules: false },
  error: { login: null, forgotPassword: null, masterModules: null },
  userDetails: null,
  userName: null,
  masterModules: null
};
const iamReducer = (state = initailIamState, action: { payload: any; type: string }): IamState => {
  switch (action.type) {
    case IAM.LOGIN.LOADING: {
      return { ...state, loading: { ...state.loading, login: true } };
    }
    case IAM.LOGIN.SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, login: false },
        error: { ...state.error, login: null },
        userDetails: action.payload
      };
    }
    case IAM.LOGIN.ERROR: {
      return {
        ...state,
        loading: { ...state.loading, login: false },
        error: { ...state.error, login: action.payload }
      };
    }
    case IAM.FORGOT_PASSWORD.LOADING: {
      return { ...state, loading: { ...state.loading, forgotPassword: true } };
    }
    case IAM.FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, forgotPassword: false },
        error: { ...state.error, forgotPassword: null },
        userName: action.payload
      };
    }
    case IAM.FORGOT_PASSWORD.ERROR: {
      return {
        ...state,
        loading: { ...state.loading, forgotPassword: false },
        error: { ...state.error, forgotPassword: action.payload }
      };
    }
    case IAM.MASTER_MODULES.LOADING: {
      return {
        ...state,
        loading: { ...state.loading, masterModules: true },
        error: { ...state.error, masterModules: null },
        masterModules: null
      };
    }
    case IAM.MASTER_MODULES.ERROR: {
      return {
        ...state,
        loading: { ...state.loading, masterModules: false },
        error: { ...state.error, masterModules: action.payload }
      };
    }
    case IAM.MASTER_MODULES.SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, masterModules: false },
        error: { ...state.error, masterModules: null },
        masterModules: []
        // masterModules: action.payload // TODO -  uncomment to use feature API in navbar
      };
    }
    default:
      return state;
  }
};
export default iamReducer;
