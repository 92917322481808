import { call, put, takeLatest } from "redux-saga/effects";
import { Logger } from "@timhortons/common/src/services/logger";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LMS_REPORTS } from "@timhortons/common/src/redux/lmsReports/actions/actionTypes";
import {
  LMSReportBuilderResponseModel,
  ReportsTableDetailsModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";
import { DetailedLmsReportsRepository } from "@timhortons/common/src/repositories/lmsReports/detailedLmsReports";
import { DetailedLmsReportsActions } from "@timhortons/common/src/redux/lmsReports/actions/detailedLmsReportsActions";
import { AllModulesDataModel } from "@timhortons/common/src/models/lmsReports/lmsReportsModel";

import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

function* getAllModulesWorkerSaga(
  value: IFluxStandardAction<{
    trainingType: string;
    pageNumber: number;
    pageSize: number;
    isPracticalStatus: boolean;
    search: string;
  }>
) {
  const { payload } = value;
  try {
    const response: AllModulesDataModel[] = yield call(
      DetailedLmsReportsRepository.getAllModules,
      payload
    );
    yield put(DetailedLmsReportsActions.getAllModulesSuccess(response));
  } catch (error) {
    Logger.error("LMS-Reports Table Details", error, "get all modules");

    yield put(DetailedLmsReportsActions.getAllModulesError(error));
  }
}

function* generateReportBuilderWorkerSaga(
  value: IFluxStandardAction<{
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    moduleSearchKeyword?: string;
    allSelected?: boolean;
    pageNum: number;
    modulesAllSelected: boolean;
    moduleType: string;
    // updateTableName: ComparsionLevel;
    dataType: string;
  }>
) {
  const { payload } = value;

  try {
    const response: LMSReportBuilderResponseModel = yield call(
      DetailedLmsReportsRepository.generateReportBuilder,
      payload
    );

    yield put(DetailedLmsReportsActions.generateReportBuilderSuccess(response));
  } catch (error) {
    Logger.error("LMS/reports", error, "generateReportBuilderWorkerSaga");

    yield put(
      DetailedLmsReportsActions.generateReportBuilderError({
        error
      })
    );
  }
}
function* detailedLmsReportsWatcherSaga() {
  yield takeLatest(LMS_REPORTS.detailedLms.GET_ALL_MODULES.LOADING, getAllModulesWorkerSaga);
  yield takeLatest(
    LMS_REPORTS.detailedLms.GENERATE_REPORT.LOADING,
    generateReportBuilderWorkerSaga
  );
}

export default detailedLmsReportsWatcherSaga;
