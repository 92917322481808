import { LMS_REPORTS } from "@timhortons/common/src/redux/lmsReports/actions/actionTypes";
import { ReportsTableDetailsModel } from "@timhortons/common/src/models/lmsReports/lmsReportsModel";
import { resEmpFormatter } from "@timhortons/common/src/redux/lmsReports/reducers/builderLMSFormatter";
import { ComparsionLevel } from "@timhortons/common/src/modules/reportBuilders/components/lms";

export interface IDetailedLmsReportsLoading {
  getAllModules: boolean;
  generatedReportBuilderResponse: boolean;
  resEmpLoading: Array<string | number>;
}
export interface IDetailedLmsReportsError {
  getAllModules: string;
  generatedReportBuilderResponse: string;
  resEmpError: Array<string | number>;
}
export interface IDetailedLmsReportsState {
  loading: IDetailedLmsReportsLoading;
  error: IDetailedLmsReportsError;
  getAllModules: ReportsTableDetailsModel;
  generatedReportBuilderResponse: {
    reportType: string;
    startDate: string;
    endDate: string;
    reportData: any[];
    countryLabel: string;
    dataType: string;
    dependentDataType: string;
  };
}

export const lmsReportBuilderInitialState: IDetailedLmsReportsState = {
  error: {
    generatedReportBuilderResponse: null,
    getAllModules: null,
    resEmpError: []
  },
  loading: {
    generatedReportBuilderResponse: false,
    getAllModules: false,
    resEmpLoading: []
  },
  generatedReportBuilderResponse: {
    reportType: null,
    startDate: null,
    endDate: null,
    reportData: null,
    countryLabel: null,
    dataType: null,
    dependentDataType: null
  },
  getAllModules: null
};

export const detailedLmsReportsReducer = (
  state = lmsReportBuilderInitialState,
  action: { payload: any; type: string }
): IDetailedLmsReportsState => {
  switch (action.type) {
    case LMS_REPORTS.detailedLms.GET_ALL_MODULES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllModules: true },
        error: { ...state.error, getAllModules: null }
      };
    case LMS_REPORTS.detailedLms.GET_ALL_MODULES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllModules: false },
        error: { ...state.error, getAllModules: null },
        getAllModules: action.payload
      };
    case LMS_REPORTS.detailedLms.GET_ALL_MODULES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllModules: false },
        error: { ...state.error, getAllModules: action.payload }
      };
    //reducer to get emp detailed lms reports
    case LMS_REPORTS.detailedLms.GENERATE_REPORT.LOADING:
      let tempAllLoad = false;
      let tempArrLoad: Array<string | number> = [];
      if (
        action.payload?.object?.levelOfReport === ComparsionLevel.RestaurantEmployees &&
        state?.generatedReportBuilderResponse?.reportType !== null
      ) {
        if (action.payload?.object?.idList?.length > 0)
          tempArrLoad = [...action.payload.object.idList];
      } else {
        tempAllLoad = true;
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          generatedReportBuilderResponse: tempAllLoad,
          resEmpLoading: tempArrLoad
        },
        error: { ...state.error, generatedReportBuilderResponse: null }
      };
    case LMS_REPORTS.detailedLms.GENERATE_REPORT.SUCCESS:
      let data = { ...state.generatedReportBuilderResponse };
      const { metaData, apiResponse } = action.payload;
      data.reportType = metaData.reportType;
      data.startDate = metaData.startDate;
      data.endDate = metaData.endDate;
      data.countryLabel = metaData.countryLabel;
      data.dataType = metaData.dataType;
      data.dependentDataType = metaData.dependentDataType;
      data.reportData = apiResponse;
      if (
        apiResponse?.content[0]?.content &&
        apiResponse?.content[0]?.content[0]?.label === "By Restaurant Employees"
      ) {
        data.reportData = resEmpFormatter(state.generatedReportBuilderResponse, apiResponse);
      }
      return {
        ...state,
        loading: { ...state.loading, generatedReportBuilderResponse: false, resEmpLoading: [] },
        generatedReportBuilderResponse: data
      };
    case LMS_REPORTS.detailedLms.GENERATE_REPORT.ERROR:
      let tempIds = [...state.loading.resEmpLoading];
      return {
        ...state,
        loading: { ...state.loading, generatedReportBuilderResponse: false, resEmpLoading: [] },
        error: {
          ...state.error,
          generatedReportBuilderResponse: action.payload,
          resEmpError: tempIds
        }
      };
    case LMS_REPORTS.detailedLms.RESET_REPORT_BUILDER:
      state = lmsReportBuilderInitialState;
      return state;

    default:
      return state;
  }
};
