import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import NavLinkButton from "@timhortons/common/src/components/atoms/navLinkButton";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { History } from "history";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";

export interface INavLinkOption {
  key: string;
  path: string;
  param: string;
  title: string;
  active: boolean;
  root: string;
}

interface IProps {
  navObjects: INavLinkOption[];
  history: History;
  setCurrentComponent: Function;
  containerStyle: StyleProp<ViewStyle>;
}

export default function NavButtons(props: IProps) {
  const { navObjects, history, setCurrentComponent, containerStyle } = props;
  return (
    <View style={[styles.container, containerStyle]}>
      {navObjects.map((item: INavLinkOption, i) => {
        return (
          <View
            style={i === navObjects.length - 1 ? styles.navLinkLastChild : styles.navlink}
            key={i}
          >
            <NavLinkButton
              root={item.root}
              key={item.key}
              activeStyles={styles.activeStyles}
              defaultStyles={styles.defaultStyle}
              to={item.path}
              param={item.param}
              history={history}
              active={item.active}
              handleNavClick={(path: Pathnames) => {
                setCurrentComponent(path);
              }}
            >
              <Text
                testId={item.key}
                textStyle={
                  item.active ? { color: colorPallete.red1 } : { color: colorPallete.black3 }
                }
                textSize={TextSize.Medium}
                fontWeight={FontWeight.Regular}
              >
                {item.title}
              </Text>
            </NavLinkButton>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  navlink: {
    flex: 1,
    ...I18nService.select({
      rtl: { marginEnd: 30 },
      ltr: { marginEnd: 30 }
    })
    // marginEnd: 30
  },
  navLinkLastChild: {
    flex: 1,
    ...I18nService.select({
      rtl: { marginEnd: 0 },
      ltr: { marginEnd: 0 }
    })
    // marginEnd: 0
  },
  activeStyles: {
    borderBottomWidth: 1,
    borderBottomColor: colorPallete.red1,
    backgroundColor: colorPallete.variant3,
    height: 54,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8
  },
  defaultStyle: {
    justifyContent: "center",
    alignItems: "center",
    color: colorPallete.black3,
    height: 54,
    backgroundColor: colorPallete.white4,
    borderRadius: 8
  }
});
