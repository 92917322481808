import { Roles } from "@timhortons/common/src/constants/roles";

export const checkAllowedRoles = (currentUserRoles: Roles[]): boolean => {
  const allowedRoles: Roles[] = [
    // Roles.Admin,
    Roles.AreaManager,
    Roles.SuperAdmin,
    Roles.TrainingAndDevManager,
    Roles.TrainingAndDevLead,
    Roles.CEO,
    Roles.COO,
    Roles.HrAndAdminDirector,
    Roles.ProductionAndDevExecutive,
    Roles.AreaTrainer,
    Roles.ChannelManager,
    Roles.CountryManager,
    Roles.ClusterHeadofOps,
    Roles.OperationManager,
    Roles.SeniorOpsManager,
    Roles.InternalAuditor,
    Roles.HeadLP,
    Roles.AuditorLP
  ];

  return currentUserRoles && currentUserRoles.some((item: Roles) => allowedRoles.includes(item));
};
