import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import {
  LpAcknowledgementModel,
  LpReportsDataModal,
  LpReportTableResponseModel,
  LpStoreAccessSummaryDataModel,
  LpIndividualAccessSummaryDataModel,
  LpReportsSummaryOpportunityDataModel,
  AllAreaManagersListDataModel,
  KPIDataModel
} from "@timhortons/common/src/models/lp/reports";
import { SelectedMonthFilter } from "@timhortons/common/src/modules/lp/components/dashboard";
import { monthsGregorian } from "@timhortons/common/src/components/molecules/customsCalenderPickers";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";
import { currMonthStartEndDate } from "@timhortons/common/src/utils/dateFormat";
import { StringIterator } from "lodash";

export interface ILoadingGenrateReport {
  all: boolean;
  [ComparsionLevel.Country]: boolean;
  [ComparsionLevel.Channels]: boolean;
  [ComparsionLevel.CountryManager]: boolean;
  [ComparsionLevel.OpsManager]: boolean;
  [ComparsionLevel.AreaManager]: boolean;
  [ComparsionLevel.Stores]: boolean;
  [ComparsionLevel.LpAuditor]: boolean;
  [ComparsionLevel.ClusterHeadOfOps]: boolean;
}

export interface IErrorGenrateReport {
  [ComparsionLevel.Country]: string;
  [ComparsionLevel.Channels]: string;
  [ComparsionLevel.CountryManager]: string;
  [ComparsionLevel.OpsManager]: string;
  [ComparsionLevel.AreaManager]: string;
  [ComparsionLevel.Stores]: string;
  [ComparsionLevel.LpAuditor]: string;
  [ComparsionLevel.ClusterHeadOfOps]: string;
}
export interface ILpReportsLoading {
  submitAudit: boolean;
  lpReportsData: boolean;
  lpAcknowledgementData: boolean;
  postLpAcknowledgement: boolean;
  lpReportTableData: boolean;
  lpStoreAccessSummaryData: boolean;
  lpIndividualAccessSummaryData: boolean;
  lpStoreAccessOpportunityData: boolean;
  getAllCountries: boolean;
  getAllAreaManager: boolean;
  getAllOpsManager: Boolean;
  getAllChannels: boolean;
  getAllStores: boolean;
  getAllCountryManager: boolean;
  comparisonCountryManagerAdmin: boolean;
  comparisonAreaManagerAdmin: boolean;
  comparisonStoresAdmin: boolean;
  highLowChannelsAdmin: boolean;
  generatedReportBuilderResponse: ILoadingGenrateReport;
  gradingTableData: boolean;
  brandStandardTableData: boolean;
  areaOfImprovementBuilder: boolean;
  getKPIData: boolean;
  lpIndividualAccessSummarySRTableData: boolean;
  countryTotalAuditsData: boolean;
  lpAuditorTotalAuditsData: boolean;
  areaManagerTotalAuidtsData: boolean;
}
export interface ILpReportsError {
  submitAudit: string;
  lpReportsData: string;
  lpAcknowledgementData: string;
  postLpAcknowledgement: string;
  lpReportTableData: string;
  lpStoreAccessSummaryData: string;
  lpIndividualAccessSummaryData: string;
  lpStoreAccessOpportunityData: string;
  getAllCountries: string;
  getAllAreaManager: string;
  getAllOpsManager: string;
  getAllChannels: string;
  getAllStores: string;
  getAllCountryManager: string;
  comparisonCountryManagerAdmin: string;
  comparisonAreaManagerAdmin: string;
  comparisonStoresAdmin: string;
  highLowChannelsAdmin: string;
  generatedReportBuilderResponse: IErrorGenrateReport;
  gradingTableData: string;
  brandStandardTableData: string;
  areaOfImprovementBuilder: string;
  getKPIData: string;
  lpIndividualAccessSummarySRTableData: string;
  countryTotalAuditsData: string;
  areaManagerTotalAuidtsData: string;
  lpAuditorTotalAuditsData: String;
}
export interface ILpReportsState {
  error: ILpReportsError;
  lpMonthFilter: { fromDate: Date; toDate: Date };
  lpCountryFilter: string;
  loading: ILpReportsLoading;
  lpReportsData: LpReportsDataModal;
  lpStoreAccessSummaryData: LpStoreAccessSummaryDataModel;
  lpStoreAccessOpportunityData: LpReportsSummaryOpportunityDataModel;
  getAllAreaManager: AllAreaManagersListDataModel;
  getAllOpsManager: any;
  getAllChannels: ChannelsListDataModel;
  getAllStores: AllStoresListDataModel;
  getAllCountryManager: AllAreaManagersListDataModel;
  getAllCountries: CountryDataListModel;
  reportGenerated: {
    auditId: string;
    reportId: null | number;
  };
  lpAcknowledgementData: LpAcknowledgementModel;
  postLpAcknowledgement: any;
  lpReportTableData: LpReportTableResponseModel;
  lpIndividualAccessSummaryData: LpIndividualAccessSummaryDataModel;
  comparisonCountryManagerAdmin: any;
  comparisonAreaManagerAdmin: any;
  comparisonStoresAdmin: any;
  highLowChannelsAdmin: any;
  generatedReportBuilderResponse: {
    reportType: string;
    startDate: string;
    endDate: string;
    reportData: any[];
    countryLabel: string;
    selectedDateRange?: string;
  };
  countryTotalAuditsData: any;
  areaManagerTotalAuidtsData: any;
  lpAuditorTotalAuditsData: any;
  gradingTableData: any;
  brandStandardTableData: any;
  areaOfImprovementBuilder: any;
  kpiDataList: KPIDataModel[];
}

export const lpReportsInitialState: ILpReportsState = {
  reportGenerated: null,
  lpReportsData: null,
  lpMonthFilter: {
    fromDate: currMonthStartEndDate().startDate,
    toDate: new Date()
  },
  lpCountryFilter: null,
  // lpMonthFilter: {
  //   year: new Date().getFullYear(),
  //   month: {
  //     label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
  //     id: new Date().getMonth()
  //   }
  // },
  lpAcknowledgementData: {
    dropDownData: []
  },
  postLpAcknowledgement: null,
  lpReportTableData: null,
  lpStoreAccessSummaryData: null,
  getAllCountries: null,
  getAllAreaManager: null,
  getAllOpsManager: null,
  getAllStores: null,
  getAllChannels: null,
  getAllCountryManager: null,
  comparisonCountryManagerAdmin: null,
  comparisonAreaManagerAdmin: null,
  comparisonStoresAdmin: null,
  highLowChannelsAdmin: null,
  gradingTableData: null,
  brandStandardTableData: null,
  areaOfImprovementBuilder: null,
  countryTotalAuditsData: null,
  areaManagerTotalAuidtsData: null,
  lpAuditorTotalAuditsData: null,
  error: {
    getAllCountries: null,
    getAllAreaManager: null,
    getAllOpsManager: null,
    getAllChannels: null,
    getAllStores: null,
    getAllCountryManager: null,
    submitAudit: null,
    lpReportsData: null,
    lpAcknowledgementData: null,
    postLpAcknowledgement: null,
    lpReportTableData: null,
    lpStoreAccessSummaryData: null,
    lpIndividualAccessSummaryData: null,
    lpStoreAccessOpportunityData: null,
    comparisonCountryManagerAdmin: null,
    comparisonAreaManagerAdmin: null,
    comparisonStoresAdmin: null,
    highLowChannelsAdmin: null,
    generatedReportBuilderResponse: {
      [ComparsionLevel.Country]: null,
      [ComparsionLevel.Channels]: null,
      [ComparsionLevel.CountryManager]: null,
      [ComparsionLevel.OpsManager]: null,
      [ComparsionLevel.AreaManager]: null,
      [ComparsionLevel.Stores]: null,
      [ComparsionLevel.ClusterHeadOfOps]: null,
      [ComparsionLevel.LpAuditor]: null
    },
    gradingTableData: null,
    brandStandardTableData: null,
    areaOfImprovementBuilder: null,
    getKPIData: null,
    lpIndividualAccessSummarySRTableData: null,
    countryTotalAuditsData: null,
    areaManagerTotalAuidtsData: null,
    lpAuditorTotalAuditsData: null
  },
  loading: {
    submitAudit: false,
    lpReportsData: false,
    lpAcknowledgementData: false,
    postLpAcknowledgement: false,
    lpReportTableData: false,
    lpStoreAccessSummaryData: false,
    lpIndividualAccessSummaryData: false,
    lpStoreAccessOpportunityData: false,
    getAllAreaManager: false,
    getAllOpsManager: false,
    getAllCountries: false,
    getAllChannels: false,
    getAllStores: false,
    getAllCountryManager: false,
    comparisonAreaManagerAdmin: false,
    comparisonCountryManagerAdmin: false,
    comparisonStoresAdmin: false,
    highLowChannelsAdmin: false,
    generatedReportBuilderResponse: {
      all: false,
      [ComparsionLevel.Country]: false,
      [ComparsionLevel.Channels]: false,
      [ComparsionLevel.CountryManager]: false,
      [ComparsionLevel.OpsManager]: false,
      [ComparsionLevel.AreaManager]: false,
      [ComparsionLevel.Stores]: false,
      [ComparsionLevel.ClusterHeadOfOps]: false,
      [ComparsionLevel.LpAuditor]: false
    },
    gradingTableData: false,
    brandStandardTableData: false,
    areaOfImprovementBuilder: false,
    countryTotalAuditsData: false,
    areaManagerTotalAuidtsData: false,
    lpAuditorTotalAuditsData: false,
    getKPIData: false,
    lpIndividualAccessSummarySRTableData: false
  },

  lpIndividualAccessSummaryData: null,
  lpStoreAccessOpportunityData: null,
  generatedReportBuilderResponse: {
    reportType: null,
    startDate: null,
    endDate: null,
    reportData: [],
    countryLabel: null,
    selectedDateRange: ""
  },
  kpiDataList: []
};

export const lpReportsReducer = (
  state = lpReportsInitialState,
  action: { payload: any; type: string }
): ILpReportsState => {
  switch (action.type) {
    case LP.SUBMIT_LP_AUDIT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: true },
        error: { ...state.error, submitAudit: null }
      };
    case LP.SUBMIT_LP_AUDIT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: false },
        error: { ...state.error, submitAudit: action.payload }
      };
    case LP.SUBMIT_LP_AUDIT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: false },
        error: { ...state.error, submitAudit: null },
        reportGenerated: action.payload
      };
    case LP.LP_REPORTS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpReportsData: true },
        error: { ...state.error, lpReportsData: null }
      };
    case LP.LP_REPORTS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpReportsData: false },
        lpReportsData: action.payload
      };
    case LP.LP_REPORTS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpReportsData: false },
        error: { ...state.error, lpReportsData: action.payload }
      };
    case LP.LP_ACKNOWLEDGEMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpAcknowledgementData: true },
        error: { ...state.error, lpAcknowledgementData: null }
      };

    case LP.LP_ACKNOWLEDGEMENT_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpAcknowledgementData: false },
        lpAcknowledgementData: action.payload
      };

    case LP.LP_ACKNOWLEDGEMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpAcknowledgementData: false },
        error: { ...state.error, lpAcknowledgementData: action.payload }
      };
    case LP.POST_LP_ACKNOWLEDGEMENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postLpAcknowledgement: true },
        error: { ...state.error, postLpAcknowledgement: null }
      };
    case LP.POST_LP_ACKNOWLEDGEMENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postLpAcknowledgement: false },
        postLpAcknowledgement: action.payload
      };
    case LP.POST_LP_ACKNOWLEDGEMENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postLpAcknowledgement: false },
        error: { ...state.error, postLpAcknowledgement: action.payload }
      };
    case LP.GET_LP_REPORT_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpReportTableData: true },
        error: { ...state.error, lpReportTableData: null },
        lpReportTableData: null
      };
    case LP.GET_LP_REPORT_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpReportTableData: false },
        lpReportTableData: action.payload
      };
    case LP.GET_LP_REPORT_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpReportTableData: false },
        error: { ...state.error, lpReportTableData: action.payload }
      };

    case LP.RESET_AUTH_ID:
      return {
        ...state,
        postLpAcknowledgement: null
      };
    case LP.RESET_REPORT_ID:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: false },
        error: { ...state.error, submitAudit: null },
        reportGenerated: null
      };
    case LP.LP_GET_STORE_ACCESS_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpStoreAccessSummaryData: true },
        error: { ...state.error, lpStoreAccessSummaryData: "" }
      };
    case LP.LP_GET_STORE_ACCESS_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpStoreAccessSummaryData: false },
        lpStoreAccessSummaryData: action.payload
      };
    case LP.LP_GET_STORE_ACCESS_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpStoreAccessSummaryData: false },
        error: { ...state.error, lpStoreAccessSummaryData: action.payload }
      };

    case LP.LP_GET_SELECTED_MONTH_FILTER.SUCCESS:
      return {
        ...state,
        lpMonthFilter: action.payload
      };
    case LP.LP_GET_SELECTED_COUNTRY_FILTER.SUCCESS:
      return {
        ...state,
        lpCountryFilter: action.payload
      };

    case LP.GET_LP_INDIVIDUAL_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          lpIndividualAccessSummaryData: true,
          lpIndividualAccessSummarySRTableData: true
        },
        error: { ...state.error, lpIndividualAccessSummaryData: "" }
      };
    case LP.GET_LP_INDIVIDUAL_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpIndividualAccessSummaryData: false },
        lpIndividualAccessSummaryData: action.payload
      };
    case LP.GET_LP_INDIVIDUAL_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpIndividualAccessSummaryData: false },
        error: { ...state.error, lpIndividualAccessSummaryData: action.payload }
      };
    case LP.GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpIndividualAccessSummarySRTableData: true },
        error: { ...state.error, lpIndividualAccessSummarySRTableData: null }
      };
    case LP.GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpIndividualAccessSummarySRTableData: false },
        lpIndividualAccessSummaryData: {
          ...state.lpIndividualAccessSummaryData,
          storeRankingTableData: action.payload
        }
      };
    case LP.GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpIndividualAccessSummarySRTableData: false },
        error: { ...state.error, lpIndividualAccessSummarySRTableData: action.payload }
      };

    case LP.GET_COUNTRY_TOTAL_AUDITS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, countryTotalAuditsData: true },
        error: { ...state.error, countryTotalAuditsData: null }
      };
    case LP.GET_COUNTRY_TOTAL_AUDITS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, countryTotalAuditsData: false },
        countryTotalAuditsData: action.payload
      };
    case LP.GET_COUNTRY_TOTAL_AUDITS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, countryTotalAuditsData: false },
        error: { ...state.error, countryTotalAuditsData: action.payload }
      };

    case LP.GET_LP_AUDITOR_TOTAL_AUDITS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpAuditorTotalAuditsData: true },
        error: { ...state.error, lpAuditorTotalAuditsData: null }
      };
    case LP.GET_LP_AUDITOR_TOTAL_AUDITS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpAuditorTotalAuditsData: false },
        lpAuditorTotalAuditsData: action.payload
      };
    case LP.GET_LP_AUDITOR_TOTAL_AUDITS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpAuditorTotalAuditsData: false },
        error: { ...state.error, lpAuditorTotalAuditsData: action.payload }
      };

    case LP.GET_AREAMANAGER_TOTAL_AUDITS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, areaManagerTotalAuidtsData: true },
        error: { ...state.error, areaManagerTotalAuidtsData: null }
      };
    case LP.GET_AREAMANAGER_TOTAL_AUDITS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, areaManagerTotalAuidtsData: false },
        areaManagerTotalAuidtsData: action.payload
      };
    case LP.GET_AREAMANAGER_TOTAL_AUDITS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, areaManagerTotalAuidtsData: false },
        error: { ...state.error, areaManagerTotalAuidtsData: action.payload }
      };

    case LP.GET_LP_STORE_ACCESS_OPPORTUNITY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpStoreAccessOpportunityData: true },
        error: { ...state.error, lpStoreAccessOpportunityData: "" }
      };
    case LP.GET_LP_STORE_ACCESS_OPPORTUNITY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpStoreAccessOpportunityData: false },
        lpStoreAccessOpportunityData: action.payload
      };
    case LP.GET_LP_STORE_ACCESS_OPPORTUNITY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpStoreAccessOpportunityData: false },
        error: { ...state.error, lpStoreAccessOpportunityData: action.payload }
      };

    case LP.ReportBuilder.GENERATE_REPORT.LOADING:
      let updateTableName: ComparsionLevel = action.payload.updateTableName;
      let loadingReportType: ComparsionLevel = action.payload?.object?.levelOfReport;
      return {
        ...state,
        loading: {
          ...state.loading,
          generatedReportBuilderResponse: {
            ...state.loading.generatedReportBuilderResponse,
            all: updateTableName === null,
            [updateTableName]: true
          }
        },
        error: {
          ...state.error,
          generatedReportBuilderResponse: {
            ...state.error.generatedReportBuilderResponse,
            [loadingReportType]: null
          }
        },
        generatedReportBuilderResponse:
          updateTableName === null
            ? {
                reportType: null,
                startDate: null,
                endDate: null,
                reportData: [],
                countryLabel: null,
                selectedDateRange: ""
              }
            : state.generatedReportBuilderResponse
      };

    case LP.ReportBuilder.GENERATE_REPORT.SUCCESS:
      let currReportType: ComparsionLevel = action?.payload?.metaData?.reportEnum;
      let data = state.generatedReportBuilderResponse;
      data.reportType = action.payload.metaData.reportType;
      data.startDate = action.payload.metaData.startDate;
      data.endDate = action.payload.metaData.endDate;
      data.countryLabel = action.payload.metaData.countryLabel;
      data.selectedDateRange = action.payload.metaData.selectedDateRange;

      let obj = {
        reportLevel: action.payload.metaData.levelOfReport,
        startDate: action.payload.metaData.startDate,
        endDate: action.payload.metaData.endDate,
        data: action.payload.apiResponse,
        reportEnum: currReportType,
        quaterLabel: action.payload.metaData.quaterLabel
      };
      let targetIndex = data.reportData.findIndex(
        (el) =>
          el.reportLevel === obj.reportLevel &&
          el.endDate === obj.endDate &&
          el.startDate === obj.startDate
      );
      targetIndex > -1 ? data.reportData.splice(targetIndex, 1, obj) : data.reportData.push(obj);

      return {
        ...state,
        loading: {
          ...state.loading,
          generatedReportBuilderResponse: {
            all: false,
            [ComparsionLevel.Country]: false,
            [ComparsionLevel.Channels]: false,
            [ComparsionLevel.CountryManager]: false,
            [ComparsionLevel.OpsManager]: false,
            [ComparsionLevel.AreaManager]: false,
            [ComparsionLevel.Stores]: false,
            [ComparsionLevel.ClusterHeadOfOps]: false,
            [ComparsionLevel.LpAuditor]: false
          }
        },
        error: {
          ...state.error,
          generatedReportBuilderResponse: {
            ...state.error.generatedReportBuilderResponse,
            [currReportType]: null
          }
        },
        generatedReportBuilderResponse: data
      };
    case LP.ReportBuilder.GENERATE_REPORT.ERROR:
      const { error, reportType }: { error: string; reportType: ComparsionLevel } = action.payload;
      let err = error ? error : "error";
      return {
        ...state,
        loading: {
          ...state.loading,
          generatedReportBuilderResponse: {
            all: false,
            [ComparsionLevel.Country]: false,
            [ComparsionLevel.Channels]: false,
            [ComparsionLevel.CountryManager]: false,
            [ComparsionLevel.OpsManager]: false,
            [ComparsionLevel.AreaManager]: false,
            [ComparsionLevel.Stores]: false,
            [ComparsionLevel.ClusterHeadOfOps]: false,
            [ComparsionLevel.LpAuditor]: false
          }
        },
        error: {
          ...state.error,
          generatedReportBuilderResponse: {
            ...state.error.generatedReportBuilderResponse,
            [reportType]: err
          }
        }
      };
    case LP.ReportBuilder.RESET_REPORT_BUILDER:
      state = lpReportsInitialState;
      return state;
    case LP.ReportBuilder.GENERATE_GRADING_REPORT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, gradingTableData: true },
        error: { ...state.error, gradingTableData: null }
      };
    case LP.ReportBuilder.GENERATE_GRADING_REPORT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, gradingTableData: false },
        error: { ...state.error, gradingTableData: null },
        gradingTableData: action.payload
      };
    case LP.ReportBuilder.GENERATE_GRADING_REPORT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, gradingTableData: false },
        error: { ...state.error, gradingTableData: action.payload ? action.payload : "error" }
      };
    case LP.ReportBuilder.BRAND_STANDARD_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, brandStandardTableData: true },
        error: { ...state.error, brandStandardTableData: null }
      };
    case LP.ReportBuilder.BRAND_STANDARD_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, brandStandardTableData: false },
        error: { ...state.error, brandStandardTableData: null },
        brandStandardTableData: action.payload
      };
    case LP.ReportBuilder.BRAND_STANDARD_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, brandStandardTableData: false },
        error: { ...state.error, brandStandardTableData: action.payload ? action.payload : "error" }
      };
    case LP.ReportBuilder.GENERATE_AREA_OF_IMPROVEMENT_REPORT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, areaOfImprovementBuilder: true },
        error: { ...state.error, areaOfImprovementBuilder: null }
      };
    case LP.ReportBuilder.GENERATE_AREA_OF_IMPROVEMENT_REPORT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, areaOfImprovementBuilder: false },
        error: { ...state.error, areaOfImprovementBuilder: null },
        areaOfImprovementBuilder: action.payload
      };
    case LP.ReportBuilder.GENERATE_AREA_OF_IMPROVEMENT_REPORT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, areaOfImprovementBuilder: false },
        error: {
          ...state.error,
          areaOfImprovementBuilder: action.payload ? action.payload : "error"
        }
      };
    default:
      return state;
  }
};
