import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LMS_REPORTS } from "@timhortons/common/src/redux/lmsReports/actions/actionTypes";
import {
  LMSEmpDetailedReport,
  ReportsTableDetailsModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";

const getReportsTableDetails = (data: {
  pageNumber: number;
  pageSize: number;
  roleId: string;
  search: string;
  idList?: [string];
}): IFluxStandardAction<{
  pageNumber: number;
  pageSize: number;
  roleId: string;
  search: string;
  idList?: [string];
}> => {
  return {
    type: LMS_REPORTS.GET_REPORTS_TABLE_DETAILS.LOADING,
    payload: data
  };
};
const getReportsTableDetailsSuccess = (
  data: ReportsTableDetailsModel[]
): IFluxStandardAction<ReportsTableDetailsModel[]> => {
  return {
    type: LMS_REPORTS.GET_REPORTS_TABLE_DETAILS.SUCCESS,
    payload: data
  };
};
const getReportsTableDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LMS_REPORTS.GET_REPORTS_TABLE_DETAILS.ERROR,
    payload: error
  };
};
const getEmpDetailedReport = (data: { empId: string }): IFluxStandardAction<{ empId: string }> => {
  return {
    type: LMS_REPORTS.GET_EMP_DETAILED_REPORT.LOADING,
    payload: data
  };
};
const getEmpDetailedReportSuccess = (
  data: LMSEmpDetailedReport
): IFluxStandardAction<LMSEmpDetailedReport> => {
  return {
    type: LMS_REPORTS.GET_EMP_DETAILED_REPORT.SUCCESS,
    payload: data
  };
};
const getEmpDetailedReportError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LMS_REPORTS.GET_EMP_DETAILED_REPORT.ERROR,
    payload: error
  };
};
export const LmsReportsActions = {
  getReportsTableDetails,
  getReportsTableDetailsSuccess,
  getReportsTableDetailsError,
  getEmpDetailedReport,
  getEmpDetailedReportSuccess,
  getEmpDetailedReportError
};
