import { NewsCategoryListModel } from "@timhortons/common/src/models/manageNews/manageNews";
export const categoryListFormatter = async (
  response: any
): Promise<Array<NewsCategoryListModel>> => {
  let formattedData: Array<NewsCategoryListModel> = [];

  response &&
    response.map((item: any) => {
      let data: NewsCategoryListModel = {
        label: "",
        value: ""
      };
      data.label = item.categoryName;
      data.value = item.categoryName;
      formattedData.push(data);
    });
  return formattedData;
};
