import {
  GetTimbitCollection,
  TimbitCollectionModel
} from "@timhortons/common/src/models/userLms/userLms";
import { call, put } from "redux-saga/effects";
import { UserLmsRepository } from "@timhortons/common/src/repositories/userLms/userLms";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import { takeLatest } from "redux-saga/effects";
import {
  DayEvents,
  FCMTokenDetails,
  UserLMSAction
} from "@timhortons/common/src/redux/userLms/actions/userLms";
import {
  LeaderboardDataListModel,
  LeaderboardRequestModel
} from "@timhortons/common/src/models/userLms/userLms";

function* getEvents(value: IFluxStandardAction<{ type: string }>) {
  try {
    const { payload } = value;
    const response: any = yield call(UserLmsRepository.getEvents, payload);
    yield put(UserLMSAction.getEventsSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.getEventsError(error));
  }
}

function* getEventDetail(value: IFluxStandardAction<{ type: string }>) {
  try {
    const { payload } = value;
    const response: any = yield call(UserLmsRepository.getEventDetail, payload);
    yield put(UserLMSAction.getEventDetailSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.getEventDetailError(error));
  }
}

function* getDayEvents(value: IFluxStandardAction<DayEvents>) {
  try {
    const { payload } = value;
    const response = yield call(UserLmsRepository.getDayEvents, payload);
    yield put(UserLMSAction.getDayEventsSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.getDayEventsError(error));
  }
}

function* getLeaderboardList(value: IFluxStandardAction<LeaderboardRequestModel>) {
  try {
    const { payload } = value;
    const response: LeaderboardDataListModel = yield call(
      UserLmsRepository.getLeaderboardList,
      payload
    );
    yield put(UserLMSAction.getLeaderboardListSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.getLeaderboardListError(error));
  }
}

function* getTimbitCollectionWorkerSaga(value: IFluxStandardAction<GetTimbitCollection>) {
  try {
    const { payload } = value;
    const response: TimbitCollectionModel = yield call(
      UserLmsRepository.getTimbitCollection,
      payload
    );
    yield put(UserLMSAction.getTimbitCollectionSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.getTimbitCollectionError(error));
  }
}

function* getUserNotifications(value: IFluxStandardAction<{ employeeId: string }>) {
  try {
    const { payload } = value;
    const response: any = yield call(UserLmsRepository.getUserNotification, payload);
    yield put(UserLMSAction.getNotificationsSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.getNotificationsError(error));
  }
}

function* saveFCMToken(value: IFluxStandardAction<FCMTokenDetails>) {
  try {
    const { payload } = value;
    const response: any = yield call(UserLmsRepository.saveUserFCMToken, payload);
    yield put(UserLMSAction.saveUserFCMTokenSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.saveUserFCMTokenError(error));
  }
}

function* moreMobileWatherSaga() {
  yield takeLatest(UserLMS.GET_EVENTS.LOADING, getEvents);
  yield takeLatest(UserLMS.GET_EVENT_DETAIL.LOADING, getEventDetail);
  yield takeLatest(UserLMS.GET_DAY_EVENTS.LOADING, getDayEvents);
  yield takeLatest(UserLMS.GET_LEADERBOARD_LIST.LOADING, getLeaderboardList);
  yield takeLatest(UserLMS.GET_TIMBIT.LOADING, getTimbitCollectionWorkerSaga);
  yield takeLatest(UserLMS.GET_USER_NOTIFICATIONS.LOADING, getUserNotifications);
  yield takeLatest(UserLMS.SAVE_USER_NOTIFICATION.LOADING, saveFCMToken);
}

export default moreMobileWatherSaga;
