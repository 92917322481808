import { Platform } from "react-native";
import PlatformUtils from "./platformUtils";

export async function webDownload(fileName: string, uri: string) {
  try {
    var link = document.createElement("a");
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.setAttribute("download", fileName);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
    return Promise.resolve("Dowloaded Successfully");
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function rnDownload(fileName: string, uri: string, headers?: HeadersInit) {
  try {
    var RNFetchBlob = require("rn-fetch-blob").default;
    const { config, fs } = RNFetchBlob;
    let downloadDir = PlatformUtils.isIOS() ? fs.dirs.DocumentDir : fs.dirs.DownloadDir;
    let fullPath = downloadDir + "/" + fileName;
    const configOptions = Platform.select({
      ios: {
        fileCache: true,
        path: fullPath,
        // mime: 'application/xlsx',
        // appendExt: 'xlsx',
        //path: filePath,
        //appendExt: fileExt,
        notification: true
      },
      android: {
        fileCache: false,
        addAndroidDownloads: {
          useDownloadManager: true,
          notification: true,
          path: fullPath,
          description: "Downloading File..."
        }
      }
    });
    let file = await config(configOptions).fetch("GET", uri, {
      ...headers
    });
    if (PlatformUtils.isIOS()) {
      // setTimeout(() => {
      try {
        RNFetchBlob.ios.openDocument(file.data);
      } catch (err) {}
      // }, 300);
    }
    return Promise.resolve("Dowloaded Successfully");
    // config(configOptions)
    //     .fetch("GET", uri)
    //     .then((res: any) => {
    //         if (PlatformUtils.isIOS()) {
    //            await setTimeout(() => {
    //                     RNFetchBlob.ios.openDocument(res.aaaa.data);
    //             }, 300);
    //         } else {
    //             return Promise.resolve("Dowloaded Successfully");
    //         }
    //     })
    //     .catch((err: any) => Promise.reject(JSON.stringify(err)));
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
}
