import * as React from "react";
import { StyleSheet, View, StyleProp, ViewStyle, TextStyle } from "react-native";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { Orientations } from "@timhortons/common/src/components/atoms/divider";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";

interface IProps {
  children?: React.ReactNode;
  buttonLists?: Array<IButtonListOption>;
  iconButtons?: boolean;
  disabled?: boolean;
  buttonsType?: ButtonType;
  orientation?: Orientations;
  containerStyle?: StyleProp<ViewStyle>;
  buttonStyles?: StyleProp<ViewStyle>;
  textStyles?: StyleProp<TextStyle>;
  textSize?: TextSize;
  fontWeight?: FontWeight;
  iconStyles?: StyleProp<TextStyle>;
}
export interface IButtonListOption {
  id: string;
  title?: string;
  iconName?: IconNames;
  iconSize?: number;
  onPress?: (id: string) => void;
  buttonStyle?: StyleProp<ViewStyle>;
  iconStyles?: StyleProp<TextStyle>;
  textStyles?: StyleProp<TextStyle>;
  disabled?: boolean;
}

const getContainerStyle = (orientation: Orientations, containerStyle = {}): ViewStyle => {
  let themedStyle: StyleProp<ViewStyle>;
  if (orientation === Orientations.Horizontal) {
    themedStyle = styles.horizontal;
  } else {
    themedStyle = styles.vertical;
  }
  return StyleSheet.flatten([styles.container, themedStyle, containerStyle]);
};

export const ButtonGroup = (props: IProps): React.ReactElement => {
  const {
    containerStyle = {},
    disabled = false,
    children,
    buttonsType = ButtonType.Primary,
    orientation = Orientations.Horizontal,
    buttonLists = [],
    textStyles = {},
    textSize = TextSize.Regular,
    fontWeight = FontWeight.Regular,
    iconStyles = {},
    buttonStyles
  } = props;

  const style = getContainerStyle(orientation, containerStyle);

  return (
    <View style={style}>
      {buttonLists.map((item, index) => (
        <Button
          type={buttonsType}
          onPress={() => item.onPress && item.onPress(item.id)}
          disabled={disabled || item.disabled}
          textStyles={[textStyles, item.textStyles]}
          iconStyles={[iconStyles, item.iconStyles]}
          title={item.title}
          textSize={textSize}
          fontWeight={fontWeight}
          iconName={item.iconName}
          iconSize={item.iconSize}
          key={index + 1}
          buttonStyles={[styles.button, buttonStyles, item.buttonStyle]}
        />
      ))}
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  horizontal: {
    flexDirection: "row"
  },
  vertical: {
    flexDirection: "column"
  },
  button: {
    borderWidth: 1,
    borderColor: colorPallete.red1
  }
});
