import { call, put, takeLatest } from "redux-saga/effects";
import { MANAGEASK } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  AskTellQuestionCountModel,
  AskTellGetQuestionByStatusModel,
  QuestionsListModel,
  AskTellSupportTicketModel
} from "@timhortons/common/src/models/askTell/manageAskModule";
import { Logger } from "@timhortons/common/src/services/logger";
import { SupportTicketRepository } from "@timhortons/common/src/repositories/askTell/suppportTicket";
import { SupportTicketsModuleAction } from "@timhortons/common/src/redux/askTell/actions/supportTicketsModuleActions";

function* getQuestionCountWorkerSaga() {
  try {
    const response: AskTellQuestionCountModel = yield call(
      SupportTicketRepository.getQuestionCount
    );
    yield put(SupportTicketsModuleAction.getQuestionCountSuccess(response));
  } catch (error) {
    Logger.error("Manage Ask/Tell - Support Ticket", error, "getQuestionCountWorkerSaga");

    yield put(SupportTicketsModuleAction.getQuestionCountError(error));
  }
}

function* getQuestionByStatusWorkerSaga(
  value: IFluxStandardAction<AskTellGetQuestionByStatusModel>
) {
  const { payload } = value;
  try {
    const response: QuestionsListModel = yield call(
      SupportTicketRepository.getQuestionById,
      payload
    );
    yield put(SupportTicketsModuleAction.getQuestionByStatusSuccess(response));
  } catch (error) {
    Logger.error("Manage Ask/Tell - Support Ticket", error, "getQuestionByStatusWorkerSaga");

    yield put(SupportTicketsModuleAction.getQuestionByStatusError(error));
  }
}

function* postAnswerReplyWorkerSaga(value: IFluxStandardAction<AskTellSupportTicketModel>) {
  const { payload } = value;
  try {
    const response: AskTellSupportTicketModel = yield call(
      SupportTicketRepository.postAnswerReply,
      payload
    );
    yield put(SupportTicketsModuleAction.postAnswerReplySuccess(response));
  } catch (error) {
    Logger.error("Manage Ask/Tell - Support Ticket", error, "postAnswerReplyWorkerSaga");

    yield put(SupportTicketsModuleAction.postAnswerReplyError(error));
  }
}

function* updateAnswerReplyWorkerSaga(value: IFluxStandardAction<AskTellSupportTicketModel>) {
  const { payload } = value;
  try {
    const response: AskTellSupportTicketModel = yield call(
      SupportTicketRepository.updateAnswerReply,
      payload
    );
    yield put(SupportTicketsModuleAction.updateAnswerReplySuccess(response));
  } catch (error) {
    Logger.error("Manage Ask/Tell - Support Ticket", error, "updateAnswerReplyWorkerSaga");

    yield put(SupportTicketsModuleAction.updateAnswerReplyError(error));
  }
}

function* supportTicketWatcherSaga() {
  yield takeLatest(MANAGEASK.supportTickets.GET_QUESTION_COUNT.LOADING, getQuestionCountWorkerSaga);
  yield takeLatest(
    MANAGEASK.supportTickets.GET_QUESTION_BY_STATUS.LOADING,
    getQuestionByStatusWorkerSaga
  );
  yield takeLatest(MANAGEASK.supportTickets.POST_ANSWER_REPLY.LOADING, postAnswerReplyWorkerSaga);
  yield takeLatest(
    MANAGEASK.supportTickets.UPDATE_ANSWER_REPLY.LOADING,
    updateAnswerReplyWorkerSaga
  );
}

export default supportTicketWatcherSaga;
