import { call, put, takeLatest } from "redux-saga/effects";
import { Logger } from "@timhortons/common/src/services/logger";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { HomeActions } from "@timhortons/common/src/redux/home/actions/homeActions";
import { HOME } from "../actions/actionTypes";
import { HomeRepository } from "@timhortons/common/src/repositories/home/userLms";

function* getHomePageVideosWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: any = yield call(HomeRepository.getHomeScreenVideos, payload);
    yield put(HomeActions.getHomePageVideosSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getLmsModulesListWorkerSaga");
    yield put(HomeActions.getHomePageVideosError(error));
  }
}

function* homeWatcherSaga() {
  yield takeLatest(HOME.GET_HOME_PAGE_VIDEOS.LOADING, getHomePageVideosWorkerSaga);
}

export default homeWatcherSaga;
