import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { FAQ } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import { FaqModuleActions } from "@timhortons/common/src/redux/askTell/actions/faqModuleActions";
import { Logger } from "@timhortons/common/src/services/logger";
import { FaqModuleRepository } from "@timhortons/common/src/repositories/askTell/faqModule";
import { FaqQuestionModel } from "@timhortons/common/src/models/askTell/faqModule";

function* getAllFaqs() {
  try {
    const response: FaqQuestionModel[] = yield call(FaqModuleRepository.getAllFaqs);
    yield put(FaqModuleActions.getAllFaqsSuccess(response));
  } catch (error) {
    Logger.error("faq", error, "getAllFaqsSuccess");
    yield put(FaqModuleActions.getAllFaqsError(error));
  }
}
function* updateFaq(
  value: IFluxStandardAction<{
    id: string;
    question: string;
    answer: string;
    image: string;
    imageKey: string;
  }>
) {
  try {
    const { payload } = value;
    const response: any = yield call(FaqModuleRepository.updateFaq, payload);
    yield put(FaqModuleActions.updateFaqSuccess(response));
  } catch (error) {
    Logger.error("faq", error, "updateFaq");
    yield put(FaqModuleActions.updateFaqError(error));
  }
}
function* createFaq(
  value: IFluxStandardAction<{ question: string; answer: string; image: string }>
) {
  try {
    const { payload } = value;
    const response: any = yield call(FaqModuleRepository.createFaq, payload);
    yield put(FaqModuleActions.createFaqSuccess(response));
  } catch (error) {
    Logger.error("faq", error, "createFaq");
    yield put(FaqModuleActions.createFaqError(error));
  }
}
function* deleteFaq(value: IFluxStandardAction<{ id: string }>) {
  const { payload } = value;
  try {
    yield call(FaqModuleRepository.deleteFaq, payload);
    yield put(FaqModuleActions.deleteFaqSuccess());
  } catch (error) {
    Logger.error("lp-audits", error, "deleteLpAuditWorkerSaga");
    yield put(FaqModuleActions.deleteFaqError(error));
  }
}
function* faqWatcherSaga() {
  yield takeLatest(FAQ.GET_ALL_FAQ.LOADING, getAllFaqs);
  yield takeLatest(FAQ.UPDATE_FAQ.LOADING, updateFaq);
  yield takeLatest(FAQ.CREATE_FAQ.LOADING, createFaq);
  yield takeLatest(FAQ.DELETE_FAQ.LOADING, deleteFaq);
}

export default faqWatcherSaga;
