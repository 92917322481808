import { UserLMSAction } from "@timhortons/common/src/redux/userLms/actions/userLms";
import {
  LMSContinueLearningModel,
  VideoUpdate
} from "@timhortons/common/src/models/userLms/userLms";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";

export interface VideoUpdateProps {
  videoId: string;
  employeeId: string;
  lastWatchedTime: number;
  topicId: string;
  categoryId: string;
  subTopicId?: string;
  language: string;
}

interface UpdateContinueLearning {
  videoId: string;
  videoName: string;
  playBackTime: number;
  topicId: string;
  categoryId: string;
  subTopicId?: string;
  moduleId?: string;
  moduleName?: string;
  videoUrl?: string;
  status?: string;
}

const getContinueLearningAction = (data: {
  language: string;
  employeeId: string;
}): IFluxStandardAction<{ language: string }> => {
  return {
    type: UserLMS.GET_CONTINUE_LEARNING.LOADING,
    payload: data
  };
};

const getContinueLearningActionSuccess = (
  data: LMSContinueLearningModel
): IFluxStandardAction<LMSContinueLearningModel> => {
  return {
    type: UserLMS.GET_CONTINUE_LEARNING.SUCCESS,
    payload: data
  };
};

const getContinueLearningActionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_CONTINUE_LEARNING.ERROR,
    payload: error
  };
};

const updateVideoStatus = (data: VideoUpdateProps): IFluxStandardAction<VideoUpdateProps> => {
  return {
    type: UserLMS.UPDATE_VIDEO_STATUS.LOADING,
    payload: data
  };
};

const updateVideoStatusSuccess = (data: VideoUpdate): IFluxStandardAction<VideoUpdate> => {
  return {
    type: UserLMS.UPDATE_VIDEO_STATUS.SUCCESS,
    payload: data
  };
};

const updateVideoStatusError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.UPDATE_VIDEO_STATUS.ERROR,
    payload: error
  };
};

// const updateContinueLearning = (data: UpdateContinueLearning): IFluxStandardAction<UpdateContinueLearning> => {

//   return {
//     type:UserLMS.UPDATE_VIDEO_ACTION.LOADING,
//     payload:data
//   }

// }

const updateContinueLearningSuccess = (
  data: UpdateContinueLearning
): IFluxStandardAction<UpdateContinueLearning> => {
  return {
    type: UserLMS.UPDATE_VIDEO_ACTION.SUCCESS,
    payload: data
  };
};

const updateVideoCurrentTime = (data: {
  currentTime: number;
}): IFluxStandardAction<{ currentTime: number }> => {
  return {
    type: UserLMS.UPDATE_VIDEO_CURRENTTIME.SUCCESS,
    payload: data
  };
};

export const MobileVideoActions = {
  getContinueLearningAction,
  getContinueLearningActionSuccess,
  getContinueLearningActionError,
  updateVideoStatus,
  updateVideoStatusSuccess,
  updateVideoStatusError,
  updateContinueLearningSuccess,
  updateVideoCurrentTime
};
