import { combineReducers } from "redux";
import { lpReducer } from "@timhortons/common/src/redux/lp/reducers/index";
import { revReducer } from "@timhortons/common/src/redux/rev/reducers/index";
import { profilesReducer } from "@timhortons/common/src/redux/profile/reducers/index";
import { IFluxStandardAction, IState } from "@timhortons/common/src/store/interfaces";
import iamReducer from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { resourcesReducer } from "@timhortons/common/src/redux/userResources/reducers";
import { logsReducer } from "@timhortons/common/src/redux/logs/reducers";
import { productionSheetReducer } from "@timhortons/common/src/redux/productionSheet/reducers";
import { manageLmsReducer } from "@timhortons/common/src/redux/manageLms/reducers";
import { mobileReducer } from "@timhortons/common/src/redux/userLms/reducers";
import { calendarReducer } from "@timhortons/common/src/redux/calendar/reducers";
import { practicalAssessmentReducer } from "@timhortons/common/src/redux/practicalAssessment/reducers";
import { manageNewsReducer } from "@timhortons/common/src/redux/manageNews/reducers";
import { manageExistingUserReducer } from "@timhortons/common/src/redux/manageExistingUser/reducers";
import { manageAskReducer } from "@timhortons/common/src/redux/askTell/reducers";
import { reportBuilderReducer } from "@timhortons/common/src/redux/reportBuilder/reducers";
import { LmsBuilderReducer } from "@timhortons/common/src/redux/lmsReports/reducers/index";

const appReducer = combineReducers<IState>({
  profile: profilesReducer,
  rev: revReducer,
  iam: iamReducer,
  resources: resourcesReducer,
  logs: logsReducer,
  productionSheet: productionSheetReducer,
  lp: lpReducer,
  manageLms: manageLmsReducer,
  mobile: mobileReducer,
  calendar: calendarReducer,
  lmsReports: LmsBuilderReducer,
  practicalAssessment: practicalAssessmentReducer,
  manageNews: manageNewsReducer,
  manageExistingUser: manageExistingUserReducer,
  manageAsk: manageAskReducer,
  reportBuilder: reportBuilderReducer
});
const rootReducer = (state: IState, action: IFluxStandardAction<any>) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
