import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IDetailedLmsReportsState,
  IDetailedLmsReportsLoading,
  IDetailedLmsReportsError
} from "../reducers/builderLmsReports";

const DetailedlmsReportsSelect = (state: IState): IDetailedLmsReportsState =>
  state.lmsReports.detailedLmsReports;

const detailedLmsReportsLoading: Selector<IState, IDetailedLmsReportsLoading> = createSelector(
  DetailedlmsReportsSelect,
  (data) => data.loading
);

const detailedLmsReportsError: Selector<IState, IDetailedLmsReportsError> = createSelector(
  DetailedlmsReportsSelect,
  (data) => data.error
);

const getAllModules: Selector<IState, any> = createSelector(
  DetailedlmsReportsSelect,
  (data) => data.getAllModules
);
const generatedreportBuilderReponse: Selector<IState, any> = createSelector(
  DetailedlmsReportsSelect,
  (data) => data.generatedReportBuilderResponse
);

export const detailedLmsReportsSelectors = {
  detailedLmsReportsLoading,
  detailedLmsReportsError,
  getAllModules,
  generatedreportBuilderReponse
};
