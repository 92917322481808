import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  SRSubCategoryItemResponseModel,
  StationReadSubCategoryModel
} from "@timhortons/common/src/models/logs/mainLogs/stationReadiness";
import {
  LogShiftModel,
  WeeklyCommentDataModel,
  WeeklyCommentModel
} from "@timhortons/common/src/models/logs/mainLogs";
import {
  ILogShift,
  IProdTempCategories,
  IStationReadCatgeories
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";

const getStationReadCategory = (data: {
  actionName: string;
  storeCode: number;
  date: string;
  label: string;
}): IFluxStandardAction<{ actionName: string; storeCode: number; date: string; label: string }> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_CATEGORIES.LOADING,
    payload: data
  };
};
const getStationReadCategorySuccess = (
  data: IStationReadCatgeories[]
): IFluxStandardAction<IStationReadCatgeories[]> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_CATEGORIES.SUCCESS,
    payload: data
  };
};
const getStationReadCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_CATEGORIES.ERROR,
    payload: error
  };
};
const getStationReadSubCategory = (
  data: IProdTempCategories
): IFluxStandardAction<IProdTempCategories> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SUBCATEGORIES.LOADING,
    payload: data
  };
};
const getStationReadSubCategorySuccess = (
  data: StationReadSubCategoryModel[]
): IFluxStandardAction<StationReadSubCategoryModel[]> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SUBCATEGORIES.SUCCESS,
    payload: data
  };
};
const getStationReadSubCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SUBCATEGORIES.ERROR,
    payload: error
  };
};
const postStationReadinessItems = (
  data: WeeklyCommentModel
): IFluxStandardAction<WeeklyCommentModel> => {
  return {
    type: LOGS.MainLogs.StationRead.POST_STATION_READINESS_ITEMS.LOADING,
    payload: data
  };
};
const postStationReadinessItemsSuccess = (
  data: WeeklyCommentDataModel
): IFluxStandardAction<WeeklyCommentDataModel> => {
  return {
    type: LOGS.MainLogs.StationRead.POST_STATION_READINESS_ITEMS.SUCCESS,
    payload: data
  };
};
const postStationReadinessItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.StationRead.POST_STATION_READINESS_ITEMS.ERROR,
    payload: error
  };
};
const getSReadSubCategoryItems = (data: {
  categoryId: string;
  label: string;
  storeCode: string;
  date: string;
}): IFluxStandardAction<{
  categoryId: string;
  label: string;
  storeCode: string;
  date: string;
}> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SUBCATEGORIES_ITEMS.LOADING,
    payload: data
  };
};
const getSReadSubCategoryItemsSuccess = (data: {
  apiRes: SRSubCategoryItemResponseModel[];
  metaData: { categoryId: string };
}): IFluxStandardAction<{
  apiRes: SRSubCategoryItemResponseModel[];
  metaData: { categoryId: string };
}> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SUBCATEGORIES_ITEMS.SUCCESS,
    payload: data
  };
};
const getSReadSubCategoryItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SUBCATEGORIES_ITEMS.ERROR,
    payload: error
  };
};

const getSRShifts = (data: ILogShift): IFluxStandardAction<ILogShift> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SR_SHIFTS.LOADING,
    payload: data
  };
};
const getSRShiftsSuccess = (data: LogShiftModel[]): IFluxStandardAction<LogShiftModel[]> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SR_SHIFTS.SUCCESS,
    payload: data
  };
};
const getSRShiftsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.StationRead.GET_SR_SHIFTS.ERROR,
    payload: error
  };
};
const resetStationLogs = () => {
  return {
    type: LOGS.MainLogs.StationRead.RESET_STATION_READ_LOGS
  };
};
const resetStationReadEntireState = () => {
  return {
    type: LOGS.MainLogs.StationRead.RESET_STATIONREAD_ENTIRE_LOGS
  };
};

const handleClickedShift = (data: { value: LogShiftModel; categoryId: string }) => {
  return {
    type: LOGS.MainLogs.StationRead.HANDLE_CLICKED_SHIFT,
    payload: data
  };
};
const handleAuthUpdation = (data: { value: any; categoryId: string }) => {
  return {
    type: LOGS.MainLogs.StationRead.HANDLE_AUTH,
    payload: data
  };
};
export const StationReadinessActions = {
  getStationReadCategory,
  getStationReadCategorySuccess,
  getStationReadCategoryError,
  getStationReadSubCategory,
  getStationReadSubCategorySuccess,
  getStationReadSubCategoryError,
  postStationReadinessItems,
  postStationReadinessItemsSuccess,
  postStationReadinessItemsError,
  getSReadSubCategoryItems,
  getSReadSubCategoryItemsSuccess,
  getSReadSubCategoryItemsError,
  getSRShifts,
  getSRShiftsSuccess,
  getSRShiftsError,
  resetStationLogs,
  resetStationReadEntireState,
  handleClickedShift,
  handleAuthUpdation
};
