import { loginForm } from "@timhortons/common/src/modules/iam/login";
import { call, put, takeLatest } from "redux-saga/effects";
import { IamRepository } from "@timhortons/common/src/repositories/iam/login";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { IAM } from "../actions/types";
import { IUserDetails } from "../reducer/reducer";
import { IamActions } from "../actions/actions";
import { ForgotPasswordForm } from "modules/iam/forgotPassword";
function* loginWorkerSaga(action: IFluxStandardAction<loginForm>) {
  try {
    const { payload } = action;
    const response: IUserDetails = yield call(IamRepository.loginPost, payload);
    yield put(IamActions.loginSuccess(response));
  } catch (error) {
    yield put(IamActions.loginError(error));
  }
}

function* forgotPasswordWorkerSaga(action: IFluxStandardAction<ForgotPasswordForm>) {
  try {
    const { payload } = action;
    const response: string = yield call(IamRepository.forgotPasswordPost, payload);
    yield put(IamActions.forgotPasswordSuccess(response));
  } catch (error) {
    yield put(IamActions.forgotPasswordError(error));
  }
}
function* masterModulesWorkerSaga() {
  try {
    const response: Array<string> = yield call(IamRepository.fetchUserMasterModules);
    yield put(IamActions.masterModulesSuccess(response));
  } catch (error) {
    yield put(IamActions.masterModulesError(error));
  }
}

export default function* iamWatcherSaga() {
  yield takeLatest(IAM.LOGIN.LOADING, loginWorkerSaga);
  yield takeLatest(IAM.FORGOT_PASSWORD.LOADING, forgotPasswordWorkerSaga);
  yield takeLatest(IAM.MASTER_MODULES.LOADING, masterModulesWorkerSaga);
}
