export enum TimeTempCategories {
  ProdTemp = "Product Temperature",
  EquipTemp = "Equipment Temperature",
  RecLog = "Receiving Logs"
}
export enum CleaningCategories {
  Daily = "Daily Checklist",
  Weekly = "Weekly Checklist",
  RestRoom = "Restroom Checklist"
}
enum MWTCategories {
  Exterior = "Exterior",
  Dining = "Dining Room",
  Washroom = "Washrooms",
  FrontHouse = "Front of House",
  CofeeExcel = "Coffee Excellence",
  BackHouse = "Back of House"
}
enum LogManagerReviewCategory {
  TimeTemp = "Time & Temp",
  Cleaning = "Cleaning",
  MWT = "Manager Walkthrough"
}
export const getManagerReviewLogId = (category: string, logName: string) => {
  if (category && logName) {
    if (category === LogManagerReviewCategory.TimeTemp) {
      switch (logName) {
        case TimeTempCategories.ProdTemp:
          return "b8b39eb7-f416-4a4f-bdbe-d734a6346743";
        case TimeTempCategories.EquipTemp:
          return "dc2168e4-38a6-416a-81c4-e38226b375c1";
        case TimeTempCategories.RecLog:
          return "8c207518-6955-423e-903d-68b8f3588dc4";
      }
    }
    if (category === LogManagerReviewCategory.Cleaning) {
      switch (logName) {
        case CleaningCategories.Daily:
          return "1";
        case CleaningCategories.Weekly:
          return "2";
        case CleaningCategories.RestRoom:
          return "3";
      }
    }
    if (category === LogManagerReviewCategory.MWT) {
      switch (logName) {
        case MWTCategories.Exterior:
          return "c3e1bf7a-0a2d-11ec-9a03-0242ac130003";
        case MWTCategories.Dining:
          return "c3e1c1b4-0a2d-11ec-9a03-0242ac130003";
        case MWTCategories.Washroom:
          return "c3e1c29a-0a2d-11ec-9a03-0242ac130003";
        case MWTCategories.FrontHouse:
          return "c3e1c358-0a2d-11ec-9a03-0242ac130003";
        case MWTCategories.CofeeExcel:
          return "c3e1c42a-0a2d-11ec-9a03-0242ac130003";
        case MWTCategories.BackHouse:
          return "c3e1c7fe-0a2d-11ec-9a03-0242ac130003";
      }
    }
  }
};
