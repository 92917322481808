import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { LpCategoryDataModel } from "models/lp/audits";

export interface ILpCategoryLoading {
  lpCategoryData: boolean;
}

export interface ILpCategoryError {
  lpCategoryData: string;
}

export interface ILpCategoryState {
  lpCategoryData: LpCategoryDataModel;
  error: ILpCategoryError;
  loading: ILpCategoryLoading;
}

export const categoryInitialState: ILpCategoryState = {
  lpCategoryData: null,
  error: { lpCategoryData: "" },
  loading: { lpCategoryData: false }
};

export const categoryReducer = (
  state = categoryInitialState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case LP.LP_CATEGORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpCategoryData: true },
        error: { ...state.error, lpCategoryData: "" }
      };
    case LP.LP_CATEGORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpCategoryData: false },
        lpCategoryData: action.payload
      };
    case LP.LP_CATEGORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpCategoryData: false },
        error: { ...state.error, lpCategoryData: action.payload }
      };
    case LP.UPDATE_LP_CATEGORY_DATA.SUCCESS:
      let data = state.lpCategoryData.categoryList;
      let index = data.findIndex((item) => item.id === action.payload.categoryId);
      if (index > -1) {
        data[index].progress = action.payload.data.categoryPercentage;
      }
      state.lpCategoryData.categoryList = data;
      return {
        ...state,
        loading: { ...state.loading, lpCategoryData: false },
        lpCategoryData: state.lpCategoryData
      };
    default:
      return state;
  }
};
