import React, { useState, useEffect } from "react";
import {
  Alert,
  StyleProp,
  TextStyle,
  StyleSheet,
  View,
  ScrollView,
  ViewStyle,
  TouchableOpacity
} from "react-native";
import DocumentPicker from "react-native-document-picker";
// import RNDocumentPicker from 'react-native-document-picker';
import { FileModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { TextSize, FontWeight, Text } from "@timhortons/common/src/components/atoms/text";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { Image } from "@timhortons/common/src/components/atoms/image";
import { commentStyles } from "@timhortons/web/src/components/molecules/uploadfile/uploadFile";
import CameraUpload from "./cameraUpload";
import { imgPlaceholder } from "@timhortons/common/src/assets/images/imageAssets";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import RNHeicConverter from "react-native-heic-converter";
import { sleep } from "@timhortons/common/src/utils/timeoutFunctions";
import ProgressiveImage from "@timhortons/common/src/components/molecules/placeholderImage";

interface IProps {
  header?: string;
  containerStyle?: StyleProp<TextStyle>;
  headerStyle?: StyleProp<TextStyle>;
  iconSize?: number;
  iconColor?: string;
  initialFiles?: FileModel[];
  handleFileChange?: Function;
  cancelFiles?: boolean;
  setCancelFiles?: Function;
  commentBoxWidth?: number;
  children?: React.ReactNode;
  hideIcon?: boolean;
  iconStyle?: StyleProp<ViewStyle>;
  isProfile?: boolean;
  fileType?: any;
  isEdit?: boolean;
  deletedImages?: boolean;
}

export const FileTypes = {
  all: DocumentPicker.types.allFiles,
  images: DocumentPicker.types.images,
  wordDocx: DocumentPicker.types.docx
};

const UploadBoxComponent = (props: IProps): React.ReactElement => {
  const [attachments, setAttachment] = useState([]);
  const [showAttachments, setShowAttachments] = useState(false);
  const [allfiles, setAllFiles] = useState([]);
  const {
    initialFiles,
    headerStyle,
    header,
    containerStyle,
    hideIcon = true,
    commentBoxWidth = 600,
    handleFileChange,
    cancelFiles,
    setCancelFiles,
    children,
    iconStyle,
    isProfile,
    fileType = FileTypes.all,
    isEdit = false,
    deletedImages = false
  } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalError, setModalError] = useState<string>("");
  const size = isProfile ? 10 : 18;
  useEffect(() => {
    if (initialFiles) {
      setAttachment(initialFiles);
    }
  }, [initialFiles]);
  useEffect(() => {
    if (attachments?.length === 0) {
      return;
    }
    let newArray = [...allfiles];
    newArray.push(...attachments);
    setAllFiles(newArray);
    handleFileChange && handleFileChange(isProfile ? attachments : newArray);
  }, [attachments]);

  useEffect(() => {
    if (allfiles?.length > 0) {
      setAllFiles([]);
      setAttachment([]);
    }
  }, [deletedImages]);

  useEffect(() => {
    if (cancelFiles) {
      setAllFiles([]);
      setCancelFiles(false);
    }
  }, [cancelFiles]);

  const convertBase64ImgtoBlob = async (base64: string): Promise<Blob> => {
    try {
      let fetchedImg = await fetch(base64);
      let blob = await fetchedImg.blob();
      return Promise.resolve(blob);
    } catch (error) {
      // Logger.debug(JSON.stringify({ error, fn: "convertBase64ImgtoBlob", file: fileName }));
      return Promise.reject(error);
    }
  };

  const convertBlobToFile = async (
    fileDetails: {
      type: string;
      extension: string;
      email: string;
      assignmentId: string;
    },
    blob: Blob
  ): Promise<File> => {
    try {
      const file = new File(
        [blob],
        `${fileDetails.email}_${fileDetails.assignmentId}_${
          fileDetails.extension === "jpg" ? "img" : "vid"
        }_${new Date().getTime()}.${fileDetails.extension}`,
        {
          type: fileDetails.type,
          lastModified: Date.now()
        }
      );
      return Promise.resolve(file);
    } catch (error) {
      // Logger.debug(JSON.stringify({ file: fileName, error, fn: "convertBlobToFormData" }));
      return Promise.reject(error);
    }
  };

  const captureDocument = async (): Promise<void> => {
    try {
      let documents = await DocumentPicker.pickMultiple({
        type: [fileType]
      });

      let newArray: FileModel[] = [];
      let newFile: any = null;
      for (let i = 0; i < documents.length; i++) {
        let file: any = documents[i];
        if (PlatformUtils.isIOS() && file.type === "image/heic") {
          newFile = {} as any;
          let result = await RNHeicConverter.convert({
            path: file.uri
            // extension:'base64'
          });
          // let blo11=await convertBase64ImgtoBlob(result.base64);
          // let fileazz=await convertBlobToFile({type:'image/jpg',extension:'jpg',email:'ddrere',assignmentId:'dw32r3'},blo11);
          // let aaa=file.uri.split('/');
          // let nm=aaa[aaa.length-1];
          // let newName=nm.split('.')[0]+'.jpg';
          // aaa[aaa.length-1]=newName;
          // let p=aaa.join('/');

          newFile.fileCopyUri = result.path;
          newFile.name = "filwqeqedxs23er34e.jpeg";
          newFile.size = 88731;
          newFile.type = "image/jpg";
          newFile.uri = result.path;
        }

        newArray.push({
          id: file.name + file.size,
          signedUrl: null,
          blob: PlatformUtils.isIOS() && newFile ? newFile : file
        });
      }
      setAttachment(newArray);
    } catch (e) {
      if (DocumentPicker.isCancel(e)) {
        setModalError("File Upload Canceled");
      } else {
        setModalError("Error in uploading: " + JSON.stringify(e));
        throw e;
      }
    }
  };
  const handleDelete = (file?: any): void => {
    if (isProfile) {
      setAttachment([]);
      setShowAttachments(false);
    } else {
      var finalFiles = [...allfiles];
      let index = finalFiles.findIndex((el) => el.id === file.id);
      if (index > -1) {
        finalFiles.splice(index, 1);
        setAllFiles(finalFiles);
        handleFileChange && handleFileChange(finalFiles);
      }
    }
  };

  const thumbs = allfiles.map((file: any, index) => {
    return (
      <View style={commentStyles(commentBoxWidth).elementsContainer} key={index}>
        <Image
          resizeMode="stretch"
          testId="1"
          imageStyle={commentStyles(commentBoxWidth).imgElement}
          source={{
            uri: file.signedUrl ? file.signedUrl : file.blob.fileCopyUri
          }}
        />
        <Icon
          name={IconNames.crossCircle}
          onPress={() => handleDelete(file)}
          customStyle={commentStyles(commentBoxWidth).closeElement}
          size={16}
        />
      </View>
    );
  });
  const getFilesFromCamera = async (image: any) => {
    const url = await image;
    setAttachment([
      {
        id: url.fileName + url.fileSize,
        signedUrl: null,
        blob: {
          fileCopyUri: url.uri,
          name: url.fileName,
          size: url.fileSize,
          type: url.type,
          uri: url.uri
        }
      }
    ]);
  };

  const getCommonUploadUI = () => {
    return (
      <>
        {!showAttachments ? (
          <View style={{ flexDirection: "row", paddingHorizontal: 10 }}>
            <TouchableOpacity
              onPress={() => setShowAttachments(!showAttachments)}
              style={
                isProfile
                  ? profileStyles.editContainer
                  : [
                      commentStyles(commentBoxWidth).uploadStyles,
                      commentStyles(commentBoxWidth).boxColor,
                      iconStyle
                    ]
              }
            >
              <Icon
                name={isProfile ? IconNames.home : IconNames.attachment}
                customStyle={
                  isProfile ? profileStyles.editIcon : commentStyles(commentBoxWidth).iconColor
                }
                size={size}
              />
              {!isProfile && (
                <Text
                  fontWeight={FontWeight.Regular}
                  testId={testIds.uploadText}
                  textSize={TextSize.ExtraSmall}
                  textStyle={[commentStyles(commentBoxWidth).header, headerStyle]}
                >
                  {header}
                </Text>
              )}
            </TouchableOpacity>
          </View>
        ) : (
          <View
            style={
              isProfile
                ? profileStyles.profileUploadStyles
                : commentStyles(commentBoxWidth).uploadStyles
            }
          >
            <View
              style={{
                flexDirection: "row",
                paddingHorizontal: 10
              }}
            >
              <Icon
                name={IconNames.crossCircle}
                customStyle={[
                  commentStyles(commentBoxWidth).iconColor,
                  commentStyles(commentBoxWidth).iconMarginStyle
                ]}
                onPress={() => setShowAttachments(!showAttachments)}
                size={size}
              />
              <Icon
                onPress={captureDocument}
                name={IconNames.photoUpld}
                customStyle={[
                  commentStyles(commentBoxWidth).iconColor,
                  commentStyles(commentBoxWidth).iconMarginStyle
                ]}
                size={size}
              />
              <CameraUpload sendFiles={getFilesFromCamera}>
                <Icon
                  name={IconNames.camera}
                  customStyle={commentStyles(commentBoxWidth).iconColor}
                  size={size}
                />
              </CameraUpload>
            </View>
          </View>
        )}
      </>
    );
  };

  return (
    <>
      {isProfile ? (
        <View style={profileStyles.container}>
          <ProgressiveImage
            thumbnailSource={imgPlaceholder}
            source={
              attachments?.length > 0
                ? {
                    uri:
                      attachments && attachments[0].signedUrl
                        ? attachments[0].signedUrl
                        : attachments[0].blob.fileCopyUri
                  }
                : null
            }
            testId="Profile_Image"
            imageStyle={profileStyles.profileImageStyle}
          />
          {!isEdit &&
            (attachments?.length > 0 ? (
              <View style={profileStyles.buttonContainer}>
                <TouchableOpacity
                  onPress={() => handleDelete()}
                  style={profileStyles.editContainer}
                >
                  <Icon name={IconNames.close} customStyle={profileStyles.iconColor} size={10} />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={profileStyles.buttonContainer}>{getCommonUploadUI()}</View>
            ))}
        </View>
      ) : (
        <>
          <ScrollView horizontal={true} style={commentStyles(commentBoxWidth).scrollContainer}>
            {allfiles.length !== 0 && (
              <View style={commentStyles(commentBoxWidth).fileContainer}>{thumbs}</View>
            )}
          </ScrollView>
          <View style={[commentStyles(commentBoxWidth).container, containerStyle]}>
            {hideIcon && getCommonUploadUI()}
            <View style={commentStyles(commentBoxWidth).childrenStyles}>{children}</View>
          </View>
        </>
      )}
      {showErrorModal && (
        <AlertModal
          setModalVisible={() => setShowErrorModal(!showErrorModal)}
          errorMsg={modalError}
        />
      )}
    </>
  );
};

export default UploadBoxComponent;

export const profileStyles = StyleSheet.create({
  container: {
    flexDirection: "row"
  },
  editIcon: {
    color: colorPallete.grey3
  },
  profileImageStyle: {
    width: 120,
    height: 120,
    borderRadius: 60
  },
  buttonContainer: {
    ...I18nService.select({
      rtl: { marginStart: -40 },
      ltr: { marginStart: -40 }
    }),
    height: 24
  },
  iconColor: {
    color: colorPallete.red3
  },
  editContainer: {
    backgroundColor: colorPallete.white3,
    borderWidth: 0,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    shadowOffset: {
      width: 4,
      height: 0
    },
    shadowColor: colorPallete.grey17,
    shadowOpacity: 0.8,
    shadowRadius: 4
  },
  profileUploadStyles: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 40,
    backgroundColor: colorPallete.white3,
    shadowOffset: {
      width: 4,
      height: 0
    },
    shadowColor: colorPallete.grey17,
    shadowOpacity: 0.8,
    shadowRadius: 4,
    height: 24
  }
});
