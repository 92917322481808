import {
  LMSGetQuizTableModel,
  LMSPostQuizResponseModel,
  MTFDNDRequestBodyModel
} from "@timhortons/common/src/models/userLms/userLms";
import { IFluxStandardAction } from "./../../../store/interfaces";
import { UserLMS } from "./actionTypes";

const getTicTacToeQuizDetails = (data: {
  boxNumber: number;
}): IFluxStandardAction<{ boxNumber: number }> => {
  return {
    type: UserLMS.GET_TIC_TAC_TOE_DETAILS.LOADING,
    payload: data
  };
};

const getTicTacToeQuizDetailsSuccess = (data: any): IFluxStandardAction<{ boxNumber: number }> => {
  return {
    type: UserLMS.GET_TIC_TAC_TOE_DETAILS.SUCCESS,
    payload: data
  };
};

const getTicTacToeQuizDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_TIC_TAC_TOE_DETAILS.ERROR,
    payload: error
  };
};

const submitTicTacToeQuiz = (data: {
  boxNumber: number;
}): IFluxStandardAction<{ boxNumber: number }> => {
  return {
    type: UserLMS.SUBMIT_TIC_TAC_TOE_QUIZ.LOADING,
    payload: data
  };
};

const submitTicTacToeQuizSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.SUBMIT_TIC_TAC_TOE_QUIZ.SUCCESS,
    payload: data
  };
};

const submitTicTacToeQuizError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.SUBMIT_TIC_TAC_TOE_QUIZ.ERROR,
    payload: error
  };
};

const submitMtfDndQuiz = (
  data: MTFDNDRequestBodyModel
): IFluxStandardAction<MTFDNDRequestBodyModel> => {
  return {
    type: UserLMS.SUBMIT_MTFDND_QUIZ.LOADING,
    payload: data
  };
};

const submitMtfDndQuizSuccess = (
  data: LMSPostQuizResponseModel
): IFluxStandardAction<LMSPostQuizResponseModel> => {
  return {
    type: UserLMS.SUBMIT_MTFDND_QUIZ.SUCCESS,
    payload: data
  };
};

const submitMtfDndQuizError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.SUBMIT_MTFDND_QUIZ.ERROR,
    payload: error
  };
};

const getDragDropQuizDetails = (data: { type: string }): IFluxStandardAction<{ type: string }> => {
  return {
    type: UserLMS.GET_DRAG_DROP_DETAILS.LOADING,
    payload: data
  };
};

const getDragDropQuizDetailsSuccess = (
  data: LMSGetQuizTableModel
): IFluxStandardAction<LMSGetQuizTableModel> => {
  return {
    type: UserLMS.GET_DRAG_DROP_DETAILS.SUCCESS,
    payload: data
  };
};

const getDragDropQuizDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_DRAG_DROP_DETAILS.ERROR,
    payload: error
  };
};

const resetMtfDndQuiz = (): IFluxStandardAction => {
  return {
    type: UserLMS.RESET_MTF_DND_QUIZ
  };
};
const resetQuizResponse = () => {
  return {
    type: UserLMS.RESET_QUIZ_RESPONSE.LOADING
  };
};

export const QuizActions = {
  getTicTacToeQuizDetails,
  getTicTacToeQuizDetailsSuccess,
  getTicTacToeQuizDetailsError,
  submitTicTacToeQuiz,
  submitTicTacToeQuizSuccess,
  submitTicTacToeQuizError,
  getDragDropQuizDetails,
  getDragDropQuizDetailsSuccess,
  getDragDropQuizDetailsError,
  submitMtfDndQuiz,
  submitMtfDndQuizSuccess,
  submitMtfDndQuizError,
  resetMtfDndQuiz,
  resetQuizResponse
};
