import React, { ReactElement, useEffect, useState } from "react";
import { LayoutChangeEvent, StyleSheet, View, ViewStyle } from "react-native";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Card from "@timhortons/common/src/components/atoms/card";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType, IconPositions } from "@timhortons/common/src/components/atoms/button";
import { Orientations } from "@timhortons/common/src/components/atoms/divider";
import CheckboxGroup, {
  ICheckBoxOption
} from "@timhortons/common/src/components/molecules/checkBoxGroup";
import CommentBox, {
  styles as CommentStyles
} from "@timhortons/common/src/modules/lp/organisms/commentBox";
import { LpAppealTable } from "@timhortons/common/src/modules/lp/components/dashboard/appeals";
import {
  LpIndividualAppealItemModel,
  LpOpportunityNewModel
} from "@timhortons/common/src/models/lp/appeals";
import { LpCommentDataModel } from "@timhortons/common/src/models/lp/audits";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import {
  textBlock,
  CardHeader,
  ClosedTableCommon
} from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/cardHeader";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import ImgMapper from "@timhortons/common/src/components/organisms/imgMapper";
import { Roles } from "@timhortons/common/src/constants/roles";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
interface IProps {
  appealCardData?: LpIndividualAppealItemModel;
  setAppealDecision: Function; // Need to change the flow of area manager appeals
  translate: any;
  currentTable: LpAppealTable;
  toggleIcon?: boolean;
  key: number;
  isIndividualLogin: boolean;
  userDetails?: IUserDetails;
}

export enum AppealStatus {
  approved = "APPROVED",
  disapproved = "DISAPPROVED",
  inProcess = "IN_PROCESS"
}

export default function AreaManagerAppealCard(props: IProps): ReactElement {
  const {
    appealCardData,
    translate,
    currentTable,
    isIndividualLogin,
    setAppealDecision,
    userDetails
  } = props;
  const [disableAppealStatus, setDisableAppealStatus] = useState(true);
  const [checkboxData, setCheckboxData] = useState<Array<ICheckBoxOption>>([]);
  const [currentAppealStatus, setCurrentAppealStatus] = useState<string>();
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  useEffect(() => {
    let dataList: Array<ICheckBoxOption> = [];
    if (appealCardData && appealCardData.appealQuestion) {
      dataList =
        appealCardData.appealQuestion &&
        appealCardData.appealQuestion.map((item: LpOpportunityNewModel, index) => {
          return {
            name: item.question,
            key: index,
            selected: item.isTicked,
            id: item.id
          };
        });
    }
    setCheckboxData(dataList);
  }, [appealCardData]);
  useEffect(() => {
    let dataList: Array<ICheckBoxOption> = [];
    if (appealCardData && appealCardData.appealQuestion) {
      dataList =
        appealCardData.appealQuestion &&
        appealCardData.appealQuestion.map((item: LpOpportunityNewModel, index) => {
          return {
            name: item.question,
            key: index,
            selected: item.isTicked,
            id: item.id
          };
        });
    }
    if (isCancelled) {
      setCheckboxData(dataList);
      setIsCancelled(false);
    }
  }, [isCancelled]);

  const handleCheckboxCallBack = (checkboxItems: any): void => {
    let tempCheckboxData = checkboxData;
    let index = checkboxData.findIndex((val) => val.key === checkboxItems.key);
    tempCheckboxData[index] = checkboxItems;
    setCheckboxData(tempCheckboxData);
    setSelectedValues((prevState) => ({
      ...selectedValues,
      opportunityData: checkboxItems
    }));

    let checkCheckboxStatus = false;
    checkboxData.forEach((val) => {
      if (val.selected === true) checkCheckboxStatus = true;
    });
    if (!checkCheckboxStatus) {
      setDisableAppealStatus(true);
    } else {
      setCurrentAppealStatus("");
      setDisableAppealStatus(false);
    }
  };
  const [selectedValues, setSelectedValues] = useState({
    dropdownValue: "",
    opportunityData: [],
    disapproveStatus: false,
    auditedBy: userDetails.empId
  });

  const setDisapproveButton = async () => {
    setCurrentAppealStatus(AppealStatus.disapproved);
    // setSelectedValues((prevState) => ({
    //   ...prevState,
    //   disapproveStatus: !selectedValues.disapproveStatus
    // }));
    setShowComment(true);
  };
  // useEffect(() => {
  //   if (currentAppealStatus) {
  //     decisionButtonClick();
  //   }
  // }, [currentAppealStatus]);

  const setApproveButton = () => {
    setCurrentAppealStatus(AppealStatus.approved);
    // setSelectedValues((prevState) => ({
    //   ...prevState,
    //   dropdownValue: AppealStatus.approved
    // }));
    setShowComment(true);
  };

  const [commentBoxWidth, setCommentBoxWidth] = useState<number>(0);

  const onPageLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;
    setCommentBoxWidth(width);
  };

  const [showComment, setShowComment] = useState(false);

  const commentBoxSaveClick = (commentFormData: LpCommentDataModel) => {
    let postData = new FormData();
    postData.append("appealItemId", JSON.stringify(appealCardData.appealItemId));
    let questions: Array<number> = [];
    checkboxData &&
      checkboxData.map((value) => {
        if (value.selected === true) questions.push(value.id);
      });
    let postfiles: Blob[] =
      (commentFormData &&
        commentFormData.files &&
        commentFormData.files.length > 0 &&
        commentFormData.files.map((img) => img.blob)) ||
      [];
    postData.append("questions", JSON.stringify(questions));
    postData.append("appealItemStatus", currentAppealStatus);
    postData.append("employeeId", userDetails.empId);
    postfiles &&
      postfiles.map((img, index) => {
        postData.append("files", img, `img-${index}.jpeg`);
      });
    commentFormData &&
      commentFormData.comment &&
      postData.append("commentByAuditor", commentFormData.comment);
    if (postData !== undefined) {
      setAppealDecision(postData);
    }
  };
  // const decisionButtonClick = () => {
  //   let postData = new FormData();
  //   postData.append("appealItemId", JSON.stringify(appealCardData.appealItemId));
  //   let questions: Array<number> = [];
  //   checkboxData &&
  //     checkboxData.map((value) => {
  //       if (value.selected === true) questions.push(value.id);
  //     });

  //   postData.append("questions", JSON.stringify(questions));
  //   postData.append("appealItemStatus", currentAppealStatus);
  //   postData.append("employeeId", userDetails.empId);

  //   postData.append("commentByAuditor", " ");
  //   if (postData !== undefined) {
  //     setAppealDecision(postData);
  //   }
  // };

  const imgCardContainer = (commentBoxWidth: number): ViewStyle => ({
    maxWidth: commentBoxWidth - 50,
    flexDirection: "row"
  });

  return (
    <>
      <View style={mainStyles(currentTable, appealCardData)}>
        <Card containerStyle={commonappealStoreStyles.cardContainerStyle} disabled={true}>
          <CardHeader translate={translate} />

          <Card.Body bodyStyle={commonappealStoreStyles.cardBodyContainerStyle}>
            <AppealCardHeader appealCardData={appealCardData} />
            {(userDetails?.roles?.includes(Roles.SuperAdmin) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevLead) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevManager) ||
              userDetails?.roles?.includes(Roles.COO) ||
              userDetails?.roles?.includes(Roles.CEO) ||
              userDetails?.roles?.includes(Roles.HrAndAdminDirector) ||
              userDetails?.roles?.includes(Roles.ProductionAndDevExecutive) ||
              // userDetails?.roles?.includes(Roles.Admin) ||
              userDetails?.roles?.includes(Roles.HeadLP)) &&
            currentTable === LpAppealTable.inProcess ? (
              <View style={CommentStyles.commentBodyContainer}>
                <CheckboxGroup
                  showId={true}
                  checkBoxOptions={checkboxData}
                  checkBoxContainerStyle={styles.bottomCheckbox}
                  parentCallBack={handleCheckboxCallBack}
                  textStyle={styles.checkboxText}
                  orientation={Orientations.Vertical}
                  fontWeight={FontWeight.Regular}
                  fontSize={TextSize.ExtraRegular}
                  iconSize={20}
                  iconStyle={PlatformUtils.isWeb() ? { marginTop: 0 } : { marginTop: 4 }}
                />
              </View>
            ) : (
              isIndividualLogin && (
                <View style={styles.appealCardContainer}>
                  {appealCardData.appealQuestion &&
                    appealCardData.appealQuestion.map((value, index: number) => (
                      <View key={index} style={styles.appealCardView}>
                        <Text
                          // key={index}
                          textSize={TextSize.ExtraRegular}
                          fontWeight={FontWeight.Regular}
                          testId={testIds.actionPlan.actionPlanCriteria}
                          textStyle={[styles.opportunityListTextNumber, styles.opportunityListText]}
                        >
                          {index + 1}.
                        </Text>
                        <Text
                          key={value.id}
                          textSize={TextSize.ExtraRegular}
                          fontWeight={FontWeight.Regular}
                          testId={testIds.actionPlan.actionPlanCriteria}
                          textStyle={styles.opportunityListText}
                        >
                          {value.question}
                        </Text>
                      </View>
                    ))}
                </View>
              )
            )}
            <View style={styles.textBlockContainer}>
              {textBlock(
                TextSize.ExtraSmall,
                FontWeight.Regular,
                "appeal",
                userDetails?.roles?.includes(Roles.SuperAdmin) ||
                  userDetails?.roles?.includes(Roles.TrainingAndDevManager) ||
                  userDetails?.roles?.includes(Roles.TrainingAndDevLead) ||
                  userDetails?.roles?.includes(Roles.COO) ||
                  userDetails?.roles?.includes(Roles.CEO) ||
                  userDetails?.roles?.includes(Roles.HrAndAdminDirector) ||
                  userDetails?.roles?.includes(Roles.ProductionAndDevExecutive) ||
                  // userDetails?.roles?.includes(Roles.Admin) ||
                  userDetails?.roles?.includes(Roles.HeadLP)
                  ? styles.cardBodyTextStyle
                  : [styles.cardBodyTextStyle, commonappealStoreStyles.storeAccessCardBody],
                appealCardData.appealerResponse.comment
              )}
            </View>
            <View style={styles.flexContainer}>
              {appealCardData?.appealerResponse?.assets?.length > 0 && (
                <ImgMapper items={appealCardData.appealerResponse.assets.map((i) => i.signedUrl)} />
              )}
            </View>
            {(userDetails?.roles?.includes(Roles.SuperAdmin) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevLead) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevManager) ||
              userDetails?.roles?.includes(Roles.COO) ||
              userDetails?.roles?.includes(Roles.CEO) ||
              userDetails?.roles?.includes(Roles.HrAndAdminDirector) ||
              userDetails?.roles?.includes(Roles.ProductionAndDevExecutive) ||
              // userDetails?.roles?.includes(Roles.Admin) ||
              userDetails?.roles?.includes(Roles.HeadLP)) &&
              currentTable === LpAppealTable.inProcess && (
                <>
                  <View style={styles.dropdownContainer}>
                    <View style={styles.dropDownView}>
                      <Button
                        type={ButtonType.Primary}
                        onPress={setApproveButton}
                        buttonStyles={
                          currentAppealStatus === AppealStatus.approved && !disableAppealStatus
                            ? [
                                styles.disapproveButton,
                                commonappealStoreStyles.selectedDropdownItem
                              ]
                            : [styles.disapproveButton, styles.unselectedApproveButton]
                        }
                        iconName={IconNames.tick}
                        iconPosition={IconPositions.Left}
                        iconStyles={
                          currentAppealStatus === AppealStatus.approved && !disableAppealStatus
                            ? [styles.unselectButton]
                            : !disableAppealStatus
                            ? styles.nonClickableIcon
                            : {
                                color: colorPallete.green1,
                                opacity: 0.5,
                                ...I18nService.select({
                                  rtl: { marginEnd: 10 },
                                  ltr: { marginEnd: 10 }
                                })
                              }
                        }
                        disabled={disableAppealStatus}
                        iconSize={12}
                      >
                        <Text
                          testId="approve"
                          textSize={TextSize.Regular}
                          fontWeight={FontWeight.Bold}
                          textStyle={
                            currentAppealStatus === AppealStatus.approved && !disableAppealStatus
                              ? commonappealStoreStyles.unselectedDropdownItem
                              : !disableAppealStatus
                              ? styles.customStyle
                              : {
                                  color: colorPallete.green1,
                                  opacity: 0.5
                                }
                          }
                        >
                          {translate("approve")}
                        </Text>
                      </Button>
                    </View>
                    <Button
                      type={ButtonType.Primary}
                      onPress={setDisapproveButton}
                      buttonStyles={
                        currentAppealStatus === AppealStatus.disapproved && !disableAppealStatus
                          ? [styles.disapproveButton, styles.selectDisapproveButton]
                          : [styles.disapproveButton, styles.unselectDisapproveButton]
                      }
                      iconName={IconNames.close}
                      iconPosition={IconPositions.Left}
                      iconStyles={
                        currentAppealStatus === AppealStatus.disapproved && !disableAppealStatus
                          ? [styles.unselectButton]
                          : !disableAppealStatus
                          ? styles.iconDisapprove
                          : styles.buttonIconStyles
                      }
                      disabled={disableAppealStatus}
                      iconSize={12}
                    >
                      <Text
                        testId="disapprove"
                        textSize={TextSize.Regular}
                        fontWeight={FontWeight.Bold}
                        textStyle={
                          currentAppealStatus === AppealStatus.disapproved && !disableAppealStatus
                            ? commonappealStoreStyles.unselectedDropdownItem
                            : !disableAppealStatus
                            ? styles.textRejectStyle
                            : {
                                color: colorPallete.red1,
                                opacity: 0.5
                              }
                        }
                      >
                        {translate("disapprove")}
                      </Text>
                    </Button>
                  </View>
                </>
              )}
          </Card.Body>
        </Card>
        {showComment && (
          <CommentBox
            translate={translate}
            saveClickCall={commentBoxSaveClick}
            acceptFileType={"image/jpeg, image/png"}
            editIcon={false}
            setCurrentAppealStatus={setCurrentAppealStatus}
            editCommentContainerStyle={styles.editCommentContainerStyle}
            iconStyle={commonappealStoreStyles.commentBoxColor}
            setIsCancelled={setIsCancelled}
            setDisableAppealStatus={setDisableAppealStatus}
            currentAppealStatus={currentAppealStatus}
            disableAppealStatus={disableAppealStatus}
          />
        )}

        {currentTable === LpAppealTable.closedAppeal ? (
          <View style={commonappealStoreStyles.cardFooterContainerStyle}>
            <ClosedTableCommon
              comment={appealCardData.auditorResponse.comment}
              status={appealCardData.appealItemStatus}
              translate={translate}
              textStyle={
                ((appealCardData.appealItemStatus === "APPROVED" ||
                  appealCardData.appealItemStatus === "APPROVED_REVERIFICATION") && [
                  commonappealStoreStyles.decisionTextStyle,
                  commonappealStoreStyles.approveDecisionTextStyle
                ]) || [
                  commonappealStoreStyles.decisionTextStyle,
                  commonappealStoreStyles.rejectDecisionTextStyle
                ]
              }
            />
          </View>
        ) : null}
        {currentTable === LpAppealTable.closedAppeal && isIndividualLogin && (
          <View style={CommentStyles.imageContainer} onLayout={onPageLayout}>
            <View style={imgCardContainer(commentBoxWidth)}>
              {appealCardData?.auditorResponse?.assets?.length > 0 && (
                <ImgMapper items={appealCardData.auditorResponse.assets.map((i) => i.signedUrl)} />
              )}
            </View>
          </View>
        )}
      </View>
    </>
  );
}
export const mainStyles = (
  currentTable: LpAppealTable,
  appealCardData: LpIndividualAppealItemModel
) => {
  return currentTable === LpAppealTable.closedAppeal
    ? appealCardData.appealItemStatus === "APPROVED" ||
      appealCardData.appealItemStatus === "APPROVED_REVERIFICATION"
      ? [commonappealStoreStyles.container, commonappealStoreStyles.closedApproveContainer]
      : [commonappealStoreStyles.container, commonappealStoreStyles.closedRejectContainer]
    : commonappealStoreStyles.container;
};
export const AppealCardHeader = (props: any) => {
  return (
    <View style={commonappealStoreStyles.cardTitleContainerStyle}>
      {textBlock(
        TextSize.ExtraRegular,
        FontWeight.SemiBold,
        "appeal",
        commonappealStoreStyles.serialNumberStyle,
        props.appealCardData.lpSubCategory.subcategoryLabel
      )}
      {textBlock(
        TextSize.ExtraRegular,
        FontWeight.SemiBold,
        "appeal",
        commonappealStoreStyles.subCategoryNameStyle,
        props.appealCardData.lpSubCategory.subcategoryName
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  cardBodyTextStyle: {
    color: colorPallete.grey1,
    ...I18nService.select({
      rtl: { marginStart: 12 },
      ltr: { marginStart: 12 }
    })
  },
  customStyle: {
    color: colorPallete.green1
    // opacity: 0.6
  },
  nonClickableIcon: {
    color: colorPallete.green2,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
  },
  iconDisapprove: {
    color: colorPallete.red1,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
  },
  nonClickable: {
    backgroundColor: "rgba(192, 185, 186, 0.1)",
    paddingHorizontal: 36,
    borderRadius: 8,
    paddingVertical: 12
  },
  nonClickableDisapprove: {
    backgroundColor: "rgba(192, 185, 186, 0.1)",
    paddingHorizontal: 36,
    borderRadius: 8,
    paddingVertical: 12,

    opacity: 0.7
  },
  checkboxText: {
    flex: 1,
    // flexWrap: "wrap",
    color: colorPallete.grey1,
    ...I18nService.select({
      rtl: { marginStart: 10 },
      ltr: { marginStart: 10 }
    })
  },
  bottomCheckbox: {
    marginBottom: 16,
    flexShrink: 1,
    alignItems: "flex-start"
  },
  disapproveButton: {
    paddingHorizontal: 36,
    borderRadius: 8,
    paddingVertical: 12
  },
  selectDisapproveButton: {
    backgroundColor: colorPallete.red1
  },
  unselectDisapproveButton: {
    backgroundColor: colorPallete.variant9
  },
  unselectedApproveButton: {
    backgroundColor: colorPallete.variant8
  },
  buttonIconStyles: {
    color: colorPallete.red1,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    }),
    opacity: 0.5
  },
  textRejectStyle: {
    color: colorPallete.red1
  },
  dropdownContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginHorizontal: 34,
    marginTop: 24
  },

  unselectButton: {
    color: colorPallete.white3,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
  },
  opportunityListText: {
    color: colorPallete.grey1,
    marginBottom: 16
  },
  opportunityListTextNumber: {
    ...I18nService.select({
      rtl: { paddingStart: 7 },
      ltr: { paddingEnd: 7 }
    })
  },
  editCommentContainerStyle: {
    padding: -10,
    backgroundColor: colorPallete.white1
  },
  flexContainer: {
    flexDirection: "row",
    ...I18nService.select({
      rtl: { marginStart: 35 },
      ltr: { marginStart: 35 }
    })
  },
  appealCardContainer: { paddingVertical: 20 },
  textBlockContainer: {},
  dropDownView: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    })
  },
  appealCardView: { flexDirection: "row" }
});

export const commonappealStoreStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colorPallete.white2,
    borderRadius: 8
  },
  closedRejectContainer: {
    borderLeftWidth: 6,
    borderColor: colorPallete.red1
  },
  closedApproveContainer: {
    borderLeftWidth: 6,

    borderColor: colorPallete.green1
  },
  cardContainerStyle: {
    padding: 24,
    shadowOffset: { width: 0, height: 0 },
    elevation: 0
  },
  cardTitleContainerStyle: {
    flexDirection: "row"
  },

  cardBodyContainerStyle: {
    // marginTop: 16
  },
  serialNumberStyle: {
    color: colorPallete.red1
  },
  subCategoryNameStyle: {
    marginHorizontal: 12,
    color: colorPallete.black3
  },
  storeAccessCardBody: {
    marginTop: 12
  },
  cardFooterContainerStyle: {
    paddingHorizontal: 24,
    paddingBottom: 24
  },

  decisionTextStyle: {
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginTop: -4
  },
  approveDecisionTextStyle: {
    color: colorPallete.green1,
    backgroundColor: colorPallete.variant8
  },
  rejectDecisionTextStyle: {
    color: colorPallete.red1,
    backgroundColor: colorPallete.variant4
  },
  selectedDropdownItem: {
    backgroundColor: colorPallete.green1
  },
  unselectedDropdownItem: {
    color: colorPallete.white3
  },
  commentBoxColor: { backgroundColor: colorPallete.white1 }
});
