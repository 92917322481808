import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  LogsAuthModel,
  LogShiftModel,
  ProductTemperatureCategoriesModel,
  ReceivingLogCategoriesSubCategoriesModel
} from "models/logs/mainLogs";
import { TabObject } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import { ITimeTempCategories } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";

export interface ITimeTempLogsLoading {
  getTimeTempCategories: boolean;
  getReceivingLogsCategories: boolean;
  getReceivingLogsSubCategories: boolean;
  getReceivingLogsItems: boolean;
  getProdTempCategory: boolean;
  getProdTempItems: boolean;
  recordProductTemperature: boolean;
  recordReceivingLogTemperature: boolean;
  getLogShift: boolean;
  equipmentTempItems: boolean;
  logsAuth: boolean;
}
export interface ITimeTempLogsError {
  getTimeTempCategories: string;
  getReceivingLogsCategories: string;
  getReceivingLogsSubCategories: string;
  getReceivingLogsItems: string;
  getProdTempCategory: string;
  getProdTempItems: string;
  recordProductTemperature: string;
  recordReceivingLogTemperature: string;
  getLogShift: string;
  equipmentTempItems: string;
  logsAuth: string;
}
export interface ITimeTempLogsState {
  loading: ITimeTempLogsLoading;
  error: ITimeTempLogsError;
  getTimeTempCategories: ITimeTempCategories[];
  receivingLogCategories: ReceivingLogCategoriesSubCategoriesModel[];
  receivingLogSubCategories: ReceivingLogCategoriesSubCategoriesModel[];
  receivingLogItems: TabObject[];
  prodTempCategory: ProductTemperatureCategoriesModel[];
  prodTempItems: TabObject[];
  logsShift: LogShiftModel[];
  equipmentTempItems: TabObject[];
  logsAuth: LogsAuthModel;
  isProdTempCompleted: boolean;
  isTwoRedProdTemp: boolean;
}
export const timeTempLogsInitialState: ITimeTempLogsState = {
  getTimeTempCategories: null,
  receivingLogCategories: null,
  receivingLogSubCategories: null,
  receivingLogItems: null,
  prodTempCategory: null,
  prodTempItems: null,
  logsShift: null,
  equipmentTempItems: null,
  logsAuth: null,
  isProdTempCompleted: null,
  isTwoRedProdTemp: null,
  loading: {
    getTimeTempCategories: false,
    getReceivingLogsCategories: false,
    getReceivingLogsSubCategories: false,
    getReceivingLogsItems: false,
    getProdTempCategory: false,
    getProdTempItems: false,
    recordProductTemperature: false,
    recordReceivingLogTemperature: false,
    getLogShift: false,
    equipmentTempItems: false,
    logsAuth: false
  },
  error: {
    getTimeTempCategories: null,
    getReceivingLogsCategories: null,
    getReceivingLogsSubCategories: null,
    getReceivingLogsItems: null,
    getProdTempCategory: null,
    getProdTempItems: null,
    recordProductTemperature: null,
    recordReceivingLogTemperature: null,
    getLogShift: null,
    equipmentTempItems: null,
    logsAuth: null
  }
};

export const timeTempLogsReducer = (
  state = timeTempLogsInitialState,
  action: { payload: any; type: string }
): ITimeTempLogsState => {
  switch (action.type) {
    case LOGS.MainLogs.TimeTemp.GET_TIME_TEMP_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTimeTempCategories: true },
        error: { ...state.error, getTimeTempCategories: null }
      };
    case LOGS.MainLogs.TimeTemp.GET_TIME_TEMP_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTimeTempCategories: false },
        error: { ...state.error, getTimeTempCategories: null },
        getTimeTempCategories: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_TIME_TEMP_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTimeTempCategories: false },
        error: { ...state.error, getTimeTempCategories: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsCategories: true },
        error: { ...state.error, getReceivingLogsCategories: null }
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsCategories: false },
        error: { ...state.error, getReceivingLogsCategories: null },
        receivingLogCategories: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsCategories: false },
        error: { ...state.error, getReceivingLogsCategories: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_SUBCATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsSubCategories: true },
        error: { ...state.error, getReceivingLogsSubCategories: null }
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_SUBCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsSubCategories: false },
        error: { ...state.error, getReceivingLogsSubCategories: null },
        receivingLogSubCategories: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_SUBCATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsSubCategories: false },
        error: { ...state.error, getReceivingLogsSubCategories: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsItems: true },
        error: { ...state.error, getReceivingLogsItems: null }
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_ITEMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsItems: false },
        error: { ...state.error, getReceivingLogsItems: null },
        receivingLogItems: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getReceivingLogsItems: false },
        error: { ...state.error, getReceivingLogsItems: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_CATEGORY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProdTempCategory: true },
        error: { ...state.error, getProdTempCategory: null }
      };
    case LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProdTempCategory: false },
        error: { ...state.error, getProdTempCategory: null },
        prodTempCategory: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_CATEGORY.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProdTempCategory: false },
        error: { ...state.error, getProdTempCategory: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProdTempItems: true },
        error: { ...state.error, getProdTempItems: null }
      };
    case LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_ITEMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProdTempItems: false },
        error: { ...state.error, getProdTempItems: null },
        prodTempItems: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProdTempItems: false },
        error: { ...state.error, getProdTempItems: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.RECORD_PRODUCT_TEMPERATURE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, recordProductTemperature: true },
        error: { ...state.error, recordProductTemperature: null }
      };
    case LOGS.MainLogs.TimeTemp.RECORD_PRODUCT_TEMPERATURE.SUCCESS:
      let productData = state.prodTempItems;
      let products = state.prodTempCategory;
      let shifts = state.logsShift;
      let equipData = state.equipmentTempItems;
      let prodTempCompleted = state.isProdTempCompleted;
      let isTwoRed = state.isTwoRedProdTemp;
      if (products !== null) {
        // marking product as isRed or isTick
        let index = products && products?.findIndex((el) => el.key === action.payload.productId);
        if (index > -1) {
          products[index].isTick = action.payload.isTicked;
          products[index].isTwoRed = action.payload.isTwoRed;
        }
        // if is tick is there for each category then marking shift as complete
        let isShiftCompleted = products.every((item) => item.isTick === true);

        if (isShiftCompleted) {
          let activeShiftIndex = shifts?.findIndex((item) => item.isEnable === true);
          if (activeShiftIndex > -1) {
            shifts[activeShiftIndex].status = "COMPLETE";
            prodTempCompleted = true;
          }
        } else {
          // if is tick is not there for each category then marking shift as incomplete
          let activeShiftIndex = shifts.findIndex((item) => item.isEnable === true);
          if (activeShiftIndex > -1) {
            shifts[activeShiftIndex].status = "INCOMPLETE";
            prodTempCompleted = false;
          }
        }
        //check if is red is there for single product then making isRed as true for popup
        let isTwoRedsProduct = products.find(
          (item) => item.key === action.payload.productId && item.isTwoRed === true
        );
        if (isTwoRedsProduct) {
          isTwoRed = true;
          products[index].isTick = false;
          let allProdQuestions =
            productData &&
            productData.every(
              (item) => item.isNASelected === true || (item.data !== null && item.data !== "-")
            );
          if (allProdQuestions) {
            products[index].isTick = true;
          } else {
            products[index].isTick = false;
          }
        } else {
          // if is red is not there for single product then making isRed as false for popup
          isTwoRed = false;
        }
      }

      if (productData !== null) {
        // for product temperature
        let dataIndex =
          productData &&
          productData?.findIndex((object) => object.uniqueItemId === action.payload.itemId);
        if (dataIndex > -1) {
          productData[dataIndex].data = action.payload?.recordedTemp;
          productData[dataIndex].isValid = action.payload?.isTempCorrect;
          productData[dataIndex].method = action.payload?.method;
        }
      }
      if (equipData !== null) {
        // for equipment temperature
        let equipIndex =
          equipData &&
          equipData?.findIndex((object) => object.uniqueItemId === action.payload.itemId);
        if (equipIndex > -1) {
          equipData[equipIndex].data = action.payload?.recordedTemp;
          equipData[equipIndex].isValid = action.payload?.isTempCorrect;
          equipData[equipIndex].method = action.payload?.method;
        }
        // for equipment temp if all questions are complete marking shift as complete
        let allQuestions =
          equipData &&
          equipData.every(
            (item) => item.isNASelected === true || (item.data !== null && item.data !== "-")
          );
        if (allQuestions === true) {
          let activeShiftEqipIndex = shifts.findIndex((item) => item.isEnable === true);
          if (activeShiftEqipIndex > -1) {
            shifts[activeShiftEqipIndex].status = "COMPLETE";
          }
        } else {
          let activeShiftEqipIndex = shifts.findIndex((item) => item.isEnable === true);
          if (activeShiftEqipIndex > -1) {
            shifts[activeShiftEqipIndex].status = "INCOMPLETE";
          }
        }
      }
      return {
        ...state,
        loading: { ...state.loading, recordProductTemperature: false },
        error: { ...state.error, recordProductTemperature: null },
        prodTempItems: productData,
        prodTempCategory: products,
        equipmentTempItems: equipData,
        isProdTempCompleted: prodTempCompleted,
        isTwoRedProdTemp: isTwoRed
      };
    case LOGS.MainLogs.TimeTemp.RECORD_PRODUCT_TEMPERATURE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, recordProductTemperature: false },
        error: { ...state.error, recordProductTemperature: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.RECORD_RECEIVINGLOG_TEMPERATURE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, recordReceivingLogTemperature: true },
        error: { ...state.error, recordReceivingLogTemperature: null }
      };
    case LOGS.MainLogs.TimeTemp.RECORD_RECEIVINGLOG_TEMPERATURE.SUCCESS:
      let formattedData = state.receivingLogItems;
      if (formattedData !== null) {
        let formattedDataIndex = formattedData?.findIndex(
          (object) => object.uniqueItemId === action.payload.itemId
        );
        if (formattedDataIndex > -1) {
          formattedData[formattedDataIndex].data = action.payload?.recordedTemp;
          formattedData[formattedDataIndex].isValid = action.payload?.isTempCorrect;
          formattedData[formattedDataIndex].method = action.payload?.method;
          action.payload?.comment !== null
            ? (formattedData[formattedDataIndex].comment = action.payload?.comment)
            : (formattedData[formattedDataIndex].comment = action.payload.reason);
          if (action.payload?.isNASelected) {
            formattedData[formattedDataIndex].comment = "";
            formattedData[formattedDataIndex].degree = "";
          }
        }
      }
      return {
        ...state,
        loading: { ...state.loading, recordReceivingLogTemperature: false },
        error: { ...state.error, recordReceivingLogTemperature: null },
        receivingLogItems: formattedData
      };
    case LOGS.MainLogs.TimeTemp.RECORD_RECEIVINGLOG_TEMPERATURE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, recordReceivingLogTemperature: false },
        error: { ...state.error, recordReceivingLogTemperature: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.GET_LOGS_SHIFT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLogShift: true },
        error: { ...state.error, getLogShift: null }
      };
    case LOGS.MainLogs.TimeTemp.GET_LOGS_SHIFT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLogShift: false },
        error: { ...state.error, getLogShift: null },
        logsShift: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_LOGS_SHIFT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLogShift: false },
        error: { ...state.error, getLogShift: action.payload }
      };
    case LOGS.MainLogs.TimeTemp.GET_EQUIPMENT_TEMP_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, equipmentTempItems: true }
      };
    case LOGS.MainLogs.TimeTemp.GET_EQUIPMENT_TEMP_ITEMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, equipmentTempItems: false },
        equipmentTempItems: action.payload
      };
    case LOGS.MainLogs.TimeTemp.GET_EQUIPMENT_TEMP_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, equipmentTempItems: false },
        error: { ...state.error, equipmentTempItems: action.payload }
      };
    case LOGS.Authentication.CHECK_LOGS_AUTH.LOADING:
      return {
        ...state,
        loading: { ...state.loading, logsAuth: true },
        error: { ...state.error, logsAuth: null },
        logsAuth: null
      };
    case LOGS.Authentication.CHECK_LOGS_AUTH.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, logsAuth: false },
        logsAuth: action.payload
      };
    case LOGS.Authentication.CHECK_LOGS_AUTH.ERROR:
      return {
        ...state,
        loading: { ...state.loading, logsAuth: false },
        error: { ...state.error, logsAuth: action.payload }
      };
    case LOGS.Authentication.RESET_LOGS_AUTH:
      return {
        ...state,
        logsAuth: null,
        logsShift: null,
        getTimeTempCategories: null,
        prodTempCategory: null
      };

    case LOGS.MainLogs.TimeTemp.RESET_TIME_TEMP_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          logsAuth: null,
          getLogShift: null,
          recordProductTemperature: null,
          recordReceivingLogTemperature: null
        }
      };
    case LOGS.MainLogs.TimeTemp.RESET_TIME_TEMP_CATEGORY_STATE:
      return {
        ...state,
        logsAuth: null,
        logsShift: null,
        prodTempItems: null,
        equipmentTempItems: null,
        receivingLogItems: null,
        prodTempCategory: null,
        isProdTempCompleted: false
      };
    case LOGS.MainLogs.TimeTemp.UPDATE_ACTIVE_SHIFT:
      let updatedShifts = state.logsShift;
      let shiftIndex =
        updatedShifts &&
        updatedShifts?.findIndex((item: any) => item.label === action.payload.label);
      let currentActiveShift =
        updatedShifts && updatedShifts?.findIndex((item: any) => item.isEnable === true);
      if (shiftIndex > -1) {
        updatedShifts[shiftIndex].isEnable = true;
      }
      if (currentActiveShift > -1) {
        updatedShifts[currentActiveShift].isEnable = false;
      }
      return {
        ...state,
        logsShift: updatedShifts
      };
    case LOGS.MainLogs.TimeTemp.RESET_PROD_TEMP_CATEGORY_STATE:
      return {
        ...state,
        prodTempCategory: null
      };
    case LOGS.MainLogs.TimeTemp.RESET_REC_LOG:
      return {
        ...state,
        receivingLogSubCategories: null,
        receivingLogItems: null
      };
    case LOGS.MainLogs.TimeTemp.RESET_AUTH_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          logsAuth: null
        }
      };
    case LOGS.MainLogs.TimeTemp.RESET_IS_PROD_TEMP_COMPLETED:
      return {
        ...state,
        isProdTempCompleted: false
      };
    case LOGS.MainLogs.TimeTemp.RESET_TWO_RED_STATE:
      return {
        ...state,
        isTwoRedProdTemp: false
      };
    case LOGS.MainLogs.TimeTemp.RESET_TIME_TEMP_ENTIRE_STATE:
      return timeTempLogsInitialState;
    default:
      return state;
  }
};
