import React, { ReactElement, useEffect, useRef, useState } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, TextStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { ButtonGroup } from "@timhortons/common/src/components/molecules/buttonGroup";
import { IDropDownItem } from "@timhortons/common/src/components/molecules/modalDropdown";
import {
  Filters,
  HideFilters,
  ResetFilters,
  ShowFiltersView
} from "@timhortons/common/src/modules/common/organisms/filters";
import { AppealCountModel } from "@timhortons/common/src/models/rev/appeals";
import { LpAppealTable } from "@timhortons/common/src/modules/lp/components/dashboard/appeals";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LpAppealCountModel } from "@timhortons/common/src/models/lp/appeals";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { AppealStatus } from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/appealCard";

interface IProps {
  translate?: any;
  currentTable: LpAppealTable;
  setCurrentTable: Function;
  lpAppealCount: LpAppealCountModel;
  isIndividualLogin: boolean;
  setAppealFilter?: Function;
  userDetails?: IUserDetails;
}
export enum DropDownLabel {
  accepted = "Approved",
  // acceptedReVerification = "Approved Reverification",
  disapproved = "Disapproved"
}
export interface IAppealData {
  userId: number;
  toDate?: Date;
  fromDate?: Date;
  search?: string;
  status?: string;
  appealStatus?: string;
}
export default function LpAppealHeader(props: IProps): ReactElement {
  const {
    translate,
    userDetails,
    currentTable,
    setCurrentTable,
    lpAppealCount,
    setAppealFilter,
    isIndividualLogin
  } = props;
  const [selectedValues, setSelectedValues] = useState({
    status: null,
    fromDate: null,
    toDate: null,
    search: null
    // columnName: ""
  });
  let prevSelectedValues = useRef(selectedValues);
  const [isShowFilter, setShowFilter] = useState(false);
  const [reset, setReset] = useState(false);
  const [statusLabelStyle, setStatusLabelStyle] = useState(null);
  const dropDownValues = [
    {
      value: AppealStatus.approved,
      label: DropDownLabel.accepted
    },
    // {
    //   value: AppealStatus.approvedWithReverification,
    //   label: DropDownLabel.acceptedReVerification
    // },
    {
      value: AppealStatus.disapproved,
      label: DropDownLabel.disapproved
    }
  ];
  const getButtonStyle = (type: LpAppealTable) => {
    let themedStyle: StyleProp<ViewStyle>;
    if (currentTable !== type) {
      themedStyle = styles.inActiveButtonStyle;
    }
    return StyleSheet.flatten([styles.buttonStyle, themedStyle]);
  };

  useEffect(() => {
    let object = {
      ...selectedValues,
      appealStatus: currentTable === LpAppealTable.closedAppeal ? "CLOSED" : "IN_PROCESS"
    };

    if (JSON.stringify(prevSelectedValues.current) !== JSON.stringify(selectedValues)) {
      isIndividualLogin &&
        setAppealFilter({
          userId: userDetails.empId,
          selectedValues: object
        });
    }

    prevSelectedValues.current = selectedValues;
  }, [selectedValues, isIndividualLogin]);

  const selectedStatusHandler = (value: string) => {
    if (value === AppealStatus.approved) {
      setStatusLabelStyle(styles.acceptedLabel);
    } else if (value === AppealStatus.disapproved) {
      setStatusLabelStyle(styles.rejectedLabel);
    } else setStatusLabelStyle(null);
    setSelectedValues((prevState) => ({
      ...prevState,
      status: value
      // columnName: "status"
    }));
  };
  const getTextStyle = (type: LpAppealTable) => {
    let themedStyle: StyleProp<TextStyle>;
    if (currentTable !== type) {
      themedStyle = styles.inActiveTextStyle;
    }
    return StyleSheet.flatten(themedStyle);
  };

  const getAppealCount = (count: number) => {
    if (count > 0) {
      return ` (${count})`;
    } else {
      return ``;
    }
  };
  const buttonLists = [
    {
      id: "1",
      title:
        translate("newAppeal") + getAppealCount(lpAppealCount && lpAppealCount.newAppealsCount),
      onPress: () => {
        setCurrentTable(LpAppealTable.newAppeal);
      },
      buttonStyle: getButtonStyle(LpAppealTable.newAppeal),
      textStyles: getTextStyle(LpAppealTable.newAppeal)
    },
    {
      id: "2",
      title: translate("inProcess") + getAppealCount(lpAppealCount && lpAppealCount.inProcessCount),
      onPress: () => {
        setCurrentTable(LpAppealTable.inProcess);
        resetState();
        setShowFilter(false);
      },
      buttonStyle: getButtonStyle(LpAppealTable.inProcess),
      textStyles: getTextStyle(LpAppealTable.inProcess)
    },
    {
      id: "3",
      title: translate("closed") + getAppealCount(lpAppealCount && lpAppealCount.closedAppealCount),
      onPress: () => {
        setCurrentTable(LpAppealTable.closedAppeal);
        resetState();
        setShowFilter(false);
      },
      buttonStyle: getButtonStyle(LpAppealTable.closedAppeal),
      textStyles: getTextStyle(LpAppealTable.closedAppeal)
    }
  ];
  const newButtonList = isIndividualLogin ? buttonLists.slice(1, 3) : buttonLists;
  const selectedFromDateHandler = (value: any) => {
    setReset(false);
    if (dateFormat(value) !== dateFormat(selectedValues.fromDate)) {
      setSelectedValues((prevState) => ({
        ...prevState,
        fromDate: value && value[0],
        toDate: value && value[1]
        // columnName: "fromDate"
      }));
    }
  };

  const selectedSearchHandler = (value: string) => {
    setReset(false);
    setSelectedValues((prevState) => ({
      ...prevState,
      search: value
      // columnName: "search"
    }));
  };
  const resetState = () => {
    setSelectedValues((prevState) => ({
      ...prevState,
      fromDate: null,
      toDate: null,
      search: null,
      status: null
    }));
    setStatusLabelStyle(null);
    setReset(true);
  };
  const getFilterData = (): Array<IDropDownItem> => {
    let data: IDropDownItem[] = [];
    data = dropDownValues.map((el) => {
      return {
        ...el,
        itemStyle: el.value === AppealStatus.approved ? styles.acceptedLabel : styles.rejectedLabel
      };
    });
    data.unshift({ value: null, label: "Status" });
    return data;
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text
            fontWeight={FontWeight.Regular}
            textSize={TextSize.ExtraLarge}
            testId={testIds.appeal.appealHeader}
            textStyle={styles.headingColor}
          >
            {translate("Appeals")}
          </Text>
          {isIndividualLogin
            ? (isShowFilter && (
                <ShowFiltersView
                  translate={translate}
                  setShowFilter={() => setShowFilter(!isShowFilter)}
                  resetState={resetState}
                />
              )) || (
                <HideFilters
                  translate={translate}
                  setShowFilter={() => setShowFilter(!isShowFilter)}
                />
              )
            : null}
          {isIndividualLogin && (
            <ResetFilters
              resetContainerStyle={styles.resetFilterMargin}
              translate={translate}
              setShowFilter={() => setShowFilter(!isShowFilter)}
              resetState={resetState}
            />
          )}
        </View>
        <View style={styles.dropdownContainer}>
          <ButtonGroup
            buttonLists={newButtonList}
            buttonsType={ButtonType.Secondary}
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.Regular}
          />
        </View>
      </View>
      {isIndividualLogin && isShowFilter ? (
        <View>
          <Filters
            currentTable={currentTable}
            parentCallBackFrom={(value: any) => selectedFromDateHandler(value)}
            // parentCallBackTo={(value: any) => selectedToDateHandler(value)}
            searchFunction={(value: any) => {
              selectedSearchHandler(value);
            }}
            resetHandler={resetState}
            searchValue={selectedValues.search}
            reset={reset}
            allItems={getFilterData()}
            selectedValueDefault={selectedValues.status}
            onChangeCallBack={(obj: IDropDownItem) => selectedStatusHandler(obj.value)}
            selectedItemStyle={statusLabelStyle}
            translate={translate}
            // disableSelectedPrevDate={selectedValues && selectedValues.fromDate}
          />
        </View>
      ) : null}
    </>
  );
}
const styles = StyleSheet.create({
  acceptedLabel: {
    color: colorPallete.green1
  },
  rejectedLabel: {
    color: colorPallete.red3
  },
  dropdownContainer: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  container: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  buttonStyle: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    ...I18nService.select({
      rtl: { marginStart: 24 },
      ltr: { marginStart: 24 }
    }),
    // marginStart: 24,
    borderRadius: 8,
    backgroundColor: colorPallete.variant1,

    borderWidth: 0
  },
  inActiveButtonStyle: {
    backgroundColor: colorPallete.white1
  },
  inActiveTextStyle: {
    color: colorPallete.grey1
  },
  headingColor: {
    color: colorPallete.black3
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  resetFilterMargin: { marginTop: 6 }
});
