import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { IndividualLogActionTableActions } from "@timhortons/common/src/redux/logs/actions/individualActionsTable";
import { IndividualActionsTableRepository } from "@timhortons/common/src/repositories/logs/individualActionsTable";
import {
  IndividualAccessActionsModel,
  IndividualAccessLogsModel,
  LogsIndividualaccessMainDasgboardsPayloads
} from "@timhortons/common/src/models/logs/individualActionTable";
import { ManagerReviewRepository } from "@timhortons/common/src/repositories/logs/managerReview";
import { ManagerReviewTableModel } from "@timhortons/common/src/models/logs/mainLogs";
function* getIndividualLogActionsTableWorkerSaga(
  value: IFluxStandardAction<LogsIndividualaccessMainDasgboardsPayloads>
) {
  const { payload } = value;
  try {
    let data: IndividualAccessActionsModel = yield call(
      IndividualActionsTableRepository.getIndividualActions,
      payload
    );
    yield put(IndividualLogActionTableActions.getIndividualLogActionsTableDataSuccess(data));
  } catch (error) {
    Logger.error("logs-individualActions", error, "getIndividualLogActionsTableWorkerSaga");

    yield put(IndividualLogActionTableActions.getIndividualLogActionsTableDataError(error));
  }
}
function* getIndividualLogsDashboardTableWorkerSaga(
  value: IFluxStandardAction<LogsIndividualaccessMainDasgboardsPayloads>
) {
  const { payload } = value;
  try {
    let data: IndividualAccessLogsModel = yield call(
      IndividualActionsTableRepository.getIndividualLogsDashboard,
      payload
    );
    yield put(IndividualLogActionTableActions.getIndividualLogsDashboardTableDataSuccess(data));
  } catch (error) {
    Logger.error("logs-individualAccess", error, "getIndividualLogsDashboardTableWorkerSaga");

    yield put(IndividualLogActionTableActions.getIndividualLogsDashboardTableDataError(error));
  }
}
function* getLogsStoreSpecificTableWorkerSaga(
  value: IFluxStandardAction<{
    storeCode: number;
    date: string;
    timeZone: string;
    search: string;
    fromIndividualAccess: boolean;
  }>
) {
  const { payload } = value;
  try {
    let data: Array<ManagerReviewTableModel> = yield call(
      ManagerReviewRepository.getManagerReviewTableData,
      payload
    );

    yield put(IndividualLogActionTableActions.getLogsStoreSpecificTableDataSuccess(data));
  } catch (error) {
    Logger.error("logs-individualAccess", error, "getLogsStoreSpecificTableWorkerSaga");

    yield put(IndividualLogActionTableActions.getLogsStoreSpecificTableDataError(error));
  }
}
function* getActionsStoreSpecificTableWorkerSaga(
  value: IFluxStandardAction<{
    storeCode: number;
    date: string;
    timeZone: string;
    search: string;
    fromIndividualAccess: boolean;
  }>
) {
  const { payload } = value;
  try {
    let data: ManagerReviewTableModel = yield call(
      ManagerReviewRepository.getManagerReviewTableData,
      payload
    );

    yield put(IndividualLogActionTableActions.getActionsStoreSpecificTableDataSuccess(data));
  } catch (error) {
    Logger.error("logs-individualAccess", error, "getActionsStoreSpecificTableWorkerSaga");

    yield put(IndividualLogActionTableActions.getActionsStoreSpecificTableDataError(error));
  }
}
function* individualActionsTableWatcherSaga() {
  yield takeLatest(
    LOGS.IndividualLogActions.GET_TABLE_DATA.LOADING,
    getIndividualLogActionsTableWorkerSaga
  );
  yield takeLatest(
    LOGS.IndividualLogActions.GET_LOGS_TABLE_DATA.LOADING,
    getIndividualLogsDashboardTableWorkerSaga
  );
  yield takeLatest(
    LOGS.IndividualLogActions.GET_LOGS_STORE_SPECIFIC_TABLE_DATA.LOADING,
    getLogsStoreSpecificTableWorkerSaga
  );
  yield takeLatest(
    LOGS.IndividualLogActions.GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA.LOADING,
    getActionsStoreSpecificTableWorkerSaga
  );
}
export default individualActionsTableWatcherSaga;
