import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { Any, JsonObject, JsonProperty } from "json2typescript";

export interface GetStoreListByCodeOrNamePayload {
  role: string;
  empId: string;
  keyword: string;
  pageNo: number;
  pageSize: number;
}
@JsonObject("StoreAuditInfoModel")
export class StoreAuditInfoModel {
  @JsonProperty("selfCalibrationId", Number)
  selfCalibrationId: number = 0;
  @JsonProperty("storeName", String)
  location: string = "";
  @JsonProperty("auditorName", String)
  auditorName: string = "";
  @JsonProperty("completed", Boolean)
  isCompleted: boolean = null;
  @JsonProperty("completedPercentage", Number)
  completionPercentage: number = 0;
  @JsonProperty("storeCode", Number, true)
  storeCode?: number = null;
  auditType: AuditType = null;
  @JsonProperty("isZT", Boolean, "IGNORE_NULLABLE")
  isZT?: boolean = false;
}

@JsonObject("CategoryListModel")
export class CategoryListModel {
  @JsonProperty("categoryId", Number)
  id: number = 0;
  @JsonProperty("categoryName", String)
  title: string = "";
  @JsonProperty("categoryLabel", String)
  label: string = "";
  @JsonProperty("percentageCompleted", Number)
  progress: number = 0;
}
@JsonObject("CategoryDataModel")
export class CategoryDataModel {
  @JsonProperty("categoryList", [CategoryListModel])
  categoryList: CategoryListModel[];
  @JsonProperty("percentageCompleted", Number)
  completionPercentage: number = 0;
}

@JsonObject("QuestionListModel")
export class QuestionListModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("questionLabel", String)
  serialNumber: string = "";
  @JsonProperty("question", String)
  questionName: string = "";
  @JsonProperty("status", String, true)
  options: string = "";
  @JsonProperty("ztStatus", String, true)
  ztStatus: string = "";
  @JsonProperty("storeSpecifics", String, true)
  storeSpecifics?: string = "";
  @JsonProperty("isStoreSpecific", Boolean, true)
  isStoreSpecific?: boolean = false;
}
@JsonObject("SubCategoryInfoModel")
export class SubCategoryInfoModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("maxPoints", Number)
  points: number = 0;
  @JsonProperty("questions", [QuestionListModel])
  questionList: QuestionListModel[];
  @JsonProperty("subcategoryLabel", String)
  serialNumber: string = "";
  @JsonProperty("subcategoryName", String)
  title: string = "";
  @JsonProperty("status", Any, true)
  status: any = null;
  @JsonProperty("appealerResponse", Any, true)
  appealerResponse: any = null;
}

@JsonObject("SubCategoryModel")
export class SubCategoryModel {
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("categoryName", String)
  categoryName: string = "";
  @JsonProperty("subCategories", [SubCategoryInfoModel])
  subCategories: SubCategoryInfoModel[];
}

@JsonObject("YesNoDataModel")
export class YesNoDataModel {
  @JsonProperty("percentageCompleted", Number)
  completionPercentage: number = 0;
  @JsonProperty("calibrationId", String)
  selfCalibrationId: string = "";
  @JsonProperty("percentageCompletedByCategory", Number, true)
  categoryPercentage?: number = 0;
}
@JsonObject("TippingPointsDataModel")
export class TippingPointsDataModel {
  @JsonProperty("tippingId", Number)
  tippingId: number = 0;
  @JsonProperty("tippingPoint", String)
  tippingPoint: string = "";
}
@JsonObject("TippingPointsModel")
export class TippingPointsModel {
  @JsonProperty("tippingPoints", [TippingPointsDataModel])
  tippingPoints: TippingPointsDataModel[];
}

@JsonObject("ObservationDataPointsModel")
export class ObservationDataPointsModel {
  @JsonProperty("observation", String)
  name: string = "";
  @JsonProperty("observationId", Number)
  key: number = 0;
  @JsonProperty("questionId", Number)
  id: number = 0;
  @JsonProperty("isCheck", Boolean && null)
  selected: boolean = false;
}

@JsonObject("ObservationDataModel")
export class ObservationDataModel {
  @JsonProperty("questionId", Number)
  questionId: number = 0;
  @JsonProperty("rejectedDataPoints", [ObservationDataPointsModel])
  rejectedDataPoints: ObservationDataPointsModel[] = null;
}

@JsonObject("FileModel")
export class FileModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("signedUrl", String)
  signedUrl: string = "";
  @JsonProperty("blob", Blob, true)
  blob?: Blob = null;
}

@JsonObject("CommentDataModel")
export class CommentDataModel {
  @JsonProperty("subCategoryId", Number)
  subCategoryId?: number = 0;
  @JsonProperty("calibrationId", Number)
  calibrationId?: number = 0;
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("deletedImage", [String], true)
  deletedImage?: string[] = [];
  @JsonProperty("images", [FileModel])
  files: FileModel[] = [];
}

@JsonObject("PostCommentModel")
export class PostCommentModel {
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("calibrationId", Number)
  calibrationId: number = 0;
  @JsonProperty("body", FormData)
  body?: FormData = null;
}

@JsonObject("PostObservationSuccessModel")
export class PostObservationSuccessModel {
  @JsonProperty("createdAt", String)
  createdAt: string = "";
  @JsonProperty("createdBy", String)
  createdBy: string = "";
  @JsonProperty("updatedAt", String)
  updatedAt: string = "";
  @JsonProperty("id", Number)
  id: number = 0;
}

@JsonObject("SosTimerModel")
export class SosTimerModel {
  @JsonProperty("orderId", String)
  orderId: string = "";
  @JsonProperty("timeTaken", String)
  timeTaken: string = "";
}

@JsonObject("SosAverageTimeModel")
export class SosAverageTimeModel {
  @JsonProperty("timerList", [SosTimerModel])
  timerList?: Array<SosTimerModel> = null;
  @JsonProperty("pointsEarned", Number)
  pointsEarned: number = 0;
  @JsonProperty("avgDuration", Number)
  avgDuration: number = 0;
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("status", String)
  status: string = null;
  @JsonProperty("percentageCompleted", Number)
  percentageCompleted: number = 0;
  @JsonProperty("percentageCompletedByCategory", String)
  percentageCompletedByCategory: number = 0;
}

@JsonObject("PreviousAuditDataModel")
export class PreviousAuditDataModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("startTime", Date)
  startTime: Date = null;
  @JsonProperty("auditedBy", String)
  auditedBy: string = null;
  @JsonProperty("completedPercentage", Number)
  completedPercentage: number = null;
  @JsonProperty("storeName", String)
  storeName: string = null;
  @JsonProperty("auditType", String)
  auditType: AuditType = null;
}

@JsonObject("ResumeAuditInfoModel")
export class ResumeAuditInfoModel {
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("selfCalibrationId", Number)
  selfCalibrationId: number = 0;
  @JsonProperty("storeLocation", String)
  location: string = "";
  @JsonProperty("auditedBy", String)
  auditorName: string = "";
  @JsonProperty("isCompleted", Any)
  isCompleted: boolean = null;
  @JsonProperty("completionPercentage", Number)
  completionPercentage: number = 0;
  @JsonProperty("auditType", String)
  auditType: AuditType = null;
  @JsonProperty("isZT", Boolean)
  isZT: boolean = false;
}
