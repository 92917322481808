import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "manageLms";
const MANAGELMS = {
  POST_CREATE_MODULE: ActionHelper.actionTypesCreator(prefix, "POST_CREATE_MODULE"),
  GET_ALL_MODULE: ActionHelper.actionTypesCreator(prefix, "GET_ALL_MODULE"),
  GET_MODULE_BY_ID: ActionHelper.actionTypesCreator(prefix, "GET_MODULE_BY_ID"),
  DELETE_MODULE: ActionHelper.actionTypesCreator(prefix, "DELETE_MODULE"),
  POST_CATEGORY: ActionHelper.actionTypesCreator(prefix, "POST_CATEGORY"),
  GET_ALL_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_CATEGORIES"),
  DELETE_CATEGORY: ActionHelper.actionTypesCreator(prefix, "DELETE_CATEGORY"),
  GET_CATEGORY_BY_ID: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORY_BY_ID"),
  POST_TOPIC: ActionHelper.actionTypesCreator(prefix, "POST_TOPIC"),
  GET_MODULE_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_MODULE_CATEGORIES"),
  UPLOAD_VIDEO: ActionHelper.actionTypesCreator(prefix, "UPLOAD_VIDEO"),
  EDIT_CATEGORY: ActionHelper.actionTypesCreator(prefix, "EDIT_CATEGORY"),
  DELETE_TOPIC: ActionHelper.actionTypesCreator(prefix, "DELETE_TOPIC"),
  GET_TOPIC_BY_ID: ActionHelper.actionTypesCreator(prefix, "GET_TOPIC_BY_ID"),
  GET_PROGRESSBAR_STATUS: ActionHelper.actionTypesCreator(prefix, "GET_PROGRESSBAR_STATUS"),
  POST_PROGRESSBAR_STATUS: ActionHelper.actionTypesCreator(prefix, "POST_PROGRESSBAR_STATUS"),
  GET_SUMMARY_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_SUMMARY_DETAILS"),
  POST_MCQ_TIC_QUIZ: ActionHelper.actionTypesCreator(prefix, "POST_MCQ_TIC_QUIZ"),
  GET_MCQ_TIC_QUIZ: ActionHelper.actionTypesCreator(prefix, "GET_MCQ_TIC_QUIZ"),
  GET_CATGORY_DETAILS_ALL: ActionHelper.actionTypesCreator(prefix, "GET_CATGORY_DETAILS_ALL"),
  EDIT_TOPIC: ActionHelper.actionTypesCreator(prefix, "EDIT_TOPIC"),
  GET_VIDEO_UPLOAD_LINK: ActionHelper.actionTypesCreator(prefix, "GET_VIDEO_UPLOAD_LINK"),
  CONFIRM_VIDEO_UPLOAD: ActionHelper.actionTypesCreator(prefix, "CONFIRM_VIDEO_UPLOAD"),
  POST_SUBTOPIC: ActionHelper.actionTypesCreator(prefix, "POST_SUBTOPIC"),
  EDIT_SUBTOPIC: ActionHelper.actionTypesCreator(prefix, "EDIT_SUBTOPIC"),
  DELETE_SUBTOPIC: ActionHelper.actionTypesCreator(prefix, "DELETE_SUBTOPIC"),
  DELETE_VIDEO: ActionHelper.actionTypesCreator(prefix, "DELETE_VIDEO"),
  UPDATE_MODULE: ActionHelper.actionTypesCreator(prefix, "UPDATE_MODULE"),
  feedback: {
    GET_MODULE_FEEDBACK_BY_ID: ActionHelper.actionTypesCreator(prefix, "GET_MODULE_FEEDBACK_BY_ID"),
    POST_MODULE_FEEDBACK: ActionHelper.actionTypesCreator(prefix, "POST_MODULE_FEEDBACK"),
    UPDATE_FEEDBACK: ActionHelper.actionTypesCreator(prefix, "UPDATE_FEEDBACK"),
    RESET_FEEDBACK_ERROR: "RESET_FEEDBACK_ERROR"
  },
  pts: {
    GET_MODULE_PTS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_MODULE_PTS_DATA"),
    POST_PTS_TOPIC: ActionHelper.actionTypesCreator(prefix, "POST_PTS_TOPIC"),
    UPDATE_PTS_TOPIC: ActionHelper.actionTypesCreator(prefix, "UPDATE_PTS_TOPIC"),
    DELETE_PTS_TOPIC: ActionHelper.actionTypesCreator(prefix, "DELETE_PTS_TOPIC"),
    POST_PTS_CHECKLIST: ActionHelper.actionTypesCreator(prefix, "POST_PTS_CHECKLIST"),
    UPDATE_PTS_CHECKLIST: ActionHelper.actionTypesCreator(prefix, "UPDATE_PTS_CHECKLIST"),
    DELETE_PTS_CHECKLIST: ActionHelper.actionTypesCreator(prefix, "DELETE_PTS_CHECKLIST"),
    RESET_PTS_ERROR: "RESET_PTS_ERROR"
  },
  assessments: {
    GET_MODULE_ASSESSMENTS_PRACTICAL_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_MODULE_ASSESSMENTS_PRACTICAL_DATA"
    ),
    POST_ASSESSMENTS_TOPIC: ActionHelper.actionTypesCreator(prefix, "POST_ASSESSMENTS_TOPIC"),
    UPDATE_ASSESSMENTS_TOPIC: ActionHelper.actionTypesCreator(prefix, "UPDATE_ASSESSMENTS_TOPIC"),
    DELETE_ASSESSMENTS_TOPIC: ActionHelper.actionTypesCreator(prefix, "DELETE_ASSESSMENTS_TOPIC"),
    POST_ASSESSMENTS_CHECKLIST: ActionHelper.actionTypesCreator(
      prefix,
      "POST_ASSESSMENTS_CHECKLIST"
    ),
    UPDATE_ASSESSMENTS_CHECKLIST: ActionHelper.actionTypesCreator(
      prefix,
      "UPDATE_ASSESSMENTS_CHECKLIST"
    ),
    DELETE_ASSESSMENTS_CHECKLIST: ActionHelper.actionTypesCreator(
      prefix,
      "DELETE_ASSESSMENTS_CHECKLIST"
    ),
    GET_LMS_ASSESSMENTS_THEORY_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LMS_ASSESSMENTS_THEORY_DATA"
    ),
    RESET_ASSESSMENTS_ERROR: "RESET_ASSESSMENTS_ERROR"
  },
  POST_MTF_DND_QUIZ: ActionHelper.actionTypesCreator(prefix, "POST_MTF_DND_QUIZ"),
  GET_MTF_DND_QUIZ: ActionHelper.actionTypesCreator(prefix, "GET_MTF_DND_QUIZ"),
  LMS_IMG_UPLOAD: ActionHelper.actionTypesCreator(prefix, "LMS_IMG_UPLOAD"),
  LMS_IMG_DELETE: ActionHelper.actionTypesCreator(prefix, "LMS_IMG_DELETE"),
  DELETE_MCQ_TTT: ActionHelper.actionTypesCreator(prefix, "DELETE_MCQ_TTT"),
  UPDATE_MCQ_TTT: ActionHelper.actionTypesCreator(prefix, "UPDATE_MCQ_TTT"),
  DELETE_MTF_DND: ActionHelper.actionTypesCreator(prefix, "DELETE_MTF_DND"),
  UPDATE_MTF_DND: ActionHelper.actionTypesCreator(prefix, "UPDATE_MTF_DND"),
  LMS_GET_CERTIFICATE: ActionHelper.actionTypesCreator(prefix, "LMS_GET_CERTIFICATE"),
  LMS_POST_CERTIFICATE: ActionHelper.actionTypesCreator(prefix, "LMS_POST_CERTIFICATE"),
  RESET_MODULE_DATA: ActionHelper.actionTypesCreator(prefix, "RESET_MODULE_DATA"),
  LMS_UPDATE_CERTIFICATE: ActionHelper.actionTypesCreator(prefix, "LMS_UPDATE_CERTIFICATE"),
  RESET_QUIZZES_DATA: "RESET_QUIZZES_DATA",
  PUBLISH_MODULE: ActionHelper.actionTypesCreator(prefix, "PUBLISH_MODULE"),
  GET_VIDEO_DATA: ActionHelper.actionTypesCreator(prefix, "GET_VIDEO_DATA"),
  GET_DASHBOARD_MODULES: ActionHelper.actionTypesCreator(prefix, "GET_DASHBOARD_MODULES"),
  RESET_QUIZ_ERROR: ActionHelper.actionTypesCreator(prefix, "RESET_QUIZ_ERROR"),
  RESET_PUBLISH: ActionHelper.actionTypesCreator(prefix, "RESET_PUBLISH"),
  RESET_IMG: ActionHelper.actionTypesCreator(prefix, "RESET_IMG"),
  GET_DASHBOARD_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_DASHBOARD_CATEGORIES"),
  RESET_MODULE_ERROR: "RESET_MODULE_ERROR",
  RESET_DASHBOARD_ERROR: "RESET_DASHBOARD_ERROR",
  RESET_CERTIFICATE_ERROR: "RESET_CERTIFICATE_ERROR"
};

export { MANAGELMS };
