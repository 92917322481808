import { ApiClient } from "@timhortons/common/src/network/apiClient";
import {
  cleaningActionsEndPoints,
  managerWalkthroughActionsEndPoints,
  timeTempActionsEndPoints
} from "@timhortons/common/src/constants/endPoints";
import {
  timeTempFormatter,
  weeklyCleaningActionsFormatter
} from "@timhortons/common/src/repositories/logs/actionLogsFormatter";
import { LOGKeys } from "@timhortons/common/src/constants/apiKeys";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";
import { IUpdateActionsRequest } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { CleaningCategories } from "@timhortons/common/src/modules/logs/dashboards/managerReview/utils/getManagerReviewLogId";

class ActionLogsRepository {
  getActionManagerWalkthroughActions = async (payload: {
    categoryId: string;
    storeCode: number;
    date: string;
    label: string;
    hasResolvedFilter?: boolean;
  }) => {
    let finalData = {
      label: payload.label,
      storeCode: payload.storeCode,
      date: payload.date,
      hasResolvedFilter: payload.hasResolvedFilter
    };
    let resp = await ApiClient.get(
      managerWalkthroughActionsEndPoints.getActionManagerWalkthroughActions(payload.categoryId),
      finalData
    );
    return weeklyCleaningActionsFormatter(resp);
  };

  updateActionManagerWalkthroughActions = async (param: IUpdateActionsRequest) => {
    let finalData = {
      storeCode: param.storeCode,
      questionId: param.itemId,
      label: param.label,
      hasResolved: param.hasResolved,
      contactMaintenanceDate: param.contactMaintenanceDate,
      levelId: param.levelId,
      levelType: param.levelType,
      timeZone: param.timeZone,
      startDate: param.startDate,
      endDate: param.endDate
    };
    let data = await ApiClient.put(
      managerWalkthroughActionsEndPoints.updateManagerWalkthroughActions(),
      {},
      finalData,
      LOGKeys.StoreAccessKeys
    );
    return data;
  };

  updateProductActions = async (param: IUpdateActionsRequest) => {
    let finalData = { ...param };
    delete finalData["hasResolved"];
    let data = await ApiClient.put(timeTempActionsEndPoints.updateProductActions(), finalData, {
      hasResolved: param.hasResolved
    });
    return data;
  };
  getWeeklyCleaningActions = async (param: IProdTempApi) => {
    const { label, storeCode, date, hasResolvedFilter } = param;
    let response = await ApiClient.get(
      cleaningActionsEndPoints.weeklyActions(param.categoryId),
      { label, storeCode, date, hasResolvedFilter },
      LOGKeys.StoreAccessKeys
    );
    return weeklyCleaningActionsFormatter(response);
  };
  updateWeeklyCleaningActions = async (param: IUpdateActionsRequest) => {
    let finalData = {
      storeCode: param.storeCode,
      questionId: param.itemId,
      label: param.label,
      hasResolved: param.hasResolved,
      levelId: param.levelId,
      levelType: param.levelType,
      timeZone: param.timeZone,
      startDate: param.startDate,
      endDate: param.endDate
    };
    let restRoomData = param;
    delete restRoomData["itemId"];
    let data =
      finalData.questionId === CleaningCategories.RestRoom
        ? await ApiClient.put(cleaningActionsEndPoints.restRoomActions(), {}, restRoomData)
        : await ApiClient.put(cleaningActionsEndPoints.dailyCheckListActions(), {}, finalData);
    return data;
  };
  getDailyCleaningItems = async (param: {
    categoryId: string;
    date: string;
    label: string;
    storeCode: string;
  }) => {
    let finalData = { label: param.label, date: param.date, storeCode: param.storeCode };
    let categoryId = param.categoryId;
    delete param["categoryId"];
    let data = await ApiClient.get(
      cleaningActionsEndPoints.getDailyCleaningItems(categoryId),
      finalData
    );

    return weeklyCleaningActionsFormatter(data);
  };
  // updateDailyCheckListActions = async (param: IUpdateActionsRequest) => {
  //   let finalData = {
  //     storeCode: param.storeCode,
  //     questionId: param.itemId,
  //     date: param.date,
  //     label: param.label,
  //     hasResolved: param.hasResolved
  //   };
  //   let response = await ApiClient.put(
  //     cleaningActionsEndPoints.dailyCheckListActions(),
  //     {},
  //     finalData
  //   );
  //   return response;
  // };

  getRestRoomCleaningActions = async (param: {
    categoryId: string;
    date: string;
    label: string;
    storeCode: string;
    hasResolvedFilter?: string;
  }) => {
    const { label, storeCode, date, hasResolvedFilter } = param;
    let response = await ApiClient.get(
      cleaningActionsEndPoints.getRestRoomCleaningActions(param.categoryId),
      { label, storeCode, date, hasResolvedFilter },
      LOGKeys.StoreAccessKeys
    );
    return weeklyCleaningActionsFormatter(response);
  };

  getProductTempActions = async (param: {
    tabId: string;
    shift: string;
    storeCode: number;
    startDate: string;
    isCategory?: boolean;
    hasResolvedFilter?: boolean;
  }) => {
    const { tabId, shift, storeCode, startDate, hasResolvedFilter } = param;
    let finalData = {
      label: shift,
      storeCode: storeCode,
      date: startDate,
      hasResolvedFilter: hasResolvedFilter
    };
    let data = param.isCategory
      ? await ApiClient.get(timeTempActionsEndPoints.getEquipmentTempActions(tabId), finalData)
      : await ApiClient.get(timeTempActionsEndPoints.getProductTempActions(tabId), finalData);
    return timeTempFormatter(data);
  };
  getStationReadItems = async (param: {
    categoryId: string;
    label: string;
    storeCode: string;
    date: string;
    hasResolvedFilter?: Boolean;
  }) => {
    let finalData = {
      label: param.label,
      date: param.date,
      storeCode: param.storeCode,
      hasResolvedFilter: param.hasResolvedFilter
    };
    let categoryId = param.categoryId;
    let data = await ApiClient.get(
      cleaningActionsEndPoints.getDailyCleaningItems(categoryId),
      finalData
    );
    return weeklyCleaningActionsFormatter(data);
  };
  updateStationReadActions = async (param: IUpdateActionsRequest) => {
    let finalData = {
      storeCode: param.storeCode,
      questionId: param.itemId,
      label: param.label,
      hasResolved: param.hasResolved,
      levelId: param.levelId,
      levelType: param.levelType,
      timeZone: param.timeZone,
      startDate: param.startDate,
      endDate: param.endDate
    };
    let response = await ApiClient.put(
      cleaningActionsEndPoints.dailyCheckListActions(),
      {},
      finalData
    );
    return response;
  };
}

const actionLogsRepository = new ActionLogsRepository();
export { actionLogsRepository as ActionLogsRepository };
