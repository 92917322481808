import { AreaCountryStorePayload } from "./../actions/calendarActions";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { CalendarModuleRepository } from "@timhortons/common/src/repositories/calendar/calendarModule";
import { CALENDAR } from "../actions/actionTypes";
import { CalendarAction, CreateEvent } from "../actions/calendarActions";

function* getAllEventsWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(CalendarModuleRepository.getAllEvents, payload);
    yield put(CalendarAction.getEventsSuccess(response));
  } catch (error) {
    yield put(CalendarAction.getEventsError(error));
  }
}

function* getEventById(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(CalendarModuleRepository.getEventById, payload);
    yield put(CalendarAction.getEventByIdSuccess(response));
  } catch (error) {
    yield put(CalendarAction.getEventByIdError(error));
  }
}

function* getAllGccCountriesWorkerSaga() {
  try {
    let response: any = yield call(CalendarModuleRepository.getGccCountries);
    yield put(CalendarAction.getGccCountriesSuccess(response));
  } catch (error) {
    yield put(CalendarAction.getGccCountriesError(error));
  }
}

function* createEventWorkerSaga(value: IFluxStandardAction<CreateEvent>) {
  try {
    const { payload } = value;
    yield call(CalendarModuleRepository.createEvent, payload);
    yield put(CalendarAction.createEventsSuccess({}));
  } catch (error) {
    yield put(CalendarAction.createEventsError(error));
  }
}

function* getAreaAndCountryManagers(value: IFluxStandardAction<AreaCountryStorePayload>) {
  try {
    const { payload } = value;
    let response = yield call(CalendarModuleRepository.getAreaCountryManagers, payload);
    yield put(CalendarAction.getAreaAndCountryManagersSuccess(response));
  } catch (error) {
    yield put(CalendarAction.getAreaAndCountryManagersError(error));
  }
}

function* getStores(value: IFluxStandardAction<AreaCountryStorePayload>) {
  try {
    const { payload } = value;
    let response = yield call(CalendarModuleRepository.getStores, payload);
    yield put(CalendarAction.getStoresSuccess(response));
  } catch (error) {
    yield put(CalendarAction.getStoresError(error));
  }
}

function* getEmployees(value: IFluxStandardAction<[]>) {
  try {
    const { payload } = value;
    let response = yield call(CalendarModuleRepository.getEmployees, payload);
    yield put(CalendarAction.getEmployeesSuccess(response));
  } catch (error) {
    yield put(CalendarAction.getEmployeesError(error));
  }
}

function* deleteEvent(value: IFluxStandardAction<{ eventId: string }>) {
  try {
    const { payload } = value;
    yield call(CalendarModuleRepository.deleteEvent, payload);
    yield put(CalendarAction.deleteEventSuccess({}));
  } catch (error) {
    yield put(CalendarAction.deleteEventError(error));
  }
}

function* updateEventWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    yield call(CalendarModuleRepository.updateEvent, payload);
    yield put(CalendarAction.updateEventSuccess({}));
  } catch (error) {
    yield put(CalendarAction.updateEventError(error));
  }
}

export default function* calendarModuleWatcherSaga() {
  yield takeEvery(CALENDAR.GET_ALL_EVENTS.LOADING, getAllEventsWorkerSaga);
  yield takeEvery(CALENDAR.GET_GCC_COUNTRIES.LOADING, getAllGccCountriesWorkerSaga);
  yield takeEvery(CALENDAR.CREATE_EVENT.LOADING, createEventWorkerSaga);
  yield takeEvery(CALENDAR.GET_AREA_COUNTRY_MANAGER.LOADING, getAreaAndCountryManagers);
  yield takeEvery(CALENDAR.GET_STORES.LOADING, getStores);
  yield takeEvery(CALENDAR.GET_EMPLOYEES.LOADING, getEmployees);
  yield takeLatest(CALENDAR.DELETE_EVENT.LOADING, deleteEvent);
  yield takeLatest(CALENDAR.UPDATE_EVENT.LOADING, updateEventWorkerSaga);
  yield takeLatest(CALENDAR.GET_EVENTBYID.LOADING, getEventById);
}
