import { all } from "@redux-saga/core/effects";
import manageLmsModuleWatcherSaga from "@timhortons/common/src/redux/manageLms/saga/manageLmsModuleWatcherSaga";
import lmsFeedbackWatcherSaga from "@timhortons/common/src/redux/manageLms/saga/lmsFeedbackWatcherSaga";
import lmsPtsWatcherSaga from "@timhortons/common/src/redux/manageLms/saga/lmsPtsWatcherSaga";
import lmsAssessmentsWatcherSaga from "@timhortons/common/src/redux/manageLms/saga/lmsAssessmentsWatcherSaga";

export default function* manageLmsRootSaga() {
  yield all([
    manageLmsModuleWatcherSaga(),
    lmsFeedbackWatcherSaga(),
    lmsPtsWatcherSaga(),
    lmsAssessmentsWatcherSaga()
  ]);
}
