// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Storage } from "./index";
class StorageService {
  getStorage = async <T>(key: string): Promise<T | null> => {
    const value: string | null = await Storage.getItem(key);
    if (value) return JSON.parse(value);
    else return null;
  };
  async setStorage(key: string, value: any) {
    return Storage.setItem(key, JSON.stringify(value));
  }
  clearStorage() {
    return Storage.clear();
  }
  removeStorage(key: string) {
    return Storage.removeItem(key);
  }
}
const storage = new StorageService();
export { storage as LocalStorage };
