import React, { useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import AuditModal from "@timhortons/common/src/modules/common/organisms/authModal/index";
import { NewAppealsTable } from "@timhortons/common/src/models/rev/appeals";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { IAppealsError, IAppealsLoading } from "@timhortons/common/src/redux/rev/reducers/appeals";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
interface IProps {
  newAppealsTableData: NewAppealsTable[];
  translate: any;
  onNewAppealClick: Function;
  appealCount?: number;
  getNewAppeals: Function;
  setModalState: () => void;
  modalVisible: boolean;
  loading: IAppealsLoading;
  error: IAppealsError;
}
const getHeaderComp = (headerItem: any, index: number) => {
  return (
    <HeaderCell
      key={index}
      columnSelected={headerItem.selected}
      title={headerItem.name}
      sortable={headerItem.isSortable}
      columnName={headerItem.name}
      fontWeight={FontWeight.Regular}
      textSize={TextSize.Regular}
      titleStyle={{ color: colorPallete.grey1 }}
      sortFunction={(val: string) => headerItem.sortFunction(val, headerItem.name)}
    />
  );
};
function NewAppealTable(props: IProps): React.ReactElement {
  const {
    newAppealsTableData,
    translate,
    onNewAppealClick,
    getNewAppeals,
    setModalState,
    modalVisible,
    loading,
    error
  } = props;
  const newAppealsTableHeader = [
    { name: translate("storeId"), isSortable: false },
    { name: translate("storeNameTable"), isSortable: false },
    { name: translate("auditorName"), isSortable: false },
    { name: translate("auditDate"), isSortable: false },
    { name: translate("appeal"), isSortable: false }
  ];
  const [currentCalibrationId, setCurrentCalibrationId] = useState<number>(0);
  const getStoreId = async () => {
    const storeDetail: { storeCode: number } = await LocalStorage.getStorage("storeCode");
    return storeDetail?.storeCode;
  };

  const handleAppealSubmit = async (empID: string) => {
    onNewAppealClick(currentCalibrationId, empID);
  };

  const getAppeals = async () => {
    let store = await getStoreId();
    getNewAppeals({ storeId: store, status: "new" });
  };

  const handleModalOpen = (id: number) => {
    setCurrentCalibrationId(id);
    setModalState();
  };
  const isFocused = useFocusedHook();
  useEffect(() => {
    isFocused && getAppeals();
  }, [isFocused]);

  return (
    <View style={{ flex: 1 }}>
      <Text
        fontWeight={FontWeight.Regular}
        textSize={TextSize.ExtraRegular}
        testId={testIds.appeal.noAudits}
        textStyle={styles.subHeadingColor}
      >
        {newAppealsTableData && newAppealsTableData.length > 0
          ? translate("selectAudit")
          : translate("noAudits")}
      </Text>
      <Table>
        <Row
          header
          rowStyle={{
            backgroundColor: colorPallete.grey9,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }}
        >
          {newAppealsTableHeader.map((headerItem, index) => getHeaderComp(headerItem, index))}
        </Row>
        {error.getNewAppeals ? (
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        ) : loading.getNewAppeals ? (
          <Loader type="component" />
        ) : newAppealsTableData && newAppealsTableData.length > 0 ? (
          <View
            style={{
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8
            }}
          >
            {newAppealsTableData &&
              newAppealsTableData.map((appeal, index) => (
                <Row key={index}>
                  <Cell title={appeal.storeId} />
                  <Cell title={appeal.storeName} />
                  <Cell title={appeal.auditorName} />
                  <Cell title={dateFormat(appeal.auditDate)} />

                  <Cell>
                    <TouchableOpacity
                      style={styles.thunderIconView}
                      onPress={() => handleModalOpen(appeal.calibrationId)}
                    >
                      <Icon
                        size={16}
                        name={IconNames.thunder}
                        customStyle={styles.thunderIconStyle}
                      />
                    </TouchableOpacity>
                  </Cell>
                </Row>
              ))}
          </View>
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noAvailableAudits")} />
        )}
        {modalVisible && (
          <GetModal setModalState={setModalState}>
            <AuditModal
              handleAuth={(empID: string) => handleAppealSubmit(empID)}
              translate={translate}
              modalVisible={modalVisible}
              setModalState={setModalState}
              isIndividualLogin={false}
              isAppeal={true}
            />
          </GetModal>
        )}
      </Table>
    </View>
  );
}

const styles = StyleSheet.create({
  thunderIconView: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 6,
    backgroundColor: colorPallete.variant1
  },

  thunderIconStyle: {
    color: colorPallete.red2,
    // fontSize: 16,
    // lineHeight: 24
    marginVertical: 5
  },
  subHeadingColor: {
    color: colorPallete.black4
    // paddingTop: PlatformUtils.isWeb() ? 0 : 20,
    // paddingBottom: PlatformUtils.isWeb() ? 0 : 15
  }
});

export default NewAppealTable;
