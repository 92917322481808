import React, { ReactElement } from "react";
import { Modal, StyleSheet, View, StyleProp, ViewStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

interface IProps {
  type?: ModalAnimationType;
  customStyle?: StyleProp<ViewStyle>;
  transparent?: boolean;
  setModalState: Function;
  children: React.ReactNode;
}

export enum ModalAnimationType {
  Slide = "slide",
  None = "none",
  Fade = "fade"
}

export const GetModal = (props: IProps): ReactElement => {
  const {
    customStyle,
    transparent = true,
    type = ModalAnimationType.Fade,
    setModalState,
    children
  } = props;

  return (
    // NEW CHANGE, NOT TESTED PROPERLY
    <Modal
      animationType={type}
      transparent={transparent}
      visible={true}
      onRequestClose={() => {
        setModalState();
      }}
    >
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps={"always"}
        contentContainerStyle={[styles.container, styles.bgColor]}
      >
        <View style={styles.container}>
          <View style={[styles.centerSubContainer, customStyle]}>{children}</View>
        </View>
      </KeyboardAwareScrollView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centerSubContainer: {
    backgroundColor: colorPallete.white3,
    borderRadius: 8
  },
  container: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center"
  },
  bgColor: {
    backgroundColor: colorPallete.variant6
  }
});
