import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "rev";
const REV = {
  GET_STORELIST_BY_CODE_OR_NAME: ActionHelper.actionTypesCreator(
    prefix,
    "GET_STORELIST_BY_CODE_OR_NAME"
  ),
  GET_STORELIST_BY_CODE_OR_NAME_LISTEND: "REV/GET_STORELIST_BY_CODE_OR_NAME_LISTEND",
  RESET_STORE_LIST_BYSTORE_CODENAME: "REV/RESET_STORE_LIST_BYSTORE_CODENAME",
  GET: ActionHelper.actionTypesCreator(prefix, "GET_TESTAUDITREJECTDATA"),
  CATEGORY_DATA: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORY_DATA"),
  UPDATE_CATEGORY_DATA: ActionHelper.actionTypesCreator(prefix, "UPDATE_CATEGORY_DATA"),
  REPORTS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_REPORTS_DATA"),
  ACKNOWLEDGEMENT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_ACKNOWLEDGEMENT_DATA"),
  GET_REJECT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_TESTAUDITREJECTDATA"),
  CHECK_AUTH_ID: ActionHelper.actionTypesCreator(prefix, "CHECK_AUTH_ID"),
  GET_STORE_AUDIT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_STORE_AUDIT_DATA"),
  GET_SUB_CATEGORY_DATA: ActionHelper.actionTypesCreator(prefix, "GET_SUB_CATEGORY_DATA"),
  GET_DETAILED_REPORT_ID: ActionHelper.actionTypesCreator(prefix, "GET_DETAILED_REPORT_ID"),
  // GET_ACTION_PLANS: ActionHelper.actionTypesCreator(prefix, "GET_ACTION_PLANS"),
  GET_FILTER_DATA: ActionHelper.actionTypesCreator(prefix, "GET_FILTER_DATA"),
  GET_SELECTED_FILTER_DATA: ActionHelper.actionTypesCreator(prefix, "GET_SELECTED_FILTER_DATA"),
  ANSWER_YES_NO_NA_DATA: ActionHelper.actionTypesCreator(prefix, "ANSWER_YES_NO_NA_DATA"),
  GET_OBSERVATION_DATA: ActionHelper.actionTypesCreator(prefix, "GET_OBSERVATION_DATA"),
  POST_OBSERVATION_DATA: ActionHelper.actionTypesCreator(prefix, "POST_OBSERVATION_DATA"),
  SUBMIT_AUDIT: ActionHelper.actionTypesCreator(prefix, "SUBMIT_AUDIT"),
  PREPARE_REPORT: ActionHelper.actionTypesCreator(prefix, "PREPARE_REPORT"),
  GET_TIPPING_POINTS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_TIPPING_POINTS_DATA"),
  UPDATE_COMPLETION: ActionHelper.actionTypesCreator(prefix, "UPDATE_COMPLETION"),
  GET_PREVIOUS_AUDIT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_PREVIOUS_AUDIT_DATA"),
  DELETE_AUDIT: ActionHelper.actionTypesCreator(prefix, "DELETE_AUDIT"),

  GET_CATEGORY_STATUS: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORY_STATUS"),
  GET_OWNER_DATA: ActionHelper.actionTypesCreator(prefix, "GET_OWNER_DATA"),
  RESUME_AUDIT: ActionHelper.actionTypesCreator(prefix, "RESUME_AUDIT"),

  GET_APPEALS_COUNT: ActionHelper.actionTypesCreator(prefix, "GET_APPEALS_COUNT"),
  GET_NEW_APPEALS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_NEW_APPEALS_DATA"),
  GET_IN_PROCESS_APPEALS_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "GET_IN_PROCESS_APPEALS_DATA "
  ),
  GET_CLOSED_APPEALS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_CLOSED_APPEALS_DATA"),
  GET_APPEAL_ACCORDION_DATA: ActionHelper.actionTypesCreator(prefix, "GET_APPEAL_ACCORDION_DATA"),
  POST_COMMENT_DATA: ActionHelper.actionTypesCreator(prefix, "POST_COMMENT_DATA"),
  UPDATE_COMMENT_DATA: ActionHelper.actionTypesCreator(prefix, "UPDATE_COMMENT_DATA"),
  RESET_AUTH_ID: "REV/CHECK_AUTH_ID/RESET",
  SET_APPEAL_DECISON_DATA: ActionHelper.actionTypesCreator(prefix, "SET_APPEAL_DECISON_DATA"),
  POST_SOS_AVG_TIME: ActionHelper.actionTypesCreator(prefix, "POST_SOS_AVG_TIME"),
  POST_SOS_AVG_TIME_RESET: ActionHelper.actionTypesCreator(prefix, "POST_SOS_AVG_TIME_RESET"),
  GET_SOS_AVG_TIME: ActionHelper.actionTypesCreator(prefix, "GET_SOS_AVG_TIME"),
  DELETE_SOS: ActionHelper.actionTypesCreator(prefix, "DELETE_SOS"),
  POST_ACKNOWLEDGEMENT: ActionHelper.actionTypesCreator(prefix, "POST_ACKNOWLEDGEMENT"),
  GET_SELECTED_MONTH_FILTER: ActionHelper.actionTypesCreator(prefix, "GET_SELECTED_MONTH_FILTER"),
  GET_EXTERNAL_META: ActionHelper.actionTypesCreator(prefix, "GET_EXTERNAL_META"),
  POST_EXTERNAL_AUDIT: ActionHelper.actionTypesCreator(prefix, "POST_EXTERNAL_AUDIT"),
  RESET_ACTION_PLAN: "REV/RESET_ACTION_PLAN/RESET",
  ActionPlans: {
    GET_ACTION_PLANS: ActionHelper.actionTypesCreator(prefix, "GET_ACTION_PLANS"),
    UPDATE_ACTION_PLAN_STATUS: ActionHelper.actionTypesCreator(prefix, "UPDATE_ACTION_PLAN_STATUS"),
    GET_ACTION_PLAN_ACCORDION_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_ACTION_PLAN_ACCORDION_DATA"
    ),
    POST_ACTION_STEPS: ActionHelper.actionTypesCreator(prefix, "POST_ACTION_STEPS"),
    GET_ACTION_PLAN_HEADER_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_ACTION_PLAN_HEADER_DATA"
    )
  },
  Appeals: {
    CREATE_NEW_APPEAL: ActionHelper.actionTypesCreator(prefix, "CREATE_NEW_APPEAL"),
    RESET_APPEAL_ERROR: "REV/RESET_APPEAL_ERROR"
  },
  GET_COMMENT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_COMMENT_DATA"),
  RESET_REPORT_ID: "REV/CHECK_REPORT_ID/RESET",
  SET_APPEAL_FILTER: ActionHelper.actionTypesCreator(prefix, "SET_APPEAL_FILTER"),
  SET_CLOSED_APPEAL_FILTER: ActionHelper.actionTypesCreator(prefix, "SET_CLOSED_APPEAL_FILTER"),
  POST_APPEAL_COMMENT_DATA: ActionHelper.actionTypesCreator(prefix, "POST_APPEAL_COMMENT_DATA"),
  GET_APPEALS_CATEGORY_DATA: ActionHelper.actionTypesCreator(prefix, "GET_APPEALS_CATEGORY_DATA"),
  RESET_APPEAL_AUTH_ID: "REV/RESET_APPEAL_AUTH_ID/RESET",
  GET_REPORT_TABLE_DATA: ActionHelper.actionTypesCreator(prefix, "GET_REPORT_TABLE_DATA"),
  GET_STORE_ACCESS_SUMMARY_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "GET_STORE_ACCESS_SUMMARY_DATA"
  ),
  GET_STORE_ACCESS_OPPORTUNITY_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "GET_STORE_ACCESS_OPPORTUNITY_DATA"
  ),
  GET_INDIVIDUAL_SUMMARY_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "GET_INDIVIDUAL_SUMMARY_DATA"
  ),
  GET_COUNTRYMANAGER_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_COUNTRYMANAGER_DETAILS"),
  ReportBuilder: {
    GET_ALL_COUNTRIES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_COUNTRIES"),
    GET_ALL_COUNTRY_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_ALL_COUNTRY_MANAGER"),
    GET_ALL_AREA_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_ALL_AREA_MANAGER"),
    GET_ALL_CHANNELS: ActionHelper.actionTypesCreator(prefix, "GET_ALL_CHANNELS"),
    GET_ALL_STORES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_STORES"),
    GENERATE_REPORT: ActionHelper.actionTypesCreator(prefix, "GENERATE_REPORT"),
    GET_COMPARISON_COUNTRY_MANAGERS_ADMIN: ActionHelper.actionTypesCreator(
      prefix,
      "GET_COMPARISON_COUNTRY_MANAGERS_ADMIN"
    ),
    GET_COMPARISON_AREA_MANAGERS_ADMIN: ActionHelper.actionTypesCreator(
      prefix,
      "GET_COMPARISON_AREA_MANAGERS_ADMIN"
    ),
    GET_COMPARISON_STORES_ADMIN: ActionHelper.actionTypesCreator(
      prefix,
      "GET_COMPARISON_STORES_ADMIN"
    ),
    GET_HIGHLOW_CHANNELS_ADMIN: ActionHelper.actionTypesCreator(
      prefix,
      "GET_HIGHLOW_CHANNELS_ADMIN"
    ),
    RESET_REPORT_BUILDER: "REV/RESET_REPORT_BUILDER/RESET"
  }
};

export { REV };
