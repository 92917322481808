import { combineReducers } from "redux";
import {
  IMainMenuState,
  mainMenuReducer
} from "@timhortons/common/src/redux/productionSheet/reducers/mainMenu";
import {
  IManagerReviewState,
  managerReviewProductionSheetReducer
} from "@timhortons/common/src/redux/productionSheet/reducers/productionSheetManagerReview";
import {
  productionSheet,
  IProductionSheetReducerState
} from "@timhortons/common/src/redux/productionSheet/reducers/productionSheet";
import {
  IProductionReportsState,
  productionReportsReducer
} from "@timhortons/common/src/redux/productionSheet/reducers/reportsReducer";

export interface IProductionSheetState {
  managerReview: IManagerReviewState;
  mainMenu: IMainMenuState;
  mainProductionSheet: IProductionSheetReducerState;
  productionSheetReport: IProductionReportsState;
}

export const productionSheetReducer = combineReducers({
  managerReview: managerReviewProductionSheetReducer,
  mainMenu: mainMenuReducer,
  mainProductionSheet: productionSheet,
  productionSheetReport: productionReportsReducer
});
