import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { ConfigHelper } from "@timhortons/common/src/utils/loadEnv.util";
import { rollBarObjWeb, rollBarObjTab, rollBarObjMob } from "./rollBarObject";

enum LogLevel {
  Debug = "debug",
  Info = "info",
  Error = "error"
}

const loggerErrorMessageFormatter = (moduleName: string, error: string, functionName: string) =>
  JSON.stringify({
    moduleName: moduleName,
    error: error ? error : "INTERNAL_SERVER_ERROR",
    functionName: functionName
  });

const loggerDebugMessageFormatter = (moduleName: string, data: any, functionName: string) =>
  JSON.stringify({
    moduleName: moduleName,
    data: data,
    functionName: functionName
  });
class Logger {
  public debug = (moduleName: string, data: any, functionName: string): void => {
    const message = loggerDebugMessageFormatter(moduleName, data, functionName);
    this.logWithLevel(LogLevel.Debug, message);
  };
  public info = (message: string): void => {
    this.logWithLevel(LogLevel.Info, message);
  };
  public error = (moduleName: string, error: any, functionName: string): void => {
    let message = loggerErrorMessageFormatter(moduleName, error, functionName);

    this.logWithLevel(LogLevel.Error, message);
  };
  private logWithLevel = (logLevel: LogLevel, message: string): void => {
    if (ConfigHelper.getNodeEnv() === "production" && PlatformUtils.isWeb()) {
      rollBarObjWeb[logLevel.toString() as LogLevel](message);
    } else if (PlatformUtils.isTab()) {
      rollBarObjTab[logLevel.toString() as LogLevel](message);
    } else if (PlatformUtils.isMobile()) {
      rollBarObjMob[logLevel.toString() as LogLevel](message);
    } else {
      rollBarObjWeb[logLevel.toString() as LogLevel](message);
    }
  };
}
const logger = new Logger();
export { logger as Logger };
