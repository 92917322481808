import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "userLms";

const UserLMS = {
  GET_LMS_MODULE_LIST: ActionHelper.actionTypesCreator(prefix, "GET_LMS_MODULE_LIST"),
  GET_LMS_CERTIFICATE_CAROUSEL: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LMS_CERTIFICATE_CAROUSEL"
  ),
  GET_CONTINUE_LEARNING: ActionHelper.actionTypesCreator(prefix, "GET_CONTINUE_LEARNING"),
  GET_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORIES"),
  GET_TOTAL_EARNED_POINTS: ActionHelper.actionTypesCreator(prefix, "GET_TOTAL_EARNED_POINTS"),
  GET_TOPICS_SUBTOPICS: ActionHelper.actionTypesCreator(prefix, "GET_TOPICS_SUBTOPICS"),
  UPDATE_VIDEO_STATUS: ActionHelper.actionTypesCreator(prefix, "UPDATE_VIDEO_STATUS"),
  GET_TOTAL_DAYS_LEFT: ActionHelper.actionTypesCreator(prefix, "GET_TOTAL_DAYS_LEFT"),
  GET_QUIZ_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_QUIZ_DETAILS"),
  RESET_QUIZ_DETAILS: "RESET_QUIZ_DETAILS",
  UPDATE_VIDEO_ACTION: ActionHelper.actionTypesCreator(prefix, "UPDATE_VIDEO_ACTION"),
  UPDATE_VIDEO_CURRENTTIME: ActionHelper.actionTypesCreator(prefix, "UPDATE_VIDEO_CURRENTTIME"),
  POST_QUIZ_RESPONSE: ActionHelper.actionTypesCreator(prefix, "POST_QUIZ_RESPONSE"),
  GET_FAQS: ActionHelper.actionTypesCreator(prefix, "GET_FAQS"),
  GET_FAQ_SEARCH_RESULT: ActionHelper.actionTypesCreator(prefix, "GET_FAQ_SEARCH_RESULT"),
  GET_ENQUIRIES: ActionHelper.actionTypesCreator(prefix, "GET_ENQUIRIES"),
  POST_QUESTION: ActionHelper.actionTypesCreator(prefix, "POST_QUESTION"),
  GET_TIC_TAC_TOE_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_TIC_TAC_TOE_DETAILS"),
  SUBMIT_TIC_TAC_TOE_QUIZ: ActionHelper.actionTypesCreator(prefix, "SUBMIT_TIC_TAC_TOE_QUIZ"),
  GET_DRAG_DROP_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_DRAG_DROP_DETAILS"),
  SUBMIT_MTFDND_QUIZ: ActionHelper.actionTypesCreator(prefix, "SUBMIT_MTFDND_QUIZ"),
  GET_EVENTS: ActionHelper.actionTypesCreator(prefix, "GET_EVENTS"),
  GET_EVENT_DETAIL: ActionHelper.actionTypesCreator(prefix, "GET_EVENT_DETAIL"),
  GET_DAY_EVENTS: ActionHelper.actionTypesCreator(prefix, "GET_DAY_EVENTS"),
  GET_THEORITICAL_ASSESSMENTS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_THEORITICAL_ASSESSMENTS"
  ),
  GET_NEWS_LIST: ActionHelper.actionTypesCreator(prefix, "GET_NEWS_LIST"),
  GET_NEWS_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_NEWS_DETAILS"),
  RESET_NEWS_LIST: "RESET_NEWS_LIST",
  RESET_NEWS_DETAILS: "RESET_NEWS_DETAILS",
  GET_LEARNING_PATH_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_LEARNING_PATH_DETAILS"),
  RESET_LEARNING_PATH_DETAILS: "RESET_LEARNING_PATH_DETAILS",
  GET_STATISTICS: ActionHelper.actionTypesCreator(prefix, "GET_STATISTICS"),
  GET_SPECIAL_MODULES: ActionHelper.actionTypesCreator(prefix, "GET_SPECIAL_MODULES"),
  GET_MODULE_DETAIL: ActionHelper.actionTypesCreator(prefix, "GET_MODULE_DETAIL"),
  SUBMIT_ASSESSMENT: ActionHelper.actionTypesCreator(prefix, "SUBMIT_ASSESSMENT"),
  EXIT_ASSESSMENT: ActionHelper.actionTypesCreator(prefix, "EXIT_ASSESSMENT"),
  RESET_SUBMIT_ASSESSMENT: ActionHelper.actionTypesCreator(prefix, "RESET_SUBMIT_ASSESSMENT"),
  RESET_MTF_DND_QUIZ: "RESET_MTF_DND_QUIZ",
  RESET_QUIZ_RESPONSE: ActionHelper.actionTypesCreator(prefix, "RESET_QUIZ_RESPONSE"),
  userLmsPTS: {
    GET_PTS_CHECKLIST: ActionHelper.actionTypesCreator(prefix, "GET_PTS_CHECKLIST"),
    PTS_CHECKLIST_SELECT: ActionHelper.actionTypesCreator(prefix, "PTS_CHECKLIST_SELECT"),
    PTS_CHECLIST_UNSELECT: ActionHelper.actionTypesCreator(prefix, "PTS_CHECLIST_UNSELECT")
  },
  RESET_ASSESMENT_ERROR: ActionHelper.actionTypesCreator(prefix, "RESET_ASSESMENT_ERROR"),
  GET_ASSESSMENT_RESULT: ActionHelper.actionTypesCreator(prefix, "GET_ASSESSMENT_RESULT"),
  RESET_DAY_EVENT: "RESET_DAY_EVENT",
  RESET_GET_ASSESSMENT_ERROR: "RESET_GET_ASSESSMENT_ERROR",
  GET_LEADERBOARD_LIST: ActionHelper.actionTypesCreator(prefix, "GET_LEADERBOARD_LIST"),
  GET_TIMBIT: ActionHelper.actionTypesCreator(prefix, "GET_TIMBIT"),
  GET_USER_NOTIFICATIONS: ActionHelper.actionTypesCreator(prefix, "GET_USER_NOTIFICATIONS"),
  SAVE_USER_NOTIFICATION: ActionHelper.actionTypesCreator(prefix, "SAVE_USER_NOTIFICATION"),
  EDIT_PROFILE: ActionHelper.actionTypesCreator(prefix, "EDIT_PROFILE"),
  PROFILE_UPDATED: "PROFILE_UPDATED",
  RESET_EDIT_PROFILE: "RESET_EDIT_PROFILE",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
  DELETE_ASSESSMENT: ActionHelper.actionTypesCreator(prefix, "DELETE_ASSESSMENT"),
  RESET_ASSESSMENT_DATA: ActionHelper.actionTypesCreator(prefix, "RESET_ASSESSMENT_DATA")
};

export { UserLMS };
