import { combineReducers } from "redux";
import {
  revReportsReducer,
  IRevReportsState
} from "@timhortons/common/src/redux/rev/reducers/reportsReducer";
import {
  ISelfCalibrationState,
  selfCalibrationReducer
} from "@timhortons/common/src/redux/rev/reducers/selfCalibration";
import {
  ICategoryState,
  categoryReducer
} from "@timhortons/common/src/redux/rev/reducers/category";
import {
  actionPlansReducer,
  IActionPlansState
} from "@timhortons/common/src/redux/rev/reducers/actionPlans";
import { appealsReducer, IAppealsState } from "./appeals";

export interface IRevState {
  selfCalibration: ISelfCalibrationState;
  category: ICategoryState;
  reports: IRevReportsState;
  actionPlan: IActionPlansState;
  appeals: IAppealsState;
}
export const revReducer = combineReducers({
  reports: revReportsReducer,
  selfCalibration: selfCalibrationReducer,
  category: categoryReducer,
  actionPlan: actionPlansReducer,
  appeals: appealsReducer
});
