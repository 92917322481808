import { StyleSheet } from "react-native";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";

export const styles = StyleSheet.create({
  overallContainer: {
    flex: 1,
    flexDirection: "row",
    marginVertical: 30,
    marginHorizontal: 30
  },
  equipmentCard: {
    marginTop: 24,
    borderColor: colorPallete.grey6,
    borderWidth: 1,
    borderRadius: 8
  },
  indexContainer: { marginTop: 6 },
  horizontalDivider: {
    ...I18nService.select({
      rtl: { marginStart: 80 },
      ltr: { marginStart: 80 }
    })
  },
  contentContainer: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 6,
    ...I18nService.select({
      rtl: { marginStart: 30 },
      ltr: { marginStart: 30 }
    })
  },
  titleContainer: {
    flexDirection: "column"
  },
  buttonContainer: {
    flexDirection: "column",
    alignItems: "center"
  },
  dataContainer: {
    width: "30%",
    flexDirection: "column",
    alignItems: "center"
  },
  loading: {
    alignSelf: "stretch",
    paddingVertical: 6,
    backgroundColor: colorPallete.white2,
    borderRadius: 8,
    marginHorizontal: 30
  },
  productTemp: {
    color: colorPallete.black3
  },
  title: {
    justifyContent: "center"
  },
  subTitle: {
    marginTop: 29,
    justifyContent: "center"
  },
  divider: {
    borderColor: colorPallete.grey6,
    width: "100%"
  },
  button: {
    width: 36,
    height: 36,
    borderRadius: 24,
    backgroundColor: colorPallete.grey9,
    borderWidth: 0,
    ...I18nService.select({
      rtl: { marginEnd: 30 },
      ltr: { marginEnd: 30 }
    })
  },
  acceptButton: {
    backgroundColor: colorPallete.red1
  },
  nAButton: {
    backgroundColor: colorPallete.yellow1
  },
  iconStyle: {
    color: colorPallete.grey2
  },
  selectedIconStyle: {
    color: colorPallete.white3
  },
  textStyle: {
    color: colorPallete.grey2
  },
  buttonGroup: {
    paddingTop: 16,
    paddingStart: 30,
    alignSelf: "flex-end"
  },
  inRangeStyles: {
    borderColor: colorPallete.green1,
    borderWidth: 1,
    borderRadius: 8,
    alignSelf: "stretch",
    paddingVertical: 6,
    backgroundColor: colorPallete.white2,
    marginHorizontal: 30
  },
  outOfRangeStyles: {
    borderColor: colorPallete.red3,
    borderWidth: 1,
    borderRadius: 8,
    alignSelf: "stretch",
    paddingVertical: 6,
    backgroundColor: colorPallete.white2,
    marginHorizontal: 30
  },
  commentContainer: {
    marginHorizontal: 75,
    flexDirection: "row"
  },
  commentHeaderIconStyle: {
    color: colorPallete.red2
  },
  commentHeaderTextStyle: {
    ...I18nService.select({
      rtl: { marginStart: 12 },
      ltr: { marginStart: 12 }
    }),
    color: colorPallete.grey1
  },
  comment: {
    flexDirection: "column",
    marginHorizontal: 30,
    color: colorPallete.black3
  },
  commentText: {
    marginVertical: 20,
    paddingHorizontal: 45
  },
  approveDropdown: { marginHorizontal: 78, marginBottom: 24 }
});
