import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import {
  LpAuditInfoModel,
  AuditStoresListByCodeOrName,
  LpPreviousAuditDataModel,
  LpSubCategoryModel,
  LpYesNoDataModel,
  LpObservationDataModel,
  LpCommentDataModel,
  LpTippingPointsModel,
  LpPostObservationSuccessModel
} from "@timhortons/common/src/models/lp/audits";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
export interface IAuditLoading {
  lpCheckAuth: boolean;
  getLpAuditStoreListByCodeOrName: boolean;
  lpSubCategoryData: boolean;
  lpPreviousAuditData: boolean;
  deleteLpAudit: boolean;
  lpUpdateCompletion: boolean;
  lpAnsweringYesNoResponse: boolean;
  lpObservationData: boolean;
  lpCommentData: boolean;
  tippingPoints: boolean;
  postLpObservationSelection: boolean;
  getLpCommentData: boolean;
}
export interface IAuditError {
  lpCheckAuth: string;
  getLpAuditStoreListByCodeOrName: string;
  lpPreviousAuditData: IFluxStandardAction<Error>;
  deleteLpAudit: IFluxStandardAction<Error>;
  lpSubCategoryData: IFluxStandardAction<Error>;
  lpUpdateCompletion: IFluxStandardAction<Error>;
  lpAnsweringYesNoResponse: IFluxStandardAction<Error>;
  lpObservationData: IFluxStandardAction<Error>;
  lpCommentData: IFluxStandardAction<Error>;
  tippingPoints: IFluxStandardAction<Error>;
  postLpObservationSelection: string;
  getLpCommentData: IFluxStandardAction<Error>;
}
export interface IAuditState {
  lpCheckAuth: LpAuditInfoModel;
  error: IAuditError;
  loading: IAuditLoading;
  getLpAuditStoreListByCodeOrName: AuditStoresListByCodeOrName;
  lpPreviousAuditData: LpPreviousAuditDataModel;
  lpSubCategoryData: LpSubCategoryModel;
  tippingPoints: LpTippingPointsModel;
  lpUpdateCompletion: number;
  lpAnsweringYesNoResponse: LpYesNoDataModel;
  lpObservationData: LpObservationDataModel[];
  postLpObservationSelection: LpPostObservationSuccessModel;
  lpCommentData: LpCommentDataModel;
  getLpCommentData: LpCommentDataModel[];
}

export const lpInitialState: IAuditState = {
  lpCheckAuth: null,
  getLpAuditStoreListByCodeOrName: null,
  lpPreviousAuditData: null,
  lpSubCategoryData: null,
  tippingPoints: null,
  lpUpdateCompletion: null,
  lpAnsweringYesNoResponse: null,
  lpObservationData: [],
  postLpObservationSelection: null,
  lpCommentData: null,
  getLpCommentData: [],
  error: {
    lpCheckAuth: null,
    getLpAuditStoreListByCodeOrName: null,
    lpPreviousAuditData: null,
    deleteLpAudit: null,
    lpSubCategoryData: null,
    lpUpdateCompletion: null,
    lpAnsweringYesNoResponse: null,
    lpObservationData: null,
    lpCommentData: null,
    tippingPoints: null,
    postLpObservationSelection: null,
    getLpCommentData: null
  },
  loading: {
    lpCheckAuth: false,
    getLpAuditStoreListByCodeOrName: false,
    lpPreviousAuditData: false,
    deleteLpAudit: false,
    lpSubCategoryData: false,
    lpUpdateCompletion: false,
    lpAnsweringYesNoResponse: false,
    lpObservationData: false,
    lpCommentData: false,
    tippingPoints: false,
    postLpObservationSelection: false,
    getLpCommentData: false
  }
};

export const auditReducer = (
  state = lpInitialState,
  action: { payload: any; type: string }
): IAuditState => {
  switch (action.type) {
    case LP.CHECK_LP_AUDIT_AUTH_ID.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpCheckAuth: true },
        error: { ...state.error, lpCheckAuth: null }
      };
    case LP.CHECK_LP_AUDIT_AUTH_ID.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpCheckAuth: false },
        lpCheckAuth: action.payload
      };
    case LP.CHECK_LP_AUDIT_AUTH_ID.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpCheckAuth: false },
        error: { ...state.error, lpCheckAuth: action.payload }
      };

    case LP.RESET_LP_AUTH_ID:
      state = lpInitialState;
      return state;

    case LP.GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpAuditStoreListByCodeOrName: true },
        error: { ...state.error, getLpAuditStoreListByCodeOrName: null }
      };

    case LP.GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpAuditStoreListByCodeOrName: false },
        error: { ...state.error, getLpAuditStoreListByCodeOrName: null },
        getLpAuditStoreListByCodeOrName: action.payload
      };
    case LP.GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpAuditStoreListByCodeOrName: true },
        error: { ...state.error, getLpAuditStoreListByCodeOrName: null }
      };

    case LP.GET_LP_PREVIOUS_AUDIT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpPreviousAuditData: true },
        error: { ...state.error, lpPreviousAuditData: null, lpCheckAuth: null }
      };
    case LP.GET_LP_PREVIOUS_AUDIT_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpPreviousAuditData: false },
        lpPreviousAuditData: action.payload[0]
      };
    case LP.GET_LP_PREVIOUS_AUDIT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpPreviousAuditData: false },
        error: { ...state.error, lpPreviousAuditData: action.payload }
      };
    case LP.DELETE_LP_AUDIT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteLpAudit: true },
        error: { ...state.error, deleteLpAudit: null }
      };
    case LP.DELETE_LP_AUDIT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteLpAudit: false },
        lpPreviousAuditData: null
      };
    case LP.DELETE_LP_AUDIT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteLpAudit: false },
        error: { ...state.error, deleteLpAudit: action.payload }
      };
    case LP.GET_LP_SUB_CATEGORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpSubCategoryData: true },
        lpSubCategoryData: null,
        error: { ...state.error, lpSubCategoryData: null }
      };
    case LP.GET_LP_SUB_CATEGORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpSubCategoryData: false },
        lpSubCategoryData: action.payload
      };
    case LP.GET_LP_SUB_CATEGORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpSubCategoryData: false },
        error: { ...state.error, lpSubCategoryData: action.payload }
      };
    case LP.GET_TIPPING_POINTS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, tippingPoints: true },
        error: { ...state.error, tippingPoints: null }
      };
    case LP.GET_TIPPING_POINTS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, tippingPoints: false },
        tippingPoints: action.payload
      };
    case LP.GET_TIPPING_POINTS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, tippingPoints: false },
        error: { ...state.error, tippingPoints: action.payload }
      };
    case LP.LP_ANSWER_YES_NO_NA_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpAnsweringYesNoResponse: true },
        error: { ...state.error, lpAnsweringYesNoResponse: null }
      };

    case LP.LP_ANSWER_YES_NO_NA_DATA.SUCCESS:
      let subCategories = [...state.lpSubCategoryData.lpSubCategories];
      const subCatIndex = subCategories.findIndex(
        (el) => el.id === action.payload?.metaData?.subCategoryId
      );
      const quesIndex = subCategories[subCatIndex].questionList.findIndex(
        (el) => el.id === action.payload?.metaData?.questionId
      );
      if (quesIndex > -1 && subCatIndex > -1) {
        subCategories[subCatIndex].questionList[quesIndex].options =
          action.payload?.metaData?.status;
      }
      state.lpSubCategoryData.lpSubCategories = subCategories;
      return {
        ...state,
        lpSubCategoryData: state.lpSubCategoryData,
        loading: { ...state.loading, lpAnsweringYesNoResponse: false },
        lpAnsweringYesNoResponse: action.payload.apiRes
      };

    case LP.LP_ANSWER_YES_NO_NA_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpAnsweringYesNoResponse: false },
        error: { ...state.error, lpAnsweringYesNoResponse: action.payload }
      };
    case LP.LP_UPDATE_COMPLETION.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpUpdateCompletion: false },
        lpUpdateCompletion:
          state &&
          state.lpAnsweringYesNoResponse &&
          state.lpAnsweringYesNoResponse.completionPercentage
      };
    case LP.GET_LP_OBSERVATION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpObservationData: true },
        error: { ...state.error, lpObservationData: null }
      };
    case LP.GET_LP_OBSERVATION_DATA.SUCCESS:
      let datum = [...state.lpObservationData];
      const qIndex = datum.findIndex((obj) => obj.questionId === action.payload.questionId);
      if (qIndex > -1) {
        datum[qIndex] = action.payload;
      } else {
        datum.push(action.payload);
      }
      return {
        ...state,
        loading: { ...state.loading, lpObservationData: false },
        lpObservationData: datum
      };
    case LP.GET_LP_OBSERVATION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpObservationData: false },
        error: { ...state.error, lpObservationData: action.payload }
      };
    case LP.LP_POST_OBSERVATION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postLpObservationSelection: true },
        error: { ...state.error, postLpObservationSelection: null }
      };
    case LP.LP_POST_OBSERVATION_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postLpObservationSelection: false },
        postLpObservationSelection: action.payload
      };
    case LP.LP_POST_OBSERVATION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postLpObservationSelection: false },
        error: { ...state.error, postLpObservationSelection: action.payload }
      };
    case LP.UPDATE_LP_COMMENT_DATA.LOADING:
    case LP.POST_LP_COMMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpCommentData: true },
        error: { ...state.error, lpCommentData: null }
      };
    case LP.UPDATE_LP_COMMENT_DATA.SUCCESS:
    case LP.POST_LP_COMMENT_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpCommentData: false },
        error: { ...state.error, lpCommentData: null }
        // commentData: action.payload,
        // getCommentData: feedbackData
      };
    case LP.UPDATE_LP_COMMENT_DATA.ERROR:
    case LP.POST_LP_COMMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpCommentData: false },
        error: { ...state.error, lpCommentData: action.payload }
      };
    case LP.GET_LP_COMMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpCommentData: true },
        error: { ...state.error, getLpCommentData: null }
      };
    case LP.GET_LP_COMMENT_DATA.SUCCESS:
      let datam = [...state.getLpCommentData];
      const subCategoryIndex = datam.findIndex(
        (obj) =>
          obj.subCategoryId === action.payload.subCategoryId &&
          obj.questionId === action.payload.questionId
      );
      if (subCategoryIndex > -1) {
        datam[subCategoryIndex] = action.payload;
      } else {
        datam.push(action.payload);
      }
      return {
        ...state,
        loading: { ...state.loading, getLpCommentData: false },
        getLpCommentData: datam
      };
    case LP.GET_LP_COMMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpCommentData: false },
        error: { ...state.error, getLpCommentData: action.payload }
      };
    default:
      return state;
  }
};
export default auditReducer;
