import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import {
  ActionPlanTableModel,
  ActionPlanHeaderModel,
  ActionPlanCategoryDataModel,
  ActionPlanAccordionDataModel,
  SelectedFilterDataModel,
  FilterDataModel
} from "@timhortons/common/src/models/rev/actionPlan";
import { CategoryStatusModel } from "@timhortons/common/src/models/rev/appeals";
import { AcknowledgementModel } from "@timhortons/common/src/models/rev/reports";
export interface IActionPlansLoading {
  getActionPlans: boolean;
  getFilterData: boolean;
  getActionPlanHeader: boolean;
  getCategoryStaus: boolean;
  getActionPlanAccordion: boolean;
  getOwnerData: boolean;
  postActionSteps: boolean;
}
export interface IActionPlansError {
  getActionPlans: string;
  getFilterData: string;
  getActionPlanHeader: string;
  getCategoryStaus: string;
  getActionPlanAccordion: string;
  getOwnerData: string;
  postActionSteps: string;
}
export interface IActionPlansState {
  actionPlansList: ActionPlanTableModel;
  filterData: FilterDataModel;
  selectedFilterData: SelectedFilterDataModel;
  actionPlanHeaderData: ActionPlanHeaderModel;
  categoryStatusData: CategoryStatusModel;
  actionPlanDetailsData: ActionPlanHeaderModel;
  categoryTable: ActionPlanCategoryDataModel[];
  actionPlanAccordianData: ActionPlanAccordionDataModel[];
  appealAccordionBodyData: ActionPlanAccordionDataModel[];
  ownerData: AcknowledgementModel;
  loading: IActionPlansLoading;
  error: IActionPlansError;
  postActionStepsData: any;
}
export const actionPlansInitalState: IActionPlansState = {
  actionPlansList: null,
  filterData: null,
  selectedFilterData: null,
  actionPlanHeaderData: null,
  categoryStatusData: null,
  actionPlanAccordianData: [],
  actionPlanDetailsData: null,
  categoryTable: [],
  ownerData: null,
  appealAccordionBodyData: null,
  postActionStepsData: null,
  loading: {
    getActionPlans: false,
    getFilterData: false,
    getActionPlanHeader: false,
    getCategoryStaus: false,
    getActionPlanAccordion: false,
    getOwnerData: false,
    postActionSteps: false
  },
  error: {
    getActionPlans: null,
    getFilterData: null,
    getActionPlanHeader: null,
    getCategoryStaus: null,
    getActionPlanAccordion: null,
    getOwnerData: null,
    postActionSteps: null
  }
};
export const actionPlansReducer = (
  state = actionPlansInitalState,
  action: { payload: any; type: string }
): IActionPlansState => {
  switch (action.type) {
    case REV.ActionPlans.GET_ACTION_PLANS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionPlans: true },
        error: { ...state.error, getActionPlans: null }
      };
    case REV.ActionPlans.GET_ACTION_PLANS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getActionPlans: false },
        error: { ...state.error, getActionPlans: null },
        actionPlansList: action.payload
      };
    case REV.ActionPlans.GET_ACTION_PLANS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionPlans: false },
        error: { ...state.error, getActionPlans: action.payload }
      };
    case REV.GET_FILTER_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getFilterData: true },
        error: { ...state.error, getFilterData: null }
      };
    case REV.GET_FILTER_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getFilterData: false },
        error: { ...state.error, getFilterData: null },
        filterData: action.payload
      };
    case REV.GET_FILTER_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getFilterData: false },
        error: { ...state.error, getFilterData: action.payload }
      };

    case REV.GET_SELECTED_FILTER_DATA.SUCCESS:
      return {
        ...state,
        selectedFilterData: action.payload
      };
    case REV.ActionPlans.GET_ACTION_PLAN_HEADER_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionPlanHeader: true },
        error: { ...state.error, getActionPlanHeader: null }
      };
    case REV.ActionPlans.GET_ACTION_PLAN_HEADER_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getActionPlanHeader: false },
        error: { ...state.error, getActionPlanHeader: null },
        actionPlanDetailsData: action.payload
      };
    case REV.ActionPlans.GET_ACTION_PLAN_HEADER_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionPlanHeader: false },
        error: { ...state.error, getActionPlanHeader: action.payload }
      };
    case REV.GET_CATEGORY_STATUS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCategoryStaus: true },
        error: { ...state.error, getCategoryStaus: null }
      };
    case REV.GET_CATEGORY_STATUS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCategoryStaus: false },
        error: { ...state.error, getCategoryStaus: null },
        categoryTable: action.payload
      };
    case REV.GET_CATEGORY_STATUS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCategoryStaus: false },
        error: { ...state.error, getCategoryStaus: action.payload }
      };
    case REV.ActionPlans.GET_ACTION_PLAN_ACCORDION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionPlanAccordion: true },
        error: { ...state.error, getActionPlanAccordion: null }
      };
    case REV.ActionPlans.GET_ACTION_PLAN_ACCORDION_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getActionPlanAccordion: false },
        error: { ...state.error, getActionPlanAccordion: null },
        actionPlanAccordianData: action.payload
      };
    case REV.ActionPlans.GET_ACTION_PLAN_ACCORDION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionPlanAccordion: false },
        error: { ...state.error, getActionPlanAccordion: action.payload }
      };
    case REV.GET_OWNER_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getOwnerData: true },
        error: { ...state.error, getOwnerData: null }
      };
    case REV.GET_OWNER_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getOwnerData: false },
        error: { ...state.error, getOwnerData: null },
        ownerData: action.payload
      };
    case REV.GET_OWNER_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getOwnerData: false },
        error: { ...state.error, getOwnerData: action.payload }
      };
    case REV.ActionPlans.POST_ACTION_STEPS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postActionSteps: true },
        error: { ...state.error, postActionSteps: null }
      };
    case REV.ActionPlans.POST_ACTION_STEPS.SUCCESS:
      let headerData = state.actionPlanDetailsData;
      let finalData = action.payload;
      let accordianData = state.actionPlanAccordianData;
      headerData.completionPercent = finalData && finalData.data.completedPercentage;
      headerData.inProcessPercent = finalData && finalData.data.inProgressPercentage;
      headerData.notStartedPercent = finalData && finalData.data.notStartedPercentage;
      for (let item of accordianData) {
        if (item.id === finalData.payload.subCategoryId) {
          item.actionSteps = finalData.data.nonConformanceComment;
          item.status = finalData.data.itemStatus;
          item.endDate = finalData.data.endDate;
          item.ownerData = finalData.data.assignTo;
        }
      }
      return {
        ...state,
        loading: { ...state.loading, postActionSteps: false },
        error: { ...state.error, postActionSteps: null },
        postActionStepsData: finalData,
        actionPlanAccordianData: accordianData,
        actionPlanDetailsData: headerData
      };
    case REV.RESET_ACTION_PLAN:
      state = actionPlansInitalState;
      return state;
    case REV.ActionPlans.POST_ACTION_STEPS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postActionSteps: false },
        error: { ...state.error, postActionSteps: action.payload }
      };
    default:
      return state;
  }
};
