import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import Loader from "@timhortons/common/src/components/molecules/loader/index";
import { HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { LpAppealTable } from "@timhortons/common/src/modules/lp/components/dashboard/appeals/index";
import StoreAccessLpAppealCard from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/storeAccessLpAppealCard";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import LpTableheader from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/lpTableHeader";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LpInProcesssClosedTable as StoreAccessInProcessTable } from "@timhortons/common/src/models/lp/appeals";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import { styles } from "modules/lp/organisms/commentBox";

interface IProps {
  translate: any;
  currentTable: LpAppealTable;
  appealsTableData: StoreAccessInProcessTable[];
  getInProcessAppealsData: Function;
  getClosedAppealsData: Function;
  isIndividualLogin: boolean;
  userDetails?: IUserDetails;
  appealsTableDataLoading: boolean;
  error: string;
}

export default function LpInProcessAppealTable(props: IProps): ReactElement {
  const {
    userDetails,
    translate,
    currentTable,
    appealsTableData,
    appealsTableDataLoading,
    getInProcessAppealsData,
    getClosedAppealsData,
    isIndividualLogin,
    error
  } = props;
  const appealTableHeader = [
    {
      name: translate("storeId")
    },
    {
      name: translate("storeNameTable")
    },
    {
      name: translate("appealDate")
    },
    {
      name:
        currentTable === LpAppealTable.inProcess
          ? translate("appealSubmittedBy")
          : translate("appealApprovedBy")
    }
  ];
  const individualAccessAppealTableHeader = [
    ...appealTableHeader.slice(0, 2),
    { name: translate("Appeal Date") },
    appealTableHeader[3],
    { name: translate("view") }
  ];
  const isFocused = useFocusedHook();
  useEffect(() => {
    if (currentTable === LpAppealTable.inProcess) {
      isFocused &&
        getInProcessAppealsData({ storeId: userDetails.storeCode, status: "in_process" });
    } else if (currentTable === LpAppealTable.closedAppeal) {
      isFocused && getClosedAppealsData({ storeId: userDetails.storeCode, status: "closed" });
    }
  }, [currentTable, isFocused]);

  const getHeaderComp = (headerItem: any, index: number): ReactElement => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={tableStyles.headerCellColor}
        cellStyle={tableStyles.headerCellStyle}
      />
    );
  };

  const generateInitialValue = (value: number) => {
    let initState = [];
    for (let i = 0; i < value; i++) {
      initState.push({
        view: false
      });
    }
    return initState;
  };
  const [toggleIcon, setToggleIcon] = useState(
    generateInitialValue(appealsTableData && appealsTableData.length)
  );
  const setToggleCard = (index: number) => {
    let temp = [...toggleIcon];
    temp.forEach((val, i) => {
      if (i === index) {
        val.view = !val.view;
      }
    });
    setToggleIcon(temp);
  };

  useEffect(() => {
    setToggleIcon(generateInitialValue(appealsTableData && appealsTableData.length));
  }, [currentTable, appealsTableData]);

  return (
    <View>
      <Table>
        <Row header rowStyle={tableStyles.tableRowStyle}>
          {isIndividualLogin
            ? individualAccessAppealTableHeader.map((headerItem, index) =>
                getHeaderComp(headerItem, index)
              )
            : appealTableHeader.map((headerItem, index) => getHeaderComp(headerItem, index))}
        </Row>
        {error ? (
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        ) : appealsTableDataLoading ? (
          <Loader type="component" />
        ) : appealsTableData && appealsTableData.length > 0 ? (
          <View style={tableStyles.tableHeaderContainer}>
            {appealsTableData.map((data, index) => {
              return (
                <View key={index}>
                  <LpTableheader
                    key={index}
                    cellStyle={tableStyles.headerCellStyle}
                    data={data}
                    translate={translate}
                    isIndividualLogin={isIndividualLogin}
                    index={index}
                    viewIconStyle={tableStyles.viewIconStyles}
                    iconOnPress={() => setToggleCard(index)}
                    toggleIcon={toggleIcon}
                    currentTable={currentTable}
                  />
                  {((index < toggleIcon.length && toggleIcon && toggleIcon[index].view) ||
                    !isIndividualLogin) &&
                    data.items.map((item, itemKey) => (
                      <Row key={itemKey} rowStyle={tableStyles.appealCardRowStyle}>
                        <StoreAccessLpAppealCard
                          userDetails={userDetails}
                          appealCardData={item}
                          translate={translate}
                          currentTable={currentTable}
                        />
                      </Row>
                    ))}
                </View>
              );
            })}
          </View>
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noAvailableAudits")} />
        )}
      </Table>
    </View>
  );
}

export const tableStyles = StyleSheet.create({
  appealCardRowStyle: {
    paddingHorizontal: 30,
    paddingBottom: 24
  },
  headerCellStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 30 },
      ltr: { marginStart: 30 }
    })
    // marginStart: 30
  },
  viewIconStyles: {
    justifyContent: "center"
  },
  tableHeaderContainer: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8
  },
  tableRowStyle: {
    backgroundColor: colorPallete.grey9,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  headerCellColor: { color: colorPallete.grey1 }
});
