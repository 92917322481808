import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";

interface ModuleHeaderProps {
  title: string;
  subTitle?: string;
  backBtnHandler?: Function;
  containerStyle?: StyleProp<ViewStyle>;
}

export default function ModuleHeader(props: ModuleHeaderProps): React.ReactElement {
  const { title, subTitle = null, backBtnHandler, containerStyle } = props;
  return (
    <View style={[styles.header, containerStyle]}>
      {backBtnHandler && (
        <Button
          iconName={IconNames.back}
          buttonStyles={styles.backButton}
          iconStyles={styles.backIcon}
          iconSize={20}
          type={ButtonType.Secondary}
          onPress={() => backBtnHandler()}
        />
      )}
      <View>
        <Text
          textSize={TextSize.ExtraLarge}
          fontWeight={FontWeight.SemiBold}
          testId={`reportBuilder-main-header1`}
          textStyle={styles.headerTitle}
        >
          {title}
        </Text>
        {subTitle && (
          <Text
            textSize={TextSize.Large}
            fontWeight={FontWeight.Regular}
            testId={`reportBuilder-main-header2`}
            textStyle={styles.subTitle}
          >
            {subTitle}
          </Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 20
  },
  headerTitle: {
    marginBottom: 6,
    color: colorPallete.black3
  },
  subTitle: {
    textTransform: "capitalize",
    color: colorPallete.grey1
  },
  backButton: {
    width: 48,
    height: 48,
    backgroundColor: colorPallete.white1,
    borderWidth: 0,
    borderRadius: 50,
    ...I18nService.select({
      rtl: { marginEnd: 20 },
      ltr: { marginEnd: 20 }
    })
  },
  backIcon: {
    color: colorPallete.grey2
  }
});
