import React, { ReactElement, useEffect, useState } from "react";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Webcam from "react-webcam";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";

interface Props {
  children: React.ReactNode;
  sendFiles: Function;
  parentCallBack: Function;
}

export default function CameraAccess(props: Props): ReactElement {
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageSources, setImageSources] = useState([]);
  const [userfacingMode, setUserFacingMode] = React.useState<boolean>(true);
  const { children, sendFiles, parentCallBack } = props;

  const webcamRef = React.useRef(null);

  const videoConstraints = {
    width: 800,
    height: 520
  };

  useEffect(() => {
    if (imageSrc) {
      let newArray = [...imageSources];
      newArray.push(imageSrc);
      setImageSources(newArray);
    }
  }, [imageSrc]);

  const onUserMediaError = (error: any) => {
    setShowError(true);
  };

  const onUserMedia = (stream: any) => {
    setShowError(false);
  };

  const handleImageCapture = React.useCallback(() => {
    const src = webcamRef.current.getScreenshot({ width: 800, height: 520 });
    setImageSrc(src);
    setShowCamera(false);
    parentCallBack(true);
    sendFiles(src);
  }, [webcamRef]);

  const handleModeChange = React.useCallback(() => {
    setUserFacingMode(!userfacingMode);
  }, []);

  const handleCameraClick = () => {
    setShowCamera(!showCamera);
    parentCallBack(showCamera);
  };

  return (
    <>
      <TouchableOpacity onPress={handleCameraClick}>{children}</TouchableOpacity>

      {showCamera && !showError && (
        <GetModal setModalState={handleCameraClick}>
          <View style={styles.changeModeButtonContainer}>
            <Button
              onPress={handleCameraClick}
              type={ButtonType.Secondary}
              iconName={IconNames.close}
              buttonStyles={styles.flipButton}
              iconSize={18}
            />
          </View>
          <Webcam
            audio={false}
            height={520}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={0.6}
            width={800}
            videoConstraints={{
              ...videoConstraints,
              facingMode: userfacingMode ? "user" : "environment"
            }}
            onUserMediaError={onUserMediaError}
            onUserMedia={onUserMedia}
          />
          <View style={styles.captureButtonContainer}>
            <Button
              onPress={handleImageCapture}
              type={ButtonType.Primary}
              iconName={IconNames.camera}
              buttonStyles={styles.captureButton}
              iconSize={28}
            />
            <Button
              onPress={handleModeChange}
              type={ButtonType.Primary}
              iconName={IconNames.cameraSwitch}
              buttonStyles={styles.captureButton}
              iconSize={28}
            />
          </View>
        </GetModal>
      )}
      {showError && (
        <AlertModal
          setModalVisible={() => setShowError(!showError)}
          errorMsg={"To take photos, allow access to your computer's camera."}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  imgElement: {
    height: 120,
    width: 120,
    ...I18nService.select({
      rtl: { marginEnd: 20 },
      ltr: { marginEnd: 20 }
    }),
    // marginEnd: 20,
    borderRadius: 8
  },
  elementsContainer: {
    flexDirection: "row"
  },
  closeElement: {
    width: 24,
    height: 24,
    marginTop: 5,
    // marginStart: -44,
    ...I18nService.select({
      rtl: { marginStart: -44 },
      ltr: { marginStart: -44 }
    }),
    color: colorPallete.white3
  },
  captureButtonContainer: {
    alignSelf: "center",
    flexDirection: "row"
  },
  changeModeButtonContainer: {
    alignSelf: "flex-end",
    flexDirection: "row"
  },
  captureButton: {
    padding: 16,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    margin: 8,
    marginTop: -32
  },
  flipButton: {
    padding: 8,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    margin: 8
  }
});
