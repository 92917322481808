/* eslint-disable react/no-unused-state */
import React, { Component, ErrorInfo, ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Logger } from "@timhortons/common/src/services/logger";
import "@timhortons/common/src/hoc/errorBoundary/errorBoundary.scss";
import { ScrollView, StyleSheet, View } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";

interface IProps extends WithTranslation {
  children: ReactNode;
}
interface IErrorState {
  hasError: boolean;
}

interface IState {
  error: Error;
  errorInfo: ErrorInfo;
  showDetails: boolean;
}
class ErrorBoundary extends Component<IProps, IState> {
  public state: IState & IErrorState = {
    hasError: false,
    error: {
      name: "",
      message: "",
      stack: ""
    },
    errorInfo: {
      componentStack: ""
    },
    showDetails: false
  };
  public static getDerivedStateFromError(_: Error): IErrorState {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo
    });
    Logger.error(JSON.stringify(error.stack ?? error));
  }
  public render(): ReactNode {
    const { hasError, errorInfo, error } = this.state;
    const { children, t } = this.props;
    if (hasError) {
      return (
        <ScrollView>
          <View style={styles.errorContainer}>
            <Text
              fontWeight={FontWeight.Bold}
              textSize={TextSize.ExtraLarge}
              testId={testIds.error}
              textStyle={styles.errorText}
            >
              {t("errorMessage")}
            </Text>
            <Text
              fontWeight={FontWeight.Light}
              textSize={TextSize.Medium}
              testId={testIds.error}
              textStyle={styles.secondaryText}
            >
              {error.name}
            </Text>
            <Text
              fontWeight={FontWeight.Regular}
              textSize={TextSize.Large}
              testId={testIds.error}
              textStyle={styles.primaryText}
            >
              {error.message}
            </Text>
            <Button
              type={ButtonType.Secondary}
              iconName={IconNames.down}
              iconSize={10}
              iconStyles={styles.detailsIcon}
              onPress={() =>
                this.setState({
                  showDetails: !this.state.showDetails
                })
              }
              buttonStyles={styles.detailsButton}
            >
              <View style={{ marginVertical: 20 }}>
                <Text
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.Medium}
                  testId={testIds.error}
                  textStyle={styles.primaryText}
                >
                  {/* {errorInfo.componentStack} */}
                  {"Details"}
                </Text>
              </View>
            </Button>
            {this.state.showDetails && (
              <Text
                fontWeight={FontWeight.Regular}
                textSize={TextSize.Small}
                testId={testIds.error}
                textStyle={styles.primaryText}
              >
                {errorInfo.componentStack}
              </Text>
            )}
          </View>
        </ScrollView>
      );
    }
    return children;
  }
}
export default withTranslation()(ErrorBoundary);
const styles = StyleSheet.create({
  errorContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    margin: 20,
    marginTop: 50
  },
  errorText: {
    color: colorPallete.red1
  },
  detailsButton: {
    borderColor: colorPallete.white3
  },
  secondaryText: {
    color: colorPallete.grey2,
    paddingVertical: 20
  },
  primaryText: {
    color: colorPallete.black1
  },
  detailsIcon: {
    paddingHorizontal: 10
  }
});
