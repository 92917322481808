import React, { ReactElement } from "react";
import { StyleProp, StyleSheet, ViewStyle, TextStyle, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { GradientBackground } from "@timhortons/common/src/components/molecules/GradientBackground";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary"
}

export enum IconPositions {
  Right = "right",
  Left = "left"
}
interface IProps {
  type: ButtonType;
  onPress?: () => void;
  disabled?: boolean;
  activeOpacity?: number;
  title?: string | number;
  textSize?: TextSize;
  fontWeight?: FontWeight;
  children?: React.ReactNode;
  iconName?: IconNames;
  iconSize?: number;
  iconPosition?: IconPositions;
  buttonStyles?: StyleProp<ViewStyle>;
  textStyles?: StyleProp<TextStyle>;
  iconStyles?: StyleProp<TextStyle>;
  isGradientButton?: boolean;
}

export default function Button(props: IProps): ReactElement {
  const {
    onPress,
    type,
    title,
    iconName,
    iconSize,
    buttonStyles,
    disabled = false,
    textStyles,
    textSize = TextSize.Regular,
    fontWeight = FontWeight.Regular,
    iconStyles,
    activeOpacity = 0.5,
    iconPosition = IconPositions.Left,
    children,
    isGradientButton = false
  } = props;

  const containerStyle = getContainerStyle(disabled, type, buttonStyles);
  const textStyle = getTextStyle(disabled, type, textStyles);
  const iconStyle = getIconStyle(disabled, type, iconStyles);
  function handleClick(): boolean {
    if (!disabled) {
      onPress && onPress();
    }
    return true;
  }

  const renderButton = () => {
    return (
      <>
        {iconPosition === IconPositions.Left && iconName && (
          <Icon size={iconSize} name={iconName} customStyle={iconStyle} />
        )}
        <Text
          fontWeight={fontWeight}
          textSize={textSize}
          testId={`txt-btn-${textSize}`}
          textStyle={textStyle}
        >
          {title}
        </Text>
        {children}
        {iconPosition === IconPositions.Right && iconName && (
          <Icon size={iconSize} name={iconName} customStyle={iconStyle} />
        )}
      </>
    );
  };

  return (
    <View onStartShouldSetResponder={() => handleClick()} style={containerStyle}>
      {type === "primary" && isGradientButton && PlatformUtils.isMobile() ? (
        <GradientBackground containerStyle={[containerStyle, { backgroundColor: "transparent" }]}>
          {renderButton()}
        </GradientBackground>
      ) : (
        renderButton()
      )}
    </View>
  );
}

const getContainerStyle = (disabled: boolean, type: ButtonType, buttonStyles = {}): ViewStyle => {
  let themedStyle: StyleProp<ViewStyle> = styles.primary;
  if (disabled) {
    themedStyle = styles.disabled;
  }
  if (type === "secondary" && !disabled) {
    themedStyle = styles.secondary;
  }
  return StyleSheet.flatten([
    styles.container,
    themedStyle,
    buttonStyles,
    PlatformUtils.isWeb() && {
      cursor: "pointer"
    }
  ]);
};

const getTextStyle = (disabled: boolean, type: ButtonType, textStyles = {}): TextStyle => {
  let themedStyle: StyleProp<TextStyle> = styles.primaryText;
  if (disabled) {
    themedStyle = styles.disabledText;
  }
  if (type === "secondary" && !disabled) {
    themedStyle = styles.secondaryText;
  }
  return StyleSheet.flatten([themedStyle, textStyles]);
};

const getIconStyle = (disabled: boolean, type: ButtonType, iconStyles = {}): TextStyle => {
  let themedStyle: StyleProp<TextStyle> = styles.primaryText;
  if (disabled) {
    themedStyle = styles.disabledText;
  }
  if (type === "secondary" && !disabled) {
    themedStyle = styles.secondaryText;
  }
  return StyleSheet.flatten([styles.iconText, themedStyle, iconStyles]);
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  iconText: {},
  primary: {
    backgroundColor: colorPallete.red1
  },
  primaryText: {
    color: colorPallete.white3
  },
  disabled: {
    backgroundColor: colorPallete.grey4
  },
  disabledText: {
    color: colorPallete.white3
  },
  secondary: {
    borderWidth: 1,
    borderColor: colorPallete.red1,
    backgroundColor: colorPallete.white3
  },
  secondaryText: {
    color: colorPallete.red1
  }
});
