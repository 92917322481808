import { call, put } from "redux-saga/effects";
import { UserLmsRepository } from "@timhortons/common/src/repositories/userLms/userLms";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import { takeLatest } from "redux-saga/effects";
import { QuizActions } from "../actions/quizActions";
import { UserLMSAction } from "../actions/userLms";
import {
  LMSGetQuizTableModel,
  LMSPostQuizResponseModel,
  MTFDNDRequestBodyModel
} from "@timhortons/common/src/models/userLms/userLms";

function* getTicTacToeQuizDetails(value: IFluxStandardAction<{ boxNumber: number }>) {
  try {
    const { payload } = value;
    const response: any = yield call(UserLmsRepository.getTicToeQuiz, payload);
    yield put(QuizActions.getTicTacToeQuizDetailsSuccess(response));
  } catch (error) {
    yield put(QuizActions.getTicTacToeQuizDetailsError(error));
  }
}

function* submitTicTacToeQuiz(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: any = yield call(UserLmsRepository.submitTicTacToeQuiz, payload);
    yield put(QuizActions.submitTicTacToeQuizSuccess(response));
  } catch (error) {
    yield put(QuizActions.submitTicTacToeQuizError(error));
  }
}

function* getDragDropQuizDetails(value: IFluxStandardAction<{ type: string }>) {
  try {
    const { payload } = value;
    const response: LMSGetQuizTableModel = yield call(UserLmsRepository.getDragDropQuiz, payload);
    yield put(QuizActions.getDragDropQuizDetailsSuccess(response));
  } catch (error) {
    yield put(QuizActions.getTicTacToeQuizDetailsError(error));
  }
}

function* submitMtfDnd(value: IFluxStandardAction<MTFDNDRequestBodyModel>) {
  try {
    const { payload } = value;
    const response: LMSPostQuizResponseModel = yield call(UserLmsRepository.submitMtfDnd, payload);
    //  throw new Error("")
    yield put(QuizActions.submitMtfDndQuizSuccess(response));
  } catch (error) {
    yield put(QuizActions.submitMtfDndQuizError(error));
  }
}

function* quizMobileWatherSaga() {
  yield takeLatest(UserLMS.GET_TIC_TAC_TOE_DETAILS.LOADING, getTicTacToeQuizDetails);
  yield takeLatest(UserLMS.SUBMIT_TIC_TAC_TOE_QUIZ.LOADING, submitTicTacToeQuiz);
  yield takeLatest(UserLMS.GET_DRAG_DROP_DETAILS.LOADING, getDragDropQuizDetails);
  yield takeLatest(UserLMS.SUBMIT_MTFDND_QUIZ.LOADING, submitMtfDnd);
}

export default quizMobileWatherSaga;
