import {
  ProfileDetailsModel,
  UserProfileDataModel,
  CreateStoreDataModel,
  CountryDataModel,
  PostCreateStoreDataModel,
  PostCreateUserDataModel,
  ChannelsDataModel,
  StoreSpecificDataModel,
  AllManagersDataModel,
  UserTableDetailsModel,
  RolesListDataModel,
  TeamDetailsTableModel,
  GetStoreByStoreCode,
  StoreTableDetailsModel,
  AssignStores,
  PostAssignStores,
  AreaManagersDataModel
} from "@timhortons/common/src/models/profile";
import { sleep } from "@timhortons/common/src/utils/timeoutFunctions";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { manageLmsEndPoints, profileEndPoints } from "@timhortons/common/src/constants/endPoints";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { REVKeys, ProfileKeys, LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import { IDocumentData } from "@timhortons/common/src/modules/profile/components/detailedUserProfile";
import { StoreListModel } from "@timhortons/common/src/modules/profile/components/createEmployee/organisms/storeList";
import { Roles } from "@timhortons/common/src/constants/roles";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import managersFormatter from "./restaurantManagersFormatter";

const userProfileData: UserProfileDataModel = {
  profileImg: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Sample_abc.jpg",
  role: "Store Manager",
  firstName: "Ashley",
  lastName: "Roberts",
  email: "ashley.roberts@gmail.com",
  storeProgress: 75,
  employeeNumber: 23,
  storeNumber: 20,
  storeCode: 1917,
  storeName: "Latifa Tower",
  employeeStatus: "In_Active"
};

class ProfileRepository {
  getIndividualProfile = async (param: { userId: string }) => {
    const { userId } = param;
    let individualProfile = await ApiClient.get(
      profileEndPoints.getIndividualProfile(userId),
      {},
      ProfileKeys.USER_ROLES
    );
    return individualProfileFormatter(individualProfile);
  };

  getStoreProfile = async (param: { storeId: string }) => {
    const { storeId } = param;
    let storeProfile = await ApiClient.get(
      profileEndPoints.getStoreProfile(storeId),
      {},
      REVKeys.USER_ROLES
    );
    return storeProfileFormatter(storeProfile);
  };

  getStoreTeamDetails = async (param: {
    storeId: number;
    pageNumber: number;
    pageSize: number;
    search: string;
  }) => {
    const { storeId, pageNumber, pageSize } = param;
    let search = param.search === "" ? null : param.search.toLowerCase();
    let data: any = {
      pageNo: pageNumber,
      pageSize: pageSize
    };
    if (param.search !== null) {
      data = {
        ...data,
        keyword: search
      };
    }
    let teamDetails = await ApiClient.get(
      profileEndPoints.getStoreTeamDetails(storeId),
      {
        ...data
      },
      REVKeys.USER_ROLES
    );
    return deserialize(TeamDetailsTableModel, teamDetails);
  };

  getIndividualTeamDetails = async (param: {
    pageNumber: number;
    pageSize: number;
    roleId: string;
    search: string;
    isStoresTab?: boolean;
    idList?: [string];
  }) => {
    const { pageNumber, pageSize, roleId, isStoresTab = false, idList = [] } = param;
    let keyword = param.search === "" ? null : param.search.toLowerCase();

    let object: any = {
      pageNo: pageNumber,
      pageSize: pageSize,
      roleIds: idList?.length > 0 ? idList.join(",") : roleId
    };
    if (param.search !== null) {
      object = {
        ...object,
        keyword: keyword
      };
    }
    let teamDetails = isStoresTab
      ? await ApiClient.get(
          profileEndPoints.getStoresTabIndividualTeamDetails(),
          {
            ...object
          },
          REVKeys.USER_ROLES
        )
      : await ApiClient.get(
          profileEndPoints.getIndividualTeamDetails(),
          {
            ...object
          },
          REVKeys.USER_ROLES
        );
    return deserialize(TeamDetailsTableModel, teamDetails);
  };

  getUserProfileDetails = async (param: { employeeId: string }) => {
    const { employeeId } = param;
    // let userProfileData: UserDetailsDataModel = ApiClient.get(profileEndPoints.getUserProfileDetails(employeeId), {});
    await sleep(2000);
    return userProfileData;
  };
  getDetailedUserInfo = async (param: { employeeId: string }) => {
    const { employeeId } = param;
    let detailedUserData = ApiClient.get(
      profileEndPoints.getDetailedUserInfo(employeeId),
      {},
      REVKeys.USER_ROLES
    );
    return detailedUserData;
  };

  ////////    Create Store and user /////////

  getAllCountries = async (param: { isGcc: boolean }) => {
    const { isGcc } = param;
    let data = isGcc
      ? await ApiClient.get(profileEndPoints.getGccCountries(), {}, REVKeys.USER_ROLES)
      : await ApiClient.get(profileEndPoints.getAllCountries(), {}, REVKeys.USER_ROLES);
    let countriesData: CountryDataModel[] = [];
    data.map((item: CountryDataModel) => {
      let deserializevar: CountryDataModel = deserialize(CountryDataModel, item);
      countriesData.push(deserializevar);
    });

    return countriesData;
  };

  getAllChannels = async () => {
    let data = await ApiClient.get(profileEndPoints.getAllChannels(), {}, REVKeys.USER_ROLES);
    let channelsData: ChannelsDataModel[] = [];
    data.map((item: ChannelsDataModel) => {
      let deserializevar: ChannelsDataModel = deserialize(ChannelsDataModel, item);
      channelsData.push(deserializevar);
    });

    return channelsData;
  };

  getStoreSpecifics = async () => {
    let data = await ApiClient.get(profileEndPoints.getStoreSpecifics(), {}, REVKeys.USER_ROLES);
    let storeSpecificsData: StoreSpecificDataModel[] = [];
    data.map((item: StoreSpecificDataModel) => {
      let deserializevar: StoreSpecificDataModel = deserialize(StoreSpecificDataModel, item);
      storeSpecificsData.push(deserializevar);
    });

    return storeSpecificsData;
  };

  getAllCities = async (param: { countryCode: number }) => {
    let data = await ApiClient.get(
      profileEndPoints.getAllCities(param.countryCode),
      {},
      REVKeys.USER_ROLES
    );
    let citiesData: CreateStoreDataModel[] = [];
    data.map((item: CreateStoreDataModel) => {
      let deserializevar: CreateStoreDataModel = deserialize(CreateStoreDataModel, item);
      citiesData.push(deserializevar);
    });
    return citiesData;
  };

  getAllAreaManager = async (param: {
    countryCode: number;
    role: string;
    pageNo: number;
    pageSize: number;
  }) => {
    let data = await ApiClient.get(
      profileEndPoints.getAllAreaManager(param.role),
      {
        countryCodes: param.countryCode,
        pageNo: param.pageNo,
        pageSize: param.pageSize
      },
      ProfileKeys.USER_PROFILE
    );
    let managersData: AreaManagersDataModel = {
      content: [],
      totalElements: null
    };
    let finalContent: AllManagersDataModel[] = [];

    data.content.map((item: AllManagersDataModel) => {
      let deserializevar: AllManagersDataModel = deserialize(AllManagersDataModel, item);
      finalContent.push(deserializevar);
    });
    managersData.totalElements = data.totalElements;
    managersData.content = finalContent;
    managersData.currentPage = param.pageNo;
    return managersData;
  };
  getAreaManager = async (param: { countryCode: number; role: string }) => {
    let data = await ApiClient.get(
      profileEndPoints.getAllAreaManager(param.role),
      {
        countryCodes: param.countryCode
      },
      REVKeys.USER_ROLES
    );
    let managersData: AllManagersDataModel[] = [];
    data.content.map((item: AllManagersDataModel) => {
      let deserializevar: AllManagersDataModel = deserialize(AllManagersDataModel, item);
      managersData.push(deserializevar);
    });
    return managersData;
  };

  getAllRoles = async () => {
    let allRolesData = await ApiClient.get(profileEndPoints.getAllRoles(), {}, REVKeys.USER_ROLES);
    let formattedRolesData = rolesFormatter(allRolesData);
    return {
      allRolesData: allRolesData,
      formattedRolesData: formattedRolesData
    };
  };

  postStoreImage = async (param: any) => {
    let data = await ApiClient.post(
      profileEndPoints.postStoreImage(),
      param,
      {},
      ProfileKeys.MANAGE_IMAGE_UPLOAD
    );
    return data.asset[0];
  };

  postCreateStore = async (param: {
    data: PostCreateStoreDataModel;
    image: any;
    isEdit: boolean;
  }) => {
    let finalData = param.data;
    finalData.profilePhoto = param.image;
    param.data = finalData;
    let finalDatadata = param.isEdit
      ? await ApiClient.put(
          profileEndPoints.postCreateStore(),
          param.data,
          {},
          ProfileKeys.MANAGE_USERS
        )
      : await ApiClient.post(profileEndPoints.postCreateStore(), param.data);
    return finalDatadata;
  };

  postUserImage = async (param: FormData) => {
    let data = await ApiClient.post(
      profileEndPoints.postUserImage(),
      param,
      {},
      ProfileKeys.MANAGE_IMAGE_UPLOAD
    );
    return data;
  };

  postUserImageMobile = async (param: FormData) => {
    let data = await ApiClient.post(profileEndPoints.postUserImage(), param, {}, LMSKeys.LMS_USER);
    return data;
  };

  postCreateUser = async (param: {
    data: PostCreateUserDataModel;
    image: string;
    isEdit: boolean;
  }) => {
    let finalData = param.data;
    finalData.userBio.profileUrl = param.image;
    if (!param.isEdit) {
      delete param.data.newEmployeeId;
    }
    param.data = finalData;
    let finalDatadata = param.isEdit
      ? await ApiClient.put(
          profileEndPoints.postCreateUser(),
          param.data,
          {},
          ProfileKeys.MANAGE_USERS
        )
      : await ApiClient.post(profileEndPoints.postCreateUser(), param.data);
    return finalDatadata;
  };
  /////////////// Employee certificate ///////////////

  getEmployeeDocument = async (param: { empId: string; docType: string; docSide: string }) => {
    const { empId, docType, docSide } = param;
    let data = await ApiClient.get(
      profileEndPoints.getEmployeeDocument(empId, docType, docSide),
      {},
      REVKeys.USER_ROLES
    );
    return data[0];
  };

  getEmployeeCertificate = async (param: { empId: string; docType: string }) => {
    const { empId, docType } = param;
    let data = await ApiClient.get(
      profileEndPoints.getEmployeeCertificate(empId, docType),
      {},
      REVKeys.USER_ROLES
    );
    return data[0];
  };

  postEmployeeDocument = async (param: { data: FormData; empId: string }) => {
    let data = await ApiClient.post(
      profileEndPoints.postEmployeeDocument(param.empId),
      param.data,
      null,
      REVKeys.DOCUMENTS
    );
    return data[0];
  };

  getEmployeeCountDocument = async (param: { empId: string }) => {
    const { empId } = param;
    let data = await ApiClient.get(
      profileEndPoints.getEmployeeCountDocument(empId),
      {},
      REVKeys.USER_ROLES
    );
    return empDocFormatter(data, "DocCount");
  };

  getEmployeeCountCertificate = async (param: { empId: string }) => {
    const { empId } = param;
    let data = await ApiClient.get(
      profileEndPoints.getEmployeeCountCertificate(empId),
      {},
      REVKeys.USER_ROLES
    );
    return empDocFormatter(data, "Certificate");
  };

  getStoreByStoreCode = async (param: { storeCode: number }) => {
    const { storeCode } = param;
    let data = await ApiClient.get(
      profileEndPoints.getStoreByCode(storeCode),
      {},
      REVKeys.USER_ROLES
    );
    return deserialize(GetStoreByStoreCode, data);
  };

  //////////////////////////////////////////////////////////////

  getAllStoresList = async (params: {
    data: AssignStores;
    user: IUserDetails;
    fromLms: Boolean;
  }) => {
    // let data =
    //   params.user.roles.includes(Roles.SuperAdmin) ||
    //   params.user.roles.includes(Roles.TrainingAndDevLead) ||
    //   params.user.roles.includes(Roles.TrainingAndDevManager)
    //     ?
    if (params.fromLms) {
      let data = await ApiClient.get(
        manageLmsEndPoints.getAllStoresListLms(params.data),
        null,
        REVKeys.USER_ROLES
      );
      return storeListFormatter(data);
    }
    let data = await ApiClient.get(
      profileEndPoints.getAllStoresList(),
      params.data,
      REVKeys.USER_ROLES
    );
    // : await ApiClient.get(
    //     profileEndPoints.getStoresTabIndividualTeamDetails(),
    //     params.data,
    //     REVKeys.USER_ROLES
    //   );
    return storeListFormatter(data);
  };
  postAssignedStores = async (param: {
    empId: string;
    postData: PostAssignStores[];
    isAllSelected?: boolean;
    roleId?: string;
    countryCode?: string;
    cityId?: string;
    channelId?: string;
  }) => {
    let { empId, postData } = param;
    let obj = {
      empId: param.empId,
      // isAllSelected: param.isAllSelected,
      roleId: param.roleId,
      countryCode: param.countryCode,
      cityId: param.cityId,
      channelId: param.channelId
    };
    // if (param.isAllSelected === true) {
    //   return await ApiClient.post(profileEndPoints.postAssignedStores(empId), null, obj);
    // } else {
    return await ApiClient.post(profileEndPoints.postAssignedStores(empId), postData, obj);
    // }
  };

  getStoreTableDetails = async (param: {
    search: string;
    pageNum: number;
    pageSize: number;
    country: number;
    date: string;
    status: string;
    toDate: string;
    fromDate: string;
    city: string;
  }) => {
    const { pageNum, pageSize, country, toDate, fromDate, city } = param;
    const filterByToDate = convert(toDate);
    const filterByFromDate = convert(fromDate);

    let keyword = param.search === "" ? null : param.search;
    let data: any = {
      pageNo: pageNum,
      pageSize: pageSize
    };
    if (toDate !== null && fromDate !== null) {
      data = {
        ...data,
        filterByToDate: filterByToDate,
        filterByFromDate: filterByFromDate
      };
    }
    if (country) {
      data = {
        ...data,
        filterByCountryCode: country
      };
    }
    if (city) {
      data = {
        ...data,
        filterByCityId: city
      };
    }
    if (param.status === "Active") {
      data = {
        ...data,
        filterByIsActive: true
      };
    }
    if (param.status === "Inactive") {
      data = {
        ...data,
        filterByIsActive: false
      };
    }
    if (param.search !== null) {
      data = {
        ...data,
        keywords: keyword
      };
    }
    let storeTableDetails = await ApiClient.get(
      profileEndPoints.getStoreTableDetails(),
      {
        ...data
      },
      REVKeys.USER_ROLES
    );
    return deserialize(StoreTableDetailsModel, storeTableDetails);
  };

  getUserTableDetails = async (param: {
    search: string;
    pageNum: number;
    pageSize: number;
    country: number;
    date: string;
    status: string;
    toDate: string;
    fromDate: string;
    role: string;
  }) => {
    const { pageNum, pageSize, country, toDate, fromDate, role } = param;
    const filterByToDate = convert(toDate);
    const filterByFromDate = convert(fromDate);
    let search = param.search === "" ? null : param.search;
    let object: any = {
      pageNo: pageNum,
      pageSize: pageSize
    };
    if (toDate !== null && fromDate !== null) {
      object = {
        ...object,
        filterByToDate: filterByToDate,
        filterByFromDate: filterByFromDate
      };
    }
    if (country) {
      object = {
        ...object,
        filterByCountryCode: country
      };
    }
    if (role) {
      object = {
        ...object,
        filterByRoleId: role
      };
    }

    if (param.status === "Active") {
      object = {
        ...object,
        filterByIsActive: true
      };
    }
    if (param.status === "Inactive") {
      object = {
        ...object,
        filterByIsActive: false
      };
    }
    if (search !== null) {
      object = {
        ...object,
        pageNo: 0
      };
    }
    if (param.search !== null) {
      object = {
        ...object,
        keyword: search
      };
    }
    let userTableDetails = await ApiClient.get(
      profileEndPoints.getUserTableDetails(),
      {
        ...object
      },
      REVKeys.USER_ROLES
    );
    return deserialize(UserTableDetailsModel, userTableDetails);
  };

  getEditStoreDetails = async (param: { storeId: number }) => {
    const { storeId } = param;
    let data = await ApiClient.get(
      profileEndPoints.getEditStoreDetails(storeId),
      {},
      REVKeys.USER_ROLES
    );
    return managersFormatter(data);
  };

  getGccCountries = async () => {
    let data = await ApiClient.get(profileEndPoints.getGccCountries(), {}, ProfileKeys.GCC_COUNTRY);
    let countriesData: CountryDataModel[] = [];
    data.map((item: CountryDataModel) => {
      let deserializevar: CountryDataModel = deserialize(CountryDataModel, item);
      countriesData.push(deserializevar);
    });

    return countriesData;
  };
}
const storeProfileFormatter = (response: any): ProfileDetailsModel => {
  let data: ProfileDetailsModel;
  data = {
    name: response.storeName,
    profilePhoto: response.profilePhoto,
    role: Roles.StoreAccess,
    gender: "",
    age: 0,
    id: response.storeCode,
    email: response.email,
    phone: response.contactNumber,
    address: response.address,
    profilePercentage: response.profilePercentage
  };
  return data;
};

const individualProfileFormatter = (response: any): ProfileDetailsModel => {
  let data: ProfileDetailsModel;
  data = {
    name: `${response.firstName} ${response.lastName}`,
    profilePhoto: response.profileUrl,
    role: response.role,
    gender: response.gender,
    age: new Date().getFullYear() - new Date(response.dateOfBirth).getFullYear(),
    id: response.employeeId,
    email: response.email,
    phone: response.phone,
    address: response.address,
    profilePercentage: response.profilePercentage,
    profileKey: response.profileKey
  };
  return data;
};
const rolesFormatter = (response: RolesListDataModel[]) => {
  let data: any = {};
  response &&
    response.map((item: RolesListDataModel) => {
      switch (item.name) {
        case "Super Admin":
          return (data.SuperAdmin = item.id);
        case "Training and Dev Lead":
          return (data.TrainingAndDevLead = item.id);
        case "Training and Dev Manager":
          return (data.TrainingAndDevManager = item.id);
        case "Chief Operating Officer":
          return (data.COO = item.id);
        case "Chief Executive Officer":
          return (data.CEO = item.id);
        case "HR and Admin Director":
          return (data.HrAndAdminDirector = item.id);
        case "Production and Dev Executive":
          return (data.ProductionAndDevExecutive = item.id);
        case "Cluster Head of Ops":
          return (data.ClusterHeadofOps = item.id);
        case "Country Manager":
          return (data.CountryManager = item.id);
        case "Senior Ops Manager":
          return (data.SeniorOpsManager = item.id);
        case "Ops Manager":
          return (data.OperationManager = item.id);
        case "Supervisor":
          return (data.Supervisor = item.id);
        case "Restaurant Manager":
          return (data.RestaurantManager = item.id);
        case "Assistant Restaurant Manager":
          return (data.AssistantRestaurantManager = item.id);
        case "Area Manager":
          return (data.AreaManager = item.id);
        case "Area Trainer":
          return (data.AreaTrainer = item.id);
        case "In-Store Trainer":
          return (data.InStoreTrainer = item.id);
        case "Internal Auditor":
          return (data.InternalAuditor = item.id);
        case "Compliance and LP Manager":
          return (data.ComplianceAndLPManager = item.id);
        case "LP Auditor":
          return (data.LPAuditor = item.id);
        case "Service Crew":
          return (data.ServiceCrew = item.id);
        case "Back Office":
          return (data.BackOffice = item.id);
        // case "QA Auditor":
        //   return (data.QAAuditor = item.id);
        // case "IT":
        //   return (data.IT = item.id);
        case "Admin":
          return (data.Admin = item.id);
        default:
          return null;
      }
    });
  return data;
};

const empDocFormatter = (data: any, status?: string) => {
  let finalData: IDocumentData[] = [];
  data &&
    data.map((item: any, index: number) => {
      let obj: any = {};
      obj.id = (index + 1).toString();
      obj.iconName = item.fileIcon;
      obj.fileName = item.fileName;
      obj.fileCount = item.fileCount;
      if (status === "Certificate") {
        obj.type1 = item.fileName;
      }
      if (status === "DocCount") {
        obj.isSelected = false;
        obj.frontsignedUrl = null;
        obj.backsignedUrl = null;
        obj.expiryDate = null;
        obj.backPhotoRequired = item.hasBackSide;
        obj.type1 = "FRONT";
        obj.type2 = item.hasBackSide && "BACK";
      }
      finalData.push(obj);
    });
  return finalData;
};

const storeListFormatter = (data: any) => {
  let finalData: StoreListModel[] = [];
  data &&
    data.content.map((el: any, index: number) => {
      let obj: any = {};
      obj.id = index + 1;
      obj.profilePhoto = el.profilePhoto || el.profileUrl;
      obj.storeName = el.name;
      obj.storeCode = el.code;
      obj.dateOfOpening = el.dateOfOpening;
      obj.noOfEmployees = el.noOfEmployees;
      obj.isSelected = false;
      obj.roleStatus = el.roleStatus;
      obj.isEmployeeAssigned = el?.isEmployeeAssigned;
      obj.isAllSelected = el?.isAllSelected;
      obj.country = el?.country;
      finalData.push(obj);
    });
  return {
    totalRecords: data.totalElements,
    storeList: finalData
  };
};
const profileRepository = new ProfileRepository();
export { profileRepository as ProfileRepository };
