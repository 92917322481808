import {
  Modal,
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
  Dimensions,
  LayoutChangeEvent,
  TouchableOpacity,
  TouchableWithoutFeedback
} from "react-native";
import React, { ReactElement, useState } from "react";
import useCurrentLanguage from "@timhortons/common/src/utils/useCurrentLanguage";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

interface IProps {
  type?: ModalAnimationType;
  customStyle?: StyleProp<ViewStyle>;
  setModalState: () => void;
  children: React.ReactNode;
  dropdownStyle?: StyleProp<ViewStyle>;
  triggerComponent: React.ReactNode;
  modalVisible: boolean;
  buttonFrame: IButtonFrame;
  spaceRequired?: boolean;
}

interface IButtonFrame {
  x: number;
  y: number;
  w: number;
  h: number;
}

export enum ModalAnimationType {
  Slide = "slide",
  None = "none",
  Fade = "fade"
}

const OverflowModal = (props: IProps): ReactElement => {
  const {
    customStyle,
    type = ModalAnimationType.Fade,
    setModalState,
    children,
    dropdownStyle,
    triggerComponent,
    modalVisible,
    buttonFrame,
    spaceRequired = true
  } = props;

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  const currentLanguage = useCurrentLanguage();

  const getTopMeasurements = (showInBottom: boolean) => {
    if (showInBottom) {
      let measure = buttonFrame.y + buttonFrame.h;
      return spaceRequired ? measure + 8 : measure;
    } else {
      let measure = buttonFrame.y - dropdownHeight;
      return spaceRequired ? Math.max(0, measure - 8) : Math.max(0, measure);
    }
  };

  const getFrameStyles = () => {
    const bottomSpace = windowHeight - buttonFrame.y - buttonFrame.h - 50;
    const showInBottom = bottomSpace >= dropdownHeight || bottomSpace >= buttonFrame.y;
    //NEW ADDITION NOT TESTED
    const rightSpace = windowWidth - buttonFrame.x - buttonFrame.w;
    const palat = rightSpace <= dropdownWidth;
    if (currentLanguage === "ar") {
      return {
        height: dropdownHeight,
        //NEW ADDITION NOT TESTED the else part is orignal
        right: palat
          ? buttonFrame.x + buttonFrame.w - windowWidth
          : buttonFrame.x - windowWidth + dropdownWidth,
        top: getTopMeasurements(showInBottom)
      };
    } else {
      return {
        height: dropdownHeight,
        //NEW ADDITION NOT TESTED the else part is orignal
        left: palat ? windowWidth - (dropdownWidth + rightSpace) : buttonFrame.x,
        top: getTopMeasurements(showInBottom)
      };
    }
  };

  const getDropdownMeasures = (event: LayoutChangeEvent) => {
    setDropdownHeight(event.nativeEvent.layout.height);
    setDropdownWidth(event.nativeEvent.layout.width);
  };

  const getDropdownLayout = (): JSX.Element => {
    return (
      <View style={[styles.dropdown, dropdownStyle]} onLayout={getDropdownMeasures}>
        {children}
      </View>
    );
  };

  let frameStyle = {};
  if (buttonFrame) {
    frameStyle = getFrameStyles();
  }
  const aab = () => {
    // do something
  };
  return (
    <>
      {triggerComponent}
      {modalVisible && (
        <TouchableOpacity onPress={setModalState}>
          <Modal
            animationType={type}
            transparent
            visible
            onRequestClose={() => {
              setModalState();
            }}
          >
            {PlatformUtils.isWeb() ? (
              <View style={[styles.container, frameStyle, customStyle]}>
                <TouchableOpacity onPress={aab}>{getDropdownLayout()}</TouchableOpacity>
              </View>
            ) : (
              <TouchableWithoutFeedback onPress={setModalState}>
                <View style={{ flex: 1 }}>
                  <TouchableWithoutFeedback onPress={setModalState}>
                    <View style={[styles.container, frameStyle, customStyle]}>
                      {getDropdownLayout()}
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              </TouchableWithoutFeedback>
            )}
          </Modal>
        </TouchableOpacity>
      )}
    </>
  );
};

export default OverflowModal;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1
  },
  dropdown: {
    position: "absolute"
  }
});
