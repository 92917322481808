import {
  feedbackValues,
  IFeedbackTexts
} from "@timhortons/web/src/modules/manageLms/components/assignedLearning/feedback/organisms/feedbackArrayConstants";
import { LmsFeedbackResponseModel } from "@timhortons/common/src/models/manageLms/lmsFeedback";
import { LmsAssessmentTheoryDataModel } from "@timhortons/common/src/models/manageLms/lmsPtsModels";

export const getFeedbackDataFormatter = async (
  data: LmsFeedbackResponseModel[]
): Promise<IFeedbackTexts[]> => {
  let tempArr: IFeedbackTexts[] = [];
  if (data && data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let obj: IFeedbackTexts = { key: 0, id: "", name: "", feedbackPoint: "" };
      obj.id = `${data[i].id}`;
      obj.feedbackPoint = data[i].feedbackPoint;
      obj.key = feedbackValues[i].key;
      obj.name = feedbackValues[i].name;
      obj.isActive = feedbackValues[i].isActive;

      tempArr.splice(i, 1, obj);
    }
  }
  return tempArr;
};

export const lmsAssessmentTheoryDataFormatter = async (
  data: LmsAssessmentTheoryDataModel[]
): Promise<LmsAssessmentTheoryDataModel[]> => {
  let newArr: any[] = [];
  let numOfAssessmentQues = 70;
  let emptyObj: LmsAssessmentTheoryDataModel = { id: null, questionNumber: null, quizType: null };

  for (let i = 0; i < numOfAssessmentQues; i++) {
    emptyObj.questionNumber = i + 1;
    newArr.push({ ...emptyObj });
  }

  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let newDataObj: LmsAssessmentTheoryDataModel = {
        id: null,
        questionNumber: null,
        quizType: null
      };
      newDataObj.id = data[i].id;
      newDataObj.questionNumber = data[i].questionNumber;
      newDataObj.quizType = data[i].quizType;
      newArr.splice(i, 1, newDataObj);
    }
  }
  return newArr;
};
