import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import { LmsFeedbackAction } from "@timhortons/common/src/redux/manageLms/actions/lmsFeedbackActions";
import { LmsFeedbackRepository } from "@timhortons/common/src/repositories/manageLms/lmsFeedback";
import { Logger } from "@timhortons/common/src/services/logger";
import {
  LmsFeedbackDataModel,
  LmsFeedbackResponseModel
} from "@timhortons/common/src/models/manageLms/lmsFeedback";
import { IFeedbackTexts } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/feedback/organisms/feedbackArrayConstants";

function* getLmsFeedbackByModuleId(value: IFluxStandardAction<{ moduleId: string }>) {
  const { payload } = value;
  try {
    let response: IFeedbackTexts[] = yield call(
      LmsFeedbackRepository.getLmsFeedbackByModule,
      payload
    );
    yield put(LmsFeedbackAction.getLmsFeedbackByIdSuccess(response));
  } catch (error) {
    Logger.error("manageLms-feedback", error, "getLmsFeedbackByModuleId");

    yield put(LmsFeedbackAction.getLmsFeedbackByIdError(error));
  }
}

function* postLmsFeedbackData(value: IFluxStandardAction<LmsFeedbackDataModel>) {
  const { payload } = value;
  try {
    let response: LmsFeedbackResponseModel = yield call(
      LmsFeedbackRepository.postLmsFeedback,
      payload
    );
    yield put(LmsFeedbackAction.postLmsFeedbackSuccess(response));
  } catch (error) {
    Logger.error("manageLms-feedback", error, "postLmsFeedbackData");

    yield put(LmsFeedbackAction.postLmsFeedbackError(error));
  }
}

function* updateLmsFeedbackData(
  value: IFluxStandardAction<{ moduleId: string; obj: LmsFeedbackResponseModel }>
) {
  const { payload } = value;
  try {
    let response: LmsFeedbackResponseModel[] = yield call(
      LmsFeedbackRepository.updateLmsFeedback,
      payload
    );
    yield put(LmsFeedbackAction.updateLmsFeedbackSuccess(response));
  } catch (error) {
    Logger.error("manageLms-feedback", error, "updateLmsFeedbackData");

    yield put(LmsFeedbackAction.updateLmsFeedbackError(error));
  }
}

export default function* lmsFeedbackWatcherSaga() {
  yield takeLatest(MANAGELMS.feedback.GET_MODULE_FEEDBACK_BY_ID.LOADING, getLmsFeedbackByModuleId);
  yield takeLatest(MANAGELMS.feedback.POST_MODULE_FEEDBACK.LOADING, postLmsFeedbackData);
  yield takeLatest(MANAGELMS.feedback.UPDATE_FEEDBACK.LOADING, updateLmsFeedbackData);
}
