import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import {
  BakingTableModel,
  ICategoryList,
  IProductList,
  PostMainMenuApiResp,
  PostMainMenuPayload
} from "@timhortons/common/src/models/productionSheet/mainMenu";

export const getAllCategoryAndProducts = (data: {
  storeCode: number;
  stationCode: string;
  date: string;
}): IFluxStandardAction<{
  storeCode: number;
  stationCode: string;
}> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.GET_ALL_CATEGORY_AND_PRODUCTS.LOADING,
    payload: data
  };
};
export const getAllCategoryAndProductsSuccess = (
  data: ICategoryList[] | BakingTableModel[]
): IFluxStandardAction<ICategoryList[] | BakingTableModel[]> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.GET_ALL_CATEGORY_AND_PRODUCTS.SUCCESS,
    payload: data
  };
};
export const getAllCategoryAndProductsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.GET_ALL_CATEGORY_AND_PRODUCTS.ERROR,
    payload: error
  };
};

export const postCategoryAndProducts = (
  data: PostMainMenuPayload
): IFluxStandardAction<PostMainMenuPayload> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.POST_CATEGORY_AND_PRODUCTS.LOADING,
    payload: data
  };
};
export const postCategoryAndProductsSuccess = (
  data: PostMainMenuApiResp[]
): IFluxStandardAction<PostMainMenuApiResp[]> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.POST_CATEGORY_AND_PRODUCTS.SUCCESS,
    payload: data
  };
};
export const postCategoryAndProductsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.POST_CATEGORY_AND_PRODUCTS.ERROR,
    payload: error
  };
};
export const resetPostMainMenu = () => {
  return {
    type: PRODUCTION_SHEET.MainMenu.RESET_POST_MAIN_MENU
  };
};

export const getAllStations = (): IFluxStandardAction => {
  return {
    type: PRODUCTION_SHEET.MainMenu.GET_ALL_STATIONS.LOADING
  };
};
export const getAllStationsSuccess = (
  data: IProductList[]
): IFluxStandardAction<IProductList[]> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.GET_ALL_STATIONS.SUCCESS,
    payload: data
  };
};
export const getAllStationsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.MainMenu.GET_ALL_STATIONS.ERROR,
    payload: error
  };
};
