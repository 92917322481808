import { combineReducers } from "redux";
import {
  ISupportTicketsState,
  supportTicketsReducer
} from "@timhortons/common/src/redux/askTell/reducers/supportTicketModuleReducer";
import {
  IQuestionsModuleState,
  questionsModuleReducer
} from "@timhortons/common/src/redux/askTell/reducers/questionsModuleReducer";
import {
  faqModuleReducer,
  IFaqModuleState
} from "@timhortons/common/src/redux/askTell/reducers/faqModuleReducer";

export interface IManageAskState {
  supportTickets: ISupportTicketsState;
  questionsModule: IQuestionsModuleState;
  faqModule: IFaqModuleState;
}

export const manageAskReducer = combineReducers({
  supportTickets: supportTicketsReducer,
  questionsModule: questionsModuleReducer,
  faqModule: faqModuleReducer
});
