import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { useRoute, getFocusedRouteNameFromRoute, useIsFocused } from "@react-navigation/native";
import { useLocation } from "react-router-dom";
export const useFocusedHook = () => {
  return PlatformUtils.isWeb() ? true : useIsFocused();
};
export const useCurrentRoute = () => {
  let currentRoute = Pathnames.lms;
  if (PlatformUtils.isWeb()) {
    const location = useLocation();
    const locationArray = location.pathname.split("/");
    currentRoute = locationArray[2] as Pathnames;
  } else {
    const route = useRoute();
    currentRoute = (getFocusedRouteNameFromRoute(route) as Pathnames) ?? Pathnames.home;
  }
  return currentRoute;
};
