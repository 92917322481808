// @ts-nocheck
import icomoon from "@timhortons/common/src/assets/fonts/icomoon.ttf";
import SofiaProSemiBold from "@timhortons/common/src/assets/fonts/Sofia-Pro-Semi-Bold.ttf";
import SofiaProBlack from "@timhortons/common/src/assets/fonts/Sofia-Pro-Black.ttf";
import SofiaProBold from "@timhortons/common/src/assets/fonts/Sofia-Pro-Bold.ttf";
import SofiaProExtraLight from "@timhortons/common/src/assets/fonts/Sofia-Pro-Extra-Light.ttf";
import SofiaProLight from "@timhortons/common/src/assets/fonts/Sofia-Pro-Light.ttf";
import SofiaProMedium from "@timhortons/common/src/assets/fonts/Sofia-Pro-Medium.ttf";
import SofiaProRegular from "@timhortons/common/src/assets/fonts/Sofia-Pro-Regular.ttf";
import SofiaProUltraLight from "@timhortons/common/src/assets/fonts/Sofia-Pro-Ultra-Light.ttf";
import AutoGraphRegular from "@timhortons/common/src/assets/fonts/Autograph-Regular.otf";
const iconFontStyles = `
@font-face {
  src: url(${icomoon});
  font-family: icomoon;
  font-display: swap;
}
@font-face {
  src: url(${SofiaProSemiBold});
  font-family: Sofia-Pro-Semi-Bold;
  font-display: swap;
}
@font-face {
  src: url(${SofiaProBlack});
  font-family: Sofia-Pro-Black;
  font-display: swap;
}
@font-face {
  src: url(${SofiaProBold});
  font-family: Sofia-Pro-Bold;
  font-display: swap;
}
@font-face {
  src: url(${SofiaProExtraLight});
  font-family: Sofia-Pro-Extra-Light;
  font-display: swap; 
}
@font-face {
  src: url(${SofiaProLight});
  font-family: Sofia-Pro-Light;
  font-display: swap;
}
@font-face {
  src: url(${SofiaProMedium});
  font-family: Sofia-Pro-Medium;
  font-display: swap;
}
@font-face {
  src: url(${SofiaProRegular});
  font-family: Sofia-Pro-Regular;
  font-display: swap;
}
@font-face {
  src: url(${SofiaProUltraLight});
  font-family: Sofia-Pro-Ultra-Light;
  font-display: swap;
}
@font-face {
  src: url(${AutoGraphRegular});
  font-family: Autograph-Regular;
    font-display: swap;
}
`;
// Create stylesheet
const style: any = document.createElement("style");
style.type = "text/css";
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}
// Inject stylesheet
document.head.appendChild(style);
