import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import {
  formatterReports,
  gradingReportBuilderFormatter,
  lpIndividualAccessSummarySRTableFormatter
} from "@timhortons/common/src/repositories/lp/reportFormatter";
import {
  AckDropDownModel,
  LpAcknowledgementModel,
  LpPostAcknowledgementModel,
  LpReportTableModel,
  LpReportsSummaryOpportunityDataModel
} from "@timhortons/common/src/models/lp/reports";
import { LPEndPoints } from "@timhortons/common/src/constants/endPoints";
import {
  lpStoreAccessSummaryFormatter,
  lpIndividualAccessSummaryFormatter,
  reportBuilderFormatter
} from "@timhortons/common/src/repositories/lp/reportFormatter";
import { IUserDetails } from "modules/iam/redux/reducer/reducer";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

class ReportsRepository {
  submitLpAudit = async (param: { auditId: number }) => {
    await ApiClient.put(
      LPEndPoints.reports.submitAudit(param.auditId),
      { isCompleted: true },
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    let reportId: null = null;
    return { auditId: param.auditId, reportId };
  };
  prepareLpReport = async (param: { auditId: number }) => {
    let reportId = await ApiClient.post(
      LPEndPoints.reports.prepareReport(),
      param.auditId.toString(),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    return { auditId: param.auditId, reportId };
  };

  fetchLpReportsData = async (param: { auditId: number; reportId: number }) => {
    const { auditId, reportId } = param;
    let reportsLpMasterData = await ApiClient.get(
      LPEndPoints.reports.fetchReportsData(reportId),
      null,
      LPKeys.LP_REPORTS
    );

    let opportunityLpMasterData = await ApiClient.get(
      LPEndPoints.reports.fetchOpportunityData(auditId),
      null,
      LPKeys.LP_REPORTS
    );

    return formatterReports(opportunityLpMasterData, reportsLpMasterData);
  };

  fetchAcknowledgementData = async (param: { storeCode: string }) => {
    let data = await ApiClient.get(
      LPEndPoints.reports.fetchAcknowledgementData(param.storeCode),
      null,
      LPKeys.LP_REPORTS
    );
    let finalData: LpAcknowledgementModel = {
      dropDownData: []
    };
    data.map((item: AckDropDownModel) => {
      let deserializeVar: AckDropDownModel = deserialize(AckDropDownModel, item);
      finalData.dropDownData.push(deserializeVar);
    });
    return finalData;
  };
  postAcknowledgementData = async (payload: LpPostAcknowledgementModel) => {
    const { reportId, storeInChargeId, file, remarks, endDate } = payload;
    let data = await ApiClient.post(
      LPEndPoints.reports.postAcknowledgementData(),
      {
        base64String: file
      },
      {
        reportId,
        storeInChargeId,
        remarks,
        endDate
      },
      LPKeys.LP_STANDARD_AUDIT
    );
    return data;
  };
  fetchReportTableData = async (param: {
    userId: string;
    storeId: number;
    isIndividualLogin: boolean;
    bodyObject: any;
  }) => {
    let response;

    if (param.isIndividualLogin) {
      response = await ApiClient.get(
        LPEndPoints.reports.fetchReportTableDataIndividual(param.userId),
        param.bodyObject,
        LPKeys.LP_REPORT_BUILDER
      );
    } else {
      response = await ApiClient.get(
        LPEndPoints.reports.fetchReportTableDataStore(param.storeId),
        param.bodyObject,
        LPKeys.LP_REPORT_BUILDER
      );
    }
    let data: Array<LpReportTableModel> = response.records.map((item: any) => {
      return deserialize(LpReportTableModel, item);
    });
    return {
      ...response,
      records: data
    };
  };
  fetchLpReportsSummaryStoreAccess = async (param: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => {
    const { storeId, startDate, endDate } = param;
    let response = await ApiClient.get(
      LPEndPoints.reports.fetchLpStoreAccessSummaryData(storeId),
      {
        filterByStartDate: startDate,
        filterByEndDate: endDate
      },
      LPKeys.LP_REPORT_SUMMARY
    );
    return lpStoreAccessSummaryFormatter(response);
  };
  fetchLpIndividualReportsSummary = async (param: {
    startDate: string;
    endDate: string;
    countryCode: number;
  }) => {
    let response = await ApiClient.get(
      LPEndPoints.reports.fetchLpIndividualReportsSummaryData(),
      param,
      LPKeys.LP_REPORT_SUMMARY
    );
    return lpIndividualAccessSummaryFormatter(response);
  };
  fetchLpIndividualSummarySRTableData = async (param: {
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }) => {
    let response = await ApiClient.get(
      LPEndPoints.reports.fetchLpIndividualSummarySRTableData(),
      param,
      LPKeys.LP_REPORT_SUMMARY
    );
    return lpIndividualAccessSummarySRTableFormatter(response);
  };
  fetchTotalAuditDetails = async (param: {
    reportType: string;
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }) => {
    let response = await ApiClient.get(
      LPEndPoints.reports.fetchTotalAuditDetailsData(),
      param,
      LPKeys.LP_REPORT_SUMMARY
    );
    return response;
  };
  fetchLpReportsOpportunityStoreAccess = async (param: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => {
    const { storeId, startDate, endDate } = param;
    let data = await ApiClient.get(
      LPEndPoints.reports.fetchLpStoreAccessOpportunityData(storeId),
      {
        filterByStartDate: startDate,
        filterByEndDate: endDate
      },
      LPKeys.LP_REPORT_SUMMARY
    );
    let finalData: LpReportsSummaryOpportunityDataModel[] = [];
    data.map((item: any) => {
      let deserializeVar: LpReportsSummaryOpportunityDataModel = deserialize(
        LpReportsSummaryOpportunityDataModel,
        item
      );
      finalData.push(deserializeVar);
    });
    return finalData;
  };

  generateReportBuilder = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
    selectedDateRange?: string;
    quaterLabel?: string;
  }) => {
    const {
      object,
      reportType,
      user,
      countryCodes,
      allSelected,
      pageNum,
      selectedDateRange,
      quaterLabel
    } = param;
    // if (
    //   // user.roles.includes(Roles.Admin) ||
    //   (user.roles.includes(Roles.SuperAdmin) ||
    //     user.roles.includes(Roles.TrainingAndDevLead) ||
    //     user.roles.includes(Roles.TrainingAndDevManager) ||
    //     user.roles.includes(Roles.CEO) ||
    //     user.roles.includes(Roles.COO)) &&
    //   reportType === ReportTypeLevel.Comparison &&
    //   object.levelOfReport === "CHANNEL"
    // ) {
    //   let resp = await ApiClient.post(
    //     LPEndPoints.reports.genReportBuilderAdminComparisonChannels(
    //       reportType,
    //       allSelected,
    //       pageNum
    //     ),
    //     object,
    //     null,
    //     LPKeys.LP_REPORT_BUILDER
    //   );
    //   return reportBuilderFormatter(param, resp);
    // } else {
    let response = await ApiClient.post(
      LPEndPoints.reports.generateReportBuilder(reportType, allSelected, pageNum),
      object,
      null,
      LPKeys.LP_REPORT_BUILDER
    );
    return reportBuilderFormatter(param, response);
    // }
  };
  generateGradingReportBuilder = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }) => {
    const { object, allSelected, pageNum } = param;
    let response = await ApiClient.post(
      LPEndPoints.reports.generateGradingReportBuilder(allSelected, pageNum),
      object,
      null,
      LPKeys.LP_REPORT_BUILDER
    );
    let data = gradingReportBuilderFormatter(param, response);
    return data;
  };
  generateAreaOfImprovementReportBuilder = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }) => {
    const { object, countryCodes } = param;
    let obj;
    if (countryCodes) {
      obj = {
        levelOfReport: object?.levelOfReport,
        startDate: object?.startDate,
        endDate: object?.endDate,
        id: object?.idList,
        countryCode: countryCodes
      };
    } else {
      obj = {
        levelOfReport: object?.levelOfReport,
        startDate: object?.startDate,
        endDate: object?.endDate,
        id: object?.idList
      };
    }

    let response = await ApiClient.get(
      LPEndPoints.reports.getAreaOfImprovementReportBuilder(),
      obj,
      LPKeys.LP_REPORT_BUILDER
    );

    return response;
  };
  brandStandardTableData = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }) => {
    const { object, pageNum, countryCodes } = param;
    let obj;
    if (countryCodes) {
      obj = {
        levelOfReport: object?.levelOfReport,
        startDate: object?.startDate,
        endDate: object?.endDate,
        id: object?.idList,
        pageNo: pageNum,
        pageSize: 10,
        countryCode: countryCodes
      };
    } else {
      obj = {
        levelOfReport: object?.levelOfReport,
        startDate: object?.startDate,
        endDate: object?.endDate,
        id: object?.idList,
        pageNo: pageNum,
        pageSize: 10
      };
    }
    let response = await ApiClient.get(
      LPEndPoints.reports.getBrandStandardTabledata(),
      obj,
      LPKeys.LP_REPORT_BUILDER
    );

    return response;
  };
}
const reportsRepository = new ReportsRepository();
export { reportsRepository as ReportsRepository };
