import React, { ReactElement, useEffect, useState } from "react";
import { View, ScrollView } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { DatePicker } from "@timhortons/web/src/components/atoms/datepicker/datepicker";
import CalendarComponent from "@timhortons/tablet/src/components/atoms/datepicker";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import TextField from "@timhortons/common/src/components/atoms/textField";
import ModalDropdown, {
  IDropDownItem
} from "@timhortons/common/src/components/molecules/modalDropdown";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import { styles } from "./styles";
import { avgScores, CategoryLabels, kpis, sosDropDownItems } from "./constants";
import { SaiGlobalUpload } from "@timhortons/web/src/components/molecules/uploadfile/saiGlobalUpload";
import SaiGlobalFile from "@timhortons/tablet/src/components/molecules/fileUpload/saiGlobalFile";
import { IExternalMeta } from "@timhortons/common/src/repositories/rev/selfCalibration";
import { FileModel } from "@timhortons/common/src/models/rev/selfCalibration";
import useDebounce from "@timhortons/web/src/utils/useDebounce";
import { GetStoreByStoreCode } from "@timhortons/common/src/models/profile";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { showError } from "@timhortons/common/src/utils/displayComponent";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import { ISelfCalibrationError } from "@timhortons/common/src/redux/rev/reducers/selfCalibration";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";

interface Props {
  setShow: (val: boolean) => void;
  translate: any;
  externalMetaData: IExternalMeta;
  postExternalAudit: Function;
  getStoreByStoreCode: Function;
  storeByStoreCode: GetStoreByStoreCode;
  getStoreByStoreCodeError: string;
  currentUser: IUserDetails;
  postAuditSuccess: any;
  error: ISelfCalibrationError;
  resetAuthID: Function;
}
interface FormProps {
  date?: any;
  frontCounter?: any;
  driveThrough?: any;
  file?: FileModel[];
}
interface IAvgScores {
  label: string;
  name: string;
  avgPts: number;
  id: number;
  placeHolder: string;
  max: number;
  min: number;
  errmsg: string;
  isValid: boolean;
}
interface IKpiData {
  label: string;
  availablePts: number;
  id: number;
  earnedPts: number;
  errorAvailable: string;
  errorEarned: string;
  isValid: boolean;
}
interface ISosTimer {
  subCategoryId: number;
  timeTaken: string;
  isDriveThrough: boolean;
}
interface IBrandStandardKpi {
  categoryId: number;
  availablePoints: number;
  earnedPoints: number;
}
interface IPostData {
  auditedBy: string;
  storeCode: number;
  auditType: string;
  auditDate: string;
  sosTimer: Array<ISosTimer>;
  foodSafetyPoints: number;
  brandStandardReport: Array<IBrandStandardKpi>;
}

export default function SaiGlobal({
  setShow,
  translate,
  externalMetaData,
  postExternalAudit,
  getStoreByStoreCode,
  storeByStoreCode,
  getStoreByStoreCodeError,
  currentUser,
  postAuditSuccess,
  error,
  resetAuthID
}: Props): ReactElement {
  const [formData, setFormData] = useState<FormProps>(null);
  const [avgScore, setAvgScore] = useState<Array<IAvgScores>>(avgScores(translate));
  const [kpiData, setKpiData] = useState<Array<IKpiData>>(kpis(translate));
  const [resetState, setResetState] = useState<number>(0);
  const [storeCode, setStoreCode] = useState("");
  const [storeCodeError, setStoreCodeError] = useState("");
  const [storeName, setStoreName] = useState("");
  const stepsDebouncedValue = useDebounce(storeCode, 300);
  const [showSCErrorModal, setShowSCErrorModal] = useState(false);

  useEffect(() => {
    if (stepsDebouncedValue !== null && stepsDebouncedValue && stepsDebouncedValue.length >= 3) {
      getStoreByStoreCode({ storeCode: Number(storeCode) });
    }
    if (stepsDebouncedValue !== null && stepsDebouncedValue && stepsDebouncedValue.length < 3) {
      setStoreName("");
    }
  }, [stepsDebouncedValue]);

  useEffect(() => {
    storeByStoreCode && setStoreName(storeByStoreCode.name);
  }, [storeByStoreCode]);

  useEffect(() => {
    setStoreName("");
    getStoreByStoreCodeError?.length > 0 && setStoreCodeError("Invalid storecode");
  }, [getStoreByStoreCodeError]);

  useEffect(() => {
    if (resetState > 0) {
      avgScore.slice(0, 2).forEach((_item, index) => {
        handleChange(index, "");
      });
      kpiData.slice(0, 6).forEach((_item, index) => {
        handleKpiChange(index, "", "available");
        handleKpiChange(index, "", "earned");
      });
      setFormData((prevState) => ({
        ...prevState,
        driveThrough: null,
        frontCounter: null
      }));
      setStoreCode("");
      setStoreName("");
    }
  }, [resetState]);
  const disableButton = () => {
    const isValid =
      storeName &&
      formData &&
      formData.date &&
      formData.driveThrough &&
      formData.file &&
      formData.file.length > 0 &&
      formData.frontCounter &&
      !avgScore.some(function (arrVal) {
        return false === arrVal.isValid;
      }) &&
      !kpiData.some(function (arrVal) {
        return false === arrVal.isValid;
      });
    return !isValid;
  };
  const findBEId = (label: CategoryLabels, type: "BS" | "TIMER"): number => {
    if (externalMetaData) {
      let categoryId = 0;
      if (type === "BS") {
        categoryId = externalMetaData.kpiCategories.find((el) => el.label === label).id;
      } else {
        categoryId = externalMetaData.timerSubCategories.find((el) => el.label === label).id;
      }
      return categoryId;
    }
  };
  const handleFormatter = () => {
    let postfiles: Blob[] = (formData.file && formData.file.map((img) => img.blob)) || [];
    let postFormData = new FormData();
    postfiles &&
      postfiles.map((img) => {
        postFormData.append("file", img);
      });
    let obj: IPostData = {
      auditedBy: currentUser.empId,
      storeCode: Number(storeCode),
      auditType: "EXTERNAL",
      auditDate: new Date(formData && formData.date).toISOString(), //"2017-01-13T17:09:42.411"
      sosTimer: [
        {
          subCategoryId: findBEId("SS12", "TIMER"),
          timeTaken: formData && formData.frontCounter, // "02:01:15"
          isDriveThrough: false
        },
        {
          subCategoryId: findBEId("SS11", "TIMER"),
          timeTaken: formData && formData.driveThrough, // "01:00:30"
          isDriveThrough: true
        }
      ],
      foodSafetyPoints: Number(avgScore[0].avgPts),
      brandStandardReport: [
        {
          categoryId: findBEId("CM", "BS"), // cleanliness
          availablePoints: kpiData[0].availablePts,
          earnedPoints: Number(kpiData[0].earnedPts)
        },
        {
          categoryId: findBEId("GUEST", "BS"), // guest
          availablePoints: kpiData[1].availablePts,
          earnedPoints: Number(kpiData[1].earnedPts)
        },
        {
          categoryId: findBEId("SOS", "BS"), //sos
          availablePoints: kpiData[2].availablePts,
          earnedPoints: Number(kpiData[2].earnedPts)
        },
        {
          categoryId: findBEId("PSM", "BS"), //pnm
          availablePoints: kpiData[3].availablePts,
          earnedPoints: Number(kpiData[3].earnedPts)
        },
        {
          categoryId: findBEId("T&D", "BS"), // training
          availablePoints: kpiData[4].availablePts,
          earnedPoints: Number(kpiData[4].earnedPts)
        },
        {
          categoryId: findBEId("FQ", "BS"), // food quality
          availablePoints: kpiData[5].availablePts,
          earnedPoints: Number(kpiData[5].earnedPts)
        }
      ]
    };
    postExternalAudit({ obj, files: postFormData });
  };

  useEffect(() => {
    if (postAuditSuccess !== null) {
      setResetState((prevState: number) => prevState + 1);
      setShow(false);
      resetAuthID();
    }
  }, [postAuditSuccess]);

  useEffect(() => {
    error.postExternalAuditData && setShowSCErrorModal(!showSCErrorModal);
  }, [error.postExternalAuditData]);

  const validateField = (index: number, val: any) => {
    let avgError;
    let data = [...avgScore];
    if (val) {
      if (!(val >= avgScore[index].min && val <= avgScore[index].max)) {
        avgError = `Range is ${avgScore[index].min} to ${avgScore[index].max}`;

        data[index].isValid = false;
      } else {
        data[index].isValid = true;
      }
    } else {
      avgError = "Required";
      data[index].isValid = false;
    }

    data[index].errmsg = avgError;
    setAvgScore(data);
  };
  const validateKpiField = (index: number, val: any, state: string) => {
    let kpiError;
    let data = [...kpiData];
    if (state === "available") {
      if (val) {
        if (val > 0) {
          data[index].isValid = true;
        } else {
          kpiError = "Only positive values";
          data[index].isValid = false;
        }
      } else {
        kpiError = "Required";
        data[index].isValid = false;
      }
      data[index].errorAvailable = kpiError;
    } else {
      if (val) {
        if (val > 0) {
          if (Number(val) > Number(kpiData[index].availablePts)) {
            kpiError = `Earned should not be greater than available`;
            data[index].isValid = false;
          }
          // else if (val < 0) {
          //   error = "Only positive values";
          //   data[index].isValid = false;
          // }
          else {
            data[index].isValid = true;
          }
          data[index].errorEarned = kpiError;
        } else {
          kpiError = "Only positive values";
          data[index].isValid = false;
        }
      } else {
        kpiError = "Required";
        data[index].isValid = false;
      }
      data[index].errorEarned = kpiError;
    }

    setKpiData(data);
  };

  const handleOverAllScore = () => {
    if (avgScore[0].isValid && avgScore[1].isValid) {
      return (Number(avgScore[0].avgPts) + Number(avgScore[1].avgPts)).toString();
    }
    return "";
  };

  const handleChange = (index: number, val: any) => {
    let data = [...avgScore];
    data[index].avgPts = val;
    setAvgScore(data);
    validateField(index, val);
  };
  const handleBlur = (index: number) => {
    const val = avgScore[index].avgPts;
    let data = [...avgScore];
    let blurError;
    if (!val) {
      blurError = "Required";
      data[index].isValid = false;
      data[index].errmsg = blurError;
    }
    setAvgScore(data);
  };
  const handleKpiChange = (index: number, val: any, state: string) => {
    let data = [...kpiData];
    if (state === "available") {
      data[index].availablePts = val;
      if (data[index].earnedPts > data[index].availablePts) {
        data[index].earnedPts = 0;
        data[index].errorEarned = "Enter value";
        data[index].isValid = false;
      }
    } else {
      data[index].earnedPts = val;
    }
    validateKpiField(index, val, state);
    setKpiData(data);
  };
  const handleKpiAvailableBlur = (index: number, state: string) => {
    let err;
    let data = [...kpiData];
    if (state === "available") {
      const val = kpiData[index].availablePts;
      if (!val) {
        err = "Required";
        data[index].isValid = false;
        data[index].errorAvailable = err;
      }
    } else {
      const val = kpiData[index].earnedPts;
      if (!val) {
        err = "Required";
        data[index].isValid = false;
        data[index].errorEarned = err;
      }
    }

    setKpiData(data);
  };

  const handleDatepickerCallback = (value: Date | Date[]) => {
    setResetState(0);
    setFormData((prevState) => ({
      ...prevState,
      date: value
    }));
  };

  const handleFileChange = (file: FileModel[]) => {
    setFormData((prevState) => ({
      ...prevState,
      file: file
    }));
  };
  const displayTextBlock = (value: string) => {
    return (
      <Text testId={value} fontWeight={FontWeight.Regular} textSize={TextSize.ExtraFineSmall}>
        {translate(value)}
      </Text>
    );
  };

  const handleStoreCode = (val: any) => {
    setStoreCode(val);
    setStoreCodeError("");
  };

  const handleStoreCodeBlur = () => {
    const val = storeCode;
    if (val === "") {
      setStoreCodeError("Required");
    } else if (Number(val?.length) !== 6 || Number(val?.charAt(0)) !== 8) {
      setStoreCodeError("Restaurant code should be of 6 digits & starting with 8");
    }
  };

  return (
    <>
      {error.getExternalMeta !== null ? (
        <GetModal customStyle={styles.masterModalWrapper} setModalState={setShow}>
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        </GetModal>
      ) : (
        <GetModal customStyle={styles.masterModalWrapper} setModalState={setShow}>
          <View style={styles.modal}>
            <View style={styles.headerWrapper}>
              <View>
                <Text testId="s" fontWeight={FontWeight.Regular} textSize={TextSize.Medium}>
                  {translate("saiGlobalAudits")}
                </Text>
              </View>
              <View>
                <Icon
                  onPress={() => {
                    setShow(false);
                    setResetState((prevState: number) => prevState + 1);
                    //  resetAuthID();
                  }}
                  name={IconNames.close}
                  size={16}
                  customStyle={{ fontWeight: 300 }}
                />
              </View>
            </View>
            <ScrollView>
              <View style={styles.formMasterWrapper}>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1 }}>
                    <Text
                      testId="s"
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                    >
                      {translate("Store Information")}
                    </Text>
                  </View>
                  <View style={{ flex: 3, flexDirection: "row" }}>
                    <View style={styles.inputBoxLbl}>
                      <Text
                        testId="ssa3sas"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.ExtraFineSmall}
                      >
                        {translate("Store Code")}
                      </Text>
                      <TextField
                        handleChange={(e: any) => handleStoreCode(e)}
                        inputStyle={styles.inputContainerStyles}
                        handleBlur={() => handleStoreCodeBlur()}
                        value={storeCode}
                        placeholder="Enter Code"
                        name={"storeCode"}
                        id={"storeCode"}
                        handleKeyPress={null}
                        error={storeCodeError}
                        keyboardType={"numeric"}
                      />
                    </View>
                    <View style={styles.inputBoxLbl}>
                      <Text
                        testId="ssa3sas"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.ExtraFineSmall}
                      >
                        {translate("Store Name")}
                      </Text>
                      <TextField
                        editable={false}
                        handleKeyPress={() => {
                          // Handle key press
                        }}
                        inputStyle={styles.inputContainerStyles}
                        handleBlur={() => {
                          // Handle blur function
                        }}
                        value={storeName}
                        placeholder=""
                        name={"storeName"}
                        id={"storeName"}
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.inputRow}>
                  <View style={{ flex: 1 }}>
                    <Text
                      testId="s"
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                    >
                      {translate("auditDate")}
                    </Text>
                  </View>
                  <View style={{ flex: 3 }}>
                    {displayTextBlock("selectDate")}
                    <View style={styles.inputBoxLbl}>
                      {PlatformUtils.isWeb() ? (
                        <DatePicker
                          parentCallBack={(value) => handleDatepickerCallback(value)}
                          dateContainerStyle={styles.inputContainerStyles}
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraSmall}
                          iconSize={12}
                          reset={resetState}
                        />
                      ) : (
                        <CalendarComponent
                          selectRange={false}
                          parentCallBack={(value) => handleDatepickerCallback(value)}
                          reset={resetState}
                          iconSize={12}
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraSmall}
                          dateContainerStyle={styles.inputContainerStyles}
                        />
                      )}
                      {showError(formData?.date || "", true, true)}
                    </View>
                  </View>
                </View>

                <View style={styles.inputRow}>
                  <View style={{ flex: 1 }}>
                    <Text
                      testId="s"
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                    >
                      {translate("averageScore")}
                    </Text>
                  </View>

                  <View style={styles.inputBoxesWrapper}>
                    {avgScore.map((el, index) => {
                      return (
                        <View key={`${el.id}-avg`} style={styles.inputBoxLbl}>
                          <Text
                            testId="ssa3sas"
                            fontWeight={FontWeight.Regular}
                            textSize={TextSize.ExtraFineSmall}
                          >
                            {el.label}
                          </Text>
                          <TextField
                            handleChange={(e: any) => handleChange(index, e)}
                            inputStyle={styles.inputContainerStyles}
                            handleBlur={() => handleBlur(index)}
                            value={avgScore[index].avgPts.toString()}
                            placeholder="Enter Pts"
                            name={`avgScores[${el.name}]`}
                            id={`avgScores[${el.id}]`}
                            handleKeyPress={null}
                            error={el.errmsg}
                            keyboardType={"numeric"}
                          />
                        </View>
                      );
                    })}
                    <View style={styles.inputBoxLbl}>
                      <Text
                        testId="ssa3sas"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.ExtraFineSmall}
                      >
                        {translate("overallScore")}
                      </Text>
                      <TextField
                        editable={false}
                        handleKeyPress={() => {
                          // Handle key press
                        }}
                        inputStyle={styles.inputContainerStyles}
                        handleBlur={() => {
                          // Handle blur function
                        }}
                        value={handleOverAllScore()}
                        placeholder="0"
                        name={"overallScores"}
                        id={"2"}
                      />
                    </View>
                  </View>
                </View>

                <View style={styles.inputRow}>
                  <View style={{ flex: 1 }}>
                    <Text
                      testId="s"
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                    >
                      {translate("sos")}
                    </Text>
                  </View>
                  <View style={styles.inputBoxesWrapper}>
                    <View style={styles.inputBoxLbl}>
                      <Text
                        testId="ssa3sas"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.ExtraFineSmall}
                      >
                        {translate("frontCourier")}
                      </Text>
                      <ModalDropdown
                        dropdownViewStyle={styles.inputContainerStyles}
                        allItems={sosDropDownItems}
                        selectedValueDefault={
                          (formData && formData.frontCounter && formData.frontCounter) || null
                        }
                        onChangeCallBack={(obj: IDropDownItem) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            frontCounter: obj.value
                          }))
                        }
                      />
                      {showError(formData?.frontCounter || "", true, true)}
                    </View>
                    <View style={styles.inputBoxLbl}>
                      <Text
                        testId="ssa3sas"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.ExtraFineSmall}
                      >
                        {translate("driveThrough")}
                      </Text>
                      <ModalDropdown
                        dropdownViewStyle={styles.inputContainerStyles}
                        allItems={sosDropDownItems}
                        selectedValueDefault={
                          (formData && formData.driveThrough && formData.driveThrough) || null
                        }
                        onChangeCallBack={(obj: IDropDownItem) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            driveThrough: obj.value
                          }))
                        }
                      />
                      {showError(formData?.driveThrough || "", true, true)}
                    </View>
                  </View>
                </View>

                <View style={styles.inputRow}>
                  <View style={{ flex: 1 }}>
                    <Text
                      testId="s"
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                    >
                      {translate("scoreByKpi")}
                    </Text>
                  </View>
                  <View style={[styles.inputBoxesWrapper, { flexDirection: "column" }]}>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 1,
                        paddingBottom: 10,
                        borderBottomWidth: 1,
                        borderColor: colorPallete.white1,
                        marginBottom: 10
                      }}
                    >
                      <View style={{ flex: 2 }}>
                        <Text
                          testId="ssa3sas"
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraRegular}
                        >
                          {translate("platform")}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text
                          testId="ssa3sas"
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraRegular}
                        >
                          {translate("available")}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text
                          testId="ssa3sas"
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraRegular}
                        >
                          {translate("earned")}
                        </Text>
                      </View>
                    </View>
                    {kpiData.map((el, index) => {
                      return (
                        <View
                          key={index}
                          style={
                            el.errorAvailable || el.errorEarned
                              ? { flexDirection: "row", flex: 1, marginBottom: 30 }
                              : { flexDirection: "row", flex: 1, marginBottom: 16 }
                          }
                        >
                          <View style={{ flex: 2 }}>
                            <Text
                              testId="ssa3sas"
                              textStyle={{ color: colorPallete.grey1 }}
                              fontWeight={FontWeight.Regular}
                              textSize={TextSize.ExtraRegular}
                            >
                              {el.label}
                            </Text>
                          </View>
                          <View style={{ flex: 1 }}>
                            <TextField
                              handleChange={(e: any) => handleKpiChange(index, e, "available")}
                              inputStyle={{
                                textAlign: "center",
                                width: 70,
                                height: 36,
                                backgroundColor: colorPallete.white2,
                                borderWidth: 1,
                                borderColor: colorPallete.grey6,
                                borderRadius: 4
                              }}
                              keyboardType={"numeric"}
                              handleBlur={() => handleKpiAvailableBlur(index, "available")}
                              value={el.availablePts.toString()}
                              placeholder="Enter Pts"
                              name={`kpis[${el.id}]`}
                              id={`kpis[${el.id}]`}
                              fieldStyle={{ justifyContent: "center" }}
                              handleKeyPress={null}
                              error={el.errorAvailable}
                            />
                          </View>
                          <View style={{ flex: 1 }}>
                            <TextField
                              handleChange={(e: any) => handleKpiChange(index, e, "earned")}
                              inputStyle={{
                                textAlign: "center",
                                width: 70,
                                height: 36,
                                borderWidth: 1,
                                borderColor: colorPallete.grey6,
                                borderRadius: 4
                              }}
                              keyboardType={"numeric"}
                              handleBlur={() => handleKpiAvailableBlur(index, "earned")}
                              error={el.errorEarned}
                              value={el.earnedPts.toString()}
                              placeholder="Enter Pts"
                              name={`kpis.${el.id}.earnedPts`}
                              id={`kpis.${el.id}.earnedPts`}
                              handleKeyPress={null}
                            />
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
                <View style={styles.inputRow}>
                  <View style={{ flex: 1 }}>
                    <Text
                      testId="s"
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                    >
                      {translate("saiGlobalReport")}
                    </Text>
                    <Text
                      testId="s"
                      textStyle={{ color: colorPallete.grey4 }}
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraFineSmall}
                    >
                      {translate("allowedFileType")}
                    </Text>
                  </View>
                  <View style={styles.inputBoxesWrapper}>
                    {PlatformUtils.isWeb() ? (
                      <SaiGlobalUpload
                        reset={resetState}
                        handleFileChange={(files: FileModel[]) => handleFileChange(files)}
                      />
                    ) : (
                      <SaiGlobalFile
                        reset={resetState}
                        handleFileChange={(files: FileModel[]) => handleFileChange(files)}
                      />
                    )}
                  </View>
                </View>
                <View style={styles.inputRow}>
                  <View style={styles.saveBtnWrapper}>
                    <Button
                      type={ButtonType.Secondary}
                      title={translate("reset")}
                      textSize={TextSize.ExtraSmall}
                      buttonStyles={styles.saveBtn}
                      onPress={() => setResetState((prevState: number) => prevState + 1)}
                    />
                    <Button
                      type={ButtonType.Primary}
                      title={translate("save")}
                      textSize={TextSize.ExtraSmall}
                      buttonStyles={styles.saveBtn}
                      onPress={() => handleFormatter()}
                      disabled={disableButton()}
                    />
                  </View>
                </View>
              </View>
              {showSCErrorModal && (
                <AlertModal setModalVisible={() => setShowSCErrorModal(!showSCErrorModal)} />
              )}
            </ScrollView>
          </View>
        </GetModal>
      )}
    </>
  );
}
