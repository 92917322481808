import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { AppealsAction } from "@timhortons/common/src/redux/rev/actions/appeals";
import { AppealRepository } from "@timhortons/common/src/repositories/rev/appeals";
import { AppealCountModel, InProcesssClosedTable } from "@timhortons/common/src/models/rev/appeals";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  AppealsAccordionDataModel,
  NewAppealsTable,
  // InProcessAppealTableModel,
  AppealsCategoryDataModel,
  CreateNewAppeal,
  AppealCommentsDataModel,
  PostAppealCommentsModel
} from "models/rev/appeals";
import { ICreateNewAppeals } from "@timhortons/common/src/modules/rev/components/dashboards";
import { Logger } from "@timhortons/common/src/services/logger";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

function* getAppealsCountWorkerSaga(
  value: IFluxStandardAction<{ appealerId: string; isIndividual: boolean }>
) {
  const { payload } = value;
  try {
    const response: AppealCountModel = yield call(AppealRepository.getAppealsCount, payload);
    yield put(AppealsAction.getAppealsCountDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "getAppealsCountWorkerSaga");

    yield put(AppealsAction.getAppealsCountDataError(error));
  }
}
function* getNewAppealsWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    status: string;
  }>
) {
  const { payload } = value;
  try {
    const response: NewAppealsTable[] = yield call(AppealRepository.getNewAppeals, payload);
    yield put(AppealsAction.getNewAppealsSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "getNewAppealsWorkerSaga");

    yield put(AppealsAction.getNewAppealsError(error));
  }
}

function* getInProcessAppealsWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    status: string;
  }>
) {
  const { payload } = value;
  try {
    const response: InProcesssClosedTable[] = yield call(
      AppealRepository.getInProcessAppeals,
      payload
    );
    yield put(AppealsAction.getInProcessAppealsSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "getInProcessAppealsWorkerSaga");

    yield put(AppealsAction.getInProcessAppealsError(error));
  }
}

function* getClosedAppealsWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    status: string;
  }>
) {
  const { payload } = value;
  try {
    const response: InProcesssClosedTable[] = yield call(
      AppealRepository.getInProcessAppeals,
      payload
    );
    yield put(AppealsAction.getClosedAppealsSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "getClosedAppealsWorkerSaga");

    yield put(AppealsAction.getClosedAppealsError(error));
  }
}

function* getAppealAccordianWorkerSaga(
  value: IFluxStandardAction<{ appealId: number; categoryId: number }>
) {
  const { payload } = value;
  try {
    const response: AppealsAccordionDataModel[] = yield call(
      AppealRepository.getAppealAccordionData,
      payload
    );
    yield put(AppealsAction.getAppealAccordionDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "getAppealAccordianWorkerSaga");

    yield put(AppealsAction.getAppealAccordionDataError(error));
  }
}

function* setAppealDecisionWorkerSaga(value: any) {
  const { payload } = value;
  let data;
  let user: IUserDetails = yield LocalStorage.getStorage("user");
  data = user.empId;
  try {
    const response: { status: string; data: any } = yield call(
      AppealRepository.setAppealDecision,
      payload
    );
    yield put(AppealsAction.setAppealDecisionSuccess(response));
    let body = payload;
    let value;
    if (PlatformUtils.isMobile()) {
      const field = body.getParts().find((item: any) => item.fieldName === "commentByAuditor");
      if (field) {
        value = field.string;
      }
    }

    if (
      (PlatformUtils.isWeb() && payload.get("commentByAuditor") !== " ") ||
      (PlatformUtils.isMobile() && value !== " ")
    ) {
      yield put(
        AppealsAction.inProcessSetAppealFilter({
          userId: Number(data),
          selectedValues: {
            appealStatus: "IN_PROCESS",
            appealItemStatus: "IN_PROCESS",
            fromDate: null,
            endDate: null
          }
        })
      );
      yield put(AppealsAction.getAppealsCountData({ isIndividual: true, appealerId: data }));
    }
  } catch (error) {
    Logger.error("Rev/appeals", error, "setAppealDecisionWorkerSaga");

    yield put(AppealsAction.setAppealDecisionError(error));
  }
}

function* setAppealFilterWorkerSaga(
  value: IFluxStandardAction<{ userId: number; selectedValues: any }>
) {
  const { payload } = value;
  try {
    const response: InProcesssClosedTable[] = yield call(AppealRepository.getAppeals, payload);
    yield put(AppealsAction.inProcessSetAppealFilterSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "setAppealFilterWorkerSaga");

    yield put(AppealsAction.inProcessSetAppealFilterError(error));
  }
}

function* postAppealsCommentDataWorkerSaga(
  value: IFluxStandardAction<{ body: PostAppealCommentsModel; employeeId: string }>
) {
  try {
    const { payload } = value;
    const response: AppealCommentsDataModel = yield call(
      AppealRepository.postAppealsCommentData,
      payload
    );
    yield put(AppealsAction.postAppealCommentsDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "postAppealsCommentDataWorkerSaga");

    yield put(AppealsAction.postAppealCommentsDataError(error));
  }
}
function* createNewAppealWorkerSaga(value: IFluxStandardAction<ICreateNewAppeals>) {
  const { payload } = value;
  try {
    let data: CreateNewAppeal = yield call(AppealRepository.createAppeal, payload);
    data.appealerId = payload.auditorId;
    yield put(AppealsAction.createNewAppealSuccess(data));
  } catch (error) {
    Logger.error("Rev/appeals", error, "createNewAppealWorkerSaga");

    yield put(AppealsAction.createNewAppealError(error));
  }
}
function* getAppealsCategoryDataWorkerSaga(value: IFluxStandardAction<{ appealId: number }>) {
  const { payload } = value;
  try {
    const response: AppealsCategoryDataModel = yield call(
      AppealRepository.getAppealsCategoryData,
      payload
    );
    yield put(AppealsAction.getAppealsCategoryDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/appeals", error, "getAppealsCategoryDataWorkerSaga");

    yield put(AppealsAction.getAppealsCategoryDataError(error));
  }
}

function* appealsWatcherSaga() {
  yield takeLatest(REV.GET_APPEALS_COUNT.LOADING, getAppealsCountWorkerSaga);
  yield takeLatest(REV.GET_NEW_APPEALS_DATA.LOADING, getNewAppealsWorkerSaga);
  yield takeLatest(REV.GET_IN_PROCESS_APPEALS_DATA.LOADING, getInProcessAppealsWorkerSaga);
  yield takeLatest(REV.GET_CLOSED_APPEALS_DATA.LOADING, getClosedAppealsWorkerSaga);
  yield takeLatest(REV.GET_APPEAL_ACCORDION_DATA.LOADING, getAppealAccordianWorkerSaga);
  yield takeLatest(REV.SET_APPEAL_DECISON_DATA.LOADING, setAppealDecisionWorkerSaga);
  yield takeEvery(REV.SET_APPEAL_FILTER.LOADING, setAppealFilterWorkerSaga);
  yield takeLatest(REV.POST_APPEAL_COMMENT_DATA.LOADING, postAppealsCommentDataWorkerSaga);
  yield takeLatest(REV.Appeals.CREATE_NEW_APPEAL.LOADING, createNewAppealWorkerSaga);
  yield takeLatest(REV.GET_APPEALS_CATEGORY_DATA.LOADING, getAppealsCategoryDataWorkerSaga);
}

export default appealsWatcherSaga;
