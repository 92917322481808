import { StyleSheet, View } from "react-native";
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import LpCommonBarGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/commonBarGraph";
import { NumberOfBars } from "@timhortons/tablet/src/components/molecules/barChart";
import { fetchYMax, graphColors } from "@timhortons/common/src/utils/graphColorHelper";
import {
  LpStoreAccessSummaryDataModel,
  LpReportsSummaryOpportunityDataModel
} from "@timhortons/common/src/models/lp/reports";
import { LpReportsSelectors } from "@timhortons/common/src/redux/lp/selectors/reportsSelector";
import { IState } from "@timhortons/common/src/store/interfaces";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import { LpReportsActions } from "@timhortons/common/src/redux/lp/actions/reportsAction";
import { SelectedMonthFilter } from "@timhortons/common/src/modules/lp/components/dashboard";
import {
  ILpReportsLoading,
  ILpReportsError
} from "@timhortons/common/src/redux/lp/reducers/reportsReducer";
import Jumbotron from "@timhortons/common/src/components/organisms/jumbotron";
import { ErrorType } from "@timhortons/common/src/components/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import LpActionplanGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpActionPlanGraph";
import LpKpiGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpKpiGraph";
import LpOpportunityTable from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/reportsSummaryOpportunityTable";

interface Iprops {
  translate?: any;
  user?: IUserDetails;
  lpSummaryData?: LpStoreAccessSummaryDataModel;
  getLpStoreAccessSummaryData: Function;
  lpSelectedMonth: { fromDate: Date; toDate: Date };
  lpLoading: ILpReportsLoading;
  lpError: ILpReportsError;
  lpOpportunityData?: LpReportsSummaryOpportunityDataModel[];
  getLpStoreAccessOpportunityData: Function;
}
function LpStoreAccessSummary(props: Iprops) {
  const {
    translate,
    user,
    lpSummaryData,
    getLpStoreAccessSummaryData,
    lpSelectedMonth,
    lpError,
    lpLoading,
    lpOpportunityData,
    getLpStoreAccessOpportunityData
  } = props;
  useEffect(() => {
    getLpStoreAccessSummaryData({
      storeId: user.storeCode,
      startDate: convert(lpSelectedMonth.fromDate),
      endDate: convert(lpSelectedMonth.toDate)
    });
    getLpStoreAccessOpportunityData({
      storeId: user.storeCode,
      startDate: convert(lpSelectedMonth.fromDate),
      endDate: convert(lpSelectedMonth.toDate)
    });
  }, [lpSelectedMonth.fromDate.toDateString(), lpSelectedMonth.toDate.toDateString()]);

  const lpActionPlanGraphData = {
    // series: summaryData && summaryData.actionPlansData.series,
    series: lpSummaryData && lpSummaryData.actionPlansData.series,
    labels: lpSummaryData && lpSummaryData.actionPlansData.labels,
    //totalReports: summaryData && summaryData.actionPlansData.totalReports,
    totalReports: lpSummaryData && lpSummaryData.actionPlansData.totalReports,
    inProcessReport: lpSummaryData && lpSummaryData.actionPlansData.inProcessReport,
    height: 300
  };

  const criticalPointsReportData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData.criticalPointsReport.averageDeductedPoints
      }
    ],
    categories: lpSummaryData && lpSummaryData.criticalPointsReport.labels,
    categoryColors: graphColors.gradeGraph,
    leftTitle: translate("criticalPoints"),
    labelSuffix: "",
    height: 300,
    ymax: fetchYMax(lpSummaryData && lpSummaryData.criticalPointsReport.averageDeductedPoints, 20),
    xAxisTitle: "KPI's",
    yAxisTitle: "Deductions"
  };

  const completeAverageScore = {
    series: [
      {
        data: lpSummaryData && lpSummaryData.completeAverageScore.percentage
      }
    ],
    categories: lpSummaryData && lpSummaryData.completeAverageScore.name,
    categoryColors: graphColors.gradeGraph,
    leftTitle: translate("completeAvgScore"),
    labelSuffix: "%",
    height: 300,
    // ymax: fetchYMax(summaryData && summaryData.completeAverageScore.percentage, 25)
    ymax: 100
  };

  // const monthOnMonthColours = () => {
  //   let monthColors: any[] = [];
  //   lpSummaryData &&
  //     lpSummaryData.monthOnMonthAverageData.percentage.map((item) => {
  //       switch (true) {
  //         case item >= 80:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[0])
  //           };
  //         case item >= 60:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[1])
  //           };
  //         case item >= 40:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[2])
  //           };
  //         case item >= 20:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[3])
  //           };
  //         case item >= 0:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[4])
  //           };
  //       }
  //     });
  //   return monthColors;
  // };

  const monthOnMonthAverageScore = {
    series: [
      {
        data: lpSummaryData && lpSummaryData.monthOnMonthAverageData.percentage
      }
    ],
    columnWidth: "25",
    categories: lpSummaryData && lpSummaryData.monthOnMonthAverageData.month,
    isMonthOnMonth: true,
    // categoryColors: monthOnMonthColours(),
    categoryColors: graphColors.gradeGraph,
    leftTitle: translate("monthOnMonthAvgScore"),
    labelSuffix: "%",
    height: 300,
    ymax: 100
  };

  const kpiGraphData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData.brandStandardAverageReport.series
      }
    ],
    categories: lpSummaryData && lpSummaryData.brandStandardAverageReport.categories,
    categoryColors: graphColors.bsGraph,
    leftTitle: translate("lp-kpi's"),
    rightTitle: `${translate("avgScore")} : ${
      lpSummaryData && lpSummaryData.brandStandardAverageReport.averageScore
    }%`,
    isBSGraph: true,
    height: 300,
    labelSuffix: "%",
    ymax: fetchYMax(lpSummaryData && lpSummaryData.brandStandardAverageReport.series, 25)
  };

  return (
    <>
      {lpError.lpStoreAccessSummaryData ? (
        <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
      ) : lpLoading.lpStoreAccessSummaryData || lpSummaryData === null ? (
        <Loader type="component" />
      ) : (
        <View style={styles.container}>
          <View style={styles.elementConatiner}>
            <View style={styles.storeGraphContainer}>
              <LpCommonBarGraph
                barWidthSpace={NumberOfBars.OneBar}
                barChartData={completeAverageScore}
              />
            </View>
            <View style={styles.momGraphContainer}>
              <LpCommonBarGraph
                barChartData={monthOnMonthAverageScore}
                barWidthSpace={NumberOfBars.OneBar}
              />
            </View>
          </View>
          <View style={styles.elementConatiner}>
            <View style={styles.actionplanGraphContainer}>
              <LpActionplanGraph
                translate={translate}
                series={lpActionPlanGraphData.series}
                labels={lpActionPlanGraphData.labels}
                totalReports={lpActionPlanGraphData.totalReports}
                inProcessReport={lpActionPlanGraphData.inProcessReport}
                height={310}
                leftHeader={translate("actionPlans")}
              />
            </View>
            <View style={styles.criticalPointsGraphContainer}>
              <LpCommonBarGraph barChartData={criticalPointsReportData} />
            </View>
          </View>
          <View style={styles.kpiGraph}>
            <LpKpiGraph graphData={kpiGraphData} />
          </View>
          <View>
            <LpOpportunityTable
              translate={translate}
              currentUserRoles={user && user.roles}
              opportunityData={lpOpportunityData}
              loading={lpLoading.lpStoreAccessOpportunityData}
              error={lpError.lpStoreAccessOpportunityData}
            />
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {},
  elementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  storeGraphContainer: {
    flex: 1
  },
  momGraphContainer: {
    flex: 1,
    marginLeft: 24
  },
  actionplanGraphContainer: {
    flex: 1
  },

  criticalPointsGraphContainer: {
    flex: 1,
    marginLeft: 24
  },
  kpiGraph: {
    marginBottom: 24
  }
});

const mapStateToProps = (state: IState) => {
  return {
    lpSummaryData: LpReportsSelectors.fetchLpStoreAccessSummaryData(state),
    lpLoading: LpReportsSelectors.lpReportsLoading(state),
    lpError: LpReportsSelectors.lpReportsError(state),
    lpSelectedMonth: LpReportsSelectors.getLpSelectedMonthFilter(state),
    lpOpportunityData: LpReportsSelectors.fetchLpStoreAccessOpportunityData(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...LpReportsActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(LpStoreAccessSummary);
