import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IManageLmsModuleState,
  IManageLmsModuleError,
  IManageLmsModuleLoading
} from "@timhortons/common/src/redux/manageLms/reducers/manageLmsModuleReducer";
import {
  ManageLmsPostCreateModuleResponseDataModel,
  LMSGetAllModulesDataModel,
  LMSCategoryModel,
  LMSGetModuleByIdModel,
  LMSPostTopicResponseModel,
  LMSGetModuleCategoriesModel,
  LMSgetTopicsModel,
  LMSGetProgressBarStatusModel,
  LMSUploadVideoModel,
  LMSMtfDndQuizModel,
  LMSImgDataModel,
  LMSCertificateModel,
  LMSTicTacQuizModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import { ISummaryData } from "@timhortons/common/src/repositories/manageLms/manageLmsFormatter";

const manageLmsModuleSelect = (state: IState): IManageLmsModuleState => state.manageLms.lmsModule;

const manageLmsModuleLoading: Selector<IState, IManageLmsModuleLoading> = createSelector(
  manageLmsModuleSelect,
  (data) => data.loading
);

const manageLmsModuleError: Selector<IState, IManageLmsModuleError> = createSelector(
  manageLmsModuleSelect,
  (data) => data.error
);

const postManageLmsCreateModuleDataResponse: Selector<
  IState,
  ManageLmsPostCreateModuleResponseDataModel
> = createSelector(manageLmsModuleSelect, (data) => data.postManageLmsCreateModuleData);

const getLMSAllModulesData: Selector<IState, LMSGetAllModulesDataModel[]> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getLMSAllModulesData
);

const lmsModuleListById: Selector<IState, LMSGetModuleByIdModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getLMSModuleById
);

const postLmsCategoryDetail: Selector<IState, LMSCategoryModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.postLMSCategory
);

const getLmsModuleCategoriesData: Selector<IState, LMSGetModuleCategoriesModel[]> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getLmsModuleCategoriesData
);

const postTopicSelector: Selector<IState, LMSPostTopicResponseModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.postTopic
);

const editCategorySelector: Selector<IState, { id: string; name: string }> = createSelector(
  manageLmsModuleSelect,
  (data) => data.editLmsCategory
);

const deleteLmsTopicSelector: Selector<IState, { id: string; name: string }> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteLmsTopic
);

const getTopicByIdData: Selector<IState, LMSgetTopicsModel[]> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getLmsTopicById
);

const getLmsProgressBarStatus: Selector<IState, LMSGetProgressBarStatusModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getProgressBarStatus
);

const deleteLmsModuleSelector: Selector<IState, LMSGetModuleByIdModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteLmsModule
);

const getSummaryDetailsSelector: Selector<IState, ISummaryData[]> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getSummaryDetails
);

const deleteCategorySelector: Selector<IState, { id: string; name: string }> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteLmsCategory
);
//TODO:use model
const getCategoryDetailsAll: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getCategoryDetailsAll
);

const getVideoUploadLink: Selector<IState, { uploadUrl: string; getUrl: string }> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getVideoUploadLink
);

const uploadLmsVideo: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.uploadLmsVideo
);

const deleteLmsVideo: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteLmsVideo
);

const postMtfDndQuiz: Selector<IState, LMSMtfDndQuizModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.postMtfDndQuiz
);

const getMtfDndQuiz: Selector<IState, LMSMtfDndQuizModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getMtfDndQuiz
);

const lmsImgUpload: Selector<IState, LMSImgDataModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.postLmsImgUpload
);

const lmsImgDelete: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteLmsImg
);
const postSubtopicResponse: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.postLmsSubtopic
);

const deleteLmsSubtopic: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteLmsSubtopic
);

const editLmsSubtopic: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.editLmsSubtopic
);

const editLmsTopic: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.editLmsTopic
);

const lmsGetCertificateData: Selector<IState, LMSCertificateModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.lmsGetCertificate
);
const lmsPostCertificateData: Selector<IState, LMSCertificateModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.lmsPostCertificate
);

const lmsGetMcqTttData: Selector<IState, LMSTicTacQuizModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getTicTacQuiz
);

const deleteMcqTtt: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteMcqTtt
);

const deleteMtfDnd: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.deleteMtfDnd
);

const updateMcqTtt: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.updateMcqTtt
);

const updateMtfDnd: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.updateMtfDnd
);

const postMcqTtt: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.postMCQTicTacQuiz
);

const lmsUpdateCertificate: Selector<IState, LMSCertificateModel> = createSelector(
  manageLmsModuleSelect,
  (data) => data.lmsUpdateCertificate
);

const getVideoData: Selector<IState, { count: number; duration: number }> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getVideoData
);

const getDashboardModules: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getDashboardModules
);

const published: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.publishLmsModule
);

const dashboardCategories: Selector<IState, any> = createSelector(
  manageLmsModuleSelect,
  (data) => data.getDashboardCategories
);

export const ManageLmsModuleSelectors = {
  dashboardCategories,
  manageLmsModuleLoading,
  manageLmsModuleError,
  postManageLmsCreateModuleDataResponse,
  getLMSAllModulesData,
  lmsModuleListById,
  postLmsCategoryDetail,
  getLmsModuleCategoriesData,
  postTopicSelector,
  editCategorySelector,
  deleteLmsTopicSelector,
  getTopicByIdData,
  getLmsProgressBarStatus,
  deleteLmsModuleSelector,
  getSummaryDetailsSelector,
  deleteCategorySelector,
  getCategoryDetailsAll,
  getVideoUploadLink,
  uploadLmsVideo,
  deleteLmsVideo,
  postMtfDndQuiz,
  getMtfDndQuiz,
  lmsImgUpload,
  lmsImgDelete,
  postSubtopicResponse,
  deleteLmsSubtopic,
  editLmsSubtopic,
  editLmsTopic,
  lmsGetCertificateData,
  lmsPostCertificateData,
  lmsGetMcqTttData,
  deleteMcqTtt,
  deleteMtfDnd,
  updateMcqTtt,
  updateMtfDnd,
  postMcqTtt,
  lmsUpdateCertificate,
  getVideoData,
  getDashboardModules,
  published
};
