import { StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";

export const tableHeaderStyles = StyleSheet.create({
  headingColor: {
    color: colorPallete.black3
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  dropdownContainer: {
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  inActiveButtonStyle: {
    backgroundColor: colorPallete.white1
  },
  inActiveTextStyle: {
    color: colorPallete.grey1
  },
  buttonStyle: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    ...I18nService.select({
      rtl: { marginStart: 24 },
      ltr: { marginStart: 24 }
    }),
    borderRadius: 8,
    backgroundColor: colorPallete.variant1,

    borderWidth: 0
  },
  monthPickerWrapper: {
    flex: 1,
    width: 200,
    marginTop: 8
  }
});
