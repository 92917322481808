export enum LogLevelType {
  ACTION = "action",
  CATEGORY = "category",
  SUBCATEGORY = "subCategory"
}
export enum Action_SubCategory {
  TIME_TEMP = "time & temperature",
  CLEANING = "cleaning",
  STATION_READ = "Station Readiness",
  Manager_Walkthrough = "Manager Walkthrough"
}
export enum Action_CategoryNSubCategory_Ids {
  STATION_READ = "6ecb2ab7-e710-477c-91c5-cbb9ec5246fa",
  MWT = "fce34311-13bb-4ffa-a09f-d6daa7d360ec"
}

export enum IconStatus {
  COMPLETE = "COMPLETE",
  AUTOCLOSED = "AUTOCLOSED",
  INCOMPLETE = "INCOMPLETE"
}

export interface LogsAuthPayload {
  storeCode: number;
  employeeId: string;
  label?: string;
  levelType?: string;
  levelId?: string;
  managerAccess: boolean;
  categoryName?: string;
  categoryId?: string;
  date?: string;
}
export interface ILogShift {
  timeZone: string;
  levelId: string;
  levelType: string;
  storeCode: number;
  subcategoryId?: string;
  date?: string;
  isManagerReview?: boolean;
  shiftStatusFilter?: ShiftStatus;
}
export interface IOperationalHours {
  startDateTime: string;
  endDateTime: string;
  isEnable: boolean;
  status: string;
  isAccessValid: boolean;
  employeeName: string;
  isShiftComplete: boolean;
}
export interface IStationReadOperationalHours {
  startDateTime: string;
  endDateTime: string;
  isEnable: boolean;
  status: string;
  isShiftComplete: boolean;
}
export interface IProdTempCategories {
  levelId: string;
  levelType: string;
  storeCode: number;
  date: string;
  label: string;
  hasResolvedFilter?: boolean;
}

export interface ITimeTempCategories {
  id: string;
  name: string;
  isActive: boolean;
  percentageCompleted?: number;
}
export interface IStationReadCatgeories {
  id: string;
  name: string;
  isAccessValid: boolean;
  percentageCompleted: number;
  employeeId: string;
  employeeName: string;
  endDate?: string;
  isEnable?: boolean;
  isShiftComplete?: boolean;
  label?: string;
  startDate?: string;
  status?: string;
}

export interface IUpdateActionsRequest {
  storeCode: number;
  itemId: string;
  label: string;
  hasResolved: boolean;
  contactMaintenanceDate?: string;
  categoryId?: string;
  method?: string;
  levelId: string;
  levelType: string;
  timeZone: string;
  startDate: string;
  endDate: string;
}

export interface IMwtItemApiPayload {
  categoryId: string;
  storeCode: number;
  date: string;
  label: string;
}

export enum ShiftStatus {
  completed = "COMPLETE",
  autoClosed = "AUTOCLOSED",
  inComplete = "INCOMPLETE",
  resolved = "RESOLVED",
  notResolved = "NOT RESOLVED"
}
