import {
  AllModulesDataModel,
  AllModulesrResponseModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";

export const DetailedLmsFormatter = async (response: any, payload: any) => {
  let data: AllModulesDataModel[] = [];
  response &&
    response?.content?.map((item: any, index: number) => {
      let object: AllModulesDataModel = {
        label: "",
        value: "",
        key: "",
        category: "",
        isActive: false,
        moduleDuration: "",
        thumbnail: ""
      };
      object.label = item.name;
      object.value = item.name;
      object.key = item.id;
      object.category = item.category;
      object.isActive = item.isActive;
      object.moduleDuration = item.moduleDuration;
      object.thumbnail = item.thumbnail;
      data.push(object);
    });
  let dummy: AllModulesrResponseModel = {
    content: data,
    totalElements: response?.totalElements,
    currentPage: payload?.pageNumber
  };
  return dummy;
};
