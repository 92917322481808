import { FAQ } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";

const createFaq = (data: { question: string; answer: string; image: string }) => {
  return {
    type: FAQ.CREATE_FAQ.LOADING,
    payload: data
  };
};
const createFaqSuccess = (data: any) => {
  return {
    type: FAQ.CREATE_FAQ.SUCCESS,
    payload: data
  };
};
const createFaqError = (error: Error) => {
  return {
    type: FAQ.CREATE_FAQ.ERROR,
    payload: error
  };
};
const deleteFaq = (data: { id: string }) => {
  return {
    type: FAQ.DELETE_FAQ.LOADING,
    payload: data
  };
};
const deleteFaqSuccess = () => {
  return {
    type: FAQ.DELETE_FAQ.SUCCESS
  };
};
const deleteFaqError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: FAQ.DELETE_FAQ.ERROR,
    payload: error
  };
};
const updateFaq = (data: { id: string; question: string; answer: string; image: string }) => {
  return {
    type: FAQ.UPDATE_FAQ.LOADING,
    payload: data
  };
};
const updateFaqSuccess = (data: any) => {
  return {
    type: FAQ.UPDATE_FAQ.SUCCESS,
    payload: data
  };
};
const updateFaqError = (error: Error) => {
  return {
    type: FAQ.UPDATE_FAQ.ERROR,
    payload: error
  };
};

const getAllFaqs = (data: any) => {
  return {
    type: FAQ.GET_ALL_FAQ.LOADING,
    payload: data
  };
};
const getAllFaqsSuccess = (data: any) => {
  return {
    type: FAQ.GET_ALL_FAQ.SUCCESS,
    payload: data
  };
};
const getAllFaqsError = (error: Error) => {
  return {
    type: FAQ.GET_ALL_FAQ.ERROR,
    payload: error
  };
};
const resetFAQError = () => {
  return {
    type: FAQ.RESET_FAQ_ERROR
  };
};
export const FaqModuleActions = {
  getAllFaqs,
  getAllFaqsSuccess,
  getAllFaqsError,
  createFaqSuccess,
  createFaqError,
  createFaq,
  deleteFaq,
  deleteFaqSuccess,
  deleteFaqError,
  updateFaq,
  updateFaqSuccess,
  updateFaqError,
  resetFAQError
};
