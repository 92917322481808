import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "manageAskUs";

const MANAGEASK = {
  Questions: {
    GET_ALL_QUESTIONS: ActionHelper.actionTypesCreator(prefix, "GET_ALL_QUESTIONS"),
    DELETE_QUESTION: ActionHelper.actionTypesCreator(prefix, "DELETE_QUESTION"),
    POST_QUESTION: ActionHelper.actionTypesCreator(prefix, "POST_QUESTION"),
    RESET_ERROR: "RESET_ERROR"
  },
  supportTickets: {
    GET_QUESTION_COUNT: ActionHelper.actionTypesCreator(prefix, "GET_QUESTION_COUNT"),
    GET_QUESTION_BY_STATUS: ActionHelper.actionTypesCreator(prefix, "GET_QUESTION_BY_STATUS"),
    POST_ANSWER_REPLY: ActionHelper.actionTypesCreator(prefix, "POST_ANSWER_REPLY"),
    UPDATE_ANSWER_REPLY: ActionHelper.actionTypesCreator(prefix, "UPDATE_ANSWER_REPLY"),
    RESET_SUPPORT_TKT_ERROR: "RESET_SUPPORT_TKT_ERROR"
  }
};
const FAQ = {
  CREATE_FAQ: ActionHelper.actionTypesCreator(prefix, "CREATE_FAQ"),
  GET_ALL_FAQ: ActionHelper.actionTypesCreator(prefix, "GET_ALL_FAQ"),
  DELETE_FAQ: ActionHelper.actionTypesCreator(prefix, "DELETE_FAQ"),
  UPDATE_FAQ: ActionHelper.actionTypesCreator(prefix, "UPDATE_FAQ"),
  RESET_FAQ_ERROR: "RESET_FAQ_ERROR"
};

export { MANAGEASK, FAQ };
