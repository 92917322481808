export const testIds = {
  webRouteScreen: {
    root: "timHortons-route-screen"
  },
  reports: {
    reportsTableHeader: "reportsTableHeader",
    serialNumber: "serialNumber",
    criteria: "criteria",
    titleSerialNumber: "titleSerialNumber",
    title: "title",
    auditorComments: "auditorComments",
    progImg: "progImg",
    categorySerialNumber: "categorySerialNumber",
    categoryName: "categoryName",
    opportunityDetailsHeader: "opportunityDetailsHeader",
    policy: "policy",
    cancelButton: "cancelButton",
    acceptAndContinue: "acceptAndContinue",
    acknow: "acknow",
    selectStore: "selectStore",
    titleId: "titleId",
    earnedValue: "earnedValue",
    availableValue: "availableValue",
    platform: "platform",
    earned: "earned",
    available: "available",
    brandStandard: "brandStandard",
    brandStandardPercentage: "brandStandardPercentage",
    brandStandardPercentageLabel: "brandStandardPercentageLabel",
    total: "total",
    totalEarnedValue: "totalEarnedValue",
    totalAvailableValue: "totalAvailableValue",
    congratsMessage: "congratsMessage",
    internalText: "internalText",
    completedImg: "completedImg",
    extraMsg: "extraMsg",
    completeNow: "completeNow",
    detailsHeader: "detailsHeader",
    detailsTitle: "detailsTitle",
    bodyHeaders: "bodyHeaders",
    foodSafetyTitle: "foodSafetyTitle",
    criticals: "criticals",
    important: "important",
    foodSafetyheader: "foodSafetyheader",
    scoreLabel: "scoreLabel",
    scoreText: "scoreText",
    scoringInfoLabel: "Label",
    scoringInfoCard: "scorecardheader",
    resultCardHeader: "resultHeader",
    resultHeaderLabel: "Label",
    resultHeaderSize: "result",
    resultGrade: "Grade",
    resultValue: "Value",
    reportHeaderMessage: "reportsMessage",
    reportHeaderRemark: "reportsRemark",
    auditSummary: "auditSummary",
    auditSummarySubHeading: "auditSummarySubHeading",
    zeroTolerance: "zeroTolerance",
    zeroToleranceSubTitle: "zeroToleranceSubTitle",
    auditSubmission: "auditSubmission",
    finalScore: "finalScore",
    newAudit: "newAudit",
    viewReport: "viewReport",
    zeroToleranceObservations: "zeroToleranceObservations",
    serialNumId: "serialNumId",
    questionId: "questionId",
    description: "description",
    auditorsComment: "auditorsComment",
    imageId: "imageId",
    noDataAvailable: "noDataAvailable",
    barChartRightTitle: "barChartRightTitle",
    barChartLeftTitle: "barChartLeftTitle",
    legends: "legends",
    donutText: "donutChart",
    comments: "comments",
    ztObservation: "ztObservation",
    ztComment: "ztComment"
  },
  testAudit: {
    categoryTableText: "categoryTableText",
    categoryHeaderText: "categoryHeaderText",
    authModalImage: "authModalImage",
    authModalText: "authModalText",
    authModalButtonText: "authModalButtonText",
    auditHeaderLocationLabel: "auditHeaderLocationLabel",
    auditHeaderLocationText: "auditHeaderLocationText",
    editImage: "editImage",
    serialNumber: "serialNumber",
    headerTitle: "headerTitle",
    points: "points",
    question: "question",
    questionTooltip: "questionTooltip",
    displayHeading: "displayHeading",
    displayPoints: "displayPoints",
    sosMin: "sosMin",
    sosSecond: "sosSecond",
    sosTime: "sosTime",
    auditProgress: "auditProgress",
    auditProgressPercent: "auditProgressPercent",
    tippingPointSS12: "tippingPointSS12",
    firstSS12Tipping: "firstSS12Tipping",
    secondSS12Tipping: "secondSS12Tipping",
    validAudit: "auditValidCheck",
    okay: "okay",
    alert: "alert"
  },
  actionPlan: {
    actionPlanOpportunity: "actionPlanOpportunity",
    actionPlanCriteria: "actionPlanCriteria",
    actionPlanAuditorComment: "actionPlanAuditorComment",
    actionPlanAuditorText: "actionPlanAuditorText",
    actionPlanAttachment: "actionPlanAttachment",
    actionSteps: "actionSteps",
    actionPlanOwner: "actionPlanOwner",
    actionPlanEndDate: "actionPlanEndDate",
    endDateLabel: "endDateLabel",
    endDateLabelText: "endDateLabelText",
    actionPlanStatus: "actionPlanStatus",
    actionPlanHeader: "actionPlanHeader",
    complete: "complete",
    completeLabel: "completeLabel",
    completePercent: "completePercent",
    inProcess: "inProcess",
    inProcessLabel: "inProcessLabel",
    inProcessPercent: "inProcessPercent",
    notStarted: "notStarted",
    notStartedLabel: "notStartedLabel",
    notStartedPercent: "notStartedPercent",
    allActionPlans: "allActionPlans",
    completedItems: "completedItems",
    headerTextTitle: "headerTextTitle",
    headerTextData: "headerTextData",
    fromDateToDate: "fromDate-toDate",
    cancel: "cancel",
    apply: "apply",
    showSelectDates: "showSelectDates",
    selectDate: "selectDate",
    detailActionPlanDownload: "detailActionPlanDownload"
  },
  appeal: {
    appealDecision: "appealDecision",
    appealApproved: "appealApproved",
    appealRemark: "appealRemark",
    noAudits: "noAudits",
    appealHeader: "appealHeader"
  },
  rev: {
    dashboardHeader: "dashboardHeader",
    storeName: "storeName"
  },
  navigation: {
    askAndTell: "askAndTell",
    navImg: "navImg"
  },
  reportSummary: {
    actionPlans: "actionPlans",
    totalReports: "totalReports"
  },
  profile: {
    title: "title",
    value: "value",
    team: "team",
    name: "name",
    createStoreHeader: "Create a Store",
    createStoreTitile: "create Store Title",
    manageStoreHeader: "Manage Stores",
    manageStoreTitle: "Manage Stores title",
    allowedFiles: "allowedFiles",
    currentRole: "currentRole",
    profileCompletionText: "profileCompletionText",
    profileCompletionPercent: "profileCompletionPercent",
    storeCodeText: "storeCodeText",
    storeNameText: "storeNameText",
    employeeRoleText: "employeeRoleText",
    storeStatus: "storeStatus",
    storePicture: "storePicture",
    userManagement: "userManagement",
    createStore: {
      employeeName: "employeeName",
      employeeProfile: {
        name: "profileEmployeeName",
        photo: "profileEmployeePhoto",
        id: "profileEmployeeId",
        role: "profileEmployeeRole",
        email: "profileEmployeeEmail",
        phone: "profileEmployeePhone",
        dob: "profileEmployeeDOB"
      }
    }
  },
  logs: {
    header: "header",
    shift: "shift",
    currentShift: "currentShift",
    category: "category",
    productTempIconTime: "productTempIconTime",
    note: "note",
    tempDisplay: "tempDisplay",
    productItemId: "itemId",
    productItemTitle: "itemTitle",
    productItemSubtitle: "itemSubtitle",
    headerText: "headerText",
    approvalManagerReview: "approvalManagerReview",
    confirmApprovalText: "confirmApprovalText",
    no: "no",
    yes: "yes",
    shiftTime: "shift time",
    logsReport: "logs Report",
    logsLastUpdatedReport: "logsLastUpdatedReport",
    logsSummaryActionName: "logsSummaryActionName",
    logsSummaryCategoryName: "logsSummaryCategoryName",
    logsStoreSpecific: "logsStoreSpecific"
  },
  iam: {
    logo: "logo",
    pageNotFound: "pageNotFound",
    gateWayLogo: "gateWayLogo",
    gateWayTimeOutImage: "gateWayTimeOutImage"
  },
  resources: {
    header: "resourceHeader"
  },
  reportBuilder: {
    dataInReport: "dataInReport"
  },
  lms: {
    resumeLearning: "resumeLearning",
    headerTitle: "headerTitle",
    orientation: "orientation",
    timhortonStory: "timhortonStory",
    subtitleText: "subtitleText",
    careerimage: "careerimage",
    gotoLearning: "gotoLearning",
    gotoCardSubtitle: "gotoCardSubtitle",
    gotoCardtimeText: "gotoCardtimeText",
    redVector: "redVector",
    resultImage: "resultImage",
    resultSuccessImage: "resultSuccessImage",
    resultFailImage: "resultFailImage",
    quizId: "quizId",
    assessmentId: "assessmentId",
    quizQuestionLabel: "quizQuestionLabel",
    quizModuleName: "quizModuleName",
    quizTitle: "quizTitle",
    awesomeText: "awesomeText",
    overAllTraining: "overAllTraining",
    emojiText: "emojiText",
    thankYouFeedback: "thankYouFeedback",
    image: "image"
  },
  productionSheet: {
    categoryName: "CategoryName"
  },
  lp: {
    dashboardHeader: "dashboardHeader",
    storeName: "storeName"
  },
  manageResources: {
    titleEditBox: {
      header: "manageResources-box-header",
      subHeader: "manageResources-box-subHeader",
      button: "manageResources-box-button",
      textField: "manageResources-box-textField"
    }
  },
  manageLms: {
    headerText: "headerText",
    orientation: "orientation",
    topic: "topic",
    selectLevelCheckboxes: "selectLevelCheckboxes",
    quiz: {
      headerTitle: "headerTitle",
      quizQuestion: "quizQuestion",
      options: "options",
      someTitle: "someTitle"
    },
    tooltip: "tooltip",
    starIdVersion: "star-rating",
    feedbackIdVersion: "feedback-txt"
  },
  askUsTellUs: {
    listOfQuestions: "listOfQuestions"
  },
  lmsReports: {
    headerText: "headerText",
    nameText: "nameText"
  },
  auditHeaderLocationText: "auditHeaderLocationText",
  editImage: "editImage",
  serialNumber: "serialNumber",
  headerTitle: "headerTitle",
  points: "points",
  question: "question",
  questionTooltip: "questionTooltip",
  uploadText: "uploadText",
  helpTextLabel: "helpTextLabel",
  labeltext: "labeltext",
  acknowledgementStatusLabel: "acknowledgementStatusLabel",
  checkBoxId: "checkBoxId",
  menuItemLabel: "menuItemLabel",
  radioGroup: "radioGroup",
  createPlanButton: "createPlanButton",
  showingTableEntries: "textShowingEntries",
  jumbotronHeading: "jumbotronHeading",
  jumbotronSubHeading: "jumbotronSubHeading",
  chipButton: "chipButton",
  slider: "slider",
  sliderWithCallBack: "sliderWithCallBack",
  collapseExpand: "collapseExpand",
  error: "error"
};

export const placeHolders = {
  testAuditAuthentication: "Employee ID",
  standardAuditAuthentication: "Store Name or Number",
  accordianOther: "Specify Other...",
  accordianComment: "Add Comments",
  reportsAcknowledgementDropdown: "Select your store in charge",
  reportsAcknowledgement: "Enter",
  date: "Date",
  auditor: "Auditor",
  status: "Status",
  manageStoreSearch: "Search by restaurant name or restaurant code",
  manageUserSearch: "Search by employee name or employee id",
  searchByKeyword: "Search by keyword"
};

export const validations = {
  required: "Required",
  acknowledgementValidation: "Enter more than 5 character",
  testAuditAuthenticationModal: "Must be 5 characters or less",
  notAuthorised: "Not authorized",
  notAValidUser: "You are not a valid user. Authorize again.",
  minTextLimitValidations: "Enter more than 10 characters",
  maxTextLimitValidations: "Maximum character limit of 250 is reached"
};
