import { forEach } from "lodash";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
// import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import {
  ManageLmsPostCreateModuleDataModel,
  ManageLmsPostCreateModuleResponseDataModel,
  LMSGetAllModulesDataModel,
  LMSCategoryModel,
  LMSPostTopicModel,
  LMSUploadVideoModel,
  LmsEditCategoryModel,
  LMSPostProgressBarStatusModel,
  LMSSummaryDataModel,
  PostLMSMCQTicTacQuiz,
  LMSGetVideoUploadLink,
  LMSPostSubtopicModel,
  LMSEditSubtopicModel,
  LMSGetModuleByIdModel,
  LMSMtfDndQuizModel,
  LMSImgDataModel,
  LMSDeleteVideoModel,
  LMSCertificateModel,
  LMSQuizMetaDataModel,
  LMSOptionDefinitionModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import { manageLmsEndPoints } from "@timhortons/common/src/constants/endPoints";
import {
  getSummaryFormmattedData,
  postProgressBarUpdateData
} from "@timhortons/common/src/repositories/manageLms/manageLmsFormatter";
import {
  getMcqTttDataFormatter,
  getMtfDndDataFormatter,
  lmsModuleByIdFormatter,
  postMcqTicTacFormatter,
  postMtfDndFormatter
} from "@timhortons/common/src/repositories/manageLms/formatters/lmsModuleFormatter";
import { IMcqData } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMCQ";
import { IMtf } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMatchTheFollowing";
import { secondsToHourMin } from "@timhortons/common/src/utils/dateFormat";

class ManageLMSModuleRepository {
  postCreateModuleData = async (data: ManageLmsPostCreateModuleDataModel) => {
    let obj = {};

    let countryCodeString = data.countryCode ? data.countryCode.join() : "";
    let channelCodeString = data.channelCode ? data.channelCode.join() : "";
    let roleIdString = data.roleId ? data.roleId.join() : "";
    let storeCodeString = data.storeCode ? data.storeCode.join() : "";

    obj = {
      name: data.name,
      countryCodes: countryCodeString,
      channelCodes: channelCodeString,
      roleIds: roleIdString,
      storeCodes: storeCodeString,
      type: data.type
    };
    let response = await ApiClient.post(
      manageLmsEndPoints.postManageLmsModule(),
      data.formData,
      obj,
      LMSKeys.LMS_MANAGE
    );

    return response;
  };

  getLMSAllModuleData = async () => {
    let response = await ApiClient.get(
      manageLmsEndPoints.getLMSAllModule(),
      {},
      LMSKeys.LMS_MANAGE
    );
    // let data = deserialize(LMSGetAllModulesDataModel, response);
    return response;
  };

  getLMSModuleById = async (data: { moduleId: string }): Promise<LMSGetModuleByIdModel> => {
    const { moduleId } = data;
    let response = await ApiClient.get(
      manageLmsEndPoints.getLMSModuleById(moduleId),
      {},
      LMSKeys.LMS_MANAGE
    );
    return lmsModuleByIdFormatter(response);
  };
  postLMSCategory = async (data: { moduleName: string; moduleId: string }) => {
    const { moduleName, moduleId } = data;

    let obj = {
      name: moduleName,
      moduleId: moduleId
    };

    let response: LMSCategoryModel = await ApiClient.post(
      manageLmsEndPoints.postLmsCategory(),
      obj,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getAllCategories = async (data: any) => {
    let response = await ApiClient.get(
      manageLmsEndPoints.getAllCategories(data),
      {},
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteCategory = async (data: { categoryId: string }) => {
    const { categoryId } = data;
    let response = await ApiClient.delete(
      manageLmsEndPoints.deleteCategory(categoryId),
      null,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getCategoryById = async (data: { categoryId: string }) => {
    const { categoryId } = data;
    let response = await ApiClient.get(
      manageLmsEndPoints.getCategoryById(categoryId),
      {},
      LMSKeys.LMS_MANAGE
    );
    return response;
  };

  postTopic = async (data: LMSPostTopicModel) => {
    let response = await ApiClient.post(
      manageLmsEndPoints.postTopic(),
      data,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getLMSModuleCategories = async (data: { moduleId: string }) => {
    const { moduleId } = data;
    let response = await ApiClient.get(
      manageLmsEndPoints.getModuleCategories(moduleId),
      {},
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  uploadLmsVideo = async (data: LMSUploadVideoModel) => {
    let response = await ApiClient.post(manageLmsEndPoints.uploadLmsVideo(data), null, null, {
      ...LMSKeys.LMS_MANAGE
    });
    return response;
  };
  editLmsCategory = async (data: LmsEditCategoryModel) => {
    let response = await ApiClient.put(
      manageLmsEndPoints.editLmsCategory(),
      data,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteLmsTopic = async (data: { id: string }) => {
    const { id } = data;
    let response = await ApiClient.delete(
      manageLmsEndPoints.deleteLmsTopic(id),
      null,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteLmsModule = async (data: { moduleId: string }) => {
    const { moduleId } = data;
    let response = await ApiClient.delete(
      manageLmsEndPoints.deleteLmsModule(moduleId),
      null,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getLmsTopicById = async (data: { categoryId: string }) => {
    const { categoryId } = data;
    let response = await ApiClient.get(
      manageLmsEndPoints.getLmsTopicById(categoryId),
      {},
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getLmsProgressBarStatus = async (data: { moduleId: string }) => {
    const { moduleId } = data;
    let response = await ApiClient.get(
      manageLmsEndPoints.lmsProgressBar(moduleId),
      {},
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  postLmsProgressBarStatus = async (data: {
    moduleId: string;
    updateStepName: string;
    isSpecialAssessment: boolean;
  }) => {
    const { moduleId, updateStepName, isSpecialAssessment } = data;
    let response = await ApiClient.post(
      manageLmsEndPoints.lmsProgressBar(moduleId),
      postProgressBarUpdateData(updateStepName, isSpecialAssessment),
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };

  getSummaryDetails = async (data: { moduleId: string }) => {
    const { moduleId } = data;
    let response: LMSSummaryDataModel = await ApiClient.get(
      manageLmsEndPoints.getSummaryDetails(moduleId),
      {},
      LMSKeys.LMS_MANAGE
    );
    return getSummaryFormmattedData(response);
  };
  postMCQTicTacQuiz = async (data: {
    metaData: LMSQuizMetaDataModel;
    quizData: IMcqData[];
  }): Promise<PostLMSMCQTicTacQuiz> => {
    const { metaData, quizData } = data;

    let response: PostLMSMCQTicTacQuiz = await ApiClient.post(
      manageLmsEndPoints.postMCQTicTacQuiz(),
      postMcqTicTacFormatter(metaData, quizData),
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  updateMCQTicTacQuiz = async (data: {
    metaData: LMSQuizMetaDataModel;
    quizData: IMcqData[];
  }): Promise<PostLMSMCQTicTacQuiz> => {
    const { metaData, quizData } = data;
    let response: PostLMSMCQTicTacQuiz = await ApiClient.put(
      manageLmsEndPoints.postMCQTicTacQuiz(),
      postMcqTicTacFormatter(metaData, quizData),
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getCategoryDetailsAll = async (data: { categoryId: string }) => {
    const { categoryId } = data;
    let response: any = await ApiClient.get(
      manageLmsEndPoints.getCategoryDetailsAll(categoryId),
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  editLmsTopic = async (data: any) => {
    const { categoryId, id, content, name } = data;
    let response: any = await ApiClient.put(manageLmsEndPoints.editLmsTopic(), {
      id: id,
      categoryId: categoryId,
      contentType: "TEXT",
      name: name,
      content: content
    });
    return response;
  };
  getVideoUploadLink = async (data: LMSGetVideoUploadLink) => {
    let response = await ApiClient.post(manageLmsEndPoints.uploadLmsVideoLink(data), null, null, {
      ...LMSKeys.LMS_MANAGE,
      language: data.language
    });
    return response;
  };
  postLmsSubtopic = async (data: LMSPostSubtopicModel) => {
    let response = await ApiClient.post(manageLmsEndPoints.postLmsSubtopic(), data);
    return response;
  };
  editLmsSubtopic = async (data: LMSEditSubtopicModel) => {
    let response = await ApiClient.put(manageLmsEndPoints.editLmsSubtopic(), data);
    return response;
  };
  deleteLmsSubtopic = async (data: { id: string }) => {
    const { id } = data;
    let response = await ApiClient.delete(manageLmsEndPoints.deleteLmsSubtoopic(id));
    return response;
  };
  deleteLmsVideo = async (data: LMSDeleteVideoModel) => {
    // const { id } = data;
    let response = await ApiClient.delete(manageLmsEndPoints.deleteLmsVideo(data));
    return response;
  };
  updateModuleData = async (data: {
    moduleId: string;
    obj: ManageLmsPostCreateModuleDataModel;
  }): Promise<ManageLmsPostCreateModuleResponseDataModel> => {
    const { moduleId, obj } = data;

    let countryCodeString = obj.countryCode ? obj.countryCode.join() : "";
    let channelCodeString = obj.channelCode ? obj.channelCode.join() : "";
    let roleIdString = obj.roleId ? obj.roleId.join() : "";
    let storeCodeString = obj.storeCode ? obj.storeCode.join() : "";

    let modifiedObj = {
      id: moduleId,
      name: obj.name,
      countryCodes: countryCodeString,
      channelCodes: channelCodeString,
      roleIds: roleIdString,
      storeCodes: storeCodeString,
      type: obj.type
    };
    let response = await ApiClient.put(
      manageLmsEndPoints.postManageLmsModule(),
      obj.formData,
      modifiedObj,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };

  getTicTacQuiz = async (data: { id: string }) => {
    const { id } = data;
    let response = await ApiClient.get(manageLmsEndPoints.getTicTacQuiz(id));

    return getMcqTttDataFormatter(response);
  };

  postMtfDndQuiz = async (data: {
    metaData: LMSQuizMetaDataModel;
    quizData: IMtf[];
  }): Promise<LMSMtfDndQuizModel> => {
    const { metaData, quizData } = data;
    let response: LMSMtfDndQuizModel = await ApiClient.post(
      manageLmsEndPoints.lmsMtfDnd(),
      postMtfDndFormatter(metaData, quizData),
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  updateMtfDndQuiz = async (data: {
    metaData: LMSQuizMetaDataModel;
    quizData: IMtf[];
  }): Promise<LMSMtfDndQuizModel> => {
    const { metaData, quizData } = data;
    let response: LMSMtfDndQuizModel = await ApiClient.put(
      manageLmsEndPoints.lmsMtfDnd(),
      postMtfDndFormatter(metaData, quizData),
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getMtfDndQuiz = async (data: { quizId: string }): Promise<LMSOptionDefinitionModel[]> => {
    const { quizId } = data;
    let response: LMSMtfDndQuizModel = await ApiClient.get(
      manageLmsEndPoints.lmsMtfDndId(quizId),
      null,
      LMSKeys.LMS_MANAGE
    );
    return getMtfDndDataFormatter(response);
  };
  lmsImgUpload = async (data: any): Promise<LMSImgDataModel> => {
    const {} = data;
    var formData = new FormData();
    formData.append("file", data.blob);
    let headers = {
      ...LMSKeys.LMS_MANAGE,
      "Content-Type": "multipart/form-data"
    };
    let response: LMSImgDataModel = await ApiClient.post(
      manageLmsEndPoints.lmsImg(),
      formData,
      {},
      headers
    );
    return response;
  };
  lmsImgDelete = async (data: { imageKey: string }): Promise<any> => {
    const { imageKey } = data;
    let response: any = await ApiClient.delete(
      manageLmsEndPoints.lmsImg(),
      imageKey,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  getLmsCertificate = async (data: { moduleId: string }): Promise<LMSCertificateModel> => {
    const { moduleId } = data;
    let response: any = await ApiClient.get(
      manageLmsEndPoints.lmsGetCertificate(moduleId),
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  postLmsCertificate = async (data: LMSCertificateModel): Promise<LMSCertificateModel> => {
    const {} = data;
    let response: any = await ApiClient.post(
      manageLmsEndPoints.lmsPostCertificate(),
      null,
      data,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteMcqTtt = async (data: { id: string }) => {
    let { id } = data;
    let response = await ApiClient.delete(manageLmsEndPoints.deleteMcqTtt(id));
    return response;
  };
  deleteMtfDnd = async (data: { id: string }) => {
    let { id } = data;
    let response = await ApiClient.delete(manageLmsEndPoints.deleteMtfDnd(id));
    return response;
  };
  updateMcqTtt = async (data: any) => {
    let response = await ApiClient.put(manageLmsEndPoints.updateMcqTtt(), data);
    return response;
  };
  updateMtdDnd = async (data: any) => {
    let response = await ApiClient.put(manageLmsEndPoints.updateMtfDnd(), data);
    return response;
  };

  updateLmsCertificate = async (data: LMSCertificateModel): Promise<LMSCertificateModel> => {
    const {} = data;
    let response: any = await ApiClient.put(
      manageLmsEndPoints.lmsPostCertificate(),
      null,
      data,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };

  publishLmsModule = async (data: { moduleId: string; state: string }) => {
    const { moduleId, state } = data;
    let tempObj = {};
    if (state !== null) {
      tempObj = {
        moduleState: state
      };
    }
    let response: any = await ApiClient.post(
      manageLmsEndPoints.publishLmsModule(moduleId),
      {},
      tempObj
    );
    let activatedId = moduleId;
    return activatedId;
  };

  getVideoData = async (data: { duration: number; count: number }) => {
    let response: any = await ApiClient.get(manageLmsEndPoints.getVideoData(data));
    const time = secondsToHourMin(response.duration);

    let obj = {
      ...response,
      ...time
    };

    return obj;
  };
  getDashboardModules = async (data: { moduleType: string; moduleState: string }) => {
    let response: any = await ApiClient.get(manageLmsEndPoints.getDashboardModules(data));

    let newArr: any = [];

    response.forEach((item: any) => {
      let duration = secondsToHourMin(item?.moduleDuration);
      let obj = {
        ...item
      };
      obj = {
        ...obj,
        moduleDuration: duration
      };
      newArr.push(obj);
    });

    return newArr;
  };
  getDashboardCategories = async (data: { moduleId: string }) => {
    let response: any = await ApiClient.get(manageLmsEndPoints.getDashboardCategories(data));

    return response;
  };
}

const manageLmsModuleRepository = new ManageLMSModuleRepository();
export { manageLmsModuleRepository as ManageLMSModuleRepository };
