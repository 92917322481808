import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import TextField from "@timhortons/common/src/components/atoms/textField";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Divider } from "@timhortons/common/src/components/atoms/divider";
import { HeaderLabels } from "../productionSheetTable";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { ProductValues } from "@timhortons/common/src/models/productionSheet/psModels";

interface IProps {
  translate: any;
  setModalState: (data?: any, modalList?: any) => void;
  productName: string;
  selectedLabel: string;
  selectedProductUnit?: string;
  selectedHeaderObj?: ProductValues;
  selectedProductValue?: any;
}

export default function CellModal(props: IProps): ReactElement {
  const {
    translate,
    setModalState,
    productName,
    selectedLabel,
    selectedProductUnit,
    selectedHeaderObj,
    selectedProductValue
  } = props;

  const textFields = [
    {
      label: "Quantity Baked",
      value:
        selectedHeaderObj?.quantityBaked !== null ? String(selectedHeaderObj?.quantityBaked) : "",
      errmsg: "",
      isValid: false
    },
    {
      label: "Expired",
      value: selectedHeaderObj?.expired !== null ? String(selectedHeaderObj?.expired) : "",
      errmsg: "",
      isValid: false
    },
    {
      label: "Damaged",
      value: selectedHeaderObj?.damaged !== null ? String(selectedHeaderObj?.damaged) : "",
      errmsg: "",
      isValid: false
    },
    {
      label: "EOD Waste",
      value: selectedHeaderObj?.value !== null ? String(selectedHeaderObj?.value) : "",
      errmsg: "",
      isValid: false
    },
    {
      label: "Quantity Prepared",
      value:
        selectedProductValue?.production !== null ? String(selectedProductValue?.production) : "",
      errmsg: "",
      isValid: false
    },
    {
      label: "Quantity Wasted",
      value: selectedProductValue?.waste !== null ? String(selectedProductValue?.waste) : "",
      errmsg: "",
      isValid: false
    },
    {
      label: "Remaining Quantity",
      value: selectedHeaderObj?.value !== null ? String(selectedHeaderObj?.value) : "",
      errmsg: "",
      isValid: false
    }
  ];

  const [modalList, setModalList] = useState(textFields);
  const [modalValues, setModalValues] = useState(textFields);

  const handleTextChange = (indexx: any, label: string, val: any) => {
    let data = [...modalValues];
    let index = data.findIndex((item) => item.label === label);
    if (index > -1) {
      data[index].value = val;
      setModalValues(data);
    }
    validateField(indexx, val);
  };

  const validateField = (index: number, val: any) => {
    let avgError;
    let data = [...modalList];
    if (val) {
      if (Number(val) !== 0 && !Number(val)) {
        avgError = `Enter only numbers`;
        data[index].isValid = false;
      } else if (Number(val) < 0) {
        avgError = `Only positive numbers allowed`;
        data[index].isValid = false;
      } else if (val.length > 3) {
        avgError = `Max digits allowed is 3`;
        data[index].isValid = false;
      } else if (val.includes(".")) {
        avgError = `Only whole numbers allowed`;
        data[index].isValid = false;
      } else {
        data[index].isValid = true;
      }
    }
    data[index].value = val;
    data[index].errmsg = avgError;
    setModalList(data);
  };

  useEffect(() => {
    if (selectedLabel === HeaderLabels.count) {
      setModalList(textFields.slice(-1));
    } else if (selectedLabel === HeaderLabels.endOfDayThrows) {
      setModalList(textFields.slice(3, 4));
    } else if (selectedLabel === HeaderLabels.quantityPrepared) {
      setModalList(textFields.slice(-3, -2));
    } else if (selectedLabel === HeaderLabels.quantityWasted) {
      setModalList(textFields.slice(-2, -1));
    } else {
      setModalList(textFields.slice(0, 3));
    }
  }, [selectedLabel]);

  function returnHeight() {
    if (
      selectedLabel === HeaderLabels.endOfDayThrows ||
      selectedLabel === HeaderLabels.quantityPrepared ||
      selectedLabel === HeaderLabels.quantityWasted ||
      selectedLabel === HeaderLabels.count
    ) {
      return 260;
    }
    return 470;
  }
  const handleDisabled = () => {
    const isValid =
      modalList.every(function (arrVal) {
        return (true && arrVal.errmsg === "") || arrVal.errmsg === undefined;
      }) &&
      modalList.some(function (arrVal) {
        return true && arrVal.value.length > 0;
      });
    return !isValid;
  };
  return (
    <View style={[styles.container, { maxHeight: returnHeight() }]}>
      <View style={styles.textContainer}>
        <Text
          fontWeight={FontWeight.Regular}
          testId={testIds.testAudit.authModalText}
          textSize={TextSize.Large}
          textStyle={styles.textStyle}
        >
          {productName}
        </Text>
        <Icon
          name={IconNames.close}
          onPress={() => setModalState(undefined)}
          customStyle={styles.iconStyles}
          size={16}
        />
      </View>
      <Divider dividerStyle={styles.dividerStyle} />

      {modalList.map((item, index) => (
        <View style={[styles.textContainer, styles.space]} key={index}>
          <Text
            fontWeight={FontWeight.Regular}
            testId={testIds.testAudit.authModalText}
            textSize={TextSize.ExtraRegular}
            textStyle={styles.textStyle}
          >
            {item.label}
          </Text>
          <View>
            <View style={styles.inputLabelContainer}>
              <TextField
                handleChange={(e: any) => handleTextChange(index, item.label, e)}
                value={item.value.toString()}
                inputStyle={styles.inputStyle}
                placeholder={translate("Enter value")}
                keyboardType={"numeric"}
                name={item.label}
                id={item.label}
                masterContainerStyle={{ width: PlatformUtils.isWeb() ? "inherit" : 120 }}
              />
              <View style={styles.uomWrapper}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId={testIds.testAudit.authModalText}
                  textSize={TextSize.FineRegular}
                  textStyle={styles.uomStyle}
                >
                  {selectedProductUnit.toLowerCase()}
                </Text>
              </View>
            </View>
            <Text
              testId={testIds.testAudit.authModalText}
              textSize={TextSize.ExtraFineSmall}
              fontWeight={FontWeight.Light}
              textStyle={{ color: "red" }}
            >
              {item.errmsg}
            </Text>
          </View>
        </View>
      ))}
      <Button
        type={ButtonType.Primary}
        title={translate("Continue")}
        buttonStyles={styles.buttonStyles}
        fontWeight={FontWeight.SemiBold}
        textSize={TextSize.ExtraRegular}
        onPress={() => setModalState(modalValues, modalList)}
        disabled={handleDisabled()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 24,
    minWidth: 484
  },
  dividerStyle: {
    borderColor: colorPallete.grey11
  },
  textContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: 30,
    flex: 1
  },
  iconStyles: {
    color: colorPallete.grey3,
    marginBottom: 16
  },
  space: {
    paddingTop: 24
  },
  textStyle: {
    color: colorPallete.black3,
    marginBottom: 16
  },
  buttonStyles: {
    borderRadius: 8,
    alignSelf: "center",
    paddingVertical: 12,
    paddingHorizontal: 179,
    marginTop: 42,
    marginHorizontal: 30
  },
  inputLabelContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.grey6,
    alignSelf: "center",
    borderRadius: 4
  },
  inputStyle: {
    maxWidth: 120,
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 8,
    borderWidth: 0
  },
  uomWrapper: { flexDirection: "row", justifyContent: "center", alignItems: "center" },
  uomStyle: {
    paddingHorizontal: 8,
    color: colorPallete.grey3
  }
});
