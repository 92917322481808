import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, ViewStyle, StyleProp, TextStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { DoubleDatePicker } from "@timhortons/web/src/components/atoms/datepicker/doubleViewCalender";
import CalendarComponent from "@timhortons/tablet/src/components/atoms/datepicker";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import {
  MonthPicker,
  QuarterPicker,
  YearPicker
} from "@timhortons/common/src/components/molecules/customsCalenderPickers";
import { DatePicker } from "@timhortons/web/src/components/atoms/datepicker/datepicker";
import RadioGroup from "@timhortons/common/src/components/molecules/radioGroup";
import {
  DateRangeTypes,
  ReportTypeLevel
} from "@timhortons/common/src/components/organisms/builder/template";
import { builderLevel } from "../..";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { CEOEqvRoles, Roles, SuperAdminEqvRoles } from "@timhortons/common/src/constants/roles";
import { QuarterPickerMultiSelect } from "../../multiSelectPickers";

interface IProps {
  translate: any;
  setReportType: Function;
  selectedReportType: ReportTypeLevel;
  selected: any;
  user?: IUserDetails;
  setSelected: Function;
  selectedOption: DateRangeTypes;
  lpSelectedDataOption: any;
  setLpSelectedDataOption: Function;
  consolidatedDate: any;
  setConsolidatedDates: any;
  setSelectedOption: Function;
  handleReset: () => void;
  moduleType?: string;
}
export interface IButtonStyle {
  styleButton?: StyleProp<ViewStyle>;
  textStyles?: StyleProp<TextStyle>;
  iconColorStyles?: StyleProp<TextStyle>;
  iconName?: IconNames;
  title?: string;
  dateTextStyle?: StyleProp<TextStyle>;
}
export enum QuaterText {
  "Quarter 1" = "Q1",
  "Quarter 2" = "Q2",
  "Quarter 3" = "Q3",
  "Quarter 4" = "Q4"
}
export default function ReportType(props: IProps) {
  const {
    translate,
    setReportType,
    selectedReportType,
    selected,
    user,
    setSelected,
    lpSelectedDataOption,
    setLpSelectedDataOption,
    selectedOption,
    consolidatedDate,
    setConsolidatedDates,
    setSelectedOption,
    handleReset,
    moduleType
  } = props;
  const maxConsolidatedEndDate = useMemo(() => {
    if (consolidatedDate.startDate !== null) {
      var year = new Date(consolidatedDate.startDate).getFullYear();
      var month = new Date(consolidatedDate.startDate).getMonth();
      var day = new Date(consolidatedDate.startDate).getDate();
      return new Date(year + 1, month, day);
    }
  }, [consolidatedDate.startDate]);
  const [reset, setReset] = useState({
    monthly: false,
    quarterly: false,
    yearDate: false,
    specific: false
  });
  const [quaterHeading, setQuaterHeading] = useState("");
  const radioOptions = useMemo(() => {
    return [
      {
        key: "1",
        name: translate("detailed"),
        isSelected: selectedReportType === ReportTypeLevel.Detailed,
        onPress: () => {
          setReportType(ReportTypeLevel.Detailed);
          handleReset();
        }
      },
      {
        key: "2",
        name: translate("comparison"),
        isSelected: selectedReportType === ReportTypeLevel.Comparison,
        onPress: () => {
          setReportType(ReportTypeLevel.Comparison);
          handleReset();
        }
      },
      {
        key: "3",
        name: translate("highToLow"),
        isSelected: selectedReportType === ReportTypeLevel.HighLow,
        onPress: () => {
          setReportType(ReportTypeLevel.HighLow);
          handleReset();
        }
      },
      {
        key: "4",
        name: translate("grading"),
        isSelected: selectedReportType === ReportTypeLevel.Grading,
        onPress: () => {
          setReportType(ReportTypeLevel.Grading);
          handleReset();
        }
      },
      {
        key: "5",
        name: translate("areaOfImprovement"),
        isSelected: selectedReportType === ReportTypeLevel.AreaOfImprovement,
        onPress: () => {
          setReportType(ReportTypeLevel.AreaOfImprovement);
          handleReset();
        }
      }
    ];
  }, [selectedReportType]);
  const revRadioOptions = useMemo(() => {
    return [
      {
        key: "1",
        name: translate("detailed"),
        isSelected: selectedReportType === ReportTypeLevel.Detailed,
        onPress: () => {
          setReportType(ReportTypeLevel.Detailed);
          handleReset();
        }
      },
      {
        key: "2",
        name: translate("comparison"),
        isSelected: selectedReportType === ReportTypeLevel.Comparison,
        onPress: () => {
          setReportType(ReportTypeLevel.Comparison);
          handleReset();
        }
      },
      {
        key: "3",
        name: translate("highToLow"),
        isSelected: selectedReportType === ReportTypeLevel.HighLow,
        onPress: () => {
          setReportType(ReportTypeLevel.HighLow);
          handleReset();
        }
      }
      // {
      //   key: "4",
      //   name: translate("consolidated"),
      //   isSelected: false,
      //   onPress: () => {
      //     setReportType(ReportTypeLevel.ConsData);
      //     handleReset();
      //   }
      // }
    ];
  }, [selectedReportType]);
  useEffect(() => {
    setLpSelectedDataOption(null);
  }, [selectedReportType, selectedOption]);
  let startDate = dateFormat(selected.specific && selected.specific.startDate)?.slice(0, 6);
  let endDate = dateFormat(selected.specific && selected.specific.endDate)?.slice(0, 6);

  const handleDatePickerPress = () => {
    setReset({ ...reset, monthly: true, quarterly: true, yearDate: true });

    setSelected((prevState: any) => ({
      ...prevState,
      monthly: null,
      quarterly: null,
      yearDate: { startDate: null, endDate: null }
    }));

    setSelectedOption(DateRangeTypes.SpecificDate);
  };
  const getQuarterDate = (type: string, value: string, year: number) => {
    if (type === "start") {
      if (value === "Quarter 1") {
        return String(year).concat("-01-01");
      }
      if (value === "Quarter 2") {
        return String(year).concat("-04-01");
      }
      if (value === "Quarter 3") {
        return String(year).concat("-07-01");
      }
      if (value === "Quarter 4") {
        return String(year).concat("-10-01");
      }
    }
    if (type === "end") {
      if (value === "Quarter 1") {
        return String(year).concat("-03-31");
      }
      if (value === "Quarter 2") {
        return String(year).concat("-06-30");
      }
      if (value === "Quarter 3") {
        return String(year).concat("-09-30");
      }
      if (value === "Quarter 4") {
        return String(year).concat("-12-31");
      }
    }
  };
  useEffect(() => {
    if (lpSelectedDataOption?.quarterly?.length > 0) {
      let text = "";
      lpSelectedDataOption?.quarterly.map((item: any, index: number) => {
        if (index === 0) {
          text = QuaterText[item?.label];
        } else {
          text = `${text},${QuaterText[item?.label]}`;
        }
        setQuaterHeading(text);
      });
    } else {
      setQuaterHeading("");
    }
  }, [lpSelectedDataOption.quarterly]);
  const handelCallBack = (data: any, year: any) => {
    let dump = data;
    let final: any = [];
    dump?.map((item: any) => {
      let quter = {
        year: year,
        month: { id: item.id, label: item.label },
        startDate: getQuarterDate("start", item.label, year),
        endDate: getQuarterDate("end", item.label, year),
        label: item.label
      };
      final.push(quter);
    });
    setSelectedOption(DateRangeTypes.Quarterly);
    setLpSelectedDataOption({
      ...selected,
      quarterly: final
    });
  };

  const handleFuture = () => {
    if (consolidatedDate.startDate?.getFullYear() === new Date().getFullYear()) {
      return new Date();
    } else {
      return maxConsolidatedEndDate;
    }
  };
  let allowedArr: Array<Roles> = [
    ...SuperAdminEqvRoles,
    ...CEOEqvRoles,
    Roles.HeadLP,
    Roles.CountryManager,
    Roles.OperationManager,
    Roles.SeniorOpsManager,
    Roles.AreaManager,
    Roles.ClusterHeadofOps
  ];
  return (
    <View
      style={
        user?.roles?.includes(Roles.StoreAccess) && moduleType === builderLevel.isProdSheet
          ? styles.container1
          : styles.container
      }
    >
      <Text
        textSize={TextSize.ExtraLarge}
        textStyle={styles.mainHeading}
        fontWeight={FontWeight.SemiBold}
        testId="1"
      >
        {moduleType === builderLevel.isProdSheet && user?.roles?.includes(Roles.StoreAccess)
          ? "1. Report Time Frame"
          : translate("reportTypeNTime")}
      </Text>
      {!user?.roles?.includes(Roles.StoreAccess) && (
        <>
          {" "}
          <Text
            textStyle={styles.selectText}
            textSize={TextSize.Regular}
            fontWeight={FontWeight.Regular}
            testId="1"
          >
            {translate("selectReport")}
          </Text>
          <View style={styles.verticalGap}>
            <RadioGroup
              radioOptions={
                moduleType === builderLevel.isLp && allowedArr.includes(user.roles[0])
                  ? radioOptions
                  : moduleType === builderLevel.isLp && !allowedArr.includes(user.roles[0])
                  ? radioOptions.slice(0, 4)
                  : moduleType === builderLevel.isRev
                  ? revRadioOptions
                  : moduleType === builderLevel.isProdSheet && radioOptions.slice(0, 2)
              }
              isBorder={true}
            />
          </View>
        </>
      )}
      <Text
        textStyle={styles.selectTimeText}
        textSize={TextSize.Regular}
        fontWeight={FontWeight.Regular}
        testId="1"
      >
        {translate("selectDateRange")}
      </Text>
      {selectedReportType === ReportTypeLevel.ConsData ? (
        <View style={styles.constDataStyles}>
          {PlatformUtils.isWeb() ? (
            <DatePicker
              // disablePastDates
              dateContainerStyle={styles.startDatePickerContainer}
              textStyles={styles.datePickerText}
              iconStyles={[styles.datePickerIcon, { marginTop: 4 }]}
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraRegular}
              placeholderText={translate("startDate")}
              iconSize={12}
              parentCallBack={(val: Date) => {
                setConsolidatedDates({ ...consolidatedDate, startDate: val });
              }}
            />
          ) : (
            <CalendarComponent
              // disablePastDates/
              dateContainerStyle={styles.startDatePickerContainer}
              textStyles={styles.datePickerText}
              iconStyles={styles.datePickerIcon}
              selectRange={false}
              parentCallBack={(val: Date) => {
                setConsolidatedDates({ ...consolidatedDate, startDate: val });
              }}
              placeholderText={translate("startDate")}
              iconSize={12}
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraRegular}
            />
          )}
          {PlatformUtils.isWeb() ? (
            <DatePicker
              dateContainerStyle={styles.endDatePickerContainer}
              textStyles={styles.datePickerText}
              iconStyles={[styles.datePickerIcon, { marginTop: 4 }]}
              maxDate={handleFuture()}
              disableFutureDates={false}
              // disablePastDates
              disableSelectedPrevDate={consolidatedDate.startDate}
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraRegular}
              placeholderText={translate("endDate")}
              iconSize={12}
              parentCallBack={(val: Date) => {
                setConsolidatedDates({ ...consolidatedDate, endDate: val });
              }}
              disableCalenderClick={consolidatedDate.startDate !== null ? false : true}
            />
          ) : (
            <CalendarComponent
              maxDate={handleFuture()}
              disableCalenderClick={consolidatedDate.startDate !== null ? false : true}
              disableFutureDates={false}
              // disablePastDates
              disableSelectedPrevDate={consolidatedDate?.startDate}
              selectRange={false}
              parentCallBack={(val: Date) => {
                setConsolidatedDates({ ...consolidatedDate, endDate: val });
              }}
              dateContainerStyle={styles.endDatePickerContainer}
              textStyles={styles.datePickerText}
              iconStyles={styles.datePickerIcon}
              placeholderText={translate("endDate")}
              iconSize={12}
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraRegular}
            />
          )}
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-start",
            flexDirection: "row",
            marginTop: 12
          }}
        >
          {selectedReportType !== ReportTypeLevel.AreaOfImprovement && (
            <View>
              <MonthPicker
                buttonProps={{
                  iconName:
                    selectedOption === DateRangeTypes.Monthly
                      ? IconNames.zeroTolerance
                      : IconNames.noneDone,
                  iconColorStyles:
                    selectedOption === DateRangeTypes.Monthly
                      ? styles.activeIcon
                      : styles.defaultIcon,
                  title:
                    selected.monthly && selected.monthly.year
                      ? `${selected.monthly.month.label} ${selected.monthly.year}`
                      : translate("monthly"),
                  styleButton:
                    selectedOption === DateRangeTypes.Monthly ? styles.active : styles.default,
                  textStyles:
                    selectedOption === DateRangeTypes.Monthly
                      ? styles.activeText
                      : styles.defaultText
                }}
                setReset={() => setReset}
                reset={selectedOption !== DateRangeTypes.Monthly}
                onPressButton={() => {
                  setReset({ ...reset, quarterly: true, yearDate: true, specific: true });
                  setSelected((prevState: any) => ({
                    ...prevState,
                    quarterly: null,
                    yearDate: { startDate: null, endDate: null },
                    specific: { startDate: null, endDate: null }
                  }));
                  setSelectedOption(DateRangeTypes.Monthly);
                }}
                triggerComponent={true}
                callBk={(data) =>
                  setSelected({
                    ...selected,
                    monthly: {
                      startDate: new Date(data.year, data.month.id, 1),
                      endDate: new Date(data.year, data.month.id + 1, 0),
                      year: data.year,
                      month: { id: data.month.id, label: data.month.label }
                    }
                  })
                }
              />
            </View>
          )}
          {moduleType === builderLevel.isLp && selectedReportType === ReportTypeLevel.Comparison ? (
            <View style={styles.quarterGap}>
              <QuarterPickerMultiSelect
                buttonProps={{
                  iconName:
                    selectedOption === DateRangeTypes.Quarterly
                      ? IconNames.zeroTolerance
                      : IconNames.noneDone,
                  iconColorStyles:
                    selectedOption === DateRangeTypes.Quarterly
                      ? styles.activeIcon
                      : styles.defaultIcon,
                  title:
                    quaterHeading && quaterHeading?.length > 0
                      ? quaterHeading
                      : translate("quarterly"),
                  styleButton:
                    selectedOption === DateRangeTypes.Quarterly ? styles.active : styles.default,
                  textStyles:
                    selectedOption === DateRangeTypes.Quarterly
                      ? styles.activeText
                      : styles.defaultText
                }}
                // setReset={() => }
                reset={lpSelectedDataOption?.quarterly.length < 1}
                onPressButton={(val: any, year: any) => {
                  setReset({ ...reset, monthly: true, yearDate: true, specific: true });
                  handelCallBack(val, year);
                }}
                triggerComponent={true}
                // callBk={(data) => handelCallBack(data?.month, data?.year)}
              />
            </View>
          ) : (
            <View
              style={
                selectedReportType === ReportTypeLevel.AreaOfImprovement ? {} : styles.quarterGap
              }
            >
              <QuarterPicker
                buttonProps={{
                  iconName:
                    selectedOption === DateRangeTypes.Quarterly
                      ? IconNames.zeroTolerance
                      : IconNames.noneDone,
                  iconColorStyles:
                    selectedOption === DateRangeTypes.Quarterly
                      ? styles.activeIcon
                      : styles.defaultIcon,
                  title:
                    selected.quarterly && selected.quarterly.year
                      ? `${selected.quarterly.month.label}, ${selected.quarterly.year}`
                      : translate("quarterly"),
                  styleButton:
                    selectedOption === DateRangeTypes.Quarterly ? styles.active : styles.default,
                  textStyles:
                    selectedOption === DateRangeTypes.Quarterly
                      ? styles.activeText
                      : styles.defaultText
                }}
                setReset={() => setReset}
                reset={selectedOption !== DateRangeTypes.Quarterly}
                onPressButton={() => {
                  setReset({ ...reset, monthly: true, yearDate: true, specific: true });

                  setSelected((prevState: any) => ({
                    ...prevState,
                    monthly: null,
                    yearDate: { startDate: null, endDate: null },
                    specific: { startDate: null, endDate: null }
                  }));
                  setSelectedOption(DateRangeTypes.Quarterly);
                }}
                triggerComponent={true}
                callBk={(data) =>
                  setSelected({
                    ...selected,
                    quarterly: {
                      year: data.year,
                      month: { id: data.month.id, label: data.month.label },
                      startDate: getQuarterDate("start", data.month.label, data.year),
                      endDate: getQuarterDate("end", data.month.label, data.year)
                    }
                  })
                }
              />
            </View>
          )}
          {selectedReportType !== ReportTypeLevel.AreaOfImprovement &&
            (selectedReportType !== ReportTypeLevel.Grading ? (
              <View>
                <Button
                  iconSize={16}
                  iconName={
                    (selectedOption === DateRangeTypes.YearDate && IconNames.zeroTolerance) ||
                    IconNames.noneDone
                  }
                  iconStyles={
                    (selectedOption === DateRangeTypes.YearDate && styles.activeIcon) ||
                    styles.defaultIcon
                  }
                  textStyles={
                    (selectedOption === DateRangeTypes.YearDate && styles.activeText) ||
                    styles.defaultText
                  }
                  title={translate("yearDate")}
                  onPress={() => {
                    setReset({ ...reset, monthly: true, quarterly: true, specific: true });

                    setSelected((prevState: any) => ({
                      ...prevState,
                      monthly: null,
                      quarterly: null,
                      yearDate: {
                        startDate: new Date(new Date().getFullYear(), 0, 1),
                        endDate: new Date()
                      },
                      specific: { startDate: null, endDate: null }
                    }));
                    setSelectedOption(DateRangeTypes.YearDate);
                  }}
                  type={ButtonType.Secondary}
                  buttonStyles={
                    (selectedOption === DateRangeTypes.YearDate && styles.active) || styles.default
                  }
                />
              </View>
            ) : (
              <View>
                <YearPicker
                  buttonProps={{
                    iconName:
                      selectedOption === DateRangeTypes.Yearly
                        ? IconNames.zeroTolerance
                        : IconNames.noneDone,
                    iconColorStyles:
                      selectedOption === DateRangeTypes.Yearly
                        ? styles.activeIcon
                        : styles.defaultIcon,
                    title:
                      selected?.yearDate?.startDate && selected.yearDate.startDate.length <= 10
                        ? `${selected.yearDate.startDate.slice(0, 4)}`
                        : translate("yearly"),
                    styleButton:
                      selectedOption === DateRangeTypes.Yearly ? styles.active : styles.default,
                    textStyles:
                      selectedOption === DateRangeTypes.Yearly
                        ? styles.activeText
                        : styles.defaultText
                  }}
                  setReset={() => setReset}
                  reset={selectedOption !== DateRangeTypes.Yearly}
                  onPressButton={() => {
                    setReset({ ...reset, quarterly: true, yearDate: true, specific: true });
                    setSelected((prevState: any) => ({
                      ...prevState,
                      quarterly: null,
                      yearDate: { startDate: null, endDate: null },
                      specific: { startDate: null, endDate: null },
                      yearly: null
                    }));
                    setSelectedOption(DateRangeTypes.Yearly);
                  }}
                  triggerComponent={true}
                  callBk={(data) =>
                    setSelected({
                      ...selected,
                      yearDate: {
                        startDate: String(data.year.label).concat("-01-01"),
                        endDate: String(data.year.label).concat("-12-31")
                      }
                    })
                  }
                  yearRange={20}
                />
              </View>
            ))}
          {selectedReportType !== ReportTypeLevel.Grading &&
            selectedReportType !== ReportTypeLevel.AreaOfImprovement && (
              <View style={styles.calenderGap}>
                {PlatformUtils.isWeb() ? (
                  <DoubleDatePicker
                    buttonProps={{
                      iconName:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? IconNames.zeroTolerance
                          : IconNames.noneDone,
                      iconColorStyles:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? styles.activeIcon
                          : styles.defaultIcon,
                      title:
                        selected.specific && selected.specific.endDate
                          ? `${startDate} - ${endDate}`
                          : translate("specificDate"),

                      styleButton:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? styles.active
                          : styles.default,
                      dateTextStyle:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? styles.activeText
                          : styles.defaultText
                    }}
                    reset={selectedOption !== DateRangeTypes.SpecificDate}
                    parentDoubleViewCall={(data: Date[]) =>
                      setSelected({
                        ...selected,
                        specific: { startDate: data && data[0], endDate: data && data[1] }
                      })
                    }
                    translate={translate}
                    onPressButton={() => handleDatePickerPress()}
                    showDoubleView
                    showWeekNumbers
                    selectRange
                    triggerComponent={true}
                  />
                ) : (
                  <CalendarComponent
                    buttonProps={{
                      iconName:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? IconNames.zeroTolerance
                          : IconNames.noneDone,
                      iconColorStyles:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? styles.activeIcon
                          : styles.defaultIcon,
                      dateTextStyle:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? styles.activeText
                          : styles.defaultText,
                      title:
                        selected.specific && selected.specific.startDate
                          ? `${startDate} - ${endDate}`
                          : translate("specificDate"),
                      styleButton:
                        selectedOption === DateRangeTypes.SpecificDate
                          ? styles.active
                          : [styles.active, styles.default]
                    }}
                    selectRange
                    reset={selectedOption !== DateRangeTypes.SpecificDate}
                    onPressButton={() => handleDatePickerPress()}
                    triggerComponent={true}
                    parentCallBack={(data: any) =>
                      setSelected({
                        ...selected,
                        specific: { startDate: data[0], endDate: data[1] }
                      })
                    }
                  />
                )}
              </View>
            )}
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 60,
    justifyContent: "flex-start"
  },
  container1: {
    flex: 1,
    marginBottom: 10,
    justifyContent: "flex-start"
  },
  selectText: {
    paddingTop: 24,
    color: colorPallete.black4
  },
  selectTimeText: {
    paddingTop: 24,
    color: colorPallete.black4
  },
  mainHeading: {
    color: colorPallete.black3
  },
  verticalGap: { marginVertical: 12 },
  quarterGap: {
    marginHorizontal: 20
  },
  timeRangeContainer: {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "row",
    marginVertical: 12
  },
  active: {
    backgroundColor: colorPallete.variant14,
    ...I18nService.select({
      rtl: { paddingEnd: 18 },
      ltr: { paddingStart: 18 }
    }),
    ...I18nService.select({
      rtl: { paddingStart: 24 },
      ltr: { paddingEnd: 24 }
    }),
    paddingVertical: 12,
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: colorPallete.red1
  },
  default: {
    backgroundColor: colorPallete.white3,
    ...I18nService.select({
      rtl: { paddingEnd: 18 },
      ltr: { paddingStart: 18 }
    }),
    ...I18nService.select({
      rtl: { paddingStart: 24 },
      ltr: { paddingEnd: 24 }
    }),
    paddingVertical: 12,
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: colorPallete.grey6
  },
  calenderGap: { marginHorizontal: 20 },
  activeText: {
    color: colorPallete.red1
  },
  defaultText: {
    color: colorPallete.grey1
  },
  activeIcon: {
    color: colorPallete.red1,
    paddingEnd: 12
  },
  defaultIcon: {
    color: colorPallete.grey4,
    paddingEnd: 12
  },

  startDatePickerContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colorPallete.white1
  },
  endDatePickerContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colorPallete.white1,
    marginHorizontal: 30
  },
  datePickerText: {
    color: colorPallete.black4
  },
  datePickerIcon: {
    color: colorPallete.grey2
  },
  constDataStyles: { flex: 1, flexDirection: "row", marginTop: 10, marginBottom: -60 }
});
