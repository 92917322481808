import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { TabObject } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import {
  IProdTempCategories,
  IUpdateActionsRequest
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { ProductTemperatureCategoriesModel } from "@timhortons/common/src/models/logs/mainLogs";

const getProductTempActions = (data: {
  tabId: string;
  shift: string;
  storeCode: number;
  startDate: string;
  isCategory?: boolean;
}): IFluxStandardAction<{
  tabId: string;
  shift: string;
  storeCode: number;
  startDate: string;
  isCategory?: boolean;
}> => {
  return {
    type: LOGS.Actions.TimeTemp.GET__PRODUCT_TEMP_ACTIONS.LOADING,
    payload: data
  };
};
const getProductTempActionsSuccess = (data: TabObject[]): IFluxStandardAction<TabObject[]> => {
  return {
    type: LOGS.Actions.TimeTemp.GET__PRODUCT_TEMP_ACTIONS.SUCCESS,
    payload: data
  };
};
const getProductTempActionsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.TimeTemp.GET__PRODUCT_TEMP_ACTIONS.ERROR,
    payload: error
  };
};

const updateProductActions = (
  data: IUpdateActionsRequest
): IFluxStandardAction<IUpdateActionsRequest> => {
  return {
    type: LOGS.Actions.TimeTemp.UPDATE_PRODUCT_ACTIONS.LOADING,
    payload: data
  };
};
const updateProductActionsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.Actions.TimeTemp.UPDATE_PRODUCT_ACTIONS.SUCCESS,
    payload: data
  };
};
const updateProductActionsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.TimeTemp.UPDATE_PRODUCT_ACTIONS.ERROR,
    payload: error
  };
};
const resetTimeTempActionError = () => {
  return {
    type: LOGS.Actions.TimeTemp.RESET_TIME_TEMP_ACTION_ERROR
  };
};
const getProdTempActionsCategory = (
  data: IProdTempCategories
): IFluxStandardAction<IProdTempCategories> => {
  return {
    type: LOGS.Actions.TimeTemp.GET_TIME_TEMP_ACTIONS_CATEGORIES.LOADING,
    payload: data
  };
};
const getProdTempActionsCategorySuccess = (
  data: ProductTemperatureCategoriesModel[]
): IFluxStandardAction<ProductTemperatureCategoriesModel[]> => {
  return {
    type: LOGS.Actions.TimeTemp.GET_TIME_TEMP_ACTIONS_CATEGORIES.SUCCESS,
    payload: data
  };
};
const getProdTempActionsCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.TimeTemp.GET_TIME_TEMP_ACTIONS_CATEGORIES.ERROR,
    payload: error
  };
};
const resetProdTempActionsCategory = () => {
  return {
    type: LOGS.Actions.TimeTemp.RESET_PROD_TEMP_ACTION_CATEGORY_STATE
  };
};
const resetTimeTempActions = () => {
  return {
    type: LOGS.Actions.TimeTemp.RESET_TIME_TEMP_ACTIONS
  };
};

export const TimeTemperatureActionLogs = {
  getProductTempActions,
  getProductTempActionsSuccess,
  getProductTempActionsError,
  updateProductActions,
  updateProductActionsSuccess,
  updateProductActionsError,
  resetTimeTempActionError,
  getProdTempActionsCategory,
  getProdTempActionsCategorySuccess,
  getProdTempActionsCategoryError,
  resetProdTempActionsCategory,
  resetTimeTempActions
};
