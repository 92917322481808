import { ApiClient } from "@timhortons/common/src/network/apiClient";
import {
  cleaningLogsEndPoints,
  managerWalkthroughLogsEndPoints,
  stationReadLogsEndPoints,
  timeTempLogsEndPoints
} from "@timhortons/common/src/constants/endPoints";
import {
  LogsAuthModel,
  LogShiftModel,
  ProductTemperatureCategoriesModel,
  ReceivingLogCategoriesSubCategoriesModel,
  WeeklyCommentModel,
  WeeklyPostItemsResponseModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { IReceivingLogsItemsApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/receivingLog/receivingLog";
import { IRecordTemperature } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import {
  ILogShift,
  IMwtItemApiPayload,
  IProdTempCategories,
  IStationReadCatgeories,
  ITimeTempCategories,
  LogsAuthPayload
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { StationReadCategoryModel } from "@timhortons/common/src/models/logs/mainLogs/stationReadiness";
import {
  dailyCleaningItemsFormatter,
  equipmentItemsFormatter,
  formatterPreviousShift,
  managerWalkthroughItemsFormatter,
  DeepCleaningItemsFormatter
} from "./mainLogsFormatter";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { LOGKeys } from "@timhortons/common/src/constants/apiKeys";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";
import { getTimeZone } from "@timhortons/common/src/utils/dateFormat";

class MainLogsRepository {
  checkLogsAuth = async (param: LogsAuthPayload) => {
    let response: LogsAuthModel = await ApiClient.get(
      timeTempLogsEndPoints.logsAuthentication(),
      param,
      LOGKeys.StoreAuthentication
    );
    return response;
  };
  updateStationReadLogsAuth = async (body: {
    employeeId: string;
    label: string;
    storeCode: number;
    categoryId: string;
    date: string;
  }) => {
    await ApiClient.put(
      stationReadLogsEndPoints.stationReadAuthenticate(),
      body,
      null,
      LOGKeys.StoreAccessKeys
    );
  };
  updateLogsAuth = async (body: { timeZone: string; data: LogsAuthPayload }) => {
    await ApiClient.put(
      timeTempLogsEndPoints.logsUpdateAuthentication(),
      body.data,
      { timeZone: body.timeZone },
      LOGKeys.StoreAccessKeys
    );
  };
  // left side catgories for actions thing like time temperature, cleaning
  getTimeTempCategories = async (param: {
    actionName: string;
    storeCode: number;
    date: string;
    label?: string;
  }) => {
    const { storeCode, date, label } = param;
    let timeZone = getTimeZone();
    let resp: ITimeTempCategories[] = await ApiClient.get(
      timeTempLogsEndPoints.getTimeTempCategories(param.actionName),
      { storeCode, date, label, timeZone },
      LOGKeys.StoreAccessKeys
    );
    return resp;
  };
  getStationReadCategories = async (param: {
    actionName: string;
    storeCode: number;
    date: string;
    label?: string;
  }) => {
    const { storeCode, date, label } = param;
    let timeZone = getTimeZone();
    let resp: IStationReadCatgeories[] = await ApiClient.get(
      timeTempLogsEndPoints.getTimeTempCategories(param.actionName),
      { storeCode, date, label, timeZone },
      LOGKeys.StoreAccessKeys
    );
    return resp;
  };
  getReceivingLogsCategories = async (param: { categoryId: string }) => {
    let response = await ApiClient.get(
      timeTempLogsEndPoints.getReceivingLogsCategories(param.categoryId),
      {},
      LOGKeys.StoreAccessKeys
    );
    let finalData: ReceivingLogCategoriesSubCategoriesModel[] = [];
    response.map((item: any) => {
      let deserializeVar: ReceivingLogCategoriesSubCategoriesModel = deserialize(
        ReceivingLogCategoriesSubCategoriesModel,
        item
      );
      finalData.push(deserializeVar);
    });
    return finalData;
  };
  getReceivingLogsSubCategories = async (param: IProdTempCategories) => {
    let response = await ApiClient.get(
      timeTempLogsEndPoints.getProductsCategories(),
      param,
      LOGKeys.StoreAccessKeys
    );
    let finalData: ProductTemperatureCategoriesModel[] = [];
    response.map((item: ProductTemperatureCategoriesModel) => {
      let deserializeVar: ProductTemperatureCategoriesModel = deserialize(
        ProductTemperatureCategoriesModel,
        item
      );
      finalData.push(deserializeVar);
    });
    return finalData;
  };
  getReceivingLogItems = async (param: IReceivingLogsItemsApi) => {
    try {
      let itemId = param.subCategoryId;
      delete param["subCategoryId"];
      let resp = await ApiClient.get(
        timeTempLogsEndPoints.getProductTempItems(itemId),
        param,
        LOGKeys.StoreAccessKeys
      );
      return equipmentItemsFormatter(resp);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  // get products for category - meats, veegrtabel, customera area baking oven
  getProductsCategory = async (payload: IProdTempCategories) => {
    let resp = await ApiClient.get(
      timeTempLogsEndPoints.getProductsCategories(),
      payload,
      LOGKeys.StoreAccessKeys
    );
    let finalData: ProductTemperatureCategoriesModel[] = [];
    resp.map((item: any) => {
      let deserializeVar: ProductTemperatureCategoriesModel = deserialize(
        ProductTemperatureCategoriesModel,
        item
      );
      finalData.push(deserializeVar);
    });
    return finalData;
  };
  getProdTempItems = async (param: IProdTempApi) => {
    try {
      const { label, date, storeCode } = param;
      let productTempItems = await ApiClient.get(
        timeTempLogsEndPoints.getProductTempItems(param.categoryId),
        {
          label,
          date,
          storeCode
        },
        LOGKeys.StoreAccessKeys
      );
      return equipmentItemsFormatter(productTempItems);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  getQuestionsUnderCategory = async (param: IProdTempApi) => {
    try {
      const { label, date, storeCode } = param;
      let productTempItems = await ApiClient.get(
        timeTempLogsEndPoints.getQuestionsUnderCategory(param.categoryId),
        {
          label,
          date,
          storeCode
        },
        LOGKeys.StoreAccessKeys
      );
      return equipmentItemsFormatter(productTempItems);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  getLogShift = async (param: ILogShift) => {
    const { isManagerReview, levelType, levelId, date, storeCode, timeZone, shiftStatusFilter } =
      param;
    let shiftsContent, previousShiftsContent;
    if (isManagerReview) {
      previousShiftsContent = await ApiClient.get(
        timeTempLogsEndPoints.getPreviousShift(),
        {
          levelType,
          levelId,
          date,
          storeCode,
          timeZone,
          shiftStatusFilter
        },
        LOGKeys.StoreAccessKeys
      );
      let abc: LogShiftModel[] = [];
      previousShiftsContent.map((item: any) => {
        let deserializeVar: LogShiftModel = deserialize(LogShiftModel, item);
        abc.push(deserializeVar);
      });
      return formatterPreviousShift(abc);
    } else {
      shiftsContent = await ApiClient.get(
        timeTempLogsEndPoints.getLogShift(),
        param,
        LOGKeys.StoreAccessKeys
      );
    }
    let finalData: LogShiftModel[] = [];
    shiftsContent.map((item: any) => {
      let deserializeVar: LogShiftModel = deserialize(LogShiftModel, item);
      finalData.push(deserializeVar);
    });
    return finalData;
  };
  getEquipmentItems = async (param: IMwtItemApiPayload) => {
    const { label, storeCode, date } = param;
    try {
      let equipmentItemsResponse = await ApiClient.get(
        timeTempLogsEndPoints.getEquipmentTempItems(param.categoryId),
        {
          label,
          storeCode,
          date
        },
        LOGKeys.StoreAccessKeys
      );
      return equipmentItemsFormatter(equipmentItemsResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  recordProductTemperature = async (param: IRecordTemperature) => {
    let data = await ApiClient.post(
      timeTempLogsEndPoints.recordTemperature(),
      param,
      null,
      LOGKeys.StoreAccessKeys
    );
    return data;
  };
  recordReceivingLogTemperature = async (param: IRecordTemperature) => {
    let data = await ApiClient.post(
      timeTempLogsEndPoints.recordTemperature(),
      param,
      null,
      LOGKeys.StoreAccessKeys
    );
    return data;
  };

  getEmployeeDropDown = async (param: { storeCode: number }) => {
    let data = await ApiClient.get(cleaningLogsEndPoints.getEmployeeDropDown(), param);
    return data;
  };
  getDeepCleaningItems = async (param: IProdTempApi) => {
    const { label, storeCode, date } = param;
    let data = await ApiClient.get(
      timeTempLogsEndPoints.getQuestionsUnderCategory(param.categoryId),
      { label, storeCode, date },
      LOGKeys.StoreAccessKeys
    );
    return DeepCleaningItemsFormatter(data);
  };
  postDeepCleaningItems = async (param: WeeklyCommentModel) => {
    let headers = {
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      cleaningLogsEndPoints.postRestRoomItems(),
      param.body,
      null,
      headers
    );
    return data;
  };

  getRestRoomItems = async (param: IProdTempApi) => {
    const { label, storeCode, date } = param;
    let dataa = await ApiClient.get(
      cleaningLogsEndPoints.getRestRoomQuestions(param.categoryId),
      { label, storeCode, date },
      LOGKeys.StoreAccessKeys
    );
    return dailyCleaningItemsFormatter(dataa);
  };
  postRestRoomItems = async (param: WeeklyCommentModel) => {
    let headers = {
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      cleaningLogsEndPoints.postRestRoomItems(),
      param.body,
      null,
      headers
    );
    return data;
  };

  // MWT Logs
  getManagerWalkthroughCategories = async (param: IProdTempCategories) => {
    let data = await ApiClient.get(
      managerWalkthroughLogsEndPoints.getManagerWalkthroughCategories(),
      param,
      LOGKeys.StoreAccessKeys
    );
    let finalData: StationReadCategoryModel[] = [];
    data.map((item: any) => {
      let deserializeVar: StationReadCategoryModel = deserialize(StationReadCategoryModel, item);
      finalData.push(deserializeVar);
    });
    return finalData;
  };
  getManagerWalkthroughItemsByCategory = async (param: IMwtItemApiPayload) => {
    const { label, date, storeCode } = param;

    let data = await ApiClient.get(
      timeTempLogsEndPoints.getQuestionsUnderCategory(param.categoryId),
      {
        storeCode,
        label,
        date
      },
      LOGKeys.StoreAccessKeys
    );

    return managerWalkthroughItemsFormatter(data);
  };
  postManagerWalkthroughItems = async (param: WeeklyCommentModel) => {
    let headers = {
      "Content-Type": "multipart/form-data"
    };
    let data: WeeklyPostItemsResponseModel = await ApiClient.post(
      managerWalkthroughLogsEndPoints.postManagerWalkthrough(),
      param.body,
      null,
      headers
    );
    return data;
  };
  // Station Readiness Logs
  getStationReadSubCategory = async (param: IProdTempCategories) => {
    let data = await ApiClient.get(
      stationReadLogsEndPoints.getSubCategory(),
      param,
      LOGKeys.StoreAccessKeys
    );
    return data;
  };
  getSRSubCategoryValue = async (payload: {
    categoryId: string;
    label: string;
    storeCode: string;
    date: string;
  }) => {
    let params = {
      label: payload.label,
      storeCode: payload.storeCode,
      date: payload.date
    };
    let data = await ApiClient.get(
      stationReadLogsEndPoints.getSubCategoryItems(payload.categoryId),
      { ...params },
      LOGKeys.StoreAccessKeys
    );
    let formattedData = await dailyCleaningItemsFormatter(data);
    return formattedData;
  };
  postStationReadinessItems = async (param: WeeklyCommentModel) => {
    let headers = {
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      stationReadLogsEndPoints.postStationReadinessItems(),
      param.body,
      null,
      headers
    );
    return data;
  };
}

const mainLogsRepository = new MainLogsRepository();
export { mainLogsRepository as MainLogsRepository };
