import { Any, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): any {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  }
  deserialize(date: any): Date {
    return new Date(date);
  }
}

@JsonObject("Profile")
export class Profile {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("firstName", String)
  fName: string = "";
  @JsonProperty("lastName", String)
  lName: string = "";
  @JsonProperty("emailId", String)
  email: string = "";
  @JsonProperty("age", Number)
  age: number = 0;
}

@JsonObject("ProfileDetailsModel")
export class ProfileDetailsModel {
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("profilePhoto", String)
  profilePhoto: string = "";
  @JsonProperty("role", String)
  role?: string = "";
  @JsonProperty("gender", String)
  gender?: string = "";
  @JsonProperty("age", Number)
  age?: number = 0;
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("email", String)
  email: string = "";
  @JsonProperty("phone", String)
  phone: string = "";
  @JsonProperty("address", String)
  address: string = "";
  @JsonProperty("profilePercentage", Number)
  profilePercentage: number = 0;
  @JsonProperty("profileKey", String)
  profileKey: string = null;
}

@JsonObject("TeamDetailsModel")
export class TeamDetailsModel {
  @JsonProperty("employeeId", String, true)
  employeeId?: string = "";
  @JsonProperty("code", Number && null, true)
  storeCode?: number = 0;
  @JsonProperty("id", Number, true)
  id?: number = 0;
  @JsonProperty("isChecked", Boolean, true)
  isChecked?: boolean = false;
  @JsonProperty("profileUrl", String && null)
  profilePhoto: string = "";
  @JsonProperty("name", String, true)
  name?: string = "";
  @JsonProperty("firstName", String, true)
  firstName?: string = "";
  @JsonProperty("lastName", String, true)
  lastName?: string = "";
  @JsonProperty("roleName", String, true)
  role?: string = "";
  @JsonProperty("nationality", String && null, true)
  nationality?: string = "";
  @JsonProperty("email", String, true)
  email?: string = "";
  @JsonProperty("workEmail", String, true)
  workEmail?: string = "";
  @JsonProperty("personalEmail", String, true)
  personalEmail?: string = "";
  @JsonProperty("phone", String, true)
  phone?: string = "";
  @JsonProperty("countryLabel", String, true)
  country?: string = "";
  @JsonProperty("dateOfBirth", String, true)
  dateofBirth?: string = "";
  @JsonProperty("dateOfJoining", String, true)
  dateOfJoining?: string = "";
  @JsonProperty("dateOfOpening", String, true)
  dateOfOpening?: string = "";
  @JsonProperty("profileCompletionPercentage", Number && null)
  completionPercentage: number = 0;
  @JsonProperty("noOfAreaManagers", Number && null, true)
  areaManagers?: number = 0;
  @JsonProperty("noOfStores", Number && null, true)
  stores?: number = 0;
  @JsonProperty("noOfEmployees", Number && null, true)
  employees?: number = 0;
  @JsonProperty("areaManagerName", String && null, true)
  areaManager?: string = "";
  @JsonProperty("storeName", String && null, true)
  storeName?: string = "";
}
@JsonObject("TeamDetailsTableModel")
export class TeamDetailsTableModel {
  @JsonProperty("totalElements", Number)
  totalRecords: number = 0;
  @JsonProperty("content", [TeamDetailsModel])
  teamDetails: TeamDetailsModel[] = null;
}

@JsonObject("DocumentDetailModel")
export class DocumentDetailModel {
  @JsonProperty("documentType", String)
  documentType?: string = "";
  @JsonProperty("receivedDate", String)
  receivedDate?: string = "";
  @JsonProperty("expiryDate", String)
  expiryDate?: string = "";
  @JsonProperty("signedUrl", String)
  signedUrl: string = "";
}
@JsonObject("PersonalInfoModal")
export class PersonalInfoModal {
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("firstName", String)
  firstName: string = "";
  @JsonProperty("lastName", String)
  lastName: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
}
@JsonObject("BiographicInfoModal")
export class BiographicInfoModal {
  @JsonProperty("dateOfJoining", String)
  dateOfJoining: string = "";
  @JsonProperty("gender", String)
  gender: string = "";
  @JsonProperty("nationality", String)
  nationality: string = "";
  @JsonProperty("dateOfBirth", String)
  dateOfBirth: string = "";
  @JsonProperty("maritalStatus", String)
  maritalStatus: string = "";
}
@JsonObject("CommunicationInfoModal")
export class CommunicationInfoModal {
  @JsonProperty("workEmail", String)
  workEmail: string = "";
  @JsonProperty("mobileNumber", String)
  mobileNumber: string = "";
  @JsonProperty("workMobileNumber", String)
  workMobileNumber: string = "";
  @JsonProperty("personalEmail", String)
  personalEmail: string = "";
}

@JsonObject("AddressInfoModal")
export class AddressInfoModal {
  @JsonProperty("mailingAddress", String)
  mailingAddress: string = "";
  @JsonProperty("homeAddress", String)
  homeAddress: string = "";
}
@JsonObject("EmergencyInfoModal")
export class EmergencyInfoModal {
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("mobileNumber", String)
  mobileNumber: string = "";
}
@JsonObject("ProfileInfoModel")
export class ProfileInfoModel {
  @JsonProperty("completionPercentage", Number)
  completionPercentage: number = 0;
  @JsonProperty("isActive", Boolean)
  isActive: boolean = false;
  @JsonProperty("profileKey", String)
  profileKey: string = "";
  @JsonProperty("profileUrl", String)
  profileUrl: string = "";
  @JsonProperty("role", String)
  role: string = "";
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("countries", String)
  countries: string = "";
}

@JsonObject("DetailedUserInfoModel")
export class DetailedUserInfoModel {
  @JsonProperty("personalInfo", PersonalInfoModal)
  personalInfo: PersonalInfoModal = null;
  @JsonProperty("biographicInfo", BiographicInfoModal)
  biographicInfo: BiographicInfoModal = null;
  @JsonProperty("communicationInfo", CommunicationInfoModal)
  communicationInfo: CommunicationInfoModal = null;
  @JsonProperty("addressInfo", AddressInfoModal)
  addressInfo: AddressInfoModal = null;
  @JsonProperty("emergencyInfo", EmergencyInfoModal)
  emergencyInfo: EmergencyInfoModal = null;
  @JsonProperty("profileInfo", ProfileInfoModel)
  profileInfo: ProfileInfoModel = null;
}
@JsonObject("UserProfileDataModel")
export class UserProfileDataModel {
  @JsonProperty("profileImg", String)
  profileImg: string = "";
  @JsonProperty("role", String)
  role: string = "";
  @JsonProperty("firstName", String)
  firstName: string = "";
  @JsonProperty("lastName", String)
  lastName: string = "";
  @JsonProperty("email", String)
  email: string = "";
  @JsonProperty("storeProgress", Number)
  storeProgress: number = 0;
  @JsonProperty("employeeNumber", Number)
  employeeNumber: number = 0;
  @JsonProperty("storeNumber", Number)
  storeNumber: number = 0;
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("employeeStatus", String)
  employeeStatus: string = "";
}

@JsonObject("StoreDetailsDataModel")
export class StoreDetailsDataModel {
  @JsonProperty("openingDate", String)
  openingDate: string = "";
  @JsonProperty("country", String)
  country: string = "";
  @JsonProperty("city", String)
  city: string = "";
  @JsonProperty("channel", String)
  channel: string = "";
  @JsonProperty("phone", String)
  phone: string = "";
  @JsonProperty("email", String)
  email: string = "";
  @JsonProperty("operationalHours", String)
  operationalHours: string = "";
  @JsonProperty("operationalDays", String)
  operationalDays: string = "";
  @JsonProperty("areaManager", String)
  areaManager: string = "";
  @JsonProperty("areaTrainer", String)
  areaTrainer: string = "";
  @JsonProperty("opsManager", String)
  opsManager: string = "";
  @JsonProperty("storeSpecifics", String)
  storeSpecifics: string = "";
  @JsonProperty("storeAddress", String)
  storeAddress: string = "";
}

@JsonObject("CreateStoreDataModel")
export class CreateStoreDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("label", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
}
@JsonObject("GetAllRolesDataModel")
export class GetAllRolesDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("label", String)
  label: string = "";
  @JsonProperty("name", String)
  name: string = "";
}

@JsonObject("CountryDataModel")
export class CountryDataModel {
  @JsonProperty("countryCode", Number)
  countryCode: number = 0;
  @JsonProperty("label", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("nationality", String, true)
  nationality?: string = "";
}
@JsonObject("CountryDataListModel")
export class CountryDataListModel {
  @JsonProperty("content", [CountryDataModel])
  content: CountryDataModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}

@JsonObject("ManagersDataModel")
export class ManagersDataModel {
  @JsonProperty("roleId", String)
  roleId: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
}

@JsonObject("PostCreateStoreDataModel")
export class PostCreateStoreDataModel {
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("code", Number)
  code: number = 0;
  @JsonProperty("channel", String)
  channel: string = "";
  @JsonProperty("city", String)
  city: string = "";
  @JsonProperty("email", String)
  email: string = "";
  @JsonProperty("address", String)
  address: string = "";
  @JsonProperty("contactNumber", String)
  contactNumber: string = "";
  @JsonProperty("dateOfOpening", String)
  dateOfOpening: string = "";
  @JsonProperty("managers", [ManagersDataModel])
  managers: ManagersDataModel = null;
  @JsonProperty("profilePercentage", Number)
  profilePercentage: number = 0;
  @JsonProperty("isActive", Boolean)
  isActive: boolean = false;
  @JsonProperty("storeSpecifies", [String])
  storeSpecifies: string[] = null;
  @JsonProperty("operationalDays", [String])
  operationalDays: string[] = null;
  @JsonProperty("operatingHoursFrom", String)
  operatingHoursFrom: string = "";
  @JsonProperty("operatingHoursTo", String)
  operatingHoursTo: string = "";
  @JsonProperty("profilePhoto", String)
  profilePhoto?: string = "";
}

@JsonObject("UserBioDataModel")
export class UserBioDataModel {
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("dateOfBirth", String)
  dateOfBirth: string = "";
  @JsonProperty("nationality", Number)
  nationality: number = 0;
  @JsonProperty("maritalStatus", String)
  maritalStatus: string = "";
  @JsonProperty("gender", String)
  gender: string = "";
  @JsonProperty("profileUrl", String)
  profileUrl: string = "";
  @JsonProperty("completionPercentage", Number)
  completionPercentage: number = 0;
}

@JsonObject("UserContactInfoRequestModel")
export class UserContactInfoRequestModel {
  @JsonProperty("workMobileNumber", String, true)
  workMobileNumber: string = "";
  @JsonProperty("mobileNumber", String)
  mobileNumber: string = "";
  @JsonProperty("workEmail", String)
  workEmail: string = "";
  @JsonProperty("personalEmail", String)
  personalEmail: string = "";
  @JsonProperty("homeAddress", String)
  homeAddress: string = "";
  @JsonProperty("mailingAddress", String)
  mailingAddress: string = "";
  @JsonProperty("guardianName", String)
  guardianName: string = "";
  @JsonProperty("guardianNumber", String)
  guardianNumber: string = "";
}

@JsonObject("PostCreateUserDataModel")
export class PostCreateUserDataModel {
  @JsonProperty("firstName", String)
  firstName: string = "";
  @JsonProperty("lastName", String)
  lastName: string = "";
  @JsonProperty("roleId", String)
  roleId: string = "";
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("newEmployeeId", String)
  newEmployeeId: string = "";
  @JsonProperty("isActive", Boolean)
  isActive: boolean = false;
  @JsonProperty("dateOfJoining", String)
  dateOfJoining: string = "";
  @JsonProperty("userBio", UserBioDataModel)
  userBio: UserBioDataModel = null;
  @JsonProperty("userContactInformationRequest", UserContactInfoRequestModel)
  userContactInformationRequest: UserContactInfoRequestModel = null;
}

@JsonObject("ChannelsDataModel")
export class ChannelsDataModel {
  @JsonProperty("id", String && null, true)
  id?: string = "";
  @JsonProperty("code", Number)
  code?: number = 0;
  @JsonProperty("label", String)
  label: string = "";
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("isActive", Boolean)
  isActive?: boolean = false;
}

@JsonObject("ChannelsListDataModel")
export class ChannelsListDataModel {
  @JsonProperty("content", [ChannelsDataModel])
  content: ChannelsDataModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}
@JsonObject("AllStoresDataModel")
export class AllStoresDataModel {
  @JsonProperty("channel", String)
  value: string = "";
  @JsonProperty("code", Number)
  code?: number = 0;
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("city", String)
  city: string = "";
  @JsonProperty("profilePercentage", Number && null)
  profilePercentage?: number = 0;
}
@JsonObject("AllStoresListDataModel")
export class AllStoresListDataModel {
  @JsonProperty("content", [AllStoresDataModel])
  content: AllStoresDataModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}
@JsonObject("StoreSpecificDataModel")
export class StoreSpecificDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
}

@JsonObject("StoreDetailsModel")
export class StoreDetailsModel {
  @JsonProperty("id", Number, true)
  id?: number = 0;
  @JsonProperty("profilePhoto", String && null)
  profilePhoto: string = "";
  @JsonProperty("name", String)
  storeName: string = "";
  @JsonProperty("code", String)
  storeCode: string = "";
  @JsonProperty("email", String)
  email: string = "";
  @JsonProperty("contactNumber", String && null)
  phone: string = "";
  @JsonProperty("country", String)
  country: string = "";
  @JsonProperty("profilePercentage", Number && null)
  progressPercentage: number = 0;
  @JsonProperty("dateOfOpening", String)
  dop: string = "";
  @JsonProperty("isActive", Boolean)
  status: boolean = false;
  @JsonProperty("isChecked", Boolean, true)
  isChecked?: boolean = false;
}

@JsonObject("StoreTableDetailsModel")
export class StoreTableDetailsModel {
  @JsonProperty("totalElements", Number)
  totalRecords: number = 0;
  @JsonProperty("content", [StoreDetailsModel])
  storeDetails: StoreDetailsModel[] = null;
}
@JsonObject("UserDetailsModel")
export class UserDetailsModel {
  @JsonProperty("id", Number, true)
  id?: number = 0;
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("profileUrl", String && null)
  profilePhoto: string = "";
  @JsonProperty("roleName", String)
  role: string = "";
  @JsonProperty("firstName", String)
  firstName: string = "";
  @JsonProperty("lastName", String && null)
  lastName: string = "";
  @JsonProperty("personalEmail", String && null)
  email: string = "";
  @JsonProperty("mobileNumber", String && null)
  phone: string = "";
  @JsonProperty("workMobileNumber", String && null)
  workMobileNumber: string = "";
  @JsonProperty("workEmail", String && null)
  workEmail: string = "";
  @JsonProperty("profileCompletionPercentage", Number && null)
  progressPercentage: number = 0;
  @JsonProperty("dateOfJoining", String && null)
  addedOn: string = "";
  @JsonProperty("isActive", Boolean)
  status: boolean = false;
  @JsonProperty("isChecked", Boolean, true)
  isChecked?: boolean = false;
}
@JsonObject("UserTableDetailsModel")
export class UserTableDetailsModel {
  @JsonProperty("totalElements", Number)
  totalRecords: number = 0;
  @JsonProperty("content", [UserDetailsModel])
  userDetails: UserDetailsModel[] = null;
}
@JsonObject("AllManagersDataModel")
export class AllManagersDataModel {
  @JsonProperty("roleId", String)
  id?: string = "";
  @JsonProperty("name", String)
  value?: string = "";
  @JsonProperty("name", String)
  label?: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("roleName", String)
  roleName: string = "";
}

@JsonObject("AreaManagersDataModel")
export class AreaManagersDataModel {
  @JsonProperty("content", [AllManagersDataModel])
  content: AllManagersDataModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}
@JsonObject("AllAreaManagersDataModel")
export class AllAreaManagersDataModel {
  @JsonProperty("roleId", String)
  id?: string = "";
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("employeeId", String && null)
  code: string = "";
  @JsonProperty("roleName", String)
  roleName: string = "";
}
@JsonObject("AllAreaManagersListDataModel")
export class AllAreaManagersListDataModel {
  @JsonProperty("content", [AllAreaManagersDataModel])
  content: AllAreaManagersDataModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
}

@JsonObject("RolesListDataModel")
export class RolesListDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("label", String)
  label: string = "";
  @JsonProperty("name", String)
  name: string = "";
}
@JsonObject("AreaUnderCountryMModel")
export class AreaUnderCountryMModel {
  @JsonProperty("roleId", String, true)
  id?: string = "";
  @JsonProperty("lastName", String)
  value: string = "";
  @JsonProperty("firstName", String)
  label: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("firstName", String)
  roleName: string = "";
}
@JsonObject("AreaUnderCountryManageDataModel")
export class AreaUnderCountryManageDataModel {
  @JsonProperty("content", [AreaUnderCountryMModel])
  content: AreaUnderCountryMModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
}

@JsonObject("ComparisonStoresAdminModelList")
export class ComparisonStoresAdminModelList {
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("code", Number)
  code?: number = 0;
}

@JsonObject("ComparisonStoresAdminModel")
export class ComparisonStoresAdminModel {
  @JsonProperty("content", [ComparisonStoresAdminModelList])
  content: ComparisonStoresAdminModelList[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}
@JsonObject("GetStoreByStoreCode")
export class GetStoreByStoreCode {
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("code", Number)
  storeCode: number = null;
  @JsonProperty("channelCode", Number)
  channelCode: number = null;
  @JsonProperty("countryCode", Number)
  countryCode: number = null;
}

@JsonObject("AssignStores")
export class AssignStores {
  @JsonProperty("roleId", String)
  roleId: string = "";
  @JsonProperty("pageSize", Number)
  pageSize: number = 0;
  @JsonProperty("pageNo", Number)
  pageNo: number = 0;
  @JsonProperty("countryCode", Number)
  countryCode: number = 0;
  @JsonProperty("cityId", String)
  cityId: string = "";
  @JsonProperty("channelId", String)
  channelId: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("search", String, true)
  search?: string = "";
}

@JsonObject("PostAssignStores")
export class PostAssignStores {
  @JsonProperty("code", Number)
  code: number = 0;
  @JsonProperty("roleStatus", String)
  roleStatus: string = "";
  @JsonProperty("isSelected", Boolean)
  isSelected: boolean = false;
}

@JsonObject("EditStoreDetails")
export class EditStoreDetails {
  @JsonProperty("address", String)
  address: string = "";
  @JsonProperty("channel", Any)
  channel: any = null;
  @JsonProperty("city", Any)
  city: any = null;
  @JsonProperty("code", Number)
  code: number = 0;
  @JsonProperty("contactNumber", String)
  contactNumber: string = "";
  @JsonProperty("country", Any)
  country: any = null;
  @JsonProperty("dateOfOpening", String)
  dateOfOpening: string = "";
  @JsonProperty("email", String)
  email: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("isActive", Boolean)
  isActive: boolean = false;
  @JsonProperty("is24HourStore", Boolean)
  is24HourStore: boolean = false;
  @JsonProperty("managers", Any)
  managers: any = [];
  @JsonProperty("operationalDays", [String])
  operationalDays: [string] = null;
  @JsonProperty("operationalHours", String)
  operationalHours: string = "";
  @JsonProperty("profilePercentage", Number)
  profilePercentage: number = 0;
  @JsonProperty("storeSpecifics", [String])
  storeSpecifics: [string] = null;
  @JsonProperty("profileKey", String)
  profileKey: string = "";
  @JsonProperty("profileUrl", String)
  profileUrl: string = "";
  @JsonProperty("operatingHoursFrom", String)
  operatingHoursFrom: string = "";
  @JsonProperty("operatingHoursTo", String)
  operatingHoursTo: string = "";
}
