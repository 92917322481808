import {
  LpAppealerResponseModel,
  LpAppealsAccordionDataModel,
  LpAppealsQuestionListModel,
  LpFileModel
} from "models/lp/appeals";
import { object } from "yup";

export const LpAppealAccordianFormatter = async (response: any) => {
  let data: LpAppealsAccordionDataModel[] = [];
  response &&
    response.map((item: any, index: number) => {
      let object: LpAppealsAccordionDataModel = {
        serialNumber: "",
        title: "",
        id: "",
        categoryId: "",
        opportunityList: [],
        appealerResponse: null,
        status: ""
      };
      object.serialNumber = item.subCategoryLabel;
      object.title = item.subCategoryName;
      object.id = item.subCategoryId;
      object.categoryId = item.categoryId;
      object.status = item.status;
      if (item?.questions?.length > 0) {
        item?.questions?.map((el: any) => {
          let obj: LpAppealsQuestionListModel = {
            id: null,
            serialNumber: "",
            questionName: "",
            subCategoryId: "",
            categoryId: "",
            auditorComment: "",
            observations: [],
            file: []
          };

          obj.id = el.id;
          obj.serialNumber = el.questionLabel;
          obj.questionName = el.question;
          obj.subCategoryId = el.subCategoryId;
          obj.categoryId = el.categoryId;
          obj.auditorComment = el.comment;
          if (el?.observations?.length > 0) {
            el?.observations?.map((els: any) => {
              let obsObj = {
                observation: els.observation,
                observationId: els.observationId
              };
              obj.observations.push(obsObj);
            });
          }

          if (el?.images?.length > 0) {
            el?.images?.map((els: any) => {
              let imgObj = {
                id: els.id,
                signedUrl: els.signedUrl
              };
              obj.file.push(imgObj);
            });
          }

          object.opportunityList.push(obj);
        });
      }
      if (item.appealerResponse !== null) {
        let appealerResponseObject: LpAppealerResponseModel = {
          comment: "",
          hasAppealed: false,
          file: []
        };
        appealerResponseObject.comment = item.appealerResponse.comment;
        appealerResponseObject.hasAppealed = item.appealerResponse.hasAppealed;
        if (item?.appealerResponse?.assets?.length > 0) {
          item?.appealerResponse?.assets.map((el: any) => {
            let objs: LpFileModel = {
              id: "",
              signedUrl: "",
              blob: null,
              observations: [],
              file: [],
              auditorComment: ""
            };
            objs.id = el.id;
            objs.signedUrl = el.signedUrl;
            objs.auditorComment = el.comment;

            if (el?.observations?.length > 0) {
              el?.observations?.map((els: any) => {
                let obsObj = {
                  observation: els.observation,
                  observationId: els.observationId
                };
                objs.observations.push(obsObj);
              });
            }

            if (el?.images?.length > 0) {
              el?.images?.map((els: any) => {
                let imgObj = {
                  id: els.id,
                  signedUrl: els.signedUrl
                };
                objs.file.push(imgObj);
              });
            }
          });
        }
        object.appealerResponse = appealerResponseObject;
      }
      data.push(object);
    });
  return data;
};
