import React from "react";
import { StyleSheet } from "react-native";
import { fetchYMax, graphColors } from "@timhortons/common/src/utils/graphColorHelper";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LpIndividualAccessSummaryDataModel } from "@timhortons/common/src/models/lp/reports";
import LpReportSummaryTemplate from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/template/lpReportSummaryTemplate";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { lpStoreRankingTableHeaderData } from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/momGraphHelper";
import { ILpReportsLoading } from "@timhortons/common/src/redux/lp/reducers/reportsReducer";

interface IProps {
  translate?: any;
  user: IUserDetails;
  lpSummaryData: LpIndividualAccessSummaryDataModel;
  loading: boolean;
  error: string;
  storeRakingTableLoading: boolean;
  totalAuditDetailsTablesLoading: ILpReportsLoading;
  storeRakingTableError: string;
  pageNumber: number;
  handlePageNo: Function;
  areaManagerTotalAuditData: any;
  countryTotalAuditData: any;
  lpAuditorTotalAuditData: any;
  handleCountryPage: Function;
  handleAreaManagerPage: Function;
  handleLpAuditorPage: Function;
  lpAuditorPageNo: number;
  areaManagerPageNo: number;
  countryPageNo: number;
  selectedCountry: string;
}

export default function AdminSummary(props: IProps) {
  const {
    translate,
    user,
    lpSummaryData,
    loading,
    error,
    storeRakingTableLoading,
    storeRakingTableError,
    pageNumber,
    handlePageNo,
    areaManagerTotalAuditData,
    countryTotalAuditData,
    lpAuditorTotalAuditData,
    handleCountryPage,
    handleAreaManagerPage,
    handleLpAuditorPage,
    lpAuditorPageNo,
    areaManagerPageNo,
    countryPageNo,
    selectedCountry,
    totalAuditDetailsTablesLoading
  } = props;

  const gradeBarChartData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData?.gradeDistribution?.storeCount
      }
    ],
    columnWidth: "25",
    categories: lpSummaryData && lpSummaryData?.gradeDistribution?.label,
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Grades",
    yAxisTitle: "No of Restaurants",
    leftTitle: "Grade Distribution",
    rightTitle: "",
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "",
    ymax: fetchYMax(lpSummaryData && lpSummaryData?.gradeDistribution?.storeCount, 25)
  };
  const avgScoreCalcLength = lpSummaryData?.averageScore?.percentage.filter((x) => x != 0).length;
  const avgScoreChartData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData?.averageScore?.percentage
      }
    ],
    columnWidth: "25",
    categories: lpSummaryData && lpSummaryData?.averageScore?.name,
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Countries",
    yAxisTitle: "Percentage",
    leftTitle: selectedCountry ? "Average Score" : "Average Score - GCC",
    rightTitle: `Avg.Score: ${
      lpSummaryData?.averageScore?.percentage?.length > 0 && avgScoreCalcLength > 0
        ? (
            (lpSummaryData && lpSummaryData?.averageScore?.percentage.reduce((a, b) => a + b, 0)) /
            avgScoreCalcLength
          )?.toFixed(2)
        : 0
    }%`,
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "%",
    ymax: fetchYMax(lpSummaryData && lpSummaryData.averageScore?.percentage, 25)
  };

  const series = lpSummaryData && lpSummaryData?.actionPlansData?.series;
  const labels = lpSummaryData && lpSummaryData?.actionPlansData?.labels;
  const totalReports = lpSummaryData && lpSummaryData?.actionPlansData?.totalReports;
  const inProcessReport = lpSummaryData && lpSummaryData?.actionPlansData?.inProcessReport;

  const criticalPointsReportGraphData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData?.criticalPointsReport?.averageDeductedPoints
      }
    ],
    categories: lpSummaryData && lpSummaryData?.criticalPointsReport?.labels,
    categoryColors: graphColors.gradeGraph,
    leftTitle: translate("criticalPoints"),
    labelSuffix: "",
    height: 300,
    ymax: fetchYMax(
      lpSummaryData && lpSummaryData?.criticalPointsReport?.averageDeductedPoints,
      20
    ),
    xAxisTitle: "KPI's",
    yAxisTitle: "Deductions"
  };

  return error ? (
    <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
  ) : loading ? (
    <Loader type="component" />
  ) : (
    lpSummaryData && (
      <>
        <LpReportSummaryTemplate
          translate={translate}
          user={user}
          gradeBarChartData={gradeBarChartData}
          momBarChartData={avgScoreChartData}
          criticalPointsReportGraph={criticalPointsReportGraphData}
          criticalCategoriesTable={lpSummaryData && lpSummaryData.criticalCategories}
          lpKPIData={lpSummaryData && lpSummaryData.lpKPIData}
          opportunityData={lpSummaryData && lpSummaryData.opportunityData}
          storeRankingTableHeaderData={lpStoreRankingTableHeaderData(translate)}
          series={series}
          labels={labels}
          totalReports={totalReports}
          inProcessReport={inProcessReport}
          actionPlanGraphHeight={300}
          showTopAndLastFive={true}
          kpiHeaderTitle={translate("By Country")}
          criticalPointHeaderTitle={translate("By Country")}
          storeRankingTableHeaderTitle={"Restaurant Ranking - GCC"}
          loading={loading}
          storeRakingTableLoading={storeRakingTableLoading}
          storeRakingTableError={storeRakingTableError}
          storeRankingTableData={lpSummaryData?.storeRankingTableData}
          pageNo={pageNumber}
          pageNoHandler={handlePageNo}
          handleCountryPage={handleCountryPage}
          handleAreaManagerPage={handleAreaManagerPage}
          handleLpAuditorPage={handleLpAuditorPage}
          areaManagerTotalAuditData={areaManagerTotalAuditData}
          countryTotalAuditData={countryTotalAuditData}
          lpAuditorTotalAuditData={lpAuditorTotalAuditData}
          lpAuditorPageNo={lpAuditorPageNo}
          areaManagerPageNo={areaManagerPageNo}
          countryPageNo={countryPageNo}
          totalAuditDetailsTablesLoading={totalAuditDetailsTablesLoading}
        />
      </>
    )
  );
}

const styles = StyleSheet.create({
  fsGraphYAxisStyle: {
    alignSelf: "center",
    transform: [{ rotate: "270deg" }, { translateY: -275 }, { translateX: -150 }]
  }
});
