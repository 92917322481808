import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { ManagerReviewActions } from "@timhortons/common/src/redux/logs/actions/managerReview";
import { ManagerReviewRepository } from "@timhortons/common/src/repositories/logs/managerReview";
import { IMRApproval } from "@timhortons/common/src/modules/logs/dashboards/managerReview";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";
import { ManagerReviewTableModel } from "@timhortons/common/src/models/logs/mainLogs";

function* getManagerReviewTableWorkerSaga(
  value: IFluxStandardAction<{
    storeCode: number;
    date: string;
    timeZone: string;
    search: string;
    fromIndividualAccess: boolean;
  }>
) {
  const { payload } = value;
  try {
    let data: Array<ManagerReviewTableModel> = yield call(
      ManagerReviewRepository.getManagerReviewTableData,
      payload
    );

    yield put(ManagerReviewActions.getManagerReviewLogsSuccess(data));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "getManagerReviewTableWorkerSaga");

    yield put(ManagerReviewActions.getManagerReviewLogsError(error));
  }
}
function* updateMRApprovalWorkerSaga(value: IFluxStandardAction<IMRApproval>) {
  try {
    const { payload } = value;
    let auth = {
      managerAccess: true,
      storeCode: payload.storeCode,
      employeeId: payload.employeeId
    };
    let dataa: {};
    let checkAuthData: {} = yield call(MainLogsRepository.checkLogsAuth, auth);
    if (checkAuthData) {
      dataa = yield call(ManagerReviewRepository.updateMRApprovalLogs, payload);
    }

    yield put(ManagerReviewActions.updateMRApprovalSuccess(dataa));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "updateMRApprovalWorkerSaga");

    yield put(ManagerReviewActions.updateMRApprovalError(error));
  }
}
function* managerReviewWatcherSaga() {
  yield takeLatest(
    LOGS.ManagerReview.GET_MANAGER_REVIEW_LOGS.LOADING,
    getManagerReviewTableWorkerSaga
  );
  yield takeLatest(
    LOGS.ManagerReview.UPDATE_MANAGER_REVIEW_APPROVAL.LOADING,
    updateMRApprovalWorkerSaga
  );
}
export default managerReviewWatcherSaga;
