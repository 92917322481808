import { IUserDetails } from "modules/iam/redux/reducer/reducer";
import React from "react";
import { StyleSheet } from "react-native";
import { fetchYMax, graphColors } from "@timhortons/common/src/utils/graphColorHelper";
import {
  CountryManagerDetailsModel,
  IndividualAccessSummaryDataModel
} from "@timhortons/common/src/models/rev/reports";
import ReportSummaryTemplate from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/template/reportSummaryTemplate";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import {
  MonthOnMonthBarData,
  storeRankingTableHeaderData
} from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/momGraphHelper";
interface IProps {
  user: IUserDetails;
  translate: any;
  summaryData: IndividualAccessSummaryDataModel;
  loading: boolean;
  error: string;
  countryManagerDetails: CountryManagerDetailsModel;
}
export default function CountryManager(props: IProps) {
  const { user, translate, summaryData, loading, error, countryManagerDetails } = props;
  const series = summaryData && summaryData.actionPlansData.series;
  const labels = summaryData && summaryData.actionPlansData.labels;
  const totalReports = summaryData && summaryData.actionPlansData.totalReports;
  const inProcessReport = summaryData && summaryData.actionPlansData.inProcessReport;

  const gradeBarChartData = {
    series: [
      {
        data: summaryData && summaryData.gradeDistribution.storeCount
      }
    ],
    columnWidth: "25",
    categories: summaryData && summaryData.gradeDistribution.label,
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Grades",
    yAxisTitle: "No of Restaurants",
    leftTitle: `REV Grade - ${countryManagerDetails && countryManagerDetails.countryLabel}`,
    rightTitle: `Avg. Score: ${(
      (summaryData && summaryData.gradeDistribution.storeCount.reduce((a, b) => a + b, 0)) /
      (summaryData && summaryData.gradeDistribution.storeCount.length)
    ).toFixed(2)}%`,
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "",
    ymax: fetchYMax(summaryData && summaryData.gradeDistribution.storeCount, 25)
  };
  const areaRankingTableHeaderData = [
    { name: translate("rank") },
    { name: translate("areaManagerNames") },
    { name: translate("avgScore") },
    { name: translate("sosFc") },
    { name: translate("sosDt") }
  ];

  const fsDeductionGraphData = {
    series: [
      {
        data: summaryData && summaryData.fsDeductions.stores
      }
    ],
    columnWidth: "20px",
    categories: summaryData && summaryData.fsDeductions.deductions,
    categoryColors: graphColors.fsDeductionGraph,
    xAxisTitle: "Deductions",
    yAxisTitle: "No. of Restaurants",
    leftTitle: `Critical Points Deductions - ${
      countryManagerDetails && countryManagerDetails.countryLabel
    }`,
    // legends: [
    //   {
    //     key: "0",
    //     color: graphColors.fsDeductionGraphLabel[0]
    //   },
    //   {
    //     key: "-4 to -8",
    //     color: graphColors.fsDeductionGraphLabel[1]
    //   },
    //   {
    //     key: "-12 to -40",
    //     color: graphColors.fsDeductionGraphLabel[2]
    //   }
    // ],
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "",
    ymax: fetchYMax(summaryData && summaryData.fsDeductions.stores, 25)
  };

  return error ? (
    <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
  ) : loading ? (
    <Loader type="component" />
  ) : (
    summaryData && (
      <>
        <ReportSummaryTemplate
          translate={translate}
          user={user}
          gradeBarChartData={gradeBarChartData}
          momBarChartData={MonthOnMonthBarData(summaryData)}
          areaRankingsTableData={summaryData && summaryData.areaManagerRankingsTableData}
          areaRankingTableHeaderData={areaRankingTableHeaderData}
          fsDeductionGraphData={fsDeductionGraphData}
          brandStandardKPIData={summaryData && summaryData.brandStandardKPIData}
          opportunityData={summaryData && summaryData.opportunityData}
          storeRankingTableHeaderData={storeRankingTableHeaderData(translate)}
          storeRankingsTopFiveTableData={summaryData && summaryData.storeRankingTopFiveData}
          storeRankingsLastFiveTableData={summaryData && summaryData.storeRankingLastFiveData}
          series={series}
          inProcessReport={inProcessReport}
          labels={labels}
          totalReports={totalReports}
          actionPlanGraphHeight={310}
          showTopAndLastFive={true}
          kpiHeaderTitle={translate("By Area Manager")}
          areaManagerRankingTableHeaderTitle={`Area manager Ranking - ${
            countryManagerDetails && countryManagerDetails.countryLabel
          }`}
          storeRankingTableHeaderTitle={`Restaurant Ranking - ${
            countryManagerDetails && countryManagerDetails.countryLabel
          }`}
        />
      </>
    )
  );
}

const styles = StyleSheet.create({
  fsGraphYAxisStyle: {
    alignSelf: "center",
    transform: [{ rotate: "270deg" }, { translateY: -275 }, { translateX: -150 }]
  }
});
