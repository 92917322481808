import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { setFrameMeasures } from "@timhortons/common/src/utils/measureLayout";
import OverflowModal from "@timhortons/common/src/components/atoms/overflowModal";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { IButtonStyle } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportType";
interface Props {
  triggerComponent?: boolean;
  callBk?: (data: { year: number; month: { label: string; id: number } }) => void;
  onPressButton?: Function;
  buttonProps?: IButtonStyle;
  iconSize?: number;

  reset?: boolean;
  setReset?: (reset: boolean) => void;
}
export const monthsGregorian = [
  { label: "January", id: 0 },
  { id: 1, label: "February" },
  { id: 2, label: "March" },
  { id: 3, label: "April" },
  { id: 4, label: "May" },
  { id: 5, label: "June" },
  { id: 6, label: "July" },
  { id: 7, label: "August" },
  { id: 8, label: "September" },
  { id: 9, label: "October" },
  { id: 10, label: "November" },
  { id: 11, label: "December" }
];

const quartersGregorian = [
  { label: "Quarter 1", id: 0 },
  { id: 1, label: "Quarter 2" },
  { id: 2, label: "Quarter 3" },
  { id: 3, label: "Quarter 4" }
];

const generateMonthList = (year: number): Array<{ label: string; id: number }> => {
  let currentYear = new Date().getFullYear();
  if (year > currentYear) {
    throw new Error("Illegal Operation");
  }
  if (year < currentYear) {
    return monthsGregorian;
  } else {
    let currentMonth = new Date().getMonth();
    return monthsGregorian.slice(0, currentMonth + 1);
  }
};
const generateQuarterList = (year: number): Array<{ label: string; id: number }> => {
  let currentYear = new Date().getFullYear();
  if (year > currentYear) {
    throw new Error("Illegal Operation");
  }
  if (year < currentYear) {
    return quartersGregorian;
  } else {
    let currentMonth = new Date().getMonth();
    let quarterLen = Math.ceil((currentMonth + 1) / 3);
    return quartersGregorian.slice(0, quarterLen);
  }
};
const UI = (props: {
  list: Array<{ label: string; id: number }>;
  onPressItem: (data: { label: string; id: number }) => void;
  centerDisp: string;
  onPressChevron: Function;
  selectedMonth?: { label: string; id: number };
  reset?: boolean;
  setOpen?: (value: boolean) => void;
}): ReactElement => {
  const { list, onPressItem, centerDisp, onPressChevron, selectedMonth, reset, setOpen } = props;
  return (
    <View style={styles.masterWrapper}>
      <View style={styles.headerWrapper}>
        <TouchableOpacity onPress={() => onPressChevron("left")}>
          <Icon name={IconNames.left} customStyle={styles.iconStyle} />
        </TouchableOpacity>
        <Text testId="crtyr" fontWeight={FontWeight.SemiBold} textSize={TextSize.Regular}>
          {centerDisp}
        </Text>
        <TouchableOpacity onPress={() => onPressChevron("right")}>
          <Icon name={IconNames.right} customStyle={styles.iconStyle} />
        </TouchableOpacity>
      </View>
      <View style={styles.monthListWrapper}>
        <FlatList
          extractor={(item) => item.id.toString()}
          listData={list}
          scrollEnabled={true}
          listContainerStyle={{ height: 200 }}
          listRenderer={(rowItem) => (
            <>
              <TouchableOpacity
                onPress={() => {
                  setOpen(false);
                  onPressItem(rowItem as { label: string; id: number });
                }}
                style={
                  selectedMonth && selectedMonth.id === rowItem.id && !reset
                    ? styles.individualMonthSelected
                    : styles.individualMonthNotSelected
                }
              >
                <Text
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.Regular}
                  testId={rowItem.id.toString()}
                >
                  {rowItem.label}
                </Text>
              </TouchableOpacity>
            </>
          )}
        />
      </View>
    </View>
  );
};

const YearUI = (props: {
  list: any;
  onPressItem: (data: { label: string; id: number }) => void;
  selectedYear?: { label: string; id: number };
  reset?: boolean;
  setOpen?: (value: boolean) => void;
}): ReactElement => {
  const { list, onPressItem, selectedYear, reset, setOpen } = props;
  return (
    <View style={styles.masterWrapper}>
      <FlatList
        extractor={(item) => item.id.toString()}
        listData={list}
        scrollEnabled={true}
        listContainerStyle={{ height: 200 }}
        listRenderer={(rowItem) => (
          <>
            <TouchableOpacity
              onPress={() => {
                setOpen(false);
                onPressItem(rowItem as { label: string; id: number });
              }}
              style={
                selectedYear && selectedYear.id === rowItem.id && !reset
                  ? styles.individualMonthSelected
                  : styles.individualMonthNotSelected
              }
            >
              <Text
                fontWeight={FontWeight.Regular}
                textSize={TextSize.Regular}
                testId={rowItem.id.toString()}
              >
                {rowItem.label}
              </Text>
            </TouchableOpacity>
          </>
        )}
      />
    </View>
  );
};
export const MonthPicker = ({
  initMonth = null,
  initYear = null,
  callBk = null,
  triggerComponent = false,
  parentCallBack = null,
  reset = false,
  setReset = null,
  onPressButton,
  iconSize = 16,
  buttonProps
}: {
  initYear?: number;
  initMonth?: { label: string; id: number };
  callBk: (data: { year: number; month: { label: string; id: number } }) => void;
  triggerComponent?: boolean;
  parentCallBack?: Function;
  reset?: boolean;
  setReset?: any;
  onPressButton?: Function;
  iconSize?: number;
  buttonProps?: IButtonStyle;
}) => {
  const [dispYear, setDispYear] = useState<number>(initYear ?? new Date().getFullYear());
  const [dispMonthList, setDispMonthList] = useState<Array<{ label: string; id: number }>>();
  const [selectedMonth, setSelectedMonth] = useState<{ label: string; id: number }>(initMonth);
  const [buttonFrame, setButtonFrame] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const triggerRef = useRef(null);

  const onDropdownOpen = (): void => {
    setOpen(!open);
  };
  useEffect(() => {
    if (reset) {
      setSelectedMonth(null);
    }
  }, [reset]);

  useEffect(() => {
    if (dispYear && selectedMonth) {
      setReset && setReset(false);
      callBk({ month: selectedMonth, year: dispYear });
    }
  }, [dispYear, selectedMonth]);

  const showModal = (): void => {
    setFrameMeasures(
      () => {
        setOpen(true);
      },
      triggerRef,
      setButtonFrame
    );
  };
  useEffect(() => {
    if (dispYear && dispYear > 0) {
      setDispMonthList(generateMonthList(dispYear));
    }
  }, [dispYear]);

  useEffect(() => {
    if (dispMonthList && dispMonthList.length > 0) {
      let z = new Date().getMonth();
      dispMonthList.filter((el) => el.id === z);
    }
  }, [dispMonthList]);
  const onIconPress = (dir: "right" | "left") => {
    let newDispYear = dispYear;
    if (dir === "right") {
      if (newDispYear + 1 <= new Date().getFullYear()) {
        newDispYear = newDispYear + 1;
      }
    } else {
      if (newDispYear - 1 >= 2000) {
        newDispYear = newDispYear - 1;
      }
    }
    setDispYear(newDispYear);
  };
  const handleButton = () => {
    onPressButton && onPressButton();
    showModal();
  };
  function getText() {
    if (!reset && selectedMonth && dispYear) {
      return `${selectedMonth.label.slice(0, 3)}, ${dispYear}`;
    } else {
      return "Select Month";
    }
  }

  return (
    <OverflowModal
      buttonFrame={buttonFrame}
      modalVisible={open}
      setModalState={onDropdownOpen}
      triggerComponent={
        triggerComponent ? (
          <TouchableOpacity ref={triggerRef}>
            <Button
              iconSize={iconSize}
              textStyles={buttonProps.textStyles}
              buttonStyles={buttonProps.styleButton}
              type={ButtonType.Secondary}
              iconName={buttonProps.iconName}
              iconStyles={buttonProps.iconColorStyles}
              title={buttonProps.title}
              onPress={handleButton}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity ref={triggerRef}>
            <Button
              type={ButtonType.Secondary}
              iconName={IconNames.calender}
              onPress={handleButton}
              buttonStyles={styles.buttonStyle}
              textStyles={styles.textStyle}
              textSize={TextSize.Regular}
              iconStyles={styles.buttonIconStyle}
            >
              <Text
                textSize={TextSize.Regular}
                fontWeight={FontWeight.Regular}
                testId="select-month"
              >
                {getText()}
              </Text>
            </Button>
          </TouchableOpacity>
        )
      }
    >
      <UI
        centerDisp={dispYear.toString()}
        onPressItem={setSelectedMonth}
        list={dispMonthList}
        onPressChevron={onIconPress}
        selectedMonth={selectedMonth}
        reset={reset}
        setOpen={setOpen}
      />
    </OverflowModal>
  );
};
export const QuarterPicker = (props: Props) => {
  const {
    setReset,
    reset,
    triggerComponent,
    callBk,
    onPressButton,
    iconSize = 16,
    buttonProps
  } = props;
  const [dispYear, setDispYear] = useState<number>(new Date().getFullYear());
  const [dispQuarterList, setDispQuarterList] = useState<Array<{ label: string; id: number }>>();
  const [selectedQuarter, setSelectedQuarter] = useState<{ label: string; id: number }>(null);
  const [buttonFrame, setButtonFrame] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const triggerQuarterRef = useRef(null);

  const onDropdownOpen = (): void => {
    setOpen(!open);
  };
  useEffect(() => {
    if (reset) {
      setSelectedQuarter(null);
    }
  }, [reset]);

  const showQuarterModal = (): void => {
    setFrameMeasures(
      () => {
        setOpen(true);
      },
      triggerQuarterRef,
      setButtonFrame
    );
  };
  useEffect(() => {
    if (dispYear && selectedQuarter) {
      setReset && setReset(false);
      callBk({ month: selectedQuarter, year: dispYear });
    }
  }, [dispYear, selectedQuarter]);
  useEffect(() => {
    if (dispYear && dispYear > 0) {
      setDispQuarterList(generateQuarterList(dispYear));
    }
  }, [dispYear]);
  const iconPress = (dir: "right" | "left") => {
    let newYear = dispYear;
    if (dir === "right") {
      if (newYear + 1 <= new Date().getFullYear()) {
        newYear = newYear + 1;
      }
    } else {
      if (newYear - 1 >= 2000) {
        newYear = newYear - 1;
      }
    }
    setDispYear(newYear);
  };
  const handleButton = () => {
    onPressButton && onPressButton();
    showQuarterModal();
  };
  return (
    <OverflowModal
      buttonFrame={buttonFrame}
      modalVisible={open}
      setModalState={onDropdownOpen}
      triggerComponent={
        triggerComponent ? (
          <TouchableOpacity ref={triggerQuarterRef}>
            <Button
              iconStyles={buttonProps.iconColorStyles}
              textStyles={buttonProps.textStyles}
              buttonStyles={buttonProps.styleButton}
              iconSize={iconSize}
              iconName={buttonProps.iconName}
              type={ButtonType.Secondary}
              title={buttonProps.title}
              onPress={handleButton}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={showQuarterModal} ref={triggerQuarterRef}>
            <Button
              type={ButtonType.Primary}
              title="Quarterly"
              iconName={IconNames.noneDone}
              buttonStyles={styles.buttonStyle}
            />
          </TouchableOpacity>
        )
      }
    >
      <UI
        centerDisp={dispYear.toString()}
        onPressItem={setSelectedQuarter}
        list={dispQuarterList}
        onPressChevron={iconPress}
        setOpen={setOpen}
        reset={reset}
        selectedMonth={selectedQuarter}
      />
    </OverflowModal>
  );
};
export const YearPicker = ({
  callBk = null,
  triggerComponent = false,
  reset = false,
  setReset = null,
  onPressButton,
  iconSize = 16,
  buttonProps,
  yearRange = 20
}: {
  initYear?: number;
  initMonth?: { label: string; id: number };
  callBk: (data: { year: { label: string; id: number } }) => void;
  triggerComponent?: boolean;
  reset?: boolean;
  setReset?: any;
  onPressButton?: Function;
  iconSize?: number;
  buttonProps?: IButtonStyle;
  yearRange?: number;
}) => {
  const [selectedYear, setSelectedYear] = useState<{ label: string; id: number }>();
  const [buttonFrame, setButtonFrame] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const triggerRef = useRef(null);
  const year = new Date().getFullYear();
  const years = Array.from(new Array(yearRange), (val, index) => year - index);
  const [dispYearList, setDispYearList] = useState<Array<{ label: number; id: number }>>();
  const onDropdownOpen = (): void => {
    setOpen(!open);
  };
  useEffect(() => {
    if (reset) {
      setSelectedYear(null);
    }
  }, [reset]);

  useEffect(() => {
    if (selectedYear) {
      setReset && setReset(false);
      callBk({ year: selectedYear });
    }
  }, [selectedYear]);

  useEffect(() => {
    let newArr: any[] = [];
    for (var i = 0, n = years.length; i < n; i++) {
      newArr.push({
        label: years[i],
        id: i + 1
      });
    }
    setDispYearList(newArr);
  }, [years.toString()]);

  const showModal = (): void => {
    setFrameMeasures(
      () => {
        setOpen(true);
      },
      triggerRef,
      setButtonFrame
    );
  };
  const handleButton = () => {
    onPressButton && onPressButton();
    showModal();
  };
  function getText() {
    if (!reset && selectedYear) {
      return `${selectedYear?.label}`;
    } else {
      return "Select Year";
    }
  }

  return (
    <OverflowModal
      buttonFrame={buttonFrame}
      modalVisible={open}
      setModalState={onDropdownOpen}
      triggerComponent={
        triggerComponent ? (
          <TouchableOpacity ref={triggerRef}>
            <Button
              iconSize={iconSize}
              textStyles={buttonProps.textStyles}
              buttonStyles={buttonProps.styleButton}
              type={ButtonType.Secondary}
              iconName={buttonProps.iconName}
              iconStyles={buttonProps.iconColorStyles}
              title={buttonProps.title}
              onPress={handleButton}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity ref={triggerRef}>
            <Button
              type={ButtonType.Secondary}
              iconName={IconNames.calender}
              onPress={handleButton}
              buttonStyles={styles.buttonStyle}
              textStyles={styles.textStyle}
              textSize={TextSize.Regular}
              iconStyles={styles.buttonIconStyle}
            >
              <Text
                textSize={TextSize.Regular}
                fontWeight={FontWeight.Regular}
                testId="select-Year"
              >
                {getText()}
              </Text>
            </Button>
          </TouchableOpacity>
        )
      }
    >
      <YearUI
        onPressItem={setSelectedYear}
        list={dispYearList}
        selectedYear={selectedYear}
        reset={reset}
        setOpen={setOpen}
      />
    </OverflowModal>
  );
};
const styles = StyleSheet.create({
  buttonIconStyle: {
    color: colorPallete.grey2
  },
  masterWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colorPallete.grey6,
    backgroundColor: colorPallete.white3,
    shadowColor: "rgba(0, 0, 0, 0.04)",
    shadowOffset: { width: 4, height: 6 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 10,
    flex: 1,
    flexDirection: "column"
  },
  iconStyle: {
    fontSize: 12,
    fontWeight: "900",
    color: colorPallete.grey1
  },
  headerWrapper: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center"
  },
  monthListWrapper: {
    flex: 1,
    marginTop: 20
  },
  individualMonthSelected: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 6,
    backgroundColor: colorPallete.variant1
  },
  individualMonthNotSelected: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 6,
    backgroundColor: null
  },
  selectedMonth: {
    // backgroundColor: "rgba(250, 250, 250, 0.85)"
  },
  buttonStyle: {
    backgroundColor: colorPallete.grey9,
    paddingVertical: 6,
    // paddingHorizontal: 12,
    borderColor: colorPallete.white1,
    borderWidth: 0,
    flex: 1,
    flexDirection: "row-reverse"
  },
  textStyle: {
    color: colorPallete.black4,
    ...I18nService.select({
      rtl: { paddingEnd: 12 },
      ltr: { paddingStart: 12 }
    }),
    alignContent: "center"
  }
});
