import {
  SubCategoriesModel,
  MarkDownContentModel,
  ContentsModelList,
  deleteSubCategoryTitleDataModel,
  SearchResultsResponseModel
} from "@timhortons/common/src/models/userResources/userResources";
import {
  filtersResources,
  searchApiDataMock
} from "@timhortons/common/src/modules/userResources/dummy";
import { ISearchKeyword } from "@timhortons/common/src/modules/userResources";
import { sleep } from "@timhortons/common/src/utils/timeoutFunctions";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { resourcesEndPoints } from "@timhortons/common/src/constants/endPoints";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ResourcesKeys } from "@timhortons/common/src/constants/apiKeys";
class ResourcesRepository {
  getContentsList = async () => {
    let data: ContentsModelList[] = await ApiClient.get(
      resourcesEndPoints.getContentList(),
      {},
      ResourcesKeys.RESOURCES_MANAGE
    );
    return data;
  };
  getPublishedCategories = async () => {
    let data: ContentsModelList[] = await ApiClient.get(
      resourcesEndPoints.getPublishCategories(),
      {},
      ResourcesKeys.RESOURCES_USER
    );
    return data;
  };

  getSubCategoriesList = async (param: { categoryId: number }) => {
    const { categoryId } = param;
    let subCategoriesListData = await ApiClient.get(
      resourcesEndPoints.getSubCategoriesList(categoryId),
      {},
      ResourcesKeys.RESOURCES_MANAGE
    );
    return deserialize(SubCategoriesModel, subCategoriesListData);
  };

  getPublishedSubCategoriesList = async (param: { categoryId: number }) => {
    const { categoryId } = param;
    let subCategoriesListData = await ApiClient.get(
      resourcesEndPoints.getPublishedSubCategoriesList(categoryId),
      {},
      ResourcesKeys.RESOURCES_USER
    );
    return deserialize(SubCategoriesModel, subCategoriesListData);
  };
  getSubCategoriesTopicList = async (param: { subCategoryId: number }) => {
    let data = await ApiClient.get(
      resourcesEndPoints.getTopicList(param.subCategoryId),
      {},
      ResourcesKeys.RESOURCES_MANAGE
    );
    return data.topics;
  };
  getPublishedSubCategoriesTopicList = async (param: { subCategoryId: number }) => {
    let data = await ApiClient.get(
      resourcesEndPoints.getPublishedTopicList(param.subCategoryId),
      {},
      ResourcesKeys.RESOURCES_USER
    );
    return data.topics;
    return [];
  };
  getTopicContent = async (param: { subCategoryId: string }) => {
    const { subCategoryId } = param;
    let topicContent: MarkDownContentModel = await ApiClient.get(
      resourcesEndPoints.getTopicContent(subCategoryId),
      {},
      ResourcesKeys.RESOURCES_MANAGE
    );
    return topicContent;
  };

  getPublishedTopicContent = async (param: { subCategoryId: string }) => {
    const { subCategoryId } = param;
    let topicContent: MarkDownContentModel = await ApiClient.get(
      resourcesEndPoints.getPublishedTopicContent(subCategoryId),
      {},
      ResourcesKeys.RESOURCES_USER
    );
    return topicContent;
  };
  getSearchResults = async (param: ISearchKeyword) => {
    // const { search } = param;

    // Write post call for sending the search string mandatory along with filters if selected & return the search results
    // param = {searchText: 'food sa', categoryFilter: ["04226b19-79e1-4284-b2c6-a84b11b2e4ae","8c9ade5b-947b-4d89-b144-676d1fd7845f"]};

    if (param.categoryFilter.length === 0) {
      const data = await ApiClient.get(
        resourcesEndPoints.searchCategory(param),
        {},
        ResourcesKeys.RESOURCES_USER
      );
      if (data.searchResponses.length === 0) {
        throw "isEmpty";
      }
      return deserialize(SearchResultsResponseModel, data);
    } else {
      const data = await ApiClient.get(
        resourcesEndPoints.searchCategoryWithFilter(param),
        {},
        ResourcesKeys.RESOURCES_USER
      );
      if (data.searchResponses.length === 0) {
        throw "isEmpty";
      }
      return deserialize(SearchResultsResponseModel, data);
    }
  };

  getFilters = () => {
    // get call for filters
    return filtersResources;
  };

  postSubCategory = async (params: {
    categoryId: string;
    name: string;
    description: any;
    subCategoryId?: string;
  }) => {
    const { categoryId, name, description, subCategoryId } = params;
    if (subCategoryId) {
      let data = await ApiClient.put(
        resourcesEndPoints.putSubCategory(subCategoryId),
        {
          name: name,
          description: description,
          categoryId: params.categoryId
        },
        null,
        ResourcesKeys.RESOURCES_MANAGE
      );
      return {
        subCategoryData: {
          id: data.id,
          name: data.name,
          description: data.description
        },
        subCategoryId: subCategoryId
      };
    } else {
      let data = await ApiClient.post(
        resourcesEndPoints.postSubCategory(),
        {
          name: name,
          description: description === undefined ? [] : description,
          categoryId: categoryId
        },
        null,
        ResourcesKeys.RESOURCES_MANAGE
      );
      return {
        subCategoryData: {
          id: data.id,
          name: data.name,
          description: data.description
        }
      };
    }
  };
  postTopic = async (params: {
    name: string;
    subCategoryId: string;
    content: any;
    topicId?: string;
    categoryId?: string;
  }) => {
    const { name, subCategoryId, content, topicId, categoryId } = params;

    if (topicId) {
      let data = await ApiClient.put(
        resourcesEndPoints.putTopic(topicId),
        {
          topic: name,
          content: content,
          categoryId: categoryId
        },
        null,
        ResourcesKeys.RESOURCES_MANAGE
      );
      return {
        topicData: {
          id: data.id,
          name: data.name,
          content: data.content
        },
        topicId: topicId
      };
    } else {
      let data = await ApiClient.post(
        resourcesEndPoints.postTopic(),
        {
          topic: name,
          subCategoryId: subCategoryId,
          content: content === undefined ? [] : content,
          categoryId: categoryId
        },
        null,
        ResourcesKeys.RESOURCES_MANAGE
      );

      return {
        topicData: {
          id: data.id,
          name: data.name,
          content: data.content
        }
      };
    }
  };
  deleteTopic = async (param: { subCatId: string; topicId: string }) => {
    const { subCatId, topicId } = param;
    await ApiClient.delete(
      resourcesEndPoints.deleteTopic(topicId),
      null,
      null,
      ResourcesKeys.RESOURCES_MANAGE
    );
  };
  deleteCategory = async (param: { catId: string }) => {
    const { catId } = param;
    await ApiClient.delete(
      resourcesEndPoints.deleteCategory(catId),
      null,
      null,
      ResourcesKeys.RESOURCES_MANAGE
    );
  };
  uploadResourceImage = async (payload: FormData) => {
    let headers = {
      ...ResourcesKeys.RESOURCES_MANAGE,
      "Content-Type": "multipart/form-data"
    };
    let p = payload as any;
    let data = await ApiClient.post(resourcesEndPoints.categoryImageUpload(), p, {}, headers);
    return data;
  };
  postCreateNewContent = async (params: { name: string }) => {
    const { name } = params;
    let data = await ApiClient.post(
      resourcesEndPoints.postCreateNewContent(),
      {
        name
      },
      null,
      ResourcesKeys.RESOURCES_MANAGE
    );
    return data;
  };

  publishCategory = async (params: { categoryId: string }) => {
    let response = await ApiClient.post(
      resourcesEndPoints.publishCategory(params.categoryId),
      null,
      null,
      ResourcesKeys.RESOURCES_MANAGE
    );
    return response;
  };
  putCategoryTitle = async (params: { categoryId: string; name: string }) => {
    const { categoryId, name } = params;
    let response = await ApiClient.put(
      resourcesEndPoints.putCategoryTitle(categoryId),
      { name },
      null,
      ResourcesKeys.RESOURCES_MANAGE
    );
    return response;
  };
  deleteSubCategory = async (data: deleteSubCategoryTitleDataModel) => {
    const { subCategoryId } = data;
    let response = await ApiClient.delete(
      resourcesEndPoints.deleteSubCategory(subCategoryId),
      null,
      null,
      ResourcesKeys.RESOURCES_MANAGE
    );
    return response;
  };
}
const resourceRepository = new ResourcesRepository();
export { resourceRepository as ResourcesRepository };
