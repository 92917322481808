import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import { Logger } from "@timhortons/common/src/services/logger";
import {
  LmsAssessmentTheoryDataModel,
  LmsModulePtsDataModel,
  LmsPtsCheckListDataModel,
  LmsPtsCheckListResponseModel,
  LmsPtsTopicDataModel,
  LmsPtsTopicResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";
import { LmsAssessmentActions } from "@timhortons/common/src/redux/manageLms/actions/lmsAssessmentsActions ";
import { LmsAssessmentsRepository } from "@timhortons/common/src/repositories/manageLms/lmsAssessmentsRepository";

function* getLmsAssessmentsPracticalWorkerSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  const { payload } = value;
  try {
    let response: LmsModulePtsDataModel[] = yield call(
      LmsAssessmentsRepository.getAssessmentsPracticalModuleData,
      payload
    );
    yield put(LmsAssessmentActions.getLmsAssessmentPracticalDataSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "getLmsAssessmentsPracticalWorkerSaga");

    yield put(LmsAssessmentActions.getLmsAssessmentPracticalDataError(error));
  }
}

function* postAssessmentsTopicWorkerSaga(value: IFluxStandardAction<LmsPtsTopicDataModel>) {
  const { payload } = value;
  try {
    let response: LmsPtsTopicResponseModel = yield call(
      LmsAssessmentsRepository.postAssessmentsTopic,
      payload
    );
    yield put(LmsAssessmentActions.postAssessmentTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "postAssessmentsTopicWorkerSaga");

    yield put(LmsAssessmentActions.postAssessmentTopicError(error));
  }
}

function* updateAssessmentsTopicWorkerSaga(
  value: IFluxStandardAction<{ topicId: string; obj: LmsPtsTopicDataModel }>
) {
  const { payload } = value;
  try {
    let response: LmsPtsTopicResponseModel = yield call(
      LmsAssessmentsRepository.updateAssessmentsTopic,
      payload
    );
    yield put(LmsAssessmentActions.updateAssessmentTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "updateAssessmentsTopicWorkerSaga");

    yield put(LmsAssessmentActions.updateAssessmentTopicError(error));
  }
}

function* deleteAssessmentsTopicWorkerSaga(value: IFluxStandardAction<{ topicId: string }>) {
  const { payload } = value;
  try {
    let response: LmsPtsTopicResponseModel = yield call(
      LmsAssessmentsRepository.deleteAssessmentsTopic,
      payload
    );
    yield put(LmsAssessmentActions.deleteAssessmentTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "deleteAssessmentsTopicWorkerSaga");

    yield put(LmsAssessmentActions.deleteAssessmentTopicError(error));
  }
}

function* postAssessmentsCheckListWorkerSaga(value: IFluxStandardAction<LmsPtsCheckListDataModel>) {
  const { payload } = value;
  try {
    let response: LmsPtsCheckListResponseModel = yield call(
      LmsAssessmentsRepository.postAssessmentsCheckList,
      payload
    );
    yield put(LmsAssessmentActions.postAssessmentCheckListSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "postAssessmentsCheckListWorkerSaga");

    yield put(LmsAssessmentActions.postAssessmentCheckListError(error));
  }
}

function* updateAssessmentsCheckListWorkerSaga(
  value: IFluxStandardAction<{ checkListId: string; obj: LmsPtsCheckListDataModel }>
) {
  const { payload } = value;
  try {
    let response: LmsPtsCheckListResponseModel = yield call(
      LmsAssessmentsRepository.updateAssessmentsCheckList,
      payload
    );
    yield put(LmsAssessmentActions.updateAssessmentCheckListSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "updateAssessmentsCheckListWorkerSaga");

    yield put(LmsAssessmentActions.updateAssessmentCheckListError(error));
  }
}

function* deleteAssessmentsCheckListWorkerSaga(
  value: IFluxStandardAction<{ checkListId: string; topicId: string }>
) {
  const { payload } = value;
  try {
    let response: LmsPtsCheckListResponseModel = yield call(
      LmsAssessmentsRepository.deleteAssessmentsCheckList,
      { checkListId: payload.checkListId }
    );
    yield put(LmsAssessmentActions.deleteAssessmentCheckListSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "deleteLmsCheckListWorkerSaga");

    yield put(LmsAssessmentActions.deleteAssessmentCheckListError(error));
  }
}

function* getAssessmentTheoryDataWorkerSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  const { payload } = value;
  try {
    let response: LmsAssessmentTheoryDataModel[] = yield call(
      LmsAssessmentsRepository.getAssessmentTheoryData,
      payload
    );
    yield put(LmsAssessmentActions.getLmsAssessmentTheoryDataSuccess(response));
  } catch (error) {
    Logger.error("manageLms-assessments", error, "getAssessmentTheoryDataWorkerSaga");

    yield put(LmsAssessmentActions.getLmsAssessmentTheoryDataError(error));
  }
}

export default function* lmsAssessmentsWatcherSaga() {
  yield takeLatest(
    MANAGELMS.assessments.GET_MODULE_ASSESSMENTS_PRACTICAL_DATA.LOADING,
    getLmsAssessmentsPracticalWorkerSaga
  );
  yield takeLatest(
    MANAGELMS.assessments.POST_ASSESSMENTS_TOPIC.LOADING,
    postAssessmentsTopicWorkerSaga
  );
  yield takeLatest(
    MANAGELMS.assessments.UPDATE_ASSESSMENTS_TOPIC.LOADING,
    updateAssessmentsTopicWorkerSaga
  );
  yield takeLatest(
    MANAGELMS.assessments.DELETE_ASSESSMENTS_TOPIC.LOADING,
    deleteAssessmentsTopicWorkerSaga
  );
  yield takeLatest(
    MANAGELMS.assessments.POST_ASSESSMENTS_CHECKLIST.LOADING,
    postAssessmentsCheckListWorkerSaga
  );
  yield takeLatest(
    MANAGELMS.assessments.UPDATE_ASSESSMENTS_CHECKLIST.LOADING,
    updateAssessmentsCheckListWorkerSaga
  );
  yield takeLatest(
    MANAGELMS.assessments.DELETE_ASSESSMENTS_CHECKLIST.LOADING,
    deleteAssessmentsCheckListWorkerSaga
  );
  yield takeLatest(
    MANAGELMS.assessments.GET_LMS_ASSESSMENTS_THEORY_DATA.LOADING,
    getAssessmentTheoryDataWorkerSaga
  );
}
