import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import { mobileEndPoints } from "@timhortons/common/src/constants/endPoints";
import {
  LmsPTSCheckListModel,
  LmsPTSCheckListRequestDataModel,
  LmsPTSCheckListUnselectModel,
  LmsPTSDataDtoModel,
  LmsPTSGetRequestDataModel
} from "@timhortons/common/src/models/userLms/userLmsPTS";

class UserLmsPTSRepository {
  getUserLmsPTSData = async (params: LmsPTSGetRequestDataModel): Promise<LmsPTSDataDtoModel[]> => {
    const { employeeId, moduleId } = params;
    let response: LmsPTSDataDtoModel[] = await ApiClient.get(
      mobileEndPoints.lmsPTS.getPTSData(employeeId, moduleId),
      {},
      LMSKeys.LMS_USER
    );
    return response;
  };
  ptsCheckListSelect = async (
    params: LmsPTSCheckListRequestDataModel
  ): Promise<LmsPTSCheckListModel> => {
    const { topicId } = params;
    let response: LmsPTSCheckListModel = await ApiClient.post(
      mobileEndPoints.lmsPTS.ptsCheckListSelect(),
      null,
      { ...params },
      LMSKeys.LMS_USER
    );
    return { ...response, topicId };
  };
  ptsCheckListUnSelect = async (
    params: LmsPTSCheckListRequestDataModel
  ): Promise<LmsPTSCheckListUnselectModel> => {
    const { employeeId, publishChecklistId, topicId } = params;
    let response: any = await ApiClient.delete(
      mobileEndPoints.lmsPTS.ptsCheckListUnSelect(employeeId, publishChecklistId),
      null,
      {},
      LMSKeys.LMS_USER
    );
    return {
      ...response,
      topicId,
      checklistId: publishChecklistId
    };
  };
}

const userLmsPTSRepository = new UserLmsPTSRepository();
export { userLmsPTSRepository as UserLmsPTSRepository };
