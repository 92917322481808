import {
  LMSGetQuizTableModel,
  LMSPostQuizResponseModel
} from "@timhortons/common/src/models/userLms/userLms";
import { UserLMS } from "../actions/actionTypes";

export interface ILmsMobileQuizLoading {
  getTicToeQuiz: boolean;
  getDragDropQuiz: boolean;
  submitQuiz: boolean;
  submitMtfDnd: boolean;
}

export interface ILmsMobileQuizError {
  getTicToeQuiz: string;
  getDragDropQuiz: string;
  submitQuiz: string;
  submitMtfDnd: string;
}

export interface ILmsMobileQuizState {
  loading: ILmsMobileQuizLoading;
  error: ILmsMobileQuizError;
  getTicTacToeQuizDetails: null;
  getDragDropQuizDetails: LMSGetQuizTableModel;
  submitQuiz: null;
  submitMtfDnd: LMSPostQuizResponseModel;
}

export const quizInitialState: ILmsMobileQuizState = {
  getTicTacToeQuizDetails: null,
  submitQuiz: null,
  getDragDropQuizDetails: null,
  submitMtfDnd: null,
  loading: {
    getTicToeQuiz: false,
    submitQuiz: false,
    getDragDropQuiz: false,
    submitMtfDnd: false
  },
  error: {
    getTicToeQuiz: null,
    submitQuiz: null,
    getDragDropQuiz: null,
    submitMtfDnd: null
  }
};

export const quizMobileReducer = (
  state = quizInitialState,
  action: { payload: any; type: string }
): ILmsMobileQuizState => {
  switch (action.type) {
    case UserLMS.GET_TIC_TAC_TOE_DETAILS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTicToeQuiz: true
        }
      };

    case UserLMS.GET_TIC_TAC_TOE_DETAILS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTicToeQuiz: false
        },
        getTicTacToeQuizDetails: action.payload
      };

    case UserLMS.GET_TIC_TAC_TOE_DETAILS.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          getTicToeQuiz: action.payload
        }
      };

    case UserLMS.SUBMIT_TIC_TAC_TOE_QUIZ.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitQuiz: true
        }
      };

    case UserLMS.SUBMIT_TIC_TAC_TOE_QUIZ.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitQuiz: false
        },
        submitQuiz: action.payload
      };

    case UserLMS.SUBMIT_TIC_TAC_TOE_QUIZ.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitQuiz: false
        },
        error: {
          ...state.error,
          submitQuiz: action.payload
        }
      };

    case UserLMS.GET_DRAG_DROP_DETAILS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDragDropQuiz: true
        },
        error: {
          ...state.error,
          getDragDropQuiz: action.payload
        }
      };

    case UserLMS.GET_DRAG_DROP_DETAILS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDragDropQuiz: false
        },
        getDragDropQuizDetails: action.payload
      };

    case UserLMS.GET_DRAG_DROP_DETAILS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDragDropQuiz: false
        },
        error: {
          ...state.error,
          getDragDropQuiz: action.payload
        }
      };

    case UserLMS.SUBMIT_MTFDND_QUIZ.LOADING:
      return {
        ...state,
        loading: { ...state.loading, submitMtfDnd: true },
        error: { ...state.error, submitMtfDnd: null }
      };
    case UserLMS.SUBMIT_MTFDND_QUIZ.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, submitMtfDnd: false },
        error: { ...state.error, submitMtfDnd: null },
        submitMtfDnd: action.payload
      };
    case UserLMS.SUBMIT_MTFDND_QUIZ.ERROR:
      return {
        ...state,
        loading: { ...state.loading, submitMtfDnd: false },
        error: { ...state.error, submitMtfDnd: action.payload }
      };
    case UserLMS.RESET_MTF_DND_QUIZ:
      return {
        ...state,
        error: {
          ...state.error,
          submitMtfDnd: null
        },
        submitMtfDnd: null
      };
    case UserLMS.RESET_QUIZ_RESPONSE.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading
        },
        error: {
          ...state.error,
          submitMtfDnd: null,
          submitQuiz: null
        }
      };
    default:
      return state;
  }
};
