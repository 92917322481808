import React from "react";
import { View, Text as RNText } from "react-native";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import FlatList from "@timhortons/common/src/components/atoms/list";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { TabObject } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import { ApproveDropdown } from "@timhortons/common/src/components/organisms/approveDropdown";
import { styles } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/tabContentStyles";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";

interface IProps {
  object: any;
  translate: any;
  isShiftOver?: boolean;
  dropDownHandler?: Function;
  loading?: boolean;
  error?: string;
}
export enum Decision {
  resolved = "Resolved",
  notResolved = "Not Resolved"
}
export default function TimeTempActionTab(props: IProps) {
  const { object, translate, isShiftOver, dropDownHandler, loading, error } = props;
  const selectDropDownHandler = (value: any, item: any, method: string) => {
    dropDownHandler(value, item.uniqueItemId, method);
  };
  const renderItem = (item: TabObject) => {
    return (
      <>
        <View style={styles.titleContainer}>
          <View style={styles.overallContainer}>
            <View style={styles.indexContainer}>
              <Text
                textSize={TextSize.Regular}
                fontWeight={FontWeight.Regular}
                testId={testIds.logs.productItemId}
                textStyle={styles.productTemp}
              >
                0{item.id}
              </Text>
            </View>
            <View style={styles.contentContainer}>
              <View style={styles.titleContainer}>
                <Text
                  textSize={TextSize.Regular}
                  fontWeight={FontWeight.Regular}
                  testId={testIds.logs.productItemTitle}
                  textStyle={styles.title}
                >
                  {item.title}
                </Text>
              </View>
              <View style={styles.dataContainer}>
                <View
                  style={
                    item.isValid === true
                      ? styles.inRangeStyles
                      : (item.isValid === false && styles.outOfRangeStyles) || styles.loading
                  }
                >
                  <Text
                    textStyle={{ alignSelf: "center" }}
                    textSize={TextSize.Regular}
                    fontWeight={FontWeight.Regular}
                    testId={testIds.logs.tempDisplay}
                  >
                    {item.data === "" ? "-" : `${item.data} ${item.degree}`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {item.comment !== "" && item.comment && (
            <>
              <View style={styles.commentContainer}>
                <Icon name={IconNames.askTellFilled} customStyle={styles.commentHeaderIconStyle} />
                <Text
                  fontWeight={FontWeight.SemiBold}
                  textSize={TextSize.ExtraRegular}
                  testId="a"
                  textStyle={styles.commentHeaderTextStyle}
                >
                  {item.isComment === true ? translate("Comment") : translate("reason")}
                </Text>
              </View>
              <View style={styles.commentText}>
                <Text
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.ExtraRegular}
                  testId="a"
                  textStyle={styles.comment}
                >
                  {item.comment}
                </Text>
              </View>
            </>
          )}
          {!isShiftOver ? (
            <RNText style={styles.approveDropdown}>
              <ApproveDropdown
                handleCallBack={(value: any) => {
                  selectDropDownHandler(value, item, item.method);
                }}
                defaultValue={item.decision === Decision.resolved}
              />
            </RNText>
          ) : (
            item.decision !== "" && (
              <View style={{ flexDirection: "row", marginHorizontal: 75, marginBottom: 24 }}>
                <Text
                  fontWeight={FontWeight.Bold}
                  textSize={TextSize.ExtraRegular}
                  testId="a"
                  textStyle={{ color: colorPallete.grey2 }}
                >
                  {translate("decision")}
                </Text>

                <View
                  style={{
                    borderRadius: 8,
                    marginHorizontal: 12,
                    justifyContent: "center",
                    backgroundColor:
                      item.decision === Decision.resolved
                        ? colorPallete.variant8
                        : colorPallete.variant9
                  }}
                >
                  <Text
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.FineRegular}
                    testId="a"
                    textStyle={{
                      paddingHorizontal: 16,
                      color:
                        item.decision === Decision.resolved
                          ? colorPallete.green1
                          : colorPallete.red6
                    }}
                  >
                    {item.decision}
                  </Text>
                </View>
              </View>
            )
          )}
        </View>
      </>
    );
  };
  const getRenderView = () => {
    if (error) {
      return <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />;
    } else if (loading) {
      return <Loader type="component" />;
    } else if (object && object.length === 0) {
      return <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />;
    } else {
      return (
        <>
          <FlatList
            listData={object}
            extractor={(item) => item.title.toString()}
            listRenderer={(item: TabObject) => renderItem(item)}
            seperator={() => (
              <View style={styles.horizontalDivider}>
                <Divider orientation={Orientations.Horizontal} dividerStyle={styles.divider} />
              </View>
            )}
          />
        </>
      );
    }
  };
  return getRenderView();
}
