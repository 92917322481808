import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { StationReadinessActions } from "@timhortons/common/src/redux/logs/actions/mainLogs/stationReadiness";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";
import {
  SRSubCategoryItemResponseModel,
  StationReadSubCategoryModel
} from "@timhortons/common/src/models/logs/mainLogs/stationReadiness";
import {
  LogShiftModel,
  WeeklyCommentDataModel,
  WeeklyCommentModel
} from "@timhortons/common/src/models/logs/mainLogs";
import {
  ILogShift,
  IProdTempCategories,
  IStationReadCatgeories
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";

function* getCategoryWorkerSaga(
  value: IFluxStandardAction<{ actionName: string; storeCode: number; date: string; label: string }>
) {
  const { payload } = value;
  try {
    let data: IStationReadCatgeories[] = yield call(
      MainLogsRepository.getStationReadCategories,
      payload
    );
    yield put(StationReadinessActions.getStationReadCategorySuccess(data));
  } catch (error) {
    Logger.error("mainLogs-stationRead", error, "getCategoryWorkerSaga");

    yield put(StationReadinessActions.getStationReadCategoryError(error));
  }
}
function* getSubCategoryWorkerSaga(value: IFluxStandardAction<IProdTempCategories>) {
  const { payload } = value;
  try {
    let data: StationReadSubCategoryModel[] = yield call(
      MainLogsRepository.getStationReadSubCategory,
      payload
    );
    yield put(StationReadinessActions.getStationReadSubCategorySuccess(data));
  } catch (error) {
    Logger.error("mainLogs-stationRead", error, "getSubCategoryWorkerSaga");

    yield put(StationReadinessActions.getStationReadSubCategoryError(error));
  }
}
function* getSubCategoryItemsWorkerSaga(
  value: IFluxStandardAction<{
    categoryId: string;
    label: string;
    storeCode: string;
    date: string;
  }>
) {
  const { payload } = value;
  try {
    let data: SRSubCategoryItemResponseModel[] = yield call(
      MainLogsRepository.getSRSubCategoryValue,
      payload
    );
    yield put(
      StationReadinessActions.getSReadSubCategoryItemsSuccess({
        apiRes: data,
        metaData: { categoryId: payload.categoryId }
      })
    );
  } catch (error) {
    Logger.error("mainLogs-stationRead", error, "getSubCategoryItemsWorkerSaga");

    yield put(StationReadinessActions.getSReadSubCategoryItemsError(error));
  }
}
function* postStationReadinessItems(value: IFluxStandardAction<WeeklyCommentModel>) {
  const { payload } = value;
  try {
    const response: WeeklyCommentDataModel = yield call(
      MainLogsRepository.postStationReadinessItems,
      payload
    );
    yield put(
      StationReadinessActions.postStationReadinessItemsSuccess({
        ...response,
        questionId: payload.questionId,
        productId: payload.productId,
        categoryId: payload.categoryId
      })
    );
  } catch (error) {
    Logger.error("mainLogs-stationRead", error, "postStationReadinessItems");

    yield put(StationReadinessActions.postStationReadinessItemsError(error));
  }
}

function* getSRShiftsWorkerSaga(value: IFluxStandardAction<ILogShift>) {
  const { payload } = value;
  try {
    let data: LogShiftModel[] = yield call(MainLogsRepository.getLogShift, payload);
    yield put(StationReadinessActions.getSRShiftsSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-stationRead", error, "getSRShiftsWorkerSaga");

    yield put(StationReadinessActions.getSRShiftsError(error));
  }
}

function* stationReadWatcherSaga() {
  yield takeLatest(LOGS.MainLogs.StationRead.GET_CATEGORIES.LOADING, getCategoryWorkerSaga);
  yield takeLatest(LOGS.MainLogs.StationRead.GET_SUBCATEGORIES.LOADING, getSubCategoryWorkerSaga);
  yield takeLatest(
    LOGS.MainLogs.StationRead.POST_STATION_READINESS_ITEMS.LOADING,
    postStationReadinessItems
  );
  yield takeLatest(
    LOGS.MainLogs.StationRead.GET_SUBCATEGORIES_ITEMS.LOADING,
    getSubCategoryItemsWorkerSaga
  );
  yield takeLatest(LOGS.MainLogs.StationRead.GET_SR_SHIFTS.LOADING, getSRShiftsWorkerSaga);
}
export default stationReadWatcherSaga;
