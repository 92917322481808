import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import AreaManagerAppealCard from "@timhortons/common/src/modules/rev/components/dashboards/appeals/organisms/inProcessAppealTable/appealCard";
import Tableheader from "@timhortons/common/src/modules/rev/components/dashboards/appeals/organisms/inProcessAppealTable/tableHeader";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import { AppealTable } from "@timhortons/common/src/modules/rev/components/dashboards/appeals";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { IAppealsError, IAppealsLoading } from "@timhortons/common/src/redux/rev/reducers/appeals";
import { InProcesssClosedTable } from "@timhortons/common/src/models/rev/appeals";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";

interface IProps {
  translate: any;
  currentTable: AppealTable;
  appealsTableData: InProcesssClosedTable[];
  loading: IAppealsLoading;
  isIndividualLogin: boolean;
  setAppealDecision: Function;
  userDetails: IUserDetails;
  error: IAppealsError;
}

export default function InProcessAppealTable(props: IProps): ReactElement {
  const {
    userDetails,
    setAppealDecision,
    translate,
    currentTable,
    appealsTableData,
    loading,
    isIndividualLogin,
    error
  } = props;
  const appealTableHeader = [
    {
      name: translate("storeId")
    },
    {
      name: translate("storeNameTable")
    },
    {
      name: translate("auditDate")
    },
    {
      name: isIndividualLogin
        ? translate("appealSubmittedBy")
        : currentTable === AppealTable.inProcess
        ? translate("appealSubmittedBy")
        : translate("appealApprovedBy")
    }
  ];
  const individualAccessAppealTableHeader = [
    ...appealTableHeader.slice(0, 2),
    { name: translate("appealDate") },
    appealTableHeader[3],
    { name: translate("view") }
  ];

  const getIndividualHeaderComp = (headerItem: any, index: number): ReactElement => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.grey1 }}
        cellStyle={styles.headerCellStyle}
      />
    );
  };

  const generateInitialValues = (value: number) => {
    let initState = [];
    for (let i = 0; i < value; i++) {
      initState.push({
        view: false
      });
    }
    return initState;
  };
  const [toggleEyeIcon, setToggleEyeIcon] = useState(
    generateInitialValues(appealsTableData && appealsTableData.length)
  );
  const setToggleCard = (index: number) => {
    let temp = [...toggleEyeIcon];
    temp.forEach((val, i) => {
      if (i === index) {
        val.view = !val.view;
      }
    });
    setToggleEyeIcon(temp);
  };

  useEffect(() => {
    setToggleEyeIcon(generateInitialValues(appealsTableData && appealsTableData.length));
  }, [currentTable, appealsTableData]);

  return (
    <Table>
      <Row header rowStyle={styles.tableRowStyle}>
        {isIndividualLogin
          ? individualAccessAppealTableHeader.map((headerItem, index) =>
              getIndividualHeaderComp(headerItem, index)
            )
          : appealTableHeader.map((headerItem, index) =>
              getIndividualHeaderComp(headerItem, index)
            )}
      </Row>
      {error.filteredData ? (
        <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
      ) : loading.closedData || loading.filteredData ? (
        <Loader type="component" />
      ) : appealsTableData && appealsTableData.length > 0 ? (
        <View>
          {appealsTableData &&
            appealsTableData.map((data, index) => {
              return currentTable === AppealTable.inProcess ||
                currentTable === AppealTable.closedAppeal ? (
                <View key={index}>
                  <Tableheader
                    cellStyle={styles.headerCellStyle}
                    data={data}
                    translate={translate}
                    isIndividualLogin={isIndividualLogin}
                    index={index}
                    viewIconStyle={styles.viewIconStyles}
                    iconOnPress={() => setToggleCard(index)}
                    toggleIcon={toggleEyeIcon}
                    currentTable={currentTable}
                  />

                  {((index < toggleEyeIcon.length && toggleEyeIcon && toggleEyeIcon[index].view) ||
                    !isIndividualLogin) &&
                    data.items.map((item, itemKey) => (
                      <Row key={itemKey} rowStyle={styles.appealCardRowStyle}>
                        {
                          <AreaManagerAppealCard
                            userDetails={userDetails}
                            setAppealDecision={setAppealDecision}
                            isIndividualLogin={isIndividualLogin}
                            key={itemKey}
                            appealCardData={item}
                            translate={translate}
                            currentTable={currentTable}
                          />
                        }
                      </Row>
                    ))}
                </View>
              ) : null;
            })}
        </View>
      ) : (
        <Jumbotron type={ErrorType.NoDataFound} title={translate("noItemAppealed")} />
      )}
    </Table>
  );
}

const styles = StyleSheet.create({
  appealCardRowStyle: {
    paddingHorizontal: 30,
    paddingBottom: 24
  },
  headerCellStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 30 },
      ltr: { marginStart: 30 }
    }),
    textAlign: "center"
  },
  viewIconStyles: {
    justifyContent: "center"
  },
  tableRowStyle: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  }
});
