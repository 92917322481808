import { UserLMS } from "../actions/actionTypes";

export interface ILmsMobileAssessmentLoading {
  getAssessmentQuestions: boolean;
  submitAssessment: boolean;
  exitAssessment: boolean;
  getAssessmentResult: boolean;
  deleteAssessment: boolean;
}

export interface ILmsMobileAssessmentError {
  getAssessmentQuestions: string;
  submitAssessment: string;
  exitAssessment: string;
  getAssessmentResult: string;
  deleteAssessment: string;
}

export interface ILmsMobileAssessmentState {
  loading: ILmsMobileAssessmentLoading;
  error: ILmsMobileAssessmentError;
  getAssessmentQuestions: null;
  submitAssessment: "";
  exitAssessment: null;
  getAssessmentResult: null;
  deleteAssessment: null;
}

export const assessmentInitialState: ILmsMobileAssessmentState = {
  getAssessmentQuestions: null,
  submitAssessment: null,
  exitAssessment: null,
  getAssessmentResult: null,
  deleteAssessment: null,
  loading: {
    getAssessmentQuestions: false,
    submitAssessment: false,
    exitAssessment: false,
    getAssessmentResult: false,
    deleteAssessment: false
  },
  error: {
    getAssessmentQuestions: null,
    submitAssessment: null,
    exitAssessment: null,
    getAssessmentResult: null,
    deleteAssessment: null
  }
};

export const assessmentReducer = (
  state = assessmentInitialState,
  action: { payload: any; type: string }
): ILmsMobileAssessmentState => {
  switch (action.type) {
    case UserLMS.GET_THEORITICAL_ASSESSMENTS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getAssessmentQuestions: true
        },
        error: {
          ...state.error,
          getAssessmentQuestions: null
        }
      };
    case UserLMS.GET_THEORITICAL_ASSESSMENTS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getAssessmentQuestions: false
        },
        error: {
          ...state.error,
          getAssessmentQuestions: null
        },
        getAssessmentQuestions: action.payload
      };
    case UserLMS.GET_THEORITICAL_ASSESSMENTS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getAssessmentQuestions: false
        },
        error: {
          ...state.error,
          getAssessmentQuestions: action.payload
        }
      };
    case UserLMS.SUBMIT_ASSESSMENT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitAssessment: true
        },
        error: {
          ...state.error,
          submitAssessment: null
        }
      };
    case UserLMS.SUBMIT_ASSESSMENT.SUCCESS:
      let result;
      if (action.payload === null) {
        result = "CONTINUE";
      } else {
        result = action.payload;
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          submitAssessment: false
        },
        error: {
          ...state.error,
          submitAssessment: null
        },
        submitAssessment: result
      };

    case UserLMS.SUBMIT_ASSESSMENT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          submitAssessment: false
        },
        error: {
          ...state.error,
          submitAssessment: action.payload
        }
      };

    case UserLMS.EXIT_ASSESSMENT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          exitAssessment: true
        },
        error: {
          ...state.error,
          exitAssessment: null
        }
      };
    case UserLMS.EXIT_ASSESSMENT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          exitAssessment: false
        },
        error: {
          ...state.error,
          exitAssessment: null
        },
        exitAssessment: action.payload
      };
    case UserLMS.EXIT_ASSESSMENT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          exitAssessment: false
        },
        error: {
          ...state.error,
          exitAssessment: action.payload
        }
      };

    case UserLMS.RESET_SUBMIT_ASSESSMENT.LOADING:
      return {
        ...state,
        submitAssessment: null
      };
    case UserLMS.RESET_ASSESMENT_ERROR.LOADING:
      return {
        ...state,
        error: {
          ...state.error,
          submitAssessment: null
        }
      };
    case UserLMS.GET_ASSESSMENT_RESULT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getAssessmentResult: true
        },
        error: {
          ...state.error,
          getAssessmentResult: null
        }
      };
    case UserLMS.GET_ASSESSMENT_RESULT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getAssessmentResult: false
        },
        error: {
          ...state.error,
          getAssessmentResult: null
        },
        getAssessmentResult: action.payload
      };
    case UserLMS.GET_ASSESSMENT_RESULT.ERROR:
      let error = "";
      if (action.payload === null) {
        error = "error";
      } else {
        error = action.payload;
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          getAssessmentResult: false
        },
        error: {
          ...state.error,
          getAssessmentResult: error
        }
      };
    case UserLMS.RESET_GET_ASSESSMENT_ERROR:
      return {
        ...state,
        getAssessmentQuestions: null,
        error: {
          ...state.error,
          getAssessmentQuestions: null
        }
      };
    case UserLMS.DELETE_ASSESSMENT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteAssessment: true
        },
        error: {
          ...state.error,
          deleteAssessment: null
        }
      };
    case UserLMS.DELETE_ASSESSMENT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteAssessment: false
        },
        error: {
          ...state.error,
          deleteAssessment: null
        },
        deleteAssessment: action.payload
      };
    case UserLMS.RESET_ASSESSMENT_DATA.LOADING:
      return {
        ...state,
        getAssessmentResult: null
      };
    case UserLMS.DELETE_ASSESSMENT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteAssessment: false
        },
        error: {
          ...state.error,
          deleteAssessment: action.payload
        }
      };
    default:
      return state;
  }
};
