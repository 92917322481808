import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import {
  CheckAuth,
  IGetManagerReviewPayload
} from "@timhortons/common/src/models/productionSheet/managerReview";

export const getManagerReviewProductionSheet = (
  data: IGetManagerReviewPayload
): IFluxStandardAction<IGetManagerReviewPayload> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.GET_MANAGER_REVIEW_PRODUCTION_SHEET.LOADING,
    payload: data
  };
};
export const getManagerReviewProductionSheetSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.GET_MANAGER_REVIEW_PRODUCTION_SHEET.SUCCESS,
    payload: data
  };
};
export const getManagerReviewProductionSheetError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.GET_MANAGER_REVIEW_PRODUCTION_SHEET.ERROR,
    payload: error
  };
};
export const updateMRItem = (data: {
  idList: Array<string>;
  empId: string;
  dateTime: string;
  rowId: any;
}): IFluxStandardAction<{
  idList: Array<string>;
  empId: string;
  dateTime: string;
  rowId: any;
}> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.UPDATE_MANAGE_REVIEW_ITEM.LOADING,
    payload: data
  };
};
export const updateMRItemSuccess = (data: {
  rowId: any;
}): IFluxStandardAction<{
  rowId: any;
}> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.UPDATE_MANAGE_REVIEW_ITEM.SUCCESS,
    payload: data
  };
};
export const updateMRItemError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.UPDATE_MANAGE_REVIEW_ITEM.ERROR,
    payload: error
  };
};
export const checkAccessToStore = (data: {
  storeCode: number;
  empid: string;
}): IFluxStandardAction<{ storeCode: number; empid: string }> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.CHECK_ACCESS_TO_STORE.LOADING,
    payload: data
  };
};
export const checkAccessToStoreSuccess = (data: CheckAuth): IFluxStandardAction<CheckAuth> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.CHECK_ACCESS_TO_STORE.SUCCESS,
    payload: data
  };
};
export const checkAccessToStoreError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.CHECK_ACCESS_TO_STORE.ERROR,
    payload: error
  };
};
export const resetMRErrorSuccessModal = (): IFluxStandardAction<{}> => {
  return {
    type: PRODUCTION_SHEET.ManagerReview.RESET_MRModals,
    payload: null
  };
};
