import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "lp";
const LP = {
  CHECK_LP_AUDIT_AUTH_ID: ActionHelper.actionTypesCreator(prefix, "CHECK_LP_AUDIT_AUTH_ID"),
  GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME"
  ),
  RESET_LP_STORE_LIST_BYSTORE_CODENAME: "LP/RESET_LP_STORE_LIST_BYSTORE_CODENAME",
  GET_LP_PREVIOUS_AUDIT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_PREVIOUS_AUDIT_DATA"),
  DELETE_LP_AUDIT: ActionHelper.actionTypesCreator(prefix, "DELETE_LP_AUDIT"),
  RESUME_AUDIT: ActionHelper.actionTypesCreator(prefix, "RESUME_AUDIT"),
  GET_LP_SUB_CATEGORY_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_SUB_CATEGORY_DATA"),
  GET_TIPPING_POINTS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_TIPPING_POINTS_DATA"),
  LP_UPDATE_COMPLETION: ActionHelper.actionTypesCreator(prefix, "LP_UPDATE_COMPLETION"),
  LP_ANSWER_YES_NO_NA_DATA: ActionHelper.actionTypesCreator(prefix, "LP_ANSWER_YES_NO_NA_DATA"),
  UPDATE_LP_CATEGORY_DATA: ActionHelper.actionTypesCreator(prefix, "UPDATE_LP_CATEGORY_DATA"),
  GET_LP_OBSERVATION_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_OBSERVATION_DATA"),
  LP_POST_OBSERVATION_DATA: ActionHelper.actionTypesCreator(prefix, "LP_POST_OBSERVATION_DATA"),
  UPDATE_LP_COMMENT_DATA: ActionHelper.actionTypesCreator(prefix, "UPDATE_LP_COMMENT_DATA"),
  POST_LP_COMMENT_DATA: ActionHelper.actionTypesCreator(prefix, "POST_LP_COMMENT_DATA"),
  GET_LP_COMMENT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_COMMENT_DATA"),
  LP_CATEGORY_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_CATEGORY_DATA"),
  RESET_LP_AUTH_ID: "LP/CHECK_AUTH_ID/RESET",
  RESET_LP_ACTION_PLAN: "LP/RESET_LP_ACTION_PLAN/RESET",
  SUBMIT_LP_AUDIT: ActionHelper.actionTypesCreator(prefix, "SUBMIT_LP_AUDIT"),
  PREPARE_LP_REPORT: ActionHelper.actionTypesCreator(prefix, "PREPARE_LP_REPORT"),
  LP_REPORTS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_REPORTS_DATA"),
  LP_ACKNOWLEDGEMENT_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_ACKNOWLEDGEMENT_DATA"),
  POST_LP_ACKNOWLEDGEMENT: ActionHelper.actionTypesCreator(prefix, "POST_LP_ACKNOWLEDGEMENT"),
  RESET_AUTH_ID: "LP/CHECK_LP_AUTH_ID/RESET",
  RESET_REPORT_ID: "REV/CHECK_REPORT_ID/RESET",
  LP_GET_STORE_ACCESS_SUMMARY_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "LP_GET_STORE_ACCESS_SUMMARY_DATA"
  ),
  GET_LP_REPORT_TABLE_DATA: ActionHelper.actionTypesCreator(prefix, "GET_REPORT_TABLE_DATA"),
  LP_GET_SELECTED_MONTH_FILTER: ActionHelper.actionTypesCreator(
    prefix,
    "LP_GET_SELECTED_MONTH_FILTER"
  ),
  LP_GET_SELECTED_COUNTRY_FILTER: ActionHelper.actionTypesCreator(
    prefix,
    "LP_GET_SELECTED_COUNTRY_FILTER"
  ),
  GET_LP_INDIVIDUAL_SUMMARY_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LP_INDIVIDUAL_SUMMARY_DATA"
  ),
  GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA"
  ),
  GET_COUNTRY_TOTAL_AUDITS: ActionHelper.actionTypesCreator(prefix, "GET_COUNTRY_TOTAL_AUDITS"),
  GET_LP_AUDITOR_TOTAL_AUDITS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LP_AUDITOR_TOTAL_AUDITS"
  ),
  GET_AREAMANAGER_TOTAL_AUDITS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_AREAMANAGER_TOTAL_AUDITS"
  ),
  GET_LP_STORE_ACCESS_OPPORTUNITY_DATA: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LP_STORE_ACCESS_OPPORTUNITY_DATA"
  ),
  GET_SELECTED_MONTH_FILTER: ActionHelper.actionTypesCreator(prefix, "GET_SELECTED_MONTH_FILTER"),
  GET_LP_APPEALS_COUNT: ActionHelper.actionTypesCreator(prefix, "GET_LP_APPEALS_COUNT"),
  GET_LP_NEW_APPEALS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_NEW_APPEALS_DATA"),
  ActionPlans: {
    GET_LP_ACTION_PLANS: ActionHelper.actionTypesCreator(prefix, "GET_LP_ACTION_PLANS"),
    GET_LP_SELECTED_FILTER_DATA: " GET_LP_SELECTED_FILTER_DATA",
    GET_LP_FILTER_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_FILTER_DATA"),
    GET_LP_ACTION_PLAN_HEADER_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LP_ACTION_PLAN_HEADER_DATA"
    ),
    UPDATE_LP_ACTION_PLAN_STATUS: ActionHelper.actionTypesCreator(
      prefix,
      "UPDATE_LP_ACTION_PLAN_STATUS"
    ),
    UPDATE_ACTION_PLAN_STATUS: ActionHelper.actionTypesCreator(prefix, "UPDATE_ACTION_PLAN_STATUS"),
    GET_LP_CATEGORY_STATUS: ActionHelper.actionTypesCreator(prefix, "GET_LP_CATEGORY_STATUS"),
    GET_LP_OWNER_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_OWNER_DATA"),
    GET_LP_CATEGORY_TABLE_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LP_CATEGORY_TABLE_DATA"
    ),
    POST_LP_ACTION_STEPS: ActionHelper.actionTypesCreator(prefix, "POST_LP_ACTION_STEPS"),
    UPDATE_LP_ACTION_STEPS: ActionHelper.actionTypesCreator(prefix, "UPDATE_LP_ACTION_STEPS"),

    GET_LP_ACTION_PLAN_ACCORDION_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LP_ACTION_PLAN_ACCORDION_DATA"
    ),

    RESET_ACTION_PLAN: "RESER_ACTION_PLAN"
  },
  SET_LP_APPEAL_FILTER: ActionHelper.actionTypesCreator(prefix, "SET_LP_APPEAL_FILTER"),
  SET_LP_APPEAL_DECISON_DATA: ActionHelper.actionTypesCreator(prefix, "SET_LP_APPEAL_DECISON_DATA"),
  //GET_LP_APPEALS_COUNT: ActionHelper.actionTypesCreator(prefix, "GET_LP_APPEALS_COUNT"),
  GET_NEW_LP_APPEALS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_NEW_LP_APPEALS_DATA"),
  // GET_IN_PROCESS_LP_APPEALS_DATA: ActionHelper.actionTypesCreator(
  //   prefix,
  //   "GET_IN_PROCESS_LP_APPEALS_DATA "
  // ),
  GET_CLOSED_LP_APPEALS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_CLOSED_LP_APPEALS_DATA"),
  Appeals: {
    RESET_LP_APPEAL_ERROR: "LP/RESET_LP_APPEAL_ERROR",
    SET_LP_APPEAL_FILTER: ActionHelper.actionTypesCreator(prefix, "SET_LP_APPEAL_FILTER"),
    CREATE_LP_NEW_APPEAL: ActionHelper.actionTypesCreator(prefix, "CREATE_LP_NEW_APPEAL"),

    GET_LP_APPEALS_CATEGORY_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LP_APPEALS_CATEGORY_DATA"
    ),
    RESET_APPEAL_AUTH_ID: "LP/RESET_APPEAL_AUTH_ID/RESET",
    GET_LP_IN_PROCESS_APPEALS_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LP_IN_PROCESS_APPEALS_DATA "
    ),
    GET_LP_CLOSED_APPEALS_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "GET_LP_CLOSED_APPEALS_DATA"
    ),
    GET_LP_APPEALS_COUNT: ActionHelper.actionTypesCreator(prefix, "GET_LP_APPEALS_COUNT"),
    GET_LP_NEW_APPEALS_DATA: ActionHelper.actionTypesCreator(prefix, "GET_LP_NEW_APPEALS_DATA"),
    SET_LP_APPEAL_DECISON_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "SET_LP_APPEAL_DECISON_DATA"
    ),
    LP_POST_APPEAL_COMMENT_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "LP_POST_APPEAL_COMMENT_DATA"
    ),
    LP_GET_APPEAL_ACCORDION_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "LP_GET_APPEAL_ACCORDION_DATA"
    )
  },
  ReportBuilder: {
    RESET_REPORT_BUILDER: "REV/RESET_REPORT_BUILDER/RESET",
    GENERATE_REPORT: ActionHelper.actionTypesCreator(prefix, "GENERATE_REPORT"),
    GENERATE_GRADING_REPORT: ActionHelper.actionTypesCreator(prefix, "GENERATE_GRADING_REPORT"),
    DOWNLOAD_GENERATED_REPORT: ActionHelper.actionTypesCreator(prefix, "DOWNLOAD_GENERATED_REPORT"),
    BRAND_STANDARD_TABLE_DATA: ActionHelper.actionTypesCreator(prefix, "BRAND_STANDARD_TABLE_DATA"),
    GENERATE_AREA_OF_IMPROVEMENT_REPORT: ActionHelper.actionTypesCreator(
      prefix,
      "GENERATE_AREA_OF_IMPROVEMENT_REPORT"
    )
  }
};

export { LP };
