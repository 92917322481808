import { Any, JsonObject, JsonProperty } from "json2typescript";
export interface GetAuditsStoreListByCodeOrNamePayload {
  role: string;
  empId: string;
  keyword: string;
  pageNo: number;
  pageSize: number;
}
@JsonObject("AuditStoresListModel")
export class AuditStoresListModel {
  @JsonProperty("name", String)
  value: string = "";
  @JsonProperty("name", String)
  label: string = "";
  @JsonProperty("code", Number)
  code?: number = 0;
}
@JsonObject("AuditStoresListByCodeOrName")
export class AuditStoresListByCodeOrName {
  @JsonProperty("content", [AuditStoresListModel])
  content: AuditStoresListModel[] = null;
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("currentPage", Number, true)
  currentPage?: number = 0;
}

@JsonObject("LpAuditInfoModel")
export class LpAuditInfoModel {
  @JsonProperty("auditType", String)
  auditType: string = null;
  @JsonProperty("auditorName", String)
  auditedBy: string = "";
  @JsonProperty("auditId", Number)
  auditId: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("completed", Boolean)
  isCompleted: boolean = false;
  @JsonProperty("completedPercentage", Number)
  completedPercentage: number = 0;
  @JsonProperty("storeCode", Number, true)
  storeCode?: number = null;
  @JsonProperty("auditInValid", Boolean)
  isAuditInValid: boolean = false;
}
@JsonObject("ResumeLpAuditInfoModel")
export class ResumeLpAuditInfoModel {
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("auditId", Number)
  auditId: number = 0;
  @JsonProperty("auditorId", Number)
  auditorId: number = 0;
  @JsonProperty("storeLocation", String)
  storeName: string = "";
  @JsonProperty("auditedBy", String)
  auditedBy: string = "";
  @JsonProperty("isCompleted", Boolean)
  isCompleted: boolean = null;
  @JsonProperty("completionPercentage", Number)
  completedPercentage: number = 0;
  @JsonProperty("auditType", String)
  auditType: string = null;
}
@JsonObject("LpPreviousAuditDataModel")
export class LpPreviousAuditDataModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("startTime", Date)
  startTime: Date = null;
  @JsonProperty("auditedBy", String)
  auditedBy: string = null;
  @JsonProperty("auditedByName", String)
  auditedByName: string = null;
  @JsonProperty("completedPercentage", Number)
  completedPercentage: number = null;
  @JsonProperty("storeName", String)
  storeName: string = null;
  @JsonProperty("storeCode", Number)
  storeCode: string = null;
}

@JsonObject("LpCategoryListModel")
export class LpCategoryListModel {
  @JsonProperty("categoryId", Number)
  id: number = 0;
  @JsonProperty("categoryName", String)
  title: string = "";
  @JsonProperty("categoryLabel", String)
  label: string = "";
  @JsonProperty("percentageCompleted", Number)
  progress: number = 0;
  @JsonProperty("critical", Boolean) // should be boolean
  isCritical: Boolean = false;
}

@JsonObject("LpCategoryDataModel")
export class LpCategoryDataModel {
  @JsonProperty("categoryList", [LpCategoryListModel])
  categoryList: LpCategoryListModel[];
  @JsonProperty("percentageCompleted", Number)
  completionPercentage: number = 0;
}
@JsonObject("LpTippingPointsDataModel")
export class LpTippingPointsDataModel {
  @JsonProperty("tippingId", Number)
  tippingId: number = 0;
  @JsonProperty("tippingPoint", String)
  tippingPoint: string = "";
}
@JsonObject("LpTippingPointsModel")
export class LpTippingPointsModel {
  @JsonProperty("tippingPoints", [LpTippingPointsDataModel])
  tippingPoints: LpTippingPointsDataModel[];
}
@JsonObject("LpQuestionListModel")
export class LpQuestionListModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("questionLabel", String)
  serialNumber: string = "";
  @JsonProperty("question", String)
  questionName: string = "";
  @JsonProperty("status", String, true)
  options: string = "";
  @JsonProperty("ztStatus", String, true)
  ztStatus: string = "";
  @JsonProperty("storeSpecifics", String, true)
  storeSpecifics?: string = "";
  @JsonProperty("isStoreSpecific", Boolean, true)
  isStoreSpecific?: boolean = false;
}
@JsonObject("LpYesNoDataModel")
export class LpYesNoDataModel {
  @JsonProperty("percentageCompleted", Number)
  completionPercentage: number = 0;
  @JsonProperty("auditId", String)
  auditId: string = "";
  @JsonProperty("percentageCompletedByCategory", Number, true)
  categoryPercentage: number = 0;
}

@JsonObject("LpSubCategoryInfoModel")
export class LpSubCategoryInfoModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("maxPoints", Number)
  points: number = 0;
  @JsonProperty("questions", [LpQuestionListModel])
  questionList: LpQuestionListModel[];
  @JsonProperty("subcategoryLabel", String)
  serialNumber: string = "";
  @JsonProperty("subcategoryName", String)
  title: string = "";
  @JsonProperty("status", Any, true)
  status: any = null;
  @JsonProperty("appealerResponse", Any, true)
  appealerResponse: any = null;
}
@JsonObject("LpSubCategoryModel")
export class LpSubCategoryModel {
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("categoryName", String)
  categoryName: string = "";
  @JsonProperty("subCategories", [LpSubCategoryInfoModel])
  lpSubCategories: LpSubCategoryInfoModel[];
}

@JsonObject("LpObservationDataPointsModel")
export class LpObservationDataPointsModel {
  @JsonProperty("observation", String)
  name: string = "";
  @JsonProperty("observationId", Number)
  key: number = 0;
  @JsonProperty("questionId", Number)
  id: number = 0;
  @JsonProperty("isCheck", Boolean && null)
  selected: boolean = false;
}
export class LpObservationDataModel {
  @JsonProperty("questionId", Number)
  questionId: number = 0;
  @JsonProperty("rejectedDataPoints", [LpObservationDataPointsModel])
  rejectedDataPoints: LpObservationDataPointsModel[] = null;
}
@JsonObject("LpPostObservationSuccessModel")
export class LpPostObservationSuccessModel {
  @JsonProperty("createdAt", String)
  createdAt: string = "";
  @JsonProperty("createdBy", String)
  createdBy: string = "";
  @JsonProperty("updatedAt", String)
  updatedAt: string = "";
  @JsonProperty("id", Number)
  id: number = 0;
}
@JsonObject("PostLpCommentModel")
export class PostLpCommentModel {
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("auditId", Number)
  auditId: number = 0;
  @JsonProperty("questionId", Number)
  questionId: number = 0;
  @JsonProperty("body", FormData)
  body?: FormData = null;
}
@JsonObject("LpFileModel")
export class LpFileModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("signedUrl", String)
  signedUrl: string = "";
  @JsonProperty("blob", Blob, true)
  blob?: Blob = null;
}
@JsonObject("LpCommentDataModel")
export class LpCommentDataModel {
  @JsonProperty("subCategoryId", Number)
  subCategoryId?: number = 0;
  @JsonProperty("auditId", Number)
  auditId?: number = 0;
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("deletedImage", [String], true)
  deletedImage?: string[] = [];
  @JsonProperty("images", [LpFileModel])
  files: LpFileModel[] = [];
  @JsonProperty("questionId", Number)
  questionId: number = 0;
}
