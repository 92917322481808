//made a seperate component for checkbox group used in rev and lp
import React, { ReactElement } from "react";
import { TextStyle, StyleProp, ViewStyle, GestureResponderEvent, View } from "react-native";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { IconProps } from "react-native-vector-icons/Icon";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
interface ICheckboxProps {
  selected: boolean;
  type: CheckboxType;
  id?: any;
  textSize?: TextSize;
  text?: string;
  testId: string;
  textFontWeight?: FontWeight;
  iconSize?: number;
  idStyles?: StyleProp<ViewStyle>;
  iconSelectedColor?: string;
  iconUnselectedColor?: string;
  onPress?: (event?: GestureResponderEvent) => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
}
export enum CheckboxType {
  Square = "Square"
}
interface IIconStyle {
  selected: Record<CheckboxType, IconProps>;
  unselected: Record<CheckboxType, IconProps>;
}
export interface ICheckboxStyle {
  container: ViewStyle;
  icon: IIconStyle;
  text: TextStyle;
}
export const CheckboxLpRev = (props: ICheckboxProps): ReactElement => {
  const {
    idStyles,
    selected = false,
    containerStyle,
    type,
    testId,
    textSize,
    id,
    text,
    textStyle,
    textFontWeight,
    iconStyle,
    onPress,
    iconSize,
    iconSelectedColor,
    iconUnselectedColor,
    disabled = false
  } = props;
  const styles = getStyles();
  const { icon } = styles;
  const { name } = selected ? icon.selected[type] : icon.unselected[type];
  const color = selected
    ? iconSelectedColor ?? icon.selected[type].color
    : iconUnselectedColor ?? icon.unselected[type].color;
  function handleClick(): boolean {
    if (!disabled) {
      onPress && onPress();
    }
    return true;
  }
  return (
    <View testID={`to${testId}`} style={[styles.container, containerStyle]}>
      <Icon
        onPress={() => handleClick()}
        name={name as IconNames}
        size={iconSize ?? 24}
        color={color}
        customStyle={iconStyle}
        testID={`ico${testId}`}
      />
      <Text
        testId={`txt${testId}`}
        textSize={textSize}
        fontWeight={textFontWeight}
        textStyle={[styles.text, idStyles]}
      >
        {id}
      </Text>
      <View
        onStartShouldSetResponder={() => handleClick()}
        style={{ flexDirection: "row", flex: 1 }}
      >
        <Text
          testId={`txt${testId}`}
          textSize={textSize}
          fontWeight={textFontWeight}
          textStyle={[styles.text, textStyle]}
        >
          {text}
        </Text>
      </View>
    </View>
  );
};
CheckboxLpRev.defaultProps = {
  containerStyle: {},
  textStyle: {},
  iconStyle: {},
  iconSize: undefined
};
const getStyles = (): ICheckboxStyle => {
  return {
    icon: {
      selected: {
        [CheckboxType.Square]: {
          color: colorPallete.red1,
          name: IconNames.checkBoxTicked
        }
      },
      unselected: {
        [CheckboxType.Square]: {
          color: colorPallete.grey4,
          name: IconNames.checkBoxUnTicked
        }
      }
    },
    container: {
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
      marginBottom: 16
    },
    text: {
      flexWrap: "wrap"
    }
  };
};
