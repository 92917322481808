import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LMS_REPORTS } from "@timhortons/common/src/redux/lmsReports/actions/actionTypes";
import { AllModulesDataModel } from "@timhortons/common/src/models/lmsReports/lmsReportsModel";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

const getAllModules = (data: {
  trainingType: string;
  pageNumber: number;
  pageSize: number;
  search: string;
  isPracticalStatus: boolean;
}): IFluxStandardAction<{
  trainingType: string;
  pageNumber: number;
  pageSize: number;
  isPracticalStatus: boolean;
  search: string;
}> => {
  return {
    type: LMS_REPORTS.detailedLms.GET_ALL_MODULES.LOADING,
    payload: data
  };
};
const getAllModulesSuccess = (data: any[]): IFluxStandardAction<AllModulesDataModel[]> => {
  return {
    type: LMS_REPORTS.detailedLms.GET_ALL_MODULES.SUCCESS,
    payload: data
  };
};
const getAllModulesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LMS_REPORTS.detailedLms.GET_ALL_MODULES.ERROR,
    payload: error
  };
};
const generateReportBuilder = (data: {
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  moduleSearchKeyword?: string;
  allSelected?: boolean;
  pageNum: number;
  modulesAllSelected: boolean;
  moduleType: string;
  // updateTableName: ComparsionLevel;
  dataType: string;
}): IFluxStandardAction<{
  object: any;
  reportType: string;
  user: IUserDetails;
  moduleSearchKeyword?: string;
  countryCodes?: any;
  allSelected?: boolean;
  modulesAllSelected: boolean;
  moduleType: string;
  pageNum: number;
  // updateTableName: ComparsionLevel;
  dataType: string;
}> => {
  return {
    type: LMS_REPORTS.detailedLms.GENERATE_REPORT.LOADING,
    payload: data
  };
};
const generateReportBuilderSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LMS_REPORTS.detailedLms.GENERATE_REPORT.SUCCESS,
    payload: data
  };
};
const generateReportBuilderError = (data: {
  error: Error;
}): IFluxStandardAction<{ error: Error }> => {
  return {
    type: LMS_REPORTS.detailedLms.GENERATE_REPORT.ERROR,
    payload: data
  };
};
const resetReportBuilder = () => {
  return {
    type: LMS_REPORTS.detailedLms.RESET_REPORT_BUILDER
  };
};

export const DetailedLmsReportsActions = {
  getAllModules,
  getAllModulesSuccess,
  getAllModulesError,
  resetReportBuilder,
  generateReportBuilder,
  generateReportBuilderSuccess,
  generateReportBuilderError
};
