import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import * as React from "react";
import { StyleSheet, View, StyleProp, TextStyle } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";

interface IProps {
  title?: any;
  children?: any;
  cellStyle?: StyleProp<TextStyle>;
  titleStyle?: StyleProp<TextStyle>;
  fontWeight?: FontWeight;
  textSize?: TextSize;
  onPress?: Function;
  numberOfLines?: number;
}

export const Cell = (props: IProps): React.ReactElement => {
  const {
    cellStyle,
    titleStyle,
    title,
    children,
    fontWeight = FontWeight.Regular,
    textSize = TextSize.ExtraRegular,
    onPress,
    numberOfLines
  } = props;
  return (
    <View
      style={[styles.container, cellStyle]}
      onStartShouldSetResponder={() => onPress && onPress()}
    >
      <Text
        fontWeight={fontWeight}
        textSize={textSize}
        testId="txt-table-header"
        textStyle={[styles.text, titleStyle]}
        numberOfLines={numberOfLines}
      >
        {title}
      </Text>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorPallete.white3,
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 18
  },
  text: {}
});
