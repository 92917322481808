import React, { ReactElement, useMemo } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, ImageStyle } from "react-native";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Text, FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import { Image } from "@timhortons/common/src/components/atoms/image";
import {
  completedTick,
  jumbotronImg,
  noDataFound,
  newErr,
  whoops
} from "@timhortons/common/src/assets/images/imageAssets";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";

export enum ErrorType {
  InternalServerError = "errorInApi",
  NoDataFound = "noDataFound",
  GatewayError = "GateWayError",
  NoActionsRequired = "noActionsRequired",
  CompletedTick = "completed",
  Jumbotron = "jumbotron"
}
interface IProps {
  type?: ErrorType;
  title?: string;
  subTitle?: string;
  backBtn?: boolean;
  backBtnCb?: Function;
  errorMsgStyles?: StyleProp<ViewStyle>;
  containerStyles?: StyleProp<ViewStyle>;
  imageStyles?: StyleProp<ImageStyle>;
}
function Jumbotron(props: IProps): ReactElement {
  const {
    title,
    subTitle,
    type,
    backBtn = false,
    backBtnCb = null,
    errorMsgStyles,
    containerStyles,
    imageStyles
  } = props;
  const dispImage = useMemo(() => {
    if (type) {
      switch (type) {
        case ErrorType.InternalServerError:
          return newErr;
        case ErrorType.NoDataFound:
          return noDataFound;
        case ErrorType.GatewayError:
          return newErr;
        case ErrorType.CompletedTick:
          return completedTick;
        case ErrorType.Jumbotron:
          return jumbotronImg;
        default:
          return null;
      }
    }
  }, [type]);
  return (
    <View style={[styles.container, containerStyles]}>
      {backBtn && backBtnCb ? (
        <View style={styles.backButtonContainer}>
          <Button
            iconName={IconNames.back}
            iconStyles={styles.closeEnabled}
            buttonStyles={styles.backButton}
            type={ButtonType.Secondary}
            onPress={() => backBtnCb()}
          />
        </View>
      ) : null}
      {type === ErrorType.NoDataFound ? (
        <>
          <Image
            testId={testIds.reports.completedImg}
            source={whoops}
            imageStyle={[
              {
                width: 175,
                height: 30,
                marginTop: 36
              },
              imageStyles
            ]}
            resizeMode="stretch"
          />
          <Text
            textSize={TextSize.Large}
            fontWeight={FontWeight.SemiBold}
            testId={testIds.jumbotronHeading}
            textStyle={[styles.headerText, errorMsgStyles, { paddingTop: 24, paddingBottom: 16 }]}
          >
            {title}
          </Text>
          <Image
            testId={testIds.reports.completedImg}
            source={dispImage}
            imageStyle={[
              {
                height: 300,
                width: 500
              },
              imageStyles
            ]}
            resizeMode="contain"
          />
        </>
      ) : (
        <>
          <View style={styles.imageContainer}>
            <Image
              testId={testIds.reports.completedImg}
              source={dispImage}
              imageStyle={[
                type === ErrorType.GatewayError || ErrorType.InternalServerError
                  ? { height: 250, width: 350 }
                  : styles.imageStyle,
                imageStyles
              ]}
              resizeMode="contain"
            />
          </View>
          <Text
            textSize={TextSize.Large}
            fontWeight={FontWeight.SemiBold}
            testId={testIds.jumbotronHeading}
            textStyle={[styles.headerText, errorMsgStyles]}
          >
            {title}
          </Text>
          <Text
            textSize={TextSize.ExtraSmall}
            fontWeight={FontWeight.Regular}
            testId={testIds.jumbotronSubHeading}
            textStyle={styles.subHeadingText}
          >
            {subTitle}
          </Text>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorPallete.white3,
    borderRadius: 8,
    shadowColor: colorPallete.black1,
    shadowOpacity: 0.04,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowRadius: 16
  },
  imageStyle: {
    height: 170,
    width: 170
    // flex:1
  },
  imageContainer: {
    paddingTop: 20
  },

  headerText: {
    paddingTop: 35,
    paddingBottom: 44,
    color: colorPallete.brown1
  },
  subHeadingText: {
    color: colorPallete.red1,
    paddingBottom: 60
  },
  backButtonContainer: {
    top: 30,
    left: 30,
    position: "absolute",
    zIndex: 9
  },
  backButton: {
    width: 48,
    height: 48,
    backgroundColor: colorPallete.white1,
    borderWidth: 0,
    borderRadius: 50
  },
  closeEnabled: {
    color: colorPallete.grey2
  }
});

export default Jumbotron;
