import Config from "react-native-config";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

export enum AppModes {
  DEBUG = "DEBUG",
  RELEASE = "RELEASE"
}

// For WEB
const {
  REACT_APP_NODE_ENV = "",
  REACT_APP_SCHEME = "",
  REACT_APP_BASE_URL = "",
  REACT_APP_VERSION = "",
  REACT_APP_PATH = "",
  REACT_APP_ROLLBAR_KEY = ""
} = process.env;

// For MOBILE
const {
  RN_NODE_ENV,
  REACT_NATIVE_SCHEME,
  REACT_NATIVE_BASE_URL,
  REACT_NATIVE_VERSION,
  REACT_NATIVE_PATH
} = Config;

class ConfigHelper {
  private readonly nodeEnv: string;
  private readonly baseUrl: string;
  private readonly scheme: string;
  private readonly version: string;
  private readonly path: string;
  private readonly rollbarKey: string;

  constructor() {
    this.nodeEnv = REACT_APP_NODE_ENV;
    this.baseUrl = REACT_APP_BASE_URL;
    this.scheme = REACT_APP_SCHEME;
    this.version = REACT_APP_VERSION;
    this.path = REACT_APP_PATH;
    this.rollbarKey = REACT_APP_ROLLBAR_KEY;

    if (PlatformUtils.isMobile()) {
      this.nodeEnv = RN_NODE_ENV;
      this.baseUrl = REACT_NATIVE_BASE_URL;
      this.scheme = REACT_NATIVE_SCHEME;
      this.version = REACT_NATIVE_VERSION;
      this.path = REACT_NATIVE_PATH;
    }
  }

  public getBaseUrl = (): string => this.baseUrl;

  public getNodeEnv = (): string => this.nodeEnv;

  public getScheme = (): string => this.scheme;

  public getVersion = (): string => this.version;

  public getPath = (): string => this.path;

  public getRollbarKey = (): string => this.rollbarKey;
}

const configHelper = new ConfigHelper();
export { configHelper as ConfigHelper };
