import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { Roles } from "@timhortons/common/src/constants/roles";
import { StoreProviderService } from "@timhortons/common/src/services/StoreProviderService";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

export const getBearerToken = async (): Promise<string> => {
  let accessToken: string = await LocalStorage.getStorage("accessToken");
  if (accessToken && accessToken.length) {
    return Promise.resolve(accessToken);
  } else {
    return Promise.reject("Token Not Found");
  }
};
export const getRefreshToken = async (): Promise<string> => {
  let refreshToken: string = await LocalStorage.getStorage("refreshToken");
  if (refreshToken && refreshToken.length) {
    return Promise.resolve(refreshToken);
  } else {
    return Promise.reject("Token Not Found");
  }
};
export const setNewBearerToken = async (
  newAccessToken: string,
  newRefreshToken: string
): Promise<void> => {
  if (newAccessToken && newAccessToken.length && newRefreshToken && newRefreshToken.length) {
    await LocalStorage.setStorage("accessToken", newAccessToken);
    await LocalStorage.setStorage("refreshToken", newRefreshToken);
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};
export const clearLocalStorage = async (): Promise<void> => {
  let tokenExists = await LocalStorage.getStorage("tokenExist");
  await LocalStorage.clearStorage();
  if (tokenExists) {
    await LocalStorage.setStorage("tokenExist", true);
  }
  return Promise.resolve();
};

export const getRefreshTokenUrl = async (baseURL: string) => {
  const storeRefreshTokenEndPoint = `${baseURL}/store/v1/refresh`;
  const userRefreshTokenEndPoint = `${baseURL}/iam/v1/refresh`;
  const role = await LocalStorage.getStorage("role");
  if (role === Roles.StoreAccess) {
    return storeRefreshTokenEndPoint;
  } else {
    return userRefreshTokenEndPoint;
  }
};
export async function forceLogout() {
  const reduxStore = StoreProviderService.getStore();
  const { dispatch } = reduxStore;
  await clearLocalStorage();
  dispatch({ type: "USER_LOGOUT", payload: null });
  if (PlatformUtils.isWeb()) {
    window.location.href = "/";
  }
}
