// file not used as of now

export const imageCompression = async (webCamImg: any): Promise<string> =>
  new Promise((resolve) => {
    let img: HTMLImageElement = new Image();
    img.src = webCamImg;
    img.onload = function () {
      const width = img.width;
      const height = img.height;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      if (context) {
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
      resolve(canvas.toDataURL("image/jpeg", 0.5));
    };
  });

export const convertBase64ImgtoBlob = async (base64: string): Promise<Blob> => {
  let fetchedImg = await fetch(base64);
  let blob = await fetchedImg.blob();

  return Promise.resolve(blob);
};

export const convertBlobToFile = async (
  fileDetails: { type: string; extension: string; email: string; loginId: string },
  blob: Blob
): Promise<File> => {
  const file = new File(
    [blob],
    `${fileDetails.email}_${fileDetails.loginId}_${
      fileDetails.extension === "jpg" ? "img" : "vid"
    }_${new Date().getTime()}.${fileDetails.extension}`,
    {
      type: fileDetails.type,
      lastModified: Date.now()
    }
  );

  return Promise.resolve(file);
};
