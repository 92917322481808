import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Animated,
  TouchableOpacity,
  ViewStyle,
  TextStyle,
  StyleProp
} from "react-native";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import OverflowModal from "@timhortons/common/src/components/atoms/overflowModal";
import { setFrameMeasures } from "@timhortons/common/src/utils/measureLayout";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import Loader from "./loader";
import FlatList from "@timhortons/common/src/components/atoms/list";
import "./molecules.scss";
import TextField from "@timhortons/common/src/components/atoms/textField";

export interface IDropDownItem {
  label: string;
  value: string;
  iconName?: IconNames;
  dropdownStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  itemStyle?: TextStyle;
}
interface Props {
  triggerElement?: React.ReactElement;
  allItems: Array<IDropDownItem>;
  selectedValueDefault?: string;
  iconName?: IconNames;
  disabledDropdown?: boolean;
  defaultDropdownText?: string;
  onChangeCallBack?: (obj: IDropDownItem) => void;
  selectedItemStyle?: TextStyle;
  dropdownViewStyle?: StyleProp<ViewStyle>;
  dropdownIconStyle?: StyleProp<TextStyle>;
  customSelectStyle?: StyleProp<TextStyle>;
  handleApiCall?: Function;
  loading?: boolean;
  onEndReached?: () => void;
  listContainerStyle?: StyleProp<ViewStyle>;
  emptyMsg?: string;
  selectedItemBgColor?: string;
  isLogs?: boolean;
  numberOfLines?: number;
  isSearchRequired?: boolean;
  isShowValue?: boolean;
  setSearchText?: Function;
  isProdSheet?: boolean;
  defaultValueHandler?: (selectedValue?: string) => void;
  resetSelectedItem?: boolean;
  setResetSelectedItem?: (val: boolean) => void;
}
interface IFormattedItem extends IDropDownItem {
  id: string;
  isSelected: boolean;
}
interface ISelectedItem {
  id: string;
  label: string;
  value: string;
}
const formatItems = (
  allItems: Array<IDropDownItem>,
  selectedValue: string
): Array<IFormattedItem> => {
  return allItems?.map((el: IDropDownItem, index: number) => {
    let obj: IFormattedItem;
    obj = { ...el, id: `${el.value}_${index}`, isSelected: el.value === selectedValue };
    return obj;
  });
};
export default function ModalDropdown({
  allItems = [],
  selectedValueDefault,
  onChangeCallBack = () => null,
  disabledDropdown = false,
  selectedItemStyle = null,
  dropdownViewStyle = null,
  dropdownIconStyle = null,
  iconName,
  customSelectStyle,
  defaultDropdownText,
  triggerElement,
  handleApiCall,
  loading = false,
  onEndReached,
  listContainerStyle,
  emptyMsg = "Nothing to select",
  selectedItemBgColor = null,
  isLogs = false,
  numberOfLines,
  isSearchRequired = false,
  isShowValue = false,
  setSearchText,
  isProdSheet = false,
  defaultValueHandler,
  resetSelectedItem = false,
  setResetSelectedItem
}: Props): ReactElement {
  const [formattedAllItems, setformattedAllItems] = useState<Array<IFormattedItem>>(
    formatItems(allItems, selectedValueDefault)
  );
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [buttonFrame, setButtonFrame] = useState(null);
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const triggerRef = useRef(null);
  const [searchString, setSearchString] = useState<string>("");
  const [filteredList, setFilteredList] = useState<any>(
    formatItems(allItems, selectedValueDefault)
  );
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: open ? 1 : 0,
      duration: 500,
      useNativeDriver: true
    }).start();

    if (open) handleApiCall && handleApiCall();
  }, [open]);

  //NEW ADDITION NOT TESTED EXHAUSTIVELY
  useEffect(() => {
    // if (allItems && allItems.length > 0 && formattedAllItems?.length <= 0) {
    // Condition changed because of infinite scroll

    // if (allItems && allItems.length > 0) {
    // Condition changed because of updating dropdown when empty data comes - 18sept2021
    if (allItems && searchString === "") {
      setformattedAllItems(formatItems(allItems, selectedValueDefault));
      setFilteredList(formatItems(allItems, selectedValueDefault));
    } else if (isProdSheet) {
      setFilteredList(formatItems(allItems, selectedValueDefault));
    }
    if (allItems && isProdSheet && resetSelectedItem) {
      setSelectedItem(null);
      setResetSelectedItem && setResetSelectedItem(false);
    }
  }, [allItems, searchString, resetSelectedItem]);

  //NEW CHANGE NOT TESTED EXHAUSTIVELY
  useEffect(() => {
    handleDefaultSelect(selectedItem);
  }, [selectedValueDefault, formattedAllItems]); //Added formattedAllItems to manage page refresh
  useEffect(() => {
    defaultValueHandler && defaultValueHandler(selectedValueDefault);
  }, [selectedValueDefault]);

  useEffect(() => {
    if (selectedItem && searchString !== "" && !isProdSheet) {
      handleItemSelect(selectedItem);
    } else {
      //NEW CHANGE NOT TESTED EXHAUSTIVELY(Earlier Not Commented)
      // handleDefaultSelect(selectedItem);
    }
  }, [selectedItem]);
  useEffect(() => {
    if (!open && !isProdSheet) {
      setSearchString("");
    }
  }, [open]);

  const handleSearch = (keyString: string) => {
    if (setSearchText) {
      if (keyString.length >= 3) {
        setSearchText(keyString);
      } else {
        setSearchText("");
      }
      setSearchString(keyString);
    } else {
      keyString = keyString.toLocaleLowerCase();
      setSearchString(keyString);
      let data = [...formattedAllItems];
      if (keyString.length > 0) {
        let results = data.filter((obj) => obj.label.toLocaleLowerCase().includes(keyString));
        if (results.length > 0) {
          setFilteredList(results);
        } else {
          setFilteredList([]);
        }
      } else {
        setFilteredList(data);
      }
    }
  };

  const handleDefaultSelect = (selectItem: ISelectedItem) => {
    let selectedIndex =
      formattedAllItems && formattedAllItems.findIndex((el) => el.value === selectedValueDefault);
    if (selectedIndex > -1) {
      let formattedItem = formattedAllItems && formattedAllItems[selectedIndex];
      delete formattedItem.isSelected;
      setSelectedItem(formattedItem);
    } else {
      if (!isProdSheet) {
        setSelectedItem(null);
      }
    }
  };

  const handleItemSelect = (selectItem: ISelectedItem) => {
    let selectedIndex =
      formattedAllItems && formattedAllItems.findIndex((el) => el.id === selectedItem.id);
    if (selectedIndex > -1) {
      let formattedDump = formatItems(allItems, selectedValueDefault);
      for (let i = 0; i < formattedDump.length; i++) {
        if (i !== selectedIndex) {
          formattedDump[i].isSelected = false;
        }
      }
      formattedDump[selectedIndex].isSelected = true;
      setFilteredList(formattedDump);
    }
  };
  const handleItemClick = (item: IFormattedItem) => {
    delete item.isSelected;
    setSelectedItem(item);
    onChangeCallBack(item);
    setOpen(false);
  };
  const setModalState = (): void => {
    setOpen(!open);
  };

  const showModal = (): void => {
    setFrameMeasures(
      () => {
        setOpen(true);
      },
      triggerRef,
      setButtonFrame
    );
  };
  function getModalText() {
    if (defaultDropdownText) {
      return defaultDropdownText;
    } else {
      return "Select...";
    }
  }
  return (
    <View style={styles.masterContainer}>
      <OverflowModal
        buttonFrame={buttonFrame}
        modalVisible={open}
        setModalState={setModalState}
        triggerComponent={
          triggerElement ? (
            <TouchableOpacity
              ref={triggerRef}
              testID="dropdown-btn"
              onPress={disabledDropdown === false && (() => showModal())}
            >
              {triggerElement}
            </TouchableOpacity>
          ) : (
            <>
              <TouchableOpacity
                ref={triggerRef}
                testID="dropdown-btn"
                style={[styles.selectBar, dropdownViewStyle]}
                onPress={disabledDropdown === false && (() => showModal())}
              >
                <View style={styles.selectedItemsWrapper}>
                  {selectedItem ? (
                    <Text
                      textStyle={[styles.defaultText, selectedItemStyle]}
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                      testId={selectedItem.value}
                      numberOfLines={numberOfLines}
                    >
                      {isShowValue ? selectedItem.value : selectedItem.label}
                    </Text>
                  ) : (
                    <Text
                      textStyle={[styles.defaultText, customSelectStyle]}
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                      testId="def"
                      numberOfLines={numberOfLines}
                    >
                      {getModalText()}
                    </Text>
                  )}
                </View>

                <Icon
                  name={iconName ? iconName : IconNames.down}
                  size={10}
                  customStyle={StyleSheet.flatten(
                    (open && [styles.iconStyle, styles.iconRotate, dropdownIconStyle]) || [
                      styles.iconStyle,
                      dropdownIconStyle
                    ]
                  )}
                />
              </TouchableOpacity>
            </>
          )
        }
      >
        <View style={styles.itemListWrapper}>
          {loading ? (
            <View style={styles.loadingContainer}>
              <Loader type="component" />
            </View>
          ) : (
            <View nativeID={isLogs ? "dropdownId" : ""}>
              {isSearchRequired && (
                <View style={styles.searchWrapper}>
                  {/* <Icon name={IconNames.search} customStyle={styles.searchIcon} /> */}
                  <TextField
                    value={searchString}
                    inputStyle={styles.searchBar}
                    handleChange={(e: string) => {
                      handleSearch(e.trim());
                    }}
                    name={`multin${new Date()}`}
                    id={`multid${new Date()}`}
                    placeholder="Enter Text"
                  />
                </View>
              )}
              <FlatList
                extractor={(item) => item.id}
                listContainerStyle={listContainerStyle}
                listData={filteredList && filteredList}
                onEndReached={onEndReached}
                nestedScrollEnabled={true}
                listEmptyComponent={
                  <View style={styles.errorContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.FineRegular}
                      testId="ellipsis"
                    >
                      {emptyMsg}
                    </Text>
                  </View>
                }
                listRenderer={(el: IFormattedItem) => (
                  <TouchableOpacity
                    key={`${el.value}-${el.id}`}
                    onPress={() => {
                      handleItemClick(el);
                    }}
                    style={styles.iconTextContainer}
                  >
                    {el?.iconName && (
                      <Icon name={el.iconName} customStyle={styles.iconTextStyle} size={12} />
                    )}
                    <Text
                      testId={el.id}
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.ExtraRegular}
                      textStyle={[
                        styles.individualItem,
                        el.itemStyle,
                        el.isSelected
                          ? { backgroundColor: selectedItemBgColor ?? colorPallete.white3 }
                          : null
                      ]}
                    >
                      {el.label}
                    </Text>
                  </TouchableOpacity>
                )}
              />
            </View>
          )}
        </View>
      </OverflowModal>
    </View>
  );
}

const styles = StyleSheet.create({
  masterContainer: {
    display: "flex",
    flexDirection: "column"
  },
  selectBar: {
    backgroundColor: colorPallete.white1,
    borderRadius: 4,
    paddingVertical: 6,
    paddingHorizontal: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
    // maxWidth: "fit-content"
  },
  selectedItemsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    overflow: "hidden",
    maxWidth: "90%"
  },
  defaultText: {
    color: colorPallete.grey3
  },
  iconRotate: {
    transform: [{ rotate: "180deg" }]
  },
  iconStyle: {
    color: colorPallete.grey3,
    // marginInlineStart: 16,
    // marginStart: 16,
    ...I18nService.select({
      rtl: { marginStart: 16 },
      ltr: { marginStart: 16 }
    })
    // fontSize: 10
  },
  iconTextStyle: { paddingBottom: 4, paddingLeft: 8 },
  iconTextContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 8
  },
  itemListWrapper: {
    backgroundColor: "white",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#DDD9DA"
    // width: "100%"
  },
  individualItem: {
    paddingRight: 16,
    paddingLeft: 8,
    color: colorPallete.black4,
    paddingVertical: 6
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    margin: 30
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 250
  },
  searchWrapper: {
    paddingVertical: 10,
    ...I18nService.select({
      rtl: { paddingStart: 12 },
      ltr: { paddingStart: 12 }
    }),
    backgroundColor: colorPallete.white3,
    borderBottomWidth: 1,
    borderBottomColor: colorPallete.grey7
    // flexDirection:'row',
  },
  searchBar: {
    borderWidth: 0
  },
  searchIcon: {
    color: colorPallete.grey7,
    ...I18nService.select({
      rtl: { marginEnd: 12 },
      ltr: { marginEnd: 12 }
    })
  }
});
