import { all } from "@redux-saga/core/effects";
import managerReviewWatcherSaga from "@timhortons/common/src/redux/logs/saga/managerReviewWatcherSaga";
import timeTempWatcherSaga from "@timhortons/common/src/redux/logs/saga/mainLogs/timeTempWatcherSaga";
import stationReadWatcherSaga from "@timhortons/common/src/redux/logs/saga/mainLogs/stationReadWatcherSaga";
import cleaningWatcherSaga from "@timhortons/common/src/redux/logs/saga/mainLogs/cleaningWatcherSaga";
import managerWalkActionWatcherSaga from "@timhortons/common/src/redux/logs/saga/actionLogs/managerWalkActionWatcherSaga";
import timeTempActionWatcherSaga from "@timhortons/common/src/redux/logs/saga/actionLogs/timeTempActionWatcherSaga";
import logsSummaryWatcherSaga from "@timhortons/common/src/redux/logs/saga/logsSummaryWatcherSaga";
import managerWalkthroughWatcherSaga from "@timhortons/common/src/redux/logs/saga/mainLogs/managerWalkthroughWatcherSaga";
import stationReadActionsWatcherSaga from "@timhortons/common/src/redux/logs/saga/actionLogs/stationReadActionsWatcherSaga";
import cleaningActionsWatcherSaga from "@timhortons/common/src/redux/logs/saga/actionLogs/cleaningActionsWatcherSaga";
import individualActionsTableWatcherSaga from "@timhortons/common/src/redux/logs/saga/individualActionsTableWatcherSaga";

export default function* logsRootSaga() {
  yield all([
    managerReviewWatcherSaga(),
    timeTempWatcherSaga(),
    stationReadWatcherSaga(),
    cleaningWatcherSaga(),
    managerWalkthroughWatcherSaga(),
    managerWalkActionWatcherSaga(),
    timeTempActionWatcherSaga(),
    cleaningActionsWatcherSaga(),
    stationReadActionsWatcherSaga(),
    logsSummaryWatcherSaga(),
    individualActionsTableWatcherSaga()
  ]);
}
