import { Roles } from "@timhortons/common/src/constants/roles";
import {
  logsSummaryEndPoints,
  selfCalibrationEndPoints
} from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LOGKeys } from "@timhortons/common/src/constants/apiKeys";
import { logsSummaryFormatter } from "@timhortons/common/src/repositories/logs/logsSummaryFormatter";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ICategoryMetaData, LogsSummaryPayload } from "@timhortons/common/src/models/logs/mainLogs";
import {
  ComparisonStoresAdminModel,
  ComparisonStoresAdminModelList
} from "@timhortons/common/src/models/profile";
import { GetStoreListByCodeOrNamePayload } from "@timhortons/common/src/models/rev/selfCalibration";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
class LogsSummaryRepository {
  getCategoriesMetaData = async (param: { storeCode: number }) => {
    let actionName = ["Time & Temperature", "Station Readiness", "Cleaning", "Manager Walkthrough"];
    let object;
    if (param.storeCode) {
      object = {
        actionNames: actionName.join(","),
        storeCode: param.storeCode
      };
    } else {
      object = {
        actionNames: actionName.join(",")
      };
    }
    let response = await ApiClient.get(
      logsSummaryEndPoints.getCategoriesMetaData(),
      object,
      LOGKeys.StoreAccessKeys
    );
    let finalData: ICategoryMetaData[] = [];
    response.map((item: any) => {
      let deserializeVar: ICategoryMetaData = deserialize(ICategoryMetaData, item);
      finalData.push(deserializeVar);
    });
    return finalData;
  };
  getLogsSummaryData = async (param: LogsSummaryPayload) => {
    let obj: LogsSummaryPayload = param;
    let roles = await LocalStorage.getStorage("role");
    if (roles === Roles.StoreAccess) {
      delete obj.employeeId;
    }

    let respp = await ApiClient.get(
      logsSummaryEndPoints.getLogsSummaryData(),
      obj,
      LOGKeys.StoreAccessKeys
    );
    return logsSummaryFormatter(respp);
  };

  getStoreListData = async (param: GetStoreListByCodeOrNamePayload) => {
    const { pageNo, pageSize, keyword, role } = param;
    let data = await ApiClient.get(
      selfCalibrationEndPoints.getStoreListByCodeOrName(param.empId),
      {
        pageNo,
        pageSize,
        keyword,
        role
      },
      LOGKeys.StoreAccessKeys
    );
    let storesData: ComparisonStoresAdminModel = {
      content: [],
      totalElements: null
    };
    let finalContent: ComparisonStoresAdminModelList[] = [];
    data.content.map((item: ComparisonStoresAdminModelList) => {
      let deserializevar: ComparisonStoresAdminModelList = deserialize(
        ComparisonStoresAdminModelList,
        item
      );
      finalContent.push(deserializevar);
    });
    storesData.totalElements = data.totalElements;
    storesData.content = finalContent;
    storesData.currentPage = pageNo;
    return storesData;
  };
}
const logsSummaryRepository = new LogsSummaryRepository();
export { logsSummaryRepository as LogsSummaryRepository };
