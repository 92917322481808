import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { ActionLogsRepository } from "@timhortons/common/src/repositories/logs/actionsLogs";
import { TimeTemperatureActionLogs } from "@timhortons/common/src/redux/logs/actions/actionLogs/timeTemperature";
import { TabObject } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import {
  IProdTempCategories,
  IUpdateActionsRequest
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { ProductTemperatureCategoriesModel } from "@timhortons/common/src/models/logs/mainLogs";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";

function* getProductTempActionsWorkerSaga(
  value: IFluxStandardAction<{
    tabId: string;
    shift: string;
    storeCode: number;
    startDate: string;
    isCategory?: boolean;
  }>
) {
  const { payload } = value;
  try {
    let data: TabObject[] = yield call(ActionLogsRepository.getProductTempActions, payload);
    yield put(TimeTemperatureActionLogs.getProductTempActionsSuccess(data));
  } catch (error) {
    Logger.error("actionLogs-timeTempActions", error, "getProductTempActionsWorkerSaga");

    yield put(TimeTemperatureActionLogs.getProductTempActionsError(error));
  }
}

function* updateProductActionsWorkerSaga(value: IFluxStandardAction<IUpdateActionsRequest>) {
  const { payload } = value;
  try {
    let data: [] = yield call(ActionLogsRepository.updateProductActions, payload);
    yield put(
      TimeTemperatureActionLogs.updateProductActionsSuccess({ ...data, uniqueId: payload.itemId })
    );
  } catch (error) {
    Logger.error("actionLogs-timeTempActions", error, "updateProductActionsWorkerSaga");

    yield put(TimeTemperatureActionLogs.updateProductActionsError(error));
  }
}
function* getProductTempActionsCategory(value: IFluxStandardAction<IProdTempCategories>) {
  const { payload } = value;
  try {
    let data: ProductTemperatureCategoriesModel[] = yield call(
      MainLogsRepository.getProductsCategory,
      payload
    );
    yield put(TimeTemperatureActionLogs.getProdTempActionsCategorySuccess(data));
  } catch (error) {
    Logger.error("actionLogs-timeTempActions", error, "getProductTempActionsCategory");

    yield put(TimeTemperatureActionLogs.getProdTempActionsCategoryError(error));
  }
}
function* timeTempActionWatcherSaga() {
  yield takeLatest(
    LOGS.Actions.TimeTemp.GET__PRODUCT_TEMP_ACTIONS.LOADING,
    getProductTempActionsWorkerSaga
  );
  yield takeLatest(
    LOGS.Actions.TimeTemp.UPDATE_PRODUCT_ACTIONS.LOADING,
    updateProductActionsWorkerSaga
  );
  yield takeLatest(
    LOGS.Actions.TimeTemp.GET_TIME_TEMP_ACTIONS_CATEGORIES.LOADING,
    getProductTempActionsCategory
  );
}
export default timeTempActionWatcherSaga;
