import { MarkDownContentModel } from "@timhortons/common/src/models/userResources/userResources";

export const searchApiDataMock = [
  {
    categoryName: "Food Safety",
    categoryId: 1,
    articles: [
      {
        id: 1,
        serial: "1",
        name: "Introduction to Food Safety"
      },
      {
        id: 2,
        serial: "2",
        name: "Preventing Foodborne Illness"
      },
      {
        id: 3,
        serial: "3",
        name: "Tellus lacus mi sed arcu ullamcorper."
      },
      {
        id: 4,
        serial: "4",
        name: "Dignissim vestibulum scelerisque placerat vel."
      }
    ]
  },
  {
    categoryName: "Food quality",
    categoryId: 2,
    articles: [
      {
        id: 1,
        serial: "1",
        name: "Sed tristique est in sit."
      },
      {
        id: 2,
        serial: "2",
        name: "Ultrices mattis turpis odio."
      },
      {
        id: 3,
        serial: "3",
        name: "Cursus dictum lacus facilisis id ut lectus."
      }
    ]
  }
];

export const filtersResources = [
  {
    key: 1,
    name: "Food Safety",
    selected: false,
    id: 1
  },
  {
    key: 2,
    name: "Guest Service",
    selected: false,
    id: 2
  },
  {
    key: 3,
    name: "People",
    selected: false,
    id: 3
  },
  {
    key: 4,
    name: "Crisis / Compliance",

    selected: false,
    id: 4
  },
  {
    key: 5,
    name: "Kitchen Basics / Equipment",
    selected: false,
    id: 5
  },
  {
    key: 6,
    name: "System basics",
    isSelected: false,
    id: 6
  },
  {
    key: 7,
    name: "Ingredient and Menu items basics",
    selected: false,
    id: 7
  },
  {
    key: 8,
    name: "Cleanliness & Maintenance",
    selected: false,
    id: 8
  },
  {
    key: 9,
    name: "Receiving and Storing products",
    selected: false,
    id: 9
  },
  {
    key: 10,
    name: "Kitchen Basics / Equipment",
    selected: false,
    id: 10
  },
  {
    key: 11,
    name: "System basics",
    isSelected: false,
    id: 11
  },
  {
    key: 12,
    name: "Ingredient and Menu items basics",
    selected: false,
    id: 12
  },
  {
    key: 13,
    name: "Cleanliness & Maintenance",
    selected: false,
    id: 13
  },
  {
    key: 14,
    name: "Receiving and Storing products",
    selected: false,
    id: 14
  }
];

export const categories = [
  {
    id: "1",
    name: "Cleanliness & Maintenance",
    hasSubCategories: true
  },
  {
    id: "2",
    name: "Lonavala",
    hasSubCategories: true
  },
  {
    id: "3",
    name: "Food Quality",
    hasSubCategories: false
  },
  {
    id: "4",
    name: "Training",
    hasSubCategories: true
  },
  {
    id: "5",
    name: "Profitability & Shift Management jjjjjjjjjjjj",
    hasSubCategories: true
  },
  {
    id: "6",
    name: "Speed of Service",
    hasSubCategories: true
  },
  {
    id: "7",
    name: "G.U.E.S.T",
    hasSubCategories: true
  },
  {
    id: "8",
    name: "Profitability & Shift Management",
    hasSubCategories: true
  },
  {
    id: "9",
    name: "Speed of Service",
    hasSubCategories: true
  },
  {
    id: "10",
    name: "G.U.E.S.T",
    hasSubCategories: true
  },
  {
    id: "11",
    name: "Profitability & Shift Management",
    hasSubCategories: true
  },
  {
    id: "12",
    name: "Speed of Service",
    hasSubCategories: true
  },
  {
    id: "13",
    name: "G.U.E.S.T",
    hasSubCategories: true
  }
];

export const listTopics = {
  id: 1,
  subCategory: "Introduction to Food Safety",
  serialNo: "1.",
  topics: [
    {
      id: 1,
      topic: "What is Food Safety?",
      serialNo: "1.1",
      resources: [
        {
          id: 1,
          title: " Why is Food Safety Important to Tim Hortons?",
          serialNo: "1.1.1"
        },
        {
          id: 2,
          title: " Food Safety is Everyone’s Business",
          serialNo: "1.1.2"
        }
      ]
    },
    {
      id: 2,
      topic: "Tim Hortons Food Safety Plan",
      serialNo: "1.2",
      resources: [
        {
          id: 3,
          title: "Tim Hortons Written Food Safety Management Plan",
          serialNo: "1.2.1"
        },
        {
          id: 4,
          title: "Tim Hortons Food Safety Objective",
          serialNo: "1.2.2"
        }
      ]
    }
  ]
};

export const markDownContent: MarkDownContentModel = {
  id: 1,
  name: "Introduction to Food Safety",
  description: null,
  serialNo: "1.",
  topics: [
    {
      id: 1,
      title: "What is Food Safety?",
      content:
        "Food Safety includes the system and Standard Operating Procedures that are carried out on a daily basis throughout all areas of the Store, to protect our Guests from foodborne illness.",
      serialNo: "1.1",
      subTopic: [
        {
          id: 1,
          title: " Why is Food Safety Important to Tim Hortons?",
          description:
            "Tim Hortons has always been a leader in quality, value, service, and cleanliness. In fact, we have built our reputation on meeting that commitment. Over the years, Tim Hortons guests have come to trust in and rely on your ability to provide safe, fresh products, friendly service, and a clean environment. By implementing Food Safety controls in every area of your operations, you can guarantee your guests the safest possible product.",
          serialNo: "1.1.1"
        },
        {
          id: 2,
          title: " Food Safety is Everyone’s Business",
          description:
            "Foodborne illness is preventable. Knowing the facts about food safety and how to avoid foodborne illness or injury at work is a responsibility we all share – it is always top priority in the commitment to Guests. Consider the devastating effects of a foodborne illness outbreak: • Ill Guests and Team Members • Medical, Legal and Investigation Costs • Increased Insurance Premiums • Lost Business and Guest Trust • Lost Sales A proactive approach is the best way to safeguard against these serious threats. Consider the benefits to serving safe food: • Repeat Guests • Profitability • High Quality Products • Reduced Liabilities • Healthy Team Members",
          serialNo: "1.1.2"
        }
      ],
      active: false
    },
    {
      id: 2,
      title: "Tim Hortons Food Safety Plan",
      content:
        "Food Safety includes the system and Standard Operating Procedures that are carried out on a daily basis throughout all areas of the Store, to protect our Guests from foodborne illness.",
      serialNo: "1.2",
      subTopic: [
        {
          id: 3,
          title: " Tim Hortons Written Food Safety Management Plan",
          description:
            "Tim Hortons Store level written Food Safety Program has been in place for over 15 years. During this time, the key elements of our HACCP-based Food Safety Program have been reviewed and assessed by various levels of government in many different regions. The following provides an overview of the many tools used to support our Food Safety Program.",
          serialNo: "1.2.1"
        },
        {
          id: 4,
          title: " Tim Hortons Food Safety Objectives:",
          description:
            "• Protect Guests and Team Members from foodborne illness • Prevent and control any hazards during the flow of food • Take a responsible and proactive approach in serving safe food • Provide Food Safety Training opportunities to all Team Members",
          serialNo: "1.2.2"
        },
        {
          id: 5,
          title: "Mandatory Food Safety Training",
          description:
            "Building a strong Food Safety Culture is fundamental to the commitment to Guests. Therefore, Food Safety Training is mandatory in the Stores. ALL team members, shift managers, store managers, area managers, operations managers, trainers, and anyone involved in operations must complete the required food safety training from an approved program. Recertification is required as per the local municipality guidelines.",
          serialNo: "1.2.3"
        }
      ],
      active: false
    }
  ],
  active: false
};
