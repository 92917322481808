import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import React, { ReactElement, useMemo } from "react";
import { History } from "history";
import { Roles } from "@timhortons/common/src/constants/roles";
import BuilderLayout from "@timhortons/common/src/components/organisms/builder/template";
interface IProps {
  user: IUserDetails;
  translate?: any;
  history?: History;
  moduleType?: string;
}

export enum ComparsionLevel {
  Country = "COUNTRY",
  Channels = "CHANNEL",
  CountryManager = "COUNTRY_MANAGER",
  OpsManager = "OPS_MANAGER",
  AreaManager = "AREA_MANAGER",
  Stores = "STORE",
  ClusterHeadOfOps = "CLUSTER_HEAD_OF_OPS",
  LpAuditor = "LP_AUDITOR"
}

export enum builderLevel {
  isLp = "LP",
  isRev = "REV",
  isProdSheet = "PRODUCTION_SHEET"
}

export default function ReportBuilder(props: IProps): ReactElement {
  const { user, translate, history, moduleType } = props;

  const detailedReportPermissions: Array<ComparsionLevel> = [
    ComparsionLevel.Country,
    ComparsionLevel.ClusterHeadOfOps,
    ComparsionLevel.CountryManager,
    ComparsionLevel.AreaManager,
    ComparsionLevel.OpsManager,
    ComparsionLevel.Channels,
    ComparsionLevel.Stores
  ];
  const revSlicedPermissions = useMemo(() => {
    let userRole = user?.roles[0];
    let result: Array<ComparsionLevel> = [];
    switch (userRole) {
      // case Roles.Admin:
      case Roles.SuperAdmin:
      case Roles.TrainingAndDevManager:
      case Roles.TrainingAndDevLead:
      case Roles.CEO:
      case Roles.COO:
      case Roles.HrAndAdminDirector:
      case Roles.ProductionAndDevExecutive:
        result = detailedReportPermissions;
        break;
      case Roles.CountryManager:
      case Roles.InternalAuditor:
      case Roles.ClusterHeadofOps:
      case Roles.OperationManager:
      case Roles.SeniorOpsManager:
        result = detailedReportPermissions.slice(2);
        break;
      case Roles.AreaManager:
      case Roles.AreaTrainer:
        result = detailedReportPermissions.slice(3);
        break;
      default:
        result = [];
        break;
    }
    return result;
  }, []);

  const lpSlicedPermissions = useMemo(() => {
    const lpDetailedReportPermissions: Array<ComparsionLevel> = [
      ComparsionLevel.Country,
      ComparsionLevel.ClusterHeadOfOps,
      ComparsionLevel.CountryManager,
      ComparsionLevel.Channels,
      ComparsionLevel.OpsManager,
      ComparsionLevel.AreaManager,
      ComparsionLevel.Stores
    ];
    let userRole = user?.roles[0];
    let result: Array<ComparsionLevel> = [];
    switch (userRole) {
      case Roles.SuperAdmin:
      case Roles.TrainingAndDevLead:
      case Roles.TrainingAndDevManager:
      case Roles.CEO:
      case Roles.COO:
      case Roles.HrAndAdminDirector:
      case Roles.ProductionAndDevExecutive:
        result = lpDetailedReportPermissions;
        break;
      case Roles.HeadLP:
        result = lpDetailedReportPermissions;
        result.splice(-1, 0, ComparsionLevel.LpAuditor);
        break;
      case Roles.ClusterHeadofOps:
        const removingElements: Array<ComparsionLevel> = [
          ComparsionLevel.ClusterHeadOfOps,
          ComparsionLevel.CountryManager
        ];
        result = lpDetailedReportPermissions.filter(
          (el: ComparsionLevel) => !removingElements.includes(el)
        );
        break;
      case Roles.CountryManager:
        const removingCmElements: Array<ComparsionLevel> = [
          ComparsionLevel.Country,
          ComparsionLevel.ClusterHeadOfOps,
          ComparsionLevel.CountryManager
        ];
        result = lpDetailedReportPermissions.filter(
          (el: ComparsionLevel) => !removingCmElements.includes(el)
        );
        break;
      case Roles.SeniorOpsManager:
      case Roles.OperationManager:
      case Roles.AreaManager:
        result = [ComparsionLevel.Channels, ComparsionLevel.Stores, ComparsionLevel.AreaManager];
        if (userRole === Roles.AreaManager) result.pop();
        break;
      default:
        result = [];
        break;
    }
    return result;
  }, []);

  const ProductionSheetSlicedPermissions = useMemo(() => {
    let userRole = user?.roles[0];
    let result: Array<ComparsionLevel> = [];
    switch (userRole) {
      case Roles.SuperAdmin:
      case Roles.TrainingAndDevLead:
      case Roles.TrainingAndDevManager:
      case Roles.CEO:
      case Roles.COO:
      case Roles.HrAndAdminDirector:
      case Roles.ProductionAndDevExecutive:
        result = detailedReportPermissions;
        break;
      case Roles.ClusterHeadofOps:
      case Roles.CountryManager:
        result = detailedReportPermissions
          .slice(0, 1)
          .concat(detailedReportPermissions.slice(3, 7));
        break;
      case Roles.OperationManager:
      case Roles.SeniorOpsManager:
        result = detailedReportPermissions
          .slice(3, 4)
          .concat(detailedReportPermissions.slice(5, 7));
        break;
      case Roles.AreaManager:
      case Roles.AreaTrainer:
        result = detailedReportPermissions.slice(5, 7);
        break;
      default:
        result = [];
        break;
    }
    return result;
  }, []);

  return (
    <>
      <BuilderLayout
        translate={translate}
        detailedReportPermissions={
          moduleType === builderLevel.isLp
            ? lpSlicedPermissions
            : moduleType === builderLevel.isProdSheet
            ? ProductionSheetSlicedPermissions
            : revSlicedPermissions
        }
        history={history}
        user={user}
        moduleType={moduleType}
      />
    </>
  );
}
