import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGEASK } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import {
  AskTellGetQuestionByStatusModel,
  AskTellQuestionCountModel,
  AskTellSupportTicketModel,
  QuestionsListModel
} from "@timhortons/common/src/models/askTell/manageAskModule";

const getQuestionCount = (): IFluxStandardAction => {
  return {
    type: MANAGEASK.supportTickets.GET_QUESTION_COUNT.LOADING
  };
};
const getQuestionCountSuccess = (
  data: AskTellQuestionCountModel
): IFluxStandardAction<AskTellQuestionCountModel> => {
  return {
    type: MANAGEASK.supportTickets.GET_QUESTION_COUNT.SUCCESS,
    payload: data
  };
};
const getQuestionCountError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGEASK.supportTickets.GET_QUESTION_COUNT.ERROR,
    payload: error
  };
};

const getQuestionByStatus = (
  data: AskTellGetQuestionByStatusModel
): IFluxStandardAction<AskTellGetQuestionByStatusModel> => {
  return {
    type: MANAGEASK.supportTickets.GET_QUESTION_BY_STATUS.LOADING,
    payload: data
  };
};
const getQuestionByStatusSuccess = (
  data: QuestionsListModel
): IFluxStandardAction<QuestionsListModel> => {
  return {
    type: MANAGEASK.supportTickets.GET_QUESTION_BY_STATUS.SUCCESS,
    payload: data
  };
};
const getQuestionByStatusError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGEASK.supportTickets.GET_QUESTION_BY_STATUS.ERROR,
    payload: error
  };
};

const postAnswerReply = (
  data: AskTellSupportTicketModel
): IFluxStandardAction<AskTellSupportTicketModel> => {
  return {
    type: MANAGEASK.supportTickets.POST_ANSWER_REPLY.LOADING,
    payload: data
  };
};
const postAnswerReplySuccess = (
  data: AskTellSupportTicketModel
): IFluxStandardAction<AskTellSupportTicketModel> => {
  return {
    type: MANAGEASK.supportTickets.POST_ANSWER_REPLY.SUCCESS,
    payload: data
  };
};
const postAnswerReplyError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGEASK.supportTickets.POST_ANSWER_REPLY.ERROR,
    payload: error
  };
};

const updateAnswerReply = (
  data: AskTellSupportTicketModel
): IFluxStandardAction<AskTellSupportTicketModel> => {
  return {
    type: MANAGEASK.supportTickets.UPDATE_ANSWER_REPLY.LOADING,
    payload: data
  };
};
const updateAnswerReplySuccess = (
  data: AskTellSupportTicketModel
): IFluxStandardAction<AskTellSupportTicketModel> => {
  return {
    type: MANAGEASK.supportTickets.UPDATE_ANSWER_REPLY.SUCCESS,
    payload: data
  };
};
const updateAnswerReplyError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGEASK.supportTickets.UPDATE_ANSWER_REPLY.ERROR,
    payload: error
  };
};

const resetSupportTktError = (): IFluxStandardAction => {
  return {
    type: MANAGEASK.supportTickets.RESET_SUPPORT_TKT_ERROR
  };
};

export const SupportTicketsModuleAction = {
  getQuestionCount,
  getQuestionCountSuccess,
  getQuestionCountError,
  getQuestionByStatus,
  getQuestionByStatusSuccess,
  getQuestionByStatusError,
  postAnswerReply,
  postAnswerReplySuccess,
  postAnswerReplyError,
  updateAnswerReply,
  updateAnswerReplySuccess,
  updateAnswerReplyError,
  resetSupportTktError
};
