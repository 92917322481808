import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";
import { ActionPlanSelectors } from "@timhortons/common/src/redux/rev/selectors/actionPlan";
import { ActionPlansAction } from "@timhortons/common/src/redux/rev/actions/actionPlanActions";
import { SelfCalibrationActions } from "@timhortons/common/src/redux/rev/actions/selfCalibration";
import { SelfCalibrationSelectors } from "@timhortons/common/src/redux/rev/selectors/selfCalibration";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Roles } from "@timhortons/common/src/constants/roles";
import Loader from "@timhortons/common/src/components/molecules/loader";
import ActionPlanHeader from "@timhortons/common/src/modules/rev/components/dashboards//organisms/actionPlanHeader";
import ActionPlanTable from "@timhortons/common/src/modules/rev/components/dashboards/actionPlans/organisms/actionPlanTable";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IActionPlansError,
  IActionPlansLoading
} from "@timhortons/common/src/redux/rev/reducers/actionPlans";
import {
  ActionPlanTableModel,
  FilterDataModel,
  SelectedFilterDataModel
} from "@timhortons/common/src/models/rev/actionPlan";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";

interface IProps {
  getFilterData: Function;
  getActionPlans: Function;
  getSeletedFilterData: Function;
  updateActionPlanStatus: Function;
  selectedFilterData: SelectedFilterDataModel;
  filterData: FilterDataModel;
  actionPlansList: ActionPlanTableModel;
  loading: IActionPlansLoading;
  error: IActionPlansError;
  history: History;
  translate: any;
  currentUserRoles?: Roles[];
  userDetails?: IUserDetails;
}
export interface IActionPlanData {
  userId: boolean;
  storeId: number;
  pageNumber: number;
  sortOrder: string;
  columnName: string;
  pageSize: number;
  date: string;
  name: string;
  status: string;
  fromDate: Date;
  toDate: Date;
  search: string;
}
function ActionPlans(props: IProps): ReactElement {
  const {
    history,
    translate,
    getFilterData,
    getActionPlans,
    getSeletedFilterData,
    updateActionPlanStatus,
    loading,
    selectedFilterData,
    error,
    actionPlansList,
    filterData,
    currentUserRoles,
    userDetails
  } = props;

  const [isIndividualLogin, setIndividualLogin] = useState<boolean>();
  useEffect(() => {
    if (checkAllowedRoles(currentUserRoles)) {
      setIndividualLogin(true);
      setActionPlanData({ ...finalActionPlan, userId: true });
    } else if (currentUserRoles !== null) {
      setIndividualLogin(false);
      setActionPlanData({ ...finalActionPlan, userId: false });
    }
  }, [currentUserRoles]);

  const actionPlanData: IActionPlanData = {
    userId: undefined,
    storeId: userDetails && userDetails.storeCode,
    pageNumber: 1,
    sortOrder: "desc",
    columnName: "",
    pageSize: 10,
    date: null,
    name: "",
    status: null,
    fromDate: null,
    toDate: null,
    search: ""
  };
  const [finalActionPlan, setActionPlanData] = useState(actionPlanData);

  useEffect(() => {
    getFilterData({ storeCode: "1" });
  }, []);
  const isFocused = useFocusedHook();

  useEffect(() => {
    if (finalActionPlan.userId !== undefined) {
      isFocused && getActionPlans(finalActionPlan);
    }
  }, [finalActionPlan, isFocused]);
  const redirectToActionPlan = (
    actionPlanId: number,
    calibrationId: number,
    createPlan: boolean
  ) => {
    let searchParams = { actionPlanId: actionPlanId, calibrationId: calibrationId };
    navigation(history, Pathnames.detailedActionPlans, searchParams);
    // if (createPlan) {
    //   updateActionPlanStatus({ actionPlanId });
    //   getActionPlans(finalActionPlan);
    // }
  };
  const selectedFilterHandler = (value: Object) => {
    getSeletedFilterData(value);
    setActionPlanData((prevState) => ({
      ...prevState,
      ...value,
      pageNumber: 1
    }));
  };
  const pageHandler = (val: number) => {
    setActionPlanData((prevState) => ({
      ...prevState,
      pageNumber: val
    }));
  };
  const sortHandler = (val: string, columnName: string) => {
    setActionPlanData((prevState) => ({
      ...prevState,
      sortOrder: val,
      columnName: columnName,
      pageNumber: 1
    }));
  };
  function loader(): JSX.Element {
    return <Loader type="component" />;
  }
  if (loading.getActionPlans && loading.getFilterData) {
    return loader();
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <ActionPlanHeader
            isIndividualLogin={isIndividualLogin}
            filterData={filterData}
            translate={translate}
            dropDownValueHandler={(value: Object) => selectedFilterHandler(value)}
          />
        </View>
      </View>
      {/* {actionPlansList.actionPlans && actionPlansList.actionPlans.length > 0 ? ( */}
      <View style={styles.tableContainer}>
        <ActionPlanTable
          pageNum={finalActionPlan.pageNumber}
          isIndividualLogin={isIndividualLogin}
          handleSortingOrder={(val: string, columnName: string) => sortHandler(val, columnName)}
          handlePagination={(value: number) => pageHandler(value)}
          onCreatePlan={redirectToActionPlan}
          translate={translate}
          getActionPlans={getActionPlans}
          loading={loading}
          error={error}
          actionPlansList={actionPlansList}
          selectedFilterData={selectedFilterData}
        />
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 18
  },
  headerContainer: {
    marginHorizontal: 8,
    zIndex: 1,
    flexWrap: "wrap"
  },
  tableContainer: {
    flex: 1,
    marginVertical: 20,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  },
  createPlanBtn: {
    backgroundColor: colorPallete.variant6,
    borderRadius: 8,
    paddingVertical: 6,
    paddingHorizontal: 16
  }
});
const mapStateToProps = (state: IState) => {
  return {
    loading: ActionPlanSelectors.actionPlanLoading(state),
    error: ActionPlanSelectors.actionPlanError(state),
    filterData: ActionPlanSelectors.getFilterData(state),
    actionPlansList: ActionPlanSelectors.getActionPlans(state),
    selectedFilterData: ActionPlanSelectors.getSelectedFilterData(state),
    checkAuth: SelfCalibrationSelectors.checkAuth(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...ActionPlansAction, ...SelfCalibrationActions }, dispatch) };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActionPlans);
