import { ILpAppealsState, lpAppealsReducer } from "./appeals";
import {
  ILpCategoryState,
  categoryReducer
} from "@timhortons/common/src/redux/lp/reducers/category";
import { combineReducers } from "redux";
import { IAuditState, auditReducer } from "@timhortons/common/src/redux/lp/reducers/audits";
import {
  ILpReportsState,
  lpReportsReducer
} from "@timhortons/common/src/redux/lp/reducers/reportsReducer";
import {
  actionPlanReducer,
  ILPActionPlansState
} from "@timhortons/common/src/redux/lp/reducers/actionPlan";

export interface ILPState {
  audit: IAuditState;
  category: ILpCategoryState;
  actionPlan: ILPActionPlansState;
  reports: ILpReportsState;
  appeals: ILpAppealsState;
}

export const lpReducer = combineReducers({
  audit: auditReducer,
  category: categoryReducer,
  actionPlan: actionPlanReducer,
  reports: lpReportsReducer,
  appeals: lpAppealsReducer
});
