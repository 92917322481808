import React, { ReactElement } from "react";
import { View, FlatList as RNFlatList, StyleProp, ViewStyle, StyleSheet } from "react-native";
import { KeyboardAwareFlatList } from "react-native-keyboard-aware-scroll-view";
type keyable = { [key: string]: any };
interface IProps {
  listData: Array<keyable>;
  listRenderer: (item: keyable, index?: number) => JSX.Element;
  header?: () => JSX.Element;
  listContainerStyle?: StyleProp<ViewStyle>;
  contentStyles?: StyleProp<ViewStyle>;
  horizontalToggler?: boolean;
  scrollEnabled?: boolean;
  extractor: (listData: keyable, index?: number) => string;
  seperator?: () => JSX.Element;
  extraData?: any;
  showsVerticalScrollIndicator?: boolean;
  showsHorizontalScrollIndicator?: boolean;
  onEndReachedThreshold?: number;
  onEndReached?: () => void;
  listFooterComponent?: React.ReactElement;
  isKeyboardAwareFlatlist?: boolean;
  clippedSubview?: boolean;
  listEmptyComponent?: JSX.Element;
  listFooterComponentStyle?: StyleProp<ViewStyle>;
  nestedScrollEnabled?: boolean;
}
function List(props: IProps): ReactElement {
  const {
    listData,
    listRenderer, //The ListItems with proper styles will returned by this prop, which furthur gets inserted into flatlist
    listContainerStyle,
    horizontalToggler = false,
    scrollEnabled = true,
    header, //The header of the list can be passed via. this function prop
    extractor,
    contentStyles,
    seperator, //The seperator funtion will return that item which has to be rendered after each listItem for eg pass a divider
    extraData,
    showsVerticalScrollIndicator = false,
    showsHorizontalScrollIndicator = false,
    onEndReachedThreshold = 0.01,
    onEndReached,
    listFooterComponent,
    isKeyboardAwareFlatlist,
    clippedSubview = false,
    listEmptyComponent,
    listFooterComponentStyle,
    nestedScrollEnabled = false
  } = props;
  const renderItem = ({ item, index }: keyable): JSX.Element => {
    return listRenderer(item, index);
  };
  const extractorHandler = (item: keyable, index: number): string => {
    return extractor(item, index);
  };
  return (
    <View style={[styles.container, listContainerStyle]}>
      {isKeyboardAwareFlatlist && (
        <KeyboardAwareFlatList
          extraHeight={150}
          extraScrollHeight={100}
          data={listData}
          scrollEnabled={scrollEnabled}
          renderItem={renderItem}
          contentContainerStyle={contentStyles}
          keyExtractor={(item: keyable, index: number) => extractorHandler(item, index)}
          ListHeaderComponent={header}
          horizontal={horizontalToggler}
          ItemSeparatorComponent={seperator}
          extraData={extraData}
          showsVerticalScrollIndicator={showsVerticalScrollIndicator}
          showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
          onEndReachedThreshold={onEndReachedThreshold}
          onEndReached={onEndReached}
          maxToRenderPerBatch={1}
          initialNumToRender={50}
          removeClippedSubviews={clippedSubview} // Unmount components when outside of window
          updateCellsBatchingPeriod={200} // Increase time between renders
          windowSize={7} // Reduce the window size
          ListFooterComponent={listFooterComponent}
        />
      )}
      {!isKeyboardAwareFlatlist && (
        <RNFlatList
          data={listData}
          scrollEnabled={scrollEnabled}
          renderItem={renderItem}
          contentContainerStyle={contentStyles}
          keyExtractor={(item: keyable, index: number) => extractorHandler(item, index)}
          ListHeaderComponent={header}
          horizontal={horizontalToggler}
          ItemSeparatorComponent={seperator}
          extraData={extraData}
          showsVerticalScrollIndicator={showsVerticalScrollIndicator}
          showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
          onEndReachedThreshold={onEndReachedThreshold}
          onEndReached={onEndReached}
          maxToRenderPerBatch={10}
          initialNumToRender={50}
          removeClippedSubviews={clippedSubview} // Unmount components when outside of window
          updateCellsBatchingPeriod={200} // Increase time between renders
          windowSize={7} // Reduce the window size
          ListFooterComponent={listFooterComponent}
          ListEmptyComponent={listEmptyComponent}
          ListFooterComponentStyle={listFooterComponentStyle}
          nestedScrollEnabled={nestedScrollEnabled}
        />
      )}
    </View>
  );
}
export default List;
const styles = StyleSheet.create({
  container: {
    width: "100%"
  }
});
