import React, { useEffect, useState, useRef } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, TextStyle } from "react-native";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { ButtonGroup } from "@timhortons/common/src/components/molecules/buttonGroup";
import {
  Filters,
  HideFilters,
  ResetFilters,
  ShowFiltersView
} from "@timhortons/common/src/modules/common/organisms/filters";
import { ReportTable } from "@timhortons/common/src/modules/rev/components/dashboards/reports";
import {
  MonthPicker,
  monthsGregorian
} from "@timhortons/common/src/components/molecules/customsCalenderPickers";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { tableHeaderStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsTableHeader/tableHeaderStyles";
import { Roles } from "@timhortons/common/src/constants/roles";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";

interface IProps {
  translate?: any;
  currentTable: ReportTable;
  setCurrentTable: Function;
  isIndividualLogin?: boolean;
  getReportTableData?: Function;
  setReportData?: Function;
  finalReportData?: any;
  getSelectedMonthFilter: Function;
  currentUserRoles: IUserDetails;
}
export interface SelectedMonthFilter {
  month: {
    id: number;
    label: string;
  };
  year: number;
}
export const selectedFromDateHandler = (
  value: any,
  setReset: any,
  selectedValues: any,
  setSelectedValues: any
) => {
  setReset(false);
  if (dateFormat(value) === dateFormat(selectedValues.fromDate)) {
    return null;
  } else {
    return setSelectedValues((prevState: any) => ({
      ...prevState,
      fromDate: value && value[0],
      toDate: value && value[1]
    }));
  }
};

export const selectedSearchHandler = (value: string, setReset: any, setSelectedValues: any) => {
  setReset(false);
  setSelectedValues((prevState: any) => ({
    ...prevState,
    search: value
  }));
};
export default function ReportsTableHeader(props: IProps) {
  const {
    translate,
    currentTable,
    setCurrentTable,
    isIndividualLogin,
    getReportTableData,
    setReportData,
    finalReportData,
    getSelectedMonthFilter,
    currentUserRoles
  } = props;
  const [selectedValues, setSelectedValues] = useState({
    visitType: "",
    fromDate: null,
    toDate: null,
    search: ""
  });
  const [selectedMonthFilter, setSelectedMonthFilter] = useState<SelectedMonthFilter>({
    year: new Date().getFullYear(),
    month: {
      label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
      id: new Date().getMonth()
    }
  });
  const [isShowFilter, setShowFilter] = useState(false);
  const [reset, setReset] = useState(false);
  // const [summaryFilter, setSummaryFilter] =
  //   useState<{ year: number; month: { label: string; id: number } }>(null);
  let prevSelectedValues = useRef(selectedValues);
  const selectedFilterHandler = (value: Object) => {
    setReportData((prevState: any) => ({
      ...prevState,
      ...value,
      pageNumber: 1
    }));
    getReportTableData({
      ...finalReportData,
      ...value,
      pageNumber: 1
    });
  };

  useEffect(() => {
    if (currentTable === ReportTable.audits) {
      if (JSON.stringify(prevSelectedValues.current) !== JSON.stringify(selectedValues)) {
        selectedFilterHandler(selectedValues);
        prevSelectedValues.current = selectedValues;
      }
    }
  }, [selectedValues]);
  useEffect(() => {
    getSelectedMonthFilter(selectedMonthFilter);
  }, [selectedMonthFilter]);

  const resetState = () => {
    setSelectedValues((prevState) => ({
      ...prevState,
      fromDate: null,
      toDate: null,
      search: "",
      visitType: ""
    }));
    setSelectedMonthFilter({
      year: new Date().getFullYear(),
      month: {
        label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
        id: new Date().getMonth()
      }
    });
    setReset(true);
  };
  const getButtonStyle = (type: ReportTable) => {
    let themedStyle: StyleProp<ViewStyle>;
    if (currentTable !== type) {
      themedStyle = tableHeaderStyles.inActiveButtonStyle;
    }
    return StyleSheet.flatten([tableHeaderStyles.buttonStyle, themedStyle]);
  };
  const getTextStyle = (type: ReportTable) => {
    let themedStyle: StyleProp<TextStyle>;
    if (currentTable !== type) {
      themedStyle = tableHeaderStyles.inActiveTextStyle;
    }
    return StyleSheet.flatten(themedStyle);
  };

  const buttonLists = [
    {
      id: "1",
      title: translate("audits"),
      onPress: () => {
        setCurrentTable(ReportTable.audits);
        resetState();
      },
      buttonStyle: getButtonStyle(ReportTable.audits),
      textStyles: getTextStyle(ReportTable.audits)
    },
    {
      id: "2",
      title: translate("summary"),
      onPress: () => {
        setCurrentTable(ReportTable.summary);

        setShowFilter(false);
      },
      buttonStyle: getButtonStyle(ReportTable.summary),
      textStyles: getTextStyle(ReportTable.summary)
    }
    // {
    //   id: "3",
    //   title: translate("reportBuilder"),
    //   onPress: () => {
    //     setCurrentTable(ReportTable.reportBuilder);

    //     setShowFilter(false);
    //   },
    //   buttonStyle: getButtonStyle(ReportTable.reportBuilder),
    //   textStyles: getTextStyle(ReportTable.reportBuilder)
    // }
  ];

  const ceoEqvRoles: Array<Roles> = [
    Roles.CEO,
    Roles.COO,
    Roles.HrAndAdminDirector,
    Roles.ProductionAndDevExecutive
  ];

  const newButtonList = buttonLists;
  //  isIndividualLogin
  //   ? PlatformUtils.isWeb()
  //     ? buttonLists
  //     : buttonLists.slice(0, 2)
  //   : ceoEqvRoles.includes(currentUserRoles?.roles[0])
  //   ? buttonLists
  //   : buttonLists.slice(0, 2);

  return (
    <>
      <View style={tableHeaderStyles.container}>
        <View style={tableHeaderStyles.textContainer}>
          <Text
            fontWeight={FontWeight.Regular}
            textSize={TextSize.ExtraLarge}
            testId={testIds.reports.reportsTableHeader}
            textStyle={tableHeaderStyles.headingColor}
          >
            {currentTable === "Report Builder" && translate("reportBuilder")}
            {currentTable !== "Report Builder" && translate("Reports")}
          </Text>
          {isShowFilter &&
          (currentTable === ReportTable.audits || currentTable === ReportTable.summary) ? (
            <ShowFiltersView
              translate={translate}
              setShowFilter={() => setShowFilter(!isShowFilter)}
              resetState={resetState}
            />
          ) : (
            (currentTable === ReportTable.audits || currentTable === ReportTable.summary) && (
              <HideFilters
                translate={translate}
                setShowFilter={() => setShowFilter(!isShowFilter)}
              />
            )
          )}
          {currentTable === ReportTable.audits || currentTable === ReportTable.summary ? (
            <ResetFilters
              resetContainerStyle={{ marginTop: 6 }}
              translate={translate}
              setShowFilter={() => setShowFilter(!isShowFilter)}
              resetState={resetState}
            />
          ) : null}
        </View>
        <View style={tableHeaderStyles.dropdownContainer}>
          <ButtonGroup
            buttonLists={newButtonList}
            buttonsType={ButtonType.Secondary}
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.Regular}
          />
        </View>
      </View>
      {isShowFilter ? (
        currentTable === ReportTable.audits ? (
          <View>
            <Filters
              isDropDownRequired={false}
              parentCallBackFrom={(value: any) =>
                selectedFromDateHandler(value, setReset, selectedValues, setSelectedValues)
              }
              searchFunction={(value: any) => {
                selectedSearchHandler(value, setReset, setSelectedValues);
              }}
              resetHandler={resetState}
              searchValue={selectedValues.search}
              reset={reset}
              disableSelectedPrevDate={selectedValues && selectedValues.fromDate}
              selectedValueDefault={selectedValues.visitType}
              translate={translate}
            />
          </View>
        ) : (
          <View style={tableHeaderStyles.monthPickerWrapper}>
            {currentTable === ReportTable.summary ? (
              <MonthPicker
                reset={reset}
                setReset={setReset}
                callBk={(data) => {
                  setSelectedMonthFilter(data);
                }}
                // initMonth={{ label: (monthsGregorian.find(el => el.id === (new Date()).getMonth())).label, id: (new Date()).getMonth() }}
              />
            ) : null}
          </View>
        )
      ) : null}
    </>
  );
}
