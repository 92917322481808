import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  DailyCheckListCategoriesModel,
  LogShiftModel,
  PostStationReadinessModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { IStationReadCatgeories } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { AnswerStatus } from "@timhortons/common/src/modules/logs/formatter/cleaningFormatter";

export interface IStationReadinessLoading {
  getCategory: boolean;
  getSubCategory: boolean;
  getSubCategoryItems: boolean;
  postStationReadiness: boolean;
  getSRShifts: boolean;
}
export interface IStationReadinessError {
  getCategory: string;
  getSubCategory: string;
  getSubCategoryItems: string;
  postStationReadiness: string;
  getSRShifts: string;
}
export interface IStationReadinessState {
  loading: IStationReadinessLoading;
  error: IStationReadinessError;
  getCategory: IStationReadCatgeories[];
  getSubCategory: DailyCheckListCategoriesModel[];
  postStationReadiness: PostStationReadinessModel;
  getSRShifts: LogShiftModel[];
}
export const stationReadLogsInitialState: IStationReadinessState = {
  getCategory: null,
  getSubCategory: null,
  postStationReadiness: null,
  getSRShifts: null,
  loading: {
    getCategory: false,
    getSubCategory: false,
    postStationReadiness: false,
    getSubCategoryItems: false,
    getSRShifts: false
  },
  error: {
    getCategory: null,
    getSubCategory: null,
    postStationReadiness: null,
    getSubCategoryItems: null,
    getSRShifts: null
  }
};
export const stationReadLogsReducer = (
  state = stationReadLogsInitialState,
  action: { payload: any; type: string }
): IStationReadinessState => {
  switch (action.type) {
    case LOGS.MainLogs.StationRead.GET_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCategory: true }
      };
    case LOGS.MainLogs.StationRead.GET_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCategory: false },
        getCategory: action.payload
      };
    case LOGS.MainLogs.StationRead.GET_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCategory: false },
        error: { ...state.error, getCategory: action.payload }
      };
    case LOGS.MainLogs.StationRead.GET_SUBCATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSubCategory: true }
      };
    case LOGS.MainLogs.StationRead.GET_SUBCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSubCategory: false },
        getSubCategory: action.payload
      };
    case LOGS.MainLogs.StationRead.GET_SUBCATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getSubCategory: false },
        error: { ...state.error, getSubCategory: action.payload }
      };
    case LOGS.MainLogs.StationRead.POST_STATION_READINESS_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postStationReadiness: true },
        error: { ...state.error, postStationReadiness: null }
      };
    case LOGS.MainLogs.StationRead.POST_STATION_READINESS_ITEMS.SUCCESS:
      let updatedData = state.getSubCategory;
      let categoryIndex =
        updatedData && updatedData.findIndex((item) => item.id === action.payload.productId);
      if (categoryIndex > -1) {
        let questionList = state.getSubCategory[categoryIndex].questionList;
        let questionIndex =
          questionList &&
          questionList.findIndex((item) => item.uniqueItemId === action.payload.questionId);
        let isComment = action.payload.answerStatus === AnswerStatus.comment;
        if (questionIndex > -1) {
          questionList[questionIndex].status = action.payload.answerType;
          questionList[questionIndex].employeeName = action.payload.employeeId;
          questionList[questionIndex].images = action.payload.assets;
          questionList[questionIndex].comment = action.payload.comment;
          questionList[questionIndex].isComment = isComment;
        }
        updatedData[categoryIndex].questionList = questionList;
      }
      let categories = state.getCategory;
      let catIndex =
        categories &&
        categories.findIndex(
          (item: IStationReadCatgeories) => item.id === action.payload.categoryId
        );
      if (catIndex > -1) {
        categories[catIndex].percentageCompleted = action.payload.percentageCompleted;
      }
      return {
        ...state,
        loading: { ...state.loading, postStationReadiness: false },
        error: { ...state.error, postStationReadiness: null },
        getSubCategory: updatedData,
        postStationReadiness: action.payload
      };
    case LOGS.MainLogs.StationRead.POST_STATION_READINESS_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postStationReadiness: false },
        error: { ...state.error, postStationReadiness: action.payload }
      };
    case LOGS.MainLogs.StationRead.GET_SUBCATEGORIES_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoryItems: true }
      };
    case LOGS.MainLogs.StationRead.GET_SUBCATEGORIES_ITEMS.SUCCESS:
      let data = state.getSubCategory;
      let index = data && data?.findIndex((obj) => obj.id === action.payload.metaData.categoryId);
      if (index > -1) {
        data[index].questionList = action.payload.apiRes;
      }
      return {
        ...state,
        loading: { ...state.loading, getSubCategoryItems: false },
        getSubCategory: data
      };
    case LOGS.MainLogs.StationRead.GET_SUBCATEGORIES_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoryItems: false },
        error: { ...state.error, getSubCategoryItems: JSON.stringify(action.payload) }
      };

    case LOGS.MainLogs.StationRead.GET_SR_SHIFTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSRShifts: true },
        error: { ...state.error, getSRShifts: null }
      };
    case LOGS.MainLogs.StationRead.GET_SR_SHIFTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSRShifts: false },
        error: { ...state.error, getSRShifts: null },
        getSRShifts: action.payload
      };
    case LOGS.MainLogs.StationRead.GET_SR_SHIFTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getSRShifts: false },
        error: { ...state.error, getSRShifts: action.payload }
      };
    case LOGS.MainLogs.StationRead.RESET_STATION_READ_LOGS:
      return {
        ...state,
        // getCategory: null,
        // getSubCategory: null,
        // getSRShifts: null,
        postStationReadiness: null,
        loading: { ...state.loading, postStationReadiness: false },
        error: { ...state.error, postStationReadiness: null }
      };
    case LOGS.MainLogs.StationRead.RESET_STATIONREAD_ENTIRE_LOGS:
      return stationReadLogsInitialState;

    case LOGS.MainLogs.StationRead.HANDLE_CLICKED_SHIFT:
      let updatedCatState = state.getCategory;
      if (updatedCatState !== null) {
        let categorySRIndex =
          updatedCatState &&
          updatedCatState.findIndex((item) => item.id === action.payload.categoryId);
        if (categorySRIndex > -1) {
          updatedCatState[categorySRIndex].endDate = action.payload.value.endDate;
          updatedCatState[categorySRIndex].startDate = action.payload.value.startDate;
          updatedCatState[categorySRIndex].isEnable = action.payload.value.isEnable;
          updatedCatState[categorySRIndex].isShiftComplete = action.payload.value.isShiftComplete;
          updatedCatState[categorySRIndex].label = action.payload.value.label;
          updatedCatState[categorySRIndex].status = action.payload.value.status;
        }
      }
      return {
        ...state,
        getCategory: updatedCatState
      };
    case LOGS.MainLogs.StationRead.HANDLE_AUTH:
      let srCatUpdate = state.getCategory;
      if (srCatUpdate !== null) {
        let srindex =
          srCatUpdate && srCatUpdate?.findIndex((item) => item.id === action.payload.categoryId);
        if (srindex > -1) {
          srCatUpdate[srindex].isAccessValid = action.payload.logsAuth.isAccessValid;
          srCatUpdate[srindex].employeeName = action.payload.logsAuth.name;
        }
      }
      return {
        ...state,
        getCategory: srCatUpdate
      };
    default:
      return state;
  }
};
