import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { StyleSheet } from "react-native";
export const styles = StyleSheet.create({
  longError: { flex: 1, justifyContent: "flex-end" },
  shortError: { flex: 1, marginTop: 0, justifyContent: "flex-end" },
  masterModalWrapper: {
    height: "80%",
    marginBottom: 30,
    width: 850,
    backgroundColor: "#ffffff",
    borderRadius: 4
  },
  modal: { borderRadius: 4, backgroundColor: colorPallete.white3, height: "100%" },
  headerWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 24,
    paddingHorizontal: 30,
    paddingBottom: 16,
    borderBottomColor: colorPallete.grey11,
    borderBottomWidth: 1
  },
  formMasterWrapper: { paddingHorizontal: 30, paddingTop: 24, paddingBottom: 30 },
  inputRow: { marginTop: 30, flexDirection: "row" },
  inputBoxesWrapper: { flex: 3, flexDirection: "row", justifyContent: "flex-start" },
  inputBoxLbl: { marginRight: 30, width: 160 },
  inputContainerStyles: {
    marginTop: 8,
    height: 48,
    alignItems: "center",
    justifyContent: "flex-start",
    borderWidth: 1,
    backgroundColor: colorPallete.white3,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 12
  },
  upldContainer: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderStyle: "dotted",
    borderWidth: 1,
    borderColor: colorPallete.grey6,
    borderRadius: 4
  },
  upldBtn: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginVertical: 6
  },
  saveBtnWrapper: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end"
  },
  saveBtn: {
    paddingVertical: 12,
    paddingHorizontal: 60,
    borderRadius: 8,
    marginVertical: 6,
    marginHorizontal: 10
  }
});
