import React, { ReactElement } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";

interface IProps {
  percentage: number;
  containerStyle?: StyleProp<ViewStyle>;
  filledStyle?: StyleProp<ViewStyle>;
}

export const ProgressBar = (props: IProps): ReactElement => {
  const { containerStyle, filledStyle } = props;
  const percentage = props.percentage === null ? 0 : props.percentage;
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[{ width: `${percentage ?? 0}%` }, styles.filled, filledStyle]}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 4,
    backgroundColor: colorPallete.grey7,
    borderRadius: 4
  },
  filled: {
    height: "100%",
    backgroundColor: colorPallete.red1,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  }
});

export default ProgressBar;
