import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { CategoryDataModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { YesNoDataModel } from "models/rev/selfCalibration";

const getCategoryData = (data: {
  selfCalibrationId: number;
  auditType: AuditType;
}): IFluxStandardAction<{ selfCalibrationId: number; auditType: AuditType }> => {
  return {
    type: REV.CATEGORY_DATA.LOADING,
    payload: data
  };
};

const getCategoryDataSuccess = (
  data: CategoryDataModel
): IFluxStandardAction<CategoryDataModel> => {
  return {
    type: REV.CATEGORY_DATA.SUCCESS,
    payload: data
  };
};
const getCategoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.CATEGORY_DATA.ERROR,
    payload: error
  };
};

const updateCategoryData = (
  data: YesNoDataModel,
  categoryId: number
): IFluxStandardAction<{ data: YesNoDataModel; categoryId: number }> => {
  return {
    type: REV.UPDATE_CATEGORY_DATA.SUCCESS,
    payload: { data, categoryId }
  };
};

export const CategoryActions = {
  getCategoryData,
  getCategoryDataSuccess,
  getCategoryDataError,
  updateCategoryData
};
