import React, { ReactElement } from "react";
import { View, StyleSheet } from "react-native";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";

interface IProps {
  setShowModal: () => void;
  translate?: any;
  restaurantName: string;
  restaurantCode: number;
}

export default function LPAuditValidationModal(props: IProps): ReactElement {
  const { setShowModal, translate, restaurantCode, restaurantName } = props;

  return (
    <View style={styles.modalContainer}>
      <View style={styles.headerContainer}>
        <Text
          fontWeight={FontWeight.Bold}
          testId={testIds.testAudit.alert}
          textSize={TextSize.Large}
          textStyle={styles.textStyle}
        >
          {translate("alert")}
        </Text>
        <Icon
          name={IconNames.close}
          onPress={() => setShowModal()}
          customStyle={styles.iconStyles}
          size={20}
        />
      </View>
      <Divider orientation={Orientations.Horizontal} dividerStyle={styles.divider} />
      <View style={styles.contentContainer}>
        <Text
          fontWeight={FontWeight.Regular}
          testId={testIds.testAudit.validAudit}
          textSize={TextSize.ExtraRegular}
          textStyle={styles.detailText}
        >
          {`${restaurantName}`} {`(${restaurantCode})`}
        </Text>
        <Text
          fontWeight={FontWeight.Regular}
          testId={testIds.testAudit.validAudit}
          textSize={TextSize.ExtraRegular}
          textStyle={styles.infoText}
        >
          {translate("auditInValidHelperText")}
        </Text>
      </View>
      <View style={styles.btnContainer}>
        <Button
          type={ButtonType.Primary}
          buttonStyles={styles.buttonStyles}
          onPress={() => setShowModal()}
        >
          <Text
            textStyle={styles.btnTextStyles}
            fontWeight={FontWeight.SemiBold}
            textSize={TextSize.ExtraRegular}
            testId={testIds.testAudit.okay}
          >
            {translate("okay")}
          </Text>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: colorPallete.white3,
    paddingVertical: 24,
    borderRadius: 8,
    maxWidth: 600,
    maxHeight: 324
  },
  headerContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    paddingBottom: 16,
    alignItems: "center",
    paddingHorizontal: 30
  },
  textStyle: {
    color: colorPallete.black3
  },
  iconStyles: {
    color: colorPallete.grey2
  },
  divider: {
    borderColor: colorPallete.grey11,
    borderWidth: 1
  },
  contentContainer: {
    padding: 30
  },
  detailText: { color: colorPallete.red1 },
  infoText: { color: colorPallete.black4, paddingTop: 24 },
  btnContainer: { alignItems: "flex-end", flex: 1, paddingHorizontal: 30 },
  buttonStyles: {
    borderRadius: 8
  },
  btnTextStyles: {
    paddingVertical: PlatformUtils.isWeb() ? 12 : 8,
    paddingHorizontal: PlatformUtils.isWeb() ? 60 : 30,
    color: colorPallete.white3
  }
});
