import { call, put, takeLatest } from "redux-saga/effects";
import { Logger } from "@timhortons/common/src/services/logger";
import { ManageNewsAction } from "@timhortons/common/src/redux/manageNews/actions/index";
import { ManageNewsRepository } from "@timhortons/common/src/repositories/manageNews/manageNews";
import {
  DeleteMediaModel,
  DeleteMediaResponseModel,
  MediaRequestModel,
  MediaResponseModel,
  NewsCategoryListModel,
  NewsDetailModel,
  NewsFeatureMediaModel,
  NewsListModel,
  SaveNewsDetailModel
} from "@timhortons/common/src/models/manageNews/manageNews";
import { MANAGENEWS } from "@timhortons/common/src/redux/manageNews/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";

function* getNewsListWorkerSaga() {
  try {
    const response: NewsListModel[] = yield call(ManageNewsRepository.getNewsList);
    yield put(ManageNewsAction.getNewsListSuccess(response));
  } catch (error) {
    Logger.error("Manage-News", error, "getNewsListWorkerSaga");
    yield put(ManageNewsAction.getNewsListError(error));
  }
}
function* deleteNewsWorkerSaga(
  value: IFluxStandardAction<{
    titleId: string;
  }>
) {
  const { payload } = value;
  try {
    yield call(ManageNewsRepository.deleteNews, payload);
    yield put(
      ManageNewsAction.newsDeletionSuccess({
        titleId: payload.titleId
      })
    );
  } catch (error) {
    Logger.error("Manage-news-deletion", error, "deleteNewsWorkerSaga");
    yield put(ManageNewsAction.newsDeletionError(error));
  }
}

function* getNewsDetailsWorkerSaga(value: IFluxStandardAction<{ newsId: string }>) {
  const { payload } = value;

  try {
    const response: NewsDetailModel = yield call(ManageNewsRepository.getNewsDetails, payload);
    yield put(ManageNewsAction.getNewsDetailsByIdSuccess(response));
  } catch (error) {
    Logger.error("Manage-News", error, "getNewsDetailsWorkerSaga");
    yield put(ManageNewsAction.getNewsDetailsByIdError(error));
  }
}
function* getNewsCategoryListWorkerSaga() {
  try {
    const response: NewsCategoryListModel[] = yield call(ManageNewsRepository.getCategoryList);
    yield put(ManageNewsAction.getCategoryListSuccess(response));
  } catch (error) {
    Logger.error("Manage-News", error, "getNewsCategoryListWorkerSaga");
    yield put(ManageNewsAction.getCategoryListError(error));
  }
}
function* postCreateNewNewsWorkerSaga(value: IFluxStandardAction<{ name: string }>) {
  let { payload } = value;
  try {
    const response: NewsListModel = yield call(ManageNewsRepository.postCreateNewNews, payload);
    yield put(ManageNewsAction.postCreateNewNewsSuccess(response));
  } catch (error) {
    Logger.error("Manage-News", error, "postCreateNewNewsWorkerSaga");
    yield put(ManageNewsAction.postCreateNewNewsError(error));
  }
}
//get Media link worker saga
function* getMediaLinkWorkerSaga(value: IFluxStandardAction<MediaRequestModel>) {
  const { payload } = value;
  try {
    const response: MediaResponseModel = yield call(ManageNewsRepository.getMediaLink, payload);
    yield put(ManageNewsAction.getMediaLinkSuccess(response));
  } catch (error) {
    Logger.error("Manage-News", error, "getMediaLinkWorkerSaga");
    yield put(ManageNewsAction.getMediaLinkError(error));
  }
}
//create Media worker saga
function* createMediaWorkerSaga(value: IFluxStandardAction<MediaRequestModel>) {
  const { payload } = value;
  try {
    const response: NewsFeatureMediaModel = yield call(ManageNewsRepository.createMedia, payload);
    yield put(ManageNewsAction.createMediaSuccess(response));
  } catch (error) {
    Logger.error("Manage-News", error, "createMediaWorkerSaga");
    yield put(ManageNewsAction.createMediaError(error));
  }
}
function* saveNewsDetailsWorkerSaga(
  value: IFluxStandardAction<{
    titleId: string;
    categoryName: string;
    description: Array<Object>;
  }>
) {
  let { payload } = value;
  try {
    const response: {
      newsDetails: SaveNewsDetailModel;
      titleId: string;
    } = yield call(ManageNewsRepository.saveNewsDetails, payload);
    yield put(ManageNewsAction.saveNewsSuccess(response));
  } catch (error) {
    Logger.error("ManageNews", error, "saveNewsDetailsWorkerSaga");
    yield put(ManageNewsAction.saveNewsError(error));
  }
}
function* publishNewsWatcherSaga(value: IFluxStandardAction<{ titleId: string }>) {
  const { payload } = value;
  try {
    yield call(ManageNewsRepository.publishNews, payload);
    yield put(ManageNewsAction.publishNewsSuccess());
  } catch (error) {
    Logger.error("ManageNews", error, "publishNewsWatcherSaga");
    yield put(ManageNewsAction.publishNewsError(error));
  }
}
function* editTitleWatcherSaga(
  value: IFluxStandardAction<{
    titleId: string;
    name: string;
  }>
) {
  let { payload } = value;
  try {
    const response: {
      data: NewsListModel;
      titleId: string;
    } = yield call(ManageNewsRepository.editTitle, payload);
    yield put(ManageNewsAction.editTitleSuccess(response));
  } catch (error) {
    Logger.error("ManageNews", error, "editTitleWorkerSaga");
    yield put(ManageNewsAction.editTitleError(error));
  }
}

function* deleteMediaWorkerSaga(value: IFluxStandardAction<MediaRequestModel>) {
  const { payload } = value;
  try {
    const response: DeleteMediaResponseModel = yield call(
      ManageNewsRepository.deleteMedia,
      payload
    );
    yield put(ManageNewsAction.deleteMediaSuccess(response));
  } catch (error) {
    Logger.error("ManageNews", error, "deleteMediaWorkerSaga");
    yield put(ManageNewsAction.deleteMediaError(error));
  }
}
function* manageNewsWatcherSaga() {
  yield takeLatest(MANAGENEWS.GET_NEWSLIST.LOADING, getNewsListWorkerSaga);
  yield takeLatest(MANAGENEWS.GET_NEWS_DETAILS_BY_ID.LOADING, getNewsDetailsWorkerSaga);
  yield takeLatest(MANAGENEWS.NEWS_DELETION.LOADING, deleteNewsWorkerSaga);
  yield takeLatest(MANAGENEWS.GET_CATEGORY_LIST.LOADING, getNewsCategoryListWorkerSaga);
  yield takeLatest(MANAGENEWS.POST_CREATE_NEW_NEWS.LOADING, postCreateNewNewsWorkerSaga);
  yield takeLatest(MANAGENEWS.GET_MEDIA_LINK.LOADING, getMediaLinkWorkerSaga);
  yield takeLatest(MANAGENEWS.CREATE_MEDIA.LOADING, createMediaWorkerSaga);
  yield takeLatest(MANAGENEWS.SAVE_NEWS.LOADING, saveNewsDetailsWorkerSaga);
  yield takeLatest(MANAGENEWS.PUBLISH_NEWS.LOADING, publishNewsWatcherSaga);
  yield takeLatest(MANAGENEWS.EDIT_TITLE.LOADING, editTitleWatcherSaga);
  yield takeLatest(MANAGENEWS.DELETE_MEDIA.LOADING, deleteMediaWorkerSaga);
}

export default manageNewsWatcherSaga;
