import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  ILogShift,
  IUpdateActionsRequest
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { LogShiftModel } from "@timhortons/common/src/models/logs/mainLogs";

const getActionManagerWalkthroughShift = (data: ILogShift): IFluxStandardAction<ILogShift> => {
  return {
    type: LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALTHROUGH_SHIFTS.LOADING,
    payload: data
  };
};
const getActionManagerWalkthroughShiftSuccess = (
  data: LogShiftModel[]
): IFluxStandardAction<LogShiftModel[]> => {
  return {
    type: LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALTHROUGH_SHIFTS.SUCCESS,
    payload: data
  };
};
const getActionManagerWalkthroughShiftError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALTHROUGH_SHIFTS.ERROR,
    payload: error
  };
};

const getActionManagerWalkthroughActions = (data: {
  categoryId: string;
  storeCode: number;
  date: string;
  label: string;
}): IFluxStandardAction<{
  categoryId: string;
  storeCode: number;
  date: string;
  label: string;
}> => {
  return {
    type: LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS.LOADING,
    payload: data
  };
};
const getActionManagerWalkthroughActionsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS.SUCCESS,
    payload: data
  };
};
const getActionManagerWalkthroughActionsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS.ERROR,
    payload: error
  };
};

const updateActionManagerWalkthroughActions = (
  data: IUpdateActionsRequest
): IFluxStandardAction<IUpdateActionsRequest> => {
  return {
    type: LOGS.Actions.ManagerWalk.UPDATE_MANAGER_WALKTHROUGH_ACTIONS.LOADING,
    payload: data
  };
};
const updateActionManagerWalkthroughActionsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.Actions.ManagerWalk.UPDATE_MANAGER_WALKTHROUGH_ACTIONS.SUCCESS,
    payload: data
  };
};
const updateActionManagerWalkthroughActionsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.ManagerWalk.UPDATE_MANAGER_WALKTHROUGH_ACTIONS.ERROR,
    payload: error
  };
};
const resetMWTActionsState = () => {
  return {
    type: LOGS.Actions.ManagerWalk.RESET_MWT_ACTIONS__STATE
  };
};
const resetMWTActionsError = () => {
  return {
    type: LOGS.Actions.ManagerWalk.RESET_MWT_ACTIONS_ERROR
  };
};

export const actionManagerWalkthoughActions = {
  getActionManagerWalkthroughShift,
  getActionManagerWalkthroughShiftSuccess,
  getActionManagerWalkthroughShiftError,
  getActionManagerWalkthroughActions,
  getActionManagerWalkthroughActionsSuccess,
  getActionManagerWalkthroughActionsError,
  updateActionManagerWalkthroughActions,
  updateActionManagerWalkthroughActionsSuccess,
  updateActionManagerWalkthroughActionsError,
  resetMWTActionsState,
  resetMWTActionsError
};
