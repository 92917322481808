import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";

import { SelectedMonthFilter } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsTableHeader";
import {
  ICategoryMetaData,
  LogsSummaryDataModel,
  LogsSummaryPayload
} from "@timhortons/common/src/models/logs/mainLogs";
import { ComparisonStoresAdminModel } from "@timhortons/common/src/models/profile";
import { GetStoreListByCodeOrNamePayload } from "@timhortons/common/src/models/rev/selfCalibration";

const getCategoriesMetaData = (data: {
  storeCode: number;
}): IFluxStandardAction<{ storeCode: number }> => {
  return {
    type: LOGS.Summary.GET_CATEGORIES_META_DATA.LOADING,
    payload: data
  };
};
const getCategoriesMetaDataSuccess = (
  data: ICategoryMetaData[]
): IFluxStandardAction<ICategoryMetaData[]> => {
  return {
    type: LOGS.Summary.GET_CATEGORIES_META_DATA.SUCCESS,
    payload: data
  };
};
const getCategoriesMetaDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Summary.GET_CATEGORIES_META_DATA.ERROR,
    payload: error
  };
};
const getTimeTempLogsSummaryData = (
  data: LogsSummaryPayload
): IFluxStandardAction<LogsSummaryPayload> => {
  return {
    type: LOGS.Summary.GET_TIME_TEMP_LOGS_SUMMARY_DATA.LOADING,
    payload: data
  };
};
const getTimeTempLogsSummaryDataSuccess = (
  data: LogsSummaryDataModel
): IFluxStandardAction<LogsSummaryDataModel> => {
  return {
    type: LOGS.Summary.GET_TIME_TEMP_LOGS_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};
const getTimeTempLogsSummaryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Summary.GET_TIME_TEMP_LOGS_SUMMARY_DATA.ERROR,
    payload: error
  };
};

const getStationLogsSummaryData = (
  data: LogsSummaryPayload
): IFluxStandardAction<LogsSummaryPayload> => {
  return {
    type: LOGS.Summary.GET_STATION_LOGS_SUMMARY_DATA.LOADING,
    payload: data
  };
};
const getStationLogsSummaryDataSuccess = (
  data: LogsSummaryDataModel
): IFluxStandardAction<LogsSummaryDataModel> => {
  return {
    type: LOGS.Summary.GET_STATION_LOGS_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};
const getStationLogsSummaryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Summary.GET_STATION_LOGS_SUMMARY_DATA.ERROR,
    payload: error
  };
};
const getCleaningLogsSummaryData = (
  data: LogsSummaryPayload
): IFluxStandardAction<LogsSummaryPayload> => {
  return {
    type: LOGS.Summary.GET_CLEANING_LOGS_SUMMARY_DATA.LOADING,
    payload: data
  };
};
const getCleaningLogsSummaryDataSuccess = (
  data: LogsSummaryDataModel
): IFluxStandardAction<LogsSummaryDataModel> => {
  return {
    type: LOGS.Summary.GET_CLEANING_LOGS_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};
const getCleaningLogsSummaryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Summary.GET_CLEANING_LOGS_SUMMARY_DATA.ERROR,
    payload: error
  };
};
const getMWTLogsSummaryData = (
  data: LogsSummaryPayload
): IFluxStandardAction<LogsSummaryPayload> => {
  return {
    type: LOGS.Summary.GET_MWT_LOGS_SUMMARY_DATA.LOADING,
    payload: data
  };
};
const getMWTLogsSummaryDataSuccess = (
  data: LogsSummaryDataModel
): IFluxStandardAction<LogsSummaryDataModel> => {
  return {
    type: LOGS.Summary.GET_MWT_LOGS_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};
const getMWTLogsSummaryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Summary.GET_MWT_LOGS_SUMMARY_DATA.ERROR,
    payload: error
  };
};
const getSelectedMonthFilter = (
  data: SelectedMonthFilter
): IFluxStandardAction<SelectedMonthFilter> => {
  return {
    type: LOGS.Summary.GET_SELECTED_MONTH_FILTER.SUCCESS,
    payload: data
  };
};
const resetLogsSummary = () => {
  return {
    type: LOGS.Summary.RESET_LOGS_SUMMARY
  };
};
const getStoreListData = (value: GetStoreListByCodeOrNamePayload) => {
  return {
    type: LOGS.Summary.GET_STORE_LIST.LOADING,
    payload: value
  };
};
const getStoreListDataSuccess = (
  data: ComparisonStoresAdminModel
): IFluxStandardAction<ComparisonStoresAdminModel> => {
  return {
    type: LOGS.Summary.GET_STORE_LIST.SUCCESS,
    payload: data
  };
};
const getStoreListDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Summary.GET_STORE_LIST.ERROR,
    payload: error
  };
};
const resetStoreList = () => {
  return {
    type: LOGS.Summary.RESET_STORE_LIST
  };
};
export const LogsSummaryActions = {
  getCategoriesMetaData,
  getCategoriesMetaDataSuccess,
  getCategoriesMetaDataError,

  getTimeTempLogsSummaryData,
  getTimeTempLogsSummaryDataSuccess,
  getTimeTempLogsSummaryDataError,

  getStationLogsSummaryData,
  getStationLogsSummaryDataSuccess,
  getStationLogsSummaryDataError,

  getCleaningLogsSummaryData,
  getCleaningLogsSummaryDataSuccess,
  getCleaningLogsSummaryDataError,

  getMWTLogsSummaryData,
  getMWTLogsSummaryDataSuccess,
  getMWTLogsSummaryDataError,

  getSelectedMonthFilter,
  resetLogsSummary,
  getStoreListData,
  getStoreListDataSuccess,
  getStoreListDataError,

  resetStoreList
};
