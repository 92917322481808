const redLogo = require("@timhortons/common/src/assets/images/redLogo.png");
const gatewayTimeoutImg = require("@timhortons/common/src/assets/images/gatewayTimeoutError.png");
const pageNotFoundImg = require("@timhortons/common/src/assets/images/pageNotFound.png");
const loaderGif = require("@timhortons/common/src/assets/images/loader1.gif");
const imgPlaceholder = require("@timhortons/common/src/assets/images/imgPlaceholder.png");
const completedTick = require("@timhortons/common/src/assets/images/Completed.png");
const commentSaved = require("@timhortons/common/src/assets/images/commentSaved.png");
const jumbotronImg = require("@timhortons/common/src/assets/images/jumbotron.png");
const thAcademyLogo = require("@timhortons/common/src/assets/images/symRed.png");
const mastHeadImgResources = require("@timhortons/common/src/assets/images/resourceMastHead.webp");
const profilePicDefault = require("@timhortons/common/src/assets/images/NoImage.png");
const noDataFound = require("@timhortons/common/src/assets/images/noDataGen.png");
const noImgFound = require("@timhortons/common/src/assets/images/noImgAny.jpg");
const bg1920 = require("@timhortons/common/src/assets/images/1920.webp");
const bg411 = require("@timhortons/common/src/assets/images/411.png");
const goldMedal = require("@timhortons/common/src/assets/images/goldMedal.png");
const silverMedal = require("@timhortons/common/src/assets/images/silverMedal.png");
const bronzeMedal = require("@timhortons/common/src/assets/images/bronzeMedal.png");
const careerLearning = require("@timhortons/common/src/assets/images/careerLearning.png");
const redVector = require("@timhortons/common/src/assets/images/redVector.png");
const correct = require("@timhortons/common/src/assets/images/correct.png");
const inCorrect = require("@timhortons/common/src/assets/images/inCorrect.png");
const thankYouFeedback = require("@timhortons/common/src/assets/images/thankYouFeedback.png");
const splash = require("@timhortons/common/src/assets/images/splash.gif");
const cheers = require("@timhortons/common/src/assets/images/cheers.png");
const pencil = require("@timhortons/common/src/assets/images/pencil.png");
const download = require("@timhortons/common/src/assets/images/download.png");
const bg1024 = require("@timhortons/common/src/assets/images/1024.webp");
const mapleLogo = require("@timhortons/common/src/assets/images/mapleLogo.png");
const newErr = require("@timhortons/common/src/assets/images/newErr.png");
const whoops = require("@timhortons/common/src/assets/images/whoops.png");
const oopsCookie = require("@timhortons/common/src/assets/images/oopsCookie.png");
const done = require("@timhortons/common/src/assets/images/done.png");
const completedCup = require("@timhortons/common/src/assets/images/completedCup.png");
const errorMessage = require("@timhortons/common/src/assets/images/errorMessage.png");
const basketball = require("@timhortons/common/src/assets/images/basketball.jpeg");
const mugTim = require("@timhortons/common/src/assets/images/mugTims.png");
const mapledashboard = require("@timhortons/common/src/assets/images/dashBoardMaple.gif");
const tickQuiz = require("@timhortons/common/src/assets/images/tickQuiz.png");
const crossQuiz = require("@timhortons/common/src/assets/images/crossQuiz.png");
const butterflyGraduation = require("@timhortons/common/src/assets/images/butterflyGraduation.png");
const stats = require("@timhortons/common/src/assets/images/stats.png");
const timGif = require("@timhortons/common/src/assets/images/timSplash.gif");
const mcqSampleGif = require("@timhortons/common/src/assets/images/mcqSampleGif.gif");
const learningpathstaytuned = require("@timhortons/common/src/assets/images/stayTuneLearningPath1.png");
const timbetStack = require("@timhortons/common/src/assets/images/timbetStack.png");
const assgnLearningDashboard1 = require("@timhortons/common/src/assets/images/assignedLearningDashboard1.png");
const assgnLearningDashboard3 = require("@timhortons/common/src/assets/images/assignedLearningDashboard3.png");
const assgnLearningDashboard2 = require("@timhortons/common/src/assets/images/assignedLearningDashboard2.png");
const noAudit = require("@timhortons/common/src/assets/images/noAudit.png");
const questionMark = require("@timhortons/common/src/assets/images/questionMark.png");
const bubble = require("@timhortons/common/src/assets/images/bubble.png");
const whiteQuestion = require("@timhortons/common/src/assets/images/whiteQues.png");
const redQuestion = require("@timhortons/common/src/assets/images/quesMark.png");
const passed = require("@timhortons/common/src/assets/images/passed.png");
const burger = require("@timhortons/common/src/assets/images/burger.png");
const nothingHere = require("@timhortons/common/src/assets/images/nothingHere.png");
const noSearch = require("@timhortons/common/src/assets/images/noSearch.png");
const starPoor = require("@timhortons/common/src/assets/images/starPoor.png");
const starFair = require("@timhortons/common/src/assets/images/starFair.png");
const starAverage = require("@timhortons/common/src/assets/images/starAverage.png");
const starHappy = require("@timhortons/common/src/assets/images/starHappy.png");
const starExcellent = require("@timhortons/common/src/assets/images/starExcellent.png");
const certDesertsRail = require("@timhortons/common/src/assets/images/certDesertsRail.png");
const ticTacToeImg1 = require("@timhortons/common/src/assets/images/ticTacToeImg1.png");
const ticTacToeImg2 = require("@timhortons/common/src/assets/images/ticTacToeImg2.png");
const ticTacToeImg3 = require("@timhortons/common/src/assets/images/ticTacToeImg3.png");
const ticTacToeImg4 = require("@timhortons/common/src/assets/images/ticTacToeImg4.png");
const ticTacToeImg5 = require("@timhortons/common/src/assets/images/ticTacToeImg5.png");
const bakingStationImg1 = require("@timhortons/common/src/assets/images/bakingStationImg1.jpg");
const bakingStationImg2 = require("@timhortons/common/src/assets/images/bakingStationImg2.jpg");
const bakingStationImg3 = require("@timhortons/common/src/assets/images/bakingStationImg3.jpg");
const bakingStationImg4 = require("@timhortons/common/src/assets/images/bakingStationImg4.jpg");
const bakingStationImg5 = require("@timhortons/common/src/assets/images/bakingStationImg5.jpg");
const bakingStationImg6 = require("@timhortons/common/src/assets/images/bakingStationImg6.jpg");
const bakingStationImg7 = require("@timhortons/common/src/assets/images/bakingStationImg7.jpg");
const bakingStationImg8 = require("@timhortons/common/src/assets/images/bakingStationImg8.jpg");
const bakingStationImg9 = require("@timhortons/common/src/assets/images/bakingStationImg9.jpg");
const certificateTitle = require("@timhortons/common/src/assets/images/certificateTitle.png");
const certificateLogo = require("@timhortons/common/src/assets/images/certificateLogo.png");
const profilePictureBg = require("@timhortons/common/src/assets/images/profilePictureBg.png");
const birthdayCakeTimbit = require("@timhortons/common/src/assets/images/birthdayCakeTimbit.png");
const chocolateGlazedTimbit = require("@timhortons/common/src/assets/images/chocolateGlazedTimbit.png");
const honeyDipTimbit = require("@timhortons/common/src/assets/images/honeyDipTimbit.png");
const certificate = require("@timhortons/common/src/assets/images/certificate.png");
const errManhole = require("@timhortons/common/src/assets/images/errManhole.png");
const chocolateGlazed = require("@timhortons/common/src/assets/images/choclateGlazed.png");
const birthdayTrophy = require("@timhortons/common/src/assets/images/birthdayTrophy.png");
const loginPage = require("@timhortons/common/src/assets/images/loginPage.json");
const nothingHereAnimated = require("@timhortons/common/src/assets/images/nothingHereAnimated.json");
const thiAcademyLogo = require("@timhortons/common/src/assets/images/thiAcademyLogo.png");
const birthdayCake = require("@timhortons/common/src/assets/images/birthdayCake.png");
const chcolateGlazed = require("@timhortons/common/src/assets/images/chcolateGlazed.png");
const honeyDip = require("@timhortons/common/src/assets/images/honeyDip.png");
const askUsAnimated = require("@timhortons/common/src/assets/images/askUsAnimated.json");
const noSearchNothingHere = require("@timhortons/common/src/assets/images/noSearchNothingHere.json");
const noDataNothingHere = require("@timhortons/common/src/assets/images/noDataNothingHere.json");
const searchAnimated = require("@timhortons/common/src/assets/images/searchAnimated.json");
const banner1 = require("@timhortons/common/src/assets/images/banner1.jpg");
const banner2 = require("@timhortons/common/src/assets/images/banner2.jpg");
const banner3 = require("@timhortons/common/src/assets/images/banner3.jpg");
const banner4 = require("@timhortons/common/src/assets/images/banner4.jpg");
const banner5 = require("@timhortons/common/src/assets/images/banner5.jpg");
const banner6 = require("@timhortons/common/src/assets/images/banner6.jpg");
const banner7 = require("@timhortons/common/src/assets/images/banner7.jpg");
const banner8 = require("@timhortons/common/src/assets/images/banner8.jpg");
const banner9 = require("@timhortons/common/src/assets/images/banner9.jpg");
const thiLogo = require("@timhortons/common/src/assets/images/thiLogo.png");
const dashboardAnimation = require("@timhortons/common/src/assets/images/dashboardAnimation.json");
const dashboardCertificate = require("@timhortons/common/src/assets/images/certificateDashboard.jpg");
const honeyRound = require("@timhortons/common/src/assets/images/honeyRound.png");
const chocoRound = require("@timhortons/common/src/assets/images/chocoRound.png");
const timbitRound = require("@timhortons/common/src/assets/images/timbitRound.png");
const blurCertificate = require("@timhortons/common/src/assets/images/blurCertificate.jpg");
const loginAnimation = require("@timhortons/common/src/assets/images/loginAnimation.json");
const profileImg = require("@timhortons/common/src/assets/images/profileImg.png");
const homeScreenIllustration = require("@timhortons/common/src/assets/images/homeScreenIllustration.png");
const timHortonsMasterLogo = require("@timhortons/common/src/assets/images/timHortonsMasterLogo.png");
const WirelessTechnology = require("@timhortons/common/src/assets/images/WirelessTechnology.json");

export {
  newErr,
  mapleLogo,
  goldMedal,
  silverMedal,
  bronzeMedal,
  careerLearning,
  redVector,
  correct,
  inCorrect,
  thankYouFeedback,
  cheers,
  pencil,
  download,
  bg1024,
  splash,
  bg1920,
  bg411,
  noImgFound,
  noDataFound,
  redLogo,
  gatewayTimeoutImg,
  pageNotFoundImg,
  loaderGif,
  imgPlaceholder,
  completedTick,
  jumbotronImg,
  thAcademyLogo,
  mastHeadImgResources,
  profilePicDefault,
  commentSaved,
  whoops,
  oopsCookie,
  done,
  completedCup,
  errorMessage,
  basketball,
  mugTim,
  mapledashboard,
  tickQuiz,
  crossQuiz,
  butterflyGraduation,
  stats,
  timGif,
  mcqSampleGif,
  learningpathstaytuned,
  timbetStack,
  assgnLearningDashboard1,
  assgnLearningDashboard2,
  assgnLearningDashboard3,
  noAudit,
  questionMark,
  bubble,
  whiteQuestion,
  redQuestion,
  passed,
  burger,
  nothingHere,
  noSearch,
  starPoor,
  starFair,
  starAverage,
  starHappy,
  starExcellent,
  certDesertsRail,
  ticTacToeImg1,
  ticTacToeImg2,
  ticTacToeImg3,
  ticTacToeImg4,
  ticTacToeImg5,
  bakingStationImg1,
  bakingStationImg2,
  bakingStationImg3,
  bakingStationImg4,
  bakingStationImg5,
  bakingStationImg6,
  bakingStationImg7,
  bakingStationImg8,
  bakingStationImg9,
  certificateTitle,
  certificateLogo,
  profilePictureBg,
  birthdayCakeTimbit,
  chocolateGlazedTimbit,
  honeyDipTimbit,
  certificate,
  errManhole,
  chocolateGlazed,
  birthdayTrophy,
  loginPage,
  nothingHereAnimated,
  thiAcademyLogo,
  birthdayCake,
  chcolateGlazed,
  honeyDip,
  askUsAnimated,
  noSearchNothingHere,
  noDataNothingHere,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner7,
  banner8,
  banner9,
  thiLogo,
  dashboardAnimation,
  dashboardCertificate,
  searchAnimated,
  honeyRound,
  chocoRound,
  timbitRound,
  blurCertificate,
  loginAnimation,
  banner6,
  profileImg,
  homeScreenIllustration,
  timHortonsMasterLogo,
  WirelessTechnology
};
