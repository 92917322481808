import {
  LMSUserCategoriesModel,
  LMSCategoriesListModel,
  LMSModuleListModel,
  LMSTopicModel,
  LMSPublishSubTopicsModel,
  LMSModuleData
} from "@timhortons/common/src/models/userLms/userLms";
import { secondsToHourMin } from "@timhortons/common/src/utils/dateFormat";
import { thiLogo } from "@timhortons/common/src/assets/images/imageAssets";

export const lmsModuleListFormatter = async (response: LMSModuleData): Promise<LMSModuleData> => {
  let data: LMSModuleData = {
    coreTraining: [],
    specialTraining: []
  };
  if (response) {
    let tempCoreData: any = [];
    let tempSpecialData: any = [];

    response.coreTraining &&
      response.coreTraining.length > 0 &&
      response.coreTraining.map((item: any) => {
        let { moduleDuration } = item;
        let updatedTime = secondsToHourMin(moduleDuration);
        tempCoreData.push({
          moduleId: item.moduleId,
          moduleName: item.moduleName,
          thumbNail: item.thumbNail,
          moduleDuration: updatedTime,
          status: item.status,
          completionPercentage: item.completePercentage,
          isClickable: true
        });
      });
    response.specialTraining &&
      response.specialTraining.length > 0 &&
      response.specialTraining.map((item: any) => {
        let { moduleDuration } = item;
        let updatedTime = secondsToHourMin(moduleDuration);
        tempSpecialData.push({
          moduleId: item.moduleId,
          moduleName: item.moduleName,
          thumbNail: item.thumbNail,
          moduleDuration: updatedTime,
          status: item.status,
          completionPercentage: item.completePercentage,
          isClickable: true
        });
      });
    data.coreTraining = [...tempCoreData];
    data.specialTraining = [...tempSpecialData];
    if (data.coreTraining.length < 10) {
      for (let i = data.coreTraining.length + 1; i <= 11; i++) {
        data.coreTraining.push({
          moduleName: `Module ${i}`,
          moduleId: `moduleId-${i}`,
          thumbNail: thiLogo,
          moduleDuration: {
            hr: 0,
            min: 0
          },
          status: "",
          completionPercentage: 0,
          isClickable: false
        });
      }
    }
  }
  return data;
};

export const getCategoriesFormatter = (
  response: LMSUserCategoriesModel
): LMSUserCategoriesModel => {
  let data: LMSCategoriesListModel[] = [];

  response.categories.map((item: any) => {
    let { totalDuration } = item;

    let updatedTime = secondsToHourMin(totalDuration);

    data.push({
      uuid: item.uuid,
      label: item.label,
      numberofVideos: item.numberofVideos,
      status: item.status,
      totalDuration: {
        hr: updatedTime.hr,
        min: updatedTime.min
      },
      completionPercentage: item.completionPercentage
    });
  });

  let updatedResponse = {
    moduleId: response.moduleId,
    moduleName: response.moduleName,
    categories: data,
    numberOfPtsChecklists: response.numberOfPtsChecklists,
    assessmentQuestions: response.assessmentQuestions,
    assessmentStatus: response.assessmentStatus,
    // assessmentState: response.assessmentState,
    ptsStatus: response.ptsStatus,
    assessmentDuration: response.assessmentDuration
  };
  return updatedResponse;
};

export const topicSubtopicFormatter = (response: any) => {
  let data: LMSTopicModel[] = [];
  let subData: LMSPublishSubTopicsModel[] = [];

  response.publishTopics.map((item: any) => {
    let { duration } = item;
    let updatedTime = secondsToHourMin(duration);
    subData = [];

    item?.publishSubTopics?.map((el: any) => {
      let { duration } = el;
      let updatedTime = secondsToHourMin(duration);
      subData.push({
        id: el.id,
        name: el.name,
        quiz: el.quiz,
        status: el.status,
        videoId: el.videoId,
        videoUrl: el.videoUrl,
        duration: updatedTime
      });
    });

    data.push({
      id: item.id,
      name: item.name,
      duration: updatedTime,
      publishSubTopics: subData,
      quiz: item.quiz,
      videoUrl: item.videoUrl,
      videoId: item.videoId,
      status: item.status
    });
  });
  return {
    id: response.id,
    name: response.name,
    status: response.status,
    publishTopics: data
  };
};

export const moduleFormatter = () => {};
