import { FaqQuestionModel } from "@timhortons/common/src/models/askTell/faqModule";

export const getFaqDataFormatter = async (
  data: FaqQuestionModel[]
): Promise<FaqQuestionModel[]> => {
  let tempArr: FaqQuestionModel[] = [];

  tempArr = [...data];
  let numOfQuesAdd = 10 - tempArr.length;
  for (let i = 0; i < numOfQuesAdd; i++) {
    let obj: FaqQuestionModel = { id: null, answer: "", question: "", image: null };
    tempArr.push({ ...obj });
  }
  return tempArr;
};
