import { loginForm } from "@timhortons/common/src/modules/iam/login";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ForgotPasswordForm } from "modules/iam/forgotPassword";
import { userAuthenticationEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { Roles } from "@timhortons/common/src/constants/roles";

class IamRepository {
  loginPost = async (body: loginForm): Promise<IUserDetails> => {
    const str = async (data: {
      id: string;
      username: string;
      firstName: string;
      lastName: string;
      name: string;
      role: any;
      employeeId: string;
      storeCode: number;
      accessToken: string;
      refreshToken: string;
      storeName: string;
    }): Promise<IUserDetails> => {
      await LocalStorage.setStorage("accessToken", data.accessToken);
      await LocalStorage.setStorage("storeCode", {
        storeCode: data.storeCode
      });
      await LocalStorage.setStorage("employeeId", { employeeId: data.employeeId });
      await LocalStorage.setStorage("role", data.role);
      await LocalStorage.setStorage("refreshToken", data.refreshToken);
      const user = {
        id: data.id,
        name: data.name,
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.username,
        storeCode: data.storeCode,
        roles: [data.role],
        empId: data.employeeId,
        storeName: data.storeName
      };
      await LocalStorage.setStorage("user", user);
      return user;
    };

    try {
      let p = { ...body };
      let length = p?.username?.length;
      let data;
      if (
        // (length >= 3 && length <= 4) ||
        length === 6 &&
        p?.username?.charAt(0) === "8"
      ) {
        data = await ApiClient.post(userAuthenticationEndPoints.storeAuthentication(), p);
        // let z=await ApiClient.get('/iam/v1/role/features')
        data.role = Roles.StoreAccess;
      } else if (length > 4) {
        data = await ApiClient.post(userAuthenticationEndPoints.fetchUserAuthentication(), p);
      }
      return await str(data);
    } catch (error) {
      let displayMessage = JSON.stringify(error) ?? "Try Again Later";
      return Promise.reject(displayMessage);
    }
  };

  forgotPasswordPost = async (body: ForgotPasswordForm): Promise<string> => {
    const { userName } = body;
    return userName;
  };
  async getUserById(id: string) {
    // let res = ApiClient.get('/user/employee/' + id, null, { [headerKey]: '54827354-603d-448d-977e-8ebfbb74ee00' })
  }
  async fetchUserMasterModules() {
    let data = await ApiClient.get(userAuthenticationEndPoints.fetchMasterModules(), null, {
      "x-api-key": "13ac5b13-e973-48e1-82d1-4056252b0459"
    });
    return data;
  }
}
const iamRepositoryObj = new IamRepository();
export { iamRepositoryObj as IamRepository };
