import React, { useEffect, useState } from "react";
import { LayoutChangeEvent, ScrollView, View } from "react-native";
import { Cell } from "@timhortons/common/src/components/molecules/table";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { styles } from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable/styles";
import {
  ProductItems,
  StationProducts
} from "@timhortons/common/src/models/productionSheet/psModels";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
interface IProps {
  tableData: StationProducts;
  showArrowIcon: boolean;
  accordionExpand: boolean;
  setAccordionExpand: Function;
  headerHeightCallBack: Function;
  index: number;
  loading: boolean;
  item: StationProducts;
  clickedCategory: string;
  translate: any;
}
export default function ProductionSheetLeft(props: IProps) {
  const {
    tableData,
    showArrowIcon,
    accordionExpand,
    setAccordionExpand,
    headerHeightCallBack,
    index,
    loading,
    item,
    clickedCategory,
    translate
  } = props;
  const [tableDataList, setTableDataList] = useState<StationProducts>(tableData);
  const [headerHeight, setHeaderHeight] = useState<number>();

  useEffect(() => {
    setTableDataList(tableData);
  }, [tableData]);

  function onPageLayout(e: LayoutChangeEvent) {
    setHeaderHeight(e.nativeEvent.layout.height);
  }

  useEffect(() => {
    headerHeightCallBack(index, headerHeight);
  }, [headerHeight]);

  return (
    <ScrollView>
      <View onLayout={onPageLayout}>
        <Cell
          cellStyle={styles.headerCell}
          fontWeight={FontWeight.SemiBold}
          onPress={() => setAccordionExpand(tableDataList?.name)}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Text
              textSize={TextSize.ExtraRegular}
              fontWeight={FontWeight.SemiBold}
              testId="header"
              textStyle={{ marginEnd: 4, flex: 0.9, paddingStart: 24 }}
            >
              {tableDataList?.name}
            </Text>
            {showArrowIcon && (
              <Icon
                name={accordionExpand ? IconNames.up : IconNames.down}
                size={12}
                customStyle={{ flex: 0.1 }}
              />
            )}
          </View>
        </Cell>
      </View>
      {loading
        ? clickedCategory === item.id && <Loader type="component" />
        : tableDataList && tableDataList.productList.length > 0
        ? accordionExpand &&
          tableDataList.productList.map((item: ProductItems, index: number) => (
            <Cell numberOfLines={1} key={index} title={item.name} cellStyle={styles.tableCell} />
          ))
        : accordionExpand && (
            <Text
              textSize={TextSize.Regular}
              fontWeight={FontWeight.Regular}
              testId="header"
              textStyle={{ padding: 24, color: colorPallete.grey2 }}
            >
              {translate("noDataAvailable")}
            </Text>
          )}
      {/* {accordionExpand &&
        tableDataList &&
        tableDataList.productList.map((item: ProductItems, index: number) => (
          <Cell numberOfLines={1} key={index} title={item.name} cellStyle={styles.tableCell} />
        ))} */}
      {!accordionExpand && <View style={styles.divider} />}
    </ScrollView>
  );
}
