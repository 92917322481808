import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import {
  practicalAssessmentEndPoints,
  profileEndPoints
} from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys, ProfileKeys, REVKeys } from "@timhortons/common/src/constants/apiKeys";
import { TopicsAndChecklistModel } from "@timhortons/common/src/models/practicalAssessment/practicalAssessment";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";

class PracticalAssessmentRepository {
  getAllModules = async (param: { employeeId: string }) => {
    // let {employeeId} = param;
    let data = await ApiClient.get(
      practicalAssessmentEndPoints.getAllModules(),
      param,
      LMSKeys.LMS_PRACTICAL_ASSESSMENT
    );
    data.forEach((element: any) => {
      element.isSelected = false;
    });

    let allModulesData: any[] = data;
    // data.map((item: any) => {
    //   let deserializevar: any = deserialize(IProductList, item);
    //   stationData.push(deserializevar);
    // });
    return allModulesData;
  };
  getAllTopicsAndCheckList = async (data: { employeeId: number; moduleId: string }) => {
    const { employeeId, moduleId } = data;
    let response = await ApiClient.get(
      practicalAssessmentEndPoints.getAllTopicsAndCheckList(employeeId, moduleId),
      null,
      LMSKeys.LMS_PRACTICAL_ASSESSMENT
    );
    // response.forEach((element: any) => {
    //   element.isSelected = false;
    // });

    let allTopicsAndCheckListData: TopicsAndChecklistModel[] = [];
    response.map((item: TopicsAndChecklistModel) => {
      let deserializevar: TopicsAndChecklistModel = deserialize(TopicsAndChecklistModel, item);
      allTopicsAndCheckListData.push(deserializevar);
    });
    return allTopicsAndCheckListData;
  };

  postChecklist = async (param: {
    employeeId: number;
    checklistId: string;
    points: number;
    answer: boolean;
    moduleId: string;
  }) => {
    let data = await ApiClient.post(
      practicalAssessmentEndPoints.postCheckList(),
      null,
      param,
      LMSKeys.LMS_PRACTICAL_ASSESSMENT
    );
    return data;
  };
  postImages = async (param: any): Promise<any> => {
    let headers = {
      ...LMSKeys.LMS_PRACTICAL_ASSESSMENT,
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      practicalAssessmentEndPoints.postImages(param),
      param.images,
      null,
      headers
    );
    return data;
  };
  deleteImages = async (param: any): Promise<any> => {
    let data = await ApiClient.delete(
      practicalAssessmentEndPoints.deleteImages(param),
      null,
      { imageIds: param },
      LMSKeys.LMS_PRACTICAL_ASSESSMENT
    );
    return data;
  };
  postModules = async (param: any): Promise<any> => {
    let data = await ApiClient.post(
      practicalAssessmentEndPoints.postModules(),
      null,
      param,
      LMSKeys.LMS_PRACTICAL_ASSESSMENT
    );
    return data;
  };
  getUserTableDetails = async (param: any): Promise<any> => {
    const obj = {
      keyword: param.search,
      pageNo: param.pageNumber,
      pageSize: param.pageSize
    };
    let data = await ApiClient.get(
      profileEndPoints.getUserTableDetails(),
      obj,
      // null,
      REVKeys.USER_ROLES
    );

    return data;
  };
  getUserInfo = async (param: { employeeId: string }): Promise<any> => {
    const { employeeId } = param;
    let response = await ApiClient.get(
      profileEndPoints.getIndividualProfile(employeeId),
      {},
      ProfileKeys.USER_ROLES
    );

    let data = {
      profileUrl: response?.profileUrl,
      firstName: response?.firstName,
      lastName: response?.lastName,
      employeeId: response?.employeeId
    };
    return data;
  };
}

const practicalAssessmentRepository = new PracticalAssessmentRepository();
export { practicalAssessmentRepository as practicalAssessmentRepository };
