import React from "react";
import { StyleSheet, View } from "react-native";
import LpOpportunityTable from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpReportsSummaryOpportunityTable";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import LpActionplanGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpActionPlanGraph";
import CommonBarGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/commonBarGraph";
import LpStoreRankingsTable from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpStoreRankingsTable";
import LpKpiTable from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpKpiTable";
import LpCriticalCategoriesTable from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpCriticalCategoriesTable";
import TotalAuditDetailsTable from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/totalAuditDetailsTable";
import {
  LpIndividualCriticalCategoriesModel,
  LpKPIDataModel,
  LpReportsSummaryOpportunityDataModel,
  LpStoreRankingDataModel
} from "@timhortons/common/src/models/lp/reports";
import { IBarChartOptions } from "@timhortons/web/src/components/molecules/barChart";
import { CEOEqvRoles, Roles, SuperAdminEqvRoles } from "@timhortons/common/src/constants/roles";
import { ILpReportsLoading } from "@timhortons/common/src/redux/lp/reducers/reportsReducer";

interface IProps {
  translate: any;
  user: IUserDetails;
  gradeBarChartData: IBarChartOptions;
  momBarChartData: IBarChartOptions;
  criticalPointsReportGraph: IBarChartOptions;
  criticalCategoriesTable: LpIndividualCriticalCategoriesModel[];
  lpKPIData: LpKPIDataModel[];
  opportunityData: LpReportsSummaryOpportunityDataModel[];
  storeRankingTableHeaderData: any;
  series: number[];
  labels: string[];
  totalReports: number;
  inProcessReport: number;
  actionPlanGraphHeight: number;
  showTopAndLastFive: boolean;
  kpiHeaderTitle: string;
  criticalPointHeaderTitle: string;
  storeRankingTableHeaderTitle: string;
  loading: boolean;
  storeRakingTableLoading: boolean;
  storeRakingTableError: string;
  storeRankingTableData: LpStoreRankingDataModel;
  pageNoHandler: Function;
  handleCountryPage: Function;
  handleAreaManagerPage: Function;
  handleLpAuditorPage: Function;
  pageNo: number;
  areaManagerTotalAuditData: any;
  countryTotalAuditData: any;
  lpAuditorTotalAuditData: any;
  lpAuditorPageNo: number;
  areaManagerPageNo: number;
  countryPageNo: number;
  totalAuditDetailsTablesLoading: ILpReportsLoading;
}

export default function LpReportSummaryTemplate(props: IProps) {
  const {
    translate,
    user,
    gradeBarChartData,
    momBarChartData,
    opportunityData,
    lpKPIData,
    storeRankingTableHeaderData,
    series,
    labels,
    totalReports,
    inProcessReport,
    actionPlanGraphHeight,
    showTopAndLastFive,
    kpiHeaderTitle,
    storeRankingTableHeaderTitle,
    criticalPointsReportGraph,
    criticalCategoriesTable,
    criticalPointHeaderTitle,
    loading,
    storeRakingTableLoading,
    storeRakingTableError,
    storeRankingTableData,
    pageNoHandler,
    pageNo,
    areaManagerTotalAuditData,
    countryTotalAuditData,
    lpAuditorTotalAuditData,
    handleCountryPage,
    handleAreaManagerPage,
    handleLpAuditorPage,
    lpAuditorPageNo,
    areaManagerPageNo,
    countryPageNo,
    totalAuditDetailsTablesLoading
  } = props;
  let allowedArr: Array<Roles> = [Roles.HeadLP];
  return (
    <View style={styles.container}>
      <View style={styles.firstElementConatiner}>
        <View style={styles.gradeGraphContainer}>
          <CommonBarGraph barChartData={gradeBarChartData} />
        </View>
        <View style={styles.momGraphContainer}>
          <CommonBarGraph barChartData={momBarChartData} />
        </View>
      </View>
      <View style={styles.secondElementConatiner}>
        <View style={styles.actionplanGraphContainer}>
          <LpActionplanGraph
            translate={translate}
            series={series}
            labels={labels}
            totalReports={totalReports}
            inProcessReport={inProcessReport}
            height={actionPlanGraphHeight}
            leftHeader={translate("Action Plans")}
          />
        </View>
        <View style={styles.fsGraphConatiner}>
          <CommonBarGraph barChartData={criticalPointsReportGraph} />
        </View>
      </View>
      {allowedArr.includes(user.roles[0]) && (
        <View style={styles.bsTableContainer}>
          <TotalAuditDetailsTable
            translate={translate}
            headerTitle={"By Country"}
            loading={totalAuditDetailsTablesLoading?.countryTotalAuditsData}
            data={countryTotalAuditData}
            handlePagination={handleCountryPage}
            pageNo={countryPageNo}
          />
        </View>
      )}
      {allowedArr.includes(user.roles[0]) && (
        <View style={styles.bsTableContainer}>
          <TotalAuditDetailsTable
            translate={translate}
            headerTitle={"LP Auditors"}
            loading={totalAuditDetailsTablesLoading?.lpAuditorTotalAuditsData}
            data={lpAuditorTotalAuditData}
            handlePagination={handleLpAuditorPage}
            pageNo={lpAuditorPageNo}
          />
        </View>
      )}
      {allowedArr.includes(user.roles[0]) && (
        <View style={styles.bsTableContainer}>
          <TotalAuditDetailsTable
            translate={translate}
            headerTitle={"Area Manager"}
            loading={totalAuditDetailsTablesLoading?.areaManagerTotalAuidtsData}
            data={areaManagerTotalAuditData}
            handlePagination={handleAreaManagerPage}
            pageNo={areaManagerPageNo}
          />
        </View>
      )}
      <View style={styles.bsTableContainer}>
        <LpCriticalCategoriesTable
          translate={translate}
          criticalCategoriesData={criticalCategoriesTable}
          headerTitle={criticalPointHeaderTitle}
          loading={loading}
        />
      </View>
      <View style={styles.bsTableContainer}>
        <LpKpiTable
          translate={translate}
          lpKPIData={lpKPIData}
          headerTitle={kpiHeaderTitle}
          loading={false}
        />
      </View>
      <View style={styles.opportunityTableContainer}>
        <LpOpportunityTable
          translate={translate}
          currentUserRoles={user && user.roles}
          opportunityData={opportunityData}
          loading={loading}
        />
      </View>
      <View style={styles.storeRankingTableContainer}>
        <LpStoreRankingsTable
          translate={translate}
          headerTitle={storeRankingTableHeaderTitle}
          tableHeaderData={storeRankingTableHeaderData}
          showTopAndLastFive={showTopAndLastFive}
          loading={storeRakingTableLoading}
          error={storeRakingTableError}
          storeRakingTableData={storeRankingTableData}
          handlePageNo={pageNoHandler}
          pageNo={pageNo}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colorPallete.white3,
    // borderRadius: 8,
    // padding: 30
  },
  firstElementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  secondElementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  gradeGraphContainer: {
    flex: 1
  },
  momGraphContainer: {
    flex: 1,
    marginLeft: 24
  },
  actionplanGraphContainer: {
    flex: 1
  },
  fsGraphConatiner: {
    flex: 1,
    marginLeft: 24
  },
  bsTableContainer: {
    marginBottom: 24
  },
  opportunityTableContainer: {
    marginBottom: 24
  },
  storeRankingTableContainer: {}
});
