import {
  AllAreaManagersListDataModel,
  KPIDataModel
} from "@timhortons/common/src/models/lp/reports";
import { SelectedMonthFilter } from "@timhortons/common/src/modules/lp/components/dashboard";
import { monthsGregorian } from "@timhortons/common/src/components/molecules/customsCalenderPickers";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";
import { REPORTBUILDER } from "../actions/actionTypes";

export interface ILoadingGenrateReportBuilder {
  all: boolean;
  [ComparsionLevel.Country]: boolean;
  [ComparsionLevel.Channels]: boolean;
  [ComparsionLevel.CountryManager]: boolean;
  [ComparsionLevel.OpsManager]: boolean;
  [ComparsionLevel.AreaManager]: boolean;
  [ComparsionLevel.Stores]: boolean;
  [ComparsionLevel.ClusterHeadOfOps]: boolean;
  [ComparsionLevel.LpAuditor]: boolean;
}

export interface IErrorGenrateReportBuilder {
  [ComparsionLevel.Country]: string;
  [ComparsionLevel.Channels]: string;
  [ComparsionLevel.CountryManager]: string;
  [ComparsionLevel.OpsManager]: string;
  [ComparsionLevel.AreaManager]: string;
  [ComparsionLevel.Stores]: string;
  [ComparsionLevel.ClusterHeadOfOps]: string;
  [ComparsionLevel.LpAuditor]: string;
}
export interface IReportsBuilderLoading {
  getAllCountries: boolean;
  getAllAreaManager: boolean;
  getAllAreaTrainer: boolean;
  getAllOpsManager: boolean;
  getAllChannels: boolean;
  getAllStores: boolean;
  getAllCountryManager: boolean;
  getAllClusterHeadOfOps: boolean;
  getAllLpAuditor: boolean;
  comparisonCountryManagerAdmin: boolean;
  comparisonAreaManagerAdmin: boolean;
  comparisonStoresAdmin: boolean;
  highLowChannelsAdmin: boolean;
  generatedReportBuilderResponse: ILoadingGenrateReportBuilder;
  gradingTableData: boolean;
  getKPIData: boolean;
  getCategory: boolean;
  getProduct: boolean;
}
export interface IReportsBuilderError {
  getAllCountries: string;
  getAllAreaManager: string;
  getAllAreaTrainer: string;
  getAllOpsManager: string;
  getAllChannels: string;
  getAllStores: string;
  getAllCountryManager: string;
  getAllClusterHeadOfOps: string;
  getAllLpAuditor: string;
  comparisonCountryManagerAdmin: string;
  comparisonAreaManagerAdmin: string;
  comparisonStoresAdmin: string;
  highLowChannelsAdmin: string;
  generatedReportBuilderResponse: IErrorGenrateReportBuilder;
  gradingTableData: string;
  getKPIData: string;
  getCategory: string;
  getProduct: string;
}
export interface IReportsBuilderState {
  error: IReportsBuilderError;
  monthFilter: SelectedMonthFilter;
  loading: IReportsBuilderLoading;
  getAllAreaManager: AllAreaManagersListDataModel;
  getAllAreaTrainer: any;
  getAllOpsManager: any;
  getAllChannels: ChannelsListDataModel;
  getAllStores: AllStoresListDataModel;
  getAllCountryManager: AllAreaManagersListDataModel;
  // TODO:Model
  getAllClusterHeadOfOps: AllAreaManagersListDataModel;
  getAllLpAuditor: AllAreaManagersListDataModel;
  getAllCountries: CountryDataListModel;
  comparisonCountryManagerAdmin: any;
  comparisonAreaManagerAdmin: any;
  comparisonStoresAdmin: any;
  highLowChannelsAdmin: any;
  generatedReportBuilderResponse: {
    reportType: string;
    startDate: string;
    endDate: string;
    reportData: any[];
  };
  gradingTableData: any;
  kpiDataList: KPIDataModel[];
  getCategory: any;
  getProduct: any;
}

export const ReportsBuilderInitialState: IReportsBuilderState = {
  monthFilter: {
    year: new Date().getFullYear(),
    month: {
      label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
      id: new Date().getMonth()
    }
  },
  getAllCountries: null,
  getAllAreaManager: null,
  getAllAreaTrainer: null,
  getAllOpsManager: null,
  getAllStores: null,
  getAllChannels: null,
  getAllCountryManager: null,
  getAllClusterHeadOfOps: null,
  getAllLpAuditor: null,
  comparisonCountryManagerAdmin: null,
  comparisonAreaManagerAdmin: null,
  comparisonStoresAdmin: null,
  highLowChannelsAdmin: null,
  gradingTableData: null,
  getCategory: null,
  getProduct: null,
  error: {
    getAllCountries: null,
    getAllAreaManager: null,
    getAllAreaTrainer: null,
    getAllOpsManager: null,
    getAllChannels: null,
    getAllStores: null,
    getAllCountryManager: null,
    getAllClusterHeadOfOps: null,
    getAllLpAuditor: null,
    comparisonCountryManagerAdmin: null,
    comparisonAreaManagerAdmin: null,
    comparisonStoresAdmin: null,
    highLowChannelsAdmin: null,
    generatedReportBuilderResponse: {
      [ComparsionLevel.Country]: null,
      [ComparsionLevel.Channels]: null,
      [ComparsionLevel.CountryManager]: null,
      [ComparsionLevel.OpsManager]: null,
      [ComparsionLevel.AreaManager]: null,
      [ComparsionLevel.Stores]: null,
      [ComparsionLevel.ClusterHeadOfOps]: null,
      [ComparsionLevel.LpAuditor]: null
    },
    gradingTableData: null,
    getKPIData: null,
    getCategory: null,
    getProduct: null
  },
  loading: {
    getAllAreaManager: false,
    getAllAreaTrainer: false,
    getAllOpsManager: false,
    getAllCountries: false,
    getAllChannels: false,
    getAllStores: false,
    getAllCountryManager: false,
    getAllClusterHeadOfOps: false,
    getAllLpAuditor: false,
    comparisonAreaManagerAdmin: false,
    comparisonCountryManagerAdmin: false,
    comparisonStoresAdmin: false,
    highLowChannelsAdmin: false,
    generatedReportBuilderResponse: {
      all: false,
      [ComparsionLevel.Country]: false,
      [ComparsionLevel.Channels]: false,
      [ComparsionLevel.CountryManager]: false,
      [ComparsionLevel.OpsManager]: false,
      [ComparsionLevel.AreaManager]: false,
      [ComparsionLevel.Stores]: false,
      [ComparsionLevel.ClusterHeadOfOps]: false,
      [ComparsionLevel.LpAuditor]: false
    },
    gradingTableData: false,
    getKPIData: false,
    getCategory: false,
    getProduct: false
  },

  generatedReportBuilderResponse: {
    reportType: null,
    startDate: null,
    endDate: null,
    reportData: []
  },
  kpiDataList: []
};

export const ReportsBuilderReducer = (
  state = ReportsBuilderInitialState,
  action: { payload: any; type: string }
): IReportsBuilderState => {
  switch (action.type) {
    case REPORTBUILDER.GET_ALL_COUNTRIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllCountries: true },
        error: { ...state.error, getAllCountries: null }
      };
    case REPORTBUILDER.GET_ALL_COUNTRIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllCountries: false },
        error: { ...state.error, getAllCountries: null },
        getAllCountries: action.payload
      };
    case REPORTBUILDER.GET_ALL_COUNTRIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllCountries: false },
        error: { ...state.error, getAllCountries: action.payload }
      };
    case REPORTBUILDER.GET_ALL_AREA_MANAGER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaManager: true },
        error: { ...state.error, getAllAreaManager: null }
      };
    case REPORTBUILDER.GET_ALL_AREA_MANAGER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaManager: false },
        error: { ...state.error, getAllAreaManager: null },
        getAllAreaManager: action.payload
      };
    case REPORTBUILDER.GET_ALL_AREA_MANAGER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaManager: false },
        error: { ...state.error, getAllAreaManager: action.payload }
      };
    case REPORTBUILDER.GET_ALL_AREA_TRAINER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaTrainer: true },
        error: { ...state.error, getAllAreaTrainer: null }
      };
    case REPORTBUILDER.GET_ALL_AREA_TRAINER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaTrainer: false },
        error: { ...state.error, getAllAreaTrainer: null },
        getAllAreaTrainer: action.payload
      };
    case REPORTBUILDER.GET_ALL_AREA_TRAINER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllAreaTrainer: false },
        error: { ...state.error, getAllAreaTrainer: action.payload }
      };
    case REPORTBUILDER.GET_ALL_OPS_MANAGER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllOpsManager: true },
        error: { ...state.error, getAllOpsManager: null }
      };
    case REPORTBUILDER.GET_ALL_OPS_MANAGER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllOpsManager: false },
        error: { ...state.error, getAllOpsManager: null },
        getAllOpsManager: action.payload
      };
    case REPORTBUILDER.GET_ALL_OPS_MANAGER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllOpsManager: false },
        error: { ...state.error, getAllOpsManager: action.payload }
      };

    case REPORTBUILDER.GET_ALL_CHANNELS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllChannels: true },
        error: { ...state.error, getAllChannels: null }
      };
    case REPORTBUILDER.GET_ALL_CHANNELS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllChannels: false },
        error: { ...state.error, getAllChannels: null },
        getAllChannels: action.payload
      };
    case REPORTBUILDER.GET_ALL_CHANNELS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllChannels: false },
        error: { ...state.error, getAllChannels: action.payload }
      };

    case REPORTBUILDER.GET_ALL_STORES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllStores: true },
        error: { ...state.error, getAllStores: null }
      };
    case REPORTBUILDER.GET_ALL_STORES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllStores: false },
        error: { ...state.error, getAllStores: null },
        getAllStores: action.payload
      };
    case REPORTBUILDER.GET_ALL_STORES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllStores: false },
        error: { ...state.error, getAllStores: action.payload }
      };
    case REPORTBUILDER.GET_ALL_COUNTRY_MANAGER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllCountryManager: true },
        error: { ...state.error, getAllCountryManager: null }
      };
    case REPORTBUILDER.GET_ALL_COUNTRY_MANAGER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllCountryManager: false },
        error: { ...state.error, getAllCountryManager: null },
        getAllCountryManager: action.payload
      };
    case REPORTBUILDER.GET_ALL_COUNTRY_MANAGER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllCountryManager: false },
        error: { ...state.error, getAllCountryManager: action.payload }
      };
    case REPORTBUILDER.GET_ALL_CLUSTER_HEAD_OF_OPS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllClusterHeadOfOps: true },
        error: { ...state.error, getAllClusterHeadOfOps: null }
      };
    case REPORTBUILDER.GET_ALL_CLUSTER_HEAD_OF_OPS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllClusterHeadOfOps: false },
        error: { ...state.error, getAllClusterHeadOfOps: null },
        getAllClusterHeadOfOps: action.payload
      };
    case REPORTBUILDER.GET_ALL_CLUSTER_HEAD_OF_OPS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllClusterHeadOfOps: false },
        error: { ...state.error, getAllClusterHeadOfOps: action.payload }
      };
    case REPORTBUILDER.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.LOADING:
      return {
        ...state,
        loading: { ...state.loading, comparisonCountryManagerAdmin: false },
        error: { ...state.error, comparisonCountryManagerAdmin: null }
      };
    case REPORTBUILDER.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, comparisonCountryManagerAdmin: false },
        error: { ...state.error, comparisonCountryManagerAdmin: null },
        comparisonCountryManagerAdmin: action.payload
      };
    case REPORTBUILDER.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.ERROR:
      return {
        ...state,
        loading: { ...state.loading, comparisonCountryManagerAdmin: false },
        error: { ...state.error, comparisonCountryManagerAdmin: action.payload }
      };

    case REPORTBUILDER.GET_COMPARISON_AREA_MANAGERS_ADMIN.LOADING:
      return {
        ...state,
        loading: { ...state.loading, comparisonAreaManagerAdmin: false },
        error: { ...state.error, comparisonAreaManagerAdmin: null }
      };
    case REPORTBUILDER.GET_COMPARISON_AREA_MANAGERS_ADMIN.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, comparisonAreaManagerAdmin: false },
        error: { ...state.error, comparisonAreaManagerAdmin: null },
        comparisonAreaManagerAdmin: action.payload
      };
    case REPORTBUILDER.GET_COMPARISON_AREA_MANAGERS_ADMIN.ERROR:
      return {
        ...state,
        loading: { ...state.loading, comparisonAreaManagerAdmin: false },
        error: { ...state.error, comparisonAreaManagerAdmin: action.payload }
      };
    case REPORTBUILDER.GET_COMPARISON_STORES_ADMIN.LOADING:
      return {
        ...state,
        loading: { ...state.loading, comparisonStoresAdmin: true },
        error: { ...state.error, comparisonStoresAdmin: null }
      };
    case REPORTBUILDER.GET_COMPARISON_STORES_ADMIN.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, comparisonStoresAdmin: false },
        error: { ...state.error, comparisonStoresAdmin: null },
        comparisonStoresAdmin: action.payload
      };
    case REPORTBUILDER.GET_COMPARISON_STORES_ADMIN.ERROR:
      return {
        ...state,
        loading: { ...state.loading, comparisonStoresAdmin: false },
        error: { ...state.error, comparisonStoresAdmin: action.payload }
      };
    case REPORTBUILDER.GET_HIGHLOW_CHANNELS_ADMIN.LOADING:
      return {
        ...state,
        loading: { ...state.loading, highLowChannelsAdmin: true },
        error: { ...state.error, highLowChannelsAdmin: null }
      };
    case REPORTBUILDER.GET_HIGHLOW_CHANNELS_ADMIN.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, highLowChannelsAdmin: false },
        error: { ...state.error, highLowChannelsAdmin: null },
        highLowChannelsAdmin: action.payload
      };
    case REPORTBUILDER.GET_HIGHLOW_CHANNELS_ADMIN.ERROR:
      return {
        ...state,
        loading: { ...state.loading, highLowChannelsAdmin: false },
        error: { ...state.error, highLowChannelsAdmin: action.payload }
      };
    case REPORTBUILDER.RESET_REPORT_BUILDER:
      state = ReportsBuilderInitialState;
      return state;

    case REPORTBUILDER.GET_KPI_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getKPIData: true },
        error: { ...state.error, getKPIData: null }
      };
    case REPORTBUILDER.GET_KPI_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getKPIData: false },
        error: { ...state.error, getKPIData: null },
        kpiDataList: action.payload
      };
    case REPORTBUILDER.GET_KPI_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getKPIData: false },
        error: { ...state.error, getKPIData: action.payload }
      };
    case REPORTBUILDER.GET_CATEGORY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCategory: true },
        error: { ...state.error, getCategory: null }
      };
    case REPORTBUILDER.GET_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCategory: false },
        error: { ...state.error, getCategory: null },
        getCategory: action.payload
      };
    case REPORTBUILDER.GET_CATEGORY.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCategory: false },
        error: { ...state.error, getCategory: action.payload }
      };
    case REPORTBUILDER.GET_PRODUCT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProduct: true },
        error: { ...state.error, getProduct: null }
      };
    case REPORTBUILDER.GET_PRODUCT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProduct: false },
        error: { ...state.error, getProduct: null },
        getProduct: action.payload
      };
    case REPORTBUILDER.GET_PRODUCT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProduct: false },
        error: { ...state.error, getProduct: action.payload }
      };
    case REPORTBUILDER.GET_ALL_LP_AUDITOR.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllLpAuditor: true },
        error: { ...state.error, getAllLpAuditor: null }
      };
    case REPORTBUILDER.GET_ALL_LP_AUDITOR.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllLpAuditor: false },
        error: { ...state.error, getAllLpAuditor: null },
        getAllLpAuditor: action.payload
      };
    case REPORTBUILDER.GET_ALL_LP_AUDITOR.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllLpAuditor: false },
        error: { ...state.error, getAllLpAuditor: action.payload }
      };
    case REPORTBUILDER.RESET_PRODUCT_DATA.LOADING:
      return {
        ...state,
        getProduct: null
      };

    default:
      return state;
  }
};
