import React, { useEffect, useMemo, useState } from "react";
import { StyleProp, TextStyle, View } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import MultiSelect, { IItem } from "@timhortons/common/src/components/molecules/multiSelect";
import RadioGroup from "@timhortons/common/src/components/molecules/radioGroup";
import ModalDropdown from "@timhortons/common/src/components/molecules/modalDropdown";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { CEOEqvRoles, Roles, SuperAdminEqvRoles } from "@timhortons/common/src/constants/roles";
import { levelOfReportStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/levelOfReports/levelOfReportStyles";
import { builderLevel, ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";
import {
  ReportTypeLevel,
  IPrevSelectedLORData,
  ISelectAllValues
} from "@timhortons/common/src/components/organisms/builder/template";
import { getField } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/levelOfReports/constants";
import { CountryDataModel } from "@timhortons/common/src/models/profile";

interface IProps {
  translate: any;
  user: IUserDetails;
  reportType: ReportTypeLevel;
  reportData: IReportData;
  detailedReportPermissions: ComparsionLevel[];
  setCountries: () => void;
  setChannel: () => void;
  setAreaManager: () => void;
  setStore: () => void;
  setCountryManager: () => void;
  setComparisionCountryManager: () => void;
  setOpsManager: () => void;
  setClusterHeadOfOps: () => void;
  setLpAuditor: () => void;
  setComparisionAreaManager: () => void;
  loading: any;
  setSelectedLevel?: Function;
  selectedLevel?: ComparsionLevel;
  handleCountrySelect?: Function;
  handleCountryManagerSelect?: Function;
  handleAreaManagerSelect?: Function;
  handleChannelSelect?: Function;
  handleStoreSelect?: Function;
  handleClusterHeadOfOpsSelect?: Function;
  handleLpAuditorSelect?: Function;
  handleOpsManagerSelect?: Function;
  getSelectedComparisonData?: Function;
  selectedDetailedData?: IReportData;
  selectedUnitReportData?: any;
  selectedUnitReportCountry?: any;
  setSelectedUnitReportCountry?: any;
  handleReset?: () => void;
  setComparisonStoreFlatList?: () => void;
  highlowChannelsFlatlist?: () => void;
  setCountrySearchText?: Function;
  setStoreSearchText?: Function;
  setChannelSearchText?: Function;
  setAreaManagerSearchText?: Function;
  setCountryManagerSearchText?: Function;
  setOpsManagerSearchText?: Function;
  setClusterHeadOfOpsSearchText?: Function;
  setLpAuditorSearchText?: Function;
  setStoresSelectAll?: Function;
  setAreaManagerSelectAll?: Function;
  setCountryManagerSelectAll?: Function;
  setChannelSelectAll?: Function;
  setCountrySelectAll?: Function;
  setOpsManagerSelectAll?: Function;
  setClusterHeadOfOpsSelectAll?: Function;
  setLpAuditorSelectAll?: Function;
  storesSelectAll?: boolean;
  areaManagerSelectAll?: boolean;
  countryManagerSelectAll?: boolean;
  channelSelectAll?: boolean;
  countrySelectAll?: boolean;
  opsManagerSelectAll?: boolean;
  clusterHeadOfOpsSelectAll?: boolean;
  lpAuditorSelectAll?: boolean;
  moduleType?: string;
  prevSelectedDetailedData: IPrevReportData;
  setPrevSelectedDetailedData: Function;
  prevSelectedLORData: IPrevSelectedLORData;
  setPrevSelectedLORData: Function;
  selectedCountryValue: any;
  handleResetSearch: boolean;
  reqSearch: string;
  setReqSearch: Function;
  reqSearchDetailed: any;
  setReqSearchDetailed: Function;
}

export interface IReportData {
  allCountries?: IItem[];
  allAreaManagers?: IItem[];
  allClusterHeadOfOps?: IItem[];
  allLpAuditor?: IItem[];
  allCountryManagers?: IItem[];
  allOpsManagers?: IItem[];
  allChannels?: IItem[];
  allStores?: IItem[];
}
export interface IPrevReportData extends IReportData {
  selectAllValues: ISelectAllValues;
}

export default function LPLevelOfReports(props: IProps) {
  const {
    translate,
    user,
    reportType,
    reportData,
    setSelectedLevel,
    selectedLevel,
    handleCountrySelect,
    handleCountryManagerSelect,
    handleAreaManagerSelect,
    handleChannelSelect,
    handleStoreSelect,
    handleClusterHeadOfOpsSelect,
    handleLpAuditorSelect,
    handleOpsManagerSelect,
    selectedCountryValue,
    getSelectedComparisonData,
    detailedReportPermissions,
    selectedDetailedData,
    selectedUnitReportData,
    selectedUnitReportCountry,
    setCountries,
    moduleType,
    setChannel,
    setAreaManager,
    setStore,
    setCountryManager,
    setComparisionCountryManager,
    setOpsManager,
    setClusterHeadOfOps,
    setLpAuditor,
    loading,
    setComparisionAreaManager,
    setComparisonStoreFlatList,
    handleReset,
    highlowChannelsFlatlist,
    setCountrySearchText,
    setStoreSearchText,
    setChannelSearchText,
    setAreaManagerSearchText,
    setCountryManagerSearchText,
    setOpsManagerSearchText,
    setClusterHeadOfOpsSearchText,
    setLpAuditorSearchText,
    setAreaManagerSelectAll,
    setCountryManagerSelectAll,
    setChannelSelectAll,
    setCountrySelectAll,
    setOpsManagerSelectAll,
    setClusterHeadOfOpsSelectAll,
    setLpAuditorSelectAll,
    setStoresSelectAll,
    areaManagerSelectAll,
    countryManagerSelectAll,
    channelSelectAll,
    countrySelectAll,
    opsManagerSelectAll,
    clusterHeadOfOpsSelectAll,
    lpAuditorSelectAll,
    storesSelectAll,
    prevSelectedDetailedData,
    setPrevSelectedDetailedData,
    prevSelectedLORData,
    setPrevSelectedLORData,
    handleResetSearch,
    setReqSearch,
    reqSearch,
    reqSearchDetailed,
    setReqSearchDetailed
  } = props;

  const {
    allCountries,
    allAreaManagers,
    allOpsManagers,
    allClusterHeadOfOps,
    allLpAuditor,
    allCountryManagers,
    allChannels,
    allStores
  } = {
    ...reportData
  };
  const dropdownFields = useMemo(() => getField(), []);
  const [dropdownData, setDropdownData] = useState(dropdownFields);
  const [prevSelectedCountry, setPrevSelectedCountry] = useState<CountryDataModel>(null);
  const [isResetProdSheet, setIsResetProdSheet] = useState<boolean>(false);
  const handlePress = (selLevel: ComparsionLevel) => {
    handleReset();
    setSelectedLevel(selLevel);
    setPrevSelectedCountry(null);
  };
  const comparisonOptions = [
    {
      name: "Country",
      key: ComparsionLevel.Country,
      onPress: () => handlePress(ComparsionLevel.Country),
      isSelected: ComparsionLevel.Country === selectedLevel
    },
    {
      name: "Cluster Head Of Ops",
      key: ComparsionLevel.ClusterHeadOfOps,
      onPress: () => handlePress(ComparsionLevel.ClusterHeadOfOps),
      isSelected: ComparsionLevel.ClusterHeadOfOps === selectedLevel
    },
    {
      name: "Channels",
      key: ComparsionLevel.Channels,
      onPress: () => handlePress(ComparsionLevel.Channels),
      isSelected: ComparsionLevel.Channels === selectedLevel
    },
    {
      name: "Country Manager",
      key: ComparsionLevel.CountryManager,
      onPress: () => handlePress(ComparsionLevel.CountryManager),
      isSelected: ComparsionLevel.CountryManager === selectedLevel
    },
    {
      name: "Ops Manager",
      key: ComparsionLevel.OpsManager,
      onPress: () => handlePress(ComparsionLevel.OpsManager),
      isSelected: ComparsionLevel.OpsManager === selectedLevel
    },
    {
      name: "Area Manager",
      key: ComparsionLevel.AreaManager,
      onPress: () => handlePress(ComparsionLevel.AreaManager),
      isSelected: ComparsionLevel.AreaManager === selectedLevel
    },
    {
      name: "Restaurant",
      key: ComparsionLevel.Stores,
      onPress: () => handlePress(ComparsionLevel.Stores),
      isSelected: ComparsionLevel.Stores === selectedLevel
    }
  ];
  const comparisonComplanceLpOptions = [
    {
      name: "Country",
      key: ComparsionLevel.Country,
      onPress: () => handlePress(ComparsionLevel.Country),
      isSelected: ComparsionLevel.Country === selectedLevel
    },
    {
      name: "Cluster Head Of Ops",
      key: ComparsionLevel.ClusterHeadOfOps,
      onPress: () => handlePress(ComparsionLevel.ClusterHeadOfOps),
      isSelected: ComparsionLevel.ClusterHeadOfOps === selectedLevel
    },
    {
      name: "Channels",
      key: ComparsionLevel.Channels,
      onPress: () => handlePress(ComparsionLevel.Channels),
      isSelected: ComparsionLevel.Channels === selectedLevel
    },
    {
      name: "Country Manager",
      key: ComparsionLevel.CountryManager,
      onPress: () => handlePress(ComparsionLevel.CountryManager),
      isSelected: ComparsionLevel.CountryManager === selectedLevel
    },
    {
      name: "Ops Manager",
      key: ComparsionLevel.OpsManager,
      onPress: () => handlePress(ComparsionLevel.OpsManager),
      isSelected: ComparsionLevel.OpsManager === selectedLevel
    },
    {
      name: "Area Manager",
      key: ComparsionLevel.AreaManager,
      onPress: () => handlePress(ComparsionLevel.AreaManager),
      isSelected: ComparsionLevel.AreaManager === selectedLevel
    },
    {
      name: "LP Auditor",
      key: ComparsionLevel.LpAuditor,
      onPress: () => handlePress(ComparsionLevel.LpAuditor),
      isSelected: ComparsionLevel.LpAuditor === selectedLevel
    },
    {
      name: "Restaurant",
      key: ComparsionLevel.Stores,
      onPress: () => handlePress(ComparsionLevel.Stores),
      isSelected: ComparsionLevel.Stores === selectedLevel
    }
  ];
  const prodSheetDetailedOptions = [
    {
      name: "Country",
      key: ComparsionLevel.Country,
      onPress: () => handlePress(ComparsionLevel.Country),
      isSelected: ComparsionLevel.Country === selectedLevel
    },
    {
      name: "Cluster Head Of Ops",
      key: ComparsionLevel.ClusterHeadOfOps,
      onPress: () => handlePress(ComparsionLevel.ClusterHeadOfOps),
      isSelected: ComparsionLevel.ClusterHeadOfOps === selectedLevel
    },
    {
      name: "Channels",
      key: ComparsionLevel.Channels,
      onPress: () => handlePress(ComparsionLevel.Channels),
      isSelected: ComparsionLevel.Channels === selectedLevel
    },
    {
      name: "Country Manager",
      key: ComparsionLevel.CountryManager,
      onPress: () => handlePress(ComparsionLevel.CountryManager),
      isSelected: ComparsionLevel.CountryManager === selectedLevel
    },
    {
      name: "Ops Manager",
      key: ComparsionLevel.OpsManager,
      onPress: () => handlePress(ComparsionLevel.OpsManager),
      isSelected: ComparsionLevel.OpsManager === selectedLevel
    },
    {
      name: "Area Manager",
      key: ComparsionLevel.AreaManager,
      onPress: () => handlePress(ComparsionLevel.AreaManager),
      isSelected: ComparsionLevel.AreaManager === selectedLevel
    },
    {
      name: "Restaurant",
      key: ComparsionLevel.Stores,
      onPress: () => handlePress(ComparsionLevel.Stores),
      isSelected: ComparsionLevel.Stores === selectedLevel
    }
  ];
  let prodSheetClusterHead = prodSheetDetailedOptions
    .slice(0, 1)
    .concat(prodSheetDetailedOptions.slice(2, 3))
    .concat(prodSheetDetailedOptions.slice(4, 7));

  let prodSheetopsManagers = prodSheetDetailedOptions
    .slice(2, 3)
    .concat(prodSheetDetailedOptions.slice(5, 7));

  let prodSheetAreaManagers = prodSheetDetailedOptions
    .slice(2, 3)
    .concat(prodSheetDetailedOptions.slice(6, 7));

  let prodSheetCountryManager = prodSheetDetailedOptions
    .slice(2, 3)
    .concat(prodSheetDetailedOptions.slice(4, 7));
  // const GradingComparisonOptions = [
  //   {
  //     name: "Country",
  //     key: ComparsionLevel.Country,
  //     onPress: () => handlePress(ComparsionLevel.Country),
  //     isSelected: ComparsionLevel.Country === selectedLevel
  //   },
  //   {
  //     name: "Channels",
  //     key: ComparsionLevel.Channels,
  //     onPress: () => handlePress(ComparsionLevel.Channels),
  //     isSelected: ComparsionLevel.Channels === selectedLevel
  //   },
  //   {
  //     name: "Country Manager",
  //     key: ComparsionLevel.CountryManager,
  //     onPress: () => handlePress(ComparsionLevel.CountryManager),
  //     isSelected: ComparsionLevel.CountryManager === selectedLevel
  //   },
  //   {
  //     name: "Ops Manager",
  //     key: ComparsionLevel.OpsManager,
  //     onPress: () => handlePress(ComparsionLevel.OpsManager),
  //     isSelected: ComparsionLevel.OpsManager === selectedLevel
  //   },
  //   {
  //     name: "Area Manager",
  //     key: ComparsionLevel.AreaManager,
  //     onPress: () => handlePress(ComparsionLevel.AreaManager),
  //     isSelected: ComparsionLevel.AreaManager === selectedLevel
  //   },
  //   {
  //     name: "Restaurant",
  //     key: ComparsionLevel.Stores,
  //     onPress: () => handlePress(ComparsionLevel.Stores),
  //     isSelected: ComparsionLevel.Stores === selectedLevel
  //   }
  // ];
  let areaManagerOptions = comparisonOptions.slice(1, 2).concat(comparisonOptions.slice(4, 5));
  let lpCMOptions = comparisonOptions.slice(2, 3).concat(comparisonOptions.slice(4, 7));
  let lpOpsOptions = comparisonOptions.slice(2, 3).concat(comparisonOptions.slice(5, 7));
  let lpAreaManagerOptions = comparisonOptions.slice(2, 3).concat(comparisonOptions.slice(6, 7));

  let countryManagerOptions = comparisonOptions
    .slice(3, 4)
    .concat(comparisonOptions.slice(1, 2))
    .concat(comparisonOptions.slice(4, 5));

  let clusterHeadOptions = comparisonOptions
    .slice(0, 1)
    .concat(comparisonOptions.slice(2, 3))
    .concat(comparisonOptions.slice(4, 7));

  const getRegularTextUI = (label: string, textStyle?: StyleProp<TextStyle>) => {
    return (
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.Regular}
        testId="dropdown"
        textStyle={[levelOfReportStyles.dropdownLabel, textStyle]}
      >
        {translate(label)}
      </Text>
    );
  };
  const handleDropdownChange = (index: number, val: any) => {
    let data = [...dropdownData];
    if (index > -1) {
      if (val.length > 0) {
        data[index].errorMsg = "";
      }
      data[index].value = val;
      setDropdownData(data);
    }
  };
  const checkApiCall = (item: any, apiCall: Function, label?: string) => {
    if (
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.HeadLP) ||
        // user?.roles?.includes(Roles.Admin) ||
        user?.roles?.includes(Roles.AuditorLP) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO)) &&
      reportType === ReportTypeLevel.Detailed &&
      moduleType !== builderLevel.isProdSheet
    ) {
      if (item.name !== "Select Country" && dropdownData[0].value.length > 0) {
        if (apiCall) {
          apiCall();
        }
      }
    }
    if (
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.AreaTrainer) ||
      user?.roles?.includes(Roles.OperationManager) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.InternalAuditor) ||
      user?.roles?.includes(Roles.SeniorOpsManager) ||
      user?.roles?.includes(Roles.ClusterHeadofOps) ||
      moduleType === builderLevel.isProdSheet
    ) {
      if (apiCall) {
        apiCall();
      }
    }

    if (
      (reportType === ReportTypeLevel.Comparison ||
        reportType === ReportTypeLevel.HighLow ||
        reportType === ReportTypeLevel.Grading) &&
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.HeadLP) ||
        // user?.roles?.includes(Roles.Admin) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedLevel !== ComparsionLevel.Channels
    ) {
      if (apiCall) {
        apiCall();
      }
    }
  };
  const handleEmpty = (item: any, label?: string) => {
    const havingCountrySingleSelectLevel: Array<ComparsionLevel> = [
      ComparsionLevel.Channels,
      ComparsionLevel.OpsManager,
      ComparsionLevel.AreaManager,
      ComparsionLevel.Stores,
      ComparsionLevel.LpAuditor
    ];
    const upperSpecialRoles: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.ClusterHeadofOps,
      Roles.HeadLP
    ];

    if (item) {
      if (reportType && reportType !== ReportTypeLevel.Detailed) {
        if (
          selectedLevel &&
          upperSpecialRoles.includes(user?.roles[0]) &&
          havingCountrySingleSelectLevel.includes(selectedLevel)
        ) {
          return selectedCountryValue ? "Nothing to select" : "Select country first";
        }
        return "Nothing to select";
      } else if (item.name !== "Select Country" && upperSpecialRoles.includes(user?.roles[0])) {
        //Detailed Report Type
        return selectedDetailedData &&
          selectedDetailedData.allCountries &&
          selectedDetailedData.allCountries.length
          ? "Nothing to select"
          : "Select country first";
      }
    } else if (
      (moduleType === builderLevel.isProdSheet && reportType === ReportTypeLevel.Detailed) ||
      reportType === ReportTypeLevel.AreaOfImprovement
    ) {
      if (
        selectedLevel &&
        upperSpecialRoles.includes(user?.roles[0]) &&
        havingCountrySingleSelectLevel.includes(selectedLevel)
      ) {
        return selectedCountryValue ? "Nothing to select" : "Select country first";
      }
      return "Nothing to select";
    }
  };

  const getMultiSelectUI = (
    label: string,
    allItems: IItem[],
    handleSelect?: Function,
    onEndReached?: () => void,
    loadingVar?: boolean,
    apiCall?: Function,
    setSearchText?: Function,
    setSelectAll?: Function,
    selectAll?: boolean,
    resetSearchText?: boolean,
    setReqSearch?: Function,
    reqSearch?: string
  ) => {
    const index = dropdownData.findIndex((item) => item.name === label);
    let item = dropdownData[index];
    return (
      <View style={levelOfReportStyles.dropdownContainer}>
        {getRegularTextUI(label)}
        <View style={levelOfReportStyles.dropdown}>
          <MultiSelect
            // loading={loadingVar}
            title={translate(
              label === "Select Operational Manager"
                ? "Select Ops Managers"
                : label === "Select Cluster Head of Ops"
                ? label
                : label + "s"
            )}
            allItems={allItems}
            handleSelectedValues={(vals: IItem[]) => {
              handleDropdownChange(
                index,
                vals.map((i) => i.value)
              );
              handleSelect && handleSelect(vals);
            }}
            emptyMsg={handleEmpty(item)}
            onEndReached={onEndReached}
            handleApiCall={() => checkApiCall(item, apiCall, label)}
            // selectedValues={resetSearchText ? [] : item && item.value}
            selectedValues={
              selectedLevel === ComparsionLevel.Channels &&
              reportType !== ReportTypeLevel.Detailed &&
              resetSearchText
                ? []
                : item && item.value
            }
            setSearchText={setSearchText}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            enableSelectAllInitialize={moduleType === builderLevel.isLp}
            isBuilder={true}
            resetSearchText={resetSearchText}
            setReqSearch={setReqSearch}
            reqSearch={reqSearch}
          />
        </View>
      </View>
    );
  };

  const getSingleSelectUI = (
    label: string,
    allItems: IItem[],
    handleSelect?: Function,
    apiCall?: Function,
    onEndReached?: () => void,
    isSearchReq?: boolean,
    setSearchText?: Function,
    isProdSheet?: boolean
  ) => {
    const defaultValueHandler = (defaultValue: string) => {
      if (defaultValue && defaultValue === prevSelectedCountry?.value) {
        handleSelect && handleSelect(prevSelectedCountry, true, label);
      }
    };
    const prodSheetResetSingleSelect = [
      "Select Channel",
      "Select Operational Manager",
      "Select Area Manager",
      "Select Store",
      "Select Restaurants",
      "Select Lp Auditor"
    ];
    const hasResetFunc =
      ((reportType === ReportTypeLevel.Detailed && moduleType === builderLevel.isProdSheet) ||
        reportType === ReportTypeLevel.AreaOfImprovement) &&
      prodSheetResetSingleSelect.includes(label);
    return (
      <View style={moduleType === builderLevel.isProdSheet ? { flex: 1 } : { flexBasis: "50%" }}>
        {getRegularTextUI(label)}
        <View style={levelOfReportStyles.dropdown}>
          <ModalDropdown
            isSearchRequired={isSearchReq}
            allItems={allItems}
            onEndReached={onEndReached}
            dropdownViewStyle={levelOfReportStyles.singleSelect}
            iconName={IconNames.filledDown}
            dropdownIconStyle={levelOfReportStyles.singleSelectIcon}
            selectedItemStyle={levelOfReportStyles.singleSelectedLabel}
            selectedValueDefault={
              prevSelectedCountry?.value
                ? prevSelectedCountry?.value
                : label === "Select Channel"
                ? selectedUnitReportData?.channel?.value
                : label === "Select Country"
                ? selectedUnitReportCountry?.data?.label
                : selectedUnitReportData
                ? selectedUnitReportData?.value
                : null
            }
            handleApiCall={() => checkApiCall(null, apiCall, label)}
            emptyMsg={handleEmpty(null, label)}
            setSearchText={setSearchText}
            onChangeCallBack={(option) => handleSelect && handleSelect(option, true, label)}
            defaultDropdownText={label}
            listContainerStyle={{ height: 300 }}
            isProdSheet={isProdSheet}
            // defaultValuexHandler={defaultValueHandler}
            resetSelectedItem={hasResetFunc ? isResetProdSheet : null}
            setResetSelectedItem={hasResetFunc ? setIsResetProdSheet : null}
          />
        </View>
      </View>
    );
  };
  const countrySingleSelect = () => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];
    if (
      allowedArr.includes(user.roles[0]) &&
      (reportType === ReportTypeLevel.Comparison ||
        reportType === ReportTypeLevel.HighLow ||
        reportType === ReportTypeLevel.Grading) &&
      (selectedLevel === ComparsionLevel.OpsManager ||
        selectedLevel === ComparsionLevel.AreaManager ||
        selectedLevel === ComparsionLevel.LpAuditor ||
        selectedLevel === ComparsionLevel.Stores)
    ) {
      return (
        <>
          {getSingleSelectUI(
            "Select Country",
            allCountries,
            getSelectedComparisonData,
            null,
            setCountries,
            true,
            setCountrySearchText,
            true
          )}
        </>
      );
    }
  };
  const psCountrySingleSelect = () => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];
    if (
      (allowedArr.includes(user.roles[0]) && reportType === ReportTypeLevel.Detailed) ||
      (allowedArr.includes(user.roles[0]) &&
        reportType === ReportTypeLevel.AreaOfImprovement &&
        (selectedLevel === ComparsionLevel.OpsManager ||
          selectedLevel === ComparsionLevel.AreaManager ||
          selectedLevel === ComparsionLevel.LpAuditor ||
          selectedLevel === ComparsionLevel.Stores))
    ) {
      return (
        <>
          {getSingleSelectUI(
            "Select Country",
            allCountries,
            getSelectedComparisonData,
            null,
            setCountries,
            true,
            setCountrySearchText,
            true
          )}
        </>
      );
    }
  };

  const getChannelComparsionUI = () => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];
    return (
      <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
        {(reportType === ReportTypeLevel.HighLow ||
          reportType === ReportTypeLevel.Comparison ||
          reportType === ReportTypeLevel.AreaOfImprovement ||
          reportType === ReportTypeLevel.Grading) &&
          allowedArr.includes(user.roles[0]) &&
          getSingleSelectUI(
            "Select Country",
            allCountries,
            getSelectedComparisonData,
            null,
            setCountries,
            true,
            setCountrySearchText,
            true
          )}
        {/* {(user?.roles?.includes(Roles.SuperAdmin) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.TrainingAndDevManager) ||
          user?.roles?.includes(Roles.HeadLP) ||
          user?.roles?.includes(Roles.CEO) ||
          user?.roles?.includes(Roles.COO) ||
          user?.roles?.includes(Roles.HrAndAdminDirector) ||
          user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
          user?.roles?.includes(Roles.ClusterHeadofOps) ||
          // user?.roles?.includes(Roles.Admin) ||
          user?.roles?.includes(Roles.AuditorLP)) &&
          moduleType !== builderLevel.isProdSheet &&
          (reportType !== ReportTypeLevel.HighLow && reportType !== ReportTypeLevel.Comparison) &&
          getMultiSelectUI(
            "Select Country",
            allCountries,
            getSelectedComparisonData,
            setCountries,
            loading.getAllCountries,
            null,
            setCountrySearchText,
            setCountrySelectAll,
            countrySelectAll
          )} */}
        {/* {(user?.roles?.includes(Roles.SuperAdmin) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.TrainingAndDevManager)) &&
          moduleType === builderLevel.isProdSheet &&
          reportType === ReportTypeLevel.Comparison &&
          getMultiSelectUI(
            "Select Country",
            allCountries,
            getSelectedComparisonData,
            setCountries,
            loading.getAllCountries,
            null,
            setCountrySearchText,
            setCountrySelectAll,
            countrySelectAll,
            false,
            setReqSearch,
            reqSearch
          )} */}
        {allowedArr.includes(user.roles[0]) &&
          moduleType === builderLevel.isProdSheet &&
          reportType === ReportTypeLevel.Detailed &&
          getSingleSelectUI(
            "Select Country",
            allCountries,
            getSelectedComparisonData,
            null,
            setCountries,
            true,
            setCountrySearchText,
            true
          )}
        {(reportType === ReportTypeLevel.HighLow ||
          reportType === ReportTypeLevel.Comparison ||
          reportType === ReportTypeLevel.Grading) &&
          getMultiSelectUI(
            "Select Channel",
            allChannels,
            handleChannelSelect,
            setChannel,
            loading.getAllChannels,
            null,
            setChannelSearchText,
            setChannelSelectAll,
            channelSelectAll,
            handleResetSearch,
            setReqSearch,
            reqSearch
          )}
        {reportType === ReportTypeLevel.Detailed &&
          moduleType === builderLevel.isProdSheet &&
          getSingleSelectUI(
            "Select Channel",
            allChannels,
            handleChannelSelect,
            null,
            setChannel,
            true,
            setChannelSearchText,
            true
          )}
        {reportType === ReportTypeLevel.AreaOfImprovement &&
          moduleType === builderLevel.isLp &&
          getSingleSelectUI(
            "Select Channel",
            allChannels,
            handleChannelSelect,
            null,
            setChannel,
            true,
            setChannelSearchText,
            true
          )}
      </View>
    );
  };

  const getDetailedReportUI = () => {
    return (
      <View style={levelOfReportStyles.detailedContainer}>
        {detailedReportPermissions.includes(ComparsionLevel.Country) &&
          getMultiSelectUI(
            "Select Country",
            allCountries,
            handleCountrySelect,
            setCountries,
            loading.getAllCountries,
            null,
            setCountrySearchText,
            setCountrySelectAll,
            countrySelectAll,
            false,
            (searchText: string) => setReqSearchDetailed("countries", searchText),
            reqSearchDetailed?.countries
          )}
        {detailedReportPermissions.includes(ComparsionLevel.ClusterHeadOfOps) &&
          getMultiSelectUI(
            "Select Cluster Head of Ops",
            allClusterHeadOfOps,
            handleClusterHeadOfOpsSelect,
            setClusterHeadOfOps,
            loading.getAllClusterHeadOfOps,
            null,
            setClusterHeadOfOpsSearchText,
            setClusterHeadOfOpsSelectAll,
            clusterHeadOfOpsSelectAll,
            handleResetSearch,
            (searchText: string) => setReqSearchDetailed("clusterHeadOfOps", searchText),
            reqSearchDetailed?.clusterHeadOfOps
          )}
        {detailedReportPermissions.includes(ComparsionLevel.CountryManager) &&
          getMultiSelectUI(
            "Select Country Manager",
            allCountryManagers,
            handleCountryManagerSelect,
            setCountryManager,
            loading.getAllCountryManager,
            null,
            setCountryManagerSearchText,
            setCountryManagerSelectAll,
            countryManagerSelectAll,
            handleResetSearch,
            (searchText: string) => setReqSearchDetailed("countryManagers", searchText),
            reqSearchDetailed?.countryManagers
          )}
        {detailedReportPermissions.includes(ComparsionLevel.Channels) &&
          getMultiSelectUI(
            "Select Channel",
            allChannels,
            handleChannelSelect,
            setChannel,
            loading.getAllChannels,
            null,
            setChannelSearchText,
            setChannelSelectAll,
            channelSelectAll,
            handleResetSearch,
            (searchText: string) => setReqSearchDetailed("channels", searchText),
            reqSearchDetailed?.channels
          )}
        {detailedReportPermissions.includes(ComparsionLevel.OpsManager) &&
          getMultiSelectUI(
            "Select Operational Manager",
            allOpsManagers,
            handleOpsManagerSelect,
            setOpsManager,
            loading.getAllOpsManager,
            null,
            setOpsManagerSearchText,
            setOpsManagerSelectAll,
            opsManagerSelectAll,
            handleResetSearch,
            (searchText: string) => setReqSearchDetailed("opsManagers", searchText),
            reqSearchDetailed?.opsManagers
          )}
        {detailedReportPermissions.includes(ComparsionLevel.AreaManager) &&
          getMultiSelectUI(
            "Select Area Manager",
            allAreaManagers,
            handleAreaManagerSelect,
            setAreaManager,
            loading.getAllAreaManager,
            null,
            setAreaManagerSearchText,
            setAreaManagerSelectAll,
            areaManagerSelectAll,
            handleResetSearch,
            (searchText: string) => setReqSearchDetailed("areaManagers", searchText),
            reqSearchDetailed?.areaManagers
          )}
        {detailedReportPermissions.includes(ComparsionLevel.LpAuditor) &&
          getMultiSelectUI(
            "Select LP Auditor",
            allLpAuditor,
            handleLpAuditorSelect,
            setLpAuditor,
            loading.getAllLpAuditor,
            null,
            setLpAuditorSearchText,
            setLpAuditorSelectAll,
            lpAuditorSelectAll,
            handleResetSearch,
            (searchText: string) => setReqSearchDetailed("lpAuditors", searchText),
            reqSearchDetailed?.lpAuditors
          )}
        {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
          getMultiSelectUI(
            "Select Store",
            allStores,
            handleStoreSelect,
            setStore,
            loading.getAllStores,
            null,
            setStoreSearchText,
            setStoresSelectAll,
            storesSelectAll,
            handleResetSearch,
            (searchText: string) => setReqSearchDetailed("stores", searchText),
            reqSearchDetailed?.stores
          )}
      </View>
    );
  };
  const getProdSheetReportUI = (isAOI: boolean) => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];
    return (
      <>
        {getRegularTextUI("Select Level", { marginBottom: 0 })}
        <RadioGroup
          selectedRadioOptionStyle={{ marginVertical: 8 }}
          unSelectedRadioOptionStyle={{ marginVertical: 8 }}
          radioOptions={
            user?.roles?.includes(Roles.SuperAdmin) ||
            user?.roles?.includes(Roles.TrainingAndDevLead) ||
            user?.roles?.includes(Roles.TrainingAndDevManager) ||
            user?.roles?.includes(Roles.CEO) ||
            user?.roles?.includes(Roles.COO) ||
            user?.roles?.includes(Roles.HrAndAdminDirector) ||
            user?.roles?.includes(Roles.ProductionAndDevExecutive)
              ? prodSheetDetailedOptions
              : isAOI && user?.roles?.includes(Roles.HeadLP)
              ? comparisonComplanceLpOptions
              : user?.roles?.includes(Roles.ClusterHeadofOps)
              ? prodSheetClusterHead
              : user?.roles?.includes(Roles.CountryManager)
              ? prodSheetCountryManager
              : user?.roles?.includes(Roles.SeniorOpsManager) ||
                user?.roles?.includes(Roles.OperationManager)
              ? prodSheetopsManagers
              : (user?.roles?.includes(Roles.AreaManager) ||
                  user?.roles?.includes(Roles.AreaTrainer)) &&
                prodSheetAreaManagers
          }
          isBorder
        />
        <View
          style={
            (selectedLevel === ComparsionLevel.Channels ||
              selectedLevel === ComparsionLevel.OpsManager ||
              selectedLevel === ComparsionLevel.AreaManager ||
              selectedLevel === ComparsionLevel.Stores ||
              selectedLevel === ComparsionLevel.LpAuditor) &&
            allowedArr.includes(user.roles[0])
              ? levelOfReportStyles.comparisonContainer
              : levelOfReportStyles.maxWidth
          }
        >
          {detailedReportPermissions.includes(ComparsionLevel.Country) &&
            selectedLevel === ComparsionLevel.Country &&
            getSingleSelectUI(
              "Select Country",
              allCountries,
              getSelectedComparisonData,
              null,
              setCountries,
              true,
              setCountrySearchText,
              true
            )}
          {detailedReportPermissions.includes(ComparsionLevel.CountryManager) &&
            selectedLevel === ComparsionLevel.CountryManager &&
            getSingleSelectUI(
              "Select Country Manager",
              allCountryManagers,
              getSelectedComparisonData,
              null,
              setComparisionCountryManager,
              true,
              setCountryManagerSearchText,
              true
            )}

          {detailedReportPermissions.includes(ComparsionLevel.ClusterHeadOfOps) &&
            selectedLevel === ComparsionLevel.ClusterHeadOfOps &&
            getSingleSelectUI(
              "Select Cluster Head of Ops",
              allClusterHeadOfOps,
              getSelectedComparisonData,
              null,
              setClusterHeadOfOps,
              true,
              setClusterHeadOfOpsSearchText,
              true
            )}
          <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
            {detailedReportPermissions.includes(ComparsionLevel.OpsManager) &&
              selectedLevel === ComparsionLevel.OpsManager &&
              psCountrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.OpsManager) &&
              selectedLevel === ComparsionLevel.OpsManager &&
              getSingleSelectUI(
                "Select Operational Manager",
                allOpsManagers,
                handleChannelSelect,
                null,
                setOpsManager,
                true,
                setOpsManagerSearchText,
                true
              )}
          </View>
          <View
            style={
              isAOI && user?.roles?.includes(Roles.HeadLP) && levelOfReportStyles.detailedContainer
            }
          >
            {isAOI &&
              user?.roles?.includes(Roles.HeadLP) &&
              selectedLevel === ComparsionLevel.LpAuditor &&
              psCountrySingleSelect()}
            {isAOI &&
              user?.roles?.includes(Roles.HeadLP) &&
              selectedLevel === ComparsionLevel.LpAuditor &&
              getSingleSelectUI(
                "Select Lp Auditor",
                allLpAuditor,
                handleLpAuditorSelect,
                null,
                setLpAuditor,
                true,
                setLpAuditorSearchText,
                true
              )}
          </View>
          <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
            {detailedReportPermissions.includes(ComparsionLevel.AreaManager) &&
              selectedLevel === ComparsionLevel.AreaManager &&
              psCountrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.AreaManager) &&
              selectedLevel === ComparsionLevel.AreaManager &&
              getSingleSelectUI(
                "Select Area Manager",
                allAreaManagers,
                handleChannelSelect,
                null,
                setComparisionAreaManager,
                true,
                setAreaManagerSearchText,
                true
              )}
          </View>

          {detailedReportPermissions.includes(ComparsionLevel.Channels) &&
            selectedLevel === ComparsionLevel.Channels &&
            getChannelComparsionUI()}
          <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
            {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
              selectedLevel === ComparsionLevel.Stores &&
              psCountrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
              selectedLevel === ComparsionLevel.Stores &&
              getSingleSelectUI(
                "Select Restaurants",
                allStores,
                handleChannelSelect,
                null,
                setComparisonStoreFlatList,
                true,
                setStoreSearchText,
                true
              )}
          </View>
        </View>
      </>
    );
  };
  const getComparisonReportUI = (isComparison: boolean) => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];

    return (
      <>
        {getRegularTextUI("Select Level", { marginBottom: 0 })}
        <RadioGroup
          selectedRadioOptionStyle={{ marginVertical: 8 }}
          unSelectedRadioOptionStyle={{ marginVertical: 8 }}
          radioOptions={
            moduleType === builderLevel.isRev
              ? user?.roles?.includes(Roles.SuperAdmin) ||
                user?.roles?.includes(Roles.HrAndAdminDirector) ||
                user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
                user?.roles?.includes(Roles.TrainingAndDevLead) ||
                user?.roles?.includes(Roles.TrainingAndDevManager) ||
                user?.roles?.includes(Roles.HeadLP) ||
                user?.roles?.includes(Roles.AuditorLP) ||
                // user?.roles?.includes(Roles.Admin) ||
                user?.roles?.includes(Roles.CEO) ||
                user?.roles?.includes(Roles.COO)
                ? comparisonOptions
                : user?.roles?.includes(Roles.AreaManager) ||
                  user?.roles?.includes(Roles.AreaTrainer)
                ? areaManagerOptions
                : user?.roles?.includes(Roles.CountryManager) ||
                  user?.roles?.includes(Roles.InternalAuditor) ||
                  user?.roles?.includes(Roles.OperationManager) ||
                  user?.roles?.includes(Roles.SeniorOpsManager)
                ? countryManagerOptions
                : user?.roles?.includes(Roles.ClusterHeadofOps) && clusterHeadOptions
              : moduleType === builderLevel.isProdSheet
              ? user?.roles?.includes(Roles.SuperAdmin) ||
                user?.roles?.includes(Roles.TrainingAndDevLead) ||
                user?.roles?.includes(Roles.TrainingAndDevManager) ||
                user?.roles?.includes(Roles.CEO) ||
                user?.roles?.includes(Roles.COO) ||
                user?.roles?.includes(Roles.HrAndAdminDirector) ||
                user?.roles?.includes(Roles.ProductionAndDevExecutive)
                ? prodSheetDetailedOptions
                : user?.roles?.includes(Roles.CountryManager)
                ? prodSheetCountryManager
                : user?.roles?.includes(Roles.ClusterHeadofOps)
                ? prodSheetClusterHead
                : user?.roles?.includes(Roles.SeniorOpsManager) ||
                  user?.roles?.includes(Roles.OperationManager)
                ? prodSheetopsManagers
                : (user?.roles?.includes(Roles.AreaManager) ||
                    user?.roles?.includes(Roles.AreaTrainer)) &&
                  prodSheetAreaManagers
              : moduleType === builderLevel.isLp &&
                (user?.roles?.includes(Roles.SuperAdmin) ||
                user?.roles?.includes(Roles.HrAndAdminDirector) ||
                user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
                user?.roles?.includes(Roles.TrainingAndDevLead) ||
                user?.roles?.includes(Roles.TrainingAndDevManager) ||
                // user?.roles?.includes(Roles.AuditorLP) ||
                // user?.roles?.includes(Roles.Admin) ||
                user?.roles?.includes(Roles.CEO) ||
                user?.roles?.includes(Roles.COO)
                  ? comparisonOptions
                  : user?.roles?.includes(Roles.ClusterHeadofOps)
                  ? clusterHeadOptions
                  : user?.roles?.includes(Roles.HeadLP)
                  ? comparisonComplanceLpOptions
                  : user?.roles?.includes(Roles.AreaManager)
                  ? lpAreaManagerOptions
                  : user?.roles?.includes(Roles.OperationManager) ||
                    user?.roles?.includes(Roles.SeniorOpsManager)
                  ? lpOpsOptions
                  : user?.roles?.includes(Roles.CountryManager) && lpCMOptions)
          }
          isBorder
        />
        <View
          style={
            (reportType === ReportTypeLevel.HighLow ||
              reportType === ReportTypeLevel.Comparison ||
              reportType === ReportTypeLevel.Grading) &&
            (selectedLevel === ComparsionLevel.Channels ||
              selectedLevel === ComparsionLevel.OpsManager ||
              selectedLevel === ComparsionLevel.AreaManager ||
              selectedLevel === ComparsionLevel.Stores ||
              selectedLevel === ComparsionLevel.LpAuditor) &&
            allowedArr.includes(user.roles[0])
              ? levelOfReportStyles.comparisonContainer
              : levelOfReportStyles.maxWidth
          }
        >
          {detailedReportPermissions.includes(ComparsionLevel.Country) &&
            selectedLevel === ComparsionLevel.Country &&
            getMultiSelectUI(
              "Select Country",
              allCountries,
              getSelectedComparisonData,
              setCountries,
              loading.getAllCountries,
              null,
              setCountrySearchText,
              setCountrySelectAll,
              countrySelectAll,
              false,
              setReqSearch,
              reqSearch
            )}
          {detailedReportPermissions.includes(ComparsionLevel.CountryManager) &&
            selectedLevel === ComparsionLevel.CountryManager &&
            getMultiSelectUI(
              "Select Country Manager",
              allCountryManagers,
              getSelectedComparisonData,
              setComparisionCountryManager,
              loading.comparisonCountryManagerAdmin,
              null,
              setCountryManagerSearchText,
              setCountryManagerSelectAll,
              countryManagerSelectAll,
              handleResetSearch,
              setReqSearch,
              reqSearch
            )}

          {detailedReportPermissions.includes(ComparsionLevel.ClusterHeadOfOps) &&
            selectedLevel === ComparsionLevel.ClusterHeadOfOps &&
            getMultiSelectUI(
              "Select Cluster Head of Ops",
              allClusterHeadOfOps,
              getSelectedComparisonData,
              setClusterHeadOfOps,
              loading.getAllClusterHeadOfOps,
              null,
              setClusterHeadOfOpsSearchText,
              setClusterHeadOfOpsSelectAll,
              clusterHeadOfOpsSelectAll,
              handleResetSearch,
              setReqSearch,
              reqSearch
            )}
          <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
            {detailedReportPermissions.includes(ComparsionLevel.OpsManager) &&
              selectedLevel === ComparsionLevel.OpsManager &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.OpsManager) &&
              selectedLevel === ComparsionLevel.OpsManager &&
              getMultiSelectUI(
                "Select Operational Manager",
                allOpsManagers,
                getSelectedComparisonData,
                setOpsManager,
                loading.getAllOpsManager,
                null,
                setOpsManagerSearchText,
                setOpsManagerSelectAll,
                opsManagerSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
          <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
            {detailedReportPermissions.includes(ComparsionLevel.LpAuditor) &&
              selectedLevel === ComparsionLevel.LpAuditor &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.LpAuditor) &&
              selectedLevel === ComparsionLevel.LpAuditor &&
              getMultiSelectUI(
                "Select LP Auditor",
                allLpAuditor,
                getSelectedComparisonData,
                setLpAuditor,
                loading.getAllLpAuditor,
                null,
                setLpAuditorSearchText,
                setLpAuditorSelectAll,
                lpAuditorSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>

          <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
            {detailedReportPermissions.includes(ComparsionLevel.AreaManager) &&
              selectedLevel === ComparsionLevel.AreaManager &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.AreaManager) &&
              selectedLevel === ComparsionLevel.AreaManager &&
              getMultiSelectUI(
                "Select Area Manager",
                allAreaManagers,
                getSelectedComparisonData,
                setComparisionAreaManager,
                loading.comparisonAreaManagerAdmin,
                null,
                setAreaManagerSearchText,
                setAreaManagerSelectAll,
                areaManagerSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>

          {detailedReportPermissions.includes(ComparsionLevel.Channels) &&
          selectedLevel === ComparsionLevel.Channels
            ? isComparison
              ? getChannelComparsionUI()
              : !isComparison && allowedArr.includes(user.roles[0])
              ? getChannelComparsionUI()
              : getMultiSelectUI(
                  "Select Channel",
                  allChannels,
                  getSelectedComparisonData,
                  highlowChannelsFlatlist,
                  loading.highLowChannelsAdmin,
                  null,
                  setChannelSearchText,
                  setChannelSelectAll,
                  channelSelectAll,
                  handleResetSearch,
                  setReqSearch,
                  reqSearch
                )
            : null}
          <View style={allowedArr.includes(user.roles[0]) && levelOfReportStyles.detailedContainer}>
            {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
              selectedLevel === ComparsionLevel.Stores &&
              countrySingleSelect()}
            {detailedReportPermissions.includes(ComparsionLevel.Stores) &&
              selectedLevel === ComparsionLevel.Stores &&
              getMultiSelectUI(
                "Select Store",
                allStores,
                getSelectedComparisonData,
                // user?.roles?.includes(Roles.Admin) ||
                user?.roles?.includes(Roles.SuperAdmin) ||
                  user?.roles?.includes(Roles.HrAndAdminDirector) ||
                  user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
                  user?.roles?.includes(Roles.TrainingAndDevLead) ||
                  user?.roles?.includes(Roles.TrainingAndDevManager) ||
                  user?.roles?.includes(Roles.CEO) ||
                  user?.roles?.includes(Roles.COO) ||
                  user?.roles?.includes(Roles.HeadLP) ||
                  user?.roles?.includes(Roles.AuditorLP)
                  ? setComparisonStoreFlatList
                  : setStore,
                loading.comparisonStoresAdmin,
                null,
                setStoreSearchText,
                setStoresSelectAll,
                storesSelectAll,
                handleResetSearch,
                setReqSearch,
                reqSearch
              )}
          </View>
        </View>
      </>
    );
  };

  useEffect(() => {
    //Re-Initializing the detailedReportType levelOfReports field
    if (prevSelectedDetailedData !== null && prevSelectedDetailedData?.allCountries?.length > 0) {
      // NOTE: Please don't change the order of this detailedMultiSelectLabels array
      const detailedMultiSelectLabels: Array<string> = [
        "Select Country", //0
        "Select Cluster Head of Ops", //1
        "Select Country Manager", //2
        "Select Channel", //3
        "Select Operational Manager", //4
        "Select Area Manager", //5
        "Select LP Auditor", //6
        "Select Store" //7
      ];
      const {
        allCountries,
        allAreaManagers,
        allClusterHeadOfOps,
        allLpAuditor,
        allCountryManagers,
        allOpsManagers,
        allChannels,
        allStores,
        selectAllValues
      } = prevSelectedDetailedData;
      const {
        storesSelectAll: recvstoresSelectAll,
        channelSelectAll: recvchannelSelectAll,
        areaManagerSelectAll: recvareaManagerSelectAll,
        opsManagerSelectAll: recvopsManagerSelectAll,
        countryManagerSelectAll: recvcountryManagerSelectAll,
        countrySelectAll: recvcountrySelectAll,
        clusterHeadOfOpsSelectAll: recvclusterHeadOfOpsSelectAll,
        lpAuditorSelectAll: recvlpAuditorSelectAll
      } = selectAllValues;
      detailedMultiSelectLabels.forEach((label: string) => {
        let vals: Array<any> = [];
        const index = dropdownData.findIndex((item) => item.name === label);
        switch (label) {
          case detailedMultiSelectLabels[0]:
            if (allCountries?.length > 0) {
              vals = allCountries;
              if (recvcountrySelectAll) {
                setCountrySelectAll && setCountrySelectAll(recvcountrySelectAll);
              } else {
                vals?.length > 0 && handleCountrySelect && handleCountrySelect(vals);
              }
            }
            break;
          case detailedMultiSelectLabels[1]:
            if (allClusterHeadOfOps?.length > 0) {
              vals = allClusterHeadOfOps;
              vals?.length > 0 &&
                handleClusterHeadOfOpsSelect &&
                handleClusterHeadOfOpsSelect(vals);
              setClusterHeadOfOpsSelectAll &&
                setClusterHeadOfOpsSelectAll(recvclusterHeadOfOpsSelectAll);
            }
            break;
          case detailedMultiSelectLabels[2]:
            if (allCountryManagers?.length > 0) {
              vals = allCountryManagers;
              vals?.length > 0 && handleCountryManagerSelect && handleCountryManagerSelect(vals);
              setCountryManagerSelectAll && setCountryManagerSelectAll(recvcountryManagerSelectAll);
            }
            break;
          case detailedMultiSelectLabels[3]:
            if (allChannels?.length > 0) {
              vals = allChannels;
              vals?.length > 0 && handleChannelSelect && handleChannelSelect(vals);
              setChannelSelectAll && setChannelSelectAll(recvchannelSelectAll);
            }
            break;
          case detailedMultiSelectLabels[4]:
            if (allOpsManagers?.length > 0) {
              vals = allOpsManagers;
              vals?.length > 0 && handleOpsManagerSelect && handleOpsManagerSelect(vals);
              setOpsManagerSelectAll && setOpsManagerSelectAll(recvopsManagerSelectAll);
            }
            break;
          case detailedMultiSelectLabels[5]:
            if (allAreaManagers?.length > 0) {
              vals = allAreaManagers;
              vals?.length > 0 && handleAreaManagerSelect && handleAreaManagerSelect(vals);
              setAreaManagerSelectAll && setAreaManagerSelectAll(recvareaManagerSelectAll);
            }
            break;
          case detailedMultiSelectLabels[6]:
            if (allLpAuditor?.length > 0) {
              vals = allLpAuditor;
              vals?.length > 0 && handleLpAuditorSelect && handleLpAuditorSelect(vals);
              setLpAuditorSelectAll && setLpAuditorSelectAll(recvlpAuditorSelectAll);
            }
            break;
          case detailedMultiSelectLabels[7]:
            if (allStores?.length > 0) {
              vals = allStores;
              vals?.length > 0 && handleStoreSelect && handleStoreSelect(vals);
              setStoresSelectAll && setStoresSelectAll(recvstoresSelectAll);
            }
            break;
          default:
            break;
        }
        if (index > -1 && vals?.length > 0) {
          handleDropdownChange(
            index,
            vals.map((i) => i.value)
          );
        }
      });
      setPrevSelectedDetailedData(null);
    }
  }, [
    prevSelectedDetailedData?.allAreaManagers,
    prevSelectedDetailedData?.allChannels,
    prevSelectedDetailedData?.allClusterHeadOfOps,
    prevSelectedDetailedData?.allCountries,
    prevSelectedDetailedData?.allCountryManagers,
    prevSelectedDetailedData?.allLpAuditor,
    prevSelectedDetailedData?.allOpsManagers,
    prevSelectedDetailedData?.allStores
  ]);
  useEffect(() => {
    //Re-Initializing the reportType except detailed levelOfReports field
    if (prevSelectedLORData?.selectedLevel) {
      const {
        selectedLevel,
        selectedUnitReportData,
        selectedUnitReportCountry,
        selectedCountry,
        selectAllValues
      } = prevSelectedLORData;
      const {
        storesSelectAll: recvstoresSelectAll,
        channelSelectAll: recvchannelSelectAll,
        areaManagerSelectAll: recvareaManagerSelectAll,
        opsManagerSelectAll: recvopsManagerSelectAll,
        countryManagerSelectAll: recvcountryManagerSelectAll,
        countrySelectAll: recvcountrySelectAll,
        clusterHeadOfOpsSelectAll: recvclusterHeadOfOpsSelectAll,
        lpAuditorSelectAll: recvlpAuditorSelectAll
      } = selectAllValues;
      const detailedMultiSelectLabels: Record<ComparsionLevel, string> = {
        [ComparsionLevel.Country]: "Select Country",
        [ComparsionLevel.CountryManager]: "Select Country Manager",
        [ComparsionLevel.ClusterHeadOfOps]: "Select Cluster Head of Ops",
        [ComparsionLevel.OpsManager]: "Select Operational Manager",
        [ComparsionLevel.LpAuditor]: "Select LP Auditor",
        [ComparsionLevel.AreaManager]: "Select Area Manager",
        [ComparsionLevel.Channels]: "Select Channel",
        [ComparsionLevel.Stores]: "Select Store"
      };
      const havingCountrySingleSelect: Array<ComparsionLevel> = [
        ComparsionLevel.Channels,
        ComparsionLevel.OpsManager,
        ComparsionLevel.AreaManager,
        ComparsionLevel.Stores,
        ComparsionLevel.LpAuditor
      ];
      const index = dropdownData.findIndex(
        (item) => item.name === detailedMultiSelectLabels[selectedLevel] || ""
      );
      if (selectedUnitReportData?.length > 0 && index > -1) {
        if (havingCountrySingleSelect.includes(selectedLevel)) {
          setPrevSelectedCountry(selectedCountry);
        } else {
          setPrevSelectedCountry(null);
        }
        handleDropdownChange(
          index,
          selectedUnitReportData.map((i: any) => i.value)
        );
        getSelectedComparisonData && getSelectedComparisonData(selectedUnitReportData);
        //selectAll
        switch (selectedLevel) {
          case ComparsionLevel.Country:
            setCountrySelectAll && setCountrySelectAll(recvcountrySelectAll);
            break;
          case ComparsionLevel.ClusterHeadOfOps:
            setClusterHeadOfOpsSelectAll &&
              setClusterHeadOfOpsSelectAll(recvclusterHeadOfOpsSelectAll);
            break;
          case ComparsionLevel.CountryManager:
            setCountryManagerSelectAll && setCountryManagerSelectAll(recvcountryManagerSelectAll);
            break;
          case ComparsionLevel.Channels:
            setChannelSelectAll && setChannelSelectAll(recvchannelSelectAll);
            break;
          case ComparsionLevel.OpsManager:
            setOpsManagerSelectAll && setOpsManagerSelectAll(recvopsManagerSelectAll);
            break;
          case ComparsionLevel.AreaManager:
            setAreaManagerSelectAll && setAreaManagerSelectAll(recvareaManagerSelectAll);
            break;
          case ComparsionLevel.LpAuditor:
            setLpAuditorSelectAll && setLpAuditorSelectAll(recvlpAuditorSelectAll);
            break;
          case ComparsionLevel.Stores:
            setStoresSelectAll && setStoresSelectAll(recvstoresSelectAll);
            break;
          default:
            break;
        }
      }
      setPrevSelectedLORData(null);
    }
  }, [prevSelectedLORData?.selectedLevel]);

  useEffect(() => {
    if (moduleType === builderLevel.isProdSheet) {
      setIsResetProdSheet(true);
    }
  }, [selectedUnitReportCountry]);
  useEffect(() => {
    if (reportType === ReportTypeLevel.AreaOfImprovement) {
      setIsResetProdSheet(true);
    }
  }, [selectedCountryValue]);

  return (
    <>
      {reportType !== ReportTypeLevel.ConsData && !user?.roles?.includes(Roles.StoreAccess) && (
        <Text
          textSize={TextSize.ExtraLarge}
          fontWeight={FontWeight.SemiBold}
          testId="levelReport"
          textStyle={levelOfReportStyles.levelReportText}
        >
          {translate("levelReport")}
        </Text>
      )}
      {reportType === ReportTypeLevel.Detailed &&
        !(moduleType === builderLevel.isProdSheet) &&
        getDetailedReportUI()}
      {moduleType === builderLevel.isProdSheet &&
        reportType === ReportTypeLevel.Detailed &&
        !user?.roles?.includes(Roles.StoreAccess) &&
        getProdSheetReportUI(false)}
      {moduleType === builderLevel.isProdSheet &&
        !user?.roles?.includes(Roles.StoreAccess) &&
        reportType === ReportTypeLevel.Comparison &&
        getComparisonReportUI(true)}
      {!(moduleType === builderLevel.isProdSheet) &&
        reportType === ReportTypeLevel.Comparison &&
        getComparisonReportUI(true)}
      {reportType === ReportTypeLevel.HighLow && getComparisonReportUI(false)}
      {reportType === ReportTypeLevel.Grading && getComparisonReportUI(true)}
      {reportType === ReportTypeLevel.AreaOfImprovement && getProdSheetReportUI(true)}
    </>
  );
}
