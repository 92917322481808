import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import {
  LmsFeedbackDataModel,
  LmsFeedbackResponseModel
} from "@timhortons/common/src/models/manageLms/lmsFeedback";
import { IFeedbackTexts } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/feedback/organisms/feedbackArrayConstants";

//* Action to get Feedback By Id
const getLmsFeedbackById = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.feedback.GET_MODULE_FEEDBACK_BY_ID.LOADING,
    payload: data
  };
};
const getLmsFeedbackByIdSuccess = (
  data: IFeedbackTexts[]
): IFluxStandardAction<IFeedbackTexts[]> => {
  return {
    type: MANAGELMS.feedback.GET_MODULE_FEEDBACK_BY_ID.SUCCESS,
    payload: data
  };
};
const getLmsFeedbackByIdError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.feedback.GET_MODULE_FEEDBACK_BY_ID.ERROR,
    payload: error
  };
};

//* Action to post module Feedback
const postLmsFeedback = (data: LmsFeedbackDataModel): IFluxStandardAction<LmsFeedbackDataModel> => {
  return {
    type: MANAGELMS.feedback.POST_MODULE_FEEDBACK.LOADING,
    payload: data
  };
};
const postLmsFeedbackSuccess = (
  data: LmsFeedbackResponseModel
): IFluxStandardAction<LmsFeedbackResponseModel> => {
  return {
    type: MANAGELMS.feedback.POST_MODULE_FEEDBACK.SUCCESS,
    payload: data
  };
};
const postLmsFeedbackError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.feedback.POST_MODULE_FEEDBACK.ERROR,
    payload: error
  };
};

//* Action to update module Feedback
const updateLmsFeedback = (data: {
  moduleId: string;
  obj: LmsFeedbackResponseModel;
}): IFluxStandardAction<{
  moduleId: string;
  obj: LmsFeedbackResponseModel;
}> => {
  return {
    type: MANAGELMS.feedback.UPDATE_FEEDBACK.LOADING,
    payload: data
  };
};
const updateLmsFeedbackSuccess = (
  data: LmsFeedbackResponseModel[]
): IFluxStandardAction<LmsFeedbackResponseModel[]> => {
  return {
    type: MANAGELMS.feedback.UPDATE_FEEDBACK.SUCCESS,
    payload: data
  };
};
const updateLmsFeedbackError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.feedback.UPDATE_FEEDBACK.ERROR,
    payload: error
  };
};

const resetFeedbackError = () => {
  return {
    type: MANAGELMS.feedback.RESET_FEEDBACK_ERROR
  };
};

export const LmsFeedbackAction = {
  getLmsFeedbackById,
  getLmsFeedbackByIdSuccess,
  getLmsFeedbackByIdError,
  postLmsFeedback,
  postLmsFeedbackSuccess,
  postLmsFeedbackError,
  updateLmsFeedback,
  updateLmsFeedbackSuccess,
  updateLmsFeedbackError,
  resetFeedbackError
};
