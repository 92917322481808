import { getBearerToken } from "@timhortons/common/src/network/authHelper";
import { IApiClientOptions } from "@timhortons/common/src/network/interfaces";
import { urlBuilder } from "@timhortons/common/src/utils/queryBuilder";
import { rnDownload } from "@timhortons/common/src/utils/download";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

interface IGetDocDownloaderProps {
  endPoint: any;
  params?: Object;
  xApiKey?: IApiClientOptions;
  customPrefix?: string;
}

export default async function documentDownloader(data: any, headers: any, url: string) {
  const resp = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers
  });

  const blob = await resp.blob();
  var file = new Blob([blob], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = fileURL;
  link.download = "report" + new Date() + ".pdf";
  link.click(); // window.open(fileURL);
  return "Done";
}

export async function getDocumentDownloader(props: IGetDocDownloaderProps) {
  const { endPoint, params = {}, xApiKey, customPrefix = "Report" } = props;

  const url = urlBuilder(endPoint, params);
  const bearerToken = await getBearerToken();
  const reportHeaders: HeadersInit = {
    contentType: "application/octet-stream",
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
    ...xApiKey
  };

  if (PlatformUtils.isWeb()) {
    const resp = await fetch(url, {
      method: "GET",
      headers: reportHeaders
    });
    if (resp === null || resp.status !== 200) return Promise.reject(new Error("Failed"));

    const blob = await resp.blob();
    var file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = customPrefix + new Date() + ".pdf";
    link.click(); // window.open(fileURL);
    return Promise.resolve("Done");
  } else {
    await rnDownload(`${customPrefix}.pdf`, url, reportHeaders)
      .then(() => Promise.resolve("Done"))
      .catch(() => Promise.reject(new Error("Failed")));
  }
}
