import { RESOURCES } from "@timhortons/common/src/redux/userResources/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  ResourcesFiltersModel,
  SearchResultsModel,
  SubCategoriesModel,
  SubTopicsModel,
  SubCategoryListModel,
  postCreateNewContentModel,
  ContentsModelList,
  MarkDownContentModel,
  SearchResultsResponseModel,
  putCategoryTitleModel,
  deleteSubCategoryTitleModel,
  deleteSubCategoryTitleDataModel
} from "@timhortons/common/src/models/userResources/userResources";
import { ISearchKeyword } from "modules/userResources";
import { ICheckBoxOption } from "components/molecules/checkBoxGroup";

const getContents = (): IFluxStandardAction => {
  return {
    type: RESOURCES.GET_CONTENTS.LOADING
  };
};
const getContentsSuccess = (
  data: ContentsModelList[]
): IFluxStandardAction<ContentsModelList[]> => {
  return {
    type: RESOURCES.GET_CONTENTS.SUCCESS,
    payload: data
  };
};
const getContentsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.GET_CONTENTS.ERROR
  };
};

const getPublishedContents = (): IFluxStandardAction => {
  return {
    type: RESOURCES.PUBLISHED_CATEGORIES.LOADING
  };
};
const getPublishedContentsSuccess = (
  data: ContentsModelList[]
): IFluxStandardAction<ContentsModelList[]> => {
  return {
    type: RESOURCES.PUBLISHED_CATEGORIES.SUCCESS,
    payload: data
  };
};
const getPublishedContentsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.PUBLISHED_CATEGORIES.ERROR
  };
};

const getSubCategoriesList = (data: {
  categoryId: number;
}): IFluxStandardAction<{ categoryId: number }> => {
  return {
    type: RESOURCES.GET_SUBCATEGORIES_LIST.LOADING,
    payload: data
  };
};
const getSubCategoriesListSuccess = (
  data: SubCategoriesModel
): IFluxStandardAction<SubCategoriesModel> => {
  return {
    type: RESOURCES.GET_SUBCATEGORIES_LIST.SUCCESS,
    payload: data
  };
};
const getSubCategoriesListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.GET_SUBCATEGORIES_LIST.ERROR,
    payload: error
  };
};

const getPublishedSubCategoriesList = (data: {
  categoryId: number;
}): IFluxStandardAction<{ categoryId: number }> => {
  return {
    type: RESOURCES.PUBLISHED_SUBCATEGORIES_LIST.LOADING,
    payload: data
  };
};
const getPublishedSubCategoriesListSuccess = (
  data: SubCategoriesModel
): IFluxStandardAction<SubCategoriesModel> => {
  return {
    type: RESOURCES.PUBLISHED_SUBCATEGORIES_LIST.SUCCESS,
    payload: data
  };
};
const getPublishedSubCategoriesListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.PUBLISHED_SUBCATEGORIES_LIST.ERROR,
    payload: error
  };
};

const getTopicContent = (data: {
  subCategoryId: string;
}): IFluxStandardAction<{ subCategoryId: string }> => {
  return {
    type: RESOURCES.GET_TOPIC_CONTENT.LOADING,
    payload: data
  };
};
const getTopicContentSuccess = (
  data: MarkDownContentModel
): IFluxStandardAction<MarkDownContentModel> => {
  return {
    type: RESOURCES.GET_TOPIC_CONTENT.SUCCESS,
    payload: data
  };
};
const getTopicContentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.GET_TOPIC_CONTENT.ERROR,
    payload: error
  };
};

const getPublishedTopicContent = (data: {
  subCategoryId: string;
}): IFluxStandardAction<{ subCategoryId: string }> => {
  return {
    type: RESOURCES.PUBLISHED_TOPIC_CONTENT.LOADING,
    payload: data
  };
};
const getPublishedTopicContentSuccess = (
  data: MarkDownContentModel
): IFluxStandardAction<MarkDownContentModel> => {
  return {
    type: RESOURCES.PUBLISHED_TOPIC_CONTENT.SUCCESS,
    payload: data
  };
};
const getPublishedTopicContentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.PUBLISHED_TOPIC_CONTENT.ERROR,
    payload: error
  };
};

const getSearchResultsLoading = (data: ISearchKeyword): IFluxStandardAction<ISearchKeyword> => {
  return {
    type: RESOURCES.GET_SEARCH_RESULTS.LOADING,
    payload: data
  };
};
const getSearchResultsSuccess = (
  data: Array<SearchResultsResponseModel>
): IFluxStandardAction<Array<SearchResultsResponseModel>> => {
  return {
    type: RESOURCES.GET_SEARCH_RESULTS.SUCCESS,
    payload: data
  };
};
const getSearchResultsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.GET_SEARCH_RESULTS.ERROR,
    payload: error
  };
};

const getFiltersLoading = (): IFluxStandardAction => {
  return {
    type: RESOURCES.GET_FILTERS.LOADING
  };
};
const getFiltersSuccess = (
  data: Array<ResourcesFiltersModel>
): IFluxStandardAction<Array<ResourcesFiltersModel>> => {
  return {
    type: RESOURCES.GET_FILTERS.SUCCESS,
    payload: data
  };
};
const getFiltersError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.GET_FILTERS.ERROR,
    payload: error
  };
};
const updateFilters = (data: ICheckBoxOption[]): IFluxStandardAction<ICheckBoxOption[]> => {
  return {
    type: RESOURCES.UPDATE_FILTERS.SUCCESS,
    payload: data
  };
};
const clearFilters = (): IFluxStandardAction => {
  return {
    type: RESOURCES.CLEAR_FILTERS.SUCCESS
  };
};
const clearSearchResults = (): IFluxStandardAction => {
  return {
    type: RESOURCES.CLEAR_SEARCH_RESULTS.SUCCESS
  };
};

const getSubCategoriesTopicList = (data: {
  subCategoryId: number;
}): IFluxStandardAction<{ subCategoryId: number }> => {
  return {
    type: RESOURCES.GET_SUBCATEGORIES_TOPICS.LOADING,
    payload: data
  };
};
const getSubCategoriesTopicListSuccess = (
  data: SubTopicsModel[]
): IFluxStandardAction<SubTopicsModel[]> => {
  return {
    type: RESOURCES.GET_SUBCATEGORIES_TOPICS.SUCCESS,
    payload: data
  };
};
const getSubCategoriesTopicListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.GET_SUBCATEGORIES_TOPICS.ERROR,
    payload: error
  };
};

const getPublishedSubCategoriesTopicList = (data: {
  subCategoryId: number;
}): IFluxStandardAction<{ subCategoryId: number }> => {
  return {
    type: RESOURCES.PUBLISHED_TOPIC_LIST.LOADING,
    payload: data
  };
};
const getPublishedSubCategoriesTopicListSuccess = (
  data: SubTopicsModel[]
): IFluxStandardAction<SubTopicsModel[]> => {
  return {
    type: RESOURCES.PUBLISHED_TOPIC_LIST.SUCCESS,
    payload: data
  };
};
const getPublishedSubCategoriesTopicListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.PUBLISHED_TOPIC_LIST.ERROR,
    payload: error
  };
};

const resetSubCategoryData = () => {
  return {
    type: RESOURCES.RESET_SUBCATEGORY_DATA
  };
};
const postSubCategory = (data: {
  categoryId: string;
  name: string;
  description: Array<Object>;
  subCategoryId?: string;
}): IFluxStandardAction<{
  categoryId: string;
  name: string;
  description: Array<Object>;
  subCategoryId?: string;
}> => {
  return {
    type: RESOURCES.POST_SUBCATEGORY.LOADING,
    payload: data
  };
};
const postSubCategorySuccess = (data: {
  subCategoryData: SubCategoryListModel;
  subCategoryId: string;
}): IFluxStandardAction<{
  subCategoryData: SubCategoryListModel;
  subCategoryId: string;
}> => {
  return {
    type: RESOURCES.POST_SUBCATEGORY.SUCCESS,
    payload: data
  };
};
const postSubCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.POST_SUBCATEGORY.ERROR,
    payload: error
  };
};
const postTopic = (data: {
  name: string;
  subCategoryId: string;
  content: any;
  topicId?: string;
  categoryId?: string;
}): IFluxStandardAction<{
  name: string;
  subCategoryId: string;
  content: any;
  topicId?: string;
  categoryId?: string;
}> => {
  return {
    type: RESOURCES.POST_TOPIC.LOADING,
    payload: data
  };
};
const postTopicSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: RESOURCES.POST_TOPIC.SUCCESS,
    payload: data
  };
};
const postTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.POST_TOPIC.ERROR,
    payload: error
  };
};
const topicDeletion = (data: {
  subCatId: string;
  topicId: string;
}): IFluxStandardAction<{ subCatId: string; topicId: string }> => {
  return {
    type: RESOURCES.TOPIC_DELETION.LOADING,
    payload: data
  };
};
const topicDeletionSuccess = (data: {
  topicId: string;
}): IFluxStandardAction<{ topicId: string }> => {
  return {
    type: RESOURCES.TOPIC_DELETION.SUCCESS,
    payload: data
  };
};
const topicDeletionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.TOPIC_DELETION.ERROR,
    payload: error
  };
};
const categoryDeletion = (data: { catId: string }): IFluxStandardAction<{ catId: string }> => {
  return {
    type: RESOURCES.CATEGORY_DELETION.LOADING,
    payload: data
  };
};
const categoryDeletionSuccess = (data: {
  catId: string;
}): IFluxStandardAction<{ catId: string }> => {
  return {
    type: RESOURCES.CATEGORY_DELETION.SUCCESS,
    payload: data
  };
};
const categoryDeletionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.CATEGORY_DELETION.ERROR,
    payload: error
  };
};
const postCreateNewContent = (data: { name: string }): IFluxStandardAction<{ name: string }> => {
  return {
    type: RESOURCES.POST_CREATE_NEW_CONTENT.LOADING,
    payload: data
  };
};
const postCreateNewContentSuccess = (data: postCreateNewContentModel): IFluxStandardAction<any> => {
  return {
    type: RESOURCES.POST_CREATE_NEW_CONTENT.SUCCESS,
    payload: data
  };
};
const postCreateNewContentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: RESOURCES.POST_CREATE_NEW_CONTENT.ERROR,
    payload: error
  };
};

const uploadResourceImageLoading = (data: any) => {
  return {
    type: RESOURCES.UPLOAD_IMAGES.LOADING,
    payload: data
  };
};
const uploadResourceImageSuccess = (data: any) => {
  return {
    type: RESOURCES.UPLOAD_IMAGES.SUCCESS,
    payload: data
  };
};

const uploadResourceImageError = (error: any) => {
  return {
    type: RESOURCES.UPLOAD_IMAGES.ERROR,
    payload: error
  };
};

const publishCategoryLoading = (data: string) => {
  return {
    type: RESOURCES.PUBLISH_CATEGORY.LOADING,
    payload: data
  };
};

const publishCategorySuccess = () => {
  return {
    type: RESOURCES.PUBLISH_CATEGORY.SUCCESS,
    payload: {}
  };
};

const publishCategoryError = () => {
  return {
    type: RESOURCES.PUBLISH_CATEGORY.ERROR,
    payload: {}
  };
};

const putCategoryTitleLoading = (data: { categoryId: string; name: string }) => {
  return {
    type: RESOURCES.PUT_CATEGORY_TITLE.LOADING,
    payload: data
  };
};

const putCategoryTitleSuccess = (data: putCategoryTitleModel) => {
  return {
    type: RESOURCES.PUT_CATEGORY_TITLE.SUCCESS,
    payload: data
  };
};

const putCategoryTitleError = (error: any) => {
  return {
    type: RESOURCES.PUT_CATEGORY_TITLE.ERROR,
    payload: error
  };
};

const deleteSubCategoryLoading = (data: deleteSubCategoryTitleDataModel) => {
  return {
    type: RESOURCES.DELETE_SUB_CATEGORY_TITLE.LOADING,
    payload: data
  };
};

const deleteSubCategorySuccess = (data: deleteSubCategoryTitleModel) => {
  return {
    type: RESOURCES.DELETE_SUB_CATEGORY_TITLE.SUCCESS,
    payload: data
  };
};

const deleteSubCategoryError = (error: any) => {
  return {
    type: RESOURCES.DELETE_SUB_CATEGORY_TITLE.ERROR,
    payload: error
  };
};

const resetPostSubCategoryData = () => {
  return {
    type: RESOURCES.RESET_POST_SUBCATEGORY_DATA
  };
};

export const ResourcesAction = {
  getSearchResultsLoading,
  getSearchResultsSuccess,
  getSearchResultsError,
  getFiltersLoading,
  getFiltersSuccess,
  getFiltersError,
  updateFilters,
  clearFilters,
  clearSearchResults,
  getContents,
  getContentsSuccess,
  getContentsError,
  getSubCategoriesList,
  getSubCategoriesListSuccess,
  getSubCategoriesListError,
  getSubCategoriesTopicList,
  getSubCategoriesTopicListSuccess,
  getSubCategoriesTopicListError,
  getTopicContent,
  getTopicContentSuccess,
  getTopicContentError,
  resetSubCategoryData,
  postSubCategory,
  postSubCategorySuccess,
  postSubCategoryError,
  postTopic,
  postTopicSuccess,
  postTopicError,
  topicDeletion,
  topicDeletionSuccess,
  topicDeletionError,
  categoryDeletion,
  categoryDeletionSuccess,
  categoryDeletionError,
  postCreateNewContent,
  postCreateNewContentSuccess,
  postCreateNewContentError,
  uploadResourceImageLoading,
  uploadResourceImageSuccess,
  uploadResourceImageError,
  publishCategoryLoading,
  publishCategorySuccess,
  publishCategoryError,
  getPublishedContents,
  getPublishedContentsSuccess,
  getPublishedContentsError,
  getPublishedSubCategoriesList,
  getPublishedSubCategoriesListSuccess,
  getPublishedSubCategoriesListError,
  getPublishedTopicContent,
  getPublishedTopicContentSuccess,
  getPublishedTopicContentError,
  getPublishedSubCategoriesTopicList,
  getPublishedSubCategoriesTopicListSuccess,
  getPublishedSubCategoriesTopicListError,
  putCategoryTitleLoading,
  putCategoryTitleSuccess,
  putCategoryTitleError,
  deleteSubCategoryLoading,
  deleteSubCategorySuccess,
  deleteSubCategoryError,
  resetPostSubCategoryData
};
