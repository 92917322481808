import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "reportBuilder";
const REPORTBUILDER = {
  GET_ALL_COUNTRIES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_COUNTRIES"),
  GET_ALL_COUNTRY_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_ALL_COUNTRY_MANAGER"),
  GET_ALL_CLUSTER_HEAD_OF_OPS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_ALL_CLUSTER_HEAD_OF_OPS"
  ),
  GET_ALL_OPS_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_ALL_OPS_MANAGER"),
  GET_ALL_AREA_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_ALL_AREA_MANAGER"),
  GET_ALL_AREA_TRAINER: ActionHelper.actionTypesCreator(prefix, "GET_ALL_AREA_TRAINER"),
  GET_ALL_CHANNELS: ActionHelper.actionTypesCreator(prefix, "GET_ALL_CHANNELS"),
  GET_ALL_STORES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_STORES"),
  GET_COMPARISON_COUNTRY_MANAGERS_ADMIN: ActionHelper.actionTypesCreator(
    prefix,
    "GET_COMPARISON_COUNTRY_MANAGERS_ADMIN"
  ),
  GET_COMPARISON_AREA_MANAGERS_ADMIN: ActionHelper.actionTypesCreator(
    prefix,
    "GET_COMPARISON_AREA_MANAGERS_ADMIN"
  ),
  GET_COMPARISON_STORES_ADMIN: ActionHelper.actionTypesCreator(
    prefix,
    "GET_COMPARISON_STORES_ADMIN"
  ),
  GET_HIGHLOW_CHANNELS_ADMIN: ActionHelper.actionTypesCreator(prefix, "GET_HIGHLOW_CHANNELS_ADMIN"),
  RESET_PRODUCT_DATA: ActionHelper.actionTypesCreator(prefix, "RESET_PRODUCT_DATA/RESET"),
  RESET_REPORT_BUILDER: "REV/RESET_REPORT_BUILDER/RESET",
  GET_KPI_DATA: ActionHelper.actionTypesCreator(prefix, "GET_KPI_DATA"),
  GET_CATEGORY: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORY"),
  GET_PRODUCT: ActionHelper.actionTypesCreator(prefix, "GET_PRODUCT"),
  GET_ALL_LP_AUDITOR: ActionHelper.actionTypesCreator(prefix, "GET_ALL_LP_Auditor")
};

export { REPORTBUILDER };
