import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";
import {
  LogShiftModel,
  ProductTemperatureCategoriesModel,
  WeeklyCleaningItemsModel,
  WeeklyCommentModel,
  WeeklyPostItemsResponseModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { CleaningActions } from "@timhortons/common/src/redux/logs/actions/mainLogs/cleaning";
import {
  ILogShift,
  IProdTempCategories,
  ITimeTempCategories
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";

function* getDeepCleaningItems(value: IFluxStandardAction<IProdTempApi>) {
  const { payload } = value;
  try {
    let data: WeeklyCleaningItemsModel[] = yield call(
      MainLogsRepository.getDeepCleaningItems,
      payload
    );
    yield put(CleaningActions.getDeepCleaningItemsSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "getDeepCleaningItems");
    yield put(CleaningActions.getDeepCleaningItemsError(error));
  }
}
function* postDeepCleaningItemsWorkerSaga(value: IFluxStandardAction<WeeklyCommentModel>) {
  const { payload } = value;
  try {
    let response: WeeklyPostItemsResponseModel = yield call(
      MainLogsRepository.postDeepCleaningItems,
      payload
    );
    yield put(
      CleaningActions.postDeepCleaningItemsSuccess({
        ...response,
        questionId: payload.questionId,
        categoryId: payload.categoryId
      })
    );
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "postDeepCleaningItemsWorkerSaga");
    yield put(CleaningActions.postDeepCleaningItemsError(error));
  }
}
function* getEmployeeDropDownWorkerSaga(value: IFluxStandardAction<{ storeCode: number }>) {
  const { payload } = value;
  try {
    let response: any[] = yield call(MainLogsRepository.getEmployeeDropDown, payload);
    yield put(CleaningActions.getEmployeeDropDownSuccess(response));
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "getEmployeeDropDownWorkerSaga");

    yield put(CleaningActions.getEmployeeDropDownError(error));
  }
}
function* getRestRoomItemsWorkerSaga(value: IFluxStandardAction<IProdTempApi>) {
  const { payload } = value;
  try {
    let response: {} = yield call(
      //TODO - model
      MainLogsRepository.getRestRoomItems,
      payload
    );
    yield put(CleaningActions.getCleaningRestRoomItemsSuccess(response));
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "getRestRoomItemsWorkerSaga");

    yield put(CleaningActions.getCleaningRestRoomItemsError(error));
  }
}
function* postRestRoomItemsWorkerSaga(value: IFluxStandardAction<any>) {
  const { payload } = value;
  try {
    let response: WeeklyPostItemsResponseModel = yield call(
      MainLogsRepository.postRestRoomItems,
      payload
    );
    let finalData = {
      ...response,
      questionId: payload.questionId,
      categoryId: payload.categoryId
    };
    yield put(
      CleaningActions.postCleaningRestRoomItemsSuccess(payload.questionId ? finalData : response)
    );
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "postRestRoomItemsWorkerSaga");

    yield put(CleaningActions.postCleaningRestRoomItemsError(error));
  }
}

function* getCleaningCategoriesWorkerSaga(
  value: IFluxStandardAction<{ actionName: string; storeCode: number; date: string }>
) {
  const { payload } = value;
  try {
    let response: ITimeTempCategories[] = yield call(
      MainLogsRepository.getTimeTempCategories,
      payload
    );
    yield put(CleaningActions.cleaningCategoriesSuccess(response));
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "getCleaningCategoriesWorkerSaga");

    yield put(CleaningActions.cleaningCategoriesError(error));
  }
}
function* getCleaningLogsShiftWatcherSaga(value: IFluxStandardAction<ILogShift>) {
  const { payload } = value;
  try {
    let data: LogShiftModel[] = yield call(MainLogsRepository.getLogShift, payload);
    yield put(CleaningActions.getCleaningLogShiftSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "getCleaningLogsShiftWatcherSaga");

    yield put(CleaningActions.getCleaningLogShiftError(error));
  }
}

function* getRestRoomProductsWatcherSaga(value: IFluxStandardAction<IProdTempCategories>) {
  const { payload } = value;
  try {
    let data: ProductTemperatureCategoriesModel[] = yield call(
      MainLogsRepository.getProductsCategory,
      payload
    );
    yield put(CleaningActions.getRestRoomProductsSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-cleaning", error, "getRestRoomProductsWatcherSaga");

    yield put(CleaningActions.getRestRoomProductsError(error));
  }
}

function* cleaningWatcherSaga() {
  yield takeLatest(LOGS.MainLogs.Cleaning.GET_DEEP_CLEANING_ITEMS.LOADING, getDeepCleaningItems);
  yield takeLatest(
    LOGS.MainLogs.Cleaning.GET_EMPLOYEE_DROPDOWN.LOADING,
    getEmployeeDropDownWorkerSaga
  );
  yield takeLatest(
    LOGS.MainLogs.Cleaning.POST_DEEP_CLEANING_ITEMS.LOADING,
    postDeepCleaningItemsWorkerSaga
  );
  yield takeLatest(LOGS.MainLogs.Cleaning.GET_RESTROOM_ITEMS.LOADING, getRestRoomItemsWorkerSaga);
  yield takeLatest(LOGS.MainLogs.Cleaning.POST_RESTROOM_ITEMS.LOADING, postRestRoomItemsWorkerSaga);
  yield takeLatest(
    LOGS.MainLogs.Cleaning.GET_CLEANING_CATEGORIES.LOADING,
    getCleaningCategoriesWorkerSaga
  );
  yield takeLatest(
    LOGS.MainLogs.Cleaning.GET_CLEANING_LOGS_SHIFT.LOADING,
    getCleaningLogsShiftWatcherSaga
  );
  yield takeLatest(
    LOGS.MainLogs.Cleaning.GET_RESTROOM_PRODUCTS.LOADING,
    getRestRoomProductsWatcherSaga
  );
}
export default cleaningWatcherSaga;
