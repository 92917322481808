import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { lpActionPlanEndPoints } from "@timhortons/common/src/constants/endPoints";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { ILPActionPlanData } from "@timhortons/common/src/modules/lp/components/dashboard/actionPlans";
import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import {
  DropDownModel,
  FilterDataModel,
  AuditorNameModel,
  LpActionPlanHeaderModel,
  LpAckDropDownModel,
  LpAcknowledgementModel,
  LpActionPlanCategoryDataModel,
  LpActionPlanTableModel,
  LpActionPlanAccordionDataModel,
  LpPostActionstepsModel
} from "@timhortons/common/src/models/lp/actionPlan";
import { Roles } from "@timhortons/common/src/constants/roles";

const finalObj = (storeObj: any, auditorObj: any, headAndIndividualObj: any, userRole: Roles) => {
  switch (userRole) {
    case Roles.StoreAccess:
      return storeObj;
    case Roles.AuditorLP:
      return auditorObj;
    default:
      return headAndIndividualObj;
  }
};
const actionplanStatus: Array<DropDownModel> = [
  {
    value: "COMPLETED",
    label: "Completed"
  },
  {
    value: "NOT_STARTED",
    label: "Not Started"
  },
  {
    value: "OVERDUE",
    label: "Overdue"
  }
];
class LPActionPlanRepository {
  getLpActionPlans = async (param: ILPActionPlanData) => {
    const userDetails: IUserDetails = await LocalStorage.getStorage("user");
    let { sortOrder, columnName } = param;
    let newDate = convert(param.date);
    let fromDate = convert(param.fromDate);
    let toDate = convert(param.toDate);
    let search = param.search === "" ? null : param.search;
    let auditorName = param.name === "" ? null : param.name;
    let storeObject: any = {
      storeCode: userDetails && userDetails.storeCode,
      pageNo: param.pageNumber,
      size: param.pageSize,
      filterByActionPlanStatus: param.status,
      filterByAuditor: auditorName,
      sortByDate: sortOrder
    };
    let headAndIndividualObject: any = {
      auditedBy: userDetails && userDetails.empId,
      pageNo: param.pageNumber,
      size: param.pageSize,
      filterByActionPlanStatus: param.status,
      filterByAuditor: auditorName,
      sortByDate: sortOrder
    };
    let auditorLPObject: any = {
      auditedBy: userDetails && userDetails.empId,
      pageNo: param.pageNumber,
      size: param.pageSize,
      filterByActionPlanStatus: param.status,
      sortByDate: sortOrder,
      filterByAuditor: auditorName
    };
    if (param.date !== null) {
      storeObject = {
        ...storeObject,
        filterByToDate: newDate,
        filterByFromDate: newDate
      };
      headAndIndividualObject = {
        ...headAndIndividualObject,
        filterByToDate: toDate,
        filterByFromDate: fromDate
      };
    }
    if (param.fromDate !== null) {
      storeObject = {
        ...storeObject,
        filterByToDate: toDate,
        filterByFromDate: fromDate
      };
      auditorLPObject = {
        ...auditorLPObject,
        filterByFromDate: fromDate,
        filterByToDate: toDate
      };
      headAndIndividualObject = {
        ...headAndIndividualObject,
        filterByToDate: toDate,
        filterByFromDate: fromDate
      };
    }
    if (param.search !== null) {
      auditorLPObject = {
        ...auditorLPObject,
        searchByKeyword: search
      };
      headAndIndividualObject = {
        ...headAndIndividualObject,
        searchByKeyword: search
      };
    }
    if (columnName === "Audit Date") {
      delete storeObject["sortByTime"];
      delete headAndIndividualObject["sortByTime"];
      delete auditorLPObject["sortByTime"];

      headAndIndividualObject = {
        ...headAndIndividualObject,
        sortByDate: sortOrder
      };
      storeObject = {
        ...storeObject,
        sortByDate: sortOrder
      };
      auditorLPObject = {
        ...auditorLPObject,
        sortByDate: sortOrder
      };
    }
    if (columnName === "Audit Time") {
      delete storeObject["sortByDate"];
      delete headAndIndividualObject["sortByDate"];
      delete auditorLPObject["sortByDate"];
      storeObject = {
        ...storeObject,
        sortByTime: sortOrder
      };
      headAndIndividualObject = {
        ...headAndIndividualObject,
        sortByTime: sortOrder
      };
      auditorLPObject = {
        ...auditorLPObject,
        sortByTime: sortOrder
      };
    }
    let data = await ApiClient.get(
      lpActionPlanEndPoints.getLpActionPlanTableData(),
      finalObj(storeObject, auditorLPObject, headAndIndividualObject, userDetails?.roles[0]),
      LPKeys.LP_ACTION_PLANS
    );
    return deserialize(LpActionPlanTableModel, data);
  };
  getLpFilterData = async (param: {
    storeCode: string;
    empId: number;
    isIndividualLogin: boolean;
  }) => {
    const { storeCode, empId, isIndividualLogin } = param;
    let auditedBy = empId;
    if (isIndividualLogin) {
      let data: AuditorNameModel[] = await ApiClient.get(
        lpActionPlanEndPoints.getLpAuditorsForActionPlan(),
        { auditedBy },
        LPKeys.LP_ACTION_PLANS
      );
      const finaldata: FilterDataModel = {
        auditorName: data,
        status: actionplanStatus
      };
      return finaldata;
    } else {
      let data: AuditorNameModel[] = await ApiClient.get(
        lpActionPlanEndPoints.getLpAuditorsForActionPlan(),
        { storeCode },
        LPKeys.LP_ACTION_PLANS
      );
      const finaldata: FilterDataModel = {
        auditorName: data,
        status: actionplanStatus
      };
      return finaldata;
    }
  };

  getUpdateActionPlanStatus = async (param: { actionPlanId: number }) => {
    return ApiClient.put(
      lpActionPlanEndPoints.updateLpActionPlanStatus(param.actionPlanId),
      null,
      null,
      LPKeys.LP_ACTION_PLANS
    );
  };

  getLpActionPlanDetails = async (param: { actionPlanId: number }) => {
    let data: { status: string; data: LpActionPlanHeaderModel } = await ApiClient.get(
      lpActionPlanEndPoints.getLpActionPlanHeaderData(param.actionPlanId),
      null,
      LPKeys.LP_ACTION_PLANS
    );

    return deserialize(LpActionPlanHeaderModel, data);
  };

  getLpActionPlanCategoryTableData = async (param: { auditId: number; actionPlanId: number }) => {
    const { actionPlanId, auditId } = param;
    let data: LpActionPlanCategoryDataModel[] = await ApiClient.get(
      lpActionPlanEndPoints.getLpActionPlanCategoryTableData(auditId, actionPlanId),
      null,
      LPKeys.LP_ACTION_PLANS
    );

    let categories: LpActionPlanCategoryDataModel[] = [];
    data.forEach((item: LpActionPlanCategoryDataModel) => {
      let deserializevar: LpActionPlanCategoryDataModel = deserialize(
        LpActionPlanCategoryDataModel,
        item
      );
      categories.push(deserializevar);
    });
    return categories;
  };
  getLpActionPlanAccordianData = async (param: {
    categoryId: number;
    actionPlanId: number;
    auditId: number;
  }) => {
    let data = await ApiClient.get(
      lpActionPlanEndPoints.getLpActionPlanAccordianData(param),
      null,
      LPKeys.LP_ACTION_PLANS
    );
    let accordianBody: any = [];
    data.map((item: LpActionPlanAccordionDataModel) => {
      let deserializevar: LpActionPlanAccordionDataModel = deserialize(
        LpActionPlanAccordionDataModel,
        item
      );
      accordianBody.push(deserializevar);
    });
    return accordianBody;
  };
  postLpActionSteps = async (postData: FormData): Promise<{}> => {
    let headers = {
      ...LPKeys.LP_ACTION_PLANS,
      "Content-Type": "multipart/form-data"
    };
    let p = postData as any;
    let data = await ApiClient.post(lpActionPlanEndPoints.postLpActionSteps(), p.body, {}, headers);
    return deserialize(LpPostActionstepsModel, data);
  };
  updateLpActionSteps = async (data: {
    subCategoryId: number;
    actionPlanId: number;
    body: FormData;
  }): Promise<{}> => {
    let headers = {
      ...LPKeys.LP_ACTION_PLANS,
      "Content-Type": "multipart/form-data"
    };
    let datum = await ApiClient.put(
      lpActionPlanEndPoints.updateLpActionSteps(data.actionPlanId, data.subCategoryId),
      data.body,
      {},
      headers
    );
    return deserialize(LpPostActionstepsModel, datum);
  };
  getLpOwnerData = async (param: { storeId: string }) => {
    let data = await ApiClient.get(
      lpActionPlanEndPoints.getLpOwnerData(param.storeId),
      null,
      LPKeys.LP_ACTION_PLANS
    );
    let finalData: LpAcknowledgementModel = {
      dropDownData: []
    };
    data.map((item: LpAckDropDownModel) => {
      let deserializeVar: LpAckDropDownModel = deserialize(LpAckDropDownModel, item);
      finalData.dropDownData.push(deserializeVar);
    });
    return finalData;
  };
}

const lpActionPlanRepository = new LPActionPlanRepository();
export { lpActionPlanRepository as LPActionPlanRepository };
