export enum quizStatus {
  passed = "PASSED",
  failed = "FAILED",
  status = "Status"
}
export enum quizType {
  mcq = "MULTIPLE_CHOICE_QUESTION",
  matchTheFollowing = "MATCH_THE_FOLLOWING",
  dragDropSort = "DRAG_AND_DROP",
  ticTacToe = "TIC_TAC_TOE",
  buildStation = "BUILD_STATION"
}
export enum completedStatus {
  completed = "COMPLETED",
  inProcess = "IN_PROGRESS",
  locked = "NOT_STARTED",
  expired = "EXPIRED"
}
