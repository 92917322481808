import React, { ReactElement, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { StyleSheet, View, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from "react-native";
import "./datepicker.scss";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import OverflowModal from "@timhortons/common/src/components/atoms/overflowModal";
import { setFrameMeasures } from "@timhortons/common/src/utils/measureLayout";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";

interface IProps {
  selectRange?: boolean;
  dateContainerStyle?: StyleProp<ViewStyle>;
  showDoubleView?: boolean;
  disablePastDates?: boolean;
  parentCallBack?: (val: Date | Date[]) => void;
  fontWeight?: FontWeight;
  textSize?: TextSize;
  textStyles?: StyleProp<TextStyle>;
  iconSize?: number;
  iconStyles?: StyleProp<ViewStyle | TextStyle>;
  reset?: boolean | number;
  preSelectedDate?: string;
  disableSelectedPrevDate?: Date;
  placeholderText?: string;
  dateViewStyle?: StyleProp<ViewStyle>;
  disableFutureDates?: boolean;
  disableCalenderClick?: boolean;
  maxDate?: any;
  placeholderColor?: string;
  rightIcon?: IconNames;
}

export const DatePicker = (props: IProps): ReactElement => {
  const {
    selectRange,
    dateContainerStyle,
    showDoubleView,
    disablePastDates,
    fontWeight = FontWeight.Medium,
    textSize = TextSize.Medium2,
    parentCallBack,
    textStyles = {},
    iconStyles,
    iconSize = 14,
    reset,
    disableSelectedPrevDate,
    preSelectedDate = null,
    placeholderText = "Select Date",
    dateViewStyle,
    disableFutureDates = true,
    disableCalenderClick = false,
    maxDate,
    placeholderColor = null,
    rightIcon = null
  } = props;
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [date, setDate] = useState<Date | Date[]>();
  const [buttonFrame, setButtonFrame] = useState(null);
  const triggerRef = useRef(null);

  const onCalenderOpen = (): void => {
    setIsCalenderOpen(!isCalenderOpen);
  };
  const onDateClick = (value: Date | Date[]) => {
    if (reset) {
      setDate(null);
    }
    setDate(value);
    parentCallBack && parentCallBack(value);
    onCalenderOpen();
  };

  useEffect(() => {
    if (preSelectedDate && preSelectedDate.length > 0) {
      setDate(new Date(preSelectedDate));
    } else {
      setDate(null);
    }
  }, [preSelectedDate]);

  const showModal = (): void => {
    setFrameMeasures(
      () => {
        setIsCalenderOpen(true);
      },
      triggerRef,
      setButtonFrame
    );
  };
  const tileDisabled = (date: any) => {
    return date < new Date();
  };
  const renderCalendar = (): ReactElement => {
    return (
      <>
        <Calendar
          maxDate={disableFutureDates ? new Date() : maxDate}
          className="react-calendar"
          value={reset ? null : date}
          onChange={(value: any) => onDateClick(value)}
          selectRange={selectRange}
          showDoubleView={showDoubleView}
          minDate={disablePastDates ? disableSelectedPrevDate : null}
          calendarType={"Hebrew"}
          next2Label={null}
          prev2Label={null}
          tileDisabled={disablePastDates ? ({ date }: any) => tileDisabled(date) : null}
        />
      </>
    );
  };

  return (
    <OverflowModal
      buttonFrame={buttonFrame}
      modalVisible={isCalenderOpen}
      setModalState={onCalenderOpen}
      triggerComponent={
        <TouchableOpacity
          ref={triggerRef}
          style={[styles.container, dateContainerStyle]}
          onPress={disableCalenderClick ? null : showModal}
        >
          <View style={[styles.dateView, dateViewStyle]}>
            <Text
              fontWeight={fontWeight}
              textSize={textSize}
              testId={testIds.actionPlan.selectDate}
              textStyle={[textStyles, date && !reset ? null : { color: placeholderColor }]}
            >
              {date && !reset ? dateFormat(date) : placeholderText}
            </Text>
            <Icon
              customStyle={[styles.calendarStyle, iconStyles]}
              name={rightIcon ? rightIcon : IconNames.calender}
              size={iconSize}
            />
          </View>
        </TouchableOpacity>
      }
    >
      {renderCalendar()}
    </OverflowModal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorPallete.white5,
    borderRadius: 8
  },
  dateView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap"
  },
  calendarStyle: {
    color: colorPallete.black3,
    paddingHorizontal: 4,
    marginBottom: 4,
    ...I18nService.select({
      rtl: { marginStart: 11 },
      ltr: { marginStart: 11 }
    })
    // marginStart: 11
  }
});
