import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys, ManageNewsKeys } from "@timhortons/common/src/constants/apiKeys";
import { mobileEndPoints } from "@timhortons/common/src/constants/endPoints";
import VideoUpdateProps from "@timhortons/common/src/redux/userLms/actions/videoActions";
import {
  ILearningPathParams,
  IModuleDetailParams
} from "@timhortons/common/src/redux/userLms/actions/userLms";
import { DayEvents, ILmsModuleList } from "@timhortons/common/src/redux/userLms/actions/userLms";
import {
  FAQSearchResultModel,
  FAQsModel,
  LMSContinueLearningModel,
  LMSQuizDetailDataModel,
  LMSVideoUpdateModel,
  NewsListModel,
  NewsDetailModel,
  LearningPathDetailsModel,
  StatisticsModel,
  PublishModuleModel,
  ModuleDetailModel,
  LMSModuleData,
  LMSPostQuizResponseModel,
  MTFDNDRequestBodyModel,
  SubmitAssessmentModel,
  LMSGetQuizTableModel,
  LearningPathDetailsResponseModel,
  TotalDaysLeftModel,
  LeaderboardRequestModel,
  LeaderboardDataListModel,
  LeaderboardDataModel,
  GetTimbitCollection
} from "@timhortons/common/src/models/userLms/userLms";
import { quizType as quizEnum, quizType } from "@timhortons/common/src/constants/quizEnums";
import {
  getCategoriesFormatter,
  lmsModuleListFormatter,
  topicSubtopicFormatter
} from "@timhortons/common/src/repositories/userLms/lmsFormatter";
import { sleep } from "@timhortons/common/src/utils/timeoutFunctions";
import { isEmpty } from "lodash";

class UserLmsRepository {
  fetchLmsModulesList = async (payload: ILmsModuleList): Promise<LMSModuleData> => {
    const { fromAssignedLearning, employeeId, storeCode, roleName, language } = payload;
    let lmsModuleList: LMSModuleData = await ApiClient.get(
      mobileEndPoints.lms.getModulesList(
        storeCode,
        fromAssignedLearning,
        roleName,
        employeeId,
        language
      ),
      {},
      LMSKeys.LMS_USER
    );
    return lmsModuleListFormatter(lmsModuleList);
  };

  fetchCarouselCertificate = async () => {
    let certificateCarouselList = await ApiClient.get(
      mobileEndPoints.lms.getCertificateCarousel(),
      {},
      LMSKeys.LMS_USER
    );
    return certificateCarouselList;
  };

  fetchTopicSubtopic = async (payload: { categoryId: string; empId: string; language: string }) => {
    let { categoryId, empId, language } = payload;

    let topicSubtopicData = await ApiClient.get(
      mobileEndPoints.lms.getTopicSubtopic(categoryId, empId, language),
      {},
      LMSKeys.LMS_USER
    );
    return topicSubtopicFormatter(topicSubtopicData);
  };
  fetchTotalEarnedPoints = async (param: { employeeId: string }) => {
    const { employeeId } = param;
    let data = await ApiClient.get(
      mobileEndPoints.lms.getTotalEarnedPoints(employeeId),
      {},
      LMSKeys.LMS_USER
    );
    return data;
  };

  getContinueLearning = async (param: {
    language: string;
    employeeId: string;
    moduleId: string;
    moduleType: string;
  }) => {
    if (param.moduleType === "BASIC") {
      delete param.moduleId;
    }

    const data = await ApiClient.get(
      mobileEndPoints.getContinueLearning(),
      { ...param },
      LMSKeys.LMS_USER
    );

    return deserialize(LMSContinueLearningModel, data);
  };

  getCategories = async (param: { employeeId: string; moduleId: string; language: string }) => {
    const { employeeId, moduleId, language } = param;

    const data = await ApiClient.get(
      mobileEndPoints.lms.getCategories(moduleId, employeeId, language),
      {},
      LMSKeys.LMS_USER
    );

    return getCategoriesFormatter(data);
  };

  getDaysLeft = async (param: { employeeId: string }) => {
    const { employeeId } = param;
    let totalDaysLeft: TotalDaysLeftModel = await ApiClient.get(
      mobileEndPoints.lms.getDaysLeft(employeeId),
      {},
      LMSKeys.LMS_USER
    );
    return totalDaysLeft;
  };
  getQuizDetails = async (param: LMSQuizDetailDataModel) => {
    const { quizId, employeeId, quizType, categoryId, topicId, subTopicId } = param;
    let lmsQuizDetails = await ApiClient.get(
      mobileEndPoints.lms.getQuizDetails(quizId, employeeId, quizType, categoryId, topicId),
      {},
      LMSKeys.LMS_USER
    );
    return lmsQuizDetails;
  };

  postQuizDetails = async (param: any) => {
    const {
      quizId,
      employeeId,
      quizType,
      categoryId,
      topicId,
      responseId,
      subTopicId,
      questionId
    } = param;
    if (quizType === quizEnum.matchTheFollowing || quizType === quizEnum.dragDropSort) {
      const postMtfDndQuiz = await ApiClient.post(
        mobileEndPoints.lms.postMtfDndQuiz(quizId, categoryId, topicId),
        null,
        null,
        LMSKeys.LMS_USER
      );
      return postMtfDndQuiz;
    } else {
      const postMcqTttQuiz = await ApiClient.post(
        mobileEndPoints.lms.postMcqTttQuiz(
          quizId,
          employeeId,
          quizType,
          categoryId,
          topicId,
          responseId,
          subTopicId,
          questionId
        ),
        null,
        null,
        LMSKeys.LMS_USER
      );
      return postMcqTttQuiz;
    }
  };

  updateVideoStatus = async (param: VideoUpdateProps) => {
    const { lastWatchedTime, videoId, employeeId, topicId, categoryId, subTopicId, language } =
      param;
    let response;
    if (!subTopicId) {
      response = await ApiClient.put(
        mobileEndPoints.lms.updateVideoStatus(videoId, employeeId, topicId, categoryId, language),
        { playBackTime: lastWatchedTime },
        null,
        LMSKeys.LMS_USER
      );
    } else {
      response = await ApiClient.put(
        mobileEndPoints.lms.updateSubTopicVideoStatus(
          videoId,
          employeeId,
          topicId,
          categoryId,
          subTopicId,
          language
        ),
        { playBackTime: lastWatchedTime },
        null,
        LMSKeys.LMS_USER
      );
    }

    return deserialize(LMSVideoUpdateModel, response);
  };

  fetchFaqs = async () => {
    const data = await ApiClient.get(mobileEndPoints.lms.getFaqs(), null, LMSKeys.LMS_USER);
    return data.map((item: FAQsModel) => deserialize(FAQsModel, item));
  };

  fetchFaqSearchResult = async (param: { searchText: string; pageNo: number }) => {
    const { searchText, pageNo } = param;
    const data = await ApiClient.get(
      mobileEndPoints.lms.getFaqSearchResult(searchText, pageNo),
      null,
      LMSKeys.LMS_USER
    );
    let searchResults: FAQsModel[] = [];
    data.content.map((item: FAQsModel) => {
      let deserializevar: FAQsModel = deserialize(FAQsModel, item);
      searchResults.push(deserializevar);
    });
    let faqSearchResult: FAQSearchResultModel = { content: [], totalElements: null };
    faqSearchResult.totalElements = data.totalElements;
    faqSearchResult.content = searchResults;
    return faqSearchResult;
  };

  fetchEnquiries = async (param: { employeeId: string; status: string }) => {
    const { employeeId, status } = param;
    const data = await ApiClient.get(
      mobileEndPoints.lms.getEnquiries(employeeId, status),
      null,
      LMSKeys.LMS_USER
    );
    return data.map((item: FAQsModel) => deserialize(FAQsModel, item));
  };

  postQuestion = async (param: any) => {
    let headers = {
      ...LMSKeys.LMS_USER,
      "Content-Type": "multipart/form-data"
    };
    const { employeeId, body } = param;
    const postQuestionResponse = await ApiClient.post(
      mobileEndPoints.lms.postQuestion(employeeId),
      body,
      null,
      headers
    );
    return postQuestionResponse;
  };

  getTicToeQuiz = async (param: { boxNumber: number }) => {
    await sleep(3000);
    let data: any = {
      ques: "What is the name of the below the coffee machine?",
      options: [
        {
          id: 1,
          option: "1'c to 4'c",
          response: false
        },
        { id: 2, option: "3'c to 4'c", response: false, onPress: () => {} },
        { id: 3, option: "1'c to 4'c", response: false, onPress: () => {} },
        { id: 4, option: "1'c to 4'c", response: false, onPress: () => {} }
      ]
    };
    return data;
  };

  getDragDropQuiz = async (param: { type: string }): Promise<LMSGetQuizTableModel> => {
    const { type } = param;
    await sleep(3000);
    let data: any = [];
    if (param === "wdrag") {
      data = [
        {
          ques: "Lifts the food particles away from the surface of the dish and suspends it in the detergent solution.",
          quesNumber: 0,
          answer: "",
          hover: false
        },
        {
          ques: "Ensures that the microorganisms will not be able to grow and multiply by keeping the surface dry.",
          quesNumber: 0,
          answer: "",
          hover: false
        },
        {
          ques: "Lifts the food particles away from the surface of the dish and suspends it in the detergent solution.",
          quesNumber: 0,
          answer: "",
          hover: false
        },
        {
          ques: "Ensures that the microorganisms will not be able to grow and multiply by keeping the surface dry.",
          quesNumber: 0,
          answer: "",
          hover: false
        }
      ];
    } else if (param === "idrag") {
      data = [
        { ques: "Triple Chocolate Muffin", ans: "", hover: false },
        { ques: "Plain Croissant", ans: "", hover: false },
        { ques: "Triple Chocolate Muffin", ans: "", hover: false },
        { ques: "Triple", ans: "", hover: false }
      ];
    }

    return data;
  };

  submitTicTacToeQuiz = async (param: any) => {
    let data = {
      status: "passed",
      result: "PASSED"
    };
    const {
      publishQuizId,
      employeeId,
      quizType,
      categoryId,
      topicId,
      responseId,
      subTopicId,
      questionId
    } = param;
    const response = await ApiClient.post(
      mobileEndPoints.lms.postTicTacToeQuizResponse(
        publishQuizId,
        employeeId,
        quizType,
        categoryId,
        topicId,
        responseId,
        subTopicId,
        questionId
      ),
      null,
      null
    );
    return response;
  };
  getEvents = async (param: any) => {
    const { startDate, endDate, storeCode, empId, category } = param;
    let data = await ApiClient.get(
      mobileEndPoints.getEvents(startDate, endDate),
      { storeCode: storeCode, employeeId: empId },
      LMSKeys.LMS_USER
    );
    return data;
  };

  getEventDetail = async (param: any) => {
    let { eventId } = param;
    let response = ApiClient.get(mobileEndPoints.getEventById(eventId), {}, LMSKeys.LMS_USER);

    return response;
  };

  getDayEvents = async (params: DayEvents) => {
    const { date, category, employeeId, storeCode } = params;
    const data = await ApiClient.get(
      mobileEndPoints.getDayEvents(date),
      { category: category, employeeId: employeeId, storeCode: storeCode },
      LMSKeys.LMS_USER
    );

    return data;
  };

  getAssessmentQuestions = async (param: { moduleId: string; employeeId: string }) => {
    const { moduleId, employeeId } = param;
    const response = await ApiClient.get(
      mobileEndPoints.getAssessmentQuestions(moduleId, employeeId),
      null,
      LMSKeys.LMS_USER
    );
    return response;
  };
  fetchNewsList = async (param: { filter: string }) => {
    const { filter } = param;
    const data = await ApiClient.get(
      mobileEndPoints.lms.getNewsList(filter),
      null,
      ManageNewsKeys.NEWS_USER
    );
    return data.map((item: NewsListModel) => deserialize(NewsListModel, item));
  };

  fetchNewsDetails = async (param: { newsId: string }) => {
    const { newsId } = param;
    const data = await ApiClient.get(
      mobileEndPoints.lms.getNewsDetails(newsId),
      null,
      ManageNewsKeys.NEWS_USER
    );
    return deserialize(NewsDetailModel, data);
  };

  fetchLearningPathDetails = async (param: ILearningPathParams) => {
    const { employeeId, storeCode, roleName, moduleType } = param;
    const data: LearningPathDetailsResponseModel = await ApiClient.get(
      mobileEndPoints.lms.getLearningPathDetails(employeeId, storeCode, roleName, moduleType),
      null,
      ManageNewsKeys.NEWS_USER
    );
    return (data.moduleStatusResponseDtoList || []).map((item: LearningPathDetailsModel) =>
      deserialize(LearningPathDetailsModel, item)
    );
  };
  fetchStatistics = async (param: { employeeId: string }) => {
    const { employeeId } = param;
    const data = await ApiClient.get(
      mobileEndPoints.lms.getStatistics(employeeId),
      null,
      LMSKeys.LMS_USER
    );
    let statistics: StatisticsModel = {
      publishModuleResponseDtos: [],
      completedPercent: 0,
      inProgressPercent: 0,
      pendingPercent: 0,
      birthdayCake: 0,
      chocolateGlazed: 0,
      honeyDip: 0
    };
    if (!isEmpty(data)) {
      let modules: PublishModuleModel[] = [];
      data.publishModuleResponseDtos.map((item: PublishModuleModel) => {
        let deserializevar: PublishModuleModel = deserialize(PublishModuleModel, item);
        modules.push(deserializevar);
      });

      statistics.completedPercent = data.completedPercent;
      statistics.inProgressPercent = data.inProgressPercent;
      statistics.pendingPercent = data.pendingPercent;
      statistics.birthdayCake = data.birthdayCake;
      statistics.chocolateGlazed = data.chocolateGlazed;
      statistics.honeyDip = data.honeyDip;
      statistics.publishModuleResponseDtos = modules;
    }
    return statistics;
  };

  fetchSpecialModules = async (param: { employeeId: string }) => {
    const { employeeId } = param;
    const data = await ApiClient.get(
      mobileEndPoints.lms.getSpecialModules(employeeId),
      null,
      LMSKeys.LMS_USER
    );
    return data.map((item: PublishModuleModel) => deserialize(PublishModuleModel, item));
  };

  fetchModuleDetail = async (param: IModuleDetailParams) => {
    const { employeeId, publishModuleName, status, publishModuleId } = param;
    const data = await ApiClient.get(
      mobileEndPoints.lms.getModuleDetail(employeeId, publishModuleName, status, publishModuleId),
      null,
      LMSKeys.LMS_USER
    );
    return deserialize(ModuleDetailModel, data);
  };

  submitMtfDnd = async (param: MTFDNDRequestBodyModel): Promise<LMSPostQuizResponseModel> => {
    const {
      pathVariables: { publishQuizId, categoryId, topicId },
      quizBody,
      paramsData
    } = param;

    const data = await ApiClient.post(
      mobileEndPoints.submitMtfDnd(publishQuizId, categoryId, topicId),
      quizBody,
      { ...paramsData },
      LMSKeys.LMS_USER
    );
    return data;
  };

  submitAssessment = async (param: SubmitAssessmentModel) => {
    const { quizId, employeeId, responseId, questionId, quizBody } = param;
    const quiz = param.quizType;
    let rqstParam: any = {
      employeeId: employeeId,
      quizType: quiz
    };
    if (quiz === quizType.mcq || quiz === quizType.ticTacToe) {
      rqstParam = {
        ...rqstParam,
        responseId: responseId,
        questionId: questionId
      };
    }
    let obj: any = {};
    if (quiz === quizType.matchTheFollowing || quiz === quizType.dragDropSort) {
      obj = quizBody;
    } else {
      obj = null;
    }
    const data = await ApiClient.post(
      mobileEndPoints.submitAssessment(quizId),
      obj,
      rqstParam,
      LMSKeys.LMS_USER
    );
    return data;
  };
  exitAssessment = async (param: { moduleId: string; employeeId: string }) => {
    const { employeeId, moduleId } = param;
    const response = await ApiClient.post(
      mobileEndPoints.exitAssessment(employeeId, moduleId),
      null,
      {},
      LMSKeys.LMS_USER
    );
    return response;
  };
  deleteAssessment = async (param: { moduleId: string; employeeId: string }) => {
    const { employeeId, moduleId } = param;
    const response = await ApiClient.delete(
      mobileEndPoints.deleteAssessment(employeeId, moduleId),
      null,
      {},
      LMSKeys.LMS_USER
    );
    return response;
  };
  getAssessmentResult = async (param: any) => {
    const { moduleId, employeeId, duration, isIncreaseAttempt = false } = param;
    const obj = {
      employeeId: employeeId,
      duration: duration,
      isIncreaseAttempt: isIncreaseAttempt
    };
    const response = await ApiClient.post(
      mobileEndPoints.getAssessmentResult(moduleId),
      null,
      obj,
      LMSKeys.LMS_USER
    );
    return response;
  };
  getLeaderboardList = async (
    params: LeaderboardRequestModel
  ): Promise<LeaderboardDataListModel> => {
    const response: LeaderboardDataListModel = await ApiClient.get(
      mobileEndPoints.getLeaderboardList(),
      { ...params },
      LMSKeys.LMS_USER
    );
    let tempData: LeaderboardDataListModel = {
      content: [],
      totalElements: 0
    };
    let finalContent: LeaderboardDataModel[] = [];
    response &&
      response.content &&
      response.content.length > 0 &&
      response.content.map((item: LeaderboardDataModel, index: number) => {
        let deserializeVar: LeaderboardDataModel = deserialize(LeaderboardDataModel, item);
        finalContent.push({
          ...deserializeVar,
          position: index + 1
        });
      });
    tempData.content = finalContent;
    tempData.totalElements = response.totalElements;
    return tempData;
  };

  getTimbitCollection = async (param: GetTimbitCollection) => {
    const response = await ApiClient.get(
      mobileEndPoints.getTimbitCollection(),
      param,
      LMSKeys.LMS_USER
    );
    return response;
  };

  getUserNotification = async (param: { employeeId: string }) => {
    const { employeeId } = param;
    const response = await ApiClient.get(mobileEndPoints.getUserNotifications(employeeId));
    return response;
  };

  saveUserFCMToken = async (param: any) => {
    const response = await ApiClient.post(mobileEndPoints.saveUserFCMToken(), param);
    return response;
  };
  editUserProfile = async (param: any) => {
    const response = await ApiClient.put(
      mobileEndPoints.editUserProfile(),
      param,
      null,
      LMSKeys.LMS_USER
    );
    return response;
  };
}

const userLmsRepository = new UserLmsRepository();
export { userLmsRepository as UserLmsRepository };
