import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { RevReportsActions } from "@timhortons/common/src/redux/rev/actions/reportsAction";
import { ReportsRepository } from "@timhortons/common/src/repositories/rev/reports";
import {
  AcknowledgementModel,
  PostAcknowledgementModel,
  ReportsDataModal,
  ReportTableResponseModel,
  StoreAccessSummaryDataModel,
  ReportsSummaryOpportunityDataModel,
  IndividualAccessSummaryDataModel,
  ReportBuilderResponseModel,
  CountryManagerDetailsModel
} from "@timhortons/common/src/models/rev/reports";
import { Logger } from "@timhortons/common/src/services/logger";
import { IReportTableData } from "@timhortons/common/src/modules/rev/components/dashboards/reports";
import { convert } from "@timhortons/common/src/utils/dateFormat";

import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

function* getReportsDataWorkerSaga(
  value: IFluxStandardAction<{
    calibrationId: number;
    reportId: number;
  }>
) {
  try {
    const { payload } = value;
    const response: ReportsDataModal = yield call(ReportsRepository.fetchReportsData, payload);
    yield put(RevReportsActions.getReportsDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/reports", error, "getReportsDataWorkerSaga");

    yield put(RevReportsActions.getReportsDataError(error));
  }
}

function* getReportsAcknowledgementWorkerSaga(value: IFluxStandardAction<{ storeCode: string }>) {
  try {
    const { payload } = value;
    const response: AcknowledgementModel = yield call(
      ReportsRepository.fetchAcknowledgementData,
      payload
    );
    yield put(RevReportsActions.getReportsAcknowledgementDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/reports", error, "getReportsAcknowledgementWorkerSaga");

    yield put(RevReportsActions.getReportsAcknowledgementDataError(error));
  }
}

function* getReportTableWorkerSaga(value: IFluxStandardAction<IReportTableData>) {
  try {
    const { payload } = value;

    let bodyObject: any = {
      page: payload.pageNumber,
      size: payload.pageSize,
      sortByAuditDate: payload.sortOrder.toUpperCase(),
      searchByKeyword: payload.search === "" ? null : payload.search
    };

    if (payload.fromDate !== null) {
      bodyObject = {
        ...bodyObject,
        filterByStartDate: convert(payload.fromDate)
      };
    }
    if (payload.toDate !== null) {
      bodyObject = {
        ...bodyObject,
        filterByEndDate: convert(payload.toDate)
      };
    }

    const response: ReportTableResponseModel = yield call(ReportsRepository.fetchReportTableData, {
      userId: payload.userId,
      storeId: payload.storeId,
      isIndividualLogin: payload.isIndividualLogin,
      bodyObject: bodyObject
    });
    yield put(RevReportsActions.getReportTableDataSuccess(response));
  } catch (error) {
    Logger.error("Rev/reports", error, "getReportTableaWorkerSaga");

    yield put(RevReportsActions.getReportTableDataError(error));
  }
}

function* postAcknowledgementWorkerSaga(value: IFluxStandardAction<PostAcknowledgementModel>) {
  try {
    const { payload } = value;
    const response: {} = yield call(ReportsRepository.postAcknowledgementData, payload);
    yield put(RevReportsActions.postAcknowledgementSuccess(response));
  } catch (error) {
    Logger.error("Rev/reports", error, "postAcknowledgementWorkerSaga");

    yield put(RevReportsActions.postAcknowledgementError(error));
  }
}
function* getStoreAccessSummaryDataWorkerSaga(
  value: IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }>
) {
  try {
    const { payload } = value;
    const response: StoreAccessSummaryDataModel = yield call(
      ReportsRepository.fetchReportsSummaryStoreAccess,
      payload
    );
    yield put(RevReportsActions.getStoreAccessSummaryDataSuccess(response));
  } catch (error) {
    yield put(RevReportsActions.getStoreAccessSummaryDataError(error));
    Logger.error("Rev/reports", error, "getStoreAccessSummaryDataWorkerSaga");
  }
}

function* getStoreAccessOpportunityDataWorkerSaga(
  value: IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }>
) {
  try {
    const { payload } = value;
    const response: ReportsSummaryOpportunityDataModel[] = yield call(
      ReportsRepository.fetchReportsOpportunityStoreAccess,
      payload
    );
    yield put(RevReportsActions.getStoreAccessOpportunityDataSuccess(response));
  } catch (error) {
    yield put(RevReportsActions.getStoreAccessOpportunityDataError(error));
    Logger.error("Rev/reports", error, "getStoreAccessOpportunityDataWorkerSaga");
  }
}

function* getIndividualSummaryDataWorkerSaga(
  value: IFluxStandardAction<{ startDate: string; endDate: string }>
) {
  try {
    const { payload } = value;
    const response: IndividualAccessSummaryDataModel = yield call(
      ReportsRepository.fetchIndividualReportsSummary,
      payload
    );
    yield put(RevReportsActions.getIndividualSummaryDataSuccess(response));
  } catch (error) {
    yield put(RevReportsActions.getIndividualSummaryDataError(error));
    Logger.error("Rev/reports", error, "getIndividualSummaryDataWorkerSaga");
  }
}
// function* getAllCountriesWorkerSaga(
//   value: IFluxStandardAction<{ pageNo: number; pageSize: number; searchByKeyword: string }>
// ) {
//   const { payload } = value;
//   try {
//     const response: CountryDataListModel = yield call(ReportsRepository.getAllCountries, payload);
//     yield put(RevReportsActions.getAllCountriesSuccess(response));
//   } catch (error) {
//     Logger.error(
//       "Rev/reports", error, "getAllCountriesWorkerSaga")
//     );
//     yield put(RevReportsActions.getAllCountriesError(error));
//   }
// }
// function* getAllAreaManagerWorkerSaga(
//   value: IFluxStandardAction<{
//     user: IUserDetails;
//     role: string;
//     countryCode: any;
//     pageNo: number;
//     pageSize: number;
//     searchByKeyword: string;
//     allSelected?: boolean;
//   }>
// ) {
//   const { payload } = value;
//   try {
//     const response: AllAreaManagersListDataModel = yield call(
//       ReportsRepository.getAllAreaManagers,
//       payload
//     );
//     yield put(RevReportsActions.getAllAreaManagerSuccess(response));
//   } catch (error) {
//     Logger.error(
//       "Rev/reports", error, "getAllAreaManagerWorkerSaga")
//     );
//     yield put(RevReportsActions.getAllAreaManagerError(error));
//   }
// }

// function* getAllCountryManagerWorkerSaga(
//   value: IFluxStandardAction<{ user: IUserDetails; role: string; countryCode: any; pageNo: number;
//     pageSize: number;searchByKeyword: string;
//     allSelected?: boolean; }>
// ) {
//   const { payload } = value;
//   try {
//     const response: AllAreaManagersListDataModel = yield call(
//       ReportsRepository.getAllCountryManagers,
//       payload
//     );
//     yield put(RevReportsActions.getAllCountryManagerSuccess(response));
//   } catch (error) {
//     Logger.error(
//       "Rev/reports", error, "getAllCountryManagerWorkerSaga")
//     );
//     yield put(RevReportsActions.getAllCountryManagerError(error));
//   }
// }
// function* getAllChannelsWorkerSaga(
//   value: IFluxStandardAction<{
//     user: IUserDetails;
//     pageNo: number;
//     pageSize: number;
//     countryCode: any;
//     searchByKeyword: string;
//     allSelected?: boolean;
//   }>
// ) {
//   const { payload } = value;
//   try {
//     const response: ChannelsListDataModel = yield call(ReportsRepository.getAllChannels, payload);
//     yield put(RevReportsActions.getAllChannelsSuccess(response));
//   } catch (error) {
//     Logger.error(
//       "Rev/reports", error, "getAllChannelsWorkerSaga")
//     );
//     yield put(RevReportsActions.getAllChannelsError(error));
//   }
// }
// function* getAllStoresWorkerSaga(
//   value: IFluxStandardAction<{
//     user: IUserDetails;
//     pageNo: number;
//     pageSize: number;
//     countryCode: any;
//     searchByKeyword: string;
//     allSelected?: boolean;
//   }>
// ) {
//   const { payload } = value;
//   try {
//     const response: AllStoresListDataModel = yield call(ReportsRepository.getAllStores, payload);
//     yield put(RevReportsActions.getAllStoresSuccess(response));
//   } catch (error) {
//     Logger.error(
//       "Rev/reports", error, "getAllStoresWorkerSaga")
//     );
//     yield put(RevReportsActions.getAllStoresError(error));
//   }
// }
function* generateReportBuilderWorkerSaga(
  value: IFluxStandardAction<{
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }>
) {
  const { payload } = value;
  try {
    const response: ReportBuilderResponseModel = yield call(
      ReportsRepository.generateReportBuilder,
      payload
    );
    yield put(RevReportsActions.generateReportBuilderSuccess(response));
  } catch (error) {
    Logger.error("Rev/reports", error, "generateReportBuilderWorkerSaga");

    yield put(
      RevReportsActions.generateReportBuilderError({
        error,
        reportType: payload?.object?.levelOfReport
      })
    );
  }
}

// function* comparisonAreaManagerAdminWorkerSaga(
//   value: IFluxStandardAction<{
//     pageNo: number;
//     pageSize: number;
//     searchByKeyword: string;
//   }>
// ) {
//   const { payload } = value;
//   try {
//     const response: {} = yield call(ReportsRepository.getComparisonAreaManager,payload);
//     yield put(RevReportsActions.comparisonAreaManagersAdminSuccess(response));
//   } catch (error) {
//     Logger.error(
//
//         "Rev/reports",
//         error,
//         "comparisonAreaManagerAdminWorkerSaga"
//       )
//
//     yield put(RevReportsActions.comparisonAreaManagersAdminError(error));
//   }
// }
// function* comparisonCountryManagerAdminWorkerSaga(value: IFluxStandardAction<{
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }>
// ) {
// const { payload } = value;
//   try {
//     const response: {} = yield call(ReportsRepository.getComparisonCountryManager,payload);
//     yield put(RevReportsActions.comparisonCountryManagersAdminSuccess(response));
//   } catch (error) {
//     Logger.error(
//
//         "Rev/reports",
//         error,
//         "comparisonCountryManagerAdminWorkerSaga"
//       )
//
//     yield put(RevReportsActions.comparisonCountryManagersAdminError(error));
//   }
// }
// function* comparisonStoresAdminWorkerSaga(
//   value: IFluxStandardAction<{ pageNo: number; pageSize: number }>
// ) {
//   const { payload } = value;
//   try {
//     const response: AllStoresListDataModel = yield call(
//       ReportsRepository.getComparisonStoresAdmin,
//       payload
//     );
//     yield put(RevReportsActions.comparisonStoresAdminSuccess(response));
//   } catch (error) {
//     Logger.error(
//
//         "Rev/reports",
//         error,
//         "comparisonStoresAdminWorkerSaga"
//       )
//
//     yield put(RevReportsActions.comparisonStoresAdminError(error));
//   }
// }
// function* highLowChannelsAdminWorkerSaga(
//   value: IFluxStandardAction<{ pageNo: number; pageSize: number; searchByKeyword: string }>
// ) {
//   const { payload } = value;
//   try {
//     const response: ChannelsListDataModel = yield call(
//       ReportsRepository.getHighLowChannelsAdmin,
//       payload
//     );
//     yield put(RevReportsActions.highlowChannelsAdminSuccess(response));
//   } catch (error) {
//     Logger.error(
//       "Rev/reports", error, "highLowChannelsAdminWorkerSaga")
//
//     yield put(RevReportsActions.highlowChannelsAdminError(error));
//   }
// }
function* getCountryManagerDetailsWorkerSaga() {
  try {
    const response: CountryManagerDetailsModel = yield call(
      ReportsRepository.getCountryDetailsOfCountryManager
    );
    yield put(RevReportsActions.getCountryManagerDetailsSuccess(response));
  } catch (error) {
    Logger.error("Rev/reports", error, "getCountryManagerDetailsWorkerSaga");

    yield put(RevReportsActions.getCountryManagerDetailsError(error));
  }
}
export default function* revReportsWatcherSaga() {
  yield takeLatest(REV.REPORTS_DATA.LOADING, getReportsDataWorkerSaga);
  yield takeLatest(REV.ACKNOWLEDGEMENT_DATA.LOADING, getReportsAcknowledgementWorkerSaga);
  yield takeLatest(REV.GET_REPORT_TABLE_DATA.LOADING, getReportTableWorkerSaga);
  yield takeLatest(REV.POST_ACKNOWLEDGEMENT.LOADING, postAcknowledgementWorkerSaga);
  yield takeLatest(REV.GET_STORE_ACCESS_SUMMARY_DATA.LOADING, getStoreAccessSummaryDataWorkerSaga);
  yield takeLatest(
    REV.GET_STORE_ACCESS_OPPORTUNITY_DATA.LOADING,
    getStoreAccessOpportunityDataWorkerSaga
  );
  yield takeLatest(REV.GET_INDIVIDUAL_SUMMARY_DATA.LOADING, getIndividualSummaryDataWorkerSaga);
  // yield takeLatest(REV.ReportBuilder.GET_ALL_COUNTRIES.LOADING, getAllCountriesWorkerSaga);
  // yield takeLatest(REV.ReportBuilder.GET_ALL_AREA_MANAGER.LOADING, getAllAreaManagerWorkerSaga);
  // yield takeLatest(REV.ReportBuilder.GET_ALL_CHANNELS.LOADING, getAllChannelsWorkerSaga);
  // yield takeLatest(REV.ReportBuilder.GET_ALL_STORES.LOADING, getAllStoresWorkerSaga);
  // yield takeLatest(
  //   REV.ReportBuilder.GET_ALL_COUNTRY_MANAGER.LOADING,
  //   getAllCountryManagerWorkerSaga
  // );
  yield takeEvery(REV.ReportBuilder.GENERATE_REPORT.LOADING, generateReportBuilderWorkerSaga);
  // yield takeLatest(
  //   REV.ReportBuilder.GET_COMPARISON_AREA_MANAGERS_ADMIN.LOADING,
  //   comparisonAreaManagerAdminWorkerSaga
  // );
  // yield takeLatest(
  //   REV.ReportBuilder.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.LOADING,
  //   comparisonCountryManagerAdminWorkerSaga
  // );
  // yield takeLatest(
  //   REV.ReportBuilder.GET_COMPARISON_STORES_ADMIN.LOADING,
  //   comparisonStoresAdminWorkerSaga
  // );
  // yield takeLatest(
  //   REV.ReportBuilder.GET_HIGHLOW_CHANNELS_ADMIN.LOADING,
  //   highLowChannelsAdminWorkerSaga
  // );
  yield takeLatest(REV.GET_COUNTRYMANAGER_DETAILS.LOADING, getCountryManagerDetailsWorkerSaga);
}
