import { logsManagerReviewEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LOGKeys } from "@timhortons/common/src/constants/apiKeys";
import { IMRApproval } from "@timhortons/common/src/modules/logs/dashboards/managerReview";
import { managerReviewTableDataFormatter } from "@timhortons/common/src/repositories/logs/managerReviewFormatter";
class ManagerReviewRepository {
  getManagerReviewTableData = async (param: {
    storeCode: number;
    date: string;
    timeZone: string;
    search: string;
    fromIndividualAccess: boolean;
  }) => {
    const { storeCode, date, timeZone, fromIndividualAccess } = param;
    let search = param.search === "" ? null : param.search;
    let object: any = {
      storeCode: storeCode,
      date: date,
      timeZone: timeZone,
      fromIndividualAccess: fromIndividualAccess
    };
    if (param.search !== null) {
      object = {
        ...object,
        keyword: search
      };
    }
    let response = await ApiClient.get(
      logsManagerReviewEndPoints.getManagerReviewTableData(),
      { ...object },
      LOGKeys.StoreAccessKeys
    );
    return managerReviewTableDataFormatter(response);
  };
  updateMRApprovalLogs = async (body: IMRApproval) => {
    let resp = await ApiClient.put(
      logsManagerReviewEndPoints.updateMRApprovalLogs(),
      body,
      null,
      LOGKeys.StoreAccessKeys
    );
    return resp;
  };
}
const managerReviewRepository = new ManagerReviewRepository();
export { managerReviewRepository as ManagerReviewRepository };
