import {
  AskTellQuestionCountModel,
  AskTellGetQuestionByStatusModel,
  QuestionsListModel,
  AskTellSupportTicketModel
} from "@timhortons/common/src/models/askTell/manageAskModule";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { manageAskEndPoints } from "@timhortons/common/src/constants/endPoints";
import { AskTellKeys } from "@timhortons/common/src/constants/apiKeys";

class SupportTicketRepository {
  getQuestionCount = async (): Promise<AskTellQuestionCountModel> => {
    let data = await ApiClient.get(
      manageAskEndPoints.getQuestionCount(),
      null,
      AskTellKeys.QUESTIONS
    );
    return data;
  };

  getQuestionById = async (param: AskTellGetQuestionByStatusModel): Promise<QuestionsListModel> => {
    let data = await ApiClient.get(
      manageAskEndPoints.getSTQuestionsList(),
      { ...param },
      AskTellKeys.QUESTIONS
    );
    return data;
  };

  postAnswerReply = async (
    param: AskTellSupportTicketModel
  ): Promise<AskTellSupportTicketModel> => {
    let { questionId } = param;
    let data = await ApiClient.post(
      manageAskEndPoints.answerReply(),
      null,
      { ...param },
      AskTellKeys.QUESTIONS
    );
    return {
      ...data,
      questionId
    };
  };

  updateAnswerReply = async (
    param: AskTellSupportTicketModel
  ): Promise<AskTellSupportTicketModel> => {
    let { questionId } = param;
    let data = await ApiClient.put(
      manageAskEndPoints.answerReply(),
      null,
      { ...param },
      AskTellKeys.QUESTIONS
    );
    return {
      ...data,
      questionId
    };
  };
}

const supportTicketRepository = new SupportTicketRepository();
export { supportTicketRepository as SupportTicketRepository };
