import { createSelector, Selector } from "reselect";
import {
  AppealCountModel,
  InProcesssClosedTable,
  AppealsAccordionDataModel,
  NewAppealsTable,
  InProcessAppealTableModel,
  CreateNewAppeal,
  AppealsCategoryDataModel,
  AppealCommentsDataModel
} from "@timhortons/common/src/models/rev/appeals";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IAppealsState,
  IAppealsError,
  IAppealsLoading
} from "@timhortons/common/src/redux/rev/reducers/appeals";
import { ActionPlanCategoryDataModel } from "@timhortons/common/src/models/rev/actionPlan";

const appealSelect = (state: IState): IAppealsState => state.rev.appeals;

const appealsLoading: Selector<IState, IAppealsLoading> = createSelector(
  appealSelect,
  (data) => data.loading
);

const appealsError: Selector<IState, IAppealsError> = createSelector(
  appealSelect,
  (data) => data.error
);

const appealsCountData: Selector<IState, AppealCountModel> = createSelector(
  appealSelect,
  (data) => data.appealsCount
);
const getNewAppealsData: Selector<IState, NewAppealsTable[]> = createSelector(
  appealSelect,
  (data) => data.newAppeals
);
const getAppealAccordionData: Selector<IState, AppealsAccordionDataModel[]> = createSelector(
  appealSelect,
  (data) => data.appealAccordianData
);
const getInProcessAppeals: Selector<IState, InProcessAppealTableModel[]> = createSelector(
  appealSelect,
  (data) => data.inProcessAppeals
);
const getClosedAppeals: Selector<IState, InProcessAppealTableModel[]> = createSelector(
  appealSelect,
  (data) => data.closedAppeals
);
const setAppealDecision: Selector<IState, boolean> = createSelector(
  appealSelect,
  (data) => data.appealsDecision
);
const appealFilteredData: Selector<IState, InProcesssClosedTable[]> = createSelector(
  appealSelect,
  (data) => data.filteredData
);

const closedappealFilteredData: Selector<IState, InProcessAppealTableModel> = createSelector(
  appealSelect,
  (data) => data.closedData
);

const createNewAppealResponse: Selector<IState, CreateNewAppeal> = createSelector(
  appealSelect,
  (data) => data.createNewAppealData
);
const appealsHeaderData: Selector<IState, AppealsCategoryDataModel> = createSelector(
  appealSelect,
  (data) => data.appealsHeaderData
);
const appealsCategoryData: Selector<IState, ActionPlanCategoryDataModel[]> = createSelector(
  appealSelect,
  (data) => data.appealsCategoryData
);
const appealsCommentData: Selector<IState, AppealCommentsDataModel> = createSelector(
  appealSelect,
  (data) => data.commentData
);
export const AppealsSelectors = {
  createNewAppealResponse,
  appealsLoading,
  appealsError,
  appealsCountData,
  getNewAppealsData,
  getInProcessAppeals,
  getClosedAppeals,
  getAppealAccordionData,
  setAppealDecision,
  appealFilteredData,
  closedappealFilteredData,
  appealsCategoryData,
  appealsHeaderData,
  appealsCommentData
};
