import React, { useEffect, useRef } from "react";
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle
} from "react-native";
import { useTranslation } from "react-i18next";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import {
  FontWeight,
  Label,
  TextSize,
  Text,
  FontFamily
} from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { testIds, validations } from "@timhortons/common/src/utils/formHelpers";

interface IProps {
  label: string;
  value: string;
  placeholder: string;
  onMessageChange?: (val: string) => void;
  containerStyle?: StyleProp<ViewStyle>;
  textAreaStyle?: StyleProp<ViewStyle>;
  wordCountLimit?: number;
  inputContainerStyle?: StyleProp<ViewStyle>;
  helpTextStyle?: StyleProp<TextStyle>;
  labelStyle?: StyleProp<TextStyle>;
  acknowledgementStatus?: boolean;
  autoFocus?: boolean;
  labelTextSize?: TextSize;
  labelFontWeight?: FontWeight;
  isShowError?: boolean;
  editHereButton?: Boolean;
  editable?: boolean;
  placeholderTextColor?: string;
  // inputTextStyle?: StyleProp<TextStyle>;
}

export const TextArea = (props: IProps): React.ReactElement => {
  const ref = useRef(null);
  const {
    label,
    placeholder,
    containerStyle = {},
    inputContainerStyle = {},
    onMessageChange,
    value,
    wordCountLimit,
    helpTextStyle,
    textAreaStyle,
    labelStyle = {},
    acknowledgementStatus,
    labelTextSize = TextSize.Regular,
    labelFontWeight = FontWeight.Regular,
    isShowError = true,
    editHereButton = false,
    editable,
    placeholderTextColor
    // inputTextStyle
  } = props;
  const { t } = useTranslation();
  const onPressBox = (): void => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (props.autoFocus) {
      ref.current.value = "";
      ref.current.focus();
      ref.current.value = value;
    }
  }, []);

  const wordCount = value
    ? (value.length === 0 && wordCountLimit) || wordCountLimit - value.length
    : // ? wordCountLimit
      // : wordCountLimit - value.length
      0;
  const helperText = value
    ? (value.trim().length >= 250 && validations.maxTextLimitValidations) ||
      (isShowError &&
        value.trim().length < 10 &&
        value.trim().length > 0 &&
        validations.minTextLimitValidations)
    : //  value.trim().length < 10 && value.trim().length > 0 && validations.minTextLimitValidations
      "";

  return (
    <View style={containerStyle}>
      <View style={styles.labelView}>
        {label && (
          <Text
            textSize={labelTextSize}
            fontWeight={labelFontWeight}
            testId={testIds.labeltext}
            textStyle={[styles.labelStyle, labelStyle]}
          >
            {label}
          </Text>
        )}

        <Label
          textSize={TextSize.Regular}
          fontWeight={FontWeight.Light}
          testId={testIds.helpTextLabel}
          textStyle={[styles.labelHelper, helpTextStyle]}
        >
          {helperText}
        </Label>
      </View>
      <TouchableOpacity
        style={[styles.textAreaContainer, textAreaStyle]}
        onPress={onPressBox}
        activeOpacity={!editHereButton ? 1 : 0}
      >
        <TextInput
          ref={ref}
          autoCorrect={false}
          style={[styles.textArea, inputContainerStyle]}
          placeholder={placeholder}
          maxLength={wordCountLimit}
          multiline
          editable={editable}
          value={value}
          onChangeText={(value) => {
            if (!editHereButton) onMessageChange(value);
          }}
          placeholderTextColor={placeholderTextColor}
        />
      </TouchableOpacity>
      {acknowledgementStatus && (
        <Label
          textSize={TextSize.Small}
          fontWeight={FontWeight.ExtraLight}
          testId={testIds.acknowledgementStatusLabel}
          textStyle={[styles.labelStyle, styles.helpText]}
        >
          {t("charactersRemaining", { wordCount })}
        </Label>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  labelStyle: {
    marginVertical: 6,
    color: colorPallete.grey5
  },
  textAreaContainer: {
    height: 100,
    borderColor: colorPallete.grey4,
    borderWidth: 1,
    padding: PlatformUtils.isWeb() ? 0 : 5
  },
  textArea: {
    justifyContent: "flex-start",
    textAlignVertical: "top",
    height: "100%",
    paddingHorizontal: 16,
    paddingVertical: 12,
    // outlineWidth: 0,
    fontFamily: PlatformUtils.isIOS() ? "SofiaPro" : FontFamily.Regular
  },
  helpText: {
    paddingVertical: 6
  },
  labelView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  labelHelper: {
    color: colorPallete.red4
  }
});
