import React, { ReactElement, useEffect, useState, useRef } from "react";
import { View, StyleSheet } from "react-native";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { DatePicker } from "@timhortons/web/src/components/atoms/datepicker/datepicker";
import ModalDropdown, {
  IDropDownItem
} from "@timhortons/common/src/components/molecules/modalDropdown";
import {
  Filters,
  HideFilters,
  ResetFilters,
  ShowFiltersView
} from "@timhortons/common/src/modules/common/organisms/filters";
import { FilterDataModel } from "@timhortons/common/src/models/rev/actionPlan";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import CalendarComponent from "@timhortons/tablet/src/components/atoms/datepicker/index";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import {
  selectedFromDateHandler,
  selectedSearchHandler
} from "../../reports/organisms/reportsTableHeader";
interface IProps {
  filterData: FilterDataModel;
  dropDownValueHandler: Function;
  translate: any;
  isIndividualLogin: boolean;
}
interface IAuditorNameData {
  id: string;
  name: string;
}
export enum FilterStatus {
  completed = "COMPLETED",
  notStarted = "NOT_STARTED",
  overdue = "OVERDUE"
}
function ActionPlanHeader(props: IProps): ReactElement {
  const { filterData, translate, dropDownValueHandler, isIndividualLogin } = props;
  const [selectedValues, setSelectedValues] = useState({
    date: null,
    name: "",
    status: null,
    fromDate: null,
    toDate: null,
    search: null
  });
  let prevSelectedValues = useRef(selectedValues);
  const [isShowFilter, setShowFilter] = useState(false);
  const [reset, setReset] = useState(false);
  const [statusLabelStyle, setStatusLabelStyle] = useState(null);
  const getListData = (data: Array<IAuditorNameData>): Array<IDropDownItem> => {
    let dataList: Array<IDropDownItem> = [];
    dataList =
      data &&
      data.map((item: IAuditorNameData) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    dataList && dataList.unshift({ label: translate("Auditor"), value: "" });
    return dataList;
  };
  const getFilterData = (): Array<IDropDownItem> => {
    let data: IDropDownItem[] = [];
    // filterData &&
    //   filterData.status.forEach((item) => {
    //     if (item.value === ) {
    //       data.push({ ...item, labelStyle: styles.completedLabel });
    //     } else if (item.value === FilterStatus.notStarted) {
    //       data.push({ ...item, labelStyle: styles.notStartedLable });
    //     }
    //   });
    // return data;
    data =
      filterData &&
      filterData.status.map((el) => {
        return {
          ...el,
          itemStyle:
            (el.value === FilterStatus.completed && styles.completedLabel) ||
            (el.value === FilterStatus.notStarted && styles.notStartedLable) ||
            styles.overdueLable
        };
      });
    data && data.unshift({ value: null, label: "Status" });
    return data;
  };

  const selectedStatusHandler = (value: string) => {
    if (value === FilterStatus.completed) {
      setStatusLabelStyle(styles.completedLabel);
    } else if (value === FilterStatus.notStarted) {
      setStatusLabelStyle(styles.notStartedLable);
    } else if (value === FilterStatus.overdue) {
      setStatusLabelStyle(styles.overdueLable);
    } else setStatusLabelStyle(null);

    setSelectedValues((prevState) => ({
      ...prevState,
      status: value
    }));
  };
  const selectedAuditorHandler = (value: string) => {
    setReset(false);
    setSelectedValues((prevState) => ({
      ...prevState,
      name: value
    }));
  };
  const selectedDateHandler = (value: any) => {
    setReset(false);
    if (dateFormat(value) !== dateFormat(selectedValues.date)) {
      setSelectedValues((prevState) => ({
        ...prevState,
        date: value
      }));
    }
  };

  const resetState = () => {
    setSelectedValues((prevState) => ({
      ...prevState,
      date: null,
      fromDate: null,
      toDate: null,
      name: "",
      search: "",
      status: null
    }));
    setStatusLabelStyle(null);
    setReset(true);
  };

  useEffect(() => {
    if (JSON.stringify(prevSelectedValues.current) !== JSON.stringify(selectedValues)) {
      dropDownValueHandler(selectedValues);
    }

    prevSelectedValues.current = selectedValues;
  }, [selectedValues]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text
            textStyle={styles.textStyles}
            fontWeight={FontWeight.Regular}
            textSize={TextSize.ExtraLarge}
            testId={testIds.actionPlan.allActionPlans}
          >
            {translate("actionPlanHeading")}
          </Text>
          {/* <Button
            type={ButtonType.Primary}
            onPress={null}
            buttonStyles={styles.button}
            iconSize={12}
            iconName={IconNames.tableDownload}
            iconStyles={styles.iconStyles}
          /> */}
          {isIndividualLogin
            ? (isShowFilter && (
                <ShowFiltersView
                  translate={translate}
                  setShowFilter={() => setShowFilter(!isShowFilter)}
                  resetState={resetState}
                />
              )) || (
                <HideFilters
                  translate={translate}
                  setShowFilter={() => setShowFilter(!isShowFilter)}
                />
              )
            : null}
          <ResetFilters
            resetContainerStyle={{ marginTop: 4 }}
            translate={translate}
            setShowFilter={() => setShowFilter(!isShowFilter)}
            resetState={resetState}
          />
        </View>
        {!isIndividualLogin && (
          <View style={styles.dropdownContainer}>
            <View style={styles.filtersContainer}>
              {PlatformUtils.isWeb() ? (
                <DatePicker
                  parentCallBack={(value) => selectedDateHandler(value)}
                  dateContainerStyle={styles.datePickerContainer}
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.ExtraSmall}
                  textStyles={styles.datePickerText}
                  iconStyles={styles.datePickerIcon}
                  iconSize={12}
                  reset={reset}
                  placeholderText={translate("Select Dates")}
                />
              ) : (
                <CalendarComponent
                  selectRange={false}
                  parentCallBack={(value) => selectedDateHandler(value)}
                  reset={reset}
                  textStyles={styles.datePickerText}
                  iconStyles={styles.datePickerIcon}
                  iconSize={12}
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.ExtraSmall}
                  dateContainerStyle={styles.datePickerContainer}
                  placeholderText={translate("Select Dates")}
                />
              )}
            </View>
            <View style={styles.filtersContainer}>
              <ModalDropdown
                allItems={getListData(filterData && filterData.auditorName)}
                selectedValueDefault={selectedValues.name}
                onChangeCallBack={(obj: IDropDownItem) => selectedAuditorHandler(obj.value)}
                selectedItemStyle={
                  selectedValues.name === ""
                    ? styles.defaultAuditorStyle
                    : styles.selctedAuditorStyle
                }
              />
            </View>
            <ModalDropdown
              allItems={getFilterData()}
              selectedValueDefault={selectedValues.status}
              onChangeCallBack={(obj: IDropDownItem) => selectedStatusHandler(obj.value)}
              selectedItemStyle={statusLabelStyle}
            />
          </View>
        )}
      </View>
      {isIndividualLogin && isShowFilter ? (
        <Filters
          parentCallBackFrom={(value: any) =>
            selectedFromDateHandler(value, setReset, selectedValues, setSelectedValues)
          }
          // parentCallBackTo={(value: any) => selectedToDateHandler(value)}
          searchFunction={(value: any) => {
            selectedSearchHandler(value, setReset, setSelectedValues);
          }}
          resetHandler={resetState}
          searchValue={selectedValues.search}
          reset={reset}
          allItems={getFilterData()}
          selectedValueDefault={selectedValues.status}
          onChangeCallBack={(obj: IDropDownItem) => selectedStatusHandler(obj.value)}
          selectedItemStyle={statusLabelStyle}
          translate={translate}
          // disableSelectedPrevDate={selectedValues && selectedValues.fromDate}
        />
      ) : null}
    </>
  );
}
export default ActionPlanHeader;
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  textStyles: {
    color: colorPallete.black3,
    ...I18nService.select({
      rtl: { marginEnd: 28 },
      ltr: { marginEnd: 28 }
    })
    // marginEnd: 28
  },
  button: {
    backgroundColor: colorPallete.white1,
    borderRadius: 8,
    marginTop: 6
  },
  iconStyles: {
    color: colorPallete.grey2,
    padding: 6
  },
  dropdownContainer: {
    height: 36,
    flexDirection: "row"
  },
  datePickerContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colorPallete.white1
  },
  datePickerText: {
    color: colorPallete.black4
  },
  datePickerIcon: {
    color: colorPallete.grey2
  },
  icondropDown: {
    color: colorPallete.grey2,
    ...I18nService.select({
      rtl: { marginStart: 0 },
      ltr: { marginStart: 0 }
    })
    // marginStart: 0
  },
  completedLabel: {
    color: colorPallete.green1
  },
  notStartedLable: {
    color: colorPallete.red3
  },
  overdueLable: {
    color: colorPallete.yellow1
  },
  selctedAuditorStyle: {
    color: colorPallete.black4
  },
  defaultAuditorStyle: {
    color: colorPallete.grey2
  },
  filtersContainer: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    })
    // marginEnd: 24
  }
});
