import { CALENDAR } from "../actions/actionTypes";

export interface ICalendarModuleLoading {
  getAllEvents: Boolean;
  getGccCountries: Boolean;
  createEvents: Boolean;
  getAreaCountryManager: Boolean;
  getEmployees: Boolean;
  getStores: Boolean;
  deleteEvent: Boolean;
  updateEvent: Boolean;
  getEventById: Boolean;
}

export interface ICalendarModuleError {
  getAllEvents: string;
  getGccCountries: string;
  createEvents: string;
  getAreaCountryManager: string;
  getEmployees: string;
  getStores: string;
  deleteEvent: string;
  updateEvent: string;
  getEventById: string;
}

export interface ICalendarModuleState {
  loading: ICalendarModuleLoading;
  error: ICalendarModuleError;
  getAllEvents: any;
  getGccCountries: any;
  createEvent: null;
  getEmployees: null;
  getAreaCountryManager: [];
  getStores: [];
  deleteEvent: null;
  updateEvent: null;
  getEventById: null;
}

export const calendarModuleInitialState: ICalendarModuleState = {
  loading: {
    getAllEvents: false,
    getGccCountries: false,
    createEvents: false,
    getAreaCountryManager: false,
    getEmployees: false,
    getStores: false,
    deleteEvent: false,
    updateEvent: false,
    getEventById: false
  },
  error: {
    getAllEvents: null,
    getGccCountries: null,
    createEvents: null,
    getAreaCountryManager: null,
    getEmployees: null,
    getStores: null,
    deleteEvent: null,
    updateEvent: null,
    getEventById: null
  },
  getAllEvents: null,
  getGccCountries: null,
  createEvent: null,
  getAreaCountryManager: [],
  getEmployees: null,
  getStores: [],
  deleteEvent: null,
  updateEvent: null,
  getEventById: null
};

export const calendarModuleReducer = (
  state = calendarModuleInitialState,
  action: { payload: any; type: string }
): ICalendarModuleState => {
  switch (action.type) {
    case CALENDAR.GET_ALL_EVENTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllEvents: true },
        deleteEvent: null,
        createEvent: null,
        updateEvent: null
      };

    case CALENDAR.GET_ALL_EVENTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllEvents: false },
        getAllEvents: action.payload
      };
    case CALENDAR.GET_ALL_EVENTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllEvents: false },
        error: { ...state.error, getAllEvents: action.payload }
      };
    case CALENDAR.GET_GCC_COUNTRIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getGccCountries: true }
      };
    case CALENDAR.GET_GCC_COUNTRIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getGccCountries: false },
        getGccCountries: action.payload
      };

    case CALENDAR.GET_GCC_COUNTRIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getGccCountries: false },
        error: { ...state.error, getGccCountries: action.payload }
      };

    case CALENDAR.CREATE_EVENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, createEvents: true },
        error: { ...state.error, createEvents: null },
        deleteEvent: null,
        createEvent: null,
        updateEvent: null
      };
    case CALENDAR.CREATE_EVENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createEvents: false },
        error: { ...state.error, createEvents: null },
        createEvent: action.payload
      };
    case CALENDAR.CREATE_EVENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, createEvents: false },
        error: { ...state.error, createEvents: action.payload }
      };

    case CALENDAR.GET_AREA_COUNTRY_MANAGER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAreaCountryManager: true }
      };

    case CALENDAR.GET_AREA_COUNTRY_MANAGER.SUCCESS:
      let newData: any = [];
      state.getAreaCountryManager.forEach((item: any) => newData.push(item));

      action.payload.content.forEach((item: any) => {
        newData.push({ ...item, value: item?.label });
      });

      return {
        ...state,
        loading: { ...state.loading, getAreaCountryManager: false },
        getAreaCountryManager: newData
      };
    case CALENDAR.GET_AREA_COUNTRY_MANAGER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAreaCountryManager: false },
        error: { ...state.error, getAreaCountryManager: action.payload }
      };

    case CALENDAR.RESET_DATA.LOADING:
      return {
        ...state,
        getAreaCountryManager: []
      };
    case CALENDAR.GET_EMPLOYEES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEmployees: true }
      };
    case CALENDAR.GET_EMPLOYEES.SUCCESS:
      let newEmpData: any = [];
      action.payload.content.forEach((item: any) => {
        newEmpData.push({ ...item, value: item?.label });
      });
      return {
        ...state,
        loading: { ...state.loading, getEmployees: false },
        getEmployees: newEmpData
      };
    case CALENDAR.GET_EMPLOYEES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEmployees: false },
        error: { ...state.error, getEmployees: action.payload }
      };
    case CALENDAR.GET_STORES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getStores: true }
      };
    case CALENDAR.GET_STORES.SUCCESS:
      let newStoreData: any = [];
      state.getStores.forEach((item: any) => newStoreData.push(item));
      action.payload.forEach((item: any) => {
        newStoreData.push({ ...item, value: item?.label });
      });
      return {
        ...state,
        loading: { ...state.loading, getStores: false },
        getStores: newStoreData
      };
    case CALENDAR.GET_STORES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStores: false },
        error: { ...state.error, getStores: action.payload }
      };
    case CALENDAR.DELETE_EVENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteEvent: true },
        error: { ...state.error, deleteEvent: null },
        deleteEvent: null,
        createEvent: null,
        updateEvent: null
      };

    case CALENDAR.DELETE_EVENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteEvent: false },
        error: { ...state.error, deleteEvent: null },

        deleteEvent: action.payload
      };
    case CALENDAR.DELETE_EVENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteEvent: false },
        error: { ...state.error, deleteEvent: action.payload }
      };
    case CALENDAR.UPDATE_EVENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateEvent: true },
        error: { ...state.error, updateEvent: null },
        deleteEvent: null,
        createEvent: null,
        updateEvent: null
      };

    case CALENDAR.UPDATE_EVENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateEvent: false },
        error: { ...state.error, updateEvent: null },
        updateEvent: action.payload
      };

    case CALENDAR.UPDATE_EVENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateEvent: false },
        error: { ...state.error, updateEvent: action.payload }
      };
    case CALENDAR.GET_EVENTBYID.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEventById: true },
        error: { ...state.error, getEventById: null }
      };

    case CALENDAR.GET_EVENTBYID.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEventById: false },
        error: { ...state.error, getEventById: null },
        getEventById: action.payload
      };

    case CALENDAR.GET_EVENTBYID.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEventById: false },
        error: { ...state.error, getEventById: action.payload }
      };
    default:
      return {
        ...state
      };
  }
};
