import { GetWeeklyActionQuestions } from "@timhortons/common/src/models/logs/actionLogs/cleaning";
import { TabObject } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import { Decision } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/timeTempActionTab";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

export const weeklyCleaningActionsFormatter = async (
  response: any
): Promise<GetWeeklyActionQuestions[]> => {
  let data: GetWeeklyActionQuestions[] = [];
  response &&
    response.map((item: any, index: number) => {
      let object: GetWeeklyActionQuestions = {
        uniqueId: "",
        id: null,
        question: "",
        assignee: "",
        comment: "",
        decision: null,
        images: [],
        answerStatus: "",
        specifyDate: ""
      };
      object.uniqueId = item.id;
      object.id = index + 1;
      object.question = item.description;
      object.answerStatus = item.answerStatus;
      object.comment = item.comment;
      object.specifyDate = PlatformUtils.isWeb()
        ? dateFormat(item.contactMaintenanceDate)
        : item.contactMaintenanceDate;
      object.assignee = item.employeeName;
      object.decision = item.hasResolved ? Decision.resolved : Decision.notResolved;
      if (item?.assets?.length > 0) {
        item?.assets?.map((el: any) => {
          let obj = {
            id: `dailyChecklist-actions-${el.id}`,
            signedUrl: el.signedUrl
          };
          object.images.push(obj);
        });
      }
      data.push(object);
    });
  return data;
};

export const timeTempFormatter = (data: any) => {
  let finalData: TabObject[] = [];
  data &&
    data.map((item: any, index: number) => {
      let object: any = {};
      object.id = index + 1;
      object.title = item.name;
      object.isValid =
        item.recordedTemp !== null
          ? item.minTemp <= item.recordedTemp && item.recordedTemp <= item.maxTemp
          : null;
      object.data = item.recordedTemp !== null ? item.recordedTemp : "-";
      object.degree = item.recordedTemp !== null ? "°C" : "";
      object.isComment = item.comment !== null ? true : false;
      object.comment =
        item.minTemp <= item.recordedTemp && item.recordedTemp <= item.maxTemp
          ? null
          : item.comment !== null
          ? item.comment
          : item.reason;
      object.decision = item.hasResolved ? Decision.resolved : Decision.notResolved;
      object.uniqueItemId = item.id;
      object.min = item.minTemp;
      object.max = item.maxTemp;
      object.method = item.method;
      finalData.push(object);
    });
  return finalData;
};
