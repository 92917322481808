import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  IMwtItemApiPayload,
  ITimeTempCategories,
  LogsAuthPayload
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import {
  LogsAuthModel,
  WeeklyCommentModel,
  WeeklyPostItemsResponseModel
} from "@timhortons/common/src/models/logs/mainLogs";

const getManagerWalkthroughCategory = (data: {
  actionName: string;
  storeCode: number;
  date: string;
  label: string;
}): IFluxStandardAction<{ actionName: string; storeCode: number; date: string; label: string }> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.GET_MANAGER_WALKTHROUGH_CATEGORIES.LOADING,
    payload: data
  };
};
const getManagerWalkthroughCategorySuccess = (
  data: ITimeTempCategories[]
): IFluxStandardAction<ITimeTempCategories[]> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.GET_MANAGER_WALKTHROUGH_CATEGORIES.SUCCESS,
    payload: data
  };
};
const getManagerWalkthroughCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.GET_MANAGER_WALKTHROUGH_CATEGORIES.ERROR,
    payload: error
  };
};

const getManagerWalkthroughItems = (
  data: IMwtItemApiPayload
): IFluxStandardAction<IMwtItemApiPayload> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.GET_ITEMS_LIST_BY_CATEGORY.LOADING,
    payload: data
  };
};
const getManagerWalkthroughItemsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.GET_ITEMS_LIST_BY_CATEGORY.SUCCESS,
    payload: data
  };
};
const getManagerWalkthroughItemsByError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.GET_ITEMS_LIST_BY_CATEGORY.ERROR,
    payload: error
  };
};
const postMWTItemsLoading = (data: WeeklyCommentModel): IFluxStandardAction<WeeklyCommentModel> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.POST_MWT_ITEMS.LOADING,
    payload: data
  };
};
const postMWTItemsSuccess = (
  data: WeeklyPostItemsResponseModel
): IFluxStandardAction<WeeklyPostItemsResponseModel> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.POST_MWT_ITEMS.SUCCESS,
    payload: data
  };
};
const postMWTItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.POST_MWT_ITEMS.ERROR,
    payload: error
  };
};
const resetMWTLogs = () => {
  return {
    type: LOGS.MainLogs.ManagerWalk.RESET_MWT_LOGS
  };
};
const resetMWTLogsError = () => {
  return {
    type: LOGS.MainLogs.ManagerWalk.RESET_MWT_ERROR
  };
};
const resetPostItemsResponse = () => {
  return {
    type: LOGS.MainLogs.ManagerWalk.RESET_MWT_POST_ITEMS_RESPONSE
  };
};
const checkMWTLogsAuth = (data: LogsAuthPayload): IFluxStandardAction<LogsAuthPayload> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.CHECK_MWT_LOGS_AUTH.LOADING,
    payload: data
  };
};

const checkMWTLogsAuthSuccess = (data: LogsAuthModel): IFluxStandardAction<LogsAuthModel> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.CHECK_MWT_LOGS_AUTH.SUCCESS,
    payload: data
  };
};

const checkMWTLogsAuthError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.ManagerWalk.CHECK_MWT_LOGS_AUTH.ERROR,
    payload: error
  };
};
const resetMWTLogsAuthError = () => {
  return {
    type: LOGS.MainLogs.ManagerWalk.RESET_MWT_LOGS_AUTH_ERROR
  };
};
const resetMWTLogsAuth = () => {
  return {
    type: LOGS.MainLogs.ManagerWalk.RESET_MWT_LOGS_AUTH
  };
};
export const ManagerWalkthroughActions = {
  getManagerWalkthroughCategory,
  getManagerWalkthroughCategorySuccess,
  getManagerWalkthroughCategoryError,
  getManagerWalkthroughItems,
  getManagerWalkthroughItemsSuccess,
  getManagerWalkthroughItemsByError,
  postMWTItemsLoading,
  postMWTItemsSuccess,
  postMWTItemsError,
  resetMWTLogs,
  resetMWTLogsError,
  resetPostItemsResponse,
  checkMWTLogsAuth,
  checkMWTLogsAuthSuccess,
  checkMWTLogsAuthError,
  resetMWTLogsAuthError,
  resetMWTLogsAuth
};
