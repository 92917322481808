import { productionSheetEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import {
  BakingTableModel,
  ICategoryList,
  IProductList,
  PostMainMenuApiResp,
  PostMainMenuPayload
} from "@timhortons/common/src/models/productionSheet/mainMenu";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ProductionSheetKeys } from "@timhortons/common/src/constants/apiKeys";
import { Logger } from "@timhortons/common/src/services/logger";
import { MainMenuTableId } from "@timhortons/common/src/modules/productionSheet/components/mainMenu";

class MainMenuRepository {
  getAllCategoryAndProducts = async (params: {
    storeCode: number;
    stationCode: string;
    date: string;
  }) => {
    const { storeCode, stationCode, date } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getAllCategoryAndProducts(storeCode, stationCode),
      { dateTime: date },
      ProductionSheetKeys.MAIN_MENU
    );
    let formattedData: any[] = [];

    try {
      if (stationCode === MainMenuTableId.BakingTableId) {
        data?.map((item: any) => {
          let deserializevar: BakingTableModel = deserialize(BakingTableModel, item);
          formattedData.push(deserializevar);
        });
      } else {
        data.map((item: any) => {
          let deserializevar: ICategoryList = deserialize(ICategoryList, item);
          formattedData.push(deserializevar);
        });
      }
      return formattedData;
    } catch (error) {
      Logger.error(JSON.stringify("repo/production-sheet", error, "getAllCategoryAndProducts"));
      return null;
    }
  };
  getAllStations = async () => {
    let data = await ApiClient.get(
      productionSheetEndPoints.getAllStations(),
      null,
      ProductionSheetKeys.MAIN_MENU
    );
    data.forEach((element: any) => {
      element.isSelected = false;
    });

    let stationData: IProductList[] = [];
    data.map((item: any) => {
      let deserializevar: IProductList = deserialize(IProductList, item);
      stationData.push(deserializevar);
    });
    return stationData;
  };
  postCategoryAndProducts = async (param: PostMainMenuPayload): Promise<PostMainMenuApiResp[]> => {
    let data = await ApiClient.post(
      productionSheetEndPoints.postCategoryAndProducts(),
      param,
      null,
      ProductionSheetKeys.MAIN_MENU
    );
    return data;
  };
}
const mainMenuRepository = new MainMenuRepository();
export { mainMenuRepository as MainMenuRepository };
