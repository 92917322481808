import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import {
  ObservationDataModel,
  PostObservationSuccessModel,
  StoreAuditInfoModel,
  YesNoDataModel,
  TippingPointsModel,
  SosAverageTimeModel,
  CommentDataModel,
  PreviousAuditDataModel,
  SubCategoryModel
} from "@timhortons/common/src/models/rev/selfCalibration";
import { IFluxStandardAction } from "store/interfaces";
import { DetailedReportIdModel } from "@timhortons/common/src/models/rev/reports";
import { IExternalMeta } from "@timhortons/common/src/repositories/rev/selfCalibration";
import { ComparisonStoresAdminModelList } from "models/profile";
export interface ISelfCalibrationLoading {
  checkAuth: boolean;
  storeAuditData: boolean;
  subCategoryData: boolean;
  detailedReportId: boolean;
  updateCompletion: boolean;
  observationData: boolean;
  asnweringYesNoResponse: boolean;
  commentData: boolean;
  postObservationSelection: boolean;
  submitAudit: boolean;
  tippingPoints: boolean;
  previousAuditData: boolean;
  sosAvgTime: boolean;
  postSosAvgTime: boolean;
  deleteAudit: boolean;
  getCommentData: boolean;
  deleteSos: boolean;
  getExternalMeta: boolean;
  postExternalAuditData: boolean;
  getStoreListByCodeOrName: boolean;
}
export interface ISelfCalibrationError {
  checkAuth: IFluxStandardAction<Error>;
  storeAuditData: IFluxStandardAction<Error>;
  subCategoryData: IFluxStandardAction<Error>;
  updateCompletion: IFluxStandardAction<Error>;
  detailedReportId: IFluxStandardAction<Error>;
  observationData: IFluxStandardAction<Error>;
  asnweringYesNoResponse: IFluxStandardAction<Error>;
  postObservationSelection: IFluxStandardAction<Error>;
  submitAudit: IFluxStandardAction<Error>;
  tippingPoints: IFluxStandardAction<Error>;
  commentData: IFluxStandardAction<Error>;
  previousAuditData: IFluxStandardAction<Error>;
  sosAvgTime: string;
  postSosAvgTime: string;
  deleteAudit: IFluxStandardAction<Error>;
  getCommentData: IFluxStandardAction<Error>;
  deleteSos: string;
  getExternalMeta: IFluxStandardAction<Error>;
  postExternalAuditData: IFluxStandardAction<Error>;
  getStoreListByCodeOrName: string;
}
export interface ISelfCalibrationState {
  auditType: AuditType;
  tippingPoints: TippingPointsModel;
  updateCompletion: number;
  checkAuth: StoreAuditInfoModel;
  error: ISelfCalibrationError;
  loading: ISelfCalibrationLoading;
  storeAuditData: StoreAuditInfoModel;
  subCategoryData: SubCategoryModel;
  detailedReportId: DetailedReportIdModel;
  observationData: ObservationDataModel[];
  asnweringYesNoResponse: YesNoDataModel;
  commentData: CommentDataModel;
  postObservationSelection: PostObservationSuccessModel;
  previousAuditData: PreviousAuditDataModel;
  sosAvgTimeData: SosAverageTimeModel[];
  postSosAvgTimeData: SosAverageTimeModel[];
  getCommentData: CommentDataModel[];
  reportedGenerated: { reportId: number; calibrationId: number };
  deleteSosData: any;
  externalMetaData: IExternalMeta;
  externalAuditResponse: any;
  getStoreListByCodeOrName: ComparisonStoresAdminModelList;
  isStoreListEnd: boolean;
  moreStoreListLoading: boolean;
}

export const revInitialState: ISelfCalibrationState = {
  auditType: null,
  checkAuth: null,
  storeAuditData: null,
  subCategoryData: null,
  detailedReportId: null,
  asnweringYesNoResponse: null,
  updateCompletion: null,
  postObservationSelection: null,
  tippingPoints: null,
  reportedGenerated: null,
  observationData: [],
  commentData: null,
  previousAuditData: null,
  sosAvgTimeData: null,
  postSosAvgTimeData: null,
  getCommentData: [],
  deleteSosData: null,
  externalMetaData: null,
  externalAuditResponse: null,
  getStoreListByCodeOrName: null,
  isStoreListEnd: false,
  moreStoreListLoading: false,
  error: {
    postExternalAuditData: null,
    getExternalMeta: null,
    commentData: null,
    checkAuth: null,
    storeAuditData: null,
    subCategoryData: null,
    detailedReportId: null,
    asnweringYesNoResponse: null,
    updateCompletion: null,
    observationData: null,
    postObservationSelection: null,
    tippingPoints: null,
    submitAudit: null,
    previousAuditData: null,
    sosAvgTime: null,
    postSosAvgTime: null,
    deleteAudit: null,
    getCommentData: null,
    deleteSos: null,
    getStoreListByCodeOrName: null
  },
  loading: {
    postExternalAuditData: false,
    getExternalMeta: false,
    storeAuditData: false,
    updateCompletion: false,
    subCategoryData: false,
    observationData: false,
    checkAuth: false,
    detailedReportId: false,
    asnweringYesNoResponse: false,
    commentData: false,
    postObservationSelection: false,
    submitAudit: false,
    tippingPoints: false,
    previousAuditData: false,
    sosAvgTime: null,
    postSosAvgTime: false,
    getCommentData: false,
    deleteAudit: false,
    deleteSos: false,
    getStoreListByCodeOrName: false
  }
};

export const selfCalibrationReducer = (
  state = revInitialState,
  action: { payload: any; type: string }
): ISelfCalibrationState => {
  switch (action.type) {
    case "SET_AUDIT_TYPE":
      return {
        ...state,
        auditType: action.payload.auditType
      };
    case REV.GET_STORE_AUDIT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, storeAuditData: true },
        error: { ...state.error, storeAuditData: null }
      };
    case REV.GET_STORE_AUDIT_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, storeAuditData: false },
        storeAuditData: action.payload
      };
    case REV.GET_STORE_AUDIT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, storeAuditData: false },
        error: { ...state.error, storeAuditData: action.payload }
      };
    case REV.RESET_AUTH_ID:
      state = revInitialState;
      return state;
    case REV.RESET_REPORT_ID:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: false },
        error: { ...state.error, submitAudit: null },
        reportedGenerated: null
      };
    case REV.CHECK_AUTH_ID.LOADING:
      return {
        ...state,
        loading: { ...state.loading, checkAuth: true },
        error: { ...state.error, checkAuth: null }
      };
    case REV.CHECK_AUTH_ID.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, checkAuth: false },
        checkAuth: action.payload
      };
    case REV.CHECK_AUTH_ID.ERROR:
      return {
        ...state,
        loading: { ...state.loading, checkAuth: false },
        error: { ...state.error, checkAuth: action.payload }
      };
    case REV.UPDATE_COMPLETION.SUCCESS:
      //This action upadte the completion percentage in headers
      return {
        ...state,
        loading: { ...state.loading, updateCompletion: false },
        updateCompletion:
          state && state.asnweringYesNoResponse && state.asnweringYesNoResponse.completionPercentage
      };
    case REV.UPDATE_COMMENT_DATA.LOADING:
    case REV.POST_COMMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, commentData: true },
        error: { ...state.error, commentData: null }
      };
    case REV.UPDATE_COMMENT_DATA.SUCCESS:
    case REV.POST_COMMENT_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, commentData: false },
        error: { ...state.error, commentData: null }
        // commentData: action.payload,
        // getCommentData: feedbackData
      };
    case REV.UPDATE_COMMENT_DATA.ERROR:
    case REV.POST_COMMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, commentData: false },
        error: { ...state.error, commentData: action.payload }
      };

    case REV.ANSWER_YES_NO_NA_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, asnweringYesNoResponse: true },
        error: { ...state.error, asnweringYesNoResponse: null }
      };

    case REV.ANSWER_YES_NO_NA_DATA.SUCCESS:
      let subCategories = [...state.subCategoryData.subCategories];
      const subCatIndex = subCategories.findIndex(
        (el) => el.id === action.payload?.metaData?.subCategoryId
      );
      const quesIndex = subCategories[subCatIndex].questionList.findIndex(
        (el) => el.id === action.payload?.metaData?.questionId
      );
      if (quesIndex > -1 && subCatIndex > -1) {
        subCategories[subCatIndex].questionList[quesIndex].options =
          action.payload?.metaData?.status;
        subCategories[subCatIndex].questionList[quesIndex].ztStatus =
          action.payload?.metaData?.ztStatus;
      }
      return {
        ...state,
        subCategoryData: { ...state.subCategoryData, subCategories },
        loading: { ...state.loading, asnweringYesNoResponse: false },
        asnweringYesNoResponse: action.payload.apiRes
      };

    case REV.ANSWER_YES_NO_NA_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, asnweringYesNoResponse: false },
        error: { ...state.error, asnweringYesNoResponse: action.payload }
      };
    case REV.GET_TIPPING_POINTS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, tippingPoints: true },
        error: { ...state.error, tippingPoints: null },
        tippingPoints: null
      };
    case REV.GET_TIPPING_POINTS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, tippingPoints: false },
        tippingPoints: action.payload
      };
    case REV.GET_TIPPING_POINTS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, tippingPoints: false },
        error: { ...state.error, tippingPoints: action.payload },
        tippingPoints: null
      };
    case REV.GET_SUB_CATEGORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, subCategoryData: true },
        subCategoryData: null,
        error: { ...state.error, subCategoryData: null }
      };
    case REV.GET_SUB_CATEGORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, subCategoryData: false },
        subCategoryData: action.payload
      };
    case REV.GET_SUB_CATEGORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, subCategoryData: false },
        error: { ...state.error, subCategoryData: action.payload }
      };
    case REV.GET_OBSERVATION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, observationData: true },
        error: { ...state.error, observationData: null }
      };
    case REV.GET_OBSERVATION_DATA.SUCCESS:
      let datum = [...state.observationData];
      const qIndex = datum.findIndex((obj) => obj.questionId === action.payload.questionId);
      if (qIndex > -1) {
        datum[qIndex] = action.payload;
      } else {
        datum.push(action.payload);
      }
      return {
        ...state,
        loading: { ...state.loading, observationData: false },
        observationData: datum
      };
    case REV.GET_OBSERVATION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, observationData: false },
        error: { ...state.error, observationData: action.payload }
      };
    case REV.POST_OBSERVATION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postObservationSelection: true },
        error: { ...state.error, postObservationSelection: null }
      };
    case REV.POST_OBSERVATION_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postObservationSelection: false },
        postObservationSelection: action.payload
      };
    case REV.POST_OBSERVATION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postObservationSelection: false },
        error: { ...state.error, postObservationSelection: action.payload }
      };

    case REV.GET_DETAILED_REPORT_ID.LOADING:
      return {
        ...state,
        loading: { ...state.loading, detailedReportId: true },
        error: { ...state.error, detailedReportId: null }
      };
    case REV.GET_DETAILED_REPORT_ID.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, detailedReportId: false },
        detailedReportId: action.payload
      };
    case REV.GET_DETAILED_REPORT_ID.ERROR:
      return {
        ...state,
        loading: { ...state.loading, detailedReportId: false },
        error: { ...state.error, detailedReportId: action.payload }
      };
    case REV.SUBMIT_AUDIT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: true },
        error: { ...state.error, submitAudit: null }
      };
    case REV.GET_PREVIOUS_AUDIT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, previousAuditData: true },
        error: { ...state.error, previousAuditData: null, checkAuth: null }
      };
    case REV.GET_PREVIOUS_AUDIT_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, previousAuditData: false },
        previousAuditData: action.payload[0]
      };
    case REV.GET_PREVIOUS_AUDIT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, previousAuditData: false },
        error: { ...state.error, previousAuditData: action.payload }
      };
    case REV.DELETE_AUDIT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteAudit: true },
        error: { ...state.error, deleteAudit: null }
      };
    case REV.DELETE_AUDIT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteAudit: false },
        previousAuditData: null
      };
    case REV.DELETE_AUDIT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteAudit: false },
        error: { ...state.error, deleteAudit: action.payload }
      };
    case REV.SUBMIT_AUDIT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: false },
        error: { ...state.error, submitAudit: action.payload }
      };
    case REV.SUBMIT_AUDIT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, submitAudit: false },
        error: { ...state.error, submitAudit: null },
        reportedGenerated: action.payload
      };
    case REV.GET_SOS_AVG_TIME.LOADING:
      return {
        ...state,
        loading: { ...state.loading, sosAvgTime: true },
        error: { ...state.error, sosAvgTime: null }
      };
    case REV.GET_SOS_AVG_TIME.SUCCESS:
      let data: SosAverageTimeModel[] = state.sosAvgTimeData || [];
      data[action.payload.subCategoryId] = action.payload;
      return {
        ...state,
        loading: { ...state.loading, sosAvgTime: false },
        error: { ...state.error, sosAvgTime: null },
        sosAvgTimeData: data
      };
    case REV.GET_SOS_AVG_TIME.ERROR:
      return {
        ...state,
        loading: { ...state.loading, sosAvgTime: false },
        error: { ...state.error, sosAvgTime: action.payload }
      };
    case REV.POST_SOS_AVG_TIME.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postSosAvgTime: true },
        error: { ...state.error, postSosAvgTime: null }
      };
    case REV.POST_SOS_AVG_TIME.SUCCESS:
      let postSosData = state.postSosAvgTimeData || [];
      postSosData[action.payload.subCategoryId] = action.payload;
      return {
        ...state,
        loading: { ...state.loading, postSosAvgTime: false },
        error: { ...state.error, postSosAvgTime: null },
        postSosAvgTimeData: postSosData,
        sosAvgTimeData: postSosData
      };
    case REV.POST_SOS_AVG_TIME.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postSosAvgTime: false },
        error: { ...state.error, postSosAvgTime: action.payload }
      };
    case REV.POST_SOS_AVG_TIME_RESET.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postSosAvgTime: false },
        error: { ...state.error, postSosAvgTime: null },
        postSosAvgTimeData: null
      };
    case REV.DELETE_SOS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteSos: true },
        error: { ...state.error, deleteSos: null },
        deleteSosData: null
      };
    case REV.DELETE_SOS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteSos: false },
        deleteSosData: action.payload
      };
    case REV.DELETE_SOS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteSos: false },
        error: { ...state.error, deleteSos: action.payload },
        deleteSosData: null
      };
    case REV.GET_COMMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCommentData: true },
        error: { ...state.error, getCommentData: null }
      };
    case REV.GET_COMMENT_DATA.SUCCESS:
      let datam = [...state.getCommentData];
      const subCategoryIndex = datam.findIndex(
        (obj) => obj.subCategoryId === action.payload.subCategoryId
      );
      if (subCategoryIndex > -1) {
        datam[subCategoryIndex] = action.payload;
      } else {
        datam.push(action.payload);
      }
      return {
        ...state,
        loading: { ...state.loading, getCommentData: false },
        getCommentData: datam
      };
    case REV.GET_COMMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCommentData: false },
        error: { ...state.error, getCommentData: action.payload }
      };
    case REV.GET_EXTERNAL_META.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getExternalMeta: true },
        error: { ...state.error, getExternalMeta: null }
      };
    case REV.GET_EXTERNAL_META.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getExternalMeta: false },
        error: { ...state.error, getExternalMeta: null },
        externalMetaData: action.payload
      };
    case REV.GET_EXTERNAL_META.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getExternalMeta: false },
        error: { ...state.error, getExternalMeta: action.payload }
      };
    case REV.POST_EXTERNAL_AUDIT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postExternalAuditData: true },
        error: { ...state.error, postExternalAuditData: null }
      };
    case REV.POST_EXTERNAL_AUDIT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postExternalAuditData: false },
        error: { ...state.error, postExternalAuditData: null },
        externalAuditResponse: action.payload
      };
    case REV.POST_EXTERNAL_AUDIT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postExternalAuditData: false },
        error: { ...state.error, postExternalAuditData: action.payload }
      };
    case REV.GET_STORELIST_BY_CODE_OR_NAME.LOADING:
      if (action.payload.pageNo === 0) {
        return {
          ...state,
          loading: { ...state.loading, getStoreListByCodeOrName: true },
          error: { ...state.error, getStoreListByCodeOrName: null }
        };
      } else {
        return { ...state, moreStoreListLoading: true };
      }

    case REV.GET_STORELIST_BY_CODE_OR_NAME.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getStoreListByCodeOrName: false },
        error: { ...state.error, getStoreListByCodeOrName: null },
        getStoreListByCodeOrName: action.payload,
        moreStoreListLoading: false
      };
    case REV.GET_STORELIST_BY_CODE_OR_NAME.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStoreListByCodeOrName: true },
        error: { ...state.error, getStoreListByCodeOrName: null },
        moreStoreListLoading: false
      };
    case REV.GET_STORELIST_BY_CODE_OR_NAME_LISTEND:
      return {
        ...state,
        loading: { ...state.loading, getStoreListByCodeOrName: false },
        isStoreListEnd: true,
        moreStoreListLoading: false
      };
    case REV.RESET_STORE_LIST_BYSTORE_CODENAME:
      return {
        ...state,
        getStoreListByCodeOrName: null
      };
    default:
      return state;
  }
};
export default selfCalibrationReducer;
