import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { LogsSummaryActions } from "@timhortons/common/src/redux/logs/actions/logsSummary";
import { LogsSummaryRepository } from "@timhortons/common/src/repositories/logs/logsSummary";
import {
  ICategoryMetaData,
  LogsSummaryDataModel,
  LogsSummaryPayload
} from "@timhortons/common/src/models/logs/mainLogs";
import { ComparisonStoresAdminModel } from "@timhortons/common/src/models/profile";
import { GetStoreListByCodeOrNamePayload } from "@timhortons/common/src/models/rev/selfCalibration";

function* getCategoriesMetaDataWorkerSaga(value: IFluxStandardAction<{ storeCode: number }>) {
  const { payload } = value;
  try {
    let data: ICategoryMetaData[] = yield call(
      LogsSummaryRepository.getCategoriesMetaData,
      payload
    );
    yield put(LogsSummaryActions.getCategoriesMetaDataSuccess(data));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "getCategoriesMetaDataWorkerSaga");

    yield put(LogsSummaryActions.getCategoriesMetaDataError(error));
  }
}
function* getTimeTempLogsSummaryDataWorkerSaga(value: IFluxStandardAction<LogsSummaryPayload>) {
  const { payload } = value;
  try {
    let data: LogsSummaryDataModel = yield call(LogsSummaryRepository.getLogsSummaryData, payload);

    yield put(LogsSummaryActions.getTimeTempLogsSummaryDataSuccess(data));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "getTimeTempLogsSummaryDataWorkerSaga");

    yield put(LogsSummaryActions.getTimeTempLogsSummaryDataError(error));
  }
}
function* getStationReadLogsSummaryDataWorkerSaga(value: IFluxStandardAction<LogsSummaryPayload>) {
  const { payload } = value;
  try {
    let data: LogsSummaryDataModel = yield call(LogsSummaryRepository.getLogsSummaryData, payload);
    yield put(LogsSummaryActions.getStationLogsSummaryDataSuccess(data));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "getStationReadLogsSummaryDataWorkerSaga");

    yield put(LogsSummaryActions.getStationLogsSummaryDataError(error));
  }
}
function* getCleaningLogsSummaryDataWorkerSaga(value: IFluxStandardAction<LogsSummaryPayload>) {
  const { payload } = value;
  try {
    let data: LogsSummaryDataModel = yield call(LogsSummaryRepository.getLogsSummaryData, payload);
    yield put(LogsSummaryActions.getCleaningLogsSummaryDataSuccess(data));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "getCleaningLogsSummaryDataWorkerSaga");

    yield put(LogsSummaryActions.getCleaningLogsSummaryDataError(error));
  }
}
function* getMWTLogsSummaryDataWorkerSaga(value: IFluxStandardAction<LogsSummaryPayload>) {
  const { payload } = value;
  try {
    let data: LogsSummaryDataModel = yield call(LogsSummaryRepository.getLogsSummaryData, payload);
    yield put(LogsSummaryActions.getMWTLogsSummaryDataSuccess(data));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "getMWTLogsSummaryDataWorkerSaga");

    yield put(LogsSummaryActions.getMWTLogsSummaryDataError(error));
  }
}
function* getStoreListDataWorkerSaga(value: IFluxStandardAction<GetStoreListByCodeOrNamePayload>) {
  const { payload } = value;
  try {
    let data: ComparisonStoresAdminModel = yield call(
      LogsSummaryRepository.getStoreListData,
      payload
    );
    yield put(LogsSummaryActions.getStoreListDataSuccess(data));
  } catch (error) {
    Logger.error("logs-logsSummary", error, "getStoreListDataWorkerSaga");

    yield put(LogsSummaryActions.getStoreListDataError(error));
  }
}
function* logsSummaryWatcherSaga() {
  yield takeLatest(LOGS.Summary.GET_CATEGORIES_META_DATA.LOADING, getCategoriesMetaDataWorkerSaga);
  yield takeLatest(
    LOGS.Summary.GET_TIME_TEMP_LOGS_SUMMARY_DATA.LOADING,
    getTimeTempLogsSummaryDataWorkerSaga
  );
  yield takeLatest(
    LOGS.Summary.GET_STATION_LOGS_SUMMARY_DATA.LOADING,
    getStationReadLogsSummaryDataWorkerSaga
  );
  yield takeLatest(
    LOGS.Summary.GET_CLEANING_LOGS_SUMMARY_DATA.LOADING,
    getCleaningLogsSummaryDataWorkerSaga
  );
  yield takeLatest(LOGS.Summary.GET_MWT_LOGS_SUMMARY_DATA.LOADING, getMWTLogsSummaryDataWorkerSaga);
  yield takeLatest(LOGS.Summary.GET_STORE_LIST.LOADING, getStoreListDataWorkerSaga);
}
export default logsSummaryWatcherSaga;
