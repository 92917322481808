import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "productionSheet";

const PRODUCTION_SHEET = {
  ManagerReview: {
    GET_MANAGER_REVIEW_PRODUCTION_SHEET: ActionHelper.actionTypesCreator(
      prefix,
      "GET_MANAGER_REVIEW_PRODUCTION_SHEET"
    ),
    UPDATE_MANAGE_REVIEW_ITEM: ActionHelper.actionTypesCreator(prefix, "UPDATE_MANAGE_REVIEW_ITEM"),
    CHECK_ACCESS_TO_STORE: ActionHelper.actionTypesCreator(prefix, "CHECK_ACCESS_TO_STORE"),
    RESET_MRModals: "RESET_MRModals"
  },
  MainMenu: {
    GET_ALL_CATEGORY_AND_PRODUCTS: ActionHelper.actionTypesCreator(
      prefix,
      "GET_ALL_CATEGORY_AND_PRODUCTS"
    ),
    POST_CATEGORY_AND_PRODUCTS: ActionHelper.actionTypesCreator(
      prefix,
      "POST_CATEGORY_AND_PRODUCTS"
    ),
    RESET_POST_MAIN_MENU: "RESET_POST_MAIN_MENU",
    GET_ALL_STATIONS: ActionHelper.actionTypesCreator(prefix, "GET_ALL_STATIONS")
  },
  ProductionSheet: {
    GET_STATION_PRODUCTS: ActionHelper.actionTypesCreator(prefix, "GET_STATION_PRODUCTS"),
    GET_PRODUCT_ITEMS: ActionHelper.actionTypesCreator(prefix, "GET_PRODUCT_ITEMS"),
    GET_STORE_HEADERS: ActionHelper.actionTypesCreator(prefix, "GET_STORE_HEADERS"),
    GET_AM_PM_EMPLOYEE: ActionHelper.actionTypesCreator(prefix, "GET_AM_PM_EMPLOYEE"),
    POST_AM_PM_EMPLOYEE_AUTH: ActionHelper.actionTypesCreator(prefix, "POST_AM_PM_EMPLOYEE_AUTH"),
    GET_OTHERS_PRODUCTS: ActionHelper.actionTypesCreator(prefix, "GET_OTHERS_PRODUCTS"),
    GET_OTHERS_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "GET_OTHERS_CATEGORIES"),
    POST_PRODUCTION_SHEET_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "POST_PRODUCTION_SHEET_DATA"
    ),
    POST_OTHERS_PRODUCTION_SHEET_DATA: ActionHelper.actionTypesCreator(
      prefix,
      "POST_OTHERS_PRODUCTION_SHEET_DATA"
    ),
    GET_STORE_SPECIFIC_STATIONS: ActionHelper.actionTypesCreator(
      prefix,
      "GET_STORE_SPECIFIC_STATIONS"
    ),
    RESET_PRODUCTION_SHEET: "RESET_PRODUCTION_SHEET",
    RESET_PS_STATION_AUTH: "PRODSHEET/RESET_PS_STATION_AUTH",
    RESET_BAKING_PS_ERROR_STATE: "PRODSHEET/RESET_BAKING_PS_ERROR_STATE",
    RESET_HEADER_PRODUCT_STATION: "PRODSHEET/RESET_HEADER_PRODUCT_STATION"
  },
  ReportBuilder: {
    GENERATE_REPORT: ActionHelper.actionTypesCreator(prefix, "GENERATE_REPORT"),
    GET_KPI_DATA: ActionHelper.actionTypesCreator(prefix, "GET_KPI_DATA")
  }
};

export { PRODUCTION_SHEET };
