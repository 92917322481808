import { IMcqData } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMCQ";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import {
  ManageLmsPostCreateModuleDataModel,
  ManageLmsPostCreateModuleResponseDataModel,
  LMSGetAllModulesDataModel,
  LMSGetModuleByIdModel,
  LMSCategoryModel,
  LMSPostTopicModel,
  LMSPostTopicResponseModel,
  LMSUploadVideoModel,
  LMSGetModuleCategoriesModel,
  LmsEditCategoryModel,
  LMSgetTopicsModel,
  LMSGetProgressBarStatusModel,
  LMSPostProgressBarStatusModel,
  PostLMSMCQTicTacQuiz,
  LMSGetVideoUploadLink,
  LMSPostSubtopicModel,
  LMSEditSubtopicModel,
  LMSEditTopicModel,
  LMSTicTacQuizModel,
  LMSMtfDndQuizModel,
  LMSImgDataModel,
  LMSDeleteVideoModel,
  LMSCertificateModel,
  LMSQuizMetaDataModel,
  LMSOptionDefinitionModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import { ISummaryData } from "@timhortons/common/src/repositories/manageLms/manageLmsFormatter";
import { IMtf } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMatchTheFollowing";

//* Action for create module
const postManageLmsCreateModule = (
  data: ManageLmsPostCreateModuleDataModel
): IFluxStandardAction<ManageLmsPostCreateModuleDataModel> => {
  return {
    type: MANAGELMS.POST_CREATE_MODULE.LOADING,
    payload: data
  };
};
const postManageLmsCreateModuleSuccess = (
  data: ManageLmsPostCreateModuleResponseDataModel
): IFluxStandardAction<ManageLmsPostCreateModuleResponseDataModel> => {
  return {
    type: MANAGELMS.POST_CREATE_MODULE.SUCCESS,
    payload: data
  };
};
const postManageLmsCreateModuleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.POST_CREATE_MODULE.ERROR,
    payload: error
  };
};

//* Action for  GetAllModules
const getLMSAllModules = (): IFluxStandardAction => {
  return {
    type: MANAGELMS.GET_ALL_MODULE.LOADING
  };
};
const getLMSAllModulesSuccess = (
  data: LMSGetAllModulesDataModel[]
): IFluxStandardAction<LMSGetAllModulesDataModel[]> => {
  return {
    type: MANAGELMS.GET_ALL_MODULE.SUCCESS,
    payload: data
  };
};
const getLMSAllModulesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_ALL_MODULE.ERROR,
    payload: error
  };
};

//* Action for get modules by id
const getLmsModuleById = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.GET_MODULE_BY_ID.LOADING,
    payload: data
  };
};

const getLmsModuleByIdSuccess = (data: LMSGetModuleByIdModel): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_MODULE_BY_ID.SUCCESS,
    payload: data
  };
};

const getLmsModuleByIdError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_MODULE_BY_ID.ERROR,
    payload: error
  };
};

//* Actions for post category

const postLmsCategory = (data: {
  moduleId: string;
  moduleName: string;
}): IFluxStandardAction<{ moduleId: string; moduleName: string }> => {
  return {
    type: MANAGELMS.POST_CATEGORY.LOADING,
    payload: data
  };
};

const postLmsCategorySuccess = (data: LMSCategoryModel): IFluxStandardAction<LMSCategoryModel> => {
  return {
    type: MANAGELMS.POST_CATEGORY.SUCCESS,
    payload: data
  };
};

const postLmsCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.POST_CATEGORY.SUCCESS,
    payload: error
  };
};

//* Actions for get all categories

const getLmsAllCategories = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_ALL_CATEGORIES.LOADING,
    payload: data
  };
};

const getLmsAllCategoriesSuccess = (data: LMSCategoryModel[]): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_ALL_CATEGORIES.SUCCESS,
    payload: data
  };
};

const getLmsAllCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_ALL_CATEGORIES.ERROR,
    payload: error
  };
};

//* Action for delete category

const deleteLmsCategory = (data: {
  categoryId: string;
}): IFluxStandardAction<{ categoryId: string }> => {
  return {
    type: MANAGELMS.DELETE_CATEGORY.LOADING,
    payload: data
  };
};

const deleteLmsCategorySuccess = (data: any): IFluxStandardAction<{ id: string; name: string }> => {
  return {
    type: MANAGELMS.DELETE_CATEGORY.SUCCESS,
    payload: data
  };
};

const deleteLmsCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.DELETE_CATEGORY.ERROR,
    payload: error
  };
};

//* Action for get category by ID

const getCategoryById = (data: {
  categoryId: string;
}): IFluxStandardAction<{ categoryId: string }> => {
  return {
    type: MANAGELMS.GET_CATEGORY_BY_ID.LOADING,
    payload: data
  };
};

const getCategoryByIdSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_CATEGORY_BY_ID.SUCCESS,
    payload: data
  };
};

const getCategoryByIdError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_CATEGORY_BY_ID.ERROR,
    payload: error
  };
};

//* Action for post topic

const postTopic = (data: LMSPostTopicModel): IFluxStandardAction<LMSPostTopicModel> => {
  return {
    type: MANAGELMS.POST_TOPIC.LOADING,
    payload: data
  };
};

const postTopicSuccess = (
  data: LMSPostTopicResponseModel
): IFluxStandardAction<LMSPostTopicResponseModel> => {
  return {
    type: MANAGELMS.POST_TOPIC.SUCCESS,
    payload: data
  };
};

const postTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.POST_TOPIC.ERROR,
    payload: error
  };
};

//* Action for get module categories

const getModuleCategories = (data: LMSPostTopicModel): IFluxStandardAction<LMSPostTopicModel> => {
  return {
    type: MANAGELMS.GET_MODULE_CATEGORIES.LOADING,
    payload: data
  };
};

const getModuleCategoriesSuccess = (
  data: LMSGetModuleCategoriesModel[]
): IFluxStandardAction<LMSGetModuleCategoriesModel[]> => {
  return {
    type: MANAGELMS.GET_MODULE_CATEGORIES.SUCCESS,
    payload: data
  };
};

const getModuleCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_MODULE_CATEGORIES.ERROR,
    payload: error
  };
};

//* Action for upload video

const uploadLmsVideo = (data: LMSUploadVideoModel): IFluxStandardAction<LMSUploadVideoModel> => {
  return {
    type: MANAGELMS.UPLOAD_VIDEO.LOADING,
    payload: data
  };
};
const uploadLmsVideoSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.UPLOAD_VIDEO.SUCCESS,
    payload: data
  };
};
const uploadLmsVideoError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.UPLOAD_VIDEO.ERROR,
    payload: error
  };
};

//* Action for get upload video link

const getVideoUploadLink = (
  data: LMSGetVideoUploadLink
): IFluxStandardAction<LMSGetVideoUploadLink> => {
  return {
    type: MANAGELMS.GET_VIDEO_UPLOAD_LINK.LOADING,
    payload: data
  };
};

const getVideoUploadLinkSuccess = (data: {
  getUrl: string;
  uploadUrl: string;
}): IFluxStandardAction<{ getUrl: string; uploadUrl: string }> => {
  return {
    type: MANAGELMS.GET_VIDEO_UPLOAD_LINK.SUCCESS,
    payload: data
  };
};

const getVideoUploadLinkError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_VIDEO_UPLOAD_LINK.SUCCESS,
    payload: error
  };
};

//* Action for edit category

const editLmsCategory = (data: LmsEditCategoryModel): IFluxStandardAction<LmsEditCategoryModel> => {
  return {
    type: MANAGELMS.EDIT_CATEGORY.LOADING,
    payload: data
  };
};

const editLmsCategorySuccess = (data: {
  id: string;
  name: string;
}): IFluxStandardAction<{ id: string; name: string }> => {
  return {
    type: MANAGELMS.EDIT_CATEGORY.SUCCESS,
    payload: data
  };
};

const editLmsCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.EDIT_CATEGORY.ERROR,
    payload: error
  };
};

//* Action for delete topic

const deleteLmsTopic = (data: { id: string }): IFluxStandardAction<{ id: string }> => {
  return {
    type: MANAGELMS.DELETE_TOPIC.LOADING,
    payload: data
  };
};

const deleteLmsTopicSuccess = (data: {
  id: string;
  name: string;
}): IFluxStandardAction<{ id: string; name: string }> => {
  return {
    type: MANAGELMS.DELETE_TOPIC.SUCCESS,
    payload: data
  };
};

const deleteLmsTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.DELETE_TOPIC.ERROR,
    payload: error
  };
};

//* Action for delete module
const deleteLmsModule = (data: { moduleId: string }): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.DELETE_MODULE.LOADING,
    payload: data
  };
};

const deleteLmsModuleSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.DELETE_MODULE.SUCCESS,
    payload: data
  };
};

const deleteLmsModuleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.DELETE_MODULE.ERROR,
    payload: error
  };
};

//* Action for getting topic by id

const getLmsTopicById = (data: {
  categoryId: string;
}): IFluxStandardAction<{ categoryId: string }> => {
  return {
    type: MANAGELMS.GET_TOPIC_BY_ID.LOADING,
    payload: data
  };
};

const getLmsTopicByIdSuccess = (
  data: LMSgetTopicsModel[]
): IFluxStandardAction<LMSgetTopicsModel[]> => {
  return {
    type: MANAGELMS.GET_TOPIC_BY_ID.SUCCESS,
    payload: data
  };
};

const getLmsTopicByIdError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_TOPIC_BY_ID.ERROR,
    payload: error
  };
};

//* Action for getting progressBar status

const getProgressBarStatus = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.GET_PROGRESSBAR_STATUS.LOADING,
    payload: data
  };
};

const getProgressBarStatusSuccess = (
  data: LMSGetProgressBarStatusModel
): IFluxStandardAction<LMSGetProgressBarStatusModel> => {
  return {
    type: MANAGELMS.GET_PROGRESSBAR_STATUS.SUCCESS,
    payload: data
  };
};

const getProgressBarStatusError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_PROGRESSBAR_STATUS.ERROR,
    payload: error
  };
};

//* Action for post progress Bar Status

const postProgressBarStatus = (data: {
  moduleId: string;
  updateStepName: string;
  isSpecialAssessment: boolean;
}): IFluxStandardAction<{
  moduleId: string;
  updateStepName: string;
  isSpecialAssessment: boolean;
}> => {
  return {
    type: MANAGELMS.POST_PROGRESSBAR_STATUS.LOADING,
    payload: data
  };
};

const postProgressBarStatusSuccess = (
  data: LMSGetProgressBarStatusModel
): IFluxStandardAction<LMSGetProgressBarStatusModel> => {
  return {
    type: MANAGELMS.POST_PROGRESSBAR_STATUS.SUCCESS,
    payload: data
  };
};

const postProgressBarStatusError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.POST_PROGRESSBAR_STATUS.ERROR,
    payload: error
  };
};

//* Action for get Summary Details

const getSummaryDetails = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.GET_SUMMARY_DETAILS.LOADING,
    payload: data
  };
};

const getSummaryDetailsSuccess = (data: ISummaryData[]): IFluxStandardAction<ISummaryData[]> => {
  return {
    type: MANAGELMS.GET_SUMMARY_DETAILS.SUCCESS,
    payload: data
  };
};

const getSummaryDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_SUMMARY_DETAILS.ERROR,
    payload: error
  };
};

const postMCQTicTacQuiz = (data: {
  metaData: LMSQuizMetaDataModel;
  quizData: IMcqData[];
}): IFluxStandardAction<{
  metaData: LMSQuizMetaDataModel;
  quizData: IMcqData[];
}> => {
  return {
    type: MANAGELMS.POST_MCQ_TIC_QUIZ.LOADING,
    payload: data
  };
};

const postMCQTicTacQuizSuccess = (
  data: PostLMSMCQTicTacQuiz
): IFluxStandardAction<PostLMSMCQTicTacQuiz> => {
  return {
    type: MANAGELMS.POST_MCQ_TIC_QUIZ.SUCCESS,
    payload: data
  };
};

const postMCQTicTacQuizError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.POST_MCQ_TIC_QUIZ.ERROR,
    payload: error
  };
};

const getCategoryDetailsAll = (data: {
  categoryId: string;
}): IFluxStandardAction<{ categoryId: string }> => {
  return {
    type: MANAGELMS.GET_CATGORY_DETAILS_ALL.LOADING,
    payload: data
  };
};

const getCategoryDetailsAllSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_CATGORY_DETAILS_ALL.SUCCESS,
    payload: data
  };
};

const getCategoryDetailsAllError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_CATGORY_DETAILS_ALL.ERROR,
    payload: error
  };
};

//* Actions for edit lms topic
const editLmsTopic = (data: LMSEditTopicModel): IFluxStandardAction<LMSEditTopicModel> => {
  return {
    type: MANAGELMS.EDIT_TOPIC.LOADING,
    payload: data
  };
};

const editLmsTopicSuccess = (data: {
  id: string;
  name: string;
}): IFluxStandardAction<{
  id: string;
  name: string;
}> => {
  return {
    type: MANAGELMS.EDIT_TOPIC.SUCCESS,
    payload: data
  };
};

const editLmsTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.EDIT_TOPIC.ERROR,
    payload: error
  };
};

//* Action for confirm video upload

const confirmVideoUpload = (data: {
  topicId: string;
}): IFluxStandardAction<{ topicId: string }> => {
  return {
    type: MANAGELMS.CONFIRM_VIDEO_UPLOAD.SUCCESS,
    payload: data
  };
};

//* Action for adding subtopic

const postLmsSubtopic = (data: LMSPostSubtopicModel): IFluxStandardAction<LMSPostSubtopicModel> => {
  return {
    type: MANAGELMS.POST_SUBTOPIC.LOADING,
    payload: data
  };
};

const postLmsSubtopicSuccess = (data: {
  id: string;
  name: string;
}): IFluxStandardAction<{ id: string; name: string }> => {
  return {
    type: MANAGELMS.POST_SUBTOPIC.SUCCESS,
    payload: data
  };
};

const postLmsSubtopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.POST_SUBTOPIC.ERROR,
    payload: error
  };
};

// * Action for editing subtopic

const editLmsSubtopic = (data: LMSEditSubtopicModel): IFluxStandardAction<LMSEditSubtopicModel> => {
  return {
    type: MANAGELMS.EDIT_SUBTOPIC.LOADING,
    payload: data
  };
};

const editLmsSubtopicSuccess = (data: {
  id: string;
  name: string;
}): IFluxStandardAction<{ id: string; name: string }> => {
  return {
    type: MANAGELMS.EDIT_SUBTOPIC.SUCCESS,
    payload: data
  };
};

const editLmsSubtopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.EDIT_SUBTOPIC.ERROR,
    payload: error
  };
};

//* Action for delete subtopic

const deleteLmsSubtopic = (data: { id: string }): IFluxStandardAction<{ id: string }> => {
  return {
    type: MANAGELMS.DELETE_SUBTOPIC.LOADING,
    payload: data
  };
};

const deleteLmsSubtopicSuccess = (data: {
  id: string;
  name: string;
}): IFluxStandardAction<{ id: string; name: string }> => {
  return {
    type: MANAGELMS.DELETE_SUBTOPIC.SUCCESS,
    payload: data
  };
};

const deleteLmsSubtopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.DELETE_SUBTOPIC.ERROR,
    payload: error
  };
};

//* Action for delete video

const deleteLmsVideo = (data: LMSDeleteVideoModel): IFluxStandardAction<LMSDeleteVideoModel> => {
  return {
    type: MANAGELMS.DELETE_VIDEO.LOADING,
    payload: data
  };
};

const deleteLmsVideoSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.DELETE_VIDEO.SUCCESS,
    payload: data
  };
};

const deleteLmsVideoError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.DELETE_VIDEO.ERROR,
    payload: error
  };
};

const updateLmsModule = (data: {
  moduleId: string;
  obj: ManageLmsPostCreateModuleDataModel;
}): IFluxStandardAction<{ moduleId: string; obj: ManageLmsPostCreateModuleDataModel }> => {
  return {
    type: MANAGELMS.UPDATE_MODULE.LOADING,
    payload: data
  };
};

const updateLmsModuleSuccess = (
  data: ManageLmsPostCreateModuleResponseDataModel
): IFluxStandardAction<ManageLmsPostCreateModuleResponseDataModel> => {
  return {
    type: MANAGELMS.UPDATE_MODULE.SUCCESS,
    payload: data
  };
};

const updateLmsModuleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.UPDATE_MODULE.ERROR,
    payload: error
  };
};

const getTicTacQuiz = (data: { id: string }): IFluxStandardAction<{ id: string }> => {
  return {
    type: MANAGELMS.GET_MCQ_TIC_QUIZ.LOADING,
    payload: data
  };
};

const getTicTacQuizSuccess = (
  data: LMSTicTacQuizModel
): IFluxStandardAction<LMSTicTacQuizModel> => {
  return {
    type: MANAGELMS.GET_MCQ_TIC_QUIZ.SUCCESS,
    payload: data
  };
};
const getTicTacQuizError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_MCQ_TIC_QUIZ.ERROR,
    payload: error
  };
};

//*post mtf dnd quiz
const postMtfDnd = (data: {
  metaData: LMSQuizMetaDataModel;
  quizData: IMtf[];
}): IFluxStandardAction<{
  metaData: LMSQuizMetaDataModel;
  quizData: IMtf[];
}> => {
  return {
    type: MANAGELMS.POST_MTF_DND_QUIZ.LOADING,
    payload: data
  };
};

const postMtfDndSuccess = (data: LMSMtfDndQuizModel): IFluxStandardAction<LMSMtfDndQuizModel> => {
  return {
    type: MANAGELMS.POST_MTF_DND_QUIZ.SUCCESS,
    payload: data
  };
};
const postMtfDndError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.POST_MTF_DND_QUIZ.ERROR,
    payload: error
  };
};

//get mtf dnd quiz
const getMtfDnd = (data: { quizId: string }): IFluxStandardAction<{ quizId: string }> => {
  return {
    type: MANAGELMS.GET_MTF_DND_QUIZ.LOADING,
    payload: data
  };
};

const getMtfDndSuccess = (
  data: LMSOptionDefinitionModel[]
): IFluxStandardAction<LMSOptionDefinitionModel[]> => {
  return {
    type: MANAGELMS.GET_MTF_DND_QUIZ.SUCCESS,
    payload: data
  };
};
const getMtfDndError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_MTF_DND_QUIZ.ERROR,
    payload: error
  };
};

//*lms image upload
const lmsImgUpload = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.LMS_IMG_UPLOAD.LOADING,
    payload: data
  };
};

const lmsImgUploadSuccess = (data: LMSImgDataModel): IFluxStandardAction<LMSImgDataModel> => {
  return {
    type: MANAGELMS.LMS_IMG_UPLOAD.SUCCESS,
    payload: data
  };
};
const lmsImgUploadError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.LMS_IMG_UPLOAD.ERROR,
    payload: error
  };
};

//*lms image Delete
const lmsImgDelete = (data: { imageKey: string }): IFluxStandardAction<{ imageKey: string }> => {
  return {
    type: MANAGELMS.LMS_IMG_DELETE.LOADING,
    payload: data
  };
};

const lmsImgDeleteSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.LMS_IMG_DELETE.SUCCESS,
    payload: data
  };
};
const lmsImgDeleteError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.LMS_IMG_DELETE.ERROR,
    payload: error
  };
};

//* lms get certificate
const lmsGetCertificate = (data: {
  moduleId: String;
}): IFluxStandardAction<{ moduleId: String }> => {
  return {
    type: MANAGELMS.LMS_GET_CERTIFICATE.LOADING,
    payload: data
  };
};
const lmsGetCertificateSuccess = (
  data: LMSCertificateModel
): IFluxStandardAction<LMSCertificateModel> => {
  return {
    type: MANAGELMS.LMS_GET_CERTIFICATE.SUCCESS,
    payload: data
  };
};
const lmsGetCertificateError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.LMS_GET_CERTIFICATE.ERROR,
    payload: error
  };
};

//lms post certificate
const lmsPostCertificate = (
  data: LMSCertificateModel
): IFluxStandardAction<LMSCertificateModel> => {
  return {
    type: MANAGELMS.LMS_POST_CERTIFICATE.LOADING,
    payload: data
  };
};

const lmsPostCertificateSuccess = (
  data: LMSCertificateModel
): IFluxStandardAction<LMSCertificateModel> => {
  return {
    type: MANAGELMS.LMS_POST_CERTIFICATE.SUCCESS,
    payload: data
  };
};
const lmsPostCertificateError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.LMS_POST_CERTIFICATE.ERROR,
    payload: error
  };
};

const resetModuleData = (): IFluxStandardAction => {
  return {
    type: MANAGELMS.RESET_MODULE_DATA.LOADING,
    payload: null
  };
};
const deleteMcqTtt = (data: { id: string }): IFluxStandardAction<{ id: string }> => {
  return {
    type: MANAGELMS.DELETE_MCQ_TTT.LOADING,
    payload: data
  };
};

const deleteMcqTttSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.DELETE_MCQ_TTT.SUCCESS,
    payload: data
  };
};

const deleteMcqTttError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.DELETE_MCQ_TTT.ERROR,
    payload: error
  };
};

//*update mtf dnd quiz
const updateMtfDnd = (data: {
  metaData: LMSQuizMetaDataModel;
  quizData: IMtf[];
}): IFluxStandardAction<{
  metaData: LMSQuizMetaDataModel;
  quizData: IMtf[];
}> => {
  return {
    type: MANAGELMS.UPDATE_MTF_DND.LOADING,
    payload: data
  };
};

const updateMtfDndSuccess = (data: LMSMtfDndQuizModel): IFluxStandardAction<LMSMtfDndQuizModel> => {
  return {
    type: MANAGELMS.UPDATE_MTF_DND.SUCCESS,
    payload: data
  };
};
const updateMtfDndError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.UPDATE_MTF_DND.ERROR,
    payload: error
  };
};

const updateMcqTtt = (data: {
  metaData: LMSQuizMetaDataModel;
  quizData: IMcqData[];
}): IFluxStandardAction<{
  metaData: LMSQuizMetaDataModel;
  quizData: IMcqData[];
}> => {
  return {
    type: MANAGELMS.UPDATE_MCQ_TTT.LOADING,
    payload: data
  };
};

const updateMcqTttSuccess = (
  data: PostLMSMCQTicTacQuiz
): IFluxStandardAction<PostLMSMCQTicTacQuiz> => {
  return {
    type: MANAGELMS.UPDATE_MCQ_TTT.SUCCESS,
    payload: data
  };
};

const updateMcqTttError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.UPDATE_MCQ_TTT.ERROR,
    payload: error
  };
};

const deleteMtfDnd = (data: { id: string }): IFluxStandardAction<{ id: string }> => {
  return {
    type: MANAGELMS.DELETE_MTF_DND.LOADING,
    payload: data
  };
};

const deleteMtfDndSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.DELETE_MTF_DND.SUCCESS,
    payload: data
  };
};

const deleteMtfDndError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.DELETE_MTF_DND.ERROR,
    payload: error
  };
};

//lms UPDATE certificate
const lmsUpdateCertificate = (
  data: LMSCertificateModel
): IFluxStandardAction<LMSCertificateModel> => {
  return {
    type: MANAGELMS.LMS_UPDATE_CERTIFICATE.LOADING,
    payload: data
  };
};

const lmsUpdateCertificateSuccess = (
  data: LMSCertificateModel
): IFluxStandardAction<LMSCertificateModel> => {
  return {
    type: MANAGELMS.LMS_UPDATE_CERTIFICATE.SUCCESS,
    payload: data
  };
};
const lmsUpdateCertificateError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.LMS_UPDATE_CERTIFICATE.ERROR,
    payload: error
  };
};
const lmsResetQuizzesData = (): any => {
  return {
    type: MANAGELMS.RESET_QUIZZES_DATA,
    payload: null
  };
};

const publishLmsModule = (data: {
  moduleId: string;
  state: string;
}): IFluxStandardAction<{ moduleId: string; state: string }> => {
  return {
    type: MANAGELMS.PUBLISH_MODULE.LOADING,
    payload: data
  };
};

const publishLmsModuleSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.PUBLISH_MODULE.SUCCESS,
    payload: data
  };
};

const publishLmsModuleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.PUBLISH_MODULE.ERROR,
    payload: error
  };
};

const getVideoData = (data: {
  count: number;
  duration: number;
}): IFluxStandardAction<{ count: number; duration: number }> => {
  return {
    type: MANAGELMS.GET_VIDEO_DATA.LOADING,
    payload: data
  };
};

const getVideoDataSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_VIDEO_DATA.SUCCESS,
    payload: data
  };
};

const getVideoDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_VIDEO_DATA.ERROR,
    payload: error
  };
};

const getDashboardModules = (data: {
  moduleState: string;
  moduleType: string;
}): IFluxStandardAction<{ moduleState: string; moduleType: string }> => {
  return {
    type: MANAGELMS.GET_DASHBOARD_MODULES.LOADING,
    payload: data
  };
};

const getDashboardModulesSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_DASHBOARD_MODULES.SUCCESS,
    payload: data
  };
};

const getDashboardModulesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_DASHBOARD_MODULES.ERROR,
    payload: error
  };
};
const resetQuizError = (): any => {
  return {
    type: MANAGELMS.RESET_QUIZ_ERROR.LOADING
  };
};

const resetPublish = (): any => {
  return {
    type: MANAGELMS.RESET_PUBLISH.LOADING
  };
};

const resetImg = (): any => {
  return {
    type: MANAGELMS.RESET_IMG.LOADING
  };
};

const getDashboardCategories = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.GET_DASHBOARD_CATEGORIES.LOADING,
    payload: data
  };
};

const getDashboardCategoriesSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGELMS.GET_DASHBOARD_CATEGORIES.SUCCESS,
    payload: data
  };
};

const getDashboardCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.GET_DASHBOARD_CATEGORIES.ERROR,
    payload: error
  };
};

const resetModuleError = () => {
  return {
    type: MANAGELMS.RESET_MODULE_ERROR
  };
};

const resetDashboardError = () => {
  return {
    type: MANAGELMS.RESET_DASHBOARD_ERROR
  };
};

const resetCertificateError = () => {
  return {
    type: MANAGELMS.RESET_CERTIFICATE_ERROR
  };
};

export const ManageLmsModuleAction = {
  resetModuleError,
  getDashboardCategories,
  getDashboardCategoriesSuccess,
  getDashboardCategoriesError,
  getDashboardModules,
  getDashboardModulesError,
  getDashboardModulesSuccess,
  getVideoData,
  getVideoDataSuccess,
  getVideoDataError,
  publishLmsModule,
  publishLmsModuleSuccess,
  publishLmsModuleError,
  postManageLmsCreateModule,
  postManageLmsCreateModuleSuccess,
  postManageLmsCreateModuleError,
  getLMSAllModules,
  getLMSAllModulesSuccess,
  getLMSAllModulesError,
  getLmsModuleById,
  getLmsModuleByIdSuccess,
  getLmsModuleByIdError,
  postLmsCategory,
  postLmsCategorySuccess,
  postLmsCategoryError,
  getLmsAllCategories,
  getLmsAllCategoriesSuccess,
  getLmsAllCategoriesError,
  deleteLmsCategory,
  deleteLmsCategoryError,
  deleteLmsCategorySuccess,
  getCategoryById,
  getCategoryByIdError,
  getCategoryByIdSuccess,
  postTopic,
  postTopicSuccess,
  postTopicError,
  getModuleCategories,
  getModuleCategoriesSuccess,
  getModuleCategoriesError,
  uploadLmsVideo,
  uploadLmsVideoSuccess,
  uploadLmsVideoError,
  resetImg,
  editLmsCategory,
  editLmsCategorySuccess,
  editLmsCategoryError,
  deleteLmsTopic,
  deleteLmsTopicSuccess,
  deleteLmsTopicError,
  deleteLmsModule,
  deleteLmsModuleSuccess,
  deleteLmsModuleError,
  getLmsTopicById,
  getLmsTopicByIdSuccess,
  getLmsTopicByIdError,
  resetQuizError,
  getProgressBarStatus,
  getProgressBarStatusSuccess,
  getProgressBarStatusError,
  postProgressBarStatus,
  postProgressBarStatusSuccess,
  postProgressBarStatusError,
  getSummaryDetails,
  getSummaryDetailsSuccess,
  getSummaryDetailsError,
  postMCQTicTacQuiz,
  postMCQTicTacQuizSuccess,
  postMCQTicTacQuizError,
  getCategoryDetailsAll,
  getCategoryDetailsAllSuccess,
  getCategoryDetailsAllError,
  editLmsTopic,
  editLmsTopicSuccess,
  editLmsTopicError,
  getVideoUploadLink,
  getVideoUploadLinkError,
  getVideoUploadLinkSuccess,
  confirmVideoUpload,
  postLmsSubtopic,
  postLmsSubtopicSuccess,
  postLmsSubtopicError,
  editLmsSubtopic,
  editLmsSubtopicSuccess,
  editLmsSubtopicError,
  deleteLmsSubtopic,
  deleteLmsSubtopicSuccess,
  deleteLmsSubtopicError,
  deleteLmsVideo,
  deleteLmsVideoSuccess,
  deleteLmsVideoError,
  updateLmsModule,
  updateLmsModuleSuccess,
  updateLmsModuleError,
  getTicTacQuiz,
  getTicTacQuizSuccess,
  getTicTacQuizError,
  postMtfDnd,
  postMtfDndSuccess,
  postMtfDndError,
  getMtfDnd,
  getMtfDndSuccess,
  getMtfDndError,
  lmsImgUpload,
  lmsImgUploadSuccess,
  lmsImgUploadError,
  lmsImgDelete,
  lmsImgDeleteSuccess,
  lmsImgDeleteError,
  lmsGetCertificate,
  lmsGetCertificateSuccess,
  lmsGetCertificateError,
  lmsPostCertificate,
  lmsPostCertificateSuccess,
  lmsPostCertificateError,
  resetModuleData,
  deleteMcqTtt,
  deleteMcqTttSuccess,
  deleteMcqTttError,
  updateMcqTtt,
  updateMcqTttSuccess,
  updateMcqTttError,
  deleteMtfDnd,
  deleteMtfDndSuccess,
  deleteMtfDndError,
  updateMtfDnd,
  updateMtfDndSuccess,
  updateMtfDndError,
  lmsUpdateCertificate,
  lmsUpdateCertificateSuccess,
  lmsUpdateCertificateError,
  lmsResetQuizzesData,
  resetPublish,
  resetDashboardError,
  resetCertificateError
};
