import { manageExistingUserEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";

class ManageExistingUsersRepository {
  onBoardExistingUser = async (param: { employeeId: string; body: any }): Promise<any> => {
    const { employeeId, body } = param;

    let data = await ApiClient.post(
      manageExistingUserEndPoints.existingUsers(),
      body,
      { employeeId: employeeId },
      LMSKeys.LMS_MANAGE
    );
    return data;
  };

  GetExistingUserData = async (param: { employeeId: string }): Promise<any> => {
    let data = await ApiClient.get(
      manageExistingUserEndPoints.existingUsers(),
      param,
      LMSKeys.LMS_MANAGE
    );
    return data;
  };
}

const manageExistingUsersRepository = new ManageExistingUsersRepository();
export { manageExistingUsersRepository as ManageExistingUsersRepository };
