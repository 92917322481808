import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  ProductTemperatureCategoriesModel,
  WeeklyCleaningItemsModel,
  WeeklyCommentModel,
  WeeklyPostItemsResponseModel
} from "@timhortons/common/src/models/logs/mainLogs";
import {
  ILogShift,
  IProdTempCategories,
  ITimeTempCategories
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";

const getDeepCleaningItems = (data: IProdTempApi): IFluxStandardAction<IProdTempApi> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_DEEP_CLEANING_ITEMS.LOADING,
    payload: data
  };
};
const getDeepCleaningItemsSuccess = (
  data: WeeklyCleaningItemsModel[]
): IFluxStandardAction<WeeklyCleaningItemsModel[]> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_DEEP_CLEANING_ITEMS.SUCCESS,
    payload: data
  };
};
const getDeepCleaningItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_DEEP_CLEANING_ITEMS.ERROR,
    payload: error
  };
};
const postDeepCleaningItems = (
  data: WeeklyCommentModel
): IFluxStandardAction<WeeklyCommentModel> => {
  return {
    type: LOGS.MainLogs.Cleaning.POST_DEEP_CLEANING_ITEMS.LOADING,
    payload: data
  };
};
const postDeepCleaningItemsSuccess = (
  data: WeeklyPostItemsResponseModel
): IFluxStandardAction<WeeklyPostItemsResponseModel> => {
  return {
    type: LOGS.MainLogs.Cleaning.POST_DEEP_CLEANING_ITEMS.SUCCESS,
    payload: data
  };
};
const postDeepCleaningItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.POST_DEEP_CLEANING_ITEMS.ERROR,
    payload: error
  };
};
const getEmployeeDropDown = (data: {
  storeCode: number;
}): IFluxStandardAction<{ storeCode: number }> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_EMPLOYEE_DROPDOWN.LOADING,
    payload: data
  };
};
const getEmployeeDropDownSuccess = (
  data: any //TODO - model
): IFluxStandardAction<any> => {
  //TODO - model
  return {
    type: LOGS.MainLogs.Cleaning.GET_EMPLOYEE_DROPDOWN.SUCCESS,
    payload: data
  };
};
const getEmployeeDropDownError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_EMPLOYEE_DROPDOWN.ERROR,
    payload: error
  };
};
const getCleaningRestRoomItems = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_RESTROOM_ITEMS.LOADING,
    payload: data
  };
};
const getCleaningRestRoomItemsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_RESTROOM_ITEMS.SUCCESS,
    payload: data
  };
};
const getCleaningRestRoomItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_RESTROOM_ITEMS.ERROR,
    payload: error
  };
};
const postCleaningRestRoomItems = (
  value: WeeklyCommentModel
): IFluxStandardAction<WeeklyCommentModel> => {
  return {
    type: LOGS.MainLogs.Cleaning.POST_RESTROOM_ITEMS.LOADING,
    payload: value
  };
};
const postCleaningRestRoomItemsSuccess = (
  data: WeeklyPostItemsResponseModel
): IFluxStandardAction<WeeklyPostItemsResponseModel> => {
  return {
    type: LOGS.MainLogs.Cleaning.POST_RESTROOM_ITEMS.SUCCESS,
    payload: data
  };
};
const postCleaningRestRoomItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.POST_RESTROOM_ITEMS.ERROR,
    payload: error
  };
};

const resetCleaningErrors = () => {
  return {
    type: LOGS.MainLogs.Cleaning.RESET_CLEANING_ERRORS
  };
};
const getCleaningCategoriesLoading = (data: {
  actionName: string;
  storeCode: number;
  date: string;
}): IFluxStandardAction<{ actionName: string; storeCode: number; date: string }> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_CLEANING_CATEGORIES.LOADING,
    payload: data
  };
};
const cleaningCategoriesSuccess = (data: ITimeTempCategories[]): IFluxStandardAction<any> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_CLEANING_CATEGORIES.SUCCESS,
    payload: data
  };
};
const cleaningCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_CLEANING_CATEGORIES.ERROR,
    payload: error
  };
};
const getCleaningLogShift = (data: ILogShift): IFluxStandardAction<ILogShift> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_CLEANING_LOGS_SHIFT.LOADING,
    payload: data
  };
};
const getCleaningLogShiftSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_CLEANING_LOGS_SHIFT.SUCCESS,
    payload: data
  };
};
const getCleaningLogShiftError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_CLEANING_LOGS_SHIFT.ERROR,
    payload: error
  };
};

const resetCleaningState = () => {
  return {
    type: LOGS.MainLogs.Cleaning.RESET_CLEANING_STATE
  };
};

const getRestRoomProducts = (
  data: IProdTempCategories
): IFluxStandardAction<IProdTempCategories> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_RESTROOM_PRODUCTS.LOADING,
    payload: data
  };
};
const getRestRoomProductsSuccess = (
  data: ProductTemperatureCategoriesModel[]
): IFluxStandardAction<ProductTemperatureCategoriesModel[]> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_RESTROOM_PRODUCTS.SUCCESS,
    payload: data
  };
};
const getRestRoomProductsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.Cleaning.GET_RESTROOM_PRODUCTS.ERROR,
    payload: error
  };
};

export const CleaningActions = {
  getDeepCleaningItems,
  getDeepCleaningItemsSuccess,
  getDeepCleaningItemsError,
  getEmployeeDropDown,
  getEmployeeDropDownSuccess,
  getEmployeeDropDownError,
  postDeepCleaningItems,
  postDeepCleaningItemsSuccess,
  postDeepCleaningItemsError,
  getCleaningRestRoomItems,
  getCleaningRestRoomItemsSuccess,
  getCleaningRestRoomItemsError,
  postCleaningRestRoomItems,
  postCleaningRestRoomItemsSuccess,
  postCleaningRestRoomItemsError,
  resetCleaningErrors,
  getCleaningCategoriesLoading,
  cleaningCategoriesSuccess,
  cleaningCategoriesError,
  resetCleaningState,
  getCleaningLogShift,
  getCleaningLogShiftSuccess,
  getCleaningLogShiftError,
  getRestRoomProducts,
  getRestRoomProductsSuccess,
  getRestRoomProductsError
};
