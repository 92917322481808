import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";

import { SelectedMonthFilter } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsTableHeader";
import { monthsGregorian } from "@timhortons/common/src/components/molecules/customsCalenderPickers";
import {
  ICategoryMetaData,
  LogsSummaryDataModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { ComparisonStoresAdminModel } from "@timhortons/common/src/models/profile";

export interface ILogsSummaryLoading {
  getCategoriesMetaData: boolean;
  getTimeTempSummary: boolean;
  getStationReadSummary: boolean;
  getCleaningSummary: boolean;
  getMWTSummary: boolean;
  getStoreList: boolean;
}
export interface ILogsSummaryError {
  getCategoriesMetaData: string;
  getTimeTempSummary: string;
  getStationReadSummary: string;
  getCleaningSummary: string;
  getMWTSummary: string;
  getStoreList: string;
}
export interface ILogsSummaryState {
  loading: ILogsSummaryLoading;
  error: ILogsSummaryError;
  getCategoriesMetaData: ICategoryMetaData[];
  monthFilter: SelectedMonthFilter;
  getTimeTempSummary: LogsSummaryDataModel;
  getStationReadSummary: LogsSummaryDataModel;
  getCleaningSummary: LogsSummaryDataModel;
  getMWTSummary: LogsSummaryDataModel;
  storeList: ComparisonStoresAdminModel;
}
export const logsSummaryInitialState: ILogsSummaryState = {
  getCategoriesMetaData: null,
  monthFilter: {
    year: new Date().getFullYear(),
    month: {
      label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
      id: new Date().getMonth()
    }
  },
  getTimeTempSummary: null,
  getStationReadSummary: null,
  getCleaningSummary: null,
  getMWTSummary: null,
  storeList: null,
  loading: {
    getCategoriesMetaData: false,
    getTimeTempSummary: false,
    getStationReadSummary: false,
    getCleaningSummary: false,
    getMWTSummary: false,
    getStoreList: false
  },
  error: {
    getCategoriesMetaData: null,
    getTimeTempSummary: null,
    getStationReadSummary: null,
    getCleaningSummary: null,
    getMWTSummary: null,
    getStoreList: null
  }
};

export const logsSummaryReducer = (
  state = logsSummaryInitialState,
  action: { payload: any; type: string }
): ILogsSummaryState => {
  switch (action.type) {
    case LOGS.Summary.GET_CATEGORIES_META_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCategoriesMetaData: true },
        error: { ...state.error, getCategoriesMetaData: null }
      };
    case LOGS.Summary.GET_CATEGORIES_META_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCategoriesMetaData: false },
        error: { ...state.error, getCategoriesMetaData: null },
        getCategoriesMetaData: action.payload
      };
    case LOGS.Summary.GET_CATEGORIES_META_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCategoriesMetaData: false },
        error: { ...state.error, getCategoriesMetaData: action.payload }
      };
    case LOGS.Summary.GET_SELECTED_MONTH_FILTER.SUCCESS:
      return {
        ...state,
        monthFilter: action.payload
      };

    case LOGS.Summary.GET_TIME_TEMP_LOGS_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTimeTempSummary: true },
        error: { ...state.error, getTimeTempSummary: null }
      };
    case LOGS.Summary.GET_TIME_TEMP_LOGS_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTimeTempSummary: false },
        error: { ...state.error, getTimeTempSummary: null },
        getTimeTempSummary: action.payload
      };
    case LOGS.Summary.GET_TIME_TEMP_LOGS_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTimeTempSummary: false },
        error: { ...state.error, getTimeTempSummary: action.payload }
      };

    case LOGS.Summary.GET_STATION_LOGS_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getStationReadSummary: true },
        error: { ...state.error, getStationReadSummary: null }
      };
    case LOGS.Summary.GET_STATION_LOGS_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getStationReadSummary: false },
        error: { ...state.error, getStationReadSummary: null },
        getStationReadSummary: action.payload
      };
    case LOGS.Summary.GET_STATION_LOGS_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStationReadSummary: false },
        error: { ...state.error, getStationReadSummary: action.payload }
      };

    case LOGS.Summary.GET_CLEANING_LOGS_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCleaningSummary: true },
        error: { ...state.error, getCleaningSummary: null }
      };
    case LOGS.Summary.GET_CLEANING_LOGS_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCleaningSummary: false },
        error: { ...state.error, getCleaningSummary: null },
        getCleaningSummary: action.payload
      };
    case LOGS.Summary.GET_CLEANING_LOGS_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCleaningSummary: false },
        error: { ...state.error, getCleaningSummary: action.payload }
      };

    case LOGS.Summary.GET_MWT_LOGS_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getMWTSummary: true },
        error: { ...state.error, getMWTSummary: null }
      };
    case LOGS.Summary.GET_MWT_LOGS_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getMWTSummary: false },
        error: { ...state.error, getMWTSummary: null },
        getMWTSummary: action.payload
      };
    case LOGS.Summary.GET_MWT_LOGS_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getMWTSummary: false },
        error: { ...state.error, getMWTSummary: action.payload }
      };
    case LOGS.Summary.RESET_LOGS_SUMMARY:
      return {
        ...state,
        getCategoriesMetaData: null,
        getTimeTempSummary: null,
        getStationReadSummary: null,
        getCleaningSummary: null,
        getMWTSummary: null,
        storeList: null,
        loading: {
          getCategoriesMetaData: false,
          getTimeTempSummary: false,
          getStationReadSummary: false,
          getCleaningSummary: false,
          getMWTSummary: false,
          getStoreList: false
        },
        error: {
          getCategoriesMetaData: null,
          getTimeTempSummary: null,
          getStationReadSummary: null,
          getCleaningSummary: null,
          getMWTSummary: null,
          getStoreList: null
        }
      };

    case LOGS.Summary.GET_STORE_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getStoreList: true },
        error: { ...state.error, getStoreList: null }
      };
    case LOGS.Summary.GET_STORE_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getStoreList: false },
        error: { ...state.error, getStoreList: null },
        storeList: action.payload
      };
    case LOGS.Summary.GET_STORE_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStoreList: false },
        error: { ...state.error, getStoreList: action.payload }
      };
    case LOGS.Summary.RESET_STORE_LIST:
      return {
        ...state,
        storeList: {
          content: [],
          totalElements: 0,
          currentPage: 0
        }
      };
    default:
      return state;
  }
};
