import React, { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Card from "@timhortons/common/src/components/atoms/card";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import HoverableOpacity from "@timhortons/common/src/components/atoms/hoverableOpacity";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Roles } from "@timhortons/common/src/constants/roles";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { RBModulesArr } from "@timhortons/common/src/modules/reportBuilders/template/rbModulesData";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import Jumbotron, { ErrorType } from "@timhortons/common/src/components/organisms/jumbotron";

interface CardsDescLayoutProps {
  translate: any;
  history: any;
  user: IUserDetails;
}
export interface IReportBuildModule {
  id: string;
  path: Pathnames;
  title: string;
  inTabAlso: boolean;
  description?: string;
  allowedRoles: Array<Roles>;
}

export default function CardsDescLayout(props: CardsDescLayoutProps): React.ReactElement {
  const { history, translate, user } = props;

  const [hoveredItemId, setHoveredItemId] = useState<string>("");

  const ReportBuildersModule: Array<IReportBuildModule> = useMemo(() => {
    let tempArr: Array<IReportBuildModule> = [];
    if (user?.roles[0]) {
      tempArr = [...RBModulesArr];
      if (!PlatformUtils.isWeb()) {
        let result = tempArr.filter((el: IReportBuildModule) => el.inTabAlso === true);
        tempArr = [...result];
      }
      tempArr = tempArr.filter(
        (el: IReportBuildModule) =>
          el.allowedRoles.includes(user.roles[0]) || el.allowedRoles.includes(Roles.All)
      );
    }
    return tempArr;
  }, [user?.roles[0]]);

  const cardRenderer = (item: IReportBuildModule) => {
    const { id, title, description } = item;
    let isSelected = id === hoveredItemId;
    return (
      <Card.Body
        bodyStyle={[
          styles.innerCardContainer,
          {
            backgroundColor: isSelected ? colorPallete.red1 : colorPallete.white3
          }
        ]}
      >
        <Text
          textSize={TextSize.Medium3}
          fontWeight={FontWeight.SemiBold}
          numberOfLines={1}
          testId={`${id} reportBuilders-card-list-title`}
          textStyle={[
            styles.cardTitleText,
            isSelected ? styles.selectedTextColor : styles.textColor
          ]}
        >
          {translate(title)}
        </Text>
        <Text
          textSize={TextSize.ExtraSmall}
          fontWeight={FontWeight.Regular}
          numberOfLines={3}
          testId={`${id} reportBuilders-card-list-desc`}
          textStyle={[
            styles.cardTitleDesc,
            isSelected ? styles.selectedTextColor : styles.textColor
          ]}
        >
          {description ? description : ""}
        </Text>
      </Card.Body>
    );
  };

  function handleNavigation(navPath: Pathnames): void {
    navigation(history, navPath, null, null, {}, navPath, navPath);
  }

  return (
    <Card.Body bodyStyle={styles.mainContainer}>
      {ReportBuildersModule?.length > 0 ? (
        <Card.Body bodyStyle={[styles.leftContainerFlex, styles.leftContainer]}>
          {ReportBuildersModule.map((item: IReportBuildModule, index: number) => (
            <HoverableOpacity
              key={index}
              onLeaveHandler={() => setHoveredItemId("")}
              onEnterHandler={() => setHoveredItemId(item.id)}
              containerStyle={styles.hoverContainerStyles}
              onPress={() => handleNavigation(item.path)}
            >
              {cardRenderer(item)}
            </HoverableOpacity>
          ))}
        </Card.Body>
      ) : (
        <Jumbotron
          containerStyles={styles.leftContainerFlex}
          type={ErrorType.NoDataFound}
          title={translate("noModuleAvailable")}
        />
      )}
      <Card.Body bodyStyle={styles.rightContainer}>
        <Text
          testId=""
          textSize={TextSize.Regular}
          fontWeight={FontWeight.Medium}
          textStyle={styles.quesText}
        >
          {translate("howDoesReportBuilderWork")}
        </Text>
        <Text
          testId=""
          textSize={TextSize.ExtraSmall}
          fontWeight={FontWeight.Regular}
          textStyle={styles.quesReply}
        >
          {translate("reportBuilderMainDesc")}
        </Text>
      </Card.Body>
    </Card.Body>
  );
}

const styles = StyleSheet.create({
  mainContainer: { height: 513, width: "100%", flexDirection: "row", flex: 1 },
  leftContainerFlex: { flex: 2.25 },
  leftContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    // justifyContent: "flex-start",
    ...I18nService.select({
      ltr: {
        paddingStart: 24
      },
      rtl: {
        paddingStart: 24
      }
    }),
    paddingBottom: 24
  },
  rightContainer: {
    flex: 0.75,
    padding: 28,
    ...I18nService.select({
      ltr: {
        borderLeftWidth: 1,
        borderLeftColor: colorPallete.grey19
      },
      rtl: {
        borderRightWidth: 1,
        borderRightColor: colorPallete.grey19
      }
    })
  },
  innerCardContainer: {
    padding: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colorPallete.grey6,
    marginTop: 24,
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    }),
    height: 140
  },
  selectedTextColor: { color: colorPallete.white3 },
  textColor: { color: colorPallete.black3 },
  cardTitleText: { marginVertical: 2, paddingVertical: 4 },
  cardTitleDesc: { marginTop: 6 },
  hoverContainerStyles: { flexBasis: "50%" },
  quesText: { color: colorPallete.black4 },
  quesReply: { color: colorPallete.grey1, paddingVertical: 8 }
});
