import { call, put, takeLatest } from "redux-saga/effects";
import { MANAGE_EXISTING_USER } from "@timhortons/common/src/redux/manageExistingUser/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { ManageExistingUsersAction } from "@timhortons/common/src/redux/manageExistingUser/actions/index";
import { ManageExistingUsersRepository } from "@timhortons/common/src/repositories/manageExistingUsers/manageExistinguser";

function* onBoardExistingUser(value: IFluxStandardAction<{ employeeId: string; body: any }>) {
  const { payload } = value;
  try {
    let data: any = yield call(ManageExistingUsersRepository.onBoardExistingUser, payload);
    yield put(ManageExistingUsersAction.onBoardExistingUserSuccess(data));
  } catch (error) {
    Logger.error("Manage existing user", error, "onBoardExistingUser");

    yield put(ManageExistingUsersAction.onBoardExistingUserError(error));
  }
}
function* getExistingUserData(value: IFluxStandardAction<{ employeeId: string }>) {
  const { payload } = value;
  try {
    let data: any = yield call(ManageExistingUsersRepository.GetExistingUserData, payload);
    yield put(ManageExistingUsersAction.getExistingUsersSuccess(data));
  } catch (error) {
    Logger.error("Manage existing user", error, "getExistingUserData");

    yield put(ManageExistingUsersAction.getExistingUsersError(error));
  }
}

function* manageExistingUsersWatcherSaga() {
  yield takeLatest(MANAGE_EXISTING_USER.ONBOARD_EXISTING_USERS.LOADING, onBoardExistingUser);
  yield takeLatest(MANAGE_EXISTING_USER.GET_EXISTING_USERS.LOADING, getExistingUserData);
}

export default manageExistingUsersWatcherSaga;
