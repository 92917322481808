import { combineReducers } from "redux";
import {
  ITimeTempLogsState,
  timeTempLogsReducer
} from "@timhortons/common/src/redux/logs/reducers/mainLogs/timeTemperature";
import {
  IManagerReviewState,
  managerReviewReducer
} from "@timhortons/common/src/redux/logs/reducers/managerReview";
import {
  IStationReadinessState,
  stationReadLogsReducer
} from "@timhortons/common/src/redux/logs/reducers/mainLogs/stationReadiness";
import {
  cleaningLogsReducer,
  ICleaningState
} from "@timhortons/common/src/redux/logs/reducers/mainLogs/cleaning";
import {
  IManagerWalkthroughState,
  managerWalkthroughLogsReducer
} from "@timhortons/common/src/redux/logs/reducers//mainLogs/managerWalkthrough";
import {
  ITimeTempLogActionsState,
  timeTempLogActionsReducer
} from "@timhortons/common/src/redux/logs/reducers/actionLogs/timeTemperature";
import {
  IManagerWalkthroughActionsState,
  managerWalkthroughActionsReducer
} from "@timhortons/common/src/redux/logs/reducers/actionLogs/managerWalkthrough";
import {
  ICleaningActionsState,
  cleaningActionsReducer
} from "@timhortons/common/src/redux/logs/reducers/actionLogs/cleaning";
import {
  IStationReadinessActionsState,
  stationReadActionsReducer
} from "@timhortons/common/src/redux/logs/reducers/actionLogs/stationReadiness";
import {
  ILogsSummaryState,
  logsSummaryReducer
} from "@timhortons/common/src/redux/logs/reducers/logsSummary";
import {
  IIndividualLogActionState,
  individualLogActionReducer
} from "@timhortons/common/src/redux/logs/reducers/individualActionsTable";

export interface ILogsState {
  managerReview: IManagerReviewState;
  timeTempLogs: ITimeTempLogsState;
  stationReadLogs: IStationReadinessState;
  cleaningLogs: ICleaningState;
  managerWalkthroughLogs: IManagerWalkthroughState;
  timeTempActions: ITimeTempLogActionsState;
  managerWalkthroughActions: IManagerWalkthroughActionsState;
  cleaningActions: ICleaningActionsState;
  stationReadActions: IStationReadinessActionsState;
  logsSummary: ILogsSummaryState;
  individualLogsActionTable: IIndividualLogActionState;
}
export const logsReducer = combineReducers({
  managerReview: managerReviewReducer,
  timeTempLogs: timeTempLogsReducer,
  stationReadLogs: stationReadLogsReducer,
  cleaningLogs: cleaningLogsReducer,
  managerWalkthroughLogs: managerWalkthroughLogsReducer,
  timeTempActions: timeTempLogActionsReducer,
  managerWalkthroughActions: managerWalkthroughActionsReducer,
  cleaningActions: cleaningActionsReducer,
  stationReadActions: stationReadActionsReducer,
  logsSummary: logsSummaryReducer,
  individualLogsActionTable: individualLogActionReducer
});
