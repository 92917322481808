import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import {
  LmsAssessmentTheoryDataModel,
  LmsModulePtsDataModel,
  LmsPtsCheckListResponseModel,
  LmsPtsTopicResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";
import {
  ptsPostTopicSuccessHandler,
  ptsUpdateTopicSuccessHandler,
  ptsDeleteTopicSuccessHandler,
  ptsPostCheckListSuccessHandler,
  ptsDeleteCheckListSuccessHandler
} from "@timhortons/common/src/repositories/manageLms/formatters/lmsPtsFormatter";

export interface ILmsAssessmentsLoading {
  getAssessmentsPracticalData: boolean;
  postAssessmentsTopic: boolean;
  updateAssessmentsTopic: boolean;
  deleteAssessmentsTopic: boolean;
  postAssessmentsCheckList: boolean;
  updateAssessmentsCheckList: boolean;
  deleteAssessmentsCheckList: boolean;
  getAssessmentsTheoryData: boolean;
}
export interface ILmsAssessmentsError {
  getAssessmentsPracticalData: string;
  postAssessmentsTopic: string;
  updateAssessmentsTopic: string;
  deleteAssessmentsTopic: string;
  postAssessmentsCheckList: string;
  updateAssessmentsCheckList: string;
  deleteAssessmentsCheckList: string;
  getAssessmentsTheoryData: string;
}
export interface ILmsAssessmentState {
  loading: ILmsAssessmentsLoading;
  error: ILmsAssessmentsError;
  getAssessmentsPracticalData: LmsModulePtsDataModel[];
  postAssessmentsTopic: LmsPtsTopicResponseModel;
  updateAssessmentsTopic: LmsPtsTopicResponseModel;
  deleteAssessmentsTopic: LmsPtsTopicResponseModel;
  postAssessmentsCheckList: LmsPtsCheckListResponseModel;
  updateAssessmentsCheckList: LmsPtsCheckListResponseModel;
  deleteAssessmentsCheckList: LmsPtsCheckListResponseModel;
  targetTopicId: string;
  getAssessmentsTheoryData: LmsAssessmentTheoryDataModel[];
}

export const lmsAssessmentsState: ILmsAssessmentState = {
  error: {
    getAssessmentsPracticalData: null,
    postAssessmentsTopic: null,
    updateAssessmentsTopic: null,
    deleteAssessmentsTopic: null,
    postAssessmentsCheckList: null,
    updateAssessmentsCheckList: null,
    deleteAssessmentsCheckList: null,
    getAssessmentsTheoryData: null
  },
  loading: {
    getAssessmentsPracticalData: false,
    postAssessmentsTopic: false,
    updateAssessmentsTopic: false,
    deleteAssessmentsTopic: false,
    postAssessmentsCheckList: false,
    updateAssessmentsCheckList: false,
    deleteAssessmentsCheckList: false,
    getAssessmentsTheoryData: false
  },
  getAssessmentsPracticalData: [],
  postAssessmentsTopic: null,
  updateAssessmentsTopic: null,
  deleteAssessmentsTopic: null,
  postAssessmentsCheckList: null,
  updateAssessmentsCheckList: null,
  deleteAssessmentsCheckList: null,
  targetTopicId: null,
  getAssessmentsTheoryData: []
};

export const lmsAssessmentsReducer = (
  state = lmsAssessmentsState,
  action: { payload: any; type: string }
): ILmsAssessmentState => {
  switch (action.type) {
    //* reducer for get pts data by module id
    case MANAGELMS.assessments.GET_MODULE_ASSESSMENTS_PRACTICAL_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAssessmentsPracticalData: true },
        error: { ...state.error, getAssessmentsPracticalData: null }
      };
    case MANAGELMS.assessments.GET_MODULE_ASSESSMENTS_PRACTICAL_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAssessmentsPracticalData: false },
        error: { ...state.error, getAssessmentsPracticalData: null },
        getAssessmentsPracticalData: action.payload
      };
    case MANAGELMS.assessments.GET_MODULE_ASSESSMENTS_PRACTICAL_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAssessmentsPracticalData: false },
        error: { ...state.error, getAssessmentsPracticalData: action.payload }
      };
    //* reducer for post pts Topic
    case MANAGELMS.assessments.POST_ASSESSMENTS_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postAssessmentsTopic: true },
        error: { ...state.error, postAssessmentsTopic: null }
      };
    case MANAGELMS.assessments.POST_ASSESSMENTS_TOPIC.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postAssessmentsTopic: false },
        error: { ...state.error, postAssessmentsTopic: null },
        getAssessmentsPracticalData: ptsPostTopicSuccessHandler(
          state.getAssessmentsPracticalData,
          action.payload
        ),
        postAssessmentsTopic: action.payload
      };
    case MANAGELMS.assessments.POST_ASSESSMENTS_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postAssessmentsTopic: false },
        error: { ...state.error, postAssessmentsTopic: action.payload }
      };
    //* reducer for update pts Topic
    case MANAGELMS.assessments.UPDATE_ASSESSMENTS_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateAssessmentsTopic: true },
        error: { ...state.error, updateAssessmentsTopic: null }
      };
    case MANAGELMS.assessments.UPDATE_ASSESSMENTS_TOPIC.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateAssessmentsTopic: false },
        error: { ...state.error, updateAssessmentsTopic: null },
        getAssessmentsPracticalData: ptsUpdateTopicSuccessHandler(
          state.getAssessmentsPracticalData,
          action.payload
        ),
        updateAssessmentsTopic: action.payload
      };
    case MANAGELMS.assessments.UPDATE_ASSESSMENTS_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateAssessmentsTopic: false },
        error: { ...state.error, updateAssessmentsTopic: action.payload }
      };
    //* reducer for delete pts Topic
    case MANAGELMS.assessments.DELETE_ASSESSMENTS_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteAssessmentsTopic: true },
        error: { ...state.error, deleteAssessmentsTopic: null }
      };
    case MANAGELMS.assessments.DELETE_ASSESSMENTS_TOPIC.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteAssessmentsTopic: false },
        error: { ...state.error, deleteAssessmentsTopic: null },
        getAssessmentsPracticalData: ptsDeleteTopicSuccessHandler(
          state.getAssessmentsPracticalData,
          action.payload
        ),
        deleteAssessmentsTopic: action.payload
      };
    case MANAGELMS.assessments.DELETE_ASSESSMENTS_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteAssessmentsTopic: false },
        error: { ...state.error, deleteAssessmentsTopic: action.payload }
      };

    //* reducer for post pts checklist
    case MANAGELMS.assessments.POST_ASSESSMENTS_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postAssessmentsCheckList: true },
        error: { ...state.error, postAssessmentsCheckList: null },
        targetTopicId: action.payload?.ptsPracticalTopicId
      };
    case MANAGELMS.assessments.POST_ASSESSMENTS_CHECKLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postAssessmentsCheckList: false },
        error: { ...state.error, postAssessmentsCheckList: null },
        getAssessmentsPracticalData: ptsPostCheckListSuccessHandler(
          state.getAssessmentsPracticalData,
          state.targetTopicId,
          action.payload
        ),
        postAssessmentsCheckList: action.payload
      };
    case MANAGELMS.assessments.POST_ASSESSMENTS_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postAssessmentsCheckList: false },
        error: { ...state.error, postAssessmentsCheckList: action.payload }
      };
    //* reducer for update pts checklist
    case MANAGELMS.assessments.UPDATE_ASSESSMENTS_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateAssessmentsCheckList: true },
        error: { ...state.error, updateAssessmentsCheckList: null }
      };
    case MANAGELMS.assessments.UPDATE_ASSESSMENTS_CHECKLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateAssessmentsCheckList: false },
        error: { ...state.error, updateAssessmentsCheckList: null },
        // getAssessmentsPracticalData: ptsUpdateCheckListSuccessHandler(state.getAssessmentsPracticalData, action.payload),
        updateAssessmentsCheckList: action.payload
      };
    case MANAGELMS.assessments.UPDATE_ASSESSMENTS_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateAssessmentsCheckList: false },
        error: { ...state.error, updateAssessmentsCheckList: action.payload }
      };
    //* reducer for delete pts checklist
    case MANAGELMS.assessments.DELETE_ASSESSMENTS_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteAssessmentsCheckList: true },
        error: { ...state.error, deleteAssessmentsCheckList: null },
        targetTopicId: action.payload?.topicId
      };
    case MANAGELMS.assessments.DELETE_ASSESSMENTS_CHECKLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteAssessmentsCheckList: false },
        error: { ...state.error, deleteAssessmentsCheckList: null },
        getAssessmentsPracticalData: ptsDeleteCheckListSuccessHandler(
          state.getAssessmentsPracticalData,
          state.targetTopicId,
          action.payload
        ),
        deleteAssessmentsCheckList: action.payload
      };
    case MANAGELMS.assessments.DELETE_ASSESSMENTS_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteAssessmentsCheckList: false },
        error: { ...state.error, deleteAssessmentsCheckList: action.payload }
      };
    //* reducer for assessment theory data
    case MANAGELMS.assessments.GET_LMS_ASSESSMENTS_THEORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAssessmentsTheoryData: true },
        error: { ...state.error, getAssessmentsTheoryData: null }
      };
    case MANAGELMS.assessments.GET_LMS_ASSESSMENTS_THEORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAssessmentsTheoryData: false },
        error: { ...state.error, getAssessmentsTheoryData: null },
        getAssessmentsTheoryData: action.payload
      };
    case MANAGELMS.assessments.GET_LMS_ASSESSMENTS_THEORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAssessmentsTheoryData: false },
        error: { ...state.error, getAssessmentsTheoryData: action.payload }
      };
    case MANAGELMS.assessments.RESET_ASSESSMENTS_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          getAssessmentsPracticalData: null,
          postAssessmentsTopic: null,
          updateAssessmentsTopic: null,
          deleteAssessmentsTopic: null,
          postAssessmentsCheckList: null,
          updateAssessmentsCheckList: null,
          deleteAssessmentsCheckList: null,
          getAssessmentsTheoryData: null
        }
      };

    default:
      return state;
  }
};
