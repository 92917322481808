import { StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
export const builderTemplateStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colorPallete.white3,
    paddingVertical: 42,
    paddingHorizontal: 30,
    borderWidth: 1,
    borderColor: colorPallete.white3,
    borderRadius: 8
  },
  margin: {
    marginVertical: 30
  },
  dividerStyle: {
    borderColor: colorPallete.grey5,
    borderStyle: "dashed",
    borderWidth: 1,
    borderRadius: 0.1,
    marginVertical: 30
  },
  generateButton: {
    paddingHorizontal: 48,
    paddingVertical: 12,
    // borderWidth: 1,
    borderRadius: 8
    // borderColor: colorPallete.red1
  },
  textContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  noteStyles: {
    backgroundColor: colorPallete.variant17,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colorPallete.variant17,
    padding: 24
  },
  noteText: {
    lineHeight: 36,
    color: colorPallete.grey1
  }
});
