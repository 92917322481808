import React, { useRef } from "react";
import {
  TextInput,
  View,
  StyleSheet,
  StyleProp,
  TextStyle,
  ViewStyle,
  KeyboardType
} from "react-native";
import {
  FontWeight,
  Label,
  TextSize,
  Text,
  FontFamily
} from "@timhortons/common/src/components/atoms/text";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "./icon";

interface IProps {
  label?: string;
  placeholder: string;
  name: string;
  styling?: Object;
  id: string;
  isSecure?: boolean;
  disabled?: boolean;
  value?: string | number;
  search?: boolean;
  focusStyle?: {};
  toggler?: boolean;
  manageFocus?: (arg: {}) => void;
  handleChange?: any;
  handleBlur?: Function;
  handleKeyPress?: Function;
  placeholderTextColor?: string;
  labelStyle?: StyleProp<TextStyle>;
  errorStyle?: StyleProp<TextStyle>;
  inputStyle?: StyleProp<TextStyle>;
  fieldStyle?: StyleProp<ViewStyle>;
  errorViewStyle?: StyleProp<ViewStyle>;
  customChange?: Function;
  keyboardType?: KeyboardType;
  error?: string;
  editable?: boolean;
  icon?: boolean;
  onPressOfIcon?: () => void;
  masterContainerStyle?: StyleProp<ViewStyle>;
  addedText?: string;
  addedTextStyle?: StyleProp<TextStyle>;
  addedTextContainerStyle?: StyleProp<ViewStyle>;
  customeIconStyle?: any;
}
export const TextField = ({
  label,
  placeholder = "",
  id = "",
  isSecure = false,
  disabled = false,
  handleBlur,
  handleChange,
  value,
  placeholderTextColor = colorPallete.grey4,
  ...props
}: IProps): React.ReactElement => {
  const {
    labelStyle,
    fieldStyle,
    manageFocus,
    focusStyle,
    styling,
    toggler = false,
    search = false,
    inputStyle,
    errorStyle,
    errorViewStyle,
    handleKeyPress,
    error = undefined,
    keyboardType,
    editable,
    icon = false,
    onPressOfIcon,
    masterContainerStyle = {},
    addedText,
    addedTextStyle,
    addedTextContainerStyle,
    customeIconStyle
  } = props;
  const inputRef = useRef<TextInput>(null);
  const onClickFocus = () => {
    toggler && inputRef.current?.focus();
  };

  onClickFocus();

  const onFocus = () => {
    search && PlatformUtils.isWeb() && manageFocus(styling);
  };
  return (
    <View style={[styles.container, masterContainerStyle]}>
      {!search && (
        <Label
          testId={id}
          textSize={TextSize.Small}
          fontWeight={FontWeight.Regular}
          textStyle={[styles.label, labelStyle]}
        >
          {label}
        </Label>
      )}
      <View
        style={icon ? [styles.iconStyle, styles.inputBox, inputStyle] : styles.elementsContainer}
      >
        <TextInput
          nativeID={`textbox-${id}`}
          editable={editable}
          keyboardType={keyboardType}
          ref={inputRef}
          placeholderTextColor={placeholderTextColor}
          placeholder={placeholder}
          secureTextEntry={isSecure}
          style={[
            search
              ? (toggler && PlatformUtils.isWeb() && focusStyle) || [fieldStyle, focusStyle]
              : icon
              ? { flex: 1 }
              : [styles.inputBox, inputStyle]
          ]}
          value={value}
          onChangeText={(e) => {
            if (props.customChange && !disabled) {
              props.customChange(e);
            }
            return !disabled && handleChange && handleChange(e);
          }}
          onBlur={() => handleBlur && handleBlur(props.name)}
          onFocus={() => onFocus()}
          onKeyPress={(e) => handleKeyPress && handleKeyPress(e)}
        />
        {icon && (
          <Icon
            size={14}
            name={IconNames.tick}
            customStyle={[styles.enterIcon, customeIconStyle]}
            onPress={onPressOfIcon}
          />
        )}
        {addedText && (
          <View style={addedTextContainerStyle}>
            <Text
              textStyle={addedTextStyle}
              testId={id}
              textSize={TextSize.Regular}
              fontWeight={FontWeight.Regular}
            >
              {addedText}
            </Text>
          </View>
        )}
        {error ? (
          <View style={errorViewStyle}>
            <Text
              testId={id}
              textSize={TextSize.ExtraFineSmall}
              fontWeight={FontWeight.Light}
              textStyle={[styles.error, errorStyle]}
            >
              {error}
            </Text>
          </View>
        ) : null}
      </View>
    </View>
  );
};
export default TextField;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    position: "relative"
  },
  elementsContainer: {
    flex: 1,
    flexDirection: "column"
  },
  label: {
    color: "green"
  },
  textinput_focused_mobile: {
    width: "100%",
    height: 50
  },
  inputBox: {
    borderWidth: 1,
    fontFamily: PlatformUtils.isIOS() ? "SofiaPro" : FontFamily.Regular,
    fontWeight: FontWeight.Regular,
    fontSize: 16,
    // lineHeight: 24,
    color: colorPallete.black1
  },
  error: {
    color: "red"

    // position: "absolute",
    // bottom: -20
  },
  iconStyle: { flex: 1, flexDirection: "row", justifyContent: "space-between" },
  enterIcon: {
    color: colorPallete.variant10
  }
});
