import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LabelValueModel")
export class LabelValueModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("label", String)
  label: string = "";
  @JsonProperty("value", String)
  value: string = "";
}

@JsonObject("LMSReportEmployeeDetailModel")
export class LMSReportEmployeeDetailModel {
  @JsonProperty("name", String)
  name: string = "0";
  @JsonProperty("role", String)
  role: string = "";
  @JsonProperty("profileImg", String)
  profileImg: string = "";
  @JsonProperty("details", [LabelValueModel])
  details: LabelValueModel[] = [];
  @JsonProperty("trainingCompletion", Number)
  trainingCompletion: number = 0;
  @JsonProperty("storeName", String && null)
  storeName: string = "";
  @JsonProperty("countryLabel", String)
  countryLabel: string = "";
}

@JsonObject("LMSReportsGraphicalDataModel")
export class LMSReportsGraphicalDataModel {
  @JsonProperty("pieChartData", [Number])
  pieChartData: number[] = [];
  @JsonProperty("timbitData", [LabelValueModel])
  timbitData: LabelValueModel[] = [];
}

@JsonObject("LMSReportTableDataModel")
export class LMSReportTableDataModel {
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("theoretical", String)
  theoretical: string = "";
  @JsonProperty("practical", String && null)
  practical?: string = "";
  @JsonProperty("attempts", Number)
  attempts: number = 0;
  @JsonProperty("score", Number)
  score: number = 0;
  @JsonProperty("duration", String)
  duration: string = "";
  @JsonProperty("completedOn", String && null)
  completedOn: string = "";
  @JsonProperty("certification", String && null)
  certification?: string = "";
  @JsonProperty("timbits", String && null)
  timbits: string = "";
}

@JsonObject("LMSReportTrainingTablesModel")
export class LMSReportTrainingTablesModel {
  @JsonProperty("core", [LMSReportTableDataModel])
  core: LMSReportTableDataModel[] = [];
  @JsonProperty("special", [LMSReportTableDataModel])
  special: LMSReportTableDataModel[] = [];
}

@JsonObject("LMSReportPracticalIncorrectData")
export class LMSReportPracticalIncorrectData {
  @JsonProperty("points", [String])
  points: string[] = [];
  @JsonProperty("attatchments", [String])
  attatchments: string[] = [];
}
@JsonObject("SubModules")
export class SubModules {
  @JsonProperty("topicTitle", String)
  topicTitle: string = "";
  @JsonProperty("incorrectDetails", LMSReportPracticalIncorrectData)
  incorrectDetails: LMSReportPracticalIncorrectData = null;
}
@JsonObject("LMSReportPracticalData")
export class LMSReportPracticalData {
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("subText", String)
  subText: string = "";
  @JsonProperty("subModules", [SubModules])
  subModules: SubModules[] = [];
}

@JsonObject("LMSEmpDetailedReport")
export class LMSEmpDetailedReport {
  @JsonProperty("empInfoData", LMSReportEmployeeDetailModel)
  empInfoData: LMSReportEmployeeDetailModel = null;
  @JsonProperty("graphicalInfoData", LMSReportsGraphicalDataModel)
  graphicalInfoData: LMSReportsGraphicalDataModel = null;
  @JsonProperty("trainingTableData", LMSReportTrainingTablesModel)
  trainingTableData: LMSReportTrainingTablesModel = null;
  @JsonProperty("practicalTrainingData", [LMSReportPracticalData])
  practicalTrainingData: LMSReportPracticalData[] = [];
}
@JsonObject("TableDetailsModel")
export class TableDetailsModel {
  @JsonProperty("employeeId", String, true)
  employeeId?: string = "";
  @JsonProperty("profileUrl", String && null)
  profilePhoto: string = "";
  @JsonProperty("firstName", String, true)
  firstName?: string = "";
  @JsonProperty("lastName", String, true)
  lastName?: string = "";
  @JsonProperty("roleName", String, true)
  role?: string = "";
  @JsonProperty("countryLabel", String, true)
  country?: string = "";
  @JsonProperty("noOfAreaManagers", Number && null, true)
  areaManagers?: number = 0;
  @JsonProperty("noOfStores", Number && null, true)
  stores?: number = 0;
  @JsonProperty("profileCompletionPercentage", Number && null)
  completionPercentage: number = 0;
  @JsonProperty("nationality", String && null, true)
  nationality?: string = "";
  @JsonProperty("areaTrainerName", String && null, true)
  areaTrainer?: string = "";
  @JsonProperty("storeName", String && null, true)
  storeName?: string = "";
  @JsonProperty("gccAverageScore", Number && null, true)
  gccAverageScore?: string = "";
  @JsonProperty("yourAverageScore", Number && null, true)
  yourAverageScore?: string = "";
}
@JsonObject("ReportsTableDetailsModel")
export class ReportsTableDetailsModel {
  @JsonProperty("totalElements", Number)
  totalRecords: number = 0;
  @JsonProperty("content", [TableDetailsModel])
  teamDetails: TableDetailsModel[] = null;
}

@JsonObject("ReportsPieDataPercentageModel")
export class ReportsPieDataPercentageModel {
  @JsonProperty("completedPercent", Number)
  completedPercent: number = 0;
  @JsonProperty("inProgressPercent", Number)
  inProgressPercent: number = 0;
  @JsonProperty("pendingPercent", Number)
  pendingPercent: number = 0;
}

@JsonObject("ReportsTimbitCollectionModel")
export class ReportsTimbitCollectionModel {
  @JsonProperty("birthdayCake", Number)
  birthdayCake: number = 0;
  @JsonProperty("chocolateGlazed", Number)
  chocolateGlazed: number = 0;
  @JsonProperty("honeyDip", Number)
  honeyDip: number = 0;
}

@JsonObject("LMSReportsTableModel")
export class LMSReportsTableModel {
  @JsonProperty("firstName", String)
  firstName: string = "";
  @JsonProperty("lastName", String)
  lastName: string = "";
  @JsonProperty("role", String)
  role: string = "";
  @JsonProperty("profileLink", String)
  profileLink: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("countryLabel", String)
  countryLabel: string = "";
  @JsonProperty("areaManagers", Number)
  areaManagers: number = 0;
  @JsonProperty("ofStores", Number)
  ofStores: number = 0;
  @JsonProperty("trainingCompletion", Number)
  trainingCompletion: number = 0;
  @JsonProperty("clusterHeadOfOps", Number)
  clusterHeadOfOps: number = 0;
  @JsonProperty("countryManagers", Number)
  countryManagers: number = 0;
  @JsonProperty("srOpsManager", Number)
  srOpsManager: number = 0;
  @JsonProperty("opsManager", Number)
  opsManager: number = 0;
  @JsonProperty("areaTrainers", Number)
  areaTrainers: number = 0;
  @JsonProperty("employees", Number)
  employees: number = 0;
  @JsonProperty("gccAverageScore", Number)
  gccAverageScore: number = 0;
  @JsonProperty("averageScore", Number)
  averageScore: number = 0;
  @JsonProperty("storeName", String && null)
  storeName: string = "";
}

@JsonObject("ReportsResponseDataModel")
export class ReportsResponseDataModel {
  @JsonProperty("continueLearningPercentageDto", ReportsPieDataPercentageModel)
  continueLearningPercentageDto: ReportsPieDataPercentageModel = null;
  @JsonProperty("coreTrainingResponseDto", [LMSReportTableDataModel])
  coreTrainingResponseDto: LMSReportTableDataModel[] = [];
  @JsonProperty("reportUserResponseDto", LMSReportsTableModel)
  reportUserResponseDto: LMSReportsTableModel = null;
  @JsonProperty("specialTrainingResponseDto", [LMSReportTableDataModel])
  specialTrainingResponseDto: LMSReportTableDataModel[] = [];
  @JsonProperty("timbitCollectionDto", ReportsTimbitCollectionModel)
  timbitCollectionDto: ReportsTimbitCollectionModel = null;
  @JsonProperty("ptsPracticalTopicCheckistResponseDto", [LMSReportPracticalData])
  ptsPracticalTopicCheckistResponseDto: LMSReportPracticalData[] = [];
}

@JsonObject("AllModulesDataModel")
export class AllModulesDataModel {
  @JsonProperty("name", String)
  label: String = "";
  @JsonProperty("name", String)
  value: String = "";
  @JsonProperty("id", String)
  key: String = "";
  @JsonProperty("category", String && null)
  category: String = "";
  @JsonProperty("isActive", Boolean && null)
  isActive: Boolean = false;
  @JsonProperty("moduleDuration", String && null)
  moduleDuration: String = "";
  @JsonProperty("thumbnail", String && null)
  thumbnail: String = "";
}

export class AllModulesrResponseModel {
  content: AllModulesDataModel; //Add modules api model
  totalElements: number;
  currentPage: number;
}
export class LMSReportResultTable {
  @JsonProperty("id", String)
  id: String = "";
  @JsonProperty("name", String)
  name: String = "";
  @JsonProperty("score", Number && null)
  score: Number = 0;
  @JsonProperty("status", String && null)
  status: String = "";
}
export class LMSReportContentItem {
  @JsonProperty("averageScore", Number && null)
  averageScore: Number = 0;
  @JsonProperty("code", String && null)
  code: String = "";
  @JsonProperty("countryLabel", String && null)
  countryLabel: String = "";
  @JsonProperty("employeeId", String && null)
  employeeId: String = "";
  @JsonProperty("label", String && null)
  label: String = "";
  @JsonProperty("type", String && null)
  type: String = "";
  @JsonProperty("resultTable", [LMSReportResultTable] && null)
  resultTable: LMSReportResultTable[] = [];
}

export class LMSReportBuilderMetaData {
  @JsonProperty("countryLabel", String && null)
  countryLabel: String = "";
  @JsonProperty("dataType", String && null)
  dataType: String = "";
  @JsonProperty("dependentDataType", String && null)
  dependentDataType: String = "";
  @JsonProperty("endDate", String)
  endDate: String = "";
  @JsonProperty("startDate", String)
  startDate: String = "";
  @JsonProperty("levelOfReport", String)
  levelOfReport: String = "";
  @JsonProperty("reportType", String)
  reportType: String = "";
}

export class LMSReportApiResponse {
  @JsonProperty("content", [LMSReportContentItem])
  content: LMSReportContentItem[] = [];
  @JsonProperty("totalElements", Number && null)
  totalElements: Number = 0;
}

export class LMSReportBuilderResponseModel {
  metaData: LMSReportBuilderMetaData = null;
  apiResponse: LMSReportApiResponse = null;
}

export class LMSResEmpResultRow {
  @JsonProperty("id", String)
  id: String = "";
  @JsonProperty("name", String)
  name: String = "";
  @JsonProperty("score", String && null)
  score: String = "";
  @JsonProperty("status", String)
  status: String = "";
  @JsonProperty("statusString", String)
  statusString: String = "";
}

export class LMSResEmpResultContent {
  @JsonProperty("code", Number)
  code: Number;
  @JsonProperty("averageScore", Number && null)
  averageScore: Number = null;
  @JsonProperty("countryLabel", String)
  countryLabel: String = "";
  @JsonProperty("employeeId", String)
  employeeId: String = "";
  @JsonProperty("label", String)
  label: String = "";
  @JsonProperty("storeName", String)
  storeName: String = "";
  @JsonProperty("type", String)
  type: String = "";
  @JsonProperty("resultTable", [LMSResEmpResultRow])
  resultTable: LMSResEmpResultRow[] = [];
}

export class LMSResEmpResultTable {
  @JsonProperty("content", [LMSResEmpResultContent])
  content: LMSResEmpResultContent[] = [];
  @JsonProperty("totalElements", Number)
  totalElements: Number = 0;
}
export class LMSResEmpResultAPIResp {
  @JsonProperty("content", [LMSResEmpResultTable])
  content: LMSResEmpResultTable[] = [];
  @JsonProperty("totalElements", Number)
  totalElements: Number = 0;
}

export class LMSResEmpModel {
  @JsonProperty("metaData", LMSReportBuilderMetaData)
  metaData: LMSReportBuilderMetaData;
  @JsonProperty("apiResponse", LMSResEmpResultAPIResp)
  apiResponse: LMSResEmpResultAPIResp;
}

export class LMSResEmpMetaData {
  @JsonProperty("countryLabel", String && null)
  countryLabel: String = "";
  @JsonProperty("dataType", String && null)
  dataType: String = "";
  @JsonProperty("dependentDataType", String && null)
  dependentDataType: String = "";
  @JsonProperty("endDate", String)
  endDate: String = "";
  @JsonProperty("startDate", String)
  startDate: String = "";
  @JsonProperty("levelOfReport", String)
  levelOfReport: String = "";
  @JsonProperty("reportType", String)
  reportType: String = "";
  @JsonProperty("reportData", LMSResEmpResultAPIResp)
  reportData: LMSResEmpResultAPIResp = null;
}
export class LMSResEmpMetaData2 {
  @JsonProperty("countryLabel", String && null)
  countryLabel: String = "";
  @JsonProperty("dataType", String && null)
  dataType: String = "";
  @JsonProperty("dependentDataType", String && null)
  dependentDataType: String = "";
  @JsonProperty("endDate", String)
  endDate: String = "";
  @JsonProperty("startDate", String)
  startDate: String = "";
  @JsonProperty("levelOfReport", String)
  levelOfReport: String = "";
  @JsonProperty("reportType", String)
  reportType: String = "";
}
