import React, { ReactElement, useEffect, useState } from "react";
import { View } from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { History } from "history";
import { useTranslation } from "react-i18next";
import { IState } from "@timhortons/common/src/store/interfaces";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Divider } from "@timhortons/common/src/components/atoms/divider";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IItem } from "@timhortons/common/src/components/molecules/multiSelect";
import { builderTemplateStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/builder/template/builderTemplateStyles";
import { builderLevel, ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";
import ReportType from "@timhortons/common/src/components/organisms/builder/organisms/reportType";
import DataInReport, {
  ILPDataInReportData
} from "@timhortons/common/src/components/organisms/builder/organisms/dataInReport";
import LevelOfReports, {
  IPrevReportData,
  IReportData
} from "@timhortons/common/src/components/organisms/builder/organisms/levelOfReports";
import useFetchMoreItems from "@timhortons/common/src/utils/useFetchMoreItems";
import { Roles, CEOEqvRoles, SuperAdminEqvRoles } from "@timhortons/common/src/constants/roles";
import { LpReportsActions } from "@timhortons/common/src/redux/lp/actions/reportsAction";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  ComparisonStoresAdminModel,
  CountryDataListModel
} from "models/profile";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { AllAreaManagersListDataModel } from "@timhortons/common/src/models/lp/reports";
import { ReportsBuilderActions } from "@timhortons/common/src/redux/reportBuilder/actions/reportsAction";
import { reportsBuilderSelectors } from "@timhortons/common/src/redux/reportBuilder/selectors/reportsSelector";
import {
  IReportsBuilderError,
  IReportsBuilderLoading
} from "@timhortons/common/src/redux/reportBuilder/reducers/reportsReducer";
import { getComponent } from "@timhortons/web/src/utils/routeHelper";
import { ReportBuilderStorageKeys } from "@timhortons/common/src/modules/reportBuilders/components";
import { CountryDataModel } from "@timhortons/common/src/models/profile";

interface IProps {
  translate?: any;
  user: IUserDetails;
  detailedReportPermissions: ComparsionLevel[];
  history?: History;
  loading: IReportsBuilderLoading;
  getAllCountriesLoading: Function;
  getAllAreaManagerLoading: Function;
  getAllOpsManagerLoading: Function;
  getAllChannelsLoading: Function;
  getAllStoresLoading: Function;
  allCountries: CountryDataListModel;
  error: IReportsBuilderError;
  allAreaManagers: AllAreaManagersListDataModel;
  allChannels: ChannelsListDataModel;
  allStores: AllStoresListDataModel;
  allCountryManagers: AllAreaManagersListDataModel;
  // TODO:Model
  allClusterHeadOfOps: any;
  allLpAuditor: any;
  getAllCountryManagerLoading: Function;
  getAllClusterHeadOfOpsLoading: Function;
  getAllLpAuditorLoading: Function;
  comparisonCountryManagers: any;
  comparisonClusterHeadOfOps: any;
  comparisonAreaManagers: any;
  comparisonStoresAdmin: ComparisonStoresAdminModel;
  getComparisonCountryManagersAdmin: Function;
  getComparisonAreaManagersAdmin: Function;
  comparisonStoresAdminLoading: Function;
  resetReportBuilder: Function;
  getHighLowChannelsAdminData: ChannelsListDataModel;
  allKPIData: any[];
  getKpiData: Function;
  allOpsManagers: any;
  moduleType: string;
  getCategory: Function;
  getProduct: Function;
  allCategory: any;
  allProducts: any;
  resetProductData: Function;
}
export enum ReportTypeLevel {
  Detailed = "DETAILED",
  Comparison = "COMPARISON",
  HighLow = "HIGHEST_TO_LOWEST",
  Grading = "GRADING",
  ConsData = "CONS. DATA",
  AreaOfImprovement = "AREA_OF_IMPROVEMENT"
}
export enum DateRangeTypes {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  YearDate = "Year-to-Date",
  SpecificDate = "Specific Date",
  Yearly = "Yearly"
}
export enum DataInReportLevel {
  ShowByOverallScore = "Show_By_Overall_Score",
  ShowByKPIs = "Show_By_KPIs",
  ShowByCriticalPoints = "Show_By_Critical_Points",
  Baking = "BAKING",
  Others = "OTHERS"
}

interface ISelectedDataOption {
  monthly: any;
  quarterly: any;
  yearDate: { startDate: Date; endDate: Date };
  specific: { startDate: Date; endDate: Date };
}
export interface ISelectAllValues {
  storesSelectAll: boolean;
  channelSelectAll: boolean;
  areaManagerSelectAll: boolean;
  opsManagerSelectAll: boolean;
  countryManagerSelectAll: boolean;
  countrySelectAll: boolean;
  clusterHeadOfOpsSelectAll: boolean;
  lpAuditorSelectAll: boolean;
}
interface IReportBuilderFinalData {
  selectedReportType: ReportTypeLevel;
  selectedDataOption: ISelectedDataOption;
  dataInReportValue: ILPDataInReportData;
  selectedDetailedData: IPrevReportData;
  selectedLevel: ComparsionLevel;
  selectedCountry: CountryDataModel;
  selectedUnitReportCountry: any;
  selectedUnitReportData: any;
  selectAllValues: ISelectAllValues;
}
export interface IPrevSelectedLORData {
  selectedUnitReportCountry: any;
  selectedUnitReportData: any;
  selectedLevel: ComparsionLevel;
  selectedCountry: CountryDataModel;
  selectAllValues: ISelectAllValues;
}

function BuilderLayout(props: IProps): ReactElement {
  const {
    translate,
    user,
    detailedReportPermissions,
    history,
    loading,
    getAllCountriesLoading, // all countryh api
    allCountries,
    getAllAreaManagerLoading, // all area manager api
    getAllOpsManagerLoading,
    allAreaManagers,
    getAllChannelsLoading, // all channels api
    getAllStoresLoading, //all stores api
    allCountryManagers,
    allClusterHeadOfOps,
    allLpAuditor,
    getAllCountryManagerLoading, //get all country managers
    allChannels,
    allStores,
    comparisonAreaManagers,
    getAllClusterHeadOfOpsLoading,
    getAllLpAuditorLoading,
    comparisonCountryManagers,
    getComparisonCountryManagersAdmin, //comparision contry manager
    comparisonStoresAdmin, //data from selector
    resetReportBuilder,
    getHighLowChannelsAdminData, // data from selector
    allKPIData,
    getKpiData,
    allOpsManagers,
    moduleType,
    getCategory,
    getProduct,
    allCategory,
    allProducts,
    resetProductData
  } = props;

  const [selectedUnitReportCountry, setSelectedUnitReportCountry] = useState(null);
  const { t } = useTranslation("rev");
  const [selectedReportType, setSelectedReportType] = useState<ReportTypeLevel>(
    ReportTypeLevel.Detailed
  );
  const [selectedLevel, setSelectedLevel] = useState<ComparsionLevel>(
    // selectedReportType === ReportTypeLevel.Detailed
    //   ? null
    //   :
    user?.roles?.includes(Roles.SuperAdmin) ||
      user?.roles?.includes(Roles.TrainingAndDevLead) ||
      user?.roles?.includes(Roles.TrainingAndDevManager) ||
      user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
      user?.roles?.includes(Roles.HrAndAdminDirector) ||
      // user?.roles?.includes(Roles.Admin) ||
      user?.roles?.includes(Roles.HeadLP) ||
      user?.roles?.includes(Roles.AuditorLP) ||
      user?.roles?.includes(Roles.CEO) ||
      user?.roles?.includes(Roles.COO)
      ? ComparsionLevel.Country
      : user?.roles?.includes(Roles.AreaManager) || user?.roles?.includes(Roles.AreaTrainer)
      ? ComparsionLevel.Channels
      : (user?.roles?.includes(Roles.CountryManager) ||
          user?.roles?.includes(Roles.InternalAuditor) ||
          user?.roles?.includes(Roles.OperationManager) ||
          user?.roles?.includes(Roles.SeniorOpsManager) ||
          user?.roles?.includes(Roles.ClusterHeadofOps)) &&
        ComparsionLevel.AreaManager
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeTypes>(null);
  const [selectedDataOption, setSelectedDataOption] = useState<ISelectedDataOption>({
    monthly: null,
    quarterly: null,
    yearDate: {
      startDate: null,
      endDate: null
    },
    specific: {
      startDate: null,
      endDate: null
    }
  });
  const [lpSelectedDataOption, setLpSelectedDataOption] = useState<ISelectedDataOption>({
    monthly: null,
    quarterly: [],
    yearDate: {
      startDate: null,
      endDate: null
    },
    specific: {
      startDate: null,
      endDate: null
    }
  });
  const [selectedDataInReportLevel, setSelectedDataInReportLevel] = useState<DataInReportLevel>(
    moduleType === builderLevel.isProdSheet
      ? DataInReportLevel.Baking
      : DataInReportLevel.ShowByKPIs
  );
  const [selectedKPI, setSelectedKPI] = useState<IItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [productsData, setProductsData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);

  const [comparisonReportData, setComparisonReportData] = useState<IReportData>(null);
  const [detailedReportData, setDetailedReportData] = useState<IReportData>({
    allCountries: [],
    allCountryManagers: [],
    allAreaManagers: [],
    allOpsManagers: [],
    allChannels: [],
    allStores: [],
    allClusterHeadOfOps: [],
    allLpAuditor: []
  });
  const [selectedUnitReportData, setSelectedUnitReportData] = useState(null); // final selected data for comparsion & highlow reports
  const [selectedDetailedData, setSelectedDetailedData] = useState<IReportData>(null); // final selected data for detailed reports
  const [dataInReportValue, setdataInReportValue] = useState<ILPDataInReportData>({
    LPKPIsData: null,
    overallScore: false,
    criticalPoints: false,
    isKPI: false,
    isBaking: false, //Added for Prod Sheet
    isOthers: false //Added for Prod Sheet
  });
  const [selectedCountryValue, setselectedCountryValue] = useState(null); // final selected data for comparsion & highlow reports
  const [consolidatedDate, setConsolidatedDates] = useState({
    startDate: null,
    endDate: null
  });
  const [storesFetch, setAllStoresFetch] = useState(false);
  const [channelFetch, setAllChannelFetch] = useState(false);
  const [areaManagerFetch, setAllAreaManagerFetch] = useState(false);
  const [opsManagerFetch, setAllOpsManagerFetch] = useState(false);
  const [countryManagerFetch, setAllCountryManagerFetch] = useState(false);
  const [clusterHeadOfOpsFetch, setAllClusterHeadOfOpsFetch] = useState(false);
  const [countryFetch, setAllCountryFetch] = useState(false);
  const [lpAuditorFetch, setAllLpAuditorFetch] = useState(false);
  const [storesSelectAll, setStoresSelectAll] = useState(false);
  const [channelSelectAll, setChannelSelectAll] = useState(false);
  const [areaManagerSelectAll, setAreaManagerSelectAll] = useState(false);
  const [opsManagerSelectAll, setOpsManagerSelectAll] = useState(false);
  const [countryManagerSelectAll, setCountryManagerSelectAll] = useState(false);
  const [countrySelectAll, setCountrySelectAll] = useState(false);
  const [clusterHeadOfOpsSelectAll, setClusterHeadOfOpsSelectAll] = useState(false);
  const [lpAuditorSelectAll, setLpAuditorSelectAll] = useState(false);
  const [countrySearchText, setCountrySearchText] = useState("");
  const [storeSearchText, setStoreSearchText] = useState("");
  const [channelSearchText, setChannelSearchText] = useState("");
  const [countryManagerSearchText, setCountryManagerSearchText] = useState("");
  const [clusterHeadOfOPsSearchText, setClusterHeadOfOPsSearchText] = useState("");
  const [areaManagerSearchText, setAreaManagerSearchText] = useState("");
  const [opsManagerSearchText, setOpsManagerSearchText] = useState("");
  const [lpAuditorSearchText, setLpAuditorSearchText] = useState("");
  const [countryPage, setCountryPage] = useState(0);
  const [channelPage, setChannelsPage] = useState(0);
  const [storesPage, setStoresPage] = useState(0);
  const [areaManagerPage, setAreaManagerPage] = useState(0);
  const [opsManagerPage, setOpsManagerPage] = useState(0);
  const [countryManagerPage, setCountryManagerPage] = useState(0);
  const [clusterHeadOfOpsPage, setClusterHeadOfOpsPage] = useState(0);
  const [lpAuditorPage, setLpAuditorPage] = useState(0);
  const [prevSelectedDetailedData, setPrevSelectedDetailedData] = useState<IPrevReportData>(null); // final selected data for detailed reports
  const [prevSelectedLORData, setPrevSelectedLORData] = useState<IPrevSelectedLORData>(null); // final selected data for reports(other than detailed)
  const [isResetRequired, setIsResetRequired] = useState(false);
  const [reqSearch, setReqSearch] = useState("");
  const [reqSearchDetailed, setReqSearchDetailed] = useState({
    countries: "",
    countryManagers: "",
    areaManagers: "",
    opsManagers: "",
    channels: "",
    stores: "",
    clusterHeadOfOps: "",
    lpAuditors: ""
  });

  // const [isCompHiLo, setIsCompHiLo] = useState(false);
  const [code, setCode] = useState([]);
  const getComparisonCodes = () => {
    // to set codes in comparison admin report
    let newArray: any[] = [];
    if (selectedUnitReportCountry) {
      if (selectedUnitReportCountry && selectedUnitReportCountry.length > 0) {
        selectedUnitReportCountry &&
          selectedUnitReportCountry?.map((item: any) => {
            newArray.push(item.countryCode);
            setCode(newArray);
            return newArray;
          });
      } else {
        setCode(newArray);
        return newArray;
      }
    }
    return newArray;
  };
  useEffect(() => {
    if (moduleType === builderLevel.isLp) {
      setCountrySearchText("");
    }
  }, [selectedReportType]);
  useEffect(() => {
    setLpSelectedDataOption((prev: any) => ({
      ...prev,
      quarterly: []
    }));
  }, [selectedReportType, selectedDateRange]);
  useEffect(() => {
    setReqSearch("");
    setIsResetRequired(true);
    if (selectedReportType === ReportTypeLevel.Detailed) {
      setReqSearchDetailed({
        ...reqSearchDetailed,
        countryManagers: "",
        areaManagers: "",
        opsManagers: "",
        channels: "",
        stores: "",
        clusterHeadOfOps: "",
        lpAuditors: ""
      });
    }
  }, [selectedCountryValue, selectedLevel, selectedDetailedData?.allCountries]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.Detailed) {
      setClusterHeadOfOpsPage(0);
      setLpAuditorPage(0);
      setOpsManagerPage(0);
      setStoresPage(0);
      setCountryManagerPage(0);
      setAreaManagerPage(0);
      setChannelsPage(0);
      setChannelSearchText("");
      setStoreSearchText("");
      setAreaManagerSearchText("");
      setCountryManagerSearchText("");
      setOpsManagerSearchText("");
      setClusterHeadOfOPsSearchText("");
      setLpAuditorSearchText("");
      setReqSearch("");
      setIsResetRequired(true);
    }
  }, [selectedDetailedData?.allCountries]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      setselectedCountryValue(null);
    }
  }, [selectedReportType, selectedLevel]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.AreaOfImprovement ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      setCountrySearchText("");
      setChannelSearchText("");
      setStoreSearchText("");
      setAreaManagerSearchText("");
      setCountryManagerSearchText("");
      setOpsManagerSearchText("");
      setClusterHeadOfOPsSearchText("");
      setLpAuditorSearchText("");
    }
    if (moduleType === builderLevel.isProdSheet) {
      setCountrySearchText("");
      setChannelSearchText("");
      setStoreSearchText("");
      setAreaManagerSearchText("");
      setCountryManagerSearchText("");
      setOpsManagerSearchText("");
      setClusterHeadOfOPsSearchText("");
      setLpAuditorSearchText("");
    }
  }, [selectedLevel]);

  useEffect(() => {
    if (
      moduleType === builderLevel.isProdSheet &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      setSelectedUnitReportData(null);
      setCountrySearchText("");
      setChannelSearchText("");
      setStoreSearchText("");
      setAreaManagerSearchText("");
      setCountryManagerSearchText("");
      setOpsManagerSearchText("");
      setClusterHeadOfOPsSearchText("");
      setLpAuditorSearchText("");
      setReqSearch("");
    }
  }, [selectedCountryValue]);

  useEffect(() => {
    if (moduleType === builderLevel.isProdSheet) {
      setProductsData(allProducts);
      setCategoryData(allCategory);
    }
  }, [allProducts, allCategory]);
  useEffect(() => {
    if (moduleType === builderLevel.isProdSheet) {
      setProductsData([]);
      setCategoryData([]);
      setSelectedCategory([]);
      setSelectedProduct([]);
      resetProductData();
    }
  }, [selectedDataInReportLevel, selectedLevel, selectedCountryValue]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.AreaOfImprovement) {
      setSelectedDetailedData({
        allCountries: [],
        allCountryManagers: [],
        allAreaManagers: [],
        allOpsManagers: [],
        allChannels: [],
        allStores: [],
        allClusterHeadOfOps: [],
        allLpAuditor: []
      });
      setSelectedUnitReportData(null);
    }
  }, [selectedCountryValue]);

  useEffect(() => {
    if (
      moduleType === builderLevel.isProdSheet &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      setProductsData([]);
      setSelectedProduct([]);
      resetProductData();
    }
  }, [selectedUnitReportData]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.Country) {
        setCountryPage(0), setDetailedReportData({ allCountries: [] });
      } else if (
        (selectedReportType === ReportTypeLevel.Comparison ||
          selectedReportType === ReportTypeLevel.Grading) &&
        selectedLevel === ComparsionLevel.Channels
      ) {
        setCountryPage(0), setDetailedReportData({ allCountries: [] });
      } else if (moduleType === builderLevel.isProdSheet) {
      }
    }
  }, [countrySearchText]);
  useEffect(() => {
    if (
      // selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.Channels) {
        setChannelsPage(0), setDetailedReportData({ allChannels: [] });
      }
    }
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (selectedLevel === ComparsionLevel.Channels) {
        setChannelsPage(0);
      }
    }
  }, [channelSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.Stores) {
        setStoresPage(0), setDetailedReportData({ allStores: [] });
      }
    }
  }, [storeSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.AreaManager) {
        setAreaManagerPage(0), setDetailedReportData({ allAreaManagers: [] });
      }
    }
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (selectedLevel === ComparsionLevel.AreaManager) {
        setAreaManagerPage(0);
      }
    }
  }, [areaManagerSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.CountryManager) {
        setCountryManagerPage(0), setDetailedReportData({ allCountryManagers: [] });
      }
    }
  }, [countryManagerSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
        setClusterHeadOfOpsPage(0), setDetailedReportData({ allClusterHeadOfOps: [] });
      }
    }
  }, [clusterHeadOfOPsSearchText, selectedCountryValue]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
        setClusterHeadOfOpsPage(0);
      }
    }
  }, [selectedLevel, selectedReportType]);
  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.LpAuditor) {
        setLpAuditorPage(0), setDetailedReportData({ allLpAuditor: [] });
      }
    }
  }, [lpAuditorSearchText, selectedLevel, selectedReportType, selectedCountryValue]);

  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.Grading
    ) {
      if (selectedLevel === ComparsionLevel.OpsManager) {
        setOpsManagerPage(0), setDetailedReportData({ allOpsManagers: [] });
      }
    }
  }, [opsManagerSearchText, selectedCountryValue]);
  const getCodes = () => {
    let newArray: any[] = [];
    // to set Codes for channels dropdown in case of Detailed Report
    if (selectedDetailedData && selectedDetailedData.allCountries) {
      if (selectedDetailedData.allCountries.length !== 0) {
        selectedDetailedData?.allCountries?.forEach((item) => {
          newArray.push(item.countryCode);
          setCode(newArray);
          return newArray;
        });
      } else {
        setCode(newArray);
        return newArray;
      }
    }
    return newArray;
  };
  useEffect(() => {
    if (moduleType === builderLevel.isProdSheet) {
      setselectedCountryValue(null);
    }
  }, [selectedReportType, selectedLevel]);
  useEffect(() => {
    setAreaManagerSearchText("");
    setOpsManagerSearchText("");
    setChannelSearchText("");
    setStoreSearchText("");
    setLpAuditorSearchText("");
  }, [selectedCountryValue]);
  const getCountries = (pageNo: number, pageSize: number) => {
    if (pageNo === allCountries?.currentPage) {
      return allCountries && allCountries.content;
    }
  };
  const getChannel = (pageNo: number, pageSize: number, isHighLow?: boolean) => {
    if (isHighLow) {
      if (pageNo === getHighLowChannelsAdminData?.currentPage) {
        return getHighLowChannelsAdminData && getHighLowChannelsAdminData.content;
      }
    } else {
      if (pageNo === allChannels?.currentPage) {
        return allChannels && allChannels.content;
      }
    }
  };
  const getAreaManager = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === allAreaManagers?.currentPage) {
        return allAreaManagers && allAreaManagers.content;
      }
    } else {
      if (pageNo === allAreaManagers?.currentPage) {
        return allAreaManagers && allAreaManagers.content;
      }
    }
  };
  const getCountryManager = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === comparisonCountryManagers?.currentPage) {
        return comparisonCountryManagers && comparisonCountryManagers.content;
      }
    } else {
      if (pageNo === allCountryManagers?.currentPage) {
        return allCountryManagers && allCountryManagers.content;
      }
    }
  };
  const getClusterHeadOfOps = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === allClusterHeadOfOps?.currentPage) {
        return allClusterHeadOfOps && allClusterHeadOfOps.content;
      }
    } else {
      if (pageNo === allClusterHeadOfOps?.currentPage) {
        return allClusterHeadOfOps && allClusterHeadOfOps.content;
      }
    }
  };
  const getLpAuditor = (pageNo: number, pageSize: number, isHighLow?: boolean) => {
    // TODO:configure
    // if (isHighLow) {
    //   if (pageNo === allClusterHeadOfOps?.currentPage) {
    //     return allClusterHeadOfOps && allClusterHeadOfOps.content;
    //   }
    // } else {
    if (pageNo === allLpAuditor?.currentPage) {
      return allLpAuditor && allLpAuditor.content;
    }
    // }
  };
  const getOpsManager = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (pageNo === allOpsManagers?.currentPage) {
      return allOpsManagers && allOpsManagers.content;
    }
  };
  const getStores = (pageNo: number, pageSize: number, isComparison?: boolean) => {
    if (isComparison) {
      if (pageNo === comparisonStoresAdmin?.currentPage) {
        return comparisonStoresAdmin && comparisonStoresAdmin.content;
      }
    } else {
      if (pageNo === allStores?.currentPage) {
        return allStores && allStores.content;
      }
    }
  };
  const countryFlatlist = useFetchMoreItems(
    getCountries,
    countryFetch,
    countryPage,
    setCountryPage,
    setAllCountryFetch,
    null,
    allCountries?.totalElements,
    true
  );
  const channelFlatlist = useFetchMoreItems(
    getChannel,
    channelFetch,
    channelPage,
    setChannelsPage,
    setAllChannelFetch,
    false,
    allChannels?.totalElements,
    true
  );
  const areaManagerFlatlist = useFetchMoreItems(
    getAreaManager,
    areaManagerFetch,
    areaManagerPage,
    setAreaManagerPage,
    setAllAreaManagerFetch,
    false,
    allAreaManagers?.totalElements,
    true
  );
  const comparisionAreaManagerFlatlist = useFetchMoreItems(
    getAreaManager,
    areaManagerFetch,
    areaManagerPage,
    setAreaManagerPage,
    setAllAreaManagerFetch,
    user.roles.includes(Roles.ClusterHeadofOps) ? false : true,
    allAreaManagers?.totalElements,
    true
  );
  const countryManagerFlatlist = useFetchMoreItems(
    getCountryManager,
    countryManagerFetch,
    countryManagerPage,
    setCountryManagerPage,
    setAllCountryManagerFetch,
    false,
    allCountryManagers?.totalElements,
    true
  );
  const clusterHeadOfOpsFlatlist = useFetchMoreItems(
    getClusterHeadOfOps,
    clusterHeadOfOpsFetch,
    clusterHeadOfOpsPage,
    setClusterHeadOfOpsPage,
    setAllClusterHeadOfOpsFetch,
    false,
    allClusterHeadOfOps?.totalElements,
    true
  );
  const lpAuditorFlatlist = useFetchMoreItems(
    getLpAuditor,
    lpAuditorFetch,
    lpAuditorPage,
    setLpAuditorPage,
    setAllLpAuditorFetch,
    false,
    allLpAuditor?.totalElements,
    true
  );
  const comparisionCountryManagerFlatlist = useFetchMoreItems(
    getCountryManager,
    countryManagerFetch,
    countryManagerPage,
    setCountryManagerPage,
    setAllCountryManagerFetch,
    false,
    allCountryManagers?.totalElements,
    true
  );
  const opsManagerFlatList = useFetchMoreItems(
    getOpsManager,
    opsManagerFetch,
    opsManagerPage,
    setOpsManagerPage,
    setAllOpsManagerFetch,
    true,
    allOpsManagers?.totalElements,
    true
  );
  const storeFlatlist = useFetchMoreItems(
    getStores,
    storesFetch,
    storesPage,
    setStoresPage,
    setAllStoresFetch,
    false,
    allStores?.totalElements,
    true
  );
  // const comparisonStoreFlatList = useFetchMoreItems(
  //   getStores,
  //   storesFetch,
  //   storesPage,
  //   setStoresPage,
  //   setAllStoresFetch,
  //   false,
  //   comparisonStoresAdmin?.totalElements,
  //   true
  // );

  const highlowChannelsFlatlist = useFetchMoreItems(
    getChannel,
    channelFetch,
    channelPage,
    setChannelsPage,
    setAllChannelFetch,
    true,
    getHighLowChannelsAdminData?.totalElements,
    true
  );
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allCountries: countryFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allCountries: countryFlatlist.arrayItems
    }));
  }, [countryFlatlist.arrayItems]);

  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allChannels: channelFlatlist.arrayItems
    }));
  }, [channelFlatlist.arrayItems]);
  useEffect(() => {
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allChannels: channelFlatlist.arrayItems
    }));
  }, [channelFlatlist.arrayItems]);

  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allStores: storeFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allStores: storeFlatlist.arrayItems
    }));
  }, [storeFlatlist.arrayItems]);
  // useEffect(() => {
  //   setComparisonReportData((prevState: any) => ({
  //     ...prevState,
  //     allStores: storeFlatlist.arrayItems
  //   }));
  // }, [storeFlatlist.arrayItems]);

  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allOpsManagers: opsManagerFlatList.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allOpsManagers: opsManagerFlatList.arrayItems
    }));
  }, [opsManagerFlatList.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allCountryManagers: countryManagerFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allCountryManagers: countryManagerFlatlist.arrayItems
    }));
  }, [countryManagerFlatlist.arrayItems]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.AreaOfImprovement) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allCountryManagers: comparisionCountryManagerFlatlist.arrayItems
      }));
    }
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allCountryManagers: comparisionCountryManagerFlatlist.arrayItems
    }));
  }, [comparisionCountryManagerFlatlist.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allAreaManagers: areaManagerFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allAreaManagers: areaManagerFlatlist.arrayItems
    }));
  }, [areaManagerFlatlist.arrayItems]);
  useEffect(() => {
    if (selectedReportType === ReportTypeLevel.AreaOfImprovement) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allAreaManagers: comparisionAreaManagerFlatlist.arrayItems
      }));
    }
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allAreaManagers: comparisionAreaManagerFlatlist.arrayItems
    }));
  }, [comparisionAreaManagerFlatlist.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allClusterHeadOfOps: clusterHeadOfOpsFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allClusterHeadOfOps: clusterHeadOfOpsFlatlist.arrayItems
    }));
  }, [clusterHeadOfOpsFlatlist.arrayItems]);
  useEffect(() => {
    setDetailedReportData((prevState: any) => ({
      ...prevState,
      allLpAuditor: lpAuditorFlatlist.arrayItems
    }));
    setComparisonReportData((prevState: any) => ({
      ...prevState,
      allLpAuditor: lpAuditorFlatlist.arrayItems
    }));
  }, [lpAuditorFlatlist.arrayItems]);
  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedReportType === ReportTypeLevel.HighLow
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: highlowChannelsFlatlist.arrayItems
      }));
    }
  }, [highlowChannelsFlatlist.arrayItems]);
  //   useEffect(() => {
  //     if (
  //       // user?.roles?.includes(Roles.Admin) ||
  //       user?.roles?.includes(Roles.SuperAdmin) ||
  //       user?.roles?.includes(Roles.TrainingAndDevLead) ||
  //       user?.roles?.includes(Roles.TrainingAndDevManager) ||
  //       user?.roles?.includes(Roles.HeadLP) ||
  //       user?.roles?.includes(Roles.AuditorLP)
  //     ) {

  //       setComparisonReportData((prevState: any) => ({
  //         ...prevState,
  //         allStores: comparisonStoreFlatList.arrayItems
  //       }));
  //     }
  //   }, [comparisonStoreFlatList.arrayItems]);

  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Grading ||
      selectedReportType === ReportTypeLevel.Comparison ||
      selectedReportType === ReportTypeLevel.HighLow ||
      selectedReportType === ReportTypeLevel.AreaOfImprovement
    ) {
      if (selectedLevel === ComparsionLevel.Country) {
        setCountryPage(0);
        setDetailedReportData({ allCountries: [] });
        if (countrySearchText.length >= 3) {
          getAllCountriesLoading({
            pageNo: 0,
            pageSize: 10,
            searchByKeyword: countrySearchText,
            moduleType: moduleType
          });
        }
      }
    }
  }, [countrySearchText]);

  useEffect(() => {
    // admin role  - country api call for all 3 reports
    if (countrySearchText === "") {
      if (countryPage === 0) {
        setDetailedReportData({ allCountries: [] });
      }
      if (countryPage <= allCountries?.totalElements / 10 && allCountries?.totalElements > 10) {
        getAllCountriesLoading({
          pageNo: countryPage,
          pageSize: 10,
          searchByKeyword: countrySearchText,
          moduleType: moduleType
        });
      } else if (
        // user?.roles?.includes(Roles.Admin) ||
        (user?.roles?.includes(Roles.SuperAdmin) ||
          user?.roles?.includes(Roles.CEO) ||
          user?.roles?.includes(Roles.COO) ||
          user?.roles?.includes(Roles.HrAndAdminDirector) ||
          user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.TrainingAndDevManager) ||
          user?.roles?.includes(Roles.HeadLP) ||
          user?.roles?.includes(Roles.ClusterHeadofOps)) &&
        // user?.roles?.includes(Roles.CountryManager) ||
        (selectedLevel === ComparsionLevel.Country ||
          (selectedLevel === ComparsionLevel.Channels &&
            selectedReportType === ReportTypeLevel.Comparison) ||
          (selectedReportType === ReportTypeLevel.Detailed &&
            moduleType !== builderLevel.isProdSheet) ||
          (moduleType === builderLevel.isProdSheet &&
            selectedReportType === ReportTypeLevel.Detailed &&
            (selectedLevel === ComparsionLevel.Channels ||
              selectedLevel === ComparsionLevel.OpsManager ||
              selectedLevel === ComparsionLevel.AreaManager ||
              selectedLevel === ComparsionLevel.Stores)) ||
          (moduleType === builderLevel.isLp &&
            selectedReportType === ReportTypeLevel.AreaOfImprovement &&
            (selectedLevel === ComparsionLevel.Channels ||
              selectedLevel === ComparsionLevel.OpsManager ||
              selectedLevel === ComparsionLevel.LpAuditor ||
              selectedLevel === ComparsionLevel.AreaManager ||
              selectedLevel === ComparsionLevel.Stores)) ||
          selectedReportType === ReportTypeLevel.HighLow ||
          selectedReportType === ReportTypeLevel.Grading)
      ) {
        getAllCountriesLoading({
          pageNo: countryPage,
          pageSize: 10,
          searchByKeyword: countrySearchText,
          moduleType: moduleType,
          selectedLevel: selectedLevel,
          selectedReportType: selectedReportType
        });
      } else if (
        // user?.roles?.includes(Roles.Admin) ||
        (user?.roles?.includes(Roles.SuperAdmin) ||
          user?.roles?.includes(Roles.CEO) ||
          user?.roles?.includes(Roles.COO) ||
          user?.roles?.includes(Roles.HrAndAdminDirector) ||
          user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.TrainingAndDevManager) ||
          user?.roles?.includes(Roles.HeadLP) ||
          user?.roles?.includes(Roles.ClusterHeadofOps)) &&
        (selectedLevel === ComparsionLevel.OpsManager ||
          selectedLevel === ComparsionLevel.LpAuditor ||
          selectedLevel === ComparsionLevel.AreaManager ||
          selectedLevel === ComparsionLevel.Stores) &&
        (selectedReportType === ReportTypeLevel.Comparison ||
          selectedReportType === ReportTypeLevel.HighLow ||
          selectedReportType === ReportTypeLevel.Grading)
      ) {
        getAllCountriesLoading({
          pageNo: countryPage,
          pageSize: 10,
          searchByKeyword: countrySearchText,
          moduleType: moduleType
        });
      }
    }
  }, [selectedReportType, selectedLevel, countryPage, countrySearchText]);
  useEffect(() => {
    if (countrySearchText !== "") {
      if (countryPage <= allCountries?.totalElements / 10 && allCountries?.totalElements > 10) {
        getAllCountriesLoading({
          pageNo: countryPage,
          pageSize: 10,
          searchByKeyword: countrySearchText,
          moduleType: moduleType
        });
      } else if (
        // user?.roles?.includes(Roles.Admin) ||
        (user?.roles?.includes(Roles.SuperAdmin) ||
          user?.roles?.includes(Roles.CEO) ||
          user?.roles?.includes(Roles.COO) ||
          user?.roles?.includes(Roles.HrAndAdminDirector) ||
          user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.ClusterHeadofOps) ||
          user?.roles?.includes(Roles.CountryManager) ||
          user?.roles?.includes(Roles.TrainingAndDevManager) ||
          user?.roles?.includes(Roles.HeadLP) ||
          user?.roles?.includes(Roles.AuditorLP)) &&
        ((selectedLevel === ComparsionLevel.Country &&
          selectedReportType === ReportTypeLevel.Detailed &&
          moduleType !== builderLevel.isProdSheet) ||
          ((selectedLevel === ComparsionLevel.Channels ||
            selectedLevel === ComparsionLevel.AreaManager ||
            selectedLevel === ComparsionLevel.OpsManager ||
            selectedLevel === ComparsionLevel.Stores ||
            selectedLevel === ComparsionLevel.LpAuditor) &&
            (selectedReportType === ReportTypeLevel.Comparison ||
              selectedReportType === ReportTypeLevel.HighLow ||
              selectedReportType === ReportTypeLevel.AreaOfImprovement ||
              selectedReportType === ReportTypeLevel.Grading)) ||
          (moduleType === builderLevel.isProdSheet &&
            selectedReportType === ReportTypeLevel.Detailed &&
            (selectedLevel === ComparsionLevel.Country ||
              selectedLevel === ComparsionLevel.Channels ||
              selectedLevel === ComparsionLevel.OpsManager ||
              selectedLevel === ComparsionLevel.AreaManager ||
              selectedLevel === ComparsionLevel.Stores)))
      ) {
        getAllCountriesLoading({
          pageNo: countryPage,
          pageSize: 10,
          searchByKeyword: countrySearchText,
          moduleType: moduleType
        });
      } else if (
        user?.roles?.includes(Roles.ClusterHeadofOps) &&
        selectedReportType === ReportTypeLevel.Detailed
      ) {
        getAllCountriesLoading({
          pageNo: countryPage,
          pageSize: 10,
          searchByKeyword: countrySearchText,
          moduleType: moduleType
        });
      }
    }
  }, [selectedReportType, selectedLevel, countryPage, countrySearchText]);

  useEffect(() => {
    // Detailed report - Admin Role  cm
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      if (selectedDetailedData?.allCountries?.length > 0) {
        let dummy = getCodes();
        if (countrySearchText.length >= 3) {
          getAllCountryManagerLoading({
            user: user ? user : null,
            role: "Country Manager",
            pageNo: 0,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: countryManagerSearchText,
            allSelected: false,
            moduleType: moduleType,
            selectedReportType
          });
        } else {
          getAllCountryManagerLoading({
            user: user ? user : null,
            role: "Country Manager",
            pageNo: 0,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: countryManagerSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType,
            selectedReportType
          });
        }
      }
    }
  }, [selectedDetailedData?.allCountries, countryManagerSearchText]);
  useEffect(() => {
    if (
      moduleType === builderLevel.isProdSheet &&
      selectedLevel === ComparsionLevel.ClusterHeadOfOps
    ) {
      getAllClusterHeadOfOpsLoading({
        user: user,
        role: "Cluster Head of Ops",
        pageNo: clusterHeadOfOpsPage,
        pageSize: 10,
        searchByKeyword: clusterHeadOfOPsSearchText.length >= 3 ? clusterHeadOfOPsSearchText : "",
        allSelected: clusterHeadOfOpsSelectAll,
        moduleType: moduleType,
        selectedReportType
      });
    }
  }, [selectedLevel, selectedReportType, clusterHeadOfOPsSearchText, clusterHeadOfOpsPage]);

  // useEffect(() => {
  //   if (
  //     moduleType === builderLevel.isProdSheet &&
  //     selectedLevel === ComparsionLevel.ClusterHeadOfOps
  //   ) {
  //     if (clusterHeadOfOPsSearchText.length >= 3) {
  //       getAllClusterHeadOfOpsLoading({
  //         user: user ? user : null,
  //         role: "Cluster Head of Ops",
  //         pageNo: clusterHeadOfOpsPage,
  //         pageSize: 10,
  //         searchByKeyword: clusterHeadOfOPsSearchText,
  //         allSelected: clusterHeadOfOpsSelectAll,
  //         moduleType: moduleType,
  //         selectedReportType
  //       });
  //     }
  //   }
  // }, [clusterHeadOfOPsSearchText]);
  useEffect(() => {
    // Detailed report - Cluster Head of Ops api call
    let allowedArr: Array<Roles> = [...SuperAdminEqvRoles, ...CEOEqvRoles, Roles.HeadLP];

    if (
      user?.roles[0] &&
      allowedArr.includes(user.roles[0]) &&
      selectedReportType === ReportTypeLevel.Detailed &&
      selectedDetailedData?.allCountries?.length > 0
    ) {
      let dummy = getCodes();
      getAllClusterHeadOfOpsLoading({
        user: user ? user : null,
        pageNo: clusterHeadOfOpsPage,
        pageSize: 10,
        role: "Cluster Head of Ops",
        countryCode: dummy,
        searchByKeyword: clusterHeadOfOPsSearchText.length >= 3 ? clusterHeadOfOPsSearchText : "",
        allSelected: countrySearchText.length >= 3 ? false : countrySelectAll,
        moduleType: moduleType,
        selectedReportType
      });
    }
  }, [selectedDetailedData?.allCountries, clusterHeadOfOPsSearchText, clusterHeadOfOpsPage]);
  useEffect(() => {
    if (
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.AreaOfImprovement ||
        selectedReportType === ReportTypeLevel.Grading) &&
      !user.roles.includes(Roles.ClusterHeadofOps) &&
      selectedLevel === ComparsionLevel.ClusterHeadOfOps &&
      moduleType !== builderLevel.isProdSheet
    ) {
      if (clusterHeadOfOPsSearchText.length >= 3) {
        getAllClusterHeadOfOpsLoading({
          user: user ? user : null,
          pageNo: clusterHeadOfOpsPage,
          pageSize: 10,
          countryCode: [],
          role: "Cluster Head of Ops",
          searchByKeyword: clusterHeadOfOPsSearchText,
          allSelected: true,
          moduleType: moduleType,
          selectedReportType
        });
      } else {
        getAllClusterHeadOfOpsLoading({
          user: user ? user : null,
          pageNo: clusterHeadOfOpsPage,
          pageSize: 10,
          countryCode: [],
          role: "Cluster Head of Ops",
          searchByKeyword: clusterHeadOfOPsSearchText,
          allSelected: true,
          moduleType: moduleType,
          selectedReportType
        });
      }
    }
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];

    if (
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      !allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.OpsManager
    ) {
      if (opsManagerSearchText.length >= 3) {
        getAllOpsManagerLoading({
          user: user ? user : null,
          role: "Ops Manager",
          pageNo: opsManagerPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: opsManagerSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      } else {
        getAllOpsManagerLoading({
          user: user ? user : null,
          role: "Ops Manager",
          pageNo: opsManagerPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: opsManagerSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
    }
    if (
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      !allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.LpAuditor
    ) {
      if (lpAuditorSearchText.length >= 3) {
        getAllLpAuditorLoading({
          user: user ? user : null,
          pageNo: lpAuditorPage,
          pageSize: 10,
          role: "LP Auditor",
          countryCode: [],
          searchByKeyword: lpAuditorSearchText,
          allSelected: true,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      } else {
        getAllLpAuditorLoading({
          user: user ? user : null,
          pageNo: lpAuditorPage,
          pageSize: 10,
          role: "LP Auditor",
          countryCode: [],
          searchByKeyword: lpAuditorSearchText,
          allSelected: true,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
    }
  }, [
    clusterHeadOfOPsSearchText,
    clusterHeadOfOpsPage,
    opsManagerSearchText,
    lpAuditorSearchText,
    selectedLevel,
    selectedReportType,
    opsManagerPage,
    lpAuditorPage
  ]);
  useEffect(() => {
    if (
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      selectedLevel === ComparsionLevel.ClusterHeadOfOps &&
      selectedUnitReportCountry?.data
    ) {
      if (clusterHeadOfOPsSearchText.length >= 3) {
        getAllClusterHeadOfOpsLoading({
          user: user ? user : null,
          pageNo: clusterHeadOfOpsPage,
          pageSize: 10,
          countryCode: selectedUnitReportCountry?.data?.countryCode,
          role: "Cluster Head of Ops",
          searchByKeyword: clusterHeadOfOPsSearchText,
          allSelected: true,
          moduleType: moduleType,
          selectedReportType
        });
      } else {
        getAllClusterHeadOfOpsLoading({
          user: user ? user : null,
          pageNo: clusterHeadOfOpsPage,
          pageSize: 10,
          countryCode: selectedUnitReportCountry?.data?.countryCode,
          role: "Cluster Head of Ops",
          searchByKeyword: clusterHeadOfOPsSearchText,
          allSelected: true,
          moduleType: moduleType,
          selectedReportType
        });
      }
    }
  }, [
    clusterHeadOfOPsSearchText,
    clusterHeadOfOpsPage,
    selectedLevel,
    selectedReportType,
    selectedUnitReportCountry
  ]);
  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];

    if (
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.AreaOfImprovement ||
        selectedReportType === ReportTypeLevel.Grading) &&
      selectedCountryValue &&
      user?.roles[0] &&
      allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.OpsManager
    ) {
      if (opsManagerSearchText.length >= 3) {
        getAllOpsManagerLoading({
          user: user ? user : null,
          role: "Ops Manager",
          pageNo: opsManagerPage,
          pageSize: 10,
          countryCode: selectedCountryValue.countryCode,
          searchByKeyword: opsManagerSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      } else {
        getAllOpsManagerLoading({
          user: user ? user : null,
          role: "Ops Manager",
          pageNo: opsManagerPage,
          pageSize: 10,
          countryCode: selectedCountryValue.countryCode,
          searchByKeyword: opsManagerSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
    }
    if (
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.AreaOfImprovement ||
        selectedReportType === ReportTypeLevel.Grading) &&
      selectedCountryValue &&
      selectedLevel === ComparsionLevel.LpAuditor
    ) {
      if (lpAuditorSearchText.length >= 3) {
        getAllLpAuditorLoading({
          user: user ? user : null,
          pageNo: lpAuditorPage,
          pageSize: 10,
          role: "LP Auditor",
          countryCode: selectedCountryValue?.countryCode,
          searchByKeyword: lpAuditorSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      } else {
        getAllLpAuditorLoading({
          user: user ? user : null,
          pageNo: lpAuditorPage,
          pageSize: 10,
          role: "LP Auditor",
          countryCode: selectedCountryValue?.countryCode,
          searchByKeyword: lpAuditorSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
    }
  }, [
    clusterHeadOfOPsSearchText,
    opsManagerSearchText,
    opsManagerPage,
    lpAuditorPage,
    lpAuditorSearchText,
    selectedLevel,
    selectedReportType,
    selectedCountryValue
  ]);
  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.ClusterHeadofOps
    ];

    if (
      moduleType === builderLevel.isProdSheet &&
      allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.CountryManager
    ) {
      getAllCountryManagerLoading({
        user: user ? user : null,
        role: "Country Manager",
        pageNo: countryManagerPage,
        pageSize: 10,
        searchByKeyword: countryManagerSearchText,
        allSelected: false,
        moduleType: moduleType,
        selectedReportType
      });
    }
  }, [countryManagerSearchText, selectedLevel, countryManagerPage]);

  useEffect(() => {
    // Detailed report - Admin Role am
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.ClusterHeadofOps) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      if (selectedDetailedData?.allCountries?.length > 0) {
        let dummy = getCodes();
        if (countrySearchText.length >= 3) {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: 0,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: areaManagerSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        } else {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: 0,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: areaManagerSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        }
      }
    }
  }, [selectedDetailedData?.allCountries, areaManagerSearchText]);
  useEffect(() => {
    // Detailed report - Admin Role channel production sheet
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps
    ];
    if (
      moduleType === builderLevel.isProdSheet &&
      // user?.roles?.includes(Roles.SuperAdmin) &&
      allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.AreaManager &&
      selectedReportType === ReportTypeLevel.Detailed &&
      selectedUnitReportCountry?.data
    ) {
      getAllAreaManagerLoading({
        user: user ? user : null,
        role: "Area Manager",
        pageNo: areaManagerPage,
        pageSize: 10,
        countryCode: selectedUnitReportCountry?.data?.countryCode,
        searchByKeyword: areaManagerSearchText,
        allSelected: false,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [areaManagerSearchText, selectedLevel, selectedUnitReportCountry, areaManagerPage]);
  useEffect(() => {
    // Detailed report - Admin Role stores
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.ClusterHeadofOps) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      if (selectedDetailedData?.allCountries?.length > 0) {
        let dummy = getCodes();

        if (storeSearchText.length >= 3) {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: storeSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        } else {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: storeSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        }
      }
    }
  }, [selectedDetailedData?.allCountries, storesPage, storeSearchText]);
  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps
    ];

    if (
      moduleType === builderLevel.isProdSheet &&
      allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.Stores &&
      selectedReportType === ReportTypeLevel.Detailed &&
      selectedUnitReportCountry?.data
    ) {
      getAllStoresLoading({
        user: user ? user : null,
        pageNo: storesPage,
        pageSize: 10,
        countryCode: selectedUnitReportCountry?.data?.countryCode,
        searchByKeyword: storeSearchText,
        allSelected: countrySelectAll,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [storeSearchText, selectedLevel, selectedUnitReportCountry, storesPage]);
  useEffect(() => {
    // Detailed report - Admin Role channel
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.ClusterHeadofOps) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      if (selectedDetailedData?.allCountries?.length > 0) {
        let dummy = getCodes();
        if (countrySearchText.length >= 3) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: 0,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        } else {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: 0,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: channelSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType
          });
        }
      }
    }
  }, [selectedDetailedData?.allCountries, channelSearchText]);

  useEffect(() => {
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps
    ];

    if (
      moduleType === builderLevel.isProdSheet &&
      allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.Channels &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      if (selectedUnitReportCountry?.data) {
        let dummy = selectedUnitReportCountry?.data.countryCode;
        getAllChannelsLoading({
          user: user ? user : null,
          pageNo: 0,
          pageSize: 10,
          countryCode: [dummy],
          searchByKeyword: channelSearchText,
          moduleType: moduleType
        });
      }
    } else if (
      !allowedArr.includes(user.roles[0]) &&
      selectedLevel === ComparsionLevel.Channels &&
      moduleType === builderLevel.isProdSheet
    ) {
      getAllChannelsLoading({
        user: user ? user : null,
        pageNo: 0,
        pageSize: 10,
        countryCode: [],
        searchByKeyword: channelSearchText,
        moduleType: moduleType
      });
    }
  }, [channelSearchText, selectedLevel, selectedUnitReportCountry, channelPage]);
  useEffect(() => {
    // Detailed report - LP Auditor api call
    let allowedArr: Array<Roles> = [Roles.HeadLP];

    if (
      user?.roles[0] &&
      allowedArr.includes(user.roles[0]) &&
      selectedReportType === ReportTypeLevel.Detailed &&
      selectedDetailedData?.allCountries?.length > 0
    ) {
      let dummy = getCodes();
      getAllLpAuditorLoading({
        user: user ? user : null,
        pageNo: lpAuditorPage,
        pageSize: 10,
        role: "LP Auditor",
        countryCode: dummy,
        searchByKeyword: lpAuditorSearchText,
        allSelected: countrySearchText.length >= 3 ? false : countrySelectAll,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [selectedDetailedData?.allCountries, lpAuditorSearchText, lpAuditorPage]);
  useEffect(() => {
    // Detailed report - Ops Manager api call
    let allowedArr: Array<Roles> = [
      ...SuperAdminEqvRoles,
      ...CEOEqvRoles,
      Roles.HeadLP,
      Roles.AuditorLP,
      Roles.ClusterHeadofOps
    ];

    if (
      user?.roles[0] &&
      allowedArr.includes(user.roles[0]) &&
      selectedReportType === ReportTypeLevel.Detailed &&
      selectedDetailedData?.allCountries?.length > 0
    ) {
      let dummy = getCodes();
      getAllOpsManagerLoading({
        user: user ? user : null,
        role: "Ops Manager",
        pageNo: opsManagerPage,
        pageSize: 10,
        countryCode: dummy,
        searchByKeyword: opsManagerSearchText,
        allSelected: countrySearchText.length >= 3 ? false : countrySelectAll,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [selectedDetailedData?.allCountries, opsManagerSearchText, opsManagerPage]);
  useEffect(() => {
    if (
      user.roles.includes(Roles.CountryManager) &&
      moduleType !== builderLevel.isProdSheet &&
      selectedReportType === ReportTypeLevel.Detailed
    ) {
      getAllOpsManagerLoading({
        user: user ? user : null,
        role: "Ops Manager",
        pageNo: opsManagerPage,
        pageSize: 10,
        countryCode: [],
        searchByKeyword: opsManagerSearchText,
        allSelected: countrySearchText.length >= 3 ? false : countrySelectAll,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [opsManagerSearchText, opsManagerPage, selectedReportType]);

  useEffect(() => {
    //   stores dropdown api - detailed report
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (
        (user?.roles?.includes(Roles.CountryManager) ||
          user?.roles?.includes(Roles.OperationManager) ||
          user?.roles?.includes(Roles.AreaManager) ||
          user?.roles?.includes(Roles.SeniorOpsManager)) &&
        moduleType !== builderLevel.isProdSheet
      ) {
        getAllStoresLoading({
          user: user ? user : null,
          pageNo: storesPage,
          pageSize: 10,
          countryCode: code,
          searchByKeyword: storeSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType,
          userRole: user?.roles[0]
        });
      }
    }
    // admin/superadmin role - stores dropdown api - comparison and highlow report
    if (
      selectedLevel === ComparsionLevel.Stores &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      // user?.roles?.includes(Roles.Admin) ||

      (user?.roles?.includes(Roles.InternalAuditor) || user?.roles?.includes(Roles.CountryManager))
    ) {
      if (storesPage <= allStores?.totalElements / 10 && allStores?.totalElements > 10) {
        getAllStoresLoading({
          user: user ? user : null,
          pageNo: storesPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: storeSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType,
          userRole: user?.roles[0]
        });
      }
    }
  }, [storesPage, storeSearchText]);

  useEffect(() => {
    //  areamanager dropdown api - detailed report
    if (
      selectedReportType === ReportTypeLevel.Detailed &&
      moduleType !== builderLevel.isProdSheet
    ) {
      if (
        areaManagerPage <= allAreaManagers?.totalElements / 10 &&
        allAreaManagers?.totalElements > 10
      ) {
        if (countrySearchText.length >= 3) {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: areaManagerSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        } else {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: areaManagerSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        }
      }
    }
  }, [areaManagerPage]);
  useEffect(() => {
    //  countrymanager dropdown api - detailed report
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (
        countryManagerPage <= allCountryManagers?.totalElements / 10 &&
        allCountryManagers?.totalElements > 10
      ) {
        if (countrySearchText.length >= 3) {
          getAllCountryManagerLoading({
            user: user ? user : null,
            role: "Country Manager",
            pageNo: countryManagerPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: countryManagerSearchText,
            allSelected: false,
            moduleType: moduleType,
            selectedReportType
          });
        } else {
          getAllCountryManagerLoading({
            user: user ? user : null,
            role: "Country Manager",
            pageNo: countryManagerPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: countryManagerSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType,
            selectedReportType
          });
        }
      }
    }

    if (
      selectedLevel === ComparsionLevel.CountryManager &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      if (
        countryManagerPage <= comparisonCountryManagers?.totalElements / 10 &&
        comparisonCountryManagers?.totalElements > 10
      ) {
        getComparisonCountryManagersAdmin({
          pageNo: countryManagerPage,
          pageSize: 10,
          searchByKeyword: countryManagerSearchText,
          moduleType: moduleType
        });
      }
    }
  }, [countryManagerPage]);

  useEffect(() => {
    //  channel dropdown api - detailed report
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (channelPage <= allChannels?.totalElements / 10 && allChannels?.totalElements > 10) {
        if (countrySearchText.length >= 3) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        } else {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType
          });
        }
      }
    }
  }, [channelPage]);
  useEffect(() => {
    // CM /AM  Role -> Detailed report - channels api
    if (selectedReportType === ReportTypeLevel.Detailed) {
      if (
        (user?.roles?.includes(Roles.CountryManager) ||
          user?.roles?.includes(Roles.OperationManager) ||
          user?.roles?.includes(Roles.SeniorOpsManager) ||
          user?.roles?.includes(Roles.AreaManager)) &&
        moduleType !== builderLevel.isProdSheet
      ) {
        if (countrySearchText.length >= 3) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        } else {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType
          });
        }
      }
    }
  }, [selectedReportType, channelPage, channelSearchText]);
  useEffect(() => {
    if (
      selectedLevel === ComparsionLevel.Channels &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.AreaOfImprovement ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.ClusterHeadofOps) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      // Admin role -> api call - channels dropdown when country is selected in Comparison Report
      if (selectedUnitReportCountry?.length > 0) {
        let dummy = getComparisonCodes();
        if (countrySearchText.length >= 3) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: 0,
            pageSize: 10,
            countryCode: dummy,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        } else {
          dummy &&
            dummy.length > 1 &&
            dummy[0] &&
            getAllChannelsLoading({
              user: user ? user : null,
              pageNo: 0,
              pageSize: 10,
              countryCode: dummy,
              searchByKeyword: channelSearchText,
              allSelected: false,
              moduleType: moduleType
            });
        }
      } else if (
        selectedReportType === ReportTypeLevel.HighLow ||
        (selectedReportType === ReportTypeLevel.AreaOfImprovement &&
          (user?.roles?.includes(Roles.SuperAdmin) ||
            user?.roles?.includes(Roles.HrAndAdminDirector) ||
            user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
            user?.roles?.includes(Roles.TrainingAndDevLead) ||
            user?.roles?.includes(Roles.TrainingAndDevManager) ||
            user?.roles?.includes(Roles.CEO) ||
            user?.roles?.includes(Roles.COO) ||
            user?.roles?.includes(Roles.HeadLP) ||
            user?.roles?.includes(Roles.ClusterHeadofOps)))
      ) {
        if (selectedUnitReportCountry?.data) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: 0,
            pageSize: 10,
            countryCode: selectedUnitReportCountry?.data?.countryCode,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        }
      }
    }
  }, [selectedUnitReportCountry, channelSearchText]);
  useEffect(() => {
    if (
      moduleType === builderLevel.isProdSheet &&
      selectedLevel === ComparsionLevel.OpsManager &&
      selectedUnitReportCountry?.data
    ) {
      getAllOpsManagerLoading({
        user: user ? user : null,
        role: "Ops Manager",
        pageNo: opsManagerPage,
        pageSize: 10,
        countryCode: selectedUnitReportCountry?.data?.countryCode,
        searchByKeyword: opsManagerSearchText,
        allSelected: countrySearchText.length >= 3 ? false : countrySelectAll,
        moduleType: moduleType,
        reportType: selectedReportType
      });
    }
  }, [
    selectedReportType,
    selectedUnitReportCountry,
    selectedLevel,
    opsManagerSearchText,
    opsManagerPage
  ]);
  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.AreaManager) ||
        user?.roles?.includes(Roles.OperationManager) ||
        user?.roles?.includes(Roles.SeniorOpsManager)) &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading ||
        selectedReportType === ReportTypeLevel.AreaOfImprovement)
    ) {
      if (selectedLevel === ComparsionLevel.AreaManager) {
        getAllAreaManagerLoading({
          user: user ? user : null,
          role: "Area Manager",
          pageNo: areaManagerPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: areaManagerSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
      if (selectedLevel === ComparsionLevel.CountryManager)
        getComparisonCountryManagersAdmin({
          pageNo: countryManagerPage,
          pageSize: 10,
          searchByKeyword: countryManagerSearchText,
          moduleType: moduleType
        });
    }
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.AreaManager) ||
        user?.roles?.includes(Roles.OperationManager) ||
        user?.roles?.includes(Roles.SeniorOpsManager)) &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading ||
        selectedReportType === ReportTypeLevel.AreaOfImprovement)
    ) {
      if (selectedLevel === ComparsionLevel.Stores) {
        getAllStoresLoading({
          user: user ? user : null,
          pageNo: storesPage,
          pageSize: 10,
          countryCode: [],
          searchByKeyword: storeSearchText,
          allSelected: false,
          moduleType: moduleType,
          reportType: selectedReportType,
          userRole: user?.roles[0]
        });
      }
    }

    if (
      user?.roles?.includes(Roles.SeniorOpsManager) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.OperationManager)
    ) {
      if (selectedLevel === ComparsionLevel.CountryManager)
        if (countrySearchText.length >= 3) {
          getAllCountryManagerLoading({
            user: user ? user : null,
            pageNo: countryManagerPage,
            pageSize: 10,
            role: "Country Manager",
            countryCode: [],
            searchByKeyword: countryManagerSearchText,
            allSelected: true,
            moduleType: moduleType,
            selectedReportType
          });
        } else {
          getAllCountryManagerLoading({
            user: user ? user : null,
            pageNo: countryManagerPage,
            pageSize: 10,
            role: "Country Manager",
            countryCode: [],
            searchByKeyword: countryManagerSearchText,
            allSelected: true,
            moduleType: moduleType,
            selectedReportType
          });
        }
      if (moduleType === builderLevel.isProdSheet && selectedLevel === ComparsionLevel.OpsManager) {
        getAllOpsManagerLoading({
          user: user ? user : null,
          role: "Ops Manager",
          pageNo: opsManagerPage,
          pageSize: 10,
          searchByKeyword: opsManagerSearchText,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
      if (
        selectedReportType === ReportTypeLevel.AreaOfImprovement &&
        selectedLevel === ComparsionLevel.OpsManager
      ) {
        getAllOpsManagerLoading({
          user: user ? user : null,
          role: "Ops Manager",
          pageNo: opsManagerPage,
          pageSize: 10,
          searchByKeyword: opsManagerSearchText,
          moduleType: moduleType,
          reportType: selectedReportType
        });
      }
      if (
        (selectedReportType === ReportTypeLevel.Comparison ||
          selectedReportType === ReportTypeLevel.HighLow ||
          selectedReportType === ReportTypeLevel.Grading ||
          selectedReportType === ReportTypeLevel.AreaOfImprovement) &&
        selectedLevel === ComparsionLevel.Channels &&
        (user?.roles?.includes(Roles.CountryManager) ||
          user?.roles?.includes(Roles.OperationManager) ||
          user?.roles?.includes(Roles.AreaManager) ||
          user?.roles?.includes(Roles.SeniorOpsManager))
      ) {
        if (countrySearchText.length >= 3) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        } else {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: channelSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType
          });
        }
      }
    }
  }, [
    selectedLevel,
    selectedReportType,
    countryManagerPage,
    channelPage,
    channelSearchText,
    opsManagerPage,
    opsManagerSearchText,
    storesPage,
    storeSearchText,
    areaManagerPage,
    areaManagerSearchText
  ]);
  useEffect(() => {
    if (
      user?.roles?.includes(Roles.SeniorOpsManager) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.OperationManager)
    ) {
      if (
        selectedReportType === ReportTypeLevel.Detailed &&
        selectedLevel === ComparsionLevel.AreaManager
      ) {
        if (countrySearchText.length >= 3) {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: areaManagerSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        } else {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: areaManagerSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        }
      }
    }
  }, [selectedLevel, selectedReportType, areaManagerSearchText, areaManagerPage]);
  useEffect(() => {
    if (
      user?.roles?.includes(Roles.SeniorOpsManager) ||
      user?.roles?.includes(Roles.CountryManager) ||
      user?.roles?.includes(Roles.AreaManager) ||
      user?.roles?.includes(Roles.OperationManager)
    ) {
      if (
        selectedReportType === ReportTypeLevel.Detailed &&
        selectedLevel === ComparsionLevel.Stores
      ) {
        if (countrySearchText.length >= 3) {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: storeSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        } else {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: code,
            searchByKeyword: storeSearchText,
            allSelected: countrySelectAll,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        }
      }
    }
  }, [selectedLevel, selectedReportType, storeSearchText, storesPage]);
  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.InternalAuditor) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP) ||
        user?.roles?.includes(Roles.ClusterHeadofOps)) &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.AreaOfImprovement ||
        selectedReportType === ReportTypeLevel.Grading) &&
      moduleType !== builderLevel.isProdSheet
    ) {
      if (selectedLevel === ComparsionLevel.CountryManager)
        if (countryManagerSearchText.length >= 3) {
          getAllCountryManagerLoading({
            user: user ? user : null,
            pageNo: countryManagerPage,
            pageSize: 10,
            role: "Country Manager",
            countryCode: [],
            searchByKeyword: countryManagerSearchText,
            allSelected: true,
            moduleType: moduleType,
            selectedReportType
          });
        } else {
          getAllCountryManagerLoading({
            user: user ? user : null,
            pageNo: countryManagerPage,
            pageSize: 10,
            role: "Country Manager",
            countryCode: [],
            searchByKeyword: countryManagerSearchText,
            allSelected: true,
            moduleType: moduleType,
            selectedReportType
          });
        }
    }
  }, [selectedLevel, selectedReportType, countryManagerSearchText, countryManagerPage]);
  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.InternalAuditor) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP) ||
        user?.roles?.includes(Roles.ClusterHeadofOps)) &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading)
    ) {
      if (selectedLevel === ComparsionLevel.Stores && selectedCountryValue) {
        if (storeSearchText.length >= 3) {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: storeSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        } else {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: "",
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        }
      }
      if (selectedLevel === ComparsionLevel.Channels && selectedCountryValue) {
        if (channelSearchText.length >= 3) {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: channelSearchText,
            allSelected: false,
            moduleType: moduleType
          });
        } else {
          getAllChannelsLoading({
            user: user ? user : null,
            pageNo: channelPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: "",
            allSelected: false,
            moduleType: moduleType
          });
        }
      }
      if (selectedLevel === ComparsionLevel.AreaManager && selectedCountryValue) {
        if (areaManagerSearchText.length >= 3) {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: areaManagerSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        } else {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: "",
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        }
      }
    } else if (
      selectedReportType === ReportTypeLevel.AreaOfImprovement &&
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.InternalAuditor) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP) ||
        user?.roles?.includes(Roles.ClusterHeadofOps))
    ) {
      if (selectedLevel === ComparsionLevel.Stores && selectedCountryValue) {
        if (storeSearchText.length >= 3) {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: storeSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        } else {
          getAllStoresLoading({
            user: user ? user : null,
            pageNo: storesPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: "",
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType,
            userRole: user?.roles[0]
          });
        }
      }
      if (selectedLevel === ComparsionLevel.AreaManager && selectedCountryValue) {
        if (areaManagerSearchText.length >= 3) {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: areaManagerSearchText,
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        } else {
          getAllAreaManagerLoading({
            user: user ? user : null,
            role: "Area Manager",
            pageNo: areaManagerPage,
            pageSize: 10,
            countryCode: selectedCountryValue.countryCode,
            searchByKeyword: "",
            allSelected: false,
            moduleType: moduleType,
            reportType: selectedReportType
          });
        }
      }
    }
  }, [
    storeSearchText,
    selectedCountryValue,
    areaManagerSearchText,
    channelSearchText,
    areaManagerPage,
    storesPage,
    channelPage
  ]);
  useEffect(() => {
    if (
      selectedLevel === ComparsionLevel.Channels &&
      selectedReportType === ReportTypeLevel.Comparison &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: allChannels?.content,
        allCountries: allCountries?.content
      }));
    }
    if (
      selectedLevel === ComparsionLevel.Channels &&
      (selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading) &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: getHighLowChannelsAdminData?.content
      }));
    }
    if (
      (user?.roles?.includes(Roles.AuditorLP) || user?.roles?.includes(Roles.HeadLP)) &&
      selectedLevel === ComparsionLevel.Stores &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading)
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allStores: allStores?.content
      }));
    }
    if (
      (user?.roles?.includes(Roles.AuditorLP) || user?.roles?.includes(Roles.HeadLP)) &&
      selectedLevel === ComparsionLevel.Channels &&
      (selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.HighLow ||
        selectedReportType === ReportTypeLevel.Grading)
    ) {
      setComparisonReportData((prevState: any) => ({
        ...prevState,
        allChannels: allChannels?.content
      }));
    }
  }, [selectedLevel, selectedReportType]);
  useEffect(() => {
    if (
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CountryManager) ||
        user?.roles?.includes(Roles.InternalAuditor) ||
        user?.roles?.includes(Roles.AreaManager) ||
        user?.roles?.includes(Roles.InternalAuditor) ||
        user?.roles?.includes(Roles.ClusterHeadofOps) ||
        user?.roles?.includes(Roles.SeniorOpsManager) ||
        user?.roles?.includes(Roles.OperationManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP)) &&
      (selectedReportType === ReportTypeLevel.Detailed ||
        selectedReportType === ReportTypeLevel.Comparison ||
        selectedReportType === ReportTypeLevel.ConsData ||
        selectedReportType === ReportTypeLevel.HighLow) &&
      moduleType !== builderLevel.isProdSheet
    ) {
      getKpiData({ moduleType: moduleType });
    }
  }, [selectedReportType, selectedLevel]);

  useEffect(() => {
    if (
      selectedReportType === ReportTypeLevel.Detailed &&
      moduleType === builderLevel.isProdSheet
    ) {
      if (
        selectedLevel === ComparsionLevel.Country ||
        selectedLevel === ComparsionLevel.CountryManager ||
        selectedLevel === ComparsionLevel.ClusterHeadOfOps
      ) {
        setSelectedProduct([]);
        if (selectedUnitReportCountry) {
          let selectedId;
          if (selectedLevel === ComparsionLevel.Country) {
            selectedId = selectedUnitReportCountry?.data?.countryCode;
            let allSelected = countrySelectAll;
            if (countrySearchText.length >= 3) {
              allSelected = false;
            }
            if (selectedId && selectedCategory && selectedCategory?.length !== 0) {
              getProduct({
                idList: selectedId,
                level: selectedLevel,
                station: selectedDataInReportLevel,
                categoryId: selectedCategory?.code
              });
            }
          } else if (selectedLevel === ComparsionLevel.CountryManager) {
            selectedId = selectedUnitReportData?.code;
            let allSelected = countryManagerSelectAll;
            if (countryManagerSearchText.length >= 3) {
              allSelected = false;
            }
            if (selectedId && selectedCategory && selectedCategory?.length !== 0) {
              getProduct({
                idList: selectedId,
                level: selectedLevel,
                station: selectedDataInReportLevel,
                categoryId: selectedCategory?.code
              });
            }
          } else if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
            selectedId = selectedUnitReportCountry?.data?.code;
            let allSelected = clusterHeadOfOpsSelectAll;
            if (clusterHeadOfOPsSearchText.length >= 3) {
              allSelected = false;
            }
            if (selectedId && selectedCategory && selectedCategory?.length !== 0) {
              getProduct({
                idList: selectedId,
                level: selectedLevel,
                station: selectedDataInReportLevel,
                categoryId: selectedCategory?.code
              });
            }
          }
        }
      } else if (selectedLevel === ComparsionLevel.Channels) {
        if (selectedUnitReportData && selectedReportType === ReportTypeLevel.Detailed) {
          let selectedId = selectedUnitReportData?.code;
          let countryCode = selectedUnitReportCountry?.data?.countryCode;

          getProduct({
            idList: selectedId,
            level: selectedLevel,
            station: selectedDataInReportLevel,
            categoryId: selectedCategory?.code,
            countryCodes: countryCode
          });
        }
      } else if (selectedLevel === ComparsionLevel.OpsManager) {
        if (selectedUnitReportData) {
          let selectedId = selectedUnitReportData?.code;
          let countryCode = selectedUnitReportCountry?.data?.countryCode;
          if (selectedId && selectedCategory && selectedCategory?.length !== 0) {
            getProduct({
              idList: selectedId,
              level: selectedLevel,
              station: selectedDataInReportLevel,
              categoryId: selectedCategory?.code,
              countryCodes: countryCode
            });
          }
        }
      } else if (selectedLevel === ComparsionLevel.AreaManager) {
        if (selectedUnitReportData) {
          let selectedId = selectedUnitReportData?.code;
          let countryCode = selectedUnitReportCountry?.data?.countryCode;
          if (selectedId && selectedCategory && selectedCategory?.length !== 0) {
            getProduct({
              idList: selectedId,
              level: selectedLevel,
              station: selectedDataInReportLevel,
              categoryId: selectedCategory?.code,
              countryCodes: countryCode
            });
          }
        }
      } else if (selectedLevel === ComparsionLevel.Stores) {
        if (selectedUnitReportData) {
          let selectedId = selectedUnitReportData?.code;
          let countryCode = selectedUnitReportCountry?.data?.countryCode;
          if (selectedId && selectedCategory && selectedCategory?.length !== 0) {
            getProduct({
              idList: selectedId,
              level: selectedLevel,
              station: selectedDataInReportLevel,
              categoryId: selectedCategory?.code,
              countryCodes: countryCode
            });
          }
        }
      }
    }
  }, [selectedCategory]);
  useEffect(() => {
    if (moduleType === builderLevel.isProdSheet && user?.roles?.includes(Roles.StoreAccess)) {
      getCategory({
        idList: user.storeCode,
        level: ComparsionLevel.Stores,
        station: selectedDataInReportLevel,
        allSelected: false
      });
    }
  }, [selectedDataInReportLevel]);
  useEffect(() => {
    if (
      moduleType === builderLevel.isProdSheet &&
      selectedReportType === ReportTypeLevel.Detailed &&
      user?.roles?.includes(Roles.StoreAccess) &&
      selectedCategory?.length !== 0
    ) {
      getProduct({
        idList: user.storeCode,
        level: ComparsionLevel.Stores,
        station: selectedDataInReportLevel,
        categoryId: selectedCategory?.code,
        countryCodes: [],
        allSelected: false
      });
    }
  }, [selectedCategory]);
  useEffect(() => {
    if (moduleType === builderLevel.isProdSheet) {
      if (selectedReportType === ReportTypeLevel.Comparison) {
        if (
          selectedLevel === ComparsionLevel.Country ||
          selectedLevel === ComparsionLevel.CountryManager ||
          selectedLevel === ComparsionLevel.ClusterHeadOfOps
        ) {
          if (selectedUnitReportData?.length) {
            let selectedId; // selected ID
            let productidList;

            if (selectedLevel === ComparsionLevel.Country) {
              selectedId = selectedUnitReportData?.data?.countryCode;
              if (selectedReportType === ReportTypeLevel.Comparison) {
                productidList = selectedUnitReportData?.map((item: any) => {
                  return item.countryCode;
                });
              }
              if (selectedReportType === ReportTypeLevel.Comparison && productidList?.length > 0) {
                let allSelected = countrySelectAll;
                if (countrySearchText.length >= 3) {
                  allSelected = false;
                }
                getCategory({
                  idList: productidList.join(","),
                  level: selectedLevel,
                  station: selectedDataInReportLevel,
                  allSelected: allSelected
                });
              }
            } else if (selectedLevel === ComparsionLevel.CountryManager) {
              selectedId = selectedUnitReportData?.data?.employeeId;
              if (selectedReportType === ReportTypeLevel.Comparison) {
                productidList = selectedUnitReportData?.map((item: any) => {
                  return item.code;
                });
              }
              if (selectedReportType === ReportTypeLevel.Comparison && productidList?.length > 0) {
                let allSelected = countryManagerSelectAll;
                if (countryManagerSearchText.length >= 3) {
                  allSelected = false;
                }
                getCategory({
                  idList: productidList.join(","),
                  level: selectedLevel,
                  station: selectedDataInReportLevel,
                  allSelected: allSelected
                });
              }
            } else if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
              if (selectedReportType === ReportTypeLevel.Comparison) {
                productidList = selectedUnitReportData?.map((item: any) => {
                  return item.code;
                });
              }
              if (selectedReportType === ReportTypeLevel.Comparison && productidList?.length > 0) {
                let allSelected = clusterHeadOfOpsSelectAll;
                if (clusterHeadOfOPsSearchText.length >= 3) {
                  allSelected = false;
                }
                getCategory({
                  idList: productidList.join(","),
                  level: selectedLevel,
                  station: selectedDataInReportLevel,
                  allSelected: allSelected
                });
              }
            }
          }
        } else if (selectedLevel === ComparsionLevel.Channels) {
          if (selectedUnitReportData?.length > 0) {
            let allSelected = channelSelectAll;
            if (channelSearchText.length >= 3) {
              allSelected = false;
            }
            let selectedId = selectedUnitReportData?.map((item: any) => {
              return item.code;
            });
            let countryCode = selectedUnitReportCountry?.data?.countryCode;
            getCategory({
              idList: selectedId.join(","),
              level: selectedLevel,
              station: selectedDataInReportLevel,
              countryCodes: countryCode,
              allSelected: allSelected
            });
          }
        } else if (selectedLevel === ComparsionLevel.AreaManager) {
          let allowedArr: Array<Roles> = [
            ...SuperAdminEqvRoles,
            ...CEOEqvRoles,
            Roles.HeadLP,
            Roles.AuditorLP,
            Roles.ClusterHeadofOps
          ];

          if (selectedUnitReportData?.length > 0) {
            let productidList;
            if (allowedArr.includes(user.roles[0])) {
              productidList = selectedUnitReportData?.map((item: any) => {
                return item.employeeId;
              });
            } else {
              productidList = selectedUnitReportData?.map((item: any) => {
                return item.code;
              });
            }
            let countryCode = selectedUnitReportCountry?.countryCode;
            if (productidList?.length > 0) {
              let allSelected = areaManagerSelectAll;
              if (areaManagerSearchText.length >= 3) {
                allSelected = false;
              }
              getCategory({
                idList: productidList.join(","),
                level: selectedLevel,
                station: selectedDataInReportLevel,
                allSelected: allSelected,
                countryCodes: countryCode
              });
            }
          }
        } else if (selectedLevel === ComparsionLevel.Stores) {
          if (selectedUnitReportData?.length > 0) {
            let productidList = selectedUnitReportData?.map((item: any) => {
              return item.code;
            });
            if (productidList?.length > 0) {
              let allSelected = storesSelectAll;
              if (storeSearchText.length >= 3) {
                allSelected = false;
              }
              let countryCode = selectedCountryValue?.countryCode;
              getCategory({
                idList: productidList.join(","),
                level: selectedLevel,
                station: selectedDataInReportLevel,
                allSelected: allSelected,
                countryCodes: countryCode
              });
            }
          }
        } else if (selectedLevel === ComparsionLevel.OpsManager) {
          if (selectedUnitReportData?.length > 0) {
            let productidList = selectedUnitReportData?.map((item: any) => {
              return item.employeeId;
            });
            if (productidList?.length > 0) {
              let allSelected = opsManagerSelectAll;
              if (opsManagerSearchText.length >= 3) {
                allSelected = false;
              }
              let countryCode = selectedUnitReportCountry?.countryCode;
              getCategory({
                idList: productidList.join(","),
                level: selectedLevel,
                station: selectedDataInReportLevel,
                allSelected: allSelected,
                countryCodes: countryCode
              });
            }
          }
        }
      }
      if (selectedReportType === ReportTypeLevel.Detailed) {
        if (
          selectedLevel === ComparsionLevel.Country ||
          selectedLevel === ComparsionLevel.CountryManager ||
          selectedLevel === ComparsionLevel.ClusterHeadOfOps
        ) {
          if (selectedUnitReportCountry) {
            let selectedId; // selected ID
            if (selectedLevel === ComparsionLevel.Country) {
              selectedId = selectedUnitReportCountry?.data?.countryCode;
              if (selectedReportType === ReportTypeLevel.Detailed && selectedId) {
                getCategory({
                  idList: selectedId,
                  level: selectedLevel,
                  station: selectedDataInReportLevel
                });
              }
            } else if (selectedLevel === ComparsionLevel.CountryManager) {
              selectedId = selectedUnitReportCountry?.data?.code;

              if (selectedReportType === ReportTypeLevel.Detailed && selectedId) {
                getCategory({
                  idList: selectedId,
                  level: selectedLevel,
                  station: selectedDataInReportLevel
                });
              }
            } else if (selectedLevel === ComparsionLevel.ClusterHeadOfOps) {
              selectedId = selectedUnitReportCountry?.data?.code;

              if (selectedReportType === ReportTypeLevel.Detailed && selectedId) {
                getCategory({
                  idList: selectedId,
                  level: selectedLevel,
                  station: selectedDataInReportLevel
                });
              }
            }
          }
        } else if (selectedLevel === ComparsionLevel.Channels) {
          if (selectedUnitReportData && selectedReportType === ReportTypeLevel.Detailed) {
            let selectedId = selectedUnitReportData?.code;
            let countryCode = selectedUnitReportCountry?.data?.countryCode;
            getCategory({
              idList: selectedId,
              level: selectedLevel,
              station: selectedDataInReportLevel,
              countryCodes: countryCode
            });
          }
        } else if (selectedLevel === ComparsionLevel.Stores) {
          if (selectedUnitReportData && selectedReportType === ReportTypeLevel.Detailed) {
            let selectedId = selectedUnitReportData?.code;
            let countryCode = selectedUnitReportCountry?.data?.countryCode;
            getCategory({
              idList: selectedId,
              level: selectedLevel,
              station: selectedDataInReportLevel,
              countryCodes: countryCode
            });
          }
        } else if (selectedLevel === ComparsionLevel.OpsManager) {
          if (selectedUnitReportData && selectedReportType === ReportTypeLevel.Detailed) {
            let selectedId = selectedUnitReportData?.code;
            let countryCode = selectedUnitReportCountry?.data?.countryCode;
            getCategory({
              idList: selectedId,
              level: selectedLevel,
              station: selectedDataInReportLevel,
              countryCodes: countryCode
            });
          }
        } else if (selectedLevel === ComparsionLevel.AreaManager) {
          if (selectedUnitReportData && selectedReportType === ReportTypeLevel.Detailed) {
            let selectedId = selectedUnitReportData?.code;
            let countryCode = selectedUnitReportCountry?.data?.countryCode;
            getCategory({
              idList: selectedId,
              level: selectedLevel,
              station: selectedDataInReportLevel,
              countryCodes: countryCode
            });
          }
        }
      }
    }
  }, [
    selectedReportType,
    selectedLevel,
    selectedDataInReportLevel,
    selectedUnitReportCountry,
    selectedUnitReportData
  ]);
  useEffect(() => {
    if (allCountries?.content && allCountries?.currentPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allCountries: allCountries.content
      }));
      //  setAllCountryFetch(true);
      setCountryPage(0);
      setComparisonReportData((prevState) => ({
        ...prevState,
        allCountries: allCountries.content
      }));
    }
  }, [allCountries]);
  useEffect(() => {
    if (allCountryManagers?.content && countryManagerPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allCountryManagers: allCountryManagers.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allCountryManagers: allCountryManagers.content
      }));
    }
    // if (comparisonCountryManagers?.content) {
    //   setComparisonReportData((prevState) => ({
    //     ...prevState,
    //     allCountryManagers: comparisonCountryManagers.content
    //   }));
    // }
  }, [allCountryManagers, comparisonCountryManagers]);

  useEffect(() => {
    if (allClusterHeadOfOps?.content && clusterHeadOfOpsPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allClusterHeadOfOps: allClusterHeadOfOps.content
      }));
    }
    if (allClusterHeadOfOps?.content && clusterHeadOfOpsPage === 0) {
      setComparisonReportData((prevState) => ({
        ...prevState,
        allClusterHeadOfOps: allClusterHeadOfOps.content
      }));
    }
  }, [allClusterHeadOfOps]);
  useEffect(() => {
    if (allOpsManagers?.content && opsManagerPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allOpsManagers: allOpsManagers?.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allOpsManagers: allOpsManagers?.content
      }));
    }
  }, [allOpsManagers]);
  useEffect(() => {
    if (allLpAuditor?.content && lpAuditorPage === 0) {
      // setLpAuditorPage(0);
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allLpAuditor: allLpAuditor?.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allLpAuditor: allLpAuditor?.content
      }));
    }
  }, [allLpAuditor]);
  useEffect(() => {
    if (allAreaManagers?.content && areaManagerPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allAreaManagers: allAreaManagers?.content
      }));
      setComparisonReportData((prevState) => ({
        ...prevState,
        allAreaManagers: allAreaManagers?.content
      }));
    }
    if (comparisonAreaManagers?.content) {
      setComparisonReportData((prevState) => ({
        ...prevState,
        allAreaManagers: comparisonAreaManagers?.content
      }));
    }
  }, [allAreaManagers, comparisonAreaManagers]);

  // useEffect(() => {
  /**
   * NOTE: Commented, as it was a repetitive task,
   * doing already while switching between the report type
   * in handle Reset function
   */
  //   setSelectedDataOption((prev) => ({
  //     ...prev,
  //     monthly: null,
  //     quarterly: null,
  //     yearDate: {
  //       startDate: null,
  //       endDate: null
  //     },
  //     specific: {
  //       startDate: null,
  //       endDate: null
  //     }
  //   }));
  //   setSelectedDateRange(null);
  // }, [selectedReportType]);
  useEffect(() => {
    if (getHighLowChannelsAdminData) {
      if (
        // user?.roles?.includes(Roles.Admin) ||
        user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP)
      ) {
        setAllChannelFetch(true);
        setChannelsPage(0);
        setComparisonReportData((prevState) => ({
          ...prevState,
          allChannels: getHighLowChannelsAdminData?.content
        }));
      }
    } else {
      if (allChannels?.content && allChannels?.currentPage === 0) {
        setDetailedReportData((prevState: any) => ({
          ...prevState,
          allChannels: allChannels.content
        }));
        setChannelsPage(0);
        setComparisonReportData((prevState) => ({
          ...prevState,
          allChannels: allChannels.content
        }));
      }
    }
  }, [allChannels, getHighLowChannelsAdminData]);
  useEffect(() => {
    if (allStores && allStores.content && allStores.currentPage === 0) {
      setDetailedReportData((prevState: any) => ({
        ...prevState,
        allStores: allStores.content
      }));
      setStoresPage(0);
      setComparisonReportData((prevState) => ({
        ...prevState,
        allStores: allStores.content
      }));
    }
    if (
      comparisonStoresAdmin &&
      comparisonStoresAdmin.content &&
      comparisonStoresAdmin.currentPage === 0 &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      setStoresPage(0);
      setComparisonReportData((prevState) => ({
        ...prevState,
        allStores: comparisonStoresAdmin?.content
      }));
    }
  }, [allStores, comparisonStoresAdmin]);

  const handleCountrySelect = (countries: any) => {
    // TODO - dispatch action to get country
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allCountries: countries
    }));
  };
  const handleCountryManagerSelect = (countryManagers: IItem[]) => {
    // TODO - dispatch action to get country managers
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allCountryManagers: countryManagers
    }));
  };

  const handleAreaManagerSelect = (areaManagers: IItem[]) => {
    // TODO - dispatch action to get area managers
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allAreaManagers: areaManagers
    }));
  };
  const handleChannelSelect = (channels: IItem[]) => {
    // TODO - dispatch action to get channels
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allChannels: channels
    }));
    setSelectedUnitReportData(channels);
  };

  const handleStoreSelect = (stores: IItem[]) => {
    // TODO - dispatch action to get stores
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allStores: stores
    }));
  };

  const handleOpsManagerSelect = (opsManagers: IItem[]) => {
    // TODO - dispatch action to get Ops Manager
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allOpsManagers: opsManagers
    }));
  };

  const handleClusterHeadOfOpsSelect = (clusterHeadOfOps: IItem[]) => {
    // TODO - dispatch action to get cluster head of ops
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allClusterHeadOfOps: clusterHeadOfOps
    }));
  };

  const handleLpAuditorSelect = (lpAuditor: IItem[]) => {
    // TODO - dispatch action to get lp auditor
    setSelectedDetailedData((prevState: any) => ({
      ...prevState,
      allLpAuditor: lpAuditor
    }));
  };
  const getSelectedComparisonData = (data: IItem[], isChannel?: boolean, label?: string) => {
    if (data && data[0]?.countryCode !== undefined) {
      setselectedCountryValue({
        data
      });
    }
    if (label === "Select Country") {
      setselectedCountryValue(data);
      if (moduleType === builderLevel.isProdSheet) {
        setSelectedUnitReportData(null);
      }
    }
    if (isChannel) {
      if (label === "Select Channel") {
        setSelectedUnitReportData({
          ...selectedUnitReportData,
          channel: data
        });
      } else if (moduleType === builderLevel.isProdSheet && label === "Select Country") {
        setSelectedUnitReportCountry({
          ...selectedUnitReportCountry,
          data
        });
        setSelectedUnitReportData(null);
      } else {
        setSelectedUnitReportCountry({
          ...selectedUnitReportData,
          data
        });
        if (moduleType === builderLevel.isProdSheet && label !== "Select Country") {
          setSelectedUnitReportData(data);
        }
      }
    } else {
      setSelectedUnitReportData(data);
      setSelectedUnitReportCountry(data);
    }
  };

  useEffect(() => {
    if (selectedDataInReportLevel === DataInReportLevel.ShowByKPIs) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        LPKPIsData: selectedKPI,
        overallScore: false,
        criticalPoints: false,
        isKPI: true,
        isBaking: false,
        isOthers: false,
        categorys: null,
        products: null
      }));
    }
    if (selectedDataInReportLevel === DataInReportLevel.ShowByOverallScore) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        LPKPIsData: null,
        overallScore: true,
        criticalPoints: false,
        isKPI: false,
        isBaking: false,
        isOthers: false,
        categorys: null,
        products: null
      }));
    }
    if (selectedDataInReportLevel === DataInReportLevel.ShowByCriticalPoints) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        LPKPIsData: null,
        overallScore: false,
        criticalPoints: true,
        isKPI: false,
        isBaking: false,
        isOthers: false,
        categorys: null,
        products: null
      }));
    }
    if (selectedDataInReportLevel === DataInReportLevel.Baking) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        LPKPIsData: null,
        overallScore: false,
        criticalPoints: false,
        isKPI: false,
        isBaking: true,
        isOthers: false,
        categorys: selectedCategory,
        products: selectedProduct
      }));
    }
    if (selectedDataInReportLevel === DataInReportLevel.Others) {
      setdataInReportValue((prevState: any) => ({
        ...prevState,
        LPKPIsData: null,
        overallScore: false,
        criticalPoints: false,
        isKPI: false,
        isBaking: false,
        isOthers: true,
        categorys: selectedCategory,
        products: selectedProduct
      }));
    }
  }, [selectedDataInReportLevel, selectedKPI, selectedCategory, selectedProduct]);

  const generateReport = async () => {
    let selectAllValues: ISelectAllValues = {
      storesSelectAll,
      channelSelectAll,
      areaManagerSelectAll,
      opsManagerSelectAll,
      countryManagerSelectAll,
      countrySelectAll,
      clusterHeadOfOpsSelectAll,
      lpAuditorSelectAll
    };
    if (moduleType === builderLevel.isLp) {
      await LocalStorage.setStorage("lpSelectedData", {
        dataInReportValue:
          selectedReportType === ReportTypeLevel.HighLow
            ? { ...dataInReportValue, isKPI: false, overallScore: true }
            : dataInReportValue,
        selectedDetailedData: selectedDetailedData,
        selectedUnitReportData: selectedUnitReportData,
        selectedReportType: selectedReportType,
        selectedLevel: selectedLevel,
        selectedDataOption: selectedDataOption,
        selectedUnitReportCountry: selectedUnitReportCountry,
        selectedCountry: selectedCountryValue,
        selectAllValues,
        lpSelectedQuaterlyDataOption: lpSelectedDataOption.quarterly,
        selectedDateRange: selectedDateRange
      });
      navigation(history, Pathnames.lpOverallReport);
      // navigation(history, Pathnames.builderReports);
    } else if (moduleType === builderLevel.isProdSheet) {
      if (user?.roles?.includes(Roles.StoreAccess)) {
        await LocalStorage.setStorage("psSelectedData", {
          dataInReportValue: dataInReportValue,
          selectedDetailedData: selectedDetailedData,
          selectedReportType: selectedReportType,
          selectedLevel: ComparsionLevel.Stores,
          selectedDataOption: selectedDataOption,
          selectedUnitReportCountry: selectedUnitReportCountry,
          selectedUnitReportData: selectedUnitReportData,
          storeCode: user.storeCode,
          selectedCountry: selectedCountryValue,
          selectAllValues
        });
      } else {
        await LocalStorage.setStorage("psSelectedData", {
          dataInReportValue: dataInReportValue,
          selectedDetailedData: selectedDetailedData,
          selectedReportType: selectedReportType,
          selectedLevel: selectedLevel,
          selectedDataOption: selectedDataOption,
          selectedUnitReportCountry: selectedUnitReportCountry,
          selectedUnitReportData: selectedUnitReportData,
          selectedCountry: selectedCountryValue,
          selectAllValues
        });
      }
      navigation(history, Pathnames.psOverallReport);
    } else {
      if (selectedReportType === ReportTypeLevel.ConsData) {
        // dispatch api call for excel sheet with consolidate dates and kpis data - TODO
      }
      await LocalStorage.setStorage("selectedData", {
        dataInReportValue: dataInReportValue,
        selectedDetailedData: selectedDetailedData,
        selectedUnitReportData: selectedUnitReportData,
        selectedReportType: selectedReportType,
        selectedLevel: selectedLevel,
        selectedDataOption: selectedDataOption,
        selectedUnitReportCountry: selectedUnitReportCountry,
        selectedCountry: selectedCountryValue
      });
      navigation(history, Pathnames.revOverallReport);
    }
  };
  useEffect(() => {
    // when no code is selected in detailed admin
    if (
      selectedReportType === ReportTypeLevel.Detailed &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      if (selectedDetailedData?.allCountries.length === 0) {
        setDetailedReportData((prevState) => ({
          ...prevState,
          allChannels: [],
          allCountryManagers: [],
          allStores: [],
          allAreaManagers: [],
          allClusterHeadOfOps: [],
          allLpAuditor: [],
          allOpsManagers: []
        }));
      }
    }
  }, [selectedDetailedData?.allCountries]);
  useEffect(() => {
    // when no code is selected in detailed admin // TODO - NOT WORKING
    if (
      selectedReportType === ReportTypeLevel.Comparison &&
      // user?.roles?.includes(Roles.Admin) ||
      (user?.roles?.includes(Roles.SuperAdmin) ||
        user?.roles?.includes(Roles.TrainingAndDevLead) ||
        user?.roles?.includes(Roles.HrAndAdminDirector) ||
        user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
        user?.roles?.includes(Roles.TrainingAndDevManager) ||
        user?.roles?.includes(Roles.CEO) ||
        user?.roles?.includes(Roles.COO) ||
        user?.roles?.includes(Roles.HeadLP) ||
        user?.roles?.includes(Roles.AuditorLP))
    ) {
      if (selectedUnitReportCountry?.length === 0) {
        setComparisonReportData((prevState) => ({
          ...prevState,
          allChannels: null
        }));
        setSelectedUnitReportData({
          ...selectedUnitReportData,
          channel: null
        });
      }
    }
  }, [selectedUnitReportCountry]);

  const handleDetailed = (title: string, searchKey: string) => {
    setReqSearchDetailed({ ...reqSearchDetailed, [title]: searchKey });
  };

  const handleReset = (isReportTypeSwitch?: boolean) => {
    setDetailedReportData((prevState) => ({
      ...prevState,
      allChannels: [],
      allStores: [],
      allCountryManagers: [],
      allAreaManagers: [],
      allClusterHeadOfOps: [],
      allLpAuditor: [],
      allOpsManagers: []
    }));
    if (isReportTypeSwitch) {
      setCountrySelectAll(false);
      setClusterHeadOfOpsSelectAll(false);
      setCountryManagerSelectAll(false);
      setChannelSelectAll(false);
      setOpsManagerSelectAll(false);
      setAreaManagerSelectAll(false);
      setLpAuditorSelectAll(false);
      setStoresSelectAll(false);
      setSelectedLevel(
        user?.roles?.includes(Roles.SuperAdmin) ||
          user?.roles?.includes(Roles.TrainingAndDevLead) ||
          user?.roles?.includes(Roles.ProductionAndDevExecutive) ||
          user?.roles?.includes(Roles.HrAndAdminDirector) ||
          user?.roles?.includes(Roles.TrainingAndDevManager) ||
          // user?.roles?.includes(Roles.Admin) ||
          user?.roles?.includes(Roles.HeadLP) ||
          user?.roles?.includes(Roles.AuditorLP) ||
          user?.roles?.includes(Roles.CEO) ||
          user?.roles?.includes(Roles.COO)
          ? ComparsionLevel.Country
          : user?.roles?.includes(Roles.AreaManager) || user?.roles?.includes(Roles.AreaTrainer)
          ? ComparsionLevel.Channels
          : (user?.roles?.includes(Roles.CountryManager) ||
              user?.roles?.includes(Roles.InternalAuditor) ||
              user?.roles?.includes(Roles.OperationManager) ||
              user?.roles?.includes(Roles.SeniorOpsManager) ||
              user?.roles?.includes(Roles.ClusterHeadofOps)) &&
            ComparsionLevel.AreaManager
      );
      setSelectedDataOption((prev) => ({
        ...prev,
        monthly: null,
        quarterly: null,
        yearDate: {
          startDate: null,
          endDate: null
        },
        specific: {
          startDate: null,
          endDate: null
        }
      }));
      setSelectedDateRange(null);
      setSelectedKPI([]);
    }
    setComparisonReportData(null);
    setSelectedUnitReportData(null);
    setSelectedUnitReportCountry(null);
    resetReportBuilder();
    setChannelsPage(0);
    setCountryPage(0);
    setStoresPage(0);
    setCountryManagerPage(0);
    setAreaManagerPage(0);
    setOpsManagerPage(0);
  };
  const checkDisable = (): boolean => {
    let val = true;

    function isDateRangeNotEmpty(): boolean {
      if (
        selectedDataOption.monthly !== null ||
        selectedDataOption.quarterly !== null ||
        lpSelectedDataOption.quarterly.length > 0 ||
        (selectedDataOption.specific.startDate !== null &&
          selectedDataOption.specific.endDate !== null) ||
        (selectedDataOption.yearDate.startDate !== null &&
          selectedDataOption.yearDate.endDate !== null) ||
        (selectedReportType === ReportTypeLevel.ConsData &&
          consolidatedDate?.startDate !== null &&
          consolidatedDate?.endDate !== null)
      )
        return true;
      return false;
    }
    function isPSValidDataInReport(): boolean {
      if (selectedReportType === ReportTypeLevel.Comparison)
        return selectedCategory && selectedCategory?.code && selectedCategory?.code?.length > 0;
      if (selectedProduct && selectedProduct.length > 0) return true;
      return false;
    }
    function isValidDataInReport(): boolean {
      if (moduleType === builderLevel.isProdSheet) return isPSValidDataInReport();
      if (selectedDataInReportLevel === DataInReportLevel.ShowByKPIs) {
        if (
          dataInReportValue &&
          dataInReportValue.LPKPIsData &&
          dataInReportValue.LPKPIsData.length > 0
        )
          return true;
        return false;
      }
      return true;
    }

    function isPSValidLevelofReport(): boolean {
      switch (selectedLevel) {
        case ComparsionLevel.Country:
        case ComparsionLevel.CountryManager:
        case ComparsionLevel.ClusterHeadOfOps:
          if (selectedReportType === ReportTypeLevel.Detailed)
            return (
              selectedUnitReportCountry &&
              selectedUnitReportCountry.data &&
              selectedUnitReportCountry.data.id &&
              selectedUnitReportCountry.data.id?.length !== 0
            );
          //Comparison
          return selectedUnitReportCountry && selectedUnitReportCountry?.length !== 0;
        case ComparsionLevel.AreaManager:
        case ComparsionLevel.Stores:
        case ComparsionLevel.OpsManager:
        case ComparsionLevel.Channels:
          if (selectedReportType === ReportTypeLevel.Detailed)
            return (
              selectedUnitReportData &&
              selectedUnitReportData.id &&
              selectedUnitReportData.id?.length !== 0
            );
          return selectedUnitReportData && selectedUnitReportData?.length > 0;
        default:
          break;
      }
    }

    function isValidLevelofReport(): boolean {
      if (moduleType === builderLevel.isProdSheet) return isPSValidLevelofReport();
      switch (selectedLevel) {
        case ComparsionLevel.Country:
          return selectedUnitReportCountry && selectedUnitReportCountry?.length !== 0;
        case ComparsionLevel.Channels:
          if (selectedReportType === ReportTypeLevel.HighLow)
            return selectedUnitReportData?.length !== 0;
          return (
            //   selectedUnitReportData &&
            //   selectedUnitReportData.channel &&
            //   selectedUnitReportData.channel.id &&
            //   selectedUnitReportData.channel.id?.length !== 0
            // TODO:Ask if the value saved here is at proper state or not
            selectedDetailedData &&
            selectedDetailedData.allChannels &&
            selectedDetailedData.allChannels.length !== 0
          );
        case ComparsionLevel.CountryManager:
        case ComparsionLevel.AreaManager:
        case ComparsionLevel.Stores:
        case ComparsionLevel.OpsManager:
        case ComparsionLevel.ClusterHeadOfOps:
        case ComparsionLevel.LpAuditor:
          if (selectedReportType === ReportTypeLevel.Comparison)
            return (
              selectedUnitReportData?.length !== undefined && selectedUnitReportData?.length !== 0
            );
          if (selectedReportType === ReportTypeLevel.AreaOfImprovement) {
            if (
              selectedLevel === ComparsionLevel.Stores ||
              selectedLevel === ComparsionLevel.AreaManager ||
              selectedLevel === ComparsionLevel.OpsManager
            ) {
              return selectedUnitReportData && selectedUnitReportData?.length !== 0;
            } else if (selectedLevel === ComparsionLevel.LpAuditor) {
              return (
                selectedDetailedData?.allLpAuditor &&
                selectedDetailedData?.allLpAuditor?.length !== 0
              );
            } else {
              return selectedUnitReportCountry && selectedUnitReportCountry?.length !== 0;
            }
          }

          return selectedUnitReportData?.length !== 0;
        case ComparsionLevel.LpAuditor:
          return (
            comparisonReportData &&
            comparisonReportData?.allLpAuditor !== undefined &&
            comparisonReportData?.allLpAuditor?.length !== 0
          );
        default:
          break;
      }
    }

    function isDetailedValidLevelofReport(): boolean {
      const LPSpecialRoles = [Roles.SeniorOpsManager, Roles.OperationManager, Roles.AreaManager];
      let currRole = user?.roles[0];
      if (moduleType === builderLevel.isProdSheet) return isPSValidLevelofReport();
      if (moduleType === builderLevel.isLp && LPSpecialRoles.includes(currRole)) {
        return (
          selectedDetailedData &&
          (selectedDetailedData?.allChannels?.length !== 0 ||
            selectedDetailedData?.allStores?.length !== 0 ||
            (selectedDetailedData?.allAreaManagers !== undefined &&
              selectedDetailedData?.allAreaManagers?.length !== 0))
        );
      }
      return selectedDetailedData?.allCountries?.length !== 0;
    }
    if (moduleType === builderLevel.isProdSheet && user?.roles?.includes(Roles.StoreAccess)) {
      val = isDateRangeNotEmpty() && isValidDataInReport() ? false : true;
    } else {
      switch (selectedReportType) {
        case ReportTypeLevel.Detailed:
          val =
            isDateRangeNotEmpty() && isDetailedValidLevelofReport() && isValidDataInReport()
              ? false
              : true;
          break;
        case ReportTypeLevel.Comparison:
          val =
            isDateRangeNotEmpty() && isValidLevelofReport() && isValidDataInReport() ? false : true;
          break;
        case ReportTypeLevel.HighLow:
          val = isDateRangeNotEmpty() && isValidLevelofReport() ? false : true;
          break;
        case ReportTypeLevel.Grading:
          val = isDateRangeNotEmpty() && isValidLevelofReport() ? false : true;
          break;
        case ReportTypeLevel.ConsData:
          val = isDateRangeNotEmpty() && isValidDataInReport() ? false : true;
          break;
        case ReportTypeLevel.AreaOfImprovement:
          val = isDateRangeNotEmpty() && isValidLevelofReport() ? false : true;
          break;
        default:
          break;
      }
    }
    return val;
  };
  //fetching & initializing the previous data
  //   useEffect(()=>{
  // if(allStores?.content && selectedLevel === ComparsionLevel.Stores && user.roles.includes(Roles.CountryManager)){
  //   setComparisonReportData((prevState) => ({
  //     ...prevState,
  //     allStores: null
  //   }));
  // }
  //   },[allStores])

  useEffect(() => {
    function reportTypeTimeFrameHandler(
      recvSelectedReportType: ReportTypeLevel,
      recvSelectedDataOption: ISelectedDataOption
    ) {
      setSelectedReportType(recvSelectedReportType);
      setSelectedDataOption(recvSelectedDataOption);
      //setting the Date Range radio button
      if (recvSelectedDataOption.monthly !== null) {
        setSelectedDateRange(DateRangeTypes.Monthly);
      } else if (recvSelectedDataOption.quarterly !== null) {
        setSelectedDateRange(DateRangeTypes.Quarterly);
      } else if (recvSelectedDataOption.specific.startDate !== null) {
        setSelectedDateRange(DateRangeTypes.SpecificDate);
      } else if (recvSelectedDataOption.yearDate.startDate !== null) {
        recvSelectedReportType === ReportTypeLevel.Grading
          ? setSelectedDateRange(DateRangeTypes.Yearly)
          : setSelectedDateRange(DateRangeTypes.YearDate);
      } else {
        setSelectedDateRange(DateRangeTypes.Yearly);
      }
    }

    function levelOfReportsHandler(data: IReportBuilderFinalData) {
      const {
        selectedReportType: recvSelectedReportType,
        selectedLevel: recvSelectedLevel,
        selectedDetailedData: recvSelectedDetailedData,
        selectedUnitReportCountry: recvSelectedUnitReportCountry,
        selectedUnitReportData: recvSelectedUnitReportData,
        selectedCountry: recvSelectedCountry,
        selectAllValues: recvSelectAllValues
      } = data;
      setSelectedUnitReportCountry(recvSelectedUnitReportCountry);
      setSelectedLevel(recvSelectedLevel);
      if (recvSelectedReportType === ReportTypeLevel.Detailed) {
        setPrevSelectedDetailedData({
          ...recvSelectedDetailedData,
          selectAllValues: recvSelectAllValues
        });
        setPrevSelectedLORData(null);
      } else {
        setPrevSelectedDetailedData(null);
        setPrevSelectedLORData({
          selectedLevel: recvSelectedLevel,
          selectedUnitReportCountry: recvSelectedUnitReportCountry,
          selectedUnitReportData: recvSelectedUnitReportData,
          selectedCountry: recvSelectedCountry,
          selectAllValues: recvSelectAllValues
        });
      }
    }

    function dataInReportHandler(recvDataInReportValue: ILPDataInReportData) {
      setdataInReportValue(recvDataInReportValue);
      if (recvDataInReportValue.criticalPoints) {
        setSelectedDataInReportLevel(DataInReportLevel.ShowByCriticalPoints);
      } else if (recvDataInReportValue.overallScore) {
        setSelectedDataInReportLevel(DataInReportLevel.ShowByOverallScore);
      } else if (recvDataInReportValue.isBaking) {
        setSelectedDataInReportLevel(DataInReportLevel.Baking);
      } else if (recvDataInReportValue.isOthers) {
        setSelectedDataInReportLevel(DataInReportLevel.Others);
      } else if (recvDataInReportValue.isKPI) {
        setSelectedKPI(recvDataInReportValue.LPKPIsData);
        setSelectedDataInReportLevel(DataInReportLevel.ShowByKPIs);
      }
    }

    const getDataFromLocalStorage = async () => {
      await LocalStorage.getStorage(ReportBuilderStorageKeys[getComponent()] ?? null)
        .then((data: IReportBuilderFinalData) => {
          if (data) {
            reportTypeTimeFrameHandler(data.selectedReportType, data.selectedDataOption);
            levelOfReportsHandler(data);
            if (
              !(
                moduleType === builderLevel.isLp &&
                data.selectedReportType === ReportTypeLevel.Grading
              )
            ) {
              dataInReportHandler(data.dataInReportValue);
            }
          }
        })
        .catch((_err) => {
          // Do Something
        });
    };
    // NOTE: Commented as re-mapping functionality not needed as of now
    // getDataFromLocalStorage();
  }, []);

  return (
    <>
      <View style={builderTemplateStyles.container}>
        <View>
          <ReportType
            selectedReportType={selectedReportType}
            setReportType={setSelectedReportType}
            selectedOption={selectedDateRange}
            setSelectedOption={setSelectedDateRange}
            selected={selectedDataOption}
            setSelected={setSelectedDataOption}
            lpSelectedDataOption={lpSelectedDataOption}
            setLpSelectedDataOption={setLpSelectedDataOption}
            translate={t}
            handleReset={() => handleReset(true)}
            moduleType={moduleType}
            consolidatedDate={consolidatedDate}
            setConsolidatedDates={setConsolidatedDates}
            user={user}
          />
        </View>
        <View>
          <LevelOfReports
            translate={t}
            user={user}
            loading={loading}
            reportType={
              selectedReportType === ReportTypeLevel.Detailed
                ? ReportTypeLevel.Detailed
                : selectedReportType === ReportTypeLevel.Comparison
                ? ReportTypeLevel.Comparison
                : selectedReportType === ReportTypeLevel.HighLow
                ? ReportTypeLevel.HighLow
                : selectedReportType === ReportTypeLevel.Grading
                ? ReportTypeLevel.Grading
                : selectedReportType === ReportTypeLevel.ConsData
                ? ReportTypeLevel.ConsData
                : selectedReportType === ReportTypeLevel.AreaOfImprovement
                ? ReportTypeLevel.AreaOfImprovement
                : null
            }
            reportData={
              selectedReportType === ReportTypeLevel.Detailed &&
              moduleType !== builderLevel.isProdSheet
                ? detailedReportData
                : selectedReportType === ReportTypeLevel.Comparison ||
                  selectedReportType === ReportTypeLevel.HighLow ||
                  selectedReportType === ReportTypeLevel.Grading
                ? comparisonReportData
                : selectedReportType === ReportTypeLevel.AreaOfImprovement
                ? detailedReportData
                : moduleType === builderLevel.isProdSheet &&
                  selectedReportType === ReportTypeLevel.Detailed &&
                  (selectedLevel === ComparsionLevel.Country
                    ? detailedReportData
                    : comparisonReportData)
            }
            handleCountrySelect={handleCountrySelect}
            handleCountryManagerSelect={handleCountryManagerSelect}
            handleAreaManagerSelect={handleAreaManagerSelect}
            handleChannelSelect={handleChannelSelect}
            handleStoreSelect={handleStoreSelect}
            handleOpsManagerSelect={handleOpsManagerSelect}
            handleClusterHeadOfOpsSelect={handleClusterHeadOfOpsSelect}
            handleLpAuditorSelect={handleLpAuditorSelect}
            setCountries={countryFlatlist.fetchMore}
            setChannel={channelFlatlist.fetchMore}
            setAreaManager={areaManagerFlatlist.fetchMore}
            setComparisionAreaManager={comparisionAreaManagerFlatlist.fetchMore}
            setCountryManager={countryManagerFlatlist.fetchMore} // clus
            setComparisionCountryManager={comparisionCountryManagerFlatlist.fetchMore}
            setStore={storeFlatlist.fetchMore}
            setOpsManager={opsManagerFlatList.fetchMore}
            setClusterHeadOfOps={clusterHeadOfOpsFlatlist.fetchMore}
            setLpAuditor={lpAuditorFlatlist.fetchMore}
            selectedDetailedData={selectedDetailedData}
            detailedReportPermissions={detailedReportPermissions}
            getSelectedComparisonData={getSelectedComparisonData}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
            selectedUnitReportData={selectedUnitReportData}
            selectedUnitReportCountry={selectedUnitReportCountry}
            setSelectedUnitReportCountry={setSelectedUnitReportCountry}
            setComparisonStoreFlatList={storeFlatlist.fetchMore}
            handleReset={handleReset}
            setCountrySearchText={setCountrySearchText}
            setChannelSearchText={setChannelSearchText}
            setStoreSearchText={setStoreSearchText}
            setAreaManagerSearchText={setAreaManagerSearchText}
            setOpsManagerSearchText={setOpsManagerSearchText}
            setCountryManagerSearchText={setCountryManagerSearchText}
            setClusterHeadOfOpsSearchText={setClusterHeadOfOPsSearchText}
            setLpAuditorSearchText={setLpAuditorSearchText}
            setStoresSelectAll={setStoresSelectAll}
            setAreaManagerSelectAll={setAreaManagerSelectAll}
            setCountryManagerSelectAll={setCountryManagerSelectAll}
            setChannelSelectAll={setChannelSelectAll}
            setCountrySelectAll={setCountrySelectAll}
            selectedCountryValue={selectedCountryValue}
            setOpsManagerSelectAll={setOpsManagerSelectAll}
            setClusterHeadOfOpsSelectAll={setClusterHeadOfOpsSelectAll}
            setLpAuditorSelectAll={setLpAuditorSelectAll}
            storesSelectAll={storesSelectAll}
            areaManagerSelectAll={areaManagerSelectAll}
            countryManagerSelectAll={countryManagerSelectAll}
            channelSelectAll={channelSelectAll}
            countrySelectAll={countrySelectAll}
            opsManagerSelectAll={opsManagerSelectAll}
            clusterHeadOfOpsSelectAll={clusterHeadOfOpsSelectAll}
            lpAuditorSelectAll={lpAuditorSelectAll}
            moduleType={moduleType}
            prevSelectedDetailedData={prevSelectedDetailedData}
            setPrevSelectedDetailedData={setPrevSelectedDetailedData}
            prevSelectedLORData={prevSelectedLORData}
            setPrevSelectedLORData={setPrevSelectedLORData}
            handleResetSearch={isResetRequired}
            reqSearch={reqSearch}
            setReqSearch={setReqSearch}
            reqSearchDetailed={reqSearchDetailed}
            setReqSearchDetailed={(title: string, searchKey: string) =>
              handleDetailed(title, searchKey)
            }
          />
        </View>
        {selectedReportType !== ReportTypeLevel.Grading &&
          selectedReportType !== ReportTypeLevel.HighLow &&
          selectedReportType !== ReportTypeLevel.AreaOfImprovement && (
            <View style={builderTemplateStyles.margin}>
              <DataInReport
                translate={t}
                selectedLevel={selectedDataInReportLevel}
                setSelectedReportLevel={setSelectedDataInReportLevel}
                reportData={{ overallScore: null, LPKPIsData: allKPIData }}
                selectedKPI={selectedKPI}
                selectedReportType={selectedReportType}
                handleSelectedValues={(vals: IItem[]) => {
                  setSelectedKPI(vals);
                }}
                handleCategorySelect={(vals: IItem[]) => {
                  setSelectedCategory(vals);
                }}
                handleProductSelect={(vals: IItem[]) => {
                  setSelectedProduct(vals);
                }}
                prodSheetData={{ allCategory: categoryData, allProducts: productsData }}
                moduleType={moduleType}
                user={user}
              />
            </View>
          )}
        <Divider dividerStyle={builderTemplateStyles.dividerStyle} />

        <View style={builderTemplateStyles.textContainer}>
          <Text textSize={TextSize.ExtraRegular} fontWeight={FontWeight.Regular} testId="text">
            {/* {moduleType === builderLevel.isLp ? null : t("reportNote")} */}
          </Text>
          <Button
            type={ButtonType.Primary}
            title={translate("Generate Report")}
            buttonStyles={builderTemplateStyles.generateButton}
            onPress={generateReport}
            disabled={checkDisable()}
          />
        </View>
      </View>
    </>
  );
}
const mapStateToProps = (state: IState) => {
  return {
    loading: reportsBuilderSelectors.reportsBuilderLoading(state),
    error: reportsBuilderSelectors.reportsBuilderError(state),
    allCountries: reportsBuilderSelectors.getAllCountries(state),
    allAreaManagers: reportsBuilderSelectors.getAllAreaManagers(state),
    allOpsManagers: reportsBuilderSelectors.getAllOpsManagers(state),
    allCountryManagers: reportsBuilderSelectors.getAllCountryManagers(state),
    allClusterHeadOfOps: reportsBuilderSelectors.getAllClusterHeadOfOps(state),
    allLpAuditor: reportsBuilderSelectors.getAllLpAuditor(state),
    allChannels: reportsBuilderSelectors.getAllChannels(state),
    allStores: reportsBuilderSelectors.getAllStores(state),
    comparisonCountryManagers: reportsBuilderSelectors.getComparisonCountryManagerAdmin(state),
    comparisonAreaManagers: reportsBuilderSelectors.getComparisonAreaManagerAdmin(state),
    comparisonStoresAdmin: reportsBuilderSelectors.getComparisonStoresAdmin(state),
    getHighLowChannelsAdminData: reportsBuilderSelectors.getHighLowChannelsAdmin(state),
    allKPIData: reportsBuilderSelectors.getKPIData(state),
    allCategory: reportsBuilderSelectors.getCategoryData(state),
    allProducts: reportsBuilderSelectors.getProductData(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...LpReportsActions, ...ReportsBuilderActions }, dispatch) };
};
export default connect(mapStateToProps, mapDispatchToProps)(BuilderLayout);
