import React from "react";
import { ReactElement } from "react-markdown";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

interface IProps {
  children?: ReactElement;
  width?: number | string;
  height?: number | string;
  shape?: SHAPE;
  fromColor?: string;
  toColor?: string;
  radius?: number;
  xPosition?: number;
  yPosition?: number;
  startOpacity?: number;
  stopOpacity?: number;
  containerStyle?: StyleProp<ViewStyle>;
}

const GradientBackground = (props: IProps) => {
  const {
    children,
    width = "100%",
    height = "100%",
    shape = "RECTANGLE",
    fromColor = "#C8102E",
    toColor = "#640817",
    radius = parseInt(width.toString()) / 2,
    xPosition = parseInt(width.toString()) / 2,
    yPosition = parseInt(height.toString()) / 2,
    startOpacity = 1,
    stopOpacity = 1,
    containerStyle
  } = props;
  return <View style={[containerStyle]}>{children && children}</View>;
};

export default GradientBackground;
