import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "./actionTypes";

const getAssessmentQuestions = (data: {
  moduleId: string;
  employeeId: string;
}): IFluxStandardAction<{ moduleId: string; employeeId: string }> => {
  return {
    type: UserLMS.GET_THEORITICAL_ASSESSMENTS.LOADING,
    payload: data
  };
};

const getAssessmentQuestionsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_THEORITICAL_ASSESSMENTS.SUCCESS,
    payload: data
  };
};

const getAssessmentQuestionsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_THEORITICAL_ASSESSMENTS.ERROR,
    payload: error
  };
};

const submitAssessment = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.SUBMIT_ASSESSMENT.LOADING,
    payload: data
  };
};

const submitAssessmentSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.SUBMIT_ASSESSMENT.SUCCESS,
    payload: data
  };
};

const submitAssessmentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.SUBMIT_ASSESSMENT.ERROR,
    payload: error
  };
};

const exitAssessment = (data: {
  moduleId: string;
  employeeId: string;
}): IFluxStandardAction<{ moduleId: string; employeeId: string }> => {
  return {
    type: UserLMS.EXIT_ASSESSMENT.LOADING,
    payload: data
  };
};

const exitAssessmentSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.EXIT_ASSESSMENT.SUCCESS,
    payload: data
  };
};

const exitAssessmentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.EXIT_ASSESSMENT.ERROR,
    payload: error
  };
};

const resetSubmitAssessment = () => {
  return {
    type: UserLMS.RESET_SUBMIT_ASSESSMENT.LOADING
  };
};
const resetAssessmentError = () => {
  return {
    type: UserLMS.RESET_ASSESMENT_ERROR.LOADING
  };
};

const getAssessmentResult = (data: any) => {
  return {
    type: UserLMS.GET_ASSESSMENT_RESULT.LOADING,
    payload: data
  };
};

const getAssessmentResultSuccess = (data: any) => {
  return {
    type: UserLMS.GET_ASSESSMENT_RESULT.SUCCESS,
    payload: data
  };
};

const getAssessmentResultError = (error: Error) => {
  return {
    type: UserLMS.GET_ASSESSMENT_RESULT.ERROR,
    payload: error
  };
};
const resetAssessmentData = () => {
  return {
    type: UserLMS.RESET_ASSESSMENT_DATA.LOADING
  };
};
const resetGetAssessmentQuestions = () => {
  return {
    type: UserLMS.RESET_GET_ASSESSMENT_ERROR
  };
};

const deleteAssessment = (data: {
  moduleId: string;
  employeeId: string;
}): IFluxStandardAction<{ moduleId: string; employeeId: string }> => {
  return {
    type: UserLMS.DELETE_ASSESSMENT.LOADING,
    payload: data
  };
};

const deleteAssessmentSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.DELETE_ASSESSMENT.SUCCESS,
    payload: data
  };
};

const deleteAssessmentError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.DELETE_ASSESSMENT.ERROR,
    payload: error
  };
};

export const AssessmentActions = {
  getAssessmentQuestions,
  getAssessmentQuestionsSuccess,
  getAssessmentQuestionsError,
  submitAssessment,
  submitAssessmentSuccess,
  submitAssessmentError,
  exitAssessment,
  exitAssessmentSuccess,
  exitAssessmentError,
  resetSubmitAssessment,
  resetAssessmentError,
  getAssessmentResult,
  getAssessmentResultSuccess,
  getAssessmentResultError,
  resetGetAssessmentQuestions,
  deleteAssessment,
  deleteAssessmentSuccess,
  deleteAssessmentError,
  resetAssessmentData
};
