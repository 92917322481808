import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import React, { useState } from "react";
import { TouchableOpacity } from "react-native";
import * as ImagePicker from "react-native-image-picker";

interface IProps {
  children: React.ReactElement;
  sendFiles: Function;
}
const CameraUpload = (props: IProps) => {
  const { children, sendFiles } = props;
  const [responseCamera, setResponseCamera] = useState(null);
  const openCameraWithPermission = async () => {
    try {
      ImagePicker.launchCamera(
        {
          mediaType: "photo",
          includeBase64: false,
          maxHeight: 200,
          maxWidth: 200,
          saveToPhotos: true
        },
        (response) => {
          let cameraRes = { ...response };
          if (
            PlatformUtils.isIOS() &&
            (response?.fileName?.endsWith(".heic") || response?.fileName?.endsWith(".HEIC"))
          ) {
            response.fileName = `${response.fileName.split(".")[0]}.jpg`;
          }
          setResponseCamera(cameraRes);
          if (!response?.errorCode) {
            sendFiles(cameraRes);
          }
        }
      );
    } catch (err) {
      console.warn(err);
    }
  };

  return <TouchableOpacity onPress={() => openCameraWithPermission()}>{children}</TouchableOpacity>;
};

export default CameraUpload;
