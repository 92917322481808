import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { HOME } from "@timhortons/common/src/redux/home/actions/actionTypes";

const getHomePageVideos = (data: any): IFluxStandardAction<any> => {
  return {
    type: HOME.GET_HOME_PAGE_VIDEOS.LOADING,
    payload: data
  };
};

const getHomePageVideosSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: HOME.GET_HOME_PAGE_VIDEOS.SUCCESS,
    payload: data
  };
};

const getHomePageVideosError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: HOME.GET_HOME_PAGE_VIDEOS.ERROR,
    payload: error
  };
};

export const HomeActions = {
  getHomePageVideos,
  getHomePageVideosSuccess,
  getHomePageVideosError
};
