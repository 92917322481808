import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import {
  LMSGetProgressBarStatusModel,
  LMSSummaryDataModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { TrainingTabsList } from "@timhortons/web/src/modules/manageLms/components/dashboard/components/assignedLearning/organisms/assignedLearningDataDashboard";
import { Status as ProgressBarStatus } from "@timhortons/web/src/modules/manageLms/organisms/assignedLearning/manageLmsProgressBar";

export enum ModuleStatus {
  uploaded = "UPLOADED",
  skipped = "SKIPPED"
}

const progressBarModules = [
  { id: 1, value: Pathnames.assignedLearningCreateModule, label: "modules" },
  { id: 2, value: Pathnames.assignedLearningCategories, label: "categories" },
  { id: 3, value: Pathnames.assignedLearningPts, label: "pts" },
  { id: 4, value: Pathnames.assignedLearningAssessments, label: "assessments" },
  { id: 5, value: Pathnames.assignedLearninCertifications, label: "certifications" },
  { id: 6, value: Pathnames.assignedLearningFeedback, label: "feedback" },
  { id: 7, value: Pathnames.assignedLearningSummary, label: "summary" }
];

export interface ISummaryDescription {
  id: string;
  descTitle: string;
  descIcon: IconNames;
  value: string | number;
  valueArr?: string[];
}
export interface ISummaryData {
  id: number;
  title: string;
  path: string;
  description: ISummaryDescription[];
}

export const getSummaryFormmattedData = (responseData: LMSSummaryDataModel) => {
  return [
    {
      id: 1,
      title: "module",
      path: Pathnames.assignedLearningCreateModule,
      description: [
        {
          id: "1a",
          descTitle: "moduleTitle",
          descIcon: IconNames.blankDoc,
          value: responseData.moduleTitle
        },
        {
          id: "1b",
          descTitle: "moduleType",
          descIcon: IconNames.typeIcon,
          value: responseData.moduleType
        },
        {
          id: "1c",
          descTitle: "level",
          descIcon: IconNames.categoriesList,
          value: "",
          valueArr: responseData.moduleLevel
        },
        {
          id: "1d",
          descTitle: "thumbnail",
          descIcon: IconNames.thumbnail,
          value: responseData.moduleThumbnail
        }
      ]
    },
    {
      id: 2,
      title: "categories",
      path: Pathnames.assignedLearningCategories,
      description: [
        {
          id: "2a",
          descTitle: "categories",
          descIcon: IconNames.smallHamburger,
          value: responseData.categories
        },
        {
          id: "2b",
          descTitle: "videos",
          descIcon: IconNames.play,
          value: responseData.videos
        },
        {
          id: "2c",
          descTitle: "quizzes",
          descIcon: IconNames.docIcon,
          value: responseData.quizzes
        },
        {
          id: "2d",
          descTitle: "totalDuration",
          descIcon: IconNames.simpleClock,
          value: `${Math.floor(responseData.totalDuration / 3600)} hrs`
        }
      ]
    },
    {
      id: 3,
      title: "Positional Training System",
      path: Pathnames.assignedLearningPts,
      description: [
        {
          id: "3a",
          descTitle: "topics",
          descIcon: IconNames.smallHamburger,
          value: responseData.topics
        },
        {
          id: "3b",
          descTitle: "checklists",
          descIcon: IconNames.checkList,
          value: responseData.checkLists
        }
      ]
    },
    {
      id: 4,
      title: "assessments",
      path: Pathnames.assignedLearningAssessments,
      description: [
        {
          id: "4a",
          descTitle: "theoretical",
          descIcon: IconNames.editDoc,
          value: responseData.theoretical
        },
        {
          id: "4b",
          descTitle: "practical",
          descIcon: IconNames.practicalJar,
          value: responseData.practical
        }
      ]
    },
    {
      id: 5,
      title: "certifications",
      path: Pathnames.assignedLearninCertifications,
      description: [
        {
          id: "5a",
          descTitle: "certificate",
          descIcon: IconNames.certificate,
          value: responseData.certificate
        }
      ]
    },
    {
      id: 6,
      title: "feedback",
      path: Pathnames.assignedLearningFeedback,
      description: [
        {
          id: "6a",
          descTitle: "feedback",
          descIcon: IconNames.feedbackFace,
          value: responseData.feedback
        }
      ]
    }
  ];
};

export const postProgressBarUpdateData = (
  updateStepName: string,
  isSpecialAssessment?: boolean
): LMSGetProgressBarStatusModel => {
  let targetIndex = progressBarModules.findIndex((item) => item.value === updateStepName);
  let data: any = {};
  progressBarModules.forEach(
    (item: { id: number; label: string; value: string }, index: number) => {
      if (index <= targetIndex) {
        return Object.assign(data, { [item.label]: ProgressBarStatus.COMPLETED });
      } else if (index === targetIndex + 1 && !isSpecialAssessment) {
        return Object.assign(data, { [item.label]: ProgressBarStatus.IN_PROGRESS });
      } else if (index === targetIndex + 1 && isSpecialAssessment) {
        return Object.assign(data, { [item.label]: ProgressBarStatus.COMPLETED });
      } else if (index === targetIndex + 2 && isSpecialAssessment) {
        return Object.assign(data, { [item.label]: ProgressBarStatus.IN_PROGRESS });
      } else {
        return Object.assign(data, { [item.label]: ProgressBarStatus.NOT_STARTED });
      }
    }
  );
  return data;
};
