import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  getBearerToken,
  getRefreshToken,
  setNewBearerToken,
  getRefreshTokenUrl,
  forceLogout
} from "@timhortons/common/src/network/authHelper";

let publicUrls = ["/iam/v1/authenticate", "/store/v1/authenticate"];
export const bearerTokenReqInterceptor = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(async function (config) {
    try {
      config.headers["Accept-Language"] = "en";
      if (publicUrls.includes(config.url)) {
        return config;
      }
      const token = await getBearerToken();
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  });
  return axiosInstance;
};

export const refreshTokenInterceptor = (
  axiosInstance: AxiosInstance,
  baseURL: string
): AxiosInstance => {
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async function (error) {
      // replace with actual end-point 11/2/2021
      const originalRequest = error.config;
      if (originalRequest?.url === (await getRefreshTokenUrl(baseURL))) {
        return Promise.reject(error);
      }
      // replace with actual error code 11/2/2021
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        try {
          if (!publicUrls.includes(originalRequest.url)) {
            originalRequest._retry = true;
            const refreshToken = await getRefreshToken();
            const header = { Authorization: `Bearer ${refreshToken}` };
            const apiUrl = await getRefreshTokenUrl(baseURL);
            const apiRes = await (await axios.get(apiUrl, { headers: header })).data;
            await setNewBearerToken(apiRes.data.accessToken, apiRes.data.refreshToken);
            originalRequest.headers.Authorization = `Bearer ${apiRes.data.accessToken}`;
            return axiosInstance(originalRequest);
          }
        } catch (error) {
          await forceLogout();
        }
      } else if (error.response && error.response.status === 401 && originalRequest._retry) {
        await forceLogout();
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
