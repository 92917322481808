import Rollbar from "rollbar";
import { ConfigHelper } from "@timhortons/common/src/utils/loadEnv.util";
import { Client, Configuration } from "rollbar-react-native";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import Config from "react-native-config";
class RollBarServiceWeb {
  public rollbar: Rollbar = new Rollbar({
    accessToken: ConfigHelper.getRollbarKey(),
    // captureUncaught: ConfigHelper.getNodeEnv() === "production",
    captureUncaught: true,
    // captureUnhandledRejections: ConfigHelper.getNodeEnv() === "production",
    captureUnhandledRejections: true,
    environment: ConfigHelper.getNodeEnv(),
    captureIp: true
  });
}

class RollBarServiceNative {
  createInstance() {
    let rollBar = null;
    if (!PlatformUtils.isWeb()) {
      // todo:need to read these values from env
      const config = new Configuration(Config.ROLLBAR_KEY, {
        environment: Config.RN_NODE_ENV,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          platform: PlatformUtils.getPlatform()
        }
      });
      rollBar = new Client(config);
    }
    return rollBar;
  }
}

class RollBarServiceNativeMobile {
  createInstance() {
    let rollBar = null;
    if (!PlatformUtils.isWeb()) {
      const config = new Configuration(Config.ROLLBAR_KEY, {
        environment: Config.RN_NODE_ENV,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          platform: PlatformUtils.getPlatform()
        }
      });
      rollBar = new Client(config);
    }
    return rollBar;
  }
}

export const rollBarObjWeb = new RollBarServiceWeb().rollbar;
export const rollBarObjTab = new RollBarServiceNative().createInstance();
export const rollBarObjMob = new RollBarServiceNativeMobile().createInstance();
