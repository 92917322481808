import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import { manageLmsEndPoints } from "@timhortons/common/src/constants/endPoints";
import {
  LmsModulePtsDataModel,
  LmsPtsTopicDataModel,
  LmsPtsTopicResponseModel,
  LmsPtsCheckListDataModel,
  LmsPtsCheckListResponseModel,
  LmsAssessmentTheoryDataModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";
import { ptsModuleDataFormatter } from "@timhortons/common/src/repositories/manageLms/formatters/lmsPtsFormatter";
import { lmsAssessmentTheoryDataFormatter } from "@timhortons/common/src/repositories/manageLms/formatters/lmsFeedbackFormatter";

class LmsAssessmentsRepository {
  getAssessmentsPracticalModuleData = async (data: {
    moduleId: string;
  }): Promise<LmsModulePtsDataModel[]> => {
    const { moduleId } = data;
    let response: LmsModulePtsDataModel[] = await ApiClient.get(
      manageLmsEndPoints.assessments.getLmsAssessmentsPracticalData(moduleId),
      {},
      LMSKeys.LMS_MANAGE
    );

    return ptsModuleDataFormatter(response);
    // return deserialize(LmsFeedbackResponseModel, getFeedbackDataFormatter(response));
  };
  postAssessmentsTopic = async (data: LmsPtsTopicDataModel): Promise<LmsPtsTopicResponseModel> => {
    const {} = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.post(
      manageLmsEndPoints.assessments.lmsAssessmentsTopic(),
      data,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  updateAssessmentsTopic = async (data: {
    topicId: string;
    obj: LmsPtsTopicDataModel;
  }): Promise<LmsPtsTopicResponseModel> => {
    const { topicId, obj } = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.put(
      manageLmsEndPoints.assessments.lmsAssessmentsTopicId(topicId),
      obj,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteAssessmentsTopic = async (data: { topicId: string }): Promise<LmsPtsTopicResponseModel> => {
    const { topicId } = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.delete(
      manageLmsEndPoints.assessments.lmsAssessmentsTopicId(topicId),
      null,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };

  postAssessmentsCheckList = async (
    data: LmsPtsCheckListDataModel
  ): Promise<LmsPtsCheckListResponseModel> => {
    const {} = data;
    let response: LmsPtsCheckListResponseModel = await ApiClient.post(
      manageLmsEndPoints.assessments.lmsAssessmentsCheckList(),
      data,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  updateAssessmentsCheckList = async (data: {
    checkListId: string;
    obj: LmsPtsCheckListDataModel;
  }): Promise<LmsPtsTopicResponseModel> => {
    const { checkListId, obj } = data;
    let response: LmsPtsTopicResponseModel = await ApiClient.put(
      manageLmsEndPoints.assessments.lmsAssessmentsCheckListId(checkListId),
      obj,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };
  deleteAssessmentsCheckList = async (data: {
    checkListId: string;
  }): Promise<LmsPtsCheckListResponseModel> => {
    const { checkListId } = data;
    let response: LmsPtsCheckListResponseModel = await ApiClient.delete(
      manageLmsEndPoints.assessments.lmsAssessmentsCheckListId(checkListId),
      null,
      null,
      LMSKeys.LMS_MANAGE
    );
    return response;
  };

  getAssessmentTheoryData = async (data: {
    moduleId: string;
  }): Promise<LmsAssessmentTheoryDataModel[]> => {
    const { moduleId } = data;
    let response: LmsAssessmentTheoryDataModel[] = await ApiClient.get(
      manageLmsEndPoints.assessments.lmsAssessmentTheory(moduleId),
      null,
      LMSKeys.LMS_MANAGE
    );
    // let response = [];
    return lmsAssessmentTheoryDataFormatter(response);
  };
}

const lmsAssessmentsRepository = new LmsAssessmentsRepository();
export { lmsAssessmentsRepository as LmsAssessmentsRepository };
