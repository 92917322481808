import React, { useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { BarChart, Grid, YAxis, XAxis } from "react-native-svg-charts";
import { useTranslation } from "react-i18next";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { Text as TextSvg } from "react-native-svg";
import * as scale from "d3-scale";
import Item from "@timhortons/common/src/modules/rev/components/testAudit/organisms/auditAccordion/item";

import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
interface IProps {
  barChartData: MobileBarChartOptions;
  barWidthSpace?: any;
}
export enum NumberOfBars {
  OneBar = "0.9",
  TwoBars = "0.8",
  SixBars = "0.7",
  Any = "0.1",
  LogsBars = "0.33"
}
export interface MobileBarChartOptions {
  leftTitle?: string;
  rightTitle?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  legends?: Array<ILegendData>;
  series: Array<ISeriesData>;
  categories: (string | string[])[];
  categoryColors?: string[];
  isFSDeduction?: boolean;
  isBSGraph?: boolean;
  isSoS?: boolean;
  isMonthOnMonth?: boolean;
  labelSuffix?: string;
  height?: number;
  yAxisTitleStyle?: StyleProp<ViewStyle>;
  ymax: number;
  width?: number;
}
interface ILegendData {
  key: string;
  color: string;
}
interface ISeriesData {
  data: number[] | string[];
}
export default function MobileBarChartGroup(props: IProps) {
  const { barChartData, barWidthSpace } = props;
  const { t } = useTranslation("common");
  const getYAxisData = (max: number, index: number) => {
    let data = [];
    for (let i = 0; i <= max / index; i++) {
      data.push(i * index);
    }
    return data;
  };

  let yAxisData: any[];
  let yMax: number;
  if (barChartData.isSoS) {
    yMax = barChartData.ymax;
    yAxisData = barChartData && getYAxisData(barChartData.ymax < 5 ? 5 : barChartData.ymax, 5);
  } else {
    yMax = barChartData.ymax;
    yAxisData = barChartData && getYAxisData(barChartData.ymax < 25 ? 25 : barChartData.ymax, 25);
  }
  const randomColor = () =>
    ("#" + ((Math.random() * 0xffffff) << 0).toString(16) + "000000").slice(0, 7);

  const pieData =
    barChartData &&
    barChartData.series[0].data &&
    barChartData.series[0].data.map((value: any, index: string | number) => ({
      value,
      category: barChartData.categories && barChartData.categories[index],
      svg: {
        fill: barChartData.categoryColors
          ? barChartData.categoryColors[index]
            ? barChartData.categoryColors[index]
            : barChartData.categoryColors[0]
          : randomColor()
      },
      key: `pie-${index}`,
      labelSuffix: barChartData.labelSuffix
    }));

  const SVG_GRID = {
    strokeDasharray: [5, 5],
    stroke: colorPallete.grey7,
    width: "100%"
  };

  const Labels = ({ x, y, bandwidth, data }) =>
    pieData.map((value, index) => (
      <TextSvg
        key={index}
        x={x(index) + bandwidth / 2}
        y={y(value.value) - 10}
        fontSize={14}
        fill={colorPallete.grey1}
        alignmentBaseline={"middle"}
        textAnchor={"middle"}
      >
        {value.value !== 0 && value.labelSuffix ? value.value + value.labelSuffix : value.value}
      </TextSvg>
    ));
  const yAxesSvg = { fontSize: 12, fill: colorPallete.grey15 };
  const xAxesSvg = { fontSize: 12, fill: colorPallete.grey1 };
  return (
    <View>
      <View style={styles.headerContainer}>
        <Text
          textSize={TextSize.ExtraRegular}
          fontWeight={FontWeight.SemiBold}
          testId={testIds.reports.barChartLeftTitle}
        >
          {barChartData.leftTitle}
        </Text>
        <View style={styles.legendStyle}>
          {barChartData && barChartData.rightTitle ? (
            <View>
              <Text
                textStyle={styles.rightTitleStyle}
                textSize={TextSize.ExtraRegular}
                fontWeight={FontWeight.Regular}
                testId={testIds.reports.barChartRightTitle}
              >
                {barChartData.rightTitle}
              </Text>
            </View>
          ) : (
            barChartData.legends &&
            barChartData.legends.map((x: any, index: number) => {
              return (
                <View style={styles.legendContainer} key={index}>
                  <View style={[styles.circleContainer, { backgroundColor: x.color }]} />
                  <Text
                    textStyle={styles.text}
                    textSize={TextSize.ExtraFineSmall}
                    fontWeight={FontWeight.SemiBold}
                    testId={testIds.reports.legends}
                  >
                    {x.key}
                  </Text>
                </View>
              );
            })
          )}
        </View>
      </View>
      {pieData && pieData?.length === 0 ? (
        <Jumbotron type={ErrorType.NoDataFound} title={t("noDataAvailable")} />
      ) : (
        <View
          style={{
            paddingHorizontal: 24,
            flexDirection: "column"
          }}
        >
          <View style={barChartData.yAxisTitleStyle}>
            <Text
              textSize={TextSize.ExtraFineSmall}
              fontWeight={FontWeight.Regular}
              testId="yAxisTitle"
              textStyle={[styles.yAxisTitle]}
            >
              {barChartData && barChartData.yAxisTitle}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              height: barChartData.height
            }}
          >
            <YAxis
              data={yAxisData}
              svg={yAxesSvg}
              formatLabel={(value: any) =>
                barChartData.yAxisTitle ? value : `${value}${barChartData.labelSuffix}`
              }
              numberOfTicks={4}
              contentInset={{ top: 20, bottom: 4 }}
            />
            <BarChart
              style={{ flex: 1, paddingStart: 12 }}
              data={pieData}
              yAccessor={({ item }) => item && item.value}
              gridMin={0}
              numberOfTicks={4}
              spacingInner={barWidthSpace}
              yMin={0}
              yMax={yMax}
              contentInset={{ top: 20 }}
            >
              <Grid direction={Grid.Direction.HORIZONTAL} svg={SVG_GRID} />
              <Labels />
            </BarChart>
          </View>
          <View
            style={[
              { paddingTop: 10 },
              barChartData.yAxisTitle ? { paddingLeft: 24 } : { paddingLeft: 24 }
            ]}
          >
            <XAxis
              style={{
                marginStart: 8
              }}
              spacingInner={barWidthSpace}
              data={pieData}
              svg={xAxesSvg}
              scale={scale.scaleBand}
              labelStyle={{ fill: "#594547" }}
              formatLabel={(_: any, index: string | number) => pieData[index].category}
            />
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Text
                textSize={TextSize.ExtraFineSmall}
                fontWeight={FontWeight.Regular}
                testId="xAxisTitle"
                textStyle={styles.xAxisTitle}
              >
                {barChartData && barChartData.xAxisTitle}
              </Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    // flex: 1
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 24,
    alignItems: "center"
  },
  circleContainer: {
    height: 10,
    width: 10,
    borderRadius: 50
  },
  legendStyle: {
    flexDirection: "row"
  },
  legendContainer: {
    flexDirection: "row",
    // marginStart: 15,
    ...I18nService.select({
      rtl: { marginStart: 15 },
      ltr: { marginStart: 15 }
    })
  },
  text: {
    // fontSize: 9,
    lineHeight: 13,
    color: colorPallete.grey1,
    // marginStart: 4,
    ...I18nService.select({
      rtl: { marginStart: 4 },
      ltr: { marginStart: 4 }
    })
  },
  chartContainer: {
    flex: 1,
    paddingHorizontal: 24,
    justifyContent: "center"
  },
  rightTitleStyle: {
    color: colorPallete.black3
  },
  xAxisTitle: {
    color: colorPallete.grey2
  },
  yAxisTitle: {
    color: colorPallete.grey2
    // transform: [{rotate: '270deg'}],
    // alignSelf: 'center',
    // justifyContent: 'center',
    // marginLeft: -40,
    // marginRight: 400
    // textAlignVertical: 'center',
    // backgroundColor: 'green',
  }
});
