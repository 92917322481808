import React from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize, Label } from "@timhortons/common/src/components/atoms/text";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { LpStoreRankingDataModel } from "@timhortons/common/src/models/lp/reports";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";

import Loader from "@timhortons/common/src/components/molecules/loader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";

interface IProps {
  translate: any;
  headerTitle: string;
  tableHeaderData: { name: string }[];
  showTopAndLastFive: boolean;
  loading: boolean;
  error: string;
  storeRakingTableData: LpStoreRankingDataModel;
  pageNo: number;
  handlePageNo: Function;
}

//TODO: Add Icon for 1st rank and change icons before top five and last five

export default function LpStoreRankingsTable(props: IProps) {
  const {
    headerTitle,
    translate,
    tableHeaderData,
    showTopAndLastFive,
    loading,
    error,
    storeRakingTableData,
    pageNo,
    handlePageNo
  } = props;

  const getHeaderComp = (headerItem: any, index: number) => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.grey1 }}
        cellStyle={
          (headerItem.name === translate("rank") && styles.cellStyle) ||
          (headerItem.name === translate("storeNames") && styles.storeNamesHeaderCellStyle) ||
          (headerItem.name === translate("areaManagerNames") && styles.storeNamesHeaderCellStyle) ||
          (headerItem.name === translate("country") && styles.countryHeaderCellStyle) ||
          styles.headerStyle
        }
      />
    );
  };

  const getTableData = (item: any, index: number) => {
    return (
      <View key={index}>
        <Row rowStyle={{ marginVertical: 12 }}>
          <Cell title={item.rank} cellStyle={styles.cellStyle} titleStyle={styles.cellTitleStyle} />
          <Cell
            title={item.storeName}
            cellStyle={styles.storeNamesHeaderCellStyle}
            titleStyle={styles.cellTitleStyle}
          />
          {showTopAndLastFive && (
            <Cell
              title={item.country}
              cellStyle={styles.countryHeaderCellStyle}
              titleStyle={styles.cellTitleStyle}
            />
          )}
          <Cell cellStyle={styles.headerStyle} titleStyle={styles.cellTitleStyle}>
            <Label
              textSize={TextSize.ExtraMedium}
              fontWeight={FontWeight.SemiBold}
              testId="id"
              style={[styles.textStyleChip]}
            >
              <Text textSize={TextSize.Regular} fontWeight={FontWeight.SemiBold} testId="id">
                {item.grade}
              </Text>
            </Label>
          </Cell>
          <Cell
            title={item.criticalPoints}
            cellStyle={styles.headerStyle}
            titleStyle={styles.cellTitleStyle}
          />
          <Cell
            title={`${item.overallScore}%`}
            cellStyle={styles.headerStyle}
            titleStyle={styles.cellTitleStyle}
          />
        </Row>
        <Divider
          orientation={Orientations.Horizontal}
          dividerStyle={{ borderColor: colorPallete.white1 }}
        />
      </View>
    );
  };
  const pageHandler = (val: number) => {
    handlePageNo(val);
  };
  return (
    <View style={styles.container}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        testId="opportunity table"
        textStyle={styles.headerTextStyle}
      >
        {headerTitle}
      </Text>
      <View>
        <Table>
          <Row header>
            {tableHeaderData.map((item, index) => {
              return getHeaderComp(item, index);
            })}
          </Row>
          {loading ? (
            <Loader type="component" />
          ) : (storeRakingTableData && storeRakingTableData?.content?.length > 0) ||
            error?.length < 0 ? (
            <View>
              {storeRakingTableData?.content.map((item, index) => {
                return getTableData(item, index);
              })}
              <CommonPagination
                handlePagination={pageHandler}
                pageNum={pageNo}
                totalRecords={storeRakingTableData && storeRakingTableData.totalElements}
                pageLimit={10}
              />
            </View>
          ) : error ? (
            <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
          ) : (
            <Jumbotron type={ErrorType.NoDataFound} title={translate("noAvailableAudits")} />
          )}
        </Table>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7
  },
  headerTextStyle: {
    padding: 24
  },
  cellStyle: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginEnd: 42 },
      ltr: { marginEnd: 42 }
    })
  },
  countryHeaderCellStyle: {
    flex: 1.5,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginEnd: 42 },
      ltr: { marginEnd: 42 }
    })
  },
  storeNamesHeaderCellStyle: {
    flex: 3,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12
  },
  headerStyle: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    ...I18nService.select({
      rtl: { marginEnd: 42 },
      ltr: { marginEnd: 42 }
    }),
    paddingVertical: 12
  },
  cellTitleStyle: {
    color: colorPallete.black4
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: colorPallete.grey8
  }
});
