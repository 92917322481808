import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import icoMoonConfig from "@timhortons/common/src/assets/icons/selection.json";
const CustomIcon = createIconSetFromIcoMoon(icoMoonConfig);
import React, { ReactElement } from "react";
import { View } from "react-native";

interface IProps {
  name: IconNames;
  customStyle?: any;
  size?: number;
  testID?: string;
  color?: string;
  onPress?: () => void;
  disabled?: boolean;
}
export enum IconNames {
  checkBoxTicked = "checkBoxTicked",
  checkBoxUnTicked = "checkBoxUnTicked",
  checkBoxTickedUnfilled = "checkBoxTickedUnfilled",
  uploadImage = "uploadImg",
  fileVideo = "file-video",
  reports = "reports",
  tipping = "tipping",
  tick = "tick",
  collapseAll = "collapseAll",
  crossCheckbox = "crossCheckbox",
  minusCheckbox = "minusCheckbox",
  doubleTick = "doubleTick",
  complete = "complete",
  back = "back",
  up = "up",
  down = "down",
  expandAll = "expandAll",
  hut = "hut",
  halfDone = "halfDone",
  noneDone = "noneDone",
  close = "close",
  watchVideo = "watch",
  star = "star",
  plus = "plus",
  download = "download",
  rev = "rev",
  tippingFilled = "tippingFilled",
  resources = "resources",
  calender = "calender",
  askTell = "askTell",
  askTellFilled = "askTellFilled",
  home = "home",
  pen = "pen",
  droplet = "droplet",
  connection = "connection",
  checkSquareChecked = "checkbox-checked",
  checkSquareUnChecked = "checkbox-unchecked",
  checkRoundChecked = "radio-checked",
  checkRoundUnChecked = "radio-checked2",
  uploadFile = "upload",
  fileText = "file-text",
  folderDownload = "folder-download",
  chevronDown = "connection",
  preview = "preview",
  search = "search",
  filledDown = "filledDown",
  hourglassStart = "hourglassStart",
  hourglassStartSolid = "hourglassStartSolid",
  clock = "stopClock",
  clockSolid = "stopClockSolid",
  bulb = "bulbSolid",
  tableDownload = "tableDownload",
  eyeView = "eyeLight",
  sort = "tableSort",
  thunder = "thunderSolid",
  showFilters = "filters",
  menuClose = "menuClose",
  menuOpen = "menuOpen",
  left = "left",
  right = "right",
  sortUpFilled = "sortUpFilled",
  checklist = "checklist",
  sortDownUnfilled = "sortDownUnfilled",
  sortUpUnfilled = "sortUpUnfilled",
  reset = "reset",
  edit = "edit",
  eyeClose = "eyeClose",
  crossCircle = "crossCircle",
  hamBurger = "hamBurger",
  cameraSwitch = "cameraSwitch",
  zeroTolerance = "zeroTolerance",
  attachment = "attachment",
  docUpld = "docUpld",
  camera = "camera",
  trash = "trash",
  photoUpld = "photoUpld",
  externalAuditImp = "externalAuditImp",
  personal = "personal",
  location = "location",
  mob = "mob",
  email = "email",
  filledChevronLeft = "filledChevronLeft",
  keyboard = "keyboard",
  ttlog1 = "ttlog1",
  ttlog2 = "ttlog2",
  ttlog3 = "ttlog3",
  managerWalk = "managerWalk",
  cleaning = "cleaning",
  readiness = "readiness",
  timeTemp = "timeTemp",
  beverageStation = "beverageStation",
  handWash = "handWash",
  thermometer = "thermometer",
  error = "error",
  certificate = "certificate",
  document = "document",
  // NEW ICONS FROM HERE..............
  blankCalender = "home",
  person = "person",
  contact = "contact",
  atTheRate = "atTheRate",
  mobile = "mobile",
  ttlog4 = "ttlog4",
  greyMedal = "greyMedal",
  video = "video",
  forward = "forward",
  clocklms = "clock",
  learn = "learn",
  careerPath = "careerPath",
  lock = "lock",
  settings = "settingVideo",
  play = "play",
  fullScreen = "fullScreen",
  subtitles = "subs",
  bluetoothConnect = "bt2",
  connectedBluetooth = "btConnected",
  disconnectedBluetooth = "bt1",
  refresh = "refresh",
  bluetoothDevice = "bt2",
  lpNav = "lpNav",
  profile = "profile",
  call = "call",
  mail = "mail",
  storeEmps = "storeEmps",
  publish = "publish",
  trashBin = "trashBin",
  docIcon = "docIcon",
  brugerCoffeeIcon = "brugerCoffeeIcon",
  categoriesList = "categoriesList",
  categoriesPanel = "categoryPanel",
  save = "save",
  editPencil = "editPencil",
  statistics = "statistics",
  more = "more",
  learningPath = "learningPath",
  courses = "courses",
  dashBoard = "dashBoard",
  filledCircle = "filledCircle",
  profileFilled = "profileFilled",
  notificationBell = "notificationBell",
  helpHand = "helpHand",
  logoutBack = "logoutBack",
  starNew = "starNew",
  settingOther = "settingOther",
  certificateNew = "certificateNew",
  empDoc = "empDoc",
  pauseVideo = "pauseVideo",
  calendar = "Calendar",
  certifications = "Certifications",
  uploadImgFilled = "uploadImgFilled",
  editPencilFilled = "editPencilFilled",
  alertWarning = "alertWarning",
  blankDoc = "blankDoc",
  certificateSolid = "certificateSolid",
  checkList = "checkList",
  editDoc = "editDoc",
  editPencilSolid = "editPencilSolid",
  pencilOutlined = "pencilOutlined",
  feedbackFace = "feedbackFace",
  practicalJar = "practicalJar",
  publishUpload = "publishUpload",
  simpleClock = "simpleClock",
  smallHamburger = "smallHamburger",
  switch = "switch",
  threeDot = "threeDot",
  thumbnail = "thumbnail",
  // TODO: Asmitha | Need to update icons
  oval = "clock",
  locationPin = "clock",
  guest = "clock",
  description = "description",
  typeIcon = "typeIcon",
  editPencilOutlined = "editPencilOutlined",
  thickOval = "thickOval",
  peopleGroup = "peopleGroup",
  locationIndicator = "locationIndicator",
  feedbackMsgT = "feedbackMsgT",
  crown = "crown",
  courseIcon = "courseIcon",
  removeCircle = "removeCircle",
  certificateOutlined = "certificateOutlined",
  rightArrowBold = "rightArrowBold",
  calendarThin = "calendarThin",
  crossCloseThin = "crossCloseThin",
  trophyCup = "trophyCup",
  warningTriangle = "warningTriangle",
  languageChange = "languageChange",
  notificationSquareCircle = "notificationSquareCircle",
  ingredients = "ingredients",
  editPencilUp = "editPencilUp",
  knife = "knife",
  profileShield = "profileShield",
  beverageMenu = "beverageMenu",
  instagram = "instagram",
  facebook = "facebook",
  linkedin = "linkedin",
  grill = "grill",
  fold = "fold",
  none = "none",
  twitter = "Twitter-X",
  expired = "Expiry-icon"
}

export default function Icon(props: IProps): ReactElement {
  const { name, onPress, customStyle = {}, size = 20, color = "black", disabled = false } = props;
  return (
    <View>
      <CustomIcon
        onPress={!disabled && onPress}
        size={size}
        name={name}
        color={color}
        style={[{ fontWeight: "900" }, customStyle]}
      />
    </View>
  );
}
