import { forEach } from "lodash";
import { PRACTICAL_ASSESSMENT } from "@timhortons/common/src/redux/practicalAssessment/actions/actionTypes";
import { UserDetailsModel } from "@timhortons/common/src/models/practicalAssessment/practicalAssessment";
import errorBoundary from "hoc/errorBoundary/errorBoundary";

export interface IPracticalAssessmentLoading {
  getUserDetails: boolean;
  getAllModules: boolean;
  getAllTopicsAndChecklist: boolean;
  postChecklist: boolean;
  postImages: boolean;
  deleteImages: boolean;
  postModules: boolean;
  getUserInfo: boolean;
}

export interface IPracticalAssessmentError {
  getUserDetails: string;
  getAllModules: string;
  getAllTopicsAndChecklist: string;
  postChecklist: string;
  postImages: string;
  deleteImages: string;
  postModules: string;
  getUserInfo: string;
}

export interface IPracticalAssessmentState {
  loading: IPracticalAssessmentLoading;
  error: IPracticalAssessmentError;
  userDetails: UserDetailsModel;
  getAllModules: any;
  getAllTopicsAndChecklist: any;
  postChecklist: any;
  postImages: any;
  deleteImages: any;
  postModules: any;
  getUserInfo: any;
}

export const practicalAssessmentInitialState: IPracticalAssessmentState = {
  userDetails: null,
  getAllModules: null,
  getAllTopicsAndChecklist: null,
  postChecklist: null,
  postImages: null,
  deleteImages: null,
  postModules: null,
  getUserInfo: null,
  loading: {
    getUserDetails: false,
    getAllModules: false,
    getAllTopicsAndChecklist: false,
    postChecklist: false,
    postImages: false,
    deleteImages: false,
    postModules: false,
    getUserInfo: false
  },
  error: {
    getUserDetails: null,
    getAllModules: null,
    getAllTopicsAndChecklist: null,
    postChecklist: null,
    postImages: null,
    deleteImages: null,
    postModules: null,
    getUserInfo: null
  }
};

export const practicalAssessmentReducer = (
  state = practicalAssessmentInitialState,
  action: { payload: any; type: string }
): IPracticalAssessmentState => {
  switch (action.type) {
    case PRACTICAL_ASSESSMENT.GET_USERDETAILS_BY_USERID.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getUserDetails: true },
        error: { ...state.error, getUserDetails: null }
      };
    case PRACTICAL_ASSESSMENT.GET_USERDETAILS_BY_USERID.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getUserDetails: false },
        error: { ...state.error, getUserDetails: null },
        userDetails: action.payload
      };
    case PRACTICAL_ASSESSMENT.GET_USERDETAILS_BY_USERID.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getUserDetails: false },
        error: { ...state.error, getUserDetails: action.payload }
      };
    case PRACTICAL_ASSESSMENT.GET_ALL_MODULES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllModules: true },
        error: { ...state.error, getAllModules: null }
      };
    case PRACTICAL_ASSESSMENT.GET_ALL_MODULES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllModules: false },
        error: { ...state.error, getAllModules: null },
        getAllModules: action.payload
      };
    case PRACTICAL_ASSESSMENT.GET_ALL_MODULES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllModules: false },
        error: { ...state.error, getAllModules: action.payload }
      };
    case PRACTICAL_ASSESSMENT.GET_ALL_TOPICS_AND_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllTopicsAndChecklist: true },
        error: { ...state.error, getAllTopicsAndChecklist: null }
      };
    case PRACTICAL_ASSESSMENT.GET_ALL_TOPICS_AND_CHECKLIST.SUCCESS:
      let newArr: any = action.payload;
      let arr: any = [];
      newArr.forEach((item: any) => {
        let newObj = { ...item };
        let cnt = 0;
        let checkArr: any = [];
        item?.checklists?.forEach((el: any) => {
          // let obj = {}
          let obj = {
            ...el,
            uniqueId: cnt
          };
          cnt++;
          checkArr.push(obj);
        });
        newObj = {
          ...newObj,
          checklists: checkArr
        };
        arr.push(newObj);
      });
      return {
        ...state,
        loading: { ...state.loading, getAllTopicsAndChecklist: false },
        error: { ...state.error, getAllTopicsAndChecklist: null },
        getAllTopicsAndChecklist: arr
      };
    case PRACTICAL_ASSESSMENT.GET_ALL_TOPICS_AND_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllTopicsAndChecklist: false },
        error: { ...state.error, getAllTopicsAndChecklist: action.payload }
      };
    case PRACTICAL_ASSESSMENT.POST_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postChecklist: true },
        error: { ...state.error, postChecklist: null }
      };
    case PRACTICAL_ASSESSMENT.POST_CHECKLIST.SUCCESS:
      const data = action.payload;
      const topicsArray = state.getAllTopicsAndChecklist;
      let percentage: number = 0;
      topicsArray?.forEach((item: any) => {
        item?.checklists?.forEach((checklist: any) => {
          if (checklist.id === data.checklistId) {
            checklist.answer = data.answer;
            percentage = data.percentage;
          }
        });
        if (percentage !== 0) {
          topicsArray[0].percentage = percentage;
        }
      });
      return {
        ...state,
        loading: { ...state.loading, postChecklist: false },
        error: { ...state.error, postChecklist: null },
        postChecklist: action.payload
      };
    case PRACTICAL_ASSESSMENT.POST_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postChecklist: false },
        error: { ...state.error, postChecklist: action.payload }
      };
    case PRACTICAL_ASSESSMENT.POST_IMAGES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postImages: true },
        error: { ...state.error, postImages: null }
      };
    case PRACTICAL_ASSESSMENT.POST_IMAGES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postImages: false },
        error: { ...state.error, postImages: null },
        postImages: action.payload
      };
    case PRACTICAL_ASSESSMENT.POST_IMAGES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postImages: false },
        error: { ...state.error, postImages: action.payload === null ? "error" : action.payload }
      };
    case PRACTICAL_ASSESSMENT.DELETE_IMAGES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteImages: true },
        error: { ...state.error, deleteImages: null }
      };
    case PRACTICAL_ASSESSMENT.DELETE_IMAGES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteImages: false },
        error: { ...state.error, deleteImages: null },
        deleteImages: action.payload
      };
    case PRACTICAL_ASSESSMENT.DELETE_IMAGES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteImages: false },
        error: { ...state.error, deleteImages: action.payload }
      };
    case PRACTICAL_ASSESSMENT.POST_MODULES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postModules: true },
        error: { ...state.error, postModules: null }
      };
    case PRACTICAL_ASSESSMENT.POST_MODULES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postModules: false },
        error: { ...state.error, postModules: null },
        postModules: action.payload
      };
    case PRACTICAL_ASSESSMENT.POST_MODULES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postModules: false },
        error: { ...state.error, postModules: action.payload }
      };
    case PRACTICAL_ASSESSMENT.GET_USER_INFO.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getUserInfo: true },
        error: { ...state.error, getUserInfo: null }
      };
    case PRACTICAL_ASSESSMENT.GET_USER_INFO.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getUserInfo: false },
        error: { ...state.error, getUserInfo: null },
        getUserInfo: action.payload
      };
    case PRACTICAL_ASSESSMENT.GET_USER_INFO.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getUserInfo: false },
        error: { ...state.error, getUserInfo: action.payload }
      };
    case PRACTICAL_ASSESSMENT.RESET_MODULES.LOADING:
      return {
        ...state,
        getAllModules: [],
        userDetails: null
      };
    case PRACTICAL_ASSESSMENT.RESET_ASSESSMENT.LOADING:
      return {
        ...state,
        postModules: null
      };
    case PRACTICAL_ASSESSMENT.RESET_USER_INFO.LOADING:
      return {
        ...state,
        getUserInfo: null
      };
    case PRACTICAL_ASSESSMENT.RESET_ERROR.LOADING:
      return {
        ...state,
        error: {
          ...state.error,
          postModules: null,
          postImages: null,
          deleteImages: null,
          postChecklist: null
        }
      };
    default:
      return state;
  }
};
