import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  ILpAppealsState,
  ILpAppealsError,
  ILpAppealsLoading
} from "@timhortons/common/src/redux/lp/reducers/appeals";
import {
  LpAppealCountModel,
  LpAppealsCategoryDataModel,
  LpCreateNewAppeal,
  LpInProcesssClosedTable,
  LpNewAppealsTable,
  LpAppealsAccordionDataModel
} from "@timhortons/common/src/models/lp/appeals";
import { LpActionPlanCategoryDataModel } from "@timhortons/common/src/models/lp/actionPlan";

const lpAppealSelect = (state: IState): ILpAppealsState => state.lp.appeals;

const lpAppealsLoading: Selector<IState, ILpAppealsLoading> = createSelector(
  lpAppealSelect,
  (data) => data.loading
);

const lpAppealsError: Selector<IState, ILpAppealsError> = createSelector(
  lpAppealSelect,
  (data) => data.error
);

const getLpInProcessAppeals: Selector<IState, LpInProcesssClosedTable[]> = createSelector(
  lpAppealSelect,
  (data) => data.lpInProcessAppeals
);
const getLpClosedAppeals: Selector<IState, LpInProcesssClosedTable[]> = createSelector(
  lpAppealSelect,
  (data) => data.lpClosedAppeals
);

const setLpAppealDecision: Selector<IState, boolean> = createSelector(
  lpAppealSelect,
  (data) => data.lpAppealsDecision
);

const lpAppealFilteredData: Selector<IState, LpInProcesssClosedTable[]> = createSelector(
  lpAppealSelect,
  (data) => data.lpFilteredData
);

const getLpNewAppealsData: Selector<IState, LpNewAppealsTable[]> = createSelector(
  lpAppealSelect,
  (data) => data.lpNewAppeals
);

const lpAppealsCountData: Selector<IState, LpAppealCountModel> = createSelector(
  lpAppealSelect,
  (data) => data.lpAppealsCount
);

const createlpNewAppealResponse: Selector<IState, LpCreateNewAppeal> = createSelector(
  lpAppealSelect,
  (data) => data.createLpNewAppealData
);
const lpAppealsHeaderData: Selector<IState, LpAppealsCategoryDataModel> = createSelector(
  lpAppealSelect,
  (data) => data.lpAppealsHeaderData
);
const lpAppealsCategoryData: Selector<IState, LpActionPlanCategoryDataModel[]> = createSelector(
  lpAppealSelect,
  (data) => data.lpAppealsCategoryData
);

const getLpAppealAccordionData: Selector<IState, LpAppealsAccordionDataModel[]> = createSelector(
  lpAppealSelect,
  (data) => data.lpAppealAccordianData
);

export const LpAppealsSelectors = {
  lpAppealsLoading,
  lpAppealsError,
  getLpNewAppealsData,
  getLpInProcessAppeals,
  getLpClosedAppeals,
  setLpAppealDecision,
  lpAppealFilteredData,
  lpAppealsCountData,
  createlpNewAppealResponse,
  lpAppealsHeaderData,
  lpAppealsCategoryData,
  getLpAppealAccordionData
};
