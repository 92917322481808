import React from "react";
import { View, StyleSheet, TouchableOpacity, StyleProp, ViewStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { DoubleDatePicker } from "@timhortons/web/src/components/atoms/datepicker/doubleViewCalender";
import ModalDropdown, {
  IDropDownItem
} from "@timhortons/common/src/components/molecules/modalDropdown";
import SearchField, {
  SearchIconPositions
} from "@timhortons/common/src/components/molecules/searchField";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import CalendarComponent from "@timhortons/tablet/src/components/atoms/datepicker";
import { LpAppealTable } from "@timhortons/common/src/modules/lp/components/dashboard/appeals";
import { FilterDataModel } from "@timhortons/common/src/models/lp/actionPlan";
import { Roles } from "@timhortons/common/src/constants/roles";

interface IProps {
  parentCallBackFrom?: (value: any) => void;
  parentCallBackTo?: (value: any) => void;
  reset?: boolean;
  searchFunction?: (value: any) => void;
  searchValue?: string;
  allItems?: Array<IDropDownItem>;
  selectedValueDefault?: string;
  onChangeCallBack?: (obj: IDropDownItem) => void;
  selectedItemStyle?: any;
  translate: any;
  setShowFilter?: () => void;
  resetState?: () => void;
  disableSelectedPrevDate?: Date;
  isDropDownRequired?: boolean;
  currentTable?: LpAppealTable;
  resetHandler?: Function;
  resetContainerStyle?: StyleProp<ViewStyle>;
  prevSelectedValues?: any;
  filterData?: FilterDataModel;
  dropDownValueHandler?: Function;
  isIndividualLogin?: boolean;
  userRole?: Roles;
  allItemsName?: Array<IDropDownItem>;
  selectedValueName?: string;
  onChangeCallBackName?: (obj: IDropDownItem) => void;
  selectedItemNameStyle?: any;
  actionPlanTable?: boolean;
  isOnlyIcon?: boolean;
}
interface ILpAuditorNameData {
  auditorId: string;
  auditorName: string;
}

export const ShowFiltersView = (props: IProps): React.ReactElement => {
  const { translate, setShowFilter } = props;

  return (
    <View style={styles.filterContainer}>
      <Button
        type={ButtonType.Primary}
        onPress={setShowFilter}
        buttonStyles={styles.button}
        iconSize={14}
        iconName={IconNames.showFilters}
        iconStyles={styles.filterIconStyles2}
      />
      <TouchableOpacity onPress={setShowFilter}>
        <Text
          textSize={TextSize.ExtraRegular}
          fontWeight={FontWeight.Regular}
          testId="showFilters"
          textStyle={styles.filterTextStyle2}
        >
          {translate("hideFilters")}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
export const ResetFilters = (props: IProps): React.ReactElement => {
  const { translate, resetState, resetContainerStyle, isOnlyIcon = false } = props;
  return (
    <View style={[{ flexDirection: "row", alignItems: "center" }, resetContainerStyle]}>
      <Button
        type={ButtonType.Primary}
        onPress={resetState}
        buttonStyles={styles.resetButton}
        iconSize={14}
        iconName={IconNames.reset}
        iconStyles={styles.resetIconStyle}
      />
      {!isOnlyIcon && (
        <TouchableOpacity onPress={resetState}>
          <Text
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.Regular}
            testId="showFilters"
            textStyle={styles.resetfilterTextStyle}
          >
            {translate("reset")}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};
export const HideFilters = (props: IProps): React.ReactElement => {
  const { translate, setShowFilter } = props;
  return (
    <View style={styles.filterContainer}>
      <Button
        type={ButtonType.Primary}
        onPress={setShowFilter}
        buttonStyles={styles.button}
        iconSize={14}
        iconName={IconNames.showFilters}
        iconStyles={styles.filterIconStyles1}
      />
      <TouchableOpacity onPress={setShowFilter}>
        <Text
          textSize={TextSize.ExtraRegular}
          fontWeight={FontWeight.Regular}
          testId="showFilters"
          textStyle={styles.filterTextStyle1}
        >
          {translate("showFilters")}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
export const Filters = (props: IProps): React.ReactElement => {
  const {
    parentCallBackFrom,
    reset,
    searchFunction,
    searchValue,
    allItems,
    selectedValueDefault,
    onChangeCallBack,
    selectedItemStyle,
    translate,
    isDropDownRequired = true,
    currentTable,
    actionPlanTable,
    resetHandler,
    prevSelectedValues,
    filterData,
    dropDownValueHandler,
    isIndividualLogin,
    userRole,
    allItemsName,
    selectedValueName,
    onChangeCallBackName,
    selectedItemNameStyle
  } = props;

  return (
    <View style={styles.filterDateContainer}>
      <View style={styles.datecontainer}>
        <View style={styles.fromDateContainer}>
          {PlatformUtils.isWeb() ? (
            <DoubleDatePicker
              selectRange
              showDoubleView
              showWeekNumbers
              parentDoubleViewCall={parentCallBackFrom}
              translate={translate}
              dateContainerStyle={styles.datePickerContainer}
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraSmall}
              textStyles={styles.datePickerText}
              reset={reset}
              prevSelectedValues={prevSelectedValues}
            />
          ) : (
            <CalendarComponent
              selectRange={true}
              parentCallBack={(value) => parentCallBackFrom(value)}
              reset={reset}
              textStyles={styles.datePickerText}
              iconStyles={styles.datePickerIcon}
              iconSize={12}
              fontWeight={FontWeight.Regular}
              textSize={TextSize.ExtraSmall}
              dateContainerStyle={styles.datePickerContainer}
              prevSelectedValues={prevSelectedValues}
            />
          )}
        </View>
        <View style={styles.searchContainer}>
          <Text
            textSize={TextSize.Small}
            fontWeight={FontWeight.Regular}
            testId="toDatepicker"
            textStyle={styles.filterDateTextStyle}
          >
            {translate("searchByKeyword")}
          </Text>
          <SearchField
            iconPosition={SearchIconPositions.Left}
            placeholder={translate("search")}
            searchFunction={searchFunction}
            fieldStyle={styles.filedS}
            textFieldDimensions={styles.searchBar}
            customStyles={styles.searchIconStyle}
            customTextFieldStyles={styles.customTextFieldStyles}
            value={searchValue}
            reset={reset}
            resetHandler={resetHandler}
          />
        </View>
      </View>
      {currentTable === LpAppealTable.inProcess
        ? null
        : isDropDownRequired && (
            <>
              {actionPlanTable && (
                <View style={styles.filtersElementContainer}>
                  <ModalDropdown
                    allItems={allItemsName}
                    selectedValueDefault={selectedValueName}
                    onChangeCallBack={onChangeCallBackName}
                    selectedItemStyle={selectedItemNameStyle}
                  />
                </View>
              )}
              <View style={styles.statusContainer}>
                <ModalDropdown
                  allItems={allItems}
                  selectedValueDefault={selectedValueDefault}
                  onChangeCallBack={onChangeCallBack}
                  selectedItemStyle={selectedItemStyle}
                />
              </View>
            </>
          )}
    </View>
  );
};
const styles = StyleSheet.create({
  filtersElementContainer: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    }),
    alignSelf: "center",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  button: {
    backgroundColor: colorPallete.white1,
    borderRadius: 8,
    marginTop: 6
  },
  datecontainer: {
    flex: 1,
    flexDirection: "row"
  },
  filterContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...I18nService.select({
      rtl: { paddingEnd: 36 },
      ltr: { paddingStart: 36 }
    })
    // paddingLeft: 36
  },
  filterIconStyles1: {
    color: colorPallete.black2,
    padding: 6
  },
  filterIconStyles2: {
    color: colorPallete.red1,
    padding: 6
  },
  filterTextStyle1: {
    color: colorPallete.black2,
    marginTop: PlatformUtils.isWeb() ? 10 : 6
  },
  filterTextStyle2: {
    color: colorPallete.red1,
    marginTop: PlatformUtils.isWeb() ? 10 : 6
  },
  datePickerContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colorPallete.white1
  },
  datePickerText: {
    color: colorPallete.grey2
  },
  datePickerIcon: {
    color: colorPallete.grey2
  },
  completedLabel: {
    color: colorPallete.green1
  },
  notStartedLable: {
    color: colorPallete.red3
  },

  filterDateTextStyle: {
    color: colorPallete.grey2,
    paddingTop: 6,
    ...I18nService.select({
      rtl: { marginEnd: 12 },
      ltr: { marginEnd: 12 }
    })
    // marginEnd: 12
  },
  resetButton: {
    backgroundColor: colorPallete.white1,
    borderRadius: 8,
    // marginTop: 6,
    ...I18nService.select({
      rtl: { marginStart: 24 },
      ltr: { marginStart: 24 }
    })
    // marginStart: 24
  },
  resetfilterTextStyle: {
    color: colorPallete.grey1,
    marginTop: PlatformUtils.isWeb() ? 4 : 0,
    paddingHorizontal: 6
  },
  resetIconStyle: {
    color: colorPallete.grey1,
    padding: 6
  },
  searchBar: {
    paddingVertical: 7,
    borderRadius: 8,
    paddingHorizontal: 0,
    // flex:1,
    // maxWidth: PlatformUtils.isWeb() ? 300 : 300,
    maxWidth: 300
  },
  filedS: {
    paddingVertical: 0
  },
  filterDateContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 14,
    paddingBottom: 4,
    flex: 1,
    flexWrap: "wrap"
  },
  fromDateContainer: {
    // flex: 0.2,
    alignItems: "center",
    flexDirection: "row"
  },

  searchContainer: {
    // flex: 0.6,
    alignSelf: "center",
    flexDirection: "row",
    ...I18nService.select({
      rtl: { marginStart: 54 },
      ltr: { marginStart: 54 }
    })
    // marginStart: 54
  },
  statusContainer: {
    // flex: 0.2,
    alignSelf: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    ...I18nService.select({
      rtl: { marginStart: 6 },
      ltr: { marginStart: 6 }
    })
    // marginStart: 60
  },
  searchIconStyle: {
    paddingHorizontal: 0,
    flex: 1
  },
  customTextFieldStyles: {
    padding: 0,
    ...I18nService.select({
      rtl: { marginStart: 0 },
      ltr: { marginStart: 0 }
    })
    // marginStart: 0
  }
});
