import { REVKeys } from "@timhortons/common/src/constants/apiKeys";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import {
  AcknowledgementModel,
  AckDropDownModel,
  OpportunityDetailsModal,
  ReportsSummaryOpportunityDataModel,
  ReportTableModel,
  PostAcknowledgementModel
} from "@timhortons/common/src/models/rev/reports";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { reportsEndPoints } from "@timhortons/common/src/constants/endPoints";
import { IReportTableData } from "@timhortons/common/src/modules/rev/components/dashboards/reports";
// import {
//   AllAreaManagersDataModel,
//   AllAreaManagersListDataModel,
//   AllStoresDataModel,
//   AllStoresListDataModel,
//   AreaUnderCountryManageDataModel,
//   AreaUnderCountryMModel,
//   ChannelsDataModel,
//   ChannelsListDataModel,
//   ComparisonStoresAdminModel,
//   ComparisonStoresAdminModelList,
//   CountryDataListModel,
//   CountryDataModel
// } from "@timhortons/common/src/models/profile";
import { Roles } from "@timhortons/common/src/constants/roles";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import {
  formatterReports,
  individualAccessSummaryFormatter,
  reportBuilderFormatter,
  storeAccessSummaryFormatter
} from "@timhortons/common/src/repositories/rev/reportFormatters";
import { ReportTypeLevel } from "@timhortons/common/src/components/organisms/builder/template";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

// let reportBuilderResponse: any = {
//   content: [
//     {
//       code: 971,
//       label: "United Arab Emirates",
//       employeeId: null,
//       overallScore: 63,
//       foodSafetyPoints: -10.0,
//       brandStandardScore: 50,
//       grade: "D",
//       cmScore: null,
//       guestScore: 12,
//       sosScore: 74,
//       psmScore: 78,
//       trainingScore: 14,
//       foodQualityScore: 42
//     }
//   ],
//   totalElements: 1
// };

class ReportsRepository {
  fetchReportTableData = async (param: {
    userId: string;
    storeId: number;
    isIndividualLogin: boolean;
    bodyObject: IReportTableData;
  }) => {
    let response;

    if (param.isIndividualLogin) {
      response = await ApiClient.get(
        reportsEndPoints.fetchReportTableDataIndividual(param.userId),
        param.bodyObject,
        REVKeys.REPORTS
      );
    } else {
      response = await ApiClient.get(
        reportsEndPoints.fetchReportTableDataStore(param.storeId),
        param.bodyObject,
        REVKeys.REPORTS
      );
    }
    let data: Array<ReportTableModel> = response.records.map((item: any) => {
      if (item.isZT) {
        item.overallKey = "ZT";
        item.overallResult = 0;
      }
      return deserialize(ReportTableModel, item);
    });
    return {
      ...response,
      records: data
    };
  };

  download = async (reportId: string) => {
    let z = await ApiClient.get(reportsEndPoints.downloadReport(reportId), null, REVKeys.REPORTS);
  };

  fetchReportsData = async (param: { calibrationId: number; reportId: number }) => {
    const { calibrationId, reportId } = param;

    let reportsMasterData = await ApiClient.get(
      reportsEndPoints.fetchReportsData(reportId),
      null,
      REVKeys.REPORTS
    );

    let opportunityMasterData: OpportunityDetailsModal[] = await ApiClient.get(
      reportsEndPoints.fetchOpportunityData(calibrationId),
      null,
      REVKeys.REPORTS
    );
    // let auditDetails = await selfCalibrationRepository.resumeAudit({
    //   calibrationId,
    //   type: reportsMasterData.selfCalibration.auditType
    // });
    return formatterReports(opportunityMasterData, reportsMasterData);
  };

  fetchReportsSummaryStoreAccess = async (param: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => {
    const { storeId, startDate, endDate } = param;
    let response = await ApiClient.get(
      reportsEndPoints.fetchStoreAccessSummaryData(storeId),
      {
        filterByStartDate: startDate,
        filterByEndDate: endDate
      },
      REVKeys.REPORT_SUMMARY
    );
    return storeAccessSummaryFormatter(response);
  };

  fetchReportsOpportunityStoreAccess = async (param: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => {
    const { storeId, startDate, endDate } = param;
    let data = await ApiClient.get(
      reportsEndPoints.fetchStoreAccessOpportunityData(storeId),
      {
        filterByStartDate: startDate,
        filterByEndDate: endDate
      },
      REVKeys.REPORT_SUMMARY
    );
    let finalData: ReportsSummaryOpportunityDataModel[] = [];
    data.map((item: any) => {
      let deserializeVar: ReportsSummaryOpportunityDataModel = deserialize(
        ReportsSummaryOpportunityDataModel,
        item
      );
      finalData.push(deserializeVar);
    });
    return finalData;
  };

  fetchAcknowledgementData = async (param: { storeCode: string }) => {
    let data = await ApiClient.get(
      reportsEndPoints.fetchAcknowledgementData(param.storeCode),
      null,
      REVKeys.REPORTS
    );
    let finalData: AcknowledgementModel = {
      dropDownData: []
    };
    data.map((item: AckDropDownModel) => {
      let deserializeVar: AckDropDownModel = deserialize(AckDropDownModel, item);
      finalData.dropDownData.push(deserializeVar);
    });
    return finalData;
  };
  postAcknowledgementData = async (payload: PostAcknowledgementModel) => {
    const { reportId, storeInChargeId, file, remarks } = payload;
    let data = await ApiClient.post(
      reportsEndPoints.postAcnowledgementData(),
      {
        base64String: file
      },
      {
        reportId,
        storeInChargeId,
        remarks
      },
      REVKeys.STANDARD_AUDIT
    );
    return data;
  };
  // getAllCountries = async (payload: {
  //   pageNo: number;
  //   pageSize: number;
  //   searchByKeyword: string;
  // }) => {
  //   let data = await ApiClient.get(
  //     reportsEndPoints.getAllCountries(),
  //     payload,
  //     REVKeys.REPORT_BUILDER
  //   );
  //   let countriesData: CountryDataListModel = {
  //     content: [],
  //     totalElements: null,
  //     currentPage: 0
  //   };
  //   let finalContent: CountryDataModel[] = [];

  //   data.content.map((item: CountryDataModel) => {
  //     let deserializevar: CountryDataModel = deserialize(CountryDataModel, item);
  //     finalContent.push(deserializevar);
  //   });

  //   countriesData.totalElements = data.totalElements;
  //   countriesData.content = finalContent;
  //   countriesData.currentPage = payload.pageNo;
  //   return countriesData;
  // };
  // getAllAreaManagers = async (param: {
  //   user: IUserDetails;
  //   role: string;
  //   countryCode: any;
  //   pageNo: number;
  //   searchByKeyword: string;
  //   allSelected?: boolean;
  //   pageSize: number;
  // }) => {
  //   const { role, countryCode, user, pageNo, pageSize, searchByKeyword, allSelected } = param;
  //   let resp;
  //   if (
  //     user.roles.includes(Roles.Admin) ||
  //     user.roles.includes(Roles.SuperAdmin) ||
  // user.roles.includes(Roles.TrainingAndDevLead) ||
  // user.roles.includes(Roles.TrainingAndDevManager) ||
  //     user.roles.includes(Roles.CEO) ||
  //     user.roles.includes(Roles.COO)
  //   ) {
  //     resp = await ApiClient.get(
  //       reportsEndPoints.getAllAreaManagers(role),
  //       {
  //         pageNo,
  //         pageSize,
  //         searchByKeyword: searchByKeyword,
  //         countryCodes: countryCode.join(","),
  //         allSelected: allSelected
  //       },
  //       REVKeys.REPORT_BUILDER
  //     );
  //     let managersData: AllAreaManagersListDataModel = {
  //       content: [],
  //       totalElements: null
  //     };
  //     let finalContent: AllAreaManagersDataModel[] = [];
  //     resp.content.map((item: AllAreaManagersDataModel) => {
  //       let deserializevar: AllAreaManagersDataModel = deserialize(AllAreaManagersDataModel, item);
  //       finalContent.push(deserializevar);
  //     });
  //     managersData.totalElements = resp.totalElements;
  //     managersData.content = finalContent;
  //     return managersData;
  //   }
  //   if (user.roles.includes(Roles.CountryManager) || user.roles.includes(Roles.OperationManager)) {
  //     resp = await ApiClient.get(
  //       reportsEndPoints.getAllAreaManagersCM(user.empId),
  //       null,
  //       REVKeys.REPORT_BUILDER
  //     );
  //     let managerData: AreaUnderCountryManageDataModel = {
  //       content: [],
  //       totalElements: null
  //     };
  //     let finalContent: AreaUnderCountryMModel[] = [];
  //     resp.content.map((item: AreaUnderCountryMModel) => {
  //       let deserializevar: AreaUnderCountryMModel = deserialize(AreaUnderCountryMModel, item);
  //       finalContent.push(deserializevar);
  //     });
  //     finalContent.forEach((item) => {
  //       item.label = item.label + " " + item.value;
  //     });
  //     managerData.totalElements = resp.totalElements;
  //     managerData.content = finalContent;

  //     return managerData;
  //   }
  // };
  // getAllCountryManagers = async (param: {
  //   pageNo: number;
  //   pageSize: number;
  //   user: IUserDetails;
  //   role: string;
  //   countryCode: any;
  //   searchByKeyword: string;
  //   allSelected?: boolean;
  // }) => {
  //   const { role, user, countryCode, pageNo, pageSize, searchByKeyword, allSelected } = param;
  //   let resp;
  //   if (
  //     user.roles.includes(Roles.Admin) ||
  //     user.roles.includes(Roles.SuperAdmin) ||
  // user.roles.includes(Roles.TrainingAndDevLead) ||
  // user.roles.includes(Roles.TrainingAndDevManager) ||
  //     user.roles.includes(Roles.CEO) ||
  //     user.roles.includes(Roles.COO)
  //   ) {
  //     resp = await ApiClient.get(
  //       reportsEndPoints.getAllAreaManagers(role),
  //       {
  //         pageNo,
  //         pageSize,
  //         searchByKeyword: searchByKeyword,
  //         countryCodes: countryCode.join(","),
  //         allSelected: allSelected
  //       },
  //       REVKeys.REPORT_BUILDER
  //     );
  //   }
  //   if (user.roles.includes(Roles.CountryManager) || user.roles.includes(Roles.OperationManager)) {
  //     resp = await ApiClient.get(
  //       reportsEndPoints.getAllAreaManagersCM(user.empId),
  //       null,
  //       REVKeys.REPORT_BUILDER
  //     );
  //   }

  //   let managersData: AllAreaManagersListDataModel = {
  //     content: [],
  //     totalElements: null
  //   };
  //   let finalContent: AllAreaManagersDataModel[] = [];
  //   resp.content.map((item: AllAreaManagersDataModel) => {
  //     let deserializevar: AllAreaManagersDataModel = deserialize(AllAreaManagersDataModel, item);
  //     finalContent.push(deserializevar);
  //   });
  //   managersData.totalElements = resp.totalElements;
  //   managersData.content = finalContent;

  //   return managersData;
  // };
  // getAllChannels = async (param: {
  //   user: IUserDetails;
  //   pageNo: number;
  //   pageSize: number;
  //   countryCode: any;
  //   searchByKeyword: string;
  //   allSelected?: boolean;
  // }) => {
  //   const { pageNo, pageSize, countryCode, searchByKeyword, allSelected } = param;
  //   let data;
  //   if (
  //     param.user.roles.includes(Roles.Admin) ||
  //     param.user.roles.includes(Roles.SuperAdmin) ||
  // param.user.roles.includes(Roles.TrainingAndDevLead) ||
  // param.user.roles.includes(Roles.TrainingAndDevManager) ||
  //     param.user.roles.includes(Roles.CEO) ||
  //     param.user.roles.includes(Roles.COO)
  //   ) {
  //     data = await ApiClient.get(
  //       reportsEndPoints.getAllChannels(),
  //       {
  //         pageNo,
  //         pageSize,
  //         keyword: searchByKeyword,
  //         countryCodes: countryCode.join(","),
  //         allSelected: allSelected
  //       },
  //       REVKeys.REPORT_BUILDER
  //     );
  //   }
  //   if (
  //     param.user.roles.includes(Roles.CountryManager) ||
  //     param.user.roles.includes(Roles.AreaManager) ||
  //     param.user.roles.includes(Roles.OperationManager) ||
  //     param.user.roles.includes(Roles.AreaTrainer)
  //   ) {
  //     data = await ApiClient.get(
  //       reportsEndPoints.getAllChannelsCM(),
  //       {
  //         employeeIds: param.user.empId,
  //         role: param.user.roles[0],
  //         pageNo: pageNo,
  //         pageSize: pageSize
  //       },
  //       REVKeys.REPORT_BUILDER
  //     );
  //   }
  //   let channelsData: ChannelsListDataModel = {
  //     content: [],
  //     totalElements: null,
  //     currentPage: 0
  //   };
  //   let finalContent: ChannelsDataModel[] = [];
  //   data.content.map((item: ChannelsDataModel) => {
  //     let deserializevar: ChannelsDataModel = deserialize(ChannelsDataModel, item);
  //     finalContent.push(deserializevar);
  //   });
  //   channelsData.totalElements = data.totalElements;
  //   channelsData.content = finalContent;
  //   channelsData.currentPage = param.pageNo;
  //   return channelsData;
  // };
  // getAllStores = async (param: {
  //   user: IUserDetails;
  //   pageNo: number;
  //   pageSize: number;
  //   countryCode: any;
  //   searchByKeyword: string;
  //   allSelected?: boolean;
  // }) => {
  //   const { pageNo, pageSize, countryCode, searchByKeyword, allSelected } = param;
  //   let data;
  //   if (
  //     param.user.roles.includes(Roles.Admin) ||
  //     param.user.roles.includes(Roles.SuperAdmin) ||
  //     param.user.roles.includes(Roles.TrainingAndDevLead) ||
  //     param.user.roles.includes(Roles.TrainingAndDevManager) ||
  //     param.user.roles.includes(Roles.CEO) ||
  //     param.user.roles.includes(Roles.COO)
  //   ) {
  //     data = await ApiClient.get(
  //       reportsEndPoints.getAllStores(),
  //       {
  //         pageNo,
  //         pageSize,
  //         searchByKeyword: searchByKeyword,
  //         countryCodes: countryCode.join(","),
  //         allSelected: allSelected
  //       },
  //       REVKeys.REPORT_BUILDER
  //     );
  //   }
  //   if (
  //     param.user.roles.includes(Roles.CountryManager) ||
  //     param.user.roles.includes(Roles.AreaManager) ||
  //     param.user.roles.includes(Roles.OperationManager) ||
  //     param.user.roles.includes(Roles.AreaTrainer)
  //   ) {
  //     data = await ApiClient.get(
  //       reportsEndPoints.getAllStoresCM(),
  //       {
  //         employeeIds: param.user.empId,
  //         role: param.user.roles[0],
  //         pageNo: pageNo,
  //         pageSize: pageSize
  //       },
  //       REVKeys.REPORT_BUILDER
  //     );
  //   }
  //   let storesData: AllStoresListDataModel = {
  //     content: [],
  //     totalElements: null
  //   };
  //   let finalContent: AllStoresDataModel[] = [];
  //   data.content.map((item: AllStoresDataModel) => {
  //     let deserializevar: AllStoresDataModel = deserialize(AllStoresDataModel, item);
  //     finalContent.push(deserializevar);
  //   });
  //   storesData.totalElements = data.totalElements;
  //   storesData.content = finalContent;
  //   storesData.currentPage = param.pageNo;
  //   return storesData;
  // };
  fetchIndividualReportsSummary = async (param: { startDate: string; endDate: string }) => {
    let response = await ApiClient.get(
      reportsEndPoints.fetchIndividualReportsSummaryData(),
      param,
      REVKeys.REPORT_SUMMARY
    );
    return individualAccessSummaryFormatter(response);
  };
  generateReportBuilder = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    allSelected?: boolean;
    countryCodes?: any;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }) => {
    const { object, reportType, user, countryCodes, allSelected, pageNum } = param;
    if (
      (user.roles.includes(Roles.SuperAdmin) ||
        // user.roles.includes(Roles.Admin) ||
        user.roles.includes(Roles.TrainingAndDevLead) ||
        user.roles.includes(Roles.TrainingAndDevManager) ||
        user.roles.includes(Roles.ProductionAndDevExecutive) ||
        user.roles.includes(Roles.HrAndAdminDirector) ||
        user.roles.includes(Roles.CEO) ||
        user.roles.includes(Roles.COO)) &&
      reportType === ReportTypeLevel.Comparison &&
      object.levelOfReport === "CHANNEL"
    ) {
      let resp = await ApiClient.post(
        reportsEndPoints.genReportBuilderAdminComparisonChannels(reportType, countryCodes, pageNum),
        object,
        null,
        REVKeys.REPORT_BUILDER
      );
      return reportBuilderFormatter(param, resp);
    } else {
      let response = await ApiClient.post(
        reportsEndPoints.generateReportBuilder(reportType, allSelected, pageNum),
        object,
        null,
        REVKeys.REPORT_BUILDER
      );
      return reportBuilderFormatter(param, response);
    }
  };
  // getComparisonCountryManager = async (param: {
  //   pageNo: number;
  //   pageSize: number;
  //   searchByKeyword: string;
  // }) => {
  //   const { pageNo, pageSize, searchByKeyword } = param;
  //   let resp = await ApiClient.get(
  //     reportsEndPoints.getComparisonCountryManager(),
  //     { pageNo, pageSize, searchByKeyword: searchByKeyword },
  //     REVKeys.REPORT_BUILDER
  //   );
  //   let managerData: AreaUnderCountryManageDataModel = {
  //     content: [],
  //     totalElements: null
  //   };
  //   let finalContent: AreaUnderCountryMModel[] = [];
  //   resp.content.map((item: AreaUnderCountryMModel) => {
  //     let deserializevar: AreaUnderCountryMModel = deserialize(AreaUnderCountryMModel, item);
  //     finalContent.push(deserializevar);
  //   });
  //   finalContent.forEach((item) => {
  //     item.label = item.label + " " + item.value;
  //   });
  //   managerData.totalElements = resp.totalElements;
  //   managerData.content = finalContent;

  //   return managerData;
  // };
  // getComparisonAreaManager = async (param: {
  //   pageNo: number;
  //   pageSize: number;
  //   searchByKeyword: string;
  // }) => {
  //   const { pageNo, pageSize, searchByKeyword } = param;
  //   let resp = await ApiClient.get(reportsEndPoints.getComparisonAreaManager(), {
  //     pageNo,
  //     pageSize,
  //     searchByKeyword: searchByKeyword
  //   });
  //   let managerData: AreaUnderCountryManageDataModel = {
  //     content: [],
  //     totalElements: null
  //   };
  //   let finalContent: AreaUnderCountryMModel[] = [];
  //   resp.content.map((item: AreaUnderCountryMModel) => {
  //     let deserializevar: AreaUnderCountryMModel = deserialize(AreaUnderCountryMModel, item);
  //     finalContent.push(deserializevar);
  //   });
  //   finalContent.forEach((item) => {
  //     item.label = item.label + " " + item.value;
  //   });
  //   managerData.totalElements = resp.totalElements;
  //   managerData.content = finalContent;
  //   return managerData;
  // };
  // getComparisonStoresAdmin = async (payload: { pageNo: number; pageSize: number }) => {
  //   let data = await ApiClient.get(
  //     reportsEndPoints.getComparisonStoresAdmin(),
  //     payload,
  //     REVKeys.REPORT_BUILDER
  //   );
  //   let storesData: ComparisonStoresAdminModel = {
  //     content: [],
  //     totalElements: null
  //   };
  //   let finalContent: ComparisonStoresAdminModelList[] = [];
  //   data.content.map((item: ComparisonStoresAdminModelList) => {
  //     let deserializevar: ComparisonStoresAdminModelList = deserialize(
  //       ComparisonStoresAdminModelList,
  //       item
  //     );
  //     finalContent.push(deserializevar);
  //   });
  //   storesData.totalElements = data.totalElements;
  //   storesData.content = finalContent;
  //   storesData.currentPage = payload.pageNo;
  //   return storesData;
  // };
  // getHighLowChannelsAdmin = async (payload: { pageNo: number; pageSize: number; searchByKeyword: string; }) => {
  //   let data = await ApiClient.get(
  //     reportsEndPoints.getHighLowChannelsAdmin(),
  //     payload,
  //     REVKeys.REPORT_BUILDER
  //   );
  //   let channelsData: ChannelsListDataModel = {
  //     content: [],
  //     totalElements: null
  //   };
  //   let finalContent: ChannelsDataModel[] = [];
  //   data.content.map((item: ChannelsDataModel) => {
  //     let deserializevar: ChannelsDataModel = deserialize(ChannelsDataModel, item);
  //     finalContent.push(deserializevar);
  //   });
  //   channelsData.totalElements = data.totalElements;
  //   channelsData.content = finalContent;
  //   channelsData.currentPage = payload.pageNo;
  //   return channelsData;
  // };
  getCountryDetailsOfCountryManager = async () => {
    let data = ApiClient.get(
      reportsEndPoints.getCountryManagerCountryDetails(),
      null,
      REVKeys.USER_ROLES
    );
    return data;
  };
}
const reportsRepository = new ReportsRepository();
export { reportsRepository as ReportsRepository };
