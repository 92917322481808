import PlatformUtils from "./platformUtils";

import { allRoutesNames as routeNames } from "@timhortons/common/src/utils/allRouteNames";
import { generatePath } from "react-router";
import { Pathnames } from "./pathnameInterface";
//AT MANY PLACES PATHNAMES.CALIBRATIONS IS USED WHICH IS NO LONGER HERE PLS REFRACTOR THAT, IT IS WORKING NOW BCOZ ITS VALUE IS SELFCALIBRATIONS
const revScreenNames = {
  testAudit: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.testAudit}`,
  detailedReports: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.detailedReports}`,
  detailedAppeals: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.detailedAppeals}`,
  detailedActionPlans: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.detailedActionPlan}`,
  selfCalibrations: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.selfCalibrationsList}`,
  appeals: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.appealsList}`,
  actionPlans: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.actionPlansList}`,
  reportNavlink: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.reportsList}`
};
const manageLmsScreenNames = {
  //dashboard
  manageLmsAssignedLearning: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.dashboards.root}${routeNames.manageLms.dashboards.urlParams.assignedLearning}`,
  manageLmsAssesments: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.dashboards.root}${routeNames.manageLms.dashboards.urlParams.assesments}`,
  manageLmsReports: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.dashboards.root}${routeNames.manageLms.dashboards.urlParams.reports}`,
  manageLmsCalendar: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.dashboards.root}${routeNames.manageLms.dashboards.urlParams.calendar}`,
  manageLmsNews: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.dashboards.root}${routeNames.manageLms.dashboards.urlParams.news}`,

  //assignedLearning
  assignedLearning: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}`,
  assignedLearningCreateModule: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}${routeNames.manageLms.assignedLearning.urlParams.createModule}`,
  assignedLearningCategories: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}${routeNames.manageLms.assignedLearning.urlParams.categories}`,
  assignedLearningPts: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}${routeNames.manageLms.assignedLearning.urlParams.pts}`,
  assignedLearningAssessments: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}${routeNames.manageLms.assignedLearning.urlParams.assessments}`,
  assignedLearninCertifications: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}${routeNames.manageLms.assignedLearning.urlParams.certifications}`,
  assignedLearningFeedback: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}${routeNames.manageLms.assignedLearning.urlParams.feedback}`,
  assignedLearningSummary: `${routeNames.app}${routeNames.manageLms.root}${routeNames.manageLms.assignedLearning.root}${routeNames.manageLms.assignedLearning.urlParams.summary}`
};

// const lmsReportsScreenNames = {
//   lmsReports: `${routeNames.app}${routeNames.lmsReports.root}`,
//   lmsReportsDashboard: `${routeNames.app}${routeNames.lmsReports.root}${routeNames.lmsReports.urlParams.main}`,
//   lmsReportsDetails: `${routeNames.app}${routeNames.lmsReports.root}${routeNames.lmsReports.urlParams.details}`
// };

const reportBuildersScreenNames = {
  reportBuilders: `${routeNames.app}${routeNames.reportBuilders.root}`,
  //lp
  lpReportBuilder: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.lp.root}`,
  lpOverallReport: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.lp.root}${routeNames.reportBuilders.lp.overallReport}`,
  //rev
  revReportBuilder: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.rev.root}`,
  revOverallReport: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.rev.root}${routeNames.reportBuilders.rev.overallReport}`,
  //lms
  lmsReportBuilder: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.lms.root}`,
  lmsOverallReport: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.lms.root}${routeNames.reportBuilders.lms.overallReport}`,
  //detailedLms
  detailedLmsReportBuilder: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.detailedLms.root}`,
  detailedLmsOverallReport: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.detailedLms.root}${routeNames.reportBuilders.detailedLms.overallReport}`,
  //logs
  logsReportBuilder: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.logs.root}`,
  logsOverallReport: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.logs.root}${routeNames.reportBuilders.logs.overallReport}`,
  //prodSheet
  psReportBuilder: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.prodSheet.root}`,
  psOverallReport: `${routeNames.app}${routeNames.reportBuilders.root}${routeNames.reportBuilders.prodSheet.root}${routeNames.reportBuilders.prodSheet.overallReport}`
};
const moreScreenName = {
  ...manageLmsScreenNames,
  // ...lmsReportsScreenNames,
  ...reportBuildersScreenNames
};

export const screenNames = {
  ...revScreenNames,
  ...moreScreenName,
  // These are for navbar items
  userHome: `${routeNames.app}${routeNames.userHome.root}`, // same for Tim Hortons logo and Home in Navbar
  // askTell: `${routeNames.app}${routeNames.askTell.root}`,
  userCalender: `${routeNames.app}${routeNames.productionSheet.root}`,
  userResources: `${routeNames.app}${routeNames.userResources.root}`,
  manageResources: `${routeNames.app}${routeNames.manageResources.root}`,
  // detailedResources: `${routeNames.app}${routeNames.userResources.root}${routeNames.userResources.contentList}`,
  reports: `${routeNames.app}${routeNames.reports.root}`,
  rev:
    `${routeNames.app}${routeNames.rev.root}` +
    generatePath(`${routeNames.rev.dashboards.root}`, {
      comp: routeNames.rev.dashboards.urlParams.selfCalibrationsList
    }),

  //LP
  // lp:
  //   `${routeNames.app}${routeNames.lp.root}` +
  //   generatePath(`${routeNames.lp.dashboards.root}`),
  lpAuditsList: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.dashboards.root}${routeNames.lp.dashboards.urlParams.auditsList}`,
  lpActionPlansList: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.dashboards.root}${routeNames.lp.dashboards.urlParams.actionPlansList}`,
  lpAppealsList: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.dashboards.root}${routeNames.lp.dashboards.urlParams.appealsList}`,
  lpReportsList: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.dashboards.root}${routeNames.lp.dashboards.urlParams.reportsList}`,
  lpAudit: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.lpAudit}`,
  detailedReport: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.detailedReport}`,
  detailedAppeal: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.detailedAppeal}`,
  detailedActionPlan: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.detailedActionPlan}`,
  builderReports: `${routeNames.app}${routeNames.lp.root}${routeNames.lp.builderReports}`,

  //LOGS
  logs:
    `${routeNames.app}${routeNames.LOGS.dashboards}` +
    generatePath(`${routeNames.LOGS.dashboards.root}`, {
      comp: routeNames.LOGS.dashboards.urlParams.logs
    }),
  logSpecificStore: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.dashboards.specificStore}`,
  //LMS Routes:-
  lms: `${routeNames.app}${routeNames.LMS.root}`,
  lmsRoot: `${routeNames.app}${routeNames.LMS.root}`,
  lmsCourses: `${routeNames.app}${routeNames.LMS.root}${routeNames.LMS.course}`,
  lmsAssessment: `${routeNames.app}${routeNames.LMS.root}${routeNames.LMS.assessment}`,
  lmsAssessmentQuestion: `${routeNames.app}${routeNames.LMS.root}${routeNames.LMS.assessment}${routeNames.LMS.assessmentQuestion}`,
  lmsAssessmentResultSuccess: `${routeNames.app}${routeNames.LMS.root}${routeNames.LMS.assessment}${routeNames.LMS.assessmentResultSuccess}`,
  lmsAssessmentResultFail: `${routeNames.app}${routeNames.LMS.root}${routeNames.LMS.assessment}${routeNames.LMS.assessmentResultFail}`,
  // These are for Navlink buttons
  selfCalibrations: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.selfCalibrationsList}`,
  appeals: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.appealsList}`,
  actionPlans: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.actionPlansList}`,
  reportNavlink: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.dashboards.root}${routeNames.rev.dashboards.urlParams.reportsList}`,

  // super admin logs
  adminFlow: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.adminFlow}`,
  adminLogsList: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.superAdminDashboard.root}${routeNames.LOGS.superAdminDashboard.urlParams.adminLogsList}`,
  adminLogActions: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.superAdminDashboard.root}${routeNames.LOGS.superAdminDashboard.urlParams.adminLogActions}`,
  adminLogsReports: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.superAdminDashboard.root}${routeNames.LOGS.superAdminDashboard.urlParams.adminLogsReports}`,
  // logs navlink buttons
  logsList: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.dashboards.root}${routeNames.LOGS.dashboards.urlParams.logs}`,
  logActions: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.dashboards.root}${routeNames.LOGS.dashboards.urlParams.actions}`,
  managerReview: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.dashboards.root}${routeNames.LOGS.dashboards.urlParams.managerReview}`,
  logsReports: `${routeNames.app}${routeNames.LOGS.root}${routeNames.LOGS.dashboards.root}${routeNames.LOGS.dashboards.urlParams.reports}`,
  // production sheet navlink buttons
  mainMenu: `${routeNames.app}${routeNames.productionSheet.root}${routeNames.productionSheet.dashboards.root}${routeNames.productionSheet.dashboards.urlParams.mainMenu}`,
  productionSheet: `${routeNames.app}${routeNames.productionSheet.root}${routeNames.productionSheet.dashboards.root}${routeNames.productionSheet.dashboards.urlParams.productionSheet}`,
  productionManagerReview: `${routeNames.app}${routeNames.productionSheet.root}${routeNames.productionSheet.dashboards.root}${routeNames.productionSheet.dashboards.urlParams.productionManagerReview}`,
  productionSummary: `${routeNames.app}${routeNames.productionSheet.root}${routeNames.productionSheet.dashboards.root}${routeNames.productionSheet.dashboards.urlParams.productionSummary}`,
  bakingProdSheet: `${routeNames.app}${routeNames.productionSheet.root}${routeNames.productionSheet.dashboards.bakingProdSheet}`,
  othersProdSheet: `${routeNames.app}${routeNames.productionSheet.root}${routeNames.productionSheet.dashboards.othersProdSheet}`,
  //Login
  login: `${routeNames.IAM.login}`,
  forgotPassword: `${routeNames.IAM.forgotPassword}`,
  builderReport: `${routeNames.app}${routeNames.rev.root}${routeNames.rev.builderReport}`,
  profile: `${routeNames.app}${routeNames.PROFILE.root}`,
  userDetails: `${routeNames.app}${routeNames.PROFILE.root}${routeNames.PROFILE.userDetails}`,
  createStore: `${routeNames.app}${routeNames.PROFILE.root}${routeNames.PROFILE.createStore}`,
  createEmployee: `${routeNames.app}${routeNames.PROFILE.root}${routeNames.PROFILE.createEmployee}`,
  manageUser: `${routeNames.app}${routeNames.PROFILE.root}${routeNames.PROFILE.manageUser}`,
  manageStore: `${routeNames.app}${routeNames.PROFILE.root}${routeNames.PROFILE.manageStore}`,
  calendar: `${routeNames.app}${routeNames.calendar.root}${routeNames.calendar.dashboards.root}`,
  //practicalAssessment
  practicalAssessments: `${routeNames.app}${routeNames.practicalAssessment.root}`,
  // practicalAssessmentsQuiz: `${routeNames.app}${routeNames.practicalAssessment.root}`
  manageNews: `${routeNames.app}${routeNames.manageNews.root}${routeNames.manageNews.dashboards.root}`,
  manageNewsCreate: `${routeNames.app}${routeNames.manageNews.root}${routeNames.manageNews.dashboards.createNew}`,
  //manage Existing Users
  manageExistingUsers: `${routeNames.app}${routeNames.manageExistingUser.root}${routeNames.manageExistingUser.manageExistingUsers}`,
  //askTell
  askTell: `${routeNames.app}${routeNames.askTell.root}${routeNames.askTell.dashboards.root}`,
  supportTickets: `${routeNames.app}${routeNames.askTell.root}${routeNames.askTell.dashboards.root}${routeNames.askTell.dashboards.urlParams.supportTickets}`,
  addQuestions: `${routeNames.app}${routeNames.askTell.root}${routeNames.askTell.dashboards.root}${routeNames.askTell.dashboards.urlParams.addQuestions}`,
  frequentlyAskedQuestions: `${routeNames.app}${routeNames.askTell.root}${routeNames.askTell.dashboards.root}${routeNames.askTell.dashboards.urlParams.frequentlyAskedQuestions}`
};

let finalPathName: string;

const getFinalPath = (pathname?: string): void => {
  for (const [key, value] of Object.entries(screenNames)) {
    if (pathname === `${key}`.toString()) {
      finalPathName = `${value}`;
    }
  }
};
export function navigation(
  history: any,
  pathname?: string,
  search?: object,
  state?: any | null,
  param?: object,
  rootScreen?: Pathnames,
  screen?: Pathnames
): void {
  if (PlatformUtils.isWeb()) {
    getFinalPath(pathname);
    let sentence = "?";
    if (search) {
      let valuesArray = Object.entries(search);
      for (let value of valuesArray) {
        sentence = sentence + `${value[0]}` + "=" + `${value[1]}&`;
      }
    }
    history.push({
      pathname: finalPathName,
      search: search !== null ? sentence.substring(0, sentence.length - 1) : "",
      state: state
    });
  } else {
    if (param) {
      history.navigate(rootScreen, {
        screen: screen,
        params: param
      });
    } else {
      history.navigate(pathname, search);
    }
  }
}
