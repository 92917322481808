import React, { ReactElement } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "@timhortons/common/src/utils/scrollToTop";
import { allRoutesNames as routeNames } from "@timhortons/common/src/utils/allRouteNames";
import AuthenticatedRoute from "navigation/authenticatedRoute";

const PrivateRouter = React.lazy(() => import("./privateRouter"));
const PublicRouter = React.lazy(() => import("navigation/publicRouter"));
const MainRouter = (): ReactElement => {
  return (
    <React.Suspense fallback={null}>
      <Router>
        <ScrollToTop />
        <Switch>
          <AuthenticatedRoute path={routeNames.app} component={PrivateRouter} />
          <PublicRouter />
        </Switch>
      </Router>
    </React.Suspense>
  );
};
export default MainRouter;
