import { Any, JsonObject, JsonProperty } from "json2typescript";
import {
  CommentDataModel,
  QuestionListModel
} from "@timhortons/common/src/models/rev/selfCalibration";

@JsonObject("ActionPlanHeaderModel")
export class ActionPlanHeaderModel {
  @JsonProperty("auditedOn", String)
  auditDate: string = "";
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("auditorName", String)
  auditedBy: string = "";
  @JsonProperty("auditorId", Number)
  auditorId: number = 0;
  @JsonProperty("calibrationId", Number)
  calibrationId: number = 0;
  @JsonProperty("actionPlanId", Number)
  actionPlanId: number = 0;
  @JsonProperty("completedPercentage", Number)
  completionPercent: number = 0;
  @JsonProperty("inProgressPercentage", Number)
  inProcessPercent: number = 0;
  @JsonProperty("notStartedPercentage", Number)
  notStartedPercent: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("completedCount", Number)
  completedItems: number = 0;
  @JsonProperty("inProgressCount", Number)
  inProcessItems: number = 0;
  @JsonProperty("notStartedCount", Number)
  notStartedItems: number = 0;
}

@JsonObject("ActionPlanModel")
export class ActionPlanModel {
  @JsonProperty("auditedOn", String)
  auditDate: Date = new Date();
  @JsonProperty("auditorName", String)
  auditBy: string = "";
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("storeCode", Number)
  storeId: number = 0;
  @JsonProperty("auditorId", Number)
  auditorId: number = 0;
  @JsonProperty("actionPlanCompletionPercentage", Number)
  completionPercentage: number = 0;
  @JsonProperty("actionPlanId", Number)
  actionPlanId: number = 0;
  @JsonProperty("calibrationId", Number)
  calibrationId: number = 0;
  @JsonProperty("planStatus", String)
  status: string = "";
}

@JsonObject("ActionPlanTableModel")
export class ActionPlanTableModel {
  // @JsonProperty("storeId", Number)
  // storeId?: number = 0;
  @JsonProperty("totalRecords", Number)
  totalRecords: number = 0;
  @JsonProperty("records", [ActionPlanModel])
  actionPlans: ActionPlanModel[] = null;
}
@JsonObject("AuditorNameModel")
export class AuditorNameModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
}

@JsonObject("DropDownModel")
export class DropDownModel {
  @JsonProperty("value", String)
  value: string = "";
  @JsonProperty("label", String)
  label: string = "";
}
@JsonObject("FilterDataModel")
export class FilterDataModel {
  @JsonProperty("storeId", String)
  storeId: string = null;
  @JsonProperty("auditorName", AuditorNameModel)
  auditorName: AuditorNameModel[] = null;
  @JsonProperty("date", [Date])
  date: Date[] = null;
  @JsonProperty("status", DropDownModel)
  status: DropDownModel[];
}

@JsonObject("ActionPlanCategoryDataModel")
export class ActionPlanCategoryDataModel {
  @JsonProperty("categoryId", Number)
  id: number = 0;
  @JsonProperty("categoryName", String)
  title: string = "";
  @JsonProperty("opportunityExists", Boolean)
  progress: boolean = false;
  @JsonProperty("categoryLabel", String)
  catgeoryLabel: string = "";
}

@JsonObject("ActionPlanImagesModel")
export class ActionPlanImagesModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("signedUrl", String)
  signedUrl: string = "";
}
@JsonObject("ActionPlanAccordionDataModel")
export class ActionPlanAccordionDataModel {
  @JsonProperty("points", Number, true)
  points?: number = 0;
  @JsonProperty("subCategoryId", Number)
  id: number = 0;
  @JsonProperty("observations", [String], true)
  observations?: string[] = null;
  @JsonProperty("categoryId", Number)
  categoryId: number = 0;
  @JsonProperty("subCategoryLabel", String)
  serialNumber: string = "";
  @JsonProperty("subCategoryName", String)
  title: string = "";
  @JsonProperty("itemStatus", Any)
  status: any = null;
  @JsonProperty("endDate", String, true)
  endDate?: string = "";
  @JsonProperty("actionSteps", String, true)
  actionSteps?: string = "";
  @JsonProperty("auditorComment", String && null)
  auditorComment: string = "";
  @JsonProperty("images", [ActionPlanImagesModel], true)
  file?: Array<ActionPlanImagesModel> = [];
  @JsonProperty("appealDescription", CommentDataModel, true)
  appealDescription?: CommentDataModel = null;
  @JsonProperty("assignedTo", Any)
  ownerData: any = null;
  @JsonProperty("questions", [QuestionListModel], false)
  opportunityList: Array<QuestionListModel> = null;
  @JsonProperty("appealerResponse", Any, true)
  appealerResponse: any = null;
}

@JsonObject("SelectedFilterDataModel")
export class SelectedFilterDataModel {
  @JsonProperty("date", String)
  date: string = "";
  @JsonProperty("name", String)
  name: string = null;
  @JsonProperty("status", String)
  status: string = null;
  @JsonProperty("fromDate", Date)
  fromDate?: Date = null;
  @JsonProperty("toDate", Date)
  toDate?: Date = null;
  @JsonProperty("search", String)
  search?: string = null;
}
