import { LMS_REPORTS } from "@timhortons/common/src/redux/lmsReports/actions/actionTypes";
import {
  LMSEmpDetailedReport,
  ReportsTableDetailsModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";

export interface ILmsReportsLoading {
  getTableDetails: boolean;
  getEmpDetailedReport: boolean;
}
export interface ILmsReportsError {
  getTableDetails: string;
  getEmpDetailedReport: string;
}
export interface ILmsReportsState {
  loading: ILmsReportsLoading;
  error: ILmsReportsError;
  reportsTableDetails: ReportsTableDetailsModel;
  getEmpDetailedReport: LMSEmpDetailedReport;
}

export const lmsReportsInitialState: ILmsReportsState = {
  error: {
    getTableDetails: null,
    getEmpDetailedReport: null
  },
  loading: {
    getTableDetails: false,
    getEmpDetailedReport: false
  },
  reportsTableDetails: null,
  getEmpDetailedReport: null
};

export const lmsReportsReducer = (
  state = lmsReportsInitialState,
  action: { payload: any; type: string }
): ILmsReportsState => {
  switch (action.type) {
    case LMS_REPORTS.GET_REPORTS_TABLE_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTableDetails: true },
        error: { ...state.error, getTableDetails: null }
      };
    case LMS_REPORTS.GET_REPORTS_TABLE_DETAILS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTableDetails: false },
        error: { ...state.error, getTableDetails: null },
        reportsTableDetails: action.payload
      };
    case LMS_REPORTS.GET_REPORTS_TABLE_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTableDetails: false },
        error: { ...state.error, getTableDetails: action.payload }
      };
    //reducer to get emp detailed lms reports
    case LMS_REPORTS.GET_EMP_DETAILED_REPORT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEmpDetailedReport: true },
        error: { ...state.error, getEmpDetailedReport: null }
      };
    case LMS_REPORTS.GET_EMP_DETAILED_REPORT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmpDetailedReport: false },
        error: { ...state.error, getEmpDetailedReport: null },
        getEmpDetailedReport: action.payload
      };
    case LMS_REPORTS.GET_EMP_DETAILED_REPORT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEmpDetailedReport: false },
        error: {
          ...state.error,
          getEmpDetailedReport: action.payload ? action.payload : "apiErrorMessage"
        },
        getEmpDetailedReport: null
      };
    default:
      return state;
  }
};
