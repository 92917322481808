import React, { ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { StyleSheet, View } from "react-native";
import { bindActionCreators } from "redux";
import { fetchYMax, graphColors } from "@timhortons/common/src/utils/graphColorHelper";
import { RevReportsActions } from "@timhortons/common/src/redux/rev/actions/reportsAction";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  ReportsSummaryOpportunityDataModel,
  StoreAccessSummaryDataModel
} from "@timhortons/common/src/models/rev/reports";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import OpportunityTable from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsSummaryOpportunityTable";
import { RevReportsSelectors } from "@timhortons/common/src/redux/rev/selectors/reportsSelector";
import Loader from "@timhortons/common/src/components/molecules/loader";
import {
  IRevReportsError,
  IRevReportsLoading
} from "@timhortons/common/src/redux/rev/reducers/reportsReducer";
import { SelectedMonthFilter } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportsTableHeader";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import CommonBarGraph from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/commonBarGraph";
import ActionplanGraph from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/actionPlanGraph";
import BrandStandardBarGraph from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/brandStandardGraph";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import { NumberOfBars } from "@timhortons/tablet/src/components/molecules/barChart";

interface IProps {
  translate?: any;
  getStoreAccessSummaryData: (value: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => void;
  getStoreAccessOpportunityData: (value: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => void;
  currentUserRoles?: IUserDetails;
  opportunityData?: ReportsSummaryOpportunityDataModel[];
  summaryData?: StoreAccessSummaryDataModel;
  loading: IRevReportsLoading;
  error: IRevReportsError;
  selectedMonth: SelectedMonthFilter;
}

function StoreAccessSummary(props: IProps): ReactElement {
  const {
    translate,
    getStoreAccessSummaryData,
    currentUserRoles,
    getStoreAccessOpportunityData,
    opportunityData,
    summaryData,
    loading,
    selectedMonth,
    error
  } = props;

  const actionPlanGraphData = {
    series: summaryData && summaryData.actionPlansData.series,
    labels: summaryData && summaryData.actionPlansData.labels,
    totalReports: summaryData && summaryData.actionPlansData.totalReports,
    height: 300,
    inProcessReport: summaryData && summaryData.actionPlansData.inProcessReport
  };
  const completeAverageScore = {
    series: [
      {
        data: summaryData && summaryData.completeAverageScore.percentage
      }
    ],
    categories: summaryData && summaryData.completeAverageScore.name,
    categoryColors: graphColors.gradeGraph,
    leftTitle: translate("completeAvgScore"),
    labelSuffix: "%",
    height: 300,
    // ymax: fetchYMax(summaryData && summaryData.completeAverageScore.percentage, 25)
    ymax: 100
  };
  // const monthOnMonthColours = () => {
  //   let monthColors: any[] = [];
  //   summaryData &&
  //     summaryData.monthOnMonthAverageData.percentage.map((item) => {
  //       switch (true) {
  //         case item >= 80:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[0])
  //           };
  //         case item >= 60:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[1])
  //           };
  //         case item >= 40:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[2])
  //           };
  //         case item >= 20:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[3])
  //           };
  //         case item >= 0:
  //           return {
  //             monthColors: monthColors.push(graphColors.gradeGraph[4])
  //           };
  //       }
  //     });
  //   return monthColors;
  // };

  const monthOnMonthAverageScore = {
    series: [
      {
        data: summaryData && summaryData.monthOnMonthAverageData.percentage
      }
    ],
    columnWidth: "25",
    categories: summaryData && summaryData.monthOnMonthAverageData.month,
    // legends: [
    //   {
    //     key: "A",
    //     color: graphColors.gradeGraph[0]
    //   },
    //   {
    //     key: "B",
    //     color: graphColors.gradeGraph[1]
    //   },
    //   {
    //     key: "C",
    //     color: graphColors.gradeGraph[2]
    //   },
    //   {
    //     key: "D",
    //     color: graphColors.gradeGraph[3]
    //   },
    //   {
    //     key: "F",
    //     color: graphColors.gradeGraph[4]
    //   }
    // ],
    isMonthOnMonth: true,
    // categoryColors: monthOnMonthColours(),
    categoryColors: graphColors.gradeGraph,
    leftTitle: translate("monthOnMonthAvgScore"),
    labelSuffix: "%",
    height: 300,
    // ymax: fetchYMax(summaryData && summaryData.monthOnMonthAverageData.percentage, 25)
    ymax: 100
  };

  // const fsDeductionColors = () => {
  //   let fsGraphColors: any[] = [];
  //   summaryData &&
  //     summaryData.foodSafetyDeductionsData.points.map((point) => {
  //       switch (true) {
  //         case point < 4:
  //           return {
  //             fsGraphColors: fsGraphColors.push(graphColors.fsDeductionGraph[0])
  //           };
  //         case point <= 8:
  //           return {
  //             fsGraphColors: fsGraphColors.push(graphColors.fsDeductionGraph[1])
  //           };
  //         case point > 8:
  //           return {
  //             fsGraphColors: fsGraphColors.push(graphColors.fsDeductionGraph[3])
  //           };
  //       }
  //     });
  //   return fsGraphColors;
  // };

  const fsDeductionGraphData = {
    series: [
      {
        data: summaryData && summaryData.foodSafetyDeductionsData.points
      }
    ],
    columnWidth: "20px",
    categories: summaryData && summaryData.foodSafetyDeductionsData.month,
    // categoryColors: fsDeductionColors(),
    categoryColors: graphColors.fsDeductionGraph,
    yAxisTitle: "Deductions",
    xAxisTitle: "Months",
    leftTitle: translate("foodSafetyDeductions"),
    rightTitle: `${translate("avgScore")} : ${
      summaryData && summaryData.foodSafetyDeductionsData.averageScore
    }pp`,
    isFSDeduction: true,
    labelSuffix: "pp",
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    ymax: fetchYMax(summaryData && summaryData.foodSafetyDeductionsData.points, 25)
  };

  const bsGraphData = {
    series: [
      {
        data: summaryData && summaryData.brandStandardsData.series
      }
    ],
    categories: summaryData && summaryData.brandStandardsData.categories,
    // categories: ["C & M", " G.U.E.S.T", "SOS", "PSM", "Training", "Training", "Food Quality"],
    categoryColors: graphColors.bsGraph,
    leftTitle: translate("brandStandards"),
    rightTitle: `${translate("avgScore")} : ${
      summaryData && summaryData.brandStandardsData.averageScore
    }%`,
    isBSGraph: true,
    height: 300,
    labelSuffix: "%",
    ymax: fetchYMax(summaryData && summaryData.brandStandardsData.series, 25)
  };
  const sosGraphData = {
    series: [
      {
        data: summaryData && summaryData.sosGraphData.series
      }
    ],
    categories: summaryData && summaryData.sosGraphData.categories,
    categoryColors: graphColors.bsGraph,
    leftTitle: translate("averageSOSTime"),
    isSoS: true,
    height: 300,
    labelSuffix: "min",
    ymax: fetchYMax(summaryData && summaryData.sosGraphData.series, 5)
  };

  useEffect(() => {
    getStoreAccessSummaryData({
      storeId: currentUserRoles.storeCode,
      startDate: convert(new Date(selectedMonth.year, selectedMonth.month.id, 1)),
      endDate: convert(new Date(selectedMonth.year, selectedMonth.month.id + 1, 0))
    });
    getStoreAccessOpportunityData({
      storeId: currentUserRoles.storeCode,
      startDate: convert(new Date(selectedMonth.year, selectedMonth.month.id, 1)),
      endDate: convert(new Date(selectedMonth.year, selectedMonth.month.id + 1, 0))
    });
  }, [selectedMonth]);

  return (
    <>
      {error.storeAccessSummaryData ? (
        <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
      ) : loading.storeAccessSummaryData || summaryData === null ? (
        <Loader type="component" />
      ) : (
        <View style={styles.container}>
          <View style={styles.elementConatiner}>
            <View style={styles.storeGraphContainer}>
              <CommonBarGraph
                barWidthSpace={NumberOfBars.OneBar}
                barChartData={completeAverageScore}
              />
            </View>
            <View style={styles.momGraphContainer}>
              <CommonBarGraph
                barChartData={monthOnMonthAverageScore}
                barWidthSpace={NumberOfBars.OneBar}
              />
            </View>
          </View>
          <View style={styles.elementConatiner}>
            <View style={styles.actionplanGraphContainer}>
              <ActionplanGraph
                translate={translate}
                series={actionPlanGraphData.series}
                labels={actionPlanGraphData.labels}
                totalReports={actionPlanGraphData.totalReports}
                // inProcessReport = {act}
                inProcessReport={actionPlanGraphData.inProcessReport}
                height={310}
                leftHeader={translate("Action Plans")}
              />
            </View>
            <View style={styles.fsGraphConatiner}>
              <CommonBarGraph barChartData={fsDeductionGraphData} />
            </View>
          </View>
          <View style={styles.bsTableContainer}>
            <BrandStandardBarGraph
              brandStandardGraphData={bsGraphData}
              SoSGraphData={sosGraphData}
            />
          </View>
        </View>
      )}
      <View>
        <OpportunityTable
          translate={translate}
          currentUserRoles={currentUserRoles && currentUserRoles.roles}
          opportunityData={opportunityData}
          loading={loading.storeAccessOpportunityData}
          error={error.storeAccessOpportunityData}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {},
  elementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  storeGraphContainer: {
    flex: 1
  },
  momGraphContainer: {
    flex: 1,
    marginLeft: 24
  },
  actionplanGraphContainer: {
    flex: 1
  },
  fsGraphConatiner: {
    flex: 1,
    marginLeft: 24
  },
  bsTableContainer: {
    marginBottom: 24
  },
  opportunityTableContainer: {
    marginBottom: 24
  },
  storeRankingTableContainer: {},
  fsGraphYAxisStyle: {
    alignSelf: "center",
    transform: [{ rotate: "270deg" }, { translateY: -275 }, { translateX: -150 }]
  }
});

const mapStateToProps = (state: IState) => {
  return {
    opportunityData: RevReportsSelectors.fetchStoreAccessOpportunityData(state),
    summaryData: RevReportsSelectors.fetchStoreAccessSummaryData(state),
    loading: RevReportsSelectors.revReportsLoading(state),
    error: RevReportsSelectors.revReportsError(state),
    selectedMonth: RevReportsSelectors.getSelectedMonthFilter(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...RevReportsActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreAccessSummary);
