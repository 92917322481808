import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { ActionLogsRepository } from "@timhortons/common/src/repositories/logs/actionsLogs";
import { actionManagerWalkthoughActions } from "@timhortons/common/src/redux/logs/actions/actionLogs/managerWalkthrough";
import {
  ILogShift,
  IUpdateActionsRequest
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { LogShiftModel } from "@timhortons/common/src/models/logs/mainLogs";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";

function* getActionManagerWalkthroughShiftWorkerSaga(value: IFluxStandardAction<ILogShift>) {
  const { payload } = value;
  try {
    let data: LogShiftModel[] = yield call(MainLogsRepository.getLogShift, payload);
    yield put(actionManagerWalkthoughActions.getActionManagerWalkthroughShiftSuccess(data));
  } catch (error) {
    Logger.error(
      "actionLogs-managerWalkActions",
      error,
      "getActionManagerWalkthroughShiftWorkerSaga"
    );

    yield put(actionManagerWalkthoughActions.getActionManagerWalkthroughShiftError(error));
  }
}

function* getActionManagerWalkthroughActionsWorkerSaga(
  value: IFluxStandardAction<{
    categoryId: string;
    storeCode: number;
    date: string;
    label: string;
  }>
) {
  const { payload } = value;
  try {
    let data: {} = yield call(ActionLogsRepository.getActionManagerWalkthroughActions, payload);
    yield put(actionManagerWalkthoughActions.getActionManagerWalkthroughActionsSuccess(data));
  } catch (error) {
    Logger.error(
      "actionLogs-managerWalkActions",
      error,
      "getActionManagerWalkthroughActionsWorkerSaga"
    );

    yield put(actionManagerWalkthoughActions.getActionManagerWalkthroughActionsError(error));
  }
}

function* updateActionManagerWalkthroughActionsWorkerSaga(
  value: IFluxStandardAction<IUpdateActionsRequest>
) {
  const { payload } = value;
  try {
    let data: {} = yield call(ActionLogsRepository.updateActionManagerWalkthroughActions, payload);
    yield put(
      actionManagerWalkthoughActions.updateActionManagerWalkthroughActionsSuccess({
        ...data,
        uniqueId: payload.itemId
      })
    );
  } catch (error) {
    Logger.error(
      "actionLogs-managerWalkActions",
      error,
      "updateActionManagerWalkthroughActionsWorkerSaga"
    );

    yield put(actionManagerWalkthoughActions.updateActionManagerWalkthroughActionsError(error));
  }
}

function* managerWalkActionWatcherSaga() {
  yield takeLatest(
    LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALTHROUGH_SHIFTS.LOADING,
    getActionManagerWalkthroughShiftWorkerSaga
  );
  yield takeLatest(
    LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS.LOADING,
    getActionManagerWalkthroughActionsWorkerSaga
  );
  yield takeLatest(
    LOGS.Actions.ManagerWalk.UPDATE_MANAGER_WALKTHROUGH_ACTIONS.LOADING,
    updateActionManagerWalkthroughActionsWorkerSaga
  );
}
export default managerWalkActionWatcherSaga;
