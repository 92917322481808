import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { Logger } from "@timhortons/common/src/services/logger";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";
import { ManagerWalkthroughActions } from "@timhortons/common/src/redux/logs/actions/mainLogs/managerWalkthrough";
import { IFluxStandardAction } from "store/interfaces";
import {
  IMwtItemApiPayload,
  ITimeTempCategories,
  LogsAuthPayload
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import {
  LogsAuthModel,
  WeeklyCommentModel,
  WeeklyPostItemsResponseModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { getTimeZone } from "@timhortons/common/src/utils/dateFormat";

function* getManagerWalkthroughCategoryWorkerSaga(
  value: IFluxStandardAction<{ actionName: string; storeCode: number; date: string; label: string }>
) {
  const { payload } = value;
  try {
    let data: ITimeTempCategories[] = yield call(MainLogsRepository.getTimeTempCategories, payload);
    yield put(ManagerWalkthroughActions.getManagerWalkthroughCategorySuccess(data));
  } catch (error) {
    Logger.error("mainLogs-managerWalkthrough", error, "getManagerWalkthroughCategoryWorkerSaga");

    yield put(ManagerWalkthroughActions.getManagerWalkthroughCategoryError(error));
  }
}
function* getManagerWalkthroughItemsByCategoryWorkerSaga(
  value: IFluxStandardAction<IMwtItemApiPayload>
) {
  const { payload } = value;
  try {
    let data: {} = yield call(MainLogsRepository.getManagerWalkthroughItemsByCategory, payload);
    yield put(ManagerWalkthroughActions.getManagerWalkthroughItemsSuccess(data));
  } catch (error) {
    Logger.error(
      "mainLogs-managerWalkthrough",
      error,
      "getManagerWalkthroughItemsByCategoryWorkerSaga"
    );

    yield put(ManagerWalkthroughActions.getManagerWalkthroughItemsByError(error));
  }
}
function* postMWTItemsWorkerSaga(value: IFluxStandardAction<WeeklyCommentModel>) {
  const { payload } = value;

  try {
    let data: WeeklyPostItemsResponseModel = yield call(
      MainLogsRepository.postManagerWalkthroughItems,
      payload
    );
    let finalData = {
      ...data,
      questionId: payload.questionId,
      categoryId: payload.categoryId
    };
    yield put(ManagerWalkthroughActions.postMWTItemsSuccess(payload.questionId ? finalData : data));
  } catch (error) {
    Logger.error("mainLogs-managerWalkthrough", error, "postMWTItemsWorkerSaga");

    yield put(ManagerWalkthroughActions.postMWTItemsError(error));
  }
}
function* mwtlogsAuthWorkerSaga(value: IFluxStandardAction<LogsAuthPayload>) {
  const { payload } = value;
  try {
    let data: LogsAuthModel = yield call(MainLogsRepository.checkLogsAuth, payload);
    yield put(ManagerWalkthroughActions.checkMWTLogsAuthSuccess(data));
    let body = {
      timeZone: getTimeZone(),
      data: payload
    };

    yield call(MainLogsRepository.updateLogsAuth, body);
  } catch (error) {
    Logger.error("mainLogs-managerWalkthrough", error, "mwtlogsAuthWorkerSaga");

    yield put(ManagerWalkthroughActions.checkMWTLogsAuthError(error));
  }
}
function* managerWalkthroughWatcherSaga() {
  yield takeLatest(
    LOGS.MainLogs.ManagerWalk.GET_MANAGER_WALKTHROUGH_CATEGORIES.LOADING,
    getManagerWalkthroughCategoryWorkerSaga
  );
  yield takeLatest(
    LOGS.MainLogs.ManagerWalk.GET_ITEMS_LIST_BY_CATEGORY.LOADING,
    getManagerWalkthroughItemsByCategoryWorkerSaga
  );
  yield takeLatest(LOGS.MainLogs.ManagerWalk.CHECK_MWT_LOGS_AUTH.LOADING, mwtlogsAuthWorkerSaga);
  yield takeLatest(LOGS.MainLogs.ManagerWalk.POST_MWT_ITEMS.LOADING, postMWTItemsWorkerSaga);
}
export default managerWalkthroughWatcherSaga;
