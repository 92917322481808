import React, { useEffect, useRef, useState } from "react";
import PageHeader from "./organisms/pageHeader";
import PageBody from "./organisms/pageBody";
import { View, StyleSheet, LayoutChangeEvent } from "react-native";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import Carousel from "@timhortons/common/src/components/molecules/carousel";
import { IState } from "@timhortons/common/src/store/interfaces";
import { MainMenuActions } from "@timhortons/common/src/redux/productionSheet/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MainMenuSelectors } from "@timhortons/common/src/redux/productionSheet/selectors/mainMenu";
import {
  IProductList,
  ICategoryList,
  PostMainMenuPayload,
  PostMainMenuApiResp
} from "@timhortons/common/src/models/productionSheet/mainMenu";
import { IUserDetails } from "modules/iam/redux/reducer/reducer";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import {
  IMainMenuError,
  IMainMenuLoading
} from "@timhortons/common/src/redux/productionSheet/reducers/mainMenu";
import { correct, oopsCookie } from "@timhortons/common/src/assets/images/imageAssets";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import { localDateToTZ } from "@timhortons/common/src/utils/dateFormat";
import BakingPageBody from "@timhortons/common/src/modules/productionSheet/components/mainMenu//organisms/bakingPageBody";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import AuditModal from "@timhortons/common/src/modules/common/organisms/authModal/index";

interface IProps {
  translate?: any;
  getAllCategoryAndProducts: Function;
  getAllCategoryProductsData: any;
  allStationsData: IProductList[];
  postCategoryAndProducts: (value: PostMainMenuPayload) => void; //Post Api
  postCatAndProdData: PostMainMenuApiResp[];
  user: IUserDetails;
  loading: IMainMenuLoading;
  error: IMainMenuError;
  resetPostMainMenu: Function;
  getAllStations: Function;
}
export enum MainMenuTableId {
  BakingTableId = "ef9cd217-b677-4648-82f8-f8e378bfcdcc",
  OthersTableId = "ef628202-0eb6-4b85-a7e0-51124c265368"
}

function MainMenu(props: IProps) {
  const {
    translate,
    getAllCategoryAndProducts,
    getAllCategoryProductsData,
    allStationsData,
    postCategoryAndProducts,
    postCatAndProdData,
    user,
    loading,
    error,
    resetPostMainMenu,
    getAllStations
  } = props;
  const ref = useRef(null);

  const [flatListWidth, setFlatListWidth] = useState<number>(0);
  const [selectedButtonId, setSelectedButtonId] = useState<string>(null);
  const [productData, setProductData] = useState<any>(null);
  const [stationList, setStationList] = useState<IProductList[]>(null);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [updatedProductData, setUpdatedProductData] = useState<any>(null);
  const [enablePrevNextButton, setEnablePrevNextButton] = useState<{
    prev: boolean;
    next: boolean;
  }>({ prev: false, next: false });

  const headerdummy = [
    {
      id: MainMenuTableId.BakingTableId,
      product: translate("baking"),
      isSelected: true
    },
    {
      id: MainMenuTableId.OthersTableId,
      product: translate("others"),
      isSelected: false
    }
  ];
  const isFocused = useFocusedHook();

  useEffect(() => {
    if (isFocused && user) {
      isFocused && getAllStations();
    }
  }, [isFocused, user]);

  useEffect(() => {
    if (allStationsData) {
      // setStationList(allStationsData);
      setStationList(headerdummy);
      setSelectedButtonId(allStationsData[0]?.id);
    }
  }, [allStationsData]);

  useEffect(() => {
    if (selectedButtonId) {
      getAllCategoriesProd();
    }
  }, [selectedButtonId]);

  useEffect(() => {
    setProductData(
      getAllCategoryProductsData && JSON.parse(JSON.stringify(getAllCategoryProductsData))
    );
  }, [getAllCategoryProductsData]);

  useEffect(() => {
    if (error && error.postCategoryAndProducts !== null) {
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (postCatAndProdData) {
      setShowError(true);
    }
  }, [postCatAndProdData]);

  const getAllCategoriesProd = () => {
    getAllCategoryAndProducts({
      storeCode: user.storeCode,
      stationCode: selectedButtonId,
      date: localDateToTZ()
    });
  };

  const handleLayout = (event: LayoutChangeEvent) => {
    setFlatListWidth(event.nativeEvent.layout.width);
  };

  const handleIndexCallBack = (enable: { prev: boolean; next: boolean }) => {
    setEnablePrevNextButton({
      prev: enable.prev,
      next: enable.next
    });
  };

  const handleSaveButtonClick = () => {
    setAuthModalVisible(true);
  };

  const handleAuth = async (empId: string) => {
    setEmployeeId(empId);
    setShowWarning(false);
    let postData: any = [];
    {
      selectedButtonId === MainMenuTableId.BakingTableId
        ? updatedProductData.map((item: any) => {
            item.products.map((el: any) => {
              el.items.map((el: any) => {
                if (el.isSelected) {
                  let obj: any = {};
                  obj.id = el.id;
                  obj.isSelected = el.isSelected;
                  postData.push(obj);
                }
              });
            });
          })
        : updatedProductData.map((item: any) => {
            item.products.map((el: any) => {
              if (el.isSelected) {
                let obj: any = {};
                obj.id = el.id;
                obj.isSelected = el.isSelected;
                postData.push(obj);
              }
            });
          });
    }
    if (empId && postData) {
      handlePostCall(empId, postData);
    }
  };
  function handlePostCall(empId: string, postData: any) {
    postCategoryAndProducts({
      products: postData,
      storeCode: user.storeCode,
      employeeId: empId,
      date: localDateToTZ(),
      stationId: selectedButtonId
    });
  }
  return error.getAllStations ? (
    <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
  ) : loading.getAllStations ? (
    <Loader type="component" />
  ) : stationList && stationList.length === 0 ? (
    <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
  ) : (
    <>
      <PageHeader
        showSwipe={getAllCategoryProductsData?.length > 3 ? true : false}
        translate={translate}
        reference={ref}
        stationList={stationList}
        setSelectedButtonId={setSelectedButtonId}
        selectedButtonId={selectedButtonId}
        enablePrevNextButton={enablePrevNextButton}
        handleSaveButtonClick={handleSaveButtonClick}
        showWarning={showWarning}
        setShowWarning={setShowWarning}
      />
      {error.getAllCategoryAndProducts ? (
        <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
      ) : loading.getAllCategoryAndProducts ? (
        <Loader type="component" />
      ) : productData && productData.length === 0 ? (
        <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
      ) : (
        <View onLayout={handleLayout} style={{ flex: 1 }}>
          {selectedButtonId === MainMenuTableId.BakingTableId ? (
            <>
              <Carousel
                nextIndexParent={2}
                listData={productData}
                extractorHandler={(item) => item.id}
                listRenderer={(item: any) => (
                  <>
                    <BakingPageBody
                      productList={item}
                      flatListWidth={flatListWidth}
                      allProductData={productData}
                      setShowWarning={setShowWarning}
                      getAllCategoryProductsData={getAllCategoryProductsData}
                      setUpdatedProductData={setUpdatedProductData}
                    />
                  </>
                )}
                parentRef={ref}
                horizontalToggler
                seperator={() => <View style={styles.divider} />}
                handleIndexCallBack={handleIndexCallBack}
              />
            </>
          ) : (
            <Carousel
              nextIndexParent={2}
              listData={productData}
              extractorHandler={(item) => item.id}
              listRenderer={(item: ICategoryList) => (
                <>
                  <PageBody
                    productList={item}
                    flatListWidth={flatListWidth}
                    allProductData={productData}
                    setUpdatedProductData={setUpdatedProductData}
                    setShowWarning={setShowWarning}
                    getAllCategoryProductsData={getAllCategoryProductsData}
                  />
                </>
              )}
              parentRef={ref}
              horizontalToggler
              seperator={() => <View style={styles.divider} />}
              handleIndexCallBack={handleIndexCallBack}
            />
          )}
          {showError && (
            <AlertModal
              setModalVisible={() => {
                setShowError(!showError);
                resetPostMainMenu();
              }}
              errorImage={
                postCatAndProdData !== null && error.postCategoryAndProducts === null
                  ? correct
                  : oopsCookie
              }
              errorMsg={
                postCatAndProdData !== null && error.postCategoryAndProducts === null
                  ? postCatAndProdData !== null &&
                    error.postCategoryAndProducts === null &&
                    translate("postSuccess")
                  : error.postCategoryAndProducts
              }
            />
          )}
          {authModalVisible && (
            <GetModal setModalState={setAuthModalVisible}>
              <AuditModal
                handleDisabled={loading.postCategoryAndProducts}
                handleAuth={handleAuth}
                translate={translate}
                modalVisible={authModalVisible}
                setModalState={() => setAuthModalVisible(false)}
                isIndividualLogin={false}
                buttonText={translate("save")}
              />
            </GetModal>
          )}
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  divider: {
    width: 30
  }
});

const mapStateToProps = (state: IState) => {
  return {
    loading: MainMenuSelectors.mainMenuLoading(state),
    error: MainMenuSelectors.mainMenuError(state),
    getAllCategoryProductsData: MainMenuSelectors.getAllCategoryAndProducts(state),
    postCatAndProdData: MainMenuSelectors.postCategoryAndProducts(state),
    allStationsData: MainMenuSelectors.getAllStations(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({ ...MainMenuActions }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
