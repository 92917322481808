import { all } from "@redux-saga/core/effects";
import selfCalibrationWatcherSaga from "@timhortons/common/src/redux/rev/saga/selfCalibrationWatcherSaga";
import revReportsWatcherSaga from "@timhortons/common/src/redux/rev/saga/revReportsWatcherSaga";
import actionPlanWatcherSaga from "@timhortons/common/src/redux/rev/saga/actionPlanWatcherSaga";
import appealsWatcherSaga from "@timhortons/common/src/redux/rev/saga/appealsWatcherSaga";
import categoryWatcherSaga from "@timhortons/common/src/redux/rev/saga/categoryWatcherSaga";

export default function* revRootSaga() {
  yield all([
    selfCalibrationWatcherSaga(),
    revReportsWatcherSaga(),
    actionPlanWatcherSaga(),
    appealsWatcherSaga(),
    categoryWatcherSaga()
  ]);
}
