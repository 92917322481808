import moment from "moment";
import { Linking } from "react-native";

export interface IStartEndDate {
  startDate: Date;
  endDate: Date;
}

export function dateFormat(value: any, isMonthLong?: boolean) {
  if (value) {
    if (value[10] === "T" && value?.slice(-1) !== "Z") {
      value = value + "Z";
    }
    value = new Date(value);
    let dateNew: string;
    let finalDate: string;
    let monthLong: string;
    dateNew = value.toString().slice(4, 15);
    monthLong = value.toLocaleString("default", { month: "long" });
    finalDate =
      (isMonthLong ? monthLong + " " + dateNew.substring(3, 6) : dateNew.substring(0, 6)) +
      ", " +
      dateNew.substring(7, 15);
    return finalDate;
  }
}
export function appendTZeroFormat(value: any) {
  if (value) {
    let val = dateFormat(value.substring(0, 10).concat("T00:00:00"));
    return val;
  }
}

export function timeFormat(value: any) {
  if (value) {
    if (value[10] === "T" && value?.slice(-1) !== "Z") {
      value = value + "Z";
    }
    value = new Date(value);
    let timeNew: string;
    let hours = value.getHours() < 10 ? "0" + value.getHours() : value.getHours();
    let minutes = value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes();
    timeNew = hours + ":" + minutes;
    return timeNew;
  }
}
export function toTitleCase(value: string) {
  return value
    .split("_")
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
}
export function convert(Idate: any) {
  var date = new Date(Idate),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function timFormat12hr(value: any) {
  if (value) {
    if (value[10] === "T" && value?.slice(-1) !== "Z") {
      value = value + "Z";
    }
    value = new Date(value);
    let hours = value.getHours();
    let minutes = value.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let updatedHours = hours < 10 ? "0" + hours : hours; // the hour '0' should be '12'
    minutes = minutes < 10 ? Number("0" + minutes) : Number(minutes);
    let minutesUpdated = minutes < 10 ? "0" + minutes : minutes;
    return updatedHours + ":" + minutesUpdated + " " + ampm;
  }
}
export const doubleformatDatePicker = (selectedDate: Date[]) => {
  if (selectedDate) {
    let a = selectedDate[0];
    let b = selectedDate[1];
    const firstDate = new Date(a).toString();
    const secondDate = new Date(b).toString();
    let dateNew: string;
    dateNew = firstDate.slice(4, 10) + " " + "-" + " " + secondDate.slice(4, 10);
    return dateNew;
  }
};
export const yearFormat = (selectedDate: Date[]) => {
  if (selectedDate) {
    let a = selectedDate[0];
    let b = selectedDate[1];
    const firstDate = new Date(a).toString().slice(4, 15);
    const secondDate = new Date(b).toString().slice(4, 15);
    return (
      firstDate.substring(0, 6) +
      ", " +
      firstDate.substring(7, 15) +
      " to " +
      secondDate.substring(0, 6) +
      ", " +
      secondDate.substring(7, 15)
    );
  }
};

export const convert12hrTimeTo24Hr = (timeStr: any) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}:00`;
};

export function convert24hrTimeTo12Hr(time24: any) {
  var ts = time24;
  var H = +ts.substr(0, 2);
  var h = H % 12 || 12;
  var hh = h < 10 ? "0" + h : h;
  var ampm = H < 12 ? " AM" : " PM";
  ts = hh + ts.substr(2, 3) + ampm;
  return ts;
}

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export function addDays(days: number, startDate: string): Date {
  let date = new Date();
  if (days && startDate) {
    date = new Date(startDate);
    date.setDate(date.getDate() + days);
  }
  return date;
}
export function subtractDays(days: number, startDate: Date): Date {
  let date = new Date();
  if (days && startDate) {
    date = new Date(startDate);
    date.setDate(date.getDate() - days);
  }
  return date;
}

export const getYesterdayDate = () => {
  let todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  return yesterdayDate;
};

export function convertISOto12hr(time: string) {
  if (time) {
    if (time[10] === "T" && time?.slice(-1) !== "Z") {
      time = time + "Z";
    }
    var hours24 = new Date(time).getUTCHours(); // retrieve current hours (in 24 mode)
    var dayMode = hours24 < 12 ? "AM" : "PM"; // if it's less than 12 then "am"
    var hours12 = hours24 <= 12 ? (hours24 == 0 ? 12 : hours24) : hours24 - 12;
    return hours12 + " " + dayMode;
  }
}
export function localDateToTZ() {
  let z = new Intl.DateTimeFormat("en-GB").format(new Date());
  let p = z.split("/");
  return p[2] + "-" + p[1] + "-" + p[0] + "T00:00:00";
}

export const secondsToHourMin = (time: number) => {
  let moduleDuration = time;
  let min = Math.ceil(moduleDuration / 60);
  let hr, remainingMin;
  if (min == 60) {
    min = 0;
    hr = 1;
  } else if (min < 60) {
    min = min;
    hr = 0;
  } else {
    hr = Math.floor(moduleDuration / 3600);
    remainingMin = moduleDuration % 3600;
    min =
      remainingMin !== 0
        ? remainingMin % 60 !== 0
          ? Math.floor(remainingMin / 60) + 1
          : Math.floor(remainingMin / 60)
        : 0;
  }
  return { hr: hr, min: min };
};
export function dateWithSlash(Idate: any) {
  var date = new Date(Idate),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
}

// Date format - Friday, 12 October - 2022
export function dayAndMonthFormat(value: any) {
  let date = value
    .toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric"
    })
    .split(",");
  date[1] = date[1].trim().split(" ").reverse().join(" ");

  return date[0] + ", " + date[1] + " - " + date[2].trim();
}
//To Check if its today's date
export const isTodaysDate = (date: string) => {
  const inputDate = new Date(date);
  const todaysDate = new Date();
  if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
};

// Time format - 04:30pm
export const getTime = (date: string) => {
  const inputDate = new Date(date);
  const time = new Date(inputDate).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit"
  });
  return time.replace(" AM", "am").replace(" PM", "pm");
};

//Days format - 2 Days ago
export const getNoOfDaysAgo = (fromDate: string) => {
  const CreatedDate = new Date(fromDate);
  const today = new Date();
  let requiredDiffrentDays;

  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(Math.abs((CreatedDate - today) / oneDay));

  if (diffDays >= 360) {
    requiredDiffrentDays =
      Math.floor(diffDays / 360) == 1
        ? `${Math.floor(diffDays / 365)} Year ago`
        : `${Math.floor(diffDays / 365)} Years ago`;
  } else if (diffDays >= 30) {
    requiredDiffrentDays =
      Math.floor(diffDays / 30) == 1
        ? `${Math.floor(diffDays / 30)} Month ago`
        : `${Math.floor(diffDays / 30)} Months ago`;
  } else if (diffDays < 30) {
    requiredDiffrentDays =
      diffDays == 1 || diffDays == 0 ? `${diffDays} Day ago` : `${diffDays} Days ago`;
  }

  return requiredDiffrentDays;
};

const convertUTCToLocalTime = (dateString: string) => {
  let date = new Date(dateString);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  const localTime = new Date(milliseconds);
  return localTime.getTime(); // local date
};

const convertUTCToLocalTimeMoment = (dateString: string) => {
  const utcMoment = moment.utc(dateString);
  const localMoment = utcMoment.local();
  return localMoment.valueOf(); // local date in milliseconds
};

export const getDurationDifference = (date: string) => {
  var delta = Math.abs(new Date().getTime() - convertUTCToLocalTimeMoment(date));

  // calculate (and subtract) whole days
  let days = delta / (1000 * 3600 * 24);
  let hours = delta / (1000 * 3600);
  let minutes = delta / (1000 * 60);
  let secs = delta / 1000;
  if (days >= 1) {
    return Math.floor(days) + "days ago";
  } else if (hours >= 1) {
    return Math.floor(hours) + "hrs ago";
  } else if (minutes >= 1) {
    return Math.floor(minutes) + "m ago";
  }

  return Math.floor(secs) + "sec ago";

  // calculate (and subtract) whole hours

  // calculate (and subtract) whole minutes
};

// Adding months to date
export const addMonths = (date: string, monthsToAdd: number) => {
  const inputDate = new Date(date);
  const updatedDate = new Date(inputDate.setMonth(inputDate.getMonth() + monthsToAdd));
  return dateFormat(updatedDate);
};

export const convertDateArr = (fromDate: any) => {
  if (fromDate && fromDate.length >= 2) {
    let dateMonth = new Date(fromDate[0], fromDate[1] - 1, fromDate[2]).toLocaleString("default", {
      month: "short"
    });
    return `${dateMonth} ${fromDate[2]}, ${fromDate[0]}`;
  }
  return null;
};

export const minuteToHourMin = (minutes: number) => {
  if (minutes > 0) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return hours > 0
      ? `${hours}hr` + `${remainingMinutes > 0 ? ` ${remainingMinutes}min` : ""}`
      : `${remainingMinutes}min`;
  }
  return "0min";
};

export const currWeekStartEndDate = (isMonStart?: boolean): IStartEndDate => {
  const now = new Date();
  let metaValue = 0;
  if (isMonStart) metaValue = now.getDay() === 0 ? -6 : 1;

  const startOfWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - now.getDay() + metaValue
  );
  const endOfWeek = new Date(
    startOfWeek.getFullYear(),
    startOfWeek.getMonth(),
    startOfWeek.getDate() + 6
  );

  return { startDate: startOfWeek, endDate: endOfWeek };
};

export const currMonthStartEndDate = (): IStartEndDate => {
  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  return { startDate: startOfMonth, endDate: endOfMonth };
};

export function dateToTZ(recvDate: Date): string {
  let z = new Intl.DateTimeFormat("en-GB").format(recvDate);
  let p = z.split("/");
  //yyy-mm-dd
  return p[2] + "-" + p[1] + "-" + p[0];
}

export const numDaysAgoStartEndDate = (numOfDays: number): IStartEndDate => {
  if (numOfDays > 0) {
    const startDate = new Date(Date.now() - numOfDays * 24 * 60 * 60 * 1000);
    const endDate = new Date();
    return { startDate, endDate };
  }
};

export const toHeadlineCase = (text: string) => {
  return text
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
