import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";
import { prodsheetKpiData } from "@timhortons/common/src/models/productionSheet/reportBuilder";

const generateReportBuilder = (data: {
  object: any;
  reportType: string;
  countryCodes?: any;
  allSelected?: boolean;
  prodSheetType: string;
}): IFluxStandardAction<{
  object: any;
  reportType: string;
  countryCodes?: any;
  allSelected?: boolean;
  prodSheetType: string;
}> => {
  return {
    type: PRODUCTION_SHEET.ReportBuilder.GENERATE_REPORT.LOADING,
    payload: data
  };
};
const generateReportBuilderSuccess = (data: prodsheetKpiData[]): IFluxStandardAction<any> => {
  return {
    type: PRODUCTION_SHEET.ReportBuilder.GENERATE_REPORT.SUCCESS,
    payload: data
  };
};
const generateReportBuilderError = (data: {
  error: Error;
  reportType: ComparsionLevel;
}): IFluxStandardAction<{ error: Error; reportType: ComparsionLevel }> => {
  return {
    type: PRODUCTION_SHEET.ReportBuilder.GENERATE_REPORT.ERROR,
    payload: data
  };
};

export const ProductionReportsActions = {
  generateReportBuilder,
  generateReportBuilderSuccess,
  generateReportBuilderError
};
