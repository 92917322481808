import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "lmsReports";

const LMS_REPORTS = {
  GET_REPORTS_TABLE_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_REPORTS_TABLE_DETAILS"),
  GET_EMP_DETAILED_REPORT: ActionHelper.actionTypesCreator(prefix, "GET_EMP_DETAILED_REPORT"),
  detailedLms: {
    GET_ALL_MODULES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_MODULES"),
    GENERATE_REPORT: ActionHelper.actionTypesCreator(prefix, "GENERATE_REPORT"),
    RESET_REPORT_BUILDER: "RESET_REPORT_BUILDER"
  }
};

export { LMS_REPORTS };
