import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { Logger } from "@timhortons/common/src/services/logger";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import { ProductionSheetRepository } from "@timhortons/common/src/repositories/productionSheet/productionSheet";
import { ProductionSheetActions } from "@timhortons/common/src/redux/productionSheet/actions/productionSheet";
import {
  AmPmEmployeeProducts,
  BakingStationCatResp,
  GetBakingCategories,
  IBakingPSPostApiPayload,
  IGetBakingProducts,
  IGetOtherProducts,
  IOtherProductionSheetPostData,
  PostStationAuthPayload,
  PostStationAuthResp,
  StationProducts
} from "@timhortons/common/src/models/productionSheet/psModels";
import {
  GetSubCategoryPayload,
  IHeaderList
} from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";

function* getStationProductsWorkerSaga(value: IFluxStandardAction<GetSubCategoryPayload>) {
  let { payload } = value;
  try {
    const response: StationProducts[] = yield call(
      ProductionSheetRepository.getStationProducts,
      payload
    );
    yield put(ProductionSheetActions.getStationProductsSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "getStationProductsWorkerSaga");

    yield put(ProductionSheetActions.getStationProductsError(error));
  }
}

function* getProductItemsWorkerSaga(value: IFluxStandardAction<IGetBakingProducts>) {
  let { payload } = value;
  try {
    const response: { productId: string; apiRes: any[] } = yield call(
      ProductionSheetRepository.getProductItems,
      payload
    );
    yield put(ProductionSheetActions.getProductItemsSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "getProductItemsWorkerSaga");

    yield put(ProductionSheetActions.getProductItemsError(error));
  }
}

function* getBakingStationHeadersWorkerSaga(
  value: IFluxStandardAction<{ storeCode: any; timeZone: string }>
) {
  let { payload } = value;
  try {
    const response: IHeaderList[] = yield call(
      ProductionSheetRepository.getBakingStationHeaders,
      payload
    );
    yield put(ProductionSheetActions.getBakingStationHeadersSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "getBakingStationHeadersWorkerSaga");

    yield put(ProductionSheetActions.getBakingStationHeadersError(error));
  }
}

function* postProductionSheetDataWorkerSaga(value: IFluxStandardAction<IBakingPSPostApiPayload>) {
  let { payload } = value;
  try {
    const response: [{}] = yield call(ProductionSheetRepository.postProductionSheetData, payload);
    yield put(ProductionSheetActions.postProductionSheetDataSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "postProductionSheetDataWorkerSaga");

    yield put(ProductionSheetActions.postProductionSheetDataError(error));
  }
}
function* getBakingStationCategoriesWorkerSaga(value: IFluxStandardAction<GetBakingCategories>) {
  let { payload } = value;
  try {
    const response: BakingStationCatResp[] = yield call(
      ProductionSheetRepository.getBakingStationCategories,
      payload
    );
    yield put(ProductionSheetActions.getBakingStationCategoriesSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "getBakingStationCategoriesWorkerSaga");

    yield put(ProductionSheetActions.getBakingStationCategoriesError(error));
  }
}
function* getAmPmEmployeeWorkerSaga(
  value: IFluxStandardAction<{ storeCode: number; timeZone: string }>
) {
  let { payload } = value;
  try {
    const response: AmPmEmployeeProducts[] = yield call(
      ProductionSheetRepository.getAmPmEmployee,
      payload
    );
    yield put(ProductionSheetActions.getAmPmEmployeeSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "getAmPmEmployeeWorkerSaga");

    yield put(ProductionSheetActions.getAmPmEmployeeError(error));
  }
}
function* postAmPmEmployeeAuthWorkerSaga(value: IFluxStandardAction<PostStationAuthPayload>) {
  let { payload } = value;
  try {
    let auth = {
      storeCode: payload.storeCode,
      employeeId: payload.employeeId,
      managerAccess: false
    };
    let data: PostStationAuthResp = yield call(MainLogsRepository.checkLogsAuth, auth);
    if (data) {
      yield call(ProductionSheetRepository.postAmPmEmployeeAuth, payload);
      yield put(ProductionSheetActions.postAmPmEmployeeAuthSuccess(data));
    }
  } catch (error) {
    Logger.error("production-sheet", error, "postAmPmEmployeeAuthWorkerSaga");

    yield put(ProductionSheetActions.postAmPmEmployeeAuthError(error));
  }
}

function* getOthersProductsWorkerSaga(value: IFluxStandardAction<IGetOtherProducts>) {
  let { payload } = value;
  const productCategoryId: string = payload.categoryId;
  try {
    const response: any[] = yield call(ProductionSheetRepository.getOthersProducts, payload);
    yield put(ProductionSheetActions.getOthersProductsSuccess(response, productCategoryId));
  } catch (error) {
    Logger.error("production-sheet", error, "getOthersProductsWorkerSaga");

    yield put(ProductionSheetActions.getOthersProductsError(error));
  }
}

function* getOthersCategoriesWorkerSaga(value: IFluxStandardAction<GetBakingCategories>) {
  let { payload } = value;
  try {
    const response: any[] = yield call(ProductionSheetRepository.getOthersCategories, payload);

    yield put(ProductionSheetActions.getOthersCategoriesSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "getOthersCategoriesWorkerSaga");

    yield put(ProductionSheetActions.getOthersCategoriesError(error));
  }
}

function* postOthersProductionSheetDataWorkerSaga(
  value: IFluxStandardAction<IOtherProductionSheetPostData>
) {
  let { payload } = value;

  try {
    const response: [{}] = yield call(
      ProductionSheetRepository.postOthersProductionSheetData,
      payload
    );
    yield put(ProductionSheetActions.postOthersProductionSheetDataSuccess(response));
  } catch (error) {
    Logger.error("production-sheet", error, "postOthersProductionSheetDataWorkerSaga");

    yield put(ProductionSheetActions.postOthersProductionSheetDataError(error));
  }
}

function* productionSheetWatcherSaga() {
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.GET_STATION_PRODUCTS.LOADING,
    getStationProductsWorkerSaga
  );
  yield takeEvery(
    PRODUCTION_SHEET.ProductionSheet.GET_PRODUCT_ITEMS.LOADING,
    getProductItemsWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.GET_STORE_HEADERS.LOADING,
    getBakingStationHeadersWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.POST_PRODUCTION_SHEET_DATA.LOADING,
    postProductionSheetDataWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.GET_STORE_SPECIFIC_STATIONS.LOADING,
    getBakingStationCategoriesWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.GET_AM_PM_EMPLOYEE.LOADING,
    getAmPmEmployeeWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_PRODUCTS.LOADING,
    getOthersProductsWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_CATEGORIES.LOADING,
    getOthersCategoriesWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.POST_AM_PM_EMPLOYEE_AUTH.LOADING,
    postAmPmEmployeeAuthWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ProductionSheet.POST_OTHERS_PRODUCTION_SHEET_DATA.LOADING,
    postOthersProductionSheetDataWorkerSaga
  );
}

export default productionSheetWatcherSaga;
