import {
  DailyCleaningItems,
  LogShiftModel,
  WeeklyCleaningItemsModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { TabObject } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import { AnswerStatus } from "@timhortons/common/src/modules/logs/formatter/cleaningFormatter";

export const formatterPreviousShift = async (response: LogShiftModel[]) => {
  let data: any = [];
  response &&
    response.map((item, index) => {
      let obj = item;
      if (index === 0) {
        obj.isEnable = true;
      } else {
        obj.isEnable = false;
      }
      data.push(obj);
    });
  return data;
};

export const equipmentItemsFormatter = async (response: any): Promise<TabObject[]> => {
  let data: TabObject[] = [];
  response &&
    response.map((item: any, index: number) => {
      let object: TabObject = {
        title: "",
        subTitle: "",
        id: null,
        min: null,
        max: null,
        data: "",
        degree: "",
        isValid: "",
        comment: "",
        isComment: "",
        decision: "",
        uniqueItemId: "",
        isNASelected: false,
        method: "",
        isHHUItem: false
      };

      object.title = item?.name;
      object.subTitle = `Min: ${item?.minTemp}°C, Max: ${item?.maxTemp}°C`;
      object.id = index;
      object.uniqueItemId = item?.id;
      object.min = item?.minTemp;
      object.max = item?.maxTemp;
      object.decision = "";
      object.data = item?.recordedTemp === null ? "-" : item?.recordedTemp;
      object.isNASelected = item?.isNASelected;
      object.degree = item?.recordedTemp === null ? "" : "°C";
      object.isValid =
        item?.recordedTemp !== null
          ? item?.recordedTemp <= item?.maxTemp && item.recordedTemp >= item?.minTemp
            ? true
            : false
          : "";
      object.isComment = item?.comment !== null;
      object.comment = item?.comment !== null ? item?.comment : item?.reason;
      object.method = item?.method;
      object.isHHUItem = item?.isHHUItem;
      data.push(object);
    });

  return data;
};

export const dailyCleaningItemsFormatter = async (
  response: any
): Promise<WeeklyCleaningItemsModel[]> => {
  let data: WeeklyCleaningItemsModel[] = [];

  response &&
    response.map((item: any, index: number) => {
      let object: WeeklyCleaningItemsModel = {
        id: null,
        question: "",
        status: "",
        uniqueItemId: "",
        employeeName: "",
        isComment: null,
        comment: "",
        employeeId: "",
        images: []
      };
      object.id = index + 1;
      object.question = item.description;
      object.status = item?.answerType;
      object.uniqueItemId = item.id;
      object.employeeName = item?.employeeId;
      object.isComment = item.answerStatus === AnswerStatus.comment;
      object.comment = item?.comment;
      item?.assets?.map((el: any) => {
        let obj: any = {
          id: el.id,
          signedUrl: el.signedUrl
        };
        object.images.push(obj);
      });

      data.push(object);
    });
  return data;
};
export const managerWalkthroughItemsFormatter = async (response: any): Promise<any> => {
  let data: any[] = [];
  response &&
    response.map((item: any, index: number) => {
      let object: any = {
        id: null,
        question: "",
        status: "",
        uniqueItemId: "",
        employeeName: "",
        isComment: null,
        isMaintainence: null,
        comment: "",
        images: [],
        employeeId: ""
      };
      object.id = index + 1;
      object.question = item.description;
      object.status = item?.answerType;
      object.uniqueItemId = item.id;
      object.employeeName = item?.employeeId;
      object.employeeId = item?.employeeName;

      object.isComment = item.answerStatus === AnswerStatus.assignTaskComment;
      object.comment = item?.comment;
      object.isMaintainence = item?.answerStatus === AnswerStatus.contactMaintenance || false;
      item?.assets?.map((el: any) => {
        let obj = {
          id: el.id,
          signedUrl: el.signedUrl
        };
        object.images.push(obj);
      });
      data.push(object);
    });
  return data;
};
export const DeepCleaningItemsFormatter = async (
  response: any
): Promise<WeeklyCleaningItemsModel[]> => {
  let formattedData: WeeklyCleaningItemsModel[] = [];
  response &&
    response.map((item: any, index: number) => {
      let data: WeeklyCleaningItemsModel = {
        id: null,
        question: "",
        status: "",
        uniqueItemId: "",
        employeeName: "",
        employeeId: "",
        isComment: null,
        comment: "",
        images: []
      };
      data.id = index + 1;
      data.question = item.description;
      data.status = item.answerType;
      data.uniqueItemId = item.id;
      data.employeeName = item.employeeId;
      data.employeeId = item.employeeName;
      data.isComment = item.answerStatus === AnswerStatus.comment;
      data.comment = item.comment;
      item?.assets?.map((el: any) => {
        let obj: any = {
          id: el.id,
          signedUrl: el.signedUrl
        };
        data.images.push(obj);
      });
      formattedData.push(data);
    });
  return formattedData;
};
