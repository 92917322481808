import { SupportedLanguages } from "./constants";

export default function format(
  value: number,
  formatString: string,
  lng: SupportedLanguages
): string {
  if (formatString.startsWith("date")) {
    return formatDate(value, formatString, lng);
  }
  return value.toString();
}
function formatDate(value: number, formatString: string, lng: SupportedLanguages): string {
  let options = toOptions(formatString);
  return formatString === null
    ? value.toString()
    : new Intl.DateTimeFormat(lng, options).format(value);
}
function toOptions(str: string) {
  if (str.trim() === "date") {
    return {};
  } else {
    try {
      return JSON.parse(toJsonString(str));
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
function toJsonString(strFormat: string) {
  const inner = strFormat
    .trim()
    .replace("date", "")
    .replace("(", "")
    .replace(")", "")
    .split(";")
    .map((param) =>
      param
        .split(":")
        .map((name) => '"' + name.trim() + '"')
        .join(":")
    )
    .join(",");
  return "{" + inner + "}";
}
