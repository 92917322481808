import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { LPActionPlansAction } from "@timhortons/common/src/redux/lp/actions/actionPlans";
import { IState } from "@timhortons/common/src/store/interfaces";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import {
  FilterDataModel,
  LpActionPlanTableModel,
  LpSelectedFilterDataModel
} from "@timhortons/common/src/models/lp/actionPlan";
import { ActionPlanLpSelectors } from "@timhortons/common/src/redux/lp/selectors/actionPlan";
import { Roles } from "@timhortons/common/src/constants/roles";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";
import {
  ILPActionPlansError,
  ILPActionPlansLoading
} from "@timhortons/common/src/redux/lp/reducers/actionPlan";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import LpActionPlanTable from "@timhortons/common/src/modules/lp/components/dashboard/actionPlans/organisms/actionPlansTable";
import LpActionPlanHeader from "@timhortons/common/src/modules/lp/components/dashboard/actionPlans/organisms/actionPlansHeader";

interface IProps {
  history: any;
  translate?: any;
  user?: IUserDetails;
  loading: ILPActionPlansLoading;
  error: ILPActionPlansError;
  currentUserRoles?: Roles[];
  getLpActionPlans: Function;
  getLpFilterData: Function;
  lpActionPlansList: LpActionPlanTableModel;
  lpSelectedFilterData: LpSelectedFilterDataModel;
  getLpSelectedFilterData: Function;
  lpFilterData: FilterDataModel;
  updateLpActionPlanStatus: Function;
  individualLogin: boolean;
}
export interface ILPActionPlanData {
  userId: boolean;
  storeId: number;
  pageNumber: number;
  sortOrder: string;
  columnName: string;
  pageSize: number;
  date: string;
  name: string;
  status: string;
  fromDate: Date;
  toDate: Date;
  search: string;
}
function ActionPlan(props: IProps): ReactElement {
  const {
    translate,
    user,
    history,
    currentUserRoles,
    loading,
    error,
    getLpActionPlans,
    getLpFilterData,
    getLpSelectedFilterData,
    lpActionPlansList,
    lpSelectedFilterData,
    lpFilterData,
    updateLpActionPlanStatus,
    individualLogin
  } = props;
  const [isIndividualLogin, setIndividualLogin] = useState<boolean>();
  useEffect(() => {
    if (checkAllowedRoles(currentUserRoles)) {
      setIndividualLogin(true);
      setFinalLpActionPlanData({ ...finalLpActionPlan, userId: true });
    } else if (currentUserRoles !== null) {
      setIndividualLogin(false);
      setFinalLpActionPlanData({ ...finalLpActionPlan, userId: false });
    }
  }, [currentUserRoles]);

  const lpActionPlanData: ILPActionPlanData = {
    userId: undefined,
    storeId: user && user.storeCode,
    pageNumber: 1,
    sortOrder: "desc",
    columnName: "",
    pageSize: 10,
    date: null,
    name: "",
    status: null,
    fromDate: null,
    toDate: null,
    search: ""
  };
  const [finalLpActionPlan, setFinalLpActionPlanData] = useState(lpActionPlanData);
  const isFocused = useFocusedHook();

  useEffect(() => {
    if (finalLpActionPlan.userId !== undefined) {
      isFocused && getLpActionPlans(finalLpActionPlan);
    }
  }, [finalLpActionPlan, isFocused]);
  useEffect(() => {
    getLpFilterData({
      storeCode: user?.storeCode,
      empId: user?.empId,
      isIndividualLogin: individualLogin
    });
  }, []);

  const detailedActionPlanRedirection = (
    actionPlanId: number,
    auditId: number,
    createPlan: boolean
  ) => {
    let searchParams = { actionPlanId: actionPlanId, auditId: auditId };
    navigation(history, Pathnames.detailedActionPlan, searchParams);
    //api calls
    // if (createPlan) {
    //   updateLpActionPlanStatus({ actionPlanId });
    // }
  };
  const handleSelectedFilter = (value: Object) => {
    getLpSelectedFilterData(value);
    setFinalLpActionPlanData((prevState) => ({
      ...prevState,
      ...value,
      pageNumber: 1
    }));
  };
  const handleSort = (val: string, colName: string) => {
    setFinalLpActionPlanData((prevState) => ({
      ...prevState,
      sortOrder: val,
      columnName: colName,
      pageNumber: 1
    }));
  };
  const handlePage = (val: number) => {
    setFinalLpActionPlanData((prevState) => ({
      ...prevState,
      pageNumber: val
    }));
  };
  return (
    <>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <LpActionPlanHeader
            isIndividualLogin={isIndividualLogin}
            filterData={lpFilterData}
            translate={translate}
            dropDownValueHandler={(value: Object) => handleSelectedFilter(value)}
            user={user}
            finalLpActionPlan={finalLpActionPlan}
          />
        </View>
      </View>
      <View style={styles.tableContainer}>
        <LpActionPlanTable
          pageNum={finalLpActionPlan.pageNumber}
          isIndividualLogin={isIndividualLogin}
          handleSortingOrder={(val: string, colName: string) => handleSort(val, colName)}
          paginationHandler={(value: number) => handlePage(value)}
          onCreatePlan={detailedActionPlanRedirection}
          translate={translate}
          getActionPlans={getLpActionPlans}
          loading={loading}
          error={error}
          actionPlansList={lpActionPlansList}
          userRole={user?.roles[0]}
          selectedLpFilterData={lpSelectedFilterData}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 18
  },
  headerContainer: {
    marginHorizontal: 8,
    zIndex: 1,
    flexWrap: "wrap"
  },
  tableContainer: {
    flex: 1,
    marginVertical: 20
  }
});
const mapStateToProps = (state: IState) => {
  return {
    loading: ActionPlanLpSelectors.lpActionPlanLoading(state),
    error: ActionPlanLpSelectors.lpActionPlanError(state),
    lpActionPlansList: ActionPlanLpSelectors.getLpActionPlans(state),
    lpSelectedFilterData: ActionPlanLpSelectors.lpSelectedFilterData(state),
    lpFilterData: ActionPlanLpSelectors.getLpFilterData(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...LPActionPlansAction }, dispatch) };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActionPlan);
