import React, { ReactElement, ReactNode } from "react";
import { StyleProp, TouchableOpacity, ViewStyle } from "react-native";

interface IProps {
  onEnterHandler?: Function;
  onLeaveHandler?: Function;
  onPress?: Function;
  containerStyle?: StyleProp<ViewStyle>;
  children?: ReactNode;
  customActiveOpacity?: number;
}
export default function HoverableOpacity(props: IProps): ReactElement {
  const {
    onEnterHandler,
    onLeaveHandler,
    containerStyle,
    children,
    onPress,
    customActiveOpacity = 1,
    ...otherProps
  } = props;

  return (
    <TouchableOpacity
      activeOpacity={customActiveOpacity}
      style={containerStyle}
      onMouseEnter={() => onEnterHandler && onEnterHandler()}
      onMouseLeave={() => onLeaveHandler && onLeaveHandler()}
      onPress={() => onPress && onPress()}
      {...otherProps}
    >
      {children}
    </TouchableOpacity>
  );
}
