import { AllAreaManagersDataModel, GetAllRolesDataModel } from "./../../../models/profile";
import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import { IProfilesError, IProfilesLoading, IProfilesState } from "../reducers";
import {
  AddressInfoModal,
  BiographicInfoModal,
  CommunicationInfoModal,
  DetailedUserInfoModel,
  EmergencyInfoModal,
  PersonalInfoModal,
  ProfileDetailsModel,
  UserProfileDataModel,
  TeamDetailsTableModel,
  CreateStoreDataModel,
  CountryDataModel,
  ChannelsDataModel,
  StoreSpecificDataModel,
  StoreTableDetailsModel,
  AllManagersDataModel,
  UserTableDetailsModel,
  DocumentDetailModel,
  GetStoreByStoreCode,
  EditStoreDetails
} from "models/profile";
import { IDocumentData } from "@timhortons/common/src/modules/profile/components/detailedUserProfile";
import { StoreListModel } from "@timhortons/common/src/modules/profile/components/createEmployee/organisms/storeList";

const profilesSelect = (state: IState): IProfilesState => state.profile;

const profilesLoading: Selector<IState, IProfilesLoading> = createSelector(
  profilesSelect,
  (data) => data.loading
);

const profilesError: Selector<IState, IProfilesError> = createSelector(
  profilesSelect,
  (data) => data.error
);

const profileDetails: Selector<IState, ProfileDetailsModel> = createSelector(
  profilesSelect,
  (data) => data.profileDetails
);

const teamDetails: Selector<IState, TeamDetailsTableModel> = createSelector(
  profilesSelect,
  (data) => data.teamDetails
);

const userProfileDetails: Selector<IState, UserProfileDataModel> = createSelector(
  profilesSelect,
  (data) => data.userDetails
);

const detailedUserInfo: Selector<IState, DetailedUserInfoModel> = createSelector(
  profilesSelect,
  (data) => data.detailedUserInfo
);
const personalInfo: Selector<IState, PersonalInfoModal> = createSelector(
  detailedUserInfo,
  (data) => data && data.personalInfo
);
const biographicInfo: Selector<IState, BiographicInfoModal> = createSelector(
  detailedUserInfo,
  (data) => data && data.biographicInfo
);
const communicationInfo: Selector<IState, CommunicationInfoModal> = createSelector(
  detailedUserInfo,
  (data) => data && data.communicationInfo
);
const addressInfo: Selector<IState, AddressInfoModal> = createSelector(
  detailedUserInfo,
  (data) => data && data.addressInfo
);
const emergencyInfo: Selector<IState, EmergencyInfoModal> = createSelector(
  detailedUserInfo,
  (data) => data && data.emergencyInfo
);

////////    Create Store and user  /////////

const countriesData: Selector<IState, CountryDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllCountries
);
const channelsData: Selector<IState, ChannelsDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllChannels
);
const storeSpecificData: Selector<IState, StoreSpecificDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getStoreSpecifics
);
const citiesData: Selector<IState, CreateStoreDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllCities
);
const areaManagersData: Selector<IState, AllManagersDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllAreaManager
);
const areaManagersEnd: Selector<IState, boolean> = createSelector(
  profilesSelect,
  (data) => data && data.isAreaManagerEnd
);

const areaTrainersData: Selector<IState, AllManagersDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllAreaTrainer
);

const areaTrainerEnd: Selector<IState, boolean> = createSelector(
  profilesSelect,
  (data) => data && data.isAreaTrainerEnd
);

const opsManagersData: Selector<IState, AllManagersDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllOpsManager
);
const opsManagerEnd: Selector<IState, boolean> = createSelector(
  profilesSelect,
  (data) => data && data.isOpsManagerEnd
);

const countryManagersData: Selector<IState, AllManagersDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllCountryManager
);
const countryManagerEnd: Selector<IState, boolean> = createSelector(
  profilesSelect,
  (data) => data && data.isCountryManagerEnd
);

const allRolesData: Selector<IState, GetAllRolesDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getAllRoles
);
const formattedRolesData: Selector<IState, any> = createSelector(
  profilesSelect,
  (data) => data && data.formattedRoles
);
const postCreateStore: Selector<IState, any> = createSelector(
  profilesSelect,
  (data) => data && data.postCreateStore
);
const postCreateUser: Selector<IState, any> = createSelector(
  profilesSelect,
  (data) => data && data.postCreateUser
);
const storeTableDetails: Selector<IState, StoreTableDetailsModel> = createSelector(
  profilesSelect,
  (data) => data.storeTableDetails
);
const userTableDetails: Selector<IState, UserTableDetailsModel> = createSelector(
  profilesSelect,
  (data) => data.userTableDetails
);

const employeeDocumentData: Selector<IState, DocumentDetailModel> = createSelector(
  profilesSelect,
  (data) => data && data.getEmployeeDocument
);
const employeeCertificateData: Selector<IState, DocumentDetailModel> = createSelector(
  profilesSelect,
  (data) => data && data.getEmployeeCertificate
);

const employeeCountDocumentData: Selector<IState, IDocumentData[]> = createSelector(
  profilesSelect,
  (data) => data && data.getEmployeeCountDocument
);

const employeeCountCertificateData: Selector<IState, IDocumentData[]> = createSelector(
  profilesSelect,
  (data) => data && data.getEmployeeCountCertificate
);

const postemployeeDocumentData: Selector<IState, { signedUrl: string }> = createSelector(
  profilesSelect,
  (data) => data && data.postEmployeeDocument
);

const getStoreByStoreCode: Selector<IState, GetStoreByStoreCode> = createSelector(
  profilesSelect,
  (data) => data.getStoreByStoreCode
);

const getAllStoresList: Selector<IState, { totalRecords: number; storeList: StoreListModel[] }> =
  createSelector(profilesSelect, (data) => data.getAllStoresList);

const postAssignedStoresData: Selector<IState, any> = createSelector(
  profilesSelect,
  (data) => data && data.postAssignedStores
);
const storeDetails: Selector<IState, EditStoreDetails> = createSelector(
  profilesSelect,
  (data) => data && data.storeDetails
);
const fetchFormattedDataLoading: Selector<IState, boolean> = createSelector(
  profilesSelect,
  (data) => data && data.loading.getAllRoles
);
const fetchFormattedDataError: Selector<IState, string> = createSelector(
  profilesSelect,
  (data) => data && data.error.getAllRoles
);

const gccCountriesData: Selector<IState, CountryDataModel[]> = createSelector(
  profilesSelect,
  (data) => data && data.getGccCountries
);

export const ProfilesSelectors = {
  profilesLoading,
  profilesError,
  profileDetails,
  teamDetails,
  userProfileDetails,
  detailedUserInfo,
  personalInfo,
  biographicInfo,
  communicationInfo,
  addressInfo,
  emergencyInfo,
  countriesData,
  channelsData,
  storeSpecificData,
  citiesData,
  areaManagersData,
  areaManagersEnd,
  areaTrainerEnd,
  opsManagerEnd,
  countryManagerEnd,
  areaTrainersData,
  opsManagersData,
  countryManagersData,
  allRolesData,
  postCreateStore,
  postCreateUser,
  storeTableDetails,
  userTableDetails,
  employeeDocumentData,
  postemployeeDocumentData,
  employeeCountDocumentData,
  employeeCountCertificateData,
  employeeCertificateData,
  formattedRolesData,
  getStoreByStoreCode,
  getAllStoresList,
  postAssignedStoresData,
  storeDetails,
  fetchFormattedDataLoading,
  fetchFormattedDataError,
  gccCountriesData
};
