import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  ILPActionPlansError,
  ILPActionPlansLoading,
  ILPActionPlansState
} from "@timhortons/common/src/redux/lp/reducers/actionPlan";
import {
  FilterDataModel,
  LpActionPlanHeaderModel,
  LpAcknowledgementModel,
  LpActionPlanCategoryDataModel,
  LpActionPlanTableModel,
  LpSelectedFilterDataModel,
  LpActionPlanAccordionDataModel
} from "@timhortons/common/src/models/lp/actionPlan";

const lpActionPlanSelect = (state: IState): ILPActionPlansState => state.lp.actionPlan;

const lpActionPlanLoading: Selector<IState, ILPActionPlansLoading> = createSelector(
  lpActionPlanSelect,
  (data) => data.loading
);

const lpActionPlanError: Selector<IState, ILPActionPlansError> = createSelector(
  lpActionPlanSelect,
  (data) => data.error
);

const getLpActionPlans: Selector<IState, LpActionPlanTableModel> = createSelector(
  lpActionPlanSelect,
  (data) => data.lpActionPlansList
);
const lpSelectedFilterData: Selector<IState, LpSelectedFilterDataModel> = createSelector(
  lpActionPlanSelect,
  (data) => data.lpselectedFilterData
);
const getLpFilterData: Selector<IState, FilterDataModel> = createSelector(
  lpActionPlanSelect,
  (data) => data.lpFilterData
);
const getLpActionPlanDetails: Selector<IState, LpActionPlanHeaderModel> = createSelector(
  lpActionPlanSelect,
  (data) => data.actionPlanDetailsData
);

const getLpCategoryData: Selector<IState, LpActionPlanCategoryDataModel[]> = createSelector(
  lpActionPlanSelect,
  (data) => data.lpCategoryTable
);
const getLpOwnerData: Selector<IState, LpAcknowledgementModel> = createSelector(
  lpActionPlanSelect,
  (data) => data.lpOwnerData
);

const getLpActionPlanAccordionData: Selector<IState, LpActionPlanAccordionDataModel[]> =
  createSelector(lpActionPlanSelect, (data) => data.actionPlanAccordianData);

const postLpActionStepsResponse: Selector<IState, any> = createSelector(
  lpActionPlanSelect,
  (data) => data.postActionStepsData
);

export const ActionPlanLpSelectors = {
  lpActionPlanLoading,
  lpActionPlanError,
  getLpActionPlans,
  lpSelectedFilterData,
  getLpFilterData,
  getLpActionPlanDetails,
  getLpCategoryData,
  getLpOwnerData,
  getLpActionPlanAccordionData,
  postLpActionStepsResponse
};
