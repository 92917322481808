import { auditsRepository } from "@timhortons/common/src/repositories/lp/audits";
import { call, put, takeLatest } from "redux-saga/effects";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { LpCategoryActions } from "@timhortons/common/src/redux/lp/actions/category";
import { Logger } from "@timhortons/common/src/services/logger";
import { LpCategoryDataModel } from "models/lp/audits";
import { IFluxStandardAction } from "store/interfaces";
import { AuditType } from "modules/lp/components/dashboard";

function* getLpCategoryDataWorkerSaga(
  value: IFluxStandardAction<{ auditId: number; auditType: AuditType }>
) {
  try {
    const { payload } = value;
    const response: LpCategoryDataModel = yield call(auditsRepository.fetchLpCategoryData, payload);
    yield put(LpCategoryActions.getLpCategoryDataSuccess(response));
  } catch (error) {
    Logger.error("lp-category", error, "getLpCategoryDataWorkerSaga");

    yield put(LpCategoryActions.getLpCategoryDataError(error));
  }
}

function* categoryWatcherSaga() {
  yield takeLatest(LP.LP_CATEGORY_DATA.LOADING, getLpCategoryDataWorkerSaga);
}

export default categoryWatcherSaga;
