import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import {
  ManageLmsPostCreateModuleResponseDataModel,
  LMSGetAllModulesDataModel,
  LMSGetModuleByIdModel,
  LMSCategoryModel,
  LMSGetCategoryByIdModel,
  LMSPostTopicResponseModel,
  LMSGetModuleCategoriesModel,
  LMSUploadVideoModel,
  LMSgetTopicsModel,
  LMSGetProgressBarStatusModel,
  LMSSummaryDataModel,
  PostLMSMCQTicTacQuiz,
  LMSTicTacQuizModel,
  LMSMtfDndQuizModel,
  LMSImgDataModel,
  LMSCertificateModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import { ISummaryData } from "@timhortons/common/src/repositories/manageLms/manageLmsFormatter";

export interface IManageLmsModuleLoading {
  postManageLmsCreateModuleData: boolean;
  getLMSAllModules: boolean;
  getLMSModuleById: boolean;
  postLMSCategory: boolean;
  getLmsAllCategories: boolean;
  deleteLmsCategory: boolean;
  getLMSCategoryById: boolean;
  postTopic: boolean;
  getLmsModuleCategories: boolean;
  uploadLmsVideo: boolean;
  editLmsCategory: boolean;
  deleteLmsTopic: boolean;
  deleteLmsModule: boolean;
  getLmsTopicById: boolean;
  getProgressBarStatus: boolean;
  getSummaryDetails: boolean;
  postMCQTicTacQuiz: boolean;
  getCategoryDetailsAll: boolean;
  editLmsTopic: boolean;
  getVideoUploadLink: boolean;
  deleteLmsSubtopic: boolean;
  postLmsSubtopic: boolean;
  editLmsSubtopic: boolean;
  deleteLmsVideo: boolean;
  getTicTacQuiz: boolean;
  postMtfDndQuiz: boolean;
  getMtfDndQuiz: boolean;
  postLmsImgUpload: boolean;
  deleteLmsImg: boolean;
  deleteMcqTtt: boolean;
  deleteMtfDnd: boolean;
  updateMcqTtt: boolean;
  updateMtfDnd: boolean;
  lmsGetCertificate: boolean;
  lmsPostCertificate: boolean;
  lmsUpdateCertificate: boolean;
  publishLmsModule: boolean;
  getVideoData: boolean;
  getDashboardModules: boolean;
  getDashboardCategories: boolean;
}
export interface IManageLmsModuleError {
  postManageLmsCreateModuleData: string;
  getLMSAllModules: string;
  getLMSModuleById: string;
  postLMSCategory: string;
  getLmsAllCategories: string;
  deleteLmsCategory: string;
  getLMSCategoryById: string;
  postTopic: string;
  getLmsModuleCategories: string;
  uploadLmsVideo: string;
  editLmsCategory: string;
  deleteLmsTopic: string;
  deleteLmsModule: string;
  getLmsTopicById: string;
  getProgressBarStatus: string;
  getSummaryDetails: string;
  postMCQTicTacQuiz: string;
  getCategoryDetailsAll: string;
  editLmsTopic: string;
  getVideoUploadLink: string;
  postLmsSubtopic: string;
  editLmsSubtopic: string;
  deleteLmsSubtopic: string;
  deleteLmsVideo: string;
  getTicTacQuiz: string;
  postMtfDndQuiz: string;
  getMtfDndQuiz: string;
  postLmsImgUpload: string;
  deleteLmsImg: string;
  deleteMcqTtt: string;
  deleteMtfDnd: string;
  updateMcqTtt: string;
  updateMtfDnd: string;
  lmsGetCertificate: string;
  lmsPostCertificate: string;
  lmsUpdateCertificate: string;
  publishLmsModule: string;
  getVideoData: string;
  getDashboardModules: string;
  getDashboardCategories: string;
}
export interface IManageLmsModuleState {
  error: IManageLmsModuleError;
  loading: IManageLmsModuleLoading;
  postManageLmsCreateModuleData: ManageLmsPostCreateModuleResponseDataModel;
  getLMSAllModulesData: LMSGetAllModulesDataModel[];
  getLMSModuleById: LMSGetModuleByIdModel;
  postLMSCategory: LMSCategoryModel;
  getLmsAllCategories: LMSCategoryModel[];
  deleteLmsCategory: LMSGetModuleByIdModel;
  getLMSCategoryByIdData: LMSGetCategoryByIdModel;
  postTopic: LMSPostTopicResponseModel;
  getLmsModuleCategoriesData: LMSGetModuleCategoriesModel[];
  uploadLmsVideo: LMSUploadVideoModel;
  editLmsCategory: { id: string; name: string };
  deleteLmsTopic: { id: string; name: string };
  deleteLmsModule: any;
  getLmsTopicById: LMSgetTopicsModel[];
  getProgressBarStatus: LMSGetProgressBarStatusModel;
  getSummaryDetails: ISummaryData[];
  postMCQTicTacQuiz: PostLMSMCQTicTacQuiz;
  //TODO:Create model based on api response
  getCategoryDetailsAll: any;
  editLmsTopic: { id: string; name: string };
  getVideoUploadLink: { getUrl: string; uploadUrl: string };
  confirmVideoUpload: { topicId: string };
  postLmsSubtopic: { id: string; name: string };
  editLmsSubtopic: { id: string; name: string };
  deleteLmsSubtopic: { id: string; name: string };
  //TODO:model
  deleteLmsVideo: any;
  getTicTacQuiz: LMSTicTacQuizModel;
  postMtfDndQuiz: LMSMtfDndQuizModel;
  getMtfDndQuiz: LMSMtfDndQuizModel;
  postLmsImgUpload: LMSImgDataModel;
  deleteLmsImg: any;
  lmsGetCertificate: LMSCertificateModel;
  lmsPostCertificate: LMSCertificateModel;
  deleteMcqTtt: any;
  deleteMtfDnd: any;
  updateMcqTtt: LMSTicTacQuizModel;
  updateMtfDnd: LMSMtfDndQuizModel;
  lmsUpdateCertificate: LMSCertificateModel;
  publishLmsModule: { moduleId: string };
  getVideoData: { count: number; duration: number };
  getDashboardModules: any;
  getDashboardCategories: any;
}

export const lmsModuleInitialState: IManageLmsModuleState = {
  error: {
    postManageLmsCreateModuleData: null,
    getLMSAllModules: null,
    getLMSModuleById: null,
    postLMSCategory: null,
    getLmsAllCategories: null,
    deleteLmsCategory: null,
    getLMSCategoryById: null,
    postTopic: null,
    getLmsModuleCategories: null,
    uploadLmsVideo: null,
    editLmsCategory: null,
    deleteLmsTopic: null,
    deleteLmsModule: null,
    getLmsTopicById: null,
    getProgressBarStatus: null,
    getSummaryDetails: null,
    postMCQTicTacQuiz: null,
    getCategoryDetailsAll: null,
    editLmsTopic: null,
    getVideoUploadLink: null,
    postLmsSubtopic: null,
    editLmsSubtopic: null,
    deleteLmsSubtopic: null,
    deleteLmsVideo: null,
    getTicTacQuiz: null,
    postMtfDndQuiz: null,
    getMtfDndQuiz: null,
    postLmsImgUpload: null,
    deleteLmsImg: null,
    deleteMcqTtt: null,
    deleteMtfDnd: null,
    updateMcqTtt: null,
    updateMtfDnd: null,
    lmsGetCertificate: null,
    lmsPostCertificate: null,
    lmsUpdateCertificate: null,
    getVideoData: null,
    publishLmsModule: null,
    getDashboardModules: null,
    getDashboardCategories: null
  },
  loading: {
    postManageLmsCreateModuleData: false,
    getLMSAllModules: false,
    getLMSModuleById: false,
    postLMSCategory: false,
    getLmsAllCategories: false,
    deleteLmsCategory: false,
    getLMSCategoryById: false,
    postTopic: false,
    getLmsModuleCategories: false,
    uploadLmsVideo: false,
    editLmsCategory: false,
    deleteLmsTopic: false,
    deleteLmsModule: false,
    getLmsTopicById: false,
    getProgressBarStatus: false,
    getSummaryDetails: false,
    postMCQTicTacQuiz: false,
    getCategoryDetailsAll: false,
    editLmsTopic: false,
    getVideoUploadLink: false,
    postLmsSubtopic: false,
    editLmsSubtopic: false,
    deleteLmsSubtopic: false,
    deleteLmsVideo: false,
    getTicTacQuiz: false,
    postMtfDndQuiz: false,
    getMtfDndQuiz: false,
    postLmsImgUpload: false,
    deleteLmsImg: false,
    deleteMcqTtt: false,
    deleteMtfDnd: false,
    updateMcqTtt: false,
    updateMtfDnd: false,
    lmsGetCertificate: false,
    lmsPostCertificate: false,
    lmsUpdateCertificate: false,
    getVideoData: false,
    publishLmsModule: false,
    getDashboardModules: false,
    getDashboardCategories: false
  },
  postManageLmsCreateModuleData: null,
  getLMSAllModulesData: [],
  getLMSModuleById: null,
  postLMSCategory: null,
  getLmsAllCategories: null,
  deleteLmsCategory: null,
  getLMSCategoryByIdData: null,
  postTopic: null,
  getLmsModuleCategoriesData: [],
  uploadLmsVideo: null,
  editLmsCategory: null,
  deleteLmsTopic: null,
  deleteLmsModule: null,
  getLmsTopicById: null,
  getProgressBarStatus: null,
  getSummaryDetails: null,
  postMCQTicTacQuiz: null,
  getCategoryDetailsAll: null,
  editLmsTopic: null,
  getVideoUploadLink: null,
  confirmVideoUpload: null,
  postLmsSubtopic: null,
  editLmsSubtopic: null,
  deleteLmsSubtopic: null,
  deleteLmsVideo: null,
  getTicTacQuiz: null,
  postMtfDndQuiz: null,
  getMtfDndQuiz: null,
  postLmsImgUpload: null,
  deleteLmsImg: null,
  deleteMcqTtt: null,
  deleteMtfDnd: null,
  updateMcqTtt: null,
  updateMtfDnd: null,
  lmsGetCertificate: null,
  lmsPostCertificate: null,
  lmsUpdateCertificate: null,
  getVideoData: null,
  publishLmsModule: null,
  getDashboardModules: null,
  getDashboardCategories: null
};

export const manageLmsModuleReducer = (
  state = lmsModuleInitialState,
  action: { payload: any; type: string }
): IManageLmsModuleState => {
  switch (action.type) {
    //* reducer for create module
    case MANAGELMS.POST_CREATE_MODULE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postManageLmsCreateModuleData: true },
        error: { ...state.error, postManageLmsCreateModuleData: null }
      };
    case MANAGELMS.POST_CREATE_MODULE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postManageLmsCreateModuleData: false },
        error: { ...state.error, postManageLmsCreateModuleData: action.payload }
      };
    case MANAGELMS.POST_CREATE_MODULE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postManageLmsCreateModuleData: false },
        error: { ...state.error, postManageLmsCreateModuleData: null },
        postManageLmsCreateModuleData: action.payload
      };

    //* reducer for get all modules
    case MANAGELMS.GET_ALL_MODULE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLMSAllModules: true },
        error: { ...state.error, getLMSAllModules: null }
      };
    case MANAGELMS.GET_ALL_MODULE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLMSAllModules: false },
        error: { ...state.error, getLMSAllModules: action.payload }
      };
    case MANAGELMS.GET_ALL_MODULE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLMSAllModules: false },
        error: { ...state.error, getLMSAllModules: null },
        getLMSAllModulesData: action.payload
      };
    case MANAGELMS.RESET_MODULE_DATA.LOADING:
      return {
        ...state,
        getLMSModuleById: null,
        postManageLmsCreateModuleData: null,
        getProgressBarStatus: null,
        postLMSCategory: null,
        deleteLmsCategory: null,
        getCategoryDetailsAll: null
      };
    //* reducer for get module by id
    case MANAGELMS.GET_MODULE_BY_ID.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLMSModuleById: true
        },
        error: {
          ...state.error,
          getLMSModuleById: null
        },
        postLMSCategory: null
      };
    case MANAGELMS.GET_MODULE_BY_ID.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLMSModuleById: false
        },
        error: {
          ...state.error,
          getLMSModuleById: null
        },
        getLMSModuleById: action.payload
      };
    case MANAGELMS.GET_MODULE_BY_ID.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLMSModuleById: false
        },
        error: {
          ...state.error,
          getLMSModuleById: action.payload
        }
      };

    //* Reducer for post category
    case MANAGELMS.POST_CATEGORY.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          postLMSCategory: true
        },
        error: {
          ...state.error,
          postLMSCategory: null
        },
        postLMSCategory: null
      };
    case MANAGELMS.POST_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          postLMSCategory: false
        },
        error: {
          ...state.error,
          postLMSCategory: null
        },
        postLMSCategory: action.payload
      };
    case MANAGELMS.POST_CATEGORY.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          postLMSCategory: false
        },
        error: {
          ...state.error,
          postLMSCategory: action.payload
        }
      };

    //* Reducer for get all categories
    case MANAGELMS.GET_ALL_CATEGORIES.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsAllCategories: true
        },
        error: {
          ...state.error,
          getLmsAllCategories: null
        }
      };
    case MANAGELMS.GET_ALL_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsAllCategories: false
        },
        error: {
          ...state.error,
          getLmsAllCategories: null
        },
        getLmsAllCategories: action.payload
      };
    case MANAGELMS.GET_ALL_CATEGORIES.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsAllCategories: false
        },
        error: {
          ...state.error,
          getLmsAllCategories: action.payload
        }
      };

    //* Reducer for delete category
    case MANAGELMS.DELETE_CATEGORY.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsCategory: true
        },
        error: {
          ...state.error,
          deleteLmsCategory: null
        }
      };

    case MANAGELMS.DELETE_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsCategory: false
        },
        error: {
          ...state.error,
          deleteLmsCategory: null
        },
        deleteLmsCategory: action.payload
      };
    case MANAGELMS.DELETE_CATEGORY.ERROR: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsCategory: false
        },
        error: {
          ...state.error,
          deleteLmsCategory: action.payload
        }
      };
    }

    //* Reducer for get category by Id
    case MANAGELMS.GET_CATEGORY_BY_ID.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLMSCategoryById: true
        },
        error: {
          ...state.error,
          getLMSCategoryById: null
        }
      };
    case MANAGELMS.GET_CATEGORY_BY_ID.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLMSCategoryById: false
        },
        error: {
          ...state.error,
          getLMSCategoryById: null
        },
        getLMSCategoryByIdData: action.payload
      };
    case MANAGELMS.GET_CATEGORY_BY_ID.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLMSCategoryById: false
        },
        error: {
          ...state.error,
          getLMSCategoryById: action.payload
        }
      };

    //* Reducer for post topic
    case MANAGELMS.POST_TOPIC.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          postTopic: true
        },
        error: {
          ...state.error,
          postTopic: null
        }
      };
    case MANAGELMS.POST_TOPIC.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,

          postTopic: false
        },
        error: {
          ...state.error,
          postTopic: null
        },
        postTopic: action.payload
      };
    case MANAGELMS.POST_TOPIC.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          postTopic: false
        },
        error: {
          ...state.error,
          postTopic: action.payload
        }
      };

    //* Reducer for get Module's Categories
    case MANAGELMS.GET_MODULE_CATEGORIES.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsModuleCategories: true
        },
        error: {
          ...state.error,
          getLmsModuleCategories: null
        },
        getLmsModuleCategoriesData: []
      };
    case MANAGELMS.GET_MODULE_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,

          getLmsModuleCategories: false
        },
        error: {
          ...state.error,
          getLmsModuleCategories: null
        },
        getLmsModuleCategoriesData: action.payload
      };
    case MANAGELMS.GET_MODULE_CATEGORIES.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsModuleCategories: false
        },
        error: {
          ...state.error,
          getLmsModuleCategories: action.payload
        }
      };

    //* reducer for upload video
    case MANAGELMS.UPLOAD_VIDEO.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          uploadLmsVideo: true
        },
        error: {
          ...state.error,
          uploadLmsVideo: null
        }
      };
    case MANAGELMS.UPLOAD_VIDEO.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          uploadLmsVideo: false
        },
        error: {
          ...state.error,
          uploadLmsVideo: null
        },
        uploadLmsVideo: action.payload
      };
    case MANAGELMS.UPLOAD_VIDEO.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          uploadLmsVideo: false
        },
        error: {
          ...state.error,
          uploadLmsVideo: action.payload
        }
      };

    //* Reducer for edit category

    case MANAGELMS.EDIT_CATEGORY.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsCategory: true
        },
        error: {
          ...state.error,
          editLmsCategory: null
        }
      };
    case MANAGELMS.EDIT_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsCategory: false
        },
        error: {
          ...state.error,
          editLmsCategory: null
        },
        editLmsCategory: action.payload
      };
    case MANAGELMS.EDIT_CATEGORY.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsCategory: false
        },
        error: {
          ...state.error,
          editLmsCategory: action.payload
        }
      };

    //* Reducer for delete topic
    case MANAGELMS.DELETE_TOPIC.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsTopic: true
        },
        error: {
          ...state.error,
          deleteLmsTopic: null
        }
      };
    case MANAGELMS.DELETE_TOPIC.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsTopic: false
        },
        error: {
          ...state.error,
          deleteLmsTopic: null
        },
        deleteLmsTopic: action.payload
      };
    case MANAGELMS.DELETE_TOPIC.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsTopic: false
        },
        error: {
          ...state.error,
          deleteLmsTopic: action.payload
        }
      };

    //* Reducer for delete module
    case MANAGELMS.DELETE_MODULE.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsModule: true
        },
        error: {
          ...state.error,
          deleteLmsModule: null
        }
      };
    case MANAGELMS.DELETE_MODULE.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsModule: false
        },
        error: {
          ...state.error,
          deleteLmsModule: null
        },
        deleteLmsModule: action.payload
      };
    case MANAGELMS.DELETE_MODULE.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsModule: false
        },
        error: {
          ...state.error,
          deleteLmsModule: action.payload
        }
      };

    //* Reducer for get topic by id

    case MANAGELMS.GET_TOPIC_BY_ID.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsTopicById: true
        },
        error: {
          ...state.error,
          getLmsTopicById: null
        }
      };
    case MANAGELMS.GET_TOPIC_BY_ID.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsTopicById: false
        },
        error: {
          ...state.error,
          getLmsTopicById: null
        },
        getLmsTopicById: action.payload
      };
    case MANAGELMS.GET_TOPIC_BY_ID.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLmsTopicById: false
        },
        error: {
          ...state.error,
          getLmsTopicById: action.payload
        }
      };
    //* Reducer for get progress bar status

    case MANAGELMS.GET_PROGRESSBAR_STATUS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getProgressBarStatus: true
        },
        error: {
          ...state.error,
          getProgressBarStatus: null
        }
      };
    case MANAGELMS.GET_PROGRESSBAR_STATUS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getProgressBarStatus: false
        },
        error: {
          ...state.error,
          getProgressBarStatus: null
        },
        getProgressBarStatus: action.payload
      };
    case MANAGELMS.GET_PROGRESSBAR_STATUS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getProgressBarStatus: false
        },
        error: {
          ...state.error,
          getProgressBarStatus: action.payload
        }
      };
    //* Reducer for post progress bar status

    case MANAGELMS.POST_PROGRESSBAR_STATUS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getProgressBarStatus: true
        },
        error: {
          ...state.error,
          getProgressBarStatus: null
        }
      };
    case MANAGELMS.POST_PROGRESSBAR_STATUS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getProgressBarStatus: false
        },
        error: {
          ...state.error,
          getProgressBarStatus: null
        },
        getProgressBarStatus: action.payload
      };
    case MANAGELMS.POST_PROGRESSBAR_STATUS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getProgressBarStatus: false
        },
        error: {
          ...state.error,
          getProgressBarStatus: action.payload
        }
      };
    //* Reducer for get summary details

    case MANAGELMS.GET_SUMMARY_DETAILS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSummaryDetails: true
        },
        error: {
          ...state.error,
          getSummaryDetails: null
        },
        getSummaryDetails: null
      };
    case MANAGELMS.GET_SUMMARY_DETAILS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSummaryDetails: false
        },
        error: {
          ...state.error,
          getSummaryDetails: null
        },
        getSummaryDetails: action.payload
      };
    case MANAGELMS.GET_SUMMARY_DETAILS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getSummaryDetails: false
        },
        error: {
          ...state.error,
          getSummaryDetails: action.payload
        }
      };
    //* Reducer for get summary details

    case MANAGELMS.POST_MCQ_TIC_QUIZ.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          postMCQTicTacQuiz: true
        },
        error: {
          ...state.error,
          postMCQTicTacQuiz: null
        },
        postMCQTicTacQuiz: null
      };
    case MANAGELMS.POST_MCQ_TIC_QUIZ.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          postMCQTicTacQuiz: false
        },
        error: {
          ...state.error,
          postMCQTicTacQuiz: null
        },
        postMCQTicTacQuiz: action.payload
      };
    case MANAGELMS.POST_MCQ_TIC_QUIZ.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          postMCQTicTacQuiz: false
        },
        error: {
          ...state.error,
          postMCQTicTacQuiz: action.payload
        }
      };
    //* Reducer for get category detailsAll

    case MANAGELMS.GET_CATGORY_DETAILS_ALL.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCategoryDetailsAll: true
        },
        error: {
          ...state.error,
          getCategoryDetailsAll: null
        },
        getCategoryDetailsAll: null
      };
    case MANAGELMS.GET_CATGORY_DETAILS_ALL.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCategoryDetailsAll: false
        },
        error: {
          ...state.error,
          getCategoryDetailsAll: null
        },
        getCategoryDetailsAll: action.payload
      };
    case MANAGELMS.GET_CATGORY_DETAILS_ALL.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCategoryDetailsAll: false
        },
        error: {
          ...state.error,
          getCategoryDetailsAll: action.payload
        }
      };

    //* Reducer for edit lms topic

    case MANAGELMS.EDIT_TOPIC.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsTopic: true
        },
        error: {
          ...state.error,
          editLmsTopic: null
        }
      };
    case MANAGELMS.EDIT_TOPIC.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsTopic: false
        },
        error: {
          ...state.error,
          editLmsTopic: null
        },
        editLmsTopic: action.payload
      };
    case MANAGELMS.EDIT_TOPIC.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsTopic: false
        },
        error: {
          ...state.error,
          editLmsTopic: action.payload
        }
      };

    //* Reducer for get video upload link

    case MANAGELMS.GET_VIDEO_UPLOAD_LINK.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoUploadLink: true
        },
        error: {
          ...state.error,
          getVideoUploadLink: null
        }
      };
    case MANAGELMS.GET_VIDEO_UPLOAD_LINK.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoUploadLink: false
        },
        error: {
          ...state.error,
          getVideoUploadLink: null
        },
        getVideoUploadLink: action.payload
      };
    case MANAGELMS.GET_VIDEO_UPLOAD_LINK.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoUploadLink: false
        },
        error: {
          ...state.error,
          getVideoUploadLink: action.payload
        }
      };

    //* Reducer for confirm video upload
    case MANAGELMS.CONFIRM_VIDEO_UPLOAD.SUCCESS:
      const { topicId } = action.payload;
      state.getCategoryDetailsAll.topicResponseDtos.forEach((item: any) => {
        if (item.id === topicId) {
          item.videoResponseDtos.push({ topicId });
        }
      });

      return {
        ...state,
        confirmVideoUpload: action.payload
      };

    //* Reducer for post subtopic
    case MANAGELMS.POST_SUBTOPIC.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          postLmsSubtopic: true
        },
        error: {
          ...state.error,
          postLmsSubtopic: null
        }
      };
    case MANAGELMS.POST_SUBTOPIC.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          postLmsSubtopic: false
        },
        error: {
          ...state.error,
          postLmsSubtopic: null
        },
        postLmsSubtopic: action.payload
      };
    case MANAGELMS.POST_SUBTOPIC.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          postLmsSubtopic: false
        },
        error: {
          ...state.error,
          postLmsSubtopic: action.payload
        }
      };

    //* Reducer for edit subtopic
    case MANAGELMS.EDIT_SUBTOPIC.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsSubtopic: true
        },
        error: {
          ...state.error,
          editLmsSubtopic: null
        }
      };
    case MANAGELMS.EDIT_SUBTOPIC.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsSubtopic: false
        },
        error: {
          ...state.error,
          editLmsSubtopic: null
        },
        editLmsSubtopic: action.payload
      };
    case MANAGELMS.EDIT_SUBTOPIC.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          editLmsSubtopic: false
        },
        error: {
          ...state.error,
          editLmsSubtopic: action.payload
        }
      };

    //* Reducer for delete subtopic
    case MANAGELMS.DELETE_SUBTOPIC.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsSubtopic: true
        },
        error: {
          ...state.error,
          deleteLmsSubtopic: null
        }
      };
    case MANAGELMS.DELETE_SUBTOPIC.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsSubtopic: false
        },
        error: {
          ...state.error,
          deleteLmsSubtopic: null
        },
        deleteLmsSubtopic: action.payload
      };

    case MANAGELMS.DELETE_SUBTOPIC.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsSubtopic: false
        },
        error: {
          ...state.error,
          deleteLmsSubtopic: action.payload
        }
      };

    //* Reducer for delete Video
    case MANAGELMS.DELETE_VIDEO.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsVideo: true
        },
        error: {
          ...state.error,
          deleteLmsVideo: null
        }
      };
    //* Reducer for get tictac quiz
    case MANAGELMS.GET_MCQ_TIC_QUIZ.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTicTacQuiz: true
        },
        error: {
          ...state.error,
          getTicTacQuiz: null
        }
      };

    case MANAGELMS.DELETE_VIDEO.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsVideo: false
        },
        error: {
          ...state.error,
          deleteLmsVideo: null
        },
        deleteLmsVideo: action.payload
      };

    case MANAGELMS.GET_MCQ_TIC_QUIZ.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTicTacQuiz: false
        },
        error: {
          ...state.error,
          getTicTacQuiz: null
        },
        getTicTacQuiz: action.payload
      };

    case MANAGELMS.DELETE_VIDEO.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLmsVideo: false
        },
        error: {
          ...state.error,
          deleteLmsVideo: action.payload
        }
      };
    case MANAGELMS.GET_MCQ_TIC_QUIZ.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTicTacQuiz: false
        },
        error: {
          ...state.error,
          getTicTacQuiz: action.payload
        }
      };

    //* reducer for update module
    case MANAGELMS.UPDATE_MODULE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postManageLmsCreateModuleData: true },
        error: { ...state.error, postManageLmsCreateModuleData: null },
        postManageLmsCreateModuleData: null
      };
    case MANAGELMS.UPDATE_MODULE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postManageLmsCreateModuleData: false },
        error: { ...state.error, postManageLmsCreateModuleData: action.payload }
      };
    case MANAGELMS.UPDATE_MODULE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postManageLmsCreateModuleData: false },
        error: { ...state.error, postManageLmsCreateModuleData: null },
        postManageLmsCreateModuleData: action.payload
      };

    //* reducer for post mtf dnd quiz
    case MANAGELMS.POST_MTF_DND_QUIZ.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postMtfDndQuiz: true },
        error: { ...state.error, postMtfDndQuiz: null },
        postMtfDndQuiz: null
      };
    case MANAGELMS.POST_MTF_DND_QUIZ.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postMtfDndQuiz: false },
        error: { ...state.error, postMtfDndQuiz: action.payload }
      };
    case MANAGELMS.POST_MTF_DND_QUIZ.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postMtfDndQuiz: false },
        error: { ...state.error, postMtfDndQuiz: null },
        postMtfDndQuiz: action.payload
      };

    //* reducer for get mtf dnd quiz
    case MANAGELMS.GET_MTF_DND_QUIZ.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getMtfDndQuiz: true },
        error: { ...state.error, getMtfDndQuiz: null },
        getMtfDndQuiz: null
      };
    case MANAGELMS.GET_MTF_DND_QUIZ.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getMtfDndQuiz: false },
        error: { ...state.error, getMtfDndQuiz: action.payload }
      };
    case MANAGELMS.GET_MTF_DND_QUIZ.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getMtfDndQuiz: false },
        error: { ...state.error, getMtfDndQuiz: null },
        getMtfDndQuiz: action.payload
      };

    //* reducer for post image
    case MANAGELMS.LMS_IMG_UPLOAD.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postLmsImgUpload: true },
        error: { ...state.error, postLmsImgUpload: null },
        postLmsImgUpload: null
      };
    case MANAGELMS.LMS_IMG_UPLOAD.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postLmsImgUpload: false },
        error: { ...state.error, postLmsImgUpload: action.payload }
      };
    case MANAGELMS.LMS_IMG_UPLOAD.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postLmsImgUpload: false },
        error: { ...state.error, postLmsImgUpload: null },
        postLmsImgUpload: action.payload
      };

    //* reducer for delete image
    case MANAGELMS.LMS_IMG_DELETE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsImg: true },
        error: { ...state.error, deleteLmsImg: null },
        deleteLmsImg: null
      };
    case MANAGELMS.LMS_IMG_DELETE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsImg: false },
        error: { ...state.error, deleteLmsImg: action.payload }
      };
    case MANAGELMS.LMS_IMG_DELETE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsImg: false },
        error: { ...state.error, deleteLmsImg: null },
        deleteLmsImg: action.payload
      };
    //* reducer for get certificate
    case MANAGELMS.LMS_GET_CERTIFICATE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lmsGetCertificate: true },
        error: { ...state.error, lmsGetCertificate: null },
        lmsGetCertificate: null
      };
    case MANAGELMS.LMS_GET_CERTIFICATE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lmsGetCertificate: false },
        error: { ...state.error, lmsGetCertificate: action.payload },
        lmsGetCertificate: null
      };
    case MANAGELMS.LMS_GET_CERTIFICATE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lmsGetCertificate: false },
        error: { ...state.error, lmsGetCertificate: null },
        lmsGetCertificate: action.payload
      };
    //* reducer for post certificate
    case MANAGELMS.LMS_POST_CERTIFICATE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lmsPostCertificate: true },
        error: { ...state.error, lmsPostCertificate: null },
        lmsPostCertificate: null
      };
    case MANAGELMS.LMS_POST_CERTIFICATE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lmsPostCertificate: false },
        error: { ...state.error, lmsPostCertificate: action.payload }
      };
    case MANAGELMS.LMS_POST_CERTIFICATE.SUCCESS:
      let data: any = action.payload;
      let newObj: any = {
        id: data.id,
        title: data.title,
        description: data.description,
        expiry: data.expiry
      };
      return {
        ...state,
        loading: { ...state.loading, lmsPostCertificate: false },
        error: { ...state.error, lmsPostCertificate: null, lmsGetCertificate: null },
        lmsPostCertificate: action.payload,
        lmsGetCertificate: newObj
      };

    case MANAGELMS.DELETE_MCQ_TTT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteMcqTtt: true
        },
        error: {
          ...state.error,
          deleteMcqTtt: null
        }
      };
    case MANAGELMS.DELETE_MCQ_TTT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteMcqTtt: false
        },
        error: {
          ...state.error,
          deleteMcqTtt: null
        },
        deleteMcqTtt: action.payload
      };
    case MANAGELMS.DELETE_MCQ_TTT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteMcqTtt: false
        },
        error: {
          ...state.error,
          deleteMcqTtt: action.payload
        }
      };

    case MANAGELMS.UPDATE_MCQ_TTT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateMcqTtt: true
        },
        error: {
          ...state.error,
          updateMcqTtt: null
        }
      };
    case MANAGELMS.UPDATE_MCQ_TTT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateMcqTtt: false
        },
        error: {
          ...state.error,
          updateMcqTtt: null
        },
        updateMcqTtt: action.payload
      };
    case MANAGELMS.UPDATE_MCQ_TTT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateMcqTtt: false
        },
        error: {
          ...state.error,
          updateMcqTtt: action.payload
        }
      };

    case MANAGELMS.DELETE_MTF_DND.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteMtfDnd: true
        },
        error: {
          ...state.error,
          deleteMtfDnd: null
        }
      };
    case MANAGELMS.DELETE_MTF_DND.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteMtfDnd: false
        },
        error: {
          ...state.error,
          deleteMtfDnd: null
        },
        deleteMtfDnd: action.payload
      };
    case MANAGELMS.DELETE_MTF_DND.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteMtfDnd: false
        },
        error: {
          ...state.error,
          deleteMtfDnd: action.payload
        }
      };

    case MANAGELMS.UPDATE_MTF_DND.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateMtfDnd: true
        },
        error: {
          ...state.error,
          updateMtfDnd: null
        }
      };
    case MANAGELMS.UPDATE_MTF_DND.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateMtfDnd: false
        },
        error: {
          ...state.error,
          updateMtfDnd: null
        },
        updateMtfDnd: action.payload
      };
    case MANAGELMS.UPDATE_MTF_DND.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateMtfDnd: false
        },
        error: {
          ...state.error,
          updateMtfDnd: action.payload
        }
      };

    case MANAGELMS.LMS_UPDATE_CERTIFICATE.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          lmsUpdateCertificate: true
        },
        error: {
          ...state.error,
          lmsUpdateCertificate: null
        }
      };
    case MANAGELMS.LMS_UPDATE_CERTIFICATE.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          lmsUpdateCertificate: false
        },
        error: {
          ...state.error,
          lmsUpdateCertificate: null
        },
        lmsUpdateCertificate: action.payload
      };
    case MANAGELMS.LMS_UPDATE_CERTIFICATE.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          lmsUpdateCertificate: false
        },
        error: {
          ...state.error,
          lmsUpdateCertificate: action.payload
        }
      };
    case MANAGELMS.RESET_QUIZZES_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          getTicTacQuiz: null,
          getMtfDndQuiz: null,
          postMCQTicTacQuiz: null,
          postMtfDndQuiz: null,
          updateMtfDnd: null,
          updateMcqTtt: null
        },
        postMCQTicTacQuiz: null,
        getTicTacQuiz: null,
        postMtfDndQuiz: null,
        getMtfDndQuiz: null,
        updateMtfDnd: null,
        updateMcqTtt: null
      };

    case MANAGELMS.GET_VIDEO_DATA.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoData: true
        },
        error: {
          ...state.error,
          getVideoData: null
        }
      };

    case MANAGELMS.GET_VIDEO_DATA.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoData: false
        },
        error: {
          ...state.error,
          getVideoData: null
        },
        getVideoData: action.payload
      };

    case MANAGELMS.GET_VIDEO_DATA.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getVideoData: false
        },
        error: {
          ...state.error,
          getVideoData: action.payload
        }
      };

    case MANAGELMS.PUBLISH_MODULE.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          publishLmsModule: true
        },
        error: {
          ...state.error,
          publishLmsModule: null
        }
      };
    case MANAGELMS.RESET_PUBLISH.LOADING:
      return {
        ...state,
        publishLmsModule: null
      };
    case MANAGELMS.PUBLISH_MODULE.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          publishLmsModule: false
        },
        error: {
          ...state.error,
          publishLmsModule: null
        },
        publishLmsModule: action.payload
      };

    case MANAGELMS.PUBLISH_MODULE.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          publishLmsModule: false
        },
        error: {
          ...state.error,
          publishLmsModule: action.payload
        }
      };

    case MANAGELMS.GET_DASHBOARD_MODULES.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDashboardModules: true
        },
        error: {
          ...state.error,
          getDashboardModules: null
        }
      };
    case MANAGELMS.GET_DASHBOARD_MODULES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDashboardModules: false
        },
        error: {
          ...state.error,
          getDashboardModules: null
        },
        getDashboardModules: action.payload
      };
    case MANAGELMS.GET_DASHBOARD_MODULES.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDashboardModules: false
        },
        error: {
          ...state.error,
          getDashboardModules: action.payload
        }
      };

    case MANAGELMS.RESET_QUIZ_ERROR.LOADING:
      return {
        ...state,
        error: {
          ...state.error,
          postMCQTicTacQuiz: null,
          postMtfDndQuiz: null
        }
      };
    case MANAGELMS.RESET_IMG.LOADING:
      return {
        ...state,
        postLmsImgUpload: null
      };
    case MANAGELMS.GET_DASHBOARD_CATEGORIES.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDashboardCategories: true
        },
        error: {
          ...state.error,
          getDashboardCategories: null
        }
      };

    case MANAGELMS.GET_DASHBOARD_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDashboardCategories: false
        },
        error: {
          ...state.error,
          getDashboardCategories: null
        },
        getDashboardCategories: action.payload
      };

    case MANAGELMS.GET_DASHBOARD_CATEGORIES.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDashboardCategories: false
        },
        error: {
          ...state.error,
          getDashboardCategories: action.payload
        }
      };
    case MANAGELMS.RESET_MODULE_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          postLMSCategory: null,
          postLmsSubtopic: null,
          postTopic: null,
          postMCQTicTacQuiz: null,
          postMtfDndQuiz: null,
          deleteLmsCategory: null,
          deleteLmsSubtopic: null,
          deleteLmsTopic: null,
          deleteMcqTtt: null,
          deleteMtfDnd: null,
          editLmsCategory: null,
          editLmsSubtopic: null,
          editLmsTopic: null,
          updateMcqTtt: null,
          updateMtfDnd: null,
          postManageLmsCreateModuleData: null
        }
      };
    case MANAGELMS.RESET_DASHBOARD_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          publishLmsModule: null,
          deleteLmsModule: null
        }
      };
    case MANAGELMS.RESET_CERTIFICATE_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          lmsPostCertificate: null,
          lmsUpdateCertificate: null
        }
      };
    default:
      return state;
  }
};
