import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { Checkbox, CheckboxType } from "@timhortons/common/src/components/atoms/checkBox";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { BakingTableModel } from "@timhortons/common/src/models/productionSheet/mainMenu";
interface IProps {
  productList?: BakingTableModel;
  flatListWidth?: number;
  setUpdatedProductData?: Function;
  allProductData?: BakingTableModel[];
  setShowWarning?: Function;
  getAllCategoryProductsData?: BakingTableModel[];
}
export default function BakingPageBody(props: IProps) {
  const {
    productList,
    flatListWidth,
    setUpdatedProductData,
    allProductData,
    setShowWarning,
    getAllCategoryProductsData
  } = props;
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    handleHeaderCheckBox();
  }, [productList]);
  const handleSelectAll = () => {
    let data = [...allProductData];
    let catIndex = data.findIndex((item) => item.id === productList.id);
    if (catIndex > -1) {
      data[catIndex].isSelected = !selectAll;
      data[catIndex].products.forEach((prodItem: any) => {
        prodItem.isSelected = !selectAll;
        prodItem.items.map((el: any) => {
          el.isSelected = !selectAll;
        });
      });
      setSelectAll(!selectAll);
      let status = JSON.stringify(data) === JSON.stringify(getAllCategoryProductsData);
      setShowWarning(!status);
      setUpdatedProductData(data);
    }
  };
  function handleHeaderCheckBox() {
    let productLength = productList.products.length;
    let count = 0;
    productList.products.forEach((item: any) => item.isSelected && count++);
    if (count === productLength) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }

  const handleSubProducts = (productId: string) => {
    let data = [...allProductData];
    let catIndex = data.findIndex((item) => item.id === productList.id);
    if (catIndex > -1) {
      let prodIndex = data[catIndex].products.findIndex((item: any) => item.id === productId);
      if (prodIndex > -1) {
        data[catIndex].products[prodIndex].isSelected =
          !data[catIndex].products[prodIndex].isSelected;
        // make all items of that product as clicked
        data[catIndex].products[prodIndex].items.forEach((el: any) => {
          if (data[catIndex].products[prodIndex].isSelected) {
            el.isSelected = true;
          } else {
            el.isSelected = false;
          }
        });
        handleHeaderCheckBox();
        let status = JSON.stringify(data) === JSON.stringify(getAllCategoryProductsData);
        setShowWarning(!status);
        setUpdatedProductData(data);
      }
    }
  };
  const handleItemSelection = (productId: string, itemId: string) => {
    let data = [...allProductData];
    let catIndex = data.findIndex((item) => item.id === productList.id);
    if (catIndex > -1) {
      let prodIndex = data[catIndex].products.findIndex((item: any) => item.id === productId);
      if (prodIndex > -1) {
        let itemIndex = data[catIndex].products[prodIndex].items.findIndex(
          (item: any) => item.id === itemId
        );
        if (itemIndex > -1) {
          data[catIndex].products[prodIndex].items[itemIndex].isSelected =
            !data[catIndex].products[prodIndex].items[itemIndex].isSelected;
          // if all items selected for a product -> product selected
          if (
            data[catIndex].products[prodIndex].items.every((item: any) => item.isSelected === true)
          ) {
            data[catIndex].products[prodIndex].isSelected = true;
          } else {
            data[catIndex].products[prodIndex].isSelected = false;
          }
          // if all products are now selected make header selected
          handleHeaderCheckBox();
          let status = JSON.stringify(data) === JSON.stringify(getAllCategoryProductsData);
          setShowWarning(!status);
          setUpdatedProductData(data);
        }
      }
    }
  };

  return (
    <View style={flatListStyle(flatListWidth).cardWidth}>
      <View style={styles.cardHeader}>
        <Checkbox
          onPress={handleSelectAll}
          selected={selectAll}
          text={productList?.category}
          testId={productList?.id}
          textSize={TextSize.ExtraRegular}
          type={CheckboxType.Square}
          textFontWeight={FontWeight.SemiBold}
          iconSize={14}
          textStyle={styles.checkboxText}
        />
      </View>

      <View style={styles.cardBody}>
        <FlatList
          showsVerticalScrollIndicator
          extractor={(item) => item.id}
          listData={productList?.products}
          seperator={() => <View style={styles.dividerVertical} />}
          listContainerStyle={styles.flatList}
          listRenderer={(prodItem: any) => (
            <>
              <View style={{ backgroundColor: colorPallete.variant23 }}>
                <Checkbox
                  onPress={() => handleSubProducts(prodItem.id)}
                  selected={prodItem.isSelected}
                  text={prodItem.productName}
                  testId={prodItem.product}
                  textSize={TextSize.ExtraRegular}
                  type={CheckboxType.Square}
                  textFontWeight={FontWeight.Regular}
                  iconSize={14}
                  textStyle={styles.checkboxText}
                  containerStyle={{ paddingHorizontal: 24, paddingVertical: 12 }}
                />
              </View>
              <View style={{ paddingHorizontal: 24, paddingTop: 12 }}>
                <FlatList
                  showsVerticalScrollIndicator
                  extractor={(item) => item.id}
                  listData={prodItem?.items}
                  seperator={() => <View style={styles.dividerVertical} />}
                  listContainerStyle={styles.flatList}
                  listRenderer={(el: any) => (
                    <Checkbox
                      onPress={() => handleItemSelection(prodItem.id, el.id)}
                      selected={el.isSelected}
                      text={el.product}
                      testId={el.product}
                      textSize={TextSize.ExtraRegular}
                      type={CheckboxType.Square}
                      textFontWeight={FontWeight.Regular}
                      iconSize={14}
                      textStyle={styles.checkboxText}
                    />
                  )}
                />
              </View>
            </>
          )}
        />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  dividerVertical: {
    height: 14
  },
  checkboxText: {
    paddingHorizontal: 16
  },
  cardHeader: {
    backgroundColor: colorPallete.grey9,
    paddingHorizontal: 24,
    paddingVertical: 12
  },
  cardBody: {
    backgroundColor: colorPallete.white3,
    // paddingHorizontal: 24,
    // paddingVertical: 12,
    height: "100%",
    paddingBottom: 50
  },
  flatList: {
    maxHeight: "100%"
  }
});

const flatListStyle = (flatListWidth: number) =>
  StyleSheet.create({
    cardWidth: {
      width: (flatListWidth - 60) / 3,
      shadowOffset: { width: 1, height: 1 },
      elevation: 1,
      shadowColor: colorPallete.variant7,
      borderRadius: 8,
      overflow: "hidden"
    }
  });
