import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { DailyCheckListActionsCategoriesModel } from "@timhortons/common/src/models/logs/actionLogs/cleaning";
import { Decision } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/timeTempActionTab";

export interface IStationReadinessActionsLoading {
  getActionsSubCategory: boolean;
  getActionsSubCategoryItems: boolean;
  updateStationReadActions: boolean;
}
export interface IStationReadinessActionsError {
  getActionsSubCategory: string;
  getActionsSubCategoryItems: string;
  updateStationReadActions: string;
}
export interface IStationReadinessActionsState {
  loading: IStationReadinessActionsLoading;
  error: IStationReadinessActionsError;
  getActionsSubCategory: DailyCheckListActionsCategoriesModel[];
}
export const stationReadActionsInitialState: IStationReadinessActionsState = {
  getActionsSubCategory: null,
  loading: {
    getActionsSubCategory: false,
    getActionsSubCategoryItems: false,
    updateStationReadActions: false
  },
  error: {
    getActionsSubCategory: null,
    getActionsSubCategoryItems: null,
    updateStationReadActions: null
  }
};
export const stationReadActionsReducer = (
  state = stationReadActionsInitialState,
  action: { payload: any; type: string }
): IStationReadinessActionsState => {
  switch (action.type) {
    case LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionsSubCategory: true }
      };
    case LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getActionsSubCategory: false },
        getActionsSubCategory: action.payload
      };
    case LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionsSubCategory: false },
        error: { ...state.error, getActionsSubCategory: action.payload }
      };

    case LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionsSubCategoryItems: true }
      };
    case LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES_ITEMS.SUCCESS:
      let data = state.getActionsSubCategory;
      let index = data && data?.findIndex((obj) => obj.id === action.payload.metaData.categoryId);
      if (index > -1) {
        data[index].questionList = action.payload.apiRes;
      }
      return {
        ...state,
        loading: { ...state.loading, getActionsSubCategoryItems: false },
        getActionsSubCategory: data
      };
    case LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionsSubCategoryItems: false },
        error: { ...state.error, getActionsSubCategoryItems: JSON.stringify(action.payload) }
      };
    case LOGS.Actions.StationRead.UPDATE_STATION_READINESS_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateStationReadActions: true },
        error: { ...state.error, updateStationReadActions: null }
      };
    case LOGS.Actions.StationRead.UPDATE_STATION_READINESS_ACTIONS.SUCCESS:
      let updatedData = state.getActionsSubCategory;
      let categoryIndex =
        updatedData &&
        updatedData.findIndex((item) => item.id === action.payload.metaData.categoryId);
      if (categoryIndex > -1) {
        let questionList = state.getActionsSubCategory[categoryIndex].questionList;
        let questionIndex =
          questionList &&
          questionList.findIndex((item) => item.uniqueId === action.payload.metaData.questionId);
        if (questionIndex > -1) {
          questionList[questionIndex].decision = action.payload.apiRes.hasResolved
            ? Decision.resolved
            : Decision.notResolved;
        }
        updatedData[categoryIndex].questionList = questionList;
      }
      return {
        ...state,
        loading: { ...state.loading, updateStationReadActions: false },
        error: { ...state.error, updateStationReadActions: null },
        getActionsSubCategory: updatedData
      };

    case LOGS.Actions.StationRead.UPDATE_STATION_READINESS_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateStationReadActions: false },
        error: { ...state.error, updateStationReadActions: action.payload }
      };
    case LOGS.Actions.StationRead.RESET_ACTIONS_POST_API_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          updateStationReadActions: null
        }
      };
    case LOGS.Actions.StationRead.RESET_ENTIRE_SR_ACTIONS:
      return stationReadActionsInitialState;

    default:
      return state;
  }
};
