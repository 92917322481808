import React, { ReactElement, ReactNode } from "react";
import { ViewStyle, StyleProp, TouchableOpacity } from "react-native";
import { History } from "history";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

interface IProps {
  activeStyles: StyleProp<ViewStyle>;
  defaultStyles: StyleProp<ViewStyle>;
  history: History;
  children?: ReactNode;
  to?: string;
  active?: boolean;
  param?: string;
  handleNavClick?: Function;
  Navigation?: any; //for mobile navigation object
  root?: string;
  handlePathChange?: Function;
  activePath?: string;
}

export default function NavLinkButton(props: IProps): ReactElement {
  const {
    activeStyles,
    defaultStyles,
    history,
    children,
    to,
    active,
    handleNavClick,
    param,
    activePath,
    handlePathChange,
    root
  } = props;

  const handleClick = () => {
    handleNavClick && handleNavClick(to);
    handlePathChange && handlePathChange(activePath);
    if (PlatformUtils.isWeb()) {
      if (root === "/logs") {
        navigation(
          history,
          to,
          null,
          null,
          { urlParam: param },
          Pathnames.logs,
          Pathnames.logsList
        );
      } else if (root === "/userCalender") {
        navigation(
          history,
          to,
          null,
          null,
          { urlParam: param },
          Pathnames.calender,
          Pathnames.mainMenu
        );
      } else {
        navigation(
          history,
          to,
          null,
          null,
          { urlParam: param },
          Pathnames.rev,
          Pathnames.calibrations
        );
      }
    }
  };

  return (
    <TouchableOpacity onPress={handleClick} style={active ? activeStyles : defaultStyles}>
      {children}
    </TouchableOpacity>
  );
}
