import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";
import {
  IProdTempCategories,
  IUpdateActionsRequest
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { ActionLogsRepository } from "@timhortons/common/src/repositories/logs/actionsLogs";
import { ActionsStationReadinessActions } from "@timhortons/common/src/redux/logs/actions/actionLogs/stationReadiness";
import {
  DailyCheckListActionsCategoriesModel,
  GetWeeklyActionQuestions
} from "@timhortons/common/src/models/logs/actionLogs/cleaning";

function* getActionsSubCategoryWorkerSaga(value: IFluxStandardAction<IProdTempCategories>) {
  const { payload } = value;
  try {
    let data: DailyCheckListActionsCategoriesModel[] = yield call(
      MainLogsRepository.getStationReadSubCategory,
      payload
    );
    yield put(ActionsStationReadinessActions.getActionsSReadSubCategorySuccess(data));
  } catch (error) {
    Logger.error("actionLogs-stationReadActions", error, "getActionsSubCategoryWorkerSaga");

    yield put(ActionsStationReadinessActions.getActionsSReadSubCategoryError(error));
  }
}
function* getActionsSubCategoryItemsWorkerSaga(
  value: IFluxStandardAction<{
    categoryId: string;
    label: string;
    storeCode: string;
    date: string;
    hasResolvedFilter?: Boolean;
  }>
) {
  try {
    const { payload } = value;
    let data: GetWeeklyActionQuestions[] | [] = yield call(
      ActionLogsRepository.getStationReadItems,
      payload
    );
    yield put(
      ActionsStationReadinessActions.getActionsSReadSubCategoryItemsSuccess({
        apiRes: data,
        metaData: { categoryId: payload.categoryId }
      })
    );
  } catch (error) {
    Logger.error("actionLogs-stationReadActions", error, "getActionsSubCategoryItemsWorkerSaga");

    yield put(ActionsStationReadinessActions.getActionsSReadSubCategoryItemsError(error));
  }
}
function* updateStationReadActionsWatcherSaga(value: IFluxStandardAction<IUpdateActionsRequest>) {
  const { payload } = value;
  try {
    let data: GetWeeklyActionQuestions[] = yield call(
      ActionLogsRepository.updateStationReadActions,
      payload
    );
    yield put(
      ActionsStationReadinessActions.updateStationReadActionsSuccess({
        apiRes: data,
        metaData: { categoryId: payload.categoryId, questionId: payload.itemId }
      })
    );
  } catch (error) {
    Logger.error("actionLogs-stationReadActions", error, "updateStationReadActionsWatcherSaga");

    yield put(ActionsStationReadinessActions.updateStationReadActionsError(error));
  }
}

function* stationReadActionsWatcherSaga() {
  yield takeLatest(
    LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES.LOADING,
    getActionsSubCategoryWorkerSaga
  );
  yield takeLatest(
    LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES_ITEMS.LOADING,
    getActionsSubCategoryItemsWorkerSaga
  );
  yield takeLatest(
    LOGS.Actions.StationRead.UPDATE_STATION_READINESS_ACTIONS.LOADING,
    updateStationReadActionsWatcherSaga
  );
}
export default stationReadActionsWatcherSaga;
