import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import ProgressBar from "@timhortons/common/src/components/atoms/progressBar";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { LpPreviousAuditDataModel } from "@timhortons/common/src/models/lp/audits";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { AuditType } from "@timhortons/common/src/modules/lp/components/dashboard/index";

interface IProps {
  translate: any;
  previousAuditData: LpPreviousAuditDataModel;
  deleteAudit: Function;
  history: any;
}

export default function LpPreviousAuditsTable(props: IProps): ReactElement {
  const { translate, previousAuditData, deleteAudit, history } = props;

  const previousLpAuditTableHeader = [
    { name: translate("Date") },
    { name: translate("storeNameTable") },
    { name: translate("percentageCompleted") },
    { name: "" }
  ];

  const getHeaderComp = (headerItem: any, index: number): ReactElement => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.black2 }}
        cellStyle={styles.headerCellStyle}
      />
    );
  };

  const deleteAuditHandler = () => {
    deleteAudit({ auditId: previousAuditData.id });
  };

  const resumeButtonHandler = () => {
    let searchParams = { id: previousAuditData.id, type: AuditType.STANDARD };
    navigation(history, Pathnames.lpAudit, searchParams);
  };

  return (
    <View style={styles.container}>
      <Table tableStyle={styles.container}>
        <Row header rowStyle={styles.rowheaderContainer}>
          {previousLpAuditTableHeader.map((headerItem, index) => getHeaderComp(headerItem, index))}
        </Row>
        <Row rowStyle={styles.rowContainer}>
          <Cell
            cellStyle={styles.headerCellStyle}
            titleStyle={styles.cellTitleStyle}
            title={dateFormat(previousAuditData.startTime)}
          />
          <Cell
            cellStyle={styles.headerCellStyle}
            titleStyle={styles.cellTitleStyle}
            title={previousAuditData.storeName}
          />
          <Cell cellStyle={styles.headerCellStyle}>
            <View style={styles.progressBarContainer}>
              <View style={styles.progressTagContainer}>
                <Text
                  textSize={TextSize.ExtraFineSmall}
                  fontWeight={FontWeight.Regular}
                  testId={testIds.testAudit.auditProgress}
                  textStyle={styles.progressTagStyle}
                >
                  {translate("progress")}
                </Text>
                <Text
                  textSize={TextSize.ExtraRegular}
                  fontWeight={FontWeight.SemiBold}
                  testId={testIds.testAudit.auditProgressPercent}
                  textStyle={styles.progressStyle}
                >
                  {previousAuditData.completedPercentage}%
                </Text>
              </View>
              <ProgressBar percentage={previousAuditData.completedPercentage} />
            </View>
          </Cell>
          <Cell cellStyle={styles.buttonCellStyle}>
            <Button
              type={ButtonType.Secondary}
              title={translate("resume")}
              onPress={() => resumeButtonHandler()}
              buttonStyles={styles.resumeButtonStyle}
            />
            <Icon
              name={IconNames.trash}
              customStyle={styles.resumeButtonStyle}
              onPress={deleteAuditHandler}
              size={18}
            />
          </Cell>
        </Row>
      </Table>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  },
  headerCellStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 30 },
      ltr: { marginStart: 30 }
    })
  },
  buttonCellStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginEnd: 12 },
      ltr: { marginEnd: 12 }
    }),
    ...I18nService.select({
      rtl: { marginStart: 18 },
      ltr: { marginStart: 18 }
    })
  },
  progressBarContainer: {
    flex: 1
  },
  progressTagContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  progressTagStyle: {
    color: colorPallete.grey3
  },
  progressStyle: {
    justifyContent: "flex-end",
    color: colorPallete.grey13
  },
  resumeButtonStyle: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    color: colorPallete.red2,
    backgroundColor: colorPallete.variant1,
    borderWidth: 0,
    borderRadius: 8,
    ...I18nService.select({
      rtl: { marginEnd: 18 },
      ltr: { marginEnd: 18 }
    })
  },
  cellTitleStyle: {
    color: colorPallete.grey1
  },
  rowContainer: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8
  },
  rowheaderContainer: {
    backgroundColor: colorPallete.grey9,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  }
});
