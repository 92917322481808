import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "news";

const MANAGENEWS = {
  GET_NEWSLIST: ActionHelper.actionTypesCreator(prefix, "GET_NEWSLIST"),
  GET_NEWS_DETAILS_BY_ID: ActionHelper.actionTypesCreator(prefix, "GET_NEWS_DETAILS_BY_ID"),
  NEWS_DELETION: ActionHelper.actionTypesCreator(prefix, "NEWS_DELETION"),
  GET_CATEGORY_LIST: ActionHelper.actionTypesCreator(prefix, "GET_CATEGORY_LIST"),
  POST_CREATE_NEW_NEWS: ActionHelper.actionTypesCreator(prefix, "POST_CREATE_NEW_NEWS"),
  GET_MEDIA_LINK: ActionHelper.actionTypesCreator(prefix, "GET_MEDIA_LINK"),
  CREATE_MEDIA: ActionHelper.actionTypesCreator(prefix, "CREATE_MEDIA"),
  SAVE_NEWS: ActionHelper.actionTypesCreator(prefix, "SAVE_NEWS"),
  PUBLISH_NEWS: ActionHelper.actionTypesCreator(prefix, "PUBLISH_NEWS"),
  RESET_PUBLISH_ERROR: "RESET_PUBLISH_ERROR",
  EDIT_TITLE: ActionHelper.actionTypesCreator(prefix, "EDIT_TITLE"),
  RESET_EDIT_TITLE_ERROR: "RESET_EDIT_TITLE_ERROR",
  DELETE_MEDIA: ActionHelper.actionTypesCreator(prefix, "DELETE_MEDIA")
};
export { MANAGENEWS };
