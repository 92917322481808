import { PRACTICAL_ASSESSMENT } from "@timhortons/common/src/redux/practicalAssessment/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  ModuleModel,
  PostChecklistPayLoad,
  TopicImageModel,
  TopicsAndChecklistModel,
  UserDetailsModel
} from "@timhortons/common/src/models/practicalAssessment/practicalAssessment";

const getUserDetails = (data: {
  pageSize: number;
  pageNumber: number;
  search: string;
}): IFluxStandardAction<{
  pageSize: number;
  pageNumber: number;
  search: string;
}> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_USERDETAILS_BY_USERID.LOADING,
    payload: data
  };
};
const getUserTableSuccess = (data: UserDetailsModel): IFluxStandardAction<UserDetailsModel> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_USERDETAILS_BY_USERID.SUCCESS,
    payload: data
  };
};
const getUserTableError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_USERDETAILS_BY_USERID.ERROR,
    payload: error
  };
};

const getAllModules = (data: { employeeId: string }): IFluxStandardAction<{}> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_ALL_MODULES.LOADING,
    payload: data
  };
};
const getAllModulesSuccess = (data: ModuleModel[]): IFluxStandardAction<any> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_ALL_MODULES.SUCCESS,
    payload: data
  };
};
const getAllModulesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_ALL_MODULES.ERROR,
    payload: error
  };
};

const getAllTopicsAndCheckList = (data: {
  employeeId: string;
  moduleId: string;
}): IFluxStandardAction<{ employeeId: string; moduleId: string }> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_ALL_TOPICS_AND_CHECKLIST.LOADING,
    payload: data
  };
};
const getAllTopicsAndCheckListSuccess = (
  data: TopicsAndChecklistModel[]
): IFluxStandardAction<any> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_ALL_TOPICS_AND_CHECKLIST.SUCCESS,
    payload: data
  };
};
const getAllTopicsAndCheckListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_ALL_TOPICS_AND_CHECKLIST.ERROR,
    payload: error
  };
};

const postChecklist = (data: PostChecklistPayLoad): IFluxStandardAction<PostChecklistPayLoad> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_CHECKLIST.LOADING,
    payload: data
  };
};

const postChecklistSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_CHECKLIST.SUCCESS,
    payload: data
  };
};

const postChecklistError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_CHECKLIST.ERROR,
    payload: error
  };
};

const postImages = (data: {
  employeeId: string;
  topicId: string;
  images: FormData[];
}): IFluxStandardAction<{ employeeId: string; topicId: string; images: FormData[] }> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_IMAGES.LOADING,
    payload: data
  };
};

const postImagesSuccess = (data: TopicImageModel[]): IFluxStandardAction<TopicImageModel[]> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_IMAGES.SUCCESS,
    payload: data
  };
};

const postImagesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_IMAGES.ERROR,
    payload: error
  };
};

const deleteImages = (data: { imageIds: string }): IFluxStandardAction<{ imageIds: string }> => {
  return {
    type: PRACTICAL_ASSESSMENT.DELETE_IMAGES.LOADING,
    payload: data
  };
};

const deleteImagesSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PRACTICAL_ASSESSMENT.DELETE_IMAGES.SUCCESS,
    payload: data
  };
};

const deleteImagesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.DELETE_IMAGES.ERROR,
    payload: error
  };
};

const postModules = (data: {
  employeeId: string;
  moduleId: string;
}): IFluxStandardAction<{ employeeId: string; moduleId: string }> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_MODULES.LOADING,
    payload: data
  };
};

const postModulesSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_MODULES.SUCCESS,
    payload: data
  };
};

const postModulesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.POST_MODULES.ERROR,
    payload: error
  };
};

const getUserInfo = (data: any): IFluxStandardAction<any> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_USER_INFO.LOADING,
    payload: data
  };
};

const getUserInfoSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_USER_INFO.SUCCESS,
    payload: data
  };
};

const getUserInFoError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PRACTICAL_ASSESSMENT.GET_USER_INFO.ERROR,
    payload: error
  };
};

const resetModules = () => {
  return {
    type: PRACTICAL_ASSESSMENT.RESET_MODULES.LOADING
  };
};

const resetAssessment = () => {
  return {
    type: PRACTICAL_ASSESSMENT.RESET_ASSESSMENT.LOADING
  };
};

const resetUserInfo = () => {
  return {
    type: PRACTICAL_ASSESSMENT.RESET_USER_INFO.LOADING
  };
};

const resetError = () => {
  return {
    type: PRACTICAL_ASSESSMENT.RESET_ERROR.LOADING
  };
};
export const PracticalAssessmentAction = {
  resetError,
  resetUserInfo,
  resetModules,
  resetAssessment,
  getUserInfo,
  getUserInfoSuccess,
  getUserInFoError,
  getUserDetails,
  getUserTableSuccess,
  getUserTableError,
  getAllModules,
  getAllModulesSuccess,
  getAllModulesError,
  getAllTopicsAndCheckList,
  getAllTopicsAndCheckListSuccess,
  getAllTopicsAndCheckListError,
  postChecklist,
  postChecklistSuccess,
  postChecklistError,
  postImages,
  postImagesSuccess,
  postImagesError,
  deleteImages,
  deleteImagesSuccess,
  deleteImagesError,
  postModules,
  postModulesSuccess,
  postModulesError
};
