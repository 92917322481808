import {
  LmsPTSCheckListDtoModel,
  LmsPTSCheckListUnselectModel,
  LmsPTSDataDtoModel
} from "@timhortons/common/src/models/userLms/userLmsPTS";

export const selectPtsCheckListFuncn = (
  checkedPtsData: LmsPTSCheckListUnselectModel,
  totalPtsData: LmsPTSDataDtoModel[],
  selectApiCall: boolean
): LmsPTSDataDtoModel[] => {
  let tempArr: LmsPTSDataDtoModel[] = [];
  tempArr = totalPtsData;
  if (tempArr && tempArr?.length > 0) {
    const topicIndexFiner = (topicItem: LmsPTSDataDtoModel) =>
      topicItem.topicId === checkedPtsData.topicId;
    let targetTopicIndex = tempArr.findIndex(topicIndexFiner);
    if (targetTopicIndex > -1) {
      const checkListIndexFinder = (checkListItem: LmsPTSCheckListDtoModel) =>
        checkListItem.checklistId === checkedPtsData.checklistId;
      let targetCheckListIndex =
        tempArr[targetTopicIndex] &&
        tempArr[targetTopicIndex].userPtsChecklistResponseDtoList &&
        tempArr[targetTopicIndex].userPtsChecklistResponseDtoList.findIndex(checkListIndexFinder);
      if (targetCheckListIndex > -1) {
        tempArr[targetTopicIndex].userPtsChecklistResponseDtoList[targetCheckListIndex].answer =
          selectApiCall;
      }
    }
  }
  return tempArr;
};
