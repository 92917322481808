import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "profile";

const PROFILE = {
  GET_INDIVIDUAL_PROFILE: ActionHelper.actionTypesCreator(prefix, "GET_INDIVIDUAL_PROFILE"),
  GET_STORE_TEAM_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_STORE_TEAM_DETAILS"),
  GET_INDIVIDUAL_TEAM_DETAILS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_INDIVIDUAL_TEAM_DETAILS"
  ),
  GET_STORE_PROFILE: ActionHelper.actionTypesCreator(prefix, "GET_STORE_PROFILE"),
  GET_USER_PROFILE_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_USER_PROFILE_DETAILS"),
  GET_DETAILED_USER_INFO: ActionHelper.actionTypesCreator(prefix, "GET_DETAILED_USER_INFO"),

  ////////    Create Store and user /////////
  RESET_CONTACT_INFO: "PROFILE/RESET_CONTACT_INFO",
  RESET_POST_CALLS: "PROFILE/RESET_POST_CALLS",
  RESET_CREATE_STORE: "PROFILE/RESET_CREATE_STORE",
  RESET_END_REACHED: "RESET_END_REACHED",
  GET_ALL_COUNTRIES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_COUNTRIES"),
  GET_ALL_CHANNELS: ActionHelper.actionTypesCreator(prefix, "GET_ALL_CHANNELS"),
  GET_STORE_SPECIFICS: ActionHelper.actionTypesCreator(prefix, "GET_STORE_SPECIFICS"),
  GET_ALL_CITIES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_CITIES"),
  GET_ALL_AREA_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_ALL_AREA_MANAGER"),
  GET_COUNTRY_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_COUNTRY_MANAGER"),
  GET_OPS_MANAGER: ActionHelper.actionTypesCreator(prefix, "GET_OPS_MANAGER"),
  GET_AREA_TRAINER: ActionHelper.actionTypesCreator(prefix, "GET_AREA_TRAINER"),
  GET_ALL_ROLES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_ROLES"),

  POST_CREATE_STORE: ActionHelper.actionTypesCreator(prefix, "POST_CREATE_STORE"),
  POST_CREATE_USER: ActionHelper.actionTypesCreator(prefix, "POST_CREATE_USER"),
  GET_STORE_TABLE_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_STORE_TABLE_DETAILS"),
  GET_USER_TABLE_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_USER_TABLE_DETAILS"),
  GET_EMPLOYEE_DOCUMENT: ActionHelper.actionTypesCreator(prefix, "GET_EMPLOYEE_DOCUMENT"),
  GET_EMPLOYEE_CERTIFICATE: ActionHelper.actionTypesCreator(prefix, "GET_EMPLOYEE_CERTIFICATE"),
  GET_EMPLOYEE_COUNT_DOCUMENT: ActionHelper.actionTypesCreator(
    prefix,
    "GET_EMPLOYEE_COUNT_DOCUMENT"
  ),
  GET_EMPLOYEE_COUNT_CERTIFICATE: ActionHelper.actionTypesCreator(
    prefix,
    "GET_EMPLOYEE_COUNT_CERTIFICATE"
  ),
  POST_EMPLOYEE_DOCUMENT: ActionHelper.actionTypesCreator(prefix, "POST_EMPLOYEE_DOCUMENT"),
  GET_STORE_BY_STORECODE: ActionHelper.actionTypesCreator(prefix, "GET_STORE_BY_STORECODE"),
  GET_ALL_STORES_LIST: ActionHelper.actionTypesCreator(prefix, "GET_ALL_STORES_LIST"),
  POST_ASSIGN_STORES: ActionHelper.actionTypesCreator(prefix, "POST_ASSIGN_STORES"),
  GET_EDIT_STORE_DETAILS: ActionHelper.actionTypesCreator(prefix, "GET_EDIT_STORE_DETAILS"),
  GET_GCC_COUNTRIES: ActionHelper.actionTypesCreator(prefix, "GET_GCC_COUNTRIES"),
  RESET_STORE_CALLS: "RESET_STORE_CALLS"
};

export { PROFILE };
