import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "practicalAssessment";

const PRACTICAL_ASSESSMENT = {
  GET_USERDETAILS_BY_USERID: ActionHelper.actionTypesCreator(prefix, "GET_USERDETAILS_BY_USERID"),
  GET_ALL_MODULES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_MODULES"),
  GET_ALL_TOPICS_AND_CHECKLIST: ActionHelper.actionTypesCreator(
    prefix,
    "GET_ALL_TOPICS_AND_CHECKLIST"
  ),
  POST_CHECKLIST: ActionHelper.actionTypesCreator(prefix, "POST_CHECKLIST"),
  POST_IMAGES: ActionHelper.actionTypesCreator(prefix, "POST_IMAGES"),
  DELETE_IMAGES: ActionHelper.actionTypesCreator(prefix, "DELETE_IMAGES"),
  POST_MODULES: ActionHelper.actionTypesCreator(prefix, "POST_MODULES"),
  GET_USER_INFO: ActionHelper.actionTypesCreator(prefix, "GET_USER_INFO"),
  RESET_MODULES: ActionHelper.actionTypesCreator(prefix, "RESET_MODULES"),
  RESET_ASSESSMENT: ActionHelper.actionTypesCreator(prefix, "RESET_ASSESSMENT"),
  RESET_USER_INFO: ActionHelper.actionTypesCreator(prefix, "RESET_USER_INFO"),
  RESET_ERROR: ActionHelper.actionTypesCreator(prefix, "RESET_ERROR")
};

export { PRACTICAL_ASSESSMENT };
