import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Card from "@timhortons/common/src/components/atoms/card";
import { TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { AppealTable } from "@timhortons/common/src/modules/rev/components/dashboards/appeals";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { IndividualAppealItemModel } from "@timhortons/common/src/models/rev/appeals";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { textBlock, CardHeader, ClosedTableCommon } from "./cardHeader";
import { AppealCardHeader, commonappealStoreStyles, mainStyles } from "./appealCard";
import ImgMapper from "@timhortons/common/src/components/organisms/imgMapper";
interface IProps {
  appealCardData: IndividualAppealItemModel;
  translate: any;
  currentTable: AppealTable;
  userDetails?: IUserDetails;
}

export default function StoreAccessAppealCard(props: IProps): ReactElement {
  const { appealCardData, translate, currentTable } = props;

  return (
    <View style={mainStyles(currentTable, appealCardData)}>
      <Card containerStyle={commonappealStoreStyles.cardContainerStyle} disabled={true}>
        <CardHeader translate={translate} />
        <Card.Body bodyStyle={commonappealStoreStyles.cardBodyContainerStyle}>
          <AppealCardHeader appealCardData={appealCardData} />
          <View style={{ flexDirection: "row" }}>
            {textBlock(
              TextSize.ExtraSmall,
              FontWeight.Regular,
              "appeal",
              [styles.cardBodyTextStyle, commonappealStoreStyles.storeAccessCardBody],
              appealCardData.appealerResponse.comment
            )}
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
              flexDirection: "row",
              marginVertical: 20
            }}
          >
            {appealCardData?.appealerResponse?.assets?.length > 0 && (
              <ImgMapper items={appealCardData.appealerResponse.assets.map((i) => i.signedUrl)} />
            )}
          </View>
        </Card.Body>
      </Card>
      {currentTable === AppealTable.closedAppeal ? (
        <View style={commonappealStoreStyles.cardFooterContainerStyle}>
          <ClosedTableCommon
            translate={translate}
            status={appealCardData.appealItemStatus}
            comment={appealCardData.auditorResponse.comment}
            textStyle={
              ((appealCardData.appealItemStatus === "APPROVED" ||
                appealCardData.appealItemStatus === "APPROVED_REVERIFICATION") && [
                commonappealStoreStyles.decisionTextStyle,
                commonappealStoreStyles.approveDecisionTextStyle
              ]) || [
                commonappealStoreStyles.decisionTextStyle,
                commonappealStoreStyles.rejectDecisionTextStyle
              ]
            }
          />
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  cardBodyTextStyle: {
    color: colorPallete.grey1
  },
  remarkTextStyle: {
    color: colorPallete.grey1
  },

  checkboxText: {
    flex: 1,
    color: colorPallete.grey1
  },
  bottomCheckbox: {
    marginBottom: 16
  },

  buttonIconStyles: {
    color: colorPallete.red6,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
  },
  textRejectStyle: {
    color: colorPallete.red6
  },

  selectedDropdownItem: {
    color: colorPallete.green1
  },
  unselectedDropdownItem: {
    color: colorPallete.white3
  },
  unselectButton: {
    color: colorPallete.white3,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
  },
  opportunityListText: {
    color: colorPallete.grey1
  },
  opportunityListTextNumber: {
    ...I18nService.select({
      rtl: { paddingStart: 7 },
      ltr: { paddingEnd: 7 }
    })
  }
});
