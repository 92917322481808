import { assessmentReducer, ILmsMobileAssessmentState } from "./assessmentReducer";
import { ILmsMobileQuizState, quizMobileReducer } from "./quizReducer";
import { combineReducers } from "redux";
import {
  IUserLmsState,
  userLmsReducer
} from "@timhortons/common/src/redux/userLms/reducers/userLms";
import { ILmsMobileMoreState, moreMobileReducer } from "./moreReducer";
import {
  IUserLmsPTSState,
  userLmsPTSReducer
} from "@timhortons/common/src/redux/userLms/reducers/userLmsPtsReducers";

export interface IMobileState {
  userLms: IUserLmsState;
  quizMobile: ILmsMobileQuizState;
  moreMobile: ILmsMobileMoreState;
  assessmentMobile: ILmsMobileAssessmentState;
  userLmsPTS: IUserLmsPTSState;
}

export const mobileReducer = combineReducers({
  userLms: userLmsReducer,
  quizMobile: quizMobileReducer,
  moreMobile: moreMobileReducer,
  assessmentMobile: assessmentReducer,
  userLmsPTS: userLmsPTSReducer
});
