import * as React from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";

interface IProps {
  children: any;
  tableStyle?: StyleProp<ViewStyle>;
}

export const Table = (props: IProps): React.ReactElement => {
  const { children, tableStyle } = props;
  return <View style={[styles.container, tableStyle]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    flex: 1
  }
});

export { Cell } from "./cell";
export { HeaderCell } from "./headerCell";
export { Row, RowType } from "./row";
