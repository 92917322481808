import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { TabObject } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import { Decision } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/timeTempActionTab";

export interface ITimeTempLogActionsLoading {
  getProductTempActions: boolean;
  updateProductTempActions: boolean;
  getProdTempActionsCategory: boolean;
}
export interface ITimeTempLogActionsError {
  getProductTempActions: string;
  updateProductTempActions: string;
  getProdTempActionsCategory: string;
}
export interface ITimeTempLogActionsState {
  loading: ITimeTempLogActionsLoading;
  error: ITimeTempLogActionsError;
  getProductTempActions: TabObject[];
  updateProductTempActions: any;
  getProdTempActionsCategory: any[];
}

export const timeTempLogActionsInitialState: ITimeTempLogActionsState = {
  getProductTempActions: null,
  updateProductTempActions: null,
  getProdTempActionsCategory: null,
  loading: {
    getProductTempActions: false,
    updateProductTempActions: false,
    getProdTempActionsCategory: false
  },
  error: {
    getProductTempActions: null,
    updateProductTempActions: null,
    getProdTempActionsCategory: null
  }
};

export const timeTempLogActionsReducer = (
  state = timeTempLogActionsInitialState,
  action: { payload: any; type: string }
): ITimeTempLogActionsState => {
  switch (action.type) {
    case LOGS.Actions.TimeTemp.GET__PRODUCT_TEMP_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProductTempActions: true },
        error: { ...state.error, getProductTempActions: null }
      };
    case LOGS.Actions.TimeTemp.GET__PRODUCT_TEMP_ACTIONS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProductTempActions: false },
        error: { ...state.error, getProductTempActions: null },
        getProductTempActions: action.payload
      };
    case LOGS.Actions.TimeTemp.GET__PRODUCT_TEMP_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProductTempActions: false },
        error: { ...state.error, getProductTempActions: action.payload }
      };

    case LOGS.Actions.TimeTemp.UPDATE_PRODUCT_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateProductTempActions: true },
        error: { ...state.error, updateProductTempActions: null }
      };
    case LOGS.Actions.TimeTemp.UPDATE_PRODUCT_ACTIONS.SUCCESS:
      let data = state.getProductTempActions;
      let index =
        data && data.findIndex((item: any) => item.uniqueItemId === action.payload.uniqueId);
      if (index > -1) {
        data[index].decision = action.payload.hasResolved
          ? Decision.resolved
          : Decision.notResolved;
      }
      return {
        ...state,
        loading: { ...state.loading, updateProductTempActions: false },
        error: { ...state.error, updateProductTempActions: null },
        updateProductTempActions: action.payload,
        getProductTempActions: data
      };
    case LOGS.Actions.TimeTemp.UPDATE_PRODUCT_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateProductTempActions: false },
        error: { ...state.error, updateProductTempActions: action.payload }
      };

    case LOGS.Actions.TimeTemp.RESET_TIME_TEMP_ACTION_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          updateProductTempActions: null
        }
      };
    case LOGS.Actions.TimeTemp.GET_TIME_TEMP_ACTIONS_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProdTempActionsCategory: true },
        error: { ...state.error, getProdTempActionsCategory: null }
      };
    case LOGS.Actions.TimeTemp.GET_TIME_TEMP_ACTIONS_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProdTempActionsCategory: false },
        error: { ...state.error, getProdTempActionsCategory: null },
        getProdTempActionsCategory: action.payload
      };
    case LOGS.Actions.TimeTemp.GET_TIME_TEMP_ACTIONS_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProdTempActionsCategory: false },
        error: { ...state.error, getProdTempActionsCategory: action.payload }
      };
    case LOGS.Actions.TimeTemp.RESET_PROD_TEMP_ACTION_CATEGORY_STATE:
      return {
        ...state,
        getProdTempActionsCategory: null
      };
    case LOGS.Actions.TimeTemp.RESET_TIME_TEMP_ACTIONS:
      return timeTempLogActionsInitialState;
    default:
      return state;
  }
};
