import { all } from "@redux-saga/core/effects";
import userLmsWatcherSaga from "@timhortons/common/src/redux/userLms/saga/userLmsWatcherSaga";
import moreMobileWatherSaga from "./moreWorkerSaga";
import quizMobileWatherSaga from "./quizWatcherSaga";
import assessmentWatcherSaga from "./assessmentWorkerSaga";
import userLmsPTSWatcherSaga from "@timhortons/common/src/redux/userLms/saga/userLmsPtsWatcherSaga";

export default function* mobileRootSaga() {
  yield all([
    userLmsWatcherSaga(),
    quizMobileWatherSaga(),
    moreMobileWatherSaga(),
    assessmentWatcherSaga(),
    userLmsPTSWatcherSaga()
  ]);
}
