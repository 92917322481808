import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "userResources";

const RESOURCES = {
  GET_CONTENTS: ActionHelper.actionTypesCreator(prefix, "GET_CONTENTS"),
  GET_SUBCATEGORIES_TOPICS: ActionHelper.actionTypesCreator(prefix, " GET_SUBCATEGORIES_TOPICS"),
  GET_SEARCH_RESULTS: ActionHelper.actionTypesCreator(prefix, "GET_SEARCH_RESULTS"),
  GET_FILTERS: ActionHelper.actionTypesCreator(prefix, "GET_FILTERS"),
  UPDATE_FILTERS: ActionHelper.actionTypesCreator(prefix, "UPDATE_FILTERS"),
  CLEAR_FILTERS: ActionHelper.actionTypesCreator(prefix, "CLEAR_FILTERS"),
  CLEAR_SEARCH_RESULTS: ActionHelper.actionTypesCreator(prefix, "CLEAR_SEARCH_RESULTS"),
  GET_SUBCATEGORIES_LIST: ActionHelper.actionTypesCreator(prefix, "GET_SUBCATEGORIES_LIST"),
  GET_TOPIC_CONTENT: ActionHelper.actionTypesCreator(prefix, "GET_TOPIC_CONTENT"),
  POST_SUBCATEGORY: ActionHelper.actionTypesCreator(prefix, "POST_SUBCATEGORY"),
  POST_TOPIC: ActionHelper.actionTypesCreator(prefix, "POST_TOPIC"),
  RESET_SUBCATEGORY_DATA: "RESOURCES/RESET_SUBCATEGORY_DATA",
  POST_CREATE_NEW_CONTENT: ActionHelper.actionTypesCreator(prefix, "POST_CREATE_NEW_CONTENT"),
  TOPIC_DELETION: ActionHelper.actionTypesCreator(prefix, "TOPIC_DELETION"),
  CATEGORY_DELETION: ActionHelper.actionTypesCreator(prefix, "CATEGORY_DELETION"),
  UPLOAD_IMAGES: ActionHelper.actionTypesCreator(prefix, "UPLOAD_IMAGE"),
  PUBLISH_CATEGORY: ActionHelper.actionTypesCreator(prefix, "PUBLISH_CATEGORY"),
  PUBLISHED_CATEGORIES: ActionHelper.actionTypesCreator(prefix, "PUBLISHED_CATEGORIES"),
  PUBLISHED_SUBCATEGORIES_LIST: ActionHelper.actionTypesCreator(
    prefix,
    "PUBLISHED_SUBCATEGORIES_LIST"
  ),
  PUBLISHED_TOPIC_CONTENT: ActionHelper.actionTypesCreator(prefix, "PUBLISHED_TOPIC_CONTENT"),
  PUBLISHED_TOPIC_LIST: ActionHelper.actionTypesCreator(prefix, "PUBLISHED_TOPIC_LIST"),
  PUT_CATEGORY_TITLE: ActionHelper.actionTypesCreator(prefix, "PUT_CATEGORY_TITLE"),
  DELETE_SUB_CATEGORY_TITLE: ActionHelper.actionTypesCreator(prefix, "DELETE_SUB_CATEGORY_TITLE"),
  RESET_POST_SUBCATEGORY_DATA: "RESOURCES/RESET_POST_SUBCATEGORY_DATA"
};
export { RESOURCES };
