import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { History } from "history";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { bindActionCreators } from "redux";
import { ManageLmsModuleSelectors } from "@timhortons/common/src/redux/manageLms/selectors/manageLmsModuleSelectors";
import { IState } from "@timhortons/common/src/store/interfaces";
import { ManageLmsModuleAction } from "@timhortons/common/src/redux/manageLms/actions/manageLmsModuleActions";
import { connect } from "react-redux";
import {
  LMSGetModuleByIdModel,
  LMSGetProgressBarStatusModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import { allRoutesNames } from "@timhortons/common/src/utils/allRouteNames";
import { IManageLmsModuleLoading } from "@timhortons/common/src/redux/manageLms/reducers/manageLmsModuleReducer";
import Loader from "@timhortons/common/src/components/molecules/loader";

interface IProps {
  history: History;
  translate?: any;
  currentComponent: string;
  handleNavigation: Function;
  getProgressBarStatus: Function;
  moduleDetail: LMSGetModuleByIdModel;
  lmsLoading: IManageLmsModuleLoading;
  moduleArray: any;
}
export enum Status {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED"
}
interface ISelectedsProp {
  text: number;
  module: string;
  isFirst: boolean;
  isMiddle: boolean;
  isActive: boolean;
  isLast: boolean;
  path: Pathnames;
}
interface IUnSelectedProp {
  text: number;
  module: string;
  isFirst: boolean;
  isMiddle: boolean;
  isActive: boolean;
  isLast: boolean;
}
function ManageLmsProgressBar(props: IProps) {
  const {
    history,
    translate,
    currentComponent,
    handleNavigation,
    getProgressBarStatus,
    moduleDetail,
    lmsLoading,
    moduleArray
  } = props;

  const handleModuleClick = (rootUrl: Pathnames) => {
    handleNavigation(rootUrl);
  };
  const currArrLen = useMemo(() => moduleArray.length, [moduleArray]);
  const GreyLine = () => {
    return <View style={styles.greyLine} />;
  };
  const RedLine = () => {
    return <View style={styles.line} />;
  };
  const NoLine = () => {
    return <View style={styles.imaginoryLine} />;
  };

  const SelectedModule = (props: ISelectedsProp) => {
    const { module, text, isFirst, isMiddle, isActive, path } = props;
    return (
      <TouchableOpacity style={{ flexDirection: "row" }} onPress={() => handleModuleClick(path)}>
        <View style={styles.firstSelectedContainer}>
          <View style={styles.container}>
            {isFirst && <NoLine />}
            {(!isFirst || isMiddle) && <RedLine />}
            {isActive ? (
              <View style={styles.outerCircle}>
                <View style={styles.innerCircle}>
                  <Text
                    style={styles.number}
                    textSize={TextSize.Small}
                    fontWeight={FontWeight.Light}
                    testId={"managelms-Progressbar-number"}
                  >
                    {text}
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.innerCircle}>
                <Text
                  textSize={TextSize.Small}
                  fontWeight={FontWeight.Light}
                  testId={"managelms-Progressbar-number2"}
                  style={styles.number}
                >
                  {text}
                </Text>
              </View>
            )}
            {(isFirst || isMiddle) && <RedLine />}
          </View>
          <View style={styles.container}>
            <Text
              fontWeight={isActive ? FontWeight.Medium : FontWeight.Regular}
              textSize={isActive ? TextSize.Regular : TextSize.FineRegular}
              style={[
                isActive ? styles.isActiveSelectedTextStyle : styles.isActiveUnSelectedTextStyle
              ]}
              testId={"manageLms-assignedLearning-selected-moduleName"}
            >
              {module}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  const UnSelectedModule = (props: IUnSelectedProp) => {
    const { module, text, isFirst, isMiddle, isActive, isLast } = props;
    return (
      <View style={{ flexDirection: "row" }}>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <View style={styles.itemContainer}>
            {(!isFirst || isMiddle) && <GreyLine />}
            {isActive ? (
              <View style={styles.outerCircle}>
                <View style={styles.greyCircle}>
                  <Text
                    textSize={TextSize.Small}
                    fontWeight={FontWeight.Light}
                    testId={"managelms-Progressbar-number3"}
                    style={styles.number}
                  >
                    {text}
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.greyCircle}>
                <Text
                  textSize={TextSize.Small}
                  fontWeight={FontWeight.Light}
                  testId={"managelms-Progressbar-number4"}
                  style={styles.number}
                >
                  {text}
                </Text>
              </View>
            )}
            {isMiddle && <GreyLine />}
            {isLast && <NoLine />}
          </View>
          <View style={styles.itemContainer}>
            <Text
              fontWeight={isActive ? FontWeight.Medium : FontWeight.Regular}
              textSize={isActive ? TextSize.Regular : TextSize.FineRegular}
              style={
                isActive ? styles.isActiveSelectedTextStyle : styles.isActiveUnSelectedTextStyle
              }
              testId={"manageLms-assignedLearning-unSelected-moduleName"}
            >
              {module}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      {lmsLoading.getProgressBarStatus ? (
        <Loader type="component" />
      ) : (
        <View style={styles.wholeStyle}>
          {moduleArray.map((item: any) => (
            <>
              {item.key === 1 && item.status === true && (
                <SelectedModule
                  isFirst={true}
                  isMiddle={false}
                  text={item.key}
                  module={item.title}
                  isActive={item.active}
                  isLast={false}
                  path={item.path}
                />
              )}
              {item.key !== 1 && item.key !== currArrLen && item.status === true && (
                <SelectedModule
                  isFirst={false}
                  isMiddle={true}
                  text={item.key}
                  module={item.title}
                  isActive={item.active}
                  isLast={false}
                  path={item.path}
                />
              )}
              {item.key === currArrLen && item.status === true && (
                <SelectedModule
                  isFirst={false}
                  isMiddle={false}
                  text={item.key}
                  module={item.title}
                  isActive={item.active}
                  isLast={true}
                  path={item.path}
                />
              )}
              {item.key !== 1 && item.key !== currArrLen && item.status === false && (
                <UnSelectedModule
                  isFirst={false}
                  isMiddle={true}
                  text={item.key}
                  module={item.title}
                  isActive={item.active}
                  isLast={false}
                />
              )}
              {item.key === currArrLen && item.status === false && (
                <UnSelectedModule
                  isFirst={false}
                  isMiddle={false}
                  text={item.key}
                  module={item.title}
                  isActive={item.active}
                  isLast={true}
                />
              )}
            </>
          ))}
        </View>
      )}
    </>
  );
}

const mapStateToProps = (state: IState) => {
  return {
    lmsLoading: ManageLmsModuleSelectors.manageLmsModuleLoading(state),
    moduleDetail: ManageLmsModuleSelectors.lmsModuleListById(state),
    progress: ManageLmsModuleSelectors.getLmsProgressBarStatus(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...ManageLmsModuleAction }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageLmsProgressBar);

const styles = StyleSheet.create({
  isActiveSelectedTextStyle: {
    paddingVertical: 10,
    color: colorPallete.black3,
    fontWeight: FontWeight.Bold
  },
  isActiveUnSelectedTextStyle: {
    paddingVertical: 10,
    color: colorPallete.grey2
  },
  firstSelectedContainer: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  itemContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
    // backgroundColor:"orange"
    // marginBottom:16
  },
  greyCircle: {
    height: 35,
    width: 35,
    borderRadius: 17.5,
    backgroundColor: colorPallete.grey4,
    justifyContent: "center",
    alignItems: "center"
  },
  outerCircle: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colorPallete.white3,
    justifyContent: "center",
    borderWidth: 3,
    borderColor: colorPallete.red1,
    alignItems: "center"
  },
  innerCircle: {
    height: 35,
    width: 35,
    borderRadius: 17.5,
    backgroundColor: colorPallete.red1,
    justifyContent: "center",
    alignItems: "center"
  },
  number: {
    color: colorPallete.white3
  },
  line: {
    width: 35,
    height: 2,
    backgroundColor: colorPallete.red1,
    zIndex: 1
  },
  imaginoryLine: {
    width: 35,
    height: 0
  },
  greyLine: {
    width: 35,
    height: 2,
    backgroundColor: colorPallete.grey4,
    zIndex: 1
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  wholeStyle: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16
  },
  singleCircle: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  }
});
