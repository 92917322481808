import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import { FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { Roles } from "@timhortons/common/src/constants/roles";
import {
  ComparisonStoresAdminModel,
  GetStoreByStoreCode
} from "@timhortons/common/src/models/profile";
import {
  GetStoreListByCodeOrNamePayload,
  PreviousAuditDataModel
} from "@timhortons/common/src/models/rev/selfCalibration";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { AuditType } from "@timhortons/common/src/modules/rev/components/dashboards";
import AuditModal from "@timhortons/common/src/modules/common/organisms/authModal/index";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import PreviousAuditsTable from "@timhortons/common/src/modules/rev/components/dashboards/organisms/previousAuditsTable/index";
import SaiGlobal from "@timhortons/common/src/modules/rev/components/testAudit/organisms/saiGlobal";
import { ProfilesAction } from "@timhortons/common/src/redux/profile/actions";
import { IProfilesError } from "@timhortons/common/src/redux/profile/reducers";
import { ProfilesSelectors } from "@timhortons/common/src/redux/profile/selectors";
import { SelfCalibrationActions } from "@timhortons/common/src/redux/rev/actions/selfCalibration";
import {
  ISelfCalibrationError,
  ISelfCalibrationLoading
} from "@timhortons/common/src/redux/rev/reducers/selfCalibration";
import { SelfCalibrationSelectors } from "@timhortons/common/src/redux/rev/selectors/selfCalibration";
import { IExternalMeta } from "@timhortons/common/src/repositories/rev/selfCalibration";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { IState } from "@timhortons/common/src/store/interfaces";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

interface IProps {
  history: any;
  translate?: any;
  currentUser?: IUserDetails;
  modalVisible?: boolean;
  isIndividualLogin?: boolean;
  setIndividualLogin?: Function;
  setModalState?: () => void;
  handleAuth?: Function;
  getPreviousAuditData: Function;
  previousAuditData: PreviousAuditDataModel;
  deleteAudit: Function;
  loading: ISelfCalibrationLoading;
  getExternalMetaDetails: () => void;
  externalMetaData: IExternalMeta;
  postExternalAudit: Function;
  error: ISelfCalibrationError;
  getStoreByStoreCode: Function;
  storeByStoreCode: GetStoreByStoreCode;
  profileError: IProfilesError;
  postExternalAuditData: any;
  resetAuthID: Function;
  getStoreListByStoreCodeOrName?: (value: GetStoreListByCodeOrNamePayload) => void;
  getStoresLists?: ComparisonStoresAdminModel;
  getStorelistByCodeIsEnd?: boolean;
  getMoreSToreListLoading?: boolean;
  resetStoreListState?: () => void;
}

function SelfCalibration(props: IProps): ReactElement {
  const {
    translate,
    currentUser,
    modalVisible,
    setModalState,
    isIndividualLogin,
    setIndividualLogin,
    handleAuth,
    getPreviousAuditData,
    previousAuditData,
    deleteAudit,
    loading,
    getExternalMetaDetails,
    externalMetaData,
    postExternalAudit,
    error,
    history,
    getStoreByStoreCode,
    storeByStoreCode,
    profileError,
    postExternalAuditData,
    resetAuthID,
    getStoreListByStoreCodeOrName,
    getStoresLists,
    getStorelistByCodeIsEnd,
    getMoreSToreListLoading,
    resetStoreListState
  } = props;

  const [currentButton, setCurrentButton] = useState<AuditType>(null);
  const [saiModalVisib, setSaiModalVisib] = useState<boolean>(false);
  const isFocused = useFocusedHook();
  useEffect(() => {
    if (checkAllowedRoles(currentUser && currentUser.roles)) {
      setIndividualLogin(true);
    }
    isFocused &&
      currentUser &&
      getPreviousAuditData({
        empId: currentUser.empId ?? currentUser.storeCode,
        role: currentUser.roles
      });
  }, [currentUser, isFocused]);

  const handleButtonPress = (type: AuditType) => {
    setCurrentButton(type);
    if (type === AuditType.EXTERNAL) {
      setSaiModalVisib(true);
      getExternalMetaDetails();
    } else {
      setModalState();
    }
  };

  const disableStatus = previousAuditData && previousAuditData !== null;

  return error.previousAuditData ? (
    <View style={styles.container}>
      <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
    </View>
  ) : loading.previousAuditData || loading.checkAuth ? (
    <Loader type="component" />
  ) : (
    <View style={styles.container}>
      {modalVisible ? (
        <GetModal setModalState={setModalState}>
          <AuditModal
            resetStoreListState={resetStoreListState}
            getStoresLists={getStoresLists}
            handleAuth={handleAuth}
            translate={translate}
            modalVisible={modalVisible}
            setModalState={setModalState}
            isIndividualLogin={isIndividualLogin}
            currentButton={currentButton}
            user={currentUser}
            getStoreListByStoreCodeOrName={getStoreListByStoreCodeOrName}
            error={error?.getStoreListByCodeOrName}
            getStorelistByCodeIsEnd={getStorelistByCodeIsEnd}
            getMoreSToreListLoading={getMoreSToreListLoading}
          />
        </GetModal>
      ) : (
        <>
          {saiModalVisib ? (
            <SaiGlobal
              postExternalAudit={postExternalAudit}
              externalMetaData={externalMetaData}
              translate={translate}
              setShow={setSaiModalVisib}
              getStoreByStoreCode={getStoreByStoreCode}
              storeByStoreCode={storeByStoreCode}
              getStoreByStoreCodeError={profileError.getStoreByStoreCode}
              currentUser={currentUser}
              error={error}
              postAuditSuccess={postExternalAuditData}
              resetAuthID={resetAuthID}
            />
          ) : null}
          <View style={styles.buttonContainer}>
            {isIndividualLogin && (
              <>
                <View style={{ flex: 1 }}>
                  <Button
                    type={ButtonType.Primary}
                    title={translate("createStandardAudit")}
                    buttonStyles={[styles.ButtonStyle, styles.standardBtnStyle]}
                    onPress={() => handleButtonPress(AuditType.STANDARD)}
                    textStyles={styles.ctaTextStyle}
                    textSize={TextSize.Regular}
                    fontWeight={FontWeight.SemiBold}
                    iconName={IconNames.plus}
                    iconSize={12}
                    iconStyles={styles.ctaIconStyle}
                    disabled={disableStatus}
                  />
                </View>
                {(currentUser.roles.includes(Roles.SuperAdmin) ||
                  currentUser.roles.includes(Roles.TrainingAndDevLead) ||
                  currentUser.roles.includes(Roles.TrainingAndDevManager) ||
                  // currentUser.roles.includes(Roles.Admin) ||
                  currentUser.roles.includes(Roles.InternalAuditor)) && (
                  <View style={{ flex: 1 }}>
                    <Button
                      type={ButtonType.Primary}
                      title={translate("importSaiGlobalAudit")}
                      buttonStyles={[styles.ButtonStyle, styles.standardBtnStyle]}
                      onPress={() => handleButtonPress(AuditType.EXTERNAL)}
                      textStyles={styles.ctaTextStyle}
                      textSize={TextSize.Regular}
                      fontWeight={FontWeight.SemiBold}
                      iconName={IconNames.externalAuditImp}
                      iconSize={12}
                      iconStyles={styles.ctaIconStyle}
                    />
                  </View>
                )}
              </>
            )}
            <View style={{ flex: 1 }}>
              <Button
                type={ButtonType.Primary}
                title={translate("createTestAudit")}
                buttonStyles={[styles.ButtonStyle, styles.standardBtnStyle]}
                onPress={() => handleButtonPress(AuditType.TEST)}
                textStyles={styles.ctaTextStyle}
                textSize={TextSize.Regular}
                fontWeight={FontWeight.SemiBold}
                iconName={IconNames.plus}
                iconSize={12}
                iconStyles={styles.ctaIconStyle}
                disabled={disableStatus}
              />
            </View>
          </View>
        </>
      )}

      {previousAuditData && (
        <View
          style={{
            marginTop: 30
          }}
        >
          <PreviousAuditsTable
            translate={translate}
            previousAuditData={previousAuditData}
            deleteAudit={deleteAudit}
            history={history}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 30,
    flexDirection: "column"
  },
  buttonContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  standardBtnStyle: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    }),
    // padding: 8,
    flex: 1,
    paddingVertical: 8,
    borderWidth: 0

    // borderWidth: 0
  },
  ButtonStyle: {
    flex: 1,
    // borderStyle: "dashed",
    borderWidth: 0,
    borderRadius: 8,
    paddingVertical: 8

    // borderColor: "red"
  },
  ctaButtonStyle: {
    flex: 1,
    borderColor: colorPallete.red1,
    paddingVertical: 8
  },
  disableCtaButtonStyle: {
    flex: 1,
    backgroundColor: colorPallete.white3,
    paddingVertical: 8,
    borderColor: colorPallete.variant10
  },
  ctaTextStyle: {
    marginTop: 2
  },
  ctaIconStyle: {
    // fontSize: 12,
    // lineHeight: 24,

    ...I18nService.select({
      rtl: { marginEnd: 6 },
      ltr: { marginEnd: 6 }
    })
    // marginEnd: 6
  },
  ctaColor: {
    color: colorPallete.white3
  },
  disabledCtaColor: {
    color: colorPallete.variant10
  },
  loader: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    previousAuditData: SelfCalibrationSelectors.selectPreviousAuditData(state),
    loading: SelfCalibrationSelectors.selfCalibrationLoading(state),
    error: SelfCalibrationSelectors.selfCalibrationError(state),
    externalMetaData: SelfCalibrationSelectors.getExternalMeta(state),
    storeByStoreCode: ProfilesSelectors.getStoreByStoreCode(state),
    profileError: ProfilesSelectors.profilesError(state),
    postExternalAuditData: SelfCalibrationSelectors.postExternalAuditData(state),
    getStoresLists: SelfCalibrationSelectors.getStoresListByStoreCodeName(state),
    getStorelistByCodeIsEnd: SelfCalibrationSelectors.getStorelistByCodeIsEnd(state),
    getMoreSToreListLoading: SelfCalibrationSelectors.getMoreSToreListLoading(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...SelfCalibrationActions, ...ProfilesAction }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfCalibration);
