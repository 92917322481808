import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "IAM";
const IAM = {
  LOGIN: ActionHelper.actionTypesCreator(prefix, "LOGIN"),
  FORGOT_PASSWORD: ActionHelper.actionTypesCreator(prefix, "FORGOT_PASSWORD"),
  MASTER_MODULES: ActionHelper.actionTypesCreator(prefix, "MASTER_MODULES")
};

export { IAM };
