import React from "react";
import {
  Text as RNText,
  StyleSheet,
  StyleProp,
  TextStyle,
  TextProps,
  Dimensions,
  PixelRatio
} from "react-native";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
export enum TextSize {
  ExtraLarge = "extraLarge", // size - 24, LH - 36
  Large = "large", // size - 20, LH - 30
  ExtraMedium = "extraMedium", // size - 20, LH - 24
  Medium = "medium", // size - 20, LH - 20
  Medium2 = "medium2", // size-18 LH-27
  Medium3 = "medium3", // size-18 LH-27
  ExtraRegular = "extraRegular", // size - 16, LH - 24
  Regular = "regular", // size - 16, LH - 20.96
  Small = "small", // size - 15, LH - 22.5
  ExtraSmall = "extraSmall", // size - 14, LH - 21,
  MediumLarge = "mediumLarge", // size - 42, LH - 42
  ExtraDoubeLarge = "doubleLarge", // size - 28, LH - 28
  ExtraFineSmall = "fineSmall", // size - 12, LH - 18
  FineRegular = "fineRegular", // size - 14, LH - 14
  ExtraFineRegular = "extraFineRegular", // size - 16, LH - 16
  ExtremeLarge = "extremeLarge", // size -30, LH - 45
  ExtremeSmall = "extremeSmall"
}
export enum FontFamily {
  ExtraLight = "Sofia-Pro-Extra-Light",
  Ultralight = "Sofia-Pro-Ultra-Light",
  Light = "Sofia-Pro-Light",
  Regular = "Sofia-Pro-Regular",
  Medium = "Sofia-Pro-Medium",
  Bold = "Sofia-Pro-Bold",
  SemiBold = "Sofia-Pro-Semi-Bold",
  // Italic = "Sofia-Pro-Black-Italic",
  Black = "Sofia-Pro-Ultra-Light",
  // QuintusRegular = "Quintus-Regular",
  // QuintusBold = "Quintus-Bold",
  AutoGraphRegular = "Autograph-Regular"
}
export enum FontWeight {
  ExtraLight = "eLight", // 400
  Light = "light", // 400
  Regular = "normal", // 400
  Medium = "medium", // 500
  Bold = "bold", // 700
  SemiBold = "semiBold", // 600
  black = "black", // 900
  UltraLight = "uLight", // 400
  QuintusReg = "quintusRegular", // 400
  QuintusBold = "quintusBold", // 400
  QuintusExtraBold = "quintusExtraBold", // 700
  AutoGraphRegular = "autoGraphRegular"
}
interface IStyle {
  textExtraLarge: TextStyle;
  textMediumLarge: TextStyle;
  textDoubleLarge: TextStyle;
  textExtremeLarge: TextStyle;
  textLarge: TextStyle;
  textExtraMedium: TextStyle;
  textMedium: TextStyle;
  textMedium2: TextStyle;
  textMedium3: TextStyle;
  textExtraRegular: TextStyle;
  textRegular: TextStyle;
  textFineRegular: TextStyle;
  textExtraFineRegular: TextStyle;
  textSmall: TextStyle;
  textExtraSmall: TextStyle;
  textFineSmall: TextStyle;
  titleSmall: TextStyle;
  titleRegular: TextStyle;
  titleLarge: TextStyle;
  labelRegular: TextStyle;
  labelSmall: TextStyle;
  labelLarge: TextStyle;
  textExtremeSmall: TextStyle;
}
interface IProps extends TextProps {
  textSize: TextSize;
  fontWeight: FontWeight;
  textStyle?: StyleProp<TextStyle>;
  testId: string;
  children: string | number | boolean | any;
}
const testIdPrefix = {
  label: "lbl",
  text: "txt",
  title: "ttl"
};
const getFontStyle = (weight: FontWeight): StyleProp<TextStyle> => {
  switch (weight) {
    case FontWeight.UltraLight:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro-UltraLight" : FontFamily.Ultralight,
        fontWeight: "400"
      };
    case FontWeight.ExtraLight:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro-ExtraLight" : FontFamily.ExtraLight,
        fontWeight: "400"
      };
    case FontWeight.Light:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro-Light" : FontFamily.Light,
        fontWeight: "400"
      };
    case FontWeight.Medium:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro-Medium" : FontFamily.Medium,
        fontWeight: "500"
      };
    case FontWeight.Bold:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro-Bold" : FontFamily.Bold,
        fontWeight: "700"
      };
    case FontWeight.SemiBold:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro-SemiBold" : FontFamily.SemiBold,
        fontWeight: "normal"
      };
    case FontWeight.black:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro-Black" : FontFamily.Black,
        fontWeight: "900"
      };
    case FontWeight.Regular:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro" : FontFamily.Regular,
        fontWeight: "400"
      };
    // case FontWeight.QuintusExtraBold:
    //   return {
    //     fontFamily: PlatformUtils.isIOS() ? "Quintus-BoldTRIAL" : FontFamily.QuintusBold,
    //     fontWeight: "700"
    //   };
    // case FontWeight.QuintusBold:
    //   return {
    //     fontFamily: PlatformUtils.isIOS() ? "Quintus-BoldTRIAL" : FontFamily.QuintusBold,
    //     fontWeight: "400"
    //   };
    // case FontWeight.QuintusReg:
    //   return {
    //     fontFamily: PlatformUtils.isIOS() ? "QuintusRegular-TRIAL" : FontFamily.QuintusRegular,
    //     fontWeight: "400"
    //   };
    case FontWeight.AutoGraphRegular:
      return {
        fontFamily: PlatformUtils.isIOS() ? "Autograph-Regular" : FontFamily.AutoGraphRegular,
        fontWeight: "400"
      };
    default:
      return {
        fontFamily: PlatformUtils.isIOS() ? "SofiaPro" : FontFamily.Regular,
        fontWeight: "normal"
      };
  }
};
const Title = (props: IProps): React.ReactElement<RNText> => {
  const { fontWeight, textSize, textStyle, children, testId } = props;
  let defaultStyle: TextStyle = {};
  const fontStyle: StyleProp<TextStyle> = getFontStyle(fontWeight);
  switch (textSize) {
    case TextSize.Small:
      defaultStyle = styles.titleSmall;
      break;
    case TextSize.Large:
      defaultStyle = styles.titleLarge;
      break;
    case TextSize.Regular:
    default:
      defaultStyle = styles.titleRegular;
      break;
  }
  return (
    <RNText
      accessibilityLabel={`${testIdPrefix.title}-${testId}`}
      testID={`${testIdPrefix.title}-${testId}`}
      style={[defaultStyle, fontStyle, textStyle]}
      {...props}
    >
      {children}
    </RNText>
  );
};
const Text = (props: IProps): React.ReactElement => {
  const { fontWeight, textSize, textStyle, children, testId } = props;
  let defaultStyle: TextStyle = {};
  const fontStyle: StyleProp<TextStyle> = getFontStyle(fontWeight);
  switch (textSize) {
    case TextSize.ExtraLarge:
      defaultStyle = styles.textExtraLarge;
      break;
    case TextSize.Large:
      defaultStyle = styles.textLarge;
      break;
    case TextSize.MediumLarge:
      defaultStyle = styles.textMediumLarge;
      break;
    case TextSize.ExtraDoubeLarge:
      defaultStyle = styles.textDoubleLarge;
      break;
    case TextSize.ExtremeLarge:
      defaultStyle = styles.textExtremeLarge;
      break;
    case TextSize.ExtraMedium:
      defaultStyle = styles.textExtraMedium;
      break;
    case TextSize.ExtraRegular:
      defaultStyle = styles.textExtraRegular;
      break;
    case TextSize.Regular:
      defaultStyle = styles.textRegular;
      break;
    case TextSize.ExtraFineRegular:
      defaultStyle = styles.textFineRegular;
      break;
    case TextSize.FineRegular:
      defaultStyle = styles.textExtraFineRegular;
      break;
    case TextSize.Small:
      defaultStyle = styles.textSmall;
      break;
    case TextSize.ExtraSmall:
      defaultStyle = styles.textExtraSmall;
      break;
    case TextSize.ExtraFineSmall:
      defaultStyle = styles.textFineSmall;
      break;
    case TextSize.ExtremeSmall:
      defaultStyle = styles.textExtremeSmall;
      break;
    case TextSize.Medium2:
      defaultStyle = styles.textMedium2;
      break;
    case TextSize.Medium3:
      defaultStyle = styles.textMedium3;
      break;
    case TextSize.Medium:
    default:
      defaultStyle = styles.textMedium;
      break;
  }
  return (
    <RNText
      accessibilityLabel={`${testIdPrefix.title}-${testId}`}
      testID={`${testIdPrefix.title}-${testId}`}
      style={[defaultStyle, fontStyle, textStyle]}
      {...props}
    >
      {children}
    </RNText>
  );
};
const Label = (props: IProps): React.ReactElement => {
  const { fontWeight, textSize, textStyle, children, testId } = props;
  let defaultStyle: TextStyle = {};
  const fontStyle: StyleProp<TextStyle> = getFontStyle(fontWeight);
  switch (textSize) {
    case TextSize.Small:
      defaultStyle = styles.labelSmall;
      break;
    case TextSize.Large:
      defaultStyle = styles.labelLarge;
      break;
    case TextSize.Regular:
    default:
      defaultStyle = styles.labelRegular;
      break;
  }
  return (
    <RNText
      accessibilityLabel={`${testIdPrefix.title}-${testId}`}
      testID={`${testIdPrefix.title}-${testId}`}
      style={[defaultStyle, fontStyle, textStyle]}
      {...props}
    >
      {children}
    </RNText>
  );
};
const screenWidth = Dimensions.get("window").width;
const guidelineBaseWidth = 375;

const scaleFont = (size: number) => {
  if (PlatformUtils.isWeb()) {
    return size;
  } else if (screenWidth <= guidelineBaseWidth) {
    return size * 1 - PixelRatio.get() / 10;
  } else if (screenWidth < 1152 && screenWidth >= 1024) {
    return size - 1.5;
  } else {
    return size;
  }
};

const styles: IStyle = StyleSheet.create({
  textExtraLarge: {
    fontSize: scaleFont(24),
    lineHeight: scaleFont(36)
  },
  textMediumLarge: {
    fontSize: scaleFont(42),
    lineHeight: scaleFont(42)
  },
  textDoubleLarge: {
    fontSize: scaleFont(28),
    lineHeight: scaleFont(28)
  },
  textExtremeLarge: {
    fontSize: scaleFont(30),
    lineHeight: scaleFont(45)
  },
  textLarge: {
    fontSize: scaleFont(20),
    lineHeight: scaleFont(30)
  },
  textExtraMedium: {
    fontSize: scaleFont(20),
    lineHeight: scaleFont(24)
  },
  textMedium: {
    fontSize: scaleFont(20),
    lineHeight: scaleFont(20)
  },
  textMedium2: {
    fontSize: scaleFont(18),
    lineHeight: scaleFont(27)
  },
  textMedium3: {
    fontSize: scaleFont(18),
    lineHeight: scaleFont(18)
  },
  textExtraRegular: {
    fontSize: scaleFont(16),
    lineHeight: scaleFont(24)
  },
  textRegular: {
    fontSize: scaleFont(16),
    lineHeight: scaleFont(20.96)
  },
  textFineRegular: {
    fontSize: scaleFont(14),
    lineHeight: scaleFont(14)
  },
  textExtraFineRegular: {
    fontSize: scaleFont(16),
    lineHeight: scaleFont(16)
  },
  textSmall: {
    fontSize: scaleFont(15),
    lineHeight: scaleFont(22.5)
  },
  textExtraSmall: {
    fontSize: scaleFont(14),
    lineHeight: scaleFont(21)
  },
  textFineSmall: {
    fontSize: scaleFont(12),
    lineHeight: scaleFont(18)
  },
  textExtremeSmall: {
    fontSize: scaleFont(10),
    lineHeight: scaleFont(10)
  },
  titleLarge: {
    fontSize: scaleFont(42),
    lineHeight: scaleFont(55)
  },
  titleSmall: {
    fontSize: scaleFont(32),
    lineHeight: scaleFont(36)
  },
  titleRegular: {
    fontSize: scaleFont(28),
    lineHeight: scaleFont(36)
  },
  labelLarge: {
    fontSize: scaleFont(16),
    lineHeight: scaleFont(20)
  },
  labelSmall: {
    fontSize: scaleFont(16),
    lineHeight: scaleFont(32)
  },
  labelRegular: {
    fontSize: scaleFont(14),
    lineHeight: scaleFont(18)
  }
});
export { Label, Title, Text };
