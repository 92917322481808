import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IMRApproval } from "@timhortons/common/src/modules/logs/dashboards/managerReview";

const getManagerReviewLogs = (data: {
  storeCode: number;
  date: string;
  timeZone: string;
  search: string;
}): IFluxStandardAction<{ storeCode: number; date: string; timeZone: string; search: string }> => {
  return {
    type: LOGS.ManagerReview.GET_MANAGER_REVIEW_LOGS.LOADING,
    payload: data
  };
};
const getManagerReviewLogsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.ManagerReview.GET_MANAGER_REVIEW_LOGS.SUCCESS,
    payload: data
  };
};
const getManagerReviewLogsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.ManagerReview.GET_MANAGER_REVIEW_LOGS.ERROR,
    payload: error
  };
};

const updateMRApproval = (data: IMRApproval): IFluxStandardAction<IMRApproval> => {
  return {
    type: LOGS.ManagerReview.UPDATE_MANAGER_REVIEW_APPROVAL.LOADING,
    payload: data
  };
};
const updateMRApprovalSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.ManagerReview.UPDATE_MANAGER_REVIEW_APPROVAL.SUCCESS,
    payload: data
  };
};
const updateMRApprovalError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.ManagerReview.UPDATE_MANAGER_REVIEW_APPROVAL.ERROR,
    payload: error
  };
};
const resetUpdateMRError = () => {
  return {
    type: LOGS.ManagerReview.RESET_MR_APPROVAL_ERROR
  };
};
export const ManagerReviewActions = {
  getManagerReviewLogs,
  getManagerReviewLogsSuccess,
  getManagerReviewLogsError,
  updateMRApproval,
  updateMRApprovalSuccess,
  updateMRApprovalError,
  resetUpdateMRError
};
