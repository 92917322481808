import { call, put, takeLatest } from "redux-saga/effects";
import { UserLmsPTSRepository } from "@timhortons/common/src/repositories/userLms/userLmsPTS";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import { UserLmsPTSActions } from "@timhortons/common/src/redux/userLms/actions/userLmsPTSActions";
import {
  LmsPTSCheckListModel,
  LmsPTSCheckListRequestDataModel,
  LmsPTSCheckListUnselectModel,
  LmsPTSDataDtoModel,
  LmsPTSGetRequestDataModel
} from "@timhortons/common/src/models/userLms/userLmsPTS";

function* getUserLmsPtsDataWorkerSaga(value: IFluxStandardAction<LmsPTSGetRequestDataModel>) {
  try {
    const { payload } = value;
    const response: LmsPTSDataDtoModel[] = yield call(
      UserLmsPTSRepository.getUserLmsPTSData,
      payload
    );
    yield put(UserLmsPTSActions.getLmsPTSDataSuccess(response));
  } catch (error) {
    yield put(UserLmsPTSActions.getLmsPTSDataError(error));
  }
}

function* ptsCheckListSelectWorkerSaga(
  value: IFluxStandardAction<LmsPTSCheckListRequestDataModel>
) {
  try {
    const { payload } = value;
    const response: LmsPTSCheckListModel = yield call(
      UserLmsPTSRepository.ptsCheckListSelect,
      payload
    );
    yield put(UserLmsPTSActions.ptsCheckListSelectSuccess(response));
  } catch (error) {
    yield put(UserLmsPTSActions.ptsCheckListSelectError(error));
  }
}

function* ptsCheckListUnSelectWorkerSaga(
  value: IFluxStandardAction<LmsPTSCheckListRequestDataModel>
) {
  try {
    const { payload } = value;
    const response: LmsPTSCheckListUnselectModel = yield call(
      UserLmsPTSRepository.ptsCheckListUnSelect,
      payload
    );
    yield put(UserLmsPTSActions.ptsCheckListUnselectSuccess(response));
  } catch (error) {
    yield put(UserLmsPTSActions.ptsCheckListUnselectError(error));
  }
}

function* userLmsPTSWatcherSaga() {
  yield takeLatest(UserLMS.userLmsPTS.GET_PTS_CHECKLIST.LOADING, getUserLmsPtsDataWorkerSaga);
  yield takeLatest(UserLMS.userLmsPTS.PTS_CHECKLIST_SELECT.LOADING, ptsCheckListSelectWorkerSaga);
  yield takeLatest(
    UserLMS.userLmsPTS.PTS_CHECLIST_UNSELECT.LOADING,
    ptsCheckListUnSelectWorkerSaga
  );
}

export default userLmsPTSWatcherSaga;
