import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import { LpSetAppealDecisionModel } from "@timhortons/common/src/models/lp/appeals";
import {
  LpAppealCountModel,
  LpAppealsCategoryDataModel,
  LpNewAppealsTable,
  SetLpAppealDecisionModel,
  PostLpAppealCommentsModel,
  LpAppealCommentsDataModel,
  LpAppealsAccordionDataModel
} from "@timhortons/common/src/models/lp/appeals";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import { LpInProcesssClosedTable } from "@timhortons/common/src/models/lp/appeals";
import { LPEndPoints, lpAppealsEndPoints } from "@timhortons/common/src/constants/endPoints";
import { validateLpAuthModel } from "@timhortons/common/src/repositories/lp/audits";
import { ILpCreateNewAppeals } from "@timhortons/common/src/modules/lp/components/dashboard";
import { LpAppealAccordianFormatter } from "@timhortons/common/src/repositories/lp/appealsFormatter";
class LpAppealRepository {
  getLpInProcessAppeals = async (param: { storeId: number; status: string }) => {
    const { storeId, status } = param;
    let data = await ApiClient.get(
      LPEndPoints.appeals.getLpAppealList(storeId, status),
      null,
      LPKeys.LP_APPEALS
    );
    let appeals: LpInProcesssClosedTable[] = [];
    data.map((item: LpInProcesssClosedTable) => {
      let deserializevar: LpInProcesssClosedTable = deserialize(LpInProcesssClosedTable, item);
      appeals.push(deserializevar);
    });
    return appeals;
  };

  createLpAppeal = async (data: ILpCreateNewAppeals) => {
    let validateStore = await validateLpAuthModel(
      data.isIndividualLogin,
      data.auditorId,
      data.storeCode,
      LPKeys.LP_APPEALS
    );
    let finalData = {
      auditorId: data.auditorId,
      auditId: data.auditId
    };
    let response;
    if (validateStore) {
      response = await ApiClient.post(
        LPEndPoints.appeals.createLpNewAppeal(),
        finalData,
        null,
        LPKeys.LP_APPEALS
      );
    }
    return { status: "success", data: response };
  };

  getLpAppealsCount = async (param: { appealerId: string; isIndividual: boolean }) => {
    const { appealerId, isIndividual } = param;
    let response = await ApiClient.get(
      isIndividual
        ? LPEndPoints.appeals.getLpAppealsCountIndividual(appealerId)
        : LPEndPoints.appeals.getLpAppealsCountStore(appealerId),
      null,
      LPKeys.LP_APPEALS
    );
    return deserialize(LpAppealCountModel, response);
  };

  setLpAppealDecision = async (param: LpSetAppealDecisionModel) => {
    let headers = {
      ...LPKeys.LP_APPEALS,
      "Content-Type": "multipart/form-data"
    };
    return ApiClient.post(lpAppealsEndPoints.setLpAppealDecision(), param, null, headers);
  };

  getLpNewAppeals = async (param: { storeId: number; status: string }) => {
    const { storeId, status } = param;
    let data = await ApiClient.get(
      LPEndPoints.appeals.getLpAppealList(storeId, status),
      null,
      LPKeys.LP_APPEALS
    );
    let appeals: LpNewAppealsTable[] = [];
    data.map((item: LpNewAppealsTable) => {
      let deserializevar: LpNewAppealsTable = deserialize(LpNewAppealsTable, item);
      appeals.push(deserializevar);
    });
    return appeals;
  };

  getLpAppeals = async (param: { userId: number; selectedValues: any }) => {
    const { userId } = param;
    let fromDate, toDate;
    if (param.selectedValues.fromDate !== null && param.selectedValues.toDate !== null) {
      fromDate = convert(param.selectedValues.fromDate);
      toDate = convert(param.selectedValues.toDate);
    }
    let search = param.selectedValues.search === null ? null : param.selectedValues.search;
    let object: any = {
      appealStatus: param.selectedValues.appealStatus
    };
    let data;
    if (fromDate !== null) {
      object = {
        ...object,
        fromDate: fromDate,
        toDate: toDate
      };
    }
    if (param.selectedValues.search !== null) {
      object = {
        ...object,
        searchByKeyword: search
      };
    }
    if (param.selectedValues.appealStatus === "CLOSED" && param.selectedValues.status !== null) {
      object = {
        ...object,
        appealItemStatus: param.selectedValues.status
      };
    }
    data = await ApiClient.post(
      lpAppealsEndPoints.fetchLpAppealFilteredAPI(userId),
      {
        ...object
      },
      null,
      LPKeys.LP_APPEALS
    );
    return data;
  };

  getLpAppealsCategoryData = async (param: { appealId: number }) => {
    const { appealId } = param;
    let data = await ApiClient.get(
      LPEndPoints.appeals.getLpAppealsCategoryData(appealId),
      null,
      LPKeys.LP_APPEALS
    );
    return deserialize(LpAppealsCategoryDataModel, data);
  };
  postAppealsCommentData = async (param: {
    body: PostLpAppealCommentsModel;
    employeeId: string;
  }) => {
    let headers = {
      ...LPKeys.LP_APPEALS,
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      LPEndPoints.appeals.postLpAppealItem(param.employeeId),
      param.body,
      null,
      headers
    );
    return deserialize(LpAppealCommentsDataModel, data);
  };

  getLpAppealAccordionData = async (param: { appealId: number; categoryId: number }) => {
    let response = await ApiClient.get(
      LPEndPoints.appeals.getLpAppealAccordionData(param.appealId, param.categoryId),
      null,
      LPKeys.LP_APPEALS
    );
    return LpAppealAccordianFormatter(response);
  };
}
const lpAppealRepository = new LpAppealRepository();
export { lpAppealRepository as LpAppealRepository };
