import React, { useRef, useImperativeHandle, useState, useEffect } from "react";
import { FlatList as RNFlatList } from "react-native";
type keyable = { [key: string]: any };

interface IProps {
  listData: Array<keyable>;
  listRenderer: (item: keyable, index?: number) => JSX.Element;
  parentRef: any;
  horizontalToggler?: boolean;
  seperator?: () => JSX.Element;
  showsVerticalScrollIndicator?: boolean;
  showsHorizontalScrollIndicator?: boolean;
  handleIndexCallBack?: Function;
  pageSize?: number;
  nextIndexParent?: number;
  extractorHandler?: (listData: keyable, index?: number) => string;
}

export default function Carousel(props: IProps) {
  const {
    listData,
    listRenderer,
    parentRef = useRef(null),
    horizontalToggler = false,
    seperator,
    showsVerticalScrollIndicator = false,
    showsHorizontalScrollIndicator = false,
    handleIndexCallBack,
    pageSize = 3,
    extractorHandler,
    nextIndexParent = 2
  } = props;
  const [index, setIndex] = useState(0);

  const renderItem = ({ item, index }: keyable): JSX.Element => {
    return listRenderer(item, index);
  };

  const ref = useRef(null);

  useEffect(() => {
    if (listData) {
      let nextIndex = Math.floor(listData?.length - 1);
      handleIndexCallBack &&
        handleIndexCallBack({
          prev: index === 0 ? true : false,
          next: index < nextIndex - nextIndexParent ? false : true
        });
    }
  }, [index, listData]);

  useImperativeHandle(parentRef, () => ({
    scrollPrevious() {
      let i = index;
      if (index === 0) {
        ref.current?.scrollToIndex({ index: i });
      } else {
        i = index - 1;
        ref.current?.scrollToIndex({ index: i });
      }
      setIndex(i);
    },
    scrollNext() {
      let i = index;
      if (index === listData.length - 1) {
        ref.current?.scrollToIndex({ index: i });
      } else {
        i = index + 1;
        ref.current?.scrollToIndex({ index: i });
      }
      setIndex(i);
    }
  }));

  return (
    <RNFlatList
      ref={ref}
      data={listData}
      renderItem={renderItem}
      horizontal={horizontalToggler}
      ItemSeparatorComponent={seperator}
      keyExtractor={(item: keyable, index: number) =>
        extractorHandler && extractorHandler(item, index)
      }
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
    />
  );
}
