export const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

export const PromiseTimeout = (ms: number, promise: Promise<any>) => {
  let timeout = new Promise<void>((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject("TimedOut");
      resolve();
    }, ms);
  });
  return Promise.race([promise, timeout]);
};
