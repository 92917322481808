import React, { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { Animated, LayoutChangeEvent, StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Checkbox, CheckboxType } from "@timhortons/common/src/components/atoms/checkBox";
import { setFrameMeasures } from "@timhortons/common/src/utils/measureLayout";
import OverflowModal from "@timhortons/common/src/components/atoms/overflowModal";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import FlatList from "@timhortons/common/src/components/atoms/list";
import Loader from "../loader";
import TextField from "@timhortons/common/src/components/atoms/textField";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
export interface IItem {
  label: string;
  value: string;
  isChecked?: boolean;
  childElements?: Array<{ label: string; value: string }>;
  countryCode?: number;
  code?: number | string;
}
export interface IAllItem {
  label: string;
  value: string;
  isChecked: boolean;
  id: string;
  selectAll: boolean;
  parentIndex?: string;
}
interface IDispItem extends IAllItem {
  childElements?: IAllItem[];
  isPreSelected?: boolean;
}
interface Props {
  allItems: Array<IItem>;
  title: string;
  isSearchRequired?: boolean;
  handleSelectedValues?: Function;
  onEndReached?: () => void;
  selectedValues?: string[];
  handleApiCall?: Function;
  loading?: boolean;
  resetData?: boolean;
  setResetMultiselect?: Function;
  emptyMsg?: string;
  // onEndReachLoading?: boolean;
  isDisablePrevSelected?: boolean;
  setSearchText?: Function;
  setSelectAll?: Function;
  selectAll?: boolean;
  enableChildSelectAll?: boolean;
  enableSelectAllInitialize?: boolean;
  resetSearchText?: boolean;
  isBuilder?: boolean;
  setReqSearch?: Function;
  reqSearch?: string;
  countryFilterReset?: boolean;
}
const compareDays = (selectedValues: string[], value: String) => {
  for (let i = 0; i < selectedValues.length; i++) {
    if (selectedValues[i].toUpperCase() === value) {
      return true;
    }
  }
  return false;
};
const generateItem = (
  rawItems: Array<IItem>,
  selectedValues: string[],
  searchText?: string
): Array<IDispItem> => {
  return rawItems?.length > 0
    ? rawItems.map((el: IItem, index: number) => {
        var childArray: Array<IAllItem> = [];
        if (el.childElements && el.childElements.length > 0) {
          childArray = el.childElements.map((item: IItem, i: number) => {
            return {
              ...item,
              isChecked: compareDays(selectedValues, el.value.toUpperCase()),
              id: `${i}-${item.value}`,
              parentIndex: `${index}-${el.value}`,
              selectAll: false,
              search: searchText
            };
          });
        }
        return {
          ...el,
          isChecked: compareDays(selectedValues, el.value?.toUpperCase()),
          id: `${index}-${el.value}`,
          childElements: childArray,
          selectAll: false,
          search: searchText
        };
      })
    : [];
};

export default function MultiSelect({
  allItems,
  title,
  isSearchRequired = true,
  handleSelectedValues,
  onEndReached,
  selectedValues = [],
  handleApiCall,
  loading = false,
  resetData = false,
  setResetMultiselect,
  emptyMsg = "Nothing to select",
  isDisablePrevSelected = false,
  setSearchText,
  setSelectAll,
  selectAll,
  enableChildSelectAll = false,
  enableSelectAllInitialize = false,
  resetSearchText = false,
  isBuilder = false,
  setReqSearch,
  reqSearch,
  countryFilterReset = false
}: // onEndReachLoading = false,
Props): ReactElement {
  const [selectedItems, setSelectedItems] = useState<Array<IDispItem>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);
  const [dispItems, setDispItems] = useState<Array<IDispItem>>([]);
  const [checkboxItems, setCheckboxItems] = useState({ selectedItems: [], hiddenItems: [] });
  const [formattedAllItems, setformattedAllItems] = useState<Array<IDispItem>>([]);
  const [buttonFrame, setButtonFrame] = useState(null);
  const [searchString, setSearchString] = useState<string>("");
  const triggerRef = useRef(null);
  const onDropdownOpen = (): void => {
    if (open && title !== "Select products") {
      setDispItems(formattedAllItems);
    }
    setOpen(!open);
  };
  useEffect(() => {
    if (title === "Select products") {
      setSearchString("");
    }
  }, [allItems]);
  useEffect(() => {
    if (dispItems?.length > 0) {
      if (setSelectAll) {
        setSelectAll(dispItems[0]?.selectAll);
      }
    }
  }, [dispItems[0]?.selectAll]);
  useEffect(() => {
    let generatedItems = generateItem(allItems, selectedValues, searchString);
    setDispItems(generatedItems);
    setformattedAllItems(generatedItems);
    // THIS IS ADDED ON 19/8/21 TO SHOW PRESELECTED VALUES
    let finalSelectedItems = generatedItems.filter((el) => el.isChecked === true);
    setCheckboxItems((prevState) => {
      return { ...prevState, selectedItems: finalSelectedItems };
    });
    setSelectedItems(finalSelectedItems);
  }, [allItems]);

  useEffect(() => {
    if (resetData) {
      selectedItems?.map((i) => removeSelectedItem(i.id));
      setCheckboxItems({
        hiddenItems: [],
        selectedItems: []
      });
      setResetMultiselect(false);
    }
  }, [resetData]);
  useEffect(() => {
    if (countryFilterReset) {
      setSelectedItems([]);
      setSearchString("");
      setCheckboxItems({
        hiddenItems: [],
        selectedItems: []
      });
    }
  }, [countryFilterReset]);
  const showModal = (): void => {
    setFrameMeasures(
      () => {
        setOpen(true);
      },
      triggerRef,
      setButtonFrame
    );
  };

  const handleLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setDropdownWidth(width);
  };

  let count = 0;
  dispItems.forEach((item) => {
    if (item.childElements?.length) {
      count = count + 1;
    }
  });
  let weight = count > 0 ? FontWeight.SemiBold : FontWeight.Regular;

  const ref = useRef(null);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: open ? 1 : 0,
      duration: 500,
      useNativeDriver: true
    }).start();

    if (open) handleApiCall && handleApiCall();
  }, [open]);

  const unselectChildSelectAll = (dump: Array<IDispItem>): Array<IDispItem> => {
    if (!enableChildSelectAll) {
      dump.forEach((item) => {
        item.childElements?.map((el) => {
          el.selectAll = false;
        });
      });
    }
    return dump;
  };

  const checkForAllSelect = (dump: Array<IDispItem>): Array<IDispItem> => {
    if (enableChildSelectAll) {
      dump.forEach((item: IDispItem) => {
        if (item?.childElements && item.childElements.length > 0) {
          if (item.childElements.every((el) => el.isChecked === true)) {
            item.isChecked = true;
          } else {
            item.isChecked = false;
          }
        }
      });
    }
    let val = false;
    if (dump.every((item) => item.isChecked === true)) {
      val = true;
      // dump[0].selectAll = true;
    } else {
      val = false;
      // dump[0].selectAll = false;
    }
    dump?.length > 0 &&
      dump.forEach((item: IDispItem) => {
        item.selectAll = val;
      });
    return dump;
  };

  const selectItem = (id: string) => {
    let itemIndexDisp = dispItems.findIndex((el) => el.id === id);
    let itemIndexAll = formattedAllItems.findIndex((el) => el.id === id);
    if (itemIndexDisp > -1) {
      let dispDump = [...dispItems];
      let allDump = [...formattedAllItems];
      let selectedDump = [...selectedItems];
      dispDump[itemIndexDisp].isChecked = !dispDump[itemIndexDisp].isChecked;
      allDump[itemIndexAll].isChecked = dispDump[itemIndexDisp].isChecked;
      if (dispDump[itemIndexDisp].isChecked) {
        selectedDump.unshift(dispDump[itemIndexDisp]);
      } else {
        let selectedIndex = selectedDump.findIndex((el) => el.id === id);
        selectedDump.splice(selectedIndex, 1);
      }
      dispDump = checkForAllSelect(dispDump);
      // dispDump.forEach((item) => (item.selectAll = false)); // Not sure  to be checked and tested
      // allDump.forEach((item) => (item.selectAll = false)); // Not sure to be checked and tested
      setDispItems(dispDump);
      setSelectedItems(selectedDump);
      setCheckboxItems((prevState) => ({
        ...prevState,
        selectedItems: selectedDump
      }));
      setformattedAllItems(allDump);
    }
  };

  const selectChildItem = (id: string, itemId: string) => {
    let itemIndexDisp = dispItems.findIndex((el) => el.id === id);
    let itemIndexAll = formattedAllItems.findIndex((el) => el.id === id);

    if (itemIndexDisp > -1) {
      let childItemIndex = dispItems[itemIndexDisp].childElements.findIndex(
        (el) => el.id === itemId
      );

      if (childItemIndex > -1) {
        let dispDump = [...dispItems];
        let allDump = [...formattedAllItems];
        let selectedDump = [...selectedItems];

        dispDump[itemIndexDisp].childElements[childItemIndex].isChecked =
          !dispDump[itemIndexDisp].childElements[childItemIndex].isChecked;
        allDump[itemIndexAll].childElements[childItemIndex].isChecked =
          dispDump[itemIndexDisp].childElements[childItemIndex].isChecked;

        if (dispDump[itemIndexDisp].childElements[childItemIndex].isChecked) {
          selectedDump.unshift(dispDump[itemIndexDisp].childElements[childItemIndex]);
        } else {
          let selectedIndex = selectedDump.findIndex((el) => el.id === itemId);
          selectedDump.splice(selectedIndex, 1);
        }
        if (
          dispDump[itemIndexDisp]?.childElements &&
          dispDump[itemIndexDisp].childElements.every((item) => item.isChecked === true)
        ) {
          dispDump[itemIndexDisp].childElements[0].selectAll = true;
          dispDump[itemIndexDisp].selectAll = true;
          dispDump[itemIndexDisp].isChecked = true;
          if (enableChildSelectAll) {
            selectedDump.push(dispDump[itemIndexDisp]);
          }
        } else {
          dispDump[itemIndexDisp].childElements[0].selectAll = false;
          dispDump[itemIndexDisp].selectAll = false;
          dispDump[itemIndexDisp].isChecked = false;
          if (enableChildSelectAll) {
            let selectedIndex = selectedDump.findIndex(
              (el) => el.id === dispDump[itemIndexDisp].id
            );
            if (selectedIndex > -1) {
              selectedDump.splice(selectedIndex, 1);
            }
          }
        }
        dispDump = checkForAllSelect(dispDump);
        setDispItems(unselectChildSelectAll(dispDump));
        setSelectedItems(unselectChildSelectAll(selectedDump));
        setCheckboxItems((prevState) => ({
          ...prevState,
          selectedItems: unselectChildSelectAll(selectedDump)
        }));
        setformattedAllItems(unselectChildSelectAll(allDump));
      }
    }
  };

  const removeSelectedItem = (id: string) => {
    // this entire thing can be moved to a common function and used both by this fn and selectItems fn
    const selectedIndex = selectedItems.findIndex((el) => el.id === id);
    const formattedIndex = formattedAllItems.findIndex((el) => el.id === id);
    const dispIndex = dispItems.findIndex((el) => el.id === id);
    let selectDump = [...selectedItems];
    let formattedDump = [...formattedAllItems];
    let dispDump = [...dispItems];

    if (selectedIndex > -1) {
      selectDump.splice(selectedIndex, 1);
      setCheckboxItems({
        hiddenItems: [],
        selectedItems: selectDump
      });
      setSelectedItems(selectDump);
    }
    if (formattedIndex > -1) {
      formattedDump.forEach((item) => (item.selectAll = false));
      formattedDump[formattedIndex].isChecked = false;
      setformattedAllItems(formattedDump);
    }
    if (dispIndex > -1) {
      dispDump.forEach((item) => (item.selectAll = false));
      dispDump[dispIndex].isChecked = false;
      setDispItems(dispDump);
    }
    if (selectedItems[selectedIndex].parentIndex) {
      const formatIndex = formattedAllItems.findIndex(
        (el) => el.id === selectedItems[selectedIndex].parentIndex
      );
      const dispdumpIndex = dispItems.findIndex(
        (el) => el.id === selectedItems[selectedIndex].parentIndex
      );
      const formattedChildIndex = formattedAllItems[formatIndex].childElements.findIndex(
        (el) => el.id === id
      );

      const dispChildIndex = dispItems[formatIndex].childElements.findIndex((el) => el.id === id);

      formattedDump[formatIndex].childElements[formattedChildIndex].isChecked = false;
      setformattedAllItems(unselectChildSelectAll(formattedDump));

      dispDump[dispdumpIndex].childElements[dispChildIndex].isChecked = false;
      setDispItems(unselectChildSelectAll(dispDump));
    }
  };
  const handleSearch = (keyString: string) => {
    if (setSearchText) {
      if (keyString.length >= 3) {
        setSearchText(keyString);
      } else {
        setSearchText("");
      }
      setReqSearch && setReqSearch(keyString);
      setSearchString(keyString);
    } else {
      setSearchString(keyString);
      if (keyString.length > 0) {
        let results = [...formattedAllItems].filter((obj) => {
          let lowerCaseKeyString = keyString.toLocaleLowerCase().trim();
          let childItemLabels: Array<any> = [];
          obj?.childElements?.length > 0 &&
            obj.childElements.map((el) => {
              el?.label && childItemLabels.push(el.label.toLocaleLowerCase());
            });
          childItemLabels.push(obj.label.toLocaleLowerCase());
          if (
            childItemLabels.some((str) =>
              str?.toLocaleLowerCase().trim().includes(lowerCaseKeyString)
            )
          )
            return true;
          return false;
        });

        setDispItems(results);
      } else {
        setDispItems(formattedAllItems);
      }
    }
  };

  useEffect(() => {
    handleSelectedValues && handleSelectedValues([...new Set(selectedItems)]);
  }, [selectedItems]);
  useEffect(() => {
    if (
      dispItems &&
      dispItems[0] &&
      !dispItems[0].selectAll &&
      selectAll &&
      enableSelectAllInitialize
    ) {
      // handleSelectAll(dispItems);
    }
  }, [enableSelectAllInitialize, selectAll, dispItems[0]?.selectAll]);

  // const getHiddenElements = async (selectedItemsArray: Array<IDispItem>) => {
  //   let visibleItems = [];
  //   let hiddenItems = [];
  //   for (let val of selectedItemsArray) {
  //     let pz = `#${val.value}`;
  //     let element = document.querySelector(pz) as HTMLElement;
  //     let perc = await elementVisibleInPercent(document.querySelector(`#${val.value}`));
  //     if (perc === 100) {
  //       visibleItems.push(val);
  //     } else {
  //       hiddenItems.push(val);
  //     }
  //   }
  //   setCheckboxItems({
  //     selectedItems: [...new Set(visibleItems)],
  //     hiddenItems: [...new Set(hiddenItems)]
  //   });
  // };

  const changeItemStatus = (items: IDispItem[], status: boolean) => {
    let dispDump: Array<IDispItem> = [];

    items.forEach((item) => {
      item.isChecked = status;
      item.selectAll = status;
      dispDump.push(item);
      item.childElements?.map((el) => {
        el.isChecked = status;
        el.selectAll = status;
        dispDump.push(el);
      });
    });
    return dispDump;
  };
  const handleSelectAll = (items: Array<IDispItem>) => {
    if (setSelectAll) {
      setSelectAll(!items[0].selectAll);
    }
    let selectDump = [...selectedItems];
    if (!items[0].selectAll) {
      // THIS IS Commented ON 19/8/21 to avoid duplicate entry in case of preselected values
      // let dump = selectDump.concat(changeItemStatus(items, true));
      let dump = changeItemStatus(items, true);
      // Added this difference to finalDump, as it was removing the previous selected items
      // A+B = B+C; venn diagram difference is referring to A set of Values
      let difference = selectDump.filter((el) => !dump.includes(el));
      let finalDump = [...dump, ...difference];
      setCheckboxItems((prevState) => ({
        ...prevState,
        selectedItems: finalDump
      }));
      setSelectedItems(finalDump);
    } else {
      let dump = selectDump.filter((el) => !changeItemStatus(items, false).includes(el));
      setCheckboxItems({
        hiddenItems: [],
        selectedItems: dump
      });
      setSelectedItems(dump);
    }
  };
  const handleChildSelectAll = (itemId: string) => {
    let itemIndexDisp = dispItems.findIndex((el) => el.id === itemId);
    let itemIndexAll = formattedAllItems.findIndex((el) => el.id === itemId);

    if (itemIndexDisp > -1) {
      let dispDump = [...dispItems];
      let allDump = [...formattedAllItems];
      let selectedDump = [...selectedItems];

      dispDump[itemIndexDisp].isChecked = !dispDump[itemIndexDisp].isChecked;
      allDump[itemIndexAll].isChecked = dispDump[itemIndexDisp].isChecked;

      dispDump[itemIndexDisp].childElements[0].selectAll =
        !dispDump[itemIndexDisp].childElements[0].selectAll;
      allDump[itemIndexAll].childElements[0].selectAll =
        dispDump[itemIndexDisp].childElements[0].selectAll;

      if (setSelectAll) {
        setSelectAll(dispDump[itemIndexDisp].childElements[0].selectAll);
      }

      const mainCategoryCheck = dispDump[itemIndexDisp].isChecked;

      if (mainCategoryCheck) {
        selectedDump.unshift(dispDump[itemIndexDisp]);
      } else {
        let selectedIndex = selectedDump.findIndex((el) => el.id === itemId);
        selectedDump.splice(selectedIndex, 1);
      }

      if (dispDump[itemIndexDisp]?.childElements?.length > 0) {
        allDump[itemIndexDisp].childElements.map(
          (childItem: IAllItem) => (childItem.isChecked = mainCategoryCheck)
        );
        dispDump[itemIndexDisp].childElements.map((childItem: IAllItem, childIndex: number) => {
          childItem.isChecked = mainCategoryCheck;
          let targetItemIndex: number = selectedDump.findIndex((el) => el.id === childItem.id);
          if (targetItemIndex > -1) {
            !mainCategoryCheck && selectedDump.splice(targetItemIndex, 1);
          } else {
            mainCategoryCheck &&
              selectedDump.unshift(dispDump[itemIndexDisp].childElements[childIndex]);
          }
        });
      }

      dispDump = checkForAllSelect(dispDump);
      setDispItems(unselectChildSelectAll(dispDump));
      setSelectedItems(unselectChildSelectAll(selectedDump));
      setCheckboxItems((prevState) => ({
        ...prevState,
        selectedItems: unselectChildSelectAll(selectedDump)
      }));
      setformattedAllItems(unselectChildSelectAll(allDump));
    }
  };
  const showCheckbox = (
    el: IDispItem,
    handleOnPress: Function,
    textWeight: FontWeight
  ): JSX.Element => {
    return (
      <Checkbox
        key={el.id}
        onPress={() => {
          if (!(isDisablePrevSelected && el?.isPreSelected)) {
            handleOnPress();
          }
        }}
        containerStyle={styles.checkBoxContainer}
        iconStyle={styles.checkBoxIcon}
        selected={el.isChecked}
        text={el.label}
        testId={el.id}
        textSize={TextSize.ExtraRegular}
        type={CheckboxType.Square}
        textFontWeight={textWeight}
        iconSize={14}
        textStyle={styles.checkboxText}
      />
    );
  };

  return (
    <View style={styles.masterContainer}>
      <OverflowModal
        buttonFrame={buttonFrame}
        modalVisible={open}
        setModalState={onDropdownOpen}
        spaceRequired={false}
        triggerComponent={
          <TouchableOpacity
            style={styles.selectBar}
            ref={triggerRef}
            onPress={showModal}
            onLayout={handleLayout}
          >
            <View style={styles.selectedItemsWrapper} ref={ref}>
              {checkboxItems.selectedItems.length > 0 ? (
                <>
                  {checkboxItems.selectedItems.map((el, index) => {
                    return (
                      // <div style={selectedItemsStyles} key={el.id} id={el.value}>
                      <View key={index} style={styles.selectedItemsStyles}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.FineRegular}
                          textStyle={styles.primaryText}
                          testId={`txt-${el.id}`}
                        >
                          {el.label}
                        </Text>
                        {!(isDisablePrevSelected && el.isPreSelected) && (
                          <Icon
                            onPress={() => {
                              removeSelectedItem(el.id);
                            }}
                            color={colorPallete.grey3}
                            name={IconNames.close}
                            size={12}
                            customStyle={styles.primaryText}
                          />
                        )}
                      </View>
                      // </div>
                    );
                  })}
                  {checkboxItems.hiddenItems.length > 0 && (
                    <View style={styles.ellipsisContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.ExtraFineRegular}
                        textStyle={styles.ellipsisText}
                        testId="ellipsis"
                      >
                        ...
                      </Text>
                    </View>
                  )}
                </>
              ) : (
                <Text
                  fontWeight={FontWeight.Light}
                  textSize={TextSize.Regular}
                  testId="txt-title"
                  textStyle={styles.defaultText}
                >
                  {title}
                </Text>
              )}
            </View>

            <View style={styles.iconContainer}>
              {checkboxItems.hiddenItems.length > 0 && (
                <View style={styles.countContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.FineRegular}
                    textStyle={styles.countText}
                    testId="ellipsis"
                  >
                    + {checkboxItems.hiddenItems.length}
                  </Text>
                </View>
              )}
              <Icon
                // onPress={() => setOpen(!open)}
                onPress={showModal}
                color={colorPallete.grey3}
                name={IconNames.filledDown}
                size={14}
                customStyle={open && styles.iconRotation}
              />
            </View>
          </TouchableOpacity>
        }
      >
        <Animated.View
          style={[
            styles.animateContainer,
            {
              opacity: fadeAnim,
              width: dropdownWidth
            }
          ]}
        >
          {isSearchRequired && (
            <View style={styles.searchWrapper}>
              <Icon name={IconNames.search} customStyle={styles.iconStyle} />
              <TextField
                value={isBuilder && resetSearchText ? reqSearch : searchString}
                inputStyle={styles.searchBar}
                handleChange={(e: string) => {
                  handleSearch(e);
                }}
                name={`multin${new Date()}`}
                id={`multid${new Date()}`}
                placeholder="Enter Text"
              />
            </View>
            // <SearchField
            //   fieldStyle={styles.fieldStyle}
            //   textFieldDimensions={styles.searchBar}
            //   searchFunction={(e: string) => {

            //     handleSearch(e.trim());
            //   }}
            //   value={searchString}
            // />
          )}
          {loading ? (
            <View style={styles.loadingContainer}>
              <Loader type="component" />
            </View>
          ) : dispItems?.length > 0 ? (
            <>
              <View style={styles.checkBoxesWrapper}>
                {showCheckbox(
                  {
                    label: "Select All",
                    value: "Select all",
                    isChecked: dispItems[0]?.selectAll,
                    id: "select all",
                    selectAll: true
                  },
                  () => handleSelectAll(dispItems),
                  weight
                )}
                <FlatList
                  extractor={(item) => item.id}
                  listContainerStyle={{ height: 200 }}
                  listData={dispItems}
                  extraData={dispItems}
                  onEndReached={onEndReached}
                  // listFooterComponent={
                  //   onEndReachLoading && (
                  //     <View style={styles.loadingContainer}>
                  //       <Loader type="component" />
                  //     </View>
                  //   )
                  // }
                  listRenderer={(el: IDispItem) => (
                    <Fragment>
                      {showCheckbox(
                        el?.childElements.length > 0 && enableChildSelectAll
                          ? {
                              ...el,
                              isChecked: el.childElements[0].selectAll,
                              id: `${el.id}-SelectAll`,
                              selectAll: true
                            }
                          : el,
                        el?.childElements.length > 0 && enableChildSelectAll
                          ? () => {
                              handleChildSelectAll(el.id);
                            }
                          : () => selectItem(el.id),
                        weight
                      )}
                      {el.childElements.length > 0 && (
                        <View style={styles.childElementsContainer}>
                          {showCheckbox(
                            {
                              label: "Select All",
                              value: "Select all",
                              isChecked: el.childElements[0].selectAll,
                              id: `${el.id}-SelectAll`,
                              selectAll: true
                            },
                            () => handleChildSelectAll(el.id),
                            FontWeight.Regular
                          )}

                          <FlatList
                            extractor={(item) => item.id.toString()}
                            listData={el.childElements}
                            extraData={el.childElements[0].selectAll}
                            listRenderer={(item: IDispItem) =>
                              showCheckbox(
                                item,
                                () => selectChildItem(el.id, item.id),
                                FontWeight.Regular
                              )
                            }
                          />
                        </View>
                      )}
                    </Fragment>
                  )}
                />
              </View>
            </>
          ) : (
            <View style={styles.errorContainer}>
              <Text
                fontWeight={FontWeight.Regular}
                textSize={TextSize.FineRegular}
                testId="ellipsis"
              >
                {emptyMsg}
              </Text>
            </View>
          )}
        </Animated.View>
      </OverflowModal>
    </View>
  );
}
const styles = StyleSheet.create({
  masterContainer: {
    flex: 1,
    flexDirection: "column"
  },
  selectBar: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.grey6,
    borderTopEndRadius: 4,
    borderTopLeftRadius: 4,
    paddingVertical: PlatformUtils.isWeb() ? 12 : 0,
    paddingHorizontal: 16,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 46,
    alignItems: "center",
    backgroundColor: colorPallete.white3
  },
  selectedItemsWrapper: {
    flex: 4,
    justifyContent: "flex-start",
    flexDirection: "row",
    overflow: "hidden"
  },
  iconContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  defaultText: {
    color: colorPallete.grey3
  },
  checkboxText: {
    color: colorPallete.black3
  },
  primaryText: {
    color: colorPallete.grey1,
    marginHorizontal: 6
  },
  ellipsisText: {
    color: colorPallete.grey1
  },
  ellipsisContainer: {
    justifyContent: "center",
    alignItems: "center",
    // paddingInlineEnd: 4,
    ...I18nService.select({
      rtl: { paddingStart: 4 },
      ltr: { paddingEnd: 4 }
    })
  },
  countContainer: {
    backgroundColor: colorPallete.grey1,
    borderRadius: 4,
    padding: 4,
    ...I18nService.select({
      rtl: { marginEnd: 12 },
      ltr: { marginEnd: 12 }
    })
    // marginEnd: 12
  },
  countText: {
    color: colorPallete.white3
  },
  iconRotation: {
    transform: [{ rotate: "180deg" }]
  },
  animateContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.grey6,
    borderBottomEndRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: colorPallete.white3
  },
  searchWrapper: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    paddingVertical: PlatformUtils.isWeb() ? 10 : 0,
    ...I18nService.select({
      rtl: { paddingStart: 12 },
      ltr: { paddingStart: 12 }
    }),
    backgroundColor: colorPallete.white3,
    borderBottomWidth: 1,
    borderBottomColor: colorPallete.grey7
  },
  iconStyle: {
    color: colorPallete.grey7,
    ...I18nService.select({
      rtl: { marginEnd: 12 },
      ltr: { marginEnd: 12 }
    })
  },
  searchBar: {
    borderWidth: 0,
    width: "80%"
  },
  checkBoxesWrapper: {
    padding: 16,
    maxHeight: 490
  },
  checkBoxContainer: {
    marginBottom: 14
  },
  checkBoxIcon: {
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
    // marginEnd: 10
  },
  childElementsContainer: {
    flex: 1,
    // marginStart: 16,
    ...I18nService.select({
      rtl: { marginStart: 16 },
      ltr: { marginStart: 16 }
    })
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 30
  },
  selectedItemsStyles: {
    flexDirection: "row",
    backgroundColor: colorPallete.variant15,
    padding: 5,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    ...I18nService.select({
      rtl: { marginEnd: 12 },
      ltr: { marginEnd: 12 }
    }),
    // marginEnd: 12,
    flexShrink: 0
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});

const selectedItemsStyles = {
  backgroundColor: colorPallete.variant8,
  padding: 5,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  ...I18nService.select({
    rtl: { marginEnd: 12 },
    ltr: { marginEnd: 12 }
  }),
  // marginEnd: 12,
  flexShrink: 0
};
