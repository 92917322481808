import {
  LMSGetModuleByIdModel,
  LMSQuizMetaDataModel,
  PostLMSMCQTicTacQuiz,
  LMSMtfDndQuizModel,
  LMSOptionDefinitionModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import {
  IMcqData,
  IMcqOption
} from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMCQ";
import { quizType } from "@timhortons/common/src/constants/quizEnums";
import { IMtf } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMatchTheFollowing";
import { initialTicTacToeOptionFuncn } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/initialsConstArrays";

export const lmsModuleByIdFormatter = async (responseData: any): Promise<LMSGetModuleByIdModel> => {
  let newObjectData: any = responseData;
  // countryDataFormatter
  for (let i = 0; i < newObjectData.country.length; i++) {
    let countryObj: { value: string; label: string; countryCode: number; isPreSelected: boolean } =
      {
        value: "",
        label: "",
        countryCode: null,
        isPreSelected: false
      };
    countryObj.value = responseData.country[i].countryName;
    countryObj.label = responseData.country[i].countryName;
    countryObj.countryCode = responseData.country[i].countryCode;
    countryObj.isPreSelected = responseData.country[i].isSelected;
    newObjectData.country.splice(i, 1, countryObj);
  }
  // channelDataFormatter
  for (let i = 0; i < newObjectData.channel.length; i++) {
    let channelObj: {
      id: string;
      value: string;
      label: string;
      code: number;
      isPreSelected: boolean;
    } = {
      id: null,
      value: "",
      label: "",
      code: null,
      isPreSelected: false
    };
    channelObj.id = `${i}`;
    channelObj.value = responseData.channel[i].channelName;
    channelObj.label = responseData.channel[i].channelName;
    channelObj.code = responseData.channel[i].channelCode;
    channelObj.isPreSelected = responseData.channel[i].isSelected;
    newObjectData.channel.splice(i, 1, channelObj);
  }
  // RoleDataFormatter
  for (let i = 0; i < newObjectData.role.length; i++) {
    let roleObj: {
      id: string;
      name: string;
      label: string;
      isPreSelected: boolean;
    } = {
      id: null,
      name: "",
      label: "",
      isPreSelected: false
    };
    roleObj.id = responseData.role[i].roleId;
    roleObj.name = responseData.role[i].roleName;
    roleObj.label = responseData.role[i].roleName;
    roleObj.isPreSelected = responseData.role[i].isSelected;
    newObjectData.role.splice(i, 1, roleObj);
  }
  //   StoreDataFormatter;
  for (let i = 0; i < newObjectData.store.length; i++) {
    let roleObj: {
      id: string;
      storeName: string;
      storeCode: string;
      isPreSelected: boolean;
    } = {
      id: null,
      storeName: "",
      storeCode: "",
      isPreSelected: false
    };
    roleObj.id = `${i}`;
    roleObj.storeName = responseData.store[i].storeName;
    roleObj.storeCode = responseData.store[i].storeCode;
    roleObj.isPreSelected = responseData.store[i].isSelected;
    newObjectData.store.splice(i, 1, roleObj);
  }
  return newObjectData;
};

export const postMcqTicTacFormatter = (
  metaData: LMSQuizMetaDataModel,
  quizData: IMcqData[]
): PostLMSMCQTicTacQuiz => {
  let isMcq = metaData.quizType === quizType.mcq;
  let apiObjData: any = { questions: [] };

  apiObjData.id = metaData.id;
  apiObjData.videoType = metaData.videoType;
  apiObjData.quizType = metaData.quizType;
  apiObjData.isAssessment = metaData.isAssessment;
  apiObjData.questionNumber = metaData.questionNumber;

  quizData.map((item: IMcqData, index: number) => {
    let tempQuesObj: any = { options: [] };
    tempQuesObj.image = item.imageKey && isMcq ? item.imageKey : null;
    // tempQuesObj.image = null;

    tempQuesObj.question = item.question;
    tempQuesObj.cell = isMcq ? 0 : item?.cell;
    let tempOptionArr: any = [];
    let isOptionEmpty = false;
    item.options.map((optionItem: IMcqOption) => {
      let tempOptionObj: any = {};
      tempOptionObj.option = optionItem.optionVal;
      tempOptionObj.isCorrect = optionItem.response;
      tempOptionArr.push(tempOptionObj);
      if (tempOptionObj.option === "" || tempOptionObj.option === null) {
        isOptionEmpty = true;
      }
    });
    tempQuesObj.options = [...tempOptionArr];
    if (!(metaData.isAssessment && metaData.quizType === quizType.mcq && index > 0)) {
      if (tempQuesObj.question !== "" && !isOptionEmpty) {
        apiObjData.questions.push({ ...tempQuesObj });
      }
    }
  });
  return apiObjData;
};

export const getMcqTttDataFormatter = (data: any) => {
  let recvQuizData: any = [];
  let modifiedData: any = [];
  recvQuizData = [...data.mcqTttQuestionDtos];
  modifiedData = [initialTicTacToeOptionFuncn(5)];

  let isQuizMcq: boolean = recvQuizData[0].quizType === quizType.mcq;
  let recvQuizLength = recvQuizData.length;

  recvQuizData.map((item: any, index: number) => {
    let tempArrObj: any = {};
    tempArrObj.question = item.question;
    tempArrObj.id = `mcqTttQuestion-${index}`;
    tempArrObj.image = isQuizMcq ? item.image : null;
    tempArrObj.imageKey = isQuizMcq ? item.imageKey : null;
    tempArrObj.cell = isQuizMcq ? 0 : item.cell;
    let tempOptionsArr: any = [];

    item.options.map((optionItem: any) => {
      let tempOptionObj: any = {};
      tempOptionObj.id = optionItem.id;
      tempOptionObj.optionVal = optionItem.option;
      tempOptionObj.response = optionItem.isCorrect;
      tempOptionsArr.push({ ...tempOptionObj });
    });
    tempArrObj.options = [...tempOptionsArr];

    modifiedData.splice(index, 1, { ...tempArrObj });
  });
  let toAddObjectLength = 5 - recvQuizLength;
  for (let i = 0; i < toAddObjectLength; i++) {
    let tempArrObj: any = {};
    let modifiyingIndex = recvQuizLength + i;
    tempArrObj.question = "";
    tempArrObj.id = `mcqTttQuestion-${modifiyingIndex + 1}`;
    tempArrObj.image = isQuizMcq ? "" : null;
    let tempOptionsArr: any = [];
    for (let j = 0; j < 4; j++) {
      let tempOptionObj: any = {};
      tempOptionObj.id = `mcq-ttt-${i}-${j}`;
      tempOptionObj.optionVal = "";
      tempOptionObj.response = j === 0 ? true : false;
      tempOptionsArr.push({ ...tempOptionObj });
    }
    tempArrObj.options = [...tempOptionsArr];

    modifiedData.splice(modifiyingIndex, 1, { ...tempArrObj });
  }
  return modifiedData;
};

export const postMtfDndFormatter = (
  metaData: LMSQuizMetaDataModel,
  quizData: IMtf[]
): LMSMtfDndQuizModel => {
  let apiObjData: any = { optionDefinition: [] };
  apiObjData.id = metaData.id;
  apiObjData.videoType = metaData.videoType;
  apiObjData.quizType = metaData.quizType;
  apiObjData.isAssessment = metaData.isAssessment;
  apiObjData.questionNumber = metaData.questionNumber;
  // if (metaData.quizType === quizType.dragDropSort) {
  //   apiObjData.type = metaData.dndType;
  // }
  quizData.map((item: IMtf) => {
    let tempOptionObj: any = {};

    tempOptionObj.imageKey = metaData.quizType === quizType.dragDropSort ? item?.imageKey : null;
    tempOptionObj.definition = item.definition;
    tempOptionObj.option = item.option;

    apiObjData.optionDefinition.push({ ...tempOptionObj });
  });

  return apiObjData;
};

export const getMtfDndDataFormatter = (data: any): LMSOptionDefinitionModel[] => {
  let recvQuizData: LMSOptionDefinitionModel[] = [];
  recvQuizData = [...data.optionDefinition];

  let isQuizMcq: boolean = data.quizType === quizType.dragDropSort;
  let newArr: any = [];

  recvQuizData.map((item: LMSOptionDefinitionModel, index: number) => {
    let tempArrObj: any = {};
    tempArrObj.id = `mcq-${index + 1}`;
    tempArrObj.definition = item.definition;
    tempArrObj.option = item.option;
    tempArrObj.imageKey = isQuizMcq ? item?.imageKey : null;
    tempArrObj.image = isQuizMcq ? item?.image : null;

    newArr.push({ ...tempArrObj });
  });
  return newArr;
};
