import {
  FilterDataModel,
  LpActionPlanHeaderModel,
  LpActionPlanCategoryDataModel,
  LpActionPlanTableModel,
  LpSelectedFilterDataModel
} from "@timhortons/common/src/models/lp/actionPlan";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
export interface ILPActionPlansLoading {
  getLpActionPlans: boolean;
  getLpFilterData: boolean;
  lpPostActionSteps: boolean;
  getLpActionPlanAccordion: boolean;
  getLpActionPlanHeader: boolean;
  updateLpActionPlanStatus: boolean;
  getLpCategoryStaus: boolean;
  getLpOwnerData: boolean;
}
export interface ILPActionPlansError {
  getLpActionPlans: string;
  getLpFilterData: string;
  lpPostActionSteps: string;
  getLpActionPlanAccordion: string;
  getLpActionPlanHeader: string;
  updateLpActionPlanStatus: string;
  getLpCategoryStaus: string;
  getLpOwnerData: string;
}

export interface ILPActionPlansState {
  lpActionPlansList: LpActionPlanTableModel;
  lpselectedFilterData: LpSelectedFilterDataModel;
  lpFilterData: FilterDataModel;
  loading: ILPActionPlansLoading;
  error: ILPActionPlansError;
  postActionStepsData: any;
  actionPlanAccordianData: any;
  actionPlanDetailsData: LpActionPlanHeaderModel;
  lpCategoryTable: LpActionPlanCategoryDataModel[];
  lpOwnerData: any;
}
export const actionPlanInitalState: ILPActionPlansState = {
  lpActionPlansList: null,
  lpselectedFilterData: null,
  lpFilterData: null,
  actionPlanDetailsData: null,
  postActionStepsData: null,
  actionPlanAccordianData: [],
  lpCategoryTable: [],
  lpOwnerData: null,
  loading: {
    getLpActionPlans: false,
    getLpFilterData: false,
    lpPostActionSteps: false,
    getLpActionPlanAccordion: false,
    getLpActionPlanHeader: false,
    updateLpActionPlanStatus: false,
    getLpCategoryStaus: false,
    getLpOwnerData: false
  },
  error: {
    getLpActionPlans: null,
    getLpFilterData: null,
    lpPostActionSteps: null,
    getLpActionPlanAccordion: null,
    getLpActionPlanHeader: null,
    updateLpActionPlanStatus: null,
    getLpCategoryStaus: null,
    getLpOwnerData: null
  }
};
export const actionPlanReducer = (
  state = actionPlanInitalState,
  action: { payload: any; type: string }
): ILPActionPlansState => {
  switch (action.type) {
    case LP.ActionPlans.GET_LP_ACTION_PLANS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlans: true },
        error: { ...state.error, getLpActionPlans: null }
      };
    case LP.ActionPlans.GET_LP_ACTION_PLANS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlans: false },
        error: { ...state.error, getLpActionPlans: null },
        lpActionPlansList: action.payload
      };
    case LP.ActionPlans.GET_LP_ACTION_PLANS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlans: false },
        error: { ...state.error, getLpActionPlans: action.payload }
      };

    case LP.ActionPlans.UPDATE_LP_ACTION_PLAN_STATUS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateLpActionPlanStatus: true },
        error: { ...state.error, updateLpActionPlanStatus: null }
      };
    case LP.ActionPlans.UPDATE_LP_ACTION_PLAN_STATUS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateLpActionPlanStatus: false },
        error: { ...state.error, updateLpActionPlanStatus: null }
      };
    case LP.ActionPlans.UPDATE_LP_ACTION_PLAN_STATUS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateLpActionPlanStatus: false },
        error: { ...state.error, updateLpActionPlanStatus: action.payload }
      };

    case LP.ActionPlans.GET_LP_SELECTED_FILTER_DATA:
      return {
        ...state,
        lpselectedFilterData: action.payload
      };
    case LP.ActionPlans.GET_LP_FILTER_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpFilterData: true },
        error: { ...state.error, getLpFilterData: null }
      };
    case LP.ActionPlans.GET_LP_FILTER_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpFilterData: false },
        error: { ...state.error, getLpFilterData: null },
        lpFilterData: action.payload
      };
    case LP.ActionPlans.GET_LP_FILTER_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpFilterData: false },
        error: { ...state.error, getLpFilterData: action.payload }
      };
    case LP.ActionPlans.GET_LP_ACTION_PLAN_ACCORDION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlanAccordion: true },
        error: { ...state.error, getLpActionPlanAccordion: null }
      };
    case LP.ActionPlans.GET_LP_ACTION_PLAN_ACCORDION_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlanAccordion: false },
        error: { ...state.error, getLpActionPlanAccordion: null },
        actionPlanAccordianData: action.payload
      };
    case LP.ActionPlans.GET_LP_ACTION_PLAN_ACCORDION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlanAccordion: false },
        error: { ...state.error, getLpActionPlanAccordion: action.payload }
      };
    case LP.ActionPlans.GET_LP_ACTION_PLAN_HEADER_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlanHeader: true },
        error: { ...state.error, getLpActionPlanHeader: null }
      };
    case LP.ActionPlans.GET_LP_ACTION_PLAN_HEADER_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlanHeader: false },
        error: { ...state.error, getLpActionPlanHeader: null },
        actionPlanDetailsData: action.payload
      };
    case LP.ActionPlans.GET_LP_ACTION_PLAN_HEADER_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpActionPlanHeader: false },
        error: { ...state.error, getLpActionPlanHeader: action.payload }
      };
    case LP.ActionPlans.GET_LP_CATEGORY_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpCategoryStaus: true },
        error: { ...state.error, getLpCategoryStaus: null }
      };
    case LP.ActionPlans.GET_LP_CATEGORY_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpCategoryStaus: false },
        error: { ...state.error, getLpCategoryStaus: null },
        lpCategoryTable: action.payload
      };
    case LP.ActionPlans.GET_LP_CATEGORY_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpCategoryStaus: false },
        error: { ...state.error, getLpCategoryStaus: action.payload }
      };
    case LP.ActionPlans.GET_LP_OWNER_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpOwnerData: true },
        error: { ...state.error, getLpOwnerData: null }
      };
    case LP.ActionPlans.GET_LP_OWNER_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpOwnerData: false },
        error: { ...state.error, getLpOwnerData: null },
        lpOwnerData: action.payload
      };
    case LP.ActionPlans.GET_LP_OWNER_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpOwnerData: false },
        error: { ...state.error, getLpOwnerData: action.payload }
      };
    case LP.ActionPlans.POST_LP_ACTION_STEPS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpPostActionSteps: true },
        error: { ...state.error, lpPostActionSteps: null }
      };
    case LP.ActionPlans.POST_LP_ACTION_STEPS.SUCCESS:
      let headerData = state.actionPlanDetailsData;
      let finalData = action.payload;
      let accordianData = state.actionPlanAccordianData;
      headerData.completionPercent = finalData && finalData.completionPercent;
      headerData.inProcessPercent = finalData && finalData.inProcessPercent;
      headerData.notStartedPercent = finalData && finalData.notStartedPercent;
      for (let item of accordianData) {
        if (item.subCategoryId === finalData.subCategoryId) {
          item.actionSteps = finalData.actionSteps;
          item.status = finalData.status;
          item.endDate = finalData.endDate;
          item.ownerData = finalData.assignedTo;
          item.files = finalData.files;
        }
      }
      return {
        ...state,
        loading: { ...state.loading, lpPostActionSteps: false },
        error: { ...state.error, lpPostActionSteps: null },
        postActionStepsData: finalData,
        actionPlanAccordianData: accordianData,
        actionPlanDetailsData: headerData
      };
    case LP.ActionPlans.POST_LP_ACTION_STEPS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpPostActionSteps: false },
        error: { ...state.error, lpPostActionSteps: action.payload }
      };
    case LP.ActionPlans.UPDATE_LP_ACTION_STEPS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpPostActionSteps: true },
        error: { ...state.error, lpPostActionSteps: null }
      };
    case LP.ActionPlans.UPDATE_LP_ACTION_STEPS.SUCCESS:
      let details = state.actionPlanDetailsData;
      let payloadData = action.payload;
      let actionPlanAccordianData = state.actionPlanAccordianData;
      details.completionPercent = payloadData && payloadData.completionPercent;
      details.inProcessPercent = payloadData && payloadData.inProcessPercent;
      details.notStartedPercent = payloadData && payloadData.notStartedPercent;
      for (let item of actionPlanAccordianData) {
        if (item.subCategoryId === payloadData.subCategoryId) {
          item.actionSteps = payloadData.actionSteps;
          item.status = payloadData.status;
          item.endDate = payloadData.endDate;
          item.ownerData = payloadData.assignedTo;
          item.files = payloadData.files;
        }
      }
      return {
        ...state,
        loading: { ...state.loading, lpPostActionSteps: false },
        error: { ...state.error, lpPostActionSteps: null },
        postActionStepsData: payloadData,
        actionPlanAccordianData: actionPlanAccordianData,
        actionPlanDetailsData: details
      };
    case LP.ActionPlans.UPDATE_LP_ACTION_STEPS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpPostActionSteps: false },
        error: { ...state.error, lpPostActionSteps: action.payload }
      };
    case LP.ActionPlans.RESET_ACTION_PLAN:
      state = actionPlanInitalState;
      return state;
    default:
      return state;
  }
};
