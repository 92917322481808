import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { CategoryDataModel } from "models/rev/selfCalibration";

export interface ICategoryLoading {
  categoryData: boolean;
}

export interface ICategoryError {
  categoryData: string;
}

export interface ICategoryState {
  categoryData: CategoryDataModel;
  error: ICategoryError;
  loading: ICategoryLoading;
}

export const categoryInitialState: ICategoryState = {
  categoryData: null,
  error: { categoryData: "" },
  loading: { categoryData: false }
};

export const categoryReducer = (
  state = categoryInitialState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case REV.CATEGORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, categoryData: true },
        error: { ...state.error, categoryData: "" }
      };
    case REV.CATEGORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, categoryData: false },
        categoryData: action.payload
      };
    case REV.CATEGORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, categoryData: false },
        error: { ...state.error, categoryData: action.payload }
      };
    case REV.UPDATE_CATEGORY_DATA.SUCCESS:
      let data = state.categoryData.categoryList;
      let index = data.findIndex((item) => item.id === action.payload.categoryId);
      if (index > -1) {
        data[index].progress = action.payload.data.categoryPercentage;
      }
      state.categoryData.categoryList = data;
      return {
        ...state,
        loading: { ...state.loading, categoryData: false },
        categoryData: state.categoryData
      };
    case REV.RESET_AUTH_ID:
      state = categoryInitialState;
      return state;
    default:
      return state;
  }
};
