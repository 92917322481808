import { productionSheetEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { ProductionSheetKeys } from "@timhortons/common/src/constants/apiKeys";
import {
  AmPmEmployeeProducts,
  BakingStationCatResp,
  GetBakingCategories,
  IBakingPSPostApiPayload,
  IGetBakingProducts,
  IGetOtherProducts,
  IOtherProductionSheetPostData,
  OthersStationCatResp,
  PostStationAuthPayload,
  ProductValues,
  StationProducts
} from "@timhortons/common/src/models/productionSheet/psModels";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import {
  GetSubCategoryPayload,
  HeaderLabels
} from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable";

function getValues(item: any, qBaked: any, waste: any) {
  if (
    item.label === HeaderLabels.count ||
    item.label === HeaderLabels.total ||
    item.label === HeaderLabels.endOfDayThrows ||
    item.label === HeaderLabels.dailyWaste ||
    item.label === HeaderLabels.totalWaste
  ) {
    if (item.value !== null) {
      return `${item.value}`;
    } else {
      return "";
    }
  } else {
    if (qBaked === "" && waste === "") {
      return "";
    } else if (qBaked !== null && waste === "") {
      return `${qBaked}`;
    } else if (qBaked === "" && waste !== "") {
      return `/${waste}`;
    } else {
      return `${qBaked}/${waste}`;
    }
  }
}
function getColor(ell: ProductValues[], label: any) {
  // highlighting cells
}
function generateShelfLifeText(timeInHours: number): string {
  if (timeInHours < 24) {
    return `${timeInHours} hours`;
  } else {
    const timeInDays = Math.abs(timeInHours / 24);
    return timeInDays > 1 ? `${timeInDays} days` : `${timeInDays} day`;
  }
}
function productItemsFormatter(data: any) {
  let dataToSend: any = data;

  data &&
    data.map((el: any, index: number) => {
      let values: { id: any; value: any }[] = [];
      if (el && el.productionSheetItems) {
        el.shelfLife = el.shelfLife = generateShelfLifeText(el.shelfLife as number);
        el.productionSheetItems.map((item: any) => {
          let waste =
            item.damaged === null && item.expired === null ? "" : item.damaged + item.expired;
          let qBaked = item.quantityBaked === null ? "" : item.quantityBaked;
          values.push({
            id: item.label,
            value: getValues(item, qBaked, waste)
          });
        });
      }
      dataToSend[index].id = el.productId;
      dataToSend[index].name = el.productName;
      dataToSend[index].productValues = values;
      // delete dataToSend[index].productionSheetItems;
      delete dataToSend[index].productName;
    });
  return dataToSend;
}

function postProductItemsFormatter(data: any, label: string) {
  let values: { id: any; value: any; color: any }[] = [];
  data.productionSheetItems.map((item: ProductValues) => {
    let waste = item.damaged === null && item.expired === null ? "" : item.damaged + item.expired;
    let qBaked = item.quantityBaked === null ? "" : item.quantityBaked;
    values.push({
      id: item.label,
      value: getValues(item, qBaked, waste),
      color: getColor(data.productionSheetItems, label)
    });
  });
  return { values: values, productSheet: data.productionSheetItems };
}

class ProductionSheetRepository {
  getStationProducts = async (params: GetSubCategoryPayload): Promise<StationProducts[]> => {
    const { storeCode, categoryId, fromManagerReview, dateTime } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getStationProducts(storeCode),
      { categoryId, fromManagerReview, dateTime },
      ProductionSheetKeys.MAIN_MENU
    );
    return data;
  };
  getProductItems = async (params: IGetBakingProducts) => {
    const { storeCode, stationId, timeZone, subCategoryId, fromManagerReview, date } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getProductItems(storeCode, stationId),
      { timeZone, subCategoryId, fromManagerReview, date },
      ProductionSheetKeys.MAIN_MENU
    );
    return { productId: subCategoryId, apiRes: productItemsFormatter(data) };
  };
  getBakingStationHeaders = async (params: { storeCode: number; timeZone: string }) => {
    const { storeCode, timeZone } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getStoreHeader(storeCode),
      { timeZone },
      ProductionSheetKeys.MAIN_MENU
    );

    return data;
  };
  postProductionSheetData = async (params: IBakingPSPostApiPayload) => {
    const {
      subCategoryId,
      storeCode,
      stationId,
      productId,
      value,
      timeZone,
      quantityBaked,
      expired,
      damaged,
      label,
      fromManagerReview,
      managerReviewDate
    } = params;
    let data = await ApiClient.post(
      productionSheetEndPoints.postProductionSheetData(storeCode, stationId, productId),
      { quantityBaked, expired, damaged, value, label },
      { timeZone, fromManagerReview, managerReviewDate },
      ProductionSheetKeys.MAIN_MENU
    );
    return {
      productId: subCategoryId,
      itemId: productId,
      apiRes: postProductItemsFormatter(data, label)
    };
  };
  getBakingStationCategories = async (params: GetBakingCategories) => {
    const { storeCode, dateTime, fromManagerReview, stationId } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getBakingStationCategories(storeCode, stationId),
      { dateTime, fromManagerReview },
      ProductionSheetKeys.MAIN_MENU
    );

    let specificStationData: BakingStationCatResp[] = [];
    data.map((item: any) => {
      if (item.id !== null) {
        let deserializevar: BakingStationCatResp = deserialize(BakingStationCatResp, item);
        specificStationData.push(deserializevar);
      }
    });
    return specificStationData;
  };
  getAmPmEmployee = async (params: {
    storeCode: number;
    timeZone: string;
  }): Promise<AmPmEmployeeProducts[]> => {
    const { storeCode, timeZone } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getAmPmEmployeeData(storeCode),
      { timeZone },
      ProductionSheetKeys.MAIN_MENU
    );
    let AmPmemployee: AmPmEmployeeProducts[] = [];
    data.map((item: any) => {
      let deserializevar: AmPmEmployeeProducts = deserialize(AmPmEmployeeProducts, item);
      AmPmemployee.push(deserializevar);
    });
    return AmPmemployee;
  };
  postAmPmEmployeeAuth = async (params: PostStationAuthPayload) => {
    const { storeCode, stationId, timeZone, amPm, employeeId } = params;
    let data = await ApiClient.post(
      productionSheetEndPoints.postAmPmEmployeeAuthData(storeCode, stationId),
      null,
      { timeZone, amPm, employeeId },
      ProductionSheetKeys.MAIN_MENU
    );
    return { apiRes: data };
  };

  getOthersProducts = async (params: IGetOtherProducts) => {
    const { storeCode, stationId, timeZone, categoryId, fromManagerReview, date } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getOthersProducts(storeCode, stationId),
      { timeZone, categoryId, fromManagerReview, date },
      ProductionSheetKeys.MAIN_MENU
    );
    let formattedData = data.map((el: any) => {
      el.shelfLife = generateShelfLifeText(el.shelfLife as number);
      return el;
    });
    return formattedData;
  };
  getOthersCategories = async (params: GetBakingCategories) => {
    const { storeCode, dateTime, fromManagerReview, stationId } = params;
    let data = await ApiClient.get(
      productionSheetEndPoints.getOthersCategories(storeCode, stationId),
      { dateTime, fromManagerReview },
      ProductionSheetKeys.MAIN_MENU
    );

    let specificStationData: OthersStationCatResp[] = [];
    data.map((item: any) => {
      if (item.id !== null) {
        let deserializevar: OthersStationCatResp = deserialize(OthersStationCatResp, item);
        specificStationData.push(deserializevar);
      }
    });
    return specificStationData;
  };
  postOthersProductionSheetData = async (params: IOtherProductionSheetPostData) => {
    const {
      storeCode,
      stationId,
      productId,
      value,
      timeZone,
      label,
      fromManagerReview,
      managerReviewDate
    } = params;
    let data = await ApiClient.post(
      productionSheetEndPoints.postOthersProductionSheetData(storeCode, stationId, productId),
      { value, label },
      { timeZone, fromManagerReview, managerReviewDate },
      ProductionSheetKeys.MAIN_MENU
    );

    return {
      itemId: productId,
      apiRes: data
    };
  };
}

const productionSheetRepo = new ProductionSheetRepository();
export { productionSheetRepo as ProductionSheetRepository };
