import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import {
  ReportsDataModal,
  AcknowledgementModel,
  ReportTableResponseModel,
  StoreAccessSummaryDataModel,
  ReportsSummaryOpportunityDataModel,
  IndividualAccessSummaryDataModel,
  CountryManagerDetailsModel
} from "@timhortons/common/src/models/rev/reports";
import { SelectedMonthFilter } from "modules/rev/components/dashboards/reports/organisms/reportsTableHeader";
import { monthsGregorian } from "@timhortons/common/src/components/molecules/customsCalenderPickers";
import {
  CountryDataListModel,
  AllAreaManagersListDataModel,
  ChannelsListDataModel,
  AllStoresListDataModel
} from "@timhortons/common/src/models/profile";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

export interface IRevLoadingGenrateReport {
  all: boolean;
  [ComparsionLevel.Country]: boolean;
  [ComparsionLevel.Channels]: boolean;
  [ComparsionLevel.CountryManager]: boolean;
  [ComparsionLevel.OpsManager]: boolean;
  [ComparsionLevel.AreaManager]: boolean;
  [ComparsionLevel.Stores]: boolean;
}

export interface IRevErrorGenrateReport {
  [ComparsionLevel.Country]: string;
  [ComparsionLevel.Channels]: string;
  [ComparsionLevel.CountryManager]: string;
  [ComparsionLevel.OpsManager]: string;
  [ComparsionLevel.AreaManager]: string;
  [ComparsionLevel.Stores]: string;
}

export interface IRevReportsLoading {
  reportsDataLoading: boolean;
  acknowledgementLoading: boolean;
  reportTableData: boolean;
  postAcknowledgementLoading: boolean;
  storeAccessSummaryData: boolean;
  storeAccessOpportunityData: boolean;
  individualAccessSummaryData: boolean;
  getAllCountries: boolean;
  getAllAreaManager: boolean;
  getAllChannels: boolean;
  getAllStores: boolean;
  getAllCountryManager: boolean;
  generatedReportBuilderResponse: IRevLoadingGenrateReport;
  comparisonCountryManagerAdmin: boolean;
  comparisonAreaManagerAdmin: boolean;
  comparisonStoresAdmin: boolean;
  highLowChannelsAdmin: boolean;
  getCountryManagerDetails: boolean;
}
export interface IRevReportsError {
  reportsDataError: string;
  acknowledgementError: string;
  reportTableData: string;
  postAcknowledgementError: string;
  storeAccessSummaryData: string;
  storeAccessOpportunityData: string;
  individualAccessSummaryData: string;
  getAllCountries: string;
  getAllAreaManager: string;
  getAllChannels: string;
  getAllStores: string;
  getAllCountryManager: string;
  generatedReportBuilderResponse: IRevErrorGenrateReport;
  comparisonCountryManagerAdmin: string;
  comparisonAreaManagerAdmin: string;
  comparisonStoresAdmin: string;
  highLowChannelsAdmin: string;
  getCountryManagerDetails: string;
}
export interface IRevReportsState {
  getAllCountries: CountryDataListModel;
  reportsData: ReportsDataModal;
  reportTableData: ReportTableResponseModel;
  error: IRevReportsError;
  loading: IRevReportsLoading;
  acknowledgement: AcknowledgementModel;
  postAckData: any;
  storeAccessSummaryData: StoreAccessSummaryDataModel;
  storeAccessOpportunityData: ReportsSummaryOpportunityDataModel[];
  monthFilter: SelectedMonthFilter;
  individualAccessSummaryData: IndividualAccessSummaryDataModel;
  getAllAreaManager: AllAreaManagersListDataModel;
  getAllChannels: ChannelsListDataModel;
  getAllStores: AllStoresListDataModel;
  getAllCountryManager: AllAreaManagersListDataModel;
  generatedReportBuilderResponse: {
    reportType: string;
    startDate: string;
    endDate: string;
    reportData: any[];
  };
  comparisonCountryManagerAdmin: any;
  comparisonAreaManagerAdmin: any;
  comparisonStoresAdmin: any;
  highLowChannelsAdmin: any;
  getCountryManagerDetails: CountryManagerDetailsModel;
}

export const revReportsInitialState: IRevReportsState = {
  reportsData: null,
  monthFilter: {
    year: new Date().getFullYear(),
    month: {
      label: monthsGregorian.find((el) => el.id === new Date().getMonth()).label,
      id: new Date().getMonth()
    }
  },
  reportTableData: null,
  postAckData: null,
  storeAccessSummaryData: null,
  storeAccessOpportunityData: null,
  getAllCountries: null,
  getAllAreaManager: null,
  getAllStores: null,
  getAllChannels: null,
  getAllCountryManager: null,
  generatedReportBuilderResponse: {
    reportType: null,
    startDate: null,
    endDate: null,
    reportData: []
  },
  comparisonCountryManagerAdmin: null,
  comparisonAreaManagerAdmin: null,
  comparisonStoresAdmin: null,
  highLowChannelsAdmin: null,
  getCountryManagerDetails: null,
  error: {
    getAllCountries: null,
    getAllAreaManager: null,
    getAllChannels: null,
    getAllStores: null,
    getAllCountryManager: null,
    generatedReportBuilderResponse: {
      [ComparsionLevel.Country]: null,
      [ComparsionLevel.Channels]: null,
      [ComparsionLevel.CountryManager]: null,
      [ComparsionLevel.OpsManager]: null,
      [ComparsionLevel.AreaManager]: null,
      [ComparsionLevel.Stores]: null
    },
    comparisonCountryManagerAdmin: null,
    comparisonAreaManagerAdmin: null,
    comparisonStoresAdmin: null,
    highLowChannelsAdmin: null,
    reportsDataError: "",
    acknowledgementError: "",
    reportTableData: "",
    postAcknowledgementError: "",
    storeAccessSummaryData: "",
    storeAccessOpportunityData: "",
    individualAccessSummaryData: "",
    getCountryManagerDetails: null
  },
  loading: {
    comparisonAreaManagerAdmin: false,
    comparisonCountryManagerAdmin: false,
    comparisonStoresAdmin: false,
    highLowChannelsAdmin: false,
    getAllAreaManager: false,
    getAllCountries: false,
    getAllChannels: false,
    getAllStores: false,
    reportsDataLoading: false,
    acknowledgementLoading: false,
    reportTableData: false,
    postAcknowledgementLoading: false,
    storeAccessSummaryData: false,
    storeAccessOpportunityData: false,
    individualAccessSummaryData: false,
    getAllCountryManager: false,
    generatedReportBuilderResponse: {
      all: false,
      [ComparsionLevel.Country]: false,
      [ComparsionLevel.Channels]: false,
      [ComparsionLevel.CountryManager]: false,
      [ComparsionLevel.OpsManager]: false,
      [ComparsionLevel.AreaManager]: false,
      [ComparsionLevel.Stores]: false
    },
    getCountryManagerDetails: false
  },
  acknowledgement: {
    dropDownData: []
  },
  individualAccessSummaryData: null
};

export const revReportsReducer = (
  state = revReportsInitialState,
  action: { payload: any; type: string }
): IRevReportsState => {
  switch (action.type) {
    case REV.REPORTS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, reportsDataLoading: true },
        error: { ...state.error, reportsDataError: "" }
      };
    case REV.ACKNOWLEDGEMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, acknowledgementLoading: true },
        error: { ...state.error, acknowledgementError: "" }
      };
    case REV.REPORTS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, reportsDataLoading: false },
        reportsData: action.payload
      };
    case REV.ACKNOWLEDGEMENT_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, acknowledgementLoading: false },
        acknowledgement: action.payload
      };
    case REV.REPORTS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, reportsDataLoading: false },
        error: { ...state.error, reportsDataError: action.payload }
      };
    case REV.ACKNOWLEDGEMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, acknowledgementLoading: false },
        error: { ...state.error, acknowledgementError: action.payload }
      };
    case REV.GET_REPORT_TABLE_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, reportTableData: true },
        error: { ...state.error, reportTableData: "" },
        reportTableData: null
      };
    case REV.GET_REPORT_TABLE_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, reportTableData: false },
        reportTableData: action.payload
      };
    case REV.GET_REPORT_TABLE_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, reportTableData: false },
        error: { ...state.error, reportTableData: action.payload }
      };

    case REV.POST_ACKNOWLEDGEMENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postAcknowledgementLoading: true },
        error: { ...state.error, postAcknowledgementError: "" },
        postAckData: null
      };
    case REV.POST_ACKNOWLEDGEMENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postAcknowledgementLoading: false },
        postAckData: action.payload
      };
    case REV.POST_ACKNOWLEDGEMENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postAcknowledgementLoading: false },
        error: { ...state.error, postAcknowledgementError: action.payload }
      };

    case REV.GET_STORE_ACCESS_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, storeAccessSummaryData: true },
        error: { ...state.error, storeAccessSummaryData: "" }
      };
    case REV.GET_STORE_ACCESS_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, storeAccessSummaryData: false },
        storeAccessSummaryData: action.payload
      };
    case REV.GET_STORE_ACCESS_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, storeAccessSummaryData: false },
        error: { ...state.error, storeAccessSummaryData: action.payload }
      };
    case REV.GET_STORE_ACCESS_OPPORTUNITY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, storeAccessOpportunityData: true },
        error: { ...state.error, storeAccessOpportunityData: "" }
      };
    case REV.GET_STORE_ACCESS_OPPORTUNITY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, storeAccessOpportunityData: false },
        storeAccessOpportunityData: action.payload
      };
    case REV.GET_STORE_ACCESS_OPPORTUNITY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, storeAccessOpportunityData: false },
        error: { ...state.error, storeAccessOpportunityData: action.payload }
      };
    case REV.GET_SELECTED_MONTH_FILTER.SUCCESS:
      return {
        ...state,
        monthFilter: action.payload
      };
    case REV.GET_INDIVIDUAL_SUMMARY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, individualAccessSummaryData: true },
        error: { ...state.error, individualAccessSummaryData: "" }
      };
    case REV.GET_INDIVIDUAL_SUMMARY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, individualAccessSummaryData: false },
        individualAccessSummaryData: action.payload
      };
    case REV.GET_INDIVIDUAL_SUMMARY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, individualAccessSummaryData: false },
        error: { ...state.error, individualAccessSummaryData: action.payload }
      };
    // case REV.ReportBuilder.GET_ALL_COUNTRIES.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllCountries: true },
    //     error: { ...state.error, getAllCountries: null }
    //   };
    // case REV.ReportBuilder.GET_ALL_COUNTRIES.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllCountries: false },
    //     error: { ...state.error, getAllCountries: null },
    //     getAllCountries: action.payload
    //   };
    // case REV.ReportBuilder.GET_ALL_COUNTRIES.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllCountries: false },
    //     error: { ...state.error, getAllCountries: action.payload }
    //   };
    // case REV.ReportBuilder.GET_ALL_AREA_MANAGER.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllAreaManager: true },
    //     error: { ...state.error, getAllAreaManager: null }
    //   };
    // case REV.ReportBuilder.GET_ALL_AREA_MANAGER.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllAreaManager: false },
    //     error: { ...state.error, getAllAreaManager: null },
    //     getAllAreaManager: action.payload
    //   };
    // case REV.ReportBuilder.GET_ALL_AREA_MANAGER.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllAreaManager: false },
    //     error: { ...state.error, getAllAreaManager: action.payload }
    //   };

    // case REV.ReportBuilder.GET_ALL_CHANNELS.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllChannels: true },
    //     error: { ...state.error, getAllChannels: null }
    //   };
    // case REV.ReportBuilder.GET_ALL_CHANNELS.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllChannels: false },
    //     error: { ...state.error, getAllChannels: null },
    //     getAllChannels: action.payload
    //   };
    // case REV.ReportBuilder.GET_ALL_CHANNELS.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllChannels: false },
    //     error: { ...state.error, getAllChannels: action.payload }
    //   };

    // case REV.ReportBuilder.GET_ALL_STORES.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllStores: true },
    //     error: { ...state.error, getAllStores: null }
    //   };
    // case REV.ReportBuilder.GET_ALL_STORES.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllStores: false },
    //     error: { ...state.error, getAllStores: null },
    //     getAllStores: action.payload
    //   };
    // case REV.ReportBuilder.GET_ALL_STORES.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllStores: false },
    //     error: { ...state.error, getAllStores: action.payload }
    //   };
    // case REV.ReportBuilder.GET_ALL_COUNTRY_MANAGER.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllCountryManager: true },
    //     error: { ...state.error, getAllCountryManager: null }
    //   };
    // case REV.ReportBuilder.GET_ALL_COUNTRY_MANAGER.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllCountryManager: false },
    //     error: { ...state.error, getAllCountryManager: null },
    //     getAllCountryManager: action.payload
    //   };
    // case REV.ReportBuilder.GET_ALL_COUNTRY_MANAGER.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, getAllCountryManager: false },
    //     error: { ...state.error, getAllCountryManager: action.payload }
    //   };
    case REV.ReportBuilder.GENERATE_REPORT.LOADING:
      let updateTableName: ComparsionLevel = action.payload.updateTableName;
      let loadingReportType: ComparsionLevel = action.payload?.object?.levelOfReport;
      return {
        ...state,
        loading: {
          ...state.loading,
          generatedReportBuilderResponse: {
            ...state.loading.generatedReportBuilderResponse,
            all: updateTableName === null,
            [updateTableName]: true
          }
        },
        error: {
          ...state.error,
          generatedReportBuilderResponse: {
            ...state.error.generatedReportBuilderResponse,
            [loadingReportType]: null
          }
        },
        generatedReportBuilderResponse:
          updateTableName === null
            ? { reportType: null, startDate: null, endDate: null, reportData: [] }
            : state.generatedReportBuilderResponse
      };
    case REV.ReportBuilder.GENERATE_REPORT.SUCCESS:
      let currReportType: ComparsionLevel = action?.payload?.metaData?.reportEnum;
      let data = state.generatedReportBuilderResponse;
      data.reportType = action.payload.metaData.reportType;
      data.startDate = action.payload.metaData.startDate;
      data.endDate = action.payload.metaData.endDate;
      let obj = {
        reportLevel: action.payload.metaData.levelOfReport,
        data: action.payload.apiResponse,
        reportEnum: currReportType,
        isKPI: action.payload?.metaData?.isKPI ?? false
      };
      let targetIndex = data.reportData.findIndex((el) => el.reportLevel === obj.reportLevel);
      targetIndex > -1 ? data.reportData.splice(targetIndex, 1, obj) : data.reportData.push(obj);
      return {
        ...state,
        loading: {
          ...state.loading,
          generatedReportBuilderResponse: {
            all: false,
            [ComparsionLevel.Country]: false,
            [ComparsionLevel.Channels]: false,
            [ComparsionLevel.CountryManager]: false,
            [ComparsionLevel.OpsManager]: false,
            [ComparsionLevel.AreaManager]: false,
            [ComparsionLevel.Stores]: false
          }
        },
        error: {
          ...state.error,
          generatedReportBuilderResponse: {
            ...state.error.generatedReportBuilderResponse,
            [currReportType]: null
          }
        },
        generatedReportBuilderResponse: data
      };
    case REV.ReportBuilder.GENERATE_REPORT.ERROR:
      const { error, reportType }: { error: string; reportType: ComparsionLevel } = action.payload;
      let err = error ? error : "error";
      return {
        ...state,
        loading: {
          ...state.loading,
          generatedReportBuilderResponse: {
            all: false,
            [ComparsionLevel.Country]: false,
            [ComparsionLevel.Channels]: false,
            [ComparsionLevel.CountryManager]: false,
            [ComparsionLevel.OpsManager]: false,
            [ComparsionLevel.AreaManager]: false,
            [ComparsionLevel.Stores]: false
          }
        },
        error: {
          ...state.error,
          generatedReportBuilderResponse: {
            ...state.error.generatedReportBuilderResponse,
            [reportType]: err
          }
        }
      };
    // case REV.ReportBuilder.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonCountryManagerAdmin: false },
    //     error: { ...state.error, comparisonCountryManagerAdmin: null }
    //   };
    // case REV.ReportBuilder.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonCountryManagerAdmin: false },
    //     error: { ...state.error, comparisonCountryManagerAdmin: null },
    //     comparisonCountryManagerAdmin: action.payload
    //   };
    // case REV.ReportBuilder.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonCountryManagerAdmin: false },
    //     error: { ...state.error, comparisonCountryManagerAdmin: action.payload }
    //   };

    // case REV.ReportBuilder.GET_COMPARISON_AREA_MANAGERS_ADMIN.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonAreaManagerAdmin: false },
    //     error: { ...state.error, comparisonAreaManagerAdmin: null }
    //   };
    // case REV.ReportBuilder.GET_COMPARISON_AREA_MANAGERS_ADMIN.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonAreaManagerAdmin: false },
    //     error: { ...state.error, comparisonAreaManagerAdmin: null },
    //     comparisonAreaManagerAdmin: action.payload
    //   };
    // case REV.ReportBuilder.GET_COMPARISON_AREA_MANAGERS_ADMIN.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonAreaManagerAdmin: false },
    //     error: { ...state.error, comparisonAreaManagerAdmin: action.payload }
    //   };
    // case REV.ReportBuilder.GET_COMPARISON_STORES_ADMIN.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonStoresAdmin: true },
    //     error: { ...state.error, comparisonStoresAdmin: null }
    //   };
    // case REV.ReportBuilder.GET_COMPARISON_STORES_ADMIN.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonStoresAdmin: false },
    //     error: { ...state.error, comparisonStoresAdmin: null },
    //     comparisonStoresAdmin: action.payload
    //   };
    // case REV.ReportBuilder.GET_COMPARISON_STORES_ADMIN.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, comparisonStoresAdmin: false },
    //     error: { ...state.error, comparisonStoresAdmin: action.payload }
    //   };
    // case REV.ReportBuilder.GET_HIGHLOW_CHANNELS_ADMIN.LOADING:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, highLowChannelsAdmin: true },
    //     error: { ...state.error, highLowChannelsAdmin: null }
    //   };
    // case REV.ReportBuilder.GET_HIGHLOW_CHANNELS_ADMIN.SUCCESS:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, highLowChannelsAdmin: false },
    //     error: { ...state.error, highLowChannelsAdmin: null },
    //     highLowChannelsAdmin: action.payload
    //   };
    // case REV.ReportBuilder.GET_HIGHLOW_CHANNELS_ADMIN.ERROR:
    //   return {
    //     ...state,
    //     loading: { ...state.loading, highLowChannelsAdmin: false },
    //     error: { ...state.error, highLowChannelsAdmin: action.payload }
    //   };
    case REV.ReportBuilder.RESET_REPORT_BUILDER:
      state = revReportsInitialState;
      return state;
    case REV.GET_COUNTRYMANAGER_DETAILS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getCountryManagerDetails: true },
        error: { ...state.error, getCountryManagerDetails: null }
      };
    case REV.GET_COUNTRYMANAGER_DETAILS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCountryManagerDetails: false },
        getCountryManagerDetails: action.payload
      };
    case REV.GET_COUNTRYMANAGER_DETAILS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getCountryManagerDetails: false },
        error: { ...state.error, getCountryManagerDetails: action.payload }
      };
    case REV.RESET_AUTH_ID:
      return {
        ...state,
        postAckData: null
      };
    default:
      return state;
  }
};
