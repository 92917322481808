import React, { ReactElement } from "react";
import { StyleSheet, View, TouchableOpacity, StyleProp, ViewStyle } from "react-native";

interface IProps {
  disabled?: boolean;
  onPress?: () => void;
  children?: React.ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  bodyStyle?: StyleProp<ViewStyle>;
  footerStyle?: StyleProp<ViewStyle>;
}

export default function Card(props: IProps): ReactElement {
  const { onPress, disabled, children, containerStyle } = props;
  return (
    <View>
      <TouchableOpacity disabled={disabled} onPress={onPress}>
        <View style={[styles.container, containerStyle]}>{children}</View>
      </TouchableOpacity>
    </View>
  );
}

const Header = ({ children, headerStyle }: IProps) => {
  return <View style={[styles.cardheader, headerStyle]}>{children}</View>;
};

const Body = ({ children, bodyStyle }: IProps) => {
  return <View style={[styles.cardbody, bodyStyle]}>{children}</View>;
};

const Footer = ({ children, footerStyle }: IProps) => {
  return <View style={[styles.cardfooter, footerStyle]}>{children}</View>;
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;

const styles = StyleSheet.create({
  container: {
    elevation: 3,
    borderRadius: 3,
    shadowOffset: { width: 1, height: 1 }
    // backgroundColor: colorPallete.grey2
  },
  cardheader: {
    // borderBottomColor: colorPallete.red1,
    // borderBottomWidth: 1
  },
  cardbody: {
    // borderBottomColor: colorPallete.red1,
    // borderBottomWidth: 1
  },
  cardfooter: {}
});
