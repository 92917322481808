import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("UserDetailsModel")
export class UserDetailsModel {
  @JsonProperty("id", Number, true)
  id?: number = 0;
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("profileUrl", String && null)
  profilePhoto: string = "";
  @JsonProperty("roleName", String)
  role: string = "";
  @JsonProperty("firstName", String)
  firstName: string = "";
  @JsonProperty("lastName", String && null)
  lastName: string = "";
  @JsonProperty("personalEmail", String && null)
  email: string = "";
  @JsonProperty("mobileNumber", String && null)
  phone: string = "";
  @JsonProperty("workMobileNumber", String && null)
  workMobileNumber: string = "";
  @JsonProperty("workEmail", String && null)
  workEmail: string = "";
  @JsonProperty("profileCompletionPercentage", Number && null)
  progressPercentage: number = 0;
  @JsonProperty("dateOfJoining", String && null)
  addedOn: string = "";
  @JsonProperty("isActive", Boolean)
  status: boolean = false;
  @JsonProperty("isChecked", Boolean, true)
  isChecked?: boolean = false;
}
@JsonObject("UserDetailsByUserIDModel")
export class UserDetailsByUserIDModel {
  @JsonProperty("totalElements", Number)
  totalRecords: number = 0;
  @JsonProperty("content", [UserDetailsModel])
  userDetails: UserDetailsModel[] = null;
}

@JsonObject("ChecklistModel")
export class ChecklistModel {
  @JsonProperty("id", String)
  id: string = null;
  @JsonProperty("checklistId", String && null)
  checklistId: string = "";
  @JsonProperty("checklist", String)
  checklist: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("answer", Boolean && null)
  answer: boolean = false;
  @JsonProperty("points", Number && null)
  points: number = 0;
}
@JsonObject("TopicImageModel")
export class TopicImageModel {
  @JsonProperty("id", String)
  id: String = "";
  @JsonProperty("topicId", String)
  topicId: String = "";
  @JsonProperty("imageUrl", String)
  imageUrl: String = "";
}

@JsonObject("TopicsAndChecklistModel")
export class TopicsAndChecklistModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("topic", String)
  topic: string = "";
  @JsonProperty("checklists", [ChecklistModel])
  checklists: ChecklistModel[] = null;
  @JsonProperty("images", [TopicImageModel])
  images: TopicImageModel[] = null;
  @JsonProperty("moduleId", String)
  moduleId: string = "";
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("percentage", Number)
  percentage: number = 0;
  @JsonProperty("totalPoints", Number)
  totalPoints: number = 0;
}
@JsonObject("PostChecklistPayLoad")
export class PostChecklistPayLoad {
  @JsonProperty("checklistId", String)
  checklistId: string = "";
  @JsonProperty("employeeId", Number)
  employeeId: Number = 0;
  @JsonProperty("answer", Boolean)
  answer: boolean = false;
  @JsonProperty("points", Number)
  points: number = 0;
  @JsonProperty("moduleId", String)
  moduleId: string = "";
}
@JsonObject("PostChecklistModel")
export class PostChecklistModel {
  @JsonProperty("id", String)
  id: string = null;
  @JsonProperty("checklistId", String)
  checklistId: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("answer", Boolean)
  answer: boolean = false;
  @JsonProperty("points", Number)
  points: number = 0;
}

@JsonObject("ModuleModel")
export class ModuleModel {
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("percentage", Number)
  percentage: number = 0;
  @JsonProperty("result", String)
  result: string = "";
  @JsonProperty("practicalStatus", String)
  practicalStatus: string = "";
  @JsonProperty("certificate", String)
  certificate: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("isStarted", Boolean)
  isStarted: boolean = false;
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("theoryAssessment", String)
  theoryAssessment: string = "";
}

@JsonObject("GetModulesModel")
export class GetModulesModel {
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("percentage", Number)
  percentage: number = 0;
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("result", String)
  result: string = "";
  @JsonProperty("id", String)
  id: string = "";
}

@JsonObject("TippingPointsDataModel")
export class TippingPointsDataModel {
  @JsonProperty("tippingId", Number)
  tippingId: number = 0;
  @JsonProperty("tippingPoint", String)
  tippingPoint: string = "";
}
@JsonObject("TippingPointsModel")
export class TippingPointsModel {
  @JsonProperty("tippingPoints", [TippingPointsDataModel])
  tippingPoints: TippingPointsDataModel[];
}
