import {
  getAllCategoryAndProductsSuccess,
  getAllCategoryAndProductsError,
  postCategoryAndProducts,
  postCategoryAndProductsSuccess,
  postCategoryAndProductsError,
  getAllCategoryAndProducts,
  resetPostMainMenu,
  getAllStations,
  getAllStationsSuccess,
  getAllStationsError
} from "./mainMenu";
import {
  getManagerReviewProductionSheet,
  getManagerReviewProductionSheetSuccess,
  getManagerReviewProductionSheetError,
  updateMRItem,
  updateMRItemSuccess,
  updateMRItemError,
  checkAccessToStore,
  checkAccessToStoreSuccess,
  checkAccessToStoreError,
  resetMRErrorSuccessModal
} from "./managerReview";
export const ManagerReviewProductionSheetActions = {
  getManagerReviewProductionSheet,
  getManagerReviewProductionSheetSuccess,
  getManagerReviewProductionSheetError,
  updateMRItem,
  updateMRItemSuccess,
  updateMRItemError,
  checkAccessToStore,
  checkAccessToStoreSuccess,
  checkAccessToStoreError,
  resetMRErrorSuccessModal
};
export const MainMenuActions = {
  getAllCategoryAndProducts,
  getAllCategoryAndProductsSuccess,
  getAllCategoryAndProductsError,
  postCategoryAndProducts,
  postCategoryAndProductsSuccess,
  postCategoryAndProductsError,
  resetPostMainMenu,
  getAllStations,
  getAllStationsSuccess,
  getAllStationsError
};
