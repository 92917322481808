import { FAQ } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import { FaqQuestionModel } from "@timhortons/common/src/models/askTell/faqModule";
import { askTelQuestionErrorHandler } from "@timhortons/common/src/redux/askTell/saga/askTellFormatter";

export interface IFaqModuleLoading {
  getAllFaq: boolean;
  createFaq: boolean;
  deleteFaq: boolean;
  updateFaq: boolean;
}
export interface IFaqModuleError {
  getAllFaq: string;
  createFaq: string;
  deleteFaq: string;
  updateFaq: string;
}

export interface IFaqModuleState {
  getAllFaq: FaqQuestionModel[];
  createFaq: any;
  deleteFaq: any;
  updateFaq: any;
  error: IFaqModuleError;
  loading: IFaqModuleLoading;
}

export const FaqModuleInitialState: IFaqModuleState = {
  getAllFaq: [],
  createFaq: null,
  deleteFaq: null,
  updateFaq: null,
  error: {
    getAllFaq: null,
    createFaq: null,
    deleteFaq: null,
    updateFaq: null
  },
  loading: {
    getAllFaq: false,
    createFaq: false,
    deleteFaq: false,
    updateFaq: false
  }
};

export const faqModuleReducer = (
  state = FaqModuleInitialState,
  action: { payload: any; type: string }
): IFaqModuleState => {
  switch (action.type) {
    case FAQ.CREATE_FAQ.LOADING:
      return {
        ...state,
        loading: { ...state.loading, createFaq: true },
        error: { ...state.error, createFaq: null }
      };
    case FAQ.CREATE_FAQ.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createFaq: false },
        createFaq: action.payload
      };
    case FAQ.CREATE_FAQ.ERROR:
      return {
        ...state,
        loading: { ...state.loading, createFaq: false },
        error: { ...state.error, createFaq: askTelQuestionErrorHandler(action.payload) }
      };

    case FAQ.UPDATE_FAQ.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateFaq: true },
        error: { ...state.error, updateFaq: null }
      };
    case FAQ.UPDATE_FAQ.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateFaq: false },
        updateFaq: action.payload
      };
    case FAQ.UPDATE_FAQ.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateFaq: false },
        error: { ...state.error, updateFaq: askTelQuestionErrorHandler(action.payload) }
      };

    case FAQ.DELETE_FAQ.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteFaq: true },
        error: { ...state.error, deleteFaq: null }
      };
    case FAQ.DELETE_FAQ.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteFaq: false },
        deleteFaq: null
      };
    case FAQ.DELETE_FAQ.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteFaq: false },
        error: { ...state.error, deleteFaq: action.payload }
      };

    case FAQ.GET_ALL_FAQ.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllFaq: true },
        error: { ...state.error, getAllFaq: null }
      };

    case FAQ.GET_ALL_FAQ.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllFaq: false },
        error: { ...state.error, getAllFaq: null },
        getAllFaq: action.payload
      };
    case FAQ.GET_ALL_FAQ.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllFaq: false },
        error: { ...state.error, getAllFaq: action.payload }
      };
    case FAQ.RESET_FAQ_ERROR:
      return {
        ...state,
        error: { ...state.error, createFaq: null, updateFaq: null, deleteFaq: null }
      };
    default:
      return state;
  }
};
