import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { manageAskEndPoints } from "@timhortons/common/src/constants/endPoints";
import {
  AddQuestionModal,
  FilterDataModel
} from "@timhortons/common/src/models/askTell/manageAskModule";
import { AskTellKeys } from "@timhortons/common/src/constants/apiKeys";

class QuestionsRepository {
  getQuestionsList = async (param: FilterDataModel) => {
    let { pageNo, pageSize } = param;
    let search = param.keyword === "" ? null : param.keyword;
    let filterType =
      param.type === "FILTER" || param.type === "Filter" ? "OPEN,ADMIN,PERSONAL" : param.type;
    let object: any = {
      pageNo: pageNo,
      pageSize: pageSize
    };
    if (param.keyword !== null) {
      object = {
        ...object,
        keyword: search
      };
    }
    if (param.type !== null) {
      object = {
        ...object,
        filterType: filterType
      };
    }
    let data = await ApiClient.get(
      manageAskEndPoints.getQuestionsList(),
      {
        ...object
      },
      AskTellKeys.QUESTIONS
    );

    return data;
  };
  deleteQuestion = async (param: { questionId: string }) => {
    const { questionId } = param;
    let res = await ApiClient.delete(
      manageAskEndPoints.deleteQuestion(questionId),
      null,
      null,
      AskTellKeys.QUESTIONS
    );
    return res;
  };
  postQuestion = async (body: AddQuestionModal) => {
    let headers = {
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(manageAskEndPoints.getQuestionsList(), body, null, headers);
    return data;
  };
}

const questionsRepository = new QuestionsRepository();
export { questionsRepository as QuestionsRepository };
