import {
  View,
  StyleSheet,
  Animated,
  ImageSourcePropType,
  ImageResizeMode,
  StyleProp,
  ImageStyle
} from "react-native";
import React, { ReactElement, useState, useEffect } from "react";
import { gatewayTimeoutImg, noImgFound } from "@timhortons/common/src/assets/images/imageAssets";

interface IProps {
  thumbnailSource: ImageSourcePropType;
  source: ImageSourcePropType;
  resizeMode?: ImageResizeMode;
  testId: string;
  imageStyle?: StyleProp<ImageStyle>;
  customStyle?: StyleProp<ImageStyle>;
  resetErrImg?: Boolean;
}

let thumbnailAnimated = new Animated.Value(0);
let imageAnimated = new Animated.Value(0);
export function ProgressiveImage(props: IProps): ReactElement {
  const [state, setstate] = useState({ error: false, errSrc: gatewayTimeoutImg });
  const onThumbnailLoad = () => {
    Animated.timing(thumbnailAnimated, {
      toValue: 1,
      useNativeDriver: true
    }).start();
  };

  const onActualImgLoad = () => {
    Animated.timing(imageAnimated, {
      toValue: 1,
      useNativeDriver: true
    }).start();
    Animated.timing(thumbnailAnimated, {
      toValue: 0,
      useNativeDriver: true
    }).start();
  };
  const {
    thumbnailSource,
    customStyle,
    source,
    imageStyle,
    testId,
    resizeMode,
    resetErrImg = false
  } = props;

  function sourceDecide() {
    let imgSrc = noImgFound;
    if (state.error) {
      imgSrc = gatewayTimeoutImg;
    } else if (source && source.uri !== null) {
      imgSrc = source;
    }
    return imgSrc;
  }
  useEffect(() => {
    if (resetErrImg) setstate({ ...state, error: false });
  }, [source, resetErrImg]);

  return (
    <View style={[styles.container, customStyle]}>
      {thumbnailSource ? (
        <Animated.Image
          source={thumbnailSource}
          style={[imageStyle, { opacity: thumbnailAnimated }]}
          onLoad={() => onThumbnailLoad()}
          blurRadius={1}
          resizeMode={resizeMode}
        />
      ) : null}
      <Animated.Image
        source={sourceDecide()}
        style={[styles.imageOverlay, { opacity: imageAnimated }, imageStyle]}
        onLoad={() => onActualImgLoad()}
        onError={() => setstate({ ...state, error: true })}
        testID={testId}
        resizeMode={resizeMode}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  imageOverlay: {
    position: "absolute"
  },
  container: {
    // height: "fit-content",
    // width: "fit-content",
    backgroundColor: "#ffffff"
  }
});
export default ProgressiveImage;
