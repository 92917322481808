import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { setFrameMeasures } from "@timhortons/common/src/utils/measureLayout";
import OverflowModal from "@timhortons/common/src/components/atoms/overflowModal";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import MultiSelect, { IItem } from "@timhortons/common/src/components/molecules/multiSelect";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { IButtonStyle } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportType";
import { Checkbox, CheckboxType } from "@timhortons/common/src/components/atoms/checkBox";
interface Props {
  triggerComponent?: boolean;
  callBk?: (data: { year: number; month: { label: string; id: number } }) => void;
  onPressButton?: Function;
  buttonProps?: IButtonStyle;
  iconSize?: number;
  reset?: boolean;
  setReset?: (reset: boolean) => void;
}
export const monthsGregorian = [
  { label: "January", id: 0, isChecked: false },
  { id: 1, label: "February", isChecked: false },
  { id: 2, label: "March", isChecked: false },
  { id: 3, label: "April", isChecked: false },
  { id: 4, label: "May", isChecked: false },
  { id: 5, label: "June", isChecked: false },
  { id: 6, label: "July", isChecked: false },
  { id: 7, label: "August", isChecked: false },
  { id: 8, label: "September", isChecked: false },
  { id: 9, label: "October", isChecked: false },
  { id: 10, label: "November", isChecked: false },
  { id: 11, label: "December", isChecked: false }
];

const quartersGregorian = [
  { label: "Quarter 1", id: 0, isChecked: false },
  { id: 1, label: "Quarter 2", isChecked: false },
  { id: 2, label: "Quarter 3", isChecked: false },
  { id: 3, label: "Quarter 4", isChecked: false }
];

const generateMonthList = (
  year: number
): Array<{ label: string; id: number; isChecked: boolean }> => {
  let currentYear = new Date().getFullYear();
  if (year > currentYear) {
    throw new Error("Illegal Operation");
  }
  if (year < currentYear) {
    return monthsGregorian;
  } else {
    let currentMonth = new Date().getMonth();
    return monthsGregorian.slice(0, currentMonth + 1);
  }
};
const generateQuarterList = (
  year: number
): Array<{ label: string; id: number; isChecked: boolean }> => {
  let currentYear = new Date().getFullYear();
  if (year > currentYear) {
    throw new Error("Illegal Operation");
  }
  if (year < currentYear) {
    return quartersGregorian;
  } else {
    let currentMonth = new Date().getMonth();
    let quarterLen = Math.ceil((currentMonth + 1) / 3);
    return quartersGregorian.slice(0, quarterLen);
  }
};
const UI = (props: {
  list: Array<{ label: string; id: number; isChecked: boolean }>;
  onPressItem: (data: { label: string; id: number; isChecked: boolean }) => void;
  centerDisp: string;
  onPressChevron: Function;
  selectedMonth?: { label: string; id: number; value: string };
  reset?: boolean;
  setOpen?: (value: boolean) => void;
}): ReactElement => {
  const { list, onPressItem, centerDisp, onPressChevron, selectedMonth, reset, setOpen } = props;

  return (
    <View style={styles.masterWrapper}>
      <View style={styles.headerWrapper}>
        <TouchableOpacity onPress={() => onPressChevron("left")}>
          <Icon name={IconNames.left} customStyle={styles.iconStyle} />
        </TouchableOpacity>
        <Text testId="crtyr" fontWeight={FontWeight.SemiBold} textSize={TextSize.Regular}>
          {centerDisp}
        </Text>
        <TouchableOpacity onPress={() => onPressChevron("right")}>
          <Icon name={IconNames.right} customStyle={styles.iconStyle} />
        </TouchableOpacity>
      </View>
      <View style={styles.monthListWrapper}>
        <FlatList
          extractor={(item) => item.id.toString()}
          listData={list}
          scrollEnabled={true}
          listContainerStyle={{ height: 200 }}
          listRenderer={(rowItem) => (
            <>
              <Checkbox
                key={rowItem.id}
                onPress={() => {
                  let final = {
                    label: rowItem?.label,
                    id: rowItem.id,
                    isChecked: rowItem.isChecked ? false : true
                  };
                  onPressItem(final as { label: string; id: number; isChecked: boolean });
                }}
                containerStyle={styles.checkBoxContainer}
                iconStyle={styles.checkBoxIcon}
                selected={rowItem.isChecked}
                text={rowItem.label}
                testId={rowItem.id}
                textSize={TextSize.ExtraRegular}
                type={CheckboxType.Square}
                iconSize={14}
              />
            </>
          )}
        />
      </View>
    </View>
  );
};

export const QuarterPickerMultiSelect = (props: Props) => {
  const {
    // setReset,
    reset,
    triggerComponent,
    // callBk,
    onPressButton,
    iconSize = 16,
    buttonProps
  } = props;
  const [dispYear, setDispYear] = useState<number>(new Date().getFullYear());
  const [dispQuarterList, setDispQuarterList] = useState<any>();
  const [selectedQuarter, setSelectedQuarter] =
    useState<Array<{ label: string; id: number; isChecked: boolean }>>(null);
  const [buttonFrame, setButtonFrame] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const triggerQuarterRef = useRef(null);
  const onDropdownOpen = (): void => {
    setOpen(!open);
  };
  useEffect(() => {
    if (reset) {
      setSelectedQuarter(null);
    }
  }, [reset]);

  const showQuarterModal = (): void => {
    setFrameMeasures(
      () => {
        setOpen(true);
      },
      triggerQuarterRef,
      setButtonFrame
    );
  };
  useEffect(() => {
    let dump = dispQuarterList;
    let final: any = [];
    dump?.map((item: any) => {
      if (item.isChecked) {
        final.push(item);
      }
    });
    onPressButton(final, dispYear);
    if (dispYear) {
      // setReset && setReset(false);
      // callBk({ month: final, year: dispYear });
    }
    // setSelectedQuarter(final)
  }, [selectedQuarter, dispYear]);
  // useEffect(() => {
  //   if (dispYear && selectedQuarter) {
  //     setReset && setReset(false);
  //     callBk({ month: selectedQuarter, year: dispYear });
  //   }
  // }, [dispYear, selectedQuarter]);
  useEffect(() => {
    if (dispYear && dispYear > 0) {
      if (reset) {
        let dump = [
          { label: "Quarter 1", id: 0, isChecked: false },
          { id: 1, label: "Quarter 2", isChecked: false },
          { id: 2, label: "Quarter 3", isChecked: false },
          { id: 3, label: "Quarter 4", isChecked: false }
        ];
        setDispQuarterList(dump);
      } else {
        setDispQuarterList(generateQuarterList(dispYear));
      }
    }
  }, [dispYear]);
  const iconPress = (dir: "right" | "left") => {
    let newYear = dispYear;
    if (dir === "right") {
      if (newYear + 1 <= new Date().getFullYear()) {
        newYear = newYear + 1;
      }
    } else {
      if (newYear - 1 >= 2000) {
        newYear = newYear - 1;
      }
    }
    setDispYear(newYear);
  };
  const handleButton = () => {
    onPressButton && onPressButton();
    showQuarterModal();
  };
  const handelSelectedQuarter = (val: any) => {
    let dump = dispQuarterList;
    dump[val?.id].isChecked = val?.isChecked;
    setDispQuarterList(dump);
    setSelectedQuarter(val);
  };

  return (
    <OverflowModal
      buttonFrame={buttonFrame}
      modalVisible={open}
      setModalState={onDropdownOpen}
      triggerComponent={
        triggerComponent ? (
          <TouchableOpacity ref={triggerQuarterRef}>
            <Button
              iconStyles={buttonProps.iconColorStyles}
              textStyles={buttonProps.textStyles}
              buttonStyles={buttonProps.styleButton}
              iconSize={iconSize}
              iconName={buttonProps.iconName}
              type={ButtonType.Secondary}
              title={buttonProps.title}
              onPress={handleButton}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={showQuarterModal} ref={triggerQuarterRef}>
            <Button
              type={ButtonType.Primary}
              title="Quarterly"
              iconName={IconNames.noneDone}
              buttonStyles={styles.buttonStyle}
            />
          </TouchableOpacity>
        )
      }
    >
      <UI
        centerDisp={dispYear.toString()}
        onPressItem={handelSelectedQuarter}
        list={dispQuarterList}
        onPressChevron={iconPress}
        setOpen={setOpen}
        reset={reset}
        selectedMonth={selectedQuarter}
      />
    </OverflowModal>
  );
};

const styles = StyleSheet.create({
  buttonIconStyle: {
    color: colorPallete.grey2
  },
  masterWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colorPallete.grey6,
    backgroundColor: colorPallete.white3,
    shadowColor: "rgba(0, 0, 0, 0.04)",
    shadowOffset: { width: 4, height: 6 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 10,
    flex: 1,
    flexDirection: "column"
  },
  iconStyle: {
    fontSize: 12,
    fontWeight: "900",
    color: colorPallete.grey1
  },
  headerWrapper: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center"
  },
  monthListWrapper: {
    flex: 1,
    marginTop: 20
  },
  individualMonthSelected: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 6,
    backgroundColor: colorPallete.variant1
  },
  individualMonthNotSelected: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 6,
    backgroundColor: null
  },
  selectedMonth: {
    // backgroundColor: "rgba(250, 250, 250, 0.85)"
  },
  buttonStyle: {
    backgroundColor: colorPallete.grey9,
    paddingVertical: 6,
    // paddingHorizontal: 12,
    borderColor: colorPallete.white1,
    borderWidth: 0,
    flex: 1,
    flexDirection: "row-reverse"
  },
  checkBoxContainer: {
    marginBottom: 14
  },
  checkBoxIcon: {
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
    // marginEnd: 10
  },
  textStyle: {
    color: colorPallete.black4,
    ...I18nService.select({
      rtl: { paddingEnd: 12 },
      ltr: { paddingStart: 12 }
    }),
    alignContent: "center"
  }
});
