const formatPayload = (data: any) => {
  let countryCodes = "";
  let len = data.countryCodes.length;
  data.countryCodes.forEach((item: any, index: number) => {
    if (index === len - 1) {
      countryCodes = countryCodes + item;
    } else {
      countryCodes = countryCodes + item + ",";
    }
  });

  let obj = {
    countryCodes: countryCodes,
    pageSize: data.pageSize,
    pageNo: data.pageNo
  };

  return obj;
};

const formatManagerData = (data: any) => {
  let obj: any = {
    content: []
  };
  data.content.forEach((item: any) => {
    let tempObj = {
      label: item.name,
      role: item.roleName,
      employeeeId: item.employeeId,
      roleId: item.roleId
    };

    obj.content.push(tempObj);
  });
  return obj;
};

const formatEmployee = (data: any) => {
  let obj: any = {
    content: []
  };
  data.forEach((item: any) => {
    let tempObj = {
      label: item.employeeId
    };
    obj.content.push(tempObj);
  });
  return obj;
};

const formatEventsData = (eventsData: any) => {
  // format data and tiime
  let formattedData: any = [];

  eventsData.forEach((item1: any, index) => {
    item1.eventDetailsResponseDtoList.forEach((item: any) => {
      let obj: any = {
        title: "",
        start: new Date(),
        end: new Date(),
        type: "",
        id: "",
        location: "",
        note: "",
        employeeList: [],
        restaurantList: [],
        show: true,
        startTime: "",
        endTime: "",
        eventName: ""
      };
      let startTiming = item.startTime.split(":");
      let endTime = item.endTime.split(":");
      let dateSplit = item1.date.split("-");
      obj.start = new Date(
        dateSplit[0],
        dateSplit[1] - 1,
        dateSplit[2],
        startTiming[0],
        startTiming[1],
        startTiming[2]
      );
      obj.end = new Date(
        dateSplit[0],
        dateSplit[1] - 1,
        dateSplit[2],
        endTime[0],
        endTime[1],
        endTime[2]
      );
      obj.title = item.eventName;
      obj.type = item.category;

      obj.id = item.id;
      obj.location = item.location;
      obj.startTime = item.startTime;
      obj.endTime = item.endTime;
      formattedData.push(obj);
      obj.eventName = item.eventName;
      obj.note = item.note;
    });
  });
  return formattedData;
};

function formatEvent(item: any) {
  let obj: any = {
    title: "",
    start: new Date(item.date),
    end: new Date(item.date),
    type: "",
    id: "",
    location: "",
    note: "",
    employeeList: [],
    restaurantList: [],
    show: true,
    startTime: "",
    endTime: "",
    eventName: "",
    countryResponseDtoList: []
  };
  let startTiming = item.startTime.split(":");
  let endTime = item.endTime.split(":");
  let dateSplit = item.date.split("-");

  obj.title = item.eventName;
  obj.type = item.category;
  obj.employeeList = item.employeeResponseDtoList;
  obj.restaurantList = item.restaurantResponseDtoList;
  obj.id = item.id;
  obj.location = item.location;
  obj.startTime = item.startTime;
  obj.endTime = item.endTime;
  obj.eventName = item.eventName;
  obj.note = item.note;
  obj.countryResponseDtoList = item.countryResponseDtoList;

  return obj;
}

function formatStores(data: any) {
  let response: any = [];
  data.content.forEach((item: any) => {
    let obj = {
      label: item.name,
      storeCode: item.code,
      city: item.city,
      channel: item.channel
    };
    response.push(obj);
  });
  return response;
}

export {
  formatPayload,
  formatManagerData,
  formatEmployee,
  formatEventsData,
  formatStores,
  formatEvent
};
