import React, { useState, useEffect } from "react";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { StyleSheet, View, StyleProp, ViewStyle, TouchableOpacity, TextStyle } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Checkbox, CheckboxType } from "@timhortons/common/src/components/atoms/checkBox";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
interface IProps {
  title?: string;
  sortable?: boolean;
  sortFunction?: (val: string, columnName: string) => void;
  fontWeight?: FontWeight;
  textSize?: TextSize;
  wrapperStyles?: StyleProp<ViewStyle>;
  cellStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  sortIconStyle?: StyleProp<TextStyle>;
  columnName?: string;
  columnSelected?: boolean;
  clickable?: boolean;
  headerCheckBox?: boolean;
  toggleHeaderCheckBox?: (val: boolean) => void;
  checkboxContainer?: StyleProp<ViewStyle>;
  disabled?: boolean;
}
export const HeaderCell = (props: IProps): React.ReactElement => {
  const {
    title,
    sortable = false,
    sortFunction,
    columnName,
    cellStyle,
    titleStyle,
    sortIconStyle,
    fontWeight = FontWeight.Regular,
    textSize = TextSize.ExtraRegular,
    columnSelected = true,
    wrapperStyles,
    clickable = false,
    headerCheckBox,
    toggleHeaderCheckBox,
    checkboxContainer,
    disabled = false
  } = props;
  const [sortIconUp, setSortIconUp] = useState<boolean>(true);
  const [sortIconDown, setSortIconDown] = useState<boolean>(true);
  const [sortIconUpColor, setSortIconUpColor] = useState<IconNames>(IconNames.sortUpUnfilled);
  const [sortIconDownColor, setSortIconDownColor] = useState<IconNames>(IconNames.filledDown);
  const [selectAll, setSelectAll] = useState<boolean>(headerCheckBox);

  function sortHeaderClick() {
    setSortIconUp(!sortIconUp);
    setSortIconDown(!sortIconDown);
    if (sortIconUp) {
      changeSortOrder(IconNames.sortUpFilled, IconNames.sortDownUnfilled, "asc", columnName);
    } else {
      changeSortOrder(IconNames.sortUpUnfilled, IconNames.filledDown, "desc", columnName);
    }
  }
  const toggleHandler = (status: boolean) => {
    setSelectAll(status);
    toggleHeaderCheckBox && toggleHeaderCheckBox(status);
  };

  useEffect(() => {
    setSelectAll(headerCheckBox);
  }, [headerCheckBox]);

  function changeSortOrder(
    ascIcon: IconNames,
    descIcon: IconNames,
    sortOrder: string,
    columnHeaderName: string
  ) {
    setSortIconUpColor(ascIcon);
    setSortIconDownColor(descIcon);
    sortFunction(sortOrder, columnHeaderName);
  }
  useEffect(() => {
    if (!columnSelected) {
      setSortIconUp(true);
      setSortIconDown(true);
      setSortIconDownColor(IconNames.sortDownUnfilled);
      setSortIconUpColor(IconNames.sortUpUnfilled);
    }
  }, [columnSelected, columnName]);

  function getContent() {
    return (
      <View style={[{ flexDirection: "row" }, wrapperStyles]}>
        {clickable && (
          <Checkbox
            disabled={disabled}
            testId={"box"}
            onPress={() => toggleHandler(!selectAll)}
            selected={selectAll}
            type={CheckboxType.Square}
            iconSize={18}
            containerStyle={[styles.checkBoxStyle, checkboxContainer]}
          />
        )}
        <Text
          fontWeight={fontWeight}
          textSize={textSize}
          testId="txt-table-header-cell"
          textStyle={[styles.text, titleStyle]}
        >
          {title}
        </Text>
        {sortable && (
          <View style={styles.iconContainer}>
            <Icon
              size={12}
              name={sortIconUpColor}
              color={colorPallete.grey3}
              customStyle={[styles.icon, sortIconStyle]}
            />
            <Icon
              name={sortIconDownColor}
              size={12}
              color={colorPallete.grey3}
              customStyle={[styles.icon, sortIconStyle]}
            />
          </View>
        )}
      </View>
    );
  }

  return sortable ? (
    <TouchableOpacity style={[styles.container, cellStyle]} onPress={sortHeaderClick}>
      {getContent()}
    </TouchableOpacity>
  ) : (
    <View style={[styles.container, cellStyle]}>{getContent()}</View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12
  },
  text: {
    color: colorPallete.grey1
  },
  iconContainer: {
    flexDirection: "column"
  },
  icon: {
    marginHorizontal: 4
  },
  checkBoxStyle: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    })
  }
});
