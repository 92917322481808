import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { UserLmsRepository } from "@timhortons/common/src/repositories/userLms/userLms";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import {
  ILearningPathParams,
  ILmsModuleList,
  IModuleDetailParams,
  UserLMSAction
} from "@timhortons/common/src/redux/userLms/actions/userLms";
import {
  VideoUpdateProps,
  MobileVideoActions
} from "@timhortons/common/src/redux/userLms/actions/videoActions";
import { Logger } from "@timhortons/common/src/services/logger";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  LMSCertificateCarousel,
  LMSModuleListModel,
  LMSContinueLearningModel,
  LMSTopicSubtopicModel,
  LMSUserCategoriesModel,
  LMSVideoUpdateModel,
  LMSTotalDaysLeftModel,
  LMSQuizDetailDataModel,
  LMSGetQuizTableModel,
  LMSPostQuizResponseModel,
  FAQsModel,
  NewsListModel,
  NewsDetailModel,
  LearningPathDetailsModel,
  StatisticsModel,
  PublishModuleModel,
  ModuleDetailModel,
  LMSModuleData,
  TotalDaysLeftModel,
  EditProfileModel
} from "@timhortons/common/src/models/userLms/userLms";
import { IFAQSearchResult } from "@timhortons/common/src/redux/userLms/reducers/userLms";
import { ProfileRepository } from "@timhortons/common/src/repositories/profile/profile";

function* getLmsModulesListWorkerSaga(value: IFluxStandardAction<ILmsModuleList>) {
  try {
    const { payload } = value;
    const response: LMSModuleData = yield call(UserLmsRepository.fetchLmsModulesList, payload);
    yield put(UserLMSAction.getLmsModulesListSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getLmsModulesListWorkerSaga");

    yield put(UserLMSAction.getLmsModulesListError(error));
  }
}

function* getCertificateCarouselWorkerSaga() {
  try {
    let response: Array<LMSCertificateCarousel[]> = yield call(
      UserLmsRepository.fetchCarouselCertificate
    );
    yield put(UserLMSAction.getCertificateCarouselSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getCertificateCarousel");
  }
}

function* getContinueLearningWorkerSaga(
  value: IFluxStandardAction<{ language: string; employeeId: string }>
) {
  try {
    const { payload } = value;
    const response: LMSContinueLearningModel = yield call(
      UserLmsRepository.getContinueLearning,
      payload
    );

    yield put(MobileVideoActions.getContinueLearningActionSuccess(response));
  } catch (error) {
    if (error !== "No Video in In-Progress") {
      Logger.error("userLms", error, "getContinueLearning");
    }
    yield put(MobileVideoActions.getContinueLearningActionError(error));
  }
}

function* getLmsEarnedPointsWorkerSaga(value: IFluxStandardAction<{ employeeId: string }>) {
  try {
    const { payload } = value;
    const response: { data: number } = yield call(
      UserLmsRepository.fetchTotalEarnedPoints,
      payload
    );

    yield put(UserLMSAction.getTotalEarnedPointsSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getLmsEarnedPointsWorkerSaga");

    yield put(UserLMSAction.getTotalEarnedPointsError(error));
  }
}

function* getTopicSubtopicWorkerSaga(
  value: IFluxStandardAction<{
    categoryId: string;
    empId: string;
    language: string;
  }>
) {
  try {
    const { payload } = value;
    const response: LMSTopicSubtopicModel = yield call(
      UserLmsRepository.fetchTopicSubtopic,
      payload
    );
    yield put(UserLMSAction.getTopicSubtopicSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getTopicSubtopicWorkerSaga");

    yield put(UserLMSAction.getTopicSubtopicError(error));
  }
}

function* getCategoriesWorkerSaga(
  value: IFluxStandardAction<{ employeeId: string; moduleId: string; language: string }>
) {
  try {
    const { payload } = value;
    const response: LMSUserCategoriesModel = yield call(UserLmsRepository.getCategories, payload);
    yield put(UserLMSAction.getModuleCategoriesSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getCategoriesWorkerSaga");

    yield put(UserLMSAction.getModuleCategoriesError(error));
  }
}

function* getQuizDetailsWorkerSaga(value: IFluxStandardAction<LMSQuizDetailDataModel>) {
  try {
    const { payload } = value;
    const response: LMSGetQuizTableModel = yield call(UserLmsRepository.getQuizDetails, payload);
    yield put(UserLMSAction.getQuizDetailsSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getQuizDetailsWorkerSaga");

    yield put(UserLMSAction.getQuizDetailsError(error));
  }
}
function* updateVideoWorkerSaga(value: IFluxStandardAction<VideoUpdateProps>) {
  try {
    const { payload } = value;
    const response: LMSVideoUpdateModel = yield call(UserLmsRepository.updateVideoStatus, payload);
    yield put(MobileVideoActions.updateVideoStatusSuccess(response));
  } catch (error) {
    yield put(MobileVideoActions.updateVideoStatusError(error));
  }
}

function* postQuizResponseWorkerSaga(value: IFluxStandardAction<LMSQuizDetailDataModel>) {
  try {
    const { payload } = value;
    const response: LMSPostQuizResponseModel = yield call(
      UserLmsRepository.postQuizDetails,
      payload
    );
    yield put(UserLMSAction.postQuizResponseSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "postQuizResponseWorkerSaga");

    yield put(UserLMSAction.postQuizResponseError(error));
  }
}

function* getFaqsWorkerSaga() {
  try {
    const response: FAQsModel[] = yield call(UserLmsRepository.fetchFaqs);
    yield put(UserLMSAction.getFaqsSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getFaqsWorkerSaga");
    yield put(UserLMSAction.getFaqsError(error));
  }
}

function* getFaqSearchResultWorkerSaga(
  value: IFluxStandardAction<{ searchText: string; pageNo: number }>
) {
  try {
    const { payload } = value;

    const response: IFAQSearchResult = yield call(UserLmsRepository.fetchFaqSearchResult, payload);
    yield put(UserLMSAction.getFaqSearchResultSuccess(response, payload.pageNo));
  } catch (error) {
    Logger.error("userLms", error, "getFaqSearchResultWorkerSaga");

    yield put(UserLMSAction.getFaqSearchResultError(error));
  }
}

function* postQuestionWorkerSaga(value: IFluxStandardAction<{ employeeId: string; body: any }>) {
  try {
    const { payload } = value;
    const response = yield call(UserLmsRepository.postQuestion, payload);
    yield put(UserLMSAction.postQuestionSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "postQuestionWorkerSaga");

    yield put(UserLMSAction.postQuestionError(error));
  }
}

function* getEnquiriesWorkerSaga(
  value: IFluxStandardAction<{ employeeId: string; status: string }>
) {
  try {
    const { payload } = value;

    const response: FAQsModel[] = yield call(UserLmsRepository.fetchEnquiries, payload);
    yield put(UserLMSAction.getEnquiriesSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getFaqSearchResultWorkerSaga");

    yield put(UserLMSAction.getEnquiriesError(error));
  }
}

function* getNewsListWorkerSaga(
  value: IFluxStandardAction<{
    filter: string;
  }>
) {
  const { payload } = value;
  try {
    const response: NewsListModel[] = yield call(UserLmsRepository.fetchNewsList, payload);
    yield put(UserLMSAction.getNewsListSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getNewsListWorkerSaga");
    yield put(UserLMSAction.getNewsListError(error));
  }
}

function* getNewsDetailsWorkerSaga(
  value: IFluxStandardAction<{
    newsId: string;
  }>
) {
  const { payload } = value;
  try {
    const response: NewsDetailModel = yield call(UserLmsRepository.fetchNewsDetails, payload);
    yield put(UserLMSAction.getNewsDetailsSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getNewsDetailsWorkerSaga");

    yield put(UserLMSAction.getNewsDetailsError(error));
  }
}

function* getlearningPathDetailsWorkerSaga(value: IFluxStandardAction<ILearningPathParams>) {
  const { payload } = value;
  try {
    const response: LearningPathDetailsModel[] = yield call(
      UserLmsRepository.fetchLearningPathDetails,
      payload
    );
    yield put(UserLMSAction.getLearningPathDetailsSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getlearningPathDetailsWorkerSaga");

    yield put(UserLMSAction.getLearningPathDetailsError(error));
  }
}

function* getStatisticsWorkerSaga(
  value: IFluxStandardAction<{
    employeeId: string;
  }>
) {
  const { payload } = value;
  try {
    const response: StatisticsModel = yield call(UserLmsRepository.fetchStatistics, payload);
    yield put(UserLMSAction.getStatisticsSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getStatisticsWorkerSaga");

    yield put(UserLMSAction.getStatisticsError(error));
  }
}

function* getSpecialModulesWorkerSaga(
  value: IFluxStandardAction<{
    employeeId: string;
  }>
) {
  const { payload } = value;
  try {
    const response: PublishModuleModel[] = yield call(
      UserLmsRepository.fetchSpecialModules,
      payload
    );
    yield put(UserLMSAction.getSpecialModulesSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getSpecialModulesWorkerSaga");

    yield put(UserLMSAction.getSpecialModulesError(error));
  }
}

function* getModuleDetailWorkerSaga(value: IFluxStandardAction<IModuleDetailParams>) {
  const { payload } = value;
  try {
    const response: ModuleDetailModel = yield call(UserLmsRepository.fetchModuleDetail, payload);
    yield put(UserLMSAction.getModuleDetailSuccess(response));
  } catch (error) {
    Logger.error("userLms", error, "getModuleDetailWorkerSaga");

    yield put(UserLMSAction.getModuleDetailError(error));
  }
}

function* getTotalDaysLeftWorkerSaga(value: IFluxStandardAction<{ employeeId: string }>) {
  try {
    const { payload } = value;
    const response: TotalDaysLeftModel = yield call(UserLmsRepository.getDaysLeft, payload);
    yield put(UserLMSAction.getTotalDaysLeftSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.getTotalDaysLeftError(error));
  }
}

function* editUserProfileWorkerSaga(value: IFluxStandardAction<EditProfileModel>) {
  try {
    const { payload } = value;
    let imageResponse: any;
    if (payload.imageChange && payload.selectedImage) {
      let postFormData = new FormData();
      postFormData.append("image", payload.selectedImage);
      imageResponse = yield call(ProfileRepository.postUserImageMobile, postFormData);
    }
    // let img = imageResponse ? imageResponse.profileUrl : payload.selectedImage?.uri ? payload.selectedImage.uri: null;
    let img = imageResponse ? imageResponse.profileUrl : payload.profileKey;
    delete payload["selectedImage"];
    delete payload["imageChange"];
    delete payload["profileKey"];
    let obj = {
      ...payload,
      profileUrl: img
    };
    const response: any = yield call(UserLmsRepository.editUserProfile, obj);
    yield put(UserLMSAction.editUserProfileSuccess(response));
  } catch (error) {
    yield put(UserLMSAction.editUserProfileError(error));
  }
}

function* userLmsWatcherSaga() {
  yield takeLatest(UserLMS.GET_LMS_MODULE_LIST.LOADING, getLmsModulesListWorkerSaga);
  yield takeLatest(UserLMS.GET_LMS_CERTIFICATE_CAROUSEL.LOADING, getCertificateCarouselWorkerSaga);
  yield takeEvery(UserLMS.GET_CONTINUE_LEARNING.LOADING, getContinueLearningWorkerSaga);
  yield takeLatest(UserLMS.GET_TOTAL_EARNED_POINTS.LOADING, getLmsEarnedPointsWorkerSaga);
  yield takeEvery(UserLMS.GET_TOPICS_SUBTOPICS.LOADING, getTopicSubtopicWorkerSaga);
  yield takeLatest(UserLMS.GET_CATEGORIES.LOADING, getCategoriesWorkerSaga);
  yield takeEvery(UserLMS.GET_QUIZ_DETAILS.LOADING, getQuizDetailsWorkerSaga);
  yield takeLatest(UserLMS.UPDATE_VIDEO_STATUS.LOADING, updateVideoWorkerSaga);
  yield takeEvery(UserLMS.POST_QUIZ_RESPONSE.LOADING, postQuizResponseWorkerSaga);
  yield takeEvery(UserLMS.GET_FAQS.LOADING, getFaqsWorkerSaga);
  yield takeEvery(UserLMS.GET_FAQ_SEARCH_RESULT.LOADING, getFaqSearchResultWorkerSaga);
  yield takeEvery(UserLMS.GET_ENQUIRIES.LOADING, getEnquiriesWorkerSaga);
  yield takeEvery(UserLMS.POST_QUESTION.LOADING, postQuestionWorkerSaga);
  yield takeEvery(UserLMS.GET_NEWS_LIST.LOADING, getNewsListWorkerSaga);
  yield takeEvery(UserLMS.GET_NEWS_DETAILS.LOADING, getNewsDetailsWorkerSaga);
  yield takeEvery(UserLMS.GET_LEARNING_PATH_DETAILS.LOADING, getlearningPathDetailsWorkerSaga);
  yield takeEvery(UserLMS.GET_STATISTICS.LOADING, getStatisticsWorkerSaga);
  yield takeEvery(UserLMS.GET_SPECIAL_MODULES.LOADING, getSpecialModulesWorkerSaga);
  yield takeEvery(UserLMS.GET_MODULE_DETAIL.LOADING, getModuleDetailWorkerSaga);
  yield takeEvery(UserLMS.GET_TOTAL_DAYS_LEFT.LOADING, getTotalDaysLeftWorkerSaga);
  yield takeEvery(UserLMS.EDIT_PROFILE.LOADING, editUserProfileWorkerSaga);
}

export default userLmsWatcherSaga;
