import { MANAGE_EXISTING_USER } from "@timhortons/common/src/redux/manageExistingUser/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";

const getExistingUsers = (data: { employeeId: string }): IFluxStandardAction<{}> => {
  return {
    type: MANAGE_EXISTING_USER.GET_EXISTING_USERS.LOADING,
    payload: data
  };
};
const getExistingUsersSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGE_EXISTING_USER.GET_EXISTING_USERS.SUCCESS,
    payload: data
  };
};
const getExistingUsersError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGE_EXISTING_USER.GET_EXISTING_USERS.ERROR,
    payload: error
  };
};

const onBoardExistingUser = (data: { employeeId: string; body: any }): IFluxStandardAction<any> => {
  return {
    type: MANAGE_EXISTING_USER.ONBOARD_EXISTING_USERS.LOADING,
    payload: data
  };
};
const onBoardExistingUserSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: MANAGE_EXISTING_USER.ONBOARD_EXISTING_USERS.SUCCESS,
    payload: data
  };
};

const onBoardExistingUserError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGE_EXISTING_USER.ONBOARD_EXISTING_USERS.ERROR,
    payload: error
  };
};

export const ManageExistingUsersAction = {
  getExistingUsers,
  getExistingUsersSuccess,
  getExistingUsersError,
  onBoardExistingUser,
  onBoardExistingUserSuccess,
  onBoardExistingUserError
};
