import { combineReducers } from "redux";
import {
  IReportsBuilderState,
  ReportsBuilderReducer
} from "@timhortons/common/src/redux/reportBuilder/reducers/reportsReducer";

export interface IReportBuilderState {
  reportsBuilder: IReportsBuilderState;
}

export const reportBuilderReducer = combineReducers({
  reportsBuilder: ReportsBuilderReducer
});
