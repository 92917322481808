import { REVKeys } from "@timhortons/common/src/constants/apiKeys";
import {
  AppealCountModel,
  SetAppealDecisionModel,
  AppealsAccordionDataModel,
  NewAppealsTable,
  // InProcessAppealTableModel,
  AppealsCategoryDataModel,
  PostAppealCommentsModel,
  AppealCommentsDataModel,
  InProcesssClosedTable
} from "@timhortons/common/src/models/rev/appeals";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { appealsEndPoints, appealEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ICreateNewAppeals } from "@timhortons/common/src/modules/rev/components/dashboards";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import { validateAuthModel } from "@timhortons/common/src/repositories/rev/selfCalibration";

class AppealRepository {
  setAppealDecision = async (param: SetAppealDecisionModel) => {
    let headers = {
      ...REVKeys.APPEALS,
      "Content-Type": "multipart/form-data"
    };
    return ApiClient.post(appealsEndPoints.setAppealDecision(), param, null, headers);
  };
  postAppealsCommentData = async (param: { body: PostAppealCommentsModel; employeeId: string }) => {
    let headers = {
      ...REVKeys.APPEALS,
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      appealsEndPoints.postAppealItem(param.employeeId),
      param.body,
      null,
      headers
    );
    return deserialize(AppealCommentsDataModel, data);
  };
  createAppeal = async (data: ICreateNewAppeals) => {
    let validateStore = await validateAuthModel(
      data.isIndividualLogin,
      data.auditorId,
      data.storeCode,
      REVKeys.APPEALS
    );
    let finalData = {
      auditorId: data.auditorId,
      selfCalibrationId: data.selfCalibrationId
    };
    let response;
    if (validateStore) {
      response = await ApiClient.post(
        appealsEndPoints.createNewAppeal(),
        finalData,
        null,
        REVKeys.APPEALS
      );
    }
    return { status: "success", data: response };
  };
  getAppealsCount = async (param: { appealerId: string; isIndividual: boolean }) => {
    const { appealerId, isIndividual } = param;
    let response = await ApiClient.get(
      isIndividual
        ? appealEndPoints.getAppealsCountIndividual(appealerId)
        : appealEndPoints.getAppealsCountStore(appealerId),
      null,
      REVKeys.APPEALS
    );
    return deserialize(AppealCountModel, response);
  };
  getAppealAccordionData = async (param: { appealId: number; categoryId: number }) => {
    let response = await ApiClient.get(
      appealsEndPoints.getAppealAccordionData(param.appealId, param.categoryId),
      null,
      REVKeys.APPEALS
    );
    return response.map((item: AppealsAccordionDataModel) =>
      deserialize(AppealsAccordionDataModel, item)
    );
  };

  getNewAppeals = async (param: { storeId: number; status: string }) => {
    const { storeId, status } = param;
    let data = await ApiClient.get(
      appealEndPoints.getAppealList(storeId, status),
      null,
      REVKeys.APPEALS
    );
    let appeals: NewAppealsTable[] = [];
    data.map((item: NewAppealsTable) => {
      let deserializevar: NewAppealsTable = deserialize(NewAppealsTable, item);
      appeals.push(deserializevar);
    });
    return appeals;
  };
  getInProcessAppeals = async (param: { storeId: number; status: string }) => {
    const { storeId, status } = param;
    let data = await ApiClient.get(
      appealEndPoints.getAppealList(storeId, status),
      null,
      REVKeys.APPEALS
    );
    let appeals: InProcesssClosedTable[] = [];
    data.map((item: InProcesssClosedTable) => {
      let deserializevar: InProcesssClosedTable = deserialize(InProcesssClosedTable, item);
      appeals.push(deserializevar);
    });
    return appeals;
  };

  getAppeals = async (param: { userId: number; selectedValues: any }) => {
    const { userId } = param;
    let fromDate, toDate;
    if (param.selectedValues.fromDate !== null && param.selectedValues.toDate !== null) {
      fromDate = convert(param.selectedValues.fromDate);
      toDate = convert(param.selectedValues.toDate);
    }
    let search = param.selectedValues.search === null ? null : param.selectedValues.search;
    let object: any = {
      appealStatus: param.selectedValues.appealStatus
    };
    let data;
    if (fromDate !== null) {
      object = {
        ...object,
        fromDate: fromDate,
        toDate: toDate
      };
    }
    if (param.selectedValues.search !== null) {
      object = {
        ...object,
        searchByKeyword: search
      };
    }
    if (param.selectedValues.appealStatus === "CLOSED" && param.selectedValues.status !== null) {
      object = {
        ...object,
        appealItemStatus: param.selectedValues.status
      };
    }
    data = await ApiClient.post(
      appealEndPoints.fetchAppealFilteredAPI(userId),
      {
        ...object
      },
      null,
      REVKeys.APPEALS
    );
    return data;
  };
  getAppealsCategoryData = async (param: { appealId: number }) => {
    const { appealId } = param;
    let data = await ApiClient.get(
      appealsEndPoints.getAppealsCategoryData(appealId),
      null,
      REVKeys.APPEALS
    );
    return deserialize(AppealsCategoryDataModel, data);
  };
}
const appealRepository = new AppealRepository();
export { appealRepository as AppealRepository };
