import React, { useEffect, useState } from "react";
import { View, ScrollView, LayoutChangeEvent } from "react-native";
import { TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { Cell, Row, RowType, Table } from "@timhortons/common/src/components/molecules/table";
import ModalDropdown, {
  IDropDownItem
} from "@timhortons/common/src/components/molecules/modalDropdown";
import { styles } from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable/styles";
import ProductionSheetLeft from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable/productionSheetLeft";
import ProductionSheetRight from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable/productionSheetRight";
import { StationProducts } from "@timhortons/common/src/models/productionSheet/psModels";
import { IProductionSheetLoading } from "@timhortons/common/src/redux/productionSheet/reducers/productionSheet";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";

interface IProps {
  translate: any;
  tableData?: StationProducts[];
  dropdownData?: IDropDownItem[];
  storeHeader?: IHeaderList[];
  postCallBack?: Function;
  viewAccess?: boolean;
  setClickedCategory?: Function;
  loading?: IProductionSheetLoading;
  clickedCategory?: string;
  query: any;
}

export interface IHeaderList {
  id: number;
  label: string;
  isActive: boolean;
}
export interface GetSubCategoryPayload {
  storeCode: number;
  categoryId: string;
  fromManagerReview: boolean;
  dateTime?: string;
}
export interface IRowList {
  id: string;
  value: string;
}

export interface IProductList {
  id: number;
  productName: string;
  productValues: IRowList[];
}

export interface ITableDataList {
  id: string;
  name: string;
  shelfLife: number;
  productList: IProductList[];
}

export enum HeaderLabels {
  total = "Total Baked",
  endOfDayThrows = "End of Day Throws",
  dailyWaste = "Daily Waste",
  totalWaste = "Total Waste",
  quantityPrepared = "Quantity Prepared",
  quantityWasted = "Quantity Wasted",
  count = "Count"
}

const getAccordionExpand = (length: number) => {
  let arr = [];
  for (let i = 0; i < length; i++) {
    arr.push({
      isExpanded: false
    });
  }
  return arr;
};

let headerHeights: number[] = [];

export default function ProductionSheetTable(props: IProps) {
  const {
    translate,
    tableData,
    dropdownData,
    storeHeader,
    postCallBack,
    viewAccess,
    setClickedCategory,
    loading,
    clickedCategory,
    query
  } = props;
  const [selectedProduct, setSelectedProduct] = useState<IDropDownItem>(null);
  const [tableHeaders, setTableHeaders] = useState<IHeaderList[]>(null);
  const [dropdownDataList, setDropdownDataList] = useState<IDropDownItem[]>(dropdownData);
  const [tableDataList, setTableDataList] = useState<StationProducts[]>(tableData);
  const [accordionExpand, setAccordionExpand] = useState(getAccordionExpand(tableData?.length));
  const [headerCellHeight, setHeaderCellHeight] = useState(
    new Array(tableDataList?.length).fill(48)
  );
  const [headerHeight, setHeaderHeight] = useState(48);
  useEffect(() => {
    if (storeHeader) {
      setTableHeaders(storeHeader);
    }
  }, [storeHeader]);

  useEffect(() => {
    if (dropdownData) {
      setDropdownDataList(dropdownData);
    }
  }, [dropdownData]);

  // useEffect(() => {
  //   let val = tableDataList.length > 1;
  //   setShowArrowIcon(val);
  // }, [tableDataList]);

  useEffect(() => {
    if (tableData) {
      setTableDataList(tableData);
      setAccordionExpand(getAccordionExpand(tableData.length));
    }
  }, [tableData]);

  const handleDropdownChange = (option: IDropDownItem) => {
    setSelectedProduct(option);
    if (option.value === "all products") {
      setTableDataList(tableData);
    } else {
      let arr = tableData.filter((i) => i.name === option.label);
      setTableDataList(arr);
      setClickedCategory(option.value);
    }
  };

  const handleAccordionExpand = (name: string) => {
    let id = tableDataList?.find((item) => item.name === name);
    let index = tableDataList.findIndex((item) => item.name === name);
    if (index > -1) {
      let temp = [...accordionExpand];
      temp[index].isExpanded = !temp[index].isExpanded;
      setAccordionExpand(temp);
    }
    id && setClickedCategory(id.id);
  };

  function handleheaderHeight(index: number, height: number) {
    let temp = [...headerCellHeight];
    temp[index] = height;
    setHeaderCellHeight(temp);
    headerHeights[index] = height;
  }

  function sendHeightToRight(index: number) {
    return headerHeights[index];
  }
  const headerHeightCal = (e: LayoutChangeEvent) => {
    setHeaderHeight(e.nativeEvent.layout.height);
  };
  let activeTime = new Date().toLocaleTimeString("en-US", { hour12: true, hour: "2-digit" });
  let hrs = activeTime.split(" ");
  if (hrs[0].length === 1) {
    activeTime = "0" + activeTime;
  }

  let activeCell = tableHeaders && tableHeaders.findIndex((i) => i.label === activeTime);
  return (
    <View style={styles.container}>
      {tableHeaders !== null && tableDataList !== null && tableDataList?.length > 0 ? (
        <Table>
          <Row>
            <Row rowType={RowType.Column}>
              <Row>
                <View style={styles.dropdownHeight} onLayout={headerHeightCal}>
                  <Cell cellStyle={styles.headerButtonCell}>
                    <ModalDropdown
                      allItems={dropdownDataList}
                      selectedValueDefault={selectedProduct?.value}
                      defaultDropdownText={translate("All Products")}
                      onChangeCallBack={handleDropdownChange}
                      dropdownViewStyle={styles.dropDownViewStyle}
                      customSelectStyle={styles.customSelectStyle}
                      selectedItemStyle={styles.customSelectStyle}
                      dropdownIconStyle={styles.customSelectStyle}
                      numberOfLines={2}
                    />
                  </Cell>
                </View>
              </Row>
              {tableDataList &&
                tableDataList.map((item: StationProducts, index) => (
                  <ProductionSheetLeft
                    tableData={item}
                    key={index}
                    showArrowIcon={true}
                    accordionExpand={accordionExpand[index].isExpanded}
                    setAccordionExpand={handleAccordionExpand}
                    headerHeightCallBack={handleheaderHeight}
                    index={index}
                    loading={loading?.getProductItems}
                    item={item}
                    clickedCategory={clickedCategory}
                    translate={translate}
                  />
                ))}
            </Row>

            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
              <Row rowType={RowType.Column}>
                <Row>
                  {tableHeaders &&
                    tableHeaders.map((item, index) => (
                      <View style={{ height: headerHeight }}>
                        <Cell
                          key={index}
                          title={item.label}
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraSmall}
                          titleStyle={
                            query?.view
                              ? null
                              : activeTime === item.label && styles.customSelectStyle
                          }
                          cellStyle={[
                            styles.headerRowStyle,
                            // styles.headerCellHeight,
                            query?.view ? null : activeTime === item.label && styles.activeCell
                          ]}
                        />
                      </View>
                    ))}
                </Row>
                {tableDataList &&
                  tableDataList?.map((item: StationProducts, index) => {
                    return (
                      <View pointerEvents={viewAccess ? "none" : null}>
                        <ProductionSheetRight
                          isMREditable={query?.view}
                          tableData={item}
                          key={index}
                          translate={translate}
                          accordionExpand={accordionExpand[index].isExpanded}
                          activeCell={activeCell}
                          headerCellHeight={sendHeightToRight(index)}
                          postCallBack={postCallBack}
                          loading={loading?.getProductItems}
                          item={item}
                          clickedCategory={clickedCategory}
                        />
                      </View>
                    );
                  })}
              </Row>
            </ScrollView>
          </Row>
        </Table>
      ) : (
        <Jumbotron type={ErrorType.NoDataFound} title={"No SubCategory Found"} />
      )}
    </View>
  );
}
// const styles = StyleSheet.create({
//   masterWrapper: { borderRadius: 8 },
//   masterColStyle1: {
//     flex: 0.7,
//     paddingVertical: 10,
//     borderWidth: 0.5,
//     borderStyle: "solid",
//     borderColor: colorPallete.grey11
//   },
//   masterColStyle2: {
//     flex: 1,
//     paddingVertical: 10,
//     borderWidth: 0.5,
//     borderStyle: "solid",
//     borderColor: colorPallete.grey11
//   },
//   backButton: {
//     width: 48,
//     height: 48,
//     backgroundColor: colorPallete.white1,
//     borderWidth: 0,
//     borderRadius: 50
//   },
//   backIcon: {
//     color: colorPallete.grey2
//   },
//   elementContainer: {
//     flexDirection: "row",
//     alignItems: "center"
//   }
// });
