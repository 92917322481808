import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { GetStoreListByCodeOrNamePayload } from "@timhortons/common/src/models/rev/selfCalibration";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import { ComparisonStoresAdminModel } from "@timhortons/common/src/models/profile";
import useFetchMoreItems from "@timhortons/common/src/utils/useFetchMoreItems";

interface IProps {
  getStoresLists: ComparisonStoresAdminModel;
  returnSelectedStore: Function;
  setOpenStoreModal: Function;
  setIsClicked: Function;
  getStoreListByStoreCodeOrName: (value: GetStoreListByCodeOrNamePayload) => void;
  user?: IUserDetails;
  setTextFeildValue: Function;
  setKeyword: Function;
  keyword: any;
}
export default function StoreSearchResults(props: IProps) {
  const {
    returnSelectedStore,
    setOpenStoreModal,
    setIsClicked,
    getStoreListByStoreCodeOrName,
    getStoresLists,
    user,
    setKeyword,
    setTextFeildValue,
    keyword
  } = props;

  const [storeListData, setStoreListData] = useState({
    allStores: []
  });
  const [selectedStore, setSelectedStore] = useState(null);
  const [storesPage, setStoresPage] = useState(0);
  const [storesFetch, setAllStoresFetch] = useState(false);
  // const [keyword, setKeyword] = useState("");
  const [value, setValue] = useState("");
  const isFocused = useFocusedHook();
  const getStores = (pageNo: number) => {
    if (pageNo === getStoresLists?.currentPage) {
      return getStoresLists && getStoresLists?.content;
    }
  };
  const storeFlatList = useFetchMoreItems(getStores, storesFetch, storesPage, setStoresPage);

  const handleSelectedStore = (item: any) => {
    returnSelectedStore(item.code, item.value);
    setStoresPage(0);
    setValue(item.label);
    setTextFeildValue(item.code);
    setKeyword(item.label);
    setOpenStoreModal(false);
    setStoreListData((prevState: any) => ({
      ...prevState,
      allStores: []
    }));
  };
  useEffect(() => {
    if (
      storesPage > 0 &&
      storesPage < getStoresLists?.totalElements / 10 &&
      getStoresLists?.content.length > 0
    ) {
      setStoreListData((prevState: any) => ({
        ...prevState,
        allStores: storeFlatList.arrayItems
      }));
    }
  }, [storeFlatList.arrayItems, storesPage]);

  useEffect(() => {
    if (getStoresLists?.content?.length > 0) {
      setOpenStoreModal(true);
    }
    if (getStoresLists && getStoresLists.currentPage === 0) {
      setAllStoresFetch(true);
      setStoresPage(0);
      setStoreListData((prevState) => ({
        ...prevState,
        allStores: getStoresLists.content
      }));
    }
  }, [getStoresLists]);

  useEffect(() => {
    if (
      storesPage < getStoresLists?.totalElements / 10 &&
      getStoresLists?.totalElements > 10 &&
      storesFetch
    ) {
      isFocused &&
        getStoreListByStoreCodeOrName({
          keyword: keyword,
          pageNo: storesPage,
          pageSize: 10,
          empId: user?.empId,
          role: user?.roles[0]
        });
    }
  }, [storesPage, isFocused]);
  useEffect(() => {
    if (
      keyword &&
      keyword?.length > 0 &&
      keyword !== value &&
      getStoresLists?.content?.length === 0 &&
      getStoresLists?.totalElements === 0 &&
      getStoresLists?.currentPage === 0
    ) {
      isFocused &&
        getStoreListByStoreCodeOrName({
          keyword: keyword,
          pageNo: storesPage,
          pageSize: 10,
          empId: user?.empId,
          role: user?.roles[0]
        });
    }
  }, [isFocused, keyword]);
  const listRenderer = (rowItem: any): ReactElement => {
    return (
      <TouchableOpacity
        onPress={() => {
          setIsClicked(true);
          setSelectedStore(rowItem);
          handleSelectedStore(rowItem);
        }}
        style={{ paddingHorizontal: 16 }}
      >
        <Text
          fontWeight={FontWeight.Regular}
          textSize={TextSize.ExtraSmall}
          textStyle={
            selectedStore?.name === rowItem?.value
              ? { color: colorPallete.red1 }
              : { color: colorPallete.grey3 }
          }
          testId={"storename"}
        >
          {rowItem.code} - {rowItem.label}
        </Text>
      </TouchableOpacity>
    );
  };
  return (
    <>
      <View style={styles.modal}>
        {storeListData.allStores.length === 0 ? (
          <Text
            testId="no stores"
            textStyle={{ textAlign: "center", paddingVertical: 16 }}
            fontWeight={FontWeight.Regular}
            textSize={TextSize.Regular}
          >
            No stores!
          </Text>
        ) : (
          <FlatList
            extractor={(item: any) => item?.code?.toString()}
            listData={storeListData.allStores}
            listContainerStyle={{ paddingVertical: 16, maxHeight: 150 }}
            listRenderer={(rowItem: any) => listRenderer(rowItem)}
            extraData={storeListData.allStores}
            showsVerticalScrollIndicator
            scrollEnabled
            onEndReached={storeFlatList.fetchMore}
            onEndReachedThreshold={0.2}
          />
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  listContainerStyle: { maxHeight: 300 },
  modal: {
    top: 290,
    width: 300,
    flex: 1,
    height: "auto",
    position: "absolute",
    left: 50,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 4
  }
});
