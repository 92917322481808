import { testIds } from "@timhortons/common/src/utils/formHelpers";
import {
  starPoor,
  starFair,
  starAverage,
  starHappy,
  starExcellent
} from "@timhortons/common/src/assets/images/imageAssets";

let starImgId = testIds.manageLms.starIdVersion;
let textFieldId = testIds.manageLms.starIdVersion;

export interface IStarRatingObj {
  id: string;
  title: string;
  image: any;
}

export interface IFeedbackTexts {
  key: number;
  id: string;
  name: string;
  feedbackPoint: string;
  isActive?: boolean;
}

export const starRatingArr: IStarRatingObj[] = [
  { id: `${starImgId}-poor`, title: "poor", image: starPoor },
  { id: `${starImgId}-fair`, title: "fair", image: starFair },
  { id: `${starImgId}-average`, title: "average", image: starAverage },
  { id: `${starImgId}-happy`, title: "happy", image: starHappy },
  { id: `${starImgId}-excellent`, title: "excellent", image: starExcellent }
];

export const feedbackValues: IFeedbackTexts[] = [
  {
    key: 1,
    id: `${textFieldId}-poor`,
    name: `${textFieldId}-poor`,
    feedbackPoint: "The information was difficult to understand."
  },
  {
    key: 2,
    id: `${textFieldId}-fair`,
    name: `${textFieldId}-fair`,
    feedbackPoint: "The content was not well organised and not easy to follow."
  },
  {
    key: 3,
    id: `${textFieldId}-average`,
    name: `${textFieldId}-average`,
    feedbackPoint: "The content was not enough to provide me the knowledge required."
  },
  {
    key: 4,
    id: `${textFieldId}-happy`,
    name: `${textFieldId}-happy`,
    feedbackPoint: "The videos were not interactive."
  }
];
