import { useState, useEffect, useCallback } from "react";
function useFetchMoreItems(
  fetchItems: Function,
  allowFetch: boolean,
  page: number,
  setPage: Function,
  resetFetch?: Function,
  isComparisonOrHighLow?: boolean,
  totalItems?: number,
  isBuilder?: boolean
) {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [arrayItems, setArrayItems] = useState([]);
  const fetchMore = useCallback(() => setShouldFetch(true), []);
  useEffect(() => {
    setShouldFetch(allowFetch);
    if (allowFetch) {
      setArrayItems([]);
      resetFetch && resetFetch(false);
    }
  }, [allowFetch]);

  useEffect(() => {
    if (!shouldFetch) {
      // setArrayItems([])
      return;
    }

    const fetch = async () => {
      // if(page===0){
      //   setArrayItems([])
      // }

      let newItems = await fetchItems(page, 10, isComparisonOrHighLow);
      setShouldFetch(false);
      if (newItems === undefined) {
        newItems = [];
      }
      if (JSON.stringify(arrayItems) === JSON.stringify(newItems)) {
        if (isBuilder) {
          newItems;
        } else {
          newItems = [];
        }
      }
      if (isBuilder) {
        if (page === 0) {
          setArrayItems(newItems);
        } else if (arrayItems.length < totalItems && page > 0) {
          setArrayItems((oldItems) => [...oldItems, ...newItems]);
        }
        if (page + 1 <= (totalItems - 1) / 10) {
          setPage(page + 1);
        }
      } else {
        setArrayItems((oldItems) => [...oldItems, ...newItems]);
        setPage(page + 1);
      }
    };

    fetch();
  }, [page, shouldFetch]);

  return { arrayItems, fetchMore };
}

export default useFetchMoreItems;
