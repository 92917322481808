import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Cell, Row } from "@timhortons/common/src/components/molecules/table";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { InProcesssClosedTable } from "@timhortons/common/src/models/rev/appeals";
import { AppealTable } from "@timhortons/common/src/modules/rev/components/dashboards/appeals";

interface IProps {
  translate: any;
  cellStyle: StyleProp<ViewStyle>;
  data: InProcesssClosedTable;
  index: number;
  viewIconStyle: StyleProp<ViewStyle>;
  iconOnPress: Function;
  toggleIcon: Array<any>;
  isIndividualLogin: boolean;
  currentTable?: AppealTable;
}

export default function Tableheader(props: IProps) {
  const {
    cellStyle,
    data,
    isIndividualLogin,
    index,
    viewIconStyle,
    iconOnPress,
    toggleIcon,
    currentTable
  } = props;

  return (
    <>
      <Row key={index}>
        <Cell cellStyle={cellStyle} title={data.storeCode} />
        <Cell cellStyle={cellStyle} title={data.storeName} />
        <Cell cellStyle={cellStyle} title={dateFormat(data?.appealDate)} />

        <Cell
          cellStyle={cellStyle}
          title={
            isIndividualLogin
              ? data.appealSubmittedByName
              : currentTable === AppealTable.inProcess
              ? data.appealSubmittedByName
              : data.auditorName
          }
        />
        {isIndividualLogin ? (
          <Cell cellStyle={[cellStyle, viewIconStyle]}>
            <Icon
              onPress={() => iconOnPress(index)}
              color={colorPallete.grey2}
              name={
                (index < toggleIcon.length &&
                  toggleIcon &&
                  toggleIcon[index].view &&
                  IconNames.eyeClose) ||
                IconNames.eyeView
              }
              size={16}
            />
          </Cell>
        ) : null}
      </Row>
    </>
  );
}
