import { ReportType } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportType";
import {
  BrandStandardKPIDataModel,
  BrandStandardListDataModel,
  Categories,
  CategoriesNameModel,
  FoodSafetyListDataModel,
  IndividualAccessSummaryDataModel,
  ReportsDataModal,
  ReportsSummaryOpportunityDataModel,
  StoreAccessSummaryDataModel,
  StoreRankingsTableData,
  ReportBuilderResponseModel
} from "@timhortons/common/src/models/rev/reports";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";

const deleteDuplicateElements = (arr1: any[], arr2: any[]) => {
  let lastData: any[] = arr2;
  let ids = new Set(arr1.map(({ storeCode }) => storeCode));
  lastData = lastData.filter(({ storeCode }) => !ids.has(storeCode));
  return lastData;
};

export const reportBuilderFormatter = async (
  metaData: any,
  response: any
): Promise<ReportBuilderResponseModel> => {
  let levelOfReport: string = "";
  let reportType: string = "";
  if (metaData.object.levelOfReport === "COUNTRY") {
    levelOfReport = "Country";
  } else if (metaData.object.levelOfReport === "COUNTRY_MANAGER") {
    levelOfReport = "Country Manager";
  } else if (metaData.object.levelOfReport === "AREA_MANAGER") {
    levelOfReport = "Area Manager";
  } else if (metaData.object.levelOfReport === "CHANNEL") {
    levelOfReport = "Channels";
  } else {
    levelOfReport = "Stores";
  }
  if (metaData.reportType === "DETAILED") {
    reportType = "Detailed";
  } else if (metaData.reportType === "COMPARISON") {
    reportType = "Comparison";
  } else {
    reportType = "Highest to Lowest";
  }
  let data: ReportBuilderResponseModel = {
    metaData: {
      startDate: metaData.object.startDate,
      endDate: metaData.object.endDate,
      reportType: reportType,
      levelOfReport: levelOfReport,
      reportEnum: metaData.object.levelOfReport,
      isKPI: metaData.object.isKPI ?? false
    },
    apiResponse: {
      content: [],
      totalElements: response.totalElements
    }
  };

  response &&
    response.content.map((item: any, index: number) => {
      let obj = {
        id: item.code ? item.code : item.employeeId,
        grade: item.grade,
        overallScore: item.overallScore,
        storeName: item.label,
        brandStandard: item.brandStandardScore,
        foodSafety: item.foodSafetyPoints?.toFixed(2),
        cleanliness: item.cmScore,
        guest: item.guestScore,
        sos: item.sosScore,
        profitability: item.psmScore,
        training: item.trainingScore,
        foodQuality: item.foodQualityScore,
        fc: "",
        dt: ""
      };
      data.apiResponse.content[index] = obj;
    });
  return data;
};

export const individualAccessSummaryFormatter = async (
  response: any
): Promise<IndividualAccessSummaryDataModel> => {
  let data: IndividualAccessSummaryDataModel;

  data = {
    actionPlansData: {
      series: [],
      labels: [],
      totalReports:
        response && response.actionPlanReport !== null && response.actionPlanReport.totalCount,
      inProcessReport: response && response.actionItemInProcessReport
    },
    averageScore: {
      name: [],
      percentage: []
    },
    fsDeductions: {
      deductions: [],
      stores: []
    },
    gradeDistribution: {
      label: [],
      storeCount: []
    },
    monthOnMonthAverageData: {
      month: [],
      percentage: []
    },
    opportunityData: [],
    storeRankingTopFiveData: [],
    storeRankingLastFiveData: [],
    brandStandardKPIData: [],
    areaManagerRankingsTableData: [] // TODO: write formatter for area manager ranking
  };

  response &&
    response.actionPlanReport &&
    response.actionPlanReport.map((el: any) => {
      data.actionPlansData.totalReports = data.actionPlansData.totalReports + el.counts;
      return {
        series: data.actionPlansData.series.push(el.counts),
        labels: data.actionPlansData.labels.push(
          el.label === "COMPLETED" ? "Completed Action" : "Pending Action"
        )
      };
    });

  response &&
    response.averageScoreReport &&
    response.averageScoreReport.map((el: any) => {
      return {
        name: data.averageScore.name.push(el.shortLabel),
        percentage: data.averageScore.percentage.push(el.overallResult)
      };
    });

  response &&
    response.foodSafetyReport &&
    response.foodSafetyReport.map((el: any) => {
      return {
        deductions: data.fsDeductions.deductions.push(el.label),
        stores: data.fsDeductions.stores.push(el.frequency)
      };
    });

  response &&
    response.gradeDistributionReport &&
    response.gradeDistributionReport.map((el: any) => {
      return {
        label: data.gradeDistribution.label.push(el.label),
        storeCount: data.gradeDistribution.storeCount.push(el.storeCount)
      };
    });

  response &&
    response.monthOnMonthReport &&
    response.monthOnMonthReport.map((el: any) => {
      return {
        month: data.monthOnMonthAverageData.month.push(el.shortLabel),
        percentage: data.monthOnMonthAverageData.percentage.push(el.averageScore)
      };
    });

  response &&
    response?.opportunityStoreFrequency?.map((el: any, i: any) => {
      let object: ReportsSummaryOpportunityDataModel = {
        categoryName: el.categoryName,
        points: el.maxPoints,
        question: el.subCategoryName,
        questionLabel: el.label,
        frequency: el.storeFrequency
      };

      data.opportunityData[i] = object;
    });

  response &&
    response?.storeTopRanking?.map((el: any, index: number) => {
      let obj: StoreRankingsTableData = {
        rank: el.rank,
        avgScore: el.avgScore,
        grade: el.grade?.toUpperCase(),
        country: el.countryName,
        sosDt: el.avgDriveThrough?.toFixed(2),
        sosFc: el.avgFrontCounter?.toFixed(2),
        storeName: el.storeName
      };
      data.storeRankingTopFiveData[index] = obj;
    });

  response &&
    response.storeTopRanking &&
    response.storeLastRanking &&
    deleteDuplicateElements(response.storeTopRanking, response.storeLastRanking).map(
      (el: any, index: number) => {
        let obj: StoreRankingsTableData = {
          rank: el.rank,
          avgScore: el.avgScore,
          grade: el.grade?.toUpperCase(),
          country: el.countryName,
          sosDt: el.avgDriveThrough?.toFixed(2),
          sosFc: el.avgFrontCounter?.toFixed(2),
          storeName: el.storeName
        };
        data.storeRankingLastFiveData[index] = obj;
      }
    );

  response &&
    response?.brandStandardKpi?.map((el: any, i: number) => {
      let obj: BrandStandardKPIDataModel = {
        id: i,
        storeName: el.label,
        brandStandard: el.brandStandardScore,
        foodSafety: el.foodSafetyPoints?.toFixed(2),
        cleanliness: el.cmScore,
        guest: el.guestScore,
        sos: el.sosScore,
        profitability: el.psmScore,
        training: el.trainingScore,
        foodQuality: el.foodQualityScore,
        fc: new Date(el.avgFrontCounter * 1000).toISOString().substr(14, 5),
        dt: new Date(el.avgDriveThrough * 1000).toISOString().substr(14, 5)
      };

      data.brandStandardKPIData[i] = obj;
    });

  response &&
    response.areaManagerRanking &&
    response.areaManagerRanking.map((el: any, index: number) => {
      let obj: StoreRankingsTableData = {
        rank: index + 1,
        avgScore: Number(el.overallScore),
        grade: el.grade?.toUpperCase(),
        storeName: el.label,
        sosDt: el.avgDriveThrough?.toFixed(2),
        sosFc: el.avgFrontCounter?.toFixed(2)
      };
      data.areaManagerRankingsTableData[index] = obj;
    });

  return data;
};

export const storeAccessSummaryFormatter = async (
  response: any
): Promise<StoreAccessSummaryDataModel> => {
  let data: StoreAccessSummaryDataModel;
  data = {
    actionPlansData: {
      series: [],
      labels: [],
      totalReports: response && response.actionPlanReport.totalCount,
      inProcessReport: response && response.actionItemInProcessReport.inProcessCount
    },
    completeAverageScore: {
      name: [],
      percentage: []
    },
    monthOnMonthAverageData: {
      percentage: [],
      month: []
    },
    foodSafetyDeductionsData: {
      month: [],
      points: [],
      averageScore: Math.ceil(
        response &&
          response.foodSafetyReport.reduce(
            (total: any, next: any) => total + next.averageDeductedPoints,
            0
          ) / response.foodSafetyReport.length
      )
    },
    brandStandardsData: {
      series: [],
      categories: [],
      averageScore: Math.ceil(
        response &&
          response.brandStandardAverageReport.reduce(
            (total: any, next: any) => total + next.averageScore,
            0
          ) / response.brandStandardAverageReport.length
      )
    },
    sosGraphData: {
      series: [],
      categories: []
    }
  };

  response &&
    response.actionPlanReport.report.map((el: any) => {
      return {
        series: data.actionPlansData.series.push(el.count),
        labels: data.actionPlansData.labels.push(
          el.label === "COMPLETED" ? "Completed Action" : "Pending Action"
        )
      };
    });

  response &&
    response.storeReport.map((el: any) => {
      return {
        name: data.completeAverageScore.name.push(el.name),
        percentage: data.completeAverageScore.percentage.push(
          Number(el.averagePercentage.toFixed(2))
        )
      };
    });

  response &&
    response.monthOnMonthReport.map((el: any) => {
      return {
        percentage: data.monthOnMonthAverageData.percentage.push(el.averageScore),
        month: data.monthOnMonthAverageData.month.push(el.shortLabel)
      };
    });

  response &&
    response.foodSafetyReport.map((el: any) => {
      return {
        month: data.foodSafetyDeductionsData.month.push(el.shortLabel),
        points: data.foodSafetyDeductionsData.points.push(el.averageDeductedPoints)
      };
    });

  response &&
    response.brandStandardAverageReport.map((el: any) => {
      return {
        series: data.brandStandardsData.series.push(Number(el.averageScore.toFixed(0))),
        categories: data.brandStandardsData.categories.push(
          el.name === "Cleanliness & Maintenance"
            ? PlatformUtils.isWeb()
              ? ["Cleanliness &", "Maintenance"]
              : "C & M"
            : el.name === "Profitability & Shift Management"
            ? PlatformUtils.isWeb()
              ? ["Profitability & Shift", "Management"]
              : "P S M"
            : el.name === "Speed of Service"
            ? PlatformUtils.isWeb()
              ? ["Speed of", "Service"]
              : "SOS"
            : el.name
        )
      };
    });

  response &&
    response.sosTimerReport.map((el: any) => {
      return {
        series: data.sosGraphData.series.push(el.avgDuration),
        Categories: data.sosGraphData.categories.push(el.shortLabel)
      };
    });

  return data;
};

export const formatterReports = async (
  opportunitApiData: any,
  reportsMasterData: any
): Promise<ReportsDataModal> => {
  let rejectedData: CategoriesNameModel[];
  rejectedData = opportunitApiData.map((el: any) => {
    let obj: CategoriesNameModel;
    obj = {
      id: el.subCategoryId,
      categoryName: el.subCategoryName,
      serialNumber: el.subCategoryLabel,
      subCategory: [],
      attachments: [],
      comment: []
    };
    obj.subCategory = el.questions?.map((el2: any, index: number) => {
      return { id: el2.id, serialNumber: `${index + 1}`, title: el2.question };
    });
    obj.comment = el.observations ? el.observations.map((el3: any) => el3.observation) : [];
    if (el.auditorComment && el.auditorComment.length > 0) {
      obj.comment.push(el.auditorComment);
    }
    obj.attachments = el.images ? el.images.map((el4: any) => el4.signedUrl) : [];
    return obj;
  });

  const ztFormatter = () => {
    if (opportunitApiData.length > 0) {
      let categories = opportunitApiData[0].questions.map((el: any) => {
        let object: Categories;
        object = {
          serialNumber: el.questionLabel,
          question: el.question,
          description: el.description,
          observations: []
        };
        return object;
      });
      categories[0].observations = opportunitApiData[0].observations;
      let zeroToleranceData = opportunitApiData[0];

      return {
        categories: categories,
        comment: zeroToleranceData.auditorComment,
        attachments: zeroToleranceData.images
          ? zeroToleranceData.images.map((el4: any) => el4.signedUrl)
          : []
      };
    } else {
      return {
        categories: [],
        comment: null,
        attachments: []
      };
    }
  };

  let slicedData = reportsMasterData;
  let finalData: ReportsDataModal = {
    externalPDFlink: slicedData.signedUrl,
    isSigned: slicedData.isSigned,
    auditType: slicedData.selfCalibration.auditType,
    zeroTolerance: reportsMasterData.status === "ZT",
    location: slicedData.selfCalibration.storeName,
    remark: "Continue to shine like a",
    remarkAppend: "and keep up the good work",
    isIcon: true,
    Details: {
      store: slicedData.selfCalibration.storeCode.toString(),
      auditor: slicedData.selfCalibration.auditorName,
      auditStartedDate: dateFormat(slicedData.selfCalibration.startTime),
      auditEndedDate: dateFormat(slicedData.selfCalibration.endTime),
      endTime: slicedData.selfCalibration.endTime + "Z",
      // THIS IS A TEMP FIX NOT SURE WHETHER CORRECT OR NOT
      startTime: slicedData.selfCalibration.startTime + "Z",
      brandStandard:
        slicedData.brandStandardsScore === "NaN" ? "0" : slicedData.brandStandardsScore,
      foodSafety: slicedData.foodSafetyScore.toString()
    },
    resultStatus: slicedData.status,
    resultPercentage:
      slicedData.overallResult[Object.keys(slicedData.overallResult)[0]] === "NaN" ||
      Object.keys(reportsMasterData.overallResult).length === 0
        ? "0"
        : slicedData.overallResult[Object.keys(slicedData.overallResult)[0]].toFixed(2),
    grade: Object.keys(reportsMasterData.overallResult)[0],
    BranStandardTable: {
      //all calculate afterwords
      BrandStandardList: [],
      totalEarnedValue: "0",
      totalAvailableValue: "0",
      brandStandardPercentage: "0"
    },
    foodSafetyTable: {
      score: slicedData.foodSafetyScore,
      //afterwards
      FoodSafetyList: []
    },
    //after
    ScoringInformation: {
      brandStandardOpportunity: 0,
      foodSafetyCriticals: -1,
      foodSafetyImportant: 0
    },
    OpportunityDetails: { rejectedData },
    zeroToleranceObservations: ztFormatter()
  };
  let totalAvailableValue = 0;
  let totalEarnedValue = 0;
  finalData.BranStandardTable.BrandStandardList = slicedData.brandStandardsReport.map(
    (el: any, index: number) => {
      let obj: BrandStandardListDataModel;
      obj = {
        id: (index + 1).toString(),
        title: el.revCategory.categoryName,
        earnedValue: el.earnedPoints,
        availableValue: el.availablePoints
      };
      totalAvailableValue += el.availablePoints;
      totalEarnedValue += el.earnedPoints;
      return obj;
    }
  );
  finalData.BranStandardTable.totalAvailableValue = totalAvailableValue.toString();
  finalData.BranStandardTable.totalEarnedValue = totalEarnedValue.toString();
  finalData.BranStandardTable.brandStandardPercentage = slicedData.brandStandardsScore?.toFixed(2);
  finalData.foodSafetyTable.FoodSafetyList = slicedData.foodSafetyReports.map(
    (el: any, index: number) => {
      let obj: FoodSafetyListDataModel;
      obj = {
        title: el.foodSafetyLabel,
        criticalValue: el.isCritical,
        importantValue: el.isImportant,
        id: `${index + 1}`
      };
      return obj;
    }
  );
  finalData.ScoringInformation.brandStandardOpportunity = totalEarnedValue;
  //rest BE will send

  return finalData;
};
