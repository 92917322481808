import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { ITimeTempCategories } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import {
  LogsAuthModel,
  WeeklyPostItemsResponseModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { AnswerStatus } from "@timhortons/common/src/modules/logs/formatter/cleaningFormatter";

export interface IManagerWalkthroughLoading {
  getManagerWalkthroughCategory: boolean;
  getItemsByCategory: boolean;
  postMWTItemsResp: boolean;
  mwtLogsAuth: boolean;
}
export interface IManagerWalkthroughError {
  getManagerWalkthroughCategory: string;
  getItemsByCategory: string;
  postMWTItemsResp: string;
  mwtLogsAuth: string;
}
export interface IManagerWalkthroughState {
  loading: IManagerWalkthroughLoading;
  error: IManagerWalkthroughError;
  managerWalkthroughCategories: ITimeTempCategories[];
  getItemsByCategory: any;
  postMWTItemsResp: WeeklyPostItemsResponseModel;
  mwtLogsAuth: LogsAuthModel;
}
export const managerWalkthroughLogsInitialState: IManagerWalkthroughState = {
  loading: {
    getManagerWalkthroughCategory: false,
    getItemsByCategory: false,
    postMWTItemsResp: false,
    mwtLogsAuth: false
  },
  error: {
    getManagerWalkthroughCategory: null,
    getItemsByCategory: null,
    postMWTItemsResp: null,
    mwtLogsAuth: null
  },
  managerWalkthroughCategories: null,
  getItemsByCategory: null,
  postMWTItemsResp: null,
  mwtLogsAuth: null
};
export const managerWalkthroughLogsReducer = (
  state = managerWalkthroughLogsInitialState,
  action: { payload: any; type: string }
): IManagerWalkthroughState => {
  switch (action.type) {
    case LOGS.MainLogs.ManagerWalk.CHECK_MWT_LOGS_AUTH.LOADING:
      return {
        ...state,
        loading: { ...state.loading, mwtLogsAuth: true },
        error: { ...state.error, mwtLogsAuth: null },
        mwtLogsAuth: null
      };
    case LOGS.MainLogs.ManagerWalk.CHECK_MWT_LOGS_AUTH.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, mwtLogsAuth: false },
        mwtLogsAuth: action.payload
      };
    case LOGS.MainLogs.ManagerWalk.CHECK_MWT_LOGS_AUTH.ERROR:
      return {
        ...state,
        loading: { ...state.loading, mwtLogsAuth: false },
        error: { ...state.error, mwtLogsAuth: action.payload }
      };
    case LOGS.MainLogs.ManagerWalk.RESET_MWT_LOGS_AUTH_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          mwtLogsAuth: null
        }
      };
    case LOGS.MainLogs.ManagerWalk.RESET_MWT_LOGS_AUTH:
      return {
        ...state,
        mwtLogsAuth: null
      };
    case LOGS.MainLogs.ManagerWalk.GET_MANAGER_WALKTHROUGH_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getManagerWalkthroughCategory: true },
        error: { ...state.error, getManagerWalkthroughCategory: null }
      };
    case LOGS.MainLogs.ManagerWalk.GET_MANAGER_WALKTHROUGH_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getManagerWalkthroughCategory: false },
        error: { ...state.error, getManagerWalkthroughCategory: null },
        managerWalkthroughCategories: action.payload
      };
    case LOGS.MainLogs.ManagerWalk.GET_MANAGER_WALKTHROUGH_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getManagerWalkthroughCategory: false },
        error: { ...state.error, getManagerWalkthroughCategory: action.payload }
      };

    case LOGS.MainLogs.ManagerWalk.GET_ITEMS_LIST_BY_CATEGORY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getItemsByCategory: true },
        error: { ...state.error, getItemsByCategory: null }
      };
    case LOGS.MainLogs.ManagerWalk.GET_ITEMS_LIST_BY_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getItemsByCategory: false },
        error: { ...state.error, getItemsByCategory: null },
        getItemsByCategory: action.payload
      };
    case LOGS.MainLogs.ManagerWalk.GET_ITEMS_LIST_BY_CATEGORY.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getItemsByCategory: false },
        error: { ...state.error, getItemsByCategory: action.payload }
      };

    case LOGS.MainLogs.ManagerWalk.POST_MWT_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postMWTItemsResp: true },
        error: { ...state.error, postMWTItemsResp: null }
      };
    case LOGS.MainLogs.ManagerWalk.POST_MWT_ITEMS.SUCCESS:
      let formattedData = state.getItemsByCategory;

      let dataIndex = formattedData?.findIndex(
        (obj: any) => obj && obj.uniqueItemId === action.payload.questionId
      );
      let isComment = action.payload.comment || action.payload.assets.length > 0 ? true : false;
      if (dataIndex > -1) {
        formattedData[dataIndex].status = action.payload.answerType;
        formattedData[dataIndex].employeeName = action.payload.employeeId;
        formattedData[dataIndex].images = action.payload.assets;
        formattedData[dataIndex].comment = action.payload.comment;
        formattedData[dataIndex].isComment = isComment;
        formattedData[dataIndex].isMaintainence =
          action.payload.answerStatus === AnswerStatus.contactMaintenance;
      }
      let categories = state.managerWalkthroughCategories;
      let categoryIndex =
        categories &&
        categories.findIndex((item: ITimeTempCategories) => item.id === action.payload.categoryId);
      if (categoryIndex > -1) {
        categories[categoryIndex].percentageCompleted = action.payload.percentageCompleted;
      }
      return {
        ...state,
        loading: { ...state.loading, postMWTItemsResp: false },
        error: { ...state.error, postMWTItemsResp: null },
        getItemsByCategory: formattedData,
        postMWTItemsResp: action.payload
      };
    case LOGS.MainLogs.ManagerWalk.POST_MWT_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postMWTItemsResp: false },
        error: { ...state.error, postMWTItemsResp: action.payload }
      };
    case LOGS.MainLogs.ManagerWalk.RESET_MWT_LOGS:
      return managerWalkthroughLogsInitialState;
    case LOGS.MainLogs.ManagerWalk.RESET_MWT_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          postMWTItemsResp: null,
          getItemsByCategory: null,
          getManagerWalkthroughCategory: null
        }
      };
    case LOGS.MainLogs.ManagerWalk.RESET_MWT_POST_ITEMS_RESPONSE:
      return {
        ...state,
        postMWTItemsResp: null
      };
    default:
      return state;
  }
};
