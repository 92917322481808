import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  ReportsDataModal,
  AcknowledgementModel,
  ReportTableResponseModel,
  PostAcknowledgementModel,
  StoreAccessSummaryDataModel,
  ReportsSummaryOpportunityDataModel,
  CountryManagerDetailsModel
} from "@timhortons/common/src/models/rev/reports";
import { IReportTableData } from "@timhortons/common/src/modules/rev/components/dashboards/reports";
import { SelectedMonthFilter } from "modules/rev/components/dashboards/reports/organisms/reportsTableHeader";
import {
  CountryDataListModel,
  AllAreaManagersListDataModel,
  ChannelsListDataModel,
  AllStoresListDataModel
} from "@timhortons/common/src/models/profile";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

const getReportsData = (data: {
  calibrationId: number;
  reportId: number;
}): IFluxStandardAction<{
  calibrationId: number;
  reportId: number;
}> => {
  return {
    type: REV.REPORTS_DATA.LOADING,
    payload: data
  };
};
const getReportsDataSuccess = (data: ReportsDataModal): IFluxStandardAction<ReportsDataModal> => {
  return {
    type: REV.REPORTS_DATA.SUCCESS,
    payload: data
  };
};
const getReportsDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.REPORTS_DATA.ERROR,
    payload: error
  };
};
const getReportsAcknowledgementData = (data: {
  storeCode: string;
}): IFluxStandardAction<{ storeCode: string }> => {
  return {
    type: REV.ACKNOWLEDGEMENT_DATA.LOADING,
    payload: data
  };
};
const getReportsAcknowledgementDataSuccess = (
  data: AcknowledgementModel
): IFluxStandardAction<AcknowledgementModel> => {
  return {
    type: REV.ACKNOWLEDGEMENT_DATA.SUCCESS,
    payload: data
  };
};
const getReportsAcknowledgementDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.ACKNOWLEDGEMENT_DATA.ERROR,
    payload: error
  };
};

const getReportTableData = (data: IReportTableData): IFluxStandardAction<IReportTableData> => {
  return {
    type: REV.GET_REPORT_TABLE_DATA.LOADING,
    payload: data
  };
};
const getReportTableDataSuccess = (
  data: ReportTableResponseModel
): IFluxStandardAction<ReportTableResponseModel> => {
  return {
    type: REV.GET_REPORT_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const getReportTableDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_REPORT_TABLE_DATA.ERROR,
    payload: error
  };
};

const postAcknowledgement = (
  data: PostAcknowledgementModel
): IFluxStandardAction<PostAcknowledgementModel> => {
  return {
    type: REV.POST_ACKNOWLEDGEMENT.LOADING,
    payload: data
  };
};
const postAcknowledgementSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: REV.POST_ACKNOWLEDGEMENT.SUCCESS,
    payload: data
  };
};
const postAcknowledgementError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.POST_ACKNOWLEDGEMENT.ERROR,
    payload: error
  };
};

const getStoreAccessSummaryData = (data: {
  storeId: number;
  startDate: string;
  endDate: string;
}): IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }> => {
  return {
    type: REV.GET_STORE_ACCESS_SUMMARY_DATA.LOADING,
    payload: data
  };
};

const getStoreAccessSummaryDataSuccess = (
  data: StoreAccessSummaryDataModel
): IFluxStandardAction<StoreAccessSummaryDataModel> => {
  return {
    type: REV.GET_STORE_ACCESS_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};

const getStoreAccessSummaryDataError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_STORE_ACCESS_SUMMARY_DATA.ERROR,
    payload: data
  };
};

const getStoreAccessOpportunityData = (data: {
  storeId: number;
  startDate: string;
  endDate: string;
}): IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }> => {
  return {
    type: REV.GET_STORE_ACCESS_OPPORTUNITY_DATA.LOADING,
    payload: data
  };
};

const getStoreAccessOpportunityDataSuccess = (
  data: ReportsSummaryOpportunityDataModel[]
): IFluxStandardAction<ReportsSummaryOpportunityDataModel[]> => {
  return {
    type: REV.GET_STORE_ACCESS_OPPORTUNITY_DATA.SUCCESS,
    payload: data
  };
};

const getStoreAccessOpportunityDataError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_STORE_ACCESS_OPPORTUNITY_DATA.ERROR,
    payload: data
  };
};

const getSelectedMonthFilter = (
  data: SelectedMonthFilter
): IFluxStandardAction<SelectedMonthFilter> => {
  return {
    type: REV.GET_SELECTED_MONTH_FILTER.SUCCESS,
    payload: data
  };
};

const getIndividualSummaryData = (data: {
  startDate: string;
  endDate: string;
}): IFluxStandardAction<{ startDate: string; endDate: string }> => {
  return {
    type: REV.GET_INDIVIDUAL_SUMMARY_DATA.LOADING,
    payload: data
  };
};

const getIndividualSummaryDataSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: REV.GET_INDIVIDUAL_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};

const getIndividualSummaryDataError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_INDIVIDUAL_SUMMARY_DATA.ERROR,
    payload: data
  };
};
// Report builder
// const getAllCountriesLoading = (data: {
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }): IFluxStandardAction<{ pageNo: number; pageSize: number; searchByKeyword: string }> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_COUNTRIES.LOADING,
//     payload: data
//   };
// };

// const getAllCountriesSuccess = (
//   data: CountryDataListModel
// ): IFluxStandardAction<CountryDataListModel> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_COUNTRIES.SUCCESS,
//     payload: data
//   };
// };
// const getAllCountriesError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_COUNTRIES.ERROR,
//     payload: error
//   };
// };
// const getAllAreaManagerLoading = (data: {
//   user: IUserDetails;
//   role: string;
//   countryCode: any;
//   searchByKeyword: string;
//   allSelected?: boolean;
// }): IFluxStandardAction<{
//   user: IUserDetails;
//   role: string;
//   countryCode: any;
//   searchByKeyword: string;
//   allSelected?: boolean;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_AREA_MANAGER.LOADING,
//     payload: data
//   };
// };

// const getAllAreaManagerSuccess = (
//   data: AllAreaManagersListDataModel
// ): IFluxStandardAction<AllAreaManagersListDataModel> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_AREA_MANAGER.SUCCESS,
//     payload: data
//   };
// };
// const getAllAreaManagerError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_AREA_MANAGER.ERROR,
//     payload: error
//   };
// };
// const getAllChannelsLoading = (data: {
//   user: IUserDetails;
//   pageNo: number;
//   pageSize: number;
//   countryCode: any;
//   searchByKeyword: string;
// }): IFluxStandardAction<{
//   user: IUserDetails;
//   pageNo: number;
//   pageSize: number;
//   countryCode: any;
//   searchByKeyword: string;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_CHANNELS.LOADING,
//     payload: data
//   };
// };

// const getAllChannelsSuccess = (
//   data: ChannelsListDataModel
// ): IFluxStandardAction<ChannelsListDataModel> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_CHANNELS.SUCCESS,
//     payload: data
//   };
// };
// const getAllChannelsError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_CHANNELS.ERROR,
//     payload: error
//   };
// };

// const getAllStoresLoading = (data: {
//   user: IUserDetails;
//   pageNo: number;
//   pageSize: number;
//   countryCode: any;
//   searchByKeyword: string;
// }): IFluxStandardAction<{
//   user: IUserDetails;
//   pageNo: number;
//   pageSize: number;
//   countryCode: any;
//   searchByKeyword: string;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_STORES.LOADING,
//     payload: data
//   };
// };

// const getAllStoresSuccess = (
//   data: AllStoresListDataModel
// ): IFluxStandardAction<AllStoresListDataModel> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_STORES.SUCCESS,
//     payload: data
//   };
// };
// const getAllStoresError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_STORES.ERROR,
//     payload: error
//   };
// };

// const getAllCountryManagerLoading = (data: {
//   user: IUserDetails;
//   role: string;
//   countryCode: any;
//   searchByKeyword: string;
//   pageNo: number;
//   pageSize: number;
// }): IFluxStandardAction<{
//   user: IUserDetails;
//   role: string;
//   searchByKeyword: string;
//   countryCode: any;
//   pageNo: number;
//   pageSize: number;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_COUNTRY_MANAGER.LOADING,
//     payload: data
//   };
// };

// const getAllCountryManagerSuccess = (
//   data: AllAreaManagersListDataModel
// ): IFluxStandardAction<AllAreaManagersListDataModel> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_COUNTRY_MANAGER.SUCCESS,
//     payload: data
//   };
// };
// const getAllCountryManagerError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_ALL_COUNTRY_MANAGER.ERROR,
//     payload: error
//   };
// };
const generateReportBuilder = (data: {
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
}): IFluxStandardAction<{
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
}> => {
  return {
    type: REV.ReportBuilder.GENERATE_REPORT.LOADING,
    payload: data
  };
};
const generateReportBuilderSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: REV.ReportBuilder.GENERATE_REPORT.SUCCESS,
    payload: data
  };
};
const generateReportBuilderError = (error: {
  error: Error;
  reportType: ComparsionLevel;
}): IFluxStandardAction<{
  error: Error;
  reportType: ComparsionLevel;
}> => {
  return {
    type: REV.ReportBuilder.GENERATE_REPORT.ERROR,
    payload: error
  };
};
// const getComparisonCountryManagersAdmin = (data: {
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }): IFluxStandardAction<{
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.LOADING,
//     payload: data
//   };
// };
// const comparisonCountryManagersAdminSuccess = (data: any): IFluxStandardAction<any> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.SUCCESS,
//     payload: data
//   };
// };
// const comparisonCountryManagersAdminError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.ERROR,
//     payload: error
//   };
// };

// const getComparisonAreaManagersAdmin = (data: {
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }): IFluxStandardAction<{
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_AREA_MANAGERS_ADMIN.LOADING,
//     payload: data
//   };
// };
// const comparisonAreaManagersAdminSuccess = (data: any): IFluxStandardAction<any> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_AREA_MANAGERS_ADMIN.SUCCESS,
//     payload: data
//   };
// };
// const comparisonAreaManagersAdminError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_AREA_MANAGERS_ADMIN.ERROR,
//     payload: error
//   };
// };
// const comparisonStoresAdminLoading = (data: {
//   pageNo: number;
//   pageSize: number;
// }): IFluxStandardAction<{
//   pageNo: number;
//   pageSize: number;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_STORES_ADMIN.LOADING,
//     payload: data
//   };
// };

// const comparisonStoresAdminSuccess = (
//   data: AllStoresListDataModel
// ): IFluxStandardAction<AllStoresListDataModel> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_STORES_ADMIN.SUCCESS,
//     payload: data
//   };
// };
// const comparisonStoresAdminError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_COMPARISON_STORES_ADMIN.ERROR,
//     payload: error
//   };
// };
const resetReportBuilder = () => {
  return {
    type: REV.ReportBuilder.RESET_REPORT_BUILDER
  };
};
// const highlowChannelsAdminLoading = (data: {
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }): IFluxStandardAction<{
//   pageNo: number;
//   pageSize: number;
//   searchByKeyword: string;
// }> => {
//   return {
//     type: REV.ReportBuilder.GET_HIGHLOW_CHANNELS_ADMIN.LOADING,
//     payload: data
//   };
// };

// const highlowChannelsAdminSuccess = (
//   data: ChannelsListDataModel
// ): IFluxStandardAction<ChannelsListDataModel> => {
//   return {
//     type: REV.ReportBuilder.GET_HIGHLOW_CHANNELS_ADMIN.SUCCESS,
//     payload: data
//   };
// };
// const highlowChannelsAdminError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: REV.ReportBuilder.GET_HIGHLOW_CHANNELS_ADMIN.ERROR,
//     payload: error
//   };
// };
const getCountryManagerDetails = () => {
  return {
    type: REV.GET_COUNTRYMANAGER_DETAILS.LOADING
  };
};
const getCountryManagerDetailsSuccess = (
  data: CountryManagerDetailsModel
): IFluxStandardAction<CountryManagerDetailsModel> => {
  return {
    type: REV.GET_COUNTRYMANAGER_DETAILS.SUCCESS,
    payload: data
  };
};
const getCountryManagerDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_COUNTRYMANAGER_DETAILS.ERROR,
    payload: error
  };
};
export const RevReportsActions = {
  getReportsData,
  getReportsDataError,
  getReportsDataSuccess,
  getReportsAcknowledgementData,
  getReportsAcknowledgementDataSuccess,
  getReportsAcknowledgementDataError,
  getReportTableData,
  getReportTableDataSuccess,
  getReportTableDataError,
  postAcknowledgement,
  postAcknowledgementSuccess,
  postAcknowledgementError,
  getStoreAccessSummaryData,
  getStoreAccessSummaryDataSuccess,
  getStoreAccessSummaryDataError,
  getStoreAccessOpportunityData,
  getStoreAccessOpportunityDataSuccess,
  getStoreAccessOpportunityDataError,
  getSelectedMonthFilter,
  getIndividualSummaryData,
  getIndividualSummaryDataSuccess,
  getIndividualSummaryDataError,
  // getAllCountriesLoading,
  // getAllCountriesSuccess,
  // getAllCountriesError,
  // getAllAreaManagerLoading,
  // getAllAreaManagerSuccess,
  // getAllAreaManagerError,
  // getAllChannelsLoading,
  // getAllChannelsSuccess,
  // getAllChannelsError,
  // getAllStoresLoading,
  // getAllStoresSuccess,
  // getAllStoresError,
  // getAllCountryManagerError,
  // getAllCountryManagerLoading,
  // getAllCountryManagerSuccess,
  generateReportBuilder,
  generateReportBuilderSuccess,
  generateReportBuilderError,
  // getCometComparisonAreaManagersAdmin,
  // comparisonAreaManagersAdminSuccess,
  // comparisonAreaManagersAdminError,parisonCountryManagersAdmin,
  // comparisonCountryManagersAdminSuccess,
  // comparisonCountryManagersAdminError,
  // g
  // comparisonStoresAdminLoading,
  // comparisonStoresAdminSuccess,
  // comparisonStoresAdminError,
  resetReportBuilder,
  // highlowChannelsAdminLoading,
  // highlowChannelsAdminSuccess,
  // highlowChannelsAdminError,
  getCountryManagerDetails,
  getCountryManagerDetailsSuccess,
  getCountryManagerDetailsError
};
