export enum Pathnames {
  // These are for navbar items
  home = "userHome",
  resources = "userResources",
  manageResources = "manageResources",
  calendar = "calendar",
  careerPath = "careerPath",
  lms = "lms",
  lmsCourses = "lmsCourses",
  lmsCoursesHome = "lmsCoursesHome",
  lmsAssessment = "lmsAssessment",
  lmsAssessmentInstructions = "lmsAssessmentInstructions",
  lmsAssessmentQuestion = "lmsAssessmentQuestion",
  lmsAssessmentResultSuccess = "lmsAssessmentResultSuccess",
  lmsAssessmentResultFail = "lmsAssessmentResultFail",
  lmsAssignedLearning = "lmsAssignedLearning",
  lmsMoreSection = "lmsMoreSection",
  lmsMoreStatyTuned = "lmsMoreStatyTuned",
  lmsMorePreferences = "lmsMorePreferences",
  lmsMoreCalendar = "lmsMoreCalendar",
  lmsNotification = "lmsNotification",
  // lmsPlayList = "lmsPlayList",
  lmsQuiz = "lmsQuiz",
  lmsQuizHome = "lmsQuizHome",
  lmsAssessmentInstructionsHome = "lmsAssessmentInstructionsHome",
  matchTheFollowingQuizHome = "matchTheFollowingQuizHome",
  ticTacToeHome = "ticTacToeHome",
  imageDragDropHome = "imageDragDropHome",
  practicalAssessmentHome = "practicalAssessmentHome",
  lmsPtsHome = "lmsPtsHome",
  lmsNotificationHome = "lmsNotificationHome",

  matchTheFollowingQuiz = "matchTheFollowingQuiz",
  ticTacToe = "ticTacToe",
  dragDropSort = "dragDropSort",
  imageDragDrop = "imageDragDrop",
  lmsPts = "lmsPts",
  lmsRoot = "lmsRoot",
  // detailedResources = "detailedResources",
  reports = "reports",
  videoScreen = "videoScreen",
  rev = "rev",
  lp = "lp",
  asktell = "askTell",
  comingSoon = "comingSoon",
  logs = "logs",
  //LP
  lpAuditsList = "lpAuditsList",
  lpActionPlansList = "lpActionPlansList",
  lpAppealsList = "lpAppealsList",
  lpReportsList = "lpReportsList",
  lpAudit = "lpAudit",
  detailedActionPlan = "detailedActionPlan",
  detailedAppeal = "detailedAppeal",
  detailedReport = "detailedReport",
  builderReports = "builderReports",

  //REV
  testAudit = "testAudit",
  detailedReports = "detailedReports",
  detailedAppeals = "detailedAppeals",
  detailedActionPlans = "detailedActionPlans",
  builderReport = "builderReport",
  calibrations = "selfCalibrations",
  appeals = "appeals",
  actionPlans = "actionPlans",
  reportNavlink = "reportNavlink",
  // logs super admim
  adminFlow = "adminFlow",
  adminLogsList = "adminLogsList",
  adminLogActions = "adminLogActions",
  adminLogsReports = "adminLogsReports",
  //LOGS
  logsList = "logsList",
  logActions = "logActions",
  logsManagerReview = "managerReview",
  logsReports = "logsReports",
  logSpecificStore = "logSpecificStore",
  //PROSHEET
  mainMenu = "mainMenu",
  productionSheet = "productionSheet",
  productionManagerReview = "productionManagerReview",
  productionSummary = "productionSummary",
  bakingProdSheet = "bakingProdSheet",
  othersProdSheet = "othersProdSheet",
  prodSheetReportBuilder = "prodSheetReportBuilder",
  //PROFILE
  profileScreen = "profile",
  profileScreenTab = "profileScreen",
  userDetails = "userDetails",
  createStore = "createStore",
  createEmployee = "createEmployee",
  manageUser = "manageUser",
  manageStore = "manageStore",
  //OTHER
  login = "login",
  aboutUs = "aboutUs",
  contact = "contact",
  notFound = "notFound",
  forgotPassword = "forgotPassword",
  //MORE
  more = "more",
  askUsTellUs = "askUsTellUs",
  questions = "questions",
  questionAndAnswer = "questionAndAnswer",
  questionUpload = "questionUpload",
  enquiries = "enquiries",
  news = "news",
  newsHome = "newsHome",
  newsDetails = "newsDetails",
  newsDetailsHome = "newsDetailsHome",
  myProfile = "myProfile",
  editProfile = "editProfile",
  myProfileHome = "myProfileHome",
  editProfileHome = "editProfileHome",
  leaderboards = "leaderboards",
  timbitCollection = "timbitCollection",
  //Manage Lms Dashboard
  manageLms = "manageLms",
  manageLmsAssignedLearning = "manageLmsAssignedLearning",
  manageLmsAssesments = "manageLmsAssesments",
  manageLmsReports = "manageLmsReports",
  manageLmsCalendar = "manageLmsCalendar",
  manageLmsNews = "manageLmsNews",
  //Practical Assessment
  practicalAssessments = "practicalAssessments",
  // practicalAssessmentsQuiz = "practicalAssessmentsQuiz",
  //manage Existing Users
  manageExistingUsers = "manageExistingUsers",
  //ManageLms AssignedLearning
  assignedLearning = "assignedLearning",
  assignedLearningCreateModule = "assignedLearningCreateModule",
  assignedLearningCategories = "assignedLearningCategories",
  assignedLearningPts = "assignedLearningPts",
  assignedLearningAssessments = "assignedLearningAssessments",
  assignedLearninCertifications = "assignedLearninCertifications",
  assignedLearningFeedback = "assignedLearningFeedback",
  assignedLearningSummary = "assignedLearningSummary",

  // user lms practical assessment

  practicalAssessment = "practicalAssessment",
  result = "result",
  resultHome = "resultHome",

  //lmsReports
  // lmsReports = "lmsReports",
  // lmsReportsDashboard = "lmsReportsDashboard",
  // lmsReportsDetails = "lmsReportsDetails",
  /// Manage news
  manageNews = "manageNews",
  manageNewsCreate = "manageNewsCreate",

  // STATISTICS
  courseDetails = "courseDetails",
  courseDetailsHome = "courseDetailsHome",

  resTimbitCollection = "resTimbitCollection",
  // Ask us
  askTell = "askTell",
  supportTickets = "supportTickets",
  addQuestions = "addQuestions",
  frequentlyAskedQuestions = "frequentlyAskedQuestions",

  //REPORT BUILDERS
  reportBuilders = "reportBuilders",
  //dashboards
  lpReportBuilder = "lpReportBuilder",
  revReportBuilder = "revReportBuilder",
  lmsReportBuilder = "lmsReportBuilder",
  detailedLmsReportBuilder = "detailedLmsReportBuilder",
  logsReportBuilder = "logsReportBuilder",
  psReportBuilder = "psReportBuilder",
  // overallReport
  lpOverallReport = "lpOverallReport",
  revOverallReport = "revOverallReport",
  lmsOverallReport = "lmsOverallReport",
  detailedLmsOverallReport = "detailedLmsOverallReport",
  logsOverallReport = "logsOverallReport",
  psOverallReport = "psOverallReport"
}

export enum StackKeys {
  IamStack = "IamStack",
  lp = "lp",
  rev = "rev",
  home = "userHome",
  resources = "userResources",
  manageResources = "manageResources",
  reports = "reports",
  asktell = "askTell",
  profileScreen = "profileScreen",
  userDetails = "userDetails",
  logs = "logs",
  lms = "lms",
  careerPath = "careerPath",
  assignedLearning = "assignedLearning",
  lmsQuiz = "lmsQuiz",
  productionSheet = "proSheet",
  more = "more",
  manageLms = "manageLms",
  calendar = "calendar",
  reportBuilders = "reportBuilders",
  practicalAssessments = "practicalAssessments",
  news = "news",
  manageNews = "manageNews",
  manageExistingUsers = "manageExistingUsers",
  askTell = "askTell"
}
