import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import React, { ReactElement, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import StoreAccessSummary from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/storeAccess";
import AreaManager from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/areaManager";
import { View, StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { SelectedMonthFilter } from "../organisms/reportsTableHeader";
import { Roles } from "@timhortons/common/src/constants/roles";
import CountryManager from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/countryManager";
import AdminSummary from "@timhortons/common/src/modules/rev/components/dashboards/reports/summary/admin";
import { RevReportsActions } from "@timhortons/common/src/redux/rev/actions/reportsAction";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import { RevReportsSelectors } from "@timhortons/common/src/redux/rev/selectors/reportsSelector";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  CountryManagerDetailsModel,
  IndividualAccessSummaryDataModel
} from "@timhortons/common/src/models/rev/reports";
import {
  IRevReportsError,
  IRevReportsLoading
} from "@timhortons/common/src/redux/rev/reducers/reportsReducer";

interface Props {
  user: IUserDetails;
  translate?: any;
  selectedMonth: SelectedMonthFilter;
  getIndividualSummaryData: Function;
  individualSummaryData: IndividualAccessSummaryDataModel;
  loading: IRevReportsLoading;
  error: IRevReportsError;
  countryManagerDetails: CountryManagerDetailsModel;
  getCountryManagerDetails: Function;
}

function Summary(props: Props): ReactElement {
  const {
    translate,
    user,
    selectedMonth,
    getIndividualSummaryData,
    individualSummaryData,
    loading,
    error,
    countryManagerDetails,
    getCountryManagerDetails
  } = props;

  useEffect(() => {
    !user.roles.includes(Roles.StoreAccess) &&
      getIndividualSummaryData({
        startDate: convert(new Date(selectedMonth.year, selectedMonth.month.id, 1)),
        endDate: convert(new Date(selectedMonth.year, selectedMonth.month.id + 1, 0))
      });
    user.roles.includes(
      Roles.CountryManager ||
        Roles.OperationManager ||
        Roles.SeniorOpsManager ||
        Roles.ClusterHeadofOps
    ) && getCountryManagerDetails();
  }, [selectedMonth]);

  const renderComponent = useMemo(() => {
    let userRole = user?.roles[0];
    let result: ReactElement = <View></View>;
    switch (userRole) {
      // case Roles.Admin:
      case Roles.SuperAdmin:
      case Roles.TrainingAndDevLead:
      case Roles.TrainingAndDevManager:
      case Roles.HrAndAdminDirector:
      case Roles.ProductionAndDevExecutive:
      case Roles.CEO:
      case Roles.COO:
        result = (
          <AdminSummary
            translate={translate}
            user={user}
            summaryData={individualSummaryData}
            loading={loading.individualAccessSummaryData}
            error={error.individualAccessSummaryData}
          />
        );
        break;
      case Roles.AreaManager:
      case Roles.AreaTrainer:
        result = (
          <AreaManager
            translate={translate}
            user={user}
            summaryData={individualSummaryData}
            loading={loading.individualAccessSummaryData}
            error={error.individualAccessSummaryData}
          />
        );
        break;
      case Roles.InternalAuditor:
      case Roles.CountryManager:
      case Roles.ClusterHeadofOps:
      case Roles.OperationManager:
      case Roles.SeniorOpsManager:
        result = (
          <CountryManager
            translate={translate}
            user={user}
            summaryData={individualSummaryData}
            loading={loading.individualAccessSummaryData}
            error={error.individualAccessSummaryData}
            countryManagerDetails={countryManagerDetails}
          />
        );
        break;
      case Roles.StoreAccess:
        result = <StoreAccessSummary translate={translate} currentUserRoles={user} />;
        break;
      default:
        result = null;
        break;
    }
    return result;
  }, [user, loading, error]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <View style={styles.headerTitle}>
            <View style={{ flexDirection: "row" }}>
              <Text
                textSize={TextSize.Large}
                fontWeight={FontWeight.SemiBold}
                testId="summary-header"
                textStyle={{ color: colorPallete.black3 }}
              >
                {translate("rev")} {selectedMonth && selectedMonth.month.label}
              </Text>
              <Text
                textSize={TextSize.Large}
                fontWeight={FontWeight.SemiBold}
                testId="summary-header"
                textStyle={{ color: colorPallete.black3, marginStart: 8 }}
              >
                {translate("summaryReportHeader")}
              </Text>
            </View>
            <Text
              textSize={TextSize.ExtraSmall}
              fontWeight={FontWeight.Regular}
              testId="summary-header"
              textStyle={{ color: colorPallete.grey1 }}
            >
              {translate("lastUpdated")} {selectedMonth && selectedMonth.month.label}{" "}
              {selectedMonth && selectedMonth.year}
            </Text>
          </View>
          {/* <DownloadButton translate={translate} title={"downloadAsPdf"} /> */}
        </View>
        {renderComponent}
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colorPallete.white3,
    borderRadius: 8,
    padding: 30
  },
  headerContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  headerTitle: {
    flex: 0.5,
    flexDirection: "column"
  }
});

const mapStateToProps = (state: IState) => {
  return {
    selectedMonth: RevReportsSelectors.getSelectedMonthFilter(state),
    individualSummaryData: RevReportsSelectors.getIndividualAccessSummaryData(state),
    loading: RevReportsSelectors.revReportsLoading(state),
    error: RevReportsSelectors.revReportsError(state),
    countryManagerDetails: RevReportsSelectors.getCountryManagerDetails(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...RevReportsActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
