import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "@timhortons/common/src/utils/allRouteNames";

const AuthenticatedRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // don't use localStorage
        const accessToken: any = JSON.parse(localStorage.getItem("accessToken"));
        const user = JSON.parse(localStorage.getItem("user"));
        if (accessToken && user) {
          return <Component {...props} />;
        } else {
          LocalStorage.clearStorage();
          return (
            <Redirect
              to={{
                pathname: `${routeNames.IAM.login}`,
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
export default AuthenticatedRoute;
