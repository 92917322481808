import { Store } from "redux";
import { IState } from "@timhortons/common/src/store/interfaces";

let store: Store<IState>;

class StoreProviderService {
  public init(configureStore: any): void {
    store = configureStore();
  }

  public getStore(): Store<IState> {
    return store;
  }
}

const storeProviderService = new StoreProviderService();
export { storeProviderService as StoreProviderService };
