import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import {
  LpAppealCountModel,
  LpAppealsCategoryDataModel,
  LpCreateNewAppeal,
  LpInProcesssClosedTable,
  LpNewAppealsTable,
  LpAppealsAccordionDataModel,
  LpAppealCommentsDataModel
} from "@timhortons/common/src/models/lp/appeals";
import { LpActionPlanCategoryDataModel } from "@timhortons/common/src/models/lp/actionPlan";
export interface ILpAppealsLoading {
  getLpInProcessAppeals: boolean;
  getLpClosedAppeals: boolean;
  getLpAppealsCount: boolean;
  getLpNewAppeals: boolean;
  setLpAppealsDecision: boolean;
  createLpNewAppeal: boolean;
  lpAppealsHeaderData: boolean;
  lpAppealsCategoryData: boolean;
  lpFilteredData: boolean;
  lpClosedData: boolean;
  lpCommentData: boolean;
  getLpAppealAccordianData: boolean;
}
export interface ILpAppealsError {
  getLpInProcessAppeals: string;
  getLpClosedAppeals: string;
  setLpAppealsDecision: string;
  lpFilteredData: string;
  lpClosedData: string;
  getLpAppealsCount: string;
  getLpNewAppeals: string;
  createLpNewAppeal: string;
  lpAppealsHeaderData: string;
  lpAppealsCategoryData: string;
  lpCommentData: string;
  getLpAppealAccordianData: string;
}
export interface ILpAppealsState {
  appealsCount: LpAppealCountModel;
  lpInProcessAppeals: LpInProcesssClosedTable[];
  lpClosedAppeals: LpInProcesssClosedTable[];
  loading: ILpAppealsLoading;
  error: ILpAppealsError;
  lpFilteredData: LpInProcesssClosedTable[];
  lpAppealsDecision: boolean;
  setLpAppealsDecision: any;
  lpClosedData: LpInProcesssClosedTable;
  newAppeals: LpNewAppealsTable[];
  lpNewAppeals: LpNewAppealsTable[];
  lpAppealsCount: LpAppealCountModel;
  createLpNewAppealData: LpCreateNewAppeal;
  lpAppealsHeaderData: LpAppealsCategoryDataModel;
  lpAppealsCategoryData: LpActionPlanCategoryDataModel[];
  lpAppealAccordianData: LpAppealsAccordionDataModel[];
  lpCommentData: LpAppealCommentsDataModel;
}
export const appealsInitialState: ILpAppealsState = {
  lpInProcessAppeals: [],
  lpClosedAppeals: [],
  lpAppealsDecision: null,
  setLpAppealsDecision: null,
  lpFilteredData: null,
  appealsCount: null,
  lpClosedData: null,
  newAppeals: [],
  lpNewAppeals: [],
  lpAppealsCount: null,
  createLpNewAppealData: null,
  lpAppealsHeaderData: null,
  lpAppealsCategoryData: null,
  lpAppealAccordianData: null,
  lpCommentData: null,
  loading: {
    createLpNewAppeal: false,
    getLpInProcessAppeals: false,
    getLpClosedAppeals: false,
    setLpAppealsDecision: false,
    lpFilteredData: false,
    lpClosedData: false,
    getLpAppealsCount: false,
    getLpNewAppeals: false,
    lpAppealsHeaderData: false,
    lpAppealsCategoryData: false,
    lpCommentData: false,
    getLpAppealAccordianData: false
  },
  error: {
    createLpNewAppeal: null,
    getLpInProcessAppeals: null,
    getLpClosedAppeals: null,
    lpFilteredData: null,
    setLpAppealsDecision: null,
    lpClosedData: null,
    getLpAppealsCount: null,
    getLpNewAppeals: null,
    lpAppealsHeaderData: null,
    lpAppealsCategoryData: null,
    lpCommentData: null,
    getLpAppealAccordianData: null
  }
};
export const lpAppealsReducer = (
  state = appealsInitialState,
  action: { payload: any; type: string }
): ILpAppealsState => {
  switch (action.type) {
    case LP.Appeals.GET_LP_IN_PROCESS_APPEALS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpInProcessAppeals: true },
        error: { ...state.error, getLpInProcessAppeals: null }
      };
    case LP.Appeals.GET_LP_IN_PROCESS_APPEALS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpInProcessAppeals: false },
        error: { ...state.error, getLpInProcessAppeals: null },
        lpInProcessAppeals: action.payload
      };
    case LP.Appeals.GET_LP_IN_PROCESS_APPEALS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpInProcessAppeals: false },
        error: { ...state.error, getLpInProcessAppeals: action.payload }
      };

    case LP.Appeals.GET_LP_CLOSED_APPEALS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpClosedAppeals: true },
        error: { ...state.error, getLpClosedAppeals: null }
      };
    case LP.Appeals.GET_LP_CLOSED_APPEALS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpClosedAppeals: false },
        error: { ...state.error, getLpClosedAppeals: null },
        lpClosedAppeals: action.payload
      };
    case LP.Appeals.GET_LP_CLOSED_APPEALS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpClosedAppeals: false },
        error: { ...state.error, getLpClosedAppeals: action.payload }
      };
    case LP.SET_LP_APPEAL_FILTER.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpFilteredData: true },
        error: { ...state.error, lpFilteredData: null },
        lpAppealsDecision: null
      };
    case LP.SET_LP_APPEAL_FILTER.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpFilteredData: false },
        error: { ...state.error, lpFilteredData: null },
        lpFilteredData: action.payload
      };
    case LP.SET_LP_APPEAL_FILTER.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpFilteredData: false },
        error: { ...state.error, lpFilteredData: action.payload }
      };

    case LP.Appeals.GET_LP_APPEALS_COUNT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpAppealsCount: true },
        error: { ...state.error, getLpAppealsCount: null }
      };
    case LP.Appeals.GET_LP_APPEALS_COUNT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpAppealsCount: false },
        error: { ...state.error, getLpAppealsCount: null },
        lpAppealsCount: action.payload
      };
    case LP.Appeals.GET_LP_APPEALS_COUNT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpAppealsCount: false },
        error: { ...state.error, getLpAppealsCount: action.payload }
      };
    case LP.Appeals.GET_LP_NEW_APPEALS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpNewAppeals: true },
        error: { ...state.error, getLpNewAppeals: null }
      };
    case LP.Appeals.GET_LP_NEW_APPEALS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpNewAppeals: false },
        error: { ...state.error, getLpNewAppeals: null },
        lpNewAppeals: action.payload
      };
    case LP.Appeals.GET_LP_NEW_APPEALS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpNewAppeals: false },
        error: { ...state.error, getLpNewAppeals: action.payload }
      };

    case LP.Appeals.CREATE_LP_NEW_APPEAL.LOADING:
      return {
        ...state,
        loading: { ...state.loading, createLpNewAppeal: true },
        error: { ...state.error, createLpNewAppeal: null }
      };
    case LP.Appeals.CREATE_LP_NEW_APPEAL.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createLpNewAppeal: false },
        error: { ...state.error, createLpNewAppeal: null },
        createLpNewAppealData: action.payload
      };
    case LP.Appeals.CREATE_LP_NEW_APPEAL.ERROR:
      return {
        ...state,
        loading: { ...state.loading, createLpNewAppeal: false },
        error: { ...state.error, createLpNewAppeal: action.payload }
      };
    case LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpAppealsCategoryData: true, lpAppealsHeaderData: true },
        error: { ...state.error, lpAppealsCategoryData: null, lpAppealsHeaderData: null }
      };
    case LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpAppealsCategoryData: false, lpAppealsHeaderData: false },
        lpAppealsHeaderData: action.payload,
        lpAppealsCategoryData: action.payload.categories
      };
    case LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpAppealsCategoryData: false, lpAppealsHeaderData: false },
        error: {
          ...state.error,
          lpAppealsHeaderData: action.payload,
          lpAppealsCategoryData: action.payload
        }
      };

    case LP.SET_LP_APPEAL_DECISON_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, setLpAppealsDecision: true },
        error: { ...state.error, setLpAppealsDecision: null },
        lpAppealsDecision: null
      };
    case LP.SET_LP_APPEAL_DECISON_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, setLpAppealsDecision: false },
        error: { ...state.error, setLpAppealsDecision: null },
        lpAppealsDecision: true
      };
    case LP.SET_LP_APPEAL_DECISON_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, setLpAppealsDecision: false },
        error: { ...state.error, setLpAppealsDecision: action.payload },
        lpAppealsDecision: null
      };
    case LP.Appeals.LP_POST_APPEAL_COMMENT_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpCommentData: true },
        error: { ...state.error, lpCommentData: null }
      };
    case LP.Appeals.LP_POST_APPEAL_COMMENT_DATA.SUCCESS:
      let data = state.lpAppealAccordianData;
      let dataIndex = data.findIndex(
        (object) =>
          object.opportunityList[0].subCategoryId === action.payload.appealItem.subCategoryId
      );
      if (dataIndex > -1) {
        data[dataIndex].appealerResponse.comment = action.payload.appealItem.commentByAppealer;
        data[dataIndex].appealerResponse.hasAppealed = action.payload.appealItem.hasAppealed;
        data[dataIndex].appealerResponse.file = action.payload.appealItem.appealItemAssets;
      }

      return {
        ...state,
        loading: { ...state.loading, lpCommentData: false },
        error: { ...state.error, lpCommentData: null },
        lpCommentData: action.payload,
        lpAppealAccordianData: data
      };
    case LP.Appeals.LP_POST_APPEAL_COMMENT_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpCommentData: false },
        error: { ...state.error, lpCommentData: action.payload }
      };
    case LP.Appeals.LP_GET_APPEAL_ACCORDION_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLpAppealAccordianData: true },
        error: { ...state.error, getLpAppealAccordianData: "" }
      };
    case LP.Appeals.LP_GET_APPEAL_ACCORDION_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLpAppealAccordianData: false },
        error: { ...state.error, getLpAppealAccordianData: "" },
        lpAppealAccordianData: action.payload
      };
    case LP.Appeals.LP_GET_APPEAL_ACCORDION_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLpAppealAccordianData: false },
        error: { ...state.error, getLpAppealAccordianData: action.payload }
      };

    case LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, lpAppealsCategoryData: true, lpAppealsHeaderData: true },
        error: { ...state.error, lpAppealsCategoryData: null, lpAppealsHeaderData: null }
      };
    case LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, lpAppealsCategoryData: false, lpAppealsHeaderData: false },
        lpAppealsHeaderData: action.payload,
        lpAppealsCategoryData: action.payload.categories
      };
    case LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, lpAppealsCategoryData: false, lpAppealsHeaderData: false },
        error: {
          ...state.error,
          lpAppealsHeaderData: action.payload,
          lpAppealsCategoryData: action.payload
        }
      };
    default:
      return state;
  }
};
