import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { ReportsBuilderRepository } from "@timhortons/common/src/repositories/reportBuilder/reports";
import { AllAreaManagersListDataModel } from "@timhortons/common/src/models/lp/reports";
import { Logger } from "@timhortons/common/src/services/logger";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ReportsBuilderActions } from "@timhortons/common/src/redux/reportBuilder/actions/reportsAction";
import { REPORTBUILDER } from "@timhortons/common/src/redux/reportBuilder/actions/actionTypes";
import {
  categoryList,
  productList
} from "@timhortons/common/src/models/productionSheet/reportBuilder";
import { ReportTypeLevel } from "@timhortons/common/src/components/organisms/builder/template";
import { Roles } from "@timhortons/common/src/constants/roles";

function* getAllCountriesWorkerSaga(
  value: IFluxStandardAction<{
    pageNo: number;
    pageSize: number;
    searchByKeyword: string;
    moduleType: string;
    selectedLevel?: string;
    selectedReportType?: string;
  }>
) {
  const { payload } = value;
  try {
    const response: CountryDataListModel = yield call(
      ReportsBuilderRepository.getAllCountries,
      payload
    );
    yield put(ReportsBuilderActions.getAllCountriesSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllCountriesWorkerSaga");

    yield put(ReportsBuilderActions.getAllCountriesError(error));
  }
}
function* getAllAreaManagerWorkerSaga(
  value: IFluxStandardAction<{
    user: IUserDetails;
    role: string;
    pageNo: number;
    pageSize: number;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: AllAreaManagersListDataModel = yield call(
      ReportsBuilderRepository.getAllAreaManagers,
      payload
    );
    yield put(ReportsBuilderActions.getAllAreaManagerSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllAreaManagerWorkerSaga");

    yield put(ReportsBuilderActions.getAllAreaManagerError(error));
  }
}

function* getAllAreaTrainerWorkerSaga(
  value: IFluxStandardAction<{
    user: IUserDetails;
    role: string;
    pageNo: number;
    pageSize: number;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: AllAreaManagersListDataModel = yield call(
      ReportsBuilderRepository.getAllAreaTrainer,
      payload
    );
    yield put(ReportsBuilderActions.getAllAreaTrainerSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllAreaTrainerWorkerSaga");

    yield put(ReportsBuilderActions.getAllAreaTrainerError(error));
  }
}

function* getAllOpsManagerWorkerSaga(
  value: IFluxStandardAction<{
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: any = yield call(ReportsBuilderRepository.getAllOpsManagers, payload);
    yield put(ReportsBuilderActions.getAllOpsManagerSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllOpsManagerWorkerSaga");

    yield put(ReportsBuilderActions.getAllOpsManagerError(error));
  }
}

function* getAllCountryManagerWorkerSaga(
  value: IFluxStandardAction<{
    pageNo: number;
    pageSize: number;
    user: IUserDetails;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    selectedReportType: ReportTypeLevel;
  }>
) {
  const { payload } = value;
  try {
    const response: AllAreaManagersListDataModel = yield call(
      ReportsBuilderRepository.getAllCountryManagers,
      payload
    );
    yield put(ReportsBuilderActions.getAllCountryManagerSuccess(response));
  } catch (error) {
    Logger.error("LP/reports", error, "getAllCountryManagerWorkerSaga");

    yield put(ReportsBuilderActions.getAllCountryManagerError(error));
  }
}
function* getAllClusterHeadOfOpsWorkerSaga(
  value: IFluxStandardAction<{
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    selectedReportType: ReportTypeLevel;
  }>
) {
  const { payload } = value;
  try {
    const response: any = yield call(ReportsBuilderRepository.getAllClusterHeadOfOps, payload);
    yield put(ReportsBuilderActions.getAllClusterHeadOfOpsSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllClusterHeadOfOpsWorkerSaga");

    yield put(ReportsBuilderActions.getAllClusterHeadOfOpsError(error));
  }
}
function* getAllChannelsWorkerSaga(
  value: IFluxStandardAction<{
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: ChannelsListDataModel = yield call(
      ReportsBuilderRepository.getAllChannels,
      payload
    );
    yield put(ReportsBuilderActions.getAllChannelsSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllChannelsWorkerSaga");

    yield put(ReportsBuilderActions.getAllChannelsError(error));
  }
}
function* getAllStoresWorkerSaga(
  value: IFluxStandardAction<{
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    userRole: Roles;
  }>
) {
  const { payload } = value;
  try {
    const response: AllStoresListDataModel = yield call(
      ReportsBuilderRepository.getAllStores,
      payload
    );
    yield put(ReportsBuilderActions.getAllStoresSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllStoresWorkerSaga");

    yield put(ReportsBuilderActions.getAllStoresError(error));
  }
}
function* comparisonAreaManagerAdminWorkerSaga(
  value: IFluxStandardAction<{
    pageNo: number;
    pageSize: number;
    searchByKeyword: string;
    moduleType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: {} = yield call(ReportsBuilderRepository.getComparisonAreaManager, payload);
    yield put(ReportsBuilderActions.comparisonAreaManagersAdminSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "comparisonAreaManagerAdminWorkerSaga");

    yield put(ReportsBuilderActions.comparisonAreaManagersAdminError(error));
  }
}
function* comparisonCountryManagerAdminWorkerSaga(
  value: IFluxStandardAction<{
    pageNo: number;
    pageSize: number;
    searchByKeyword: string;
    moduleType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: {} = yield call(ReportsBuilderRepository.getComparisonCountryManager, payload);
    yield put(ReportsBuilderActions.comparisonCountryManagersAdminSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "comparisonCountryManagerAdminWorkerSaga");

    yield put(ReportsBuilderActions.comparisonCountryManagersAdminError(error));
  }
}
function* comparisonStoresAdminWorkerSaga(
  value: IFluxStandardAction<{
    pageNo: number;
    pageSize: number;
    moduleType: string;
    searchByKeyword: string;
  }>
) {
  const { payload } = value;
  try {
    const response: AllStoresListDataModel = yield call(
      ReportsBuilderRepository.getComparisonStoresAdmin,
      payload
    );
    yield put(ReportsBuilderActions.comparisonStoresAdminSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "comparisonStoresAdminWorkerSaga");

    yield put(ReportsBuilderActions.comparisonStoresAdminError(error));
  }
}
function* highLowChannelsAdminWorkerSaga(
  value: IFluxStandardAction<{
    pageNo: number;
    pageSize: number;
    searchByKeyword: string;
    moduleType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: ChannelsListDataModel = yield call(
      ReportsBuilderRepository.getHighLowChannelsAdmin,
      payload
    );
    yield put(ReportsBuilderActions.highlowChannelsAdminSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "highLowChannelsAdminWorkerSaga");

    yield put(ReportsBuilderActions.highlowChannelsAdminError(error));
  }
}
function* getKPIdataWorkerSaga(
  value: IFluxStandardAction<{
    moduleType: string;
  }>
) {
  const { payload } = value;
  try {
    const response: any[] = yield call(ReportsBuilderRepository.getKPIdata, payload);
    yield put(ReportsBuilderActions.getKpiDataSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getKPIdataWorkerSaga");

    yield put(ReportsBuilderActions.getKpiDataError(error));
  }
}
function* getCategoryWorkerSaga(
  value: IFluxStandardAction<{
    idList: any;
    level: string;
    station: string;
    countryCodes: any;
    allSelected: boolean;
  }>
) {
  const { payload } = value;
  try {
    const response: categoryList[] = yield call(ReportsBuilderRepository.getCategoryList, payload);
    yield put(ReportsBuilderActions.getCategorySuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getCategory");
    yield put(ReportsBuilderActions.getCategoryError(error));
  }
}
function* getProductWorkerSaga(
  value: IFluxStandardAction<{
    idList: any;
    level: string;
    station: string;
    categoryId: string;
    countryCodes: any;
    allSelected: boolean;
  }>
) {
  const { payload } = value;
  try {
    const response: productList[] = yield call(ReportsBuilderRepository.getProductList, payload);
    yield put(ReportsBuilderActions.getProductSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getProduct");
    yield put(ReportsBuilderActions.getProductError(error));
  }
}

function* getAllLpAuditorWorkerSaga(
  value: IFluxStandardAction<{
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    reportType: string;
    moduleType: string;
  }>
) {
  const { payload } = value;
  //TODO:Model
  try {
    const response: CountryDataListModel = yield call(
      ReportsBuilderRepository.getAllLpAuditor,
      payload
    );
    yield put(ReportsBuilderActions.getAllLpAuditorSuccess(response));
  } catch (error) {
    Logger.error("reportBuilder", error, "getAllLpAuditorWorkerSaga");

    yield put(ReportsBuilderActions.getAllLpAuditorError(error));
  }
}

export default function* ReportsBuilderWatcherSaga() {
  yield takeLatest(REPORTBUILDER.GET_ALL_COUNTRIES.LOADING, getAllCountriesWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_ALL_AREA_MANAGER.LOADING, getAllAreaManagerWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_ALL_AREA_TRAINER.LOADING, getAllAreaTrainerWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_ALL_OPS_MANAGER.LOADING, getAllOpsManagerWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_ALL_CHANNELS.LOADING, getAllChannelsWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_ALL_STORES.LOADING, getAllStoresWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_ALL_COUNTRY_MANAGER.LOADING, getAllCountryManagerWorkerSaga);
  yield takeLatest(
    REPORTBUILDER.GET_ALL_CLUSTER_HEAD_OF_OPS.LOADING,
    getAllClusterHeadOfOpsWorkerSaga
  );
  yield takeLatest(
    REPORTBUILDER.GET_COMPARISON_AREA_MANAGERS_ADMIN.LOADING,
    comparisonAreaManagerAdminWorkerSaga
  );
  yield takeLatest(
    REPORTBUILDER.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.LOADING,
    comparisonCountryManagerAdminWorkerSaga
  );
  yield takeLatest(
    REPORTBUILDER.GET_COMPARISON_STORES_ADMIN.LOADING,
    comparisonStoresAdminWorkerSaga
  );
  yield takeLatest(
    REPORTBUILDER.GET_HIGHLOW_CHANNELS_ADMIN.LOADING,
    highLowChannelsAdminWorkerSaga
  );

  yield takeLatest(REPORTBUILDER.GET_KPI_DATA.LOADING, getKPIdataWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_CATEGORY.LOADING, getCategoryWorkerSaga);
  yield takeLatest(REPORTBUILDER.GET_PRODUCT.LOADING, getProductWorkerSaga);

  yield takeLatest(REPORTBUILDER.GET_ALL_LP_AUDITOR.LOADING, getAllLpAuditorWorkerSaga);
}
