import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { LogShiftModel } from "@timhortons/common/src/models/logs/mainLogs";
import { Decision } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/timeTempActionTab";

export interface IManagerWalkthroughActionsLoading {
  getActionManagerWalkthroughShift: boolean;
  getActionManagerWalkthroughActions: boolean;
  updateActionManagerWalkthroughActions: boolean;
}
export interface IManagerWalkthroughActionsError {
  getActionManagerWalkthroughShift: string;
  getActionManagerWalkthroughActions: string;
  updateActionManagerWalkthroughActions: string;
}
export interface IManagerWalkthroughActionsState {
  actionManagerWalkthoughShift: LogShiftModel[];
  actionManagerWalkthroughActions: any;
  updateManagerWalkthroughActions: any;
  loading: IManagerWalkthroughActionsLoading;
  error: IManagerWalkthroughActionsError;
}

export const managerWalkthroughActionsInitialState: IManagerWalkthroughActionsState = {
  actionManagerWalkthoughShift: null,
  actionManagerWalkthroughActions: null,
  updateManagerWalkthroughActions: null,
  loading: {
    getActionManagerWalkthroughShift: false,
    getActionManagerWalkthroughActions: false,
    updateActionManagerWalkthroughActions: false
  },
  error: {
    getActionManagerWalkthroughShift: null,
    getActionManagerWalkthroughActions: null,
    updateActionManagerWalkthroughActions: null
  }
};

export const managerWalkthroughActionsReducer = (
  state = managerWalkthroughActionsInitialState,
  action: { payload: any; type: string }
): IManagerWalkthroughActionsState => {
  switch (action.type) {
    case LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALTHROUGH_SHIFTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionManagerWalkthroughShift: true },
        error: { ...state.error, getActionManagerWalkthroughShift: null }
      };
    case LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALTHROUGH_SHIFTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getActionManagerWalkthroughShift: false },
        error: { ...state.error, getActionManagerWalkthroughShift: null },
        actionManagerWalkthoughShift: action.payload
      };
    case LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALTHROUGH_SHIFTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionManagerWalkthroughShift: false },
        error: { ...state.error, getActionManagerWalkthroughShift: action.payload }
      };
    case LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getActionManagerWalkthroughActions: true },
        error: { ...state.error, getActionManagerWalkthroughActions: null }
      };
    case LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getActionManagerWalkthroughActions: false },
        error: { ...state.error, getActionManagerWalkthroughActions: null },
        actionManagerWalkthroughActions: action.payload
      };
    case LOGS.Actions.ManagerWalk.GET_ACTION_MANAGER_WALKTHROUGH_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getActionManagerWalkthroughActions: false },
        error: { ...state.error, getActionManagerWalkthroughActions: action.payload }
      };

    case LOGS.Actions.ManagerWalk.UPDATE_MANAGER_WALKTHROUGH_ACTIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateActionManagerWalkthroughActions: true },
        error: { ...state.error, updateActionManagerWalkthroughActions: null }
      };
    case LOGS.Actions.ManagerWalk.UPDATE_MANAGER_WALKTHROUGH_ACTIONS.SUCCESS:
      let data = state.actionManagerWalkthroughActions;
      let index = data && data?.findIndex((item: any) => item.uniqueId === action.payload.uniqueId);
      if (index > -1) {
        data[index].decision = action.payload.hasResolved
          ? Decision.resolved
          : Decision.notResolved;
      }
      return {
        ...state,
        loading: { ...state.loading, updateActionManagerWalkthroughActions: false },
        error: { ...state.error, updateActionManagerWalkthroughActions: null },
        updateManagerWalkthroughActions: action.payload,
        actionManagerWalkthroughActions: data
      };
    case LOGS.Actions.ManagerWalk.UPDATE_MANAGER_WALKTHROUGH_ACTIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateActionManagerWalkthroughActions: false },
        error: { ...state.error, updateActionManagerWalkthroughActions: action.payload }
      };
    case LOGS.Actions.ManagerWalk.RESET_MWT_ACTIONS__STATE:
      return managerWalkthroughActionsInitialState;
    case LOGS.Actions.ManagerWalk.RESET_MWT_ACTIONS_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          updateActionManagerWalkthroughActions: null
        }
      };
    default:
      return state;
  }
};
