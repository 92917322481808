export const graphColors = {
  // gradeGraph: ["#4EAC5B", "#A0CD63", "#F7CE47", "#EF9B39", "#EB3223"],
  gradeGraph: ["#C8102E", "#C8102E", "#C8102E", "#C8102E", "#C8102E"],
  gradeGraph2: ["#30171A", "#30171A", "#30171A", "#30171A", "#30171A"],

  // actionPlanGraph: ["#C35C57", "#6FCF97"],
  actionPlanGraph: ["#DDCBA4", "#C8102E", "#3F2021"],
  learningGraph: ["#CE0E2D", "#EAC895", "#4D2C0A"],
  lmsReportGraph: ["#CE0E2D", "#4D2C0A", "#EAC895"],
  // fsDeductionGraph: [
  //   "#4EA              C5B",
  //   "#EF9B39",
  //   "#EF9B39",
  //   "#EB3223",
  //   "#EB3223",
  //   "#EB3223",
  //   "#EB3223",
  //   "#EB3223",
  //   "#EB3223",
  //   "#EB3223",
  //   "#EB3223",
  //   "#EB3223"
  // ],
  fsDeductionGraph: [
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E",
    "#C8102E"
  ],
  // fsDeductionGraphLabel: ["#4EAC5B", "#F7CE47", "#EB3223"],
  // bsGraph: ["#9E5964"],
  bsGraph: ["#3F2021"],
  logsSummary: ["#7DA659", "#CF122C"]
};
export const fetchYMax = (arr: number[], index: number) => {
  return arr ? Math.ceil(Math.max(...arr) / index) * index : null;
};
