export interface IReportBuilderDetails {
  name: string;
  value?: any;
  errorMsg?: string;
}

export const getField = (): IReportBuilderDetails[] => [
  {
    name: "Select Country",
    value: []
  },
  {
    name: "Select Area Manager",
    value: [],
    errorMsg: "Select Country first"
  },
  {
    name: "Select Country Manager",
    value: [],
    errorMsg: "Select Country first"
  },
  {
    name: "Select Channel",
    value: [],
    errorMsg: "Select Country first"
  },
  {
    name: "Select Store",
    value: [],
    errorMsg: "Select Country first"
  },
  {
    name: "Select Operational Manager",
    value: [],
    errorMsg: "Select Country first"
  },
  {
    name: "Select LP Auditor",
    value: [],
    errorMsg: "Select Country first"
  },
  {
    name: "Select Cluster Head of Ops",
    value: [],
    errorMsg: "Select Country first"
  }
];
