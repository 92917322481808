import React from "react";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize, Text, Label } from "@timhortons/common/src/components/atoms/text";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { BrandStandardKPIDataModel } from "@timhortons/common/src/models/rev/reports";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";
import {
  gradeLabelStyle,
  gradingStyle
} from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportAuditTable";

interface IProps {
  loading: boolean;
  translate: any;
  showSos?: boolean;
  headerTitle?: string;
  brandStandardKPIData: BrandStandardKPIDataModel[];
  handlePagination: (pageNum: number) => void;
  showOverallScore?: boolean;
  headerDataParent?: IHeaderData[];
  upperHeaderDataParent?: IHeaderData[];
  type?: string;
  pageNum: number;
  totalElements: number;
  error: string;
  showOnlyAvgScore?: boolean;
}

export interface IHeaderData {
  id: string;
  name: string;
  cellStyle?: StyleProp<ViewStyle>;
  clickable?: boolean;
}

export default function KpiBrandStandardTable(props: IProps) {
  const {
    translate,
    brandStandardKPIData,
    headerTitle = translate("By Stores"),
    showSos = true,
    loading = false,
    handlePagination,
    showOverallScore,
    type,
    pageNum = 0,
    totalElements,
    error,
    showOnlyAvgScore = false
  } = props;
  const upperHeaderData: IHeaderData[] = [
    { id: "0", name: "", cellStyle: styles.flexStyle },
    {
      id: "1",
      name: translate("Score (Average)"),
      cellStyle: [showOverallScore ? { flex: 3 } : { flex: 2 }, styles.upperheaderColor]
    },
    {
      id: "2",
      name: translate("Score by KPI"),
      cellStyle: [{ flex: 6 }, styles.upperheaderColor]
    },
    {
      id: "3",
      name: translate("SOS"),
      cellStyle: [{ flex: 2 }, styles.upperheaderColor]
    }
  ];

  const headerData: IHeaderData[] = [
    { id: "1", name: headerTitle },
    showOverallScore && { id: "12", name: translate("overall") },
    { id: "2", name: translate(type === "reportBuilder" ? "foodSafety" : "brandStandards") },
    { id: "3", name: translate(type === "reportBuilder" ? "brandStandards" : "foodSafety") },
    { id: "4", name: translate("C & M") },
    { id: "5", name: translate("G.U.E.S.T") },
    { id: "6", name: translate("SOS") },
    { id: "7", name: translate("P & SM") },
    { id: "8", name: translate("Training") },
    { id: "9", name: translate("Food Quality") },
    { id: "10", name: translate("FC") },
    { id: "11", name: translate("DT") }
  ];

  const getDividerUI = (): JSX.Element => {
    return <Divider orientation={Orientations.Vertical} dividerStyle={styles.dashedDivider} />;
  };

  const getTableRowUI = (item: BrandStandardKPIDataModel, header: boolean): JSX.Element => {
    return (
      <Row rowStyle={styles.flexStyle}>
        <Cell
          title={item.storeName}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
        {showOverallScore && (
          <Cell cellStyle={header && styles.headerColor}>
            <Label
              textSize={TextSize.ExtraMedium}
              fontWeight={FontWeight.SemiBold}
              testId="id"
              style={[styles.textStyleChip, gradeLabelStyle(item.grade)]}
            >
              <Text
                textSize={TextSize.Regular}
                fontWeight={FontWeight.SemiBold}
                testId="id"
                textStyle={gradingStyle(item.grade)}
              >
                {item.grade} -
                {item.overallScore === 0 || item.overallScore === null ? 0 : item.overallScore}
                {item.overallScore || item.overallScore == 0 ? "%" : ""}
              </Text>
            </Label>
          </Cell>
        )}
        <Cell
          title={type === "reportBuilder" ? item.foodSafety + "pts." : item.brandStandard + "%"}
          cellStyle={header && styles.headerColor}
        />
        <Cell
          title={type === "reportBuilder" ? item.brandStandard + "%" : item.foodSafety + "pts."}
          cellStyle={header && styles.headerColor}
        />
        {!showOnlyAvgScore && (
          <>
            {getDividerUI()}
            <Cell title={item.cleanliness + "%"} cellStyle={header && styles.headerColor} />
            <Cell title={item.guest + "%"} cellStyle={header && styles.headerColor} />
            <Cell title={item.sos + "%"} cellStyle={header && styles.headerColor} />
            <Cell title={item.profitability + "%"} cellStyle={header && styles.headerColor} />
            <Cell title={item.training + "%"} cellStyle={header && styles.headerColor} />
            <Cell title={item.foodQuality + "%"} cellStyle={header && styles.headerColor} />
            {showSos && (
              <>
                {getDividerUI()}
                <Cell title={item.fc} cellStyle={header && styles.headerColor} />
                <Cell title={item.dt} cellStyle={header && styles.headerColor} />
              </>
            )}
          </>
        )}
      </Row>
    );
  };

  const getUpperHeaderUI = (upperData: IHeaderData[]) => {
    return upperData.map((item: IHeaderData, index: number) => (
      <React.Fragment key={index}>
        {(index === 2 || index === 3) && getDividerUI()}
        <HeaderCell
          title={item.name}
          cellStyle={[item.cellStyle, index === 0 && styles.contentCell]}
          fontWeight={FontWeight.Bold}
          textSize={TextSize.ExtraRegular}
        />
      </React.Fragment>
    ));
  };

  const getHeaderUI = (header: IHeaderData[]) => {
    return header
      .filter((i) => i !== undefined)
      .map((item: IHeaderData, index: number) => (
        <React.Fragment key={index}>
          {(item.id === "4" || item.id === "10") && getDividerUI()}
          <HeaderCell
            title={item && item.name}
            cellStyle={index === 0 && styles.contentCell}
            wrapperStyles={{
              justifyContent: index === 0 ? "flex-start" : "center",
              flex: 1,
              backgroundColor: colorPallete.white2
            }}
            titleStyle={item.name !== "Overall" ? styles.centerAlign : styles.overAllcenterAlign}
          />
        </React.Fragment>
      ));
  };

  return (
    <View style={[styles.flexStyle, styles.container]}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        testId="opportunity table"
        textStyle={styles.headerTextStyle}
      >
        {translate("brandStandardKpi")}
      </Text>
      <Table>
        <Row header>
          {showOnlyAvgScore
            ? getUpperHeaderUI(upperHeaderData.slice(0, 2))
            : showSos
            ? getUpperHeaderUI(upperHeaderData)
            : getUpperHeaderUI(upperHeaderData.slice(0, -1))}
        </Row>
        <Row header rowStyle={{ backgroundColor: colorPallete.white2 }}>
          {showOnlyAvgScore
            ? getHeaderUI(headerData).slice(0, 4)
            : showSos
            ? getHeaderUI(headerData)
            : getHeaderUI(headerData.slice(0, -2))}
        </Row>
        {loading ? (
          <Loader type="component" />
        ) : error ? (
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        ) : brandStandardKPIData && brandStandardKPIData.length > 0 ? (
          <>
            <FlatList
              extractor={(item) => item.id.toString()}
              listData={
                brandStandardKPIData.length > 1
                  ? brandStandardKPIData.slice(0, -1)
                  : brandStandardKPIData
              }
              seperator={() => <Divider dividerStyle={styles.solidDivider} />}
              listRenderer={(item: BrandStandardKPIDataModel) => getTableRowUI(item, false)}
            />
            {brandStandardKPIData.length > 1 && (
              <Row header>
                {getTableRowUI(brandStandardKPIData[brandStandardKPIData.length - 1], true)}
              </Row>
            )}
          </>
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
        )}
      </Table>
      {brandStandardKPIData && brandStandardKPIData.length > 0 && (
        <CommonPagination
          handlePagination={(val: number) => handlePagination(val - 1)}
          pageNum={pageNum + 1}
          totalRecords={totalElements}
          pageLimit={5}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7
  },
  dashedDivider: {
    borderWidth: 1,
    borderRadius: 0.1,
    borderStyle: "dashed",
    borderColor: colorPallete.grey7
  },
  solidDivider: {
    borderColor: colorPallete.white1
  },
  contentCell: {
    justifyContent: "flex-start",
    // backgroundColor: colorPallete.white2,
    ...I18nService.select({
      rtl: { paddingEnd: 18 },
      ltr: { paddingStart: 18 }
    })
  },
  upperheaderColor: {
    backgroundColor: colorPallete.variant16
  },
  centerAlign: {
    textAlign: "center"
  },
  overAllcenterAlign: {
    textAlign: "center",
    fontWeight: FontWeight.Bold
  },
  flexStyle: {
    flex: 1
  },
  headerColor: {
    backgroundColor: colorPallete.white2
  },
  headerTextStyle: {
    padding: 24
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center"
    // backgroundColor: colorPallete.grey8
  }
});
