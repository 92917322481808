import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import RadioGroup from "@timhortons/common/src/components/molecules/radioGroup";
import MultiSelect, { IItem } from "@timhortons/common/src/components/molecules/multiSelect";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { levelOfReportStyles } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/levelOfReports/levelOfReportStyles";
import { DataInReportLevel, ReportTypeLevel } from "../../template";
import { builderLevel } from "../..";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Roles } from "@timhortons/common/src/constants/roles";
import ModalDropdown from "@timhortons/common/src/components/molecules/modalDropdown";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";

interface IProps {
  translate?: any;
  reportData?: ILPDataInReportData;
  setSelectedReportLevel?: Function;
  selectedLevel?: DataInReportLevel;
  selectedKPI: IItem[];
  handleSelectedValues: Function;
  handleCategorySelect: Function;
  handleProductSelect: Function;
  moduleType: string;
  user?: IUserDetails;
  prodSheetData?: any;
  selectedReportType?: ReportTypeLevel;
}
export interface ILPDataInReportData {
  LPKPIsData?: IItem[];
  overallScore?: boolean;
  criticalPoints?: boolean;
  isKPI?: boolean;
  isBaking?: boolean;
  isOthers?: boolean;
}
export default function DataInReport(props: IProps) {
  const {
    translate,
    moduleType,
    reportData,
    setSelectedReportLevel,
    selectedLevel,
    selectedKPI,
    handleSelectedValues,
    handleCategorySelect,
    handleProductSelect,
    user,
    prodSheetData,
    selectedReportType
  } = props;

  const lpDataInReportOptions = [
    {
      name: translate("ShowByOverallScore"),
      key: DataInReportLevel.ShowByOverallScore,
      onPress: () => {
        setSelectedReportLevel(DataInReportLevel.ShowByOverallScore);
        handleSelectedValues && handleSelectedValues([]);
      },
      isSelected: DataInReportLevel.ShowByOverallScore === selectedLevel
    },
    {
      name: translate("ShowByKPIs"),
      key: DataInReportLevel.ShowByKPIs,
      onPress: () => {
        setSelectedReportLevel(DataInReportLevel.ShowByKPIs);
      },
      isSelected: DataInReportLevel.ShowByKPIs === selectedLevel
    },
    {
      name: translate("ShowByCriticalPoints"),
      key: DataInReportLevel.ShowByCriticalPoints,
      onPress: () => {
        setSelectedReportLevel(DataInReportLevel.ShowByCriticalPoints);
        handleSelectedValues && handleSelectedValues([]);
      },
      isSelected: DataInReportLevel.ShowByCriticalPoints === selectedLevel
    }
  ];
  const revDataInReportOptions = [
    {
      name: translate("ShowByOverallScore"),
      key: DataInReportLevel.ShowByOverallScore,
      onPress: () => setSelectedReportLevel(DataInReportLevel.ShowByOverallScore),
      isSelected: DataInReportLevel.ShowByOverallScore === selectedLevel
    },
    {
      name: translate("ShowByKPIs"),
      key: DataInReportLevel.ShowByKPIs,
      onPress: () => setSelectedReportLevel(DataInReportLevel.ShowByKPIs),
      isSelected: DataInReportLevel.ShowByKPIs === selectedLevel
    }
  ];
  const prodSheetDataInReportOptions = [
    {
      name: translate("Baking"),
      key: DataInReportLevel.Baking,
      onPress: () => setSelectedReportLevel(DataInReportLevel.Baking),
      isSelected: DataInReportLevel.Baking === selectedLevel
    },
    {
      name: translate("Others"),
      key: DataInReportLevel.Others,
      onPress: () => setSelectedReportLevel(DataInReportLevel.Others),
      isSelected: DataInReportLevel.Others === selectedLevel
    }
  ];

  const { LPKPIsData } = {
    ...reportData
  };
  const preSelectedKPIValues: Array<string> = useMemo(() => {
    let values: Array<string> = [];
    if (selectedKPI?.length > 0) {
      values = selectedKPI.map((item: IItem) => item.value);
    }
    return values;
  }, [selectedKPI]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {user?.roles?.includes(Roles.StoreAccess) ? (
          <Text
            fontWeight={FontWeight.SemiBold}
            textSize={TextSize.ExtraLarge}
            testId={testIds.reportBuilder.dataInReport}
            textStyle={styles.headerText}
          >
            {translate("StoreDataInReport")}
          </Text>
        ) : (
          <Text
            fontWeight={FontWeight.SemiBold}
            textSize={TextSize.ExtraLarge}
            testId={testIds.reportBuilder.dataInReport}
            textStyle={styles.headerText}
          >
            {translate("3. Data in Report")}
          </Text>
        )}
      </View>
      <View style={styles.elementContainer}>
        <View style={styles.radioGroupContainer}>
          <RadioGroup
            isBorder={true}
            radioOptions={
              moduleType === builderLevel.isLp
                ? lpDataInReportOptions
                : moduleType === builderLevel.isRev
                ? revDataInReportOptions
                : moduleType === builderLevel.isProdSheet && prodSheetDataInReportOptions
            }
          />
        </View>
        {DataInReportLevel.ShowByKPIs === selectedLevel && LPKPIsData?.length > 0 && (
          <>
            <Text
              textSize={TextSize.ExtraRegular}
              fontWeight={FontWeight.Regular}
              testId="dropdown"
              textStyle={levelOfReportStyles.dropdownLabel}
            >
              {translate("SelectKPIs")}
            </Text>
            <View style={styles.dropDownContainer}>
              <MultiSelect
                allItems={LPKPIsData}
                title={translate("SelectKPIs")}
                isSearchRequired={true}
                handleSelectedValues={(vals: IItem[]) => {
                  handleSelectedValues && handleSelectedValues(vals);
                }}
                enableChildSelectAll={true}
                selectedValues={preSelectedKPIValues}
              />
            </View>
          </>
        )}
        {(DataInReportLevel.Baking === selectedLevel ||
          DataInReportLevel.Others === selectedLevel) && (
          <>
            <>
              <Text
                textSize={TextSize.ExtraRegular}
                fontWeight={FontWeight.Regular}
                testId="dropdown"
                textStyle={levelOfReportStyles.dropdownLabel}
              >
                {translate("Select Category")}
              </Text>
              <View style={styles.dropDownContainer}>
                {/* <MultiSelect
                  allItems={prodSheetData.allCategory}
                  title={translate("Select Category")}
                  isSearchRequired={true}
                  handleSelectedValues={(vals: IItem[]) => {
                    handleCategorySelect && handleCategorySelect(vals);
                  }}
                /> */}
                {/* <View style={levelOfReportStyles.dropdown}> */}
                <ModalDropdown
                  // isSearchRequired={isSearchReq}
                  allItems={prodSheetData.allCategory}
                  // onEndReached={onEndReached}
                  dropdownViewStyle={levelOfReportStyles.singleSelect}
                  iconName={IconNames.filledDown}
                  dropdownIconStyle={levelOfReportStyles.singleSelectIcon}
                  selectedItemStyle={levelOfReportStyles.singleSelectedLabel}
                  selectedValueDefault={translate("Select Category")}
                  defaultDropdownText={translate("Select category")}
                  // handleApiCall={() => checkApiCall(null, apiCall, label)}
                  // emptyMsg={handleEmpty(null, label)}
                  // setSearchText={setSearchText}
                  onChangeCallBack={(options) => {
                    handleCategorySelect && handleCategorySelect(options);
                  }}
                  // defaultDropdownText={label}
                />
                {/* </View> */}
              </View>
            </>

            {selectedReportType === ReportTypeLevel.Detailed && (
              <View style={{ marginTop: 30 }}>
                <Text
                  textSize={TextSize.ExtraRegular}
                  fontWeight={FontWeight.Regular}
                  testId="dropdown"
                  textStyle={levelOfReportStyles.dropdownLabel}
                >
                  {translate("Select Product")}
                </Text>
                <View style={styles.dropDownContainer}>
                  <MultiSelect
                    allItems={prodSheetData.allProducts}
                    title={translate("Select products")}
                    isSearchRequired={true}
                    handleSelectedValues={(vals: IItem[]) => {
                      handleProductSelect && handleProductSelect(vals);
                    }}
                    enableChildSelectAll={true}
                  />
                </View>
              </View>
            )}
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {
    flex: 1
  },
  headerText: {
    color: colorPallete.black3
  },
  elementContainer: {
    maxWidth: 530,
    marginTop: 24
  },
  radioGroupContainer: {
    marginBottom: 24,
    flexDirection: "row"
  },
  dropDownContainer: {}
});
