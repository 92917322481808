import { AllLPAuditorDataModel } from "./../../models/lp/reports";
import { LPKeys, REVKeys } from "@timhortons/common/src/constants/apiKeys";
import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import {
  formatterReports,
  gradingReportBuilderFormatter
} from "@timhortons/common/src/repositories/lp/reportFormatter";
import {
  AckDropDownModel,
  LpAcknowledgementModel,
  LpPostAcknowledgementModel,
  LpReportTableModel,
  LpReportsSummaryOpportunityDataModel,
  AllAreaManagersListDataModel,
  KPIDataModel,
  LPAllStoresDataModel,
  OpsManagerDataModel,
  OpsManagerModel,
  RevKPIDataModel,
  RevKPIElementModel,
  AllLPAuditorListDataModel
} from "@timhortons/common/src/models/lp/reports";
import {
  LPEndPoints,
  productionSheetEndPoints,
  profileEndPoints
} from "@timhortons/common/src/constants/endPoints";
import {
  lpStoreAccessSummaryFormatter,
  lpIndividualAccessSummaryFormatter,
  reportBuilderFormatter
} from "@timhortons/common/src/repositories/lp/reportFormatter";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { CEOEqvRoles, Roles } from "@timhortons/common/src/constants/roles";
import {
  AllAreaManagersDataModel,
  AllStoresListDataModel,
  ChannelsDataModel,
  ChannelsListDataModel,
  ComparisonStoresAdminModel,
  ComparisonStoresAdminModelList,
  CountryDataListModel,
  CountryDataModel
} from "@timhortons/common/src/models/profile";
import { reportsEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ReportTypeLevel } from "@timhortons/common/src/components/organisms/builder/template";
import { builderLevel, ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";
import {
  AreaUnderCountryManageDataModelRev,
  AreaUnderCountryMModelRev
} from "@timhortons/common/src/models/rev/reports";
import {
  PSCategoryDropDownFormatter,
  PSProductDropDownFormatter
} from "@timhortons/common/src/repositories/reportBuilder/reportBuilderFormatter";

class ReportsBuilderRepository {
  submitLpAudit = async (param: { auditId: number }) => {
    await ApiClient.put(
      LPEndPoints.reports.submitAudit(param.auditId),
      { isCompleted: true },
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    let reportId: null = null;
    return { auditId: param.auditId, reportId };
  };
  prepareLpReport = async (param: { auditId: number }) => {
    let reportId = await ApiClient.post(
      LPEndPoints.reports.prepareReport(),
      param.auditId.toString(),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    return { auditId: param.auditId, reportId };
  };

  fetchLpReportsData = async (param: { auditId: number; reportId: number }) => {
    const { auditId, reportId } = param;
    let reportsLpMasterData = await ApiClient.get(
      LPEndPoints.reports.fetchReportsData(reportId),
      null,
      LPKeys.LP_REPORTS
    );

    let opportunityLpMasterData = await ApiClient.get(
      LPEndPoints.reports.fetchOpportunityData(auditId),
      null,
      LPKeys.LP_REPORTS
    );

    return formatterReports(opportunityLpMasterData, reportsLpMasterData);
  };

  fetchAcknowledgementData = async (param: { storeCode: string }) => {
    let data = await ApiClient.get(
      LPEndPoints.reports.fetchAcknowledgementData(param.storeCode),
      null,
      LPKeys.LP_REPORTS
    );
    let finalData: LpAcknowledgementModel = {
      dropDownData: []
    };
    data.map((item: AckDropDownModel) => {
      let deserializeVar: AckDropDownModel = deserialize(AckDropDownModel, item);
      finalData.dropDownData.push(deserializeVar);
    });
    return finalData;
  };
  postAcknowledgementData = async (payload: LpPostAcknowledgementModel) => {
    const { reportId, storeInChargeId, file, remarks } = payload;
    let data = await ApiClient.post(
      LPEndPoints.reports.postAcknowledgementData(),
      {
        base64String: file
      },
      {
        reportId,
        storeInChargeId,
        remarks
      },
      LPKeys.LP_STANDARD_AUDIT
    );
    return data;
  };
  fetchReportTableData = async (param: {
    userId: string;
    storeId: number;
    isIndividualLogin: boolean;
    bodyObject: any;
  }) => {
    let response;

    if (param.isIndividualLogin) {
      response = await ApiClient.get(
        LPEndPoints.reports.fetchReportTableDataIndividual(param.userId),
        param.bodyObject,
        LPKeys.LP_REPORT_BUILDER
      );
    } else {
      response = await ApiClient.get(
        LPEndPoints.reports.fetchReportTableDataStore(param.storeId),
        param.bodyObject,
        LPKeys.LP_REPORT_BUILDER
      );
    }
    let data: Array<LpReportTableModel> = response.records.map((item: any) => {
      return deserialize(LpReportTableModel, item);
    });
    return {
      ...response,
      records: data
    };
  };
  fetchLpReportsSummaryStoreAccess = async (param: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => {
    const { storeId, startDate, endDate } = param;
    let response = await ApiClient.get(
      LPEndPoints.reports.fetchLpStoreAccessSummaryData(storeId),
      {
        filterByStartDate: startDate,
        filterByEndDate: endDate
      },
      LPKeys.LP_REPORT_SUMMARY
    );
    return lpStoreAccessSummaryFormatter(response);
  };
  fetchLpIndividualReportsSummary = async (param: { startDate: string; endDate: string }) => {
    let response = await ApiClient.get(
      LPEndPoints.reports.fetchLpIndividualReportsSummaryData(),
      param,
      LPKeys.LP_REPORT_SUMMARY
    );
    return lpIndividualAccessSummaryFormatter(response);
  };

  fetchLpReportsOpportunityStoreAccess = async (param: {
    storeId: number;
    startDate: string;
    endDate: string;
  }) => {
    const { storeId, startDate, endDate } = param;
    let data = await ApiClient.get(
      LPEndPoints.reports.fetchLpStoreAccessOpportunityData(storeId),
      {
        filterByStartDate: startDate,
        filterByEndDate: endDate
      },
      LPKeys.LP_REPORT_SUMMARY
    );
    let finalData: LpReportsSummaryOpportunityDataModel[] = [];
    data.map((item: any) => {
      let deserializeVar: LpReportsSummaryOpportunityDataModel = deserialize(
        LpReportsSummaryOpportunityDataModel,
        item
      );
      finalData.push(deserializeVar);
    });
    return finalData;
  };

  getAllCountries = async (param: {
    pageNo: number;
    pageSize: number;
    searchByKeyword: string;
    moduleType: string;
    selectedLevel?: string;
    selectedReportType?: string;
  }) => {
    const { pageNo, pageSize, searchByKeyword, moduleType, selectedLevel, selectedReportType } =
      param;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    let isAreaOfImprovement: boolean = false;
    if (
      selectedReportType === ReportTypeLevel.AreaOfImprovement &&
      selectedLevel === ComparsionLevel.Country
    ) {
      isAreaOfImprovement = true;
    }
    let data = await ApiClient.get(
      profileEndPoints.getGccCountries(),
      { pageNo, pageSize, searchByKeyword, isAreaOfImprovement },
      keys
    );
    let countriesData: CountryDataListModel = {
      content: [],
      totalElements: null,
      currentPage: 0
    };
    let finalContent: CountryDataModel[] = [];
    data.map((item: CountryDataModel) => {
      let deserializevar: CountryDataModel = deserialize(CountryDataModel, item);
      finalContent.push(deserializevar);
    });

    countriesData.totalElements = data.totalElements;
    countriesData.content = finalContent;
    countriesData.currentPage = param.pageNo;
    return countriesData;
  };
  getAllAreaManagers = async (param: {
    pageNo: number;
    pageSize: number;
    user: IUserDetails;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
  }) => {
    const {
      role,
      countryCode,
      user,
      pageNo,
      pageSize,
      searchByKeyword,
      allSelected,
      reportType,
      moduleType
    } = param;
    let resp;
    const isProdSheet = moduleType === builderLevel.isProdSheet;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    if (
      (user.roles.includes(Roles.SuperAdmin) ||
        user.roles.includes(Roles.HrAndAdminDirector) ||
        user.roles.includes(Roles.ProductionAndDevExecutive) ||
        user.roles.includes(Roles.TrainingAndDevLead) ||
        user.roles.includes(Roles.TrainingAndDevManager) ||
        user.roles.includes(Roles.CEO) ||
        user.roles.includes(Roles.COO) ||
        param.user.roles.includes(Roles.HeadLP) ||
        user.roles.includes(Roles.ClusterHeadofOps) ||
        param.user.roles.includes(Roles.AuditorLP) ||
        param.user.roles.includes(Roles.AreaTrainer)) &&
      reportType === ReportTypeLevel.Detailed
    ) {
      resp = await ApiClient.get(
        LPEndPoints.reports.getAllDetailedAreaManagers(role),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          countryCodes: isProdSheet ? countryCode : countryCode.join(","),
          allSelected: allSelected
        },
        keys
      );
      let managersData: AllAreaManagersListDataModel = {
        content: [],
        totalElements: null
      };
      let finalContent: AllAreaManagersDataModel[] = [];
      resp.content.map((item: AllAreaManagersDataModel) => {
        let deserializevar: AllAreaManagersDataModel = deserialize(AllAreaManagersDataModel, item);
        finalContent.push(deserializevar);
      });

      finalContent.forEach((item: any, index: number) => {
        // item.label = item.label + " " + item.value;
        item.value = `${item?.code}-${index}`;
      });
      managersData.totalElements = resp.totalElements;
      managersData.content = finalContent;
      managersData.currentPage = pageNo;
      return managersData;
    }
    if (
      // user.roles.includes(Roles.Admin) ||
      (user.roles.includes(Roles.SuperAdmin) ||
        user.roles.includes(Roles.HrAndAdminDirector) ||
        user.roles.includes(Roles.ProductionAndDevExecutive) ||
        user.roles.includes(Roles.TrainingAndDevLead) ||
        user.roles.includes(Roles.TrainingAndDevManager) ||
        user.roles.includes(Roles.CEO) ||
        user.roles.includes(Roles.COO) ||
        user.roles.includes(Roles.HeadLP) ||
        user.roles.includes(Roles.ClusterHeadofOps) ||
        user.roles.includes(Roles.AreaTrainer)) &&
      reportType !== ReportTypeLevel.Detailed
    ) {
      resp = await ApiClient.get(
        LPEndPoints.reports.getAllAreaManagers(),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          country: isProdSheet
            ? countryCode
            : reportType === ReportTypeLevel.Comparison ||
              reportType === ReportTypeLevel.HighLow ||
              reportType === ReportTypeLevel.AreaOfImprovement ||
              reportType === ReportTypeLevel.Grading
            ? countryCode
            : countryCode.join(","),
          allSelected: allSelected
        },
        keys
      );
      let managersData: AllAreaManagersListDataModel = {
        content: [],
        totalElements: null
      };
      let finalContent: OpsManagerModel[] = [];
      resp.content.map((item: OpsManagerModel) => {
        let deserializevar: OpsManagerModel = deserialize(OpsManagerModel, item);
        finalContent.push(deserializevar);
      });
      finalContent.forEach((item: any, index: number) => {
        item.label = item.label + " " + item.value;
        item.value = `${item.employeeId}-${index}`;
      });
      managersData.totalElements = resp.totalElements;
      managersData.content = finalContent;
      managersData.currentPage = pageNo;
      return managersData;
    }
    if (
      user.roles.includes(Roles.CountryManager) ||
      user.roles.includes(Roles.SeniorOpsManager) ||
      user.roles.includes(Roles.OperationManager)
    ) {
      resp = await ApiClient.get(
        reportsEndPoints.getAllAreaManagers(role),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          counryCodes: countryCode,
          allSelected: allSelected
        },
        keys
      );
      let managerData: AllLPAuditorListDataModel = {
        content: [],
        totalElements: null
      };

      let finalContent: AllLPAuditorDataModel[] = [];

      resp.content.map((item: AllLPAuditorDataModel) => {
        let deserializevar: AllLPAuditorDataModel = deserialize(AllLPAuditorDataModel, item);
        finalContent.push(deserializevar);
      });
      finalContent.forEach((item: any, index: number) => {
        // item.label = item.label + " " + item.value;
        item.value = `${item?.employeeId}-${index}`;
      });
      managerData.totalElements = resp.totalElements;
      managerData.content = finalContent;
      managerData.currentPage = pageNo;
      return managerData;
    }
  };

  getAllAreaTrainer = async (param: {
    pageNo: number;
    pageSize: number;
    user: IUserDetails;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
  }) => {
    const {
      role,
      countryCode,
      user,
      pageNo,
      pageSize,
      searchByKeyword,
      allSelected,
      reportType,
      moduleType
    } = param;
    let resp;
    let keys = REVKeys.REPORT_BUILDER;
    if (
      user.roles.includes(Roles.CountryManager) ||
      user.roles.includes(Roles.AreaManager) ||
      user.roles.includes(Roles.AreaTrainer) ||
      user.roles.includes(Roles.SeniorOpsManager) ||
      user.roles.includes(Roles.OperationManager)
    ) {
      resp = await ApiClient.get(
        LPEndPoints.reports.getAllAreaTrainer(),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          countryCodes: countryCode,
          allSelected: allSelected
        },
        keys
      );
    } else {
      resp = await ApiClient.get(
        LPEndPoints.reports.getAllDetailedAreaTrainer(role),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          countryCodes: countryCode,
          allSelected: allSelected
        },
        keys
      );
    }

    let managersData: AllAreaManagersListDataModel = {
      content: [],
      totalElements: null
    };
    let finalContent: AllAreaManagersDataModel[] = [];
    resp.content.map((item: AllAreaManagersDataModel) => {
      let deserializevar: AllAreaManagersDataModel = deserialize(AllAreaManagersDataModel, item);
      finalContent.push(deserializevar);
    });

    finalContent.forEach((item: any, index: number) => {
      // item.label = item.label + " " + item.value;
      item.value = `${item?.code}-${index}`;
    });
    managersData.totalElements = resp.totalElements;
    managersData.content = finalContent;
    managersData.currentPage = pageNo;
    return managersData;
  };
  getAllOpsManagers = async (param: {
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
  }) => {
    const {
      user,
      pageSize,
      pageNo,
      role,
      countryCode,
      allSelected,
      moduleType,
      searchByKeyword,
      reportType
    } = param;
    let resp;
    const isProdSheet = moduleType === builderLevel.isProdSheet;
    if (reportType === ReportTypeLevel.Detailed) {
      //hitting this api depending on user role for getting role wise data
      resp = await ApiClient.get(
        LPEndPoints.reports.getClusterHeadOfOps(role),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          allSelected: allSelected,
          countryCodes: isProdSheet ? countryCode : !countryCode ? null : countryCode.join(",")
        },
        LPKeys.LP_REPORT_BUILDER
      );
      let managersData: AllAreaManagersListDataModel = {
        content: [],
        totalElements: null,
        currentPage: 0
      };

      let finalContent: AllAreaManagersDataModel[] = [];
      resp.content.map((item: AllAreaManagersDataModel, index: number) => {
        let deserializevar: AllAreaManagersDataModel = deserialize(AllAreaManagersDataModel, item);
        deserializevar.value = `${deserializevar.value}-${index}`;
        finalContent.push(deserializevar);
      });

      managersData.totalElements = resp.totalElements;
      managersData.content = finalContent;
      managersData.currentPage = pageNo;
      return managersData;
    } else if (
      // user.roles.includes(Roles.Admin) ||
      user.roles.includes(Roles.SuperAdmin) ||
      user.roles.includes(Roles.TrainingAndDevLead) ||
      user.roles.includes(Roles.TrainingAndDevManager) ||
      user.roles.includes(Roles.HeadLP) ||
      user.roles.includes(Roles.AuditorLP) ||
      ((moduleType === builderLevel.isLp || moduleType === builderLevel.isProdSheet) &&
        (CEOEqvRoles.includes(user?.roles[0]) ||
          user.roles.includes(Roles.CountryManager) ||
          user.roles.includes(Roles.ClusterHeadofOps)))
    ) {
      resp = await ApiClient.get(
        LPEndPoints.reports.getAllOpsManagers(),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          countryCode: isProdSheet && !countryCode ? null : countryCode,
          allSelected: isProdSheet ? null : allSelected
        },
        LPKeys.LP_REPORT_BUILDER
      );
      let managersData: OpsManagerDataModel = {
        content: [],
        totalElements: null
      };
      let finalContent: OpsManagerModel[] = [];
      resp.content.map((item: OpsManagerModel) => {
        let deserializevar: OpsManagerModel = deserialize(OpsManagerModel, item);
        finalContent.push(deserializevar);
      });
      finalContent.forEach((item: any, index: number) => {
        item.label = item.label + " " + item.value;
        item.value = `${item.employeeId}-${index}`;
      });
      managersData.totalElements = resp.totalElements;
      managersData.content = finalContent;
      managersData.currentPage = param.pageNo;
      return managersData;
    }
  };
  getAllCountryManagers = async (param: {
    pageNo: number;
    pageSize: number;
    user: IUserDetails;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    selectedReportType: ReportTypeLevel;
  }) => {
    const {
      role,
      user,
      moduleType,
      countryCode,
      pageNo,
      pageSize,
      searchByKeyword,
      allSelected,
      selectedReportType
    } = param;
    let resp;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    if (
      // user.roles.includes(Roles.Admin) ||
      user.roles.includes(Roles.SuperAdmin) ||
      user.roles.includes(Roles.HrAndAdminDirector) ||
      user.roles.includes(Roles.ProductionAndDevExecutive) ||
      user.roles.includes(Roles.TrainingAndDevLead) ||
      user.roles.includes(Roles.TrainingAndDevManager) ||
      user.roles.includes(Roles.CEO) ||
      user.roles.includes(Roles.COO) ||
      param.user.roles.includes(Roles.HeadLP) ||
      param.user.roles.includes(Roles.AuditorLP)
    ) {
      resp = await ApiClient.get(
        selectedReportType === ReportTypeLevel.Detailed && moduleType !== builderLevel.isProdSheet
          ? LPEndPoints.reports.getCountryManager(role)
          : LPEndPoints.reports.getCountryManagerWoCountry(role),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          countryCodes:
            selectedReportType === ReportTypeLevel.Detailed &&
            moduleType !== builderLevel.isProdSheet
              ? countryCode.join(",")
              : null,
          allSelected: allSelected
        },
        keys
      );
    }
    // if (
    //   user.roles.includes(Roles.CountryManager) ||
    //   user.roles.includes(Roles.InternalAuditor) ||
    //   user.roles.includes(Roles.OperationManager) ||
    //   user.roles.includes(Roles.SeniorOpsManager) ||
    //   user.roles.includes(Roles.ClusterHeadofOps)
    // ) {
    //   resp = await ApiClient.get(
    //     reportsEndPoints.getAllAreaManagersCM(user.empId),
    //     {
    //       pageNo,
    //       pageSize,
    //       searchByKeyword: searchByKeyword
    //     },
    //     keys
    //   );
    // }

    let managersData: AllAreaManagersListDataModel = {
      content: [],
      totalElements: null
    };

    let finalContent: AllAreaManagersDataModel[] = [];
    resp.content.map((item: AllAreaManagersDataModel, index: number) => {
      let deserializevar: AllAreaManagersDataModel = deserialize(AllAreaManagersDataModel, item);
      deserializevar.value = `${deserializevar.value}-${index}`;
      finalContent.push(deserializevar);
    });

    managersData.totalElements = resp.totalElements;
    managersData.content = finalContent;
    managersData.currentPage = pageNo;
    return managersData;
  };
  getAllClusterHeadOfOps = async (param: {
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    selectedReportType: ReportTypeLevel;
  }) => {
    const {
      user,
      role,
      moduleType,
      countryCode,
      pageNo,
      pageSize,
      searchByKeyword,
      allSelected,
      selectedReportType
    } = param;
    let resp;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    const isProdSheet = moduleType === builderLevel.isProdSheet;
    resp = await ApiClient.get(
      selectedReportType === ReportTypeLevel.Detailed && !isProdSheet
        ? LPEndPoints.reports.getClusterHeadOfOps(role)
        : isProdSheet && user.roles.includes(Roles.ClusterHeadofOps)
        ? LPEndPoints.reports.getClusterHeadOfOps(role)
        : LPEndPoints.reports.getClusterHeadOfOpsWocountry(role),
      {
        pageNo,
        pageSize,
        searchByKeyword: searchByKeyword,
        allSelected: isProdSheet ? true : allSelected,
        countryCodes: isProdSheet
          ? null
          : selectedReportType === ReportTypeLevel.Detailed
          ? countryCode.join(",")
          : null
      },
      keys
    );
    let managersData: AllAreaManagersListDataModel = {
      content: [],
      totalElements: null,
      currentPage: 0
    };

    let finalContent: AllAreaManagersDataModel[] = [];
    resp.content.map((item: AllAreaManagersDataModel, index: number) => {
      let deserializevar: AllAreaManagersDataModel = deserialize(AllAreaManagersDataModel, item);
      deserializevar.value = `${deserializevar.value}-${index}`;
      finalContent.push(deserializevar);
    });

    managersData.totalElements = resp.totalElements;
    managersData.content = finalContent;
    managersData.currentPage = pageNo;
    return managersData;
  };
  getAllChannels = async (param: {
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
  }) => {
    const { pageNo, moduleType, pageSize, countryCode, searchByKeyword, allSelected } = param;
    let data;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    if (
      // param.user.roles.includes(Roles.Admin) ||
      param.user.roles.includes(Roles.SuperAdmin) ||
      param.user.roles.includes(Roles.HrAndAdminDirector) ||
      param.user.roles.includes(Roles.ProductionAndDevExecutive) ||
      param.user.roles.includes(Roles.TrainingAndDevLead) ||
      param.user.roles.includes(Roles.TrainingAndDevManager) ||
      param.user.roles.includes(Roles.CEO) ||
      param.user.roles.includes(Roles.COO) ||
      param.user.roles.includes(Roles.HeadLP) ||
      param.user.roles.includes(Roles.AuditorLP) ||
      param.user.roles.includes(Roles.ClusterHeadofOps)
    ) {
      data = await ApiClient.get(
        LPEndPoints.reports.getAllChannels(),
        {
          pageNo,
          pageSize,
          keyword: searchByKeyword,
          countryCodes: countryCode?.length !== undefined ? countryCode.join(",") : countryCode,
          allSelected: allSelected
        },
        keys
      );
    }
    if (
      param.user.roles.includes(Roles.CountryManager) ||
      param.user.roles.includes(Roles.InternalAuditor) ||
      param.user.roles.includes(Roles.AreaManager) ||
      param.user.roles.includes(Roles.OperationManager) ||
      param.user.roles.includes(Roles.AreaTrainer) ||
      param.user.roles.includes(Roles.SeniorOpsManager)
    ) {
      const keywordParamRoles: Array<Roles> = [
        Roles.SeniorOpsManager,
        Roles.OperationManager,
        Roles.CountryManager,
        Roles.AreaManager
      ];
      const isKeywordRole = keywordParamRoles.includes(param.user.roles[0]);
      data = await ApiClient.get(
        reportsEndPoints.getAllChannelsCM(),
        {
          employeeIds: param.user.empId,
          role: param.user.roles[0],
          pageNo: pageNo,
          pageSize: pageSize,
          keyword: isKeywordRole ? null : searchByKeyword,
          searchByKeyword: isKeywordRole ? searchByKeyword : null
        },
        keys
      );
    }
    let channelsData: ChannelsListDataModel = {
      content: [],
      totalElements: null,
      currentPage: 0
    };
    let finalContent: ChannelsDataModel[] = [];
    data.content.map((item: ChannelsDataModel, index: number) => {
      let deserializevar: ChannelsDataModel = deserialize(ChannelsDataModel, item);
      deserializevar.value = `${deserializevar.value}-${index}`;
      finalContent.push(deserializevar);
    });
    channelsData.totalElements = data.totalElements;
    channelsData.content = finalContent;
    channelsData.currentPage = param.pageNo;
    return channelsData;
  };
  getAllStores = async (param: {
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
    userRole: Roles;
  }) => {
    const {
      pageNo,
      moduleType,
      pageSize,
      reportType,
      countryCode,
      searchByKeyword,
      allSelected,
      userRole
    } = param;
    let data;
    const isUserClusterHeadOfOps = userRole === Roles.ClusterHeadofOps;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    if (
      (param.user.roles.includes(Roles.SuperAdmin) ||
        param.user.roles.includes(Roles.HrAndAdminDirector) ||
        param.user.roles.includes(Roles.ProductionAndDevExecutive) ||
        param.user.roles.includes(Roles.TrainingAndDevLead) ||
        param.user.roles.includes(Roles.TrainingAndDevManager) ||
        param.user.roles.includes(Roles.CEO) ||
        param.user.roles.includes(Roles.COO) ||
        param.user.roles.includes(Roles.HeadLP) ||
        param.user.roles.includes(Roles.AreaTrainer) ||
        param.user.roles.includes(Roles.AuditorLP) ||
        param.user.roles.includes(Roles.ClusterHeadofOps)) &&
      reportType === ReportTypeLevel.Detailed
    ) {
      data = await ApiClient.get(
        LPEndPoints.reports.getAllStores(),
        {
          pageNo,
          pageSize,
          searchByKeyword,
          countryCodes:
            moduleType === builderLevel.isProdSheet
              ? countryCode
              : !countryCode
              ? null
              : countryCode.join(","),
          allSelected: allSelected
        },
        keys
      );
    }
    if (
      // param.user.roles.includes(Roles.Admin) ||
      (param.user.roles.includes(Roles.SuperAdmin) ||
        param.user.roles.includes(Roles.HrAndAdminDirector) ||
        param.user.roles.includes(Roles.ProductionAndDevExecutive) ||
        param.user.roles.includes(Roles.TrainingAndDevLead) ||
        param.user.roles.includes(Roles.TrainingAndDevManager) ||
        param.user.roles.includes(Roles.CEO) ||
        param.user.roles.includes(Roles.AreaTrainer) ||
        param.user.roles.includes(Roles.COO) ||
        param.user.roles.includes(Roles.HeadLP) ||
        param.user.roles.includes(Roles.AuditorLP)) &&
      reportType !== ReportTypeLevel.Detailed
    ) {
      data = await ApiClient.get(
        isUserClusterHeadOfOps
          ? LPEndPoints.reports.getAllStores()
          : LPEndPoints.reports.getComparisonStoresAdmin(),
        {
          pageNo,
          pageSize,
          searchByKeyword: searchByKeyword,
          countryCode: isUserClusterHeadOfOps
            ? null
            : moduleType === builderLevel.isProdSheet
            ? countryCode
            : !countryCode
            ? null
            : reportType === ReportTypeLevel.Comparison ||
              reportType === ReportTypeLevel.HighLow ||
              reportType === ReportTypeLevel.AreaOfImprovement ||
              reportType === ReportTypeLevel.Grading
            ? countryCode
            : countryCode.join(","),
          countryCodes: isUserClusterHeadOfOps ? countryCode : null,
          allSelected: isUserClusterHeadOfOps ? false : null
        },
        keys
      );
    }
    if (
      // param.user.roles.includes(Roles.Admin) ||
      param.user.roles.includes(Roles.ClusterHeadofOps) &&
      reportType !== ReportTypeLevel.Detailed
    ) {
      data = await ApiClient.get(
        LPEndPoints.reports.getAllStores(),
        {
          pageNo,
          pageSize,
          searchByKeyword,
          countryCodes: countryCode,
          allSelected: false
        },
        keys
      );
    }
    if (
      param.user.roles.includes(Roles.CountryManager) ||
      param.user.roles.includes(Roles.AreaManager) ||
      param.user.roles.includes(Roles.OperationManager) ||
      param.user.roles.includes(Roles.SeniorOpsManager)
    ) {
      data = await ApiClient.get(
        reportsEndPoints.getAllStoresCM(),
        {
          employeeIds: param.user.empId,
          role: param.user.roles[0],
          searchByKeyword: searchByKeyword,
          pageNo: pageNo,
          pageSize: pageSize
        },
        keys
      );
    }
    let storesData: AllStoresListDataModel = {
      content: [],
      totalElements: null
    };
    let finalContent: LPAllStoresDataModel[] = [];
    data.content.map((item: LPAllStoresDataModel, index: number) => {
      let deserializevar: LPAllStoresDataModel = deserialize(LPAllStoresDataModel, item);

      deserializevar.value = `${deserializevar.code}-${index}`;
      finalContent.push(deserializevar);
    });
    storesData.totalElements = data.totalElements;
    storesData.content = finalContent;
    storesData.currentPage = param.pageNo;

    return storesData;
  };
  getComparisonCountryManager = async (param: {
    pageNo: number;
    pageSize: number;
    searchByKeyword: string;
    moduleType: string;
  }) => {
    const { pageNo, moduleType, pageSize, searchByKeyword } = param;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    let resp = await ApiClient.get(
      LPEndPoints.reports.getComparisonCountryManager(),
      { pageNo, pageSize, searchByKeyword: searchByKeyword },
      keys
    );
    let managerData: OpsManagerDataModel = {
      content: [],
      totalElements: null
    };
    let finalContent: OpsManagerModel[] = [];
    resp.content.map((item: OpsManagerModel) => {
      let deserializevar: OpsManagerModel = deserialize(OpsManagerModel, item);
      finalContent.push(deserializevar);
    });
    finalContent.forEach((item: any, index: number) => {
      item.label = item.label + " " + item.value;
      item.value = `${item.value}-${index}`;
    });
    managerData.totalElements = resp.totalElements;
    managerData.content = finalContent;
    managerData.currentPage = param.pageNo;

    return managerData;
  };
  getComparisonAreaManager = async (param: {
    pageNo: number;
    pageSize: number;
    searchByKeyword: string;
    moduleType: string;
  }) => {
    const { pageNo, moduleType, pageSize, searchByKeyword } = param;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    let resp = await ApiClient.get(
      LPEndPoints.reports.getComparisonAreaManager(),
      { pageNo, pageSize, searchByKeyword: searchByKeyword },
      keys
    );
    let managerData: OpsManagerDataModel = {
      content: [],
      totalElements: null
    };
    let finalContent: OpsManagerModel[] = [];
    resp.content.map((item: OpsManagerModel) => {
      let deserializevar: OpsManagerModel = deserialize(OpsManagerModel, item);
      finalContent.push(deserializevar);
    });
    finalContent.forEach((item: any, index: number) => {
      item.label = item.label + " " + item.value;
      item.value = `${item.value}-${index}`;
    });
    managerData.totalElements = resp.totalElements;
    managerData.content = finalContent;
    managerData.currentPage = param.pageNo;
    return managerData;
  };
  getComparisonStoresAdmin = async (payload: {
    pageNo: number;
    pageSize: number;
    moduleType: string;
    searchByKeyword: string;
  }) => {
    const { pageNo, moduleType, pageSize, searchByKeyword } = payload;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    let data = await ApiClient.get(
      LPEndPoints.reports.getComparisonStoresAdmin(),
      { pageNo, pageSize, searchByKeyword },
      keys
    );
    let storesData: ComparisonStoresAdminModel = {
      content: [],
      totalElements: null
    };
    let finalContent: ComparisonStoresAdminModelList[] = [];
    data.content.map((item: ComparisonStoresAdminModelList, index: number) => {
      let deserializevar: ComparisonStoresAdminModelList = deserialize(
        ComparisonStoresAdminModelList,
        item
      );
      deserializevar.value = `${deserializevar.value}-${index}`;
      finalContent.push(deserializevar);
    });
    storesData.totalElements = data.totalElements;
    storesData.content = finalContent;
    storesData.currentPage = payload.pageNo;
    return storesData;
  };
  getHighLowChannelsAdmin = async (payload: {
    pageNo: number;
    pageSize: number;
    moduleType: string;
    searchByKeyword: string;
  }) => {
    const { pageNo, moduleType, pageSize, searchByKeyword } = payload;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    let data = await ApiClient.get(
      LPEndPoints.reports.getHighLowChannelsAdmin(),
      { pageNo, pageSize, searchByKeyword },
      keys
    );
    let channelsData: ChannelsListDataModel = {
      content: [],
      totalElements: null
    };
    let finalContent: ChannelsDataModel[] = [];
    data.content.map((item: ChannelsDataModel, index: number) => {
      let deserializevar: ChannelsDataModel = deserialize(ChannelsDataModel, item);
      deserializevar.value = `${deserializevar.value}-${index}`;
      finalContent.push(deserializevar);
    });
    channelsData.totalElements = data.totalElements;
    channelsData.content = finalContent;
    channelsData.currentPage = payload.pageNo;
    return channelsData;
  };
  generateReportBuilder = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }) => {
    const { object, reportType, user, countryCodes, allSelected, pageNum } = param;
    if (
      // user.roles.includes(Roles.Admin) ||
      (user.roles.includes(Roles.SuperAdmin) ||
        user.roles.includes(Roles.TrainingAndDevLead) ||
        user.roles.includes(Roles.TrainingAndDevManager) ||
        user.roles.includes(Roles.CEO) ||
        user.roles.includes(Roles.COO)) &&
      reportType === ReportTypeLevel.Comparison &&
      object.levelOfReport === "CHANNEL"
    ) {
      let resp = await ApiClient.post(
        LPEndPoints.reports.genReportBuilderAdminComparisonChannels(
          reportType,
          countryCodes,
          pageNum
        ),
        object,
        null,
        LPKeys.LP_REPORT_BUILDER
      );
      return reportBuilderFormatter(param, resp);
    } else {
      let response = await ApiClient.post(
        LPEndPoints.reports.generateReportBuilder(reportType, allSelected, pageNum),
        object,
        null,
        LPKeys.LP_REPORT_BUILDER
      );
      return reportBuilderFormatter(param, response);
    }
  };
  generateGradingReportBuilder = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }) => {
    const { object, allSelected, pageNum } = param;
    let response = await ApiClient.post(
      LPEndPoints.reports.generateGradingReportBuilder(allSelected, pageNum),
      object,
      null,
      LPKeys.LP_REPORT_BUILDER
    );
    let data = gradingReportBuilderFormatter(param, response);
    return data;
  };
  getKPIdata = async (param: { moduleType: string }) => {
    const { moduleType } = param;
    if (moduleType === builderLevel.isLp) {
      let data = await ApiClient.get(
        LPEndPoints.reports.getKPIdata(),
        {},
        LPKeys.LP_REPORT_BUILDER
      );
      let kpiData: KPIDataModel[] = [];
      data?.map((item: any) => {
        let deserializevar: KPIDataModel = deserialize(KPIDataModel, item);
        kpiData.push(deserializevar);
      });
      return kpiData;
    } else {
      let data = await ApiClient.get(reportsEndPoints.getKPIdata(), {}, REVKeys.REPORT_BUILDER);
      let revKpiData: RevKPIDataModel[] = [];
      data?.map((item: RevKPIDataModel) => {
        let childElements: RevKPIElementModel[] = [];
        if (item?.childElements && item.childElements.length > 0) {
          item.childElements.map((childElement) => {
            let deserializChildEl: RevKPIElementModel = deserialize(
              RevKPIElementModel,
              childElement
            );
            childElements.push(deserializChildEl);
          });
          item.childElements = [...childElements];
        }
        let deserializevar: RevKPIDataModel = deserialize(RevKPIDataModel, item);
        revKpiData.push(deserializevar);
      });
      return revKpiData;
    }
  };
  getCategoryList = async (param: {
    idList: any;
    level: string;
    station: string;
    countryCodes: any;
    allSelected: boolean;
  }) => {
    const { idList, level, station, countryCodes, allSelected } = param;
    let data = await ApiClient.get(
      productionSheetEndPoints.reports.getCategory(),
      {
        idList: idList,
        levelOfReport: level,
        station: station,
        countryCodes: countryCodes,
        allSelected: allSelected
      },
      LPKeys.LP_REPORT_BUILDER
    );
    return PSCategoryDropDownFormatter(data);
  };
  getProductList = async (param: {
    idList: any;
    level: string;
    station: string;
    categoryId: string;
    countryCodes: any;
    allSelected: boolean;
  }) => {
    const { idList, level, station, categoryId, countryCodes, allSelected } = param;
    let data = await ApiClient.get(
      productionSheetEndPoints.reports.getProduct(),
      {
        idList: idList,
        levelOfReport: level,
        station: station,
        categoryId: categoryId,
        countryCodes: countryCodes,
        allSelected: allSelected
      },
      LPKeys.LP_REPORT_BUILDER
    );
    return PSProductDropDownFormatter(data);
  };
  getAllLpAuditor = async (param: {
    user: IUserDetails;
    pageNo: number;
    pageSize: number;
    role: string;
    countryCode: any;
    searchByKeyword: string;
    allSelected?: boolean;
    moduleType: string;
    reportType: string;
  }) => {
    const {
      user,
      pageNo,
      pageSize,
      role,
      countryCode,
      searchByKeyword,
      allSelected,
      moduleType,
      reportType
    } = param;
    let keys = REVKeys.REPORT_BUILDER;
    if (moduleType === builderLevel.isLp) {
      keys = LPKeys.LP_REPORT_BUILDER;
    }
    let data = await ApiClient.get(
      LPEndPoints.reports.getAllLpAuditor(role),
      {
        pageNo,
        pageSize,
        searchByKeyword,
        countryCodes: reportType === ReportTypeLevel.Detailed ? countryCode.join(",") : countryCode,
        allSelected
      },
      keys
    );
    let lpAuditorData: AllLPAuditorListDataModel = {
      content: [],
      totalElements: null,
      currentPage: 0
    };
    let finalContent: AllLPAuditorDataModel[] = [];

    data.content.map((item: AllLPAuditorDataModel, index: number) => {
      let deserializevar: AllLPAuditorDataModel = deserialize(AllAreaManagersDataModel, item);
      deserializevar.value = `${deserializevar.code}-${index}`;
      finalContent.push(deserializevar);
    });

    lpAuditorData.totalElements = data.totalElements;
    lpAuditorData.content = finalContent;
    lpAuditorData.currentPage = param.pageNo;
    return lpAuditorData;
  };
}
const reportsBuilderRepository = new ReportsBuilderRepository();
export { reportsBuilderRepository as ReportsBuilderRepository };
