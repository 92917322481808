import React from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize, Label } from "@timhortons/common/src/components/atoms/text";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { StoreRankingsTableData } from "@timhortons/common/src/models/rev/reports";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import {
  gradeLabelStyle,
  gradingStyle
} from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportAuditTable";

interface IProps {
  translate?: any;
  headerTitle: string;
  tableHeaderData?: { name: string }[];
  showTopAndLastFive?: boolean;
  topFiveTableData?: StoreRankingsTableData[];
  lastFiveTableData?: StoreRankingsTableData[];
}

//TODO: Add Icon for 1st rank and change icons before top five and last five

export default function StoreRankingsTable(props: IProps) {
  const {
    headerTitle,
    translate,
    tableHeaderData,
    showTopAndLastFive,
    topFiveTableData,
    lastFiveTableData
  } = props;

  const getHeaderComp = (headerItem: any, index: number) => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.grey1 }}
        cellStyle={
          (headerItem.name === translate("rank") && styles.cellStyle) ||
          (headerItem.name === translate("storeNames") && styles.storeNamesHeaderCellStyle) ||
          (headerItem.name === translate("areaManagerNames") && styles.storeNamesHeaderCellStyle) ||
          (headerItem.name === translate("country") && styles.countryHeaderCellStyle) ||
          styles.headerStyle
        }
      />
    );
  };

  const getTableData = (item: any, index: number) => {
    return (
      <View key={index}>
        <Row rowStyle={{ marginVertical: 12 }}>
          <Cell title={item.rank} cellStyle={styles.cellStyle} titleStyle={styles.cellTitleStyle} />
          <Cell
            title={item.storeName}
            cellStyle={styles.storeNamesHeaderCellStyle}
            titleStyle={styles.cellTitleStyle}
          />
          {showTopAndLastFive && (
            <Cell
              title={item.country}
              cellStyle={styles.countryHeaderCellStyle}
              titleStyle={styles.cellTitleStyle}
            />
          )}
          <Cell
            // title={item.avgScore}
            cellStyle={styles.headerStyle}
            titleStyle={styles.cellTitleStyle}
          >
            <Label
              textSize={TextSize.ExtraMedium}
              fontWeight={FontWeight.SemiBold}
              testId="id"
              style={[styles.textStyleChip, gradeLabelStyle(item.grade)]}
            >
              <Text
                textSize={TextSize.Regular}
                fontWeight={FontWeight.SemiBold}
                testId="id"
                textStyle={gradingStyle(item.grade)}
              >
                {item.grade} -
                {item.avgScore === 0 || item.avgScore === null ? 0 : item.avgScore.toFixed(2)}
                {item.avgScore ? "%" : ""}
              </Text>
            </Label>
          </Cell>
          <Cell
            title={item.sosFc}
            cellStyle={styles.headerStyle}
            titleStyle={styles.cellTitleStyle}
          />
          <Cell
            title={item.sosDt}
            cellStyle={styles.headerStyle}
            titleStyle={styles.cellTitleStyle}
          />
        </Row>
        <Divider
          orientation={Orientations.Horizontal}
          dividerStyle={{ borderColor: colorPallete.white1 }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        testId="opportunity table"
        textStyle={styles.headerTextStyle}
      >
        {headerTitle}
      </Text>
      <View>
        <Table>
          <Row header>
            {tableHeaderData.map((item, index) => {
              return getHeaderComp(item, index);
            })}
          </Row>
          {(topFiveTableData === null && lastFiveTableData === null) ||
          (topFiveTableData.length === 0 && lastFiveTableData.length === 0) ? (
            <Jumbotron type={ErrorType.NoDataFound} title={translate("noAvailableAudits")} />
          ) : (
            <>
              {topFiveTableData.map((item, index) => {
                return getTableData(item, index);
              })}
              {lastFiveTableData.length > 0 && showTopAndLastFive && (
                <Row header rowStyle={styles.tableDividerStyle}>
                  <Text
                    textSize={TextSize.ExtraSmall}
                    fontWeight={FontWeight.Regular}
                    testId="topfiveText"
                  >
                    <Icon name={IconNames.sortUpFilled} /> {translate("topFive")}
                  </Text>
                  <Text
                    textSize={TextSize.ExtraSmall}
                    fontWeight={FontWeight.Regular}
                    testId="topfiveText"
                    textStyle={{ marginStart: 60 }}
                  >
                    <Icon name={IconNames.sortDownUnfilled} /> {translate("lastFive")}
                  </Text>
                </Row>
              )}
              {lastFiveTableData.map((item, index) => {
                return getTableData(item, index);
              })}
            </>
          )}
        </Table>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7
  },
  headerTextStyle: {
    padding: 24
  },
  cellStyle: {
    flex: 1,
    justifyContent: "flex-start",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 24 },
      ltr: { marginStart: 24 }
    })
  },
  countryHeaderCellStyle: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginEnd: 42 },
      ltr: { marginEnd: 42 }
    })
  },
  storeNamesHeaderCellStyle: {
    flex: 3,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12
  },
  headerStyle: {
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1,
    ...I18nService.select({
      rtl: { marginEnd: 42 },
      ltr: { marginEnd: 42 }
    }),
    paddingVertical: 12
  },
  tableDividerStyle: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12
  },
  cellTitleStyle: {
    color: colorPallete.black4
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: colorPallete.grey8
  }
});
