import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import {
  LmsPTSCheckListModel,
  LmsPTSCheckListRequestDataModel,
  LmsPTSCheckListUnselectModel,
  LmsPTSDataDtoModel,
  LmsPTSGetRequestDataModel
} from "@timhortons/common/src/models/userLms/userLmsPTS";

const getLmsPTSData = (
  data: LmsPTSGetRequestDataModel
): IFluxStandardAction<LmsPTSGetRequestDataModel> => {
  return {
    type: UserLMS.userLmsPTS.GET_PTS_CHECKLIST.LOADING,
    payload: data
  };
};

const getLmsPTSDataSuccess = (
  data: LmsPTSDataDtoModel[]
): IFluxStandardAction<LmsPTSDataDtoModel[]> => {
  return {
    type: UserLMS.userLmsPTS.GET_PTS_CHECKLIST.SUCCESS,
    payload: data
  };
};

const getLmsPTSDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.userLmsPTS.GET_PTS_CHECKLIST.ERROR,
    payload: error
  };
};

const ptsCheckListSelect = (
  data: LmsPTSCheckListRequestDataModel
): IFluxStandardAction<LmsPTSCheckListRequestDataModel> => {
  return {
    type: UserLMS.userLmsPTS.PTS_CHECKLIST_SELECT.LOADING,
    payload: data
  };
};

const ptsCheckListSelectSuccess = (
  data: LmsPTSCheckListModel
): IFluxStandardAction<LmsPTSCheckListModel> => {
  return {
    type: UserLMS.userLmsPTS.PTS_CHECKLIST_SELECT.SUCCESS,
    payload: data
  };
};

const ptsCheckListSelectError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.userLmsPTS.PTS_CHECKLIST_SELECT.ERROR,
    payload: error
  };
};

const ptsCheckListUnselect = (
  data: LmsPTSCheckListRequestDataModel
): IFluxStandardAction<LmsPTSCheckListRequestDataModel> => {
  return {
    type: UserLMS.userLmsPTS.PTS_CHECLIST_UNSELECT.LOADING,
    payload: data
  };
};

const ptsCheckListUnselectSuccess = (
  data: LmsPTSCheckListUnselectModel
): IFluxStandardAction<LmsPTSCheckListUnselectModel> => {
  return {
    type: UserLMS.userLmsPTS.PTS_CHECLIST_UNSELECT.SUCCESS,
    payload: data
  };
};

const ptsCheckListUnselectError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.userLmsPTS.PTS_CHECLIST_UNSELECT.ERROR,
    payload: error
  };
};

export const UserLmsPTSActions = {
  getLmsPTSData,
  getLmsPTSDataSuccess,
  getLmsPTSDataError,
  ptsCheckListSelect,
  ptsCheckListSelectSuccess,
  ptsCheckListSelectError,
  ptsCheckListUnselect,
  ptsCheckListUnselectSuccess,
  ptsCheckListUnselectError
};
