import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("ReceivingLogCategoriesSubCategoriesModel")
export class ReceivingLogCategoriesSubCategoriesModel {
  @JsonProperty("id", String)
  key: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("isTicked", Boolean, true)
  isTick?: boolean = false;
}
@JsonObject("ItemTempDto")
export class ItemTempDto {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("recordedTemp", Number, true)
  data?: number = 0;
  @JsonProperty("isNASelected", Boolean, true)
  isNASelected?: boolean = false;
  @JsonProperty("reason", String && null, true)
  reason?: string = "";
  @JsonProperty("comment", String && null, true)
  comment?: string = "";
  @JsonProperty("itemId", String)
  itemId: string = "";
  @JsonProperty("shift", String, true)
  shift?: string = "";
  @JsonProperty("startDate", String)
  startDate: string = "";
  @JsonProperty("endDate", String)
  endDate: string = "";
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("productId", String)
  productId?: string = "";
}
@JsonObject("ProductTemperatureCategoriesModel")
export class ProductTemperatureCategoriesModel {
  @JsonProperty("id", String)
  key: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("isTicked", Boolean, true)
  isTick?: boolean = false;
  @JsonProperty("isTwoRed", Boolean, true)
  isTwoRed?: boolean = false;
}
@JsonObject("LogShiftModel")
export class LogShiftModel {
  @JsonProperty("label", String)
  label: string = "";
  @JsonProperty("status", String && null, true)
  status?: string = "";
  @JsonProperty("isShiftActive", Boolean)
  isEnable: boolean = false;
  @JsonProperty("startDate", String)
  startDate: string = "";
  @JsonProperty("endDate", String)
  endDate: string = "";
  @JsonProperty("isAccessValid", Boolean)
  isAccessValid: boolean = false;
  @JsonProperty("employeeId", String && null)
  empId: string = "";
  @JsonProperty("employeeName", String && null)
  empName: string = "";
  @JsonProperty("isShiftComplete", Boolean && null)
  isShiftComplete: boolean = null;
}
//<---------- Cleaning ------------>//
@JsonObject("DailyCheckListCategoriesModel")
export class DailyCheckListCategoriesModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("questionList", [], true)
  questionList?: DailyCleaningItems[] = [];
}

export class DailyCleaningItems {
  id: number = null;
  question: string = "";
  status: string = "";
  uniqueItemId: string = "";
  employeeName: string = "";
  isComment: boolean = null;
  comment: string = "";
  images: string[] = null;
}
@JsonObject("PostStationReadinessModel")
export class PostStationReadinessModel {
  @JsonProperty("id", String)
  id?: string = "";
  @JsonProperty("isNASelected", Boolean)
  isNASelected?: boolean = false;
  @JsonProperty("isCorrectSelected", Boolean)
  isCorrectSelected?: boolean = false;
  @JsonProperty("isWrongSelected", Boolean)
  isWrongSelected?: boolean = false;
  @JsonProperty("startDate", String)
  startDate: string = "";
  @JsonProperty("endDate", String)
  endDate: string = "";
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("shift", String)
  shift: string = "";
  @JsonProperty("stationReadinessItemId", String)
  stationReadinessItemId: string = "";
}

@JsonObject("WeeklyCleaningItemsModel")
export class WeeklyCleaningItemsModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("uniqueItemId", String && null)
  uniqueItemId: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("employeeName", String && null)
  employeeName: string = "";
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("isComment", Boolean && null)
  isComment: boolean = false;
  @JsonProperty("images", [String] && null)
  images: string[] = [];
  @JsonProperty("employeeId", String && null)
  employeeId: string = "";
}

@JsonObject("WeeklyCommentModel")
export class WeeklyCommentModel {
  @JsonProperty("body", FormData)
  body: FormData = null;
  @JsonProperty("questionId", String)
  questionId?: string = "";
  @JsonProperty("productId", String)
  productId?: string = "";
  @JsonProperty("categoryId", String)
  categoryId?: string = "";
}
@JsonObject("WeeklyCommentDataModel")
export class WeeklyCommentDataModel {
  @JsonProperty("actionType", String)
  actionType: string = "";
  @JsonProperty("wrongActionType", String)
  wrongActionType: string = "";
  @JsonProperty("comment", String)
  comment: string = "";
  @JsonProperty("employeeName", String)
  employeeName: string = "";
  @JsonProperty("imageKey", [String] && null)
  imageKey: string[] = [];
  @JsonProperty("cleaningItemId", String)
  cleaningItemId: string = "";
  @JsonProperty("questionId", String)
  questionId?: string = "";
  @JsonProperty("productId", String)
  productId?: string = "";
  @JsonProperty("categoryId", String, true)
  categoryId?: string = "";
}

export class LogsAuthModel {
  employeeId: string = "";
  name: string = "";
  storeCode: number = null;
  storeName: string = "";
  isAccessValid: boolean = false;
}
export class WeeklyPostItemsResponseModel {
  answer: string = "";
  assets: any[] = [];
  comment: string = null;
  employeeId: string = "";
  id: string = "";
  label: string = "";
  storeCode: number = null;
  questionId?: string = "";
  categoryId?: string = "";
}

// logs summary
@JsonObject("IItemLogs")
export class IItemLogs {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
}

@JsonObject("ICategoryMetaData")
export class ICategoryMetaData {
  @JsonProperty("actionName", String)
  actionName: string = "";
  @JsonProperty("actionId", String)
  actionId: string = "";
  @JsonProperty("summaryCategoryResponseDtos", [IItemLogs] && null)
  categories: Array<IItemLogs> = [];
}

export interface LogsSummaryPayload {
  storeCode: number;
  actionId: string;
  categoryId?: string;
  date: string;
  employeeId?: string;
}

@JsonObject("CompletedGraphData")
export class CompletedGraphData {
  @JsonProperty("series", Array)
  series: number[] = [];
  @JsonProperty("labels", Array)
  labels: string[] = [];
}
export class BarGraphDataModel {
  series: number[] = [];
  categories: string[] = [];
}
export class LogsSummaryDataModel {
  completedReport: CompletedGraphData = null;
  complianceReport: BarGraphDataModel = null;
  resolvedReport: BarGraphDataModel = null;
}

export class logsSummaryStoreList {
  code: number;
  name: string;
}

@JsonObject("ManagerReviewTableModel")
export class ManagerReviewTableModel {
  @JsonProperty("date", String)
  date: string = "";
  @JsonProperty("logName", String)
  logName: string = "";
  @JsonProperty("category", String)
  category: string = "";
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("itemsInAction", Number)
  itemsInAction: Number = 0;
  @JsonProperty("completed", Number)
  completed: Number = 0;
  @JsonProperty("autoClosed", Number)
  autoClosed: Number = 0;
  @JsonProperty("isApproved", Boolean)
  isApproved: Boolean = false;
  @JsonProperty("inComplete", Number)
  inComplete: Number = 0;
  @JsonProperty("actionId", String)
  actionId: string = "";
  @JsonProperty("shiftLabel", String)
  shiftLabel: string = "";
  @JsonProperty("isAdminApproval", Boolean)
  isAdminApproval: Boolean = false;
  @JsonProperty("isEditable", Boolean)
  isEditable: Boolean = false;
  @JsonProperty("resolvedCount", Number)
  resolvedCount: Number = 0;
  @JsonProperty("unResolvedCount", Number)
  unResolvedCount: Number = 0;
}
