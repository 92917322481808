import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  LogsAuthModel,
  LogShiftModel,
  ProductTemperatureCategoriesModel,
  ReceivingLogCategoriesSubCategoriesModel
} from "models/logs/mainLogs";
import { IReceivingLogsItemsApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/receivingLog/receivingLog";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";
import {
  IRecordTemperature,
  TabObject
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import {
  ILogShift,
  IMwtItemApiPayload,
  IProdTempCategories,
  ITimeTempCategories,
  LogsAuthPayload
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";

const getReceivingLogsCategories = (data: {
  categoryId: string;
}): IFluxStandardAction<{ categoryId: string }> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_CATEGORIES.LOADING,
    payload: data
  };
};
const getReceivingLogsCategoriesSuccess = (
  data: ReceivingLogCategoriesSubCategoriesModel[]
): IFluxStandardAction<ReceivingLogCategoriesSubCategoriesModel[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_CATEGORIES.SUCCESS,
    payload: data
  };
};
const getReceivingLogsCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_CATEGORIES.ERROR,
    payload: error
  };
};
const getReceivingLogsSubCategories = (
  data: IProdTempCategories
): IFluxStandardAction<IProdTempCategories> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_SUBCATEGORIES.LOADING,
    payload: data
  };
};
const getReceivingLogsSubCategoriesSuccess = (
  data: ReceivingLogCategoriesSubCategoriesModel[]
): IFluxStandardAction<ReceivingLogCategoriesSubCategoriesModel[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_SUBCATEGORIES.SUCCESS,
    payload: data
  };
};
const getReceivingLogsSubCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_SUBCATEGORIES.ERROR,
    payload: error
  };
};
const getReceivingLogsItems = (
  data: IReceivingLogsItemsApi
): IFluxStandardAction<IReceivingLogsItemsApi> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_ITEMS.LOADING,
    payload: data
  };
};
const getReceivingLogsItemsSuccess = (data: TabObject[]): IFluxStandardAction<TabObject[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_ITEMS.SUCCESS,
    payload: data
  };
};
const getReceivingLogsItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_ITEMS.ERROR,
    payload: error
  };
};
const getProdTempCategory = (
  data: IProdTempCategories
): IFluxStandardAction<IProdTempCategories> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_CATEGORY.LOADING,
    payload: data
  };
};
const getProdTempCategorySuccess = (
  data: ProductTemperatureCategoriesModel[]
): IFluxStandardAction<ProductTemperatureCategoriesModel[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_CATEGORY.SUCCESS,
    payload: data
  };
};
const getProdTempCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_CATEGORY.ERROR,
    payload: error
  };
};
const getProductTempLogsItems = (data: IProdTempApi): IFluxStandardAction<IProdTempApi> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_ITEMS.LOADING,
    payload: data
  };
};
const getProductTempLogsItemsSuccess = (data: TabObject[]): IFluxStandardAction<TabObject[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_ITEMS.SUCCESS,
    payload: data
  };
};
const getProductTempLogsItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_ITEMS.ERROR,
    payload: error
  };
};
const recordProductTemperature = (data: {
  finalData: IRecordTemperature;
  productId: string;
}): IFluxStandardAction<{
  finalData: IRecordTemperature;
  productId: string;
}> => {
  return {
    type: LOGS.MainLogs.TimeTemp.RECORD_PRODUCT_TEMPERATURE.LOADING,
    payload: data
  };
};
const recordProductTemperatureSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.MainLogs.TimeTemp.RECORD_PRODUCT_TEMPERATURE.SUCCESS,
    payload: data
  };
};
const recordProductTemperatureError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.RECORD_PRODUCT_TEMPERATURE.ERROR,
    payload: error
  };
};
const recordReceivingLogTemperature = (data: {
  finalData: IRecordTemperature;
  productId: string;
}): IFluxStandardAction<{
  finalData: IRecordTemperature;
  productId: string;
}> => {
  return {
    type: LOGS.MainLogs.TimeTemp.RECORD_RECEIVINGLOG_TEMPERATURE.LOADING,
    payload: data
  };
};
const recordReceivingLogTemperatureSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LOGS.MainLogs.TimeTemp.RECORD_RECEIVINGLOG_TEMPERATURE.SUCCESS,
    payload: data
  };
};
const recordReceivingLogTemperatureError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.RECORD_RECEIVINGLOG_TEMPERATURE.ERROR,
    payload: error
  };
};
const getLogShift = (data: ILogShift): IFluxStandardAction<ILogShift> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_LOGS_SHIFT.LOADING,
    payload: data
  };
};
const getLogShiftSuccess = (data: LogShiftModel[]): IFluxStandardAction<LogShiftModel[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_LOGS_SHIFT.SUCCESS,
    payload: data
  };
};
const getLogShiftError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_LOGS_SHIFT.ERROR,
    payload: error
  };
};

const getEquipmentTempItems = (
  data: IMwtItemApiPayload
): IFluxStandardAction<IMwtItemApiPayload> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_EQUIPMENT_TEMP_ITEMS.LOADING,
    payload: data
  };
};

const getEquipmentTempItemsSuccess = (data: TabObject[]): IFluxStandardAction<TabObject[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_EQUIPMENT_TEMP_ITEMS.SUCCESS,
    payload: data
  };
};

const getEquipmentTempItemsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_EQUIPMENT_TEMP_ITEMS.ERROR,
    payload: data
  };
};

const checkLogsAuth = (data: LogsAuthPayload): IFluxStandardAction<LogsAuthPayload> => {
  return {
    type: LOGS.Authentication.CHECK_LOGS_AUTH.LOADING,
    payload: data
  };
};

const checkLogsAuthSuccess = (data: LogsAuthModel): IFluxStandardAction<LogsAuthModel> => {
  return {
    type: LOGS.Authentication.CHECK_LOGS_AUTH.SUCCESS,
    payload: data
  };
};

const checkLogsAuthError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Authentication.CHECK_LOGS_AUTH.ERROR,
    payload: error
  };
};

const resetLogsAuth = () => {
  return {
    type: LOGS.Authentication.RESET_LOGS_AUTH
  };
};
const resetTimeTempError = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_TIME_TEMP_ERROR
  };
};
const resetTimeTempCategoryState = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_TIME_TEMP_CATEGORY_STATE
  };
};
const getTimeTempCategoriesLoading = (data: {
  actionName: string;
  storeCode: number;
  date: string;
}): IFluxStandardAction<{ actionName: string; storeCode: number; date: string }> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_TIME_TEMP_CATEGORIES.LOADING,
    payload: data
  };
};
const timeTempCategoriesSuccess = (
  data: ITimeTempCategories[]
): IFluxStandardAction<ITimeTempCategories[]> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_TIME_TEMP_CATEGORIES.SUCCESS,
    payload: data
  };
};
const timeTempCategoriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.MainLogs.TimeTemp.GET_TIME_TEMP_CATEGORIES.ERROR,
    payload: error
  };
};
const updateActiveShift = (value: LogShiftModel): IFluxStandardAction<LogShiftModel> => {
  return {
    type: LOGS.MainLogs.TimeTemp.UPDATE_ACTIVE_SHIFT,
    payload: value
  };
};
const resetProdTempCategory = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_PROD_TEMP_CATEGORY_STATE
  };
};
const resetLogsAuthError = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_AUTH_ERROR
  };
};
const resetReceivingLogs = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_REC_LOG
  };
};
const resetIsProdCompleted = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_IS_PROD_TEMP_COMPLETED
  };
};
const resetTwoRedState = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_TWO_RED_STATE
  };
};
const resetTimeTempLogs = () => {
  return {
    type: LOGS.MainLogs.TimeTemp.RESET_TIME_TEMP_ENTIRE_STATE
  };
};
export const TimeTemperatureActions = {
  getReceivingLogsCategories,
  getReceivingLogsCategoriesSuccess,
  getReceivingLogsCategoriesError,
  getReceivingLogsSubCategories,
  getReceivingLogsSubCategoriesSuccess,
  getReceivingLogsSubCategoriesError,
  getReceivingLogsItems,
  getReceivingLogsItemsSuccess,
  getReceivingLogsItemsError,
  getProdTempCategory,
  getProdTempCategorySuccess,
  getProdTempCategoryError,
  getProductTempLogsItems,
  getProductTempLogsItemsSuccess,
  getProductTempLogsItemsError,
  recordProductTemperature,
  recordProductTemperatureSuccess,
  recordProductTemperatureError,
  getLogShift,
  getLogShiftSuccess,
  getLogShiftError,
  getEquipmentTempItems,
  getEquipmentTempItemsSuccess,
  getEquipmentTempItemsError,
  checkLogsAuth,
  checkLogsAuthSuccess,
  checkLogsAuthError,
  resetLogsAuth,
  resetTimeTempError,
  getTimeTempCategoriesLoading,
  timeTempCategoriesSuccess,
  timeTempCategoriesError,
  resetTimeTempCategoryState,
  updateActiveShift,
  resetProdTempCategory,
  resetReceivingLogs,
  resetLogsAuthError,
  recordReceivingLogTemperature,
  recordReceivingLogTemperatureSuccess,
  recordReceivingLogTemperatureError,
  resetIsProdCompleted,
  resetTwoRedState,
  resetTimeTempLogs
};
