import { Any, JsonObject, JsonProperty } from "json2typescript";
import { string } from "yup";

@JsonObject("ContentObject")
export class ContentObject {
  @JsonProperty("attributes", Any && true)
  attributes?: any = null;
  @JsonProperty("insert", String)
  insert?: string = "";
}
@JsonObject("SubTopicsModel")
export class SubTopicsModel {
  @JsonProperty("id", String)
  id: String = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("content", [ContentObject] && null)
  content: Array<ContentObject> = [];
}
@JsonObject("SubCategoryListModel")
export class SubCategoryListModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("description", [ContentObject] && null)
  description: Array<ContentObject> = [];
}

@JsonObject("ContentsModelList")
export class ContentsModelList {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("createdAt", String)
  createdAt: string = "";
  @JsonProperty("updatedAt", String)
  updatedAt: string = "";
  @JsonProperty("subCategoryCount", Number)
  subCategoryCount: number = 0;
  // @JsonProperty("image", String)
  image: string = "https://dev-mindscribble-nir.s3.ap-south-1.amazonaws.com/imageWelcome.png";
}

@JsonObject("Resource")
export class Resource {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
}

@JsonObject("SubCategoriesModel")
export class SubCategoriesModel {
  @JsonProperty("category", Resource)
  resource: Resource = null;
  @JsonProperty("subCategories", [SubCategoryListModel])
  subCategories: Array<SubCategoryListModel> = [];
}

@JsonObject("MarkDownContentModel")
export class MarkDownContentModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("description", [ContentObject] && null)
  description: Array<ContentObject> = [];
  @JsonProperty("topics", [SubTopicsModel])
  topics: Array<SubTopicsModel> = [];
}
@JsonObject("SearchArticles")
export class SearchArticles {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("serial", String)
  serial: string = "";
  @JsonProperty("name", String)
  name: string = "";
}

// @JsonObject("SearchResultsModel")
// export class SearchResultsModel {
//   @JsonProperty("categoryName", String)
//   categoryName: string = "";
//   @JsonProperty("categoryId", Number)
//   categoryId: number = 0;
//   @JsonProperty("searchResults", [SearchArticles])
//   articles: Array<SearchArticles> = [];
// }
@JsonObject("subCategories")
export class SearchSubCategories {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
}
@JsonObject("SearchResultsModel")
export class SearchResultsModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", Number)
  categoryName: number = 0;
  @JsonProperty("subCategories", [SearchSubCategories])
  subCategories: Array<SearchSubCategories> = [];
}

@JsonObject("SearchResultsModel")
export class SearchResultsResponseModel {
  @JsonProperty("searchResponses", [SearchResultsModel])
  subCategories: Array<SearchResultsModel> = [];
}

@JsonObject("ResourcesFiltersModel")
export class ResourcesFiltersModel {
  @JsonProperty("categoryName", String)
  name: string = "";
  @JsonProperty("categoryId", string, true)
  id?: string = "";
  @JsonProperty("key", Number)
  key: number = 0;
  @JsonProperty("selected", Boolean)
  selected: boolean = false;
}

@JsonObject("postCreateNewContent")
export class postCreateNewContentModel {
  @JsonProperty("createdAt", String)
  createdAt: string = "";
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("updatedAt", String)
  updatedAt: string = "";
}

@JsonObject("putCategoryTitleDataModel")
export class putCategoryTitleDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("createdAt", String)
  createdAt: string = "";
  @JsonProperty("updatedAt", String)
  updatedAt: string = "";
}

@JsonObject("putCategoryTitleModel")
export class putCategoryTitleModel {
  @JsonProperty("data", { putCategoryTitleDataModel } && null)
  data: putCategoryTitleDataModel = null;
  @JsonProperty("status", String)
  status: string = "";
}

@JsonObject("deleteSubCategoryTitleDataModel")
export class deleteSubCategoryTitleDataModel {
  @JsonProperty("subCategoryId", String)
  subCategoryId: string = "";
}

@JsonObject("deleteSubCategoryTitleModel")
export class deleteSubCategoryTitleModel {
  @JsonProperty("data", { deleteSubCategoryTitleDataModel } && null)
  data: deleteSubCategoryTitleDataModel = null;
  @JsonProperty("status", String)
  status?: string = "";
}
