import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";

export interface IManagerReviewLoading {
  getManagerReviewTable: boolean;
  updateMRApproval: boolean;
}
export interface IManagerReviewError {
  getManagerReviewTable: string;
  updateMRApproval: string;
}
export interface IManagerReviewState {
  loading: IManagerReviewLoading;
  error: IManagerReviewError;
  managerReviewTableData: any;
}
export const managerReviewInitialState: IManagerReviewState = {
  managerReviewTableData: null,
  loading: {
    getManagerReviewTable: false,
    updateMRApproval: false
  },
  error: {
    getManagerReviewTable: null,
    updateMRApproval: null
  }
};

export const managerReviewReducer = (
  state = managerReviewInitialState,
  action: { payload: any; type: string }
): IManagerReviewState => {
  switch (action.type) {
    case LOGS.ManagerReview.GET_MANAGER_REVIEW_LOGS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getManagerReviewTable: true },
        error: { ...state.error, getManagerReviewTable: null }
      };
    case LOGS.ManagerReview.GET_MANAGER_REVIEW_LOGS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getManagerReviewTable: false },
        error: { ...state.error, getManagerReviewTable: null },
        managerReviewTableData: action.payload
      };
    case LOGS.ManagerReview.GET_MANAGER_REVIEW_LOGS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getManagerReviewTable: false },
        error: { ...state.error, getManagerReviewTable: action.payload }
      };
    case LOGS.ManagerReview.UPDATE_MANAGER_REVIEW_APPROVAL.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateMRApproval: true },
        error: { ...state.error, updateMRApproval: null }
      };
    case LOGS.ManagerReview.UPDATE_MANAGER_REVIEW_APPROVAL.SUCCESS:
      let formattedData = state.managerReviewTableData;
      let dataIndex;

      if (action.payload.shiftLabel === null) {
        dataIndex =
          formattedData &&
          formattedData?.findIndex((obj: any) => obj.categoryId === action.payload.categoryId);
        if (dataIndex > -1) {
          formattedData[dataIndex].isApproved = action.payload.isApproved;
        }
      } else {
        dataIndex =
          formattedData &&
          formattedData?.findIndex(
            (obj: any) =>
              obj.actionId === action.payload.actionId &&
              obj.shiftLabel === action.payload.shiftLabel
          );
        if (dataIndex > -1) {
          formattedData[dataIndex].isApproved = action.payload.isApproved;
        }
      }
      return {
        ...state,
        loading: { ...state.loading, updateMRApproval: false },
        error: { ...state.error, updateMRApproval: null },
        managerReviewTableData: formattedData
      };
    case LOGS.ManagerReview.UPDATE_MANAGER_REVIEW_APPROVAL.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateMRApproval: false },
        error: { ...state.error, updateMRApproval: action.payload }
      };
    case LOGS.ManagerReview.RESET_MR_APPROVAL_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          updateMRApproval: null
        }
      };
    default:
      return state;
  }
};
