import React, { ReactElement } from "react";
import { TouchableOpacity, StyleSheet, StyleProp, TextStyle, ViewStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
interface IProps {
  radioText: string | number;
  isChecked: boolean;
  isChart?: boolean;
  onSelect?: () => void;
  iconSize?: number;
  iconStyle?: object;
  iconSelectedStyle?: object;
  chartSelectedStyle?: StyleProp<ViewStyle> | StyleProp<TextStyle>;
  testId?: string;
  textStyle?: StyleProp<TextStyle>;
  fontWeight?: FontWeight;
  fontSize?: TextSize;
  radioViewStyle?: StyleProp<TextStyle>;
  colors?: string;
  selectedIconName?: IconNames;
}
export const RadioButton = (props: IProps): ReactElement => {
  const {
    isChecked,
    radioText,
    onSelect,
    iconSize = 16,
    iconStyle,
    iconSelectedStyle,
    testId,
    textStyle,
    fontWeight = FontWeight.Regular,
    fontSize = TextSize.Regular,
    radioViewStyle,
    isChart,
    chartSelectedStyle,
    colors,
    selectedIconName = null
  } = props;
  let selectedIcon = selectedIconName === null ? IconNames.zeroTolerance : selectedIconName;

  return (
    <TouchableOpacity onPress={onSelect} style={[styles(isChecked).container, radioViewStyle]}>
      <Icon
        size={iconSize}
        color={isChecked ? colorPallete.red1 : colorPallete.grey4}
        name={isChecked ? selectedIcon : IconNames.noneDone}
        customStyle={
          isChecked
            ? iconSelectedStyle
            : (isChart && [chartSelectedStyle, { color: colors, borderColor: colors }]) || iconStyle
        }
      />
      <Text
        textStyle={[styles(isChecked).text, textStyle]}
        testId={testId}
        fontWeight={fontWeight}
        textSize={fontSize}
      >
        {radioText}
      </Text>
    </TouchableOpacity>
  );
};
const styles = (isChecked: boolean) =>
  StyleSheet.create({
    container: {
      // flex:1,
      flexDirection: "row",
      alignItems: "center"
    },
    text: {
      color: isChecked ? colorPallete.red1 : colorPallete.grey1,
      paddingVertical: 12,
      // marginStart: 10,
      ...I18nService.select({
        rtl: { marginStart: 10 },
        ltr: { marginStart: 10 }
      })
    }
  });
