import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { FontWeight, TextSize, Text } from "@timhortons/common/src/components/atoms/text";
import ModalDropdown, { IDropDownItem } from "./modalDropdown";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";

interface IProps {
  allItems: IDropDownItem[];
  defaultSelectedIndex?: number;
  handleCallBack?: Function;
}

export const ButtonDropdown = (props: IProps): React.ReactElement => {
  const { allItems, defaultSelectedIndex = 0, handleCallBack } = props;
  const [selectedOption, setSelectedOption] = useState<IDropDownItem>(
    allItems[defaultSelectedIndex]
  );

  const handleDropdownCallBack = (option: IDropDownItem) => {
    setSelectedOption(option);
    handleCallBack && handleCallBack(option.value);
  };

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <View style={styles.container}>
        <View style={[styles.textContainer, selectedOption?.dropdownStyle]}>
          <Icon
            name={selectedOption?.iconName}
            size={12}
            customStyle={[styles.text, selectedOption?.textStyle]}
          />
          <Text
            fontWeight={FontWeight.SemiBold}
            textSize={TextSize.ExtraRegular}
            testId="dropdown"
            textStyle={[styles.text, selectedOption?.textStyle]}
          >
            {selectedOption?.label}
          </Text>
        </View>
      </View>
      <ModalDropdown
        triggerElement={
          <View style={[styles.dropdownButton, selectedOption?.dropdownStyle]}>
            <Icon
              name={IconNames.down}
              size={14}
              customStyle={[styles.dropdownIcon, selectedOption?.textStyle]}
            />
          </View>
        }
        allItems={allItems}
        onChangeCallBack={handleDropdownCallBack}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    overflow: "hidden"
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 24,
    paddingVertical: 7.5,
    backgroundColor: colorPallete.red1
  },
  text: {
    paddingHorizontal: 8,
    color: colorPallete.white3
  },
  dropdownButton: {
    marginHorizontal: 1,
    borderRadius: 0,
    borderTopEndRadius: 8,
    borderBottomEndRadius: 8,
    overflow: "hidden"
  },
  dropdownIcon: {
    padding: 12
  }
});
