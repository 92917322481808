import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { View, StyleSheet } from "react-native";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";
import { AppealsAction } from "@timhortons/common/src/redux/rev/actions/appeals";
import { AppealsSelectors } from "@timhortons/common/src/redux/rev/selectors/appeals";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Loader from "@timhortons/common/src/components/molecules/loader";
import AppealHeader from "@timhortons/common/src/modules/rev/components/dashboards/appeals/organisms/appealHeader";
import NewAppealTable from "@timhortons/common/src/modules/rev/components/dashboards/appeals/organisms/newAppealTable";
import InProcessAppealTable from "@timhortons/common/src/modules/rev/components/dashboards/appeals/organisms/inProcessAppealTable";
import IndividualTable from "@timhortons/common/src/modules/rev/components/dashboards/appeals/organisms/inProcessAppealTable/inProcessAppealTable";
import { IState } from "@timhortons/common/src/store/interfaces";
import { IAppealsLoading, IAppealsError } from "@timhortons/common/src/redux/rev/reducers/appeals";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import {
  AppealCountModel,
  InProcesssClosedTable,
  // InProcessAppealTableModel,
  NewAppealsTable
} from "@timhortons/common/src/models/rev/appeals";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import { oopsCookie } from "@timhortons/common/src/assets/images/imageAssets";

interface IProps {
  appealCount: AppealCountModel;
  newAppealsTableData: NewAppealsTable[];
  inProcessAppeals: InProcesssClosedTable[];
  closedAppeals: InProcesssClosedTable[];
  setAppealDecision: Function;
  inProcessSetAppealFilter: Function;
  getAppealsCountData: (param: { appealerId: string; isIndividual: boolean }) => void;
  getNewAppealsData: Function;
  getAppealAccordionData: Function;
  getInProcessAppealsData: Function;
  getClosedAppealsData: Function;
  loading: IAppealsLoading;
  error: IAppealsError;
  individualInprocess: InProcesssClosedTable[];
  translate?: any;
  modalVisible?: boolean;
  setModalState?: () => void;
  handleAuth?: Function;
  currentUserRoles?: IUserDetails;
}

export enum AppealTable {
  newAppeal = "New Appeal",
  inProcess = "In Process",
  closedAppeal = "Closed Appeal"
}

function Appeals(props: IProps): ReactElement {
  const {
    translate,
    loading,
    inProcessSetAppealFilter,
    getClosedAppealsData,
    closedAppeals,
    inProcessAppeals,
    getInProcessAppealsData,
    appealCount,
    getNewAppealsData,
    newAppealsTableData,
    modalVisible,
    setModalState,
    getAppealsCountData,
    individualInprocess,
    handleAuth,
    currentUserRoles,
    setAppealDecision,
    error
  } = props;

  const [currentTable, setCurrentTable] = useState<AppealTable>(AppealTable.newAppeal);
  const [isIndividualLogin, setIndividualLogin] = useState<boolean>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const isFocused = useFocusedHook();
  useEffect(() => {
    if (checkAllowedRoles(currentUserRoles && currentUserRoles.roles)) {
      setIndividualLogin(true);
      setCurrentTable(AppealTable.inProcess);
      getAppealsCountData({ appealerId: currentUserRoles.empId, isIndividual: true });
    } else if (currentUserRoles !== null) {
      getAppealsCountData({
        appealerId: currentUserRoles.storeCode.toString(),
        isIndividual: false
      });
      setIndividualLogin(false);
    }
  }, [currentUserRoles, isFocused]);

  useEffect(() => {
    if (currentTable === AppealTable.inProcess) {
      isIndividualLogin &&
        inProcessSetAppealFilter({
          userId: currentUserRoles.empId,
          selectedValues: {
            appealStatus: "IN_PROCESS",
            appealItemStatus: "IN_PROCESS",
            fromDate: null,
            endDate: null
          }
        });
    } else if (currentTable === AppealTable.closedAppeal) {
      isIndividualLogin &&
        inProcessSetAppealFilter({
          userId: currentUserRoles.empId,
          selectedValues: {
            appealStatus: "CLOSED",
            fromDate: null,
            endDate: null
          }
        });
    }
  }, [currentTable]);

  function loader(): JSX.Element {
    return <Loader type="component" />;
  }
  if (loading.closedData && loading.filteredData) {
    return loader();
  }
  useEffect(() => {
    return () => {
      // clear error state
      setShowError(false);
    };
  }, []);
  useEffect(() => {
    if (error.setAppealsDecision) {
      setShowError(true);
    }
  }, [error.setAppealsDecision]);
  return (
    <>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <AppealHeader
            userDetails={currentUserRoles}
            isIndividualLogin={isIndividualLogin}
            setAppealFilter={inProcessSetAppealFilter}
            translate={translate}
            currentTable={currentTable}
            setCurrentTable={setCurrentTable}
            appealCount={appealCount}
          />
        </View>
        <View style={styles.tableContainer}>
          {currentUserRoles !== null ? (
            isIndividualLogin ? (
              <IndividualTable
                userDetails={currentUserRoles}
                loading={loading}
                setAppealDecision={setAppealDecision}
                isIndividualLogin={isIndividualLogin}
                translate={translate}
                currentTable={currentTable}
                appealsTableData={individualInprocess}
                error={error}
              />
            ) : isIndividualLogin === false && currentTable === AppealTable.newAppeal ? (
              <NewAppealTable
                newAppealsTableData={newAppealsTableData}
                translate={translate}
                onNewAppealClick={handleAuth}
                modalVisible={modalVisible}
                setModalState={setModalState}
                appealCount={appealCount && appealCount.newAppealsCount}
                getNewAppeals={getNewAppealsData}
                loading={loading}
                error={error}
              />
            ) : (
              <InProcessAppealTable
                userDetails={currentUserRoles}
                isIndividualLogin={isIndividualLogin}
                translate={translate}
                currentTable={currentTable}
                appealsTableData={
                  currentTable === AppealTable.inProcess ? inProcessAppeals : closedAppeals
                }
                appealsTableDataLoading={
                  currentTable === AppealTable.inProcess
                    ? loading.getInProcessAppeals
                    : loading.getClosedAppeals
                }
                error={
                  currentTable === AppealTable.inProcess
                    ? error.getInProcessAppeals
                    : error.getClosedAppeals
                }
                getInProcessAppealsData={getInProcessAppealsData}
                getClosedAppealsData={getClosedAppealsData}
              />
            )
          ) : null}
        </View>
      </View>
      {showError && (
        <AlertModal
          setModalVisible={() => setShowError(false)}
          errorImage={error.setAppealsDecision !== null && oopsCookie}
          errorMsg={error.setAppealsDecision !== null && translate("errorSetAppealDecision")}
        />
      )}
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    marginTop: 30
  },
  headerContainer: {
    // header container styles goes here
  },
  tableContainer: {
    // new appeals table styles goes here
    flex: 1,
    // marginVertical: PlatformUtils.isWeb() ? 16 : 0,
    marginTop: PlatformUtils.isWeb() ? 16 : 12,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  },
  createPlanBtn: {
    backgroundColor: colorPallete.variant6,
    borderRadius: 8,
    paddingVertical: 6,
    paddingHorizontal: 16,
    width: 200
  }
});
const mapStateToProps = (state: IState) => {
  return {
    loading: AppealsSelectors.appealsLoading(state),
    error: AppealsSelectors.appealsError(state),
    appealCount: AppealsSelectors.appealsCountData(state),
    newAppealsTableData: AppealsSelectors.getNewAppealsData(state),
    inProcessAppeals: AppealsSelectors.getInProcessAppeals(state),
    closedAppeals: AppealsSelectors.getClosedAppeals(state),
    // appealAccordianData: AppealsSelectors.getAppealAccordionData(state),
    individualInprocess: AppealsSelectors.appealFilteredData(state),
    individualClosed: AppealsSelectors.closedappealFilteredData(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...AppealsAction }, dispatch) };
};
export default connect(mapStateToProps, mapDispatchToProps)(Appeals);
