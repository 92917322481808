import React, { useEffect, useMemo, useRef } from "react";
import { Animated, View, StyleSheet } from "react-native";
import { Image } from "@timhortons/common/src/components/atoms/image";
import { loaderGif } from "@timhortons/common/src/assets/images/imageAssets";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";

export enum LoaderType {
  TwoLine = "twoline",
  SOS1 = "sos1",
  SOS2 = "sos2"
}

export default function Loader(props: any) {
  const animatedController = useRef(new Animated.Value(0.5)).current;

  useEffect(() => {
    Animated.loop(
      Animated.timing(animatedController, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true
      })
    ).start();
  }, [animatedController]);

  const getPlaceholderUI = (customWidth?: number) => {
    return (
      <View style={styles.container}>
        <View style={styles.firstPlaceholder} />
        <View style={[styles.secondPlaceholder, { width: customWidth }]} />
      </View>
    );
  };

  const getTwoLineLoader = () => {
    return (
      <Animated.View
        style={{
          marginBottom: 36,
          opacity: animatedController
        }}
      >
        {getPlaceholderUI(240)}
        <View style={styles.divider} />
        {getPlaceholderUI(240)}
      </Animated.View>
    );
  };

  const getSOS1Loader = () => {
    return (
      <Animated.View
        style={{
          margin: 36,
          opacity: animatedController
        }}
      >
        {getPlaceholderUI(550)}
        <View style={{ marginHorizontal: 52 }}>
          <View style={styles.placeholderLine} />
          <View style={styles.placeholderLine} />
          <View style={styles.placeholderLine} />
          <View style={styles.placeholderLine} />
          <View style={styles.placeholderLine} />
        </View>
      </Animated.View>
    );
  };

  const getSOSUI = () => {
    return (
      <View style={styles.container}>
        <View style={[styles.placeholderLine, styles.placeholderTiny]} />
        <View style={[styles.placeholderLine, styles.placeholderMedium]} />
        <View style={[styles.placeholderLine, styles.placeholderSmall]} />
        <View style={[styles.placeholderLine, styles.placeholderSmall]} />
        <View style={[styles.placeholderLine, styles.placeholderMedium]} />
      </View>
    );
  };

  const getSOS2Loader = () => {
    return (
      <Animated.View
        style={{
          marginHorizontal: 36,
          marginBottom: 36,
          opacity: animatedController
        }}
      >
        <View style={[styles.container, styles.subContainer]}>
          <View>
            {getSOSUI()}
            {getSOSUI()}
            {getSOSUI()}
            {getSOSUI()}
          </View>
          <View>
            <View style={styles.placeholderBox} />
            <View style={styles.placeholderBox} />
          </View>
        </View>
      </Animated.View>
    );
  };

  const defaultLoader = () => {
    return (
      <View style={props.type === "component" && styles.loaderComponent}>
        <Image testId="loader" source={loaderGif} imageStyle={styles.imageStyle} />
      </View>
    );
  };

  const displayLoaderComponent = useMemo(() => {
    switch (props.placeholder) {
      case LoaderType.TwoLine:
        return getTwoLineLoader();
      case LoaderType.SOS1:
        return getSOS1Loader();
      case LoaderType.SOS2:
        return getSOS2Loader();
      default:
        return defaultLoader();
    }
  }, [props.placeholder]);

  return <>{displayLoaderComponent}</>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
  subContainer: {
    justifyContent: "space-between"
  },
  imageStyle: {
    marginVertical: 60,
    height: 80,
    width: 80
  },
  firstPlaceholder: {
    width: 16,
    height: 16,
    backgroundColor: colorPallete.grey7,
    borderRadius: 2
  },
  secondPlaceholder: {
    width: 240,
    height: 16,
    backgroundColor: colorPallete.grey7,
    borderRadius: 2,
    marginHorizontal: 36
  },
  divider: { marginVertical: 12 },
  loaderComponent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  placeholderLine: {
    width: 150,
    height: 16,
    backgroundColor: colorPallete.grey7,
    borderRadius: 2,
    marginTop: 24
  },
  placeholderBox: {
    width: 150,
    height: 84,
    backgroundColor: colorPallete.grey7,
    borderRadius: 2,
    marginTop: 24
  },
  placeholderSmall: {
    height: 30,
    width: 120,
    marginLeft: 24
  },
  placeholderMedium: {
    height: 30,
    width: 90,
    marginLeft: 24
  },
  placeholderTiny: {
    height: 16,
    width: 16,
    alignSelf: "center"
  }
});
