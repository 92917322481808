import { LpIndividualAccessSummaryDataModel } from "@timhortons/common/src/models/lp/reports";
import { graphColors } from "@timhortons/common/src/utils/graphColorHelper";

export function MonthOnMonthBarData(summaryData: LpIndividualAccessSummaryDataModel) {
  return {
    series: [
      {
        data: summaryData && summaryData.monthOnMonthAverageData.percentage
      }
    ],
    columnWidth: "35",
    categories: summaryData && summaryData.monthOnMonthAverageData.month,
    categoryColors: graphColors.gradeGraph,
    leftTitle: "Month on Month Avg. Score",
    legends: [
      {
        key: "A",
        color: graphColors.gradeGraph[0]
      },
      {
        key: "B",
        color: graphColors.gradeGraph[1]
      },
      {
        key: "C",
        color: graphColors.gradeGraph[2]
      },
      {
        key: "D",
        color: graphColors.gradeGraph[3]
      },
      {
        key: "F",
        color: graphColors.gradeGraph[4]
      }
    ],
    isMonthOnMonth: true,
    labelSuffix: "%",
    height: 300,
    // ymax: fetchYMax(summaryData && summaryData.monthOnMonthAverageData.percentage, 25)
    ymax: 100
  };
}

export const lpStoreRankingTableHeaderData = (translate: any) => {
  return [
    { name: translate("rank") },
    { name: translate("storeNames") },
    { name: translate("country") },
    { name: translate("grade") },
    { name: translate("criticalPoints") },
    { name: translate("overall") }
  ];
};
