import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import { prodsheetKpiData } from "@timhortons/common/src/models/productionSheet/reportBuilder";

export interface IProductionReportsLoading {
  generatedReportBuilderResponse: boolean;
}
export interface IProductionReportsError {
  generatedReportBuilderResponse: string;
}
export interface IProductionReportsState {
  error: IProductionReportsError;
  loading: IProductionReportsLoading;
  generatedReportBuilderResponse: prodsheetKpiData[];
}

export const lpReportsInitialState: IProductionReportsState = {
  error: {
    generatedReportBuilderResponse: null
  },
  loading: {
    generatedReportBuilderResponse: false
  },
  generatedReportBuilderResponse: null
};

export const productionReportsReducer = (
  state = lpReportsInitialState,
  action: { payload: any; type: string }
): IProductionReportsState => {
  switch (action.type) {
    case PRODUCTION_SHEET.ReportBuilder.GENERATE_REPORT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, generatedReportBuilderResponse: true },
        error: { ...state.error, generatedReportBuilderResponse: null }
      };
    case PRODUCTION_SHEET.ReportBuilder.GENERATE_REPORT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, generatedReportBuilderResponse: false },
        error: { ...state.error, generatedReportBuilderResponse: null },
        generatedReportBuilderResponse: action.payload
      };
    case PRODUCTION_SHEET.ReportBuilder.GENERATE_REPORT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, generatedReportBuilderResponse: false },
        error: {
          ...state.error,
          generatedReportBuilderResponse: action.payload
        }
      };
    default:
      return state;
  }
};
