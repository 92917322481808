import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import { IHeaderList } from "@timhortons/common/src/modules/productionSheet/organisms/productionSheetTable";
import {
  AmPmEmployeeProducts,
  BakingStationCatResp,
  OthersStationCatResp,
  StationProducts
} from "@timhortons/common/src/models/productionSheet/psModels";

export interface IProductionSheetLoading {
  getStationProducts: boolean;
  getProductItems: boolean;
  getBakingStationHeaders: boolean;
  getAmPmEmployee: boolean;
  postPSData: boolean;
  bakingStationCategories: boolean;
  othersStationCategories: boolean;
  postAmPmEmployeeAuth: boolean;
  othersStationProducts: boolean;
  postOthersPSData: boolean;
}

export interface IProductionSheetError {
  getStationProducts: string;
  getProductItems: string;
  getBakingStationHeaders: string;
  postPSData: string;
  postAmPmEmployeeAuth: string;
  getAmPmEmployee: string;
  bakingStationCategories: string;
  othersStationCategories: string;
  othersStationProducts: string;
  postOthersPSData: string;
}

export interface IProductionSheetReducerState {
  loading: IProductionSheetLoading;
  error: IProductionSheetError;
  getStationProducts: StationProducts[];
  getBakingStationHeaders: IHeaderList[];
  postPSData: any;
  postOthersPSData: any;
  bakingStationCategories: BakingStationCatResp[];
  othersStationCategories: OthersStationCatResp[];
  othersStationProducts: any;
  postAmPmEmployeeAuth: any;
  getAmPmEmployee: AmPmEmployeeProducts[];
}

export const productionSheetInitialState: IProductionSheetReducerState = {
  loading: {
    getStationProducts: false,
    getProductItems: false,
    getBakingStationHeaders: false,
    postPSData: false,
    bakingStationCategories: false,
    othersStationCategories: false,
    postAmPmEmployeeAuth: false,
    othersStationProducts: false,
    getAmPmEmployee: false,
    postOthersPSData: false
  },
  error: {
    getStationProducts: null,
    getProductItems: null,
    getBakingStationHeaders: null,
    postPSData: null,
    bakingStationCategories: null,
    othersStationCategories: null,
    postAmPmEmployeeAuth: null,
    othersStationProducts: null,
    getAmPmEmployee: null,
    postOthersPSData: null
  },
  getStationProducts: null,
  getBakingStationHeaders: null,
  postPSData: null,
  bakingStationCategories: null,
  othersStationCategories: null,
  postAmPmEmployeeAuth: null,
  othersStationProducts: null,
  getAmPmEmployee: null,
  postOthersPSData: null
};

export const productionSheet = (
  state = productionSheetInitialState,
  action: { payload: any; type: string }
): IProductionSheetReducerState => {
  switch (action.type) {
    case PRODUCTION_SHEET.ProductionSheet.GET_STATION_PRODUCTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getStationProducts: true },
        error: { ...state.error, getStationProducts: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STATION_PRODUCTS.SUCCESS:
      let datum = action.payload;
      datum.forEach((element: any) => {
        element.productList = [];
      });
      return {
        ...state,
        loading: { ...state.loading, getStationProducts: false },
        getStationProducts: datum
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STATION_PRODUCTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getStationProducts: false },
        error: { ...state.error, getStationProducts: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_PRODUCT_ITEMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProductItems: true },
        error: { ...state.error, getProductItems: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_PRODUCT_ITEMS.SUCCESS:
      let data = state.getStationProducts;
      let index = data.findIndex((el: any) => el.id === action.payload.productId);
      if (index > -1) {
        data[index].shelfLife = action.payload.apiRes[0]?.shelfLife;
        data[index].productList = action.payload.apiRes;
      }
      return {
        ...state,
        loading: { ...state.loading, getProductItems: false },
        getStationProducts: data
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_PRODUCT_ITEMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProductItems: false },
        error: { ...state.error, getProductItems: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STORE_HEADERS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getBakingStationHeaders: true },
        error: { ...state.error, getBakingStationHeaders: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STORE_HEADERS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getBakingStationHeaders: false },
        getBakingStationHeaders: action.payload
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STORE_HEADERS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getBakingStationHeaders: false },
        error: { ...state.error, getBakingStationHeaders: action.payload }
      };

    case PRODUCTION_SHEET.ProductionSheet.POST_PRODUCTION_SHEET_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postPSData: true },
        error: { ...state.error, postPSData: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.POST_PRODUCTION_SHEET_DATA.SUCCESS:
      let datam = state.getStationProducts;
      let subCat = datam?.find((item) => item.id === action.payload.productId);
      let subCatIndex = datam?.findIndex((item) => item.id === action.payload.productId);
      if (subCat !== null) {
        let itemIndex = subCat.productList.findIndex(
          (item) => item.productId === action.payload.itemId
        );
        if (itemIndex > -1) {
          datam[subCatIndex].productList[itemIndex].productValues = action.payload.apiRes.values;
          datam[subCatIndex].productList[itemIndex].productionSheetItems =
            action.payload.apiRes.productSheet;
        }
      }
      return {
        ...state,
        loading: { ...state.loading, postPSData: false },
        postPSData: action.payload,
        getStationProducts: datam
      };
    case PRODUCTION_SHEET.ProductionSheet.POST_PRODUCTION_SHEET_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postPSData: false },
        error: { ...state.error, postPSData: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STORE_SPECIFIC_STATIONS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, bakingStationCategories: true },
        error: { ...state.error, bakingStationCategories: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STORE_SPECIFIC_STATIONS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, bakingStationCategories: false },
        bakingStationCategories: action.payload
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_STORE_SPECIFIC_STATIONS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, bakingStationCategories: false },
        error: { ...state.error, bakingStationCategories: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.RESET_PRODUCTION_SHEET:
      state = productionSheetInitialState;
      return state;
    case PRODUCTION_SHEET.ProductionSheet.GET_AM_PM_EMPLOYEE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAmPmEmployee: true },
        error: { ...state.error, getAmPmEmployee: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_AM_PM_EMPLOYEE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAmPmEmployee: false },
        error: { ...state.error, getAmPmEmployee: null },
        getAmPmEmployee: action.payload
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_AM_PM_EMPLOYEE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAmPmEmployee: false },
        error: { ...state.error, getAmPmEmployee: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.POST_AM_PM_EMPLOYEE_AUTH.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postAmPmEmployeeAuth: true },
        error: { ...state.error, postAmPmEmployeeAuth: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.POST_AM_PM_EMPLOYEE_AUTH.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postAmPmEmployeeAuth: false },
        error: { ...state.error, postAmPmEmployeeAuth: null },
        postAmPmEmployeeAuth: action.payload
      };
    case PRODUCTION_SHEET.ProductionSheet.POST_AM_PM_EMPLOYEE_AUTH.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postAmPmEmployeeAuth: false },
        error: { ...state.error, postAmPmEmployeeAuth: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, othersStationCategories: true },
        error: { ...state.error, othersStationCategories: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_CATEGORIES.SUCCESS:
      let updatedCat = action.payload;

      updatedCat.forEach((element: any) => {
        element.productList = [];
        element.shelfLife = null;
      });
      return {
        ...state,
        loading: { ...state.loading, othersStationCategories: false },
        othersStationCategories: updatedCat
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, othersStationCategories: false },
        error: { ...state.error, othersStationCategories: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_PRODUCTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, othersStationProducts: true },
        error: { ...state.error, othersStationProducts: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_PRODUCTS.SUCCESS:
      let catdata = state.othersStationCategories;
      let catIndex = catdata.findIndex((el: any) => el.value === action.payload.productCategoryId);
      if (catIndex > -1) {
        catdata[catIndex].shelfLife = action.payload.data[0].shelfLife;
        catdata[catIndex].productList = action.payload.data;
      }

      return {
        ...state,
        loading: { ...state.loading, othersStationProducts: false },
        othersStationProducts: action.payload.data,
        othersStationCategories: catdata
      };
    case PRODUCTION_SHEET.ProductionSheet.GET_OTHERS_PRODUCTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, othersStationProducts: false },
        error: { ...state.error, othersStationProducts: action.payload.data }
      };

    case PRODUCTION_SHEET.ProductionSheet.POST_OTHERS_PRODUCTION_SHEET_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postOthersPSData: true },
        error: { ...state.error, postOthersPSData: null }
      };
    case PRODUCTION_SHEET.ProductionSheet.POST_OTHERS_PRODUCTION_SHEET_DATA.SUCCESS:
      let catedata = state.othersStationCategories;
      for (let i = 0, n = state.othersStationCategories.length; i < n; i++) {
        let cateIndex = catedata[i].productList.findIndex(
          (el: any) => el.productId === action.payload.apiRes.productId
        );

        if (cateIndex > -1) {
          catedata[i].productList[cateIndex].productionSheetItems =
            action.payload.apiRes.productionSheetItems;
        }
      }
      return {
        ...state,
        loading: { ...state.loading, postOthersPSData: false },
        postOthersPSData: action.payload,
        othersStationCategories: catedata
      };
    case PRODUCTION_SHEET.ProductionSheet.POST_OTHERS_PRODUCTION_SHEET_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postOthersPSData: false },
        error: { ...state.error, postOthersPSData: action.payload }
      };
    case PRODUCTION_SHEET.ProductionSheet.RESET_PS_STATION_AUTH:
      return {
        ...state,
        error: {
          ...state.error,
          postAmPmEmployeeAuth: null
        }
      };
    case PRODUCTION_SHEET.ProductionSheet.RESET_BAKING_PS_ERROR_STATE:
      return {
        ...state,
        error: {
          ...state.error,
          postAmPmEmployeeAuth: null,
          getAmPmEmployee: null,
          getBakingStationHeaders: null,
          getProductItems: null,
          getStationProducts: null,
          bakingStationCategories: null
        }
      };
    case PRODUCTION_SHEET.ProductionSheet.RESET_HEADER_PRODUCT_STATION:
      return {
        ...state,
        getBakingStationHeaders: null,
        getStationProducts: null
      };
    default:
      return state;
  }
};
