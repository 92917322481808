import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, StyleProp, TextStyle, ViewStyle } from "react-native";
import { CheckboxLpRev, CheckboxType } from "@timhortons/common/src/components/atoms/CheckboxLpRev";
import { TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { Orientations } from "@timhortons/common/src/components/atoms/divider";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";

export interface ICheckBoxOption {
  name: string;
  key: number;
  selected: boolean;
  id?: number;
}
interface IProps {
  checkBoxOptions: ICheckBoxOption[];
  showId?: boolean;
  checkBoxContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<TextStyle>;
  orientation?: Orientations;
  iconSize?: number;
  iconStyle?: object;
  iconSelectedStyle?: object;
  textStyle?: StyleProp<TextStyle>;
  fontWeight?: FontWeight;
  fontSize?: TextSize;
  parentCallBack?: Function;
}
const getContainerStyle = (orientation: Orientations, containerStyle = {}): ViewStyle => {
  let themedStyle: StyleProp<ViewStyle>;
  if (orientation === Orientations.Horizontal) {
    themedStyle = styles.horizontal;
  } else {
    themedStyle = styles.vertical;
  }
  return StyleSheet.flatten([styles.container, themedStyle, containerStyle]);
};
export default function CheckboxGroup(props: IProps): ReactElement {
  const {
    checkBoxOptions,
    containerStyle = {},
    orientation = Orientations.Horizontal,
    checkBoxContainerStyle = {},
    parentCallBack: Function,
    iconStyle = {},
    fontSize = TextSize.Small,
    fontWeight = FontWeight.Light,
    textStyle,
    showId = false,
    ...rest
  } = props;
  const [checkBoxItems, setcheckBoxItems] = useState(checkBoxOptions);

  useEffect(() => {
    setcheckBoxItems(checkBoxOptions);
  }, [checkBoxOptions]);

  const style = getContainerStyle(orientation, containerStyle);
  const onPress = (option: ICheckBoxOption) => {
    const updatedItems = checkBoxItems.map((item) => {
      if (item.key === option.key) {
        callBackReference({ ...item, selected: !item.selected });
        return { ...item, selected: !item.selected };
      }

      return item;
    });
    setcheckBoxItems(updatedItems);
  };

  const callBackReference = (item: Object) => {
    props.parentCallBack(item);
  };

  return (
    <View style={style}>
      {checkBoxItems?.map((option, index) => {
        return (
          <CheckboxLpRev
            {...rest}
            selected={option.selected}
            testId={testIds.checkBoxId}
            onPress={() => onPress(option)}
            key={option.key}
            idStyles={styles.idStyles}
            id={showId ? `${index + 1}.` : null}
            type={CheckboxType.Square}
            text={option.name}
            textSize={fontSize}
            textFontWeight={fontWeight}
            containerStyle={checkBoxContainerStyle}
            iconStyle={iconStyle}
            textStyle={textStyle}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    flex: 1
  },
  horizontal: {
    // margin: 10,
    flexDirection: "row"
  },
  vertical: {
    flexDirection: "column"
  },
  idStyles: {
    marginHorizontal: 10,
    color: colorPallete.grey1
  }
});
