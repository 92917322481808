import { JsonObject, JsonProperty } from "json2typescript";

export interface PostMainMenuPayload {
  products: Array<{ id: string; isSelected: boolean }>;
  storeCode: number;
  employeeId: string;
  date: string;
  stationId: string;
}
export interface PostMainMenuApiResp {
  id: string;
  isSelected: boolean;
  name: string;
}
@JsonObject("IProductList")
export class IProductList {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  product: string = "";
  @JsonProperty("isSelected", Boolean)
  isSelected: boolean = false;
}
@JsonObject("SubCategoriesModel")
export class SubCategoriesModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  productName: string = "";
  @JsonProperty("isSelected", Boolean)
  isSelected: boolean;
  // @JsonProperty("shelfLife", Number&&true)
  // shelfLife?: number = 0;
  @JsonProperty("products", [IProductList])
  items: IProductList[] = null;
}
@JsonObject("BakingTableModel")
export class BakingTableModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  category: string = "";
  // @JsonProperty("shelfLife", Number&&true)
  // shelfLife?: number = 0;
  @JsonProperty("isSelected", Boolean)
  isSelected: boolean = false;
  @JsonProperty("subCategories", [SubCategoriesModel], true)
  products: SubCategoriesModel[] = null;
}
@JsonObject("ICategoryList")
export class ICategoryList {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  category: string = "";
  @JsonProperty("isSelected", Boolean)
  isSelected: boolean;
  @JsonProperty("shelfLife", Number, true)
  shelfLife: number = 0;
  @JsonProperty("products", [IProductList])
  products: IProductList[] = null;
}
