import { Any, JsonObject, JsonProperty } from "json2typescript";
import { boolean } from "yup";

class ModuleDurationModel {
  @JsonProperty("hr", Number)
  hr: number = 0;
  @JsonProperty("min", Number)
  min: number = 0;
}

@JsonObject("LMSModuleListModel")
export class LMSModuleListModel {
  @JsonProperty("moduleId", String)
  moduleId: string = "";
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("thumbNail", String)
  thumbNail: string = "";
  @JsonProperty("moduleDuration", ModuleDurationModel)
  moduleDuration: ModuleDurationModel;
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("completionPercentage", Number)
  completionPercentage?: number = 0;
  @JsonProperty("completePercentage", Number)
  completePercentage?: number = 0;
  @JsonProperty("isClickable", Boolean)
  isClickable: boolean = false;
}

@JsonObject("LmsCertificateCarousel")
export class LmsCertificateCarousel {
  @JsonProperty("heading", String)
  heading: string = "";
  @JsonProperty("body", String)
  body: string = "";
  @JsonProperty("image", String)
  image: string = "";
}

@JsonObject("LMSContinueLearningModel")
export class LMSContinueLearningModel {
  @JsonProperty("videoId", String)
  videoId: string = "";
  @JsonProperty("videoName", String)
  videoName: string = "";
  @JsonProperty("moduleId", String)
  moduleId: string = "";
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("topicId", null)
  topicId: string = null;
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("videoUrl", String)
  videoUrl: string = "";
  @JsonProperty("playBackTime", Number)
  playBackTime: number = 0;
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("subTopicId", null)
  subTopicId: string = null;
  @JsonProperty("thumbnail", String)
  thumbnail: string = "";
  @JsonProperty("isAutoPlay", boolean, true)
  autoPlay?: boolean = false;
  @JsonProperty("topicName", String)
  topicName: string = "";
  @JsonProperty("subTopicName", String, true)
  subTopicName: string = null;
}

@JsonObject("EarnedPointModel")
export class EarnedPointModel {
  @JsonProperty("employeeId", String)
  employeeId: string = "";
}
export class Quiz {
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("status", String)
  status: string = "";
}

export class publishSubTopicsModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("videoUrl", String)
  videoUrl: string = "";
  @JsonProperty("videoId", String)
  videoId: string = "";
  @JsonProperty("duration", ModuleDurationModel)
  duration: ModuleDurationModel = null;
  @JsonProperty("quiz", Quiz)
  quiz: Quiz = null;
}
export class LMSTopicModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("videoUrl", String)
  videoUrl: string = "";
  @JsonProperty("videoId", String)
  videoId: string = "";
  @JsonProperty("duration", Number)
  // duration: number = 0;
  duration: ModuleDurationModel = null;
  @JsonProperty("quiz", Quiz)
  quiz: Quiz = null;
  @JsonProperty("publishSubTopics", [publishSubTopicsModel])
  publishSubTopics: publishSubTopicsModel[] = null;
}

@JsonObject("LMSTopicSubtopicModel")
export class LMSTopicSubtopicModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("label", String)
  label: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("publishTopics", [LMSTopicModel])
  publishTopics: LMSTopicModel[] = [];
}

@JsonObject("LMSCategoriesListModel")
export class LMSCategoriesListModel {
  @JsonProperty("uuid", String)
  uuid: string = "";
  @JsonProperty("label", String)
  label: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("totalDuration", ModuleDurationModel)
  totalDuration: ModuleDurationModel;
  @JsonProperty("completionPercentage", Number)
  completionPercentage: number = 0;
  @JsonProperty("numberofVideos", Number)
  numberofVideos: number = 0;
  @JsonProperty("topicSubtopic", LMSTopicSubtopicModel, true)
  topicSubtopic?: LMSTopicSubtopicModel = null;
}

@JsonObject("LMSUserCategoriesModel")
export class LMSUserCategoriesModel {
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("moduleId", String)
  moduleId: string = "";
  @JsonProperty("currentCategory", String, true)
  currentCategory?: string = null;
  @JsonProperty("categories", [LMSCategoriesListModel])
  categories: LMSCategoriesListModel[] = null;
  @JsonProperty("numberOfPtsChecklists", Number)
  numberOfPtsChecklists: number = 0;
  @JsonProperty("assessmentQuestions", Number)
  assessmentQuestions: number = 0;
  // @JsonProperty("assessmentState", Boolean)
  // assessmentState: boolean = false;
  @JsonProperty("ptsStatus", String)
  ptsStatus: string = "";
  @JsonProperty("assessmentStatus", String)
  assessmentStatus: string = "";
  @JsonProperty("assessmentDuration", String)
  assessmentDuration: string = "";
}

@JsonObject("LMSVideoUpdateModel")
export class LMSVideoUpdateModel {
  @JsonProperty("publishTopicId", String)
  publishTopicId: string = "";
  @JsonProperty("publishSubTopicId", String, true)
  publishSubTopicId?: string = "";
  @JsonProperty("publishVideoId", String)
  publishVideoId: string = "";
  @JsonProperty("playBackTime", Number)
  playBackTime: Number = 0;
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("status", String)
  status: string = "";
}

@JsonObject("TotalDaysLeft")
export class TotalDaysLeft {
  @JsonProperty("daysLeft", Number)
  daysLeft: number = 0;
}

@JsonObject("LMSQuizDetailDataModel")
export class LMSQuizDetailDataModel {
  @JsonProperty("quizId", String)
  quizId: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("quizType", String)
  quizType: string = "";
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("topicId", String)
  topicId: string = "";
  @JsonProperty("subTopicId", String)
  subTopicId?: string = "";
}

@JsonObject("LMSQuizQuestionMCQModel")
export class LMSQuizQuestionMCQModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("option", String)
  option: string = "";
  @JsonProperty("response", Boolean)
  response: boolean = false;
}

@JsonObject("LMSGetQuizTableMCQOptionModel")
export class LMSGetQuizTableMCQOptionModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("option", String)
  option: string = "";
  @JsonProperty("response", Boolean)
  response: boolean = false;
}

@JsonObject("LMSGetQuizTableMCQModel")
export class LMSGetQuizTableMCQModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("questionId", String)
  questionId: string = "";
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("quizId", String)
  quizId: string = "";
  @JsonProperty("quizType", String)
  quizType: string = "";
  @JsonProperty("image", String)
  image: string = "";
  @JsonProperty("cell", String)
  cell: string = "";
  @JsonProperty("isActive", Boolean)
  isActive: boolean = false;
  @JsonProperty("options", [LMSGetQuizTableMCQOptionModel])
  options: Array<LMSGetQuizTableMCQOptionModel> = [];
}

@JsonObject("LMSPostQuizResponseModel")
export class LMSPostQuizResponseModel {
  @JsonProperty("result", String)
  result: string = "";
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("isCorrect", Boolean)
  isCorrect?: boolean = false;
}

@JsonObject("FAQsModel")
export class FAQsModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("answer", null)
  answer: string = null;
  @JsonProperty("image", null)
  image: string = null;
}

@JsonObject("FAQSearchResultModel")
export class FAQSearchResultModel {
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
  @JsonProperty("content", [FAQsModel])
  content: Array<FAQsModel> = [];
}

@JsonObject("DescriptionModel")
export class DescriptionModel {
  @JsonProperty("insert", String)
  insert: string = "";
}

@JsonObject("NewsListModel")
export class NewsListModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("publishCategory", String)
  publishCategory: string = "";
  @JsonProperty("featureMedia", [String])
  featureMedia: string[] = [];
  @JsonProperty("publishedDate", String)
  publishedDate: string = "";
  @JsonProperty("description", DescriptionModel)
  description: DescriptionModel = null;
}

@JsonObject("NewsDetailModel")
export class NewsDetailModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("publishCategory", String)
  publishCategory: string = "";
  @JsonProperty("featureMedia", [String])
  featureMedia: string[] = [];
  @JsonProperty("publishedDate", String)
  publishedDate: string = "";
  @JsonProperty("description", [])
  description: [] = [];
}

@JsonObject("LearningPathDetailsModel")
export class LearningPathDetailsModel {
  @JsonProperty("publishModuleId", String)
  publishModuleId: string = "";
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("completionPercentage", Number && null)
  completionPercentage: number = 0;
  @JsonProperty("moduleStatus", String)
  moduleStatus: string = "";
}

@JsonObject("LearningPathDetailsResponseModel")
export class LearningPathDetailsResponseModel {
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("moduleStatusResponseDtoList", [LearningPathDetailsModel])
  moduleStatusResponseDtoList: Array<LearningPathDetailsModel> = null;
}
@JsonObject("PublishModuleModel")
export class PublishModuleModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("publishModuleName", String)
  publishModuleName: string = "";
  @JsonProperty("percentage", Number)
  percentage: Number = 0;
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("practicalAssessment", Boolean)
  practicalAssessment: boolean = false;
}

@JsonObject("StatisticsModel")
export class StatisticsModel {
  @JsonProperty("completedPercent", Number)
  completedPercent: Number = 0;
  @JsonProperty("inProgressPercent", Number)
  inProgressPercent: Number = 0;
  @JsonProperty("pendingPercent", Number)
  pendingPercent: Number = 0;
  @JsonProperty("birthdayCake", Number)
  birthdayCake: Number = 0;
  @JsonProperty("chocolateGlazed", Number)
  chocolateGlazed: Number = 0;
  @JsonProperty("honeyDip", Number)
  honeyDip: Number = 0;
  @JsonProperty("publishModuleResponseDtos", [PublishModuleModel])
  publishModuleResponseDtos: Array<PublishModuleModel> = null;
}

@JsonObject("ModuleDetailModel")
export class ModuleDetailModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("publishModuleName", String)
  publishModuleName: string = "";
  @JsonProperty("status", String && null)
  status: string = "";
  @JsonProperty("score", Number && null)
  score: number = 0;
  @JsonProperty("duration", String && null)
  duration: string = "";
  @JsonProperty("completedOn", String && null)
  completedOn: string = "";
  @JsonProperty("theoreticalAssesment", String && null)
  theoreticalAssesment: string = "";
  @JsonProperty("practicalAssesment", String && null)
  practicalAssesment: string = "";
  @JsonProperty("timbit", String && null)
  timbit: string = "";
  @JsonProperty("description", String && null)
  description: string = "";
  @JsonProperty("certificateStartOn", String && null)
  certificateStartOn: string = "";
  @JsonProperty("certificateEndOn", String && null)
  certificateEndOn: string = "";
  @JsonProperty("expiry", String && null)
  expiry: string = null;
  @JsonProperty("hasCertificate", Boolean)
  hasCertificate: boolean = false;
  @JsonProperty("certificateDate", String && null)
  certificateDate: string = null;
}
@JsonObject("LMSModuleData")
export class LMSModuleData {
  @JsonProperty("coreTraining", [LMSModuleListModel])
  coreTraining: LMSModuleListModel[] = [];
  @JsonProperty("specialTraining", [LMSModuleListModel])
  specialTraining: LMSModuleListModel[] = [];
}

@JsonObject("DragDropQuizQuesModel")
export class DragDropQuizQuesModel {
  @JsonProperty("ans", String)
  ans: string = "";
  @JsonProperty("hover", Boolean)
  hover: boolean = false;
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("imageUrl", String)
  imageUrl: string = "";
  @JsonProperty("ques", String)
  ques: string = "";
  @JsonProperty("ansId", String)
  ansId: string = "";
}

@JsonObject("MTFDNDPathVariableModel")
export class MTFDNDPathVariableModel {
  @JsonProperty("publishQuizId", String)
  publishQuizId: string = "";
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("topicId", String)
  topicId: string = "";
}

@JsonObject("MTFDNDParamsModel")
export class MTFDNDParamsModel {
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("quizType", String)
  quizType: string = "";
  @JsonProperty("subTopicId", String)
  subTopicId?: string = "";
}

@JsonObject("MTFDNDQuizBodyModel")
export class MTFDNDQuizBodyModel {
  @JsonProperty("option", String)
  option: string = "";
  @JsonProperty("optionName", String)
  optionName: string = "";
  @JsonProperty("definition", String)
  definition: string = "";
  @JsonProperty("definitionName", String)
  definitionName: string = "";
}
@JsonObject("MtfDndModel")
export class MtfDndModel {
  @JsonProperty("option", Number)
  option: number = 0;
  @JsonProperty("optionName", String)
  optionName: string = "";
  @JsonProperty("definition", Number)
  definition: number = 0;
  @JsonProperty("definitionName", String)
  definitionName: string = "";
}

@JsonObject("MTFDNDRequestBodyModel")
export class MTFDNDRequestBodyModel {
  @JsonProperty("pathVariables", MTFDNDPathVariableModel)
  pathVariables: MTFDNDPathVariableModel = null;
  @JsonProperty("paramsData", MTFDNDParamsModel)
  paramsData: MTFDNDParamsModel = null;
  @JsonProperty("quizBody", [MTFDNDQuizBodyModel])
  quizBody: MTFDNDQuizBodyModel[] = [];
}
@JsonObject("SubmitAssessmentModel")
export class SubmitAssessmentModel {
  @JsonProperty("quizId", String)
  quizId: string = "";
  @JsonProperty("questionId", String)
  questionId: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("responseId", String)
  responseId: string = "";
  @JsonProperty("quizType", String)
  quizType: string = "";
  @JsonProperty("quizBody", MtfDndModel)
  quizBody?: MtfDndModel = null;
}

@JsonObject("MTFDNDGetDetailsOptionsModel")
export class MTFDNDGetDetailsOptionsModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("imageUrl", String)
  imageUrl: string = "";
  @JsonProperty("selectedDefinitionId", String)
  selectedDefinitionId: string = "";
  @JsonProperty("value", String)
  value: string = "";
}

@JsonObject("MTFDNDPublishArrModel")
export class MTFDNDPublishArrModel {
  @JsonProperty("definitions", [MTFDNDGetDetailsOptionsModel])
  definitions: MTFDNDGetDetailsOptionsModel[] = [];
  @JsonProperty("options", [MTFDNDGetDetailsOptionsModel])
  options: MTFDNDGetDetailsOptionsModel[] = [];
}

@JsonObject("MTFDNDRequestDataModel")
export class MTFDNDRequestDataModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("isActive", Boolean)
  isActive: boolean = false;
  @JsonProperty("optionDefinition", String)
  optionDefinition: string = "";
  @JsonProperty("publishOptionDefinition", [MTFDNDPublishArrModel])
  publishOptionDefinition: MTFDNDPublishArrModel[] = [];
  @JsonProperty("quizType", String)
  quizType: string = "";
  @JsonProperty("type", String)
  type: string = "";
}

@JsonObject("LMSGetQuizTableModel")
export class LMSGetQuizTableModel {
  @JsonProperty("quizId", String)
  quizId: string = "";
  @JsonProperty("quizType", String)
  quizType: string = "";
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("topicId", String)
  topicId: string = "";
  @JsonProperty("subTopicId", String)
  subTopicId: string = "";
  @JsonProperty("mcqTtt", LMSGetQuizTableMCQModel)
  mcqTtt?: LMSGetQuizTableMCQModel = null;
  @JsonProperty("mtfDnd", MTFDNDRequestDataModel)
  mtfDnd?: MTFDNDRequestDataModel = null;
}

@JsonObject("GetTimbitCollection")
export class GetTimbitCollection {
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("startDate", String)
  startDate: string = "";
  @JsonProperty("endDate", String)
  endDate: string = "";
}

@JsonObject("TimbitCollectionModel")
export class TimbitCollectionModel {
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("profileUrl", String)
  profileUrl: string = "";
  @JsonProperty("birthdayCake", Number)
  birthdayCake: number = 0;
  @JsonProperty("chocolateGlazed", Number)
  chocolateGlazed: number = 0;
  @JsonProperty("honeyDip", Number)
  honeyDip: number = 0;
  @JsonProperty("score", Number)
  score: number = 0;
}

@JsonObject("AssessmentResultModel")
export class AssessmentResultModel {}

@JsonObject("TotalDaysLeftModel")
export class TotalDaysLeftModel {
  @JsonProperty("moduleId", String)
  moduleId: string = "";
  @JsonProperty("moduleName", String)
  moduleName: string = "";
  @JsonProperty("moduleStatus", String)
  moduleStatus: string = "";
  @JsonProperty("days", Number)
  days: number = 0;
}
@JsonObject("LeaderboardRequestModel")
export class LeaderboardRequestModel {
  @JsonProperty("countryCodes", Number)
  countryCodes: number = null;
  @JsonProperty("startDate", String)
  startDate?: string = null;
  @JsonProperty("endDate", String)
  endDate?: string = null;
  @JsonProperty("pageNo", Number)
  pageNo: number = 0;
  @JsonProperty("pageSize", Number)
  pageSize: number = 0;
}

@JsonObject("LeaderboardDataModel")
export class LeaderboardDataModel {
  @JsonProperty("employeeId", Number)
  id: number = 0;
  @JsonProperty("name", String)
  name: string = "";
  @JsonProperty("profileUrl", String && null)
  image: string = null;
  @JsonProperty("score", Number)
  score: number = 0;
  @JsonProperty("position", Number, true)
  position?: number = null;
}
@JsonObject("LeaderboardDataListModel")
export class LeaderboardDataListModel {
  @JsonProperty("content", LeaderboardDataModel)
  content: LeaderboardDataModel[] = [];
  @JsonProperty("totalElements", Number)
  totalElements: number = 0;
}

@JsonObject("GenerateResultModel")
export class GenerateResultModel {
  @JsonProperty("completedOn", String)
  completedOn: string = "";
  @JsonProperty("description", String)
  description: string = "";
  @JsonProperty("duration", String)
  duration: string = "";
  @JsonProperty("earned", String)
  earned: string = "";
  @JsonProperty("isSpecial", Boolean)
  isSpecial: boolean = false;
  @JsonProperty("hasCertificate", Boolean)
  hasCertificate: boolean = false;
  @JsonProperty("module", String)
  module: string = "";
  @JsonProperty("practicalAssessment", Boolean)
  practicalAssessment: boolean = false;
  @JsonProperty("isExpired", Boolean)
  isExpired: boolean = false;
  @JsonProperty("score", Number)
  score: number = 0;
  @JsonProperty("certificateDate", String && null)
  certificateDate: string = null;
  @JsonProperty("moduleId", String)
  moduleId: string = "";
}

@JsonObject("EditProfileModel")
export class EditProfileModel {
  @JsonProperty("mobileNumber", String)
  mobileNumber: string = "";
  @JsonProperty("personalEmail", String)
  personalEmail: string = "";
  @JsonProperty("homeAddress", String)
  homeAddress?: string = "";
  @JsonProperty("employeeId", String)
  employeeId: string = "";
  @JsonProperty("selectedImage", Any)
  selectedImage?: any = null;
  @JsonProperty("profilePhoto", Any)
  profilePhoto?: any = null;
  @JsonProperty("imageChange", Boolean)
  imageChange?: boolean = false;
  @JsonProperty("profileKey", String)
  profileKey: string = "";
}

@JsonObject("NotificationModel")
export class NotificationModel {
  @JsonProperty("created", String)
  created: string = "";
  @JsonProperty("deepLink", String)
  deepLink?: string = null;
  @JsonProperty("message", String)
  message: string = "";
  @JsonProperty("serviceType", String)
  serviceType: string = "";
  @JsonProperty("title", String)
  title: string = "";
}
