import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { manageNewsEndPoints } from "@timhortons/common/src/constants/endPoints";
import { ManageNewsKeys } from "@timhortons/common/src/constants/apiKeys";
import {
  NewsListModel,
  NewsDetailModel,
  MediaResponseModel,
  MediaRequestModel,
  NewsFeatureMediaModel,
  DeleteMediaModel,
  DeleteMediaResponseModel
} from "@timhortons/common/src/models/manageNews/manageNews";
import { categoryListFormatter } from "./newsFormatter";
class ManageNewsRepository {
  getNewsList = async () => {
    let data: NewsListModel[] = await ApiClient.get(
      manageNewsEndPoints.getNewsList(),
      {},
      ManageNewsKeys.NEWS_MANAGE
    );
    return data;
  };
  deleteNews = async (param: { titleId: string }) => {
    const { titleId } = param;
    await ApiClient.delete(
      manageNewsEndPoints.deleteNews(titleId),
      null,
      null,
      ManageNewsKeys.NEWS_MANAGE
    );
  };

  getNewsDetails = async (param: { newsId: string }): Promise<NewsDetailModel> => {
    const { newsId } = param;
    let response: NewsDetailModel = await ApiClient.get(
      manageNewsEndPoints.getNewsDetails(newsId),
      null,
      ManageNewsKeys.NEWS_MANAGE
    );
    return response;
  };
  getCategoryList = async () => {
    let response: NewsDetailModel[] = await ApiClient.get(
      manageNewsEndPoints.getCategoryList(),
      null,
      ManageNewsKeys.NEWS_MANAGE
    );
    return categoryListFormatter(response);
  };
  postCreateNewNews = async (params: { name: string }) => {
    const { name } = params;
    let data = await ApiClient.post(
      manageNewsEndPoints.postCreateNewNews(),
      {
        name
      },
      null,
      ManageNewsKeys.NEWS_MANAGE
    );
    return data;
  };
  getMediaLink = async (param: MediaRequestModel): Promise<MediaResponseModel> => {
    let response: MediaResponseModel = await ApiClient.post(
      manageNewsEndPoints.getMediaLink(),
      null,
      param,
      ManageNewsKeys.NEWS_MANAGE
    );
    return response;
  };
  createMedia = async (param: MediaRequestModel): Promise<NewsFeatureMediaModel> => {
    let response: NewsFeatureMediaModel = await ApiClient.post(
      manageNewsEndPoints.createMedia(),
      null,
      param,
      ManageNewsKeys.NEWS_MANAGE
    );
    return response;
  };
  saveNewsDetails = async (params: { titleId: string; categoryName: string; description: any }) => {
    const { titleId, categoryName, description } = params;
    let data = await ApiClient.post(
      manageNewsEndPoints.saveNewsDetails(titleId),
      {
        categoryName: categoryName,
        description: description === undefined ? [] : description
      },
      null,
      ManageNewsKeys.NEWS_MANAGE
    );
    return {
      titleId: titleId,
      newsDetails: data
    };
  };
  uploadResourceImage = async (payload: FormData) => {
    let headers = {
      ...ManageNewsKeys.NEWS_MANAGE,
      "Content-Type": "multipart/form-data"
    };
    let imgPayload = payload as any;
    let data = await ApiClient.post(manageNewsEndPoints.imgPublicUrl(), imgPayload, {}, headers);
    return data;
  };
  publishNews = async (params: { titleId: string }) => {
    const { titleId } = params;
    let response = await ApiClient.post(
      manageNewsEndPoints.publishNews(),
      null,
      { titleId: titleId },
      ManageNewsKeys.NEWS_MANAGE
    );
    return response;
  };
  editTitle = async (params: { titleId: string; name: string }) => {
    const { titleId, name } = params;
    let data = await ApiClient.put(
      manageNewsEndPoints.deleteNews(titleId),
      {
        name: name
      },
      null,
      ManageNewsKeys.NEWS_MANAGE
    );
    return {
      titleId: titleId,
      data: data
    };
  };
  deleteMedia = async (params: DeleteMediaModel): Promise<DeleteMediaResponseModel> => {
    const { fileId, fileName, titleId } = params;
    let apiObj = {
      titleId: titleId,
      fileName: fileName
    };
    let responseObj = {
      fileId: fileId,
      fileName: fileName
    };
    await ApiClient.delete(
      manageNewsEndPoints.createMedia(),
      null,
      apiObj,
      ManageNewsKeys.NEWS_MANAGE
    );
    return responseObj;
  };
}
const manageNewsRepository = new ManageNewsRepository();
export { manageNewsRepository as ManageNewsRepository };
