import i18next, { i18n } from "i18next";
import { I18nManager } from "react-native";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import format from "@timhortons/common/src/services/internalization/format";
import {
  LocaleConstants,
  SupportedLanguages
} from "@timhortons/common/src/services/internalization/constants";
import { ConfigHelper } from "@timhortons/common/src/utils/loadEnv.util";

class I18nextService {
  private readonly _instance: i18n;

  constructor() {
    this._instance = i18next.createInstance();
  }

  public init = async (lng = SupportedLanguages.English): Promise<void> => {
    await this._instance
      .use(initReactI18next)
      .use(HttpApi)
      .init(
        {
          lng,
          resources: LocaleConstants.resources,
          supportedLngs: LocaleConstants.supportedLangList,
          fallbackLng: LocaleConstants.fallback,
          ns: LocaleConstants.namespaces,
          defaultNS: LocaleConstants.defaultNamespace,
          interpolation: {
            escapeValue: false,
            format
          },
          debug: ConfigHelper.getNodeEnv() === "development"
        },
        this.initCallback
      );
    this.forceRTL(lng);
  };

  get instance(): i18n {
    return this._instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public t = (key: string, options?: any): string => {
    if (!this._instance) {
      this.init().then();
    }
    return this._instance.t(key, options);
  };

  public isRTL = (): boolean => {
    return I18nManager.isRTL;
  };

  public select<T>(map: { [position in "rtl" | "ltr"]: T }): T {
    const key = this.isRTL() ? "rtl" : "ltr";
    return map[key];
  }

  public changeLanguage = async (lngCode: SupportedLanguages): Promise<void> => {
    await this._instance.changeLanguage(lngCode);
    this.forceRTL(lngCode);
  };

  public getLanguage = (): string => {
    return this._instance.language;
  };

  /**
   * @returns {'LTR' | 'RTL'}
   */
  public dir = (): string => {
    return this._instance.dir().toUpperCase();
  };

  public forceRTL = (lngCode: SupportedLanguages): void => {
    I18nManager.forceRTL(lngCode === SupportedLanguages.Arabic);
  };

  private initCallback = (error: Error): void => {
    if (error) {
      // Logger.error(error.message);
    } else {
      // Logger.info("Loaded i18next");
    }
  };
}

const i18nService = new I18nextService();
export { i18nService as I18nService };
