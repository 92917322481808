import { ApiClient } from "@timhortons/common/src/network/apiClient";

export function buildQueryString(params: Object | any): null | string {
  const queryString = [];
  const invalidCondn = [null, undefined, ""];

  if (!params || Object.keys(params).length === 0) return "";

  for (const key in params)
    if (!invalidCondn.includes(params[key]))
      queryString.push(`${key}=${encodeURIComponent(params[key])}`);

  return `?${queryString.join("&")}`;
}

export function urlBuilder(endPoint: string, params: Object): string {
  const baseUrl = ApiClient.getUrl();
  const paramQuery = buildQueryString(params);

  return `${baseUrl}${endPoint}${paramQuery}`;
}
