import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { IApiClient, IApiClientOptions } from "@timhortons/common/src/network/interfaces";
import {
  bearerTokenReqInterceptor,
  refreshTokenInterceptor
} from "@timhortons/common/src/network/interceptors";
import { errorHandler, successHandler } from "@timhortons/common/src/network/responseHandlers";

const getAxiosInstance = (baseUrl: string, client: IApiClient): AxiosInstance => {
  let axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
      // "Trace-Id": uuidv4(),
      "Content-Type": "application/json"
    }
  });
  // Add Request Interceptor to add Authorization header
  axiosInstance = bearerTokenReqInterceptor(axiosInstance);
  // Add Response Interceptor for Authorization token expiry
  axiosInstance = refreshTokenInterceptor(axiosInstance, baseUrl);
  return axiosInstance;
};

class ApiClient implements IApiClient {
  private client: AxiosInstance;
  private baseUrl: String;

  private async request(config: AxiosRequestConfig) {
    try {
      let response = await this.client.request(config);
      let handledResponse = await successHandler(response);
      return Promise.resolve(handledResponse);
    } catch (error) {
      let handledError = await errorHandler(error);
      return Promise.reject(handledError);
    }
  }
  public async get(url: string, params?: Object, headers?: IApiClientOptions) {
    return this.request({ method: "GET", url, params, headers });
  }
  public async put(url: string, data: Object, params?: Object, headers?: Object) {
    return this.request({ method: "PUT", url, data, params, headers });
  }
  public async post(url: string, data: Object, params?: Object, headers?: Object) {
    return this.request({ method: "POST", url, data, params, headers });
  }
  public async delete(url: string, data?: Object, params?: Object, headers?: Object) {
    return this.request({ method: "DELETE", url, data, params, headers });
  }
  public async patch(url: string, data: Object, params?: Object) {
    return this.request({ method: "PATCH", url, data, params });
  }
  public async setUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.client = getAxiosInstance(baseUrl, this);
  }
  public getUrl() {
    return this.baseUrl;
  }
}
const apiClient = new ApiClient();

export { apiClient as ApiClient };
