import React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import Card from "@timhortons/common/src/components/atoms/card";
const DonutChart = React.lazy(() => import("@timhortons/web/src/components/molecules/donutChart"));
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { graphColors } from "@timhortons/common/src/utils/graphColorHelper";
import DonutChartMobile from "@timhortons/tablet/src/components/molecules/donutChart";
import { Divider } from "@timhortons/common/src/components/atoms/divider";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { inProcessReportModal } from "models/rev/reports";

interface IProps {
  series: number[];
  labels: string[];
  totalReports?: number;
  translate?: any;
  height?: number;
  containerStyle?: StyleProp<ViewStyle>;
  donutContainerStyle?: StyleProp<ViewStyle>;
  leftHeader?: string;
  textContainerStyle?: StyleProp<ViewStyle>;
  bodyContainerStyle?: StyleProp<ViewStyle>;
  isDividerStyle?: boolean;
  customColor?: string[];
  inProcessReport?: inProcessReportModal;
  showActionItemSteps?: boolean;
}

export default function ActionplanGraph(props: IProps) {
  const {
    textContainerStyle,
    donutContainerStyle,
    containerStyle,
    series,
    labels,
    totalReports,
    translate,
    height,
    leftHeader,
    isDividerStyle,
    bodyContainerStyle,
    customColor,
    inProcessReport,
    showActionItemSteps
  } = props;
  return (
    <React.Fragment>
      <View style={[styles.container, containerStyle]}>
        <Card.Header headerStyle={styles.headerContainer}>
          <Text
            textStyle={styles.headertextStyle}
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.SemiBold}
            testId={testIds.reportSummary.actionPlans}
          >
            {leftHeader}
          </Text>
          {totalReports
            ? totalReports > 0 && (
                <Text
                  textStyle={styles.totalReports}
                  textSize={TextSize.ExtraRegular}
                  fontWeight={FontWeight.SemiBold}
                  testId={testIds.reportSummary.totalReports}
                >
                  {totalReports} {translate("reports")}
                </Text>
              )
            : null}
        </Card.Header>
        <Card.Body bodyStyle={[styles.bodyContainer, bodyContainerStyle]}>
          {PlatformUtils.isWeb() ? (
            <View>
              <React.Suspense fallback={null}>
                <DonutChart
                  isDividerStyle={isDividerStyle}
                  textContainerStyle={textContainerStyle}
                  containerStyle={donutContainerStyle}
                  series={series}
                  labels={labels}
                  colors={customColor ? customColor : graphColors.actionPlanGraph}
                />
              </React.Suspense>
            </View>
          ) : (
            <DonutChartMobile
              isDividerStyle={isDividerStyle}
              textContainerStyle={textContainerStyle}
              containerStyle={donutContainerStyle}
              series={series}
              colors={customColor ? customColor : graphColors.actionPlanGraph}
              labels={labels}
              height={height}
            />
          )}
        </Card.Body>
        {showActionItemSteps && (
          <Card.Footer footerStyle={styles.footerContainer}>
            <Divider dividerStyle={styles.divider} />
            <View style={styles.divisionContainer}>
              <View style={styles.elementContainer}>
                <View style={styles.iconContainer}>
                  <Icon name={IconNames.hourglassStartSolid} customStyle={styles.icon} size={16} />
                </View>
                <Text
                  textStyle={styles.headertextStyle}
                  textSize={TextSize.Medium2}
                  fontWeight={FontWeight.SemiBold}
                  testId={testIds.reportSummary.actionPlans}
                >
                  {translate("inprocessActionStepsCount")}
                </Text>
              </View>
              <View style={styles.inProcessCountContainer}>
                <Text
                  textStyle={styles.inProcessReports}
                  textSize={TextSize.Medium2}
                  fontWeight={FontWeight.SemiBold}
                  testId={testIds.reportSummary.totalReports}
                >
                  {inProcessReport?.inProcessCount} {translate("items")}
                </Text>
              </View>
            </View>
          </Card.Footer>
        )}
      </View>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colorPallete.grey7,
    borderRadius: 4
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    paddingTop: 24
  },
  headertextStyle: {
    color: colorPallete.black3,
    flexWrap: "wrap",
    display: "flex",
    maxWidth: 244
  },
  totalReports: {
    color: colorPallete.grey1
  },
  bodyContainer: {
    paddingTop: 36,
    paddingBottom: PlatformUtils.isWeb() ? 90 : 43
  },
  footerContainer: {
    flexDirection: "column",
    paddingBottom: 16
  },
  divider: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: colorPallete.grey7
  },
  divisionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingTop: 16,
    paddingHorizontal: 20
  },
  elementContainer: { flexDirection: "row", alignItems: "center", flex: 1 },
  iconContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: colorPallete.blue4,
    width: 32,
    height: 28,
    ...I18nService.select({
      rtl: { marginEnd: 16 },
      ltr: { marginEnd: 16 }
    })
  },
  icon: {
    color: colorPallete.whiite7
  },
  inProcessCountContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: colorPallete.white1
  },
  inProcessReports: {
    color: colorPallete.black3,
    paddingTop: PlatformUtils.isWeb() ? 2 : 0,
    paddingBottom: PlatformUtils.isWeb() ? 2 : 6,
    paddingHorizontal: 20
  }
});
