import { MANAGEASK } from "@timhortons/common/src/redux/askTell/actions/actionTypes";
import {
  AskTellQuestionCountModel,
  AskTellSupportTicketModel,
  QuestionModel,
  QuestionsListModel
} from "@timhortons/common/src/models/askTell/manageAskModule";

export interface ISupportTicketsLoading {
  getQuestionCount: boolean;
  getQuestionByStatus: boolean;
  postAnswerReply: boolean;
  updateAnswerReply: boolean;
}

export interface ISupportTicketsError {
  getQuestionCount: string;
  getQuestionByStatus: string;
  postAnswerReply: string;
  updateAnswerReply: string;
}

export interface ISupportTicketsState {
  error: ISupportTicketsError;
  loading: ISupportTicketsLoading;
  getQuestionCount: AskTellQuestionCountModel;
  getQuestionByStatus: QuestionsListModel;
  postAnswerReply: AskTellSupportTicketModel;
  updateAnswerReply: AskTellSupportTicketModel;
}

export const supportTicketsInitialState: ISupportTicketsState = {
  getQuestionCount: { answeredCount: 0, pendingCount: 0 },
  getQuestionByStatus: null,
  postAnswerReply: null,
  updateAnswerReply: null,
  loading: {
    getQuestionCount: false,
    getQuestionByStatus: false,
    postAnswerReply: false,
    updateAnswerReply: false
  },
  error: {
    getQuestionCount: null,
    getQuestionByStatus: null,
    postAnswerReply: null,
    updateAnswerReply: null
  }
};

export const supportTicketsReducer = (
  state = supportTicketsInitialState,
  action: { payload: any; type: string }
): ISupportTicketsState => {
  switch (action.type) {
    //get question count reducer
    case MANAGEASK.supportTickets.GET_QUESTION_COUNT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getQuestionCount: true },
        error: { ...state.error, getQuestionCount: null }
      };
    case MANAGEASK.supportTickets.GET_QUESTION_COUNT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getQuestionCount: false },
        error: { ...state.error, getQuestionCount: null },
        getQuestionCount: action.payload
      };
    case MANAGEASK.supportTickets.GET_QUESTION_COUNT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getQuestionCount: false },
        error: { ...state.error, getQuestionCount: action.payload }
      };
    //get question by status reducer
    case MANAGEASK.supportTickets.GET_QUESTION_BY_STATUS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getQuestionByStatus: true },
        error: { ...state.error, getQuestionByStatus: null }
      };
    case MANAGEASK.supportTickets.GET_QUESTION_BY_STATUS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getQuestionByStatus: false },
        error: { ...state.error, getQuestionByStatus: null },
        getQuestionByStatus: action.payload
      };
    case MANAGEASK.supportTickets.GET_QUESTION_BY_STATUS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getQuestionByStatus: false },
        error: { ...state.error, getQuestionByStatus: action.payload }
      };
    //post answer reply reducer
    case MANAGEASK.supportTickets.POST_ANSWER_REPLY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postAnswerReply: true },
        error: { ...state.error, postAnswerReply: null }
      };
    case MANAGEASK.supportTickets.POST_ANSWER_REPLY.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postAnswerReply: false },
        error: { ...state.error, postAnswerReply: null },
        getQuestionCount: {
          ...state.getQuestionCount,
          answeredCount: state.getQuestionCount.answeredCount + 1,
          pendingCount: state.getQuestionCount.pendingCount - 1
        },
        postAnswerReply: action.payload
      };
    case MANAGEASK.supportTickets.POST_ANSWER_REPLY.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postAnswerReply: false },
        error: { ...state.error, postAnswerReply: action.payload }
      };
    //update answer reply reducer
    case MANAGEASK.supportTickets.UPDATE_ANSWER_REPLY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postAnswerReply: true },
        error: { ...state.error, updateAnswerReply: null }
      };
    case MANAGEASK.supportTickets.UPDATE_ANSWER_REPLY.SUCCESS:
      let oldData: QuestionModel[] = state?.getQuestionByStatus?.content;
      let updateData: AskTellSupportTicketModel = action?.payload;
      if (oldData && updateData && updateData.questionId) {
        const findIndexVal = (obj: QuestionModel) => obj.id === updateData.questionId;
        let updateQuestionIndex = oldData.findIndex(findIndexVal);
        if (updateQuestionIndex > -1) {
          let tempObj: any = {};
          tempObj = oldData[updateQuestionIndex];
          tempObj.answer = updateData.answer;
          tempObj.type = updateData.answerType;
          oldData.splice(updateQuestionIndex, 1, tempObj);
        }
      }
      return {
        ...state,
        loading: { ...state.loading, updateAnswerReply: false },
        error: { ...state.error, updateAnswerReply: null },
        updateAnswerReply: action.payload,
        getQuestionByStatus: {
          ...state.getQuestionByStatus,
          content: oldData
        }
      };
    case MANAGEASK.supportTickets.UPDATE_ANSWER_REPLY.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateAnswerReply: false },
        error: { ...state.error, updateAnswerReply: action.payload }
      };
    case MANAGEASK.supportTickets.RESET_SUPPORT_TKT_ERROR:
      return {
        ...state,
        error: { ...state.error, updateAnswerReply: null, postAnswerReply: null }
      };
    default:
      return state;
  }
};
