import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  ILogActionTableData,
  ILogsDashboardTableData
} from "@timhortons/common/src/modules/logs/dashboards/IndividualAccessScreens/actions";
import {
  IndividualAccessActionsModel,
  IndividualAccessLogsModel
} from "@timhortons/common/src/models/logs/individualActionTable";
import { ManagerReviewTableModel } from "@timhortons/common/src/models/logs/mainLogs";
const getIndividualLogActionsTableData = (
  data: ILogActionTableData
): IFluxStandardAction<ILogActionTableData> => {
  return {
    type: LOGS.IndividualLogActions.GET_TABLE_DATA.LOADING,
    payload: data
  };
};
const getIndividualLogActionsTableDataSuccess = (
  data: IndividualAccessActionsModel
): IFluxStandardAction<IndividualAccessActionsModel> => {
  return {
    type: LOGS.IndividualLogActions.GET_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const getIndividualLogActionsTableDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.IndividualLogActions.GET_TABLE_DATA.ERROR,
    payload: error
  };
};

const getLogsStoreSpecificTableData = (data: {
  storeCode: number;
  date: string;
  timeZone: string;
  search: string;
}): IFluxStandardAction<{ storeCode: number; date: string; timeZone: string; search: string }> => {
  return {
    type: LOGS.IndividualLogActions.GET_LOGS_STORE_SPECIFIC_TABLE_DATA.LOADING,
    payload: data
  };
};
const getLogsStoreSpecificTableDataSuccess = (
  data: Array<ManagerReviewTableModel>
): IFluxStandardAction<Array<ManagerReviewTableModel>> => {
  return {
    type: LOGS.IndividualLogActions.GET_LOGS_STORE_SPECIFIC_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const getLogsStoreSpecificTableDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.IndividualLogActions.GET_LOGS_STORE_SPECIFIC_TABLE_DATA.ERROR,
    payload: error
  };
};
const getIndividualLogsDashboardTableData = (
  data: ILogsDashboardTableData
): IFluxStandardAction<ILogsDashboardTableData> => {
  return {
    type: LOGS.IndividualLogActions.GET_LOGS_TABLE_DATA.LOADING,
    payload: data
  };
};
const getIndividualLogsDashboardTableDataSuccess = (
  data: IndividualAccessLogsModel
): IFluxStandardAction<IndividualAccessLogsModel> => {
  return {
    type: LOGS.IndividualLogActions.GET_LOGS_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const getIndividualLogsDashboardTableDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.IndividualLogActions.GET_LOGS_TABLE_DATA.ERROR,
    payload: error
  };
};
const getActionsStoreSpecificTableData = (data: {
  storeCode: number;
  date: string;
  timeZone: string;
  search: string;
}): IFluxStandardAction<{ storeCode: number; date: string; timeZone: string; search: string }> => {
  return {
    type: LOGS.IndividualLogActions.GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA.LOADING,
    payload: data
  };
};
const getActionsStoreSpecificTableDataSuccess = (
  data: ManagerReviewTableModel
): IFluxStandardAction<ManagerReviewTableModel> => {
  return {
    type: LOGS.IndividualLogActions.GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const getActionsStoreSpecificTableDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.IndividualLogActions.GET_ACTIONS_STORE_SPECIFIC_TABLE_DATA.ERROR,
    payload: error
  };
};

export const IndividualLogActionTableActions = {
  getIndividualLogActionsTableData,
  getIndividualLogActionsTableDataSuccess,
  getIndividualLogActionsTableDataError,
  getLogsStoreSpecificTableData,
  getLogsStoreSpecificTableDataSuccess,
  getLogsStoreSpecificTableDataError,
  getIndividualLogsDashboardTableData,
  getIndividualLogsDashboardTableDataSuccess,
  getIndividualLogsDashboardTableDataError,
  getActionsStoreSpecificTableData,
  getActionsStoreSpecificTableDataSuccess,
  getActionsStoreSpecificTableDataError
};
