import { call, put, takeLatest } from "redux-saga/effects";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { TimeTemperatureActions } from "@timhortons/common/src/redux/logs/actions/mainLogs/timeTemperature";
import { MainLogsRepository } from "@timhortons/common/src/repositories/logs/mainLogs";
import {
  ItemTempDto,
  LogsAuthModel,
  LogShiftModel,
  ProductTemperatureCategoriesModel,
  ReceivingLogCategoriesSubCategoriesModel
} from "@timhortons/common/src/models/logs/mainLogs";
import { IReceivingLogsItemsApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/receivingLog/receivingLog";
import { IProdTempApi } from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/contents";
import {
  IRecordTemperature,
  TabObject
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/organisms/itemTab";
import {
  Action_CategoryNSubCategory_Ids,
  ILogShift,
  IMwtItemApiPayload,
  IProdTempCategories,
  ITimeTempCategories,
  LogsAuthPayload
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import { getTimeZone } from "@timhortons/common/src/utils/dateFormat";

function* getReceivingLogsCategories(value: IFluxStandardAction<{ categoryId: string }>) {
  const { payload } = value;
  try {
    let data: ReceivingLogCategoriesSubCategoriesModel[] = yield call(
      MainLogsRepository.getReceivingLogsCategories,
      payload
    );
    yield put(TimeTemperatureActions.getReceivingLogsCategoriesSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getReceivingLogsCategories");

    yield put(TimeTemperatureActions.getReceivingLogsCategoriesError(error));
  }
}
function* getReceivingLogsSubCategories(value: IFluxStandardAction<IProdTempCategories>) {
  const { payload } = value;
  try {
    let data: ReceivingLogCategoriesSubCategoriesModel[] = yield call(
      MainLogsRepository.getReceivingLogsSubCategories,
      payload
    );
    yield put(TimeTemperatureActions.getReceivingLogsSubCategoriesSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getReceivingLogsSubCategories");

    yield put(TimeTemperatureActions.getReceivingLogsSubCategoriesError(error));
  }
}
function* getReceivingLogsItems(value: IFluxStandardAction<IReceivingLogsItemsApi>) {
  const { payload } = value;
  try {
    let data: TabObject[] = yield call(MainLogsRepository.getReceivingLogItems, payload);
    yield put(TimeTemperatureActions.getReceivingLogsItemsSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getReceivingLogsItems");

    yield put(TimeTemperatureActions.getReceivingLogsItemsError(error));
  }
}
function* getProductTempCategory(value: IFluxStandardAction<IProdTempCategories>) {
  const { payload } = value;
  try {
    let data: ProductTemperatureCategoriesModel[] = yield call(
      MainLogsRepository.getProductsCategory,
      payload
    );
    yield put(TimeTemperatureActions.getProdTempCategorySuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getProductTempCategory");

    yield put(TimeTemperatureActions.getProdTempCategoryError(error));
  }
}
function* getProdTempItems(value: IFluxStandardAction<IProdTempApi>) {
  const { payload } = value;
  try {
    let data: TabObject[] = yield call(MainLogsRepository.getProdTempItems, payload);
    yield put(TimeTemperatureActions.getProductTempLogsItemsSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getProdTempItems");

    yield put(TimeTemperatureActions.getProductTempLogsItemsError(error));
  }
}
function* recordProductTemperature(
  value: IFluxStandardAction<{
    finalData: IRecordTemperature;
    productId: string;
  }>
) {
  const { payload } = value;
  try {
    const response: ItemTempDto = yield call(
      MainLogsRepository.recordProductTemperature,
      payload.finalData
    );
    yield put(
      TimeTemperatureActions.recordProductTemperatureSuccess({
        ...response,
        itemId: payload.finalData.itemId,
        productId: payload.productId
      })
    );
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "recordProductTemperature");

    yield put(TimeTemperatureActions.recordProductTemperatureError(error));
  }
}
function* recordReceivingLogTemperature(
  value: IFluxStandardAction<{
    finalData: IRecordTemperature;
    productId: string;
  }>
) {
  const { payload } = value;
  try {
    const response: ItemTempDto = yield call(
      MainLogsRepository.recordReceivingLogTemperature,
      payload?.finalData
    );

    yield put(
      TimeTemperatureActions.recordReceivingLogTemperatureSuccess({
        ...response,
        itemId: payload?.finalData?.itemId,
        productId: payload?.productId
      })
    );
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "recordReceivingLogTemperature");

    yield put(TimeTemperatureActions.recordReceivingLogTemperatureError(error));
  }
}
function* getLogsShift(value: IFluxStandardAction<ILogShift>) {
  const { payload } = value;
  try {
    let data: LogShiftModel[] = yield call(MainLogsRepository.getLogShift, payload);
    yield put(TimeTemperatureActions.getLogShiftSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getLogsShift");

    yield put(TimeTemperatureActions.getLogShiftError(error));
  }
}

function* getEquipmentTempItemsWorkerSaga(value: IFluxStandardAction<IMwtItemApiPayload>) {
  const { payload } = value;
  try {
    let data: TabObject[] = yield call(MainLogsRepository.getEquipmentItems, payload);
    yield put(TimeTemperatureActions.getEquipmentTempItemsSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getEquipmentTempItemsWorkerSaga");

    yield put(TimeTemperatureActions.getEquipmentTempItemsError(error));
  }
}

function* logsAuthWorkerSaga(value: IFluxStandardAction<LogsAuthPayload>) {
  const { payload } = value;
  try {
    let data: LogsAuthModel = yield call(MainLogsRepository.checkLogsAuth, payload);
    yield put(TimeTemperatureActions.checkLogsAuthSuccess(data));
    let body = {
      timeZone: getTimeZone(),
      data: payload
    };
    if (payload.levelId === Action_CategoryNSubCategory_Ids.STATION_READ) {
      let dataa = {
        employeeId: payload.employeeId,
        label: payload.label,
        storeCode: payload.storeCode,
        categoryId: payload.categoryId,
        date: payload.date
      };
      yield call(MainLogsRepository.updateStationReadLogsAuth, dataa);
    } else {
      yield call(MainLogsRepository.updateLogsAuth, body);
    }
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "logsAuthWorkerSaga");

    yield put(TimeTemperatureActions.checkLogsAuthError(error));
  }
}
function* getTimeTempCategoriesWorkerSaga(
  value: IFluxStandardAction<{ actionName: string; storeCode: number; date: string }>
) {
  const { payload } = value;
  try {
    let data: ITimeTempCategories[] = yield call(MainLogsRepository.getTimeTempCategories, payload);
    yield put(TimeTemperatureActions.timeTempCategoriesSuccess(data));
  } catch (error) {
    Logger.error("mainLogs-timeTemp", error, "getTimeTempCategoriesWorkerSaga");

    yield put(TimeTemperatureActions.timeTempCategoriesError(error));
  }
}

function* timeTempWatcherSaga() {
  yield takeLatest(
    LOGS.MainLogs.TimeTemp.GET_TIME_TEMP_CATEGORIES.LOADING,
    getTimeTempCategoriesWorkerSaga
  );
  yield takeLatest(LOGS.Authentication.CHECK_LOGS_AUTH.LOADING, logsAuthWorkerSaga);
  yield takeLatest(
    LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_CATEGORIES.LOADING,
    getReceivingLogsCategories
  );
  yield takeLatest(
    LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_SUBCATEGORIES.LOADING,
    getReceivingLogsSubCategories
  );
  yield takeLatest(LOGS.MainLogs.TimeTemp.GET_RECEIVING_LOGS_ITEMS.LOADING, getReceivingLogsItems);
  yield takeLatest(
    LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_CATEGORY.LOADING,
    getProductTempCategory
  );
  yield takeLatest(LOGS.MainLogs.TimeTemp.GET_PRODUCT_TEMP_ITEMS.LOADING, getProdTempItems);
  yield takeLatest(
    LOGS.MainLogs.TimeTemp.RECORD_PRODUCT_TEMPERATURE.LOADING,
    recordProductTemperature
  );

  yield takeLatest(LOGS.MainLogs.TimeTemp.GET_LOGS_SHIFT.LOADING, getLogsShift);
  yield takeLatest(
    LOGS.MainLogs.TimeTemp.GET_EQUIPMENT_TEMP_ITEMS.LOADING,
    getEquipmentTempItemsWorkerSaga
  );
  yield takeLatest(
    LOGS.MainLogs.TimeTemp.RECORD_RECEIVINGLOG_TEMPERATURE.LOADING,
    recordReceivingLogTemperature
  );
}
export default timeTempWatcherSaga;
