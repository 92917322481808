import { TimbitCollectionModel } from "models/userLms/userLms";
import { UserLMS } from "../actions/actionTypes";
import {
  LeaderboardDataListModel,
  GetTimbitCollection
} from "@timhortons/common/src/models/userLms/userLms";

export interface ILmsMobileMoreLoading {
  getEvents: boolean;
  getEventDetail: boolean;
  getDayEvent: boolean;
  getLeaderBoardList: boolean;
  getTimbitCollection: boolean;
}

export interface ILmsMobileMoreError {
  getEvents: string;
  getEventDetail: string;
  getDayEvent: string;
  getLeaderBoardList: string;
  getTimbitCollection: string;
}

export interface IGuests {
  userName: string;
  role: string;
  profileUrl: string;
}
export interface IEventDetail {
  date: string;
  eventName: string;
  startTime: string;
  category: string;
  location: string;
  address: string;
  employeeResponseDtoList: IGuests[];
  note: string;
  endTime: string;
}

export interface ILmsMobileMoreState {
  loading: ILmsMobileMoreLoading;
  error: ILmsMobileMoreError;
  getEvents: any;
  getEventDetail: IEventDetail;
  getDayEvents: any;
  getLeaderBoardList: LeaderboardDataListModel;
  getTimbitCollection: TimbitCollectionModel;
}

export const moreInitialState: ILmsMobileMoreState = {
  getEvents: null,
  getEventDetail: null,
  getDayEvents: null,
  getLeaderBoardList: {
    content: [],
    totalElements: null
  },
  getTimbitCollection: null,
  loading: {
    getEvents: false,
    getEventDetail: false,
    getDayEvent: false,
    getLeaderBoardList: false,
    getTimbitCollection: false
  },
  error: {
    getEvents: null,
    getEventDetail: null,
    getDayEvent: null,
    getLeaderBoardList: null,
    getTimbitCollection: null
  }
};

export const moreMobileReducer = (
  state = moreInitialState,
  action: { payload: any; type: string }
): ILmsMobileMoreState => {
  switch (action.type) {
    case UserLMS.GET_EVENTS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getEvents: true
        }
      };

    case UserLMS.GET_EVENTS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getEvents: false
        },
        getEvents: action.payload
      };

    case UserLMS.GET_EVENTS.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          getEvents: action.payload
        }
      };

    case UserLMS.GET_EVENT_DETAIL.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getEventDetail: true
        }
      };

    case UserLMS.GET_EVENT_DETAIL.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getEventDetail: false
        },
        getEventDetail: action.payload
      };

    case UserLMS.GET_EVENT_DETAIL.ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          getEventDetail: action.payload
        }
      };

    case UserLMS.GET_DAY_EVENTS.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDayEvent: true
        },
        error: { ...state.error, getDayEvent: null }
      };

    case UserLMS.GET_DAY_EVENTS.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDayEvent: false
        },
        error: { ...state.error, getDayEvent: null },
        getDayEvents: action.payload
      };

    case UserLMS.GET_DAY_EVENTS.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getDayEvent: false
        },
        error: { ...state.error, getDayEvent: action.payload }
      };
    case UserLMS.RESET_DAY_EVENT:
      return {
        ...state,
        getDayEvents: null
      };
    case UserLMS.GET_LEADERBOARD_LIST.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLeaderBoardList: true
        },
        error: { ...state.error, getLeaderBoardList: null }
      };

    case UserLMS.GET_LEADERBOARD_LIST.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLeaderBoardList: false
        },
        error: { ...state.error, getLeaderBoardList: null },
        getLeaderBoardList: action.payload
      };

    case UserLMS.GET_LEADERBOARD_LIST.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getLeaderBoardList: false
        },
        error: { ...state.error, getLeaderBoardList: action.payload }
      };

    case UserLMS.GET_TIMBIT.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTimbitCollection: true
        },
        error: {
          ...state.error,
          getTimbitCollection: null
        }
      };
    case UserLMS.GET_TIMBIT.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTimbitCollection: false
        },
        error: {
          ...state.error,
          getTimbitCollection: null
        },
        getTimbitCollection: action.payload
      };
    case UserLMS.GET_TIMBIT.ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getTimbitCollection: false
        },
        error: {
          ...state.error,
          getTimbitCollection: action.payload
        }
      };

    default:
      return state;
  }
};
