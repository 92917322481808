import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import { Logger } from "@timhortons/common/src/services/logger";
import {
  LmsModulePtsDataModel,
  LmsPtsCheckListDataModel,
  LmsPtsCheckListResponseModel,
  LmsPtsTopicDataModel,
  LmsPtsTopicResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";
import { LmsPtsActions } from "@timhortons/common/src/redux/manageLms/actions/lmsPtsActions";
import { LmsPtsRepository } from "@timhortons/common/src/repositories/manageLms/lmsPtsRepository";

function* getLmsModulePtsWorkerSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  const { payload } = value;
  try {
    let response: LmsModulePtsDataModel[] = yield call(
      LmsPtsRepository.getLmsPtsModuleData,
      payload
    );
    yield put(LmsPtsActions.getLmsModulePtsDataSuccess(response));
  } catch (error) {
    Logger.error("manageLms-pts", error, "getLmsModulePtsWorkerSaga");

    yield put(LmsPtsActions.getLmsModulePtsDataError(error));
  }
}

function* postLmsTopicWorkerSaga(value: IFluxStandardAction<LmsPtsTopicDataModel>) {
  const { payload } = value;
  try {
    let response: LmsPtsTopicResponseModel = yield call(LmsPtsRepository.postLmsPtsTopic, payload);
    yield put(LmsPtsActions.postLmsPtsTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-pts", error, "postLmsTopicWorkerSaga");

    yield put(LmsPtsActions.postLmsPtsTopicError(error));
  }
}

function* updateLmsTopicWorkerSaga(
  value: IFluxStandardAction<{ topicId: string; obj: LmsPtsTopicDataModel }>
) {
  const { payload } = value;
  try {
    let response: LmsPtsTopicResponseModel = yield call(
      LmsPtsRepository.updateLmsPtsTopic,
      payload
    );
    yield put(LmsPtsActions.updateLmsPtsTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-pts", error, "updateLmsTopicWorkerSaga");

    yield put(LmsPtsActions.updateLmsPtsTopicError(error));
  }
}

function* deleteLmsTopicWorkerSaga(value: IFluxStandardAction<{ topicId: string }>) {
  const { payload } = value;
  try {
    let response: LmsPtsTopicResponseModel = yield call(
      LmsPtsRepository.deleteLmsPtsTopic,
      payload
    );
    yield put(LmsPtsActions.deleteLmsPtsTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-pts", error, "deleteLmsTopicWorkerSaga");

    yield put(LmsPtsActions.deleteLmsPtsTopicError(error));
  }
}

function* postLmsCheckListWorkerSaga(value: IFluxStandardAction<LmsPtsCheckListDataModel>) {
  const { payload } = value;
  try {
    let response: LmsPtsCheckListResponseModel = yield call(
      LmsPtsRepository.postLmsPtsCheckList,
      payload
    );
    yield put(LmsPtsActions.postLmsPtsCheckListSuccess(response));
  } catch (error) {
    Logger.error("manageLms-pts", error, "postLmsCheckListWorkerSaga");

    yield put(LmsPtsActions.postLmsPtsCheckListError(error));
  }
}

function* updateLmsCheckListWorkerSaga(
  value: IFluxStandardAction<{ checkListId: string; obj: LmsPtsCheckListDataModel }>
) {
  const { payload } = value;
  try {
    let response: LmsPtsCheckListResponseModel = yield call(
      LmsPtsRepository.updateLmsPtsCheckList,
      payload
    );
    yield put(LmsPtsActions.updateLmsPtsCheckListSuccess(response));
  } catch (error) {
    Logger.error("manageLms-pts", error, "updateLmsCheckListWorkerSaga");

    yield put(LmsPtsActions.updateLmsPtsCheckListError(error));
  }
}

function* deleteLmsCheckListWorkerSaga(
  value: IFluxStandardAction<{ checkListId: string; topicId: string }>
) {
  const { payload } = value;
  try {
    let response: LmsPtsCheckListResponseModel = yield call(
      LmsPtsRepository.deleteLmsPtsCheckList,
      { checkListId: payload.checkListId }
    );
    yield put(LmsPtsActions.deleteLmsPtsCheckListSuccess(response));
  } catch (error) {
    Logger.error("manageLms-pts", error, "deleteLmsCheckListWorkerSaga");

    yield put(LmsPtsActions.deleteLmsPtsCheckListError(error));
  }
}

export default function* lmsPtsWatcherSaga() {
  yield takeLatest(MANAGELMS.pts.GET_MODULE_PTS_DATA.LOADING, getLmsModulePtsWorkerSaga);
  yield takeLatest(MANAGELMS.pts.POST_PTS_TOPIC.LOADING, postLmsTopicWorkerSaga);
  yield takeLatest(MANAGELMS.pts.UPDATE_PTS_TOPIC.LOADING, updateLmsTopicWorkerSaga);
  yield takeLatest(MANAGELMS.pts.DELETE_PTS_TOPIC.LOADING, deleteLmsTopicWorkerSaga);
  yield takeLatest(MANAGELMS.pts.POST_PTS_CHECKLIST.LOADING, postLmsCheckListWorkerSaga);
  yield takeLatest(MANAGELMS.pts.UPDATE_PTS_CHECKLIST.LOADING, updateLmsCheckListWorkerSaga);
  yield takeLatest(MANAGELMS.pts.DELETE_PTS_CHECKLIST.LOADING, deleteLmsCheckListWorkerSaga);
}
