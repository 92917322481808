import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import {
  LpAcknowledgementModel,
  LpPostAcknowledgementModel,
  LpReportsDataModal,
  LpReportTableResponseModel,
  LpStoreAccessSummaryDataModel,
  LpReportsSummaryOpportunityDataModel,
  LpIndividualAccessSummaryDataModel,
  KPIDataModel,
  AllAreaManagersListDataModel,
  LpStoreRankingDataModel
} from "@timhortons/common/src/models/lp/reports";
import { SelectedMonthFilter } from "@timhortons/common/src/modules/lp/components/dashboard";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

const submitLpAudit = (data: {
  auditId: number;
}): IFluxStandardAction<{
  auditId: number;
}> => {
  return {
    type: LP.SUBMIT_LP_AUDIT.LOADING,
    payload: data
  };
};

const submitLpAuditSuccess = (data: {
  reportId: null | number;
  auditId: number;
}): IFluxStandardAction<{
  reportId: null | number;
  auditId: number;
}> => {
  return {
    type: LP.SUBMIT_LP_AUDIT.SUCCESS,
    payload: data
  };
};

const submitLpAuditError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.SUBMIT_LP_AUDIT.ERROR,
    payload: error
  };
};
const prepareLpReport = (data: {
  auditId: number;
}): IFluxStandardAction<{
  auditId: number;
}> => {
  return {
    type: LP.PREPARE_LP_REPORT.LOADING,
    payload: data
  };
};
const getLpReportsData = (data: {
  auditId: number;
  reportId: number;
}): IFluxStandardAction<{
  auditId: number;
  reportId: number;
}> => {
  return {
    type: LP.LP_REPORTS_DATA.LOADING,
    payload: data
  };
};
const getLpReportsDataSuccess = (
  data: LpReportsDataModal
): IFluxStandardAction<LpReportsDataModal> => {
  return {
    type: LP.LP_REPORTS_DATA.SUCCESS,
    payload: data
  };
};
const getLpReportsDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.LP_REPORTS_DATA.ERROR,
    payload: error
  };
};

const getLpAcknowledgementData = (data: {
  storeCode: string;
}): IFluxStandardAction<{ storeCode: string }> => {
  return {
    type: LP.LP_ACKNOWLEDGEMENT_DATA.LOADING,
    payload: data
  };
};
const getLpAcknowledgementDataSuccess = (
  data: LpAcknowledgementModel
): IFluxStandardAction<LpAcknowledgementModel> => {
  return {
    type: LP.LP_ACKNOWLEDGEMENT_DATA.SUCCESS,
    payload: data
  };
};
const getLpAcknowledgementDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.LP_ACKNOWLEDGEMENT_DATA.ERROR,
    payload: error
  };
};
const postLpAcknowledgement = (
  data: LpPostAcknowledgementModel
): IFluxStandardAction<LpPostAcknowledgementModel> => {
  return {
    type: LP.POST_LP_ACKNOWLEDGEMENT.LOADING,
    payload: data
  };
};
const postLpAcknowledgementSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.POST_LP_ACKNOWLEDGEMENT.SUCCESS,
    payload: data
  };
};
const postLpAcknowledgementError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.POST_LP_ACKNOWLEDGEMENT.ERROR,
    payload: error
  };
};
const getLpReportTableData = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.GET_LP_REPORT_TABLE_DATA.LOADING,
    payload: data
  };
};
const getLpReportTableDataSuccess = (
  data: LpReportTableResponseModel
): IFluxStandardAction<LpReportTableResponseModel> => {
  return {
    type: LP.GET_LP_REPORT_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const getLpReportTableDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_REPORT_TABLE_DATA.ERROR,
    payload: error
  };
};

const resetAuthID = () => {
  return {
    type: LP.RESET_AUTH_ID
  };
};

const resetReportID = () => {
  return {
    type: LP.RESET_REPORT_ID
  };
};

const getLpStoreAccessSummaryData = (data: {
  storeId: number;
  startDate: string;
  endDate: string;
}): IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }> => {
  return {
    type: LP.LP_GET_STORE_ACCESS_SUMMARY_DATA.LOADING,
    payload: data
  };
};

const getLpStoreAccessSummaryDataSuccess = (
  data: LpStoreAccessSummaryDataModel
): IFluxStandardAction<LpStoreAccessSummaryDataModel> => {
  return {
    type: LP.LP_GET_STORE_ACCESS_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};

const getLpStoreAccessSummaryDataError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.LP_GET_STORE_ACCESS_SUMMARY_DATA.ERROR,
    payload: data
  };
};

const getLpSelectedMonthFilter = (
  data: SelectedMonthFilter
): IFluxStandardAction<SelectedMonthFilter> => {
  return {
    type: LP.LP_GET_SELECTED_MONTH_FILTER.SUCCESS,
    payload: data
  };
};

const getLpSelectedCountryFilter = (countryCode: string): IFluxStandardAction<string> => {
  return {
    type: LP.LP_GET_SELECTED_COUNTRY_FILTER.SUCCESS,
    payload: countryCode
  };
};

const getLpIndividualSummaryData = (data: {
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}): IFluxStandardAction<{
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}> => {
  return {
    type: LP.GET_LP_INDIVIDUAL_SUMMARY_DATA.LOADING,
    payload: data
  };
};

const getLpIndividualSummaryDataSuccess = (
  data: LpIndividualAccessSummaryDataModel
): IFluxStandardAction<LpIndividualAccessSummaryDataModel> => {
  return {
    type: LP.GET_LP_INDIVIDUAL_SUMMARY_DATA.SUCCESS,
    payload: data
  };
};

const getLpIndividualSummaryDataError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_INDIVIDUAL_SUMMARY_DATA.ERROR,
    payload: data
  };
};
const getLpIndividualSummaryStoreRankingData = (data: {
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}): IFluxStandardAction<{
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}> => {
  return {
    type: LP.GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA.LOADING,
    payload: data
  };
};

const getLpIndividualSummaryStoreRankingDataSuccess = (
  data: LpStoreRankingDataModel
): IFluxStandardAction<LpStoreRankingDataModel> => {
  return {
    type: LP.GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA.SUCCESS,
    payload: data
  };
};

const getLpIndividualSummaryStoreRankingDataError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA.ERROR,
    payload: data
  };
};
const getCountryTotalAudits = (data: {
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}): IFluxStandardAction<{
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}> => {
  return {
    type: LP.GET_COUNTRY_TOTAL_AUDITS.LOADING,
    payload: data
  };
};

const getCountryTotalAuditsSuccess = (data: LpStoreRankingDataModel): IFluxStandardAction<any> => {
  return {
    type: LP.GET_COUNTRY_TOTAL_AUDITS.SUCCESS,
    payload: data
  };
};

const getCountryTotalAuditsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_COUNTRY_TOTAL_AUDITS.ERROR,
    payload: data
  };
};

const getLpAuditorTotalAudits = (data: {
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}): IFluxStandardAction<{
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}> => {
  return {
    type: LP.GET_LP_AUDITOR_TOTAL_AUDITS.LOADING,
    payload: data
  };
};

const getLpAuditorTotalAuditsSuccess = (
  data: LpStoreRankingDataModel
): IFluxStandardAction<any> => {
  return {
    type: LP.GET_LP_AUDITOR_TOTAL_AUDITS.SUCCESS,
    payload: data
  };
};

const getLpAuditorTotalAuditsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_AUDITOR_TOTAL_AUDITS.ERROR,
    payload: data
  };
};

const getAreaManagerTotalAudits = (data: {
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}): IFluxStandardAction<{
  startDate: string;
  endDate: string;
  countryCode: number;
  pageNo: number;
  pageSize: number;
}> => {
  return {
    type: LP.GET_AREAMANAGER_TOTAL_AUDITS.LOADING,
    payload: data
  };
};

const getAreaManagerTotalAuditsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.GET_AREAMANAGER_TOTAL_AUDITS.SUCCESS,
    payload: data
  };
};

const getAreaManagerTotalAuditsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_AREAMANAGER_TOTAL_AUDITS.ERROR,
    payload: data
  };
};

const getLpStoreAccessOpportunityData = (data: {
  storeId: number;
  startDate: string;
  endDate: string;
}): IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }> => {
  return {
    type: LP.GET_LP_STORE_ACCESS_OPPORTUNITY_DATA.LOADING,
    payload: data
  };
};

const getLpStoreAccessOpportunityDataSuccess = (
  data: LpReportsSummaryOpportunityDataModel[]
): IFluxStandardAction<LpReportsSummaryOpportunityDataModel[]> => {
  return {
    type: LP.GET_LP_STORE_ACCESS_OPPORTUNITY_DATA.SUCCESS,
    payload: data
  };
};

const getLpStoreAccessOpportunityDataError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_STORE_ACCESS_OPPORTUNITY_DATA.ERROR,
    payload: data
  };
};

const resetReportBuilder = () => {
  return {
    type: LP.ReportBuilder.RESET_REPORT_BUILDER
  };
};

const generateReportBuilder = (data: {
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
  selectedDateRange?: string;
  quaterLabel?: string;
}): IFluxStandardAction<{
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
  selectedDateRange?: string;
  quaterLabel?: string;
}> => {
  return {
    type: LP.ReportBuilder.GENERATE_REPORT.LOADING,
    payload: data
  };
};
const generateReportBuilderSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.ReportBuilder.GENERATE_REPORT.SUCCESS,
    payload: data
  };
};
const generateReportBuilderError = (data: {
  error: Error;
  reportType: ComparsionLevel;
}): IFluxStandardAction<{ error: Error; reportType: ComparsionLevel }> => {
  return {
    type: LP.ReportBuilder.GENERATE_REPORT.ERROR,
    payload: data
  };
};
const generateGradingReportBuilder = (data: {
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
}): IFluxStandardAction<{
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
}> => {
  return {
    type: LP.ReportBuilder.GENERATE_GRADING_REPORT.LOADING,
    payload: data
  };
};
const generateGradingReportBuilderSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.ReportBuilder.GENERATE_GRADING_REPORT.SUCCESS,
    payload: data
  };
};
const generateGradingReportBuilderError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ReportBuilder.GENERATE_GRADING_REPORT.ERROR,
    payload: error
  };
};
const generateAreaOfImprovementReportBuilder = (data: {
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
  selectedDateRange?: string;
  quaterLabel?: string;
}): IFluxStandardAction<{
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
  selectedDateRange?: string;
  quaterLabel?: string;
}> => {
  return {
    type: LP.ReportBuilder.GENERATE_AREA_OF_IMPROVEMENT_REPORT.LOADING,
    payload: data
  };
};
const generateAreaOfImprovementReportBuilderSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.ReportBuilder.GENERATE_AREA_OF_IMPROVEMENT_REPORT.SUCCESS,
    payload: data
  };
};
const generateAreaOfImprovementReportBuilderError = (data: {
  error: Error;
  reportType: ComparsionLevel;
}): IFluxStandardAction<{ error: Error; reportType: ComparsionLevel }> => {
  return {
    type: LP.ReportBuilder.GENERATE_AREA_OF_IMPROVEMENT_REPORT.ERROR,
    payload: data
  };
};
const brandStandardTableData = (data: {
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
  selectedDateRange?: string;
  quaterLabel?: string;
}): IFluxStandardAction<{
  object: any;
  reportType: string;
  user: IUserDetails;
  countryCodes?: any;
  allSelected?: boolean;
  pageNum: number;
  updateTableName: ComparsionLevel;
  selectedDateRange?: string;
  quaterLabel?: string;
}> => {
  return {
    type: LP.ReportBuilder.BRAND_STANDARD_TABLE_DATA.LOADING,
    payload: data
  };
};
const brandStandardTableDataSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.ReportBuilder.BRAND_STANDARD_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const brandStandardTableDataError = (data: {
  error: Error;
  reportType: ComparsionLevel;
}): IFluxStandardAction<{ error: Error; reportType: ComparsionLevel }> => {
  return {
    type: LP.ReportBuilder.BRAND_STANDARD_TABLE_DATA.ERROR,
    payload: data
  };
};

export const LpReportsActions = {
  submitLpAudit,
  submitLpAuditError,
  submitLpAuditSuccess,
  prepareLpReport,
  getLpReportsData,
  getLpReportsDataError,
  getLpReportsDataSuccess,
  getLpAcknowledgementData,
  getLpAcknowledgementDataSuccess,
  getLpAcknowledgementDataError,
  postLpAcknowledgement,
  postLpAcknowledgementSuccess,
  postLpAcknowledgementError,
  resetAuthID,
  resetReportID,
  getLpReportTableData,
  getLpReportTableDataSuccess,
  getLpReportTableDataError,
  getLpStoreAccessSummaryData,
  getLpStoreAccessSummaryDataSuccess,
  getLpStoreAccessSummaryDataError,
  getLpSelectedMonthFilter,
  getLpSelectedCountryFilter,
  getLpIndividualSummaryData,
  getLpIndividualSummaryDataSuccess,
  getLpIndividualSummaryDataError,
  getLpStoreAccessOpportunityData,
  getLpStoreAccessOpportunityDataSuccess,
  getLpStoreAccessOpportunityDataError,
  resetReportBuilder,
  generateReportBuilder,
  generateReportBuilderSuccess,
  generateReportBuilderError,
  generateGradingReportBuilder,
  generateGradingReportBuilderSuccess,
  generateGradingReportBuilderError,
  getLpIndividualSummaryStoreRankingData,
  getLpIndividualSummaryStoreRankingDataSuccess,
  getLpIndividualSummaryStoreRankingDataError,
  getAreaManagerTotalAudits,
  getAreaManagerTotalAuditsError,
  getAreaManagerTotalAuditsSuccess,
  getCountryTotalAudits,
  getCountryTotalAuditsSuccess,
  getCountryTotalAuditsError,
  getLpAuditorTotalAudits,
  getLpAuditorTotalAuditsSuccess,
  getLpAuditorTotalAuditsError,
  brandStandardTableData,
  brandStandardTableDataSuccess,
  brandStandardTableDataError,
  generateAreaOfImprovementReportBuilder,
  generateAreaOfImprovementReportBuilderSuccess,
  generateAreaOfImprovementReportBuilderError
};
