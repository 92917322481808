import { Platform } from "react-native";
import { isTablet } from "react-native-device-info";

export type OSTypes = "ios" | "android" | "windows" | "macos" | "web";
const isAndroid = (): boolean => {
  return Platform.OS === "android";
};
const isIOS = (): boolean => {
  return Platform.OS === "ios";
};
const isWeb = (): boolean => {
  return Platform.OS === "web";
};
const isMobile = (): boolean => {
  return isAndroid() || isIOS();
};

const isTab = (): boolean => {
  return isTablet();
};

const getPlatform = (): OSTypes => Platform.OS;
const PlatformUtils = {
  isAndroid,
  isIOS,
  isWeb,
  isMobile,
  getPlatform,
  isTab
};
export default PlatformUtils;
