import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Card from "@timhortons/common/src/components/atoms/card";
import { TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { LpAppealTable } from "@timhortons/common/src/modules/lp/components/dashboard/appeals";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LpIndividualAppealItemModel } from "@timhortons/common/src/models/lp/appeals";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import {
  textBlock,
  CardHeader,
  ClosedTableCommon
} from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/cardHeader";
import {
  AppealCardHeader,
  commonappealStoreStyles,
  mainStyles
} from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/appealCard";
import ImgMapper from "@timhortons/common/src/components/organisms/imgMapper";

interface IProps {
  appealCardData: LpIndividualAppealItemModel;
  translate: any;
  currentTable: LpAppealTable;
  userDetails?: IUserDetails;
}

export default function StoreAccessLpAppealCard(props: IProps): ReactElement {
  const { appealCardData, translate, currentTable } = props;

  return (
    <View style={mainStyles(currentTable, appealCardData)}>
      <Card containerStyle={commonappealStoreStyles.cardContainerStyle} disabled={true}>
        <CardHeader translate={translate} />
        <Card.Body bodyStyle={commonappealStoreStyles.cardBodyContainerStyle}>
          <AppealCardHeader appealCardData={appealCardData} />
          <View style={styles.cardDataView}>
            {textBlock(
              TextSize.ExtraSmall,
              FontWeight.Regular,
              "appeal",
              [styles.cardBodyTextStyle, commonappealStoreStyles.storeAccessCardBody],
              appealCardData.appealerResponse.comment
            )}
          </View>
          <View style={styles.imgCardContainer}>
            {appealCardData?.appealerResponse?.assets?.length > 0 && (
              <ImgMapper
                items={appealCardData.appealerResponse.assets.map((i) => i.signedUrl)}
                customStyle={{
                  ...I18nService.select({
                    rtl: { paddingEnd: 0 },
                    ltr: { paddingStart: 0 }
                  })
                }}
              />
            )}
          </View>
        </Card.Body>
      </Card>
      {currentTable === LpAppealTable.closedAppeal ? (
        <View style={commonappealStoreStyles.cardFooterContainerStyle}>
          <ClosedTableCommon
            translate={translate}
            status={appealCardData.appealItemStatus}
            comment={appealCardData.auditorResponse.comment}
            textStyle={
              ((appealCardData.appealItemStatus === "APPROVED" ||
                appealCardData.appealItemStatus === "APPROVED_REVERIFICATION") && [
                commonappealStoreStyles.decisionTextStyle,
                commonappealStoreStyles.approveDecisionTextStyle
              ]) || [
                commonappealStoreStyles.decisionTextStyle,
                commonappealStoreStyles.rejectDecisionTextStyle
              ]
            }
          />
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  cardBodyTextStyle: {
    color: colorPallete.grey1
  },
  cardDataView: { flexDirection: "row" },
  imgCardContainer: {
    flex: 1,
    alignItems: "flex-end",
    flexDirection: "row",
    marginVertical: 20
  }
});
