import React, { useEffect, useState } from "react";
import { Cell, Row } from "@timhortons/common/src/components/molecules/table";
import { styles } from "./styles";
import { HeaderLabels } from "./index";
import { View, StyleSheet } from "react-native";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import CellModal from "../cellModal";
import {
  ProductItems,
  ProductValues,
  ProductValuesModel,
  StationProducts
} from "@timhortons/common/src/models/productionSheet/psModels";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

interface IProps {
  tableData: StationProducts;
  translate: any;
  accordionExpand: boolean;
  activeCell: number;
  headerCellHeight: number;
  postCallBack: Function;
  loading: boolean;
  clickedCategory: string;
  item: StationProducts;
  isMREditable: any;
}
interface SelectedProduct {
  errmsg: string;
  isValid: boolean;
  label: string;
  value: string;
}

export default function ProductionSheetRight(props: IProps) {
  const {
    tableData,
    translate,
    accordionExpand,
    activeCell,
    headerCellHeight,
    postCallBack,
    loading,
    clickedCategory,
    item,
    isMREditable
  } = props;
  const [tableDataList, setTableDataList] = useState<StationProducts>(tableData);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedCellLabel, setSelectedCellLabel] = useState<string>();
  const [selectedHeaderObj, setSelectedHeaderObj] = useState<ProductValues>(null);

  useEffect(() => {
    setTableDataList(tableData);
  }, [tableData]);

  const [modalVisible, setModalVisible] = useState(false);

  const setModalState = (data?: SelectedProduct[]): void => {
    data &&
      postCallBack(
        tableDataList?.id,
        selectedProduct?.productId,
        data[0].value,
        data[1].value,
        data[2].value,
        selectedCellLabel === HeaderLabels.count
          ? data[6].value
          : selectedCellLabel === HeaderLabels.endOfDayThrows
          ? data[3].value
          : null,
        selectedCellLabel
      );
    setModalVisible(!modalVisible);
  };

  const selectedCell = (cellLabel: string, product: ProductItems, index: number) => {
    let active: boolean = true;
    let timeLab = product.productionSheetItems.find(
      (item: ProductValues) => item.label === cellLabel
    );
    if (isMREditable) {
      // for manager review editable
      setSelectedProduct(product);
      setSelectedCellLabel(cellLabel);
      if (
        cellLabel === HeaderLabels.dailyWaste ||
        cellLabel === HeaderLabels.total ||
        cellLabel === HeaderLabels.totalWaste
      ) {
        active = false;
      }
      if (timeLab !== null) {
        setSelectedHeaderObj(timeLab);
      }
      active && setModalState();
    } else {
      // for normal production sheet
      let timeLab = product.productionSheetItems.find(
        (item: ProductValues) => item.label === cellLabel
      );
      setSelectedProduct(product);
      setSelectedCellLabel(cellLabel);
      if (
        cellLabel === HeaderLabels.dailyWaste ||
        cellLabel === HeaderLabels.total ||
        cellLabel === HeaderLabels.totalWaste
      ) {
        active = false;
      } else if (activeCell === index) {
        if (timeLab !== null) {
          setSelectedHeaderObj(timeLab);
        }
        active = true;
      } else if (cellLabel === HeaderLabels.endOfDayThrows || cellLabel === HeaderLabels.count) {
        if (timeLab !== null) {
          setSelectedHeaderObj(timeLab);
        }
        active = true;
      } else {
        active = false;
      }
      active && setModalState();
    }
  };
  const handlePress = (itemId: string, rowItem: ProductItems, index: number) => {
    if (isMREditable === null || isMREditable === undefined) {
      selectedCell(itemId, rowItem, index);
    } else {
      if (PlatformUtils.isWeb()) {
        isMREditable === "true" ? selectedCell(itemId, rowItem, index) : null;
      } else {
        isMREditable === true ? selectedCell(itemId, rowItem, index) : null;
      }
    }
  };
  return (
    <>
      <Row>
        <Cell
          title={
            tableDataList?.shelfLife !== undefined &&
            tableDataList?.shelfLife !== null &&
            "( " + tableDataList?.shelfLife + " " + translate("Shelf-Life") + " )"
          }
          cellStyle={[{ height: headerCellHeight }, styles.shelfStyle]}
        />
      </Row>
      {loading
        ? clickedCategory === item.id && <Loader type="component" />
        : tableDataList && tableDataList.productList.length > 0
        ? accordionExpand &&
          tableDataList &&
          tableDataList.productList.map((rowItem: ProductItems, i) => {
            return (
              <Row key={i}>
                {rowItem.productValues?.map((item: ProductValuesModel, index) => {
                  return (
                    <Cell
                      title={item.value}
                      cellStyle={[
                        styles.headerRowStyle,
                        style(item.color).back,
                        isMREditable ? null : activeCell === index && styles.activeChildCell
                      ]}
                      key={index}
                      onPress={() => handlePress(item?.id, rowItem, index)}
                    />
                  );
                })}
              </Row>
            );
          })
        : accordionExpand && (
            <Text
              textSize={TextSize.Regular}
              fontWeight={FontWeight.Regular}
              testId="header"
              textStyle={{ padding: 24, color: colorPallete.grey2, alignSelf: "center" }}
            >
              {translate("noDataAvailable")}
            </Text>
          )}

      {modalVisible && (
        <GetModal setModalState={setModalState}>
          <CellModal
            selectedHeaderObj={selectedHeaderObj}
            selectedProductUnit={selectedProduct?.unit}
            translate={translate}
            setModalState={setModalState}
            productName={selectedProduct?.name}
            selectedLabel={selectedCellLabel}
          />
        </GetModal>
      )}
      {!accordionExpand && <View style={styles.divider} />}
    </>
  );
}
const style = (color: any) =>
  StyleSheet.create({
    back: {
      backgroundColor: color
    }
  });
