import {
  LmsModulePtsDataModel,
  LmsPtsCheckListDataModel,
  LmsPtsCheckListResponseModel,
  LmsPtsTopicResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";

const returnEmptyArr = () => {
  let emptyCheckListObj: LmsPtsCheckListResponseModel = { id: null, name: "", points: 0 };
  let emptyPtsDataObj: LmsModulePtsDataModel = {
    id: null,
    title: "",
    isEditable: true,
    ptsPracticalChecklist: [{ ...emptyCheckListObj }]
  };
  return [emptyPtsDataObj];
};

export const ptsModuleDataFormatter = async (
  data: LmsModulePtsDataModel[]
): Promise<LmsModulePtsDataModel[]> => {
  let emptyCheckListObj: LmsPtsCheckListResponseModel = { id: null, name: "", points: 0 };
  if (data && data?.length > 0) {
    let tempArr: LmsModulePtsDataModel[] = [...data];
    for (let i = 0; i < data.length; i++) {
      let checkListData: LmsPtsCheckListResponseModel[] = data[i].ptsPracticalChecklist;
      let newCheckListArr: LmsPtsCheckListResponseModel[] = [];
      if (checkListData.length > 0) {
        newCheckListArr = [...checkListData];
      } else {
        newCheckListArr = [{ ...emptyCheckListObj }];
      }
      let newObj: LmsModulePtsDataModel = {
        ...tempArr[i],
        isEditable: false,
        // ptsPracticalChecklist: [...data[i].ptsPracticalChecklist, emptyCheckListObj]
        ptsPracticalChecklist: [...newCheckListArr]
      };
      tempArr.splice(i, 1, newObj);
    }
    return tempArr;
  } else if (data.length === 0) {
    return returnEmptyArr();
  }
};

export const ptsPostTopicSuccessHandler = (
  currModulePtsData: LmsModulePtsDataModel[],
  response: LmsPtsTopicResponseModel
): LmsModulePtsDataModel[] => {
  let tempArr: LmsModulePtsDataModel[] = [...currModulePtsData];
  if (response && response?.id) {
    let newObj: LmsModulePtsDataModel = {
      id: response.id,
      title: response.title,
      isEditable: false,
      ptsPracticalChecklist: [{ id: null, name: "", points: "" }]
    };
    if (tempArr.length === 1 && currModulePtsData[0].id === null) {
      tempArr.splice(0, 1, newObj);
    } else {
      tempArr.splice(currModulePtsData.length, 1, newObj);
    }
  }
  return tempArr;
};

export const ptsUpdateTopicSuccessHandler = (
  currModulePtsData: LmsModulePtsDataModel[],
  response: LmsPtsTopicResponseModel
): LmsModulePtsDataModel[] => {
  let tempArr: LmsModulePtsDataModel[] = [...currModulePtsData];
  let updatedIndex =
    currModulePtsData &&
    currModulePtsData?.findIndex((el: LmsModulePtsDataModel) => el.id === response.id);
  let newObj: LmsModulePtsDataModel = {
    ...tempArr[updatedIndex],
    title: response.title,
    isEditable: false
  };
  tempArr.splice(updatedIndex, 1, newObj);

  return tempArr;
};

export const ptsDeleteTopicSuccessHandler = (
  currModulePtsData: LmsModulePtsDataModel[],
  response: LmsPtsTopicResponseModel
): LmsModulePtsDataModel[] => {
  let tempArr: LmsModulePtsDataModel[] = [...currModulePtsData];
  let deletedIndex =
    currModulePtsData &&
    currModulePtsData?.findIndex((el: LmsModulePtsDataModel) => el.id === response.id);
  tempArr.splice(deletedIndex, 1);
  if (tempArr.length === 0) {
    return returnEmptyArr();
  } else {
    return tempArr;
  }
};

export const ptsPostCheckListSuccessHandler = (
  currModulePtsData: LmsModulePtsDataModel[],
  topicId: string,
  response: LmsPtsCheckListResponseModel
): LmsModulePtsDataModel[] => {
  let tempArr: LmsModulePtsDataModel[] = [...currModulePtsData];
  let targetTopicIndex = currModulePtsData.findIndex(
    (el: LmsModulePtsDataModel) => el.id === topicId
  );
  let newObj = {
    id: response.id,
    name: response.name,
    points: response?.points
  };
  let targetCheckListIndex =
    tempArr[targetTopicIndex].ptsPracticalChecklist.length > 0
      ? tempArr[targetTopicIndex].ptsPracticalChecklist.length - 1
      : 0;
  tempArr[targetTopicIndex].ptsPracticalChecklist.splice(targetCheckListIndex, 1, newObj);
  // tempArr[targetTopicIndex].ptsPracticalChecklist.push({ id: null, name: "" });
  return tempArr;
};

export const ptsUpdateCheckListSuccessHandler = (
  currModulePtsData: LmsModulePtsDataModel[],
  response: LmsPtsCheckListResponseModel
): LmsModulePtsDataModel[] => {
  return currModulePtsData;
};

export const ptsDeleteCheckListSuccessHandler = (
  currModulePtsData: LmsModulePtsDataModel[],
  targetTopicId: string,
  response: LmsPtsCheckListResponseModel
): LmsModulePtsDataModel[] => {
  let tempArr: LmsModulePtsDataModel[] = [...currModulePtsData];
  let targetTopicIndex: number = currModulePtsData.findIndex(
    (el: LmsModulePtsDataModel) => el.id === targetTopicId
  );
  let targetCheckListIndex: number = currModulePtsData[
    targetTopicIndex
  ].ptsPracticalChecklist.findIndex((el: LmsPtsCheckListResponseModel) => el.id === response.id);

  tempArr[targetTopicIndex].ptsPracticalChecklist.splice(targetCheckListIndex, 1);
  if (tempArr[targetTopicIndex].ptsPracticalChecklist.length === 0) {
    tempArr[targetTopicIndex].ptsPracticalChecklist.push({ id: null, name: "", points: "" });
  }
  return tempArr;
};
