import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import React, { Fragment, useEffect, useState } from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";

interface IProps {
  totalRecords: number;
  getTotalPages?: Function;
  pageNumber: number;
  pageLimit?: number;
  pageNeighbour?: number;
  onPageChanged: Function;
  fontWeight?: FontWeight;
  textSize?: TextSize;
  customStyles?: StyleProp<ViewStyle>;
  pageBtnStyles?: StyleProp<ViewStyle>;
  blockLength?: number;
  isDoubleSkip?: boolean;
}

enum PageType {
  LEFT_PAGE = "Left",
  RIGHT_PAGE = "Right",
  SPILL_PAGE = "Spill"
}

const range = (from: number, to: number, step = 1): (number | string)[] => {
  let i = from;
  const pageRange = [];
  while (i <= to) {
    pageRange.push(i);
    i += step;
  }
  return pageRange;
};

export const Pagination = (props: IProps): React.ReactElement => {
  const {
    totalRecords = null,
    pageLimit = 10,
    getTotalPages,
    pageNumber,
    pageNeighbour = 1,
    onPageChanged,
    fontWeight = FontWeight.Regular,
    textSize = TextSize.ExtraRegular,
    customStyles,
    pageBtnStyles,
    blockLength = 2,
    isDoubleSkip = true
  } = props;

  let pageNeighbours = Math.max(0, Math.min(pageNeighbour, 2));
  let totalPages = Math.ceil(totalRecords / pageLimit);

  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [pages, setPages] = useState<(number | string)[]>(null);

  useEffect(() => {
    gotoPage(pageNumber);
  }, [pageNumber]);
  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    let page = fetchPageNumbers();
    setPages(page);
  }, [currentPage, totalRecords]);

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours + blockLength;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);
      let overallPages = range(startPage, endPage);
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (overallPages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          overallPages = [
            PageType.LEFT_PAGE,
            1,
            PageType.SPILL_PAGE,
            ...extraPages,
            ...overallPages,
            PageType.RIGHT_PAGE
          ];
          break;
        }
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          overallPages = [
            PageType.LEFT_PAGE,
            ...overallPages,
            ...extraPages,
            PageType.SPILL_PAGE,
            totalPages,
            PageType.RIGHT_PAGE
          ];
          break;
        }
        case hasLeftSpill && hasRightSpill:
        default: {
          overallPages = [
            PageType.LEFT_PAGE,
            PageType.SPILL_PAGE,
            ...overallPages,
            PageType.SPILL_PAGE,
            PageType.RIGHT_PAGE
          ];
          break;
        }
      }

      return [...overallPages];
    }
    return range(1, totalPages);
  };
  useEffect(() => {
    getTotalPages && getTotalPages(totalPages);
  });
  const gotoPage = (page: any): void => {
    const currentPageNumber = Math.max(0, Math.min(page, totalPages));
    const paginationData = {
      currentPage: currentPageNumber,
      totalPages: totalPages,
      pageLimit: pageLimit,
      totalRecords: totalRecords
    };

    setCurrentPage(currentPageNumber);
    if (onPageChanged) {
      onPageChanged(paginationData);
    }
  };

  const handleClick = (page: any): void => {
    gotoPage(page);
  };

  const handleMoveLeft = (): void => {
    let page = currentPage - 1;
    if (page < 1) {
      page = 1;
    }
    gotoPage(page);
  };

  const handleMoveRight = (): void => {
    let page = currentPage + 1;
    if (page > totalPages) {
      page = totalPages;
    }
    gotoPage(page);
  };

  const handleMoveSetLeft = (): void => {
    let page = currentPage - pageNeighbours * 2 - 1;
    if (page < 1) {
      page = 1;
    }
    gotoPage(page);
  };

  const handleMoveSetRight = (): void => {
    let page = currentPage + pageNeighbours * 2 + 1;
    if (page > totalPages) {
      page = totalPages;
    }
    gotoPage(page);
  };

  if (!totalRecords || totalPages === 1) return null;

  return (
    <View style={[styles.container, customStyles]}>
      {pages &&
        pages.map((page, index) => {
          {
            if (page === PageType.LEFT_PAGE)
              return (
                <Fragment key={`${index}-page-left`}>
                  {isDoubleSkip && (
                    <Button
                      key={`${index}-double-left`}
                      iconName={IconNames.menuClose} // chnage it to chevron left
                      iconSize={16}
                      type={ButtonType.Primary}
                      buttonStyles={[styles.buttonStyle, styles.inActiveBtnStyle, pageBtnStyles]}
                      iconStyles={
                        currentPage === 1 ? styles.disabledTextStyle : styles.inActiveTextStyle
                      }
                      disabled={currentPage === 1}
                      onPress={handleMoveSetLeft}
                    />
                  )}
                  <Button
                    key={`${index}-single-left`}
                    iconName={IconNames.left} // chnage it to chevron left
                    iconSize={16}
                    type={ButtonType.Primary}
                    buttonStyles={[styles.buttonStyle, styles.inActiveBtnStyle, pageBtnStyles]}
                    iconStyles={
                      currentPage === 1 ? styles.disabledTextStyle : styles.inActiveTextStyle
                    }
                    disabled={currentPage === 1}
                    onPress={handleMoveLeft}
                  />
                </Fragment>
              );
          }

          {
            if (page === PageType.RIGHT_PAGE)
              return (
                <Fragment key={`${index}-page-right`}>
                  <Button
                    key={`${index}-single-right`}
                    iconName={IconNames.right} // change to chevron right
                    iconSize={16}
                    type={ButtonType.Primary}
                    buttonStyles={[styles.buttonStyle, styles.inActiveBtnStyle, pageBtnStyles]}
                    iconStyles={
                      currentPage === totalPages
                        ? styles.disabledTextStyle
                        : styles.inActiveTextStyle
                    }
                    disabled={currentPage === totalPages}
                    onPress={handleMoveRight}
                  />
                  {isDoubleSkip && (
                    <Button
                      key={`${index}-double-right`}
                      iconName={IconNames.menuOpen} // change to chevron right
                      iconSize={16}
                      type={ButtonType.Primary}
                      buttonStyles={[styles.buttonStyle, styles.inActiveBtnStyle, pageBtnStyles]}
                      iconStyles={
                        currentPage === totalPages
                          ? styles.disabledTextStyle
                          : styles.inActiveTextStyle
                      }
                      disabled={currentPage === totalPages}
                      onPress={handleMoveSetRight}
                    />
                  )}
                </Fragment>
              );
          }

          {
            if (page === PageType.SPILL_PAGE)
              return (
                <View style={styles.spillTextContainer} key={`${index}-page-spill`}>
                  <Text
                    fontWeight={fontWeight}
                    textSize={textSize}
                    testId="txt-pagination"
                    textStyle={styles.spillText}
                  >
                    ....
                  </Text>
                </View>
              );
          }

          return (
            <Button
              key={`${index}-page`}
              title={page}
              type={ButtonType.Primary}
              buttonStyles={
                currentPage === page
                  ? [styles.buttonStyle, pageBtnStyles]
                  : [styles.buttonStyle, styles.inActiveBtnStyle, pageBtnStyles]
              }
              textStyles={currentPage !== page && styles.inActiveTextStyle}
              onPress={() => handleClick(page)}
            />
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  buttonStyle: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginHorizontal: 6
  },
  inActiveBtnStyle: {
    backgroundColor: colorPallete.white1
  },
  inActiveTextStyle: {
    color: colorPallete.grey2
  },
  disabledTextStyle: {
    color: colorPallete.peach1
  },
  spillTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 6,
    borderRadius: 8,
    width: 24,
    height: 24
  },
  spillText: {
    color: colorPallete.grey2
  }
});
