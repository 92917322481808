import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  IReportsBuilderError,
  IReportsBuilderLoading,
  IReportsBuilderState
} from "@timhortons/common/src/redux/reportBuilder/reducers/reportsReducer";

import {
  AllAreaManagersListDataModel,
  KPIDataModel
} from "@timhortons/common/src/models/lp/reports";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";

const reportsBuilderSelect = (state: IState): IReportsBuilderState =>
  state.reportBuilder.reportsBuilder;

const reportsBuilderLoading: Selector<IState, IReportsBuilderLoading> = createSelector(
  reportsBuilderSelect,
  (data) => data.loading
);

const reportsBuilderError: Selector<IState, IReportsBuilderError> = createSelector(
  reportsBuilderSelect,
  (data) => data.error
);
const getAllCountries: Selector<IState, CountryDataListModel> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllCountries
);

const getAllAreaManagers: Selector<IState, AllAreaManagersListDataModel> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllAreaManager
);
const getAllAreaTrainer: Selector<IState, any> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllAreaTrainer
);
const getAllOpsManagers: Selector<IState, AllAreaManagersListDataModel> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllOpsManager
);
const getAllCountryManagers: Selector<IState, AllAreaManagersListDataModel> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllCountryManager
);
const getAllClusterHeadOfOps: Selector<IState, AllAreaManagersListDataModel> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllClusterHeadOfOps
);
const getAllChannels: Selector<IState, ChannelsListDataModel> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllChannels
);
const getAllStores: Selector<IState, AllStoresListDataModel> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllStores
);
const getComparisonCountryManagerAdmin: Selector<IState, any> = createSelector(
  reportsBuilderSelect,
  (data) => data.comparisonCountryManagerAdmin
);
const getComparisonAreaManagerAdmin: Selector<IState, any> = createSelector(
  reportsBuilderSelect,
  (data) => data.comparisonAreaManagerAdmin
);
const getComparisonStoresAdmin: Selector<IState, any> = createSelector(
  reportsBuilderSelect,
  (data) => data.comparisonStoresAdmin
);
const getHighLowChannelsAdmin: Selector<IState, any> = createSelector(
  reportsBuilderSelect,
  (data) => data.highLowChannelsAdmin
);

const getKPIData: Selector<IState, KPIDataModel[]> = createSelector(
  reportsBuilderSelect,
  (data) => data.kpiDataList
);
const getCategoryData: Selector<IState, KPIDataModel[]> = createSelector(
  reportsBuilderSelect,
  (data) => data.getCategory
);
const getProductData: Selector<IState, KPIDataModel[]> = createSelector(
  reportsBuilderSelect,
  (data) => data.getProduct
);
// TODO: Models
const getAllLpAuditor: Selector<IState, any> = createSelector(
  reportsBuilderSelect,
  (data) => data.getAllLpAuditor
);
export const reportsBuilderSelectors = {
  reportsBuilderSelect,
  reportsBuilderLoading,
  reportsBuilderError,
  getAllCountries,
  getAllAreaManagers,
  getAllCountryManagers,
  getAllChannels,
  getAllStores,
  getComparisonAreaManagerAdmin,
  getComparisonCountryManagerAdmin,
  getComparisonStoresAdmin,
  getHighLowChannelsAdmin,
  getAllOpsManagers,
  getKPIData,
  getCategoryData,
  getProductData,
  getAllClusterHeadOfOps,
  getAllLpAuditor,
  getAllAreaTrainer
};
