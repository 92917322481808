import { ApiClient } from "@timhortons/common/src/network/apiClient";
import {
  detailedLmsReportsEndPoints,
  lmsReportsEndPoints
} from "@timhortons/common/src/constants/endPoints";
import { LmsReportsKeys } from "@timhortons/common/src/constants/apiKeys";
import { DetailedLmsFormatter } from "./formatters/detailedLmsFormatter";

import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { lmsBuilderFormatter } from "@timhortons/common/src/repositories/lmsReports/formatters/lmsReportsFormatter";

class DetailedLmsReportsRepository {
  getAllModules = async (param: {
    trainingType: string;
    pageNumber: number;
    pageSize: number;
    isPracticalStatus: boolean;
    search: string;
  }) => {
    const { trainingType, pageNumber, pageSize, search, isPracticalStatus } = param;
    let object: any = {
      pageNo: pageNumber,
      pageSize: pageSize,
      searchByKeyword: search?.length > 0 ? search : null,
      isPracticalStatus: isPracticalStatus
    };
    if (trainingType === "CORE_TRAINING") {
      object = {
        ...object,
        type: "BASIC"
      };
    } else {
      object = {
        ...object,
        type: "SPECIAL"
      };
    }
    let tableDetails = await ApiClient.get(
      detailedLmsReportsEndPoints.getAllModules(),
      {
        ...object
      },
      LmsReportsKeys.USER_ROLES
    );
    return DetailedLmsFormatter(tableDetails, param);
  };
  generateReportBuilder = async (param: {
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    moduleSearchKeyword?: string;
    allSelected?: boolean;
    modulesAllSelected: boolean;
    moduleType: string;
    pageNum: number;
    // updateTableName: ComparsionLevel;
    dataType: string;
  }) => {
    const {
      object,
      reportType,
      user,
      countryCodes,
      moduleSearchKeyword,
      moduleType,
      modulesAllSelected,
      allSelected,
      pageNum,
      dataType
    } = param;
    let finalModule = "";
    if (moduleType === "CORE_TRAINING") {
      finalModule = "BASIC";
    } else {
      finalModule = "SPECIAL";
    }
    let response = await ApiClient.post(
      lmsReportsEndPoints.generateReportBuilder(
        reportType,
        allSelected,
        pageNum,
        dataType,
        modulesAllSelected,
        finalModule,
        moduleSearchKeyword
      ),
      object,
      null,
      LmsReportsKeys.LMS_GENERATE_REPORT
    );
    return lmsBuilderFormatter(param, response);
  };
}

const detailedLmsReportsRepository = new DetailedLmsReportsRepository();
export { detailedLmsReportsRepository as DetailedLmsReportsRepository };
