import React from "react";
import { View, StyleProp, StyleSheet, TextStyle } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Card from "@timhortons/common/src/components/atoms/card";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { testIds } from "@timhortons/common/src/utils/formHelpers";

interface IProps {
  translate: any;
}
export const CardHeader = (props: IProps) => {
  const { translate } = props;
  return (
    <Card.Header headerStyle={styles.cardHeaderContainerStyle}>
      <Icon name={IconNames.thunder} customStyle={styles.cardHeaderIconStyle} />
      {textBlock(
        TextSize.ExtraRegular,
        FontWeight.SemiBold,
        "appeal",
        styles.cardHeaderTextStyle,
        translate("appeal")
      )}
    </Card.Header>
  );
};
export const textBlock = (
  textSize: TextSize,
  fontWeight: FontWeight,
  testId: any,
  style: StyleProp<TextStyle>,
  text: string
): React.ReactElement => {
  return (
    <Text textSize={textSize} fontWeight={fontWeight} testId={testId} textStyle={style}>
      {text}
    </Text>
  );
};
interface Props {
  translate: any;
  textStyle: any;
  status: any;
  comment: any;
}
export const ClosedTableCommon = (props: Props) => {
  const { status, comment, textStyle, translate } = props;
  return (
    <>
      <View style={{ flexDirection: "row", marginBottom: 12 }}>
        <Icon name={IconNames.thunder} customStyle={styles.cardHeaderIconStyle} />
        <Text
          textSize={TextSize.ExtraRegular}
          fontWeight={FontWeight.SemiBold}
          testId={testIds.appeal.appealDecision}
          textStyle={styles.decisionTagStyle}
        >
          {translate("decision")}
        </Text>
        <Text
          textSize={TextSize.ExtraSmall}
          fontWeight={FontWeight.SemiBold}
          testId={testIds.appeal.appealApproved}
          textStyle={textStyle}
        >
          {status}
        </Text>
      </View>
      <Text
        textSize={TextSize.ExtraSmall}
        fontWeight={FontWeight.Regular}
        testId={testIds.appeal.appealRemark}
        textStyle={styles.remarkTextStyle}
      >
        {comment}
      </Text>
    </>
  );
};
const styles = StyleSheet.create({
  cardHeaderContainerStyle: {
    flexDirection: "row",
    paddingBottom: 20
  },
  cardHeaderTextStyle: {
    marginHorizontal: 10,
    color: colorPallete.grey2
  },
  cardHeaderIconStyle: {
    color: colorPallete.red2
  },

  decisionTagStyle: {
    color: colorPallete.grey2,
    marginHorizontal: 10
  },
  decisionTextStyle: {
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginTop: -4
  },
  approveDecisionTextStyle: {
    color: colorPallete.green1,
    backgroundColor: colorPallete.variant8
  },
  rejectDecisionTextStyle: {
    color: colorPallete.red4,
    backgroundColor: colorPallete.variant4
  },
  remarkTextStyle: {
    color: colorPallete.grey1
    // marginBottom: 20
  }
});
