import { call, put, takeLatest } from "redux-saga/effects";
import { Logger } from "@timhortons/common/src/services/logger";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LMS_REPORTS } from "@timhortons/common/src/redux/lmsReports/actions/actionTypes";
import {
  LMSEmpDetailedReport,
  ReportsTableDetailsModel
} from "@timhortons/common/src/models/lmsReports/lmsReportsModel";
import { LmsReportsActions } from "@timhortons/common/src/redux/lmsReports/actions/lmsReportsActions";
import { LmsReportsRepository } from "@timhortons/common/src/repositories/lmsReports/lmsReports";

function* getEmpDetailedLmsReportWorkerSaga(value: IFluxStandardAction<{ empId: string }>) {
  try {
    const { payload } = value;
    const response: LMSEmpDetailedReport = yield call(
      LmsReportsRepository.getEmpDetailedLmsReport,
      payload
    );
    yield put(LmsReportsActions.getEmpDetailedReportSuccess(response));
  } catch (error) {
    Logger.error("lms-Reports", error, "getEmpDetailedLmsReportWorkerSaga");

    yield put(LmsReportsActions.getEmpDetailedReportError(error));
  }
}
function* getReportsTableDetailsWorkerSaga(
  value: IFluxStandardAction<{
    pageNumber: number;
    pageSize: number;
    roleId: string;
    search: string;
    idList?: [string];
  }>
) {
  const { payload } = value;
  try {
    const response: ReportsTableDetailsModel[] = yield call(
      LmsReportsRepository.getReportsTableDetails,
      payload
    );
    yield put(LmsReportsActions.getReportsTableDetailsSuccess(response));
  } catch (error) {
    Logger.error("LMS-Reports Table Details", error, "getReportsTableDetailsWorkerSaga");

    yield put(LmsReportsActions.getReportsTableDetailsError(error));
  }
}

function* lmsReportsWatcherSaga() {
  yield takeLatest(LMS_REPORTS.GET_REPORTS_TABLE_DETAILS.LOADING, getReportsTableDetailsWorkerSaga);
  yield takeLatest(LMS_REPORTS.GET_EMP_DETAILED_REPORT.LOADING, getEmpDetailedLmsReportWorkerSaga);
}

export default lmsReportsWatcherSaga;
