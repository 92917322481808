import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import AreaManagerAppealCard from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/appealCard";
import Tableheader from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/lpTableHeader";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import { LpAppealTable } from "@timhortons/common/src/modules/lp/components/dashboard/appeals";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import {
  ILpAppealsError,
  ILpAppealsLoading
} from "@timhortons/common/src/redux/lp/reducers/appeals";
import { LpInProcesssClosedTable } from "@timhortons/common/src/models/lp/appeals";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { tableStyles } from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/index";

interface IProps {
  translate: any;
  currentTable: LpAppealTable;
  appealsTableData: LpInProcesssClosedTable[];
  loading: ILpAppealsLoading;
  isIndividualLogin: boolean;
  setAppealDecision: Function;
  userDetails: IUserDetails;
  error: ILpAppealsError;
  handleSortingOrder?: Function;
}

export default function InProcessAppealTable(props: IProps): ReactElement {
  const {
    userDetails,
    setAppealDecision,
    translate,
    currentTable,
    appealsTableData,
    loading,
    isIndividualLogin,
    error,
    handleSortingOrder
  } = props;
  // const [logDate, setlogDate] = useState(true);

  const appealTableHeader = [
    {
      name: translate("storeId")
    },
    {
      name: translate("storeNameTable")
    },
    {
      name: translate("auditDate")
    },
    {
      name:
        currentTable === LpAppealTable.inProcess
          ? translate("appealSubmittedBy")
          : translate("appealApprovedBy")
    }
  ];
  const individualAccessAppealTableHeader = [
    ...appealTableHeader.slice(0, 2),
    { name: translate("appealDate") },
    appealTableHeader[3],
    { name: translate("view") }
  ];
  // const handleSort = (val: "asc" | "desc", columnName: string) => {
  //   setlogDate(true);
  //   handleSortingOrder(val, columnName);
  // };
  const getIndividualHeaderComp = (headerItem: any, index: number): ReactElement => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={styles.headerCellcolor}
        cellStyle={styles.headerCellStyle}
        // sortFunction={(val: "asc" | "desc") => handleSort(val, headerItem.name)}
        // sortable={headerItem.isSortable}
      />
    );
  };

  const generateInitialValues = (value: number) => {
    let initState = [];
    for (let i = 0; i < value; i++) {
      initState.push({
        view: false
      });
    }
    return initState;
  };
  const [toggleEyeIcon, setToggleEyeIcon] = useState(
    generateInitialValues(appealsTableData && appealsTableData.length)
  );
  const setToggleCard = (index: number) => {
    let temp = [...toggleEyeIcon];
    temp.forEach((val, i) => {
      if (i === index) {
        val.view = !val.view;
      }
    });
    setToggleEyeIcon(temp);
  };

  useEffect(() => {
    setToggleEyeIcon(generateInitialValues(appealsTableData && appealsTableData.length));
  }, [currentTable, appealsTableData]);

  return (
    <Table>
      <Row header rowStyle={tableStyles.tableRowStyle}>
        {isIndividualLogin
          ? individualAccessAppealTableHeader.map((headerItem, index) =>
              getIndividualHeaderComp(headerItem, index)
            )
          : appealTableHeader.map((headerItem, index) =>
              getIndividualHeaderComp(headerItem, index)
            )}
      </Row>
      {error.lpFilteredData ? (
        <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
      ) : loading.lpClosedData || loading.lpFilteredData ? (
        <Loader type="component" />
      ) : appealsTableData && appealsTableData.length > 0 ? (
        <View>
          {appealsTableData &&
            appealsTableData.map((data, index) => {
              return currentTable === LpAppealTable.inProcess ||
                currentTable === LpAppealTable.closedAppeal ? (
                <View key={index}>
                  <Tableheader
                    cellStyle={styles.headerCellStyle}
                    data={data}
                    translate={translate}
                    isIndividualLogin={isIndividualLogin}
                    index={index}
                    viewIconStyle={styles.viewIconStyles}
                    iconOnPress={() => setToggleCard(index)}
                    toggleIcon={toggleEyeIcon}
                    currentTable={currentTable}
                  />

                  {((index < toggleEyeIcon.length && toggleEyeIcon && toggleEyeIcon[index].view) ||
                    !isIndividualLogin) &&
                    data.items.map((item, itemKey) => (
                      <Row key={itemKey} rowStyle={styles.appealCardRowStyle}>
                        {
                          <AreaManagerAppealCard
                            userDetails={userDetails}
                            setAppealDecision={setAppealDecision}
                            isIndividualLogin={isIndividualLogin}
                            key={itemKey}
                            appealCardData={item}
                            translate={translate}
                            currentTable={currentTable}
                          />
                        }
                      </Row>
                    ))}
                </View>
              ) : null;
            })}
        </View>
      ) : (
        <Jumbotron type={ErrorType.NoDataFound} title={translate("noItemAppealed")} />
      )}
    </Table>
  );
}

const styles = StyleSheet.create({
  appealCardRowStyle: {
    paddingHorizontal: 30,
    paddingBottom: 24
  },
  headerCellStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 30 },
      ltr: { marginStart: 30 }
    }),
    textAlign: "center"
  },
  viewIconStyles: {
    justifyContent: "center"
  },
  headerCellcolor: { color: colorPallete.grey1 }
});
