import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import {
  ActionPlanHeaderModel,
  ActionPlanTableModel,
  ActionPlanCategoryDataModel,
  ActionPlanAccordionDataModel,
  FilterDataModel,
  DropDownModel
} from "@timhortons/common/src/models/rev/actionPlan";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { actionPlanEndPoints } from "@timhortons/common/src/constants/endPoints";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import { IActionPlanData } from "@timhortons/common/src/modules/rev/components/dashboards/actionPlans";
import { IActionItemsData } from "@timhortons/common/src/modules/rev/organisms/actionPlanAccordion/body";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { REVKeys } from "@timhortons/common/src/constants/apiKeys";
import { AckDropDownModel, AcknowledgementModel } from "@timhortons/common/src/models/rev/reports";

const actionplanStatus: Array<DropDownModel> = [
  {
    value: "COMPLETED",
    label: "Completed"
  },
  {
    value: "NOT_STARTED",
    label: "Not Started"
  },
  {
    value: "OVERDUE",
    label: "Overdue"
  }
];

const filterData: FilterDataModel = {
  storeId: "1",
  auditorName: [
    {
      name: "Spock",
      id: "01"
    }
  ],
  date: [
    new Date(2021, 4, 10, 17, 23, 42, 11),
    new Date(2021, 4, 6, 17, 23, 42, 11),
    new Date(2021, 4, 1, 17, 23, 42, 11),
    new Date(2021, 3, 25, 17, 23, 42, 11),
    new Date(2021, 3, 15, 17, 23, 42, 11)
  ],
  status: actionplanStatus
};
class ActionPlanRepository {
  getActionPlans = async (param: IActionPlanData) => {
    const userDetails: IUserDetails = await LocalStorage.getStorage("user");
    let { sortOrder, columnName } = param;
    let newDate = convert(param.date);
    let fromDate = convert(param.fromDate);
    let toDate = convert(param.toDate);
    let search = param.search === "" ? null : param.search;
    let object: any = {
      // userId: userDetails && userDetails.storeCode,
      storeCode: userDetails && userDetails.storeCode,
      pageNo: param.pageNumber,
      size: param.pageSize,
      filterByActionPlanStatus: param.status
    };
    let individualAccessObject: any = {
      auditedBy: userDetails && userDetails.empId,
      // storeCode: userDetails && userDetails.storeCode,
      pageNo: param.pageNumber,
      size: param.pageSize,
      filterByActionPlanStatus: param.status
    };
    if (param.date !== null) {
      object = {
        ...object,
        filterByToDate: newDate,
        filterByFromDate: newDate
      };
    }
    if (param.fromDate !== null) {
      individualAccessObject = {
        ...individualAccessObject,
        filterByFromDate: fromDate,
        filterByToDate: toDate
      };
    }
    if (param.search !== null) {
      individualAccessObject = {
        ...individualAccessObject,
        searchByKeyword: search
      };
    }
    let data;
    if (param.userId === true) {
      data = await ApiClient.get(
        actionPlanEndPoints.getActionPlanTableData(),
        {
          ...individualAccessObject,
          sortByDate: sortOrder
        },
        REVKeys.ACTION_PLANS
      );
    } else if (columnName === "Audit Time") {
      data = await ApiClient.get(
        actionPlanEndPoints.getActionPlanTableData(),
        {
          ...object,
          sortByTime: sortOrder
        },
        REVKeys.ACTION_PLANS
      );
    } else {
      data = await ApiClient.get(
        actionPlanEndPoints.getActionPlanTableData(),
        {
          ...object,
          sortByDate: sortOrder
        },
        REVKeys.ACTION_PLANS
      );
    }
    return deserialize(ActionPlanTableModel, data);
  };

  getActionPlanCategoryTableData = async (param: {
    calibrationId: number;
    actionPlanId: number;
  }) => {
    const { actionPlanId, calibrationId } = param;
    let data: ActionPlanCategoryDataModel[] = await ApiClient.get(
      actionPlanEndPoints.getActionPlanCategoryTableData(calibrationId, actionPlanId),
      null,
      REVKeys.ACTION_PLANS
    );

    let categories: ActionPlanCategoryDataModel[] = [];
    data.forEach((item: ActionPlanCategoryDataModel) => {
      let deserializevar: ActionPlanCategoryDataModel = deserialize(
        ActionPlanCategoryDataModel,
        item
      );
      categories.push(deserializevar);
    });

    return categories;
  };

  getUpdateActionPlanStatus = async (param: { actionPlanId: number }) => {
    return ApiClient.put(
      actionPlanEndPoints.updateActionPlanStatus(param.actionPlanId),
      null,
      null,
      REVKeys.ACTION_PLANS
    );
  };
  getActionPlanAccordian = async (param: {
    categoryId: number;
    actionPlanId: number;
    calibrationId: number;
  }) => {
    let data = await ApiClient.get(
      actionPlanEndPoints.getActionPlanAccordianData(param),
      null,
      REVKeys.ACTION_PLANS
    );
    let accordianBody: ActionPlanAccordionDataModel[] = [];
    data.map((item: ActionPlanAccordionDataModel) => {
      let deserializevar: ActionPlanAccordionDataModel = deserialize(
        ActionPlanAccordionDataModel,
        item
      );
      accordianBody.push(deserializevar);
    });
    return accordianBody;
  };
  getActionPlanDetails = async (param: { actionPlanId: number }) => {
    let data: { status: string; data: ActionPlanHeaderModel } = await ApiClient.get(
      actionPlanEndPoints.getActionPlanHeaderData(param.actionPlanId),
      null,
      REVKeys.ACTION_PLANS
    );
    return deserialize(ActionPlanHeaderModel, data);
  };
  postActionSteps = async (postData: IActionItemsData): Promise<{}> => {
    let p = postData as any;
    return ApiClient.post(actionPlanEndPoints.postActionSteps(), p, {}, REVKeys.ACTION_PLANS);
  };
  getFilterData = (param: { storeCode: string }): FilterDataModel | Error => {
    const { storeCode } = param;
    if (filterData.storeId === storeCode) {
      return filterData;
    } else throw new Error("Store id required");
  };

  getOwnerData = async (param: { storeId: string }) => {
    let data = await ApiClient.get(
      actionPlanEndPoints.getOwnerData(param.storeId),
      null,
      REVKeys.ACTION_PLANS
    );
    let finalData: AcknowledgementModel = {
      dropDownData: []
    };
    data.map((item: AckDropDownModel) => {
      let deserializeVar: AckDropDownModel = deserialize(AckDropDownModel, item);
      finalData.dropDownData.push(deserializeVar);
    });
    return finalData;
  };
}

const actionPlanRepository = new ActionPlanRepository();
export { actionPlanRepository as ActionPlanRepository };
