import { ActionHelper } from "@timhortons/common/src/utils/actionHelper";

const prefix = "manageExistingUser";

const MANAGE_EXISTING_USER = {
  GET_EXISTING_USERS: ActionHelper.actionTypesCreator(prefix, "GET_EXISTING_USERS"),
  ONBOARD_EXISTING_USERS: ActionHelper.actionTypesCreator(prefix, "ONBOARD_EXISTING_USERS")
};

export { MANAGE_EXISTING_USER };
