import React from "react";
import { StyleSheet, View } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";
import { ReportsSummaryOpportunityDataModel } from "models/rev/reports";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { Roles } from "@timhortons/common/src/constants/roles";

interface IProps {
  translate?: any;
  currentUserRoles: Roles[];
  opportunityData: ReportsSummaryOpportunityDataModel[];
  loading?: boolean;
  error?: string;
}

export default function OpportunityTable(props: IProps) {
  const { translate, currentUserRoles, opportunityData = [], loading, error } = props;

  const getHeaderComp = (headerItem: any, index: number) => {
    return (
      <HeaderCell
        key={index}
        title={headerItem.name}
        columnName={headerItem.name}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.grey1 }}
        cellStyle={
          (headerItem.name === translate("opportunity") && styles.opportunityHeaderCellStyle) ||
          (headerItem.name === translate("brandStandards") && styles.cellStyle) ||
          styles.headerCellStyle
        }
      />
    );
  };

  const opportunityTableHeader = [
    { name: translate("brandStandards") },
    { name: translate("opportunity") },
    { name: translate("points") },
    {
      name: checkAllowedRoles(currentUserRoles) ? translate("stores") : translate("frequency")
    }
  ];

  return (
    <View style={styles.container}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        testId="opportunity table"
        textStyle={styles.headerTextStyle}
      >
        {translate("opportunityDetails")}
      </Text>
      <View>
        <Table>
          <Row header rowStyle={{}}>
            {opportunityTableHeader.map((headerItem, index) => {
              return getHeaderComp(headerItem, index);
            })}
          </Row>
          {error ? (
            <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
          ) : loading ? (
            <Loader type="component" />
          ) : opportunityData === null || opportunityData.length === 0 ? (
            <Jumbotron type={ErrorType.NoDataFound} title={translate("noOpportunities")} />
          ) : (
            <FlatList
              scrollEnabled={false}
              extractor={(item) => item.question}
              listData={opportunityData}
              listRenderer={(item: ReportsSummaryOpportunityDataModel) => (
                <View>
                  <Row rowStyle={{ marginVertical: 12 }}>
                    <Cell
                      title={item.categoryName}
                      cellStyle={styles.cellStyle}
                      titleStyle={styles.cellTitleStyle}
                    />
                    <Cell
                      titleStyle={{ flexDirection: "row", color: colorPallete.black4 }}
                      cellStyle={styles.opportunityHeaderCellStyle}
                    >
                      <View style={{ width: 60, alignSelf: "center" }}>
                        <Text
                          textSize={TextSize.ExtraRegular}
                          fontWeight={FontWeight.SemiBold}
                          testId="questionLabel"
                        >
                          {item.questionLabel}
                        </Text>
                      </View>
                      <Text
                        textSize={TextSize.ExtraRegular}
                        fontWeight={FontWeight.Regular}
                        testId="question"
                        textStyle={styles.questionStyle}
                      >
                        {item.question}
                      </Text>
                    </Cell>
                    <Cell
                      title={`${item.points}pts`}
                      cellStyle={styles.headerCellStyle}
                      titleStyle={styles.cellTitleStyle}
                    />
                    <Cell
                      title={item.frequency}
                      cellStyle={styles.headerCellStyle}
                      titleStyle={styles.cellTitleStyle}
                    />
                  </Row>
                </View>
              )}
              seperator={() => (
                <Divider
                  orientation={Orientations.Horizontal}
                  dividerStyle={{ borderColor: colorPallete.white1 }}
                />
              )}
            />
          )}
        </Table>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7
  },
  headerTextStyle: {
    padding: 24
  },
  headerCellStyle: {
    flex: 1,
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 24, marginEnd: 30 },
      ltr: { marginStart: 24, marginEnd: 30 }
    })
  },
  cellStyle: {
    flex: 1,
    justifyContent: "flex-start",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 24, marginEnd: 30 },
      ltr: { marginStart: 24, marginEnd: 30 }
    })
  },
  opportunityHeaderCellStyle: {
    flex: 4,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 12,
    ...I18nService.select({
      rtl: { marginStart: 24, marginEnd: 30 },
      ltr: { marginStart: 24, marginEnd: 30 }
    })
  },
  questionStyle: {
    ...I18nService.select({
      rtl: { marginStart: 10 },
      ltr: { marginStart: 10 }
    })
  },
  cellTitleStyle: {
    color: colorPallete.black4
  }
});
