import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { manageAskEndPoints } from "@timhortons/common/src/constants/endPoints";
import { LMSKeys } from "@timhortons/common/src/constants/apiKeys";
import { CreatFaqModal, FaqQuestionModel } from "@timhortons/common/src/models/askTell/faqModule";
import { getFaqDataFormatter } from "@timhortons/common/src/repositories/askTell/formatter/manageAskFormatters";

class FaqModuleRepository {
  updateFaq = async (param: { id: string; body: CreatFaqModal }) => {
    const { id } = param;
    let data = await ApiClient.put(manageAskEndPoints.faq.updateFaq(id), param.body, null);
    return data;
  };
  getAllFaqs = async (): Promise<FaqQuestionModel[]> => {
    let data: FaqQuestionModel[] = await ApiClient.get(manageAskEndPoints.faq.getAllFaqs(), null);
    return getFaqDataFormatter(data);
  };
  createFaq = async (param: { body: CreatFaqModal }) => {
    let data = await ApiClient.post(
      manageAskEndPoints.faq.createFaq(),
      param.body,
      null,
      LMSKeys.LMS_MANAGE
    );
    return data;
  };
  deleteFaq = async (param: { id: string }) => {
    const { id } = param;
    await ApiClient.delete(manageAskEndPoints.faq.deleteFaq(id), null, null);
  };
}

const faqModuleRepository = new FaqModuleRepository();
export { faqModuleRepository as FaqModuleRepository };
