import { Any, JsonObject, JsonProperty } from "json2typescript";
import { ActionPlanImagesModel } from "@timhortons/common/src/models/rev/actionPlan";

@JsonObject("CreateNewAppeal")
export class CreateNewAppeal {
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("data", Number)
  data: number = 0;
  @JsonProperty("appealerId", Number)
  appealerId: number = 0;
}
@JsonObject("FileModel")
export class FileModel {
  @JsonProperty("id", String && null)
  id: string = "";
  @JsonProperty("signedUrl", String && null)
  signedUrl: string = "";
  @JsonProperty("blob", Blob, true)
  blob?: Blob = null;
}
@JsonObject("AppealsQuestionListModel")
export class AppealsQuestionListModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("questionLabel", String)
  serialNumber: string = "";
  @JsonProperty("question", String)
  questionName: string = "";
  @JsonProperty("subCategoryId", String)
  subCategoryId: string = "";
  @JsonProperty("categoryId", String)
  categoryId: string = "";
}

@JsonObject("AppealerResponseModel")
export class AppealerResponseModel {
  @JsonProperty("comment", String, true)
  comment: string = "";
  @JsonProperty("hasAppealed", Boolean)
  hasAppealed: boolean = false;
  @JsonProperty("assets", [FileModel], true)
  file?: Array<FileModel> = [];
}
@JsonObject("AppealsAccordionDataModel")
export class AppealsAccordionDataModel {
  @JsonProperty("subCategoryLabel", String)
  serialNumber: string = "";
  @JsonProperty("subCategoryName", String)
  title: string = "";
  @JsonProperty("subCategoryId", Number)
  id: string = "";
  @JsonProperty("categoryId", Number)
  categoryId: string = "";
  @JsonProperty("auditorComment", String && null)
  auditorComment?: string = "";
  @JsonProperty("images", [ActionPlanImagesModel] && null)
  file?: Array<ActionPlanImagesModel> = [];
  @JsonProperty("observations", [String], true)
  observations?: string[] = null;
  @JsonProperty("questions", [AppealsQuestionListModel])
  opportunityList: Array<AppealsQuestionListModel> = null;
  @JsonProperty("appealerResponse", AppealerResponseModel)
  appealerResponse: AppealerResponseModel = null;
  @JsonProperty("points", Number, true)
  points: number = 0;
  @JsonProperty("status", Any, true)
  status: any = null;
}

@JsonObject("AppealItemsModel")
export class AppealItemsModel {
  @JsonProperty("appealItemId", Number)
  appealItemId: number = 0;
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("subCategoryName", String)
  subCategoryName: string = "";
  @JsonProperty("subCategoryLabel", String)
  subCategoryLabel: string = "";
  @JsonProperty("auditorComment", String && null)
  auditorComment: string = "";
  @JsonProperty("appealerComment", String && null)
  appealerComment: string = "";
  @JsonProperty("appealedItemDecisionStatus", String && null)
  appealedItemDecisionStatus: string = "";
}

// @JsonObject("InProcessAppealTableModel")
// export class InProcessAppealTableModel {
//   @JsonProperty("storeCode", Number)
//   storeCode: number = 0;
//   @JsonProperty("storeName", String)
//   storeName: string = "";
//   @JsonProperty("auditorId", String)
//   auditorId: string = "";
//   @JsonProperty("auditorName", String)
//   auditorName: string = "";
//   @JsonProperty("appealSubmittedByName", String)
//   appealSubmittedByName: string = "";
//   @JsonProperty("calibrationId", Number)
//   calibrationId: number = 0;
//   @JsonProperty("appealId", Number)
//   appealId: number = 0;
//   @JsonProperty("appealDate", String)
//   appealDate: string = "";
//   @JsonProperty("appealedItems", [AppealItemsModel])
//   appeals: AppealItemsModel[] = null;
// }

@JsonObject("NewAppealsTable")
export class NewAppealsTable {
  @JsonProperty("storeCode", Number)
  storeId: number = 0;
  @JsonProperty("calibrationId", Number)
  calibrationId: number = 0;
  @JsonProperty("appealId", null)
  appealId: null;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("auditorName", String)
  auditorName: string = "";
  @JsonProperty("auditDate", String)
  auditDate: string = "";
  @JsonProperty("appealedItems", null)
  appealedItems: null;
}

@JsonObject("AppealItemModel")
export class AppealItemModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("appealId", Number)
  appealId: number = 0;
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("commentByAppealer", String && null)
  commentByAppealer: string = "";
  @JsonProperty("commentByAuditor", null)
  commentByAuditor: null;
  @JsonProperty("hasAppealed", Boolean)
  hasAppealed: boolean = false;
  @JsonProperty("appealItemAssets", [FileModel])
  appealItemAssets: FileModel[] = [];
}

@JsonObject("AppealCommentsDataModel")
export class AppealCommentsDataModel {
  @JsonProperty("categoryId", Number)
  categoryId: number = 0;
  @JsonProperty("isExist", Boolean)
  isExist: boolean = false;
  @JsonProperty("appealItem", AppealItemModel)
  appealItem: AppealItemModel = null;
}
@JsonObject("NewAppealsCommentsDataModel")
export class NewAppealsCommentsDataModel {
  @JsonProperty("appealId", Number)
  appealId: number = 0;
  @JsonProperty("calibrationId", Number)
  calibrationId: number = 0;
  @JsonProperty("comment", String)
  comment: string = "";
  @JsonProperty("userId", Number)
  userId: number = 0;
  @JsonProperty("questions", [Number])
  questions: Array<number> = null;
  @JsonProperty("images", [FileModel])
  files: FileModel[] = [];
}
@JsonObject("PostAppealCommentsModel")
export class PostAppealCommentsModel {
  // @JsonProperty("appealId", Number)
  // appealId: number = 0;
  // @JsonProperty("subCategoryId", Number)
  // subCategoryId: number = 0;
  // @JsonProperty("comment", String)
  // comment: string = "";
  // @JsonProperty("userId", Number)
  // userId: number = 0;
  @JsonProperty("body", FormData)
  body?: FormData = null;
}
@JsonObject("AppealsCategoryData")
export class AppealsCategoryData {
  @JsonProperty("categoryId", Number)
  id: number = 0;
  @JsonProperty("categoryName", String)
  title: string = "";
  @JsonProperty("hasPendingAppeal", Boolean)
  progress: boolean = false;
  @JsonProperty("categoryLabel", String, true)
  catgeoryLabel: string = "";
}

@JsonObject("AppealsCategoryDataModel")
export class AppealsCategoryDataModel {
  @JsonProperty("storeCode", Number)
  storeId: number = 0;
  @JsonProperty("location", String)
  location: string = "";
  @JsonProperty("submittedBy", String)
  auditorName: string = "";
  @JsonProperty("completedPercentage", Number, true)
  completionPercentage?: number = 0;
  @JsonProperty("categories", [AppealsCategoryData])
  categories: Array<AppealsCategoryData> = null;
}

@JsonObject("SetAppealDecisionModel")
export class SetAppealDecisionModel {
  // @JsonProperty("opportunityList", [OpportunityListModel])
  // opportunityList: Array<OpportunityListModel> = null;
  // @JsonProperty("approveDecision", String)
  // approveDecision?: string = "";
  // @JsonProperty("disapproveDecision", Boolean)
  // disapproveDecision?: boolean = false;
  // @JsonProperty("auditedBy", String)
  // auditedBy?: string = "";
  @JsonProperty("body", FormData)
  body: FormData = null;
}

@JsonObject("AppealCountModel")
export class AppealCountModel {
  @JsonProperty("newAppealsCount", Number, true)
  newAppealsCount?: number = 0;
  @JsonProperty("inProcessCount", Number)
  inProcessCount: number = 0;
  @JsonProperty("closedCount", Number)
  closedAppealCount: number = 0;
}
//=============================================================================

@JsonObject("InProcessCommentDataModel")
export class InProcessCommentDataModel {
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("assets", [{ id: Number, signedUrl: String }] && [])
  assets: Array<{ id: number; signedUrl: string }> = [];
  @JsonProperty("hasAppealed", Boolean && null)
  hasAppealed: boolean = false;
}
@JsonObject("RevSubCategoryModel")
export class RevSubCategoryModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("subcategoryName", String)
  subcategoryName: string = "";
  @JsonProperty("subcategoryLabel", String)
  subcategoryLabel: string = "";
}

@JsonObject("OpportunityNewModel")
export class OpportunityNewModel {
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("isTicked", Boolean)
  isTicked: boolean = false;
}
@JsonObject("IndividualAppealItemModel")
export class IndividualAppealItemModel {
  @JsonProperty("appealItemId", Number)
  appealItemId: number = 0;
  @JsonProperty("appealItemStatus", String)
  appealItemStatus: string = "";
  @JsonProperty("hasAppealed", Boolean)
  hasAppealed: boolean = false;
  @JsonProperty("auditorResponse", InProcessCommentDataModel)
  auditorResponse: InProcessCommentDataModel = null;
  @JsonProperty("appealerResponse", InProcessCommentDataModel)
  appealerResponse: InProcessCommentDataModel = null;
  @JsonProperty("appealQuestion", [OpportunityNewModel], false)
  appealQuestion?: OpportunityNewModel[] = null;
  @JsonProperty("revSubCategory", RevSubCategoryModel)
  revSubCategory: RevSubCategoryModel;
}
@JsonObject("InProcesssClosedTable")
export class InProcesssClosedTable {
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("auditorName", String)
  auditorName: string = "";
  @JsonProperty("appealSubmittedByName", String)
  appealSubmittedByName: string = "";
  @JsonProperty("appealId", Number)
  appealId: number = 0;
  @JsonProperty("auditedBy", String)
  auditedBy: string = "";
  @JsonProperty("appealDate", String)
  appealDate: string = "";
  @JsonProperty("items", [IndividualAppealItemModel])
  items: IndividualAppealItemModel[] = null;
}

@JsonObject("CategoryDataModel")
export class CategoryDataModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("progress", Number)
  progress: number = 0;
  @JsonProperty("categoryLabel", String)
  label: string = "";
}
@JsonObject("CategoryStatusModel")
export class CategoryStatusModel {
  @JsonProperty("actionPlanId", String)
  actionPlanId: string = null;
  @JsonProperty("categoryTableItems", [CategoryDataModel])
  categoryTableItems: Array<CategoryDataModel> = null;
  // @JsonProperty("categoryTableItems", [CategoryListModel])
  // categoryTableItems: Array<CategoryListModel> = null;
}
