import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import {
  LmsModulePtsDataModel,
  LmsPtsCheckListResponseModel,
  LmsPtsTopicResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";
import {
  ptsPostTopicSuccessHandler,
  ptsUpdateTopicSuccessHandler,
  ptsDeleteTopicSuccessHandler,
  ptsPostCheckListSuccessHandler,
  ptsUpdateCheckListSuccessHandler,
  ptsDeleteCheckListSuccessHandler
} from "@timhortons/common/src/repositories/manageLms/formatters/lmsPtsFormatter";

export interface ILmsPtsLoading {
  getLmsPtsData: boolean;
  postLmsPtsTopic: boolean;
  updateLmsPtsTopic: boolean;
  deleteLmsPtsTopic: boolean;
  postLmsPtsCheckList: boolean;
  updateLmsPtsCheckList: boolean;
  deleteLmsPtsCheckList: boolean;
}
export interface ILmsPtsError {
  getLmsPtsData: string;
  postLmsPtsTopic: string;
  updateLmsPtsTopic: string;
  deleteLmsPtsTopic: string;
  postLmsPtsCheckList: string;
  updateLmsPtsCheckList: string;
  deleteLmsPtsCheckList: string;
}
export interface ILmsPtsState {
  loading: ILmsPtsLoading;
  error: ILmsPtsError;
  getLmsPtsData: LmsModulePtsDataModel[];
  postLmsPtsTopic: LmsPtsTopicResponseModel;
  updateLmsPtsTopic: LmsPtsTopicResponseModel;
  deleteLmsPtsTopic: LmsPtsTopicResponseModel;
  postLmsPtsCheckList: LmsPtsCheckListResponseModel;
  updateLmsPtsCheckList: LmsPtsCheckListResponseModel;
  deleteLmsPtsCheckList: LmsPtsCheckListResponseModel;
  targetTopicId: string;
}

export const lmsModuleInitialState: ILmsPtsState = {
  error: {
    getLmsPtsData: null,
    postLmsPtsTopic: null,
    updateLmsPtsTopic: null,
    deleteLmsPtsTopic: null,
    postLmsPtsCheckList: null,
    updateLmsPtsCheckList: null,
    deleteLmsPtsCheckList: null
  },
  loading: {
    getLmsPtsData: false,
    postLmsPtsTopic: false,
    updateLmsPtsTopic: false,
    deleteLmsPtsTopic: false,
    postLmsPtsCheckList: false,
    updateLmsPtsCheckList: false,
    deleteLmsPtsCheckList: false
  },
  getLmsPtsData: [],
  postLmsPtsTopic: null,
  updateLmsPtsTopic: null,
  deleteLmsPtsTopic: null,
  postLmsPtsCheckList: null,
  updateLmsPtsCheckList: null,
  deleteLmsPtsCheckList: null,
  targetTopicId: null
};

export const lmsPtsReducer = (
  state = lmsModuleInitialState,
  action: { payload: any; type: string }
): ILmsPtsState => {
  switch (action.type) {
    //* reducer for get pts data by module id
    case MANAGELMS.pts.GET_MODULE_PTS_DATA.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLmsPtsData: true },
        error: { ...state.error, getLmsPtsData: null }
      };
    case MANAGELMS.pts.GET_MODULE_PTS_DATA.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLmsPtsData: false },
        error: { ...state.error, getLmsPtsData: null },
        getLmsPtsData: action.payload
      };
    case MANAGELMS.pts.GET_MODULE_PTS_DATA.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLmsPtsData: false },
        error: { ...state.error, getLmsPtsData: action.payload }
      };
    //* reducer for post pts Topic
    case MANAGELMS.pts.POST_PTS_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postLmsPtsTopic: true },
        error: { ...state.error, postLmsPtsTopic: null }
      };
    case MANAGELMS.pts.POST_PTS_TOPIC.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postLmsPtsTopic: false },
        error: { ...state.error, postLmsPtsTopic: null },
        getLmsPtsData: ptsPostTopicSuccessHandler(state.getLmsPtsData, action.payload),
        postLmsPtsTopic: action.payload
      };
    case MANAGELMS.pts.POST_PTS_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postLmsPtsTopic: false },
        error: { ...state.error, postLmsPtsTopic: action.payload }
      };
    //* reducer for update pts Topic
    case MANAGELMS.pts.UPDATE_PTS_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateLmsPtsTopic: true },
        error: { ...state.error, updateLmsPtsTopic: null }
      };
    case MANAGELMS.pts.UPDATE_PTS_TOPIC.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateLmsPtsTopic: false },
        error: { ...state.error, updateLmsPtsTopic: null },
        getLmsPtsData: ptsUpdateTopicSuccessHandler(state.getLmsPtsData, action.payload),
        updateLmsPtsTopic: action.payload
      };
    case MANAGELMS.pts.UPDATE_PTS_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateLmsPtsTopic: false },
        error: { ...state.error, updateLmsPtsTopic: action.payload }
      };
    //* reducer for delete pts Topic
    case MANAGELMS.pts.DELETE_PTS_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsPtsTopic: true },
        error: { ...state.error, deleteLmsPtsTopic: null }
      };
    case MANAGELMS.pts.DELETE_PTS_TOPIC.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsPtsTopic: false },
        error: { ...state.error, deleteLmsPtsTopic: null },
        getLmsPtsData: ptsDeleteTopicSuccessHandler(state.getLmsPtsData, action.payload),
        deleteLmsPtsTopic: action.payload
      };
    case MANAGELMS.pts.DELETE_PTS_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsPtsTopic: false },
        error: { ...state.error, deleteLmsPtsTopic: action.payload }
      };

    //* reducer for post pts checklist
    case MANAGELMS.pts.POST_PTS_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postLmsPtsCheckList: true },
        error: { ...state.error, postLmsPtsCheckList: null },
        targetTopicId: action.payload?.ptsPracticalTopicId
      };
    case MANAGELMS.pts.POST_PTS_CHECKLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postLmsPtsCheckList: false },
        error: { ...state.error, postLmsPtsCheckList: null },
        getLmsPtsData: ptsPostCheckListSuccessHandler(
          state.getLmsPtsData,
          state.targetTopicId,
          action.payload
        ),
        postLmsPtsCheckList: action.payload
      };
    case MANAGELMS.pts.POST_PTS_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postLmsPtsCheckList: false },
        error: { ...state.error, postLmsPtsCheckList: action.payload }
      };
    //* reducer for update pts checklist
    case MANAGELMS.pts.UPDATE_PTS_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateLmsPtsCheckList: true },
        error: { ...state.error, updateLmsPtsCheckList: null }
      };
    case MANAGELMS.pts.UPDATE_PTS_CHECKLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateLmsPtsCheckList: false },
        error: { ...state.error, updateLmsPtsCheckList: null },
        // getLmsPtsData: ptsUpdateCheckListSuccessHandler(state.getLmsPtsData, action.payload),
        updateLmsPtsCheckList: action.payload
      };
    case MANAGELMS.pts.UPDATE_PTS_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateLmsPtsCheckList: false },
        error: { ...state.error, updateLmsPtsCheckList: action.payload }
      };
    //* reducer for delete pts checklist
    case MANAGELMS.pts.DELETE_PTS_CHECKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsPtsCheckList: true },
        error: { ...state.error, deleteLmsPtsCheckList: null },
        targetTopicId: action.payload?.topicId
      };
    case MANAGELMS.pts.DELETE_PTS_CHECKLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsPtsCheckList: false },
        error: { ...state.error, deleteLmsPtsCheckList: null },
        getLmsPtsData: ptsDeleteCheckListSuccessHandler(
          state.getLmsPtsData,
          state.targetTopicId,
          action.payload
        ),
        deleteLmsPtsCheckList: action.payload
      };
    case MANAGELMS.pts.DELETE_PTS_CHECKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteLmsPtsCheckList: false },
        error: { ...state.error, deleteLmsPtsCheckList: action.payload }
      };
    case MANAGELMS.pts.RESET_PTS_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          getLmsPtsData: null,
          postLmsPtsTopic: null,
          updateLmsPtsTopic: null,
          deleteLmsPtsTopic: null,
          postLmsPtsCheckList: null,
          updateLmsPtsCheckList: null,
          deleteLmsPtsCheckList: null
        }
      };
    default:
      return state;
  }
};
