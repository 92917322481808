import { combineReducers } from "redux";

import {
  ILmsReportsState,
  lmsReportsReducer
} from "@timhortons/common/src/redux/lmsReports/reducers/lmsReports";
import { IDetailedLmsReportsState, detailedLmsReportsReducer } from "./builderLmsReports";

export interface ILmsReportBuildersState {
  lmsReports: ILmsReportsState;
  detailedLmsReports: IDetailedLmsReportsState;
}
export const LmsBuilderReducer = combineReducers({
  lmsReports: lmsReportsReducer,
  detailedLmsReports: detailedLmsReportsReducer
});
