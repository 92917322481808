export const allRoutesNames = {
  HOME: "/dd",
  LOGIN: "/login",
  IAM: {
    root: "",
    login: "/",
    forgotPassword: "/forgotPassword"
  },
  CONTACT: "/contact",
  app: "/app",
  ABOUTUS: "/aboutUs",
  PRIVACY: "/privacy",
  PROFILE: {
    root: "/profile",
    profile: "/",
    userDetails: "/userDetails",
    createStore: "/createStore",
    createEmployee: "/createEmployee",
    manageStore: "/manageStore",
    manageUser: "/manageUser"
  },
  LMS: {
    root: "/lms",
    course: "/course",
    assessment: "/assessment",
    assessmentQuestion: "/assessmentQuestion",
    assessmentResultSuccess: "/assessmentResultSuccess",
    assessmentResultFail: "/assessmentResultFail"
    // dashboards: {
    //   root: `/dashboard/`,
    //   urlParams: {
    //     logs: `logsList`,
    //     actions: `logActions`,
    //     managerReview: `managerReview`,
    //     reports: `logsReports`
    //   }
    // }
  },
  LOGS: {
    root: "/logs",
    adminFlow: "/adminFlow",
    dashboards: {
      root: `/dashboard/`,
      urlParams: {
        logs: `logsList`,
        actions: `logActions`,
        managerReview: `managerReview`,
        reports: `logsReports`
      },

      specificStore: "/store"
    },
    superAdminDashboard: {
      root: `/superAdminDashboard/`,
      urlParams: {
        adminLogsList: `adminLogsList`,
        adminLogActions: `adminLogActions`,
        adminLogsReports: `adminLogsReports`
      }
    }
  },
  // PRODUCTIONSHEET: {
  //   root: "/productionSheet",
  //   dashboards: {
  //     root: `/dashboard/`,
  //     urlParams: {
  //       mainMenu: `mainMenu`,
  //       productionSheet: `productionSheet`,
  //       productionManagerReview: `productionManagerReview`,
  //       productionSummary: `productionSummary`
  //     }
  //   }
  // },
  rev: {
    root: "/rev",
    dashboards: {
      root: `/dashboard/`,
      urlParams: {
        selfCalibrationsList: `selfCalibrations`,
        reportsList: `reports`,
        actionPlansList: `actionPlans`,
        appealsList: `appeals`
      }
    },
    testAudit: `/testAudit`,
    detailedReports: `/detailedReports`,
    detailedActionPlan: `/actionPlan`,
    detailedAppeals: `/appeals`,
    builderReport: `/builderReport`
  },
  lp: {
    root: "/lp",
    dashboards: {
      root: `/dashboard/`,
      urlParams: {
        auditsList: `audits`,
        reportsList: `reports`,
        actionPlansList: `actionPlans`,
        appealsList: `appeals`
      }
    },
    lpAudit: `/audit`,
    detailedReport: `/report`,
    detailedActionPlan: `/actionPlan`,
    detailedAppeal: `/appeal`,
    builderReports: `/builderReports`
  },
  // askTell: {
  //   root: "/askTell"
  // },
  reports: {
    root: "/reports"
  },
  productionSheet: {
    root: "/productionSheet",
    dashboards: {
      root: `/dashboard/`,
      urlParams: {
        mainMenu: `mainMenu`,
        productionSheet: `productionSheet`,
        productionManagerReview: `productionManagerReview`,
        productionSummary: `productionSummary`,
        prodSheetReportBuilder: `prodSheetReportBuilder`
      },
      bakingProdSheet: `/bakingProdSheet`,
      othersProdSheet: `/othersProdSheet`
    }
  },
  userHome: {
    root: "/userHome"
  },
  userResources: {
    root: `/userResources`,
    resources: `/`
    // manageResources: `/manageResources`
  },
  trials: { root: "/trials" },
  manageResources: {
    root: `/manageResources`,
    manageResources: `/`
  },
  manageLms: {
    root: `/manageLms`,
    dashboards: {
      root: `/dashboard/`,
      urlParams: {
        assignedLearning: `assignedLearning`,
        assesments: `assesments`,
        reports: `reports`,
        calendar: `calendar`,
        news: `news`
      }
    },
    assignedLearning: {
      root: `/assignedLearning/`,
      urlParams: {
        createModule: `createModule`,
        categories: `categories`,
        pts: `pts`,
        assessments: `assessments`,
        certifications: `certifications`,
        feedback: `feedback`,
        summary: `summary`
      }
    },
    assessments: {
      root: `/assessments/`,
      urlParams: {}
    }
  },
  calendar: {
    root: "/calendar/",
    dashboards: {
      root: `dashboard/`,
      urlParams: {}
    }
  },
  lmsReports: {
    root: `/lmsReports`,
    urlParams: {
      main: `/`,
      details: `/details`
    }
  },
  practicalAssessment: {
    root: `/practicalAssessments`,
    practicalAssessments: `/`
  },
  manageNews: {
    root: `/manageNews`,
    dashboards: {
      root: `/dashboard/`,
      createNew: `/createNew`
    }
  },
  manageExistingUser: {
    root: `/manageExistingUsers`,
    manageExistingUsers: `/`
  },
  askTell: {
    root: `/askTell`,
    dashboards: {
      root: `/dashboard/`,
      urlParams: {
        supportTickets: `supportTickets`,
        addQuestions: `addQuestions`,
        frequentlyAskedQuestions: `frequentlyAskedQuestions`
      }
    }
  },
  reportBuilders: {
    root: `/reportBuilders`,
    lp: {
      root: `/lp`,
      overallReport: `/overallReport`
    },
    rev: {
      root: `/rev`,
      overallReport: `/overallReport`
    },
    lms: {
      root: `/lms`,
      overallReport: `/overallReport`
    },
    detailedLms: {
      root: `/detailedLms`,
      overallReport: `/overallReport`
    },
    logs: {
      root: `/logs`,
      overallReport: `/overallReport`
    },
    prodSheet: {
      root: `/prodSheet`,
      overallReport: `/overallReport`
    }
  }
};
