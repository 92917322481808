import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "@timhortons/common/src/store/rootReducer";
import rootSaga from "@timhortons/common/src/store/rootSaga";
import { IState } from "@timhortons/common/src/store/interfaces";
import { composeWithDevTools } from "redux-devtools-extension";
const sagaMiddleware = createSagaMiddleware();

export const store = (): Store<IState> => {
  let rootStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return rootStore;
};

export default store;
