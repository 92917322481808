import * as React from "react";
import { StyleSheet } from "react-native";
import { ButtonDropdown } from "@timhortons/common/src/components/molecules/buttonDropdown";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { useTranslation } from "react-i18next";

interface IProps {
  handleCallBack?: Function;
  defaultValue?: boolean;
}

export const ApproveDropdown = (props: IProps): React.ReactElement => {
  const { handleCallBack, defaultValue = false } = props;
  const { t } = useTranslation("common");
  return (
    <ButtonDropdown
      // handleApiCall={handleApiCall && handleApiCall}
      handleCallBack={handleCallBack ? handleCallBack : null}
      allItems={[
        {
          label: defaultValue === true ? t("resolved") : t("notResolved"),
          value: defaultValue === true ? t("resolved") : t("notResolved"),
          iconName: defaultValue === true ? IconNames.tick : IconNames.close,
          dropdownStyle: defaultValue === true ? styles.approveStyle : styles.rejectStyle,
          textStyle: defaultValue === true ? styles.acceptTextColor : styles.rejectTextColor
        },
        {
          label: defaultValue !== true ? t("resolved") : t("notResolved"),
          value: defaultValue !== true ? t("resolved") : t("notResolved"),
          iconName: defaultValue !== true ? IconNames.tick : IconNames.close,
          dropdownStyle: defaultValue !== true ? styles.approveStyle : styles.rejectStyle,
          textStyle: defaultValue !== true ? styles.acceptTextColor : styles.rejectTextColor
        }
      ]}
    />
  );
};

const styles = StyleSheet.create({
  approveStyle: {
    backgroundColor: colorPallete.green1
  },
  rejectStyle: {
    backgroundColor: colorPallete.variant9
  },
  rejectTextColor: {
    color: colorPallete.red6
  },
  acceptTextColor: {
    color: colorPallete.white3
  }
});
