const managersFormatter = (data) => {
  let tempData = data;
  let managersData = [];
  for (let i = 0; i < tempData.managers.length; i++) {
    if (
      tempData.managers[i].role === "Area Manager" ||
      tempData.managers[i].role === "Country Manager" ||
      tempData.managers[i].role === "Area Trainer" ||
      tempData.managers[i].role === "Ops Manager" ||
      tempData.managers[i].role === "Senior Ops Manager"
    ) {
      managersData.push(tempData.managers[i]);
    }
  }
  tempData.managers = managersData;
  return tempData;
};

export default managersFormatter;
