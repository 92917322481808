import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LOGS } from "@timhortons/common/src/redux/logs/actions/actionTypes";
import {
  IProdTempCategories,
  IUpdateActionsRequest
} from "@timhortons/common/src/modules/logs/organisms/timeTemperature/interfaces";
import {
  DailyCheckListActionsCategoriesModel,
  GetWeeklyActionQuestions
} from "@timhortons/common/src/models/logs/actionLogs/cleaning";

const getActionsSReadSubCategory = (
  data: IProdTempCategories
): IFluxStandardAction<IProdTempCategories> => {
  return {
    type: LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES.LOADING,
    payload: data
  };
};
const getActionsSReadSubCategorySuccess = (
  data: DailyCheckListActionsCategoriesModel[]
): IFluxStandardAction<DailyCheckListActionsCategoriesModel[]> => {
  return {
    type: LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES.SUCCESS,
    payload: data
  };
};
const getActionsSReadSubCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES.ERROR,
    payload: error
  };
};
const getActionsSReadSubCategoryItems = (data: {
  categoryId: string;
  label: string;
  storeCode: string;
  date: string;
  hasResolvedFilter?: Boolean;
}): IFluxStandardAction<{
  categoryId: string;
  label: string;
  storeCode: string;
  date: string;
  hasResolvedFilter?: Boolean;
}> => {
  return {
    type: LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES_ITEMS.LOADING,
    payload: data
  };
};
const getActionsSReadSubCategoryItemsSuccess = (data: {
  apiRes: GetWeeklyActionQuestions[];
  metaData: { categoryId: string };
}): IFluxStandardAction<{
  apiRes: GetWeeklyActionQuestions[];
  metaData: { categoryId: string };
}> => {
  return {
    type: LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES_ITEMS.SUCCESS,
    payload: data
  };
};
const getActionsSReadSubCategoryItemsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.StationRead.GET_ACTIONS_SUBCATEGORIES_ITEMS.ERROR,
    payload: error
  };
};
const updateStationReadActions = (
  data: IUpdateActionsRequest
): IFluxStandardAction<IUpdateActionsRequest> => {
  return {
    type: LOGS.Actions.StationRead.UPDATE_STATION_READINESS_ACTIONS.LOADING,
    payload: data
  };
};

const updateStationReadActionsSuccess = (data: {
  apiRes: GetWeeklyActionQuestions[];
  metaData: { categoryId: string; questionId: string };
}): IFluxStandardAction<{
  apiRes: GetWeeklyActionQuestions[];
  metaData: { categoryId: string; questionId: string };
}> => {
  return {
    type: LOGS.Actions.StationRead.UPDATE_STATION_READINESS_ACTIONS.SUCCESS,
    payload: data
  };
};

const updateStationReadActionsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: LOGS.Actions.StationRead.UPDATE_STATION_READINESS_ACTIONS.ERROR,
    payload: data
  };
};
const resetSRPostActionError = () => {
  return {
    type: LOGS.Actions.StationRead.RESET_ACTIONS_POST_API_ERROR
  };
};
const resetStationReadActionsEntireState = () => {
  return {
    type: LOGS.Actions.StationRead.RESET_ENTIRE_SR_ACTIONS
  };
};

export const ActionsStationReadinessActions = {
  getActionsSReadSubCategory,
  getActionsSReadSubCategorySuccess,
  getActionsSReadSubCategoryError,
  getActionsSReadSubCategoryItems,
  getActionsSReadSubCategoryItemsSuccess,
  getActionsSReadSubCategoryItemsError,
  updateStationReadActions,
  updateStationReadActionsSuccess,
  updateStationReadActionsError,
  resetSRPostActionError,
  resetStationReadActionsEntireState
};
