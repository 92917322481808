import { combineReducers } from "redux";
import {
  IManageLmsModuleState,
  manageLmsModuleReducer
} from "@timhortons/common/src/redux/manageLms/reducers/manageLmsModuleReducer";
import {
  ILmsFeedbackState,
  lmsFeedbackReducer
} from "@timhortons/common/src/redux/manageLms/reducers/lmsFeedbackReducer";
import {
  ILmsPtsState,
  lmsPtsReducer
} from "@timhortons/common/src/redux/manageLms/reducers/lmsPtsReducer";
import {
  ILmsAssessmentState,
  lmsAssessmentsReducer
} from "@timhortons/common/src/redux/manageLms/reducers/lmsAssessmentsReducer ";

export interface IManageLMSState {
  lmsModule: IManageLmsModuleState;
  lmsFeedback: ILmsFeedbackState;
  lmsPts: ILmsPtsState;
  lmsAssessments: ILmsAssessmentState;
}

export const manageLmsReducer = combineReducers({
  lmsModule: manageLmsModuleReducer,
  lmsFeedback: lmsFeedbackReducer,
  lmsPts: lmsPtsReducer,
  lmsAssessments: lmsAssessmentsReducer
});
