import React, { useEffect, useState } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { PieChart } from "react-native-svg-charts";
import { useTranslation } from "react-i18next";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import { RadioButton } from "@timhortons/common/src/components/atoms/radioButton";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import Jumbotron, { ErrorType } from "@timhortons/common/src/components/organisms/jumbotron";

interface IProps {
  series?: number[];
  labels?: string[];
  colors?: string[];
  height?: number;
  containerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  isDividerStyle?: boolean;
  percentValue?: boolean;
}
interface IGeneralDonutGraphData {
  key: number;
  title: string;
  value: number;
  svg: { fill: string };
}
const INNER_RADIUS = "15%";
const HEIGHT = 200;
export default function DonutChartMobile(props: IProps): React.ReactElement {
  const {
    series,
    labels,
    colors,
    height,
    textContainerStyle,
    containerStyle,
    isDividerStyle,
    percentValue = false
  } = props;
  const [donutData, setDonutData] = useState<IGeneralDonutGraphData[]>([]);
  const { t } = useTranslation("common");
  useEffect(() => {
    let transformedData = [];
    series.forEach((value, index) => {
      transformedData.push({
        value: value,
        title: labels[index],
        svg: {
          fill: colors[index]
        },
        key: `pie-${index}`
      });
    });
    setDonutData(transformedData);
  }, [series]);
  function radioSelect() {
    // function call
  }
  return (
    <View style={[styles.container, containerStyle]}>
      {series && series?.length === 0 ? (
        <Jumbotron type={ErrorType.NoDataFound} title={t("noDataAvailable")} />
      ) : (
        <>
          <View style={[styles.chartContainer, { height: height }]}>
            <PieChart
              style={styles.donutChart}
              data={donutData}
              padAngle={0.015}
              innerRadius={INNER_RADIUS}
            />
          </View>
          <View style={[styles.textContainer, textContainerStyle]}>
            {labels &&
              labels.map((item: string, index) => {
                return (
                  <>
                    <View style={styles.infoContainer} key={index}>
                      <Text
                        textStyle={styles.text}
                        textSize={TextSize.Medium2}
                        fontWeight={FontWeight.SemiBold}
                        testId={`${testIds.reports.donutText}-${item}`}
                      >
                        {item}
                      </Text>
                      <RadioButton
                        radioText={percentValue ? `${series[index]}%` : series[index]}
                        isChecked={false}
                        isChart={true}
                        onSelect={radioSelect}
                        chartSelectedStyle={styles.donutIcon}
                        textStyle={styles.value}
                        colors={colors[index]}
                        fontWeight={FontWeight.Regular}
                        fontSize={TextSize.Medium2}
                      />
                      {!isDividerStyle &&
                        (labels.length - 1 === index ? null : (
                          <Divider
                            orientation={Orientations.Horizontal}
                            dividerStyle={styles.divider}
                          />
                        ))}
                    </View>
                    {isDividerStyle && (
                      <View style={{ height: 60 }}>
                        {labels?.length - 1 === index ? null : (
                          <Divider
                            orientation={Orientations.Vertical}
                            dividerStyle={styles.dividerVertical}
                          />
                        )}
                      </View>
                    )}
                  </>
                );
              })}
          </View>
        </>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
  chartContainer: {
    flex: 0.6,
    justifyContent: "center"
  },
  donutChart: {
    height: HEIGHT
  },
  textContainer: {
    flex: 0.5,
    justifyContent: "center"
  },
  infoContainer: {
    alignItems: "flex-start"
  },
  text: {
    marginBottom: 3,
    color: colorPallete.black3
  },
  value: {
    fontSize: 18,
    fontWeight: FontWeight.Regular
  },
  donutIcon: {
    fontSize: 3,
    borderRadius: 50,
    borderWidth: 2
  },
  divider: {
    flex: 1,
    borderColor: colorPallete.grey7,
    marginVertical: 20,
    borderStyle: "dashed",
    borderWidth: 1,
    borderRadius: 0.1,
    width: "60%"
  },
  dividerVertical: {
    flex: 1,
    borderColor: colorPallete.grey7,
    borderStyle: "dashed",
    borderWidth: 1,
    borderRadius: 0.1
  }
});
