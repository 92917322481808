import { call, put, takeLatest } from "redux-saga/effects";
import { PRODUCTION_SHEET } from "@timhortons/common/src/redux/productionSheet/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { ManagerReviewProductionSheetActions } from "@timhortons/common/src/redux/productionSheet/actions/index";
import { ManagerReviewProductionSheetRepository } from "@timhortons/common/src/repositories/productionSheet/managerReview";
import {
  CheckAuth,
  IGetManagerReviewPayload,
  ManagerReviewModel
} from "@timhortons/common/src/models/productionSheet/managerReview";

function* getManagerReviewProductionSheetTableWorkerSaga(
  value: IFluxStandardAction<IGetManagerReviewPayload>
) {
  const { payload } = value;
  try {
    let data: ManagerReviewModel = yield call(
      ManagerReviewProductionSheetRepository.getManagerReviewTableData,
      payload
    );
    yield put(ManagerReviewProductionSheetActions.getManagerReviewProductionSheetSuccess(data));
  } catch (error) {
    Logger.error("manager-review", error, "getManagerReviewProductionSheetTableWorkerSaga");

    yield put(ManagerReviewProductionSheetActions.getManagerReviewProductionSheetError(error));
  }
}
function* updateManagerReviewItem(
  value: IFluxStandardAction<{
    idList: Array<string>;
    empId: string;
    dateTime: string;
    rowId: any;
  }>
) {
  const { payload } = value;
  try {
    yield call(ManagerReviewProductionSheetRepository.updateManagerReviewData, payload);
    let data = {
      rowId: payload.rowId
    };
    yield put(ManagerReviewProductionSheetActions.updateMRItemSuccess(data));
  } catch (error) {
    Logger.error("manager-review", error, "updateManagerReviewItem");

    yield put(ManagerReviewProductionSheetActions.updateMRItemError(error));
  }
}
function* checkAuthToStoreWorkerSaga(
  value: IFluxStandardAction<{ storeCode: number; employeeId: string }>
) {
  const { payload } = value;
  try {
    let data: CheckAuth = yield call(
      ManagerReviewProductionSheetRepository.checkAuthToStore,
      payload
    );
    yield put(ManagerReviewProductionSheetActions.checkAccessToStoreSuccess(data));
  } catch (error) {
    Logger.error("manager-review", error, "checkAuthToStoreWorkerSaga");

    yield put(ManagerReviewProductionSheetActions.checkAccessToStoreError(error));
  }
}

function* managerReviewProductionSheetWatcherSaga() {
  yield takeLatest(
    PRODUCTION_SHEET.ManagerReview.GET_MANAGER_REVIEW_PRODUCTION_SHEET.LOADING,
    getManagerReviewProductionSheetTableWorkerSaga
  );
  yield takeLatest(
    PRODUCTION_SHEET.ManagerReview.UPDATE_MANAGE_REVIEW_ITEM.LOADING,
    updateManagerReviewItem
  );
  yield takeLatest(
    PRODUCTION_SHEET.ManagerReview.CHECK_ACCESS_TO_STORE.LOADING,
    checkAuthToStoreWorkerSaga
  );
}
export default managerReviewProductionSheetWatcherSaga;
