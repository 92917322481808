import { SearchResultsResponseModel } from "./../../../models/userResources/userResources";
import { RESOURCES } from "@timhortons/common/src/redux/userResources/actions/actionTypes";
import {
  SubCategoriesModel,
  SubTopicsModel,
  ResourcesFiltersModel,
  SearchResultsModel,
  postCreateNewContentModel,
  ContentsModelList,
  MarkDownContentModel
} from "@timhortons/common/src/models/userResources/userResources";

export interface IResourcesLoading {
  contents: boolean;
  getSearchResults: boolean;
  getFilters: boolean;
  getSubCategoriesList: boolean;
  getSubCategoryTopicsList: boolean;
  getTopicContent: boolean;
  postSubCategory: boolean;
  postTopic: boolean;
  deleteSubCategory: boolean;
  postCreateNewContent: boolean;
  deleteCategory: boolean;
  imageLoad: boolean;
  publishLoading: boolean;
  publishedCategories: boolean;
  getPublishedSubcategoryLoading: boolean;
  getPublishedTopicContentLoading: boolean;
  getPublishedTopicListLoading: boolean;
  putCategoryTitleLoading: boolean;
  deleteSubCategoryLoading: boolean;
}

export interface IResourcesError {
  contents: string;
  getSearchResults: string;
  getFilters: string;
  getSubCategoriesList: string;
  getSubCategoryTopicsList: string;
  getTopicContent: string;
  postSubCategory: string;
  postTopic: string;
  deleteSubCategory: string;
  postCreateNewContent: string;
  deleteCategory: string;
  imageError: string[];
  publishError: string;
  publishedCategoriesError: string;
  getPublishedSubCategoryError: string;
  getPublishedTopicContentError: string;
  getPublishedTopicListError: string;
  putCategoryTitleError: string;
  deleteSubCategoryError: string;
}

export interface IResourcesState {
  subCategoriesList: SubCategoriesModel;
  contentsList: ContentsModelList[];
  loading: IResourcesLoading;
  error: IResourcesError;
  searchResults: Array<SearchResultsResponseModel>;
  filters: Array<ResourcesFiltersModel>;
  markDownContent: MarkDownContentModel;
  subCategoryTopicList: SubTopicsModel[];
  publishedSubCategoryTopicList: SubTopicsModel[];
  postCreateNewContent: postCreateNewContentModel;
  postSubCategory: any;
  imagesList: string;
  publishSuccess: boolean;
  publishedCategoriesList: ContentsModelList[];
  publishedSubCategoriesList: SubCategoriesModel;
  publishedTopicList: SubTopicsModel[];
  publishedTopicContent: MarkDownContentModel;
  putCategoryTitle: string;
  deleteSubCategoryData: string;
}
export const resourcesInitialState: IResourcesState = {
  contentsList: null,
  subCategoriesList: null,
  searchResults: null,
  filters: null,
  subCategoryTopicList: null,
  markDownContent: null,
  postCreateNewContent: null,
  postSubCategory: null,
  imagesList: null,
  publishSuccess: null,
  publishedCategoriesList: null,
  publishedSubCategoryTopicList: null,
  publishedSubCategoriesList: null,
  publishedTopicList: null,
  publishedTopicContent: null,
  putCategoryTitle: null,
  deleteSubCategoryData: null,
  loading: {
    contents: false,
    getSearchResults: false,
    getFilters: false,
    getSubCategoriesList: false,
    getSubCategoryTopicsList: false,
    getTopicContent: false,
    postSubCategory: false,
    deleteSubCategory: false,
    postCreateNewContent: false,
    postTopic: false,
    deleteCategory: false,
    imageLoad: false,
    publishLoading: false,
    publishedCategories: false,
    getPublishedSubcategoryLoading: false,
    getPublishedTopicContentLoading: false,
    getPublishedTopicListLoading: false,
    putCategoryTitleLoading: false,
    deleteSubCategoryLoading: false
  },
  error: {
    contents: null,
    getSearchResults: null,
    getFilters: null,
    getSubCategoriesList: null,
    getSubCategoryTopicsList: null,
    getTopicContent: null,
    postSubCategory: null,
    deleteSubCategory: null,
    postCreateNewContent: null,
    postTopic: null,
    deleteCategory: null,
    imageError: null,
    publishError: null,
    publishedCategoriesError: null,
    getPublishedSubCategoryError: null,
    getPublishedTopicContentError: null,
    getPublishedTopicListError: null,
    putCategoryTitleError: null,
    deleteSubCategoryError: null
  }
};
export const resourcesReducer = (
  state = resourcesInitialState,
  action: { payload: any; type: string }
): IResourcesState => {
  switch (action.type) {
    case RESOURCES.GET_CONTENTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, contents: true },
        error: { ...state.error, contents: null }
      };
    case RESOURCES.GET_CONTENTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, contents: false },
        error: { ...state.error, contents: null },
        contentsList: action.payload
      };
    case RESOURCES.GET_CONTENTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, contents: false },
        error: { ...state.error, contents: action.payload }
      };

    case RESOURCES.PUBLISHED_CATEGORIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, publishedCategories: true },
        error: { ...state.error, publishedCategoriesError: null }
      };
    case RESOURCES.PUBLISHED_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, publishedCategories: false },
        error: { ...state.error, publishedCategoriesError: null },
        publishedCategoriesList: action.payload
      };
    case RESOURCES.PUBLISHED_CATEGORIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, publishedCategories: false },
        error: { ...state.error, publishedCategoriesError: action.payload || "Error fetching Data" }
      };

    case RESOURCES.PUBLISH_CATEGORY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, publishLoading: true },
        error: { ...state.error, publishError: null },
        publishSuccess: false
      };

    case RESOURCES.PUBLISH_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, publishLoading: false },
        publishSuccess: true
      };

    case RESOURCES.PUBLISH_CATEGORY.ERROR:
      return {
        ...state,
        loading: { ...state.loading, publishLoading: false },
        publishSuccess: false,
        error: { ...state.error, publishError: "error" }
      };

    case RESOURCES.UPLOAD_IMAGES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, imageLoad: true }
      };
    case RESOURCES.UPLOAD_IMAGES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, imageLoad: false },
        imagesList: action.payload.imageUrl
      };
    case RESOURCES.UPLOAD_IMAGES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, imageLoad: false },
        error: { ...state.error, imageError: action.payload }
      };
    case RESOURCES.GET_SUBCATEGORIES_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoriesList: true },
        error: { ...state.error, getSubCategoriesList: null }
      };
    case RESOURCES.GET_SUBCATEGORIES_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoriesList: false },
        error: { ...state.error, getSubCategoriesList: null },
        subCategoriesList: action.payload
      };
    case RESOURCES.GET_SUBCATEGORIES_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoriesList: false },
        error: { ...state.error, getSubCategoriesList: action.payload }
      };

    case RESOURCES.PUBLISHED_SUBCATEGORIES_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getPublishedSubcategoryLoading: true },
        error: { ...state.error, getPublishedSubCategoryError: null }
      };
    case RESOURCES.PUBLISHED_SUBCATEGORIES_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPublishedSubcategoryLoading: false },
        error: { ...state.error, getPublishedSubCategoryError: null },
        publishedSubCategoriesList: action.payload
      };
    case RESOURCES.PUBLISHED_SUBCATEGORIES_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getPublishedSubcategoryLoading: false },
        error: { ...state.error, getPublishedSubCategoryError: action.payload }
      };

    case RESOURCES.GET_TOPIC_CONTENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getTopicContent: true },
        error: { ...state.error, getTopicContent: null }
      };
    case RESOURCES.GET_TOPIC_CONTENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTopicContent: false },
        error: { ...state.error, getTopicContent: null },
        markDownContent: action.payload
      };
    case RESOURCES.GET_TOPIC_CONTENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getTopicContent: false },
        error: { ...state.error, getTopicContent: action.payload }
      };

    case RESOURCES.PUBLISHED_TOPIC_CONTENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getPublishedTopicContentLoading: true },
        error: { ...state.error, getPublishedTopicContentError: null }
      };
    case RESOURCES.PUBLISHED_TOPIC_CONTENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPublishedTopicContentLoading: false },
        error: { ...state.error, getPublishedTopicContentError: null },
        publishedTopicContent: action.payload
      };
    case RESOURCES.PUBLISHED_TOPIC_CONTENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getPublishedTopicContentLoading: false },
        error: { ...state.error, getPublishedTopicContentError: action.payload }
      };

    case RESOURCES.GET_SEARCH_RESULTS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSearchResults: true },
        error: { ...state.error, getSearchResults: null }
      };
    case RESOURCES.GET_SEARCH_RESULTS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSearchResults: false },
        error: { ...state.error, getSearchResults: null },
        searchResults: action.payload
      };
    case RESOURCES.GET_SEARCH_RESULTS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getSearchResults: false },
        error: { ...state.error, getSearchResults: action.payload }
      };
    case RESOURCES.GET_FILTERS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getFilters: true },
        error: { ...state.error, getFilters: null }
      };
    case RESOURCES.GET_FILTERS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getFilters: false },
        error: { ...state.error, getFilters: null },
        filters: action.payload
      };
    case RESOURCES.GET_FILTERS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getFilters: false },
        error: { ...state.error, getFilters: action.payload }
      };
    case RESOURCES.UPDATE_FILTERS.SUCCESS:
      let updatedFilters = state.filters;
      let index = updatedFilters.findIndex((item: any) => item.id === action.payload.id);
      if (index > -1) {
        updatedFilters[index].selected = action.payload.selected;
      }
      state.filters = updatedFilters;
      return {
        ...state,
        filters: state.filters
      };
    case RESOURCES.CLEAR_FILTERS.SUCCESS:
      let data = state.filters;
      data.forEach((item: any, i: number) => {
        if (item.selected === true) {
          data[i].selected = false;
        }
      });
      return {
        ...state,
        filters: data
      };
    case RESOURCES.CLEAR_SEARCH_RESULTS.SUCCESS:
      return {
        ...state,
        searchResults: null
      };
    case RESOURCES.GET_SUBCATEGORIES_TOPICS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoryTopicsList: true },
        error: { ...state.error, getSubCategoryTopicsList: null }
      };
    case RESOURCES.GET_SUBCATEGORIES_TOPICS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoryTopicsList: false },
        error: { ...state.error, getSubCategoryTopicsList: null },
        subCategoryTopicList: action.payload
      };
    case RESOURCES.GET_SUBCATEGORIES_TOPICS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoryTopicsList: false },
        error: { ...state.error, getSubCategoryTopicsList: action.payload },
        subCategoryTopicList: null
      };

    case RESOURCES.PUBLISHED_TOPIC_LIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getPublishedTopicListLoading: true },
        error: { ...state.error, getPublishedTopicListError: null }
      };
    case RESOURCES.PUBLISHED_TOPIC_LIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSubCategoryTopicsList: false },
        error: { ...state.error, getSubCategoryTopicsList: null },
        publishedSubCategoryTopicList: action.payload
      };
    case RESOURCES.PUBLISHED_TOPIC_LIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getPublishedTopicListLoading: false },
        error: { ...state.error, getPublishedTopicListError: action.payload },
        publishedSubCategoryTopicList: null
      };

    case RESOURCES.RESET_SUBCATEGORY_DATA:
      return {
        ...state,
        subCategoriesList: null,
        subCategoryTopicList: null,
        markDownContent: null
      };

    case RESOURCES.POST_SUBCATEGORY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postSubCategory: true },
        error: { ...state.error, postSubCategory: null }
      };
    case RESOURCES.POST_SUBCATEGORY.SUCCESS:
      let newCategoryData = state.subCategoriesList;
      if (action.payload.subCategoryId) {
        let subIndex = newCategoryData.subCategories.findIndex(
          (obj: any) => obj.id === action.payload.subCategoryId
        );
        if (subIndex > -1) {
          newCategoryData.subCategories[subIndex].name = action.payload.subCategoryData.name;
          newCategoryData.subCategories[subIndex].description =
            action.payload.subCategoryData.description;
        }
      } else {
        newCategoryData.subCategories.push(action.payload.subCategoryData);
      }
      return {
        ...state,
        subCategoriesList: newCategoryData,
        postSubCategory: action.payload
      };
    case RESOURCES.POST_SUBCATEGORY.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postSubCategory: false },
        error: { ...state.error, postSubCategory: action.payload }
      };

    case RESOURCES.POST_TOPIC.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postTopic: true },
        error: { ...state.error, postTopic: null }
      };
    case RESOURCES.POST_TOPIC.SUCCESS:
      let formattedMarkDownData: any;
      let formattedSubCatTopicList: any;
      if (state.markDownContent !== null) {
        formattedMarkDownData = state.markDownContent;
        if (action.payload.topicId) {
          let topicIndex = formattedMarkDownData?.topics?.findIndex(
            (obj: any) => obj.id === action.payload.topicId
          );
          if (topicIndex > -1) {
            formattedMarkDownData.topics[topicIndex].name = action.payload.topicData.name;
            formattedMarkDownData.topics[topicIndex].content = action.payload.topicData.content;
          }
        } else
          formattedMarkDownData.topics.push({
            id: action.payload.topicData.id,
            name: action.payload.topicData.name,
            content: action.payload.topicData.content
          });
      } else {
        formattedMarkDownData.topics = [
          {
            id: action.payload.topicData.id,
            name: action.payload.topicData.name,
            content: action.payload.topicData.content
          }
        ];
      }
      if (state.subCategoryTopicList !== null) {
        formattedSubCatTopicList = [...state.subCategoryTopicList];
        if (action.payload.topicId) {
          let topicsIndex = formattedSubCatTopicList.findIndex(
            (obj: any) => obj.id === action.payload.topicId
          );
          if (topicsIndex > -1) {
            formattedSubCatTopicList[topicsIndex].name = action.payload.topicData.name;
            formattedSubCatTopicList[topicsIndex].content = action.payload.topicData.content;
          }
        } else formattedSubCatTopicList.push(action.payload.topicData);
      } else {
        formattedSubCatTopicList = [action.payload.topicData];
      }
      return {
        ...state,
        subCategoryTopicList: formattedSubCatTopicList,
        markDownContent: formattedMarkDownData
      };
    case RESOURCES.POST_TOPIC.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postTopic: false },
        error: { ...state.error, postTopic: action.payload }
      };
    case RESOURCES.TOPIC_DELETION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteSubCategory: true },
        error: { ...state.error, deleteSubCategory: null }
      };
    case RESOURCES.TOPIC_DELETION.SUCCESS:
      let topicsList = state.subCategoryTopicList;
      let markDownData = JSON.parse(JSON.stringify(state.markDownContent));
      let deleteIndex = topicsList.findIndex((obj: any) => obj.id === action.payload.topicId);
      if (deleteIndex > -1) {
        topicsList.splice(deleteIndex, 1);
        markDownData.topics.splice(deleteIndex, 1);
      }
      return {
        ...state,
        loading: { ...state.loading, deleteSubCategory: false },
        error: { ...state.error, deleteSubCategory: null },
        subCategoryTopicList: topicsList,
        markDownContent: markDownData
      };
    case RESOURCES.TOPIC_DELETION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteSubCategory: false },
        error: { ...state.error, deleteSubCategory: action.payload }
      };
    case RESOURCES.POST_CREATE_NEW_CONTENT.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postCreateNewContent: true },
        error: { ...state.error, postCreateNewContent: null }
      };
    case RESOURCES.POST_CREATE_NEW_CONTENT.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postCreateNewContent: true },
        error: { ...state.error, postCreateNewContent: null },
        postCreateNewContent: action.payload
      };
    case RESOURCES.POST_CREATE_NEW_CONTENT.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postCreateNewContent: false },
        error: { ...state.error, postCreateNewContent: action.payload }
      };

    case RESOURCES.CATEGORY_DELETION.LOADING:
      return {
        ...state,
        loading: { ...state.loading, deleteCategory: true },
        error: { ...state.error, deleteCategory: null }
      };
    case RESOURCES.CATEGORY_DELETION.SUCCESS:
      let categoryList = state.contentsList;
      let deleteCatIndex = categoryList.findIndex((obj: any) => obj.id === action.payload.catId);
      if (deleteCatIndex > -1) {
        categoryList.splice(deleteCatIndex, 1);
      }
      return {
        ...state,
        loading: { ...state.loading, deleteCategory: false },
        error: { ...state.error, deleteCategory: null },
        contentsList: categoryList
      };
    case RESOURCES.CATEGORY_DELETION.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteCategory: false },
        error: { ...state.error, deleteCategory: action.payload }
      };

    case RESOURCES.PUT_CATEGORY_TITLE.LOADING:
      return {
        ...state,
        putCategoryTitle: action.payload,
        loading: { ...state.loading, putCategoryTitleLoading: true },
        error: { ...state.error, putCategoryTitleError: null }
      };
    case RESOURCES.PUT_CATEGORY_TITLE.SUCCESS:
      return {
        ...state,
        putCategoryTitle: action.payload,
        loading: { ...state.loading, putCategoryTitleLoading: false },
        error: { ...state.error, putCategoryTitleError: null }
      };
    case RESOURCES.PUT_CATEGORY_TITLE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, putCategoryTitleLoading: false },
        error: { ...state.error, putCategoryTitleError: action.payload }
      };

    case RESOURCES.DELETE_SUB_CATEGORY_TITLE.LOADING:
      return {
        ...state,
        deleteSubCategoryData: action.payload,
        loading: { ...state.loading, deleteSubCategoryLoading: true },
        error: { ...state.error, deleteSubCategoryError: null }
      };
    case RESOURCES.DELETE_SUB_CATEGORY_TITLE.SUCCESS:
      return {
        ...state,
        deleteSubCategoryData: action.payload,
        loading: { ...state.loading, deleteSubCategoryLoading: false },
        error: { ...state.error, deleteSubCategoryError: null }
      };
    case RESOURCES.DELETE_SUB_CATEGORY_TITLE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, deleteSubCategoryLoading: false },
        error: { ...state.error, deleteSubCategoryError: action.payload }
      };
    case RESOURCES.RESET_POST_SUBCATEGORY_DATA:
      return {
        ...state,
        postSubCategory: null
      };
    default:
      return state;
  }
};
