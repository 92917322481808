export const kpis = (translate: any) => [
  {
    label: translate("clean&Maintainence"),
    availablePts: 200,
    id: 0,
    earnedPts: 0,
    errorAvailable: "",
    errorEarned: "",
    isValid: false
  },
  {
    label: translate("guest"),
    availablePts: 120,
    id: 1,
    earnedPts: 0,
    errorAvailable: "",
    errorEarned: "",
    isValid: false
  },
  {
    label: translate("sos"),
    availablePts: 180,
    id: 2,
    earnedPts: 0,
    errorAvailable: "",
    errorEarned: "",
    isValid: false
  },
  {
    label: translate("profit&Management"),
    availablePts: 160,
    id: 3,
    earnedPts: 0,
    errorAvailable: "",
    errorEarned: "",
    isValid: false
  },
  {
    label: translate("training"),
    availablePts: 70,
    id: 4,
    earnedPts: 0,
    errorAvailable: "",
    errorEarned: "",
    isValid: false
  },
  {
    label: translate("foodQuality"),
    availablePts: 270,
    id: 5,
    earnedPts: 0,
    errorAvailable: "",
    errorEarned: "",
    isValid: false
  }
];
export const avgScores = (translate: any) => [
  {
    label: translate("foodSafety"),
    name: "foodSafety",
    avgPts: "",
    id: 0,
    placeHolder: "Enter Pts",
    max: 0,
    min: -88,
    errmsg: "",
    isValid: false
  },
  {
    label: translate("brandStandards"),
    name: "brandStandards",
    avgPts: "",
    id: 1,
    placeHolder: "Enter %",
    max: 100,
    min: 0,
    errmsg: "",
    isValid: false
  }
  // {
  //   label: "Overall Score",
  //   name: "overallScores",
  //   avgPts: 0,
  //   id: 2,
  //   placeHolder: "Enter %",
  //   max: 100,
  //   min: 0,
  //   errmsg: "",
  //   isValid: false
  // }
];
export const sosDropDownItems = [
  { label: "< = 75s ", value: "00:01:15" },
  { label: "76s - 80s", value: "00:01:20" },
  { label: "81s - 85s", value: "00:01:25" },
  { label: "86s - 90s", value: "00:01:30" },
  { label: "> 90s", value: "00:01:35" }
];
export const sosVals = [
  { name: "Front Counter Time", id: 0, items: sosDropDownItems },
  { name: "Drive Through Time", id: 1, items: sosDropDownItems }
];
export type CategoryLabels =
  | "GUEST"
  | "T&D"
  | "CM"
  | "FQ"
  | "FS"
  | "ZT"
  | "SOS"
  | "PSM"
  | "SS11"
  | "SS12";
