import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import {
  LmsModulePtsDataModel,
  LmsPtsCheckListDataModel,
  LmsPtsCheckListResponseModel,
  LmsPtsTopicDataModel,
  LmsPtsTopicResponseModel
} from "@timhortons/common/src/models/manageLms/lmsPtsModels";

//* Action to get lms module's pts data
const getLmsModulePtsData = (data: {
  moduleId: string;
}): IFluxStandardAction<{ moduleId: string }> => {
  return {
    type: MANAGELMS.pts.GET_MODULE_PTS_DATA.LOADING,
    payload: data
  };
};
const getLmsModulePtsDataSuccess = (
  data: LmsModulePtsDataModel[]
): IFluxStandardAction<LmsModulePtsDataModel[]> => {
  return {
    type: MANAGELMS.pts.GET_MODULE_PTS_DATA.SUCCESS,
    payload: data
  };
};
const getLmsModulePtsDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.pts.GET_MODULE_PTS_DATA.ERROR,
    payload: error
  };
};

//* Action to post lms pts topic
const postLmsPtsTopic = (data: LmsPtsTopicDataModel): IFluxStandardAction<LmsPtsTopicDataModel> => {
  return {
    type: MANAGELMS.pts.POST_PTS_TOPIC.LOADING,
    payload: data
  };
};
const postLmsPtsTopicSuccess = (
  data: LmsPtsTopicResponseModel
): IFluxStandardAction<LmsPtsTopicResponseModel> => {
  return {
    type: MANAGELMS.pts.POST_PTS_TOPIC.SUCCESS,
    payload: data
  };
};
const postLmsPtsTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.pts.POST_PTS_TOPIC.ERROR,
    payload: error
  };
};

//* Action to update lms pts topic
const updateLmsPtsTopic = (data: {
  topicId: string;
  obj: LmsPtsTopicDataModel;
}): IFluxStandardAction<{ topicId: string; obj: LmsPtsTopicDataModel }> => {
  return {
    type: MANAGELMS.pts.UPDATE_PTS_TOPIC.LOADING,
    payload: data
  };
};
const updateLmsPtsTopicSuccess = (
  data: LmsPtsTopicResponseModel
): IFluxStandardAction<LmsPtsTopicResponseModel> => {
  return {
    type: MANAGELMS.pts.UPDATE_PTS_TOPIC.SUCCESS,
    payload: data
  };
};
const updateLmsPtsTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.pts.UPDATE_PTS_TOPIC.ERROR,
    payload: error
  };
};

//* Action to delete lms pts topic
const deleteLmsPtsTopic = (data: { topicId: string }): IFluxStandardAction<{ topicId: string }> => {
  return {
    type: MANAGELMS.pts.DELETE_PTS_TOPIC.LOADING,
    payload: data
  };
};
const deleteLmsPtsTopicSuccess = (
  data: LmsPtsTopicResponseModel
): IFluxStandardAction<LmsPtsTopicResponseModel> => {
  return {
    type: MANAGELMS.pts.DELETE_PTS_TOPIC.SUCCESS,
    payload: data
  };
};
const deleteLmsPtsTopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.pts.DELETE_PTS_TOPIC.ERROR,
    payload: error
  };
};

//* Action to post lms pts Checklist
const postLmsPtsCheckList = (
  data: LmsPtsCheckListDataModel
): IFluxStandardAction<LmsPtsCheckListDataModel> => {
  return {
    type: MANAGELMS.pts.POST_PTS_CHECKLIST.LOADING,
    payload: data
  };
};
const postLmsPtsCheckListSuccess = (
  data: LmsPtsCheckListResponseModel
): IFluxStandardAction<LmsPtsCheckListResponseModel> => {
  return {
    type: MANAGELMS.pts.POST_PTS_CHECKLIST.SUCCESS,
    payload: data
  };
};
const postLmsPtsCheckListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.pts.POST_PTS_CHECKLIST.ERROR,
    payload: error
  };
};

//* Action to update lms pts Checklist
const updateLmsPtsCheckList = (data: {
  checkListId: string;
  obj: LmsPtsCheckListDataModel;
}): IFluxStandardAction<{ checkListId: string; obj: LmsPtsCheckListDataModel }> => {
  return {
    type: MANAGELMS.pts.UPDATE_PTS_CHECKLIST.LOADING,
    payload: data
  };
};
const updateLmsPtsCheckListSuccess = (
  data: LmsPtsCheckListResponseModel
): IFluxStandardAction<LmsPtsCheckListResponseModel> => {
  return {
    type: MANAGELMS.pts.UPDATE_PTS_CHECKLIST.SUCCESS,
    payload: data
  };
};
const updateLmsPtsCheckListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.pts.UPDATE_PTS_CHECKLIST.ERROR,
    payload: error
  };
};

//* Action to delete lms pts Checklist
const deleteLmsPtsCheckList = (data: {
  checkListId: string;
  topicId: string;
}): IFluxStandardAction<{ checkListId: string; topicId: string }> => {
  return {
    type: MANAGELMS.pts.DELETE_PTS_CHECKLIST.LOADING,
    payload: data
  };
};
const deleteLmsPtsCheckListSuccess = (
  data: LmsPtsCheckListResponseModel
): IFluxStandardAction<LmsPtsCheckListResponseModel> => {
  return {
    type: MANAGELMS.pts.DELETE_PTS_CHECKLIST.SUCCESS,
    payload: data
  };
};
const deleteLmsPtsCheckListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: MANAGELMS.pts.DELETE_PTS_CHECKLIST.ERROR,
    payload: error
  };
};

const resetPtsError = () => {
  return {
    type: MANAGELMS.pts.RESET_PTS_ERROR
  };
};

export const LmsPtsActions = {
  getLmsModulePtsData,
  getLmsModulePtsDataSuccess,
  getLmsModulePtsDataError,
  postLmsPtsTopic,
  postLmsPtsTopicSuccess,
  postLmsPtsTopicError,
  updateLmsPtsTopic,
  updateLmsPtsTopicSuccess,
  updateLmsPtsTopicError,
  deleteLmsPtsTopic,
  deleteLmsPtsTopicSuccess,
  deleteLmsPtsTopicError,
  postLmsPtsCheckList,
  postLmsPtsCheckListSuccess,
  postLmsPtsCheckListError,
  updateLmsPtsCheckList,
  updateLmsPtsCheckListSuccess,
  updateLmsPtsCheckListError,
  deleteLmsPtsCheckList,
  deleteLmsPtsCheckListSuccess,
  deleteLmsPtsCheckListError,
  resetPtsError
};
