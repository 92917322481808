import React, { useState, useEffect } from "react";
import { Alert, StyleSheet, TouchableOpacity, View } from "react-native";
import DocumentPicker from "react-native-document-picker";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { FileModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { TextSize, FontWeight, Text } from "@timhortons/common/src/components/atoms/text";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";

interface IProps {
  handleFileChange?: Function;
  initialFile?: any;
  disabled?: boolean;
  reset?: number;
}

const SaiGlobalFile = (props: IProps): React.ReactElement => {
  const [attachments, setAttachment] = useState([]);
  const { handleFileChange, disabled, initialFile, reset } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalError, setModalError] = useState<string>("");

  useEffect(() => {
    if (attachments?.length === 0) {
      return;
    }
    let newArray = [...attachments];
    handleFileChange && handleFileChange(newArray);
  }, [attachments]);
  useEffect(() => {
    setAttachment([]);
  }, [reset]);

  const captureDocument = async (): Promise<void> => {
    try {
      let docs = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.pdf]
      });
      let updatedArray: FileModel[] = [];
      docs.forEach((file: any) => {
        updatedArray.push({
          id: file.name + file.size,
          signedUrl: null,
          blob: file
        });
      });

      setAttachment(updatedArray);
    } catch (e) {
      setShowErrorModal(true);
      if (DocumentPicker.isCancel(e)) {
        setModalError("File Upload Canceled");
      } else {
        setModalError("Error in uploading: " + JSON.stringify(e));
        throw e;
      }
    }
  };

  useEffect(() => {
    if (initialFile) {
      let files = [];
      files.push(initialFile);
      setAttachment(files);
    } else {
      setAttachment([]);
    }
  }, [initialFile]);

  const thumbs = attachments.map((file, index) => (
    <View style={styles.uploadContainer} key={index}>
      <View style={styles.iconContainer}>
        <Icon name={IconNames.docUpld} color={colorPallete.red1} />
      </View>
      <View>
        <Text
          textSize={TextSize.ExtraSmall}
          fontWeight={FontWeight.SemiBold}
          testId="tets"
          textStyle={styles.fileText}
        >
          {file.blob.name}
        </Text>
        <Text
          textSize={TextSize.ExtraFineSmall}
          fontWeight={FontWeight.Regular}
          testId="tets"
          textStyle={styles.fileTextInActive}
        >
          {(file.blob.size * 0.001).toFixed(2) + " Kb"}
        </Text>
      </View>
    </View>
  ));

  return (
    <View style={[styles.container, disabled && styles.disabled]}>
      <Button
        type={ButtonType.Primary}
        title="Upload"
        iconName={IconNames.docUpld}
        textStyles={styles.uploadButtonText}
        onPress={captureDocument}
        disabled={disabled}
        buttonStyles={styles.uploadButton}
      />
      {attachments?.length > 0 && (
        <>
          <Divider orientation={Orientations.Vertical} dividerStyle={styles.divider} />
          <View style={styles.fileContainer}>
            <View>{thumbs}</View>
            <TouchableOpacity onPress={() => setAttachment([])}>
              <Icon name={IconNames.trash} color={colorPallete.grey2} />
            </TouchableOpacity>
          </View>
        </>
      )}
      {showErrorModal && (
        <AlertModal
          setModalVisible={() => setShowErrorModal(!showErrorModal)}
          errorMsg={modalError}
        />
      )}
    </View>
  );
};

export default SaiGlobalFile;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 8,
    borderColor: colorPallete.red1
  },
  disabled: {
    borderColor: colorPallete.grey6
  },
  uploadButton: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
    margin: 16
  },
  uploadButtonText: {
    paddingHorizontal: 4
  },
  uploadContainer: {
    flex: 1,
    flexDirection: "row"
  },
  fileText: {
    paddingHorizontal: 12,
    color: colorPallete.black4
  },
  fileTextInActive: {
    paddingHorizontal: 12,
    color: colorPallete.grey2
  },
  iconContainer: {
    alignSelf: "center"
  },
  divider: {
    marginVertical: 10,
    backgroundColor: colorPallete.grey6
  },
  fileContainer: {
    flex: 1,
    flexDirection: "row",
    padding: 16,
    alignItems: "center",
    justifyContent: "space-between"
  }
});
