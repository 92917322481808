import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { individualActionsTableEndPoints } from "@timhortons/common/src/constants/endPoints";
class IndividualActionsTableRepository {
  getIndividualActions = async (param: {
    timeZone: string;
    pageNo: number;
    employeeId: number;
    pageSize: number;
    fromDate: string;
    toDate: string;
    keyword: string;
    sortByDate: string;
  }) => {
    let respp = await ApiClient.get(
      individualActionsTableEndPoints.getIndividualLogActionsTable(),
      param
    );
    return respp;
  };
  getIndividualLogsDashboard = async (param: {
    timeZone: string;
    pageNo: number;
    employeeId: number;
    pageSize: number;
    fromDate: string;
    toDate: string;
    keyword: string;
    sortByDate: string;
  }) => {
    let respp = await ApiClient.get(
      individualActionsTableEndPoints.getIndividualLogsDashboardTable(),
      param
    );
    return respp;
  };
}
const individualActionsTableRepository = new IndividualActionsTableRepository();
export { individualActionsTableRepository as IndividualActionsTableRepository };
