import * as React from "react";
import {
  Image as RNImage,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  ImageURISource,
  StyleSheet,
  View,
  ViewStyle
} from "react-native";

interface IProps {
  source: ImageSourcePropType;
  testId: string;
  imageStyle?: StyleProp<ImageStyle>;
  resizeMode?: ImageResizeMode;
  loadingImageSource?: ImageURISource;
  containerStyle?: StyleProp<ViewStyle>;
}

export const Image = (props: IProps): React.ReactElement => {
  const {
    source,
    imageStyle,
    testId,
    loadingImageSource,
    resizeMode = "cover",
    containerStyle
  } = props;
  return (
    <View style={[styles.container, containerStyle]}>
      <RNImage
        resizeMode={resizeMode}
        testID={testId}
        source={source}
        style={[styles.image, imageStyle]}
        loadingIndicatorSource={loadingImageSource}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //container styles
  },
  image: {
    width: 100,
    height: 100
  }
});
