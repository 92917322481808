import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { UserLMS } from "@timhortons/common/src/redux/userLms/actions/actionTypes";
import {
  LMSCategoriesListModel,
  LmsCertificateCarousel,
  LMSModuleListModel,
  LMSTopicSubtopicModel,
  LMSQuizDetailDataModel,
  FAQsModel,
  NewsListModel,
  NewsDetailModel,
  LearningPathDetailsModel,
  StatisticsModel,
  PublishModuleModel,
  CourseDetailModel,
  LMSModuleData,
  TotalDaysLeftModel,
  LeaderboardRequestModel,
  LeaderboardDataListModel,
  GetTimbitCollection,
  TimbitCollectionModel,
  EditProfileModel
} from "@timhortons/common/src/models/userLms/userLms";
import { IFAQSearchResult } from "@timhortons/common/src/redux/userLms/reducers/userLms";

export interface ILmsModuleList {
  fromAssignedLearning: boolean;
  employeeId: string;
  storeCode: number;
  roleName: string;
  language: string;
}
export interface ILearningPathParams {
  employeeId: string;
  storeCode: number;
  roleName: string;
  moduleType: string;
}

export interface IModuleDetailParams {
  publishModuleName: string;
  status: string;
  publishModuleId: string;
  employeeId: string;
}

export interface DayEvents {
  date: string;
  category: string;
  employeeId: string;
  storeCode: string;
}

export interface FCMTokenDetails {
  employeeId: number;
  token: string;
  deviceType: string;
}

// * Actions for modules list
const getLmsModulesList = (data: ILmsModuleList): IFluxStandardAction<ILmsModuleList> => {
  return {
    type: UserLMS.GET_LMS_MODULE_LIST.LOADING,
    payload: data
  };
};

const getLmsModulesListSuccess = (data: LMSModuleData): IFluxStandardAction<LMSModuleData> => {
  return {
    type: UserLMS.GET_LMS_MODULE_LIST.SUCCESS,
    payload: data
  };
};

const getLmsModulesListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_LMS_MODULE_LIST.ERROR,
    payload: error
  };
};

// * Actions for certificate carousel

const getCertificateCarousel = (): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_LMS_CERTIFICATE_CAROUSEL.LOADING
  };
};

const getCertificateCarouselSuccess = (data: any): IFluxStandardAction<LmsCertificateCarousel> => {
  return {
    type: UserLMS.GET_LMS_CERTIFICATE_CAROUSEL.SUCCESS,
    payload: data
  };
};

const getCertificateCarouselError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_LMS_CERTIFICATE_CAROUSEL.ERROR,
    payload: error
  };
};

// * Actions for getting total Earned Points
const getTotalEarnedPoints = (data: {
  employeeId: string;
}): IFluxStandardAction<{ employeeId: string }> => {
  return {
    type: UserLMS.GET_TOTAL_EARNED_POINTS.LOADING,
    payload: data
  };
};

const getTotalEarnedPointsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_TOTAL_EARNED_POINTS.SUCCESS,
    payload: data
  };
};

const getTotalEarnedPointsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_TOTAL_EARNED_POINTS.ERROR,
    payload: error
  };
};

// * Actions for categories

const getModuleCategories = (data: { employeeId: string; moduleId: string; language: string }) => {
  return {
    type: UserLMS.GET_CATEGORIES.LOADING,
    payload: data
  };
};

const getModuleCategoriesSuccess = (data: LMSCategoriesListModel) => {
  return {
    type: UserLMS.GET_CATEGORIES.SUCCESS,
    payload: data
  };
};

const getModuleCategoriesError = (error: Error) => {
  return {
    type: UserLMS.GET_CATEGORIES.ERROR,
    payload: error
  };
};

// * Actions for topics and subtopics

const getTopicSubtopic = (data: {
  categoryId: string;
  empId: string;
  language: string;
}): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_TOPICS_SUBTOPICS.LOADING,
    payload: data
  };
};

const getTopicSubtopicSuccess = (
  data: LMSTopicSubtopicModel
): IFluxStandardAction<LMSTopicSubtopicModel> => {
  return {
    type: UserLMS.GET_TOPICS_SUBTOPICS.SUCCESS,
    payload: data
  };
};

const getTopicSubtopicError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_TOPICS_SUBTOPICS.ERROR,
    payload: error
  };
};

// * Actions for total days left

const getTotalDaysLeft = (data: {
  employeeId: string;
}): IFluxStandardAction<{ employeeId: string }> => {
  return {
    type: UserLMS.GET_TOTAL_DAYS_LEFT.LOADING,
    payload: data
  };
};

const getTotalDaysLeftSuccess = (
  data: TotalDaysLeftModel
): IFluxStandardAction<TotalDaysLeftModel> => {
  return {
    type: UserLMS.GET_TOTAL_DAYS_LEFT.SUCCESS,
    payload: data
  };
};

const getTotalDaysLeftError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_TOTAL_DAYS_LEFT.ERROR,
    payload: error
  };
};

// * Actions for getting Quiz Details

const getQuizDetails = (
  data: LMSQuizDetailDataModel
): IFluxStandardAction<LMSQuizDetailDataModel> => {
  return {
    type: UserLMS.GET_QUIZ_DETAILS.LOADING,
    payload: data
  };
};

const getQuizDetailsSuccess = (data: { data: number }): IFluxStandardAction<{ data: number }> => {
  return {
    type: UserLMS.GET_QUIZ_DETAILS.SUCCESS,
    payload: data
  };
};

const getQuizDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_QUIZ_DETAILS.ERROR,
    payload: error
  };
};

// * Actions for posting Quiz response
//TODO:Create model
const postQuizResponse = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.POST_QUIZ_RESPONSE.LOADING,
    payload: data
  };
};

const postQuizResponseSuccess = (data: { data: number }): IFluxStandardAction<{ data: number }> => {
  return {
    type: UserLMS.POST_QUIZ_RESPONSE.SUCCESS,
    payload: data
  };
};

const postQuizResponseError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.POST_QUIZ_RESPONSE.ERROR,
    payload: error
  };
};

// * Action for resetting quiz details
const resetQuizDetails = (): IFluxStandardAction<any> => {
  return {
    type: UserLMS.RESET_QUIZ_DETAILS
  };
};

// * Actions for getting FAQs

const getFaqs = (): IFluxStandardAction<FAQsModel[]> => {
  return {
    type: UserLMS.GET_FAQS.LOADING
  };
};

const getFaqsSuccess = (data: FAQsModel[]): IFluxStandardAction<FAQsModel[]> => {
  return {
    type: UserLMS.GET_FAQS.SUCCESS,
    payload: data
  };
};

const getFaqsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_FAQS.ERROR,
    payload: error
  };
};

const getFaqSearchResult = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_FAQ_SEARCH_RESULT.LOADING,
    payload: data
  };
};

const getFaqSearchResultSuccess = (
  data: IFAQSearchResult,
  pageNo: number
): IFluxStandardAction<IFAQSearchResult> => {
  return {
    type: UserLMS.GET_FAQ_SEARCH_RESULT.SUCCESS,
    payload: { ...data, pageNo }
  };
};

const getFaqSearchResultError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_FAQ_SEARCH_RESULT.ERROR,
    payload: error
  };
};

const getEnquiries = (data: {
  employeeId: string;
  status: string;
}): IFluxStandardAction<{ employeeId: string; status: string }> => {
  return {
    type: UserLMS.GET_ENQUIRIES.LOADING,
    payload: data
  };
};

const getEnquiriesSuccess = (data: FAQsModel[]): IFluxStandardAction<FAQsModel[]> => {
  return {
    type: UserLMS.GET_ENQUIRIES.SUCCESS,
    payload: data
  };
};

const getEnquiriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_ENQUIRIES.ERROR,
    payload: error
  };
};

const postQuestion = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.POST_QUESTION.LOADING,
    payload: data
  };
};

const postQuestionSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.POST_QUESTION.SUCCESS,
    payload: data
  };
};

const postQuestionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.POST_QUESTION.ERROR,
    payload: error
  };
};

const getEvents = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_EVENTS.LOADING,
    payload: data
  };
};
// * Action for news
const getNewsList = (data: string): IFluxStandardAction<string> => {
  return {
    type: UserLMS.GET_NEWS_LIST.LOADING,
    payload: data
  };
};

const getEventsSuccess = (data: any): IFluxStandardAction<{ data: any }> => {
  return {
    type: UserLMS.GET_EVENTS.SUCCESS,
    payload: data
  };
};
const getNewsListSuccess = (data: NewsListModel[] | null): IFluxStandardAction<NewsListModel[]> => {
  return {
    type: UserLMS.GET_NEWS_LIST.SUCCESS,
    payload: data
  };
};

const getEventsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_EVENTS.ERROR,
    payload: error
  };
};

const getNewsListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_NEWS_LIST.ERROR,
    payload: error
  };
};

const getEventDetail = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_EVENT_DETAIL.LOADING,
    payload: data
  };
};

const getEventDetailSuccess = (data: any): IFluxStandardAction<{ data: any }> => {
  return {
    type: UserLMS.GET_EVENT_DETAIL.SUCCESS,
    payload: data
  };
};
const resetNewsList = () => {
  return {
    type: UserLMS.RESET_NEWS_LIST
  };
};

const getNewsDetails = (data: string): IFluxStandardAction<string> => {
  return {
    type: UserLMS.GET_NEWS_DETAILS.LOADING,
    payload: data
  };
};

const getEventDetailError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_EVENT_DETAIL.ERROR,
    payload: error
  };
};

const getDayEvents = (data: DayEvents): IFluxStandardAction<DayEvents> => {
  return {
    type: UserLMS.GET_DAY_EVENTS.LOADING,
    payload: data
  };
};
const getNewsDetailsSuccess = (data: NewsDetailModel): IFluxStandardAction<NewsDetailModel> => {
  return {
    type: UserLMS.GET_NEWS_DETAILS.SUCCESS,
    payload: data
  };
};

const getDayEventsSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.GET_DAY_EVENTS.SUCCESS,
    payload: data
  };
};

const getDayEventsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_DAY_EVENTS.ERROR,
    payload: error
  };
};
const getNewsDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_NEWS_DETAILS.ERROR,
    payload: error
  };
};

const resetNewsDetails = () => {
  return {
    type: UserLMS.RESET_NEWS_DETAILS
  };
};

// * Action for Statistics
const getStatistics = (data: string): IFluxStandardAction<string> => {
  return {
    type: UserLMS.GET_STATISTICS.LOADING,
    payload: data
  };
};

const getStatisticsSuccess = (data: StatisticsModel): IFluxStandardAction<StatisticsModel> => {
  return {
    type: UserLMS.GET_STATISTICS.SUCCESS,
    payload: data
  };
};

const getStatisticsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_STATISTICS.ERROR,
    payload: error
  };
};

const getSpecialModules = (data: string): IFluxStandardAction<string> => {
  return {
    type: UserLMS.GET_SPECIAL_MODULES.LOADING,
    payload: data
  };
};

const getSpecialModulesSuccess = (
  data: PublishModuleModel[]
): IFluxStandardAction<PublishModuleModel[]> => {
  return {
    type: UserLMS.GET_SPECIAL_MODULES.SUCCESS,
    payload: data
  };
};

const getSpecialModulesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_SPECIAL_MODULES.ERROR,
    payload: error
  };
};

const getModuleDetail = (data: IModuleDetailParams): IFluxStandardAction<IModuleDetailParams> => {
  return {
    type: UserLMS.GET_MODULE_DETAIL.LOADING,
    payload: data
  };
};

const getModuleDetailSuccess = (
  data: CourseDetailModel
): IFluxStandardAction<CourseDetailModel> => {
  return {
    type: UserLMS.GET_MODULE_DETAIL.SUCCESS,
    payload: data
  };
};

const getModuleDetailError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_MODULE_DETAIL.ERROR,
    payload: error
  };
};

// * Action for Learning Path Details
const getLearningPathDetails = (
  data: ILearningPathParams
): IFluxStandardAction<ILearningPathParams> => {
  return {
    type: UserLMS.GET_LEARNING_PATH_DETAILS.LOADING,
    payload: data
  };
};

const getLearningPathDetailsSuccess = (
  data: LearningPathDetailsModel[]
): IFluxStandardAction<LearningPathDetailsModel[]> => {
  return {
    type: UserLMS.GET_LEARNING_PATH_DETAILS.SUCCESS,
    payload: data
  };
};

const getLearningPathDetailsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_LEARNING_PATH_DETAILS.ERROR,
    payload: error
  };
};

const resetLearningPathDetails = () => {
  return {
    type: UserLMS.RESET_LEARNING_PATH_DETAILS
  };
};

const resetQuizError = () => {
  return {
    type: UserLMS.RESET_QUIZ_RESPONSE.LOADING
  };
};

const resetDayEvent = () => {
  return {
    type: UserLMS.RESET_DAY_EVENT
  };
};

// * Action for getting leaderboard user list
const getLeaderboardList = (
  data: LeaderboardRequestModel
): IFluxStandardAction<LeaderboardRequestModel> => {
  return {
    type: UserLMS.GET_LEADERBOARD_LIST.LOADING,
    payload: data
  };
};

const getLeaderboardListSuccess = (
  data: LeaderboardDataListModel
): IFluxStandardAction<LeaderboardDataListModel> => {
  return {
    type: UserLMS.GET_LEADERBOARD_LIST.SUCCESS,
    payload: data
  };
};

const getLeaderboardListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_LEADERBOARD_LIST.ERROR,
    payload: error
  };
};

const getTimbitCollection = (
  data: GetTimbitCollection
): IFluxStandardAction<GetTimbitCollection> => {
  return {
    type: UserLMS.GET_TIMBIT.LOADING,
    payload: data
  };
};
const getTimbitCollectionSuccess = (
  data: TimbitCollectionModel
): IFluxStandardAction<TimbitCollectionModel> => {
  return {
    type: UserLMS.GET_TIMBIT.SUCCESS,
    payload: data
  };
};
const getTimbitCollectionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_TIMBIT.ERROR,
    payload: error
  };
};

const getNotifications = (data: {
  employeeId: string;
}): IFluxStandardAction<{ employeeId: string }> => {
  return {
    type: UserLMS.GET_USER_NOTIFICATIONS.LOADING,
    payload: data
  };
};
const editUserProfile = (data: EditProfileModel): IFluxStandardAction<EditProfileModel> => {
  return {
    type: UserLMS.EDIT_PROFILE.LOADING,
    payload: data
  };
};

const getNotificationsSuccess = (data: any): IFluxStandardAction<{ employeeId: string }> => {
  return {
    type: UserLMS.GET_USER_NOTIFICATIONS.SUCCESS,
    payload: data
  };
};

const editUserProfileSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.EDIT_PROFILE.SUCCESS,
    payload: data
  };
};

const getNotificationsError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.GET_USER_NOTIFICATIONS.ERROR,
    payload: data
  };
};

const saveUserFCMToken = (data: FCMTokenDetails): IFluxStandardAction<FCMTokenDetails> => {
  return {
    type: UserLMS.SAVE_USER_NOTIFICATION.LOADING,
    payload: data
  };
};
const editUserProfileError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.EDIT_PROFILE.ERROR,
    payload: error
  };
};

const profileUpdated = (data: { isUpdated: Boolean }) => {
  return {
    type: UserLMS.PROFILE_UPDATED,
    payload: data
  };
};

const saveUserFCMTokenSuccess = (data: any): IFluxStandardAction<{ employeeId: string }> => {
  return {
    type: UserLMS.SAVE_USER_NOTIFICATION.SUCCESS,
    payload: data
  };
};

const saveUserFCMTokenError = (data: Error): IFluxStandardAction<Error> => {
  return {
    type: UserLMS.SAVE_USER_NOTIFICATION.ERROR,
    payload: data
  };
};
const resetEditProfile = () => {
  return {
    type: UserLMS.RESET_EDIT_PROFILE
  };
};

const addNotification = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.ADD_NOTIFICATION,
    payload: data
  };
};

const clearNotification = (data: any): IFluxStandardAction<any> => {
  return {
    type: UserLMS.CLEAR_NOTIFICATION,
    payload: data
  };
};

export const UserLMSAction = {
  getLmsModulesList,
  getLmsModulesListSuccess,
  getLmsModulesListError,
  getCertificateCarousel,
  getCertificateCarouselSuccess,
  getCertificateCarouselError,
  getTotalEarnedPoints,
  getTotalEarnedPointsSuccess,
  getTotalEarnedPointsError,
  getTopicSubtopic,
  getTopicSubtopicSuccess,
  getTopicSubtopicError,
  getModuleCategories,
  getModuleCategoriesSuccess,
  getModuleCategoriesError,
  getTotalDaysLeft,
  getTotalDaysLeftSuccess,
  getTotalDaysLeftError,
  getQuizDetails,
  getQuizDetailsSuccess,
  getQuizDetailsError,
  postQuizResponse,
  postQuizResponseSuccess,
  postQuizResponseError,
  resetQuizDetails,
  getFaqs,
  getFaqsSuccess,
  getFaqsError,
  getFaqSearchResult,
  getFaqSearchResultSuccess,
  getFaqSearchResultError,
  getEnquiries,
  getEnquiriesSuccess,
  getEnquiriesError,
  postQuestion,
  postQuestionSuccess,
  postQuestionError,
  getEvents,
  getEventsSuccess,
  getEventsError,
  getEventDetail,
  getEventDetailSuccess,
  getEventDetailError,
  getDayEvents,
  getDayEventsSuccess,
  getDayEventsError,
  getNewsList,
  getNewsListSuccess,
  getNewsListError,
  getNewsDetails,
  getNewsDetailsSuccess,
  getNewsDetailsError,
  resetNewsList,
  resetNewsDetails,
  getLearningPathDetails,
  getLearningPathDetailsSuccess,
  getLearningPathDetailsError,
  resetLearningPathDetails,
  getStatistics,
  getStatisticsSuccess,
  getStatisticsError,
  getSpecialModules,
  getSpecialModulesSuccess,
  getSpecialModulesError,
  getModuleDetail,
  getModuleDetailSuccess,
  getModuleDetailError,
  resetQuizError,
  resetDayEvent,
  getLeaderboardList,
  getLeaderboardListSuccess,
  getLeaderboardListError,
  getTimbitCollection,
  getTimbitCollectionSuccess,
  getTimbitCollectionError,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsError,
  saveUserFCMToken,
  saveUserFCMTokenSuccess,
  saveUserFCMTokenError,
  editUserProfile,
  editUserProfileSuccess,
  editUserProfileError,
  profileUpdated,
  resetEditProfile,
  addNotification,
  clearNotification
};
