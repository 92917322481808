import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  StyleProp,
  ViewStyle,
  StyleSheet,
  View,
  TextStyle,
  ScrollView,
  TouchableOpacity
} from "react-native";
import { useDropzone } from "react-dropzone";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Image } from "@timhortons/common/src/components/atoms/image";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { TextSize, FontWeight, Text } from "@timhortons/common/src/components/atoms/text";
import CameraAccess from "@timhortons/web/src/components/molecules/CameraAccess/index";
import { profileStyles } from "@timhortons/tablet/src/components/molecules/fileUpload/uploadBoxComponent";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { convertBase64ImgtoBlob } from "@timhortons/web/src/utils/fo2Helpers";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { FileModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { imgPlaceholder } from "@timhortons/common/src/assets/images/imageAssets";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import ProgressiveImage from "@timhortons/common/src/components/molecules/placeholderImage";
interface IProps {
  header?: string;
  containerStyle?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<TextStyle>;
  webOnDropAccepted?: (files: any) => void;
  multipleUpload: boolean;
  hideIcon?: boolean;
  uploadResetter?: boolean;
  acceptFileType?: string;
  children?: React.ReactNode;
  commentBoxWidth?: number;
  initialFiles?: FileModel[];
  handleFileChange?: Function;
  cancelFiles?: boolean;
  setCancelFiles?: Function;
  isProfile?: boolean;
  isEdit?: Boolean;
  deletedImages?: boolean;
}
export const UploadBox: React.FC<IProps> = (props: IProps) => {
  const {
    multipleUpload,
    containerStyle,
    headerStyle,
    header,
    hideIcon = true,
    acceptFileType,
    webOnDropAccepted,
    uploadResetter,
    children,
    commentBoxWidth = 600,
    handleFileChange,
    initialFiles,
    cancelFiles,
    setCancelFiles,
    isProfile = false,
    isEdit = false,
    deletedImages = false
  } = props;
  const size = isProfile ? 10 : 18;
  const [files, setFiles] = useState([]);
  const [allfiles, setAllFiles] = useState([]);
  const [showAttachments, setShowAttachments] = useState(false);
  const [cameraStatus, setCameraStatus] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalError, setModalError] = useState(null);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.map((data: any) => {
      let errors: any[] = [];
      data.errors.map((err: any) => {
        errors.push(`Cannot upload ${data.file.name}. ${err.message}`);
      });
      setModalError(errors);
      setShowErrorModal(true);
    });
    let newArrays: FileModel[] = [];
    acceptedFiles.forEach((file: File) => {
      newArrays.push({
        id: file.name + file.size,
        signedUrl: URL.createObjectURL(file),
        blob: file
      });
    });
    setFiles(newArrays);
    {
      isProfile && handleFileChange && handleFileChange(newArrays);
    }
  }, []);

  useEffect(() => {
    if (files?.length === 0) {
      return;
    }
    let newArray = [...allfiles];
    newArray.push(...files);
    setAllFiles(newArray);
    {
      !isProfile && handleFileChange && handleFileChange(newArray);
    }
  }, [files]);

  useEffect(() => {
    if (allfiles?.length > 0) {
      setAllFiles([]);
      setFiles([]);
    }
  }, [deletedImages]);

  useEffect(() => {
    if (initialFiles) {
      setAllFiles([]);
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  useEffect(() => {
    if (cancelFiles) {
      setAllFiles([]);
      setCancelFiles(false);
    }
  }, [cancelFiles]);

  useEffect(() => {
    if (uploadResetter) {
      setAllFiles([]);
    }
  }, [uploadResetter]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileType,
    multiple: multipleUpload,
    onDropAccepted: webOnDropAccepted,
    maxSize: 5120000
  });

  const handleDelete = (file?: any): void => {
    if (isProfile) {
      setFiles([]);
      handleFileChange && handleFileChange([]);
      setShowAttachments(false);
    } else {
      var finalFiles = [...allfiles];
      let index = finalFiles.findIndex((el) => el.id === file.id);
      if (index > -1) {
        finalFiles.splice(index, 1);
        setAllFiles(finalFiles);
        URL.revokeObjectURL(file.preview);
        webOnDropAccepted(finalFiles);
        handleFileChange && handleFileChange(finalFiles);
      }
    }
  };

  const thumbs = allfiles.map((file: any, index) => (
    <View style={commentStyles(commentBoxWidth).elementsContainer} key={index}>
      <Image
        resizeMode="stretch"
        testId={file.id}
        imageStyle={commentStyles(commentBoxWidth).imgElement}
        source={{
          uri: file.signedUrl
        }}
      />
      <Icon
        name={IconNames.crossCircle}
        onPress={() => handleDelete(file)}
        customStyle={commentStyles(commentBoxWidth).closeElement}
        size={16}
      />
    </View>
  ));

  const getFilesFromCamera = async (image: any) => {
    const url = await convertBase64ImgtoBlob(image);
    setFiles([
      {
        id: image,
        signedUrl: URL.createObjectURL(url),
        blob: new Blob([url], { type: "image/jpeg" })
      }
    ]);
  };

  const handleCallBack = (): void => {
    setCameraStatus(cameraStatus);
  };

  function getCameraProps() {
    if (cameraStatus) {
      return <input {...getInputProps()} />;
    } else {
      return null;
    }
  }

  const getCommonUploadBoxUI = () => {
    return (
      <div {...getRootProps()}>
        {!showAttachments ? (
          <TouchableOpacity
            onPress={() => setShowAttachments(!showAttachments)}
            style={
              (isProfile && profileStyles.editContainer) || [
                commentStyles(commentBoxWidth).uploadStyles,
                commentStyles(commentBoxWidth).boxColor
              ]
            }
          >
            <Icon
              name={(isProfile && IconNames.edit) || IconNames.attachment}
              customStyle={
                (isProfile && profileStyles.editIcon) || commentStyles(commentBoxWidth).iconColor
              }
              size={size}
            />
            {!isProfile && (
              <Text
                fontWeight={FontWeight.Regular}
                testId={testIds.uploadText}
                textSize={TextSize.ExtraSmall}
                textStyle={[commentStyles(commentBoxWidth).header, headerStyle]}
              >
                {header}
              </Text>
            )}
          </TouchableOpacity>
        ) : (
          <View
            style={
              (isProfile && profileStyles.profileUploadStyles) ||
              commentStyles(commentBoxWidth).uploadStyles
            }
          >
            <Icon
              name={IconNames.crossCircle}
              customStyle={[
                commentStyles(commentBoxWidth).iconColor,
                commentStyles(commentBoxWidth).iconMarginStyle
              ]}
              onPress={() => setShowAttachments(!showAttachments)}
              size={size}
            />
            {getCameraProps()}
            <Icon
              name={IconNames.photoUpld}
              customStyle={[
                commentStyles(commentBoxWidth).iconColor,
                commentStyles(commentBoxWidth).iconMarginStyle
              ]}
              size={size}
            />
            <CameraAccess sendFiles={getFilesFromCamera} parentCallBack={handleCallBack}>
              <Icon
                name={IconNames.camera}
                customStyle={commentStyles(commentBoxWidth).iconColor}
                size={size}
              />
            </CameraAccess>
          </View>
        )}
      </div>
    );
  };
  const pImgSrc = useMemo(() => {
    if (files?.length > 0 && files[0].signedUrl) {
      return { uri: files[0].signedUrl };
    }
    return null;
  }, [files[0]?.signedUrl]);
  return (
    <>
      {isProfile ? (
        <View style={profileStyles.container}>
          <ProgressiveImage
            thumbnailSource={imgPlaceholder}
            source={pImgSrc}
            testId="Profile_Image"
            imageStyle={profileStyles.profileImageStyle}
            resetErrImg
          />
          {!isEdit &&
            ((files?.length > 0 && (
              <View style={profileStyles.buttonContainer}>
                <TouchableOpacity
                  onPress={() => handleDelete()}
                  style={profileStyles.editContainer}
                >
                  <Icon name={IconNames.close} customStyle={profileStyles.iconColor} size={10} />
                </TouchableOpacity>
              </View>
            )) || <View style={profileStyles.buttonContainer}>{getCommonUploadBoxUI()}</View>)}
        </View>
      ) : (
        <>
          <ScrollView horizontal={true} style={commentStyles(commentBoxWidth).scrollContainer}>
            {allfiles.length !== 0 && (
              <View style={commentStyles(commentBoxWidth).fileContainer}>{thumbs}</View>
            )}
          </ScrollView>
          <View>
            <View style={[commentStyles(commentBoxWidth).container, containerStyle]}>
              {hideIcon && getCommonUploadBoxUI()}
              <View style={commentStyles(commentBoxWidth).childrenStyles}>{children}</View>
            </View>
          </View>
        </>
      )}
      {showErrorModal && (
        <AlertModal
          setModalVisible={() => setShowErrorModal(!showErrorModal)}
          errorMsg={modalError}
        />
      )}
    </>
  );
};
export const commentStyles = (commentBoxWidth: number) =>
  StyleSheet.create({
    container: {
      flexDirection: "row"
    },
    uploadStyles: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: 12,
      paddingVertical: 8,
      borderRadius: 40,
      backgroundColor: colorPallete.variant1
    },
    childrenStyles: {
      flex: 1
    },
    scrollContainer: {
      marginBottom: 32,
      maxWidth: commentBoxWidth
    },
    header: {
      color: colorPallete.grey9,
      marginHorizontal: 12,
      paddingTop: 2
    },
    fileContainer: {
      flexDirection: "row"
    },
    elementsContainer: {
      flexDirection: "row",
      margin: 8
    },
    imgElement: {
      borderRadius: 8
    },
    closeElement: {
      width: 24,
      height: 24,
      marginTop: 5,
      ...I18nService.select({
        rtl: { marginStart: -20 },
        ltr: { marginStart: -20 }
      }),
      color: colorPallete.white3
    },
    iconColor: {
      color: colorPallete.red2
    },
    iconMarginStyle: {
      ...I18nService.select({
        rtl: { marginEnd: 12 },
        ltr: { marginEnd: 12 }
      })
    },
    boxColor: {
      backgroundColor: colorPallete.white2
    }
  });
