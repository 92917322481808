import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import { LmsFeedbackResponseModel } from "@timhortons/common/src/models/manageLms/lmsFeedback";
import { IFeedbackTexts } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/feedback/organisms/feedbackArrayConstants";

export interface ILmsFeedbackLoading {
  getLmsFeedback: boolean;
  postLmsFeedback: boolean;
  updateLmsFeedback: boolean;
}
export interface ILmsFeedbackError {
  getLmsFeedback: string;
  postLmsFeedback: string;
  updateLmsFeedback: string;
}
export interface ILmsFeedbackState {
  loading: ILmsFeedbackLoading;
  error: ILmsFeedbackError;
  getLmsFeedback: IFeedbackTexts[];
  postLmsFeedback: LmsFeedbackResponseModel;
  updateLmsFeedback: LmsFeedbackResponseModel;
}

export const lmsModuleInitialState: ILmsFeedbackState = {
  error: {
    getLmsFeedback: null,
    postLmsFeedback: null,
    updateLmsFeedback: null
  },
  loading: {
    getLmsFeedback: false,
    postLmsFeedback: false,
    updateLmsFeedback: false
  },
  getLmsFeedback: [],
  postLmsFeedback: null,
  updateLmsFeedback: null
};

export const lmsFeedbackReducer = (
  state = lmsModuleInitialState,
  action: { payload: any; type: string }
): ILmsFeedbackState => {
  switch (action.type) {
    //* reducer for get feedback by module id
    case MANAGELMS.feedback.GET_MODULE_FEEDBACK_BY_ID.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLmsFeedback: true },
        error: { ...state.error, getLmsFeedback: null }
      };
    case MANAGELMS.feedback.GET_MODULE_FEEDBACK_BY_ID.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLmsFeedback: false },
        error: { ...state.error, getLmsFeedback: null },
        getLmsFeedback: action.payload
      };
    case MANAGELMS.feedback.GET_MODULE_FEEDBACK_BY_ID.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLmsFeedback: false },
        error: { ...state.error, getLmsFeedback: action.payload }
      };

    //* reducer for post feedback
    case MANAGELMS.feedback.POST_MODULE_FEEDBACK.LOADING:
      return {
        ...state,
        loading: { ...state.loading, postLmsFeedback: true },
        error: { ...state.error, postLmsFeedback: null }
      };
    case MANAGELMS.feedback.POST_MODULE_FEEDBACK.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, postLmsFeedback: false },
        error: { ...state.error, postLmsFeedback: null },
        postLmsFeedback: action.payload,
        getLmsFeedback: action.payload
      };
    case MANAGELMS.feedback.POST_MODULE_FEEDBACK.ERROR:
      return {
        ...state,
        loading: { ...state.loading, postLmsFeedback: false },
        error: { ...state.error, postLmsFeedback: action.payload }
      };

    //* reducer for update feedback
    case MANAGELMS.feedback.UPDATE_FEEDBACK.LOADING:
      return {
        ...state,
        loading: { ...state.loading, updateLmsFeedback: true },
        error: { ...state.error, updateLmsFeedback: null }
      };
    case MANAGELMS.feedback.UPDATE_FEEDBACK.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateLmsFeedback: false },
        error: { ...state.error, updateLmsFeedback: null },
        updateLmsFeedback: action.payload
      };
    case MANAGELMS.feedback.UPDATE_FEEDBACK.ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateLmsFeedback: false },
        error: { ...state.error, updateLmsFeedback: action.payload }
      };
    case MANAGELMS.feedback.RESET_FEEDBACK_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          getLmsFeedback: null,
          postLmsFeedback: null,
          updateLmsFeedback: null
        }
      };

    default:
      return state;
  }
};
