import React, { ReactElement, useEffect, useState } from "react";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Card from "@timhortons/common/src/components/atoms/card";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Button, { ButtonType, IconPositions } from "@timhortons/common/src/components/atoms/button";
import { Orientations } from "@timhortons/common/src/components/atoms/divider";
import CheckboxGroup, {
  ICheckBoxOption
} from "@timhortons/common/src/components/molecules/checkBoxGroup";
import CommentBox, {
  styles as CommentStyles
} from "@timhortons/common/src/modules/rev/organisms/commentBox";
import { AppealTable } from "@timhortons/common/src/modules/rev/components/dashboards/appeals";
import {
  IndividualAppealItemModel,
  OpportunityNewModel
} from "@timhortons/common/src/models/rev/appeals";
import { CommentDataModel } from "@timhortons/common/src/models/rev/selfCalibration";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { textBlock, CardHeader, ClosedTableCommon } from "./cardHeader";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import ImgMapper from "@timhortons/common/src/components/organisms/imgMapper";
import { Roles } from "@timhortons/common/src/constants/roles";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
interface IProps {
  appealCardData?: IndividualAppealItemModel;
  setAppealDecision: Function; // Need to change the flow of area manager appeals
  translate: any;
  currentTable: AppealTable;
  toggleIcon?: boolean;
  key: number;
  isIndividualLogin: boolean;
  userDetails?: IUserDetails;
}

export enum AppealStatus {
  approved = "APPROVED",
  // approvedWithReverification = "APPROVED_REVERIFICATION",
  disapproved = "DISAPPROVED",
  inProcess = "IN_PROCESS"
}

export default function AreaManagerAppealCard(props: IProps): ReactElement {
  const {
    appealCardData,
    translate,
    currentTable,
    isIndividualLogin,
    setAppealDecision,
    userDetails
  } = props;

  const [disableAppealStatus, setDisableAppealStatus] = useState(false);
  const [checkboxData, setCheckboxData] = useState<Array<ICheckBoxOption>>([]);
  const [currentAppealStatus, setCurrentAppealStatus] = useState<string>();
  useEffect(() => {
    let dataList: Array<ICheckBoxOption> = [];
    if (appealCardData && appealCardData.appealQuestion) {
      dataList =
        appealCardData.appealQuestion &&
        appealCardData.appealQuestion.map((item: OpportunityNewModel, index) => {
          return {
            name: item.question,
            key: index,
            selected: item.isTicked,
            id: item.id
          };
        });
    }
    setCheckboxData(dataList);
  }, [appealCardData]);

  const handleCheckboxCallBack = (checkboxItems: any): void => {
    let tempCheckboxData = checkboxData;
    let index = checkboxData.findIndex((val) => val.key === checkboxItems.key);
    tempCheckboxData[index] = checkboxItems;
    setCheckboxData(tempCheckboxData);
    setSelectedValues((prevState) => ({
      ...selectedValues,
      opportunityData: checkboxItems
    }));

    let checkCheckboxStatus = false;
    checkboxData.forEach((val) => {
      if (val.selected === true) checkCheckboxStatus = true;
    });
    if (!checkCheckboxStatus) {
      setDisableAppealStatus(true);
    } else {
      setDisableAppealStatus(false);
    }
  };
  const [selectedValues, setSelectedValues] = useState({
    dropdownValue: "",
    opportunityData: [],
    disapproveStatus: false,
    auditedBy: userDetails.empId
  });

  const setDisapproveButton = async () => {
    setCurrentAppealStatus(AppealStatus.disapproved);
    setSelectedValues((prevState) => ({
      ...prevState,
      disapproveStatus: !selectedValues.disapproveStatus
    }));
    setShowComment(true);
  };

  useEffect(() => {
    if (currentAppealStatus) {
      decisionButtonClick();
    }
  }, [currentAppealStatus]);

  const setApproveButton = () => {
    setCurrentAppealStatus(AppealStatus.approved);
    setSelectedValues((prevState) => ({
      ...prevState,
      dropdownValue: AppealStatus.approved
    }));
    setShowComment(true);
  };

  const [commentBoxWidth, setCommentBoxWidth] = useState<number>(0);

  const onPageLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;
    setCommentBoxWidth(width);
  };

  const [showComment, setShowComment] = useState(false);

  const commentBoxSaveClick = (commentFormData: CommentDataModel) => {
    let postData = new FormData();
    postData.append("appealItemId", JSON.stringify(appealCardData.appealItemId));
    let questions: Array<number> = [];
    checkboxData &&
      checkboxData.map((value) => {
        if (value.selected === true) questions.push(value.id);
      });
    let postfiles: Blob[] =
      (commentFormData &&
        commentFormData.files &&
        commentFormData.files.length > 0 &&
        commentFormData.files.map((img) => img.blob)) ||
      [];
    postData.append("questions", JSON.stringify(questions));
    postData.append("appealItemStatus", currentAppealStatus);
    postData.append("employeeId", userDetails.empId);
    postfiles &&
      postfiles.map((img, index) => {
        postData.append("files", img, `img-${index}.jpeg`);
      });
    commentFormData &&
      commentFormData.comment &&
      postData.append("commentByAuditor", commentFormData.comment);
    if (postData !== undefined) {
      setAppealDecision(postData);
    }
  };
  const decisionButtonClick = () => {
    let postData = new FormData();
    postData.append("appealItemId", JSON.stringify(appealCardData.appealItemId));
    let questions: Array<number> = [];
    checkboxData &&
      checkboxData.map((value) => {
        if (value.selected === true) questions.push(value.id);
      });

    postData.append("questions", JSON.stringify(questions));
    postData.append("appealItemStatus", currentAppealStatus);
    postData.append("employeeId", userDetails.empId);

    postData.append("commentByAuditor", " ");
    if (postData !== undefined) {
      setAppealDecision(postData);
    }
  };
  return (
    <>
      <View style={mainStyles(currentTable, appealCardData)}>
        <Card containerStyle={commonappealStoreStyles.cardContainerStyle} disabled={true}>
          <CardHeader translate={translate} />

          <Card.Body bodyStyle={commonappealStoreStyles.cardBodyContainerStyle}>
            <AppealCardHeader appealCardData={appealCardData} />
            {(userDetails?.roles?.includes(Roles.SuperAdmin) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevLead) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevManager) ||
              userDetails?.roles?.includes(Roles.InternalAuditor)) &&
            currentTable === AppealTable.inProcess ? (
              <View style={{ marginTop: 20 }}>
                <CheckboxGroup
                  showId={true}
                  checkBoxOptions={checkboxData}
                  // checkBoxContainerStyle={{ marginBottom: 16 }}
                  parentCallBack={handleCheckboxCallBack}
                  textStyle={styles.checkboxText}
                  orientation={Orientations.Vertical}
                  fontWeight={FontWeight.Regular}
                  fontSize={TextSize.Regular}
                  iconSize={16}
                  // containerStyle={{
                  //   flex: 1
                  // }}
                  iconStyle={PlatformUtils.isWeb() ? { marginTop: 0 } : { marginTop: 4 }}
                />
              </View>
            ) : (
              isIndividualLogin && (
                <View style={{ paddingVertical: 20 }}>
                  {appealCardData.appealQuestion &&
                    appealCardData.appealQuestion.map((value, index: number) => (
                      <View key={index} style={{ flexDirection: "row" }}>
                        <Text
                          // key={index}
                          textSize={TextSize.ExtraRegular}
                          fontWeight={FontWeight.Regular}
                          testId={testIds.actionPlan.actionPlanCriteria}
                          textStyle={[styles.opportunityListTextNumber, styles.opportunityListText]}
                        >
                          {index + 1}.
                        </Text>
                        <Text
                          key={value.id}
                          textSize={TextSize.ExtraRegular}
                          fontWeight={FontWeight.Regular}
                          testId={testIds.actionPlan.actionPlanCriteria}
                          textStyle={styles.opportunityListText}
                        >
                          {value.question}
                        </Text>
                      </View>
                    ))}
                </View>
              )
            )}
            <View style={{ flexDirection: "row" }}>
              {textBlock(
                TextSize.ExtraSmall,
                FontWeight.Regular,
                "appeal",
                userDetails?.roles?.includes(Roles.SuperAdmin) ||
                  userDetails?.roles?.includes(Roles.TrainingAndDevLead) ||
                  userDetails?.roles?.includes(Roles.TrainingAndDevManager) ||
                  userDetails?.roles?.includes(Roles.InternalAuditor)
                  ? styles.cardBodyTextStyle
                  : [styles.cardBodyTextStyle, commonappealStoreStyles.storeAccessCardBody],
                appealCardData.appealerResponse.comment
              )}
            </View>
            <View style={styles.flexContainer}>
              {appealCardData?.appealerResponse?.assets?.length > 0 && (
                <ImgMapper items={appealCardData.appealerResponse.assets.map((i) => i.signedUrl)} />
              )}
            </View>
            {(userDetails?.roles?.includes(Roles.SuperAdmin) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevLead) ||
              userDetails?.roles?.includes(Roles.TrainingAndDevManager) ||
              userDetails?.roles?.includes(Roles.InternalAuditor)) &&
              currentTable === AppealTable.inProcess && (
                <>
                  <View style={styles.dropdownContainer}>
                    <View
                      style={{
                        ...I18nService.select({
                          rtl: { marginEnd: 24 },
                          ltr: { marginEnd: 24 }
                        })
                      }}
                    >
                      <Button
                        type={ButtonType.Primary}
                        onPress={setApproveButton}
                        buttonStyles={
                          currentAppealStatus === AppealStatus.approved
                            ? [
                                styles.disapproveButton,
                                commonappealStoreStyles.selectedDropdownItem
                              ]
                            : [styles.disapproveButton, styles.unselectedApproveButton]
                        }
                        iconName={IconNames.tick}
                        iconPosition={IconPositions.Left}
                        iconStyles={
                          currentAppealStatus === AppealStatus.approved
                            ? [styles.unselectButton]
                            : {
                                color: colorPallete.green1,
                                ...I18nService.select({
                                  rtl: { marginEnd: 10 },
                                  ltr: { marginEnd: 10 }
                                })
                              }
                        }
                        disabled={disableAppealStatus}
                        iconSize={12}
                      >
                        <Text
                          testId="approve"
                          textSize={TextSize.Regular}
                          fontWeight={FontWeight.Bold}
                          textStyle={
                            currentAppealStatus === AppealStatus.approved
                              ? commonappealStoreStyles.unselectedDropdownItem
                              : { color: colorPallete.green1 }
                          }
                        >
                          {translate("approve")}
                        </Text>
                      </Button>
                    </View>
                    <Button
                      type={ButtonType.Primary}
                      onPress={setDisapproveButton}
                      buttonStyles={
                        currentAppealStatus === AppealStatus.disapproved
                          ? [styles.disapproveButton, styles.selectDisapproveButton]
                          : [styles.disapproveButton, styles.unselectDisapproveButton]
                      }
                      iconName={IconNames.close}
                      iconPosition={IconPositions.Left}
                      iconStyles={
                        currentAppealStatus === AppealStatus.disapproved
                          ? [styles.unselectButton]
                          : styles.buttonIconStyles
                      }
                      disabled={disableAppealStatus}
                      iconSize={12}
                    >
                      <Text
                        testId="disapprove"
                        textSize={TextSize.Regular}
                        fontWeight={FontWeight.Bold}
                        textStyle={
                          currentAppealStatus === AppealStatus.disapproved
                            ? commonappealStoreStyles.unselectedDropdownItem
                            : styles.textRejectStyle
                        }
                      >
                        {translate("disapprove")}
                      </Text>
                    </Button>
                  </View>
                </>
              )}
          </Card.Body>
        </Card>
        {showComment && (
          <CommentBox
            translate={translate}
            saveClickCall={commentBoxSaveClick}
            acceptFileType={"image/jpeg, image/png"}
            editIcon={false}
            editCommentContainerStyle={styles.editCommentContainerStyle}
            iconStyle={{ backgroundColor: colorPallete.white1 }}
          />
        )}

        {currentTable === AppealTable.closedAppeal ? (
          <View style={commonappealStoreStyles.cardFooterContainerStyle}>
            <ClosedTableCommon
              comment={appealCardData.auditorResponse.comment}
              status={appealCardData.appealItemStatus}
              translate={translate}
              textStyle={
                ((appealCardData.appealItemStatus === "APPROVED" ||
                  appealCardData.appealItemStatus === "APPROVED_REVERIFICATION") && [
                  commonappealStoreStyles.decisionTextStyle,
                  commonappealStoreStyles.approveDecisionTextStyle
                ]) || [
                  commonappealStoreStyles.decisionTextStyle,
                  commonappealStoreStyles.rejectDecisionTextStyle
                ]
              }
            />
            {isIndividualLogin && (
              <View style={CommentStyles.imageContainer} onLayout={onPageLayout}>
                <View style={{ maxWidth: commentBoxWidth - 50, flexDirection: "row" }}>
                  {appealCardData?.auditorResponse?.assets?.length > 0 && (
                    <ImgMapper
                      items={appealCardData.auditorResponse.assets.map((i) => i.signedUrl)}
                    />
                  )}
                </View>
              </View>
            )}
          </View>
        ) : null}
      </View>
    </>
  );
}
export const mainStyles = (
  currentTable: AppealTable,
  appealCardData: IndividualAppealItemModel
) => {
  return currentTable === AppealTable.closedAppeal
    ? appealCardData.appealItemStatus === "APPROVED" ||
      appealCardData.appealItemStatus === "APPROVED_REVERIFICATION"
      ? [commonappealStoreStyles.container, commonappealStoreStyles.closedApproveContainer]
      : [commonappealStoreStyles.container, commonappealStoreStyles.closedRejectContainer]
    : commonappealStoreStyles.container;
};
export const AppealCardHeader = (props: any) => {
  return (
    <View style={commonappealStoreStyles.cardTitleContainerStyle}>
      {textBlock(
        TextSize.ExtraRegular,
        FontWeight.SemiBold,
        "appeal",
        commonappealStoreStyles.serialNumberStyle,
        props.appealCardData.revSubCategory.subcategoryLabel
      )}
      {textBlock(
        TextSize.ExtraRegular,
        FontWeight.SemiBold,
        "appeal",
        commonappealStoreStyles.subCategoryNameStyle,
        props.appealCardData.revSubCategory.subcategoryName
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  cardBodyTextStyle: {
    color: colorPallete.grey1,
    ...I18nService.select({
      rtl: { marginStart: 34 },
      ltr: { marginStart: 34 }
    })
  },
  ///
  checkboxText: {
    flex: 1,
    flexWrap: "wrap",
    alignItems: "center",
    color: colorPallete.grey1,
    ...I18nService.select({
      rtl: { marginStart: 10 },
      ltr: { marginStart: 10 }
    })
  },
  appealerAssetContainer: {
    marginVertical: 24,
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    })
  },
  attachments: {
    width: 120,
    height: 120,
    borderRadius: 8
  },
  // checkboxText: {
  //   flex: 1,
  //   color: colorPallete.grey1,
  //   ...I18nService.select({
  //     rtl: { marginStart: 10 },
  //     ltr: { marginStart: 10 }
  //   })
  // },
  bottomCheckbox: {
    alignItems: "flex-start"
  },
  disapproveButton: {
    paddingHorizontal: 36,
    borderRadius: 8,
    paddingVertical: 12
  },
  selectDisapproveButton: {
    backgroundColor: colorPallete.red6
  },
  unselectDisapproveButton: {
    backgroundColor: colorPallete.variant9
  },
  unselectedApproveButton: {
    backgroundColor: colorPallete.variant8
  },
  buttonIconStyles: {
    color: colorPallete.red6,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
  },
  textRejectStyle: {
    color: colorPallete.red6
  },
  dropdownContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginHorizontal: 34,
    marginTop: 24
  },

  unselectButton: {
    color: colorPallete.white3,
    ...I18nService.select({
      rtl: { marginEnd: 10 },
      ltr: { marginEnd: 10 }
    })
  },
  opportunityListText: {
    color: colorPallete.grey1,
    marginBottom: 16
  },
  opportunityListTextNumber: {
    ...I18nService.select({
      rtl: { paddingStart: 7 },
      ltr: { paddingEnd: 7 }
    })
  },
  editCommentContainerStyle: {
    padding: -10,
    backgroundColor: colorPallete.white1
  },
  flexContainer: {
    flexDirection: "row",
    ...I18nService.select({
      rtl: { marginStart: 35 },
      ltr: { marginStart: 35 }
    })
  }
});

export const commonappealStoreStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colorPallete.white2,
    borderRadius: 8
  },
  closedRejectContainer: {
    borderLeftWidth: 6,
    borderColor: colorPallete.red3
  },
  closedApproveContainer: {
    borderLeftWidth: 6,

    borderColor: colorPallete.green1
  },
  cardContainerStyle: {
    padding: 24,
    shadowOffset: { width: 0, height: 0 },
    elevation: 0
  },
  cardHeaderContainerStyle: {
    flexDirection: "row",
    paddingBottom: 20
  },
  cardTitleContainerStyle: {
    flexDirection: "row",
    marginBottom: 16
  },
  cardHeaderIconStyle: {
    color: colorPallete.red2
  },
  cardHeaderTextStyle: {
    marginHorizontal: 10,
    color: colorPallete.grey2
  },
  cardBodyContainerStyle: {
    // marginTop: 16
  },
  serialNumberStyle: {
    color: colorPallete.red1
  },
  subCategoryNameStyle: {
    marginHorizontal: 12,
    color: colorPallete.black3
  },
  storeAccessCardBody: {
    marginTop: 12
  },
  cardFooterContainerStyle: {
    paddingHorizontal: 24,
    paddingBottom: 24
  },

  decisionTextStyle: {
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginTop: -4
  },
  approveDecisionTextStyle: {
    color: colorPallete.green1,
    backgroundColor: colorPallete.variant8
  },
  rejectDecisionTextStyle: {
    color: colorPallete.red4,
    backgroundColor: colorPallete.variant4
  },
  selectedDropdownItem: {
    backgroundColor: colorPallete.green1
  },
  unselectedDropdownItem: {
    color: colorPallete.white3
  }
});
