import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { MANAGELMS } from "@timhortons/common/src/redux/manageLms/actions/actionTypes";
import { ManageLmsModuleAction } from "@timhortons/common/src/redux/manageLms/actions/manageLmsModuleActions";
import { ManageLMSModuleRepository } from "@timhortons/common/src/repositories/manageLms/manageLmsModule";
import { Logger } from "@timhortons/common/src/services/logger";
import {
  ManageLmsPostCreateModuleDataModel,
  ManageLmsPostCreateModuleResponseDataModel,
  LMSGetAllModulesDataModel,
  LMSCategoryModel,
  LMSGetModuleByIdModel,
  LMSGetCategoryByIdModel,
  LMSPostTopicResponseModel,
  LMSGetModuleCategoriesModel,
  LMSgetTopicsModel,
  LMSGetProgressBarStatusModel,
  LmsEditCategoryModel,
  LMSPostProgressBarStatusModel,
  PostLMSMCQTicTacQuiz,
  LMSTicTacQuizModel,
  LMSMtfDndQuizModel,
  LMSImgDataModel,
  LMSCertificateModel,
  LMSQuizMetaDataModel
} from "@timhortons/common/src/models/manageLms/lmsModule";
import { ISummaryData } from "@timhortons/common/src/repositories/manageLms/manageLmsFormatter";
import { IMcqData } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMCQ";
import { IMtf } from "@timhortons/web/src/modules/manageLms/components/assignedLearning/quiz/organisms/lmsMatchTheFollowing";
import { pullAllWith } from "lodash";

function* postManageLmsCreateModuleSaga(
  value: IFluxStandardAction<ManageLmsPostCreateModuleDataModel>
) {
  const { payload } = value;
  try {
    let response: ManageLmsPostCreateModuleResponseDataModel = yield call(
      ManageLMSModuleRepository.postCreateModuleData,
      payload
    );
    yield put(ManageLmsModuleAction.postManageLmsCreateModuleSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "postManageLmsCreateModuleSaga");

    yield put(ManageLmsModuleAction.postManageLmsCreateModuleError(error));
  }
}

function* getLMSAllModulesDataWorkerSaga() {
  try {
    let response: LMSGetAllModulesDataModel[] = yield call(
      ManageLMSModuleRepository.getLMSAllModuleData
    );
    yield put(ManageLmsModuleAction.getLMSAllModulesSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getLMSAllModulesDataWorkerSaga");

    yield put(ManageLmsModuleAction.getLMSAllModulesError(error));
  }
}

function* geLMSModuleByIdWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: LMSGetModuleByIdModel = yield call(
      ManageLMSModuleRepository.getLMSModuleById,
      payload
    );
    yield put(ManageLmsModuleAction.getLmsModuleByIdSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "geLMSModuleByIdWorkerSaga");

    yield put(ManageLmsModuleAction.getLmsModuleByIdError(error));
  }
}
function* postLMSCategoryWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: LMSCategoryModel = yield call(ManageLMSModuleRepository.postLMSCategory, payload);
    yield put(ManageLmsModuleAction.postLmsCategorySuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "postLMSCategoryWorkerSaga");

    yield put(ManageLmsModuleAction.postLmsCategoryError(error));
  }
}

function* getAllCategoriesWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: LMSCategoryModel[] = yield call(
      ManageLMSModuleRepository.getAllCategories,
      payload
    );
    yield put(ManageLmsModuleAction.getLmsAllCategoriesSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getAllCategoriesWorkerSaga");

    yield put(ManageLmsModuleAction.getLmsAllCategoriesError(error));
  }
}

function* getCategoryByIdWorkerSaga(value: IFluxStandardAction<{ categoryId: string }>) {
  try {
    const { payload } = value;
    let response: LMSGetCategoryByIdModel = yield call(
      ManageLMSModuleRepository.getCategoryById,
      payload
    );
    yield put(ManageLmsModuleAction.getCategoryByIdSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getCategoryByIdWorkerSaga");

    yield put(ManageLmsModuleAction.getCategoryByIdError(error));
  }
}
function* deleteCategoryWorkerSaga(value: IFluxStandardAction<{ categoryId: string }>) {
  try {
    const { payload } = value;
    let response: { id: string; name: string } = yield call(
      ManageLMSModuleRepository.deleteCategory,
      payload
    );
    yield put(ManageLmsModuleAction.deleteLmsCategorySuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "deleteCategoryWorkerSaga");

    yield put(ManageLmsModuleAction.deleteLmsCategoryError(error));
  }
}

function* postTopicWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: LMSPostTopicResponseModel = yield call(
      ManageLMSModuleRepository.postTopic,
      payload
    );
    yield put(ManageLmsModuleAction.postTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "postTopicWorkerSaga");

    yield put(ManageLmsModuleAction.postTopicError(error));
  }
}

function* uploadLmsVideoWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(ManageLMSModuleRepository.uploadLmsVideo, payload);
    yield put(ManageLmsModuleAction.uploadLmsVideoSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "uploadLmsVideoWorkerSaga");

    yield put(ManageLmsModuleAction.uploadLmsVideoError(error));
  }
}

function* getModuleCategoriesWorkerSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  try {
    const { payload } = value;
    let response: LMSGetModuleCategoriesModel[] = yield call(
      ManageLMSModuleRepository.getLMSModuleCategories,
      payload
    );
    yield put(ManageLmsModuleAction.getModuleCategoriesSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getModuleCategoriesWorkerSaga");

    yield put(ManageLmsModuleAction.getModuleCategoriesError(error));
  }
}

function* editLmsCategoryWorkerSaga(value: IFluxStandardAction<LmsEditCategoryModel>) {
  try {
    const { payload } = value;
    let response: { id: string; name: string } = yield call(
      ManageLMSModuleRepository.editLmsCategory,
      payload
    );
    yield put(ManageLmsModuleAction.editLmsCategorySuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "editLmsCategoryWorkerSaga");

    yield put(ManageLmsModuleAction.editLmsCategoryError(error));
  }
}

function* deleteLmsTopicWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: { id: string; name: string } = yield call(
      ManageLMSModuleRepository.deleteLmsTopic,
      payload
    );
    yield put(ManageLmsModuleAction.deleteLmsTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "deleteLmsTopicWorkerSaga");

    yield put(ManageLmsModuleAction.deleteLmsTopicError(error));
  }
}

function* deleteLmsModuleWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response = yield call(ManageLMSModuleRepository.deleteLmsModule, payload);
    yield put(ManageLmsModuleAction.deleteLmsModuleSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "deleteLmsModuleWorkerSaga");

    yield put(ManageLmsModuleAction.deleteLmsModuleError(error));
  }
}

function* getLmsTopicByIdWorkerSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  try {
    const { payload } = value;
    let response: LMSgetTopicsModel[] = yield call(
      ManageLMSModuleRepository.getLmsTopicById,
      payload
    );
    yield put(ManageLmsModuleAction.getLmsTopicByIdSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getLmsTopicByIdWorkerSaga");

    yield put(ManageLmsModuleAction.getLmsTopicByIdError(error));
  }
}

function* getProgressBarStatusWorkerSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  try {
    const { payload } = value;
    let response: LMSGetProgressBarStatusModel = yield call(
      ManageLMSModuleRepository.getLmsProgressBarStatus,
      payload
    );
    yield put(ManageLmsModuleAction.getProgressBarStatusSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getProgressBarStatusWorkerSaga");

    yield put(ManageLmsModuleAction.getProgressBarStatusError(error));
  }
}

function* postProgressBarStatusWorkerSaga(
  value: IFluxStandardAction<{
    moduleId: string;
    updateStepName: string;
    isSpecialAssessment: boolean;
  }>
) {
  try {
    const { payload } = value;
    let response: LMSGetProgressBarStatusModel = yield call(
      ManageLMSModuleRepository.postLmsProgressBarStatus,
      payload
    );
    yield put(ManageLmsModuleAction.postProgressBarStatusSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "postProgressBarStatusWorkerSaga");

    yield put(ManageLmsModuleAction.postProgressBarStatusError(error));
  }
}

function* getSummaryDetailsWatcherSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  try {
    const { payload } = value;
    let response: ISummaryData[] = yield call(ManageLMSModuleRepository.getSummaryDetails, payload);
    yield put(ManageLmsModuleAction.getSummaryDetailsSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getSummaryDetailsWatcherSaga");

    yield put(ManageLmsModuleAction.getSummaryDetailsError(error));
  }
}

function* getCategoriesDetailsAllWorkerSaga(value: IFluxStandardAction<{ categoryId: string }>) {
  try {
    const { payload } = value;
    //TODO:create and use model
    let response: any = yield call(ManageLMSModuleRepository.getCategoryDetailsAll, payload);
    yield put(ManageLmsModuleAction.getCategoryDetailsAllSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getCategoriesDetailsAllWorkerSaga");

    yield put(ManageLmsModuleAction.getCategoryDetailsAllError(error));
  }
}

function* editLmsTopicWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: { id: string; name: string } = yield call(
      ManageLMSModuleRepository.editLmsTopic,
      payload
    );
    yield put(ManageLmsModuleAction.editLmsTopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "editLmsTopicWorkerSaga");

    yield put(ManageLmsModuleAction.editLmsTopicError(error));
  }
}

function* getVideoUploadLinkWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: { getUrl: string; uploadUrl: string } = yield call(
      ManageLMSModuleRepository.getVideoUploadLink,
      payload
    );
    yield put(ManageLmsModuleAction.getVideoUploadLinkSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getVideoUploadLinkWorkerSaga");

    yield put(ManageLmsModuleAction.getVideoUploadLinkError(error));
  }
}
function* postLmsSubtopicWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: { id: string; name: string } = yield call(
      ManageLMSModuleRepository.postLmsSubtopic,
      payload
    );
    yield put(ManageLmsModuleAction.postLmsSubtopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "postLmsSubtopicWorkerSaga");

    yield put(ManageLmsModuleAction.postLmsSubtopicError(error));
  }
}

function* editLmsSubtopicWorkerSaga(value: any) {
  try {
    let { payload } = value;
    let response: { id: string; name: string } = yield call(
      ManageLMSModuleRepository.editLmsSubtopic,
      payload
    );
    yield put(ManageLmsModuleAction.editLmsSubtopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "editLmsSubtopicWorkerSaga");

    yield put(ManageLmsModuleAction.editLmsSubtopicError(error));
  }
}

function* deleteLmsSubtopicWorkerSaga(value: any) {
  try {
    let { payload } = value;
    let response: { id: string; name: string } = yield call(
      ManageLMSModuleRepository.deleteLmsSubtopic,
      payload
    );
    yield put(ManageLmsModuleAction.deleteLmsSubtopicSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "deleteLmsSubtopicWorkerSaga");

    yield put(ManageLmsModuleAction.deleteLmsSubtopicError(error));
  }
}

function* deleteLmsVideoWorkerSaga(value: IFluxStandardAction<{ id: string }>) {
  try {
    let { payload } = value;
    let response: { id: string } = yield call(ManageLMSModuleRepository.deleteLmsVideo, payload);
    yield put(ManageLmsModuleAction.deleteLmsVideoSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "deleteLmsVideoWorkerSaga");

    yield put(ManageLmsModuleAction.deleteLmsVideoError(error));
  }
}

function* updateLmsModuleWorkerSaga(
  value: IFluxStandardAction<{ moduleId: string; obj: ManageLmsPostCreateModuleDataModel }>
) {
  const { payload } = value;
  try {
    let response: ManageLmsPostCreateModuleResponseDataModel = yield call(
      ManageLMSModuleRepository.updateModuleData,
      payload
    );
    yield put(ManageLmsModuleAction.updateLmsModuleSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "updateLmsModuleWorkerSaga");

    yield put(ManageLmsModuleAction.updateLmsModuleError(error));
  }
}

function* getTicTacQuizWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: LMSTicTacQuizModel = yield call(ManageLMSModuleRepository.getTicTacQuiz, payload);
    yield put(ManageLmsModuleAction.getTicTacQuizSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getTicTacQuizWorkerSaga");

    yield put(ManageLmsModuleAction.getTicTacQuizError(error));
  }
}

function* postMtfDndQuizWorkerSaga(
  value: IFluxStandardAction<{
    metaData: LMSQuizMetaDataModel;
    quizData: IMtf[];
  }>
) {
  try {
    const { payload } = value;
    let response: LMSMtfDndQuizModel = yield call(
      ManageLMSModuleRepository.postMtfDndQuiz,
      payload
    );
    yield put(ManageLmsModuleAction.postMtfDndSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "postMtfDndQuizWorkerSaga");

    yield put(ManageLmsModuleAction.postMtfDndError(error));
  }
}

function* getMtfDndQuizWorkerSaga(value: IFluxStandardAction<{ quizId: string }>) {
  try {
    const { payload } = value;
    let response: LMSMtfDndQuizModel = yield call(ManageLMSModuleRepository.getMtfDndQuiz, payload);
    yield put(ManageLmsModuleAction.getMtfDndSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getMtfDndQuizWorkerSaga");

    yield put(ManageLmsModuleAction.getMtfDndError(error));
  }
}

function* lmsImgUploadWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    let response: LMSImgDataModel = yield call(ManageLMSModuleRepository.lmsImgUpload, payload);
    yield put(ManageLmsModuleAction.lmsImgUploadSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "lmsImgUploadWorkerSaga");

    yield put(ManageLmsModuleAction.lmsImgUploadError(error));
  }
}

function* lmsImgDeleteWorkerSaga(value: IFluxStandardAction<{ imageKey: string }>) {
  try {
    const { payload } = value;
    let response: LMSImgDataModel = yield call(ManageLMSModuleRepository.lmsImgDelete, payload);
    yield put(ManageLmsModuleAction.lmsImgDeleteSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "lmsImgDeleteWorkerSaga");

    yield put(ManageLmsModuleAction.lmsImgDeleteError(error));
  }
}

function* postMcqTicQuizWorkerSaga(
  value: IFluxStandardAction<{
    metaData: LMSQuizMetaDataModel;
    quizData: IMcqData[];
  }>
) {
  try {
    const { payload } = value;
    let response: PostLMSMCQTicTacQuiz = yield call(
      ManageLMSModuleRepository.postMCQTicTacQuiz,
      payload
    );
    yield put(ManageLmsModuleAction.postMCQTicTacQuizSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "postMcqTicQuizWorkerSaga");

    yield put(ManageLmsModuleAction.postMCQTicTacQuizError(error));
  }
}

function* lmsGetCertificateWorerSaga(value: IFluxStandardAction<{ moduleId: string }>) {
  try {
    const { payload } = value;
    let response: LMSCertificateModel = yield call(
      ManageLMSModuleRepository.getLmsCertificate,
      payload
    );
    yield put(ManageLmsModuleAction.lmsGetCertificateSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "lmsGetCertificateWorerSaga");

    yield put(ManageLmsModuleAction.lmsGetCertificateError(error));
  }
}
function* lmsPostCertificateWorerSaga(value: IFluxStandardAction<LMSCertificateModel>) {
  try {
    const { payload } = value;
    let response: LMSCertificateModel = yield call(
      ManageLMSModuleRepository.postLmsCertificate,
      payload
    );
    yield put(ManageLmsModuleAction.lmsPostCertificateSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "lmsPostCertificateWorerSaga");

    yield put(ManageLmsModuleAction.lmsPostCertificateError(error));
  }
}

function* deleteMcqTttWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(ManageLMSModuleRepository.deleteMcqTtt, payload);
    yield put(ManageLmsModuleAction.deleteMcqTttSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "deleteMcqTttWorkerSaga");

    yield put(ManageLmsModuleAction.deleteMcqTttError(error));
  }
}

function* deleteMtfDndWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(ManageLMSModuleRepository.deleteMtfDnd, payload);
    yield put(ManageLmsModuleAction.deleteMtfDndSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "deleteMtfDndWorkerSaga");

    yield put(ManageLmsModuleAction.deleteMtfDndError(error));
  }
}

function* updateMcqTttWorkerSaga(
  value: IFluxStandardAction<{
    metaData: LMSQuizMetaDataModel;
    quizData: IMcqData[];
  }>
) {
  try {
    const { payload } = value;
    let response: PostLMSMCQTicTacQuiz = yield call(
      ManageLMSModuleRepository.updateMCQTicTacQuiz,
      payload
    );
    yield put(ManageLmsModuleAction.updateMcqTttSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "updateMcqTttWorkerSaga");

    yield put(ManageLmsModuleAction.updateMcqTttError(error));
  }
}

function* updateMtfDndWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: LMSMtfDndQuizModel = yield call(
      ManageLMSModuleRepository.updateMtfDndQuiz,
      payload
    );
    yield put(ManageLmsModuleAction.updateMtfDndSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "updateMtfDndWorkerSaga");

    yield put(ManageLmsModuleAction.updateMtfDndError(error));
  }
}

function* lmsUpdateCertificateWorkerSaga(value: IFluxStandardAction<LMSCertificateModel>) {
  try {
    const { payload } = value;
    let response: LMSCertificateModel = yield call(
      ManageLMSModuleRepository.updateLmsCertificate,
      payload
    );
    yield put(ManageLmsModuleAction.lmsUpdateCertificateSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "lmsUpdateCertificateWorkerSaga");

    yield put(ManageLmsModuleAction.lmsUpdateCertificateError(error));
  }
}

function* publishLmsModuleWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(ManageLMSModuleRepository.publishLmsModule, payload);
    yield put(ManageLmsModuleAction.publishLmsModuleSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "publishLmsModuleWorkerSaga");

    yield put(ManageLmsModuleAction.publishLmsModuleError(error));
  }
}

function* getVideoDataWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(ManageLMSModuleRepository.getVideoData, payload);
    yield put(ManageLmsModuleAction.getVideoDataSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getVideoDataWorkerSaga");

    yield put(ManageLmsModuleAction.getVideoDataError(error));
  }
}

function* getDashboardModulesWorkerSaga(value: any) {
  try {
    const { payload } = value;
    let response: any = yield call(ManageLMSModuleRepository.getDashboardModules, payload);
    yield put(ManageLmsModuleAction.getDashboardModulesSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getDashboardModulesWorkerSaga");

    yield put(ManageLmsModuleAction.getDashboardModulesError(error));
  }
}

function* getDashboardCategoriesWorkerSaga(value: any) {
  try {
    let { payload } = value;
    let response: any = yield call(ManageLMSModuleRepository.getDashboardCategories, payload);
    yield put(ManageLmsModuleAction.getDashboardCategoriesSuccess(response));
  } catch (error) {
    Logger.error("manageLms-module", error, "getDashboardCategoriesWorkerSaga");
    yield put(ManageLmsModuleAction.getDashboardCategoriesError(error));
  }
}

export default function* manageLmsModuleWatcherSaga() {
  yield takeLatest(MANAGELMS.POST_CREATE_MODULE.LOADING, postManageLmsCreateModuleSaga);
  yield takeLatest(MANAGELMS.GET_ALL_MODULE.LOADING, getLMSAllModulesDataWorkerSaga);
  yield takeLatest(MANAGELMS.GET_MODULE_BY_ID.LOADING, geLMSModuleByIdWorkerSaga);
  yield takeLatest(MANAGELMS.POST_CATEGORY.LOADING, postLMSCategoryWorkerSaga);
  yield takeLatest(MANAGELMS.GET_ALL_CATEGORIES.LOADING, getAllCategoriesWorkerSaga);
  yield takeLatest(MANAGELMS.DELETE_CATEGORY.LOADING, deleteCategoryWorkerSaga);
  yield takeLatest(MANAGELMS.GET_CATEGORY_BY_ID.LOADING, getCategoryByIdWorkerSaga);
  yield takeLatest(MANAGELMS.POST_TOPIC.LOADING, postTopicWorkerSaga);
  yield takeLatest(MANAGELMS.GET_MODULE_CATEGORIES.LOADING, getModuleCategoriesWorkerSaga);
  yield takeLatest(MANAGELMS.UPLOAD_VIDEO.LOADING, uploadLmsVideoWorkerSaga);
  yield takeLatest(MANAGELMS.EDIT_CATEGORY.LOADING, editLmsCategoryWorkerSaga);
  yield takeLatest(MANAGELMS.DELETE_TOPIC.LOADING, deleteLmsTopicWorkerSaga);
  yield takeLatest(MANAGELMS.DELETE_MODULE.LOADING, deleteLmsModuleWorkerSaga);
  yield takeLatest(MANAGELMS.GET_TOPIC_BY_ID.LOADING, getLmsTopicByIdWorkerSaga);
  yield takeLatest(MANAGELMS.GET_PROGRESSBAR_STATUS.LOADING, getProgressBarStatusWorkerSaga);
  yield takeLatest(MANAGELMS.POST_PROGRESSBAR_STATUS.LOADING, postProgressBarStatusWorkerSaga);
  yield takeLatest(MANAGELMS.GET_SUMMARY_DETAILS.LOADING, getSummaryDetailsWatcherSaga);
  yield takeLatest(MANAGELMS.GET_CATGORY_DETAILS_ALL.LOADING, getCategoriesDetailsAllWorkerSaga);
  yield takeLatest(MANAGELMS.EDIT_TOPIC.LOADING, editLmsTopicWorkerSaga);
  yield takeLatest(MANAGELMS.GET_VIDEO_UPLOAD_LINK.LOADING, getVideoUploadLinkWorkerSaga);
  yield takeLatest(MANAGELMS.POST_SUBTOPIC.LOADING, postLmsSubtopicWorkerSaga);
  yield takeLatest(MANAGELMS.EDIT_SUBTOPIC.LOADING, editLmsSubtopicWorkerSaga);
  yield takeLatest(MANAGELMS.DELETE_SUBTOPIC.LOADING, deleteLmsSubtopicWorkerSaga);
  yield takeLatest(MANAGELMS.DELETE_VIDEO.LOADING, deleteLmsVideoWorkerSaga);
  yield takeLatest(MANAGELMS.UPDATE_MODULE.LOADING, updateLmsModuleWorkerSaga);
  yield takeLatest(MANAGELMS.GET_MCQ_TIC_QUIZ.LOADING, getTicTacQuizWorkerSaga);
  yield takeLatest(MANAGELMS.POST_MTF_DND_QUIZ.LOADING, postMtfDndQuizWorkerSaga);
  yield takeLatest(MANAGELMS.GET_MTF_DND_QUIZ.LOADING, getMtfDndQuizWorkerSaga);
  yield takeLatest(MANAGELMS.LMS_IMG_UPLOAD.LOADING, lmsImgUploadWorkerSaga);
  yield takeLatest(MANAGELMS.LMS_IMG_DELETE.LOADING, lmsImgDeleteWorkerSaga);
  yield takeLatest(MANAGELMS.LMS_GET_CERTIFICATE.LOADING, lmsGetCertificateWorerSaga);
  yield takeLatest(MANAGELMS.LMS_POST_CERTIFICATE.LOADING, lmsPostCertificateWorerSaga);
  yield takeLatest(MANAGELMS.POST_MCQ_TIC_QUIZ.LOADING, postMcqTicQuizWorkerSaga);
  yield takeLatest(MANAGELMS.DELETE_MCQ_TTT.LOADING, deleteMcqTttWorkerSaga);
  yield takeLatest(MANAGELMS.DELETE_MTF_DND.LOADING, deleteMtfDndWorkerSaga);
  yield takeLatest(MANAGELMS.UPDATE_MCQ_TTT.LOADING, updateMcqTttWorkerSaga);
  yield takeLatest(MANAGELMS.UPDATE_MTF_DND.LOADING, updateMtfDndWorkerSaga);
  yield takeLatest(MANAGELMS.LMS_UPDATE_CERTIFICATE.LOADING, lmsUpdateCertificateWorkerSaga);
  yield takeLatest(MANAGELMS.PUBLISH_MODULE.LOADING, publishLmsModuleWorkerSaga);
  yield takeLatest(MANAGELMS.GET_VIDEO_DATA.LOADING, getVideoDataWorkerSaga);
  yield takeLatest(MANAGELMS.GET_DASHBOARD_MODULES.LOADING, getDashboardModulesWorkerSaga);
  yield takeLatest(MANAGELMS.GET_DASHBOARD_CATEGORIES.LOADING, getDashboardCategoriesWorkerSaga);
}
