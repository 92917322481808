import React, { useState, useRef, ReactElement, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, StyleProp, TextStyle, ViewStyle } from "react-native";
import CalendarPicker from "react-native-calendar-picker";
import {
  Text,
  FontWeight,
  TextSize,
  FontFamily
} from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import OverflowModal from "@timhortons/common/src/components/atoms/overflowModal";
import { setFrameMeasures } from "@timhortons/common/src/utils/measureLayout";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import {
  dateFormat,
  doubleformatDatePicker,
  subtractDays,
  currMonthStartEndDate
} from "@timhortons/common/src/utils/dateFormat";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import { IButtonStyle } from "@timhortons/common/src/modules/rev/components/dashboards/reports/organisms/reportType";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";

interface IProps {
  reset?: boolean | number;
  selectRange?: boolean;
  parentCallBack?: (val: Date | Date[]) => void;
  textStyles?: StyleProp<TextStyle>;
  fontWeight?: FontWeight;
  textSize?: TextSize;
  iconSize?: number;
  iconStyles?: StyleProp<ViewStyle | TextStyle>;
  dateContainerStyle?: StyleProp<ViewStyle>;
  preSelectedDate?: string;
  triggerComponent?: boolean;
  onPressButton?: () => void;
  buttonProps?: IButtonStyle;
  dateViewStyle?: StyleProp<ViewStyle>;
  placeholderText?: string;
  disableSelectedPrevDate?: Date;
  disableFutureDates?: boolean;
  disableCalenderClick?: boolean;
  maxDate?: any;
  disablePastDates?: boolean;
  prevSelectedValues?: any;
  placeholderColor?: string;
  rightIcon?: IconNames;
  enablePrevValuesFetch?: boolean;
  resetToMonthStart?: boolean;
  setReset?: Function;
}

export default function CalendarComponent(props: IProps): ReactElement {
  const {
    reset,
    selectRange,
    parentCallBack,
    textStyles = {},
    fontWeight = FontWeight.Medium,
    textSize = TextSize.Medium2,
    iconStyles,
    iconSize = 14,
    dateContainerStyle,
    preSelectedDate = null,
    onPressButton,
    buttonProps,
    triggerComponent,
    dateViewStyle,
    placeholderText = "Select Dates",
    disableFutureDates = true,
    disableCalenderClick = false,
    disableSelectedPrevDate,
    disablePastDates,
    maxDate,
    prevSelectedValues = null,
    placeholderColor = null,
    rightIcon = null,
    enablePrevValuesFetch = false,
    resetToMonthStart = false,
    setReset
  } = props;

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [buttonFrame, setButtonFrame] = useState(null);

  const triggerRef = useRef(null);

  const onCalenderOpen = (): void => {
    setIsCalenderOpen(!isCalenderOpen);
  };

  useEffect(() => {
    if (preSelectedDate && prevSelectedValues === null) {
      setSelectedStartDate(new Date(preSelectedDate));
      setSelectedEndDate(null);
    } else if (preSelectedDate === null && prevSelectedValues !== null) {
      setSelectedStartDate(new Date(prevSelectedValues.current.fromDate));
      setSelectedEndDate(new Date(prevSelectedValues?.current?.toDate));
    } else if (!preSelectedDate) {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [preSelectedDate, prevSelectedValues]);

  const onDateChange = (date, type) => {
    if (type === "END_DATE") {
      setSelectedEndDate(date);
    } else {
      setSelectedStartDate(date);
      setSelectedEndDate(null);
    }
    if (!selectRange) {
      parentCallBack(date);
      onCalenderOpen();
    }
  };

  useEffect(() => {
    if (reset) {
      if (prevSelectedValues && resetToMonthStart) {
        setSelectedStartDate(new Date(currMonthStartEndDate().startDate));
        // subtractDays(2, new Date())
        setSelectedEndDate(new Date(new Date().setHours(23, 59, 59, 999)));
      } else {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
      }
      setReset && setReset(false);
    }
  }, [reset]);

  const showModal = (): void => {
    setFrameMeasures(
      () => {
        setIsCalenderOpen(true);
      },
      triggerRef,
      setButtonFrame
    );
  };

  const handleApply = () => {
    let dates = [selectedStartDate, selectedEndDate];
    parentCallBack(dates);
    onCalenderOpen();
  };

  const handleCancel = () => {
    onCalenderOpen();
    if (prevSelectedValues) {
      setSelectedStartDate(new Date(prevSelectedValues?.current.fromDate));
      setSelectedEndDate(
        new Date(new Date(prevSelectedValues?.current.toDate).setHours(23, 59, 59, 999))
      );
      let dates = [
        new Date(prevSelectedValues?.current.fromDate),
        new Date(new Date(prevSelectedValues?.current.toDate).setHours(23, 59, 59, 999))
      ];

      enablePrevValuesFetch && parentCallBack(dates);
    } else {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      parentCallBack(null);
    }
  };

  // const handleFuture = () => {
  //   if (new Date(disableSelectedPrevDate).getFullYear() === new Date().getFullYear()) {
  //     return new Date();
  //   } else {
  //     return maxDate;
  //   }
  // };
  const renderCalendar = (): ReactElement => {
    return (
      <View style={styles.calenderContainer}>
        <CalendarPicker
          maxDate={disableFutureDates ? new Date() : maxDate}
          minDate={disablePastDates ? new Date(Date.now()) : null}
          height={330}
          width={330}
          startFromMonday={false}
          allowRangeSelection={selectRange}
          todayBackgroundColor={colorPallete.variant4}
          todayTextStyle={{ color: colorPallete.black1 }}
          selectedDayColor={colorPallete.red1}
          selectedDayTextColor={colorPallete.white3}
          onDateChange={onDateChange}
          textStyle={styles.datesStyle}
          selectedRangeStartStyle={styles.selectedRangeStartStyle}
          selectedRangeEndStyle={styles.selectedRangeStartStyle}
          selectedRangeStartTextStyle={styles.selectedRangeStartTextStyle}
          selectedRangeEndTextStyle={styles.selectedRangeStartTextStyle}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
        {selectRange && (
          <View>
            <View style={styles.dividerContainer}>
              <Divider
                orientation={Orientations.Horizontal}
                dividerStyle={styles.verticalDivider}
              />
            </View>
            <View style={styles.buttonContainer}>
              <Button
                type={ButtonType.Primary}
                title="Cancel"
                onPress={handleCancel}
                textStyles={styles.cancelText}
                buttonStyles={styles.cancelButton}
              />
              <Button
                type={ButtonType.Primary}
                title="Apply"
                onPress={handleApply}
                textStyles={styles.applyText}
                buttonStyles={styles.applyButton}
              />
            </View>
          </View>
        )}
      </View>
    );
  };
  const handleButton = () => {
    onPressButton && onPressButton();
    showModal();
  };

  function getSelectedDate() {
    if (selectRange) {
      if (selectedStartDate !== null && selectedEndDate !== null) {
        return doubleformatDatePicker([selectedStartDate, selectedEndDate]);
      } else {
        return placeholderText;
      }
    }
    if (selectedStartDate !== null && !reset) {
      return dateFormat(selectedStartDate);
    } else {
      return placeholderText;
    }
  }

  return (
    <OverflowModal
      buttonFrame={buttonFrame}
      modalVisible={isCalenderOpen}
      setModalState={onCalenderOpen}
      triggerComponent={
        triggerComponent ? (
          <TouchableOpacity ref={triggerRef} style={[styles.container, dateContainerStyle]}>
            <Button
              iconSize={iconSize}
              iconStyles={buttonProps.iconColorStyles}
              textStyles={buttonProps.dateTextStyle}
              iconName={buttonProps.iconName}
              type={ButtonType.Secondary}
              buttonStyles={buttonProps.styleButton}
              onPress={handleButton}
              title={buttonProps.title}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            ref={triggerRef}
            style={[styles.container, dateContainerStyle]}
            onPress={disableCalenderClick ? null : showModal}
          >
            <View style={[styles.dateView, dateViewStyle]}>
              <Text
                fontWeight={fontWeight}
                textSize={textSize}
                testId="calendar"
                textStyle={[
                  textStyles,
                  getSelectedDate() === placeholderText &&
                    placeholderColor && { color: placeholderColor }
                ]}
              >
                {getSelectedDate()}
              </Text>
              <Icon
                customStyle={[styles.calendarStyle, iconStyles]}
                name={rightIcon ? rightIcon : IconNames.calender}
                size={iconSize}
              />
            </View>
          </TouchableOpacity>
        )
      }
    >
      {isCalenderOpen && renderCalendar()}
    </OverflowModal>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorPallete.white5
  },
  calenderContainer: {
    backgroundColor: colorPallete.white3,
    borderWidth: 1,
    borderColor: colorPallete.grey8,
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderRadius: 6
  },
  dateView: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  selectedRangeStartStyle: {
    borderWidth: 1,
    borderColor: colorPallete.red1
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  selectedRangeStartTextStyle: {
    color: colorPallete.white1
  },
  datesStyle: {
    fontSize: 14,
    color: colorPallete.grey1,
    fontFamily: PlatformUtils.isIOS() ? "SofiaPro" : FontFamily.Regular
  },
  dayLabelsWrapper: {
    fontWeight: "700"
  },
  calendarStyle: {
    color: colorPallete.black3,
    paddingHorizontal: 4,
    // marginBottom:PlatformUtils.isWeb() ? 4 : 0,
    ...I18nService.select({
      rtl: { marginStart: 11 },
      ltr: { marginStart: 11 }
    })
  },
  dividerContainer: {
    zIndex: 9,
    // position: 'absolute',
    // ...I18nService.select({
    //   rtl: {marginStart: 359},
    //   ltr: {marginStart: 359},
    // }),
    // marginStart: 359,
    // flex: 1,
    // justifyContent: 'center',
    // flexDirection: 'row',
    // height: '100%',
    paddingVertical: 20
  },
  verticalDivider: {
    // marginBlockStart: 24,
    // marginBlockEnd: 100,
    borderStyle: "dashed",
    borderWidth: 1,
    borderRadius: 0.1,
    borderColor: colorPallete.grey11
  },
  cancelText: {
    color: colorPallete.red1
  },
  cancelButton: {
    borderColor: colorPallete.red1,
    borderWidth: 1,
    backgroundColor: colorPallete.white1,
    borderRadius: 8,
    paddingHorizontal: 18,
    paddingVertical: 10,
    height: 40,
    ...I18nService.select({
      rtl: { marginEnd: 16 },
      ltr: { marginEnd: 16 }
    })
    // marginEnd: 16
  },
  applyText: { color: colorPallete.white1 },
  applyButton: {
    paddingVertical: 10,
    backgroundColor: colorPallete.red1,
    borderRadius: 8,
    paddingHorizontal: 18,
    height: 40,
    ...I18nService.select({
      rtl: { marginEnd: 16 },
      ltr: { marginEnd: 16 }
    })
  }
});
