import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { REPORTBUILDER } from "@timhortons/common/src/redux/reportBuilder/actions/actionTypes";
import {
  KPIDataModel,
  AllAreaManagersListDataModel
} from "@timhortons/common/src/models/lp/reports";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";
import {
  categoryList,
  productList
} from "@timhortons/common/src/models/productionSheet/reportBuilder";
import { ReportTypeLevel } from "@timhortons/common/src/components/organisms/builder/template";
import { Roles } from "@timhortons/common/src/constants/roles";

const getAllCountriesLoading = (data: {
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
  selectedLevel?: string;
  selectedReportType?: string;
}): IFluxStandardAction<{
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
  selectedLevel?: string;
  selectedReportType?: string;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_COUNTRIES.LOADING,
    payload: data
  };
};

const getAllCountriesSuccess = (
  data: CountryDataListModel
): IFluxStandardAction<CountryDataListModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_COUNTRIES.SUCCESS,
    payload: data
  };
};
const getAllCountriesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_COUNTRIES.ERROR,
    payload: error
  };
};
const getAllAreaManagerLoading = (data: {
  user: IUserDetails;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  reportType: string;
}): IFluxStandardAction<{
  user: IUserDetails;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  reportType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_AREA_MANAGER.LOADING,
    payload: data
  };
};

const getAllAreaManagerSuccess = (
  data: AllAreaManagersListDataModel
): IFluxStandardAction<AllAreaManagersListDataModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_AREA_MANAGER.SUCCESS,
    payload: data
  };
};
const getAllAreaManagerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_AREA_MANAGER.ERROR,
    payload: error
  };
};
const getAllAreaTrainerLoading = (data: {
  user: IUserDetails;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  reportType: string;
}): IFluxStandardAction<{
  user: IUserDetails;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  reportType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_AREA_TRAINER.LOADING,
    payload: data
  };
};

const getAllAreaTrainerSuccess = (
  data: AllAreaManagersListDataModel
): IFluxStandardAction<AllAreaManagersListDataModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_AREA_TRAINER.SUCCESS,
    payload: data
  };
};
const getAllAreaTrainerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_AREA_TRAINER.ERROR,
    payload: error
  };
};
const getAllOpsManagerLoading = (data: {
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  reportType: string;
}): IFluxStandardAction<{
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  reportType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_OPS_MANAGER.LOADING,
    payload: data
  };
};

const getAllOpsManagerSuccess = (
  data: AllAreaManagersListDataModel
): IFluxStandardAction<AllAreaManagersListDataModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_OPS_MANAGER.SUCCESS,
    payload: data
  };
};
const getAllOpsManagerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_OPS_MANAGER.ERROR,
    payload: error
  };
};
const getAllChannelsLoading = (data: {
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  countryCode: any;
  searchByKeyword: string;
  moduleType: string;
}): IFluxStandardAction<{
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  countryCode: any;
  searchByKeyword: string;
  moduleType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_CHANNELS.LOADING,
    payload: data
  };
};

const getAllChannelsSuccess = (
  data: ChannelsListDataModel
): IFluxStandardAction<ChannelsListDataModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_CHANNELS.SUCCESS,
    payload: data
  };
};
const getAllChannelsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_CHANNELS.ERROR,
    payload: error
  };
};

const getAllStoresLoading = (data: {
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  countryCode: any;
  searchByKeyword: string;
  moduleType: string;
  reportType: string;
  userRole: Roles;
}): IFluxStandardAction<{
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  countryCode: any;
  searchByKeyword: string;
  moduleType: string;
  reportType: string;
  userRole: Roles;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_STORES.LOADING,
    payload: data
  };
};

const getAllStoresSuccess = (
  data: AllStoresListDataModel
): IFluxStandardAction<AllStoresListDataModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_STORES.SUCCESS,
    payload: data
  };
};
const getAllStoresError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_STORES.ERROR,
    payload: error
  };
};

const getAllCountryManagerLoading = (data: {
  user: IUserDetails;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  pageNo: number;
  pageSize: number;
  moduleType: string;
  selectedReportType: ReportTypeLevel;
}): IFluxStandardAction<{
  user: IUserDetails;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  pageNo: number;
  pageSize: number;
  moduleType: string;
  selectedReportType: ReportTypeLevel;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_COUNTRY_MANAGER.LOADING,
    payload: data
  };
};

const getAllCountryManagerSuccess = (
  data: AllAreaManagersListDataModel
): IFluxStandardAction<AllAreaManagersListDataModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_COUNTRY_MANAGER.SUCCESS,
    payload: data
  };
};
const getAllCountryManagerError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_COUNTRY_MANAGER.ERROR,
    payload: error
  };
};
const getAllClusterHeadOfOpsLoading = (data: {
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  selectedReportType: ReportTypeLevel;
}): IFluxStandardAction<{
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  moduleType: string;
  selectedReportType: ReportTypeLevel;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_CLUSTER_HEAD_OF_OPS.LOADING,
    payload: data
  };
};

const getAllClusterHeadOfOpsSuccess = (
  data: AllAreaManagersListDataModel
): IFluxStandardAction<AllAreaManagersListDataModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_CLUSTER_HEAD_OF_OPS.SUCCESS,
    payload: data
  };
};
const getAllClusterHeadOfOpsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_CLUSTER_HEAD_OF_OPS.ERROR,
    payload: error
  };
};
const getComparisonCountryManagersAdmin = (data: {
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
}): IFluxStandardAction<{
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.LOADING,
    payload: data
  };
};
const comparisonCountryManagersAdminSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.SUCCESS,
    payload: data
  };
};
const comparisonCountryManagersAdminError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_COUNTRY_MANAGERS_ADMIN.ERROR,
    payload: error
  };
};

const getComparisonAreaManagersAdmin = (data: {
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
}): IFluxStandardAction<{
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_AREA_MANAGERS_ADMIN.LOADING,
    payload: data
  };
};
const comparisonAreaManagersAdminSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_AREA_MANAGERS_ADMIN.SUCCESS,
    payload: data
  };
};
const comparisonAreaManagersAdminError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_AREA_MANAGERS_ADMIN.ERROR,
    payload: error
  };
};
const comparisonStoresAdminLoading = (data: {
  pageNo: number;
  pageSize: number;
  moduleType: string;
  searchByKeyword: string;
}): IFluxStandardAction<{
  pageNo: number;
  pageSize: number;
  moduleType: string;
  searchByKeyword: string;
}> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_STORES_ADMIN.LOADING,
    payload: data
  };
};

const comparisonStoresAdminSuccess = (
  data: AllStoresListDataModel
): IFluxStandardAction<AllStoresListDataModel> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_STORES_ADMIN.SUCCESS,
    payload: data
  };
};
const comparisonStoresAdminError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_COMPARISON_STORES_ADMIN.ERROR,
    payload: error
  };
};
const resetReportBuilder = () => {
  return {
    type: REPORTBUILDER.RESET_REPORT_BUILDER
  };
};
const highlowChannelsAdminLoading = (data: {
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
}): IFluxStandardAction<{
  pageNo: number;
  pageSize: number;
  searchByKeyword: string;
  moduleType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_HIGHLOW_CHANNELS_ADMIN.LOADING,
    payload: data
  };
};

const highlowChannelsAdminSuccess = (
  data: ChannelsListDataModel
): IFluxStandardAction<ChannelsListDataModel> => {
  return {
    type: REPORTBUILDER.GET_HIGHLOW_CHANNELS_ADMIN.SUCCESS,
    payload: data
  };
};
const highlowChannelsAdminError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_HIGHLOW_CHANNELS_ADMIN.ERROR,
    payload: error
  };
};
const getKpiData = (data: {
  moduleType: string;
}): IFluxStandardAction<{
  moduleType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_KPI_DATA.LOADING,
    payload: data
  };
};

const getKpiDataSuccess = (data: any[]): IFluxStandardAction<any[]> => {
  return {
    type: REPORTBUILDER.GET_KPI_DATA.SUCCESS,
    payload: data
  };
};
const getKpiDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_KPI_DATA.ERROR,
    payload: error
  };
};
const getCategory = (data: {
  idList: number;
  level: string;
  station: string;
  allSelected: boolean;
  countryCodes?: any;
}): IFluxStandardAction<{
  idList: number;
  level: string;
  station: string;
  allSelected: boolean;
  countryCodes?: any;
}> => {
  return {
    type: REPORTBUILDER.GET_CATEGORY.LOADING,
    payload: data
  };
};

const getCategorySuccess = (data: categoryList[]): IFluxStandardAction<categoryList[]> => {
  return {
    type: REPORTBUILDER.GET_CATEGORY.SUCCESS,
    payload: data
  };
};
const getCategoryError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_CATEGORY.ERROR,
    payload: error
  };
};
const getProduct = (data: {
  idList: any;
  level: string;
  station: string;
  categoryId: string;
  allSelected: boolean;
  countryCodes?: any;
}): IFluxStandardAction<{
  idList: any;
  level: string;
  station: string;
  categoryId: string;
  allSelected: boolean;
  countryCodes?: any;
}> => {
  return {
    type: REPORTBUILDER.GET_PRODUCT.LOADING,
    payload: data
  };
};

const getProductSuccess = (data: productList[]): IFluxStandardAction<productList[]> => {
  return {
    type: REPORTBUILDER.GET_PRODUCT.SUCCESS,
    payload: data
  };
};
const getProductError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_PRODUCT.ERROR,
    payload: error
  };
};
const getAllLpAuditorLoading = (data: {
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  reportType: string;
  moduleType: string;
}): IFluxStandardAction<{
  user: IUserDetails;
  pageNo: number;
  pageSize: number;
  role: string;
  countryCode: any;
  searchByKeyword: string;
  allSelected?: boolean;
  reportType: string;
  moduleType: string;
}> => {
  return {
    type: REPORTBUILDER.GET_ALL_LP_AUDITOR.LOADING,
    payload: data
  };
};

const getAllLpAuditorSuccess = (
  data: CountryDataListModel
): IFluxStandardAction<CountryDataListModel> => {
  return {
    type: REPORTBUILDER.GET_ALL_LP_AUDITOR.SUCCESS,
    payload: data
  };
};
const getAllLpAuditorError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REPORTBUILDER.GET_ALL_LP_AUDITOR.ERROR,
    payload: error
  };
};
const resetProductData = () => {
  return {
    type: REPORTBUILDER.RESET_PRODUCT_DATA.LOADING
  };
};
export const ReportsBuilderActions = {
  getAllCountriesLoading,
  getAllCountriesSuccess,
  getAllCountriesError,
  getAllAreaManagerLoading,
  getAllAreaManagerSuccess,
  getAllAreaManagerError,
  getAllAreaTrainerLoading,
  getAllAreaTrainerSuccess,
  getAllAreaTrainerError,
  getAllOpsManagerLoading,
  getAllOpsManagerSuccess,
  getAllOpsManagerError,
  getAllChannelsLoading,
  getAllChannelsSuccess,
  getAllChannelsError,
  getAllStoresLoading,
  getAllStoresSuccess,
  getAllStoresError,
  getAllCountryManagerLoading,
  getAllCountryManagerSuccess,
  getAllCountryManagerError,
  getAllClusterHeadOfOpsLoading,
  getAllClusterHeadOfOpsSuccess,
  getAllClusterHeadOfOpsError,
  getAllLpAuditorLoading,
  getAllLpAuditorSuccess,
  getAllLpAuditorError,
  getComparisonCountryManagersAdmin,
  comparisonCountryManagersAdminSuccess,
  comparisonCountryManagersAdminError,
  getComparisonAreaManagersAdmin,
  comparisonAreaManagersAdminSuccess,
  comparisonAreaManagersAdminError,
  comparisonStoresAdminLoading,
  comparisonStoresAdminSuccess,
  comparisonStoresAdminError,
  resetReportBuilder,
  highlowChannelsAdminLoading,
  highlowChannelsAdminSuccess,
  highlowChannelsAdminError,
  getKpiData,
  getKpiDataSuccess,
  getKpiDataError,
  getProduct,
  getProductSuccess,
  getProductError,
  getCategory,
  getCategorySuccess,
  getCategoryError,
  resetProductData
};
