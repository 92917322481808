import React, { ReactElement, useEffect, useState, useRef } from "react";
import { View, StyleSheet } from "react-native";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { DatePicker } from "@timhortons/web/src/components/atoms/datepicker/datepicker";
import ModalDropdown, {
  IDropDownItem
} from "@timhortons/common/src/components/molecules/modalDropdown";
import {
  Filters,
  HideFilters,
  ResetFilters,
  ShowFiltersView
} from "@timhortons/common/src/modules/common/organisms/filters";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import CalendarComponent from "@timhortons/tablet/src/components/atoms/datepicker/index";
import { convert, dateFormat, getTimeZone } from "@timhortons/common/src/utils/dateFormat";
import { Roles } from "@timhortons/common/src/constants/roles";
import { FilterDataModel } from "@timhortons/common/src/models/lp/actionPlan";
import { DoubleDatePicker } from "@timhortons/web/src/components/atoms/datepicker/doubleViewCalender";
import { lpActionPlanEndPoints } from "@timhortons/common/src/constants/endPoints";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { getDocumentDownloader } from "@timhortons/web/src/utils/docsDownloader";
import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";

interface IProps {
  filterData: FilterDataModel;
  dropDownValueHandler: Function;
  translate: any;
  isIndividualLogin: boolean;
  user?: IUserDetails;
  finalLpActionPlan?: any;
}
interface ILpAuditorNameData {
  auditorId: string;
  auditorName: string;
}
export enum FilterStatus {
  completed = "COMPLETED",
  notStarted = "NOT_STARTED",
  overdue = "OVERDUE"
}
function LpActionPlanHeader(props: IProps): ReactElement {
  const {
    filterData,
    translate,
    dropDownValueHandler,
    isIndividualLogin,
    user,
    finalLpActionPlan
  } = props;
  const [selectedValues, setSelectedValues] = useState({
    date: null,
    name: "",
    status: null,
    fromDate: null,
    toDate: null,
    search: null
  });
  let prevSelectedValues = useRef(selectedValues);
  const [isShowFilter, setShowFilter] = useState(false);
  const [statusLabelStyle, setStatusLabelStyle] = useState(null);
  const [isDownloadBtnDisabled, setIsDownloadBtnDisabled] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState<boolean>(false);
  const [reset, setReset] = useState(false);
  const downloadHandler = async () => {
    setIsDownloadBtnDisabled(true);

    try {
      const endPoint = lpActionPlanEndPoints.actionPlanDownload();
      let params: any;
      if (user?.roles[0].includes(Roles.StoreAccess)) {
        params = {
          storeCode: user && user.storeCode,
          filterByActionPlanStatus: finalLpActionPlan?.status,
          filterByAuditor: finalLpActionPlan?.name,
          sortByDate:
            finalLpActionPlan?.columnName !== "Audit Time" ? finalLpActionPlan?.sortOrder : "",
          sortByTime:
            finalLpActionPlan?.columnName === "Audit Time" ? finalLpActionPlan?.sortOrder : "",
          filterByFromDate: finalLpActionPlan?.fromDate && convert(finalLpActionPlan?.fromDate),
          filterByToDate: finalLpActionPlan?.toDate && convert(finalLpActionPlan?.toDate),
          searchByKeyword: finalLpActionPlan?.search,
          timeZone: getTimeZone()
        };
      } else {
        params = {
          auditedBy: user && user.empId,
          filterByActionPlanStatus: finalLpActionPlan?.status,
          filterByAuditor: finalLpActionPlan?.name,
          sortByDate:
            finalLpActionPlan?.columnName !== "Audit Time" ? finalLpActionPlan?.sortOrder : "",
          sortByTime:
            finalLpActionPlan?.columnName === "Audit Time" ? finalLpActionPlan?.sortOrder : "",
          filterByFromDate: finalLpActionPlan?.fromDate && convert(finalLpActionPlan?.fromDate),
          filterByToDate: finalLpActionPlan?.toDate && convert(finalLpActionPlan?.toDate),
          searchByKeyword: finalLpActionPlan?.search,
          timeZone: getTimeZone()
        };
      }
      await getDocumentDownloader({
        endPoint,
        params,
        xApiKey: LPKeys.LP_ACTION_PLANS
      });
    } catch (_err) {
      setDownloadFailed(true);
    }
    setIsDownloadBtnDisabled(false);
    return "Downloaded";
  };
  useEffect(() => {
    if (JSON.stringify(prevSelectedValues.current) !== JSON.stringify(selectedValues)) {
      dropDownValueHandler(selectedValues);
    }
    prevSelectedValues.current = selectedValues;
  }, [selectedValues]);
  const getLpListData = (data: Array<ILpAuditorNameData>): Array<IDropDownItem> => {
    let dataList: Array<IDropDownItem> = [];
    dataList =
      data &&
      data.map((item: ILpAuditorNameData) => {
        return {
          value: item.auditorId,
          label: item.auditorName
        };
      });
    dataList && dataList.unshift({ label: translate("Auditor"), value: "" });
    return dataList;
  };
  const getLpFilterData = (): Array<IDropDownItem> => {
    let data: IDropDownItem[] = [];
    data =
      filterData &&
      filterData.status.map((el: any) => {
        return {
          ...el,
          itemStyle:
            (el.value === FilterStatus.completed && styles.completedLabel) ||
            (el.value === FilterStatus.notStarted && styles.notStartedLable) ||
            styles.overdueLable
        };
      });
    data && data.unshift({ value: null, label: "Status" });
    return data;
  };

  const statusSelectedHandler = (value: string) => {
    if (value === FilterStatus.completed) {
      setStatusLabelStyle(styles.completedLabel);
    } else if (value === FilterStatus.notStarted) {
      setStatusLabelStyle(styles.notStartedLable);
    } else if (value === FilterStatus.overdue) {
      setStatusLabelStyle(styles.overdueLable);
    } else setStatusLabelStyle(null);
    setSelectedValues((prevState) => ({
      ...prevState,
      status: value
    }));
  };
  const auditorSelectedHandler = (value: string) => {
    setReset(false);
    setSelectedValues((prevState) => ({
      ...prevState,
      name: value
    }));
  };
  const handleSelectedDate = (value: any) => {
    setReset(false);
    if (dateFormat(value) !== dateFormat(selectedValues.date)) {
      setSelectedValues((prevState) => ({
        ...prevState,
        fromDate: value && value[0],
        toDate: value && value[1]
      }));
    }
  };
  const selectedFromDateHandler = (value: any) => {
    setReset(false);
    if (dateFormat(value) === dateFormat(selectedValues.fromDate)) {
      return null;
    } else {
      return setSelectedValues((prevState: any) => ({
        ...prevState,
        fromDate: value && value[0],
        toDate: value && value[1]
      }));
    }
  };

  const selectedSearchHandler = (value: string) => {
    setReset(false);
    setSelectedValues((prevState: any) => ({
      ...prevState,
      search: value
    }));
  };
  const resetState = () => {
    setSelectedValues((prevState) => ({
      ...prevState,
      date: null,
      fromDate: null,
      toDate: null,
      name: "",
      search: "",
      status: null
    }));
    setReset(true);
    setStatusLabelStyle(null);
  };

  return (
    <>
      <View style={styles.elementContainer}>
        <View style={styles.textContainer}>
          <Text
            textStyle={styles.textStyles}
            fontWeight={FontWeight.Regular}
            textSize={TextSize.ExtraLarge}
            testId={testIds.actionPlan.allActionPlans}
          >
            {translate("actionPlanHeading")}
          </Text>
          {/* <Button
            type={ButtonType.Primary}
            onPress={null}
            buttonStyles={styles.button}
            iconSize={16}
            iconName={IconNames.tableDownload}
            iconStyles={styles.iconStyles}
          /> */}
          <Icon
            testID="download"
            name={IconNames.tableDownload}
            color={colorPallete.black4}
            size={16}
            customStyle={styles.download}
            onPress={downloadHandler}
            disabled={isDownloadBtnDisabled}
          />
          {isIndividualLogin &&
          (user?.roles[0] === Roles.AuditorLP ||
            user?.roles[0] === Roles.SuperAdmin ||
            user?.roles[0] === Roles.TrainingAndDevManager ||
            user?.roles[0] === Roles.TrainingAndDevLead ||
            user?.roles[0] === Roles.COO ||
            user?.roles[0] === Roles.CEO ||
            user?.roles[0] === Roles.HrAndAdminDirector ||
            user?.roles[0] === Roles.ProductionAndDevExecutive ||
            user?.roles[0] === Roles.HeadLP)
            ? (isShowFilter && (
                <ShowFiltersView
                  translate={translate}
                  setShowFilter={() => setShowFilter(!isShowFilter)}
                  resetState={resetState}
                />
              )) || (
                <HideFilters
                  translate={translate}
                  setShowFilter={() => setShowFilter(!isShowFilter)}
                />
              )
            : null}
          <ResetFilters
            resetContainerStyle={{ marginTop: 4 }}
            translate={translate}
            setShowFilter={() => setShowFilter(!isShowFilter)}
            resetState={resetState}
          />
        </View>
        {(!isIndividualLogin ||
          (user?.roles[0] !== Roles.AuditorLP &&
            user?.roles[0] !== Roles.SuperAdmin &&
            user?.roles[0] !== Roles.TrainingAndDevLead &&
            user?.roles[0] !== Roles.TrainingAndDevManager &&
            user?.roles[0] !== Roles.HeadLP &&
            user?.roles[0] !== Roles.CEO &&
            user?.roles[0] !== Roles.COO &&
            user?.roles[0] !== Roles.ProductionAndDevExecutive &&
            user?.roles[0] !== Roles.HrAndAdminDirector)) && (
          <View style={styles.dropdownElementContainer}>
            <View style={styles.filtersElementContainer}>
              {PlatformUtils.isWeb() ? (
                <DoubleDatePicker
                  selectRange
                  showDoubleView
                  showWeekNumbers
                  parentDoubleViewCall={(value: any) => selectedFromDateHandler(value)}
                  translate={translate}
                  dateContainerStyle={styles.datePickerContainer}
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.ExtraSmall}
                  textStyles={styles.datePickerText}
                  reset={reset}
                  prevSelectedValues={prevSelectedValues}
                />
              ) : (
                <CalendarComponent
                  selectRange={true}
                  parentCallBack={(value) => handleSelectedDate(value)}
                  reset={reset}
                  textStyles={styles.datePickerText}
                  iconStyles={styles.datePickerIcon}
                  iconSize={12}
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.ExtraSmall}
                  dateContainerStyle={styles.datePickerContainer}
                  placeholderText={translate("Select Dates")}
                />
              )}
            </View>
            <View style={styles.filtersElementContainer}>
              <ModalDropdown
                allItems={getLpListData(filterData && filterData.auditorName)}
                selectedValueDefault={selectedValues.name}
                onChangeCallBack={(obj: IDropDownItem) => auditorSelectedHandler(obj.value)}
                selectedItemStyle={
                  selectedValues.name === ""
                    ? styles.defaultAuditorStyle
                    : styles.selctedAuditorStyle
                }
              />
            </View>
            <ModalDropdown
              allItems={getLpFilterData()}
              selectedValueDefault={selectedValues.status}
              onChangeCallBack={(obj: IDropDownItem) => statusSelectedHandler(obj.value)}
              selectedItemStyle={statusLabelStyle}
            />
          </View>
        )}
      </View>
      {isIndividualLogin && isShowFilter ? (
        <Filters
          actionPlanTable={true}
          parentCallBackFrom={(value: any) => selectedFromDateHandler(value)}
          searchFunction={(value: any) => {
            selectedSearchHandler(value);
          }}
          resetHandler={resetState}
          searchValue={selectedValues.search}
          reset={reset}
          allItems={getLpFilterData()}
          selectedValueDefault={selectedValues.status}
          onChangeCallBack={(obj: IDropDownItem) => statusSelectedHandler(obj.value)}
          selectedItemStyle={statusLabelStyle}
          translate={translate}
          allItemsName={getLpListData(filterData && filterData.auditorName)}
          selectedValueName={selectedValues.name}
          onChangeCallBackName={(obj: IDropDownItem) => auditorSelectedHandler(obj.value)}
          selectedItemNameStyle={
            selectedValues.name === "" ? styles.defaultAuditorStyle : styles.selctedAuditorStyle
          }
        />
      ) : null}
      {downloadFailed && (
        <AlertModal
          errorMsg={translate("downloadFailed")}
          setModalVisible={function (): void {
            setDownloadFailed(false);
          }}
        />
      )}
    </>
  );
}
export default LpActionPlanHeader;
const styles = StyleSheet.create({
  elementContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  download: {
    marginTop: 10,
    ...I18nService.select({
      rtl: { paddingStart: 10 },
      ltr: { paddingStart: 10 }
    })
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "flex-start"
  },
  textStyles: {
    color: colorPallete.black3,
    ...I18nService.select({
      rtl: { marginEnd: 28 },
      ltr: { marginEnd: 28 }
    })
  },
  iconStyles: {
    color: colorPallete.grey2,
    padding: 6
  },
  button: {
    backgroundColor: colorPallete.white1,
    borderRadius: 8,
    marginTop: 6
  },

  dropdownElementContainer: {
    height: 36,
    flexDirection: "row"
  },

  datePickerText: {
    color: colorPallete.black4
  },
  datePickerIcon: {
    color: colorPallete.grey2
  },
  datePickerContainer: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: colorPallete.white1
  },
  completedLabel: {
    color: colorPallete.green1
  },
  notStartedLable: {
    color: colorPallete.red3
  },
  overdueLable: {
    color: colorPallete.yellow1
  },
  selctedAuditorStyle: {
    color: colorPallete.black4
  },
  defaultAuditorStyle: {
    color: colorPallete.grey2
  },
  filtersElementContainer: {
    ...I18nService.select({
      rtl: { marginEnd: 24 },
      ltr: { marginEnd: 24 }
    })
  }
});
