import { REV } from "@timhortons/common/src/redux/rev/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";

import {
  ActionPlanHeaderModel,
  ActionPlanTableModel,
  ActionPlanCategoryDataModel,
  ActionPlanAccordionDataModel,
  FilterDataModel,
  SelectedFilterDataModel
} from "@timhortons/common/src/models/rev/actionPlan";
import { IActionPlanData } from "@timhortons/common/src/modules/rev/components/dashboards/actionPlans";
import { IActionItemsData } from "@timhortons/common/src/modules/rev/organisms/actionPlanAccordion/body";
import { AcknowledgementModel } from "@timhortons/common/src/models/rev/reports";

const getActionPlans = (data: IActionPlanData): IFluxStandardAction<IActionPlanData> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLANS.LOADING,
    payload: data
  };
};
const getActionPlansSuccess = (
  data: ActionPlanTableModel
): IFluxStandardAction<ActionPlanTableModel> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLANS.SUCCESS,
    payload: data
  };
};
const getActionPlansError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLANS.ERROR,
    payload: error
  };
};
const getFilterData = (data: { storeId: string }): IFluxStandardAction<{ storeId: string }> => {
  return {
    type: REV.GET_FILTER_DATA.LOADING,
    payload: data
  };
};
const getFilterDataSuccess = (data: FilterDataModel): IFluxStandardAction<FilterDataModel> => {
  return {
    type: REV.GET_FILTER_DATA.SUCCESS,
    payload: data
  };
};
const getFilterDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_FILTER_DATA.ERROR,
    payload: error
  };
};
const getSeletedFilterData = (
  data: SelectedFilterDataModel
): IFluxStandardAction<SelectedFilterDataModel> => {
  return {
    type: REV.GET_SELECTED_FILTER_DATA.SUCCESS,
    payload: data
  };
};

const getActionPlanDetails = (data: {
  actionPlanId: number;
}): IFluxStandardAction<{ actionPlanId: number }> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLAN_HEADER_DATA.LOADING,
    payload: data
  };
};
const getActionPlanDetailSuccess = (
  data: ActionPlanHeaderModel
): IFluxStandardAction<ActionPlanHeaderModel> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLAN_HEADER_DATA.SUCCESS,
    payload: data
  };
};
const getActionPlanDetailError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLAN_HEADER_DATA.ERROR,
    payload: error
  };
};

const getCategoryStatusData = (data: {
  actionPlanId: number;
  calibrationId: number;
}): IFluxStandardAction<{ actionPlanId: number; calibrationId: number }> => {
  return {
    type: REV.GET_CATEGORY_STATUS.LOADING,
    payload: data
  };
};
const getCategoryStatusDataSuccess = (
  data: ActionPlanCategoryDataModel[]
): IFluxStandardAction<ActionPlanCategoryDataModel[]> => {
  return {
    type: REV.GET_CATEGORY_STATUS.SUCCESS,
    payload: data
  };
};
const getCategoryStatusDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_CATEGORY_STATUS.ERROR,
    payload: error
  };
};

const getActionPlanAccordionData = (data: {
  categoryId: number;
  actionPlanId: number;
  calibrationId: number;
}): IFluxStandardAction<{ categoryId: number }> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLAN_ACCORDION_DATA.LOADING,
    payload: data
  };
};
const getActionPlanAccordionDataSuccess = (
  data: ActionPlanAccordionDataModel[]
): IFluxStandardAction<ActionPlanAccordionDataModel[]> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLAN_ACCORDION_DATA.SUCCESS,
    payload: data
  };
};
const getActionPlanAccordionDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.ActionPlans.GET_ACTION_PLAN_ACCORDION_DATA.ERROR,
    payload: error
  };
};

const getOwnerData = (data: { storeId: string }): IFluxStandardAction<{ storeId: string }> => {
  return {
    type: REV.GET_OWNER_DATA.LOADING,
    payload: data
  };
};
const getOwnerDataSuccess = (
  data: AcknowledgementModel
): IFluxStandardAction<AcknowledgementModel> => {
  return {
    type: REV.GET_OWNER_DATA.SUCCESS,
    payload: data
  };
};
const getOwnerDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.GET_OWNER_DATA.ERROR,
    payload: error
  };
};

const updateActionPlanStatus = (data: {
  actionPlanId: number;
}): IFluxStandardAction<{ actionPlanId: number }> => {
  return {
    type: REV.ActionPlans.UPDATE_ACTION_PLAN_STATUS.LOADING,
    payload: data
  };
};
const updateActionPlanStatusSuccess = (): IFluxStandardAction => {
  return {
    type: REV.ActionPlans.UPDATE_ACTION_PLAN_STATUS.SUCCESS
  };
};
const updateActionPlanStatusError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.ActionPlans.UPDATE_ACTION_PLAN_STATUS.ERROR,
    payload: error
  };
};

const postActionSteps = (data: IActionItemsData): IFluxStandardAction<IActionItemsData> => {
  return {
    type: REV.ActionPlans.POST_ACTION_STEPS.LOADING,
    payload: data
  };
};
const postActionStepsSuccess = (
  data: any,
  payload: any
): IFluxStandardAction<{ data: any; payload: any }> => {
  return {
    type: REV.ActionPlans.POST_ACTION_STEPS.SUCCESS,
    payload: { data, payload }
  };
};
const postActionStepsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: REV.ActionPlans.POST_ACTION_STEPS.ERROR,
    payload: error
  };
};
const resetActionPlan = () => {
  return {
    type: REV.RESET_ACTION_PLAN
  };
};
export const ActionPlansAction = {
  getActionPlans,
  getActionPlansSuccess,
  getActionPlansError,
  getFilterData,
  getFilterDataError,
  getFilterDataSuccess,
  getSeletedFilterData,
  getActionPlanDetails,
  getActionPlanDetailError,
  getActionPlanDetailSuccess,
  getCategoryStatusData,
  getCategoryStatusDataError,
  getCategoryStatusDataSuccess,
  getActionPlanAccordionData,
  getActionPlanAccordionDataError,
  getActionPlanAccordionDataSuccess,
  getOwnerData,
  getOwnerDataSuccess,
  getOwnerDataError,
  updateActionPlanStatus,
  updateActionPlanStatusError,
  updateActionPlanStatusSuccess,
  postActionSteps,
  postActionStepsSuccess,
  postActionStepsError,
  resetActionPlan
};
