import React, { useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { Text, TextSize, FontWeight } from "@timhortons/common/src/components/atoms/text";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import AuditModal from "@timhortons/common/src/modules/common/organisms/authModal/index";
import { LpNewAppealsTable } from "@timhortons/common/src/models/lp/appeals";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import {
  ILpAppealsError,
  ILpAppealsLoading
} from "@timhortons/common/src/redux/lp/reducers/appeals";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";

interface IProps {
  lpNewAppealsTableData: LpNewAppealsTable[];
  translate: any;
  onNewAppealClick: Function;
  lpAppealCount?: number;
  getLpNewAppeals: Function;
  setModalState: () => void;
  modalVisible: boolean;
  loading: ILpAppealsLoading;
  error: ILpAppealsError;
}
const getHeaderComp = (headerItem: any, index: number) => {
  return (
    <HeaderCell
      key={index}
      columnSelected={headerItem.selected}
      title={headerItem.name}
      sortable={headerItem.isSortable}
      columnName={headerItem.name}
      fontWeight={FontWeight.Regular}
      textSize={TextSize.Regular}
      titleStyle={styles.headerCellColor}
      sortFunction={(val: string) => headerItem.sortFunction(val, headerItem.name)}
    />
  );
};
function LpNewAppealTable(props: IProps): React.ReactElement {
  const {
    lpNewAppealsTableData,
    translate,
    onNewAppealClick,
    getLpNewAppeals,
    modalVisible,
    setModalState,
    loading,
    error
  } = props;

  const newAppealsTableHeader = [
    { name: translate("storeId"), isSortable: false },
    { name: translate("storeNameTable"), isSortable: false },
    { name: translate("auditorName"), isSortable: false },
    { name: translate("auditDate"), isSortable: false },
    { name: translate("appeal"), isSortable: false }
  ];
  const [currentAuditId, setCurrentAuditId] = useState<number>(0);

  const getStoreId = async () => {
    const storeDetail: { storeCode: number } = await LocalStorage.getStorage("storeCode");
    return storeDetail?.storeCode;
  };

  const handleAppealSubmit = async (empID: string) => {
    onNewAppealClick(currentAuditId, empID);
  };

  const getLpAppeals = async () => {
    let store = await getStoreId();
    getLpNewAppeals({ storeId: store, status: "new" });
  };

  const handleModalOpen = (id: number) => {
    setCurrentAuditId(id);
    setModalState();
  };
  const isFocused = useFocusedHook();
  useEffect(() => {
    isFocused && getLpAppeals();
  }, [isFocused]);

  return (
    <View style={styles.containerFlex}>
      <Text
        fontWeight={FontWeight.Regular}
        textSize={TextSize.ExtraRegular}
        testId={testIds.appeal.noAudits}
        textStyle={styles.subHeadingColor}
      >
        {lpNewAppealsTableData && lpNewAppealsTableData.length > 0
          ? translate("selectAudit")
          : translate("noAudits")}
      </Text>
      <Table>
        <Row header rowStyle={styles.tableRow}>
          {newAppealsTableHeader.map((headerItem, index) => getHeaderComp(headerItem, index))}
        </Row>
        {error.getLpNewAppeals ? (
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        ) : loading.getLpNewAppeals ? (
          <Loader type="component" />
        ) : lpNewAppealsTableData && lpNewAppealsTableData.length > 0 ? (
          <View style={styles.tableContainerView}>
            {lpNewAppealsTableData &&
              lpNewAppealsTableData.map((lpAppeal, index) => (
                <Row key={index}>
                  <Cell title={lpAppeal.storeCode} />
                  <Cell title={lpAppeal.storeName} />
                  <Cell title={lpAppeal.auditorName} />
                  <Cell title={dateFormat(lpAppeal.auditDate)} />

                  <Cell>
                    <TouchableOpacity
                      style={styles.thunderIconView}
                      onPress={() => handleModalOpen(lpAppeal.auditId)}
                    >
                      <Icon
                        size={16}
                        name={IconNames.thunder}
                        customStyle={styles.thunderIconStyle}
                      />
                    </TouchableOpacity>
                  </Cell>
                </Row>
              ))}
          </View>
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noAvailableAudits")} />
        )}
        {modalVisible && (
          <GetModal setModalState={setModalState}>
            <AuditModal
              handleAuth={(empID: string) => handleAppealSubmit(empID)}
              translate={translate}
              modalVisible={modalVisible}
              setModalState={setModalState}
              isIndividualLogin={false}
              isAppeal={true}
            />
          </GetModal>
        )}
      </Table>
    </View>
  );
}

const styles = StyleSheet.create({
  thunderIconView: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 6,
    backgroundColor: colorPallete.variant1
  },

  thunderIconStyle: {
    color: colorPallete.red2,
    // fontSize: 16,
    // lineHeight: 24
    marginVertical: 5
  },
  subHeadingColor: {
    color: colorPallete.black4
    // paddingTop: PlatformUtils.isWeb() ? 0 : 20,
    // paddingBottom: PlatformUtils.isWeb() ? 0 : 15
  },
  headerCellColor: { color: colorPallete.grey1 },
  containerFlex: { flex: 1 },
  tableRow: {
    backgroundColor: colorPallete.grey9,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  tableContainerView: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8
  }
});

export default LpNewAppealTable;
